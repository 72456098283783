import type { Static } from "runtypes";
import { Literal, Union } from "runtypes";

export const RoleName = Union(
  Literal("Super Administrator"),
  Literal("Regional Manager"),
  Literal("Request Manager"),
  Literal("Request Assignee"),
  Literal("Request Reporter"),
  Literal("Property Manager"),
  Literal("Workplace Manager"),
  Literal("Booking Manager"),
  Literal("Booking Observer"),
  Literal("Flex Manager"),
);

export type RoleName = Static<typeof RoleName>;

export const toRoleName = (name: unknown) => {
  return name != null && RoleName.guard(name) ? name : ("Unknown" as const);
};
