import type { Static } from "runtypes";
import { Literal, Union } from "runtypes";

export const ResourceType = Union(
  Literal("region"),
  Literal("client"),
  Literal("site"),
  Literal("building"),
  Literal("company"),
  Literal("siteCompany"),
  Literal("buildingCompany"),
);

export type ResourceType = Static<typeof ResourceType>;

export const toResourceType = (type: unknown) => {
  return type != null && ResourceType.guard(type) ? type : ("unknown" as const);
};
