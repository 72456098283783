import React from "react";

import { Toggler } from "@equiem/react-admin-ui";

import type { CompanyFragment } from "../../../generated/settings-client";

import { JLLEvolutionIntegration } from "./integrations/JLLEvolutionIntegration";

interface Props {
  company: CompanyFragment;
}

export const CompanyIntegrations: React.FC<Props> = ({ company }) => {
  return (
    <>
      <div className="integrations-container">
        <div className="settings-container">
          <Toggler heading="JLL Evolution" defaultOpen>
            <JLLEvolutionIntegration company={company} />
          </Toggler>
        </div>
      </div>
      <style jsx>{`
        .integrations-container {
          margin: 24px 0;
        }

        .settings-container :global(h4) {
          font-size: 14px;
          line-height: 24px;
        }

        .settings-container :global(.head) {
          align-items: center;
        }
      `}</style>
    </>
  );
};
