import React, { useContext, useEffect, useState } from "react";

import { useTranslation } from "@equiem/localisation-eq1";
import {
  Button,
  Dropdown,
  ProgressCircle,
  SteppedProgressBar,
  Text,
  useConfirmer,
  useTheme,
  useToast,
} from "@equiem/react-admin-ui";
import { RiArrowLeftLine, RiCloseFill } from "@equiem/react-admin-ui/icons";

import { useCreatePartialRequestMutation } from "../../generated/requests-client";

import { BuildingStep } from "./steps/BuildingStep";
import { CategoryStep } from "./steps/CategoryStep";
import { DetailsStep } from "./steps/DetailsStep";
import { LevelStep } from "./steps/LevelStep";
import { ReviewStep } from "./steps/ReviewStep";
import { SpaceStep } from "./steps/SpaceStep";
import type { RequestValues } from "./CreateRequestContext";
import { CreateRequestContext } from "./CreateRequestContext";

const SubmitRequestButton = ({ isLoading, onClick }: { isLoading: boolean; onClick: () => void }) => {
  const { t } = useTranslation();

  return (
    <Button variant="primary" size="lg" onClick={onClick} className="w-100 m-0" disabled={isLoading}>
      {isLoading && <ProgressCircle size="sm" className="mr-3" />}
      {isLoading ? t("common.submitting") : t("requests.create.submitRequest")}
    </Button>
  );
};

const ReviewRequestButton = ({ onClick, isDisabled }: { onClick: () => void; isDisabled: boolean }) => {
  const { t } = useTranslation();

  return (
    <Button variant="primary" size="lg" onClick={onClick} className="w-100 m-0" disabled={isDisabled}>
      {t("requests.create.reviewRequest")}
    </Button>
  );
};

export const CreateRequest: React.FC = () => {
  const { colors } = useTheme();
  const [step, setStep] = useState(1);
  const [navigationDirection, setNavigationDirection] =
    useState<CreateRequestContext["navigationDirection"]>("forward");
  const [values, setValues] = useState<RequestValues>({});
  const { setShow, subscribe } = useContext(Dropdown.Context);
  const { withConfirmation } = useConfirmer();
  const { t } = useTranslation();
  const toast = useToast();
  const [isAttachmentUploading, setIsAttachmentUploading] = useState(false);
  const [createRequest, { loading: isSubmitLoading }] = useCreatePartialRequestMutation();
  const [showModal, setShowModal] = useState(false);

  const handleSubmit = () => {
    if (values.spaceUuid != null && values.categoryUuid != null) {
      createRequest({
        variables: {
          input: {
            spaceUuid: values.spaceUuid,
            categoryUuid: values.categoryUuid,
            description: values.description ?? "",
            attachments:
              values.attachments?.map((file) => ({
                temporaryUuid: file.temporaryUuid,
                filename: file.value?.filename ?? (file as { filename: string }).filename,
              })) ?? [],
          },
        },
      })
        .then((result) => {
          if (result.errors != null) {
            toast.negative(result.errors[0].message);
            return;
          }

          if (result.data?.reqMgt.createPartialRequest.reference != null) {
            setValues((prev) => ({ ...prev, reference: result.data?.reqMgt.createPartialRequest.reference }));
            setShowModal(true);
          }
        })
        .catch((e: Error) => {
          toast.negative(e.message ?? t("requests.create.error"));
        });
    }
  };

  const handleClose = () => {
    withConfirmation({
      title: t("requests.create.deleteNewRequest"),
      message: t("requests.create.deleteNewRequestMessage"),
      confirmButtonText: t("common.yesClose"),
      cancelButtonText: t("common.cancel"),
      confirmButtonVariant: "danger",
      onConfirm: () => setShow(false),
    })();
  };

  useEffect(() => {
    const unsubscribe = subscribe("hide", handleClose);

    return () => {
      unsubscribe();
    };
  }, []);

  const nextStep = () => {
    setStep((prev) => prev + 1);
    setNavigationDirection("forward");
  };

  const handleBack = () => {
    setStep((prev) => prev - 1);
    setNavigationDirection("backward");
  };

  const setStepValue = (value: number) => {
    setStep(value);
    setNavigationDirection(undefined);
  };

  return (
    <CreateRequestContext.Provider
      value={{
        nextStep,
        isAttachmentUploading,
        setIsAttachmentUploading,
        setStep: setStepValue,
        showModal,
        navigationDirection,
        step,
        values,
        setValues,
      }}
    >
      <div className="container">
        <div className="header">
          {step > 1 && (
            <Button
              variant="ghost"
              size="md"
              className="back"
              round
              onClick={handleBack}
              disabled={isAttachmentUploading}
              aria-label={t("common.back")}
            >
              <RiArrowLeftLine size={20} color={colors.grayscale[60]} />
            </Button>
          )}
          <Text variant="label">{t("requests.create.newRequest")}</Text>
          {step === 1 && (
            <Button
              variant="ghost"
              size="md"
              className="close"
              round
              onClick={handleClose}
              aria-label={t("common.close")}
            >
              <RiCloseFill size={20} color={colors.grayscale[60]} />
            </Button>
          )}
        </div>
        <SteppedProgressBar steps={6} currentStep={step} className="mb-6" />
        <div className="content">
          {step === 1 && <BuildingStep />}
          {step === 2 && <LevelStep />}
          {step === 3 && <SpaceStep />}
          {step === 4 && <CategoryStep />}
          {step === 5 && <DetailsStep />}
          {step === 6 && <ReviewStep />}
        </div>
      </div>
      {(step === 5 || step === 6) && (
        <div className="action-container">
          {step === 5 && (
            <ReviewRequestButton
              onClick={nextStep}
              isDisabled={values.description == null || values.description.length === 0 || isAttachmentUploading}
            />
          )}
          {step === 6 && <SubmitRequestButton isLoading={isSubmitLoading} onClick={handleSubmit} />}
        </div>
      )}
      <style jsx>{`
        .container {
          display: flex;
          flex-direction: column;
          padding: 0 16px;
          height: 100vh;
          overflow: hidden;
        }

        .header {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: 8px;
          position: relative;
          width: 100%;
          min-height: 32px;
        }

        .content {
          overflow-y: auto;
          height: 100%;
        }

        .container :global(.close),
        .container :global(.back) {
          position: absolute;
          top: 0;
        }

        .container :global(.back) {
          left: 0;
          margin-left: -8px;
        }

        .container :global(.close) {
          right: 0;
          margin-right: -8px;
        }

        .action-container {
          position: relative;
          bottom: 0;
          border-top: 1px solid ${colors.grayscale[10]};
          width: 100%;
          padding: 16px 16px 8px;
        }
      `}</style>
    </CreateRequestContext.Provider>
  );
};
