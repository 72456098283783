import { notNullOrUndefined } from "@equiem/lib";
import { DateTime } from "luxon";

function parseRange(rangeString: string | null | undefined, timezone: string) {
  if (rangeString == null) {
    return { startDate: null, endDate: null };
  }
  const availabilityStartInclusive = rangeString.startsWith("[");
  const availabilityEndInclusive = rangeString.endsWith("]");
  const tokens = rangeString
    .slice(1, rangeString.length - 1)
    .split(",")
    .map((str) => str.trim());
  if (tokens.length !== 2) {
    return { startDate: null, endDate: null };
  }
  const [startDateStr, endDateStr] = tokens;

  const startDate =
    startDateStr !== "infinity"
      ? DateTime.fromFormat(startDateStr, "yyyy-LL-dd", { zone: timezone }).plus({
          days: availabilityStartInclusive ? 0 : 1,
        })
      : null;
  const endDate =
    endDateStr !== "infinity"
      ? DateTime.fromFormat(endDateStr, "yyyy-LL-dd", { zone: timezone }).minus({
          days: availabilityEndInclusive ? 0 : 1,
        })
      : null;

  return {
    startDate: startDate?.isValid !== false ? startDate : null,
    endDate: endDate?.isValid !== false ? endDate : null,
  };
}

function getBookingWindow(
  bookingWindowMinInMinutes: number | null | undefined,
  bookingWindowMaxInMinutes: number | null | undefined,
  timezone: string,
) {
  const startDate =
    bookingWindowMinInMinutes != null
      ? DateTime.now().setZone(timezone).plus({
          minutes: bookingWindowMinInMinutes,
        })
      : null;
  const endDate =
    bookingWindowMaxInMinutes != null
      ? DateTime.now().setZone(timezone).plus({
          minutes: bookingWindowMaxInMinutes,
        })
      : null;

  return { startDate, endDate };
}

export function getAvailabilityDateRange(
  availabilityDateRange: string | null | undefined,
  bookingWindowMinInMinutes: number | null | undefined,
  bookingWindowMaxInMinutes: number | null | undefined,
  timezone: string,
) {
  const availability = parseRange(availabilityDateRange, timezone);
  const bookingWindow = getBookingWindow(bookingWindowMinInMinutes, bookingWindowMaxInMinutes, timezone);

  const startDateConstraints = [availability.startDate, bookingWindow.startDate].filter(notNullOrUndefined);
  const endDateConstraints = [availability.endDate, bookingWindow.endDate].filter(notNullOrUndefined);

  const startDate = DateTime.max(...startDateConstraints, DateTime.now().setZone(timezone));
  const endDate = endDateConstraints.length > 0 ? DateTime.min(...endDateConstraints) : null;

  return { startDate, endDate };
}
