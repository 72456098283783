import React, { useContext, useMemo, useState } from "react";
import { useTranslation } from "@equiem/localisation-eq1";
import { IconContext } from "./IconProvider";
import { useTheme } from "../../contexts";
import { useDebounced } from "../../hooks";
import { AdminDropdownButton } from "../Dropdown/DropdownButton/AdminDropdownButton";
import * as Dropdown from "../Dropdown";
import * as Form from "../Form";
import { ProgressCircle } from "../ProgressCircle/ProgressCircle";
import { RiCloseCircleLine, RiQuestionLine, RiSearchLine } from "react-icons/ri";
import { Icon } from "./Icon";

interface ChangeEvent {
  target: Partial<EventTarget & HTMLInputElement>;
}

export interface Props {
  selectedIcon?: string;
  onSelect: (icon: string) => void;
  onSelectLoading?: boolean;
  size?: "sm" | "md" | "lg";
  className?: string;
  name?: string;
  onChange?: (event: ChangeEvent) => void;
}
export const IconSelector: React.FC<Props> = ({
  name,
  selectedIcon,
  onSelect,
  onSelectLoading,
  size = "md",
  className,
  onChange,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const { t } = useTranslation();
  const theme = useTheme();
  const debounceTimeout = 200;
  const debouncedSearchTerm = useDebounced(searchTerm, debounceTimeout);
  const { filteredIcon } = useContext(IconContext);

  const icons = useMemo(() => {
    return filteredIcon(debouncedSearchTerm);
  }, [debouncedSearchTerm, filteredIcon]);

  return (
    <>
      <AdminDropdownButton
        className={className}
        variant="outline"
        dropdownToggleColor={theme.colors.grayscale["60"]}
        title={
          onSelectLoading === true ? (
            <ProgressCircle size="xs" />
          ) : selectedIcon != null ? (
            <Icon name={selectedIcon} size="sm" />
          ) : (
            <RiQuestionLine size="18px" color={theme.colors.grayscale["60"]} />
          )
        }
        size={size}
        disabled={onSelectLoading === true}
        fixedHeader={
          <div className="px-3 pb-2">
            <Form.InputGroup.Group>
              <Form.InputGroup.Prefix>
                <RiSearchLine size={16} />
              </Form.InputGroup.Prefix>
              <Form.Input
                type="text"
                placeholder={t("common.search") ?? ""}
                className="search"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                maxLength={20}
              />
              {searchTerm !== "" && (
                <Form.InputGroup.Suffix>
                  <RiCloseCircleLine onClick={() => setSearchTerm("")} />
                </Form.InputGroup.Suffix>
              )}
            </Form.InputGroup.Group>
          </div>
        }
      >
        <div className="icon-container px-3">
          {icons.map((icon) => (
            <Dropdown.Item
              showTick={false}
              selected={selectedIcon === icon.name}
              key={icon.name}
              className="p-2 m-0 d-flex align-items-center"
              onClick={() => {
                onSelect(icon.name);
                onChange?.({
                  target: {
                    name: name,
                    value: icon.name,
                  },
                });
              }}
            >
              <Icon
                name={icon.name}
                humanName={icon.human}
                fontSize="20px"
                className="cursor-pinter"
                color={theme.colors.grayscale["60"]}
              />
            </Dropdown.Item>
          ))}
          {icons.length === 0 && (
            <div className="p-3 empty-result">{t("common.emptySearch", { searchTerm: debouncedSearchTerm })}</div>
          )}
        </div>
      </AdminDropdownButton>

      <style jsx>{`
        .icon-container {
          display: grid;
          grid-template-columns: repeat(8, 29px);
          gap: ${theme.spacers.s2};
        }
        .empty-result {
          min-width: 280px;
        }
      `}</style>
    </>
  );
};
