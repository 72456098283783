import React from "react";

import type { BorderCssProps } from "../useInputBorderCss";

export type FormInputGroupDirection = "row" | "column";
export interface FormInputGroupContext {
  inGroup: boolean;
  hasPrefix: boolean;
  hasSuffix: boolean;
  setHasPrefix: (has: boolean) => void;
  setHasSuffix: (has: boolean) => void;
  rounded?: BorderCssProps["rounded"];
  direction: FormInputGroupDirection;
}

export const FormInputGroupContext = React.createContext<FormInputGroupContext>({
  inGroup: false,
  hasPrefix: false,
  hasSuffix: false,
  setHasPrefix: () => undefined,
  setHasSuffix: () => undefined,
  direction: "row",
});
