import React from "react";
import { useCurrencyCode } from "../hooks/useCurrency";
import { formatCurrency } from "./formatCurrency";
import { useTranslation } from "@equiem/localisation-eq1";

interface Props {
  amount: number;
  buildingUuid?: string | null;
  currencyCode?: string | null;
  className?: string;
  priceIsPerItem?: boolean;
  zeroValueText?: string;
  overridden?: boolean;
}
export const CurrencyAmount: React.FC<Props> = ({
  amount,
  buildingUuid,
  currencyCode,
  priceIsPerItem = false,
  className,
  zeroValueText,
  overridden = false,
}) => {
  const currency = useCurrencyCode(buildingUuid, currencyCode);
  const { t, i18n } = useTranslation();

  if (zeroValueText != null && amount === 0) {
    return <span className={className}>{zeroValueText}</span>;
  }

  const price = formatCurrency(amount, currency, i18n.language);

  return (
    <>
      <span className={`${className} ${overridden ? "overridden" : ""}`}>
        {priceIsPerItem ? t("common.perItemCost", { price }) : price}
      </span>
      <style jsx>{`
        .overridden {
          text-decoration: line-through;
        }
      `}</style>
    </>
  );
};
