import React, { useContext } from "react";

import { useTranslation } from "@equiem/localisation-eq1";
import { Button, Material, Text, useTheme } from "@equiem/react-admin-ui";
import { RiBuilding4Fill, RiEditLine } from "@equiem/react-admin-ui/icons";

import { FileItem } from "../../../components/FileItem";
import { CreateRequestContext } from "../CreateRequestContext";
import { useBuildingData } from "../hooks/useBuildingData";
import { RequestCreatedWidget } from "../widgets/RequestCreatedWidget";

export const ReviewStep: React.FC = () => {
  const { colors } = useTheme();
  const { t } = useTranslation();
  const { locationName, currentCategory } = useBuildingData();
  const { setStep, values, showModal } = useContext(CreateRequestContext);

  const handleEdit = (step: number) => () => {
    setStep(step);
  };

  return (
    <div className="container">
      <Text variant="label">{t("common.summary")}</Text>
      <Text variant="heading" size="small">
        {t("requests.create.reviewYourRequest")}
      </Text>
      <div className="list">
        <div className="item">
          <Text variant="label">{t("requests.create.location")}</Text>
          <div className="details">
            <RiBuilding4Fill size={16} color={colors.blue[60]} />
            <Text variant="text" size="small" weight="medium" className="m-0">
              {locationName}
            </Text>
            <Button variant="ghost" onClick={handleEdit(1)} className="m-0" round>
              <RiEditLine size={16} color={colors.transparent.black[60]} />
            </Button>
          </div>
        </div>
        <div className="item">
          <Text variant="label">{t("requests.create.category")}</Text>
          <div className="details">
            <Material.Icon name={currentCategory?.iconName ?? "category"} size="sm" color={colors.blue[60]} />
            <Text variant="text" size="small" weight="medium" className="m-0">
              {currentCategory?.name ?? "-"}
            </Text>
            <Button variant="ghost" onClick={handleEdit(4)} className="m-0" round>
              <RiEditLine size={16} color={colors.transparent.black[60]} />
            </Button>
          </div>
        </div>
        <div className="item">
          <Text variant="label">{t("requests.create.details")}</Text>
          <div className="details large">
            <Text variant="text" className="m-0">
              {values.description ?? "-"}
            </Text>
            <Button variant="ghost" onClick={handleEdit(5)} className="m-0" round>
              <RiEditLine size={16} color={colors.transparent.black[60]} />
            </Button>
          </div>
        </div>
        {(values.attachments?.length ?? 0) > 0 && (
          <div className="item">
            <Text variant="label">{t("common.attachments")}</Text>

            <div className="attached-files">
              {values.attachments?.map((item, index) => (
                <FileItem isFullWidthAttachment index={index} file={item} key={item.temporaryUuid} />
              ))}
            </div>
          </div>
        )}
      </div>
      <RequestCreatedWidget show={showModal} />
      <style jsx>
        {`
          .container {
            display: flex;
            flex-direction: column;
            height: 100%;
          }

          .list {
            display: flex;
            flex-direction: column;
            margin-top: 24px;
            padding-bottom: 24px;
            gap: 24px;
          }

          .item {
            display: flex;
            flex-direction: column;
            gap: 8px;
          }

          .details {
            display: grid;
            grid-template-columns: 16px 1fr 32px;
            align-items: center;
            gap: 12px;
            padding: 8px 8px 8px 16px;
            border-radius: 4px;
            border: 1px solid ${colors.grayscale[10]};
            cursor: pointer;
          }

          .details.large {
            grid-template-columns: 1fr 32px;
            align-items: flex-start;
          }

          .details.large :global(p) {
            overflow-y: auto;
            padding-top: 6px;
            max-height: 100px;
            white-space: pre-wrap;
          }

          .action-container {
            position: absolute;
            bottom: 0;
            border-top: 1px solid ${colors.grayscale[10]};
            width: 100%;
            padding: 16px;
            margin-left: -16px;
          }
        `}
      </style>
    </div>
  );
};
