import type { DefaultOptions } from "@apollo/client/core";

export const defaultOptions: DefaultOptions = {
  query: { errorPolicy: "all" },
  watchQuery: {
    errorPolicy: "all",
    nextFetchPolicy(currentFetchPolicy) {
      if (currentFetchPolicy === "network-only" || currentFetchPolicy === "cache-and-network") {
        // Demote the network policies (except "no-cache") to "cache-first"
        // after the first request.
        return "cache-first";
      }
      // Leave all other fetch policies unchanged.
      return currentFetchPolicy;
    },
  },
};
