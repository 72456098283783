import React from "react";

import type { ButtonProps } from "../../Button/AdminButton";
import { Dropdown } from "../Dropdown";
import { DropdownMenu } from "../DropdownMenu/DropdownMenu";
import { AdminDropdownToggle as DropdownToggle } from "../DropdownToggle/AdminDropdownToggle";
import type { Placement } from "@floating-ui/react-dom";

// eslint-disable-next-line @typescript-eslint/no-type-alias
export type DropdownButtonProps = Omit<ButtonProps, "title"> & {
  placement?: Placement;
  onSelect?: (eventKey: string) => undefined;
  flip?: boolean;
  icon?: React.ReactNode;
  dropdownTitle?: string;
  supportsMobile?: boolean;
  title?: string | React.ReactNode | undefined;
  fixedHeader?: React.ReactElement;
};

export interface DropdownButtonRef {
  click: () => void;
  focus: () => void;
}

export const AdminDropdownButton = React.forwardRef<DropdownButtonRef, DropdownButtonProps>(
  (
    {
      children,
      placement = "bottom-start",
      onSelect,
      flip = true,
      dropdownTitle,
      supportsMobile,
      title,
      fixedHeader,
      ...props
    },
    ref,
  ) => {
    return (
      <Dropdown placement={placement} onSelect={onSelect} flip={flip}>
        <DropdownToggle {...props} ref={ref}>
          {title}
        </DropdownToggle>
        <DropdownMenu
          title={dropdownTitle}
          mobileView={supportsMobile === true ? "full" : undefined}
          fixedHeader={fixedHeader}
        >
          {children}
        </DropdownMenu>
      </Dropdown>
    );
  },
);

AdminDropdownButton.displayName = "DropdownButton";
