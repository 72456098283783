import React from "react";

import { useTranslation } from "@equiem/localisation-eq1";
import { Text, useIsMobileWidth, useTheme } from "@equiem/react-admin-ui";

export const NewRequestActivity = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const isMobile = useIsMobileWidth();

  return (
    <div className="container">
      <div className="p-6 header">
        <Text variant="label" color={theme.colors.grayscale[isMobile ? "60" : "40"]}>
          {t("common.activity")}
        </Text>
      </div>

      <div className="activities">
        <div className="empty-message px-4">
          <Text variant="text" size="small" color={theme.colors.grayscale[40]}>
            {t("requests.activity.emptyMessage")}
          </Text>
        </div>
      </div>

      <style jsx>{`
        .container {
          height: calc(100vh - 242px);
        }
        .header {
          display: flex;
          align-items: center;
          border-bottom: 1px solid ${theme.colors.border};
        }

        .activities {
          height: calc(100% - 65px);
          background: ${isMobile ? theme.colors.grayscale[3] : "inherit"};
        }

        .empty-message {
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        @media (max-width: ${theme.breakpoints.lg}px) {
          .container {
            height: 100%;
          }
        }
      `}</style>
    </div>
  );
};
