import React from "react";
import { SiteLogo, useSiteContext, stringNotEmpty } from "@equiem/lib";
import { Avatar, Tag, Tooltip, useTheme } from "@equiem/react-admin-ui";
import { useTranslation, formatters } from "@equiem/localisation-eq1";
import { DateTime } from "luxon";

import type { ArticleDetail } from "../util/Article";
import { RiInformationLine } from "@equiem/react-admin-ui/icons";

interface Props {
  article: ArticleDetail;
  showAuthorTooltip?: boolean;
}

export const ArticleByline: React.FC<Props> = ({ article, showAuthorTooltip = false }) => {
  const { author, publishDate, categories } = article;

  const { name: siteName, timezone } = useSiteContext();
  const { spacers, colors } = useTheme();
  const { i18n, t } = useTranslation();

  return (
    <>
      <div className="byline">
        <div className="avatar">
          {Object.values(author).some(stringNotEmpty) ? (
            <Avatar firstName={author.firstName} lastName={author.lastName} imageUrl={author.avatarUrl} />
          ) : (
            <SiteLogo className="mr-3" size="32px" />
          )}
        </div>
        <div className="details">
          <div className="author">
            {author.displayName ?? siteName}
            {showAuthorTooltip && (
              <Tooltip title={t("contentManagement.articles.form.authorPreviewTooltip")} placement="right">
                <span>
                  <RiInformationLine color={colors.grayscale[60]} className="ml-1 mb-2" />
                </span>
              </Tooltip>
            )}
          </div>

          {publishDate != null ? (
            <time className="text-muted" dateTime={DateTime.fromMillis(publishDate, { zone: timezone }).toISO() ?? ""}>
              {formatters.timeshort(publishDate, i18n.language, { timezone })}{" "}
              {formatters.dateshort(publishDate, i18n.language, { timezone })}
            </time>
          ) : (
            <div className="text-muted">{t("contentManagement.articles.noPublishDate")}</div>
          )}
        </div>
        <div className="categories">
          {categories.map((label, i) => (
            <Tag key={i}>{label}</Tag>
          ))}
        </div>
      </div>
      <style jsx>{`
        .byline {
          display: flex;
          flex-direction: row;
          gap: ${spacers.s3};
        }
        .avatar {
          flex: 0;
          min-width: 32px;
          width: 32px;
        }
        .details {
          line-height: 16px;
          max-width: 67%;
        }
        .author {
          font-weight: 500;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        time {
          font-size: 12px;
        }
        .categories {
          flex: 1;
          display: flex;
          justify-content: flex-end;
          align-content: center;
          flex-wrap: wrap;
          gap: ${spacers.s3};
          max-width: 67%;
        }
      `}</style>
    </>
  );
};
