import { SubTopMenu } from "@equiem/lib";
import { Text } from "@equiem/react-admin-ui";
import React from "react";
import { useTranslation } from "@equiem/localisation-eq1";

interface Props {
  title?: string;
}

export const Menu: React.FC<Props> = ({ title }) => {
  const { t } = useTranslation();

  return (
    <>
      <SubTopMenu className="title-container mb-0">
        <Text variant="heading" size="large">
          {title ?? t("flex.shortcuts.memberships")}
        </Text>
      </SubTopMenu>
    </>
  );
};
