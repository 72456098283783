import React, { useEffect, useMemo } from "react";

import { stringIsEmpty, stringNotEmpty, useSaferFormikContext, useShowError } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import { Form, Skeleton, useTheme } from "@equiem/react-admin-ui";

import { StatusTag } from "../../../components/StatusTag";
import { ReqMgtStatusType, useStatusesQuery } from "../../../generated/requests-client";
import type { NewRequestFormValues } from "../../create-request/NewRequest";

export const NewRequestStatus = ({ disabled }: { disabled: boolean }) => {
  const showError = useShowError();
  const theme = useTheme();
  const { t } = useTranslation();
  const { values, setFieldValue } = useSaferFormikContext<NewRequestFormValues>();
  const { data, loading, error } = useStatusesQuery({
    fetchPolicy: "network-only",
    variables: { ownerCompanyUuid: values.space?.ownerCompany?.uuid },
    skip: stringIsEmpty(values.space?.ownerCompany?.uuid),
  });
  const weight = useMemo(
    () => [ReqMgtStatusType.NotStarted, ReqMgtStatusType.InProgress, ReqMgtStatusType.Completed],
    [],
  );

  const options = useMemo(() => {
    return (data?.reqMgt.statuses ?? []).map((status) => ({
      label: <StatusTag name={status.name} type={status.type} />,
      value: status.uuid,
      name: status.name,
      group: { name: t(`requests.status.type.${status.type}`), weight: weight.findIndex((v) => v === status.type) },
    }));
  }, [data?.reqMgt.statuses, t, weight]);

  useEffect(() => {
    if (error != null) {
      showError(error);
    }
  }, [error]);

  if (loading) {
    return <Skeleton.Line width="100px" height="20px" />;
  }

  return (
    <>
      <div>
        <Form.DynamicSelect
          mobileView="minimal"
          size="sm"
          disabled={disabled || stringIsEmpty(values.space?.uuid)}
          showSelectedOnList
          name="rstatus"
          showChrome
          value={values.status ?? ""}
          options={options}
          onChange={(event) => {
            const uuid = event.target.value;
            if (stringNotEmpty(uuid) && uuid !== values.status) {
              void setFieldValue("status", uuid);
            }
          }}
        />
      </div>
      <style jsx>{`
        div {
          margin-left: -${theme.spacers.s3};
        }
      `}</style>
    </>
  );
};
