/* eslint-disable @typescript-eslint/no-type-alias */
import React from "react";

interface TypographyHeadingProps {
  variant: "heading";
  size?: "large" | "medium" | "small";
  weight?: "bold";
}

interface TypographyTextProps {
  variant: "text";
  size?: "large" | "medium" | "small" | "extra-small";
  weight?: "regular" | "medium" | "bold";
}

interface TypographyNavigationProps {
  variant: "navigation";
  size?: "default";
  weight?: "medium";
}

interface TypographyButtonProps {
  variant: "button";
  size?: "small" | "default";
  weight?: "bold";
}

interface TypographyLabelProps {
  variant: "label";
  size?: "small" | "default";
  weight?: "medium";
}

interface TypographyBlockProps {
  variant: "block";
  size?: "large" | "medium" | "small" | "extra-small";
  weight?: "regular" | "medium" | "bold";
}

type Props = React.HTMLAttributes<HTMLElement> & {
  component?: keyof HTMLElementTagNameMap;
} & (
    | TypographyHeadingProps
    | TypographyTextProps
    | TypographyNavigationProps
    | TypographyButtonProps
    | TypographyLabelProps
    | TypographyBlockProps
  );

interface TypographyElements {
  h1: React.DetailedHTMLProps<React.HTMLAttributes<HTMLHeadingElement>, HTMLHeadingElement>;
  p: React.DetailedHTMLProps<React.HTMLAttributes<HTMLParagraphElement>, HTMLParagraphElement>;
  span: React.DetailedHTMLProps<React.HTMLAttributes<HTMLSpanElement>, HTMLSpanElement>;
}

const componentMapping = {
  heading: "h1",
  navigation: "span",
  label: "span",
  text: "p",
  button: "span",
  block: "div",
} as const;

export const Text = React.forwardRef<HTMLElement, Props>(
  ({ children, variant = "text", size = "default", weight, component, className, color, ...props }, ref) => {
    const Component = (component ?? componentMapping[variant]) as keyof TypographyElements;

    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    const propsWithRef = { ...props, ref } as Props & { ref: never };

    return (
      <Component
        className={`break ${color != null ? "color" : ""} typography--${variant} typography--${variant}-${size} ${
          className ?? ""
        } ${weight != null ? `typography--${weight}` : ""}`.trim()}
        {...propsWithRef}
      >
        {children}
        <style jsx>{`
          .break {
            overflow-wrap: break-word;
          }

          .color {
            color: ${color};
          }

          .typography--navigation {
            font-size: 0.75rem;
            line-height: 1rem;
            font-weight: 500;
          }

          .typography--text {
            font-size: 1rem;
            line-height: 1.25rem;
            font-weight: 400;
          }
          .typography--text-small {
            font-size: 0.875rem;
            line-height: 1rem;
          }
          .typography--text-extra-small {
            font-size: 0.75rem;
            line-height: 1rem;
          }
          .typography--text-large {
            font-size: 1.125rem;
            line-height: 1.5rem;
          }

          .typography--label {
            font-size: 0.75rem;
            line-height: 1rem;
            font-weight: 500;
            text-transform: uppercase;
            letter-spacing: 0.02em;
          }
          .typography--label-small {
            font-size: 0.625rem;
            line-height: 1rem;
          }

          .typography--heading {
            font-size: 1.25rem;
            line-height: 1.5rem;
            font-weight: 700;
          }
          .typography--heading-small {
            font-size: 1.125rem;
            line-height: 1.5rem;
          }
          .typography--heading-large {
            font-size: 1.5rem;
            line-height: 1.75rem;
          }

          .typography--button {
            font-size: 0.875rem;
            line-height: 1.5rem;
            font-weight: 700;
            text-transform: uppercase;
            letter-spacing: 0.02em;
          }
          .typography--button-small {
            font-size: 0.75rem;
            line-height: 1rem;
          }

          .typography--regular {
            font-weight: 400;
          }
          .typography--medium {
            font-weight: 500;
          }
          .typography--bold {
            font-weight: 700;
          }
        `}</style>
      </Component>
    );
  },
);

Text.displayName = "Text";
