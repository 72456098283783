import { useCallback } from "react";
import { useApolloClient } from "@apollo/client";
import { saveAs } from "file-saver";

import { useSiteContext } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import { useToast } from "@equiem/react-admin-ui";

import type { ExportCompaniesCsvQuery, ExportCompaniesCsvQueryVariables } from "../../../generated/settings-client";
import { ExportCompaniesCsvDocument } from "../../../generated/settings-client";

export const useExportCompanyList = (variables: ExportCompaniesCsvQueryVariables) => {
  const { t } = useTranslation();
  const client = useApolloClient();
  const toast = useToast();
  const site = useSiteContext();
  const func = useCallback(async () => {
    const result = await client.query<ExportCompaniesCsvQuery, ExportCompaniesCsvQueryVariables>({
      query: ExportCompaniesCsvDocument,
      variables,
    });
    if (result.data.companiesV2CSV == null) {
      toast.negative(t("settings.failedToExportCompanies"));
      return;
    }
    saveAs(new Blob([result.data.companiesV2CSV]), `${site.name.toLowerCase()}_companies.csv`, true);
  }, [variables, client]);

  return func;
};
