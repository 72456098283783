import type { ReactElement } from "react";
import React from "react";

import { RiUserAddLine } from "@equiem/react-admin-ui/icons";

export const NoUsersPlaceholder: React.FC<{ text: ReactElement }> = ({ text }) => (
  <div className="placeholder-container align-items-center flex-column p-7">
    <div className="p-5 m-5" style={{ background: "rgba(0, 0, 0, 0.05)", borderRadius: 30 }}>
      <RiUserAddLine size={21} color="#999999" />
    </div>
    <span className="placeholder-message">{text}</span>
    <style jsx>{`
      .placeholder-container {
        display: flex;
        max-width: 260px;
        margin: auto;
        text-align: center;
      }
      .placeholder-message {
        font-size: 12px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.4);
      }
    `}</style>
  </div>
);
