import type { FC } from "react";
import { RiMenuLine } from "@equiem/react-admin-ui/icons";
import { Button, useTheme } from "@equiem/react-admin-ui";

export const HamburgerButton: FC<{ toggleMenu: () => void }> = ({ toggleMenu }) => {
  const { colors } = useTheme();

  return (
    <>
      <div
        className="burger-menu-button"
        style={{
          paddingRight: 15,
        }}
      >
        <Button size="sm" round variant="ghost" onClick={toggleMenu}>
          <RiMenuLine size={20} />
        </Button>
      </div>
      <style jsx>{`
        div :global(button:hover) {
          background-color: ${colors.white} !important;
        }
      `}</style>
    </>
  );
};
