import type { FC } from "react";
import { useState } from "react";
import { Avatar, useTheme, useToast } from "@equiem/react-admin-ui";
import { UnreadIcon } from "./UnreadIcon";
import type { EquiemOneWidgetId } from "../../generated/gateway-client";
import {
  useDeleteAdminNotificationMutation,
  useMarkAdminNotificationAsReadMutation,
} from "../../generated/gateway-client";
import { DeleteIcon } from "./DeleteIcon";
import { DateTime } from "luxon";
import { useRouter } from "next/router";
import { useTranslation } from "@equiem/localisation-eq1";

interface Props {
  uuid: string;
  title: string;
  read: boolean;
  url?: string | null;
  avatar?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  created: number;
  linkToWidget?: EquiemOneWidgetId | null;
  reload: () => Promise<unknown>;
}

export const Notification: FC<Props> = ({
  uuid,
  title,
  read,
  created,
  url,
  avatar,
  firstName,
  lastName,
  reload,
  linkToWidget,
}) => {
  const router = useRouter();
  const [mutation] = useMarkAdminNotificationAsReadMutation();
  const [deleteMutation] = useDeleteAdminNotificationMutation();
  const { colors, spacers, borderRadius, topMenuHeight } = useTheme();
  const toast = useToast();
  const [mouseOver, setMouseOver] = useState(false);
  const [deleted, setDeleted] = useState(false);
  const { t } = useTranslation();

  const relativeTime = DateTime.fromMillis(created).toRelative();

  const handleClick = async () => {
    if (url == null && linkToWidget != null) {
      const element = document.getElementById(linkToWidget);
      window.scrollTo({
        top: (element?.getBoundingClientRect().top ?? 0) - topMenuHeight,
        behavior: "smooth",
      });
    }

    await mutation({
      variables: {
        uuid,
      },
    });
    if (url != null) {
      const isRelative = new URL(document.baseURI).origin === new URL(url, document.baseURI).origin;
      if (isRelative) {
        await router.push(url);
      } else {
        window.open(url, "_blank");
      }
    }
  };

  const handleDelete = () => {
    setDeleted(true);
    const FIVE_SECONDS = 5000;
    const timeout = setTimeout(async () => {
      await deleteMutation({
        variables: {
          uuid,
        },
      });
      await reload();
    }, FIVE_SECONDS);

    const deletionToast = toast.neutral(t("home.notificationRemoved"), {
      secondaryAction: {
        title: t("common.undo"),
        onClick: () => {
          clearTimeout(timeout);
          setDeleted(false);
          deletionToast.remove();
        },
      },
    });
  };

  return (
    <div className="container" onMouseEnter={() => setMouseOver(true)} onMouseLeave={() => setMouseOver(false)}>
      <div className="icon" onClick={handleClick}>
        <Avatar imageUrl={avatar} firstName={firstName} lastName={lastName} size="medium" />
      </div>
      <div className="content" onClick={handleClick}>
        <p>{title}</p>
        <p className="time">{relativeTime}</p>
      </div>
      <div className="extras">
        {!read && (
          <>
            {mouseOver ? (
              <div className="delete" onClick={handleDelete}>
                <DeleteIcon />
              </div>
            ) : (
              <div className="unread">
                <UnreadIcon />
              </div>
            )}
          </>
        )}
        {read && (
          <div className="delete" onClick={handleDelete}>
            <DeleteIcon />
          </div>
        )}
      </div>

      <style jsx>{`
        .container {
          padding: ${spacers.s3};
          cursor: default;
          text-align: left;
          border-radius: ${borderRadius};
          margin: 0 ${spacers.s3};
          font-weight: 500;
          font-size: 12px;
          line-height: 16px;
          display: flex;
          align: center;
          display: ${deleted ? "none" : "default"};
          min-width: 268px;
        }
        p {
          margin: 0;
        }
        .content {
          display: flex;
          flex-direction: column;
          cursor: ${url != null ? "pointer" : "default"};
        }
        .time {
          font-weight: 400;
          color: #666666;
        }
        div:hover {
          background: ${colors.lightHover};
        }
        .extras {
          display: flex;
          margin-left: auto;
        }
        .unread,
        .delete {
          margin-left: ${spacers.s3};
          margin-right: ${spacers.s3};
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;
        }
        .icon {
          display: flex;
          align-items: start;
          justify-content: center;
          padding-right: ${spacers.s3};
        }
        .unread {
          opacity: ${mouseOver ? 0 : 1};
        }
        .delete {
          opacity: ${mouseOver ? 1 : 0};
          cursor: pointer;
        }
      `}</style>
    </div>
  );
};
