import React, { forwardRef, useState } from "react";
import type { ComponentProps, ReactNode } from "react";
import { useTheme } from "../../contexts";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import type { IconType } from "react-icons";
import { IconButton } from "../Button/IconButton";

// eslint-disable-next-line @typescript-eslint/no-type-alias
export type AccordionListProps = ComponentProps<"div"> & {
  showCollapseButton?: boolean;
  showCollapsed?: boolean;
  HeaderIcon?: IconType;
  headerText: ReactNode;
  colorTheme?: "success" | "warning" | "error" | "white";
};

export const AccordionList = forwardRef<HTMLDivElement, AccordionListProps>(
  (
    {
      showCollapseButton = true,
      showCollapsed = true,
      HeaderIcon,
      colorTheme = "success",
      headerText,
      children,
      className,
    },
    ref,
  ) => {
    const { colors, spacers, sizes } = useTheme();
    const [collapseList, setCollapseList] = useState(showCollapsed);

    const onListCollapseClick = () => {
      setCollapseList(!collapseList);
    };

    const CollapseIcon = collapseList ? BsChevronUp : BsChevronDown;

    const coloring =
      colorTheme === "warning"
        ? {
            border: "transparent",
            background: colors.status.warning.accent,
            iconColor: colors.status.warning.primary,
          }
        : colorTheme === "error"
        ? {
            border: "transparent",
            background: colors.status.danger.accent,
            iconColor: colors.status.danger.primary,
          }
        : colorTheme === "white"
        ? {
            border: colors.border,
            background: colors.white,
            iconColor: colors.primary,
          }
        : {
            border: "transparent",
            background: colors.status.positive.accent,
            iconColor: colors.status.positive.primary,
          };

    return (
      <div className={`accordion-cont ${className} ${collapseList ? "opened" : "closed"}`} ref={ref}>
        <div className="accordion-internal">
          <div className="icon-and-header">
            {HeaderIcon != null && (
              <div className="icon d-flex justify-content-center align-items-center">
                <HeaderIcon color={coloring.iconColor} size={sizes.xs} />
              </div>
            )}
            <div className="header-text">{headerText}</div>
          </div>
          {showCollapseButton && (
            <div>
              <IconButton onClick={onListCollapseClick}>
                <CollapseIcon size={16} strokeWidth={0.5} />
              </IconButton>
            </div>
          )}
        </div>
        {collapseList && <div className="accordion-items">{children}</div>}
        <style jsx>{`
          .accordion-cont {
            border: 1px solid ${coloring.border};
            background-color: ${coloring.background};
            padding: ${sizes.xs}px;
            border-radius: 8px;
          }
          .accordion-internal {
            display: grid;
            grid-template-columns: 1fr 32px;
            line-height: ${sizes.sm}px;
          }
          .accordion-items {
            margin-top: 10px;
          }
          .icon {
            flex-shrink: 0;
            height: ${sizes.md}px;
            width: ${sizes.md}px;
            border-radius: 50%;
            background-color: rgba(from ${coloring.iconColor} r g b / 10%);
          }
          .icon-and-header {
            display: flex;
            gap: ${spacers.s3};
            align-items: center;
          }
          .header-text {
            font-size: 14px;
          }
        `}</style>
      </div>
    );
  },
);

AccordionList.displayName = "AccordionList";
