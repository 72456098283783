import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { Button, Text, Tooltip, useTheme } from "@equiem/react-admin-ui";
import { RiCloseLine, RiFileLine } from "@equiem/react-admin-ui/icons";
import { Session } from "@equiem/lib";
import { useCreateSecurePresignedUrlMutation } from "../generated/iris-client";
import type { IFile } from "@equiem/uploader";
import { useTranslation } from "@equiem/localisation-eq1";
import { StatusCodes } from "http-status-codes";

interface Props {
  file: File | IFile;
  index: number;
  onRemove: (_index: number) => void;
  onUpload: (_index: number, _file: IFile) => void;
}

const maxFileSizeInBytes = 5000000;

type Status = "loading" | "success" | "error";

export const FileItem: React.FC<Props> = ({ file, index, onRemove, onUpload }) => {
  const isIFile = "key" in file;
  const fileName = isIFile ? file.title : file.name;

  const { t } = useTranslation();
  const { colors } = useTheme();
  const session = useContext(Session);
  const [loaded, setLoaded] = useState(isIFile);
  const [progress, setProgress] = useState(isIFile ? 100 : 1);
  const [status, setStatus] = useState<Status>(isIFile ? "success" : "loading");
  const [errorMessage, setErrorMessage] = useState("");

  const [createSecurePresignedUrl] = useCreateSecurePresignedUrlMutation({
    client: session.irisClient?.cortex,
  });

  useEffect(() => {
    const handle = async () => {
      setLoaded(true);
      if (isIFile) {
        return;
      }
      try {
        setProgress(3);
        if (file.size > maxFileSizeInBytes) {
          throw new Error(t("common.contentTooBig") ?? "");
        }
        const presignedUrl = (
          await createSecurePresignedUrl({
            variables: {
              input: {
                filename: file.name,
                contentType: file.type,
              },
            },
          })
        ).data?.createSecurePresignedUrl;
        if (presignedUrl == null) {
          throw new Error(t("common.uploadError") ?? "");
        }
        const blob = await file.arrayBuffer();
        const result = await axios.put(presignedUrl.securePresignedUrl, blob, {
          headers: {
            "Content-Type": file.type,
          },
          onUploadProgress: (e: ProgressEvent) => {
            const p = Math.ceil((e.loaded / e.total) * 100);
            setProgress(p);
          },
        });

        if (result.status === StatusCodes.OK) {
          // Pass result up.
          onUpload(index, {
            title: file.name,
            key: presignedUrl.key,
          });
          setStatus("success");
        } else {
          throw new Error(t("common.uploadError") ?? "");
        }
      } catch (e: unknown) {
        setErrorMessage(e instanceof Error ? e.message : t("common.unknownErrorMessage"));
        setProgress(100);
        setStatus("error");
      }
    };
    if (!loaded) {
      handle().catch((e) => {
        console.error(e);
      });
    }
  }, [createSecurePresignedUrl, file, onUpload, loaded, isIFile, index, t]);

  return (
    <>
      <div className="cont file-upload">
        <div className="item align-items-center">
          <div className="item-container">
            <Text variant="text">
              <RiFileLine size={20} /> {fileName}
            </Text>
          </div>

          <div className="actions d-flex">
            {status === "error" && (
              <Text variant="text" size="extra-small">
                {errorMessage}
              </Text>
            )}
            <Tooltip placement="left" title="Remove file">
              <Button size="sm" className="remove-file" shape="round" variant="ghost" onClick={() => onRemove(index)}>
                <RiCloseLine size={16} color={status === "error" ? colors.danger : colors.medium} />
              </Button>
            </Tooltip>
          </div>
        </div>
        <div className="progress"></div>
      </div>
      <style jsx>
        {`
          .cont {
            border: 1px solid #e6e6e6;
            border-radius: 4px;
            margin-bottom: 8px;
            margin-top: ${index === 0 ? 0 : "12px"};
          }
          .progress {
            border-bottom: 4px solid
              ${status === "loading" ? colors.primary : status === "error" ? colors.danger : colors.success};
            border-bottom-left-radius: 4px;
            border-bottom-right-radius: ${progress < 100 ? "0px" : "4px"};
            width: ${progress}%;
          }
          .item {
            display: flex;
            justify-content: space-between;
            padding: 16px;
          }
          .item-caption {
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
          }
          .list-container {
            display: flex;
          }
          .list {
            margin: 0;
            padding: 0;
            display: flex;
          }
          .list li {
            font-size: 14px;
            margin-right: 4px;
            padding-left: 4px;
            list-style: none;
            color: rgba(0, 0, 0, 0.6);
          }
          .list li::before {
            font-size: 10px;
            padding-right: 4px;
            content: "•";
            color: rgba(0, 0, 0, 0.6);
          }
          .delete-icon {
            margin-top: 1px;
          }
          .actions {
            gap: 10px;
            align-items: center;
            color: ${status === "error" ? colors.danger : "default"};
          }
        `}
      </style>
    </>
  );
};
