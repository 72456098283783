import { useTranslation } from "@equiem/localisation-eq1";
import { Form, useToast } from "@equiem/react-admin-ui";
import type { ChangeEvent } from "react";
import React, { useEffect, useState } from "react";
import {
  CompanyBookingSettingsDocument,
  useCompanyBookingSettingsQuery,
  useUpdateCompanyBookingSettingsMutation,
} from "../../../../generated/gateway-client";

export const InvoiceSettingsSummary: React.FC = () => {
  const { t } = useTranslation();
  const toast = useToast();
  const [localVal, setLocalValue] = useState(false);
  const { data, loading } = useCompanyBookingSettingsQuery({});
  const [mutation] = useUpdateCompanyBookingSettingsMutation();

  const company = data?.viewer.profile?.companyV2;

  useEffect(() => {
    setLocalValue(company?.bookingSettings?.requireBillingCustomerForInvoices ?? false);
  }, [company]);

  const handleToggle = async (e: ChangeEvent<HTMLInputElement>) => {
    const isNowChecked = e.target.checked;
    if (company == null) {
      return;
    }

    setLocalValue(isNowChecked);

    try {
      await mutation({
        variables: {
          input: {
            companyUuid: company.uuid,
            requireBillingCustomerForInvoices: isNowChecked,
          },
        },
        refetchQueries: [
          {
            query: CompanyBookingSettingsDocument,
          },
        ],
      });
      toast.positive(t("common.changesSaved"));
    } catch (err: unknown) {
      toast.negative(t("common.changesSaveFailed"));
    }
  };

  return (
    <Form.Group
      label={t("bookings.settings.billingDetails")}
      showTooltip
      tooltipText={t("bookings.settings.billingDetailsTooltip")}
    >
      <Form.Checkbox
        disabled={loading}
        label={t("bookings.settings.enableBillingDetails")}
        value={localVal}
        onChange={(e) => {
          void handleToggle(e);
        }}
      />
    </Form.Group>
  );
};
