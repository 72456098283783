import React, { useContext, useMemo } from "react";

import { useTranslation } from "@equiem/localisation-eq1";
import { FilterTab } from "@equiem/react-admin-ui";

import { useAllRequestsTotalCountQuery } from "../../../generated/requests-client";
import type { ActiveTab } from "../contexts/ActiveTab";
import { RequestsFilterContext } from "../contexts/RequestsFilterContext";

interface Tab {
  id: ActiveTab;
  name: string;
  count?: number;
  countLoading: boolean;
}

export const RequestQuickTab: React.FC = () => {
  const { t } = useTranslation();
  const {
    defaultCountFilters,
    translatedFilters: filter,
    activeTab,
    setActiveTab,
    tabs,
  } = useContext(RequestsFilterContext);

  const { data: allCount, loading: allCountLoading } = useAllRequestsTotalCountQuery({
    variables: activeTab === "all" ? { filter } : { filter: defaultCountFilters.all },
    fetchPolicy: "network-only",
    skip: !tabs.all,
  });
  const { data: openCount, loading: openCountLoading } = useAllRequestsTotalCountQuery({
    variables: activeTab === "open" ? { filter } : { filter: defaultCountFilters.open },
    fetchPolicy: "network-only",
    skip: !tabs.open,
  });
  const { data: completedCount, loading: completedCountLoading } = useAllRequestsTotalCountQuery({
    variables: activeTab === "completed" ? { filter } : { filter: defaultCountFilters.completed },
    fetchPolicy: "network-only",
    skip: !tabs.completed,
  });

  const alltabs = useMemo<Tab[]>(() => {
    const items: Tab[] = [];

    if (tabs.open) {
      items.push({
        id: "open",
        name: t("requests.open"),
        count: openCount?.reqMgt.requests.totalCount,
        countLoading: openCountLoading,
      });
    }
    if (tabs.completed) {
      items.push({
        id: "completed",
        name: t("requests.tab.completed"),
        count: completedCount?.reqMgt.requests.totalCount,
        countLoading: completedCountLoading,
      });
    }
    if (tabs.all) {
      items.push({
        id: "all",
        name: t("requests.tab.all"),
        count: allCount?.reqMgt.requests.totalCount,
        countLoading: completedCountLoading,
      });
    }

    return items;
  }, [
    allCount?.reqMgt.requests.totalCount,
    allCountLoading,
    openCount?.reqMgt.requests.totalCount,
    openCount,
    openCountLoading,
    completedCount?.reqMgt.requests.totalCount,
    completedCount,
    completedCountLoading,
  ]);

  return (
    <>
      {alltabs.map((tab) => (
        <FilterTab
          valueLoading={tab.countLoading}
          title={tab.name}
          value={tab.count}
          isActive={activeTab === tab.id}
          onClick={() => setActiveTab(tab.id)}
          key={tab.id}
        />
      ))}
    </>
  );
};
