import React, { useContext, useEffect, useImperativeHandle } from "react";

import { Button } from "../../Button/Button";
import type { ButtonProps } from "../../Button/Button";
import { DropdownContext } from "../Dropdown";
import { useHtmlId } from "../../../hooks/useHtmlId";

// eslint-disable-next-line @typescript-eslint/no-type-alias
export type DropdownButtonProps = ButtonProps;

export interface DropdownButtonRef {
  click: () => void;
  focus: () => void;
}

export const DropdownToggle = React.forwardRef<DropdownButtonRef, DropdownButtonProps>(
  ({ children, ...props }, ref) => {
    const { show, setShow, reference, refs, setToggleId } = useContext(DropdownContext);
    const id = useHtmlId(props.id);

    useEffect(() => {
      setToggleId(id);
    }, [id, setToggleId]);

    useImperativeHandle(ref, () => ({
      click: () => {
        if (refs.reference.current instanceof HTMLButtonElement) {
          refs.reference.current.click();
        }
      },
      focus: () => {
        if (refs.reference.current instanceof HTMLButtonElement) {
          refs.reference.current.focus();
        }
      },
    }));

    return (
      <>
        <Button
          {...props}
          ref={reference}
          onClick={() => {
            setShow(!show);
          }}
          id={id}
          dropdownToggle
          aria-expanded={show ? "true" : "false"}
        >
          {children}
        </Button>
      </>
    );
  },
);

DropdownToggle.displayName = "DropdownToggle";
