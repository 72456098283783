import type { ArticleDetailFragmentFragment } from "../../../generated/gateway-client";
import type { ArticleDetail } from "./Article";
import { mungeArticle } from "./mungeArticle";

export function mungeArticleDetail(
  article: ArticleDetailFragmentFragment,
): ArticleDetail & Required<Pick<ArticleDetail, "uuid" | "title">> {
  return {
    ...mungeArticle(article),
    author: {
      displayName: article.author?.displayName ?? null,
      firstName: article.author?.firstName ?? null,
      lastName: article.author?.lastName ?? null,
      avatarUrl: article.author?.avatar ?? null,
    },
    categories: [],
    images: article.images,
    body: article.body,
    relatedInfo: [],
    downloads: article.downloads,
  };
}
