import { Dropdown } from "@equiem/react-admin-ui";
import React, { useContext } from "react";
import { CurrentPortfolio } from "../../contexts/PortfolioContext";
import { useTranslation } from "@equiem/localisation-eq1";

export const PortfolioSelector = () => {
  const { currentPortfolio, myPortfolios, setCurrentPortfolio } = useContext(CurrentPortfolio);
  const { t } = useTranslation();

  return (
    <Dropdown.Button
      variant="ghost"
      title={currentPortfolio?.name ?? t("contentManagement.noPortfolio")}
      disabled={myPortfolios.length === 0}
    >
      {myPortfolios.map((p) => {
        return (
          <Dropdown.Item key={p.uuid} onClick={() => setCurrentPortfolio(p)}>
            {p.name}
          </Dropdown.Item>
        );
      })}
    </Dropdown.Button>
  );
};
