import { useCallback, useMemo, useState } from "react";

import { stringIsEmpty } from "@equiem/lib";

import {
  BarcodeType,
  useBarrierControlConfigCodeTypeLazyQuery,
  useCurrentDestinationBuildingsQuery,
} from "../generated/visitors-client";

/** drop-in replacement for useCurrentDestinationBuildingsQuery with fetchBuildingCodeType and codeType  */
export function useFetchBuildingCodeType() {
  const [codeType, setCodeType] = useState<BarcodeType | null>(null);
  const { data: buildingsData, loading: buildingsLoading } = useCurrentDestinationBuildingsQuery();
  const [fetchCodeType] = useBarrierControlConfigCodeTypeLazyQuery();

  /** uuids of buildings that has at least one AC1 integration configured */
  const ac1EnabledBuildingUuids = useMemo(
    () =>
      buildingsData?.viewer.currentDestination?.destination.buildings
        ?.filter((building) => building.ac1BuildingIntegrations?.some((integration) => integration.visitorsEnabled))
        .map((building) => building.uuid) ?? [],
    [buildingsData?.viewer.currentDestination?.destination.buildings],
  );

  const fetchBuildingCodeType = useCallback(
    async (buildingUuid: string): Promise<BarcodeType | null> => {
      if (stringIsEmpty(buildingUuid)) {
        setCodeType(null);
        return null;
      }

      if (ac1EnabledBuildingUuids.includes(buildingUuid)) {
        setCodeType(BarcodeType.Qr);
        return BarcodeType.Qr;
      }

      const result = await fetchCodeType({ variables: { buildingUuid } });
      const type = result.data?.barrierControlConfigCodeType.type ?? null;
      setCodeType(type);
      return type;
    },
    [ac1EnabledBuildingUuids, fetchCodeType],
  );

  return {
    /** codeType from Barrier Config, or always use QR code if using AC1 for this building */
    fetchBuildingCodeType,
    codeType,
    buildingsData,
    buildingsLoading,
  };
}
