import type { FC } from "react";
import React, { useContext, useState } from "react";
import {
  FlexTenantStatus,
  MyFlexTenantsDocument,
  useUpdateFlexTenantMutation,
  type FlexTenantFragmentFragment,
  type UpdateFlexTenantInput,
} from "../../generated/gateway-client";
import type { FormikHelpers } from "formik";
import { Field, Form, Formik } from "formik";
import {
  Button,
  EntitySearch,
  Form as EqForm,
  Modal,
  ProgressCircle,
  Text,
  remainingCharacters,
} from "@equiem/react-admin-ui";
import { useTranslation } from "@equiem/localisation-eq1";
import { SideModalContext } from "../../contexts/SideModalContext";
import { MembershipDateField } from "./MembershipDateField";
import * as yup from "yup";
import { detailsValidation } from "./CreateFlexTenantForm";

interface Props {
  flexTenant: FlexTenantFragmentFragment;
  readonly?: boolean;
}

export const EditMembershipForm: FC<Props> = ({ flexTenant, readonly = false }) => {
  const { t } = useTranslation();
  const { closeModal } = useContext(SideModalContext);
  const [mutation] = useUpdateFlexTenantMutation();
  const [unlimitedMembers, setUnlimitedMembers] = useState(flexTenant.memberLimit === null);

  const validationSchema = () => {
    return yup.object().shape({
      ...detailsValidation(t),
    });
  };

  type FormValue = UpdateFlexTenantInput & { unlimitedMembers: boolean };

  const handleSubmit = async (values: FormValue, { setSubmitting }: FormikHelpers<FormValue>): Promise<boolean> => {
    setSubmitting(true);
    const { unlimitedMembers: _, ...input } = values;
    await mutation({
      variables: {
        input,
      },
      refetchQueries: [
        {
          query: MyFlexTenantsDocument,
        },
      ],
    });
    setSubmitting(false);
    closeModal();
    return true;
  };

  const canEdit = flexTenant.status !== FlexTenantStatus.Expired && !readonly;
  const canCreate = flexTenant.status === FlexTenantStatus.PreMoveIn && !readonly;

  return (
    <Formik<FormValue>
      initialValues={{
        flexTenantUuid: flexTenant.uuid,
        name: flexTenant.name,
        memberLimit: unlimitedMembers ? null : flexTenant.memberLimit,
        monthlyCreditAllowance: flexTenant.monthlyCreditAllowance,
        moveInDate: flexTenant.moveInDate,
        expirationDate: flexTenant.expirationDate,
        unlimitedMembers,
      }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ values, isSubmitting, submitForm, isValid, dirty, errors, setValues }) => (
        <>
          <Modal.Body>
            <Form>
              <Text variant="heading" size="small" weight="bold" className="mb-2">
                {t("flex.members.form.generalInformation")}
              </Text>
              <EqForm.Group label={t("flex.members.form.company")}>
                <EntitySearch.EntityCard className="selected-member mt-2" label={flexTenant.company.name} />
              </EqForm.Group>
              <EqForm.Group
                error={errors.name}
                label={t("flex.members.form.membershipName")}
                hint={t("common.remainingCharacters", {
                  count: remainingCharacters(200, values.name.length),
                })}
                required={canEdit}
              >
                <Field
                  type="text"
                  name="name"
                  readOnly={!canEdit}
                  as={EqForm.Input}
                  maxLength={200}
                  autoComplete="off"
                />
              </EqForm.Group>
              <div className="d-flex">
                <EqForm.Group
                  error={errors.moveInDate}
                  className="mr-4"
                  label={t("flex.members.form.moveInDate")}
                  required={canEdit}
                >
                  <MembershipDateField readOnly={!canCreate} name="moveInDate" />
                </EqForm.Group>
                <EqForm.Group
                  error={errors.expirationDate}
                  label={t("flex.members.form.expirationDate")}
                  required={canEdit}
                >
                  <MembershipDateField readOnly={!canCreate} name="expirationDate" />
                </EqForm.Group>
              </div>
              <EqForm.Group label={t("flex.members.form.teamSize")} required={canEdit}>
                <div className="mb-2">
                  <input
                    type="radio"
                    id="team-size-all"
                    checked={unlimitedMembers}
                    onChange={() => {
                      setUnlimitedMembers(true);
                      void setValues({
                        ...values,
                        memberLimit: null,
                        unlimitedMembers: true,
                      });
                    }}
                    readOnly={!canEdit}
                    disabled={!canEdit}
                  />
                  <label htmlFor="team-size-all" className="mx-3 text-dark">
                    {t("flex.members.form.teamSizeAll")}
                  </label>
                </div>
                <div>
                  <input
                    type="radio"
                    id="team-size-limited"
                    checked={!unlimitedMembers}
                    onChange={() => {
                      setUnlimitedMembers(false);
                      void setValues({
                        ...values,
                        unlimitedMembers: false,
                        memberLimit: values.memberLimit ?? flexTenant.memberLimit ?? 10,
                      });
                    }}
                    readOnly={!canEdit}
                    disabled={!canEdit}
                  />
                  <label htmlFor="team-size-limited" className="mx-3 text-dark">
                    {t("flex.members.form.teamSizeLimited")}
                  </label>
                </div>
              </EqForm.Group>
              {!unlimitedMembers && (
                <EqForm.Group error={errors.memberLimit} label={t("flex.members.form.teamSize")} required={canEdit}>
                  <Field type="number" readOnly={!canEdit} name="memberLimit" as={EqForm.Input} />
                </EqForm.Group>
              )}
              <Text variant="heading" size="small" weight="bold" className="mb-2">
                {t("flex.members.form.creditAllowance")}
                <EqForm.Group
                  error={errors.monthlyCreditAllowance}
                  label={t("flex.members.form.creditAllowanceMonthly")}
                  required={canEdit}
                >
                  <Field type="number" readOnly={!canCreate} name="monthlyCreditAllowance" as={EqForm.Input} />
                </EqForm.Group>
              </Text>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={closeModal} variant="ghost">
              {t("common.cancel")}
            </Button>
            {canEdit && (
              <Button
                className="ml-2"
                disabled={isSubmitting || !isValid || !dirty}
                onClick={() => {
                  void submitForm();
                }}
              >
                {isSubmitting && <ProgressCircle size="xs" />} {t("common.saveChanges")}
              </Button>
            )}
          </Modal.Footer>
        </>
      )}
    </Formik>
  );
};
