import { useTranslation } from "@equiem/localisation-eq1";
import { useTheme } from "@equiem/react-admin-ui";
import type { FC } from "react";
import type { InviteUsersMutation } from "./generated/gateway-client";
import { stringNotEmpty } from "@equiem/lib";

interface Props {
  firstName?: string;
  result: InviteUsersMutation["inviteUsersV3"];
}

export const ResultScreen: FC<Props> = ({ firstName, result }) => {
  const { t } = useTranslation();
  const { colors, spacers, borderRadius } = useTheme(true);

  const hasErrors = stringNotEmpty(result.invalidEmails) || stringNotEmpty(result.existingEmails);
  const wasSuccessful = result.invitedCount > 0;

  return (
    <>
      {wasSuccessful ? (
        <>
          <h1>{t("home.widgets.thankYouUser", { firstName: firstName ?? "" })}</h1>
          <h3>
            {t("home.widgets.usersWereSuccessfullyInvited", {
              count: result.invitedCount,
            })}
          </h3>
          <div className="section">
            <h4>{t("home.widgets.invited")}</h4>
            <p>{result.invitedEmails}</p>
          </div>
        </>
      ) : (
        <h1>{t("common.somethingWrong")}</h1>
      )}
      {wasSuccessful && hasErrors && <div className="spacer" />}
      {hasErrors && (
        <>
          <h3>{t("home.widgets.issiesWithUsers")}</h3>
          <div className="section">
            {result.existingEmails != null && (
              <>
                <h4>{t("home.widgets.existingEmails")}</h4>
                <p>{result.existingEmails}</p>
              </>
            )}
            {result.existingEmails != null && result.invalidEmails != null && <div className="spacer" />}
            {result.invalidEmails != null && (
              <>
                <h4>{t("home.widgets.invalidEmails")}</h4>
                <p>{result.invalidEmails}</p>
              </>
            )}
          </div>
        </>
      )}
      <style jsx>{`
        h1 {
          font-weight: 700;
          margin-bottom: ${spacers.s5};
        }
        .section {
          border-radius: ${borderRadius};
          background: ${colors.adminBackground};
          padding: ${spacers.s5};
        }
        h3 {
          font-weight: 400;
          font-size: 18px;
          line-height: 28px;
        }
        h4 {
          font-weight: 500;
          font-size: 12px;
          line-height: 16px;
          color: ${colors.medium};
          text-transform: uppercase;
        }
        p {
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          margin: 0;
        }
        .spacer {
          margin: ${spacers.s5} 0;
          border-top: 1px solid #e6e6e6;
        }
      `}</style>
    </>
  );
};
