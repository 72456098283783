import { stringNotEmpty } from "@equiem/lib";
import type { BookableResourceAddOnFragmentFragment } from "../../../generated/gateway-client";
import { BookableResourceAddOnType } from "../../../generated/gateway-client";
import { convertInputNumberToNumber } from "../../../lib/convertNumberStringToNumber";
import type { AddOnCheckboxOrRadio, AddOnFreeText, BookingFormValue } from "../models/BookingFormValue";

export const convertInputAddOnsToSummaryView = (
  resourceAddOns: BookableResourceAddOnFragmentFragment[] | null,
  inputAddOns: BookingFormValue["addOns"] | null,
) => {
  if (resourceAddOns == null || resourceAddOns.length === 0 || inputAddOns == null) {
    return null;
  }

  return resourceAddOns.reduce<
    Array<{
      addOnUuid: string;
      addOnName: string;
      addOnType: BookableResourceAddOnType;
      optionUuid?: string;
      optionName?: string;
      value?: string;
      quantity?: number;
    }>
  >((prev, resourceAddOn, index) => {
    if (inputAddOns[index] == null) {
      return prev;
    }

    switch (resourceAddOn.type) {
      case BookableResourceAddOnType.FreeText: {
        const target = inputAddOns[index] as AddOnFreeText;
        return stringNotEmpty(target.value)
          ? [
              ...prev,
              {
                addOnUuid: resourceAddOn.uuid,
                addOnName: resourceAddOn.name ?? "",
                addOnType: resourceAddOn.type,
                value: target.value,
              },
            ]
          : prev;
      }

      case BookableResourceAddOnType.SingleChoice:
      case BookableResourceAddOnType.SingleOption:
      case BookableResourceAddOnType.MultiOption: {
        const target = inputAddOns[index] as AddOnCheckboxOrRadio;

        if (resourceAddOn.options == null) {
          return prev;
        }

        const results = resourceAddOn.options.flatMap((option) => {
          const inputTarget = target[option.uuid];
          if (inputTarget == null) {
            return [];
          }

          return [
            {
              addOnUuid: resourceAddOn.uuid,
              addOnName: resourceAddOn.name ?? "",
              addOnType: resourceAddOn.type,
              optionUuid: option.uuid,
              optionName: option.name,
              quantity: convertInputNumberToNumber(inputTarget.quantity, true) ?? undefined,
            },
          ];
        });

        return [...prev, ...results];
      }

      default:
        return prev;
    }
  }, []);
};
