import type { ReactNode } from "react";
import React from "react";
import { MainContainer, usePageGradient, notNullOrUndefined } from "@equiem/lib";
import { useTheme } from "@equiem/react-admin-ui";
import { RiNewspaperLine } from "@equiem/react-admin-ui/icons";
import { useTranslation, useApolloErrorTranslation } from "@equiem/localisation-eq1";

import { useGetLatestArticlesQuery } from "../../generated/gateway-client";
import { SiteBanner } from "./SiteBanner";
import { ArticleCard } from "./ArticleCard";
import { ArticleCardLoading } from "./ArticleCardLoading";

const numPosts = 20;

const Loading: React.FC = () => (
  <>
    {Array(numPosts)
      .fill(null)
      .map((_, i) => (
        <ArticleCardLoading key={i} />
      ))}
  </>
);

const Message: React.FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <>
      <div className="message w-100 p-7">
        <div className="mb-6">{children}</div>
        <RiNewspaperLine size={64} />
      </div>
      <style jsx>{`
        .message {
          max-width: 600px;
          margin: 0 auto;
          font-size: 24px;
          text-align: center;
        }
      `}</style>
    </>
  );
};

export const Homepage: React.FC = () => {
  const { colors, spacers } = useTheme();
  const { t } = useTranslation();
  const { tError } = useApolloErrorTranslation();

  const { data, error, loading } = useGetLatestArticlesQuery({
    variables: { numPosts },
    fetchPolicy: "cache-and-network",
  });
  const articles =
    data?.contentFeed.edges.map((e) => (e.__typename === "ArticleEdge" ? e.node : null)).filter(notNullOrUndefined) ??
    [];

  usePageGradient();

  return (
    <>
      <MainContainer padding={false}>
        <div className="mx-7">
          <SiteBanner />
        </div>
        <h1 className="mt-5 mx-7">{t("homepage.newsAndUpdates")}</h1>
        <hr className="my-5" />
        <div className="news-feed mx-7 mb-7">
          {loading ? (
            <Loading />
          ) : articles.length > 0 ? (
            articles.map((article) => <ArticleCard key={article.uuid} {...article} />)
          ) : (
            <Message>{error != null ? tError(error) : t("homepage.noArticlesFound")}</Message>
          )}
        </div>
      </MainContainer>
      <style jsx>{`
        h1 {
          line-height: 32px;
          font-size: 24px;
          font-weight: 600;
        }
        hr {
          border: none;
          border-top: 1px solid ${colors.border};
        }
        .news-feed {
          display: grid;
          grid-template-columns: repeat(auto-fit, minmax(272px, 1fr));
          gap: ${spacers.s3};
        }
      `}</style>
    </>
  );
};
