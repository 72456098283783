import React, { useContext, useEffect, useMemo } from "react";
import { useRouter } from "next/router";

import { stringIsEmpty, useSaferFormikContext } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import { ProgressCircle, useTheme } from "@equiem/react-admin-ui";

import { Modal } from "../../contexts/ModalContext";
import { withContexts } from "../../contexts/withContexts";
import { useVisitorAppointmentQuery, VisitorRole } from "../../generated/visitors-client";
import { Appointment } from "../appointment/Appointment";
import { EditAppointmentProvider } from "../appointment/context/EditAppointmentContext";
import { initialValues } from "../appointment/initialValues";
import { mapAppointmentToForm } from "../appointment/mappings";
import type { FormValues } from "../appointment/types";
import { AppointmentDeleteWidget } from "../appointment/widgets/AppointmentDeleteWidget";

import { DeskMenu } from "./components/DeskMenu";
import { useNewAppointmentCancelConfirmer } from "./hooks/useNewAppointmentCancelConfirmer";
import { useReceptionDate } from "./hooks/useReceptionDate";

const AppointmentPageBase = ({
  receptionUuid,
  appointmentUuid,
  isAllBuildingsReceptions,
  isReceptionView,
}: {
  receptionUuid?: string;
  appointmentUuid?: string;
  isReceptionView?: boolean;
  isAllBuildingsReceptions?: boolean;
}) => {
  const { i18n } = useTranslation();
  const modal = useContext(Modal);
  const theme = useTheme(true);
  const router = useRouter();
  const { date } = useReceptionDate();
  const { values, isSubmitting, submitForm, resetForm, touched, setFieldValue } = useSaferFormikContext<FormValues>();
  const { loading, data, error } = useVisitorAppointmentQuery({
    variables: { uuid: appointmentUuid ?? "" },
    fetchPolicy: "network-only",
    skip: stringIsEmpty(appointmentUuid),
  });

  const showRecurringOptions = useMemo(() => {
    const appointments = { ...data }.visitorAppointment?.recurringInfo?.appointments ?? [];
    const sortedAppointments = [...appointments].sort((a, b) => a.startDate - b.startDate);
    return (
      sortedAppointments != null &&
      sortedAppointments.length !== 0 &&
      sortedAppointments.findIndex((x) => data?.visitorAppointment.uuid === x.uuid) !== sortedAppointments.length - 1
    );
  }, [data?.visitorAppointment.recurringInfo?.appointments]);

  const currentReceptionUuid = receptionUuid ?? data?.visitorAppointment.reception?.uuid;

  const handleCancel = useNewAppointmentCancelConfirmer(() => {
    router.back();
    setTimeout(() => {
      /**
       * Needs to happen after the back nav has finished, otherwise
       * it can trigger side effects that make the form ditry again,
       * which will prevent the new appointment tab from being removed.
       */
      resetForm({ values: initialValues });
    }, 500);
  });

  const handleSubmit = async (): Promise<void> => {
    if (appointmentUuid != null && values.recurringInfo != null) {
      return new Promise((_) => modal.open("AppointmentRecurringUpdate"));
    } else {
      return submitForm();
    }
  };

  useEffect(() => {
    return () => {
      resetForm({ values: initialValues });
    };
  }, [appointmentUuid]);

  useEffect(() => {
    if (touched.date !== true && appointmentUuid == null) {
      if (date.diffNow("day").days >= 0) {
        setFieldValue("date", date.startOf("day").toFormat("yyyy-MM-dd")).catch(console.error);
      }
    }
  }, [date, touched, setFieldValue, appointmentUuid]);

  useEffect(() => {
    if (!loading && data != null) {
      resetForm({ values: mapAppointmentToForm(data.visitorAppointment, i18n.language) });
    }
  }, [resetForm, loading, data, i18n.language]);

  return (
    <EditAppointmentProvider>
      <div>
        <DeskMenu isAllBuildingsReceptions={isAllBuildingsReceptions} uuid={currentReceptionUuid as string} search="" />
        {loading ? (
          <div style={{ minHeight: "90vh" }} className="p-8 d-flex justify-content-center align-items-center">
            <ProgressCircle size="md" />
          </div>
        ) : error != null ? (
          <p className="px-7">{error.message}</p>
        ) : (
          <>
            <Appointment
              isReceptionView={isReceptionView === true}
              isAllBuildingsReceptionsView={isAllBuildingsReceptions === true}
              uuid={appointmentUuid}
              isSubmitting={isSubmitting}
              submitForm={handleSubmit}
              handleCancel={handleCancel}
              receptionUuid={currentReceptionUuid}
              as={VisitorRole.Receptionist}
            />
          </>
        )}
        <AppointmentDeleteWidget
          showRecurringOptions={showRecurringOptions}
          onDelete={() => {
            const url =
              isAllBuildingsReceptions === true
                ? "/visitor-management/building-receptions/pre_booked"
                : `/visitor-management/receptions/${currentReceptionUuid}/pre_booked`;
            void router.push(url);
          }}
        />
        <style jsx>{`
          div {
            display: flex;
            flex-direction: column;
            height: 100%;
            background: ${theme.colors.white};
          }
        `}</style>
      </div>
    </EditAppointmentProvider>
  );
};

export const AppointmentPage = withContexts(AppointmentPageBase);
