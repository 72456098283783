import { useTranslation } from "@equiem/localisation-eq1";
import { Button, Dropdown, Modal } from "@equiem/react-admin-ui";
import { RiDraftLine, RiUploadLine } from "react-icons/ri";
import type { EquiemOnePostAudience } from "./generated/iris-client";

interface Props {
  close: () => void;
  disabled: boolean;
  saveDraft: () => void;
  savePublished: () => void;
  postAudience: EquiemOnePostAudience;
  isPublished: boolean;
  saving: boolean;
}

export const FormFooter: React.FC<Props> = ({
  close,
  disabled,
  saveDraft,
  savePublished,
  postAudience,
  isPublished,
  saving,
}) => {
  const { t } = useTranslation();

  return (
    <Modal.Footer>
      <Button className="mr-2" size="md" variant="ghost" onClick={close}>
        {t("common.cancel")}
      </Button>
      {isPublished ? (
        <Button onClick={savePublished} size="md" disabled={disabled || saving}>
          {saving ? t("common.saving") : t("home.widgets.saveAndPublish")}
        </Button>
      ) : (
        <Dropdown.Button
          title={(saving ? t("common.saving") : t("common.save")) ?? ""}
          size="md"
          disabled={disabled || saving}
        >
          <Dropdown.Item onClick={savePublished} icon={RiUploadLine}>
            {postAudience === "BUILDING" ? t("home.widgets.saveAndSubmit") : t("home.widgets.saveAndPublish")}
          </Dropdown.Item>
          <Dropdown.Item onClick={saveDraft} icon={RiDraftLine}>
            {t("home.widgets.saveDraft")}
          </Dropdown.Item>
        </Dropdown.Button>
      )}
    </Modal.Footer>
  );
};
