/**
 * Convert string to number, mainly for input type number.
 */
export const convertInputNumberToNumber = (target?: string | number | null, convertZeroToNull = false) => {
  const convert = target == null || target === "" ? null : Number(target);

  if (convert != null && Number.isNaN(convert)) {
    return null;
  }

  if (convertZeroToNull && convert === 0) {
    return null;
  }

  return convert;
};
