import type { PropsWithChildren } from "react";
import React, { createContext, useCallback, useState } from "react";
import { Modal, ProgressCircle, useTheme } from "@equiem/react-admin-ui";
import { useTranslation } from "@equiem/localisation-eq1";

interface Input {
  numberOfBookings: number;
}

export interface RecurringBookingCreationLoaderModalContext {
  show: (i: Input) => void;
  hide: () => void;
}

export const RecurringBookingCreationLoaderModal = createContext<RecurringBookingCreationLoaderModalContext>({
  show: (_i: Input) => undefined,
  hide: () => undefined,
});

export const RecurringBookingCreationLoaderModalProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { t } = useTranslation();
  const { colors } = useTheme();
  const [numberOfBookings, setNumberOfBookings] = useState(0);
  const [showModal, setShowModal] = useState(false);

  const show = useCallback((input: Input) => {
    setShowModal(true);
    setNumberOfBookings(input.numberOfBookings);
  }, []);

  const hide = useCallback(() => {
    setShowModal(false);
  }, []);

  return (
    <RecurringBookingCreationLoaderModal.Provider
      value={{
        show,
        hide,
      }}
    >
      {children}
      <Modal.Dialog
        title={t("common.repeat")}
        size="md"
        hideOnEsc={false}
        hideOnClick={false}
        show={showModal}
        centered
      >
        <Modal.Header intro={t("bookings.operations.createRecurringBooking")} closeButton={false} noBorder={true} />
        <Modal.Body>
          <div className="container d-flex align-items-center justify-content-center h-100 flex-column" tabIndex={0}>
            <ProgressCircle className="mb-6" size="md" />
            <h4>{t("bookings.resources.creatingXBookingsForYou", { occurrences: numberOfBookings })}</h4>
            <div className="sub mt-4"> {t("bookings.resources.thisCouldTakeAMoment")}</div>
          </div>
        </Modal.Body>
      </Modal.Dialog>
      <style jsx>{`
        .container {
          height: 250px;
        }
        h4 {
          color: ${colors.grayscale[60]};
          font-weight: 600 !important;
        }
        .sub {
          color: ${colors.grayscale[60]};
        }
      `}</style>
    </RecurringBookingCreationLoaderModal.Provider>
  );
};
