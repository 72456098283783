import React from "react";

export interface SelectedContext {
  selected: string;
  setSelected: (eventKey: string) => void;
}

export const SelectedContext = React.createContext<SelectedContext>({
  selected: "",
  setSelected: () => undefined,
});
