import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { useSiteContext } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import { Alert, Form, useTheme } from "@equiem/react-admin-ui";
import { RiErrorWarningLine } from "@equiem/react-admin-ui/icons";

import { useWidgetContext } from "../../../contexts/WidgetContext";
import {
  type CompanyDetailsQuery,
  FlexTenantStatus,
  useUpdateFlexTenantSettingsMutation,
} from "../../../generated/settings-client";

interface P {
  company: NonNullable<CompanyDetailsQuery["companyV2"]>;
  registerSaveHandler: (handler: () => Promise<boolean>) => void;
}
export const CreditPaymentsPermission: React.FC<P> = ({ company, registerSaveHandler }) => {
  const { t } = useTranslation();
  const { colors } = useTheme();
  const { setActionState } = useWidgetContext();
  const [mutation, { loading }] = useUpdateFlexTenantSettingsMutation();
  const isBookingsEnabled = useSiteContext().featureModules.bookings.enabled;

  const allFlexTenants = company.flexTenants;
  const activeFlexTenants = allFlexTenants.filter(
    (ft) => ft.status === FlexTenantStatus.Active || ft.status === FlexTenantStatus.NearingExpiry,
  );
  const flexTenantUuidsInitial = activeFlexTenants
    .filter((ft) => ft.spendingCreditsRequiresApproval)
    .map((ft) => ft.flexTenantUuid);

  const [approvePaymentsByCredits, setApprovePaymentsByCredits] = useState(flexTenantUuidsInitial.length > 0);
  const [flexTenantUuids, setFlexTenantUuids] = useState(flexTenantUuidsInitial);

  const isValid = useMemo(
    () => !approvePaymentsByCredits || flexTenantUuids.length > 0,
    [approvePaymentsByCredits, flexTenantUuids.length],
  );

  const multiSelectRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (approvePaymentsByCredits && multiSelectRef.current != null) {
      multiSelectRef.current.scrollIntoView({ behavior: "smooth", block: "end" });
    }
  }, [approvePaymentsByCredits]);

  useEffect(() => {
    setActionState("onSave", isValid ? "normal" : "disabled");
  }, [isValid, setActionState]);

  const saveChanges = useCallback(async () => {
    if (loading) {
      return false;
    }

    const shouldApproveSpending = (ftUuid: string) => approvePaymentsByCredits && flexTenantUuids.includes(ftUuid);

    const mutationPromises = allFlexTenants
      .filter((ft) => ft.spendingCreditsRequiresApproval !== shouldApproveSpending(ft.flexTenantUuid))
      .map(async (ft) =>
        mutation({
          variables: {
            input: {
              flexTenantUuid: ft.flexTenantUuid,
              spendingCreditsRequiresApproval: shouldApproveSpending(ft.flexTenantUuid),
            },
          },
        }),
      );
    if (mutationPromises.length === 0) {
      return false;
    }

    await Promise.all(mutationPromises);
    return true;
  }, [allFlexTenants, approvePaymentsByCredits, flexTenantUuids, loading, mutation]);

  useEffect(() => {
    registerSaveHandler(saveChanges);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveChanges]);

  return (
    <div className="permission-form">
      <h4>{t("settings.editCompany.creditPayments")}</h4>
      {!isBookingsEnabled ? (
        <Alert
          size="large"
          variant="gray"
          icon={<RiErrorWarningLine size={18} color={colors.grayscale[50]} />}
          message={t("settings.moduleNotEnabled")}
          className="my-3 w-100"
        />
      ) : (
        <>
          {activeFlexTenants.length === 0 && (
            <Alert
              size="large"
              variant="gray"
              icon={<RiErrorWarningLine size={18} color={colors.grayscale[50]} />}
              message={t("settings.editCompany.creditPaymentsNoActiveMemberships")}
              className="w-100"
            />
          )}
          <div>
            <Form.Group
              label={t("settings.editCompany.creditPaymentsApprovalsTitle")}
              showTooltip={true}
              tooltipText={t("settings.editCompany.creditPaymentsApprovalsTooltip")}
            >
              <Form.Checkbox
                name="creditPaymentsApproval"
                label={t("settings.editCompany.creditPaymentsApprovalsLabel")}
                disabled={loading || activeFlexTenants.length === 0}
                value={approvePaymentsByCredits}
                onChange={(e) => {
                  setApprovePaymentsByCredits(e.target.checked);
                  if (e.target.checked && activeFlexTenants.length === 1) {
                    setFlexTenantUuids([activeFlexTenants[0].flexTenantUuid]);
                  }
                }}
              />
            </Form.Group>
          </div>
          {approvePaymentsByCredits && activeFlexTenants.length > 0 ? (
            <div className="w-100" ref={multiSelectRef}>
              <Form.Group
                label={t("settings.editCompany.creditPaymentsAssignMembershipsTitle")}
                required
                ref={multiSelectRef}
              >
                <Form.MultiSelect
                  options={activeFlexTenants
                    .map((ft) => ({
                      value: ft.flexTenantUuid,
                      label: ft.name,
                    }))
                    .sort((a, b) => a.label.localeCompare(b.label))}
                  value={flexTenantUuids}
                  onChange={(value) => setFlexTenantUuids(value.target.value)}
                  size="small"
                  placeholder={t("settings.editCompany.creditPaymentsAssignMembershipsTooltip")}
                  variant="wrap"
                />
              </Form.Group>
            </div>
          ) : (
            <div></div>
          )}
        </>
      )}
    </div>
  );
};
