import { ThemeContext } from "@equiem/react-admin-ui";
import type { RefObject } from "react";
import React, { useContext } from "react";
import ReactDOM from "react-dom";

export const ButtonTrayContext = React.createContext<{ containerRef: RefObject<HTMLDivElement> }>({
  containerRef: { current: null },
});

export const ButtonTray: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const { containerRef } = useContext(ButtonTrayContext);
  const container = containerRef.current ?? document.body;

  return ReactDOM.createPortal(
    <ThemeContext.Consumer>
      {({ colors }) => (
        <div className="footer d-flex justify-content-end px-7 py-5">
          {children}
          <style jsx>{`
            .footer {
              position: sticky;
              bottom: 0;
              width: 100%;
              background-color: ${colors.white};
              border-top: 1px solid #e6e6e6;
              z-index: 999;
            }
          `}</style>
        </div>
      )}
    </ThemeContext.Consumer>,
    container,
  );
};
