import { useTranslation } from "@equiem/localisation-eq1";
import { ActivityFeed, EmptyState, Skeleton } from "@equiem/react-admin-ui";
import type { ReceiverOption } from "@equiem/react-ui/admin";
import type { FC } from "react";
import React from "react";

import type { CustomMapping, FieldNameMapping } from "../../hooks/useMappings";
import { ConfiguredActivity } from "./ConfiguredActivity";
import { useActivitiesQuery } from "../../generated/gateway-client";

const LoadingState = () => (
  <div>
    <div className="mb-2">
      <Skeleton.Line width="100px" height="15px" />
    </div>
    <Skeleton.Line width="250px" height="20px" />
  </div>
);

interface Props {
  entityUuid: string;
  showReversedOrder?: boolean;
  messageOptions?: {
    receivers: ReceiverOption[];
    onSubmit: (message: string, receiverId?: string) => void;
  };
  customMappings?: CustomMapping[];
  fieldNameMappings?: FieldNameMapping;
}

export const ConfiguredActivityFeed: FC<Props> = ({
  entityUuid,
  showReversedOrder = false,
  messageOptions,
  customMappings,
  fieldNameMappings,
}) => {
  const { t } = useTranslation();
  const { data, loading, fetchMore, called } = useActivitiesQuery({
    variables: {
      entityUuid,
      first: 25,
      sort: {
        asc: false,
        field: "TIMESTAMP",
      },
    },
    fetchPolicy: "network-only",
  });

  const fetchMoreAsync = async () => {
    await fetchMore({
      variables: {
        after: data?.activities.pageInfo.endCursor,
      },
      updateQuery(prev, { fetchMoreResult }) {
        return {
          ...fetchMoreResult,
          activities: {
            ...fetchMoreResult.activities,
            edges: [...prev.activities.edges, ...fetchMoreResult.activities.edges],
          },
        };
      },
    });
  };

  return (
    <>
      {called && !loading && data?.activities.edges.length === 0 && (
        <div className="h-100 d-flex align-items-center justify-content-center">
          <EmptyState message={t("bookings.discounts.activity.noActivity")} />
        </div>
      )}
      <ActivityFeed
        dataLength={data?.activities.totalCount ?? 0}
        hasMore={data?.activities.pageInfo.hasNextPage ?? false}
        showReversedOrder={showReversedOrder}
        next={fetchMoreAsync}
        messageOptions={messageOptions}
      >
        {!loading ? (
          data?.activities.edges.map(({ node }) =>
            node != null ? (
              <ConfiguredActivity
                key={node.uuid}
                activity={node}
                customMappings={customMappings}
                fieldNameMappings={fieldNameMappings}
              />
            ) : null,
          )
        ) : (
          <LoadingState />
        )}
      </ActivityFeed>
    </>
  );
};
