import type { Static } from "runtypes";
import { Number, Partial, Record, String } from "runtypes";

const Jwt = Record({ exp: Number });

interface Token {
  accessToken: string;
  idToken: string;
  appId?: string;
  expiresAt: number;
}

const Message = Record({
  access_token: String,
  id_token: String,
}).And(
  Partial({
    app_id: String,
    app_version: String,
  }),
);

type Message = Static<typeof Message>;

let authTokenCache: Token | undefined;
let getAccessTokenPromise: Promise<Token> | undefined;

const formatToken = (message: Message) => {
  const jwt = Jwt.check(JSON.parse(Buffer.from(message.access_token.split(".")[1], "base64").toString("utf-8")));

  return {
    accessToken: message.access_token,
    idToken: message.id_token,
    appId: message.app_id,
    expiresAt: jwt.exp * 1000,
  };
};

export const getTokensFromPostMessage = async () => {
  if (authTokenCache != null && authTokenCache.expiresAt > Date.now() + 60_000) {
    return authTokenCache;
  }

  if (getAccessTokenPromise != null) {
    return getAccessTokenPromise;
  }

  let onMessage: ((event: MessageEvent) => void) | undefined;

  getAccessTokenPromise = new Promise<Token>((resolve, reject) => {
    const timeout = setTimeout(
      () => {
        reject(new Error("Request for access token timed out after 10s"));
        console.log("Request for access token timed out after 10s");
      },
      process.env.authTokenPostMessageTimeout != null ? parseInt(process.env.authTokenPostMessageTimeout, 10) : 10000,
    );
    const int = setInterval(() => {
      window.postMessage("request_token", "*");
      window.postMessage({ method: "request_token" }, "*");
      if (window.top !== window) {
        window.top?.postMessage("request_token", "*");
        window.top?.postMessage({ method: "request_token" }, "*");
      }
    }, 500);
    onMessage = (event: MessageEvent) => {
      console.log("event from getAccessTokenPromise", event);
      if (Message.guard(event.data)) {
        try {
          const token = formatToken(event.data);
          authTokenCache = token;
          resolve(token);
          clearTimeout(timeout);
          clearInterval(int);
        } catch (e: unknown) {
          clearInterval(int);
          reject(new Error(e instanceof Error ? e.message : "Unknown error."));
        }
      }
    };
    window.addEventListener("message", onMessage, false);
    window.postMessage("request_token", "*");
    window.postMessage({ method: "request_token" }, "*");
    if (window.top !== window) {
      window.top?.postMessage("request_token", "*");
      window.top?.postMessage({ method: "request_token" }, "*");
    }
  }).finally(() => {
    getAccessTokenPromise = undefined;
    if (onMessage != null) {
      window.removeEventListener("message", onMessage);
    }
  });

  return getAccessTokenPromise;
};
