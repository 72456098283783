import { useMemo } from "react";

import { useTranslation } from "@equiem/localisation-eq1";
import type { FilterItem } from "@equiem/react-admin-ui";
import { FilterOptionsModifier } from "@equiem/react-admin-ui";
import { RiListCheck } from "@equiem/react-admin-ui/icons";
import type { CreditTransactionType } from "@equiem/lib/generated/gateway-client";
import { useCreditsTypes } from "./useCreditsTypes";

export function useCreditsFilterType(types?: CreditTransactionType[]) {
  const { t } = useTranslation();
  const creditTypes = useCreditsTypes();

  const options = useMemo(
    () =>
      (types ?? [])
        .map((type) => ({
          label: creditTypes[type],
          value: type,
        }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    [types, creditTypes],
  );

  const filter: FilterItem = {
    title: t("credits.type"),
    type: "options",
    options,
    icon: RiListCheck,
    modifiers: [FilterOptionsModifier.includes],
  };

  return {
    filter,
  };
}
