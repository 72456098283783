import type { HttpLink } from "@apollo/client";
import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client";
import { AdminTheme, ConfirmerProvider } from "@equiem/react-admin-ui";
import React from "react";
import { ForcedLocaleProvider } from "@equiem/localisation";
import { AudienceProvider } from "../contexts/AudienceProvider";
import type { NotificationMethod } from "../state/NotificationMethod";
import type { NotificationType } from "../state/NotificationType";
import type { AudienceInnerWidgetProps } from "./AudienceInnerWidget";
import { AudienceInnerWidget } from "./AudienceInnerWidget";

export interface AudienceProps extends AudienceInnerWidgetProps {
  site: string;
  segmentIds?: string[];
  notificationType?: NotificationType;
  notificationMethod?: NotificationMethod;
  connection: {
    link?: HttpLink;
    client?: ApolloClient<object>;
    gatewayEndpoint?: string;
  };
  /**
   * NOTE: Only for use in non-react front-ends, e.g. `vms-ui`, where there is
   * no existing `I18nextProvider` React context.
   */
  locale?: string | null;
}

const createClient = (link?: HttpLink) =>
  new ApolloClient({
    name: "equiem-one-ui",
    link,
    cache: new InMemoryCache(),
  });

export const AudienceWidget: React.FC<AudienceProps> = (props) => {
  const { client, link } = props.connection;

  if (client == null && link == null) {
    return null;
  }

  return (
    <ForcedLocaleProvider locale={props.locale}>
      <AdminTheme
        zIndexes={{
          modal: 10001,
          dropdown: 10002,
          tooltips: 10003,
        }}
        useUtilityClasses={true}
      >
        <ApolloProvider client={client != null ? client : createClient(link)}>
          <AudienceProvider
            notificationMethod={props.notificationMethod}
            notificationType={props.notificationType}
            initialSegmentIds={props.segmentIds}
            siteId={props.site}
            gatewayEndpoint={props.connection.gatewayEndpoint}
          >
            <ConfirmerProvider>
              <AudienceInnerWidget {...props} />
            </ConfirmerProvider>
          </AudienceProvider>
        </ApolloProvider>
      </AdminTheme>
    </ForcedLocaleProvider>
  );
};
