import { useEffect, useState } from "react";
import { useTheme } from "../contexts/Theme";
import { useWindowDimensions } from "./useWindowDimensions";

export const useIsMobileWidth = (breakpoint: "md" | "lg" | "sm" = "lg") => {
  const { breakpoints } = useTheme();
  const { width } = useWindowDimensions();
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    setIsMobile(width < breakpoints[breakpoint]);
  }, [width, breakpoint, breakpoints]);

  return isMobile;
};
