import React, { useContext, useEffect, useState } from "react";

import type { BorderCssProps } from "../useInputBorderCss";
import type { ComponentProps, PropsWithChildren } from "react";
import { FormGroupContext } from "../../../contexts/FormGroupContext";
import { FormInputGroupContext } from "../FormInputGroup/FormInputGroupContext";
import { useDropdownChevronBackground } from "../../../hooks/useDropdownChevronBackground";
import { useInputBorderCss } from "../useInputBorderCss";
import { useInputPadding } from "../useInputPadding";
import { useTheme } from "../../../contexts/Theme";

// eslint-disable-next-line @typescript-eslint/no-type-alias
type Props = ComponentProps<"select"> & {
  rounded?: BorderCssProps["rounded"];
  dropdownIcon?: ({ color }: { color: string }) => string;
  placeholder?: string;
};

export const FormSelect: React.FC<PropsWithChildren<Props>> = ({ children, dropdownIcon, ...props }) => {
  const [value, setValue] = useState<string>(typeof props.value === "string" ? props.value : "");
  const { id, hintId } = useContext(FormGroupContext);
  const { colors } = useTheme();
  const fontColor = value === "" ? colors.muted1 : "inherit";
  const defaultBg = useDropdownChevronBackground({
    color: value === "" ? colors.muted1 : "#333",
  });
  const dropdownBg =
    dropdownIcon != null
      ? dropdownIcon({
          color: value === "" ? colors.muted1 : "#333",
        })
      : defaultBg;

  const borderCss = useInputBorderCss({ rounded: props.rounded });
  const padding = useInputPadding({ right: "32px" });
  const { hasSuffix } = useContext(FormInputGroupContext);

  useEffect(() => {
    setValue(typeof props.value === "string" ? props.value : "");
  }, [props.value]);

  return (
    <>
      <select
        {...props}
        id={props.id ?? id}
        aria-describedby={hintId}
        className={`${props.className ?? ""} ${borderCss.className}`}
        onChange={(e) => {
          setValue(e.target.value);
          if (props.onChange != null) {
            props.onChange(e);
          }
        }}
      >
        {children}
      </select>
      <style jsx>{`
        select {
          line-height: 22px;
          padding: ${padding.shorthand};
          appearance: none;
          display: inline-block;
          cursor: pointer;
          float: none;
          outline: none;
          width: 100%;
          font-size: 14px;
          color: ${fontColor};
          background: ${dropdownBg} right ${hasSuffix ? "0" : "11px"} center/ 16px 16px no-repeat;
          text-overflow: ellipsis;
        }
        select:disabled {
          cursor: unset;
          background-color: ${colors.grayscale[3]};
        }
        select :global(option) {
          color: #333;
        }
        select:first-child {
          padding-top: ${padding.top};
        }
        select:last-child {
          padding-bottom: ${padding.bottom};
        }
      `}</style>
      {borderCss.styles}
    </>
  );
};
