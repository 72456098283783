import type { ApolloError } from "@apollo/client";
import { useTranslation, useApolloErrorTranslation } from "@equiem/localisation-eq1";
import { useToast } from "@equiem/react-admin-ui";
import { useCallback } from "react";
import type { Static } from "runtypes";
import { Array, Number, Optional, Partial, Record, String, Null, Union } from "runtypes";

const MutationError = Record({
  message: String,
  extensions: Optional(Record({ code: String })),
});

const MutationErrors = Partial({
  message: String,
  graphQLErrors: Union(Array(MutationError), Null),
  networkError: Union(
    Partial({
      message: String,
      statusCode: Number,
      result: Partial({
        errors: Array(MutationError),
      }),
    }),
    Null,
  ),
});

type MutationErrors = Static<typeof MutationErrors>;

const getFriendlyErrors = (errors: MutationErrors) => {
  const allErrors =
    errors.graphQLErrors != null && errors.graphQLErrors.length > 0
      ? errors.graphQLErrors
      : errors.networkError?.result?.errors ?? [];

  const friendlyErrors = allErrors
    .filter((e) => ["BAD_USER_INPUT", "FORBIDDEN", "UNAUTHENTICATED"].includes(e.extensions?.code ?? ""))
    .map((e) => e.message);

  if (friendlyErrors.length === 0) {
    const fallback = (errors.message ?? errors.networkError?.message ?? "").trim();
    if (fallback !== "") {
      friendlyErrors.push(fallback);
    }
  }

  return friendlyErrors;
};

export const useShowError = () => {
  const toast = useToast();
  const { tError } = useApolloErrorTranslation();
  const { t } = useTranslation();

  return useCallback(
    (e: unknown) => {
      console.error(e);
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if ((e as ApolloError)?.graphQLErrors?.some((gqlError) => gqlError?.extensions?.localisableErrorParams != null)) {
        toast.negative(tError(e as ApolloError));
        return;
      }

      if (MutationErrors.guard(e)) {
        const friendlyErrors = getFriendlyErrors(e);
        if (friendlyErrors.length > 0) {
          toast.negative(friendlyErrors[0]);
          return;
        }
      }

      toast.negative(t("common.somethingWrong"));
    },
    [toast, tError, t],
  );
};
