import type { ReactNode } from "react";
import React, { createContext, useContext } from "react";

import { CurrentProfile, Site } from "@equiem/lib";

import { useViewerRelationsQuery } from "../../../generated/settings-client";

export interface CurrentUserRoleContext {
  canManageCurrentSite: boolean;
  canManageBuildingCompanyBookings: boolean;
  canManageCurrentSiteCompany: boolean;
  canObserveBuildingCompanyBookings: boolean;
}

export const CurrentUserRoleContext = createContext<CurrentUserRoleContext>({
  canManageCurrentSiteCompany: false,
  canObserveBuildingCompanyBookings: false,
  canManageCurrentSite: false,
  canManageBuildingCompanyBookings: false,
});

export const CurrentUserRoleProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const site = useContext(Site);
  const featureEnabled = site.featureModules.bookings.enabled;
  const { canManageCurrentSite, canManageCurrentSiteCompany } = useContext(CurrentProfile);
  const { data } = useViewerRelationsQuery({ skip: !featureEnabled });
  const relations = data?.viewer.currentDestination?.destination.viewerRelations;

  return (
    <CurrentUserRoleContext.Provider
      value={{
        canManageCurrentSite,
        canManageCurrentSiteCompany,
        canObserveBuildingCompanyBookings: relations?.canObserveBuildingCompanyBookings ?? false,
        canManageBuildingCompanyBookings: relations?.canManageBuildingCompanyBookings ?? false,
      }}
    >
      {children}
    </CurrentUserRoleContext.Provider>
  );
};
