import { useTranslation } from "@equiem/localisation-eq1";
import { Button, Modal, ProgressCircle, useTheme } from "@equiem/react-admin-ui";
import type { Ref } from "react";
import React from "react";
import { Cropper as ImageCropper } from "react-cropper";

interface Props {
  showUploadModal: boolean;
  localAttachedImage: { file: File; img: string };
  onCrop: () => void;
  cropperRef: Ref<HTMLImageElement>;
  onSaveCropped: () => Promise<void>;
  clearCropper: () => void;
  progress: number;
  uploading: boolean;
}

export const ResourceCreateAndEditFormGalleryUploaderModal: React.FC<Props> = ({
  showUploadModal,
  localAttachedImage,
  onCrop,
  cropperRef,
  onSaveCropped,
  clearCropper,
  progress,
  uploading,
}) => {
  const { t } = useTranslation();
  const { breakpoints, colors, spacers } = useTheme(true);

  const inProgress = progress !== 0 || uploading;

  return (
    <Modal.Dialog
      title={t("bookings.resources.cropAndUploadImage")}
      show={showUploadModal}
      size="xl"
      onHide={() => {
        clearCropper();
      }}
      hideOnClick={false}
      hideOnEsc={false}
      supportsMobile={true}
      focusTrapOptions={{ initialFocus: false }}
      centered
    >
      <div className="gallery-uploader-modal">
        <Modal.Header supportsMobile={true} closeButton={!inProgress} noBorder={false} />
        <Modal.Body>
          <ImageCropper
            src={localAttachedImage.img}
            style={{ height: 400, width: "100%" }}
            // Cropper.js options
            aspectRatio={16 / 9}
            guides={false}
            crop={onCrop}
            ref={cropperRef}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="ghost"
            type="button"
            disabled={inProgress}
            onClick={() => {
              clearCropper();
            }}
          >
            {t("common.cancel")}
          </Button>
          <Button
            className="upload-button"
            type="button"
            variant="primary"
            disabled={inProgress}
            onClick={() => {
              void onSaveCropped();
            }}
          >
            {inProgress ? (
              <>
                <ProgressCircle size="xs" /> {progress !== 0 ? t("uploader.uploading") : t("uploader.processing")}
              </>
            ) : (
              <>{t("common.upload")}</>
            )}
          </Button>
        </Modal.Footer>
      </div>
      <style jsx>
        {`
          .gallery-uploader-modal :global(.header) {
            padding: ${spacers.s6} !important;
          }
          .gallery-uploader-modal :global(.header .title-row) {
            margin: ${spacers.s0} !important;
          }
          .gallery-uploader-modal :global(.header .title) {
            color: ${colors.dark};
            font-size: 24px;
            font-weight: 700;
            line-height: 28px;
            text-transform: none;
            text-align: left;
            z-index: 1;
          }
          .gallery-uploader-modal :global(.modal-body) {
            display: flex;
            flex-direction: column;
            padding: ${spacers.s7} ${spacers.s6};
            margin: ${spacers.s0};
            gap: ${spacers.s7};
            overflow-y: auto;
            max-height: 400px;
          }
          .gallery-uploader-modal :global(.footer) {
            padding: ${spacers.s5} ${spacers.s7} !important;
            gap: ${spacers.s3};
            border-top: 1px solid ${colors.grayscale[10]};
          }
          .gallery-uploader-modal :global(.footer .upload-button) {
            width: 200px;
          }
          @media screen and (max-width: ${breakpoints.md}px) {
            .gallery-uploader-modal :global(.header .title) {
              font-size: 20px;
            }
            .gallery-uploader-modal :global(.header button) {
              z-index: 2;
            }
            .gallery-uploader-modal :global(.modal-body) {
              padding: ${spacers.s5};
            }
            .gallery-uploader-modal :global(.footer button) {
              flex: 1;
            }
          }
        `}
      </style>
    </Modal.Dialog>
  );
};
