import { useCallback, useContext, useMemo, useState } from "react";

import { CurrentRole, notNullOrUndefined, Role } from "@equiem/lib";

import {
  ReqMgtSortField,
  useAllRequestsQuery,
  useAllRequestsWorkplaceManagerQuery,
} from "../../../generated/requests-client";
import { RequestsFilterContext } from "../contexts/RequestsFilterContext";

const DEFAULT_PAGE_SIZE = 30;

export function usePagedRequests(currentScope = "all", first = DEFAULT_PAGE_SIZE) {
  const [loadingMore, setLoadingMore] = useState(false);
  const { currentRole } = useContext(CurrentRole);
  const { translatedFilters: filter } = useContext(RequestsFilterContext);
  const isAllRequestFlow = currentRole !== Role.WorkplaceManager || ["my", "requestManager"].includes(currentScope);
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("desc");

  const sort = useCallback(
    (direction: "asc" | "desc") => ({
      field: ReqMgtSortField.Reference,
      asc: direction === "asc",
    }),
    [],
  );

  const request = useMemo(
    () => ({
      variables: {
        page: { first },
        filter,
        sort: sort(sortDirection),
      },
    }),
    [sortDirection, filter, first, sort],
  );
  const workplaceManagerRequests = useAllRequestsWorkplaceManagerQuery({
    ...request,
    fetchPolicy: "network-only",
    skip: isAllRequestFlow,
  });
  const allRequests = useAllRequestsQuery({
    ...request,
    skip: !isAllRequestFlow,
    fetchPolicy: "network-only",
  });

  const result = isAllRequestFlow ? allRequests : workplaceManagerRequests;
  const fetchMoreAsync = async (latestData = result.data) =>
    result.fetchMore({
      variables: {
        page: {
          first,
          after: latestData?.reqMgt.requests.pageInfo.endCursor,
        },
        sort: sort(sortDirection),
      },
      updateQuery(prev, { fetchMoreResult }) {
        return {
          ...fetchMoreResult,
          reqMgt: {
            requests: {
              ...fetchMoreResult.reqMgt.requests,
              edges: [...prev.reqMgt.requests.edges, ...fetchMoreResult.reqMgt.requests.edges],
            },
          },
        };
      },
    });

  const handleSort = useCallback(
    (index: number) => {
      if (index !== 0) {
        return;
      }

      const toggleDirection = sortDirection === "asc" ? "desc" : "asc";
      setSortDirection(toggleDirection);
      void result.refetch({ sort: sort(toggleDirection) }).catch((e) => console.error(e));
    },
    [sortDirection, result, sort],
  );

  const fetchMore = () => {
    if (result.loading || loadingMore) {
      return;
    }

    setLoadingMore(true);
    fetchMoreAsync()
      .catch((e) => console.error(e))
      .finally(() => setLoadingMore(false));
  };

  return {
    requests: result.data?.reqMgt.requests.edges.map((edge) => edge.node).filter(notNullOrUndefined) ?? [],
    hasMoreData: result.data?.reqMgt.requests.pageInfo.hasNextPage ?? false,
    error: result.error,
    loading: result.loading || loadingMore,
    fetchMore,
    refetch: async () => result.refetch({ sort: sort(sortDirection) }),
    sortDirection,
    handleSort,
  };
}
