import { stringNotEmpty } from "@equiem/lib";
import { parse, serialize } from "cookie";
export const lastSiteCookieName = "auth:last_site";

export const setLastSiteCookie = (uuid?: string | null) => {
  if (stringNotEmpty(uuid)) {
    document.cookie = serialize(lastSiteCookieName, uuid, {
      domain: process.env.defaultEquiemOneHost,
      path: "/",
      maxAge: 30 * 24 * 60 * 60,
    });
  }
};

export const getLastSiteUuid = (cookies: string | Partial<Record<string, string>>) => {
  return typeof cookies === "string" ? parse(document.cookie)[lastSiteCookieName] : cookies[lastSiteCookieName];
};
