import React, { useContext, useMemo, useState } from "react";
import { Dropdown } from "../Dropdown";
import { DropdownMenu } from "../Dropdown/DropdownMenu/DropdownMenu";
import { FilterChip } from "./FilterChip";
import { ComplexFilterContext } from "./ComplexFilterContext";
import { Text } from "../Text/Text";
import * as Form from "../Form";
import { useTheme } from "../../contexts/Theme";
import type { FilterItemNumber, FilterItemProps } from "./ComplexFilter.types";
import { FilterNumberModifier, FilterType } from "./ComplexFilter.types";
import { useTranslation } from "@equiem/localisation-eq1";
import { ModifierSelect } from "./ModifierSelect";

export const defaultModifier = FilterNumberModifier.is;

export const FilterNumber: React.FC<FilterItemProps<FilterItemNumber>> = ({ title, name, modifiers, icon }) => {
  const { t } = useTranslation();
  const { colors } = useTheme();
  const { values, setValue, removeValue } = useContext(ComplexFilterContext);
  const [selectedModifier, setSelectedModifier] = useState<FilterNumberModifier>(
    (values[name]?.modifier ?? modifiers?.[0] ?? defaultModifier) as FilterNumberModifier,
  );
  const [prevSelectedModifier, setPrevSelectedModifier] = useState(selectedModifier);
  const isRange = useMemo(() => selectedModifier === FilterNumberModifier.between, [selectedModifier]);

  if (selectedModifier !== prevSelectedModifier) {
    setPrevSelectedModifier(selectedModifier);
    setValue(name, {
      type: FilterType.number,
      modifier: selectedModifier,
      value: undefined,
    });
  }

  const handleStartChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (selectedModifier === FilterNumberModifier.between) {
      let secondValue = Number(values[name]?.value?.[1] ?? e.target.value);

      if (secondValue < Number(e.target.value)) {
        secondValue = Number(e.target.value);
      }

      setValue(name, {
        type: FilterType.number,
        modifier: FilterNumberModifier.between,
        value: [e.target.value, secondValue.toString()],
      });
    } else {
      setValue(name, {
        type: FilterType.number,
        modifier: selectedModifier,
        value: e.target.value,
      });
    }
  };

  const handleEndChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (isRange) {
      let firstValue = Number(values[name]?.value?.[0] ?? e.target.value);

      if (firstValue > Number(e.target.value)) {
        firstValue = Number(e.target.value);
      }

      setValue(name, {
        type: FilterType.number,
        modifier: FilterNumberModifier.between,
        value: [firstValue.toString(), e.target.value],
      });
    }
  };

  const labelValue = useMemo(() => {
    const value = values[name];

    if (value?.value == null) {
      return undefined;
    }

    if (value.modifier === FilterNumberModifier.between) {
      return `${value.value[0]} - ${value.value[1]}`;
    }

    if (value.modifier === FilterNumberModifier.is) {
      return value.value;
    }

    if (value.modifier === FilterNumberModifier.greater) {
      return `> ${value.value}`;
    }

    if (value.modifier === FilterNumberModifier.less) {
      return `< ${value.value}`;
    }

    return undefined;
  }, [values, name]);

  const getLocalizedTitle = (modifier: FilterNumberModifier) => {
    switch (modifier) {
      case FilterNumberModifier.is:
        return t("common.is");
      case FilterNumberModifier.between:
        return t("common.between");
      case FilterNumberModifier.greater:
        return t("common.greaterThan");
      case FilterNumberModifier.less:
        return t("common.lessThan");
      default:
        return "";
    }
  };

  const value = values[name]?.value as string | string[] | undefined;

  return (
    <>
      <Dropdown placement="bottom-start" flip>
        <FilterChip
          title={title}
          value={labelValue}
          name={name}
          type={FilterType.number}
          onClose={() => removeValue(name)}
          icon={icon}
        />
        <DropdownMenu width={252} mobileView="regular">
          {modifiers != null && (
            <div className="numbers-top">
              <Text variant="label" className="m-0">
                {title}
              </Text>
              <ModifierSelect
                options={modifiers.map((m) => ({
                  value: m,
                  label: getLocalizedTitle(m),
                }))}
                selected={selectedModifier}
                onChange={(v) => setSelectedModifier(v as FilterNumberModifier)}
              />
            </div>
          )}
          {!isRange ? (
            <div className="number-input">
              <Form.Input type="number" value={value ?? ""} onChange={handleStartChange} />
            </div>
          ) : (
            <div className="range-input">
              <Form.Input type="number" value={value?.[0] ?? ""} onChange={handleStartChange} />
              <Form.Input type="number" value={value?.[1]} onChange={handleEndChange} min={value?.[0]} />
            </div>
          )}
        </DropdownMenu>
      </Dropdown>
      <style jsx>{`
        .options-list {
          display: flex;
          flex-direction: column;
          gap: 1rem;
          padding: 1rem;
        }

        .numbers-top {
          padding: 0 1rem 0.25rem;
          display: flex;
          flex-direction: row;
          align-items: center;
          color: ${colors.grayscale[100]};
        }

        .number-input {
          display: flex;
          padding: 0 0.5rem;
        }

        .range-input {
          padding: 0 0.5rem;
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-gap: 0.5rem;
        }
      `}</style>
    </>
  );
};
