import { stringNotEmpty } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import { ProgressCircle } from "@equiem/react-admin-ui";
import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";
import { useCompleteStripeConnect } from "../hooks/useCompleteStripeConnect";

export const CompleteStripeConnect: React.FC = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const { processIt } = useCompleteStripeConnect();
  const [info, setInfo] = useState({ loading: false, message: "" });

  useEffect(() => {
    const process = async () => {
      try {
        setInfo((x) => ({ ...x, loading: true }));
        await processIt(router.query);
        await router.push("/bookings/settings");
      } catch (e: unknown) {
        setInfo((x) => ({ ...x, message: e instanceof Error ? e.message : "Unknown error" }));
      } finally {
        setInfo((x) => ({ ...x, loading: false }));
      }
    };

    process().catch(console.log);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.query]);

  return (
    <div>
      {info.loading && (
        <>
          <ProgressCircle size="md" /> {t("bookings.settings.completingConnectingYourAccount")}
        </>
      )}
      {stringNotEmpty(info.message) && (
        <>
          <p>
            <strong>{t("bookings.settings.failedToConnect")}</strong>
          </p>
          <p>{t("bookings.settings.stringContactSupport", { message: info.message })}</p>
        </>
      )}
    </div>
  );
};
