import React from "react";

import { getReceptionName } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import { Button, Tag, useTheme } from "@equiem/react-admin-ui";

import { useReceptionDate } from "../../reception/hooks/useReceptionDate";

interface Props {
  uuid: string;
  buildingName?: string;
  companyName?: string;
  buildingLevelName?: string;
  suffix?: string;
  visitorStats?: number[];
  type: "building" | "tenant";
}

export const ReceptionItem: React.FC<Props> = ({
  uuid,
  buildingName,
  companyName,
  buildingLevelName,
  suffix,
  type,
  visitorStats,
}) => {
  const { t } = useTranslation();
  const { spacers } = useTheme();
  const { pushUrl } = useReceptionDate();

  const typeMap: Record<Props["type"], string> = {
    building: t("visitors.common.building"),
    tenant: t("visitors.common.tenant"),
  };

  const handleAccess = () => {
    void pushUrl(`/visitor-management/receptions/${uuid}`);
  };

  return (
    <div className="item justify-content-between align-items-center reception-item">
      <div className="item-content">
        <span className="item-caption">
          {getReceptionName(
            {
              company: { name: companyName },
              building: { name: buildingName },
              buildingLevel: { name: buildingLevelName },
              suffix,
            },
            t,
          )}
        </span>
        <div className="d-flex mt-2 ">
          <Tag className="mr-2">{typeMap[type]}</Tag>
          {visitorStats != null && visitorStats.length > 0 ? (
            <ul className="d-flex list m-0 p-0">
              <li>{t("visitors.receptions.preBookedVisitorsToday", { count: visitorStats[0] })}</li>
              <li>{t("visitors.receptions.preBookedVisitorsTomorrow", { count: visitorStats[1] })}</li>
            </ul>
          ) : (
            <ul className="d-flex list m-0 p-0">
              {buildingName != null && <li>{buildingName}</li>}
              {buildingLevelName != null && <li>{buildingLevelName}</li>}
              {companyName != null && <li>{companyName}</li>}
              {suffix != null && <li>{suffix}</li>}
            </ul>
          )}
        </div>
      </div>
      <Button onClick={handleAccess} variant="secondary" size="md" className="action-button">
        {t("visitors.receptions.accessReception")}
      </Button>
      <style jsx>
        {`
          .item {
            border: 1px solid #e6e6e6;
            border-radius: 4px;
            padding: 16px;
            display: grid;
            grid-template-columns: auto;
            gap: 16px;
          }
          .item :global(.action-button) {
            display: none;
          }

          .item:hover {
            grid-template-columns: auto 80px;
          }

          .item:hover :global(.action-button) {
            display: block;
          }
          .item-content {
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .item-caption {
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            white-space: nowrap;
          }
          .list li {
            font-size: 14px;
            margin-right: ${spacers.s2};
            padding-left: ${spacers.s2};
            list-style: none;
            color: rgba(0, 0, 0, 0.6);
            white-space: nowrap;
          }
          .list li::before {
            font-size: 10px;
            padding-right: ${spacers.s2};
            content: "•";
            color: rgba(0, 0, 0, 0.6);
          }
        `}
      </style>
    </div>
  );
};
