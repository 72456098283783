/* eslint-disable @typescript-eslint/no-type-alias */
import React, { useRef } from "react";
import { useTheme } from "../../contexts/Theme";
import type { DropdownButtonRef } from "../Dropdown/DropdownButton/AdminDropdownButton";
import { AdminDropdownButton as DropdownButton } from "../Dropdown/DropdownButton/AdminDropdownButton";
import { DropdownItem } from "../Dropdown/DropdownItem/DropdownItem";

type Option = {
  value: string;
  label: string;
};

interface Props {
  options: Option[];
  onChange: (value: string) => void;
  selected: string;
}

export const ModifierSelect: React.FC<Props> = ({ options, selected, onChange }) => {
  const { colors, breakpoints } = useTheme();
  const modifierButtonRef = useRef<DropdownButtonRef>(null);

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    onChange(e.target.value);
  };

  const handleDropdownItemClick = (value: string) => () => {
    onChange(value);
    setTimeout(() => modifierButtonRef.current?.click(), 0);
  };

  return (
    <>
      <DropdownButton
        title={options.find((o) => o.value === selected)?.label}
        variant="ghost"
        ref={modifierButtonRef}
        className="filter-modifier-select--desktop"
      >
        {options.map((option) => (
          <DropdownItem key={option.value} onClick={handleDropdownItemClick(option.value)} autoClose={false}>
            {option.label}
          </DropdownItem>
        ))}
      </DropdownButton>
      <select value={selected} onChange={handleChange}>
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      <style jsx>
        {`
          @media (min-width: ${breakpoints.md}px) {
            select {
              display: none;
            }
          }

          @media (max-width: ${breakpoints.md}px) {
            :global(.filter-modifier-select--desktop) {
              display: none !important;
            }
          }

          select {
            border: 0;
            outline: none;
            background: transparent;
            color: ${colors.grayscale[60]};
            height: 2rem;
            font-weight: 700;
            font-size: 0.75rem;
            line-height: 1rem;
            letter-spacing: 0.02em;
            text-transform: uppercase;
            padding: 0.5rem 1rem 0.5rem 0.75rem;
            appearance: none;
            background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0nMTYnIGhlaWdodD0nMTYnIHZpZXdCb3g9JzAgMCAxNiAxNicgZmlsbD0nbm9uZScgeG1sbnM9J2h0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnJz48cGF0aCBkPSdNNy45OTk4NyA4Ljc4MTQ1TDExLjI5OTkgNS40ODE0NUwxMi4yNDI1IDYuNDI0MTFMNy45OTk4NyAxMC42NjY4TDMuNzU3MiA2LjQyNDExTDQuNjk5ODcgNS40ODE0NUw3Ljk5OTg3IDguNzgxNDVaJyBmaWxsPScjNjY2NjY2Jy8+PC9zdmc+");
            background-repeat: no-repeat;
            background-position: 105% center;
          }

          option {
            text-transform: capitalize;
          }
        `}
      </style>
    </>
  );
};
