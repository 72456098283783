import { useCallback, useState } from "react";

import type { VisitorAppointmentsSortInput } from "../../../generated/visitors-client";
import { VisitorAppointmentsSortField } from "../../../generated/visitors-client";

export const useSorting = () => {
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("asc");

  const sort = useCallback(
    (direction: "asc" | "desc") =>
      [
        {
          field: VisitorAppointmentsSortField.Date,
          asc: direction === "asc",
        },
      ] as VisitorAppointmentsSortInput[],
    [],
  );

  const handleSort = useCallback(
    (index: number) => {
      if (index !== 0) {
        return;
      }

      const toggleDirection = sortDirection === "asc" ? "desc" : "asc";
      setSortDirection(toggleDirection);
    },
    [sortDirection],
  );

  return { handleSort, sortDirection, sort };
};
