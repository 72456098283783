import type { SyntheticEvent } from "react";
import React, { useCallback, useContext } from "react";
import { useRouter } from "next/router";

import { AppointmentsMenuContext } from "@equiem/lib";
import { formatters, useTranslation } from "@equiem/localisation-eq1";
import { BulkTable, Text, Tooltip, useStickyElement, useTheme } from "@equiem/react-admin-ui";

import { Modal } from "../../../contexts/ModalContext";
import type { VisitorAppointment, VisitorManagementSiteSettingsQuery } from "../../../generated/visitors-client";
import { CancelFormWidget } from "../../appointment/widgets/CancelFormWidget";

import { DropdownRecurringInfo } from "./AppointmentRecurringInfoView";
import { DropdownGuest } from "./DropdownGuest";

interface Props {
  appointments: VisitorAppointment[];
  sortDirection: "asc" | "desc";
  handleSort: (index: number) => void;
  vmSiteSettings?: VisitorManagementSiteSettingsQuery;
}

export const AppointmentsTable: React.FC<Props> = ({ vmSiteSettings, appointments, sortDirection, handleSort }) => {
  const { i18n, t } = useTranslation();
  const router = useRouter();
  const { spacers, breakpoints } = useTheme();
  const { top, ref } = useStickyElement<HTMLTableCellElement>();
  const modal = useContext(Modal);
  const { isEditAppointment, isNewAppointment, selectedAppointmentUuid, isPreview, clearState } =
    useContext(AppointmentsMenuContext);

  const isVisitorTypeFeatureEnabled = vmSiteSettings?.visitorManagementSiteSettings.isVisitorTypesEnabled === true;

  const onEdit = useCallback(
    (e: SyntheticEvent, uuid: string): void => {
      const td = (e.target as HTMLElement).closest("td");
      const tr = td?.parentNode;

      if (tr != null && td != null) {
        const clickedColumnIndex = Array.from(tr.children).indexOf(td);
        const actionsColumn = td.classList.contains("actions");

        if (clickedColumnIndex === 0 || actionsColumn) {
          return;
        }
      }

      if (isNewAppointment || (isEditAppointment && !isPreview && selectedAppointmentUuid !== uuid)) {
        e.preventDefault();
        e.stopPropagation();

        modal.open("CancelForm", uuid);
      } else {
        clearState();
        router
          .push({
            pathname: `/visitor-management/edit-appointment/${uuid}`,
            query: router.query,
          })
          .catch(console.log);
      }
    },
    [isNewAppointment, isEditAppointment, selectedAppointmentUuid],
  );

  return (
    <div className="appointment-table">
      <BulkTable
        sticky={{ top, isStuck: false }}
        responsive
        ref={ref}
        sort={{
          columnIndex: 0,
          direction: sortDirection,
          handleSort,
        }}
        className="w-100 mt-5"
        onRowClick={(id: string, e) => {
          onEdit(e, id);
        }}
        selectionEnabled={false}
        bulkOperations={null}
        headers={
          [
            { label: t("common.date") },
            { label: t("common.time") },
            { label: t("visitors.reception.meetingTitle") },
            { label: t("visitors.common.location") },
            { label: t("visitors.common.visitors") },
            isVisitorTypeFeatureEnabled && { label: t("visitors.common.visitorsType") },
          ].filter(Boolean) as Array<{ label: string }>
        }
        bodyRows={appointments.map((appointment) => {
          return {
            className: "row appointment",
            id: appointment.uuid,

            cols: [
              {
                content: (
                  <div className="d-flex flew-row justify-content-between">
                    <div className="align-self-center" style={{ width: "140px" }}>
                      <span className="">{formatters.dateshort(appointment.startTime, i18n.language)}</span>
                    </div>
                    <div className="align-self-center recurr-icon">
                      {appointment.recurringUuid != null && (
                        <div className="recurr-info">
                          <DropdownRecurringInfo appointmendUuid={appointment.uuid} />
                        </div>
                      )}
                    </div>
                  </div>
                ),
              },
              {
                content: `${formatters.timeshort(appointment.startTime, i18n.language)} - ${formatters.timeshort(
                  appointment.endTime,
                  i18n.language,
                )}`,
              },
              {
                content: (
                  <Tooltip title={appointment.title as string} placement="bottom">
                    <Text size="small" className="appointment-title" variant="text">
                      {appointment.title}
                    </Text>
                  </Tooltip>
                ),
              },
              {
                content: appointment.building?.name,
              },
              {
                className: "actions",
                content: <DropdownGuest appointment={appointment} />,
              },
              isVisitorTypeFeatureEnabled && {
                content: appointment.visitorType?.name,
              },
            ].filter(Boolean) as Array<{ content: React.ReactNode; className?: string }>,
          };
        })}
      />
      <CancelFormWidget
        bodyText={t("visitors.appointmentForm.cancelBodyCloseTab")}
        // istanbul ignore next
        onCancel={() => router.push(`/visitor-management/edit-appointment/${modal.id}`) as unknown as void}
      />
      <style jsx>
        {`
          :global(.appointment-title) {
            text-overflow: ellipsis;
            overflow: hidden;
            max-height: 2rem;
            display: -webkit-inline-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }
          .table-head {
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
            text-transform: uppercase;
            color: rgba(0, 0, 0, 0.6);
          }
          :global(.table) {
            border-style: hidden;
            box-shadow: 0 0 0 1px #e6e6e6;
            border-radius: 8px;
            border-collapse: collapse;
            width: 100%;
            padding-bottom: ${spacers.s10};
          }
          .table-body {
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
          }
          .table-body tr {
            border-bottom: 1px solid #e6e6e6;
          }
          .table-body :global(.appointment) {
            cursor: pointer;
          }
          :global(.table) th,
          :global(.table) td {
            padding: ${spacers.s3} ${spacers.s5};
          }
          :global(.table) .row:hover {
            background: #f2f2f2;
          }
          .large-column {
            width: 20%;
          }
          .company {
            font-size: 12px;
            line-height: 16px;
            color: #666666;
          }
          :global(.notes-button) {
            overflow: unset !important;
          }
          @media screen and (min-width: ${breakpoints.md}px) {
            :global(.appointment-table > div) {
              overflow-x: visible;
            }
          }
          .recurr-icon {
            margin-right: auto;
          }
        `}
      </style>
    </div>
  );
};
