import { useTheme } from "@equiem/react-admin-ui";
import type { ComponentProps, FC } from "react";

interface Props extends ComponentProps<"span"> {
  count: number;
}

export const BadgeIcon: FC<Props> = ({ count, ...props }) => {
  const { colors } = useTheme();

  if (count === 0) {
    return <></>;
  }

  return (
    <span {...props}>
      {count}
      <style jsx>{`
        span {
          cursor: default;
          border-radius: 7px;
          color: ${colors.white};
          padding: 4px;
          height: 14px;
          min-width: 14px;
          font-size: 10px;
          font-weight: 700;
          line-height: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: ${colors.transparent.black[80]};
        }
      `}</style>
    </span>
  );
};
