import React, { useContext, useCallback } from "react";
import { Modal } from "@equiem/react-admin-ui";
import { BookingModal } from "../../operations/contexts/BookingModalContext";
import { BookingDiscountForm } from "./BookingDiscountForm";
import { BookingDiscountAddEditContext, ViewMode } from "./BookingDiscountAddEditProvider";
import { BookingDiscountDisplay } from "./BookingDiscountDisplay";
import { BookingDiscountActivityHistory } from "./BookingDiscountActivityHistory";

export const BookingDiscountFormModal: React.FC = () => {
  const modal = useContext(BookingModal);
  const { viewMode } = useContext(BookingDiscountAddEditContext);

  const getDisplay = useCallback(() => {
    switch (viewMode) {
      case ViewMode.View:
        return <BookingDiscountDisplay />;
      case ViewMode.Edit:
      case ViewMode.New:
        return <BookingDiscountForm />;
      case ViewMode.History:
        return <BookingDiscountActivityHistory />;

      default:
        return <BookingDiscountForm />;
    }
  }, [viewMode]);

  return modal.id != null ? (
    <Modal.Dialog
      show
      focusTrapOptions={{ initialFocus: false }}
      sideModal
      key={modal.id}
      title={modal.title}
      onHide={() => modal.close()}
      hideOnEsc={true}
      hideOnClick={true}
      containerClassName="booking-discount-modal"
      size="md"
    >
      <Modal.Header closeButton noBorder={true} onClose={() => modal.close()} />
      {getDisplay()}
    </Modal.Dialog>
  ) : null;
};
