import { useContext, useEffect, useLayoutEffect, useMemo } from "react";

import { IframeResizerContext } from "../contexts/IframePageInfoContext";
import { VisibleAreaContext } from "../contexts/VisibleAreaContext";
import { offset, shift, useFloating } from "@floating-ui/react-dom";

/* eslint-disable @typescript-eslint/no-magic-numbers */

/**
 * Provides positioning information to help position floating elements
 * within the visible area, when running inside an iframe.
 */
export const useFloatingInIframe = ({
  placement,
  visible = true,
  fixedSize = false,
  pinToInitialPosition = false,
  offset: offsetInput = 0,
}: {
  placement: "top" | "center" | "bottom";
  offset?: number;
  visible?: boolean;
  fixedSize?: boolean;
  pinToInitialPosition?: boolean;
}) => {
  const iframe = useContext(IframeResizerContext);
  const visibleAreaContext = useContext(VisibleAreaContext);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const initVisibleArea = useMemo(() => visibleAreaContext.visibleArea, []);
  const visibleArea = useMemo(
    () => (pinToInitialPosition ? initVisibleArea : visibleAreaContext.visibleArea),
    [pinToInitialPosition, initVisibleArea, visibleAreaContext.visibleArea],
  );

  useEffect(() => {
    if (fixedSize) {
      return () => undefined;
    }

    const origMethod = iframe.heightCalculationMethod;

    if (visible) {
      iframe.setHeightCalculationMethod("lowestElement");
    }

    return () => {
      iframe.setHeightCalculationMethod(origMethod);
    };
  }, [fixedSize, iframe, visible]);

  const floating = useFloating({
    placement: placement === "bottom" ? "top" : "bottom",
    middleware: [
      offset(({ rects }) => (placement === "center" ? 0 - rects.floating.height / 2 : offsetInput)),
      shift(),
    ],
  });

  useLayoutEffect(() => {
    if (visibleArea != null) {
      const x = visibleArea.left + (visibleArea.right - visibleArea.left) / 2;
      const y =
        placement === "center"
          ? visibleArea.top + (visibleArea.bottom - visibleArea.top) / 2
          : placement === "top"
          ? visibleArea.top
          : visibleArea.bottom;
      const rect = {
        top: y,
        bottom: y,
        y,
        left: x,
        right: x,
        x,
        width: 0,
        height: 0,
      };

      floating.reference({
        getBoundingClientRect: () => rect,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visibleArea, placement]);

  const positioning = useMemo(
    () =>
      visibleArea == null
        ? undefined
        : {
            position: "absolute",
            top: `${floating.y ?? 0}px`,
            left: `${floating.x ?? 0}px`,
          },
    [visibleArea, floating.x, floating.y],
  );

  return { ...positioning, floating };
};
