import { Role, useShortcuts } from "@equiem/lib";
import {
  RiCalendarEventLine,
  RiFileAddLine,
  RiMailSendLine,
  RiEditBoxLine,
  RiFileList2Line,
} from "@equiem/react-admin-ui/icons";
import { useTranslation } from "@equiem/localisation-eq1";
import type { ShortcutEvents } from "./lib/ShortcutEvents";

export const Shortcuts = (currentRole: Role) => {
  const { t } = useTranslation();
  const { dispatch } = useShortcuts<ShortcutEvents>();

  if (currentRole === Role.WorkplaceManager) {
    return [
      {
        icon: RiFileAddLine,
        text: t("home.shortcuts.createNewsPost"),
        onClick: () => dispatch("createNewsPost"),
      },
      {
        icon: RiCalendarEventLine,
        text: t("home.shortcuts.createEventPost"),
        onClick: () => dispatch("createEventPost"),
      },
    ];
  }

  if (currentRole === Role.PropertyManager) {
    return [
      {
        icon: RiFileAddLine,
        text: t("home.shortcuts.createNewsPost"),
        link: process.env.irisNewsPostLink,
      },
      {
        icon: RiCalendarEventLine,
        text: t("home.shortcuts.createEventPost"),
        link: process.env.irisEventPostLink,
      },
      {
        icon: RiMailSendLine,
        text: t("home.shortcuts.sendEmailNotification"),
        link: process.env.irisEmailNotificationLink,
      },
      {
        icon: RiEditBoxLine,
        text: t("home.shortcuts.sendSinglePostNotification"),
        link: process.env.irisSinglePostNotificationLink,
      },
      {
        icon: RiFileList2Line,
        text: t("home.shortcuts.sendNewsletter"),
        link: process.env.irisNewsletterLink,
      },
    ];
  }

  return [];
};
