import { useEffect, useMemo } from "react";

const ShortcutEvent = "shortcutClick";
interface IEvent<T> extends CustomEvent {
  detail: {
    shortcut: T;
  };
}

export const useShortcuts = <T,>() => {
  const eventBus = useMemo(() => {
    return {
      on(callback: (_shortcut: T) => void) {
        document.addEventListener(ShortcutEvent, (e) => callback((e as IEvent<T>).detail.shortcut));
      },
      dispatch(shortcut: T) {
        document.dispatchEvent(new CustomEvent(ShortcutEvent, { detail: { shortcut } }));
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      remove(event: typeof ShortcutEvent, callback?: any) {
        document.removeEventListener(event, callback);
      },
    };
  }, []);

  useEffect(() => {
    return () => eventBus.remove(ShortcutEvent);
  }, [eventBus]);

  return { dispatch: eventBus.dispatch.bind(eventBus), on: eventBus.on.bind(eventBus) };
};
