import React from "react";

export const NoAccessIcon = () => {
  return (
    <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="56" height="56" rx="28" fill="black" fillOpacity="0.05" />
      <path
        d="M30 30.252V32.342C29.0949 32.022 28.1263 31.9239 27.1754 32.0558C26.2245 32.1877 25.3192 32.5459 24.5354 33.1002C23.7517 33.6545 23.1123 34.3888 22.6712 35.2414C22.23 36.094 21.9998 37.04 22 38L20 37.999C19.9997 36.7779 20.2789 35.5729 20.8163 34.4764C21.3537 33.3799 22.1349 32.4209 23.1002 31.673C24.0655 30.9251 25.1892 30.4081 26.3852 30.1616C27.5811 29.9152 28.8177 29.9457 30 30.251V30.252ZM28 29C24.685 29 22 26.315 22 23C22 19.685 24.685 17 28 17C31.315 17 34 19.685 34 23C34 26.315 31.315 29 28 29ZM28 27C30.21 27 32 25.21 32 23C32 20.79 30.21 19 28 19C25.79 19 24 20.79 24 23C24 25.21 25.79 27 28 27ZM35 33.586L37.121 31.464L38.536 32.879L36.414 35L38.536 37.121L37.121 38.536L35 36.414L32.879 38.536L31.464 37.121L33.586 35L31.464 32.879L32.879 31.464L35 33.586Z"
        fill="#999999"
      />
    </svg>
  );
};
