import React, { useState } from "react";
import { useTranslation } from "@equiem/localisation-eq1";
import { SiteLogo } from "../SiteLogo";
import { EquiemOneGreyscale } from "../EquiemOne/EquiemOneGreyscale";
import { TermsModal } from "./TermsModal";
import { stringNotEmpty } from "../../util/stringNotEmpty";
import { withDefaultPrevented } from "../../util/withDefaultPrevented";

interface Props {
  heroImage: string | null | undefined;
  name: string;
  logo: string | null | undefined;
  showLogoOnWhiteBackground: boolean;
  primaryColour: string;
  termsAndConditions: string | null | undefined;
  privacyPolicy: string | null | undefined;
}

const fallbackBackground =
  '\'data:image/svg+xml,<svg width="1920" height="1080" viewBox="0 0 1920 1080" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(%23clip0_314_389)"><rect width="1920" height="1080" fill="%23DCF4F0"/><g opacity="0.5"><circle cx="991.403" cy="505.403" r="1162.33" transform="rotate(-72.1979 991.403 505.403)" fill="url(%23paint0_radial_314_389)"/></g><g opacity="0.5"><circle cx="2154.87" cy="-166.131" r="1327.04" transform="rotate(-72.1979 2154.87 -166.131)" fill="url(%23paint1_radial_314_389)"/></g><g opacity="0.5"><circle cx="60.282" cy="1044.72" r="710.848" transform="rotate(-72.1979 60.282 1044.72)" fill="url(%23paint2_radial_314_389)"/></g></g><defs><radialGradient id="paint0_radial_314_389" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(991.068 506.053) rotate(-162.933) scale(1055.42)"><stop stop-color="%23BDEAE2"/><stop offset="1" stop-color="white"/></radialGradient><radialGradient id="paint1_radial_314_389" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(2154.49 -165.389) rotate(-162.933) scale(1204.99)"><stop stop-color="%23BDEAE2"/><stop offset="1" stop-color="white"/></radialGradient><radialGradient id="paint2_radial_314_389" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(60.0776 1045.12) rotate(-162.933) scale(645.465)"><stop stop-color="%23BDEAE2"/><stop offset="1" stop-color="white"/></radialGradient><clipPath id="clip0_314_389"><rect width="1920" height="1080" fill="white"/></clipPath></defs></svg>\'';

export const SiteHeroImageLayout: React.FC<React.PropsWithChildren<Props>> = ({
  heroImage,
  name,
  logo,
  showLogoOnWhiteBackground,
  primaryColour,
  termsAndConditions,
  privacyPolicy,
  children,
}) => {
  const { t } = useTranslation();

  const [showTermsAndConditions, setShowTermsAndConditions] = useState(false);
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);

  const hasFooterContent = stringNotEmpty(termsAndConditions) || stringNotEmpty(privacyPolicy);

  return (
    <>
      <div className="container">
        <div className="card">
          <div className="header">
            {stringNotEmpty(logo) ? (
              <SiteLogo
                size="72px"
                name={name}
                logo={logo}
                showLogoOnWhiteBackground={showLogoOnWhiteBackground}
                primaryColour={primaryColour}
              />
            ) : (
              <EquiemOneGreyscale width="67%" height="auto" />
            )}
          </div>
          <div className="content">{children}</div>
          <div className="footer">
            {stringNotEmpty(termsAndConditions) && (
              <a href="#" onClick={withDefaultPrevented(() => setShowTermsAndConditions(true))}>
                {t("common.termsAndConditions")}
              </a>
            )}
            {stringNotEmpty(privacyPolicy) && (
              <a href="#" onClick={withDefaultPrevented(() => setShowPrivacyPolicy(true))}>
                {t("common.privacyPolicy")}
              </a>
            )}
          </div>
        </div>
      </div>
      <TermsModal
        type="termsAndConditions"
        siteName={name}
        terms={termsAndConditions ?? ""}
        show={showTermsAndConditions}
        onHide={() => setShowTermsAndConditions(false)}
      />
      <TermsModal
        type="privacyPolicy"
        siteName={name}
        terms={privacyPolicy ?? ""}
        show={showPrivacyPolicy}
        onHide={() => setShowPrivacyPolicy(false)}
      />
      <style jsx>{`
        .container {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100vw;
          height: 100vh;
          padding: 32px;
          background: ${heroImage != null ? `url("${heroImage.replace(/"/gu, '\\"')}")` : `url(${fallbackBackground})`};
          background-color: white;
          background-size: cover;
          background-position: center;
        }
        .card {
          width: 100%;
          max-width: 400px;
          max-height: 100%;
          overflow-y: auto;
          padding: 0 32px;
          border-radius: 8px;
          background-color: white;
          overflow-wrap: break-word;
        }
        .header {
          position: sticky;
          top: 0;
          padding-top: 32px;
          padding-bottom: 24px;
          display: flex;
          justify-content: center;
          background-color: white;
        }
        .content {
          overflow-x: hidden;
        }
        .footer {
          position: sticky;
          bottom: 0;
          padding-top: ${hasFooterContent ? "24px" : "0"};
          padding-bottom: 24px;
          display: flex;
          justify-content: space-around;
          gap: 8px;
          background-color: white;
        }
        a {
          font-size: 12px;
          line-height: 16px;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.8);
          text-decoration: none;
        }
      `}</style>
    </>
  );
};
