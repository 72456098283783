import { onError } from "@apollo/client/link/error";
import type { AuthenticationContext } from "../context/SessionContext";
import type { OperationDefinitionNode } from "graphql";
import { noticeError } from "../noticeError";
import { rejectFetchError } from "../rejectFetchError";
import { Session } from "../Session";
import { homeApplicationId } from "./applicationIds";
import { getTokensFromPostMessage } from "./getTokensFromPostMessage";

export type AuthSetter = (_ctx: AuthenticationContext) => void;

export interface SessionState {
  authenticated: boolean;
  authenticationError?: string;
  expiresAt: number;
  accessToken?: string;
  idToken?: string;
}

let inflightAuthentication: Promise<SessionState> | undefined;
let authSetter: AuthSetter | null = null;

// eslint-disable-next-line no-return-assign
export const setAuthSetter = (setAuth: AuthSetter) => (authSetter = setAuth);

let session: SessionState = {
  authenticated: false,
  expiresAt: new Date().getTime(),
};

const tokensFromAuth0 = async (force = false) => {
  if (force || session.accessToken == null || session.expiresAt < new Date().getTime()) {
    try {
      const res = await fetch(`/api/token${force ? "?refresh" : ""}`, {
        method: "post",
        credentials: "same-origin",
      }).then(rejectFetchError);
      const resSession = Session.check(await res.json());

      if (resSession.accessToken != null) {
        const expiresAt = resSession.expiresAt * 1000;

        return {
          authenticated: true,
          accessToken: resSession.accessToken,
          expiresAt,
          idToken: resSession.idToken,
        };
      } else {
        return {
          authenticated: false,
          authenticationError: "Session expired.",
          expiresAt: Date.now(),
        };
      }
    } catch (e: unknown) {
      console.error(e);
      noticeError(e);

      return {
        authenticated: false,
        authenticationError: e instanceof Error ? e.message : "Unknown error",
        expiresAt: Date.now(),
      };
    }
  } else {
    return session;
  }
};

const tokensFromPostMessage = async (): Promise<SessionState> => {
  try {
    const tokens = await getTokensFromPostMessage();
    return {
      authenticated: true,
      accessToken: tokens.accessToken,
      expiresAt: tokens.expiresAt,
      idToken: tokens.idToken,
    };
  } catch (e: unknown) {
    return {
      authenticated: false,
      authenticationError: "Authentication from post message failed.",
      expiresAt: Date.now(),
    };
  }
};

export const authenticate = async (force = false, getTokenFromPostMessage = false) => {
  if (inflightAuthentication != null) {
    return inflightAuthentication;
  }

  // eslint-disable-next-line no-return-assign
  return (inflightAuthentication = (async () => {
    return getTokenFromPostMessage
      ? tokensFromPostMessage().then(async (result) => {
          if (result.authenticationError != null && window.location.pathname !== "/auth/error") {
            window.location.href = "/auth/error";
            return new Promise<typeof result>(() => null);
          }
          return result;
        })
      : tokensFromAuth0(force);
  })().then((result) => {
    inflightAuthentication = undefined;
    session = result;
    authSetter?.({
      loaded: true,
      authenticated: result.authenticated,
      authenticationError: result.authenticationError,
      idToken: result.idToken,
      accessToken: result.accessToken,
    });

    return result;
  }));
};

const with401Retry =
  (origFetch: typeof fetch, getTokenFromPostMessage: boolean) =>
  async (...args: Parameters<typeof fetch>) => {
    const res = await origFetch(...args);

    if (res.status === 401) {
      // The user's JWT has probably expired. Attempt to reauthenticate.
      const authRes = await authenticate(false, getTokenFromPostMessage);

      if (authRes.authenticated && authRes.accessToken != null) {
        return origFetch(args[0], {
          ...args[1],
          headers: {
            ...args[1]?.headers,
            authorization: `Bearer ${authRes.accessToken}`,
          },
        });
      }
    }

    return res;
  };

export const withAuth = (origFetch: typeof fetch, getTokenFromPostMessage: boolean) => {
  const wrappedFetch: typeof fetch = async (...args: Parameters<typeof fetch>) => {
    const authRes = await authenticate(false, getTokenFromPostMessage);

    if (authRes.accessToken == null) {
      throw new Error(`Failed to authenticate: ${authRes.authenticationError ?? "Unknown error"}`);
    }

    return origFetch(args[0], {
      ...args[1],
      headers: {
        ...args[1]?.headers,
        authorization: `Bearer ${authRes.accessToken}`,
      },
    });
  };

  return with401Retry(wrappedFetch, getTokenFromPostMessage);
};

export const dataIdFromObject = (obj: { uuid?: string; __typename?: string }) =>
  obj.__typename != null && obj.uuid != null ? `${obj.__typename}:${obj.uuid}` : undefined;

let lastErrorMessage: string | undefined;

const showError = (message: string) => {
  if (lastErrorMessage !== message) {
    console.log(message);
    lastErrorMessage = undefined;
  }
};

export const errorLink = onError((res) => {
  console.error(res);

  res.graphQLErrors = res.graphQLErrors?.filter(
    (e) => e.message.indexOf("Cannot return null for non-nullable field") === -1,
  );

  const operation = res.operation.query.definitions.find(
    (def): def is OperationDefinitionNode => def.kind === "OperationDefinition",
  );

  if (operation?.operation !== "query" || res.operation.getContext().ignoreErrors === true) {
    return;
  }

  if (res.networkError != null) {
    showError(res.networkError.message);
  }

  if (res.graphQLErrors != null && res.graphQLErrors.length > 0) {
    showError(res.graphQLErrors[0].message);
  }
});

export const headers = {
  "X-Equiem-Application": homeApplicationId,
};
