import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import type { FormikHelpers } from "formik";
import { Form } from "formik";
import { useRouter } from "next/router";

import { stringNotEmpty, useSaferFormik, useSaferFormikContext } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import {
  Button,
  Form as EqForm,
  MemberCard,
  Modal,
  useDebounced,
  useIsMobileWidth,
  useTheme,
  useToast,
} from "@equiem/react-admin-ui";
import { RiAddLine, RiCheckLine } from "@equiem/react-admin-ui/icons";

import { Modal as ModalContext } from "../../../contexts/ModalContext";
import type { VisitorSuggestionsQuery } from "../../../generated/visitors-client";
import { useVisitorReceptionQuery, useVisitorSuggestionsQuery } from "../../../generated/visitors-client";
import type { FormValues, FormVisitor } from "../types";
import { visitorValidationSchema } from "../utils/validationSchema";

const initialValues: FormVisitor = {
  firstName: "",
  lastName: "",
  companyName: "",
  email: "",
  receptionNoteMessage: undefined,
  visitorInfo: [],
};

interface Props {
  mode: "default" | "walkin";
}

export const AddVisitorWidget: React.FC<Props> = ({ mode }) => {
  const [showModal, setShowModal] = useState(false);
  const modal = useContext(ModalContext);
  const toast = useToast();
  const isMobile = useIsMobileWidth();
  const { breakpoints } = useTheme();
  const [unsavedVisitors, setUnsavedVisitors] = useState<FormVisitor[]>([]);
  const { values: formValues, setFieldValue } = useSaferFormikContext<FormValues>();
  const router = useRouter();

  const skipQueries = mode === "default" && !showModal;
  const isReceptionsRoute = router.pathname.includes("receptions");
  const isUuidMissing = typeof router.query.uuid !== "string";
  const { t } = useTranslation();

  const { data: receptionData } = useVisitorReceptionQuery({
    variables: { uuid: router.query.uuid as string },
    skip: isUuidMissing || !isReceptionsRoute || skipQueries,
  });

  const handleFormSubmit = (submitValues: FormVisitor, { resetForm }: FormikHelpers<FormVisitor>) => {
    setUnsavedVisitors((v) => [...v, submitValues]);
    resetForm();
  };

  const { dirty, values, isValid, isSubmitting, errors, handleChange, handleSubmit, resetForm } =
    useSaferFormik<FormVisitor>({
      initialValues,
      validationSchema: visitorValidationSchema(t),
      onSubmit: handleFormSubmit,
    });

  const debounceTimeout = 200;
  const debouncedValues = useDebounced(values, debounceTimeout);

  const isVisitorSearchMode = useMemo(
    () =>
      (stringNotEmpty(debouncedValues.firstName) || stringNotEmpty(debouncedValues.lastName)) &&
      !stringNotEmpty(debouncedValues.companyName) &&
      !stringNotEmpty(debouncedValues.email),
    [debouncedValues],
  );

  const { data: suggestedVisitorsData } = useVisitorSuggestionsQuery({
    variables: isVisitorSearchMode
      ? {
          firstName: debouncedValues.firstName,
          lastName: debouncedValues.lastName,
          companyName: debouncedValues.companyName,
          email: debouncedValues.email,
        }
      : {
          firstName: undefined,
          lastName: undefined,
          companyName: undefined,
          email: undefined,
        },
    skip: skipQueries,
  });

  const onClose = useCallback(() => {
    setShowModal(false);
    modal.close();
    setUnsavedVisitors([]);
    resetForm();
  }, [setShowModal, setUnsavedVisitors, resetForm]);

  useEffect(() => {
    if (mode === "walkin") {
      setUnsavedVisitors([...formValues.visitors]);
    }
  }, []);

  useEffect(() => {
    if (mode === "walkin") {
      setFieldValue("visitors", [...unsavedVisitors]).catch(console.error);
    }
  }, [mode, setFieldValue, unsavedVisitors]);

  useEffect(() => {
    if (modal.activeModal === "AddVisitor") {
      setShowModal(true);
    }
  }, [modal.activeModal, setShowModal]);

  const addVisitor = (visitor: VisitorSuggestionsQuery["visitorSuggestions"][number]) => {
    const formVisitor: FormVisitor = {
      uuid: visitor.uuid ?? undefined,
      email: visitor.email ?? "",
      companyName: visitor.companyName ?? "",
      firstName: visitor.firstName,
      lastName: visitor.lastName,
      receptionNoteMessage: undefined,
      visitorInfo: [],
    };

    setUnsavedVisitors((v) => [...v, formVisitor]);

    resetForm();
  };

  /* istanbul ignore next */
  const updateCardId = (cardId: string, index: number) => {
    const visitors = [...unsavedVisitors];
    visitors[index].cardId = cardId;
    setFieldValue("visitors", visitors).catch(console.error);
    setUnsavedVisitors(() => visitors);
  };

  const removeVisitor = useCallback(
    (visitor: FormVisitor) => {
      if (unsavedVisitors.includes(visitor)) {
        setUnsavedVisitors((v) => v.filter((e) => e !== visitor));
      }
    },
    [unsavedVisitors],
  );

  const handleSaveModal = useCallback(() => {
    setFieldValue("visitors", [...formValues.visitors, ...unsavedVisitors]).catch(console.error);
    toast.positive(t("visitors.appointmentForm.visitorsAdded", { count: unsavedVisitors.length }));
    onClose();
  }, [setFieldValue, formValues.visitors, unsavedVisitors, toast, t, onClose]);

  const handleChangeTrim = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.trim().length === 0) {
      e.target.value = e.target.value.trim();
    }
    handleChange(e);
  };

  const visitorExists = useCallback(
    (visitor: FormVisitor) => {
      const firstName = visitor.firstName.toLowerCase();
      const lastName = visitor.lastName.toLowerCase();
      const email = visitor.email?.toLowerCase() ?? "";
      const unsavedVisitor = unsavedVisitors.find(
        (v) =>
          v.firstName.toLowerCase() === firstName &&
          v.lastName.toLowerCase() === lastName &&
          (v.email?.toLowerCase() ?? "") === email,
      );
      const existedVisitor = formValues.visitors.find(
        (v) =>
          v.firstName.toLowerCase() === firstName &&
          v.lastName.toLowerCase() === lastName &&
          (v.email?.toLowerCase() ?? "") === email,
      );

      return Boolean(unsavedVisitor) || Boolean(existedVisitor);
    },
    [unsavedVisitors, formValues],
  );

  const suggestedVisitors = useMemo(
    () =>
      suggestedVisitorsData?.visitorSuggestions.filter(
        (v) => !visitorExists({ firstName: v.firstName, lastName: v.lastName, email: v.email ?? "" }),
      ) ?? [],
    [suggestedVisitorsData, visitorExists],
  );

  const renderForm = () => (
    <Form className="d-flex body">
      <div className="form-block px-6 mt-6">
        <EqForm.Group
          label={t("common.firstName")}
          error={errors.firstName !== undefined ? errors.firstName : undefined}
          required
        >
          <EqForm.Input
            placeholder={t("common.firstName") ?? ""}
            type="text"
            name="firstName"
            value={values.firstName}
            onChange={handleChangeTrim}
            autoComplete="off"
          />
        </EqForm.Group>
        <EqForm.Group
          label={t("common.lastName")}
          error={errors.lastName !== undefined ? errors.lastName : undefined}
          required
        >
          <EqForm.Input
            placeholder={t("common.lastName") ?? ""}
            type="text"
            name="lastName"
            value={values.lastName}
            onChange={handleChangeTrim}
            autoComplete="off"
          />
        </EqForm.Group>
        <EqForm.Group
          label={t("common.company")}
          error={errors.companyName !== undefined ? errors.companyName : undefined}
        >
          <EqForm.Input
            placeholder={t("common.company") ?? ""}
            type="text"
            name="companyName"
            value={values.companyName}
            onChange={handleChangeTrim}
            autoComplete="off"
          />
        </EqForm.Group>
        <EqForm.Group label={t("common.email")} error={errors.email !== undefined ? errors.email : undefined}>
          <EqForm.Input
            placeholder={t("common.email") ?? ""}
            type="text"
            name="email"
            value={values.email}
            onChange={handleChangeTrim}
            autoComplete="off"
          />
        </EqForm.Group>
      </div>
      <div className="visitors-block p-6 pt-0">
        {dirty && (
          <EqForm.Group className="mb-0" label={t("visitors.appointmentForm.createNewVisitor")}>
            <MemberCard.Card
              firstName={values.firstName}
              lastName={values.lastName}
              companyName={values.companyName ?? ""}
              email={values.email ?? ""}
              className="visitor mt-2"
              buttonElement={isMobile ? <RiAddLine size={20} /> : undefined}
              showButtonOnHover={!isMobile}
              buttonSize={isMobile ? "md" : "sm"}
              showButton
              buttonRound={isMobile}
              transparentBackground={!isMobile}
              buttonText={t("visitors.appointmentForm.addVisitor")}
              isButtonDisabled={!isValid || !dirty || isSubmitting}
              buttonType="submit"
              buttonVariant={isMobile ? "secondary" : "primary"}
              isCardInModal={false}
              onClick={() => handleSubmit()}
            />
          </EqForm.Group>
        )}
        {unsavedVisitors.length > 0 && !isVisitorSearchMode && (
          <EqForm.Group className="mb-0" label={t("visitors.appointmentForm.addedVisitors")}>
            <div className="list">
              {unsavedVisitors.map((visitor, index) => {
                return (
                  <MemberCard.Card
                    buttonElement={<RiAddLine size={32} />}
                    firstName={visitor.firstName}
                    lastName={visitor.lastName}
                    companyName={visitor.companyName ?? ""}
                    email={visitor.email ?? ""}
                    className="visitor mt-2"
                    showButtonOnHover={false}
                    isDeleteVisibleAlways={isMobile}
                    transparentBackground={!isMobile}
                    deleteButton={isMobile ? <RiCheckLine size={20} /> : undefined}
                    deleteButtonVariant={isMobile ? "primary" : "ghost"}
                    isCardInModal={false}
                    key={`${visitor.firstName}_${visitor.lastName}_${index}`}
                    defaultCardId={formValues.visitors[index]?.cardId ?? ""}
                    onDeleteButtonClick={() => removeVisitor(visitor)}
                    onSaveCardId={mode === "walkin" ? (cardId: string) => updateCardId(cardId, index) : undefined}
                    showCardId={mode === "walkin" && receptionData?.visitorReception.enableAccessCard === true}
                  />
                );
              })}
            </div>
          </EqForm.Group>
        )}
        <EqForm.Group
          className="mb-0"
          label={
            isVisitorSearchMode
              ? t("visitors.appointmentForm.suggestedHosts")
              : t("visitors.appointmentForm.suggestedVisitors")
          }
        >
          <div className="list">
            {suggestedVisitors.map((visitor, index) => (
              <MemberCard.Card
                firstName={visitor.firstName}
                lastName={visitor.lastName}
                companyName={visitor.companyName ?? ""}
                email={visitor.email ?? ""}
                className="visitor mt-2"
                buttonElement={isMobile ? <RiAddLine size={20} /> : undefined}
                showButtonOnHover={!isMobile}
                buttonSize={isMobile ? "md" : "sm"}
                showButton={isMobile}
                buttonRound={isMobile}
                transparentBackground={!isMobile}
                isCardInModal={false}
                key={`${visitor.firstName}_${visitor.lastName}_${index}`}
                buttonVariant="secondary"
                buttonText={t("common.add")}
                onButtonClick={() => addVisitor(visitor)}
              />
            ))}
          </div>
        </EqForm.Group>
      </div>
      <style jsx>
        {`
          .form-block {
            flex: 4;
          }

          .visitors-block {
            flex: 3;
            gap: 24px;
            background: #f7f7f7;
            max-height: 400px;
            display: flex;
            flex-direction: column;
            overflow: auto;
          }

          @media (max-width: ${breakpoints.md}px) {
            :global(.visitor-modal-root) {
              padding: 0 !important;
            }

            .visitors-block {
              position: sticky;
              bottom: 0;
              max-height: 140px;
            }

            :global(.body) {
              flex-direction: column;
              height: 100%;
            }

            .form-block {
              flex: 1;
              overflow: auto;
            }

            .preview {
              flex: 1;
              height: unset;
            }

            .list {
              display: flex;
              flex-direction: row;
              gap: 8px;
              overflow: auto;
            }

            .modal-body {
              overflow: auto;
            }

            .row {
              flex-direction: row;
              flex-wrap: nowrap;
            }

            :global(.visitor) {
              min-width: 200px;
              max-width: 200px;
            }
          }
        `}
      </style>
    </Form>
  );

  if (mode === "default") {
    return (
      <>
        <Modal.Dialog
          title={t("visitors.appointmentForm.addVisitors")}
          show={showModal}
          onHide={onClose}
          supportsMobile
          hideOnEsc={true}
          hideOnClick={false}
          focusTrapOptions={{ allowOutsideClick: () => true }}
          size="lg"
          className="visitors-modal"
        >
          <Modal.Header
            closeButton
            intro={!isMobile ? t("visitors.appointmentForm.addVisitorsIntro") : undefined}
            noBorder={true}
          />
          <div className="modal-body">
            <hr className="custom-liner" />
            <div style={{ height: "100%" }}>
              {renderForm()}
              <hr className="custom-liner" />
            </div>
          </div>
          <Modal.Footer>
            <div className="footer">
              <Button size="md" onClick={onClose} variant="ghost">
                {t("common.cancel")}
              </Button>
              <Button size="md" onClick={handleSaveModal} disabled={unsavedVisitors.length === 0}>
                {t("common.done")}
              </Button>
            </div>
          </Modal.Footer>
        </Modal.Dialog>
        <style jsx>
          {`
            .custom-liner {
              margin: 0px;
              border-top: 1px solid rgba(0, 0, 0, 0.1);
            }

            @media (max-width: ${breakpoints.sm}px) {
              :global(.visitors-modal) {
                height: 100% !important;
              }
            }

            @media (max-width: ${breakpoints.md}px) {
              .modal-body {
                height: 100%;
                max-height: 100%;
                overflow: auto;
              }

              :global(.visitors-modal) {
                max-height: 100% !important;
              }

              :global(.visitors-modal .title-row) {
                margin: 20px 0 !important;
              }

              :global(.visitors-modal .title-row .close) {
                margin-bottom: 0;
              }

              :global(.visitors-modal .title-row .title) {
                font-size: 24px;
                color: black;
                text-transform: none;
                font-weight: 700;
              }

              :global(.visitors-modal .footer) {
                padding: 8px !important;
              }
            }

            .footer {
              display: grid;
              grid-auto-flow: column;
              grid-column-gap: 8px;
              z-index: 999;
            }
          `}
        </style>
      </>
    );
  }

  return (
    <div className="inline-container">
      {renderForm()}
      <style jsx>
        {`
          .inline-container {
          }
        `}
      </style>
    </div>
  );
};
