import React from "react";

export const EquiemOneGreyscale: React.FC<{ width?: string; height?: string }> = ({
  width = "775",
  height = "109",
}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 775 109" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.9">
        <path
          d="M561.131 86.8533C549.537 86.8533 540.168 82.8716 533.024 74.6739C525.764 66.5933 522.25 56.1705 522.25 43.4055C522.25 30.7576 525.764 20.4519 533.024 12.2542C540.051 4.2907 549.42 0.191849 561.131 0.191849C572.842 0.191849 582.328 4.2907 589.472 12.2542C596.498 20.3348 600.129 30.7576 600.129 43.4055C600.129 56.2876 596.498 66.7104 589.472 74.6739C582.211 82.8716 572.842 86.8533 561.131 86.8533ZM561.131 80.4123C570.734 80.4123 578.346 77.0161 584.202 70.2237C589.94 63.5484 592.868 54.648 592.868 43.4055C592.868 32.3971 589.94 23.4968 584.202 16.7044C578.346 10.0291 570.617 6.63291 561.131 6.63291C551.528 6.63291 543.916 10.0291 538.177 16.7044C532.322 23.4968 529.511 32.3971 529.511 43.4055C529.511 54.5309 532.439 63.4313 538.294 70.2237C544.033 77.0161 551.645 80.4123 561.131 80.4123ZM677.028 75.2594V1.71428H683.82V85.0967H675.857L626.202 11.7857V85.0967H619.527V1.71428H627.139L677.028 75.2594ZM713.128 44.9279V78.8898H764.774V85.0967H706.101V1.71428H763.251V7.92111H713.128V38.7211H759.621V44.9279H713.128Z"
          fill="#191C22"
        />
        <g clipPath="url(#clip0_1301_30)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1.79492 3.52783V85.0985H62.2537V69.3844H19.1886V52.7107H57.3357V36.9961H19.1886V19.2419H62.2537V3.52783H1.79492Z"
            fill="#191C22"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M265.927 48.0314C265.927 72.3836 251.292 86.8979 228.98 86.8979C206.788 86.8979 192.273 72.3836 192.273 48.0314V3.52783H209.547V48.1517C209.547 61.7073 217.344 70.704 228.98 70.704C240.856 70.704 248.653 61.7073 248.653 48.1517V3.52783H265.927V48.0314Z"
            fill="#191C22"
          />
          <mask
            id="mask0_1301_30"
            style={{ maskType: "alpha" }}
            maskUnits="userSpaceOnUse"
            x="1"
            y="1"
            width="509"
            height="86"
          >
            <path d="M1.79492 86.8993H509.566V1.84961H1.79492V86.8993Z" fill="white" />
          </mask>
          <g mask="url(#mask0_1301_30)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M292.92 85.0985H310.314V3.52783H292.92V85.0985Z"
              fill="#191C22"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M355.778 36.9956H393.925V52.7102H355.778V69.3844H398.843V85.098H338.384V3.52783H398.843V19.2414H355.778V36.9956Z"
              fill="#191C22"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M509.566 85.0985H492.172V40.8341L471.779 72.8629H461.583V72.7426L441.07 40.4746L441.31 85.0985H423.796V3.52783H436.272L466.741 51.5104L497.21 3.52783H509.566V85.0985Z"
              fill="#191C22"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M141.968 44.375H125.72C125.72 67.8608 144.759 86.8996 168.245 86.8996V70.6513C153.733 70.6513 141.968 58.8869 141.968 44.375Z"
              fill="#191C22"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M125.719 70.651C111.207 70.651 99.4426 58.8866 99.4426 44.3747C99.4426 29.8628 111.207 18.0983 125.719 18.0983C140.231 18.0983 151.995 29.8628 151.995 44.3747H168.244C168.244 20.8889 149.205 1.8501 125.719 1.8501C102.233 1.8501 83.1943 20.8889 83.1943 44.3747C83.1943 67.8604 102.233 86.8993 125.719 86.8993V70.651Z"
              fill="#191C22"
            />
          </g>
        </g>
      </g>
      <defs>
        <clipPath id="clip0_1301_30">
          <rect width="507.955" height="99" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
