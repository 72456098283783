import React, { useContext, useEffect, useCallback } from "react";
import { ModalContext } from "../ModalContext";
import { useTheme } from "../../../contexts/Theme";

interface P {
  noPadding?: boolean;
  noMargin?: boolean;
  onScrolledToBottom?: () => void;
}
export const ModalBody: React.FC<React.PropsWithChildren<P>> = ({
  children,
  noPadding = false,
  noMargin = false,
  onScrolledToBottom,
}) => {
  const {
    isAdmin,
    spacers: { s5, s6 },
  } = useTheme();
  const { scrollable, headerBorder, setStickyHeader, setStickyFooter, modalBodyRef, withFooter } =
    useContext(ModalContext);
  const noBorderStyle = isAdmin && !headerBorder ? "0" : s5;

  const handleModalScroll = useCallback(() => {
    if (!isAdmin || modalBodyRef?.current == null || !scrollable) {
      return;
    }

    const { scrollTop, clientHeight, scrollHeight } = modalBodyRef.current;
    const heightDelta = scrollHeight - clientHeight;
    const scrollBottom = heightDelta - scrollTop;

    const scrollMarginLow = 10;
    const scrollMarginHigh = 20;
    const deltaHeightMargin = 200;

    if (scrollTop < scrollMarginLow) {
      setStickyHeader(false);
    } else if (scrollTop > scrollMarginHigh && heightDelta > deltaHeightMargin) {
      setStickyHeader(true);
    }

    if (scrollBottom < scrollMarginLow) {
      setStickyFooter(false);
      onScrolledToBottom?.();
    } else if (scrollBottom > scrollMarginHigh && heightDelta > deltaHeightMargin) {
      setStickyFooter(true);
    }
  }, [isAdmin, modalBodyRef, onScrolledToBottom, scrollable, setStickyFooter, setStickyHeader]);

  useEffect(() => {
    const ref = modalBodyRef?.current;
    ref?.addEventListener("scroll", handleModalScroll, {
      passive: true,
    });

    return () => ref?.removeEventListener("scroll", handleModalScroll);
  }, [handleModalScroll, modalBodyRef]);

  return (
    <div ref={modalBodyRef} className="modal-body">
      {children}
      <style jsx>{`
        div {
          padding: ${noPadding ? 0 : `0 ${s6} ${withFooter ? 0 : "2rem"}`};
          margin: ${noMargin ? "0" : noBorderStyle} 0 0 0;
          overflow: ${scrollable ? "auto" : "visible"};
          height: 100%;
          word-break: break-word;
          flex-grow: 1;
        }
      `}</style>
    </div>
  );
};
