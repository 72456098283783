import React from "react";

import { useTranslation } from "@equiem/localisation-eq1";
import { Tooltip } from "@equiem/react-admin-ui";
import { RiCheckboxCircleLine, RiCloseCircleLine } from "@equiem/react-admin-ui/icons";

interface Props {
  color: string;
  verified?: boolean;
}

export const VerifiedStatus: React.FC<Props> = ({ color, verified = true }) => {
  const { t } = useTranslation();
  return (
    <Tooltip title={verified ? t("settings.emailVerifiedTooltip") : t("settings.emailNotVerifiedTooltip")}>
      <span>
        {verified ? (
          <RiCheckboxCircleLine
            data-cy="email-verified"
            style={{
              color,
            }}
          />
        ) : (
          <RiCloseCircleLine
            data-cy="email-not-verified"
            style={{
              color,
            }}
          />
        )}
      </span>
    </Tooltip>
  );
};
