import React from "react";
import css from "styled-jsx/css";

import { useTheme } from "../../../contexts/Theme";

export interface Props {
  required?: boolean;
  htmlFor?: string;
  id?: string;
  inline?: "sm" | "md" | "lg" | boolean;
  textWrap?: boolean;
  noMargin?: boolean;
  capitalize?: boolean;
}

export const FormLabel: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  required = false,
  htmlFor,
  id,
  inline = false,
  textWrap = false,
  noMargin = false,
  capitalize = true,
}) => {
  const { colors, spacers, isAdmin, breakpoints } = useTheme();

  const { styles: inlineResponsiveStyles, className } =
    typeof inline !== "string"
      ? css.resolve``
      : css.resolve`
          @media screen (max-width: ${breakpoints[inline]}px) {
            div {
              padding: ${spacers.s2} 0;
            }
          }
        `;

  return (
    <div className={className}>
      <label id={id} htmlFor={htmlFor}>
        {children}
        {required ? <span className="required-marker">*</span> : null}
      </label>
      <style jsx>{`
        div {
          display: flex;
          justify-content: ${isAdmin ? "flex-start" : "space-between"};
          align-items: ${isAdmin ? "initial" : "flex-start"};
          padding: ${inline !== false ? spacers.s4 : noMargin ? spacers.s0 : spacers.s2} 0;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
        label {
          flex: 1;
          font-weight: 500;
          font-size: ${isAdmin ? "12" : "14"}px;
          color: ${isAdmin ? colors.medium : colors.dark};
          padding-right: ${isAdmin ? spacers.s1 : spacers.s3};
          margin: 0;
          margin-bottom: ${isAdmin && !noMargin ? spacers.s3 : "0"};
          text-overflow: ellipsis;
          overflow: hidden;
          display: inline-block;
          white-space: nowrap;
          text-wrap: ${textWrap ? "wrap" : undefined};
          text-transform: ${isAdmin && capitalize ? "uppercase" : undefined};
        }
        .required-marker {
          color: red;
          font-size: 14px;
          margin-left: ${spacers.s1};
          font: "Roboto";
        }
      `}</style>
      {inlineResponsiveStyles}
    </div>
  );
};
