import React, { useContext } from "react";

import { CurrentProfile } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import { Button } from "@equiem/react-admin-ui";
import { RiSettings3Line } from "@equiem/react-admin-ui/icons";

import { Modal as ModalContext } from "../contexts/ModalContext";
import { CompanyDetailsWidget } from "../pages/companies/widgets/CompanyDetailsWidget";

export const OwnCompanySettings = () => {
  const { t } = useTranslation();
  const { profile } = useContext(CurrentProfile);
  const currentUserHasCompany = profile?.companyV2?.uuid != null;
  const modal = useContext(ModalContext);

  if (!currentUserHasCompany) {
    console.error("company not found for the current user");
  }

  const onClick = () => {
    if (profile?.companyV2?.uuid != null) {
      modal.open("CompanyDetails", profile.companyV2.uuid);
    }
  };

  return (
    <>
      <div className="d-flex align-items-center">
        <Button size="md" variant="ghost" disabled={!currentUserHasCompany} onClick={onClick}>
          <RiSettings3Line size={18} />
          {t("settings.companySettings")}
        </Button>
      </div>
      <CompanyDetailsWidget />
    </>
  );
};
