import React, { useContext, useEffect, useState } from "react";
import type { ApolloQueryResult } from "@apollo/client";

import { useBcUuidContext } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import { ProgressCircle, Skeleton, Text, useIsMobileWidth, useTheme } from "@equiem/react-admin-ui";
import { RiCloudLine } from "@equiem/react-admin-ui/icons";

import type { RequestQuery } from "../../../generated/requests-client";
import { ReqMgtStatusType } from "../../../generated/requests-client";
import { RequestDetailsContext } from "../contexts/RequestDetailsContext";

import { RequestAssignee } from "./RequestAssignee";
import { RequestAttachments } from "./RequestAttachments";
import { RequestCategory } from "./RequestCategory";
import { RequestDateCompleted } from "./RequestDateCompleted";
import { RequestDateReported } from "./RequestDateReported";
import { RequestDescription } from "./RequestDescription";
import { RequestLocation } from "./RequestLocation";
import { RequestReporter } from "./RequestReporter";
import { RequestResendCafm } from "./RequestResendCafm";
import { RequestStatus } from "./RequestStatus";
import { RequestWatchers } from "./RequestWatchers";
import { RequestWatchUnwatch } from "./RequestWatchUnwatch";

interface P {
  refetchRequest?: () => Promise<ApolloQueryResult<RequestQuery>>;
  request?: RequestQuery["reqMgt"]["request"] | null;
  loading: boolean;
}

const minute = 60_000;
const intervalTime = 15 * minute;

export const RequestDetails: React.FC<P> = ({ refetchRequest, request, loading }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { loading: updateLoading, lastSaved } = useContext(RequestDetailsContext);
  const [refreshing, setRefreshing] = useState(false);
  const { updateBcUuid } = useBcUuidContext();
  const isMobile = useIsMobileWidth();

  useEffect(() => {
    const timer = setInterval(() => {
      if (!updateLoading) {
        setRefreshing(true);
        refetchRequest?.().catch(console.log);
        setRefreshing(false);
      }
    }, intervalTime);

    return () => clearTimeout(timer);
  }, [lastSaved, refetchRequest, updateLoading]);

  useEffect(() => {
    if (request?.uuid != null) {
      updateBcUuid(request.uuid, t("requests.details.requestTitle", { reference: request.reference }));
    }
  }, [request?.reference, request?.uuid, t, updateBcUuid]);

  if (loading) {
    return <Skeleton.Line height="25px" width="200px" />;
  }
  if (request == null) {
    return null;
  }

  return (
    <>
      {!isMobile && (
        <div className="d-flex justify-content-between pb-3">
          <div className="d-flex align-items-center">
            <h1 className="font-weight-bold">{request.reference}</h1>
            {refreshing && (
              <div className="processing ml-4">
                <ProgressCircle size="xs" className="mr-2" /> {t("common.refreshing")}...
              </div>
            )}
            {updateLoading && (
              <div className="processing saving ml-4">
                <RiCloudLine className="mr-2" /> {t("common.saving")}...
              </div>
            )}
            {!updateLoading && lastSaved != null && (
              <div className="processing saving ml-4">
                <RiCloudLine className="mr-2" /> {t("common.saved")}
              </div>
            )}
          </div>
        </div>
      )}

      <div className="row">
        <div className="col status-col">
          <Text variant="label" color={theme.colors.grayscale["50"]}>
            {t("requests.details.status")}
          </Text>
          <div>
            <RequestStatus request={request} />
          </div>
        </div>
        {request.status.type === ReqMgtStatusType.Completed && (
          <div className="col datecompleted-col">
            <Text variant="label" color={theme.colors.grayscale["50"]}>
              {t("requests.details.dateCompleted")}
            </Text>
            <RequestDateCompleted request={request} />
          </div>
        )}
      </div>

      {(request.viewerRelations.internalParticipant || request.viewerRelations.externalParticipant) && (
        <div className="row">
          {request.viewerRelations.internalParticipant && (
            <div className="col assignee-col">
              <Text variant="label" color={theme.colors.grayscale["50"]}>
                {t("requests.assignee")}
              </Text>
              <RequestAssignee request={request} />
            </div>
          )}
          <div className="col watcher-col">
            <Text variant="label" color={theme.colors.grayscale["50"]}>
              {t("requests.details.watchers")}
            </Text>
            <div>
              {request.viewerRelations.canManage ? (
                <RequestWatchers request={request} />
              ) : (
                <RequestWatchUnwatch request={request} />
              )}
            </div>
          </div>
        </div>
      )}

      <div className="row">
        <div className="col reporter-col">
          <Text variant="label" color={theme.colors.grayscale["50"]}>
            {t("requests.reporter")}
          </Text>
          <RequestReporter request={request} />
        </div>
        <div className="col datereported-col">
          <Text variant="label" color={theme.colors.grayscale["50"]}>
            {t("requests.details.dateReported")}
          </Text>
          <RequestDateReported request={request} />
        </div>
      </div>

      <div className="row">
        <div className="col category-col">
          <Text variant="label" color={theme.colors.grayscale["50"]}>
            {request.viewerRelations.internalParticipant
              ? t("requests.details.categoryAndSubcategory")
              : t("requests.details.category")}
          </Text>
          <RequestCategory request={request} />
        </div>
        <div className="col location-col">
          <Text variant="label" color={theme.colors.grayscale["50"]}>
            {t("requests.details.location")}
          </Text>
          <RequestLocation request={request} />
        </div>
        {request.cafmRequestId == null && request.cafmSendAttempts != null && request.subCategory?.uuid != null && (
          <div className="row">
            <div className="col category-col">
              <RequestResendCafm request={request} />
            </div>
          </div>
        )}
      </div>

      <div className="description-col">
        <Text variant="label" color={theme.colors.grayscale["50"]}>
          {t("common.description")}
        </Text>
        <RequestDescription request={request} />
      </div>
      <div className="attachment-col">
        <Text variant="label" color={theme.colors.grayscale["50"]}>
          {t("common.attachments")}
        </Text>
        <div className="attachment-files">
          <RequestAttachments request={request} />
        </div>
      </div>
      <style jsx>{`
        .row {
          display: grid;
          gap: ${theme.spacers.s7};
        }
        .col {
          display: grid;
          grid-template-columns: 35% 65%;
          align-items: center;
          gap: ${theme.spacers.s2};
        }

        @media (min-width: ${theme.breakpoints.md}px) {
          .row {
            grid-template-columns: 50% 1fr;
          }
        }
        .attachment-files {
          margin-top: ${theme.spacers.s3};
        }
        .attachment-col {
          margin-top: ${theme.spacers.s7};
          gap: 8px;
        }
        .description-col {
          margin-top: ${theme.spacers.s7};
          padding-top: ${theme.spacers.s6};
          border-top: 1px solid ${theme.colors.border};
        }
        .processing {
          font-weight: 500;
          text-transform: uppercase;
          color: ${theme.colors.grayscale["60"]};
          display: flex;
          align-items: center;
        }
      `}</style>
    </>
  );
};
