import React, { createContext, useCallback, useEffect, useState } from "react";

import type { HeightCalculationMethod, IFramePage, PageInfo } from "iframe-resizer";

export const defaultIframePageInfo: PageInfo = {
  iframeHeight: 0,
  iframeWidth: 0,
  offsetLeft: 0,
  offsetTop: 0,
  scrollLeft: 0,
  scrollTop: 0,
  clientHeight: 0,
  clientWidth: 0,
};

export type SizeMethod = (customHeight?: number, customWidth?: number) => void;
export type SetHeightCalculationMethod = (method: HeightCalculationMethod) => void;

export interface IframeResizerContext {
  inIframe: boolean;
  pageInfo: PageInfo;
  size: IFramePage["size"];
  setHeightCalculationMethod: IFramePage["setHeightCalculationMethod"];
  heightCalculationMethod: HeightCalculationMethod;
  minIframeHeight?: number;
}

export const IframeResizerContext = createContext<React.PropsWithChildren<IframeResizerContext>>({
  inIframe: false,
  pageInfo: defaultIframePageInfo,
  heightCalculationMethod: "bodyOffset",
  size: () => undefined,
  setHeightCalculationMethod: () => undefined,
});

export const IframeResizerProvider: React.FC<React.PropsWithChildren<{ parentIFrame?: IFramePage }>> = ({
  children,
  parentIFrame,
}) => {
  const [minIframeHeight, setMinIframeHeight] = useState<number>();
  const [iframePageInfo, setIframePageInfo] = useState(defaultIframePageInfo);
  const [heightMethod, setHeightMethod] = useState<HeightCalculationMethod>("bodyOffset");

  useEffect(() => {
    if (parentIFrame != null) {
      parentIFrame.getPageInfo(setIframePageInfo);
    }
  }, [parentIFrame]);

  useEffect(() => {
    const listener = (e: MessageEvent) => {
      if (typeof e.data === "string" && e.data.startsWith("EquiemMinIframeHeight:")) {
        setMinIframeHeight(parseInt(e.data.replace("EquiemMinIframeHeight:", ""), 10));
      }
    };

    window.addEventListener("message", listener);
    return () => {
      window.removeEventListener("message", listener);
    };
  }, []);

  const size = useCallback<IFramePage["size"]>(
    (...args) => {
      parentIFrame?.size(...args);
    },
    [parentIFrame],
  );

  const setHeightCalculationMethod = useCallback<IFramePage["setHeightCalculationMethod"]>(
    (...args) => {
      setHeightMethod(args[0]);
      parentIFrame?.setHeightCalculationMethod(...args);
    },
    [parentIFrame],
  );

  return (
    <IframeResizerContext.Provider
      value={{
        inIframe: window.parentIFrame != null,
        pageInfo: iframePageInfo,
        size,
        heightCalculationMethod: heightMethod,
        setHeightCalculationMethod,
        minIframeHeight,
      }}
    >
      {children}
    </IframeResizerContext.Provider>
  );
};
