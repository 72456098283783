import React from "react";
import { Role } from "../Role";

export interface RoleContext {
  currentRole: Role;
  setCurrentRole: (_role: Role) => void;
  jwtRoles: string[];
}

export const CurrentRole = React.createContext<RoleContext>({
  currentRole: Role.PropertyManager,
  setCurrentRole: () => undefined,
  jwtRoles: [],
});
