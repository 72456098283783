import React, { useCallback, useContext, useEffect, useState } from "react";
import { Button, useTheme } from "@equiem/react-admin-ui";
import { RiAddLine, RiUserAddLine } from "@equiem/react-admin-ui/icons";
import { useTranslation, formatters } from "@equiem/localisation-eq1";
import { v4 as uuidv4 } from "uuid";

import type { SaveSegmentV2Input } from "../generated/gateway-client";
import {
  useEstimateAudienceLazyQuery,
  useEstimateUserCountLazyQuery,
  useFindMatchingSegmentsLazyQuery,
  useSaveSegmentMutation,
} from "../generated/gateway-client";
import { CurrentAudience } from "../contexts/AudienceProvider";
import { Segment } from "./Segment";
import { EmptyAudience } from "./EmptyAudience";
import { SegmentProvider } from "../contexts/SegmentProvider";
import { AudienceTooltip } from "./AudienceTooltip";
import { NotificationMethod } from "../state/NotificationMethod";
import { NotificationType } from "../state/NotificationType";

export interface AudienceInnerWidgetProps {
  callback: (
    segmentIds: string[] | null,
    segmentSummary: string | null,
    changes: string | null,
    matchingSegments?: string[] | null,
  ) => void;
  hideModal?: () => void;
}

interface IQuery {
  id: string;
  query: SaveSegmentV2Input;
  summary: string;
  changes?: string;
}

interface ISegment {
  id: string;
  segmentId?: string;
  defaultOpen: boolean;
}

export const AudienceInnerWidget: React.FC<AudienceInnerWidgetProps> = ({ hideModal, callback }) => {
  const { t, i18n } = useTranslation();
  const { spacers, colors } = useTheme();
  const [saving, setSaving] = useState(false);
  const { site, initialSegmentIds, notificationMethod, notificationType, gatewayEndpoint } =
    useContext(CurrentAudience);
  const [segmentQueries, setSegmentQueries] = useState<IQuery[]>([]);
  const [segments, setSegments] = useState<ISegment[]>(
    initialSegmentIds.map((id) => ({
      id,
      segmentId: id,
      defaultOpen: false,
    })),
  );
  const [estimatedUsers, setEstimatedUsers] = useState(0);

  const [estimateAudienceQuery] = useEstimateAudienceLazyQuery({
    context: {
      uri: gatewayEndpoint,
    },
  });
  const [estimateUserQuery] = useEstimateUserCountLazyQuery({
    context: {
      uri: gatewayEndpoint,
    },
  });
  const [findMatchingSegments] = useFindMatchingSegmentsLazyQuery();

  const [mutation] = useSaveSegmentMutation({
    context: {
      uri: gatewayEndpoint,
    },
  });

  const addNewSegment = () => {
    setSegments((prev) => [...prev, { defaultOpen: true, id: uuidv4() }]);
  };

  const removeSegment = (id: string) => {
    setSegmentQueries((prev) => prev.filter((sq) => sq.id !== id));
    setSegments((prev) => prev.filter((s) => s.id !== id));
  };

  const combinedChanges = useCallback(() => {
    const segmentsWithChanges = segmentQueries.filter((sq) => sq.changes != null);
    if (segmentsWithChanges.length === 0) {
      return null;
    }
    return formatters.list(
      segmentsWithChanges.map((s, i) =>
        t("segmentation.segmentDescription", { id: i + 1, descrption: s.changes ?? "" }),
      ),
      i18n.language,
      { style: "narrow" },
    );
  }, [segmentQueries, t, i18n.language]);

  const combinedSummaries = useCallback(() => {
    return formatters.list(
      segmentQueries.map((s, i) => t("segmentation.segmentDescription", { id: i + 1, descrption: s.summary })),
      i18n.language,
      { style: "narrow" },
    );
  }, [segmentQueries, t, i18n.language]);

  useEffect(() => {
    async function cb() {
      if (segmentQueries.length > 0) {
        const rs = await estimateAudienceQuery({
          variables: {
            input: segmentQueries.map((sq) => sq.query),
          },
        });
        setEstimatedUsers(rs.data?.estimateAudienceUserCount ?? 0);
      } else {
        const rs = await estimateUserQuery({
          variables: {
            input: {
              site: site.destination.uuid,
              filters: [],
              requiresSubscribedToEmails: notificationMethod === NotificationMethod.EMAIL,
              requiresMobileNumber: notificationMethod === NotificationMethod.SMS,
              requiresSubscribedToNotifications: notificationType === NotificationType.REGULAR,
            },
          },
        });
        setEstimatedUsers(rs.data?.userCount ?? 0);
      }
    }

    cb().catch((e) => console.error(e));
  }, [
    estimateAudienceQuery,
    segmentQueries,
    estimateUserQuery,
    site.destination.uuid,
    notificationMethod,
    notificationType,
  ]);

  const onQueryUpdate = useCallback((id: string, query: SaveSegmentV2Input, summary: string, changes?: string) => {
    setSegmentQueries((prev) => {
      // Check if it exists.
      const existing = prev.find((s) => s.id === id);
      if (existing != null) {
        return prev.map((seg) => {
          if (seg.id === id) {
            return {
              ...seg,
              query,
              summary,
              changes,
            };
          } else {
            return seg;
          }
        });
      } else {
        return [...prev, { id, query, summary, changes }];
      }
    });
  }, []);

  const saveAudience = useCallback(async () => {
    setSaving(true);
    // save segments.
    const segmentIds = (
      await Promise.all(
        segmentQueries.map(async (seg) => {
          const rs = await mutation({
            variables: {
              input: seg.query,
            },
          });

          return rs.data?.saveSegmentV2.uuid;
        }),
      )
    ).filter((id): id is string => id != null);
    setSaving(false);

    const matchingSegments = await findMatchingSegments({
      variables: {
        uuids: segmentIds,
        site: site.destination.uuid,
      },
    });

    callback(segmentIds, combinedSummaries(), combinedChanges(), matchingSegments.data?.matchingSegmentsV2);
  }, [
    callback,
    mutation,
    segmentQueries,
    combinedSummaries,
    combinedChanges,
    findMatchingSegments,
    site.destination.uuid,
  ]);

  return (
    <>
      <h1>{t("segmentation.setAudienceTitle", { destinationName: site.destination.name })}</h1>
      {segments.length === 0 && <EmptyAudience />}
      {segments.map((s, i) => {
        return (
          <SegmentProvider key={s.id} id={s.id} segmentId={s.segmentId} onQueryUpdate={onQueryUpdate}>
            <Segment
              defaultOpen={s.defaultOpen}
              remove={removeSegment}
              id={s.id}
              title={t("segmentation.segmentName", { id: i + 1 })}
            />
          </SegmentProvider>
        );
      })}
      <Button
        onClick={addNewSegment}
        variant="outline"
        className="add-segment mb-4"
        style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        <RiAddLine size="18" /> {t("segmentation.addSegment")}
      </Button>
      <div className="footer">
        <div className="total-estimate">
          <RiUserAddLine className="me-1" size="16" />{" "}
          {t("segmentation.estimatedUserCountAudience", { count: estimatedUsers })}{" "}
          <AudienceTooltip notificationMethod={notificationMethod} />
        </div>
        <div>
          <Button type="button" variant="ghost" className="mr-2" disabled={saving} onClick={hideModal}>
            {t("common.cancel")}
          </Button>
          <Button
            type="button"
            className="save-audience"
            disabled={saving}
            onClick={() => {
              void saveAudience();
            }}
          >
            {saving ? t("common.saving") : t("segmentation.saveAudience")}
          </Button>
        </div>
      </div>
      <style jsx>{`
        h1 {
          font-weight: 700;
          margin-bottom: ${spacers.s5};
        }
        .footer {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
        .total-estimate {
          font-weight: 500;
          color: ${colors.medium};
          font-size: 12px;
          line-height: 16px;
          display: flex;
          align-items: center;
        }
      `}</style>
    </>
  );
};
