import { useTranslation } from "@equiem/localisation-eq1";
import React from "react";
import { Summary } from "./Summary";
import { ResourceTypeList } from "./ResourceTypeList";
import { SummaryRightLabel } from "./SummaryRightLabel";

export const ResourceTypeSummary: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Summary
      left={
        <>
          <h3 className="font-weight-bold">{t("bookings.settings.resourceType")}</h3>
          <p>{t("bookings.settings.resourceTypeParagraph1")}</p>
          <p>{t("bookings.settings.resourceTypeParagraph2")}</p>
        </>
      }
      right={
        <>
          <SummaryRightLabel title={t("bookings.settings.resourceType")} />
          <ResourceTypeList />
        </>
      }
    />
  );
};
