import { Card, Table, useTheme } from "@equiem/react-admin-ui";
import React, { useContext, useEffect, useMemo, useState } from "react";
import type { CreditTransaction } from "../../../generated/gateway-client";
import { CreditsTable } from "../CreditsTable/CreditsTable";
import { ActivityContainerTitle } from "./ActivityContainerTitle";
import { ActivityHeader } from "./ActivityHeader";
import { notNullOrUndefined } from "@equiem/lib/notNullOrUndefined";
import { EmptyState } from "../CreditsTable/EmptyState";
import { useTranslation } from "@equiem/localisation-eq1";
import { Modal as ModalContext } from "../../../contexts/ModalContext";
import { CurrentRole, Role } from "@equiem/lib";
import { useCreditDashboardContext } from "../context/CreditDashboardContext";

export const CreditsActivity: React.FC<unknown> = () => {
  const { colors } = useTheme();
  const { t } = useTranslation();
  const modal = useContext(ModalContext);
  const [wasPurchaseCreditsModalOpened, setWasPurchaseCreditsModalOpened] = useState(false);
  const { currentRole } = useContext(CurrentRole);
  const isFlexManager = currentRole === Role.FlexManager;

  const { creditsActivity } = useCreditDashboardContext();
  const creditTransactions = useMemo(
    () => creditsActivity?.data?.creditTransactions.edges.map(({ node }) => node).filter(notNullOrUndefined) ?? [],
    [creditsActivity?.data?.creditTransactions.edges],
  );
  const isLoading = creditsActivity?.loading === true;
  const isViewActivityDisabled = isLoading || (creditsActivity?.data?.creditTransactions.edges.length ?? 0) < 1;

  useEffect(() => {
    // This code refresh credits activity on modal close action because the purchase could have been made.
    if (modal.activeModal === "PurchaseCredits") {
      setWasPurchaseCreditsModalOpened(true);
    } else {
      setWasPurchaseCreditsModalOpened(false);
    }
    if (creditsActivity?.refetch != null && modal.activeModal === undefined && wasPurchaseCreditsModalOpened) {
      void creditsActivity.refetch();
    }
  }, [modal.activeModal, creditsActivity, wasPurchaseCreditsModalOpened]);

  const renderTable = useMemo(() => {
    return (
      <div className="table">
        <Table.Table className="w-100">
          {!isLoading && creditTransactions.length > 0 && (
            <thead>
              <tr>
                <Table.Header label={""} />
                <Table.Header label={t("credits.date")} />
                <Table.Header label={t("credits.type")} />
                {isFlexManager ? (
                  <Table.Header label={t("credits.membership")} />
                ) : (
                  <Table.Header label={t("credits.user")} />
                )}
                <Table.Header label={t("credits.creditMonth")} />
                <Table.Header label={t("credits.amount")} />
                <Table.Header label={t("credits.balance")} />
                <Table.Header label={t("credits.netCost")} />
                <Table.Header label={t("credits.tax")} />
                <Table.Header label={t("credits.total")} />
              </tr>
            </thead>
          )}
          <tbody>
            <CreditsTable
              credits={creditTransactions as CreditTransaction[]}
              loading={isLoading}
              skeletonLoadingItems={10}
            />
          </tbody>
        </Table.Table>
        <style jsx>
          {`
            .table :global(table) {
              border: 0;
              border-radius: 0;
            }
          `}
        </style>
      </div>
    );
  }, [creditTransactions, isLoading, t]);

  return (
    <Card.Card
      style={{
        border: "1px solid #e6e6e6",
        padding: "1rem 0 0 0",
        height: "100%",
      }}
      className="credits-activity-transactions"
    >
      <ActivityHeader />
      <ActivityContainerTitle isViewActivityDisabled={isViewActivityDisabled} />
      <div className="separator" />

      {renderTable}
      {!isLoading && creditTransactions.length === 0 && <EmptyState />}

      <style jsx>
        {`
          .separator {
            height: 1px;
            width: 100%;
            background: ${colors.grayscale[10]};
            margin: 0.75rem 0 0 0;
          }
        `}
      </style>
    </Card.Card>
  );
};
