import React from "react";
import { Button, Dropdown } from "@equiem/react-admin-ui";
import { useTranslation } from "@equiem/localisation-eq1";

import type { Report } from "../utils/ReportParams";

interface Props {
  yardiEnabled: boolean;
  onClick: (report: Report) => unknown;
}

export const GenerateReportButton: React.FC<Props> = ({ yardiEnabled, onClick }) => {
  const { t } = useTranslation();

  if (!yardiEnabled) {
    return (
      <Button className="main-button" size="md" variant="primary" onClick={() => onClick("reconciliation")}>
        {t("bookings.reports.generateCsv")}
      </Button>
    );
  }

  return (
    <Dropdown.Button className="main-button" size="md" variant="primary" title={t("bookings.reports.generateCsv")}>
      <Dropdown.Item onClick={() => onClick("reconciliation")}>
        {t("bookings.reports.reconciliationReport")}
      </Dropdown.Item>
      <Dropdown.Item onClick={() => onClick("yardi")}>{t("bookings.reports.yardiExport")}</Dropdown.Item>
    </Dropdown.Button>
  );
};
