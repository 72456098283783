import React, { useContext, useState, useMemo, useEffect } from "react";

import type { BorderCssProps } from "../useInputBorderCss";
import type { ComponentProps } from "react";
import { FormGroupContext } from "../../../contexts/FormGroupContext";
import { FormInputGroupContext } from "../FormInputGroup/FormInputGroupContext";
import { useInputBorderCss } from "../useInputBorderCss";
import { useInputPadding } from "../useInputPadding";
import { useTheme } from "../../../contexts/Theme";
import { DateTime } from "luxon";
import { useClockIconBackground } from "./useClockIconBackground";
import { useTranslation, formatters } from "@equiem/localisation-eq1";

export interface Time {
  hour: number;
  minute: number;
}

export interface Props extends ComponentProps<"select"> {
  rounded?: BorderCssProps["rounded"];
  diffMinutes: number;
  startTime: Time;
  endTime: Time;
  defaultOptionValue?: string;
  defaultOptionDisabled?: boolean;
  setFieldValue?: (a: string, b: number) => void;
  type?: "create" | "update";
  timeFormat?: string;
  language?: string;
  value?: number;
  placeholder?: string;
}

export const FormTimeSelect: React.FC<Props> = ({
  diffMinutes,
  startTime,
  endTime,
  setFieldValue,
  type,
  defaultOptionValue = "Start time",
  defaultOptionDisabled = true,
  timeFormat = "h:mma",
  language,
  ...props
}) => {
  const { i18n } = useTranslation();
  const [localValue, setLocalValue] = useState<number>(typeof props.value === "number" ? props.value : 0);
  const value = Number(props.value) > 0 ? props.value : localValue;

  const { id, hintId } = useContext(FormGroupContext);
  const { colors } = useTheme();
  const fontColor = value === 0 ? colors.muted1 : "inherit";
  const dropdownBg = useClockIconBackground({
    color: value === 0 ? colors.muted1 : "#333",
  });
  const borderCss = useInputBorderCss({ rounded: props.rounded });
  const padding = useInputPadding({ right: "32px" });
  const { hasSuffix } = useContext(FormInputGroupContext);

  const options = useMemo(() => {
    let start = DateTime.now();
    let end = DateTime.now();

    start = start.set({
      hour: startTime.hour,
      minute: startTime.minute,
      second: 0,
      millisecond: 0,
    });
    end = end.set({
      hour: endTime.hour,
      minute: endTime.minute,
      second: 0,
      millisecond: 0,
    });

    const res: number[] = [];

    while (start.toSeconds() < end.toSeconds()) {
      res.push(start.toSeconds());

      // fail safe so there's no way we can trigger an infinite loop even with horrifically bad input
      start = start.plus({
        minutes: Math.max(Math.floor(diffMinutes), 1),
      });
    }
    res.push(end.toSeconds());

    return res;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startTime.hour, startTime.minute, endTime.hour, endTime.minute, diffMinutes, timeFormat]);

  useEffect(() => {
    if (type === "create" && setFieldValue != null && props.value === 0) {
      const currentTime = DateTime.local().toSeconds();

      const closestTime = options.reduce((a, b) => {
        return Math.abs(a - currentTime) < Math.abs(b - currentTime) ? a : b;
      });
      let closest = 0;
      closest = closestTime;
      if (closestTime <= currentTime && closestTime !== options[options.length - 1]) {
        closest = DateTime.fromSeconds(closestTime).plus({ minutes: 15 }).toSeconds();
      }
      setFieldValue("startTime", closest);
      setLocalValue(closest);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);

  const localisedOptions = useMemo(
    () =>
      options.map((time) => {
        return (
          <option value={time} key={time}>
            {formatters.timeshort(DateTime.fromSeconds(time), language ?? i18n.language)}
          </option>
        );
      }),
    [language, options, i18n.language],
  );

  return (
    <>
      <select
        {...props}
        id={props.id ?? id}
        aria-describedby={hintId}
        className={`${props.className ?? ""} ${borderCss.className}`}
        onChange={(e) => {
          setLocalValue(Number(e.target.value));
          if (props.onChange != null) {
            props.onChange(e);
          }
        }}
      >
        <option value={0} disabled={defaultOptionDisabled}>
          {defaultOptionValue}
        </option>
        {localisedOptions}
      </select>
      <style jsx>{`
        select {
          line-height: 22px;
          padding: ${padding.shorthand};
          appearance: none;
          display: inline-block;
          cursor: pointer;
          float: none;
          outline: none;
          width: 100%;
          font-size: 14px;
          color: ${fontColor};
          background: ${dropdownBg} right ${hasSuffix ? "0" : "11px"} center/16px 16px no-repeat;
          text-overflow: ellipsis;
        }
        select :global(option) {
          color: #333;
        }
        select:first-child {
          padding-top: ${padding.top};
        }
        select:last-child {
          padding-bottom: ${padding.bottom};
        }
      `}</style>
      {borderCss.styles}
    </>
  );
};
