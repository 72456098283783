import React from "react";
import { RiArrowDownSLine } from "react-icons/ri";
import { AdminButton as Button } from "../../Button/AdminButton";

interface P {
  className?: string;
  size?: "small" | "large";
  placeholder?: string | React.ReactNode | null;
}
export const FormFacadeButtonGhost: React.FC<P> = ({ className, size, placeholder }) => {
  return (
    <Button
      className={`${className} text-uppercase`}
      type="button"
      variant="ghost"
      size={size === "large" ? "lg" : "sm"}
    >
      {placeholder} <RiArrowDownSLine size={16} />
    </Button>
  );
};
