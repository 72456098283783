"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useTranslationGeneric = void 0;
var react_1 = require("react");
var react_i18next_1 = require("react-i18next");
var getSafeTOptions_1 = require("./getSafeTOptions");
function useTranslationGeneric(namespace, options) {
    var _a = (0, react_i18next_1.useTranslation)(namespace, options), t = _a.t, i18n = _a.i18n, ready = _a.ready;
    var tWrapped = (0, react_1.useCallback)(function (key, variables) { return t(key, (0, getSafeTOptions_1.getSafeTOptions)(variables)); }, [t]);
    return {
        t: tWrapped,
        i18n: i18n,
        ready: ready,
    };
}
exports.useTranslationGeneric = useTranslationGeneric;
