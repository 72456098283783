import { useToast } from "@equiem/react-admin-ui";
import React, { useCallback } from "react";
import type { BookableResourceFeatureType } from "../../../../generated/gateway-client";
import { useDeleteResourceFeatureMutation } from "../../../../generated/gateway-client";
import { DeleteItem } from "./DeleteItem";
import type { Item } from "./helpers/Item";

interface P {
  item: Item;
  disable: boolean;
  featureType: BookableResourceFeatureType;
  refetch: () => Promise<void>;
}
export const ResourceFeatureDelete: React.FC<P> = ({ refetch, item, featureType, disable }) => {
  const [deleteRoomConfig, { loading }] = useDeleteResourceFeatureMutation({
    variables: { uuid: item.uuid, featureType },
  });
  const toast = useToast();
  const disableIt = loading || disable;

  const onDelete = useCallback(async () => {
    if (loading) {
      return;
    }

    try {
      await deleteRoomConfig();
      await refetch();
    } catch (e: unknown) {
      toast.negative(e instanceof Error ? e.message : "Unknown error", { autoDismiss: true });
    }
  }, [deleteRoomConfig, loading, refetch, toast]);

  return <DeleteItem title={item.name} disable={disableIt} onDelete={onDelete} loading={loading} />;
};
