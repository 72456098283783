import type { FC, PropsWithChildren, ReactElement } from "react";
import React, { useContext } from "react";

import type { Props as DropdownProps } from "./Dropdown";
import { Dropdown, DropdownContext } from "./Dropdown";
import type { DropdownMobileView } from "./DropdownMenu/DropdownMenu";
import { DropdownMenu } from "./DropdownMenu/DropdownMenu";
import { useTheme } from "../../contexts/Theme";

export type Props = DropdownProps & {
  trigger: React.ReactNode;
  fixedHeader?: ReactElement;
  width?: number;
  maxHeight?: number;
  mobileView?: DropdownMobileView;
  title?: string;
  disabled?: boolean;
  disableKeyboard?: boolean;
};

// eslint-disable-next-line @typescript-eslint/no-type-alias
export type DropdownContainerProps = PropsWithChildren<Props>;

const Inner: FC<Props> = ({ trigger, disableKeyboard, disabled }) => {
  const dropdown = useContext(DropdownContext);

  const handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (disabled ?? false) {
      return;
    }

    dropdown.setShow(!dropdown.show);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (disableKeyboard !== true) {
      switch (event.key.toLowerCase()) {
        case " ":
        case "Enter":
          event.preventDefault();
          dropdown.setShow(!dropdown.show);
          break;
        default:
          break;
      }
    }
  };

  return (
    <>
      <div ref={dropdown.reference} onClick={handleClick} onKeyDown={handleKeyDown}>
        {trigger}
      </div>
    </>
  );
};

export const DropdownContainer: React.FC<DropdownContainerProps> = ({
  children,
  placement = "bottom-start",
  onSelect,
  onClose,
  fontWeight,
  fixedHeader,
  width,
  mobileView,
  title,
  sameWidth,
  maxHeight,
  ...props
}) => {
  const { isAdmin } = useTheme();

  return (
    <>
      <Dropdown
        placement={placement}
        onSelect={onSelect}
        fontWeight={fontWeight ?? (isAdmin ? "heavy" : "light")}
        sameWidth={sameWidth}
        onClose={onClose}
        flip
      >
        <Inner {...props} />
        {children != null && (
          <DropdownMenu
            fixedHeader={fixedHeader}
            width={width}
            maxHeight={maxHeight}
            mobileView={mobileView}
            title={title}
          >
            {children}
          </DropdownMenu>
        )}
      </Dropdown>
    </>
  );
};

DropdownContainer.displayName = "DropdownContainer";
