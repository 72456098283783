import { IconButton, useTheme } from "@equiem/react-admin-ui";
import { RiArrowLeftSLine, RiArrowRightSLine } from "@equiem/react-admin-ui/icons";
import React, { useState, useRef, useEffect } from "react";
import { Swiper, Navigation } from "swiper";
import "swiper/css";
import { contrastsWithWhite } from "../contrastsWithWhite";

const NAV_BUTTON_SIZE = 32;
const NAV_BUTTON_GAP = 4;
const NAV_CONTAINER_MARGIN = 16;

const NAV_WIDTH = NAV_BUTTON_SIZE + NAV_BUTTON_GAP + NAV_BUTTON_SIZE;
const NAV_HEIGHT = NAV_BUTTON_SIZE;

const TRANSITION_MS = 300;

const clampChannel = (value: number) => {
  const rounded = Math.round(value);
  if (rounded < 0) {
    return 0;
  }
  if (rounded > 255) {
    return 255;
  }
  return rounded;
};

const getNavButtonTheme = (img: HTMLImageElement | null | undefined): "light" | "dark" => {
  try {
    if (img == null) {
      return "light";
    }

    const { width, height } = img;
    const canvas = document.createElement("canvas");
    canvas.width = width;
    canvas.height = height;

    const ctx = canvas.getContext("2d");
    if (ctx == null) {
      return "light";
    }
    ctx.drawImage(img, 0, 0);

    const navX = width - NAV_WIDTH - NAV_CONTAINER_MARGIN;
    const navY = height - NAV_HEIGHT - NAV_CONTAINER_MARGIN;
    const data = ctx.getImageData(navX, navY, NAV_WIDTH, NAV_HEIGHT);

    // data array is interleaved [r, g, b, a, ...etc.] values
    const numPixels = data.data.length / 4;
    const averageColor = data.data.reduce(
      (acc, curr, i) => {
        switch (i % 4) {
          case 0: // red channel
            return { ...acc, r: acc.r + curr / numPixels };
          case 1: // green channel
            return { ...acc, g: acc.g + curr / numPixels };
          case 2: // blue channel
            return { ...acc, b: acc.b + curr / numPixels };
          default: // alpha channel
            return acc;
        }
      },
      { r: 0, g: 0, b: 0 },
    );

    const rHex = clampChannel(averageColor.r).toString(16).padStart(2, "0");
    const gHex = clampChannel(averageColor.g).toString(16).padStart(2, "0");
    const bHex = clampChannel(averageColor.b).toString(16).padStart(2, "0");
    const averageColorHex = `#${rHex}${gHex}${bHex}`;

    return contrastsWithWhite(averageColorHex) ? "light" : "dark";
  } catch (e: unknown) {
    console.error("Failed to get nav button theme:", e);
    return "light";
  }
};

export const ImageCarousel: React.FC<{ className?: string; images: string[] }> = ({ className, images }) => {
  const { colors, borderRadius } = useTheme();

  const containerRef = useRef<HTMLDivElement>(null);
  const [swiper, setSwiper] = useState<Swiper | null>(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const [buttonTheme, setButtonTheme] = useState(getNavButtonTheme(null));
  useEffect(() => {
    if (containerRef.current == null) {
      setSwiper(null);
      return () => undefined;
    }

    const instance = new Swiper(containerRef.current, {
      modules: [Navigation],
      loop: true,
      simulateTouch: false,
    });
    setSwiper(instance);

    return () => instance.destroy(true, true);
  }, []);
  useEffect(() => {
    swiper?.update();
    swiper?.slideTo(0, TRANSITION_MS);
    setActiveIndex(0);
  }, [swiper, images]);
  useEffect(() => {
    const activeImage = containerRef.current?.querySelector<HTMLImageElement>(
      `img[data-swiper-index="${activeIndex}"]`,
    );

    const applyTheme = () => setButtonTheme(getNavButtonTheme(activeImage));

    // handle loaded (or missing) image
    applyTheme();

    // handle partially loaded image
    activeImage?.addEventListener("load", applyTheme);
    return () => activeImage?.removeEventListener("load", applyTheme);
  }, [activeIndex]);

  // swiper is bad at tracking its active index when `loop: true`...
  const handleNext = () => {
    const next = (activeIndex + 1) % images.length;
    swiper?.slideTo(next, TRANSITION_MS);
    setActiveIndex(next);
  };
  const handlePrev = () => {
    const prev = (activeIndex - 1 + images.length) % images.length;
    swiper?.slideTo(prev, TRANSITION_MS);
    setActiveIndex(prev);
  };

  return (
    <>
      <div ref={containerRef} className={`carousel swiper ${className ?? ""}`}>
        <div className="swiper-wrapper">
          {images.map((image, i) => (
            <div key={i} className="swiper-slide">
              <img src={image} crossOrigin="anonymous" data-swiper-index={i} />
            </div>
          ))}
        </div>
        {images.length > 1 && (
          <div className="swiper-navigation">
            <IconButton
              className={`swiper-button swiper-button-${buttonTheme} swiper-button-prev`}
              onClick={handlePrev}
            >
              <RiArrowLeftSLine size={16} />
            </IconButton>
            <IconButton
              className={`swiper-button swiper-button-${buttonTheme} swiper-button-next`}
              onClick={handleNext}
            >
              <RiArrowRightSLine size={16} />
            </IconButton>
          </div>
        )}
      </div>
      <style jsx>{`
        .swiper {
          position: relative;
          max-width: 100%;
          border-radius: ${borderRadius};
          aspect-ratio: 3 / 2;
          background: ${colors.grayscale[20]};
        }
        .swiper-wrapper {
          max-width: 1vw;
        }
        img {
          width: 100%;
          height: 100%;
          object-position: center;
          object-fit: cover;
        }
        .swiper-navigation {
          display: flex;
          gap: ${NAV_BUTTON_GAP}px;
          position: absolute;
          right: ${NAV_CONTAINER_MARGIN}px;
          bottom: ${NAV_CONTAINER_MARGIN}px;
          z-index: 1;
        }
        .swiper-navigation :global(.swiper-button) {
          height: ${NAV_BUTTON_SIZE}px;
          width: ${NAV_BUTTON_SIZE}px;
          backdrop-filter: blur(200px);
          transition: ease-in-out ${TRANSITION_MS}ms;
          color: white;
          background: ${colors.transparent.white[25]};
        }
        .swiper-navigation :global(.swiper-button:hover) {
          background: ${colors.transparent.white[15]};
        }
        .swiper-navigation :global(.swiper-button-dark) {
          color: black;
          background: ${colors.transparent.black[20]};
        }
        .swiper-navigation :global(.swiper-button-dark:hover) {
          background: ${colors.transparent.black[10]};
        }
      `}</style>
    </>
  );
};
