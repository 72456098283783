import { useState, useEffect } from "react";
import { useTranslation } from "@equiem/localisation-eq1";

interface HookResult {
  ready: boolean;
  localization: object | undefined;
}

const LOADING: HookResult = { ready: false, localization: undefined };

const getStrings = async (language: string) => {
  switch (language.split(/-_/u)[0]) {
    case "de":
      return import("react-phone-input-2/lang/de.json");
    case "es":
      return import("react-phone-input-2/lang/es.json");
    case "fr":
      return import("react-phone-input-2/lang/fr.json");
    case "pl":
      return import("react-phone-input-2/lang/pl.json");
    case "hu":
      return import("react-phone-input-2/lang/hu.json");
    case "ja":
      return import("react-phone-input-2/lang/jp.json");

    // unsupported, fall back to English:
    case "nl":
      return Promise.resolve(undefined);
    case "sk":
      return Promise.resolve(undefined);
    case "cs":
      return Promise.resolve(undefined);

    // not in use by us, but available in the library:
    case "ru":
      return import("react-phone-input-2/lang/ru.json");
    case "cn":
      return import("react-phone-input-2/lang/cn.json");
    case "pt":
      return import("react-phone-input-2/lang/pt.json");
    case "it":
      return import("react-phone-input-2/lang/it.json");
    case "ir":
      return import("react-phone-input-2/lang/ir.json");
    case "ar":
      return import("react-phone-input-2/lang/ar.json");
    case "tr":
      return import("react-phone-input-2/lang/tr.json");
    case "id":
      return import("react-phone-input-2/lang/id.json");
    case "ko":
      return import("react-phone-input-2/lang/ko.json");

    case "en":
    default:
      return Promise.resolve(undefined);
  }
};

/**
 * react-phone-input-2 does not support changes to the `localization` prop.
 *
 * Load its translations, ensuring at least one render happens betwen when the
 * locale changes and when the new translations are loaded, so the component
 * gets unmounted.
 */
export const usePhoneNumberLocalization = (): HookResult => {
  const { i18n } = useTranslation();

  const [locale, setLocale] = useState<HookResult>(LOADING);
  const [hasRenderedSinceLocaleChange, setHasRenderedSinceLocaleChange] = useState(true);

  useEffect(() => {
    let active = true;

    setLocale(LOADING);
    setHasRenderedSinceLocaleChange(false);
    getStrings(i18n.language)
      .then((newStrings) => {
        if (active) {
          setLocale({ ready: true, localization: newStrings });
        }
      })
      .catch((e) => {
        // eslint-disable-next-line no-console
        console.error(e);
        if (active) {
          setLocale({ ready: true, localization: undefined });
        }
      });

    return () => {
      active = false;
    };
  }, [i18n.language]);

  useEffect(() => {
    if (!hasRenderedSinceLocaleChange) {
      setHasRenderedSinceLocaleChange(true);
    }
  }, [hasRenderedSinceLocaleChange]);

  return hasRenderedSinceLocaleChange ? locale : LOADING;
};
