import React from "react";
import { Tooltip, useTheme } from "@equiem/react-admin-ui";
import { formatters, useTranslation } from "@equiem/localisation-eq1";
import type { CreditTransactionDetail } from "../../../generated/gateway-client";
import { getTranscationDetailsByGroup } from "../utils/getLatestDate";
import { DateTime } from "luxon";
import { convertCents } from "../utils/convertCents";

export const CreditsAmountTransactionDetails = ({
  transactionDetails,
  amount,
}: {
  transactionDetails?: CreditTransactionDetail[];
  amount: number;
}) => {
  const { t, i18n } = useTranslation();
  const { colors } = useTheme();
  const transactionDetailsByGroup =
    transactionDetails != null && transactionDetails.length > 0
      ? getTranscationDetailsByGroup(transactionDetails)
      : undefined;

  return (
    <>
      <div className="amount-wrapper">
        <Tooltip
          placement="bottom-start"
          variant="advanced"
          description={
            <div className="additional-information">
              <div className="details-container">
                {transactionDetailsByGroup?.latest != null && (
                  <div className="details-row">
                    <span className="name">
                      {formatters.datemonth(DateTime.fromMillis(transactionDetailsByGroup.latest.date), i18n.language)}
                    </span>
                    <span className="value">{convertCents(transactionDetailsByGroup.latest.amount)}</span>
                  </div>
                )}
                {transactionDetailsByGroup?.additional != null && (
                  <div className="details-row">
                    <span className="name">{t("credits.additionalCredits")}</span>
                    <span className="value">{convertCents(transactionDetailsByGroup.additional.amount)}</span>
                  </div>
                )}
              </div>
            </div>
          }
        >
          <span className={`amount-with-sign ${amount > 0 ? "greened" : ""}`}>
            <b>{amount > 0 ? "+" : "-"}</b>
            <b>{Math.abs(amount)}</b>
          </span>
        </Tooltip>
      </div>
      <style jsx>{`
        .amount-with-sign > b:first-child {
          letter-spacing: 2px;
        }

        .amount-wrapper :global(.tooltip) {
          background: white;
          border-radius: 4px;
          border: 1px solid ${colors.grayscale[10]};
          box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
        }

        .amount-wrapper :global(.tooltip--advanced) {
          max-width: 300px;
          width: max-content;
          min-width: 200px;
        }

        .details-container {
          display: flex;
          flex-direction: column;
          gap: 12px;
        }

        .details-row {
          color: ${colors.grayscale[100]};
          display: flex;
          justify-content: space-between;
          gap: 8px;
        }

        .details-row .name {
          overflow: hidden;
          color: ${colors.grayscale[60]};
          text-overflow: ellipsis;
          font-size: 12px;
          font-weight: 500;
          line-height: 16px;
          letter-spacing: 0.24px;
          text-transform: uppercase;
        }

        .details-row .value {
          overflow: hidden;
          font-family: Roboto;
          font-size: 14px;
          font-weight: 400;
          line-height: 16px;
        }

        .amount-wrapper :global(.title),
        .amount-wrapper :global(.arrow) {
          display: none;
        }

        .amount-with-sign {
          cursor: pointer;
        }

        .amount-with-sign:hover .additional-information {
          display: flex;
        }

        .greened {
          color: #008000;
        }
      `}</style>
    </>
  );
};
