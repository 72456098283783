import React, { useCallback, useContext } from "react";

import { useTheme } from "../../../contexts/Theme";
import { IframeResizerContext } from "../../../contexts/IframePageInfoContext";
import type { KeyboardEvent } from "react";
import { ModalContext } from "../ModalContext";
import { useFloatingInIframe } from "../../../hooks/useFloatingInIframe";

export interface Props {
  className?: string;
  containerClassName?: string;
  centered?: boolean;
  hideOnEsc?: boolean;
  hideOnClick?: boolean;
  supportsMobile?: boolean;
  zIndex?: number;
}

export const ModalInner = React.forwardRef<HTMLDivElement, React.PropsWithChildren<Props>>(
  (
    {
      children,
      className,
      containerClassName,
      centered = false,
      hideOnEsc = true,
      hideOnClick = true,
      supportsMobile = false,
      zIndex,
    },
    ref,
  ) => {
    const {
      colors,
      spacers: { s7, s5 },
    } = useTheme();
    const { title, scrollable, onHide, show, size } = useContext(ModalContext);
    const { inIframe } = useContext(IframeResizerContext);
    const { zIndexes, breakpoints } = useTheme();

    const onKeyDown = useCallback(
      (e: KeyboardEvent<HTMLDivElement>) => {
        if (e.key === "Escape") {
          e.stopPropagation();
          if (hideOnEsc) {
            onHide();
          }
        }
      },
      [hideOnEsc, onHide],
    );

    const { position, top, left, floating } = useFloatingInIframe({
      visible: show,
      fixedSize: false,
      placement: centered ? "center" : "top",
      pinToInitialPosition: true,
      offset: 30,
    });

    return (
      <div
        onClick={hideOnClick ? onHide : undefined}
        onKeyDown={onKeyDown}
        ref={ref}
        className={`${containerClassName} ${supportsMobile && "modal-container--mobile"}`}
      >
        <section
          role="dialog"
          aria-modal="true"
          aria-label={title}
          className={`${className} ${supportsMobile && "modal--mobile"}`}
          ref={floating.floating}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          {children}
        </section>
        <style jsx>{`
          div {
            position: fixed;
            top: 0;
            left: 0;
            display: flex;
            width: 100%;
            height: 100%;
            padding: ${s7} ${s5};
            justify-content: center;
            align-items: ${centered ? "center" : "flex-start"};
            overflow: ${scrollable || inIframe ? "none" : "auto"};
            z-index: ${zIndex ?? zIndexes.modal};
            box-sizing: border-box;
          }

          div :global(*) {
            box-sizing: border-box;
          }

          section {
            position: ${position ?? "static"};
            top: ${top ?? "0"};
            left: ${left ?? "0"};
            display: flex;
            pointer-events: all;
            flex-direction: column;
            justify-content: flex-end;
            background: #fff;
            border: 1px solid ${colors.border};
            border-radius: 8px;
            max-width: 90%;
            max-height: ${scrollable && !inIframe ? "100%" : "none"};
            width: ${
              {
                xs: "300px",
                sm: "360px",
                md: "500px",
                lg: "800px",
                xl: "1000px",
                auto: "auto",
              }[size ?? "auto"]
            };
          }

          @media (max-width: ${breakpoints.md}px) {
            .modal-container--mobile {
              padding: 0;
              align-items: flex-end;
            }

            .modal--mobile {
              max-width: 100%;
              width: 100%;
              border-bottom-left-radius: 0;
              border-bottom-right-radius: 0;
            }
        `}</style>
      </div>
    );
  },
);

ModalInner.displayName = "ModalInner";
