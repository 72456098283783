import React from "react";

import { useReceptionForm } from "./hooks/useReceptionForm";

export type ReceptionFormContext = ReturnType<typeof useReceptionForm>;

export const ReceptionFormContext = React.createContext<ReceptionFormContext>({
  buildings: [],
  buildingsLoading: true,
  companies: [],
  companiesLoading: true,
  levels: [],
  reception: undefined,
  receptionLoading: true,
  selectedBuilding: undefined,
  selectedBuildingLevel: undefined,
  selectedCompany: undefined,
  searchUsers: [],
  searchUsersLoading: false,
  searchUsersRecipients: [],
  searchUsersLoadingRecipients: false,
  barrierControlAccesses: [],
  barrierControlAccessesLoading: true,
});

export const ReceptionFormProvider: React.FC<
  {
    children?: React.ReactNode;
  } & Parameters<typeof useReceptionForm>[0]
> = ({ children, ...args }) => {
  const props = useReceptionForm(args);

  return <ReceptionFormContext.Provider value={props}>{children}</ReceptionFormContext.Provider>;
};
