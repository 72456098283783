import { useEffect, useState } from "react";

import type { CardFragmentFragment as Card } from "../../../generated/settings-client";
import {
  CardFragmentFragmentDoc,
  useSetDefaultCardMutation as useBaseSetDefaultCardMutation,
} from "../../../generated/settings-client";

type HookReturn = ReturnType<typeof useBaseSetDefaultCardMutation>;
type MutationFn = HookReturn[0];

/**
 * A better version of the `useSetDefaultCardMutation` hook generated by
 * codegen, working around some cache delay issues.
 *
 * - Updates the cache to unset `default` on the old default card.
 * - Doesn't unset the `loading` flag until the card list is *actually* updated
 *   in the list.
 */
export const useSetDefaultCardMutation = (cards: Card[]): HookReturn => {
  const [mutation, { loading, client, ...rest }] = useBaseSetDefaultCardMutation();

  const defaultCard = cards.find((card) => card.default);
  const [newDefaultCard, setNewDefaultCard] = useState<string | null>(null);
  const waitingForCacheUpdate = newDefaultCard != null && newDefaultCard !== defaultCard?.uuid;

  useEffect(() => {
    if (!waitingForCacheUpdate && newDefaultCard != null) {
      setNewDefaultCard(null);
    }
  }, [newDefaultCard, waitingForCacheUpdate]);

  const wrappedMutation: MutationFn = async (options, ...args) => {
    try {
      const oldDefaultCard = defaultCard;
      setNewDefaultCard(options?.variables?.uuid ?? null);

      const result = await mutation(options, ...args);

      if (oldDefaultCard != null) {
        client.writeFragment<Card>({
          fragment: CardFragmentFragmentDoc,
          data: { __typename: "Card", ...oldDefaultCard, default: false },
        });
      }

      return result;
    } catch (e: unknown) {
      setNewDefaultCard(null);
      throw e;
    }
  };

  return [wrappedMutation, { ...rest, client, loading: loading || waitingForCacheUpdate }];
};
