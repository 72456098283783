import type { FC } from "react";
import { useContext, useEffect, useMemo, useState } from "react";
import { Dropdown, useToast, Button, useTheme } from "@equiem/react-admin-ui";
import { RiNotification3Line } from "@equiem/react-admin-ui/icons";
import {
  AdminNotificationCountDocument,
  useAdminNotificationCountLazyQuery,
  useDeleteAllAdminNotificationsForCurrentUserMutation,
} from "../../generated/gateway-client";
import { BadgeIcon } from "./BadgeIcon";
import { NotificationsInner } from "./NotificationsInner";
import { CurrentProfile } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";

export const NotificationDropdown: FC = () => {
  const [query, data] = useAdminNotificationCountLazyQuery({
    fetchPolicy: "network-only",
  });
  const [deleteMutation] = useDeleteAllAdminNotificationsForCurrentUserMutation();
  const toast = useToast();
  const { t } = useTranslation();
  const { profile } = useContext(CurrentProfile);
  const [clearedAllNotifications, setClearedAllNotifications] = useState(false);
  const [hasNotifications, setHasNotifications] = useState(false);
  const showClearAll = useMemo(
    () => hasNotifications && !clearedAllNotifications,
    [hasNotifications, clearedAllNotifications],
  );
  const { colors } = useTheme();
  const FIVE_SECONDS = 5000;
  const TEN_SECONDS = 10000;

  const onClearAll = () => {
    if (profile == null) {
      return;
    }

    setClearedAllNotifications(true);

    const timeout = setTimeout(async () => {
      await deleteMutation({
        refetchQueries: [AdminNotificationCountDocument],
      });
    }, FIVE_SECONDS);
    const deletionToast = toast.neutral(t("home.allNotificationsCleared"), {
      secondaryAction: {
        title: t("common.undo"),
        onClick: () => {
          clearTimeout(timeout);
          setClearedAllNotifications(false);
          deletionToast.remove();
        },
      },
    });
  };

  useEffect(() => {
    const int = setInterval(() => {
      query().catch(() => {
        console.error("Failed to fetch notifications");
      });
    }, TEN_SECONDS);

    return () => clearInterval(int);
  }, [query]);

  return (
    <span style={{ position: "relative" }}>
      <BadgeIcon
        className="notificationsCount"
        count={data.data?.adminNotifications.unreadCount ?? 0}
        style={{ position: "absolute", top: "-14px", left: "18px", zIndex: 1 }}
      />
      <Dropdown.Icon
        icon={RiNotification3Line}
        placement="bottom-end"
        className="notificationsIcon"
        width={336}
        color={colors.grayscale[60]}
        maxHeight={500}
      >
        <Dropdown.Header>
          <div className="d-flex justify-content-between align-items-center">
            <div style={{ padding: "4px 0px" }}>{t("home.notifications")}</div>
            <Button
              size="sm"
              variant="ghost"
              style={{
                fontWeight: "500",
                fontSize: 10,
                marginRight: 0,
              }}
              onClick={onClearAll}
              disabled={!showClearAll}
            >
              {t("home.clearAllNotifications")}
            </Button>
          </div>
        </Dropdown.Header>
        <NotificationsInner
          clearedAllNotifications={clearedAllNotifications}
          setHasNotifications={setHasNotifications}
        />
      </Dropdown.Icon>
    </span>
  );
};
