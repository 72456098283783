import { createContext } from "react";

export type SideModalTab = "sites" | "publishing" | "publishSettings";

export interface SideModalContext {
  openTab: (tab: SideModalTab) => void;
  closeModal: () => void;
  currentTab: SideModalTab;
}

export const SideModalContext = createContext<SideModalContext>({
  openTab: () => ({}),
  closeModal: () => ({}),
  currentTab: "sites",
});
