/* eslint-disable complexity */
import React, { useContext, useEffect } from "react";
import { ModalContext } from "../ModalContext";
import { SrText } from "../../SrText/SrText";
import { useTheme } from "../../../contexts/Theme";
import { RiArrowLeftLine, RiCloseFill } from "../../../admin-icons";

export const ModalHeader: React.FC<
  React.PropsWithChildren<{
    closeButton?: boolean;
    onClose?: () => void;
    backButton?: boolean;
    noTitle?: boolean;
    noBorder?: boolean;
    intro?: string | null;
    supportsMobile?: boolean;
  }>
> = ({
  children,
  closeButton = false,
  backButton = false,
  noBorder,
  noTitle = false,
  intro,
  supportsMobile = false,
  onClose,
}) => {
  const { onHide, title, headerBorder, setHeaderBorder, stickyHeader, scrollable, size, onBack } =
    useContext(ModalContext);
  const { isAdmin, colors, breakpoints } = useTheme();

  useEffect(() => {
    if (noBorder != null) {
      setHeaderBorder(!noBorder);
    }
  }, [noBorder, setHeaderBorder]);

  function getMarginStyle() {
    if (stickyHeader) {
      return "0.5rem 0";
    }

    let bottomMargin = "0px";
    if ((intro?.length ?? 0) === 0) {
      bottomMargin = headerBorder ? "1rem" : "0.5rem";
    } else if (!closeButton && !backButton) {
      bottomMargin = "0.25rem";
    }

    return `1.25rem 0 ${bottomMargin}`;
  }

  return (
    <div
      style={{ zIndex: 1 }}
      className={`mb-2 header ${headerBorder ? "with-border" : ""} ${
        isAdmin && scrollable && stickyHeader ? "sticky" : ""
      }`}
    >
      <div className={`title-row ${supportsMobile ? "title-row--mobile" : ""}`} style={{ margin: getMarginStyle() }}>
        {!backButton ? null : (
          <button
            className="back ml-n3"
            onClick={() => {
              if (onBack != null) {
                onBack();
              }
            }}
          >
            <span aria-hidden="true">
              <RiArrowLeftLine size={24} color={colors.grayscale[60]} />
            </span>
            <SrText>Back</SrText>
          </button>
        )}
        <h1 className={`title ${supportsMobile && "title--mobile"}`}>{noTitle ? "" : title}</h1>
        {!closeButton && backButton && <span style={{ width: 24 }}></span>}
        {!closeButton ? null : (
          <button className="close mr-n3" onClick={onClose ?? onHide}>
            <span aria-hidden="true">
              <RiCloseFill size={24} color={colors.grayscale[60]} />
            </span>
            <SrText>Close</SrText>
          </button>
        )}
      </div>
      {intro != null && intro.length > 0 && <h2 className="intro">{intro}</h2>}
      {children}
      <style jsx>{`
        .header {
          transition: all 300ms;
          padding-left: 24px;
          padding-right: 24px;
        }
        .header.sticky {
          box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.1);
        }
        .header.with-border {
          border-bottom: 1px solid ${colors.border};
        }
        .title-row {
          display: flex;
          justify-content: space-between;
          align-items: ${isAdmin ? "center" : "flex-start"};
          transition: all 300ms !important;
          position: relative;
        }
        .title {
          margin: 0;
          margin-top: ${!closeButton && !backButton ? "0.5rem" : "0"};
          text-transform: ${isAdmin ? "uppercase" : "none"};
          font-weight: ${isAdmin ? "500" : "normal"};
          font-size: ${isAdmin ? "12px" : "inherit"};
          line-height: ${isAdmin ? "16px" : "inherit"};
          color: ${isAdmin ? colors.medium : colors.dark};
          word-break: break-word;
        }
        .close,
        .back {
          color: ${isAdmin ? colors.medium : colors.dark};
          line-height: 10px;
          font-size: ${isAdmin ? "24px" : "inherit"};
          width: ${isAdmin ? "32px" : "inherit"};
          height: ${isAdmin ? "32px" : "inherit"};
          margin-bottom: -0.25rem;

          &:hover {
            background: rgba(0, 0, 0, 0.05);
            border-radius: 50%;
          }
        }
        button {
          font-size: 1.5rem;
          line-height: 1;
          font-weight: normal;
          border: 0;
          background: none;
          cursor: pointer;
          padding: 0;
          margin: 0;
        }
        .intro {
          font-weight: 700;
          font-size: ${size === "sm" ? "1.125rem" : "1.5rem"};
          line-height: ${size === "sm" ? "1.125rem" : "1.75rem"};
          margin: 0.5rem 0 ${stickyHeader ? 0 : size === "sm" ? "0.75rem" : "1rem"} 0;
          transition: all 300ms !important, opacity 0ms !important;
          transform: ${stickyHeader ? "translateY(-1rem)" : "none"};
          opacity: ${stickyHeader ? 0 : 1};
          height: ${stickyHeader ? 0 : "auto"};
          text-align: ${backButton ? "center" : "inherit"};
        }
        @media (max-width: ${breakpoints.md}px) {
          .title--mobile {
            position: absolute;
            right: 0;
            left: 0;
            width: 100%;
            text-align: center;
            z-index: -1;
          }
          .title-row--mobile {
            justify-content: ${closeButton ? "flex-end" : "flex-start"};
          }
        }
      `}</style>
    </div>
  );
};
