import type { AuthenticationContext, TransformedSite } from "@equiem/lib";
import { useEffect, useState } from "react";
import { parseJwt } from "../lib/jwt";

export const useSiteFromClientSideUuid = ({
  skip,
  authState,
  authenticate,
}: {
  skip: boolean;
  authState: AuthenticationContext;
  authenticate: () => Promise<unknown>;
}) => {
  const [loading, setLoading] = useState(!skip);
  const [site, setSite] = useState<TransformedSite | null>(null);

  const documentAvailable = typeof document !== "undefined";
  useEffect(() => {
    if (documentAvailable && !skip) {
      let clientSideSiteUuid: string | undefined = undefined;
      try {
        if (authState.accessToken != null) {
          const parsed = parseJwt(authState.accessToken);
          clientSideSiteUuid = parsed?.["http://getequiem.com/portal"];
        }
      } catch (e: unknown) {
        console.error(e);
      }

      setLoading(true);
      if (clientSideSiteUuid != null) {
        fetch(`/api/site?uuid=${clientSideSiteUuid}`)
          .then(async (r) => r.json())
          .then((result) => {
            setSite(result as TransformedSite);
          })
          .finally(() => {
            setLoading(false);
          });
      } else {
        void authenticate().finally(() => {
          setLoading(false);
        });
      }
    }
  }, [documentAvailable, authState.accessToken, skip, authenticate]);

  return { loading: loading && site == null, site };
};
