import { type Static, Array, Optional, Record, String, Union } from "runtypes";

const ValueEntry = Union(String, Record({ label: String, value: String }));

const FilterValue = Union(
  Record({
    type: String,
    modifier: String,
    value: Optional(Union(ValueEntry, Array(ValueEntry))),
    search: Optional(String),
  }),
);

export const SavedBookingFilters = Record({
  site: Optional(FilterValue),
  building: Optional(FilterValue),
  level: Optional(FilterValue),
  userCompany: Optional(FilterValue),
  resourceOwnerCompany: Optional(FilterValue),
  resourceName: Optional(FilterValue),
  resourceTypeUuid: Optional(FilterValue),
  date: Optional(FilterValue),
  time: Optional(FilterValue),
  duration: Optional(FilterValue),
  showPaidBookings: Optional(FilterValue),
  capacity: Optional(FilterValue),
  bookingStatus: Optional(FilterValue),
});
export type SavedBookingFilters = Static<typeof SavedBookingFilters>;
