import { useContext, useMemo } from "react";
import { uniq, uniqBy } from "lodash";

import { CurrentRole, Role, sortByAlphabet, useSaferFormikContext, useSiteContext } from "@equiem/lib";

import { useCurrentDestinationBuildingsQuery, useWorkPlaceBuildingsQuery } from "../../generated/visitors-client";
import type { ReceptionFormValues } from "../ReceptionFormValues";

import type { useReceptionFormCompanies } from "./useReceptionFormCompanies";

export const useReceptionFormBuildings = ({
  selectedCompany,
}: {
  selectedCompany: ReturnType<typeof useReceptionFormCompanies>["selectedCompany"];
}) => {
  const { currentRole } = useContext(CurrentRole);
  const { values } = useSaferFormikContext<ReceptionFormValues>();
  const { uuid: siteUuid } = useSiteContext();

  const { data: buildingsData } = useCurrentDestinationBuildingsQuery();
  const { data: workplaceBuilding, loading } = useWorkPlaceBuildingsQuery({
    variables: {
      uuid: siteUuid,
    },
  });

  /**
   * Get the buildings available, depending on whether the current user is a workplace or property manager.
   */
  const buildings = useMemo(() => {
    if (currentRole === Role.WorkplaceManager) {
      const levelsData = workplaceBuilding?.viewer.profile?.companyV2?.companyDestination?.buildingLevels ?? [];

      /**
       * A de-deduped list of buildings, derived from the building levels the user's company occupies.
       * The levels are not yet attached.
       */
      const uniqueBuildings = sortByAlphabet(
        uniqBy(
          levelsData.map((level) => ({
            uuid: level.building.uuid,
            name: level.building.name,
            levels: [],
          })),
          "uuid",
        ),
        "name",
      );

      // Attach the levels to each building.
      return uniqueBuildings.map((building) => ({
        ...building,
        levels: levelsData
          .filter((level) => level.building.uuid === building.uuid)
          .map((level) => ({
            uuid: level.uuid,
            name: level.name,
          })),
      }));
    }

    if (currentRole === Role.PropertyManager) {
      const destBuildings = buildingsData?.viewer.currentDestination?.destination.buildings ?? [];

      // All the buildings at the site. This list is already unique.
      const allBuildings = sortByAlphabet(
        destBuildings.map((building) => ({
          uuid: building.uuid,
          name: building.name,
          levels: building.buildingLevels.map((level) => ({
            uuid: level.uuid,
            name: level.name,
          })),
        })),
        "name",
      );

      if (values.receptionType === "tenant") {
        /**
         * If the current type of reception is "tenant", then filter the buildings to
         * only include those occupied by the selected company.
         */
        const selectedCompanyLevelUuids = uniq(
          selectedCompany?.companyDestination?.buildingLevels.map((l) => l.uuid) ?? [],
        );

        return allBuildings.filter((building) =>
          building.levels.some((level) => selectedCompanyLevelUuids.includes(level.uuid)),
        );
      }

      return allBuildings;
    }

    return [];
  }, [workplaceBuilding, buildingsData, currentRole, selectedCompany, values.receptionType]);

  const selectedBuilding = useMemo(
    () => buildings.find((building) => building.uuid === values.buildingUuid),
    [buildings, values.buildingUuid],
  );

  // Extract a sorted, unique list of building levels available to the current user in the selected building.
  const levels = useMemo(() => {
    return sortByAlphabet(
      uniqBy(
        buildings.filter((building) => building.uuid === selectedBuilding?.uuid).flatMap((building) => building.levels),
        "uuid",
      ),
      "name",
    );
  }, [buildings, selectedBuilding]);

  const selectedBuildingLevel = useMemo(
    () => levels.find((level) => level.uuid === values.buildingLevelUuid),
    [levels, values.buildingLevelUuid],
  );

  return { buildings, levels, selectedBuilding, selectedBuildingLevel, loading };
};
