import { formatters, useTranslation } from "@equiem/localisation-eq1";
import React, { type ReactElement } from "react";

const RenderPrice = ({ price, className = "" }: { price?: number | null; className?: string }): ReactElement => {
  const { i18n } = useTranslation();
  if (price == null) {
    return <></>;
  }
  return <div className={className}>{formatters.currency(price, i18n.language, { currency: "GBP" })}</div>;
};

export default RenderPrice;
