import type { DateTime, WeekdayNumbers } from "luxon";
import type { ValidateBookingTimesQuery } from "../../../generated/gateway-client";

export interface RecurringDate {
  startTime: DateTime;
  endTime: DateTime;
}

export type ValidatedRecurringDate = ValidateBookingTimesQuery["validateBookingTimes"][number];

export type EndsOnType = "date" | "occurrences";

export enum RecurringType {
  Daily,
  Weekly,
  Monthly,
}

export type Version = { before?: string; current?: string };

export interface RecurringSettings {
  start: DateTime;
  end: DateTime;
  recurringType: RecurringType;
  repeatEvery: number;
  repeatOn: WeekdayNumbers[];
  sameWeekDayEachMonth: boolean;
  lastWeekDayEachMonth: boolean;
  // Repeat ends on.
  repeatEndsType: EndsOnType;
  repeatEndsTimes?: number;
  repeatEndsUntil?: DateTime;
  // The original selected time and end.
  selectedStartTime: DateTime;
  selectedEndTime: DateTime;
  // Consist of selected dates and its availability from backend.
  validatedRecurringDates: ValidatedRecurringDate[];
  // The simplest way I can think of to detect changes.
  version: Version;
}
