import * as yup from "yup";

import type { TFunction } from "@equiem/localisation-eq1";

export const getValidationSchema = (t: TFunction) =>
  yup.object().shape({
    name: yup
      .string()
      .required(t("settings.editApartment.nameRequired"))
      .max(60, t("settings.editProfile.charactersMaximum")),
    levels: yup.array().of(yup.string()).min(1, t("settings.levelsRequired")).required(t("settings.levelsRequired")),
  });
