import React, { useEffect, useMemo } from "react";
import { Field, FieldArray, Form } from "formik";

import type { CompanyFormValues } from "@equiem/lib";
import { notNullOrUndefined, useSaferFormikContext, useShowError } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import { Form as EqForm, useTheme } from "@equiem/react-admin-ui";

import { useWidgetContext } from "../../../contexts/WidgetContext";
import {
  type CompanyDetailsQuery,
  useAc1CompanySiteCredentialSetupConfigsFromAccessGroupsQuery,
} from "../../../generated/settings-client";

interface Props {
  accessGroups: Array<{ uuid: string; name: string }>;
  company: NonNullable<CompanyDetailsQuery["companyV2"]>;
  onAccessPassesChange: (values: CompanyFormValues) => void;
}

export const MultipleAccessGroupSelect: React.FC<Props> = ({ accessGroups, company, onAccessPassesChange }) => {
  const { t } = useTranslation();
  const { colors } = useTheme();
  const { subscribe } = useWidgetContext();
  const { submitForm, setFieldValue, values } = useSaferFormikContext<CompanyFormValues>();
  const showError = useShowError();
  const selectedAccessGroups = useMemo(() => {
    return accessGroups.filter((g) => values.accessGroupsUuids.includes(g.uuid));
  }, [accessGroups, values.accessGroupsUuids]);

  const { data: accessPasses } = useAc1CompanySiteCredentialSetupConfigsFromAccessGroupsQuery({
    variables: { accessGroupUuids: values.accessGroupsUuids, companyUuid: company.uuid },
    fetchPolicy: "network-only", // it prevents getting old values from cache if we edit company again without refreshing the page
  });
  useEffect(() => {
    const newAccessPasses =
      accessPasses?.ac1CompanySiteCredentialSetupConfigsFromAccessGroups.filter(notNullOrUndefined).map((data) => {
        return {
          accessPassesMaxNumber: data.accessPassesMaxNumber,
          autoAssignment: data.autoAssignment,
          category: data.credentialSetup.category,
          uuid: data.credentialSetup.uuid,
          passName: data.credentialSetup.passName,
          enabled: data.enabled,
        };
      }) ?? [];
    onAccessPassesChange({ ...values, accessPasses: newAccessPasses });
  }, [accessPasses]);

  useEffect(() => {
    return subscribe("onSave", () => {
      void submitForm();
    });
  }, [submitForm, subscribe]);
  return (
    <>
      <Form>
        <EqForm.Group
          className="p-3 form-box mb-0"
          showTooltip
          required
          label={t("settings.editCompany.selectGroupsTitle")}
          tooltipText={t("settings.editCompany.accessGroupTooltip")}
        >
          <Field
            name="accessGroupsUuids"
            as={EqForm.MultiSelect}
            variant="wrap"
            isMulti={true}
            placeholder={t("settings.editCompany.selectGroupsTitle")}
            searchPlaceholder={t("settings.editCompany.selectGroupsTitle")}
            options={accessGroups.map((group) => {
              return { label: group.name, value: group.uuid };
            })}
          />
        </EqForm.Group>

        <EqForm.Group
          className="p-3 form-box mb-0"
          required
          label={t("settings.editCompany.defaultGroupLabel")}
          showTooltip
          tooltipText={t("settings.editCompany.accessGroupTooltip")}
        >
          <Field name="defaultAccessGroupUuid" as={EqForm.Select}>
            <option value="">{t("settings.editCompany.selectDefaultAccessGroupsPlaceholder")}</option>
            {selectedAccessGroups.map((group) => (
              <option key={group.uuid} value={group.uuid}>
                {group.name}
              </option>
            ))}
          </Field>
        </EqForm.Group>
        <>
          {values.accessPasses != null && values.accessPasses.length > 0 && (
            <EqForm.Group
              className="p-3 form-box mb-0"
              required
              label={t("settings.editCompany.selectAccessPassesTitle")}
              showTooltip
              tooltipText={t("settings.editCompany.selectPassTooltip")}
            >
              <FieldArray
                name="accessPasses"
                render={() => (
                  <>
                    {values.accessPasses?.map((config, index) => {
                      const isFirst = index === 0;
                      const isLast = values.accessPasses != null && index === values.accessPasses.length - 1;
                      return (
                        <div
                          key={`accessPass-${index}`}
                          className={`access-pass-div ${isFirst ? "first" : ""} ${
                            isLast ? "last" : ""
                          } d-flex flex-column`}
                        >
                          <section className="d-flex align-items-center access-pass-checkbox">
                            <Field className="w-fit" as={EqForm.Checkbox} name={`accessPasses.${index}.enabled`} />
                            <span>
                              <strong>{config.passName}</strong> - {config.category}
                            </span>
                          </section>
                          {values.accessPasses?.length != null && values.accessPasses[index].enabled && (
                            <section className="d-flex align-items-center access-pass-settings">
                              <EqForm.Group
                                className="form-box mb-0 "
                                label={t("settings.editCompany.assigmentTitle")}
                                showTooltip
                                tooltipText={t("settings.editCompany.assigmentTooltip")}
                              >
                                <div className="d-flex assignment">
                                  <EqForm.RadioButton
                                    name={`accessPasses[${index}].autoAssignment`}
                                    checked={values.accessPasses[index].autoAssignment}
                                    label={t("settings.editCompany.autoAssignment")}
                                    labelStyle={{ padding: "8px 16px 8px 8px", gap: "4px" }}
                                    onChange={() => {
                                      setFieldValue(
                                        `accessPasses[${index}].autoAssignment` as keyof CompanyFormValues,
                                        true,
                                      ).catch(showError);
                                    }}
                                  />

                                  <EqForm.RadioButton
                                    name={`accessPasses[${index}].autoAssignment`}
                                    checked={!values.accessPasses[index].autoAssignment}
                                    label={t("settings.editCompany.addingCardsManuallyLabel")}
                                    labelStyle={{ padding: "8px 16px 8px 8px", gap: "4px" }}
                                    onChange={() => {
                                      setFieldValue(
                                        `accessPasses[${index}].autoAssignment` as keyof CompanyFormValues,
                                        false,
                                      ).catch(showError);
                                    }}
                                  />
                                </div>
                              </EqForm.Group>

                              <EqForm.Group
                                className="p-3 form-box mb-0"
                                label={t("settings.editCompany.maximumNumberOfAccessesTitle")}
                                showTooltip
                                tooltipText={t("settings.editCompany.maximumNumberOfAccessesTooltip")}
                              >
                                <Field
                                  name={`accessPasses[${index}].accessPassesMaxNumber`}
                                  as={EqForm.Input}
                                  type="number"
                                  min={0}
                                  autoComplete="off"
                                />
                              </EqForm.Group>
                            </section>
                          )}
                        </div>
                      );
                    })}
                  </>
                )}
              />
            </EqForm.Group>
          )}
        </>
      </Form>
      <style jsx>
        {`
          .form-box {
            border: 1px solid ${colors.grayscale[20]};
          }

          .access-pass-settings {
            padding-left: 28px;
          }

          .access-pass-checkbox {
            gap: 4px;
          }

          .access-pass-div {
            gap: 16px;
            padding: 16px;
            border-left: 1px solid ${colors.grayscale[20]};
            border-right: 1px solid ${colors.grayscale[20]};
          }

          .access-pass-div:not(.first) {
            border-top: 1px solid ${colors.grayscale[20]};
          }

          .access-pass-div.last {
            border-bottom: 1px solid ${colors.grayscale[20]};
          }

          .access-pass-div.first {
            border-top: 1px solid ${colors.grayscale[20]};
            border-radius: 16px 16px 0 0;
          }

          .access-pass-div.last {
            border-radius: 0 0 16px 16px;
          }

          .assignment {
            gap: 8px;
          }

          .access-pass-div.first.last {
            border-radius: 16px;
          }
        `}
      </style>
    </>
  );
};
