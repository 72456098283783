import { Field, Formik } from "formik";
import React, { useContext } from "react";
import { Button, Form as EqForm, Modal, ProgressCircle, remainingCharacters, useTheme } from "@equiem/react-admin-ui";
import { useSiteContext } from "@equiem/lib";
import type { FormValues } from "./helpers/FormValues";
import { validateAddForm } from "./helpers/validateAddForm";
import { useTranslation } from "@equiem/localisation-eq1";
import { BlockoutContext } from "./BlockoutProvider";
import { toSeconds, parseTime } from "../../../../lib/dateTimeHelpers";
import { ResourceSelector } from "../../../../components/ResourceSelector";

const TITLE_MAX_LENGTH = 100;

export const AddEditForm: React.FC<{ className?: string }> = ({ className }) => {
  const { t } = useTranslation();
  const { timezone } = useSiteContext();
  const { cancel, showModal, value, createOrUpdate } = useContext(BlockoutContext);
  const theme = useTheme(true);

  return (
    <>
      <Modal.Dialog
        centered
        title={t("bookings.settings.blockOutDates")}
        show={showModal}
        onHide={cancel}
        hideOnEsc={false}
        hideOnClick={false}
        scrollable
        size="lg"
        className={`blockout-modal ${className}`}
      >
        <Modal.Header closeButton noBorder={true} />
        <Formik<FormValues> initialValues={value} validate={validateAddForm(timezone, t)} onSubmit={createOrUpdate}>
          {({ values, isSubmitting, setFieldValue, submitForm, errors }) => (
            <>
              <Modal.Body>
                <div className="flatit">
                  <EqForm.Group
                    className="w-50"
                    label={t("bookings.settings.blockOutTitle")}
                    required
                    hint={t("common.remainingCharacters", {
                      count: remainingCharacters(TITLE_MAX_LENGTH, values.title.length),
                    })}
                    error={errors.title}
                  >
                    <Field
                      id="title"
                      name="title"
                      placeholder={t("bookings.settings.blockOutTitleHint")}
                      as={EqForm.Input}
                      maxLength={TITLE_MAX_LENGTH}
                    />
                  </EqForm.Group>
                  <ResourceSelector className="w-50" />
                </div>
                <div className="flatit">
                  <EqForm.Group label={t("bookings.settings.startDate")} required error={errors.startDate}>
                    <Field id="start-date" name="startDate" as={EqForm.Input} type="date" />
                  </EqForm.Group>
                  <EqForm.Group label={t("bookings.settings.startTime")} error={errors.startTime}>
                    <Field name="startTime">
                      {() => (
                        <EqForm.TimeSelect
                          id="start-time"
                          name="startTime"
                          defaultOptionValue="--/--"
                          defaultOptionDisabled={false}
                          value={toSeconds(values.startTime)}
                          onChange={(e) => {
                            setFieldValue("startTime", e.currentTarget.value === "0" ? "" : parseTime(e)).catch(
                              console.error,
                            );
                          }}
                          startTime={{ hour: 0, minute: 0 }}
                          endTime={{ hour: 23, minute: 59 }}
                          diffMinutes={15}
                        />
                      )}
                    </Field>
                  </EqForm.Group>
                  <EqForm.Group label={t("bookings.settings.endDate")} required error={errors.endDate}>
                    <Field id="end-date" name="endDate" as={EqForm.Input} type="date" />
                  </EqForm.Group>
                  <EqForm.Group label={t("bookings.settings.endTime")} error={errors.endTime}>
                    <Field name="endTime">
                      {() => (
                        <EqForm.TimeSelect
                          id="end-time"
                          name="endTime"
                          defaultOptionValue="--/--"
                          defaultOptionDisabled={false}
                          value={toSeconds(values.endTime)}
                          onChange={(e) => {
                            setFieldValue("endTime", e.currentTarget.value === "0" ? "" : parseTime(e)).catch(
                              console.error,
                            );
                          }}
                          startTime={{ hour: 0, minute: 0 }}
                          endTime={{ hour: 23, minute: 59 }}
                          diffMinutes={15}
                        />
                      )}
                    </Field>
                  </EqForm.Group>
                </div>
              </Modal.Body>

              <Modal.Footer>
                <Button className="mr-2" size="md" variant="ghost" onClick={cancel}>
                  {t("common.cancel")}
                </Button>
                <Button
                  type="submit"
                  variant="primary"
                  disabled={isSubmitting}
                  onClick={() => {
                    submitForm().catch(console.log);
                  }}
                >
                  {isSubmitting ? <ProgressCircle size="sm" /> : t("common.save")}
                </Button>
              </Modal.Footer>
            </>
          )}
        </Formik>
      </Modal.Dialog>
      <style jsx>{`
        @media screen and (min-width: ${theme.breakpoints.md}px) {
          .flatit {
            display: flex;
            gap: ${theme.spacers.s4};
          }
        }
      `}</style>
    </>
  );
};
