import { stringNotEmpty, StripeProvider } from "@equiem/lib";
import { useErrorTranslation, useTranslation } from "@equiem/localisation-eq1";
import React, { useContext, useEffect } from "react";
import { BookingForm } from "./BookingForm";
import { useBookableResourceQuery } from "../../../generated/gateway-client";
import { BookingModal } from "../contexts/BookingModalContext";
import { BookingModalInfoProvider } from "../contexts/BookingModalInfoProvider";
import { BookingModalContentLoading } from "./BookingModalContentLoading";
import { RecurringBookingCreationLoaderModalProvider } from "../contexts/RecurringBookingCreationLoaderModalContext";

export const BookingCreateNewModalContent: React.FC = () => {
  const { t } = useTranslation();
  const { tError } = useErrorTranslation();
  const modal = useContext(BookingModal);
  const uuid = modal.id;

  const { data, loading, error } = useBookableResourceQuery({
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    variables: { uuid: uuid! },
    fetchPolicy: "network-only",
    skip: uuid == null,
  });

  const resource = data?.bookableResource;
  const resourceName = resource?.name;

  useEffect(() => {
    if (stringNotEmpty(resourceName)) {
      modal.setTitle(resourceName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resourceName]);

  if (uuid == null) {
    return null;
  }

  if (loading) {
    return <BookingModalContentLoading />;
  }

  if (error != null) {
    return <div>{tError(error)}</div>;
  }

  if (resource == null) {
    return <div>{t("bookings.operations.resourceNotFound")}</div>;
  }

  return (
    <BookingModalInfoProvider resource={resource}>
      <RecurringBookingCreationLoaderModalProvider>
        <StripeProvider>
          <BookingForm />
        </StripeProvider>
      </RecurringBookingCreationLoaderModalProvider>
    </BookingModalInfoProvider>
  );
};
