export enum Role {
  Unknown = "None",
  WorkplaceManager = "Workplace Manager",
  PropertyManager = "Property Manager",
  FlexManager = "Flex Manager",
}

export const RoleFromJWTRole = (roleName?: string) => {
  if (roleName === "WORKPLACE_MANAGER") {
    return Role.WorkplaceManager;
  }
  if (roleName === "PROPERTY_MANAGER") {
    return Role.PropertyManager;
  }
  if (roleName === "FLEX_MANAGER") {
    return Role.FlexManager;
  }

  return Role.Unknown;
};
