import { useTheme, Dropdown } from "@equiem/react-admin-ui";

import React, { useContext } from "react";
import { RiQuestionLine } from "react-icons/ri";

export const FloatingActionButton: React.FC = () => {
  const { colors } = useTheme();
  const { setShow, show, reference } = useContext(Dropdown.Context);

  const handleDropdown = () => {
    setShow(!show);
  };

  return (
    <>
      <button onClick={handleDropdown} ref={reference}>
        <RiQuestionLine size={28} />
      </button>
      <style jsx>{`
        button {
          bottom: 16px;
          left: 16px;
          overflow: hidden;
          height: 48px;
          width: 48px;
          background: ${colors.grayscale[80]};
          border: none;
          color: #fff;
          outline: none;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
          z-index: 1000;
          position: sticky;
          border-radius: 50%;
        }

        button:hover {
          background: ${colors.grayscale[100]};
        }
      `}</style>
    </>
  );
};
