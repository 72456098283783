import React from "react";
import { useTheme } from "@equiem/react-admin-ui";

interface P {
  left: React.ReactNode;
  right: React.ReactNode;
}
export const Summary: React.FC<P> = ({ left, right }) => {
  const theme = useTheme();

  return (
    <>
      <div className="display">
        <div className="left-section">{left}</div>
        <div className="right-section">{right}</div>
      </div>
      <style jsx>{`
        .display {
          padding: ${theme.spacers.s6} 0 ${theme.spacers.s7};
          margin-bottom: ${theme.spacers.s8};
          border-bottom: 1px solid ${theme.colors.border};
        }
        @media screen and (min-width: ${theme.breakpoints.md}px) {
          .display {
            display: flex;
            justify-content: space-between;
          }
          .left-section {
            max-width: 40%;
          }
          .right-section {
            width: 50%;
          }
        }
      `}</style>
    </>
  );
};
