import { useState, useEffect } from "react";

const defaultFont = "Roboto";

const languageSpecificFonts: Partial<Record<string, string>> = {
  ja: "Noto Sans JP",
};

export const mainFont =
  "https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=block";

export const fontSrcList = [
  mainFont,
  "https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@300;400;500;700&display=block",
];

export const fontImports = fontSrcList.map((src) => `@import url("${src}");`).join("\n");

const currentLang = () => {
  if (typeof document === "undefined") {
    return "en";
  }
  return document.documentElement.getAttribute("lang") ?? "en";
};

export function useDefaultFont() {
  const [lang, setLang] = useState(currentLang());

  useEffect(() => {
    const observer = new MutationObserver(() => setLang(currentLang()));
    observer.observe(document.documentElement, { attributes: true, attributeFilter: ["lang"] });

    return () => observer.disconnect();
  }, []);

  return languageSpecificFonts[lang] ?? defaultFont;
}
