import React, { useContext, useEffect } from "react";

import { stringIsEmpty, useSaferFormikContext } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import { Form, Text, useTheme } from "@equiem/react-admin-ui";

import type { NewRequestFormValues } from "../../create-request/NewRequest";
import { NewRequestContext } from "../../requests/contexts/NewRequestContext";

import { NewRequestAssignee } from "./NewRequestAssignee";
import { NewRequestAttachments } from "./NewRequestAttachments";
import { NewRequestCategory } from "./NewRequestCategory";
import { NewRequestDateReported } from "./NewRequestDateReported";
import { NewRequestDescription } from "./NewRequestDescription";
import { NewRequestLocation } from "./NewRequestLocation";
import { NewRequestReporter } from "./NewRequestReporter";
import { NewRequestStatus } from "./NewRequestStatus";
import { NewRequestWatchers } from "./NewRequestWatchers";

export const NewRequestDetails = ({ isSaving }: { isSaving: boolean }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { resetForm, values } = useSaferFormikContext<NewRequestFormValues>();

  const { requestForm, cacheRequestForm } = useContext(NewRequestContext);
  const isSpaceEmpty = stringIsEmpty(values.space?.uuid);
  const isCategoryAndStatusEmpty = stringIsEmpty(values.category) || stringIsEmpty(values.status);

  useEffect(() => {
    resetForm(requestForm as NewRequestFormValues);
  }, []);

  useEffect(() => {
    if (values.buildingUuid == null) {
      resetForm();
    }
  }, [values.buildingUuid]);

  useEffect(() => {
    cacheRequestForm(values);
  }, [values]);

  return (
    <>
      <div className="d-flex justify-content-between pb-3">
        <div className="d-flex align-items-center">
          <h1 className="font-weight-bold">{t("requests.newRequest.title")}</h1>
        </div>
      </div>

      <div className="row">
        <div className="col location-col">
          <Form.Label required inline noMargin>
            <Text variant="label" color={theme.colors.grayscale["50"]}>
              {t("requests.details.location")}
            </Text>
          </Form.Label>
          <NewRequestLocation disabled={isSaving} />
        </div>
      </div>
      <div className="row">
        <div className="col status-col">
          <Form.Label required inline noMargin>
            <Text variant="label" color={isSpaceEmpty ? theme.colors.grayscale[20] : theme.colors.grayscale["50"]}>
              {t("requests.details.status")}
            </Text>
          </Form.Label>
          <div>
            <NewRequestStatus disabled={isSaving} />
          </div>
        </div>
        <div className="col category-col">
          <Form.Label required inline noMargin>
            <Text variant="label" color={isSpaceEmpty ? theme.colors.grayscale[20] : theme.colors.grayscale["50"]}>
              {t("requests.details.category")}
            </Text>
          </Form.Label>

          <NewRequestCategory disabled={isSaving} />
        </div>
      </div>
      <div className="row">
        <div className="col reporter-col">
          <Form.Label required inline noMargin>
            <Text
              variant="label"
              color={
                isSpaceEmpty && isCategoryAndStatusEmpty ? theme.colors.grayscale[20] : theme.colors.grayscale["50"]
              }
            >
              {t("requests.reporter")}
            </Text>
          </Form.Label>
          <NewRequestReporter disabled={isSaving} />
        </div>
        <div className="col watcher-col">
          <Text
            variant="label"
            color={isSpaceEmpty && isCategoryAndStatusEmpty ? theme.colors.grayscale[20] : theme.colors.grayscale["50"]}
          >
            {t("requests.details.watchers")}
          </Text>
          <div>
            <NewRequestWatchers disabled={isSaving} />
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col assignee-col">
          <Text
            variant="label"
            color={isSpaceEmpty && isCategoryAndStatusEmpty ? theme.colors.grayscale[20] : theme.colors.grayscale["50"]}
          >
            {t("requests.assignee")}
          </Text>
          <NewRequestAssignee disabled={isSaving} />
        </div>
        <div className="col datereported-col">
          <Text
            variant="label"
            style={{ overflow: "hidden" }}
            color={isSpaceEmpty && isCategoryAndStatusEmpty ? theme.colors.grayscale[20] : theme.colors.grayscale["50"]}
          >
            {t("requests.details.dateReported")}
          </Text>
          <NewRequestDateReported disabled={isSaving} />
        </div>
      </div>

      <div className="description-col">
        <Form.Label required noMargin>
          <Text variant="label" color={isSpaceEmpty ? theme.colors.grayscale[20] : theme.colors.grayscale["50"]}>
            {t("common.description")}
          </Text>
        </Form.Label>
        <div className="description-field">
          <NewRequestDescription disabled={isSaving} />
        </div>
      </div>
      <div className="attachment-col">
        <Text variant="label" color={isSpaceEmpty ? theme.colors.grayscale[20] : theme.colors.grayscale["50"]}>
          {t("common.attachments")}
        </Text>
        <div className="attachment-files">
          <NewRequestAttachments disabled={isSaving} />
        </div>
      </div>
      <style jsx>{`
        .location-col {
          align-items: center;
        }
        .row {
          display: grid;
          gap: ${theme.spacers.s7};
        }
        .col {
          display: grid;
          grid-template-columns: 35% 65%;
          align-items: center;
          gap: ${theme.spacers.s2};
        }

        .description-field {
          margin-top: 8px;
        }

        @media (min-width: ${theme.breakpoints.md}px) {
          .row {
            grid-template-columns: 50% 1fr;
          }
        }
        .attachment-files {
          margin-top: ${theme.spacers.s3};
        }
        .attachment-col {
          margin-top: ${theme.spacers.s7};
          gap: 8px;
        }
        .description-col {
          margin-top: ${theme.spacers.s7};
          padding-top: ${theme.spacers.s6};
          border-top: 1px solid ${theme.colors.border};
        }
        .processing {
          font-weight: 500;
          text-transform: uppercase;
          color: ${theme.colors.grayscale["60"]};
          display: flex;
          align-items: center;
        }
      `}</style>
    </>
  );
};
