import { useTranslation } from "@equiem/localisation-eq1";
import { IconButton, ProgressCircle, Tooltip, useConfirmer, useTheme } from "@equiem/react-admin-ui";
import React from "react";
import { RiDeleteBin6Line } from "@equiem/react-admin-ui/icons";

interface P {
  title: string;
  disable: boolean;
  loading: boolean;
  onDelete: () => Promise<void>;
}
export const DeleteItem: React.FC<P> = ({ title, disable, loading, onDelete }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { withConfirmation } = useConfirmer();
  const disableIt = loading || disable;

  return loading ? (
    <ProgressCircle size="xs" />
  ) : (
    <IconButton
      className="delete"
      size="sm"
      onClick={withConfirmation({
        onConfirm: () => {
          onDelete().catch(console.log);
        },
        title: t("common.deleteConfirmationTitle"),
        message: t("common.deleteConfirmationWithTitle", { title }),
        confirmButtonText: t("common.yesDelete"),
        confirmButtonVariant: "danger",
      })}
      disabled={disableIt}
      hover={{ background: theme.colors.status.danger.accent }}
    >
      <Tooltip title={t("common.delete")} placement="bottom">
        <span>
          <RiDeleteBin6Line size={16} color={theme.colors.danger} />
        </span>
      </Tooltip>
    </IconButton>
  );
};
