import { CurrentProfile } from "@equiem/lib";
import { flatten, groupBy, sortBy } from "lodash";
import { DateTime } from "luxon";
import React, { useContext, useEffect, useState } from "react";
import { AvailableTimeRange } from "./AvailableTimeRange";
import { AvailableTimeSlot } from "./AvailableTimeSlot";
import type { TimeRange } from "./BaseTimeRange";
import { LoadingHourlyTimeRange } from "./LoadingHourlyTimeRange";
import { OwnTimeRange } from "./OwnTimeRange";
import { TakenTimeRange } from "./TakenTimeRange";
import { UnavailableTimeRange } from "./UnavailableTimeRange";
import { useNotBokable } from "../../hooks/useNotBookable";
import { useResourceData } from "../../hooks/useResourceData";
import { BookingModal } from "../../../operations/contexts/BookingModalContext";
import type { BookableResourceCalendarTaken, ResourceFragment } from "../../../../generated/gateway-client";
import { BookableResourceAvailabilityType } from "../../../../generated/gateway-client";

type TimeSlot = {
  start: number;
  end: number;
  columnNum: number;
  columnSize: number;
  totalColumns: number;
  overflow: boolean;
  overflowAvailabilities: number;
};

const MILLISECONDS_IN_MINUTE = 60000;
const MAX_TOTAL_COLUMNS_NUM = 3;

const generateHourlyRanges = (range: TimeRange): TimeRange[] => {
  const { start, end } = range;

  const startTime = DateTime.fromMillis(start);
  const endTime = DateTime.fromMillis(end);

  const hourlyRanges: TimeRange[] = [];

  let currentStart = startTime;
  while (currentStart < endTime) {
    const currentEnd = currentStart.plus({ hours: 1 }).minus({ milliseconds: 1 });

    if (currentEnd > endTime) {
      break;
    }

    hourlyRanges.push({
      start: currentStart.toMillis(),
      end: currentEnd.toMillis(),
    });

    currentStart = currentStart.plus({ hours: 1 });
  }

  return hourlyRanges;
};

const findUnavailableRanges = (dayRange: TimeRange, availableSlots: TimeRange[]): TimeRange[] => {
  availableSlots.sort((a, b) => a.start - b.start);

  const unavailableSlots: TimeRange[] = [];
  let currentStart = dayRange.start;

  for (const { start, end } of availableSlots) {
    if (currentStart < start) {
      unavailableSlots.push({ start: currentStart, end: start });
    }

    currentStart = Math.max(currentStart, end);
  }

  if (currentStart < dayRange.end) {
    unavailableSlots.push({ start: currentStart, end: dayRange.end });
  }

  return unavailableSlots;
};

const buildAvailableSlotsMap = (
  availableSlots: TimeSlot[],
  resource: ResourceFragment,
  start: number,
  end: number,
): TimeSlot[] => {
  const groupedAvailableSlots = groupBy(availableSlots, (slot) => {
    const slotEnd = end - slot.end + 1 === MILLISECONDS_IN_MINUTE ? slot.end + MILLISECONDS_IN_MINUTE : slot.end;
    const diff =
      slotEnd +
      (resource.prepTimeAfterInMinutes ?? 0) * MILLISECONDS_IN_MINUTE -
      (slot.start - (resource.prepTimeBeforeInMinutes ?? 0) * MILLISECONDS_IN_MINUTE);
    const delta = (slotEnd - start) % diff;
    return `${delta / MILLISECONDS_IN_MINUTE}-${diff / MILLISECONDS_IN_MINUTE}`;
  });

  // Convert groups to array for easier processing
  const groupArrays = Object.values(groupedAvailableSlots);

  // Function to check if two slots intersect
  const intersect = (slot1: TimeRange, slot2: TimeRange): boolean => {
    return slot1.start < slot2.end && slot2.start < slot1.end;
  };

  // Function to combine intersecting slots
  const combineIntersectingSlots = (slots: TimeSlot[]): TimeRange => {
    return { start: Math.min(...slots.map((slot) => slot.start)), end: Math.max(...slots.map((slot) => slot.end)) };
  };

  // Initialize columnNum, columnSize and totalColumns
  groupArrays.forEach((group, groupIndex) => {
    group.forEach((slot) => {
      let maxTotalColumns = 1;

      // Determine columnNum by checking intersections with previous groups
      if (groupIndex > 0) {
        const previousGroups = groupArrays.slice(0, groupIndex);
        const intersectingSlots = previousGroups.flat().filter((prevSlot) => intersect(prevSlot, slot));
        if (intersectingSlots.length > 0) {
          maxTotalColumns = Math.max(...intersectingSlots.map((prevSlot) => prevSlot.totalColumns), 1);

          const maxColumnNum = Math.max(...intersectingSlots.map((prevSlot) => prevSlot.columnNum), 0);
          slot.columnNum = maxColumnNum + 1;
        }
      }

      // Determine columnSize and totalColumns by counting intersecting neighboring groups
      // Traverse left (previous groups)
      const traverseLeft = (currentSlot: TimeRange, currentGroupIndex: number) => {
        for (let i = currentGroupIndex - 1; i >= 0; i--) {
          const prevGroup = groupArrays[i];
          const intersectingSlots = prevGroup.filter((prevSlot) => intersect(prevSlot, currentSlot));
          if (intersectingSlots.length > 0) {
            slot.totalColumns++;
            if (maxTotalColumns >= slot.totalColumns) {
              slot.columnSize = maxTotalColumns - slot.totalColumns + 1;
            }

            // Combine intersecting slots and traverse further left
            const combinedSlot = combineIntersectingSlots(intersectingSlots);
            traverseLeft(combinedSlot, i);
            break;
          }
        }
      };

      // Traverse right (next groups)
      const traverseRight = (currentSlot: TimeRange, currentGroupIndex: number) => {
        for (let i = currentGroupIndex + 1; i < groupArrays.length; i++) {
          const nextGroup = groupArrays[i];
          const intersectingSlots = nextGroup.filter((nextSlot) => intersect(nextSlot, currentSlot));
          if (intersectingSlots.length > 0) {
            slot.totalColumns++;
            if (maxTotalColumns >= slot.totalColumns) {
              slot.columnSize = maxTotalColumns - slot.totalColumns + 1;
            }

            // Combine intersecting slots and traverse further right
            const combinedSlot = combineIntersectingSlots(intersectingSlots);
            traverseRight(combinedSlot, i);
            break;
          }
        }
      };

      // Start traversing both directions
      traverseLeft(slot, groupIndex);
      traverseRight(slot, groupIndex);
    });
  });

  // Combine all groups back into a single array
  let allocatedSlots = flatten(groupArrays)
    .filter((slot) => slot.columnNum < MAX_TOTAL_COLUMNS_NUM)
    .sort((a, b) => a.start - b.start);
  allocatedSlots = sortBy(allocatedSlots, ["columnNum", "start"]);

  // Handle overflow
  allocatedSlots.forEach((slot) => {
    if (slot.totalColumns > MAX_TOTAL_COLUMNS_NUM) {
      if (slot.columnNum === MAX_TOTAL_COLUMNS_NUM - 1) {
        slot.overflow = true;
        slot.overflowAvailabilities = slot.totalColumns - MAX_TOTAL_COLUMNS_NUM + 1;
      }
      slot.totalColumns = MAX_TOTAL_COLUMNS_NUM;
    }
  });

  // Combine consecutive slots with overflow
  for (let i = 0; i < allocatedSlots.length; i++) {
    const currentSlot = allocatedSlots[i];

    if (currentSlot.overflow) {
      let j = i + 1;

      while (
        j < allocatedSlots.length &&
        allocatedSlots[j].overflow &&
        allocatedSlots[j].start - (resource.prepTimeBeforeInMinutes ?? 0) * MILLISECONDS_IN_MINUTE ===
          currentSlot.end + (resource.prepTimeAfterInMinutes ?? 0) * MILLISECONDS_IN_MINUTE
      ) {
        if (allocatedSlots[j].overflowAvailabilities > currentSlot.overflowAvailabilities) {
          currentSlot.overflowAvailabilities = allocatedSlots[j].overflowAvailabilities;
        }
        currentSlot.end = allocatedSlots[j].end;
        j++;
      }

      if (j > i + 1) {
        allocatedSlots.splice(i + 1, j - i - 1);
      }
    }
  }

  return allocatedSlots;
};

interface Props {
  siteTimezone: string;
  resource: ResourceFragment;
  start: number;
  end: number;
  isLoaded: boolean;
}

export const CatalogueCalendarResourceColumn: React.FC<Props> = ({ siteTimezone, resource, start, end, isLoaded }) => {
  const { profile } = useContext(CurrentProfile);
  const [selectedSlot, setSelectedSlot] = useState<TimeRange | null>(null);
  const [reload, setReload] = useState(false);
  const [reloadUuid, setReloadUuid] = useState<string | null>(null);
  const { start: modalStart, end: modalEnd, id: modalId } = useContext(BookingModal);
  const notBookable = useNotBokable(resource);

  const hourlyRanges = generateHourlyRanges({ start, end });

  const { data, loading } = useResourceData(resource.uuid, start, end, isLoaded, reload);

  useEffect(() => {
    if (modalId == null) {
      if (resource.uuid === reloadUuid) {
        setReload((prev) => !prev);
        setReloadUuid(null);
        setSelectedSlot(null);
      }
    } else if (modalId === resource.uuid) {
      if (modalStart != null && modalStart >= start && modalEnd != null && modalEnd <= end) {
        setSelectedSlot({ start: modalStart, end: modalEnd });
      } else {
        setSelectedSlot(null);
      }
    }
  }, [modalStart, modalEnd, setSelectedSlot, modalId, resource.uuid, reloadUuid, start, end]);

  const takenSlots = data?.bookableResource.availabilityCalendar
    .filter(
      (calendarItem) =>
        calendarItem.__typename === "BookableResourceCalendarTaken" &&
        calendarItem.booking != null &&
        calendarItem.booking.user.uuid !== profile?.uuid &&
        calendarItem.booking.startDate >= start &&
        calendarItem.booking.endDate <= end,
    )
    .map((ts) => {
      const bookingSlot = (ts as BookableResourceCalendarTaken).booking;
      return {
        start: bookingSlot!.startDate,
        end: bookingSlot!.endDate,
      };
    });
  const ownSlots = data?.bookableResource.availabilityCalendar
    .filter(
      (calendarItem) =>
        calendarItem.__typename === "BookableResourceCalendarTaken" &&
        calendarItem.booking != null &&
        calendarItem.booking.user.uuid === profile?.uuid &&
        calendarItem.booking.startDate >= start &&
        calendarItem.booking.endDate <= end,
    )
    .map((ts) => {
      const bookingSlot = (ts as BookableResourceCalendarTaken).booking;
      return {
        start: bookingSlot!.startDate,
        end: bookingSlot!.endDate,
      };
    });
  if (selectedSlot != null) {
    ownSlots?.push(selectedSlot);
    ownSlots?.sort((a, b) => a.start - b.start);
  }
  const availableRanges = data?.bookableResource.availabilityCalendar
    .filter(
      (calendarItem) =>
        calendarItem.__typename === "BookableResourceCalendarAvailable" &&
        calendarItem.type === BookableResourceAvailabilityType.Flexible,
    )
    .map((ts) => ({ start: ts.startTime, end: ts.endTime }));
  const availableSlots = data?.bookableResource.availabilityCalendar
    .filter(
      (calendarItem) =>
        calendarItem.__typename === "BookableResourceCalendarAvailable" &&
        calendarItem.type === BookableResourceAvailabilityType.Slots,
    )
    .filter(
      (calendarItem) =>
        selectedSlot == null ||
        ((calendarItem.startTime !== selectedSlot.start || calendarItem.endTime !== selectedSlot.end) &&
          !(calendarItem.startTime < selectedSlot.end && calendarItem.endTime > selectedSlot.start)),
    )
    .filter(
      (slot, index, self) =>
        index === self.findIndex((s) => s.startTime === slot.startTime && s.endTime === slot.endTime),
    )
    .map((ts) => ({
      start: ts.startTime,
      end: ts.endTime,
      columnNum: 0,
      columnSize: 1,
      totalColumns: 1,
      overflow: false,
      overflowAvailabilities: 0,
    }));
  const allocatedAvailableSlots =
    availableSlots != null ? buildAvailableSlotsMap(availableSlots, resource, start, end) : [];

  const unavailableRanges = findUnavailableRanges({ start, end }, [
    ...(takenSlots ?? []),
    ...(ownSlots ?? []),
    ...(availableRanges ?? []),
  ]);

  return (
    <>
      {loading ? (
        <>
          {hourlyRanges.map((hourlyRange, i) => (
            <LoadingHourlyTimeRange key={i} {...hourlyRange} timezone={siteTimezone} />
          ))}
        </>
      ) : (
        <>
          {unavailableRanges.map((unavailableRange, i) => (
            <UnavailableTimeRange key={i} {...unavailableRange} timezone={siteTimezone} />
          ))}
          {takenSlots != null && (
            <>
              {takenSlots.map((takenSlot, i) => (
                <TakenTimeRange key={i} {...takenSlot} timezone={siteTimezone} />
              ))}
            </>
          )}
          {ownSlots != null && (
            <>
              {ownSlots.map((ownSlot, i) => (
                <OwnTimeRange key={i} {...ownSlot} timezone={siteTimezone} />
              ))}
            </>
          )}
          {availableRanges != null && (
            <>
              {availableRanges.map((availableRange, i) => (
                <AvailableTimeRange
                  key={i}
                  {...availableRange}
                  timezone={siteTimezone}
                  resource={resource}
                  notBookable={notBookable}
                  setReloadUuid={setReloadUuid}
                />
              ))}
            </>
          )}
          {allocatedAvailableSlots.map((availableSlot, i) => (
            <AvailableTimeSlot
              key={i}
              {...availableSlot}
              timezone={siteTimezone}
              resource={resource}
              notBookable={notBookable}
              setReloadUuid={setReloadUuid}
            />
          ))}
        </>
      )}
    </>
  );
};
