import React, { useCallback, useContext, useEffect, useState } from "react";

import { useTranslation } from "@equiem/localisation-eq1";
import { Modal, useConfirmer } from "@equiem/react-admin-ui";

import { Modal as ModalContext } from "../../../contexts/ModalContext";
import { BulkUploadProvider } from "../components/bulk-upload-visitors/BulkUploadVisitorsContext";
import { BulkUploadVisitorsView } from "../components/bulk-upload-visitors/BulkUploadVisitorsView";

export const VisitorsBulkUploadWidget: React.FC = () => {
  const [showModal, setShowModal] = useState(false);
  const modal = useContext(ModalContext);
  const { withConfirmation } = useConfirmer();
  const { t } = useTranslation();

  const onClose = useCallback(() => {
    modal.close();
    setShowModal(false);
  }, [setShowModal, modal]);

  useEffect(() => {
    if (modal.activeModal === "BulkUploadVisitors") {
      setShowModal(true);
    }
  }, [modal.activeModal, setShowModal]);

  const confirmCancel = useCallback(
    (onConfirm?: () => void) => {
      withConfirmation({
        title: t("common.areYouSure"),
        message: t("home.widgets.inviteCancelModal"),
        confirmButtonText: t("common.yesCancel"),
        cancelButtonText: t("home.widgets.cancelNo"),
        confirmButtonVariant: "danger",
        onConfirm: () => {
          onConfirm?.();
          onClose();
        },
      })();
    },
    [onClose, t, withConfirmation],
  );

  return (
    <>
      <Modal.Dialog
        title={t("visitors.appointmentForm.addVisitors")}
        show={showModal}
        scrollable={true}
        centered={true}
        onHide={() => confirmCancel()}
        hideOnEsc={true}
        hideOnClick={false}
        focusTrapOptions={{ allowOutsideClick: () => true }}
        size="xl"
      >
        <Modal.Header closeButton={true} noBorder={true} noTitle={false} intro={t("common.bulkUpload")}></Modal.Header>
        <BulkUploadProvider onClose={onClose} confirmCancel={confirmCancel}>
          <BulkUploadVisitorsView />
        </BulkUploadProvider>
      </Modal.Dialog>
    </>
  );
};
