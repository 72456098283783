import { useMemo } from "react";
import { notNullOrUndefined } from "@equiem/lib";
import { useFormikContext } from "formik";
import uniqBy from "lodash/uniqBy";

import {
  useDestinationDetailsQuery,
  useSearchDestinationsByClientQuery,
  useGetCompanyDestinationsQuery,
} from "../../../generated/gateway-client";
import type { FormValues } from "../../../lib/formValidation";
import { useCompanyIsFlexOperator } from "./useCompanyIsFlexOperator";

export interface AudienceDestination {
  uuid: string;
  name: string;
}

export function useAudienceDestinations(siteUuid: string): AudienceDestination[] {
  const fm = useFormikContext<FormValues>();

  const destinationDetails = useDestinationDetailsQuery({ variables: { uuid: siteUuid } });
  const site = destinationDetails.data?.destination;

  const clientDestinationsQuery = useSearchDestinationsByClientQuery({
    variables: { clientUuid: site?.client?.uuid ?? "" },
    skip: site?.client?.uuid == null,
  });
  const clientDestinations = clientDestinationsQuery.data?.searchDestinationsByClient;

  const isFlexOperator = useCompanyIsFlexOperator();
  const companyDestinationsQuery = useGetCompanyDestinationsQuery({
    variables: { uuid: fm.values.ownerCompanyUuid },
    skip: !isFlexOperator,
  });
  const companyDestinations = companyDestinationsQuery.data?.companyV2?.companyDestinations;

  const output = useMemo(() => {
    const list = [
      site != null ? { uuid: site.uuid, name: site.name, tierLevel: site.tierLevel } : null,
      ...(clientDestinations ?? []),
      ...(companyDestinations ?? []).map((cd) => cd.destination),
    ]
      .filter(notNullOrUndefined)
      .sort((a, b) => a.name.localeCompare(b.name));

    return uniqBy(list, (d) => d.uuid);
  }, [site, clientDestinations, companyDestinations]);

  return output;
}
