import React, { useContext, useMemo, useState } from "react";
import { useTheme } from "../../../contexts/Theme";
import { FormGroupContext } from "../../../contexts/FormGroupContext";
import type { IconType } from "react-icons";

// eslint-disable-next-line @typescript-eslint/no-type-alias
type Props = Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, "value"> & {
  label: string;
  icon?: IconType;
  value?: boolean;
  onChange?: (value: boolean) => void;
};

export const FormToggleButton = React.forwardRef<HTMLButtonElement, Props>(
  ({ label, value: propsValue, onChange, className, icon: Icon, ...props }, ref) => {
    const { hasError } = useContext(FormGroupContext);
    const { colors } = useTheme();
    const [localValue, setLocalValue] = useState<boolean>(propsValue ?? false);

    const value = useMemo(() => propsValue ?? localValue, [propsValue, localValue]);

    const handleClick = () => {
      setLocalValue(!value);
      onChange?.(!value);
    };

    return (
      <>
        <button
          ref={ref}
          {...props}
          onClick={handleClick}
          className={`button ${value ? "button--active" : ""} ${hasError ? "button--error" : ""} ${
            className ?? ""
          }`.trim()}
        >
          {Icon != null && <Icon size={16} fill="#000" />}
          {label}
        </button>
        <style jsx>{`
          .button {
            background: #fff;
            font-size: 16px;
            line-height: 24px;
            color: #000000;
            border: 1px solid ${colors.transparent.black[10]};
            border-radius: 100px;
            display: inline-flex;
            gap: 8px;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 0.5rem 1.25rem;
            gap: 0.5rem;
            cursor: pointer;
          }

          .button:not(.button--active):hover {
            background-color: ${colors.transparent.black[5]};
          }

          .button--active {
            background-color: ${colors.blue[10]};
            border-color: ${colors.blue[60]};
          }

          .button--error {
            border-color: ${colors.status.danger.primary};
          }
        `}</style>
      </>
    );
  },
);

FormToggleButton.displayName = "FormToggleButton";
