import type { TFunction } from "@equiem/localisation-eq1";
import { stringNotEmpty } from "./stringNotEmpty";

interface Reception {
  building?: { name?: string | null } | null;
  buildingLevel?: { name?: string | null } | null;
  company?: { name?: string | null } | null;
  suffix?: string;
}

export const getReceptionName = (reception: Reception | null | undefined, t: TFunction) => {
  const companyName = reception?.company?.name;
  const buildingName = reception?.building?.name;
  const levelName = reception?.buildingLevel?.name;
  const levelSuffix = `${levelName ?? ""}${
    stringNotEmpty(reception?.suffix) ? `${levelName != null ? ", " : ""}${reception?.suffix}` : ""
  }`;

  if (companyName != null && buildingName != null) {
    return t("visitors.reception.displayName", {
      prefix: companyName,
      suffix: `${buildingName}${stringNotEmpty(levelSuffix) ? ` (${levelSuffix})` : ""}`,
    });
  }

  if (companyName != null) {
    return t("visitors.reception.displayName", {
      prefix: companyName,
      suffix: stringNotEmpty(levelSuffix) ? `(${levelSuffix})` : "",
    });
  }

  if (buildingName != null) {
    return t("visitors.reception.displayName", {
      prefix: buildingName,
      suffix: stringNotEmpty(levelSuffix) ? `(${levelSuffix})` : "",
    });
  }

  return t("visitors.common.reception");
};
