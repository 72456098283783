import React, { useContext } from "react";
import { Tag, useTheme } from "@equiem/react-admin-ui";
import { useTranslation } from "@equiem/localisation-eq1";

import type { BookingDiscount } from "../util/BookingDiscount";
import { transformDiscountType } from "../util/transformDiscountType";
import { BookingDiscountActions } from "./BookingDiscountActions";
import { BookingDiscountAddEditContext } from "./BookingDiscountAddEditProvider";
import { BookingDiscountStatusTag } from "./BookingDiscountStatusTag";

interface Props {
  discount: BookingDiscount;
}

export const BookingDiscountRow: React.FC<Props> = ({ discount }) => {
  const { t } = useTranslation();
  const { viewDiscount } = useContext(BookingDiscountAddEditContext);
  const { colors } = useTheme(true);

  return (
    <>
      <tr className="discount-row">
        {/* Each td except for the td .discounts-actions  will get its' own onClick handler
            because when the enitre .discount row element gets the onClick handler to view
            the discount, the <Dropdown.Item> element inside BookingDiscountActions starts
            acting in weird ways.
        */}
        <td className="discount-title" onClick={() => viewDiscount(discount)}>
          {discount.title}
        </td>
        <td className="discount-type" onClick={() => viewDiscount(discount)}>
          {transformDiscountType(discount.type, t)}
        </td>
        <td width={"50%"} className="discount-resources" onClick={() => viewDiscount(discount)}>
          {discount.resources.map((resource, i) => (
            <Tag key={i} className="me-2 mb-2" size="small">
              {resource.name}
            </Tag>
          ))}
        </td>
        <td className="discount-status" onClick={() => viewDiscount(discount)}>
          <BookingDiscountStatusTag status={discount.status} />
        </td>
        <td className="discount-actions">
          <BookingDiscountActions discount={discount} />
        </td>
      </tr>
      <style jsx>{`
        .discount-row:hover {
          background-color: ${colors.grayscale[3]};
        }
      `}</style>
    </>
  );
};
