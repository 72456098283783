import React from "react";
import { RiCloseLine } from "react-icons/ri";

import { useTheme } from "../../contexts/Theme";
import { AdminButton } from "../Button/AdminButton";
import { Button } from "../Button/Button";
import { Text } from "../Text/Text";

export type AlertType = "neutral" | "positive" | "negative" | "gray";
export type AlertSize = "normal" | "large";

export interface AlertAction {
  title: string;
  onClick: () => void;
}

export interface Props {
  message: string | React.ReactNode;
  description?: string;
  variant?: AlertType;
  size?: AlertSize;
  icon?: React.ReactNode;
  onClose?: () => void;
  primaryAction?: AlertAction;
  secondaryAction?: AlertAction;
  className?: string;
  centerIcon?: boolean;
}

export const Alert: React.FC<Props> = ({
  message,
  description,
  variant = "neutral",
  size = "normal",
  icon,
  onClose,
  primaryAction,
  secondaryAction,
  className,
  centerIcon,
}) => {
  const { colors, isAdmin } = useTheme();

  return (
    <>
      <div
        onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
          e.stopPropagation();
        }}
        className={`alert ${`alert--${variant} alert--${size} ${className ?? ""}`}`}
        role="alert"
        data-escape-focus-trap
      >
        <div className="left-group">
          {icon != null && <div className="icon-container">{icon}</div>}
          <div className="left-group-message">
            {typeof message === "string" ? (
              <Text variant="text" size="small" component="span">
                {message}
              </Text>
            ) : (
              message
            )}
            {description != null && (
              <Text variant="text" size="small" style={{ color: colors.grayscale[60] }} component="span">
                {description}
              </Text>
            )}
          </div>
        </div>
        {(secondaryAction != null || primaryAction != null || onClose != null) && (
          <div className="right-group">
            {secondaryAction != null && isAdmin && (
              <AdminButton variant="outline" size="sm" inverted onClick={secondaryAction.onClick}>
                {secondaryAction.title}
              </AdminButton>
            )}
            {secondaryAction != null && !isAdmin && (
              <Button variant="secondary" color="#fff" size="sm" onClick={secondaryAction.onClick}>
                {secondaryAction.title}
              </Button>
            )}
            {primaryAction != null && isAdmin && (
              <AdminButton variant="secondary" size="sm" inverted onClick={primaryAction.onClick}>
                {primaryAction.title}
              </AdminButton>
            )}
            {primaryAction != null && !isAdmin && (
              <Button variant="primary" color="#fff" size="sm" onClick={primaryAction.onClick}>
                {primaryAction.title}
              </Button>
            )}
            {onClose != null && size !== "large" && (
              <div className="close-container" role="button" aria-label="close" onClick={onClose}>
                <RiCloseLine size={24} />
              </div>
            )}
          </div>
        )}
      </div>
      <style jsx>{`
        .alert {
          color: #ffffff;
          padding: 0.75rem 1rem 0.75rem 1.5rem;
          border-radius: 0.5rem;
          display: flex;
          align-items: center;
          gap: 1.5rem;
          height: 3.5rem;
        }
        .alert :global(span) {
          font-weight: 500;
          line-height: 1.5rem;
        }

        .alert--large {
          flex-direction: column;
          align-items: flex-start;
          height: unset;
          padding-left: 1rem;
        }

        .alert--large :global(span) {
          line-height: 1.25rem;
        }

        .alert--large .left-group {
          align-items: flex-start;
        }

        .alert--neutral {
          background-color: ${colors.grayscale[80]};
        }
        .alert--positive {
          background-color: ${colors.blue[60]};
        }
        .alert--negative {
          background-color: ${colors.status.danger.primary};
        }
        .alert--gray {
          background-color: ${colors.grayscale[5]};
          color: #000;
        }
        .alert--gray :global(span) {
          font-weight: 400;
        }

        .icon-container {
          align-self: ${centerIcon === true ? "center" : "auto"};
        }
        .icon-container :global(svg) {
          display: block;
        }

        .right-group {
          display: flex;
          align-items: center;
          margin-left: auto;
          gap: 0.5rem;
        }

        .left-group {
          display: flex;
          align-items: center;
          gap: 0.5rem;
        }

        .left-group-message {
          display: flex;
          flex-direction: column;
          gap: 5px;
        }

        .close-container {
          cursor: pointer;
        }
      `}</style>
    </>
  );
};
