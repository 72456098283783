import React, { useState } from "react";

import { Text } from "@equiem/react-admin-ui";
import { RiArrowDownSLine, RiArrowUpSLine } from "@equiem/react-admin-ui/icons";

interface Profile {
  uuid: string;
}

interface Props<T> {
  profile: T;
}

/**
 * Display user uuid in a collapsible section.
 */
export const UserDebugInfo = <T extends Profile>({ profile }: Props<T>) => {
  const [show, setShow] = useState(false);

  return (
    <div className="mb-5">
      <hr className="mt-6" />
      <Text
        variant="heading"
        size="small"
        onClick={() => {
          setShow(!show);
        }}
      >
        Debugging information {show ? <RiArrowUpSLine /> : <RiArrowDownSLine />}
      </Text>
      {show ? (
        <div className="container">
          <div className="d-flex mt-5">
            <div className="flex-fill font-weight-bold">UUID</div>
            <div className="flex-fill">{profile.uuid}</div>
          </div>
        </div>
      ) : null}
    </div>
  );
};
