import { useContext } from "react";
import { Site } from "@equiem/lib";
import type { MyPostsQuery } from "../generated/iris-client";

export const getNewsPostUrl = (uuid: string, webAppUrl: string) => {
  return `https://${webAppUrl}/news/${uuid}`;
};

export const getBuildingInfoPostUrl = (uuid: string, webAppUrl: string) => {
  return `https://${webAppUrl}/info/${uuid}`;
};

export const getEventPostUrl = (uuid: string, webAppUrl: string) => {
  return `https://${webAppUrl}/node/uuid/${uuid}`;
};

export const useContentUrl = (post: MyPostsQuery["myPosts"][number]) => {
  const { webAppUrl } = useContext(Site);

  switch (post.__typename) {
    case "event_post":
      return `https://${webAppUrl}/event/${post.repeats?.occurrences?.[0]?.uuid ?? ""}`;
    case "news_post":
      return getNewsPostUrl(post.uuid, webAppUrl);
    case "building_info_post":
      return getBuildingInfoPostUrl(post.uuid, webAppUrl);

    default:
      throw new Error(`Content type: ${post.__typename} not supported`);
  }
};
