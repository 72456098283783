import { Button, Modal, useToast } from "@equiem/react-admin-ui";
import React, { useContext } from "react";
import type { ShortcutInput } from "../../../generated/gateway-client";
import { useSaveEquiemOneShortcutsMutation } from "../../../generated/gateway-client";
import { CurrentRole } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";

export const ShortcutDeleteModal: React.FC<{ shortcuts: ShortcutInput[]; close: () => void; i: number }> = ({
  shortcuts,
  close,
  i,
}) => {
  const [mutation] = useSaveEquiemOneShortcutsMutation();
  const { currentRole } = useContext(CurrentRole);
  const toast = useToast();
  const { t } = useTranslation();

  const deleteSc = async () => {
    await mutation({
      variables: {
        input: {
          role: currentRole,
          shortcuts: shortcuts
            .filter((_sc, li) => li !== i)
            .map((sc) => ({
              icon: sc.icon,
              title: sc.title,
              url: sc.url,
            })),
        },
      },
      refetchQueries: ["MyProfile"],
    });
    toast.positive(t("home.sideMenu.yourShortcutHasBeenSuccessfullyDeleted"));
    close();
  };

  return (
    <>
      <Modal.Header closeButton={true} noBorder={true} />
      <Modal.Body>
        <div>{t("home.sideMenu.areYouSureYouWantDelete", { title: shortcuts[i].title })}</div>
      </Modal.Body>
      <Modal.Footer>
        <Button size="md" variant="ghost" className="mr-3" onClick={close}>
          {t("common.cancel")}
        </Button>
        <Button variant="danger" size="md" onClick={deleteSc}>
          {t("home.sideMenu.deleteShortcut")}
        </Button>
      </Modal.Footer>
      <style jsx>{`
        h2 {
          font-weight: 400;
          size: 18px;
          line-height: 28px;
        }
      `}</style>
    </>
  );
};
