import { useTranslation } from "@equiem/localisation-eq1";
import { useTheme } from "@equiem/react-admin-ui";
import Link from "next/link";
import { useRouter } from "next/router";
import React, { useMemo } from "react";
import type { CmsArticleFragmentFragment } from "../../generated/gateway-client";
import { ArticlePublishingTag } from "./ArticlePublishingTag";

interface Props {
  article: CmsArticleFragmentFragment;
}

const blank = "data:image/svg+xml,%3Csvg viewBox='0 0 300 200' xmlns='http://www.w3.org/2000/svg'%3E%3C/svg%3E";

export const ArticleCard: React.FC<Props> = ({ article }) => {
  const { colors, spacers, borderRadius } = useTheme();
  const router = useRouter();
  const { t } = useTranslation();
  const { id } = router.query as { id: string };

  const imgUrl: string = useMemo(() => {
    if (article.draftContent.images != null && article.draftContent.images.length > 0) {
      const firstImage = article.draftContent.images[0];
      if (firstImage.value.__typename === "ImageV2") {
        return firstImage.value.imageUrl;
      }
    }
    return blank;
  }, [article]);

  return (
    <>
      <Link
        href={{
          pathname: "/content-management/[id]/articles/[slug]",
          query: {
            ...router.query,
            id,
            slug: article.uuid,
          },
        }}
        legacyBehavior
      >
        <a className="article-card">
          <img className="mb-2" src={imgUrl} />
          <small className="published text-muted mb-2"></small>
          <div className="tags mb-2">
            <ArticlePublishingTag article={article} />
          </div>
          <div className="title mb-2">{article.draftContent.title ?? t("contentManagement.articles.defaultTitle")}</div>
          <div className="excerpt text-muted">{article.draftContent.excerpt}</div>
        </a>
      </Link>
      <style jsx>{`
        .article-card {
          display: block;
          position: relative;
          width: 272px;
          padding: ${spacers.s3};
          border-radius: ${borderRadius};
          cursor: pointer;
          text-decoration: none;
        }
        .article-card:hover {
          background-color: ${colors.transparent.black[5]};
        }

        img {
          width: 100%;
          aspect-ratio: 3 / 2;
          border-radius: ${borderRadius};
          object-position: center;
          object-fit: cover;
          background: #efefef;
        }

        .published {
          font-weight: 500;
          font-size: 10px;
          line-height: 16px;
          text-transform: uppercase;
        }

        .title {
          font-weight: 700;
          font-size: 16px;
          line-height: 20px;
        }

        .excerpt {
          font-weight: 400px;
          font-size: 14px;
          line-height: 16px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
      `}</style>
    </>
  );
};
