import React, { useContext } from "react";
import { Button } from "../../../Button/Button";
import type { ComponentProps } from "react";
import { FormRichSelectContext } from "../FormRichSelectContext";
import { useTheme } from "../../../../contexts/Theme";

export const FormRichSelectItem: React.FC<
  ComponentProps<typeof Button> & {
    notSelectable?: boolean;
    paddingX?: boolean;
    paddingY?: boolean;
    sticky?: boolean;
    allowOverflow?: boolean;
  }
> = ({
  children,
  paddingX = true,
  paddingY = true,
  sticky = false,
  notSelectable = false,
  allowOverflow = false,
  onClick,
}) => {
  const { spacers, colors, breakpoints } = useTheme();
  const { setOpen } = useContext(FormRichSelectContext);

  return (
    <div role="option">
      <span>{children}</span>
      {notSelectable ? null : (
        <Button
          size="sm"
          variant="secondary"
          type="button"
          onClick={(e) => {
            setOpen(false);
            if (onClick != null) {
              onClick(e);
            }
          }}
        >
          Select
        </Button>
      )}
      <style jsx>{`
        div {
          display: flex;
          justify-content: space-between;
          background: ${colors.white};
          align-items: center;
          padding: ${paddingY ? spacers.s3 : ""} ${paddingX ? spacers.s3 : "0"};
          border-bottom: 1px solid ${colors.border};
          margin: 0 ${spacers.s3};
          position: ${sticky ? "sticky" : "relative"};
          top: ${sticky ? "0" : "auto"};
          z-index: ${sticky ? "1" : "0"};
        }
        div:last-child {
          border-bottom: none;
        }
        span {
          flex-grow: 1;
          width: 100%;
          max-width: ${notSelectable ? "none" : "calc(100% - 4.5rem)"};
          overflow: ${allowOverflow ? "visible" : "hidden"};
          text-overflow: ${allowOverflow ? "initial" : "ellipsis"};
        }
        @media (max-width: ${breakpoints.sm}px) {
          div {
            padding: ${paddingY ? spacers.s3 : ""} 0;
          }
        }
      `}</style>
    </div>
  );
};
