import React from "react";
import { useTheme } from "@equiem/react-admin-ui";

type ListProps = React.DetailedHTMLProps<React.HTMLAttributes<HTMLUListElement>, HTMLUListElement>;
type ItemProps = React.DetailedHTMLProps<React.LiHTMLAttributes<HTMLLIElement>, HTMLLIElement>;

export const BookingActivityList: React.FC<ListProps> = ({ children, ...props }) => (
  <>
    <ul {...props}>{children}</ul>
    <style jsx>{`
      ul {
        position: relative;
        list-style: none;
        padding-inline-start: 0;
      }
    `}</style>
  </>
);

export const BookingActivityListItem: React.FC<ItemProps> = ({ children, ...props }) => {
  const { colors } = useTheme();
  return (
    <>
      <li {...props}>{children}</li>
      <style jsx>{`
        li {
          margin: 0;
          padding: 0;
          padding-bottom: 1.5rem;
          position: relative;
          padding-left: 1rem;
          margin-left: 0.5rem;
          border-left: 1px solid ${colors.grayscale[10]};
        }
        li:last-child {
          border-left: none;
        }
        li:before {
          content: "";
          display: inline-block;
          height: 1rem;
          width: 1rem;
          position: absolute;
          left: -0.5rem;
          top: 0;
          border-radius: 0.5em;
          background: ${colors.grayscale[10]};
        }
      `}</style>
    </>
  );
};
