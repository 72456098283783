/* eslint-disable @typescript-eslint/no-magic-numbers */

export const isValid = (str: string | null | undefined): str is string => {
  return str != null && /^\d*:?\d?\d?$/u.test(str);
};

export const toMinutes = (str: string | null | undefined): number | null => {
  if (!isValid(str)) {
    return null;
  }

  const tokens = str.split(":");
  const hoursStr = tokens.length === 1 || tokens[0] === "" ? "0" : tokens[0];
  const minutesStr = tokens.length === 1 ? tokens[0] : tokens[1] === "" ? "0" : tokens[1];

  const mins = 60 * parseInt(hoursStr) + parseInt(minutesStr);
  if (Number.isNaN(mins)) {
    return null;
  }

  return mins;
};

export const fromMinutes = (mins: number | null | undefined): string | null => {
  if (mins == null || Number.isNaN(mins) || mins < 0) {
    return null;
  }

  const hours = Math.floor(mins / 60);
  const minutes = mins % 60;

  const hoursStr = hours.toString(10).padStart(2, "0");
  const minutesStr = minutes.toString(10).padStart(2, "0");

  return `${hoursStr}:${minutesStr}`;
};

export const format = (str: string | null | undefined): string | null | undefined => {
  return fromMinutes(toMinutes(str));
};

export default {
  isValid,
  toMinutes,
  fromMinutes,
  format,
};
