import { useToast } from "@equiem/react-admin-ui";

import { useCancelResourceDeletionMutation } from "../generated/gateway-client";
import { useTranslation } from "@equiem/localisation-eq1";

interface Params {
  uuid: string;
  name: string;
  onSuccess: () => unknown;
}

export const useCancelResourceDeletion = ({ uuid, name, onSuccess }: Params) => {
  const toast = useToast();
  const [cancelDeletionMutation, { loading: cancelLoading }] = useCancelResourceDeletionMutation();
  const { t } = useTranslation();

  return async () => {
    try {
      if (cancelLoading) {
        return;
      }

      const result = await cancelDeletionMutation({ variables: { uuid } });
      if (result.errors != null) {
        throw new Error(`failed to cancel deletion: ${JSON.stringify(result)}`);
      }

      toast.positive(t("bookings.operations.cancelledDeletion", { name }));
      onSuccess();
    } catch (e: unknown) {
      console.error(e);
      toast.negative(t("bookings.operations.failedToCancelDeletion"));
    }
  };
};
