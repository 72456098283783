import type { AdminThemeContext, ThemeContext } from "../contexts";

const propMap: Record<string, string> = {
  m: "margin",
  p: "padding",
};

export const utilityClasses = (theme: ThemeContext | AdminThemeContext, parent = "") => {
  let styles = `
     ${parent} .m-auto {
      margin: auto !important;
    }
     ${parent} .mt-auto,
     ${parent} .my-auto {
      margin-top: auto !important;
    }

     ${parent} .mr-auto,
     ${parent} .mx-auto {
      margin-right: auto !important;
    }

     ${parent} .mb-auto,
     ${parent} .my-auto {
      margin-bottom: auto !important;
    }

     ${parent} .ml-auto,
     ${parent} .mx-auto {
      margin-left: auto !important;
    }

     ${parent} .small { font-size: 0.8rem; }

     ${parent} .font-weight-bold   { font-weight: 700!important; }
     ${parent} .font-weight-normal { font-weight: 400!important; }
     ${parent} .font-weight-light  { font-weight: 300!important; }

     ${parent} .d-none              { display: none !important; }
     ${parent} .d-flex              { display: flex !important; }
     ${parent} .flex-row            { flex-direction: row !important; }
     ${parent} .flex-column         { flex-direction: column !important; }
     ${parent} .flex-row-reverse    { flex-direction: row-reverse !important; }
     ${parent} .flex-column-reverse { flex-direction: column-reverse !important; }

     ${parent} .flex-wrap         { flex-wrap: wrap !important; }
     ${parent} .flex-nowrap       { flex-wrap: nowrap !important; }
     ${parent} .flex-wrap-reverse { flex-wrap: wrap-reverse !important; }
     ${parent} .flex-fill         { flex: 1 1 auto !important; }
     ${parent} .flex-grow-0       { flex-grow: 0 !important; }
     ${parent} .flex-grow-1       { flex-grow: 1 !important; }
     ${parent} .flex-shrink-0     { flex-shrink: 0 !important; }
     ${parent} .flex-shrink-1     { flex-shrink: 1 !important; }
     ${parent} .flex-basis-0      { flex-basis: 0 !important; }

     ${parent} .justify-content-start   { justify-content: flex-start !important; }
     ${parent} .justify-content-end     { justify-content: flex-end !important; }
     ${parent} .justify-content-center  { justify-content: center !important; }
     ${parent} .justify-content-between { justify-content: space-between !important; }
     ${parent} .justify-content-around  { justify-content: space-around !important; }

     ${parent} .align-items-start    { align-items: flex-start !important; }
     ${parent} .align-items-end      { align-items: flex-end !important; }
     ${parent} .align-items-center   { align-items: center !important; }
     ${parent} .align-items-baseline { align-items: baseline !important; }
     ${parent} .align-items-stretch  { align-items: stretch !important; }

     ${parent} .align-content-start   { align-content: flex-start !important; }
     ${parent} .align-content-end     { align-content: flex-end !important; }
     ${parent} .align-content-center  { align-content: center !important; }
     ${parent} .align-content-between { align-content: space-between !important; }
     ${parent} .align-content-around  { align-content: space-around !important; }
     ${parent} .align-content-stretch { align-content: stretch !important; }

     ${parent} .align-self-auto     { align-self: auto !important; }
     ${parent} .align-self-start    { align-self: flex-start !important; }
     ${parent} .align-self-end      { align-self: flex-end !important; }
     ${parent} .align-self-center   { align-self: center !important; }
     ${parent} .align-self-baseline { align-self: baseline !important; }
     ${parent} .align-self-stretch  { align-self: stretch !important; }

     ${parent} .justify-self-end   { justify-self: flex-end !important; }

     ${parent} .text-left   { text-align: left !important; }
     ${parent} .text-right  { text-align: right !important; }
     ${parent} .text-center { text-align: center !important; }
     ${parent} .text-muted  { color: ${theme.colors.muted0} !important; }
     ${parent} .text-medium { color: ${theme.colors.medium} !important; }
     ${parent} .text-decoration-none { text-decoration: none !important; }

     ${parent} .cursor-pointer { cursor: pointer }

    ${parent} .h-100 { height: 100%; }

     ${parent} .w-25 { width: 25% !important; }
     ${parent} .w-50 { width: 50% !important; }
     ${parent} .w-75 { width: 75% !important; }
     ${parent} .w-100 { width: 100% !important; }

     ${parent} .bg-white { background-color: #fff!important; }
  `;

  Object.keys(propMap).forEach((abbreviation) => {
    Object.keys(theme.spacers).forEach((key) => {
      const spacer = key as keyof typeof theme.spacers;
      const size = spacer.substring(1);
      const prop = propMap[abbreviation];
      const length = theme.spacers[spacer];

      styles = `
        ${styles}
         ${parent} .${abbreviation}-${size} {
          ${prop}: ${length} !important;
        }

         ${parent} .${abbreviation}t-${size},
         ${parent} .${abbreviation}y-${size} {
          ${prop}-top: ${length} !important;
        }

         ${parent} .${abbreviation}r-${size},
         ${parent} .${abbreviation}e-${size},
         ${parent} .${abbreviation}x-${size} {
          ${prop}-right: ${length} !important;
        }

         ${parent} .${abbreviation}b-${size},
         ${parent} .${abbreviation}y-${size} {
          ${prop}-bottom: ${length} !important;
        }

         ${parent} .${abbreviation}l-${size},
         ${parent} .${abbreviation}s-${size},
         ${parent} .${abbreviation}x-${size} {
          ${prop}-left: ${length} !important;
        }
      `;
    });

    // Negative margins (eg, where `.mb-n1` is negative version of `.mb-1`)
    Object.keys(theme.spacers).forEach((key) => {
      const spacer = key as keyof typeof theme.spacers;
      const size = spacer.charAt(1);
      if (size === "0") {
        return;
      }

      if (abbreviation === "p") {
        return;
      }

      const prop = propMap[abbreviation];
      const length = theme.spacers[spacer];

      styles = `
        ${styles}
         ${parent} .m-n${size} {
          ${prop}: -${length} !important;
        }

         ${parent} .mt-n${size},
         ${parent} .my-n${size} {
          ${prop}-top: -${length} !important;
        }

         ${parent} .mr-n${size},
         ${parent} .me-n${size},
         ${parent} .mx-n${size} {
          ${prop}-right: -${length} !important;
        }

         ${parent} .mb-n${size},
         ${parent} .my-n${size} {
          ${prop}-bottom: -${length} !important;
        }

         ${parent} .ml-n${size},
         ${parent} .ms-n${size},
         ${parent} .mx-n${size} {
          ${prop}-left: -${length} !important;
        }
      `;
    });
  });

  return styles;
};
