import type { ComponentProps } from "react";
import React, { useState, useContext } from "react";

import { useTheme } from "../../../contexts/Theme";
import { FormGroupContext } from "../../../contexts/FormGroupContext";

interface Props extends Omit<ComponentProps<"input">, "type" | "value" | "checked"> {
  label: string;
  description?: string;
  value?: boolean;
  children?: React.ReactNode;
}

export const FormHeroCheckboxOld = React.forwardRef<HTMLInputElement, Props>((props, ref) => {
  const { label, description, value: propsValue, onChange, children, ...inputProps } = props;

  const { colors, isAdmin, borderRadius, spacers } = useTheme();
  const { id, hintId } = useContext(FormGroupContext);

  const [localValue, setLocalValue] = useState<boolean>(propsValue ?? false);
  const value = propsValue ?? localValue;

  return (
    <>
      <div className="container">
        <label className="row control-row" htmlFor={props.id ?? id}>
          <div className="control">
            <input
              {...inputProps}
              ref={ref}
              id={props.id ?? id}
              aria-describedby={hintId}
              type="checkbox"
              checked={value}
              onChange={(e) => {
                setLocalValue(e.target.checked);
                if (onChange != null) {
                  onChange(e);
                }
              }}
            />
          </div>
          <div className="info">
            <div className="label">{label}</div>
            <div className="description">{description}</div>
          </div>
        </label>
        {value && children != null && children !== false && <div className="row children-row">{children}</div>}
      </div>
      <style jsx>{`
        .container {
          display: inline-block;
          white-space: nowrap;
          max-width: 100%;
          border: 1px solid ${colors.border};
          border-radius: ${borderRadius};
        }
        .row {
          display: block;
          padding: ${spacers.s7} ${spacers.s6};
        }
        .control-row {
          cursor: pointer;
        }
        .control {
          display: inline-block;
          vertical-align: middle;
        }
        .info {
          display: inline-block;
          vertical-align: middle;
          padding-left: ${spacers.s6};
          padding-right: ${spacers.s7};
        }
        .label {
          font-size: 16px;
          line-height: 24px;
          font-weight: 600;
          white-space: normal;
        }
        .description {
          font-size: 12px;
          line-height: 16px;
          color: ${isAdmin ? colors.medium : colors.primary};
          white-space: normal;
        }
        .children-row {
          border-top: 1px solid ${colors.border};
          white-space: normal;
        }
        /** Handle the common expected use case where checkbox children are a set of FormGroups */
        :global(.form-group:last-child) {
          margin-bottom: 0 !important;
        }
      `}</style>
    </>
  );
});

FormHeroCheckboxOld.displayName = "FormHeroCheckboxOld";
