import { ModalBody } from "./ModalBody/ModalBody";
import { ModalDialog } from "./ModalDialog";
import { ModalFooter } from "./ModalFooter/ModalFooter";
import { ModalHeader } from "./ModalHeader/ModalHeader";
import { ModalHeaderTab } from "./ModalHeaderTab/ModalHeaderTab";
import { ModalHeaderTabs } from "./ModalHeaderTabs/ModalHeaderTabs";

export {
  ModalDialog as Dialog,
  ModalHeader as Header,
  ModalBody as Body,
  ModalHeaderTab as HeaderTab,
  ModalHeaderTabs as HeaderTabs,
  ModalFooter as Footer,
};

export default ModalDialog;
