import React, { useCallback, useContext, useState } from "react";
import { Card, Tag, Toggler } from "@equiem/react-admin-ui";
import { useTranslation } from "@equiem/localisation-eq1";

import { CurrentSegment } from "../contexts/SegmentProvider";
import { useSummaryFilter } from "../hooks/useSummaryForFilter";
import { useSummaryForUserTypeFilter } from "../hooks/useSummaryForUserTypeFilter";
import { translateFilterType } from "../lib/mapGraphqlFilterType";
import { EmptyFilters } from "./EmptyFilters";
import { Filter } from "./Filter";
import { SegmentSuffix } from "./SegmentSuffix";
import { UserTypeFilter } from "./UserTypeFilter";

interface Props {
  id: string;
  title: string;
  defaultOpen: boolean;
  remove: (id: string) => void;
}

export const Segment: React.FC<Props> = ({ defaultOpen, id, remove, title }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(defaultOpen);
  const { activeFilters, filterItems, estimatedAudience, commercialSelected, visitorsSelected, residentialSelected } =
    useContext(CurrentSegment);
  const [filterSummary] = useSummaryFilter(filterItems);
  const [utFilterSummary] = useSummaryForUserTypeFilter(commercialSelected, visitorsSelected, residentialSelected);

  const summary = useCallback(() => {
    return activeFilters.map((af) => {
      if (af.type === "userType") {
        const sum = utFilterSummary() ?? t("common.emptyNone");
        return (
          <div key={af.type}>
            <strong>{translateFilterType(af.type, t)}</strong>: {sum}
          </div>
        );
      } else {
        const sum = filterSummary(af.type);

        return (
          <div key={af.type}>
            <strong>{translateFilterType(af.type, t)}</strong>: {sum}
          </div>
        );
      }
    });
  }, [activeFilters, filterSummary, utFilterSummary, t]);

  return (
    <>
      <Toggler
        defaultOpen={defaultOpen}
        onToggle={setOpen}
        className="segment mb-2"
        heading={title}
        subheading={
          <>
            <Tag className="my-1">{t("segmentation.estimatedUserCountSegment", { count: estimatedAudience })}</Tag>
            {!open && <div className="segment-summary">{summary()}</div>}
          </>
        }
        headingSuffix={<SegmentSuffix id={id} open={open} remove={remove} />}
      >
        <Card.Card>
          <Card.CardBody>
            {activeFilters.map((f) => {
              switch (f.type) {
                case "userType":
                  return <UserTypeFilter segmentId={id} key={f.type} filter={f.type} />;
                default:
                  return <Filter segmentId={id} key={f.type} filter={f} />;
              }
            })}
            {activeFilters.length === 0 && <EmptyFilters />}
          </Card.CardBody>
        </Card.Card>
      </Toggler>
    </>
  );
};
