import { stringNotEmpty, useSiteContext, getHostInfo } from "@equiem/lib";
import { Button, ProgressCircle, Skeleton } from "@equiem/react-admin-ui";
import React, { useState } from "react";
import { usePaymentGatewayClientIdQuery } from "../../../generated/gateway-client";
import { useRouter } from "next/router";
import { useTranslation } from "@equiem/localisation-eq1";
import { getStripeConnectState } from "../../../lib/stripeState";

// Time window in ms we allow to take to connect to stripe (7 days).
// eslint-disable-next-line @typescript-eslint/no-magic-numbers
const offset = 7 * 24 * 60 * 60 * 1000;

export const getStripeUrls = (clientId: string, siteUuid: string) => {
  const state = getStripeConnectState(siteUuid);

  const { defaultHost } = getHostInfo(window.location.hostname);
  const returnUri = `https://${defaultHost}/api/stripe-connect`;

  const query = new URLSearchParams({
    response_type: "code",
    client_id: clientId,
    scope: "read_write",
    redirect_uri: returnUri,
    state,
  });

  return {
    state,
    returnUri,
    url: `https://connect.stripe.com/oauth/authorize?${query.toString()}`,
  };
};

export const ConnectStripe: React.FC = () => {
  const { t } = useTranslation();
  const site = useSiteContext();
  const router = useRouter();
  const { data, loading, error } = usePaymentGatewayClientIdQuery({ variables: { uuid: site.uuid } });
  const [submitting, setSubmitting] = useState(false);

  if (loading) {
    return <Skeleton.Line width="200px" height="35px" />;
  }

  const clientId = data?.destination.paymentGatewayClientId;
  try {
    if (error != null) {
      console.log(error);
      throw new Error(t("bookings.settings.unableToLoadPaymentDetails"));
    }

    if (!stringNotEmpty(clientId)) {
      throw new Error(t("bookings.settings.unableToFindPaymentDetails"));
    }
  } catch (e: unknown) {
    return (
      <div>
        {t("bookings.settings.stringContactSupport", {
          message: e instanceof Error ? e.message : t("bookings.settings.unknownError"),
        })}
      </div>
    );
  }

  const connect = () => {
    if (submitting) {
      return;
    }

    setSubmitting(true);
    const { state, url } = getStripeUrls(clientId, site.uuid);
    window.localStorage.setItem(state, `${Date.now() + offset}`);
    router.push(url).catch(console.log);
  };

  return (
    <div>
      <Button variant="outline" onClick={connect} disabled={submitting}>
        {submitting && <ProgressCircle size="xs" />}
        {t("bookings.settings.connectStripeAccount")}
      </Button>
    </div>
  );
};
