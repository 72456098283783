import type { ReactNode } from "react";
import React, { useEffect } from "react";
import { useTranslation, formatters } from "@equiem/localisation-eq1";
import { setLocale } from "yup";

const tryFormatValue = (value: unknown, language: string) => {
  if (typeof value === "string") {
    return value;
  }
  if (typeof value === "number") {
    return formatters.number(value, language);
  }
  if (value instanceof Date) {
    return formatters.datelong(value, language);
  }

  return JSON.stringify(value);
};

const formatOptions = (values: unknown, language: string): string => {
  if (!Array.isArray(values)) {
    // yup has given us something weird... do the best we can
    return tryFormatValue(values, language);
  }

  return formatters.list(
    values.map((value) => tryFormatValue(value, language)),
    language,
    { style: "short", type: "disjunction" },
  );
};

export const YupLocalisation: React.FC<{ children: ReactNode }> = ({ children }) => {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    // Messages lightly adapted from Yup's inbuilt locale:
    // https://github.com/jquense/yup/blob/v0.32.11/src/locale.ts
    setLocale({
      mixed: {
        default: ({ path }) => t("common.validation.default", { path }),
        required: ({ path }) => t("common.validation.required", { path }),
        oneOf: ({ path, values }) =>
          t("common.validation.oneOf", {
            path,
            values: formatOptions(values, i18n.language),
          }),
        notOneOf: ({ path, values }) =>
          t("common.validation.notOneOf", {
            path,
            values: formatOptions(values, i18n.language),
          }),
        // omitted:
        // - defined
        // - notType
      },
      string: {
        min: ({ path, min }) => t("common.validation.stringMin", { path, min }),
        max: ({ path, max }) => t("common.validation.stringMax", { path, max }),
        email: ({ path }) => t("common.validation.email", { path }),
        // omitted:
        // - length
        // - matches
        // - url
        // - uuid
        // - trim
        // - lowercase
        // - uppercase
      },
      number: {
        min: ({ path, min }) => t("common.validation.numberMin", { path, min }),
        max: ({ path, max }) => t("common.validation.numberMax", { path, max }),
        // omitted:
        // - lessThan
        // - moreThan
        // - positive
        // - negtive
        // - integer
      },
      date: {
        min: ({ path, min }) => t("common.validation.dateMin", { path, min: String(min) }),
        max: ({ path, max }) => t("common.validation.dateMax", { path, max: String(max) }),
      },
      boolean: {
        isValue: ({ path, value }: { path: string; value: unknown }) =>
          t("common.validation.exactValue", { path, value: String(value) }),
      },
      object: {
        // omitted:
        // - noUnknown
      },
      array: {
        min: ({ path, min }) => t("common.validation.arrayMin", { path, min }),
        max: ({ path, max }) => t("common.validation.arrayMax", { path, max }),
        // omitted:
        // - length
      },
    });
  }, [t, i18n.language]);

  return <>{children}</>;
};
