import { useTranslation } from "@equiem/localisation-eq1";
import { Form } from "@equiem/react-admin-ui";
import { Field, useFormikContext } from "formik";
import React from "react";
import { CKEditorNoSSR } from "../../../../../components/CKEditorNoSSR";
import type { FormValues } from "../../../../../lib/formValidation";

export const ResourceCreateAndEditFormTermsAndConditions: React.FC = () => {
  const { t } = useTranslation();
  const fm = useFormikContext<FormValues>();

  return (
    <Form.Group error={fm.errors.termsAndConditions}>
      <Field
        id="termsAndConditions"
        name="termsAndConditions"
        placeholder={t("bookings.resources.termsAndConditions")}
        as={CKEditorNoSSR}
        toolbar={[
          "heading",
          "|",
          "bold",
          "italic",
          "|",
          "link",
          "|",
          "bulletedList",
          "numberedList",
          "|",
          "outdent",
          "indent",
          "|",
          "undo",
          "redo",
        ]}
        disabled={fm.isSubmitting}
      />
    </Form.Group>
  );
};
