export interface OnChange {
  name: string;
  target: {
    name: string;
    value: string[];
  };
}

export const onChangeObject = (name: string | null | undefined, value: string[]) => ({
  name: name ?? "resources",
  target: {
    name: name ?? "resources",
    value,
  },
});
