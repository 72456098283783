import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import Link from "next/link";

import { CurrentRole, getReceptionName, notNullOrUndefined, Role, stringIsEmpty, SubTopMenu } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import { Button, ButtonLink, Form, ProgressCircle, Tooltip, useTheme, useToast } from "@equiem/react-admin-ui";
import { RiFileDownloadLine, RiSearchLine } from "@equiem/react-admin-ui/icons";

import { Modal } from "../../contexts/ModalContext";
import { withContexts } from "../../contexts/withContexts";
import type { VisitorReceptionsQuery } from "../../generated/visitors-client";
import { useCancelReceptionDeletionMutation, useVisitorReceptionsQuery } from "../../generated/visitors-client";
import { filterReceptionSettingsList } from "../../helpers/filterReceptionList";
import { getReceptionRole } from "../../helpers/receptionRole";
import { useReceptionsSettingsExportData } from "../reception/hooks/useReceptionsSettingsExportData";

import { Menu } from "./components/NestedMenu";
import { NoReceptions } from "./components/NoReceptions";
import ReceptionItem from "./components/ReceptionItem";

const ReceptionsAppointmentBase = () => {
  const { t } = useTranslation();
  const toast = useToast();
  const { currentRole } = useContext(CurrentRole);
  const [cancelDelete] = useCancelReceptionDeletionMutation();
  const modal = useContext(Modal);
  const theme = useTheme(true);
  const {
    data: receptionsData,
    refetch,
    fetchMore,
    loading,
  } = useVisitorReceptionsQuery({
    variables: { as: getReceptionRole(currentRole), first: 1000 },
  });

  const [search, setSearch] = useState<string>("");

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };
  const clearSearch = () => {
    setSearch("");
  };

  useEffect(() => {
    void refetch();
  }, [modal.activeModal]);

  const receptions = useMemo(() => {
    const result = filterReceptionSettingsList<VisitorReceptionsQuery>(receptionsData, search);
    return result == null ? [] : result.visitorReceptions.edges.map((e) => e.node).filter(notNullOrUndefined);
  }, [receptionsData, search]);
  const { exportCsv, reportLoading } = useReceptionsSettingsExportData({
    search: search,
    role: getReceptionRole(currentRole),
  });

  const handleCancelDelete = async (uuid: string) => {
    const result = await cancelDelete({ variables: { uuid } });
    if (result.errors == null) {
      toast.positive(
        t("visitors.settings.receptionCancelDeletion", {
          receptionName: getReceptionName(
            receptions.find((reception) => reception.uuid === uuid),
            t,
          ),
        }),
      );
    } else {
      toast.negative(t("common.unknownError"));
    }
    await refetch();
  };

  const handleNextPage = useCallback(
    async () =>
      fetchMore({
        variables: {
          after: receptionsData?.visitorReceptions.pageInfo.endCursor,
        },
      }),
    [receptionsData?.visitorReceptions.pageInfo.endCursor],
  );

  const hasMoreReceptions = receptionsData?.visitorReceptions.pageInfo.hasNextPage ?? false;

  return (
    <div className="page-container ">
      <SubTopMenu btmPadding={false} topPadding={false}>
        <h1 className="font-weight-bold mb-2">Settings</h1>
      </SubTopMenu>
      <SubTopMenu btmPadding={false} topPadding={false} minHeight={false} alignItems="flex-end" sticky>
        <Menu />
      </SubTopMenu>
      <div className="d-flex mt-5 main-container">
        <div className="left-container">
          <div className="d-flex " style={{ justifyContent: "space-between", alignItems: "center" }}>
            <h1 className="item-description">{t("visitors.settings.manageReceptions")}</h1>
            <div className="push"></div>
            {currentRole === Role.PropertyManager && (
              <div className="mr-3">
                <Tooltip title={t("visitors.reception.csvExport")} placement="bottom">
                  <Button
                    variant="ghost"
                    round
                    disabled={reportLoading}
                    onClick={() => {
                      void exportCsv();
                    }}
                    className="export-csv"
                  >
                    {reportLoading ? <ProgressCircle size={16} /> : <RiFileDownloadLine size={16} />}
                  </Button>
                </Tooltip>
              </div>
            )}
            <div className="search-container mr-3">
              <Form.InputExtended
                className="search-input"
                icon={RiSearchLine}
                placeholder={`${t("common.search")}...`}
                variant="sm"
                value={search}
                onChange={handleSearch}
                onClear={clearSearch}
                clearable
              />
            </div>
            <Link href="create-reception" passHref legacyBehavior>
              <ButtonLink variant="secondary" size="md">
                {t("visitors.receptions.createReception")}
              </ButtonLink>
            </Link>
          </div>

          {loading && receptions.length === 0 ? (
            <div className="mt-6 mb-6 d-flex justify-content-center">
              <ProgressCircle size="md" />
            </div>
          ) : (!loading || !stringIsEmpty(search)) && receptions.length === 0 ? (
            <NoReceptions />
          ) : (
            <InfiniteScroll
              next={handleNextPage}
              dataLength={receptions.length}
              hasMore={hasMoreReceptions}
              style={{ overflow: undefined }}
              loader={
                <div className="mt-6 mb-6 d-flex justify-content-center">
                  <ProgressCircle size="md" />
                </div>
              }
            >
              <div className="pb-6">
                {receptions.map((reception) => (
                  <ReceptionItem
                    key={reception.uuid}
                    uuid={reception.uuid}
                    companyName={reception.company?.name}
                    levelName={reception.buildingLevel?.name}
                    buildingName={reception.building?.name}
                    suffix={reception.suffix}
                    deletionTime={reception.deletionTime}
                    deleted={reception.deleted}
                    handleCancelDelete={handleCancelDelete}
                  />
                ))}
              </div>
            </InfiniteScroll>
          )}
        </div>
      </div>
      <style jsx>{`
        .page-container {
          height: 100%;
          background: ${theme.colors.white};
        }
        .left-container {
          margin-right: 20px;
          margin-left: 40px;
          width: 100%;
        }
        .item-description {
          font-weight: 700;
          font-size: 20px;
          padding: 14px 0 15px 0;
        }
        .main-container {
          background-color: ${theme.colors.white};
        }
        .push {
          margin-left: auto;
        }
      `}</style>
    </div>
  );
};

export const ReceptionsAppointment = withContexts(ReceptionsAppointmentBase);
