/* eslint-disable @typescript-eslint/no-type-alias */
/* eslint-disable @typescript-eslint/no-magic-numbers */
import type { ComponentProps } from "react";
import React, { useState, useContext } from "react";

import { useTheme } from "../../../contexts/Theme";
import { FormGroupContext } from "../../../contexts/FormGroupContext";
import type { BorderCssProps } from "../useInputBorderCss";
import { useInputBorderCss } from "../useInputBorderCss";
import { useInputPadding } from "../useInputPadding";
import durationString from "../../../util/durationString";

type Props = Omit<ComponentProps<"input">, "type" | "value" | "checked" | "ref"> & {
  /** The duration in minutes */
  value?: string | null;
  min?: number;
  max?: number;
  variant?: "md" | "lg";
  rounded?: BorderCssProps["rounded"];
};

const handleBounds = (e: React.ChangeEvent<HTMLInputElement>, bounds: { min?: number; max?: number }) => {
  const input = durationString.toMinutes(e.currentTarget.value);
  if (input == null) {
    return;
  }

  let bounded = input;
  if (bounds.min != null) {
    bounded = Math.max(bounded, bounds.min);
  }
  if (bounds.max != null) {
    bounded = Math.min(bounded, bounds.max);
  }

  if (bounded !== input) {
    e.currentTarget.value = durationString.fromMinutes(bounded) ?? "";
  }
};

const onlyAllowValidCharacters = (
  e: React.ChangeEvent<HTMLInputElement> | React.KeyboardEvent<HTMLInputElement> | React.FormEvent<HTMLInputElement>,
) => {
  e.currentTarget.value = e.currentTarget.value.replace(/[^0-9:]/gu, "");

  const tokens = e.currentTarget.value.split(":");
  if (tokens.length > 1) {
    e.currentTarget.value = `${tokens[0]}:${tokens[1].slice(0, 2)}`;
  }
};

export const FormDuration: React.FC<Props> = React.forwardRef<HTMLInputElement, Props>(
  (
    {
      className,
      id: propsId,
      value: propsValue,
      placeholder,
      variant,
      rounded,
      min,
      max,
      onKeyDown,
      onInput,
      onChange,
      onBlur,
      ...inputProps
    },
    ref,
  ) => {
    const { colors, animationDuration } = useTheme();
    const { id, hintId } = useContext(FormGroupContext);
    const borderCss = useInputBorderCss({ rounded });
    const padding = useInputPadding();

    const [localValue, setLocalValue] = useState(propsValue);
    const value = propsValue ?? localValue ?? "";

    return (
      <>
        <input
          {...inputProps}
          ref={ref}
          id={propsId ?? id}
          aria-describedby={hintId}
          className={`duration-input ${borderCss.className} ${className ?? ""}`}
          pattern={"/^\\d\\d+:\\d\\d$/u"}
          value={value}
          placeholder={placeholder ?? "hh:mm"}
          onKeyDown={(e) => {
            onlyAllowValidCharacters(e);
            if (onKeyDown != null) {
              onKeyDown(e);
            }
          }}
          onInput={(e) => {
            onlyAllowValidCharacters(e);
            if (onInput != null) {
              onInput(e);
            }
          }}
          onChange={(e) => {
            onlyAllowValidCharacters(e);
            handleBounds(e, { min, max });

            setLocalValue(e.target.value);
            if (onChange != null) {
              onChange(e);
            }
          }}
          onBlur={(e) => {
            const formatted = durationString.format(e.currentTarget.value);
            if (formatted !== e.currentTarget.value) {
              e.currentTarget.value = formatted ?? "";
              setLocalValue(formatted);
              if (onChange != null) {
                onChange(e);
              }
            }
            if (onBlur != null) {
              onBlur(e);
            }
          }}
        />
        <style jsx>{`
          input {
            padding: ${padding.shorthand};
            line-height: ${variant === "lg" ? "24px" : "22px"} !important;
            appearance: none;
            cursor: pointer;
            float: none;
            outline: none;
            transition: ${animationDuration} box-shadow, border;
            width: 100%;
            font-size: ${variant === "lg" ? "16px" : "14px"} !important;
            color: ${value === "" ? colors.muted1 : "inherit"};
            position: relative;
            background: white;
          }
          ::placeholder {
            color: ${colors.muted1};
          }
        `}</style>
        {borderCss.styles}
      </>
    );
  },
);

FormDuration.displayName = "FormDuration";
