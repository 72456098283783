import React, { useContext, useEffect, useState } from "react";

import type { ComponentProps } from "react";
import { FormGroupContext } from "../../../contexts/FormGroupContext";
import { useInputBorderCss } from "../useInputBorderCss";
import { useInputPadding } from "../useInputPadding";
import { useTheme } from "../../../contexts/Theme";

// eslint-disable-next-line @typescript-eslint/no-type-alias
type Props = ComponentProps<"textarea"> & {
  variant?: "md" | "lg";
  showChrome?: boolean | "onInteraction";
};

export const FormTextarea = React.forwardRef<HTMLTextAreaElement, Props>((props, ref) => {
  const [value, setValue] = useState<string>(typeof props.value === "string" ? props.value : "");
  const { id, hintId } = useContext(FormGroupContext);
  const { colors } = useTheme();
  const borderCss = useInputBorderCss({ showChrome: props.showChrome });
  const padding = useInputPadding();

  useEffect(() => {
    if (typeof props.value === "string") {
      setValue(props.value);
    }
  }, [props.value]);

  return (
    <>
      <textarea
        {...props}
        ref={ref}
        id={props.id ?? id}
        aria-describedby={hintId}
        className={`${props.className ?? ""} ${borderCss.className}`}
        onChange={(e) => {
          setValue(e.target.value);
          if (props.onChange != null) {
            props.onChange(e);
          }
        }}
      />
      <style jsx>{`
        textarea {
          padding: ${padding.shorthand};
          line-height: ${props.variant === "lg" ? "24px" : "22px"};
          appearance: none;
          display: inline-block;
          cursor: pointer;
          float: none;
          outline: none;
          width: 100%;
          font-size: ${props.variant === "lg" ? "16px" : "14px"};
          color: ${value === "" ? colors.muted1 : "inherit"};
          max-width: 100%;
          resize: vertical;
          min-height: 88px;
        }
        textarea:first-child {
          padding-top: ${padding.top};
        }
        textarea:last-child {
          padding-bottom: ${padding.bottom};
        }
        textarea::placeholder {
          color: ${colors.muted1};
        }
      `}</style>
      {borderCss.styles}
    </>
  );
});

FormTextarea.displayName = "FormTextarea";
