import * as yup from "yup";

import type { TFunction } from "@equiem/localisation-eq1";

import type { CategoryPresetsQuery } from "../../generated/requests-client";

export interface CategoryFormValues {
  name: string;
  iconName: string;
  type: string;
  ownerCompanyUuid?: string;
  buildings: string[];
  queueUuid: string;
}

export const getValidationSchema = (t: TFunction, typeRequired: boolean, canManageRegion: boolean) =>
  yup.object().shape({
    name: yup.string().required(t("common.nameRequired")),
    iconName: yup.string().required(t("common.selectIcon")),
    type: typeRequired ? yup.string().required(t("common.selectType")) : yup.string().optional(),
    ownerCompanyUuid: canManageRegion ? yup.string().required(t("common.selectCompany")) : yup.string().optional(),
    queueUuid: yup.string().required(t("common.selectQueue")),
    buildings: yup.array(yup.string()).min(1).required(t("common.selectBuilding")),
  });

export type CategoryPreset = CategoryPresetsQuery["reqMgt"]["categoryPresets"][number];
