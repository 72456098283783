import React from "react";
import type { ProfileFragmentFragment, CompanyFlexTenantsFragmentFragment } from "../generated/gateway-client";

export interface ProfileContext {
  profile?: ProfileFragmentFragment | null;
  siteProfile?: ProfileFragmentFragment["siteProfiles"]["edges"][number]["node"];
  canManageRegion: boolean;
  canManageCurrentSite: boolean;
  canManageCurrentSiteCompany: boolean;
  canManageCurrentSiteSettings: boolean;
  canManageCurrentSiteRequests: boolean;
  canBeAssignedCurrentSiteRequests: boolean;
  isReceptionist: boolean;
  companyIsFlexOperator: boolean;
  canManageFlexTenants: boolean;
  receptionCount: number;
  loading: boolean;
  companyFlexTenants: CompanyFlexTenantsFragmentFragment[];
  isFlexRelated: boolean;
}

export const CurrentProfile = React.createContext<ProfileContext>({
  profile: null,
  loading: true,
  canManageRegion: false,
  canManageCurrentSite: false,
  canManageCurrentSiteCompany: false,
  canManageCurrentSiteSettings: false,
  canManageCurrentSiteRequests: false,
  canBeAssignedCurrentSiteRequests: false,
  isReceptionist: false,
  companyIsFlexOperator: false,
  canManageFlexTenants: false,
  receptionCount: 0,
  companyFlexTenants: [],
  isFlexRelated: false,
});
