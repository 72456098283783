import { useMemo } from "react";

import { stringNotEmpty } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import { type FilterItem, FilterOptionsModifier } from "@equiem/react-admin-ui";
import { RiUserFollowLine } from "@equiem/react-admin-ui/icons";

interface Person {
  uuid: string;
  firstName?: string | null;
  lastName?: string | null;
  email: string;
}
export function useRequestFilterAssignee(skip: boolean, assignees?: Person[]) {
  const { t } = useTranslation();

  const options = useMemo(() => {
    return skip
      ? []
      : (assignees ?? [])
          .map((assignee) => ({
            label: `${assignee.firstName} ${assignee.lastName}`,
            value: assignee.uuid,
            searchKeywords: [assignee.firstName ?? "", assignee.lastName ?? "", assignee.email].filter(stringNotEmpty),
          }))
          .sort((a, b) => a.label.localeCompare(b.label));
  }, [assignees, skip]);

  const filter: FilterItem = {
    title: t("requests.assignee") as string,
    type: "options",
    options,
    icon: RiUserFollowLine,
    modifiers: [FilterOptionsModifier.includes],
  };

  return {
    filter: filter.options.length > 0 ? filter : null,
  };
}
