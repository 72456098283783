import React, { useMemo } from "react";
import Link from "next/link";
import { useTheme } from "@equiem/react-admin-ui";
import { RiFile3Line } from "@equiem/react-admin-ui/icons";

export const FileCard: React.FC<{
  className?: string;
  name: string;
  url: string;
  mimeType?: string;
  bytes?: number;
}> = ({ className, name, url, mimeType, bytes }) => {
  const { spacers, colors, borderRadius } = useTheme();

  const niceSize = useMemo(() => {
    if (bytes == null || bytes === 0) {
      return "0 Bytes";
    }

    const k = 1024;
    const dm = 0;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
  }, [bytes]);

  return (
    <>
      <Link href={url} legacyBehavior>
        <a className={`p-4 ${className}`} target="_blank" rel="noopener noreferrer" title={name}>
          <div className="circle">
            <RiFile3Line size="14px" />
          </div>
          <div className="details">
            <div>
              <div className="filename">{name}</div>
              <div className="info mt-2">
                {mimeType} • {niceSize}
              </div>
            </div>
          </div>
        </a>
      </Link>
      <style jsx>{`
        a {
          flex: 1;
          display: flex;
          flex-direction: row;
          gap: ${spacers.s3};
          min-width: 240px;
          max-width: 50%;
          border: 1px solid ${colors.border};
          border-radius: ${borderRadius};
          text-decoration: none;
          color: black;
        }
        .circle {
          flex: 0;
          display: flex;
          min-width: 32px;
          width: 32px;
          height: 32px;
          border-radius: 50%;
          justify-content: center;
          align-items: center;
          background: ${colors.grayscale[5]};
          color: ${colors.grayscale[60]};
        }
        .details {
          flex: 1;
          display: flex;
          align-items: center;
          overflow: hidden;
        }
        .details > div {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        .filename {
          font-size: 16px;
          line-height: 22px;
        }
        .info {
          text-transform: uppercase;
          color: ${colors.grayscale[60]};
        }
      `}</style>
    </>
  );
};
