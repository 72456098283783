import React from "react";
import { useTheme } from "../../contexts";

export interface SteppedProgressBarProps {
  steps: number;
  currentStep: number;
  className?: string;
}

export const SteppedProgressBar: React.FC<SteppedProgressBarProps> = ({ steps, currentStep, className }) => {
  const { colors } = useTheme();

  return (
    <>
      <div className={`progress-container ${className}`.trim()}>
        {Array.from({ length: steps }, (_, i) => {
          const isCompletedStep = i < currentStep;

          return <div key={i} className={`progress-step ${isCompletedStep ? "progress-step--active" : ""}`} />;
        })}
      </div>
      <style jsx>
        {`
          .progress-container {
            width: 100%;
            display: grid;
            grid-template-columns: repeat(${steps}, 1fr);
            grid-gap: 0.25rem;
          }

          .progress-step {
            height: 0.25rem;
            width: 100%;
            background-color: ${colors.transparent.black[5]};
          }

          .progress-step--active {
            background-color: ${colors.blue[60]};
          }
        `}
      </style>
    </>
  );
};
