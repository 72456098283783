import { useTranslation } from "@equiem/localisation-eq1";
import { useConfirmer } from "@equiem/react-admin-ui";

export const useNewAppointmentCancelConfirmer = (onConfirm: () => void) => {
  const { t } = useTranslation();
  const confirmer = useConfirmer();

  return confirmer.withConfirmation({
    onConfirm,
    confirmButtonText: t("visitors.appointmentForm.cancelYes"),
    confirmButtonVariant: "danger",
    message: t("visitors.appointmentForm.cancelBody"),
    title: t("common.areYouSure"),
  });
};
