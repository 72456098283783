import React, { useContext } from "react";
import { Modal, useTheme } from "@equiem/react-admin-ui";
import { BookingModal } from "../contexts/BookingModalContext";
import { BookingResourceDetails } from "./BookingResourceDetails";
import { BookingCalendarContextProvider } from "../contexts/BookingCalendarContext";
import { BookingViewModalContent } from "./BookingViewModalContent";

export const BookingViewModal: React.FC = () => {
  const modal = useContext(BookingModal);
  const theme = useTheme(true);

  return modal.id != null ? (
    <BookingCalendarContextProvider>
      <Modal.Dialog
        show
        focusTrapOptions={{ initialFocus: false }}
        sideModal
        expansion={modal.showExpansion != null ? <BookingResourceDetails /> : undefined}
        expansionBackground={theme.colors.grayscale_5}
        key={modal.id}
        title={modal.title}
        onHide={() => modal.close()}
        hideOnEsc={true}
        hideOnClick={true}
        containerClassName="end-user-booking-modal"
        size="md"
      >
        <Modal.Header closeButton noBorder={true} onClose={() => modal.close()} />
        <Modal.Body>
          <BookingViewModalContent reference={modal.id} />
        </Modal.Body>
        <Modal.Footer>
          <div className="w-100" ref={modal.footerRef}></div>
        </Modal.Footer>
      </Modal.Dialog>
    </BookingCalendarContextProvider>
  ) : null;
};
