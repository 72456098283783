import { useContext, useMemo, useState } from "react";

import { CurrentProfile, CurrentRole, Role, useShowError } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";

import {
  useCategoriesQuery,
  useCategoryPresetsQuery,
  useCategoryTypesQuery,
  useCreateCategoryMutation,
  useCreateSubCategoryMutation,
  useCurrentDestinationBuildingsQuery,
  useDeleteCategoryMutation,
  useDeleteSubCategoryMutation,
  useFlexManagerBuildingsQuery,
  useSeedCategoryMutation,
  useSubCategoriesQuery,
  useUpdateCategoryMutation,
  useUpdateSubCategoryMutation,
} from "../../../../generated/requests-client";
import CategoryTypeKey from "../runtypes/CategoryTypeKey";

export function useCategoryData() {
  const { t } = useTranslation();
  const showError = useShowError();
  const { canManageRegion, profile } = useContext(CurrentProfile);
  const { currentRole } = useContext(CurrentRole);
  const [selectedCategoryUuid, setSelectedCategoryUuid] = useState<string>();
  const isFlexManager = currentRole === Role.FlexManager;
  const categoriesVariables = useMemo(() => {
    const variables: {
      buildingUuid?: string | null;
      ownerCompanyUuid?: string;
    } = {
      buildingUuid: null,
    };
    if (!canManageRegion) {
      variables.ownerCompanyUuid = profile?.companyV2?.uuid;
    }
    return variables;
  }, [canManageRegion, profile]);

  const {
    data,
    loading: categoriesLoading,
    refetch: categoriesRefetch,
  } = useCategoriesQuery({
    variables: categoriesVariables,
  });
  const { data: buildingsData } = useCurrentDestinationBuildingsQuery({
    fetchPolicy: "network-only", // always fetch up-to-date list of buildings
    skip: isFlexManager,
  });
  const { data: flexBuildings } = useFlexManagerBuildingsQuery({
    fetchPolicy: "network-only", // always fetch up-to-date list of buildings
    variables: {
      first: 100,
    },
    skip: !isFlexManager,
  });
  const { data: typesData } = useCategoryTypesQuery();
  const { data: subCategoriesData, refetch: subCategoriesRefetch } = useSubCategoriesQuery({
    fetchPolicy: "network-only",
    skip: selectedCategoryUuid == null || selectedCategoryUuid === "",
    variables: { categoryUuid: selectedCategoryUuid ?? "" }, // empty string won't ever actual get sent in a request because of the skip rule
  });

  const { data: presetsData, refetch: categoryPresetsRefetch } = useCategoryPresetsQuery({
    variables: { excludeUsed: !canManageRegion },
  });

  const [createCategoryMutation, { loading: createCategoryLoading }] = useCreateCategoryMutation();
  const [seedCategoryMutation, { loading: seedCategoryLoading }] = useSeedCategoryMutation();
  const [updateCategoryMutation, { loading: updateCategoryLoading }] = useUpdateCategoryMutation();
  const [deleteCategoryMutation] = useDeleteCategoryMutation({ refetchQueries: ["Categories", "CategoryPresets"] });

  const [createSubCategoryMutation, { loading: createSubCategoryLoading }] = useCreateSubCategoryMutation();
  const [updateSubCategoryMutation, { loading: updateSubCategoryLoading }] = useUpdateSubCategoryMutation();
  const [deleteSubCategoryMutation, { loading: deleteSubCategoryLoading }] = useDeleteSubCategoryMutation();

  const categories = data?.reqMgt.categories ?? [];
  const subCategories = subCategoriesData?.reqMgt.subCategories ?? [];
  const buildings = useMemo(() => {
    if (isFlexManager) {
      return flexBuildings?.myFlexBuildings.edges.flatMap((edge) => edge.node);
    }

    return buildingsData?.viewer.currentDestination?.destination.buildings ?? [];
  }, [flexBuildings, buildingsData, isFlexManager]);
  const presets = presetsData?.reqMgt.categoryPresets ?? [];

  const categoriesMap = useMemo(
    () => new Map(data?.reqMgt.categories.map((category) => [category.uuid, category])),
    [data],
  );

  const typeOptions = useMemo(() => {
    if (typesData?.reqMgt.categoryTypes == null) {
      return [];
    }

    // only provide localised types
    return typesData.reqMgt.categoryTypes
      .filter(({ key }) => CategoryTypeKey.guard(key))
      .map(({ uuid, key }) => {
        return {
          value: uuid,
          label: t(`requests.category.types.${CategoryTypeKey.check(key)}`),
        };
      });
  }, [t, typesData]);

  const refreshCategoriesList = async ({ categoryUuid }: { categoryUuid?: string | null }) => {
    await subCategoriesRefetch({
      categoryUuid: categoryUuid ?? selectedCategoryUuid,
    });
    await categoriesRefetch();
    await categoryPresetsRefetch();
  };

  const handleSubcategoriesActions = async (
    subCategoriesList: Array<{
      name: string;
      uuid?: string;
    }>,
    categoryUuid?: string | null,
  ) => {
    if (createSubCategoryLoading || updateSubCategoryLoading || deleteSubCategoryLoading) {
      return undefined;
    }
    const promises: unknown[] = [];
    // get new subcategories which aren't in subCategories
    const newSubCategories = subCategoriesList.filter(
      (subCategory) => !subCategories.some(({ uuid }) => uuid === subCategory.uuid),
    );
    // get subcategories to remove which are in subCategories but not in subCategoriesList
    const subCategoriesToRemove = subCategories.filter(
      (subCategory) => !subCategoriesList.some(({ uuid }) => uuid === subCategory.uuid),
    );
    // update subcategories which names have changed
    const subCategoriesToUpdate = subCategoriesList.filter((subCategory) => {
      const existingSubCategory = subCategories.find(({ uuid }) => uuid === subCategory.uuid);
      return existingSubCategory != null && existingSubCategory.name !== subCategory.name;
    });

    subCategoriesToRemove.forEach((subCategory) => {
      promises.push(
        deleteSubCategoryMutation({
          variables: {
            uuid: subCategory.uuid,
          },
        }),
      );
    });

    subCategoriesToUpdate.forEach((subCategory) => {
      promises.push(updateSubCategoryMutation({ variables: { name: subCategory.name, uuid: subCategory.uuid ?? "" } }));
    });

    newSubCategories.forEach((subCategory) => {
      promises.push(
        createSubCategoryMutation({
          variables: {
            name: subCategory.name,
            categoryUuid: categoryUuid ?? selectedCategoryUuid ?? "",
          },
        }),
      );
    });

    return Promise.all(promises)
      .then(async () => {
        await refreshCategoriesList({ categoryUuid });
      })
      .catch(showError);
  };

  return {
    categories,
    categoriesMap,
    categoriesLoading,
    setSelectedCategoryUuid,
    selectedCategoryUuid,
    createCategoryMutation,
    createCategoryLoading,
    updateCategoryMutation,
    updateCategoryLoading,
    deleteCategoryMutation,
    seedCategoryMutation,
    seedCategoryLoading,
    subCategories,
    handleSubcategoriesActions,
    refreshCategoriesList,
    buildings,
    typeOptions,
    presets,
  };
}
