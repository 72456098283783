import { Modal, Tag } from "@equiem/react-admin-ui";
import type { FC } from "react";
import React from "react";
import type { FlexTenantFragmentFragment } from "../../generated/gateway-client";
import { ConfiguredActivityFeed } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import { when } from "runtypes";
import { memberLimit, workplaceManagersAdded, spendingCreditsRequiresApprovalChanged } from "../lib/activityTypes";

interface Props {
  flexTenant: FlexTenantFragmentFragment;
}

export const FlexTenantHistory: FC<Props> = ({ flexTenant }) => {
  const { t } = useTranslation();

  const fieldNameMappings = {
    memberLimit: t("flex.activity.teamSize"),
    defaultWorkplaceManagerEmails: t("flex.members.form.workplaceManager"),
    name: t("flex.activity.membershipName"),
    moveInDate: t("flex.activity.moveInDate"),
    expirationDate: t("flex.activity.expirationDate"),
    monthlyCreditAllowance: t("flex.activity.monthlyCreditAllowance"),
    spendingCreditsRequiresApproval: t("flex.activity.spendingCreditsRequiresApproval"),
  };

  const customMappings = [
    when(memberLimit, (a) => {
      const from = a.change.from?.integer;
      const to = a.change.to?.integer;
      return {
        title: t("activity.updated", { fieldName: t("flex.activity.teamSize") }),
        from: <Tag>{from != null && from > 0 ? from : t("flex.members.form.teamSizeAll")}</Tag>,
        to: <Tag>{to != null && to > 0 ? to : t("flex.members.form.teamSizeAll")}</Tag>,
      };
    }),
    when(workplaceManagersAdded, (a) => {
      const added = a.change.toValues
        .map((v) => v.value)
        .filter((v) => a.change.fromValues.every((f) => f.value !== v));

      return {
        title: t("activity.addedA", { fieldName: fieldNameMappings.defaultWorkplaceManagerEmails }),
        to: added.map((v, i) => <Tag key={i}>{v}</Tag>),
      };
    }),
    when(spendingCreditsRequiresApprovalChanged, (a) => {
      const toState = (val: string | null | undefined) => {
        switch (val?.trim().toLowerCase()) {
          case "true":
            return t("common.enabled");
          case "false":
            return t("common.disabled");
          default:
            return val ?? "-";
        }
      };
      return {
        title: t("activity.updated", { fieldName: fieldNameMappings.spendingCreditsRequiresApproval }),
        from: <Tag>{toState(a.change.from?.value)}</Tag>,
        to: <Tag>{toState(a.change.to?.value)}</Tag>,
      };
    }),
  ];

  return (
    <>
      <Modal.Body>
        <ConfiguredActivityFeed
          customMappings={customMappings}
          entityUuid={flexTenant.uuid}
          fieldNameMappings={fieldNameMappings}
        />
      </Modal.Body>
    </>
  );
};
