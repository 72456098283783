import { DateTime } from "luxon";
import { validate as uuidValidate } from "uuid";

import { stringIsEmpty } from "@equiem/lib";
import type { VisitorHost } from "@equiem/lib/generated/gateway-client";

import type {
  InputMaybe,
  VisitorAppointmentFragment,
  VisitorAppointmentInput,
  VisitorAppointmentRecurringInfoInput,
  VisitorAppointmentRecurringType,
  VisitorAppointmentWeekday,
  VisitorInfo,
} from "../../generated/visitors-client";

import {
  NumberToRecurringTypeGraphQLMap,
  NumberToRecurringWeekdayGraphQLMap,
  RecurringWeekdayToNumberMap,
  VisitorAppointmentRecurringTypeToNumberMap,
} from "./components/recurring-settings/RecurringSettingsAuxiliary";
import { getAppointmentIntervals } from "./utils/appointmentIntervals";
import type { AppointmentRecurringInfo, AppointmentRecurringSettings, FormValues, FormVisitor } from "./types";

interface GetVisitorInfo {
  uuid: string;
  firstName: string;
  lastName: string;
  email?: string | null;
  companyName?: string | null;
  receptionNote?: {
    value?: string;
  } | null;
  visitorInfo?: VisitorInfo[] | null;
  recurringUuid?: string | null;
}

const getVisitorInfo = (visitor: GetVisitorInfo): FormVisitor => ({
  uuid: visitor.uuid,
  firstName: visitor.firstName,
  lastName: visitor.lastName,
  email: visitor.email ?? "",
  companyName: visitor.companyName ?? "",
  receptionNoteMessage: visitor.receptionNote?.value,
  visitorInfo: visitor.visitorInfo ?? [],
  recurringId: visitor.recurringUuid ?? undefined,
});

const getRecurringInfo = (appointment: VisitorAppointmentFragment): AppointmentRecurringInfo | undefined => {
  return appointment.recurringInfo == null
    ? undefined
    : {
        uuid: appointment.recurringInfo.uuid,
        startDate: appointment.recurringInfo.startDate,
        endDate: appointment.recurringInfo.endDate,
        appointments: appointment.recurringInfo.appointments.map((x) => ({
          uuid: x.uuid,
          startDate: x.startDate,
          endDate: x.endDate,
        })),
        recurringType: VisitorAppointmentRecurringTypeToNumberMap[appointment.recurringInfo.recurringType],
        repeatEvery: appointment.recurringInfo.repeatEvery ?? undefined,
        repeatOn:
          appointment.recurringInfo.repeatOn == null
            ? undefined
            : appointment.recurringInfo.repeatOn.map((x) => RecurringWeekdayToNumberMap[x]),
        repeatTimes: appointment.recurringInfo.repeatTimes ?? undefined,
        repeatUntil: appointment.recurringInfo.repeatUntil ?? undefined,
        sameWeekDayEachMonth: appointment.recurringInfo.sameDayEachMonth ?? undefined,
        lastWeekDayEachMonth: appointment.recurringInfo.lastWeekDayEachMonth ?? undefined,
      };
};

const getRecurringSettings = (appointment: VisitorAppointmentFragment): AppointmentRecurringSettings | undefined => {
  return appointment.recurringInfo == null
    ? undefined
    : {
        startDate: appointment.recurringInfo.startDate,
        endDate: appointment.recurringInfo.endDate,
        recurringType: VisitorAppointmentRecurringTypeToNumberMap[appointment.recurringInfo.recurringType],
        recurringDates: appointment.recurringInfo.appointments
          .filter((a) => a.uuid !== appointment.uuid)
          .map((a) => a.startDate),
        repeatEvery: appointment.recurringInfo.repeatEvery ?? undefined,
        repeatOn:
          appointment.recurringInfo.repeatOn == null
            ? undefined
            : appointment.recurringInfo.repeatOn.map((x) => RecurringWeekdayToNumberMap[x]),
        repeatTimes: appointment.recurringInfo.repeatTimes ?? undefined,
        repeatUntil: appointment.recurringInfo.repeatUntil ?? undefined,
        sameWeekDayEachMonth: appointment.recurringInfo.sameDayEachMonth ?? undefined,
        lastWeekDayEachMonth: appointment.recurringInfo.lastWeekDayEachMonth ?? undefined,
      };
};

const getHost = (
  host: VisitorHost,
): {
  firstName: string;
  lastName: string;
  company: {
    uuid: string;
    name: string;
  };
  email: string;
  avatar: string;
  userUuid: string;
} => ({
  firstName: host.firstName,
  lastName: host.lastName,
  company: {
    uuid: host.company?.uuid ?? "",
    name: host.company?.name ?? "",
  },
  email: host.email ?? "",
  avatar: host.profile?.avatar ?? "",
  userUuid: host.userUuid ?? "",
});

const getBookingInfo = (appointment: VisitorAppointmentFragment) => {
  const bookingInfo = appointment.bookingInfo;
  const resource = bookingInfo?.resource;
  const building = resource?.building;
  const level = resource?.level;

  const mappedBuilding =
    building != null
      ? {
          uuid: building.uuid,
          name: building.name,
        }
      : undefined;

  const mappedLevel =
    level != null
      ? {
          uuid: level.uuid,
          name: level.name,
        }
      : undefined;

  return bookingInfo != null
    ? {
        uuid: bookingInfo.uuid,
        reference: bookingInfo.reference,
        createdByUuid: bookingInfo.createdByUuid,
        userUuid: bookingInfo.userUuid,
        resourceDestinationUuid: bookingInfo.resourceDestinationUuid,
        resource:
          resource != null
            ? {
                uuid: resource.uuid,
                name: resource.name,
                building: mappedBuilding,
                level: mappedLevel,
              }
            : undefined,
      }
    : undefined;
};

export const mapAppointmentToForm = (appointment: VisitorAppointmentFragment, locale: string): FormValues => {
  const date = DateTime.fromMillis(appointment.startTime).toISODate() ?? "";
  const startTime = DateTime.now()
    .set({
      hour: DateTime.fromMillis(appointment.startTime).get("hour"),
      minute: DateTime.fromMillis(appointment.startTime).get("minute"),
      second: 0,
      millisecond: 0,
    })
    .toSeconds();

  /* istanbul ignore next */
  return {
    isWalkIn: appointment.isWalkIn ?? false,
    title: appointment.title ?? "",
    description: appointment.description ?? "",
    visitorNotes: appointment.visitorNotes ?? "",
    buildingReceptionNotes: appointment.buildingReceptionNotes ?? "",
    companyReceptionNotes: appointment.companyReceptionNotes ?? "",
    holdInLobby: Boolean(appointment.holdInLobby),
    notifyHost: appointment.notifyHost,
    bookingInfo: getBookingInfo(appointment),
    notifyOrganizer: Boolean(appointment.notifyOrganizer),
    date,
    startTime,
    visitorTypeUuid: appointment.visitorType?.uuid ?? undefined,
    organizer: {
      userUuid: appointment.organizer?.uuid,
      firstName: appointment.organizer?.firstName as string,
      lastName: appointment.organizer?.lastName as string,
      email: appointment.organizer?.email ?? "",
      company: {
        uuid: appointment.organizer?.companyV2?.uuid ?? "",
        name: appointment.organizer?.companyV2?.name ?? "",
      },
    },
    duration: getAppointmentIntervals(startTime, date, locale).get(appointment.endTime)?.value ?? "",
    visitors: appointment.visitors.map(getVisitorInfo),
    host: getHost(appointment.host as VisitorHost),
    additionalDates: [],
    location: appointment.receptionUuid ?? "",
    recurringType: appointment.recurringInfo == null ? undefined : "current",
    recurringInfo: getRecurringInfo(appointment),
    recurringSettings: getRecurringSettings(appointment),
  };
};

export const mapFormToDTO = (values: FormValues): VisitorAppointmentInput => {
  const getStartTime = (date: string, startTime: number) => {
    const isoStartTime = DateTime.fromSeconds(Number(startTime)).toISOTime();
    const hour = DateTime.fromSeconds(Number(startTime)).get("hour");
    const minute = DateTime.fromSeconds(Number(startTime)).get("minute");

    return DateTime.fromISO(`${date}T${isoStartTime}`, { zone: "utc" })
      .toLocal()
      .set({ second: 0, millisecond: 0, hour, minute })
      .toMillis();
  };

  const getEndTime = (date: string, duration: string) => {
    const isoEndTime = DateTime.fromFormat(duration, "h:mma").toISOTime();
    const hour = DateTime.fromFormat(duration, "h:mma").get("hour");
    const minute = DateTime.fromFormat(duration, "h:mma").get("minute");

    return DateTime.fromISO(`${date}T${isoEndTime}`, { zone: "utc" })
      .toLocal()
      .set({ second: 0, millisecond: 0, hour, minute })
      .toMillis();
  };

  const mapRecurringSettingsToDto = (): InputMaybe<VisitorAppointmentRecurringInfoInput> | undefined => {
    if (values.recurringSettings != null) {
      return {
        startDate: values.recurringSettings.startDate,
        recurringType: NumberToRecurringTypeGraphQLMap[
          values.recurringSettings.recurringType
        ] as VisitorAppointmentRecurringType,
        repeatOn: values.recurringSettings.repeatOn?.map(
          (x) => NumberToRecurringWeekdayGraphQLMap[x],
        ) as VisitorAppointmentWeekday[],
        repeatTimes: values.recurringSettings.repeatTimes,
        repeatUntil: values.recurringSettings.repeatUntil,
        repeatEvery: values.recurringSettings.repeatEvery,
        sameDayEachMonth: values.recurringSettings.sameWeekDayEachMonth,
        lastWeekDayEachMonth: values.recurringSettings.lastWeekDayEachMonth,
      };
    }

    return undefined;
  };

  const startTime = getStartTime(values.date, values.startTime);
  const endTime = getEndTime(values.date, values.duration);

  /* istanbul ignore next */
  return {
    isWalkIn: values.isWalkIn,
    title: values.title.trim(),
    description: values.description.trim(),
    startTime,
    endTime,
    isMadeByReceptionist: values.isMadeByReceptionist,
    holdInLobby: values.holdInLobby,
    notifyHost: values.notifyHost,
    notifyOrganizer: values.notifyOrganizer,
    visitorNotes: values.visitorNotes.trim(),
    buildingReceptionNotes: values.buildingReceptionNotes.trim(),
    companyReceptionNotes: values.companyReceptionNotes.trim(),
    receptionUuid: stringIsEmpty(values.location) ? undefined : values.location,
    visitorsTypeUuid: values.visitorTypeUuid,
    visitors: values.visitors.map((visitor) => {
      const visitorEmail = visitor.email?.trim();
      return {
        uuid: visitor.uuid,
        firstName: visitor.firstName.trim(),
        lastName: visitor.lastName.trim(),
        email: visitorEmail === "" ? null : visitorEmail,
        companyName: visitor.companyName?.trim() ?? "",
        cardId: visitor.cardId?.trim(),
        receptionNoteMessage: visitor.receptionNoteMessage?.trim(),
        visitorInfo: visitor.visitorInfo ?? [],
        recurringId: visitor.recurringId,
      };
    }),
    host: {
      userUuid: uuidValidate(values.host.userUuid ?? "") ? values.host.userUuid : undefined,
      firstName: values.host.firstName.trim(),
      lastName: values.host.lastName.trim(),
      email: values.host.email != null && values.host.email.length > 0 ? values.host.email.trim() : undefined,
      companyUuid: uuidValidate(values.host.company?.uuid ?? "") ? values.host.company?.uuid : undefined,
    },
    additionalDates: values.additionalDates.map((date) => {
      return {
        startTime: getStartTime(date.date, Number(date.startTime)),
        endTime: getEndTime(date.date, date.duration),
      };
    }),
    recurringUuid: values.recurringInfo?.uuid,
    recurringInfo: mapRecurringSettingsToDto(),
  };
};
