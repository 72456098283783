import type { ChangeEvent, FC } from "react";
import React, { useRef } from "react";
import type { FieldProps } from "formik";
import Papa from "papaparse";
import { v4 as uuidv4 } from "uuid";

import { stringIsEmpty } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import { Button } from "@equiem/react-admin-ui";
import { RiFileUploadLine } from "@equiem/react-admin-ui/icons";
import type { IFile } from "@equiem/uploader";

import parseAndValidateName from "../../../../helpers/parseAndValidateName";
import validateEmail from "../../../../helpers/validateEmail";

import type { BulkUploadVisitor, BulkUploadVisitorCsvImport } from "./BulkUploadVisitorsContext";
import { useBulkUploadVisitorsContext, VisitorBulkUploadStep } from "./BulkUploadVisitorsContext";

export const BulkUploadVisitorsFileUploader: FC<FieldProps<IFile>["field"]> = () => {
  const fileRef = useRef<HTMLInputElement>(null);
  const { setVisitors, setUploadVia, uploadVia, setStep } = useBulkUploadVisitorsContext();
  const { t } = useTranslation();

  const onClick = () => {
    fileRef.current?.click();
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setVisitors([]);
    const csv = event.target.files?.item(0);
    if (csv == null) {
      return;
    }
    Papa.parse<BulkUploadVisitorCsvImport>(csv, {
      header: true,
      skipEmptyLines: true,
      complete: function (results) {
        if (results.data.length > 0) {
          setUploadVia("File");
          const res = results.data.map((x) => {
            const parsedFirstName = parseAndValidateName(x.FirstName);
            const parsedLastName = parseAndValidateName(x.LastName);
            const _: BulkUploadVisitor = {
              firstName: parsedFirstName.parsedName ?? x.FirstName,
              lastName: parsedLastName.parsedName ?? x.LastName,
              companyName: x.Company,
              email: x.Email,
              index: uuidv4(),
              isChecked: false,
              firstNameError: parsedFirstName.errorMessage,
              lastNameError: parsedLastName.errorMessage,
              companyNameError:
                x.Company != null && (x.Company as string).length > 100
                  ? t("settings.editProfile.charactersMaximumNum", { maxNum: "100" })
                  : undefined,
            };
            if (_.email != null && !stringIsEmpty(_.email) && !validateEmail(_.email)) {
              _.emailError = "Invalid Email";
            }
            return _;
          });
          setVisitors(res);
          setStep(VisitorBulkUploadStep.Edit);
        }
      },
    });
  };

  return (
    <>
      <input onChange={handleChange} ref={fileRef} hidden type="file" accept=".csv" multiple={false} max={1} />
      <Button variant="outline" type="button" disabled={uploadVia === "String"} onClick={onClick}>
        <RiFileUploadLine /> {t("common.upload")}
      </Button>
    </>
  );
};
