import React from "react";

import { BulkTable } from "@equiem/react-admin-ui";

import {
  type VisitorForReceptionFragment,
  type VisitorsByReceptionQuery,
  VisitorStatus,
} from "../../../generated/visitors-client";
import { CardIdStatusWidget } from "../../appointment/widgets/CardIdStatusWidget";
import { useAppointmentsBodyRows } from "../hooks/useAppointmentsBodyRows";
import { useAppointmentsHeaders } from "../hooks/useAppointmentsHeaders";
import { useAppointmentTableData } from "../hooks/useAppointmentTableData";

import { BulkOperations } from "./BulkOperations";

interface Props {
  visitors: VisitorForReceptionFragment[];
  receptionType: "building" | "tenant";
  receptionUuid: string;
  enableAccessCard: boolean | null | undefined;
  updateEdges: (
    cb: (
      edges: VisitorsByReceptionQuery["visitorsByReception"]["edges"],
    ) => VisitorsByReceptionQuery["visitorsByReception"]["edges"],
  ) => void;
  visitorsLoading: boolean;
}

export const AppointmentsTable: React.FC<Props> = ({
  visitors,
  receptionType,
  receptionUuid,
  enableAccessCard,
  updateEdges,
  visitorsLoading,
}) => {
  const {
    handleAction,
    handleNotes,
    handleSetCardStatusReturned,
    handleCardIdBlur,
    selectedVisitorIds,
    setSelectedVisitorIds,
    cardStatusUpdatingVisitors,
    handleNotify,
    enablePassPrinting,
    setPrintingVisitors,
    spacers,
    top,
    ref,
    isStuck,
    setCardIdLoading,
    setCardStatusLoading,
    modalCallback,
    cardIds,
    setCardIds,
    statusUpdatingVisitors,
    cardIdUpdatingVisitors,
    router,
    modal,
  } = useAppointmentTableData({ updateEdges, receptionUuid });

  const handleActivity = (uuid: string) => () => {
    modal.open("VisitorActivity", uuid);
  };

  const handleEdit = (uuid: string) => () => {
    void router.push(`/visitor-management/receptions/${receptionUuid}/appointment?appointmentUuid=${uuid}`);
  };

  const handleResendCode = (uuid: string) => () => {
    modal.open("VisitorResendCode", uuid);
  };

  const handlePassPrint = (visitor: VisitorForReceptionFragment) => setPrintingVisitors?.([visitor]);

  const isCardIdDisabled = (visitor: VisitorForReceptionFragment) =>
    visitor.status === VisitorStatus.CheckedOut || setCardIdLoading || setCardStatusLoading || visitor.cardReturned;

  const onClickBulk = () => {
    setPrintingVisitors?.(visitors.filter((visitor) => selectedVisitorIds.includes(visitor.uuid)));
  };

  const bodyRows = useAppointmentsBodyRows({
    data: visitors,
    handleAction,
    handleNotes,
    handleSetCardStatusReturned,
    handleCardIdBlur,
    selectedVisitorIds,
    cardStatusUpdatingVisitors,
    handleNotify,
    setCardIdLoading,
    setCardStatusLoading,
    cardIds,
    setCardIds,
    statusUpdatingVisitors,
    cardIdUpdatingVisitors,
    receptionType,
    enableAccessCard,
    handleActivity,
    handleEdit,
    handlePassPrint,
    handleResendCode,
    isCardIdDisabled,
    enablePassPrinting: () => enablePassPrinting,
    visitorsLoading,
  });

  const headers = useAppointmentsHeaders(enableAccessCard as boolean);

  return (
    <>
      <>
        <BulkTable
          sticky={{ top, isStuck }}
          ref={ref}
          className="w-100"
          onSelected={(ids) => {
            setSelectedVisitorIds(ids);
          }}
          selectionEnabled={enablePassPrinting}
          bulkOperations={<BulkOperations onClick={onClickBulk} />}
          headers={headers}
          bodyRows={bodyRows}
        />
        <CardIdStatusWidget handleAction={modalCallback} />
      </>
      <style jsx>
        {`
          .table-head {
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
            text-transform: uppercase;
            color: rgba(0, 0, 0, 0.6);
          }
          :global(.table) {
            border-style: hidden;
            box-shadow: 0 0 0 1px #e6e6e6;
            border-radius: 8px;
            border-collapse: collapse;
            width: 100%;
            padding-bottom: ${spacers.s10};
          }
          .table-body {
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
          }
          .table-body tr {
            border-bottom: 1px solid #e6e6e6;
          }
          :global(.table) th,
          :global(.table) td {
            padding: ${spacers.s3} ${spacers.s5};
          }
          :global(.table) .row:hover {
            background: #f2f2f2;
          }
          .large-column {
            width: 20%;
          }
          :global(.notes-button) {
            overflow: unset !important;
          }
          :global(.add-notes) {
          }
        `}
      </style>
    </>
  );
};
