import type { FC } from "react";
import React, { useMemo, useState } from "react";
import { UserSearch } from "@equiem/react-admin-ui";
import { useTranslation } from "@equiem/localisation-eq1";
import type { PropertyManagersQuery } from "../../generated/gateway-client";
import { usePropertyManagersQuery } from "../../generated/gateway-client";

export type UserSearchUser = NonNullable<
  PropertyManagersQuery["destination"]["usersWithRelation"]["manager"][number]
>["profile"];

interface Props {
  site: string;
  onSelect?: (item: UserSearchUser) => void;
  disabled?: boolean;
  placeholder?: string;
}

export const UserSearchHOC: FC<Props> = ({ site, onSelect, disabled, placeholder }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const { t } = useTranslation();
  const { data, loading } = usePropertyManagersQuery({
    variables: {
      site,
    },
  });
  const userData = data?.destination.usersWithRelation.manager;

  const searchUsers = useMemo(
    () =>
      (userData ?? [])
        .flatMap((u) => (u?.profile == null ? [] : [u.profile]))
        .filter((user) => user.displayName.includes(searchTerm) || user.email.includes(searchTerm))
        .map((user) => ({ value: "", user })),
    [userData, searchTerm],
  );

  return (
    <>
      <UserSearch
        disabled={disabled}
        items={searchUsers}
        loading={loading}
        onSearch={setSearchTerm}
        onSelect={({ user }) => {
          if (onSelect != null) {
            onSelect(user);
          }
        }}
        buttonText={t("common.select") ?? ""}
        placeholder={placeholder}
      />
    </>
  );
};
