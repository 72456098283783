import React from "react";

import { stringIsEmpty } from "@equiem/lib";
import type { ProfileFragmentFragment } from "@equiem/lib/generated/gateway-client";
import { formatters, useTranslation } from "@equiem/localisation-eq1";
import { Button } from "@equiem/react-admin-ui";
import { RiFileAddLine } from "@equiem/react-admin-ui/icons";

import {
  type VisitorForBuildingReceptionFragment,
  type VisitorForReceptionFragment,
  VisitorStatus,
} from "../../../generated/visitors-client";
import { NotesCounter } from "../components/NotesCounter";
import { ActionButton } from "../components/TableColumns/ActionButton";
import { ActionsMenu } from "../components/TableColumns/ActionsMenu";
import { CardIdInput } from "../components/TableColumns/CardIdInput";
import { NotifyButton } from "../components/TableColumns/NotifyButton";
import { VisitorDetails } from "../components/TableColumns/VisitorDetails";
import { VisitorHostData } from "../components/TableColumns/VisitorHostData";
import { VisitorPersonalData } from "../components/TableColumns/VisitorPersonalData";

export const useAppointmentsBodyRows = ({
  data,
  receptionType,
  enableAccessCard,
  handleAction,
  handleNotes,
  handleSetCardStatusReturned,
  handleCardIdBlur,
  selectedVisitorIds,
  cardStatusUpdatingVisitors,
  handleNotify,
  enablePassPrinting,
  setCardIdLoading,
  setCardStatusLoading,
  cardIds,
  setCardIds,
  statusUpdatingVisitors,
  cardIdUpdatingVisitors,
  handleActivity,
  handleEdit,
  handlePassPrint,
  handleResendCode,
  isCardIdDisabled,
  visitorsLoading,
}: {
  data: Array<VisitorForBuildingReceptionFragment | VisitorForReceptionFragment>;
  receptionType: "tenant" | "building";
  enableAccessCard: boolean | null | undefined;
  handleActivity: (uuid: string) => () => void;
  handleEdit: (uuid: string) => () => void;
  handlePassPrint: (visitor: VisitorForBuildingReceptionFragment | VisitorForReceptionFragment) => void;
  handleResendCode: (uuid: string) => () => void;
  handleAction: (visitor: VisitorForBuildingReceptionFragment | VisitorForReceptionFragment) => () => Promise<void>;
  handleNotes: (visitor: VisitorForBuildingReceptionFragment | VisitorForReceptionFragment) => () => void;
  handleNotify: (visitor: VisitorForBuildingReceptionFragment | VisitorForReceptionFragment) => Promise<void>;
  handleSetCardStatusReturned: (visitorUuid: string, returned?: boolean) => Promise<void>;
  handleCardIdBlur: (cardId: string, visitorUuid: string) => Promise<void>;
  selectedVisitorIds: string[];
  cardStatusUpdatingVisitors: Record<string, boolean | undefined>;
  profile?: ProfileFragmentFragment | null;
  setCardIdLoading: boolean;
  setCardStatusLoading: boolean;
  enablePassPrinting: (visitor?: VisitorForBuildingReceptionFragment | VisitorForReceptionFragment) => boolean;
  cardIds: Record<string, string | undefined>;
  setCardIds: (ids: Record<string, string | undefined>) => void;
  statusUpdatingVisitors: Record<string, boolean | undefined>;
  cardIdUpdatingVisitors: Record<string, boolean | undefined>;
  isCardIdDisabled: (visitor: VisitorForBuildingReceptionFragment | VisitorForReceptionFragment) => boolean;
  visitorsLoading: boolean;
}) => {
  const { i18n } = useTranslation();

  return data.map((visitor) => {
    const notes =
      (receptionType === "tenant"
        ? visitor.appointment.companyReceptionNotes
        : visitor.appointment.buildingReceptionNotes) ?? "";

    const cardIdDisabled = isCardIdDisabled(visitor);
    const isPreBookedHoldInLobby =
      visitor.status === VisitorStatus.PreBooked && Boolean(visitor.appointment.holdInLobby);

    const locationTitle = `${visitor.appointment.building?.name}${
      visitor.appointment.buildingLevel?.name != null ? `, ${visitor.appointment.buildingLevel.name}` : ""
    }`;

    return {
      className: "row appointment",
      id: visitor.uuid,
      cols: [
        {
          content: (
            <div className="d-flex flew-row">
              <div>{formatters.timeshort(visitor.appointment.startTime, i18n.language)}</div>
            </div>
          ),
        },
        {
          content: <VisitorPersonalData visitor={visitor} />,
        },
        {
          content: <VisitorHostData visitor={visitor} />,
        },
        { content: locationTitle },
        { content: visitor.appointment.title },
        ...(enableAccessCard !== true
          ? []
          : [
              {
                content: (
                  <CardIdInput
                    cardIdDisabled={cardIdDisabled}
                    visitor={visitor}
                    setCardIdLoading={setCardIdLoading}
                    setCardStatusLoading={setCardStatusLoading}
                    cardIds={cardIds}
                    handleCardIdBlur={handleCardIdBlur}
                    setCardIds={setCardIds}
                  />
                ),
              },
            ]),
        {
          content: <VisitorDetails visitor={visitor} />,
        },
        ...(isPreBookedHoldInLobby
          ? [
              {
                content: <NotifyButton visitor={visitor} handleNotify={handleNotify} />,
              },
            ]
          : [
              {
                content: (
                  <ActionButton
                    visitor={visitor}
                    selectedVisitorIds={selectedVisitorIds}
                    cardIdUpdatingVisitors={cardIdUpdatingVisitors}
                    statusUpdatingVisitors={statusUpdatingVisitors}
                    cardStatusUpdatingVisitors={cardStatusUpdatingVisitors}
                    handleAction={handleAction}
                    visitorsLoading={visitorsLoading}
                  />
                ),
              },
            ]),
        {
          content:
            !stringIsEmpty(notes) || visitor.receptionistNotes.length > 0 ? (
              <Button size="sm" variant="ghost" className="notes-button" round onClick={handleNotes(visitor)}>
                <NotesCounter
                  newNotes={visitor.receptionistNotes.filter((note) => !note.viewed).length > 0}
                  counter={(stringIsEmpty(notes) ? 0 : 1) + visitor.receptionistNotes.length}
                />
              </Button>
            ) : (
              <Button size="sm" variant="ghost" round onClick={handleNotes(visitor)} className="add-notes">
                <RiFileAddLine size={16}></RiFileAddLine>
              </Button>
            ),
        },
        {
          className: "actions",
          content: (
            <ActionsMenu
              visitor={visitor}
              receptionType={receptionType}
              notes={notes}
              handleActivity={handleActivity}
              handleSetCardStatusReturned={handleSetCardStatusReturned}
              handleEdit={handleEdit}
              enablePassPrinting={enablePassPrinting(visitor)}
              handlePrintPass={handlePassPrint}
              handleResendCode={handleResendCode}
              handleNotes={handleNotes}
            />
          ),
        },
      ],
    };
  });
};
