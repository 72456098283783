import React from "react";

import { useTranslation } from "@equiem/localisation-eq1";
import { Text, useTheme } from "@equiem/react-admin-ui";
import { RiSearchLine } from "@equiem/react-admin-ui/icons";

type Props = {
  query?: string;
};

export const EmptyResult: React.FC<Props> = ({ query }) => {
  const { t } = useTranslation();
  const { colors } = useTheme();

  return (
    <div className="container">
      <div className="icon-container">
        <RiSearchLine size={24} color={colors.grayscale[40]} />
      </div>
      <Text variant="text" size="small">
        {t("requests.thereAreNoResultsAvailable", { query: query ?? "" })}
      </Text>
      <style jsx>
        {`
          .container {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            color: ${colors.grayscale[60]};
            padding: 100px 0;
          }

          .container .icon-container {
            padding: 16px;
            background: ${colors.grayscale[5]};
            border-radius: 50%;
          }
        `}
      </style>
    </div>
  );
};
