import React from "react";
import { useFormikContext } from "formik";
import type { BookingFormValue } from "../../models/BookingFormValue";
import { useTranslation } from "@equiem/localisation-eq1";
import { Form, useTheme } from "@equiem/react-admin-ui";
import { BookingInvoiceContactTypeInput } from "../../../../generated/gateway-client";

export const BookingPaymentNewContactForm: React.FC = () => {
  const { t } = useTranslation();
  const fm = useFormikContext<BookingFormValue>();
  const contact = fm.values.contact;
  const theme = useTheme();

  return (
    <>
      <div className="new-contact-form">
        <Form.Group className="mb-3" label={t("common.fullName").toUpperCase()} required>
          <Form.Input
            type="text"
            placeholder={t("common.fullName")}
            value={(contact?.type === BookingInvoiceContactTypeInput.NewContact ? contact.fullName : null) ?? ""}
            onChange={(e) => {
              void fm.setFieldValue("contact", {
                type: BookingInvoiceContactTypeInput.NewContact,
                email: contact?.type === BookingInvoiceContactTypeInput.NewContact ? contact.email : "",
                fullName: e.target.value,
                uuid: undefined,
                saveDetails:
                  (contact?.type === BookingInvoiceContactTypeInput.NewContact ? contact.saveDetails : null) ?? false,
              });
            }}
          />
        </Form.Group>

        <Form.Group className="mb-3" label={t("common.email").toUpperCase()} required>
          <Form.Input
            type="email"
            placeholder={t("common.emailAddress")}
            value={(contact?.type === BookingInvoiceContactTypeInput.NewContact ? contact.email : null) ?? ""}
            onChange={(e) => {
              void fm.setFieldValue("contact", {
                type: BookingInvoiceContactTypeInput.NewContact,
                email: e.target.value,
                fullName: contact?.type === BookingInvoiceContactTypeInput.NewContact ? contact.fullName : "",
                uuid: undefined,
                saveDetails:
                  (contact?.type === BookingInvoiceContactTypeInput.NewContact ? contact.saveDetails : null) ?? false,
              });
            }}
          />
        </Form.Group>

        <Form.Checkbox
          label={t("bookings.operations.saveDetails")}
          id="save-me"
          checked={(contact?.type === BookingInvoiceContactTypeInput.NewContact ? contact.saveDetails : null) ?? false}
          onChange={(e) => {
            void fm.setFieldValue("contact", {
              type: BookingInvoiceContactTypeInput.NewContact,
              email: contact?.type === BookingInvoiceContactTypeInput.NewContact ? contact.email : "",
              fullName: contact?.type === BookingInvoiceContactTypeInput.NewContact ? contact.fullName : "",
              uuid: undefined,
              saveDetails: e.target.checked,
            });
          }}
        />
      </div>
      <style jsx>{`
        .new-contact-form {
          border: 1px solid ${theme.colors.border};
          border-radius: ${theme.borderRadius};
          padding: ${theme.spacers.s4};
          margin: 0 0 ${theme.spacers.s3};
        }
      `}</style>
    </>
  );
};
