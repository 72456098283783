export {
  type TFunction,
  type i18n,
  useTranslation,
  Trans,
  useServerMessageTranslation,
  useApolloErrorTranslation,
  useErrorTranslation,
  formatters,
  standaloneTestT,
  getCustomStandaloneTestT,
} from "./generated/localisation";

export { getCachedPreference } from "@equiem/localisation";
