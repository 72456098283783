import { Record as RRecord, String } from "runtypes";

export class CookieJar {
  private readonly cookies: Record<string, string[] | undefined>;

  constructor(cookieHeader: string | undefined | null, private readonly domain: string) {
    this.cookies = cookieHeader == null ? {} : this.parseCookieHeader(cookieHeader);
  }

  public static wrap(value: string, domain: string) {
    const wrappedValue = { [domain]: value };

    return JSON.stringify(wrappedValue);
  }

  public unwrap(name: string) {
    const wrappedValues = this.cookies[name] ?? [];

    for (const wrappedValue of wrappedValues) {
      const value = this.unwrapDomainScopedCookieValue(wrappedValue);

      if (value !== "") {
        return value;
      }
    }

    return "";
  }

  private unwrapDomainScopedCookieValue(value?: string | null) {
    try {
      const parsedCookie = RRecord({ [this.domain]: String }).check(JSON.parse(value ?? ""));
      return parsedCookie[this.domain];
    } catch (e: unknown) {
      return "";
    }
  }

  private decode(str: string) {
    try {
      return str.indexOf("%") !== -1 ? decodeURIComponent(str) : str;
    } catch (e: unknown) {
      return str;
    }
  }

  private parseCookieHeader(str: string) {
    if (typeof str !== "string") {
      throw new TypeError("argument str must be a string");
    }

    const obj: Record<string, string[] | undefined> = {};

    let index = 0;
    while (index < str.length) {
      const eqIdx = str.indexOf("=", index);

      // no more cookie pairs
      if (eqIdx === -1) {
        break;
      }

      let endIdx = str.indexOf(";", index);

      if (endIdx === -1) {
        endIdx = str.length;
      } else if (endIdx < eqIdx) {
        // backtrack on prior semicolon
        index = str.lastIndexOf(";", eqIdx - 1) + 1;
        continue;
      }

      const key = str.slice(index, eqIdx).trim();

      // only assign once
      let val = str.slice(eqIdx + 1, endIdx).trim();

      // quoted values
      if (val.charCodeAt(0) === 0x22) {
        val = val.slice(1, -1);
      }

      if (obj[key] == null) {
        obj[key] = [];
      }

      obj[key]?.push(this.decode(val));

      index = endIdx + 1;
    }

    return obj;
  }
}
