import type { ComponentProps } from "react";
import React, { useContext, useState } from "react";

import { useTheme } from "../../../contexts/Theme";
import { FormGroupContext } from "../../../contexts/FormGroupContext";
import type { BorderCssProps } from "../useInputBorderCss";
import { useInputBorderCss } from "../useInputBorderCss";
import { useInputPadding } from "../useInputPadding";

const DEFAULT_MIN = 0;
const DEFAULT_MAX = 999;

// eslint-disable-next-line @typescript-eslint/no-type-alias
type Props = ComponentProps<"input"> & {
  variant?: "md" | "lg";
  rounded?: BorderCssProps["rounded"];
};

export const FormPercentage = React.forwardRef<HTMLInputElement, Props>((props, ref) => {
  const { id, hintId } = useContext(FormGroupContext);
  const { colors, animationDuration } = useTheme();
  const borderCss = useInputBorderCss({ rounded: props.rounded });
  const padding = useInputPadding();

  const [internalValue, setInternalValue] = useState(props.value ?? "");

  const sanitise = (rawValue: string) => {
    let value = rawValue.replace(/\D/gu, "");

    const num = Number(value);
    const min = Number(props.min ?? DEFAULT_MIN);
    const max = Number(props.max ?? DEFAULT_MAX);
    if (num < min) {
      value = String(min);
    }
    if (num > max) {
      value = String(max);
    }

    return value;
  };

  return (
    <>
      <div className={`input-percentage ${props.className ?? ""} ${borderCss.className}`}>
        <input
          {...props}
          type="number"
          ref={ref}
          id={props.id ?? id}
          aria-describedby={hintId}
          value={props.value ?? internalValue}
          onKeyDown={(e) => {
            e.currentTarget.value = sanitise(e.currentTarget.value);
            if (props.onKeyDown != null) {
              props.onKeyDown(e);
            }
          }}
          onInput={(e) => {
            e.currentTarget.value = sanitise(e.currentTarget.value);
            if (props.onInput != null) {
              props.onInput(e);
            }
          }}
          onChange={(e) => {
            e.currentTarget.value = sanitise(e.currentTarget.value);
            setInternalValue(e.target.value);
            if (props.onChange != null) {
              props.onChange(e);
            }
          }}
          onBlur={(e) => {
            e.currentTarget.value = sanitise(e.currentTarget.value);
            setInternalValue(e.currentTarget.value);
            if (props.onBlur != null) {
              props.onBlur(e);
            }
          }}
        />
        <span>%</span>
      </div>
      <style jsx>{`
        .input-percentage {
          display: flex;
          align-items: center;
          padding: ${padding.shorthand};
          font-size: ${props.variant === "lg" ? "16px" : "14px"} !important;
          line-height: ${props.variant === "lg" ? "24px" : "22px"} !important;
        }
        input {
          border: 0;
          appearance: none;
          cursor: pointer;
          outline: none;
          transition: ${animationDuration} box-shadow, border;
          width: 100%;
          position: relative;
          background: #fff;
          text-align: right;
        }
        input::placeholder {
          color: ${colors.muted1};
        }
      `}</style>
      {borderCss.styles}
    </>
  );
});

FormPercentage.displayName = "FormPercentage";
