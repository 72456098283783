import type { RefObject } from "react";
import { useSiteContext } from "../context/SiteContext";
import { Loader } from "@googlemaps/js-api-loader";
/* global google */

export interface GoogleMapsServices {
  autoCompleteService: google.maps.places.AutocompleteService;
  placesService: google.maps.places.PlacesService;
  siteCoordinates: google.maps.LatLng;
}

export const useGooglePlacesService = () => {
  const {
    coordinates: { latitude, longitude },
  } = useSiteContext();

  if (typeof window === "undefined") {
    return null;
  }

  const loader = new Loader({
    // This key is okay to be hardcoded as this is shared accross stage/prod and
    // this is already available to be seen in the DOM.
    apiKey: "AIzaSyDwWxpS1W-jFXhmqDy6E1_dKnHvNv9h03I",
    libraries: ["places"],
  });

  const loadServices = async (ref: RefObject<HTMLDivElement>): Promise<GoogleMapsServices | null> => {
    if (ref.current == null) {
      return null;
    }

    const gApi = await loader.load();

    const autoCompleteService = new gApi.maps.places.AutocompleteService();
    const placesService = new gApi.maps.places.PlacesService(ref.current);
    const siteCoordinates = new gApi.maps.LatLng(latitude, longitude);

    return {
      placesService,
      autoCompleteService,
      siteCoordinates,
    };
  };

  return {
    loadServices,
  };
};
