import type { FC } from "react";
import React from "react";
import type { FormikHelpers } from "formik";
import { Formik } from "formik";
import * as yup from "yup";

import { billingCustomerValidation } from "@equiem/flex";
import { useTranslation } from "@equiem/localisation-eq1";
import { useToast } from "@equiem/react-admin-ui";

import { useWidgetContext } from "../../../contexts/WidgetContext";
import {
  type BillingCustomerCreateInput,
  type BillingCustomerFragmentFragment,
  BillingCustomerType,
  useBillingCustomerCreateMutation,
} from "../../../generated/settings-client";

import { CompanyBillingFormInner } from "./CompanyBillingFormInner";

interface Props {
  companyUuid: string;
  companyName: string;
  billingCustomer?: BillingCustomerFragmentFragment;
  modalClose: () => void;
}

export const CompanyBillingForm: FC<Props> = ({ companyUuid, billingCustomer, modalClose, companyName }) => {
  const { t } = useTranslation();
  const { setActionState } = useWidgetContext();
  const toast = useToast();
  const [mutation] = useBillingCustomerCreateMutation();

  const handleSubmit = async (
    values: BillingCustomerCreateInput,
    { setSubmitting }: FormikHelpers<BillingCustomerCreateInput>,
  ): Promise<void> => {
    setActionState("onSave", "loading");
    setSubmitting(true);
    await mutation({
      variables: {
        input: values,
      },
    });
    setSubmitting(false);
    toast.positive(t("settings.createCompany.companySuccessfullyUpdated", { companyName }));
    setActionState("onSave", "normal");
    modalClose();
  };

  const initialValues = {
    customerUuid: companyUuid,
    type: BillingCustomerType.Company,
    address: {
      line1: billingCustomer?.address?.line1,
      postalCode: billingCustomer?.address?.postalCode,
      city: billingCustomer?.address?.city,
      state: billingCustomer?.address?.state,
    },
    email: billingCustomer?.email ?? "",
    contactName: billingCustomer?.contactName ?? "",
  };

  const readOnly = billingCustomer != null;

  const validationSchema = () =>
    yup.object().shape({
      ...billingCustomerValidation(t),
    });

  return (
    <>
      <Formik<BillingCustomerCreateInput>
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        isInitialValid={false}
      >
        <CompanyBillingFormInner readOnly={readOnly} />
      </Formik>
    </>
  );
};
