/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import type { FC } from "react";
import React, { useEffect } from "react";
import { Field, Form } from "formik";

import { useSaferFormikContext, useShowError } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import { Form as EqForm, Text } from "@equiem/react-admin-ui";

import { useWidgetContext } from "../../../contexts/WidgetContext";
import { type BillingCustomerCreateInput } from "../../../generated/settings-client";

interface Props {
  readOnly: boolean;
}

export const CompanyBillingFormInner: FC<Props> = ({ readOnly }) => {
  const { t } = useTranslation();
  const showError = useShowError();
  const { subscribe, setActionState } = useWidgetContext();
  const { errors, touched, isValid, submitForm } = useSaferFormikContext<BillingCustomerCreateInput>();

  useEffect(() => {
    return subscribe("onSave", () => {
      void submitForm().catch(showError);
    });
  }, [submitForm, subscribe, showError]);

  useEffect(() => {
    setActionState("onSave", isValid ? "normal" : "disabled");
  }, [isValid, setActionState]);

  return (
    <>
      <Form>
        <Text variant="heading" size="small" weight="bold" className="mb-2">
          {t("flex.members.form.address")}
        </Text>
        <EqForm.Group
          error={touched.address?.line1 ? errors.address?.line1 : undefined}
          label={t("flex.members.form.address")}
          required={!readOnly}
        >
          <Field type="text" readOnly={readOnly} name="address.line1" as={EqForm.Input} />
        </EqForm.Group>
        <EqForm.Group
          error={touched.address?.line2 ? errors.address?.line2 : undefined}
          label={t("flex.members.form.address2")}
        >
          <Field type="text" readOnly={readOnly} name="address.line2" as={EqForm.Input} />
        </EqForm.Group>
        <div className="d-flex">
          <EqForm.Group
            className="mr-4"
            error={touched.address?.city ? errors.address?.city : undefined}
            label={t("flex.members.form.city")}
            required={!readOnly}
          >
            <Field type="text" readOnly={readOnly} name="address.city" as={EqForm.Input} />
          </EqForm.Group>
          <EqForm.Group
            error={touched.address?.postalCode ? errors.address?.postalCode : undefined}
            label={t("flex.members.form.postalCode")}
            required={!readOnly}
          >
            <Field type="text" readOnly={readOnly} name="address.postalCode" as={EqForm.Input} />
          </EqForm.Group>
        </div>
        <div className="d-flex">
          <EqForm.Group
            className="mr-4"
            error={touched.address?.state ? errors.address?.state : undefined}
            label={t("flex.members.form.state")}
          >
            <Field type="text" readOnly={readOnly} name="address.state" as={EqForm.Input} />
          </EqForm.Group>
          <EqForm.Group
            error={touched.address?.country ? errors.address?.country : undefined}
            label={t("flex.members.form.country")}
            required={!readOnly}
          >
            <Field type="text" readOnly={readOnly} name="address.country" as={EqForm.Input} />
          </EqForm.Group>
        </div>
        <Text variant="heading" size="small" weight="bold" className="mb-2">
          {t("flex.members.form.billingInformation")}
        </Text>
        <EqForm.Group
          error={touched.email ? errors.email : undefined}
          label={t("flex.members.form.contactEmail")}
          required={!readOnly}
        >
          <Field type="email" readOnly={readOnly} name="email" as={EqForm.Input} />
        </EqForm.Group>
        <EqForm.Group
          error={touched.contactName ? errors.contactName : undefined}
          label={t("flex.members.form.companyBusinessName")}
          required={!readOnly}
        >
          <Field type="text" readOnly={readOnly} name="contactName" as={EqForm.Input} />
        </EqForm.Group>
      </Form>
    </>
  );
};
