import { hotjar } from "react-hotjar";
import type { ReactNode } from "react";
import React, { useContext, useEffect, useState } from "react";
import { CookieSettings } from "./CookieContext";
import { Role, CurrentRole, CurrentProfile, useCookieSettings } from "@equiem/lib";

const hotjarSv = 6;

export const CookieProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const { cookiePreferencesSaved, optionalCookiesEnabled, setCookiePreferencesSaved, setOptionalCookiesEnabled } =
    useCookieSettings();
  const [trackingIsSetup, setIsTrackingSetup] = useState(false);

  const { currentRole } = useContext(CurrentRole);
  const { profile } = useContext(CurrentProfile);

  useEffect(() => {
    // only initialize when in the browser
    if (typeof window !== "undefined" && process.env.trackingEnabled === "true" && process.env.hotjarId != null) {
      // Check whether user has accepted cookie policy.
      if (optionalCookiesEnabled) {
        console.log("Initializing tracking software...");
        hotjar.initialize(parseInt(process.env.hotjarId), hotjarSv);
        // plugins should also only be initialized when in the browser
        setIsTrackingSetup(true);
      } else {
        console.log("Not Initializing tracking software due to cookie policy...");
      }
    }
  }, [optionalCookiesEnabled, currentRole, profile]);

  useEffect(() => {
    // only initialize when in the browser
    if (
      typeof window !== "undefined" &&
      process.env.trackingEnabled! === "true" &&
      optionalCookiesEnabled &&
      trackingIsSetup &&
      profile != null &&
      currentRole !== Role.Unknown
    ) {
      hotjar.identify(profile.uuid, {
        role: currentRole,
        companyName: profile.companyV2?.name ?? "Unknown",
      });
    }
  }, [profile, optionalCookiesEnabled, currentRole, trackingIsSetup]);

  return (
    <CookieSettings.Provider
      value={{
        cookiePreferencesSaved,
        setCookiePreferencesSaved,
        optionalCookiesEnabled,
        setOptionalCookiesEnabled,
      }}
    >
      {children}
    </CookieSettings.Provider>
  );
};
