import { useBcUuidContext } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import { useTheme } from "@equiem/react-admin-ui";
import { useRouter } from "next/router";
import type { PropsWithChildren } from "react";
import React, { useCallback, useEffect, useState, type FC } from "react";
import { NoAccess } from "../components/shared/NoAccess";
import { useMyPortfoliosQuery, type PortfolioFragmentFragment } from "../generated/gateway-client";
import { CurrentPortfolio } from "./PortfolioContext";

interface Props {
  defaultPortfolio?: PortfolioFragmentFragment;
}

export const PortfolioProvider: FC<PropsWithChildren<Props>> = ({ defaultPortfolio, children }) => {
  const [currentPortfolio, _setCurrentPortfolio] = useState<PortfolioFragmentFragment | null>(defaultPortfolio ?? null);
  const router = useRouter();
  const { colors } = useTheme();
  const { id } = router.query as { id?: string };
  const { data, loading } = useMyPortfoliosQuery();
  const { updateBcUuid } = useBcUuidContext();
  const { t } = useTranslation();

  const changePortfolio = useCallback(
    async (portfolioUuid: string, updateHistory?: boolean) => {
      const nextPath = router.pathname.includes("[id]")
        ? {
            pathname: router.pathname,
            query: {
              ...router.query,
              id: portfolioUuid,
            },
          }
        : {
            pathname: "/content-management/[id]/articles",
            query: {
              ...router.query,
              id: portfolioUuid,
            },
          };

      if (updateHistory !== false) {
        return router.push(nextPath);
      } else {
        return router.replace(nextPath);
      }
    },
    [router],
  );

  useEffect(() => {
    if (id == null && data != null && data.myPortfolios.length > 0) {
      void changePortfolio(data.myPortfolios[0].uuid, false);
    }
    if (id != null && data != null) {
      const portfolio = data.myPortfolios.find((p) => p.uuid === id);
      if (portfolio != null) {
        _setCurrentPortfolio(portfolio);
      } else if (data.myPortfolios.length > 0) {
        void changePortfolio(data.myPortfolios[0].uuid, false);
      }
    }
    if (data != null && data.myPortfolios.length === 0 && router.pathname !== "/content-management") {
      void router.replace({ pathname: "/content-management", query: router.query });
    }
  }, [id, data, changePortfolio, router]);

  useEffect(() => {
    const portfolio = data?.myPortfolios.find((p) => p.uuid === id) ?? null;
    const crumbName =
      portfolio != null
        ? `${t("breadcrumbs.contentManagement")} (${portfolio.name})`
        : t("breadcrumbs.contentManagement");

    updateBcUuid("content-management", crumbName);
  }, [data?.myPortfolios, id, t, updateBcUuid]);

  const setCurrentPortfolio = (portfolio: PortfolioFragmentFragment) => {
    void changePortfolio(portfolio.uuid);
  };

  return (
    <>
      <CurrentPortfolio.Provider
        value={{
          currentPortfolio,
          setCurrentPortfolio,
          myPortfolios: data?.myPortfolios ?? [],
        }}
      >
        <div className="content-page">
          {!loading && data != null && data.myPortfolios.length > 0 && children}
          {!loading && data?.myPortfolios.length === 0 && <NoAccess />}
        </div>
      </CurrentPortfolio.Provider>
      <style jsx>{`
        .content-page {
          background: ${colors.white};
        }
      `}</style>
    </>
  );
};
