import React, { useState } from "react";
import { v4 as uuidv4 } from "uuid";

import { useTranslation } from "@equiem/localisation-eq1";
import { Button, ColumnView, useTheme, useToast } from "@equiem/react-admin-ui";
import { RiAddLine, RiDeleteBinLine, RiEditLine } from "@equiem/react-admin-ui/icons";

interface P {
  isEditing: boolean;
  currentSubcategories?: Array<{ name: string; uuid: string }>;
  setCurrentSubcategories: (subcategories: Array<{ name: string; uuid: string }>) => void;
}
export const Subcategories: React.FC<P> = ({ isEditing, currentSubcategories, setCurrentSubcategories }) => {
  const { t } = useTranslation();
  const { colors } = useTheme();
  const toast = useToast();
  const [showSubCategoryAdder, setShowSubCategoryAdder] = useState(false);
  const [editingSubCategoryUuid, setEditingSubCategoryUuid] = useState<string>();

  return (
    <>
      <div
        className={
          (currentSubcategories !== undefined && currentSubcategories.length > 0) || showSubCategoryAdder
            ? "subcategories"
            : ""
        }
      >
        {currentSubcategories?.map((subCategory) => (
          <ColumnView.Item
            value={subCategory.name}
            key={subCategory.uuid}
            isEditing={subCategory.uuid === editingSubCategoryUuid}
            onChange={(name) => {
              const trimmedName = name.trim();
              // validate if name is already in the list currentSubcategories
              const isError =
                currentSubcategories.find((subCat) => subCat.name.toLowerCase() === trimmedName.toLowerCase())?.uuid !=
                null;
              if (isError) {
                toast.negative(t("requests.category.subCategoryNameTaken"));
                return;
              }
              setCurrentSubcategories(
                currentSubcategories.map((sc) => (sc.uuid === subCategory.uuid ? { ...sc, name } : sc)),
              );
              setEditingSubCategoryUuid(undefined);
            }}
            onReset={() => setEditingSubCategoryUuid(undefined)}
            maxIcons={2}
            actions={
              isEditing
                ? [
                    {
                      title: t("common.edit"),
                      disabled: false,
                      icon: RiEditLine,
                      onClick: () => {
                        setEditingSubCategoryUuid(subCategory.uuid);
                      },
                    },
                    {
                      title: t("common.delete"),
                      disabled: false,
                      icon: RiDeleteBinLine,
                      loading: false,
                      color: colors.status.danger.primary,
                      onClick: () => {
                        setCurrentSubcategories(currentSubcategories.filter((sc) => sc.uuid !== subCategory.uuid));
                        setEditingSubCategoryUuid(undefined);
                      },
                    },
                  ]
                : []
            }
          />
        ))}
        {showSubCategoryAdder ? (
          <ColumnView.Item
            isNew
            value=""
            placeholder={t("requests.category.addSubcategory")}
            onChange={(name: string) => {
              const trimmedName = name.trim();
              // validate if name is already in the list currentSubcategories
              const isError =
                currentSubcategories?.find(
                  (subCategory) => subCategory.name.toLowerCase() === trimmedName.toLowerCase(),
                )?.uuid != null;
              if (isError) {
                toast.negative(t("requests.category.subCategoryNameTaken"));
                return;
              }
              setCurrentSubcategories([...(currentSubcategories ?? []), { name: trimmedName, uuid: uuidv4() }]);
              setShowSubCategoryAdder(false);
            }}
            onReset={() => setShowSubCategoryAdder(false)}
          />
        ) : null}
      </div>
      {isEditing && (
        <div className="mt-3">
          <Button variant="secondary" onClick={() => setShowSubCategoryAdder(true)} disabled={showSubCategoryAdder}>
            <RiAddLine /> {t("requests.category.addSubcategory")}
          </Button>
        </div>
      )}
      <style jsx>{`
        .subcategories {
          border: 1px solid ${colors.grayscale[10]};
          border-radius: 8px;
        }
        .subcategories :global(.item),
        .subcategories :global(.item.item--new) {
          background-color: transparent;
          padding: 0.5rem 1rem;
        }
        .subcategories :global(.item):hover,
        .subcategories :global(.item.item--new):hover {
          background-color: ${colors.grayscale[3]};
        }
        .subcategories :global(.item:not(:last-child)) {
          border-bottom: 1px solid ${colors.grayscale[10]};
          border-radius: 0;
        }
      `}</style>
    </>
  );
};
