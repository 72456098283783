import React from "react";
import { RiArrowDownSLine } from "react-icons/ri";

import type { BaseButtonSize } from "./renderAdminButtonStyles";

interface AdminButtonDropdownToggleProps {
  size: BaseButtonSize;
  color?: string;
}

export const AdminButtonDropdownToggle: React.FC<AdminButtonDropdownToggleProps> = ({ size, color }) => {
  return (
    <>
      {" "}
      <RiArrowDownSLine
        size={{ sm: 16, md: 16, lg: 24 }[size]}
        style={{
          color,
          marginRight: { sm: "-3px", md: "-3px", lg: "-5px" }[size],
          marginLeft: { sm: "4px", md: "4px", lg: "8px" }[size],
        }}
      />
    </>
  );
};
