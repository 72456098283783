import { useTheme } from "@equiem/react-admin-ui";
import { RiGroupLine } from "@equiem/react-admin-ui/icons";
import React from "react";

interface P {
  capacity?: number | null;
  className?: string;
}
export const ResourceCardCapacity: React.FC<P> = ({ capacity, className = "" }) => {
  const theme = useTheme(true);

  return capacity != null && capacity > 0 ? (
    <>
      <div className={`resource-capacity ml-1 ${className}`}>
        <RiGroupLine height="13px" /> <span>{capacity}</span>
      </div>
      <style jsx>{`
        .resource-capacity {
          display: flex;
          align-items: center;
          gap: ${theme.spacers.s1};
          background: ${theme.colors.grayscale[10]};
          border-radius: 20px;
          padding: ${theme.spacers.s2} ${theme.spacers.s4};
          height: 28px;
        }
        span {
          font-size: 12px;
          font-weight: 500;
        }
      `}</style>
    </>
  ) : null;
};
