import { useTheme } from "@equiem/react-admin-ui";
import type { ComponentProps } from "react";
import React from "react";

export const TopMenuTitle: React.FC<ComponentProps<"h1">> = ({ children, ...props }) => {
  const { colors, spacers, isAdmin } = useTheme();

  return (
    <>
      <h1 {...props}>{children}</h1>
      <style jsx>{`
        h1 {
          cursor: default;
          text-transform: uppercase;
          font-weight: 500;
          font-size: 13px;
          line-height: 16px;
          color: ${isAdmin ? colors.medium : colors.dark};
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0 ${spacers.s3};
        }
      `}</style>
    </>
  );
};
