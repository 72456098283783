import { useTranslation } from "@equiem/localisation-eq1";
import { Text, useTheme } from "@equiem/react-admin-ui";
import { RiBuilding4Line } from "@equiem/react-admin-ui/icons";
import React, { useContext } from "react";
import { BookingModalInfo } from "../contexts/BookingModalInfoProvider";

interface P {
  className?: string;
  showAddress?: boolean;
  showLabel?: boolean;
}
export const BookingResourceLocation: React.FC<P> = ({ className, showAddress = false, showLabel = false }) => {
  const { t } = useTranslation();
  const { resource } = useContext(BookingModalInfo);
  const theme = useTheme(true);

  if (resource.building == null) {
    return null;
  }

  return (
    <div className={className}>
      {showLabel && (
        <div className="pb-3">
          <Text variant="label" color={theme.colors.grayscale[60]}>
            {t("bookings.resources.location")}
          </Text>
        </div>
      )}
      <div className="location-cont">
        <div>
          <RiBuilding4Line size={16} color={theme.colors.grayscale[50]} />
        </div>
        <div className="location-data">
          {resource.destination.name}, {resource.building.name}
          {resource.level != null && `, ${resource.level.name}`}
          {showAddress && <>, {resource.building.address}</>}
        </div>
      </div>

      <style jsx>{`
        .location-cont {
          display: flex;
          gap: ${theme.spacers.s3};
        }
      `}</style>
    </div>
  );
};
