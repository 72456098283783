import React from "react";
import { Field, Form } from "formik";
import { get } from "lodash";

import { useSaferFormikContext } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import { Form as EqForm } from "@equiem/react-admin-ui";

import type { AddressInfo, BuildingFormValues } from "../types";

import { AddressInput } from "./AddressInput";

export const BuildingForm: React.FC = () => {
  const { t } = useTranslation();
  const { values, setValues, handleChange, setTouched, touched, errors } = useSaferFormikContext<BuildingFormValues>();

  const unitOptions: Array<{ value: BuildingFormValues["units"]; label: string }> = [
    { value: "square feet", label: t("common.squareFeet") },
    { value: "square meters", label: t("common.squareMeters") },
  ];

  const addressToggleOptions: Array<{ value: BuildingFormValues["addressStatus"]; label: string }> = [
    { value: "enabled", label: t("common.enabled") },
    { value: "disabled", label: t("common.disabled") },
  ];

  const getFieldError = (field: string): string | undefined =>
    get(touched, field.split(".")[0]) === true || Array.isArray(get(touched, field))
      ? get(errors, field as keyof typeof errors)?.toString()
      : undefined;

  const handleAddress = (address: AddressInfo) => {
    setValues({ ...values, address }).catch(console.error);
    setTouched({ ...touched, address: true }).catch(console.error);
  };

  return (
    <div className="mb-4">
      <div className="main-container-inner">
        <Form>
          <EqForm.Group label={t("settings.build.buildingName")} required error={getFieldError("name")}>
            <Field
              as={EqForm.Input}
              placeholder={t("settings.build.enterBuildingName")}
              type="text"
              name="name"
              autoComplete="off"
            />
          </EqForm.Group>
          <EqForm.Group
            label={t("settings.build.enableAddress")}
            tooltipText={t("settings.build.buildingAddressHint")}
            tooltipPlacement="bottom"
            showTooltip
            required
          >
            <EqForm.Select name="addressStatus" value={values.addressStatus} onChange={handleChange}>
              {addressToggleOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </EqForm.Select>
          </EqForm.Group>
          {values.address != null && values.addressStatus === "enabled" && (
            <EqForm.Group label={t("common.address")} error={getFieldError("address.address")} required>
              <AddressInput name="address" value={values.address} onChange={handleAddress} />
            </EqForm.Group>
          )}
          <div className="row">
            <EqForm.Group label={t("common.area")} error={getFieldError("area")}>
              <Field
                as={EqForm.Input}
                placeholder={t("settings.build.enterAreaSize")}
                type="number"
                name="area"
                autoComplete="off"
              />
            </EqForm.Group>
            <EqForm.Group label={t("common.unit")}>
              <EqForm.Select name="units" value={values.units} onChange={handleChange}>
                <option value="" disabled>
                  {t("settings.build.selectUnit")}
                </option>
                {unitOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </EqForm.Select>
            </EqForm.Group>
          </div>
          <EqForm.Group label={t("common.occupants")} error={getFieldError("occupants")}>
            <Field
              as={EqForm.Input}
              placeholder={t("settings.build.enterNumberOfOccupants")}
              type="number"
              name="occupants"
              autoComplete="off"
            />
          </EqForm.Group>
        </Form>
      </div>
      <style jsx>
        {`
          .main-container-inner {
            padding-right: 0px !important;
          }

          .row {
            display: flex;
            flex-direction: row;
            gap: 8px;
          }
        `}
      </style>
    </div>
  );
};
