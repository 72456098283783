import { useTranslation } from "@equiem/localisation-eq1";
import { FilterDateModifier, type FilterItem } from "@equiem/react-admin-ui";
import { RiCalendar2Line } from "@equiem/react-admin-ui/icons";

export function useCreditsFilterDate() {
  const { t } = useTranslation();

  const filter: FilterItem = {
    title: t("common.date"),
    type: "date",
    icon: RiCalendar2Line,
    modifiers: [FilterDateModifier.is, FilterDateModifier.between],
  };

  return {
    filter,
  };
}
