import { Form, useTheme } from "@equiem/react-admin-ui";
import type { FC } from "react";
import { useCallback, useContext } from "react";
import type { Invite } from "./NewInvitesContext";
import { NewInvites } from "./NewInvitesContext";
import { useTranslation } from "@equiem/localisation-eq1";
import { stringNotEmpty } from "@equiem/lib";
import { useFlexTenants } from "./hooks/useFlexTenants";

export const InviteFlexTenantWidget: FC<{ invite: Invite; disabled: boolean }> = ({ invite, disabled }) => {
  const { colors } = useTheme(true);
  const { updateFlexTenant } = useContext(NewInvites);
  const { flexTenants: options, loading } = useFlexTenants({
    granteeCompanyUuids: stringNotEmpty(invite.companyUuid) ? [invite.companyUuid] : [],
  });

  const { t } = useTranslation();

  const onChange = useCallback(
    (e: { target: { value?: string } }) => {
      updateFlexTenant(invite.id, e.target.value);
    },
    [updateFlexTenant, invite.id],
  );

  return (
    <Form.DynamicSelect
      name={t("home.widgets.selectFlexTenant")}
      size="sm"
      disabled={disabled || loading || options.length === 0}
      noneLabel={
        <span style={{ color: colors.muted1 }}>
          <span>{t("home.widgets.selectFlexTenant")}</span>
        </span>
      }
      showChrome={"onInteraction"}
      showSelectedOnList
      supportsMobile
      allowClear
      options={options}
      value={invite.flexTenantUuid ?? ""}
      onChange={onChange}
      className="flex-tenant-selector"
      minWidth={240}
    />
  );
};
