import React from "react";

import { Tooltip } from "../Tooltip/Tooltip";
import { ProgressCircle } from "../ProgressCircle/ProgressCircle";
import { RiInformationLine } from "../../admin-icons";
import { useTheme } from "../../contexts";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  title: string;
  tooltip?: string;
  value: React.ReactNode;
  children?: React.ReactNode;
  loading?: boolean;
  description?: React.ReactNode;
  emptyTemplate?: React.ReactNode;
  isEmpty?: boolean;
}

export const ValueMetric: React.FC<Props> = ({
  title,
  tooltip,
  value,
  children,
  description,
  loading = false,
  emptyTemplate,
  ...rest
}) => {
  const { colors } = useTheme();

  return (
    <div className="stat d-flex flex-column justify-content-between p-5" {...rest}>
      <div className="d-flex align-items-center justify-content-between">
        <span className="title">{title}</span>
        {tooltip != null && (
          <Tooltip placement="left" showArrow title={tooltip}>
            <div style={{ display: "inline-block" }}>
              <RiInformationLine
                style={{
                  color: colors.medium,
                  cursor: "pointer",
                }}
              />
            </div>
          </Tooltip>
        )}
      </div>
      <div className="container">
        {loading && <ProgressCircle size="sm" />}
        {!loading && emptyTemplate}
        {!loading && emptyTemplate == null && (
          <>
            <h1>{value}</h1>
            {description != null && (
              <>
                <hr className="separator" />
                <p className="description">{description}</p>
              </>
            )}
          </>
        )}
      </div>
      <style jsx>
        {`
          .description {
            color: ${colors.grayscale[60]};
            font-size: 14px;
            font-weight: 400;
            padding: 0;
            margin: 0;
          }

          .container {
            align-items: ${emptyTemplate != null ? "center" : "start"};
            display: flex;
            flex-direction: column;
            justify-content: ${emptyTemplate != null ? "center" : "start"};
          }

          .separator {
            border: 0;
            border-top: 1px solid ${colors.grayscale[10]};
            width: 100%;
            margin: 1rem 0;
          }

          .stat {
            border: 1px solid #e6e6e6;
            border-radius: 8px;
            min-height: 232px;
            height: 100%;
          }
          .title {
            font-weight: 500;
            font-size: 0.75rem;
            line-height: 1rem;
            text-transform: uppercase;
            color: rgba(0, 0, 0, 0.6);
          }
          h1 {
            width: -webkit-fill-available;
            font-weight: 700;
            font-size: 3rem;
            line-height: 3rem;
          }

          .placeholder {
            height: 1.5rem;
          }
        `}
      </style>
    </div>
  );
};
