import React, { useCallback, useContext, useEffect, useState } from "react";
import { DateTime } from "luxon";

import { ButtonTray, useSaferFormikContext } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import { Button, useTheme } from "@equiem/react-admin-ui";

import { Modal } from "../../contexts/ModalContext";
import { withContexts } from "../../contexts/withContexts";
import type { FormValues } from "../appointment/types";

import { DeskMenu } from "./components/DeskMenu";
import { WalkInStep1 } from "./components/steps/WalkInStep1";
import { WalkInStep2 } from "./components/steps/WalkInStep2";
import { WalkInStep3 } from "./components/steps/WalkInStep3";

interface Props {
  uuid?: string;
  isAllBuildingsReceptions?: boolean;
}

const WalkInBase: React.FC<Props> = ({ uuid, isAllBuildingsReceptions }) => {
  const { t } = useTranslation();
  const { colors } = useTheme(true);
  const modal = useContext(Modal);
  const [currentStep, setCurrentStep] = useState(1);
  const { isValid, isSubmitting, handleSubmit, setFieldValue } = useSaferFormikContext<FormValues>();

  const handleStep = (step: number) => {
    setCurrentStep(step);
  };

  const handleCancel = () => {
    modal.open("CloseWalkin");
  };

  const reset = useCallback(() => {
    const now = DateTime.local().plus({ minutes: 1 });

    setFieldValue("isWalkIn", true).catch(console.error);
    setFieldValue("date", now.toISODate()).catch(console.error);
    setFieldValue("startTime", Math.round(now.toSeconds())).catch(console.error);
  }, [setFieldValue]);

  useEffect(() => {
    reset();
    const interval = setInterval(reset, 60_000);

    return () => {
      clearInterval(interval);
    };
  }, [reset]);

  return (
    <div className="walkin-container d-flex flex-column">
      <DeskMenu isAllBuildingsReceptions={isAllBuildingsReceptions} uuid={uuid as string} search="" />
      <div className="p-7 walkin-content">
        <h2>{t("visitors.walkIn.newWalkIn")}</h2>
        <div className="step-container d-flex flex-column">
          <WalkInStep1 isActive={currentStep === 1} handleStep={handleStep} />
          <WalkInStep2 isActive={currentStep === 2} handleStep={handleStep} receptionUuid={uuid} />
          <WalkInStep3 isActive={currentStep === 3} receptionUuid={uuid} />
        </div>
      </div>
      <ButtonTray>
        <Button variant="ghost" size="md" className="mr-3 checkin-cancel" onClick={handleCancel}>
          {t("common.cancel")}
        </Button>
        <Button
          variant="primary"
          size="md"
          className="checkin-button"
          disabled={isSubmitting || !isValid}
          onClick={() => handleSubmit()}
        >
          {t("visitors.reception.checkIn")}
        </Button>
      </ButtonTray>
      <style jsx>
        {`
          h2 {
            font-weight: 600;
          }
          .walkin-container {
            background: ${colors.white};
          }
          .walkin-content {
            min-height: 90vh;
          }
        `}
      </style>
    </div>
  );
};

export const WalkIn = withContexts(WalkInBase);
