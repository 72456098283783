import React from "react";

import { BodyPortal } from "../BodyPortal/BodyPortal";

interface Props {
  zIndex?: number;
}

export const Backdrop: React.FC<Props> = ({ zIndex }) => {
  return (
    <BodyPortal>
      <div className="overlay-backdrop">
        <style jsx>{`
          div {
            position: fixed;
            top: 0;
            left: 0;
            width: 100vw;
            height: 100vh;
            background: #000;
            opacity: 0.4;
            z-index: ${zIndex ?? 1000};
          }
        `}</style>
      </div>
    </BodyPortal>
  );
};
