import React, { createContext, useContext, useMemo, useState } from "react";

type WidgetEvent = "onSave";

type ActionState = "normal" | "loading" | "hidden" | "disabled";

type CallbackFunction = VoidFunction;

interface WidgetContext {
  subscribe: (id: WidgetEvent, callback: CallbackFunction) => VoidFunction;
  handleSave: () => void;
  actionsState: { [key in WidgetEvent]: ActionState };
  setActionState: (id: WidgetEvent, state: ActionState) => void;
}

export const WidgetContext = createContext<WidgetContext>({
  subscribe: () => () => undefined,
  handleSave: () => undefined,
  setActionState: () => undefined,
  actionsState: {
    onSave: "normal",
  },
});

export interface Props {
  children: React.ReactNode;
}

export const WidgetProvider: React.FC<Props> = ({ children }) => {
  const [actionsState, setActionsState] = useState<{ [key in WidgetEvent]: ActionState }>({
    onSave: "normal",
  });

  const [subscribers, setSubscribers] = useState<{ [key in WidgetEvent]: CallbackFunction[] }>({
    onSave: [],
  });

  const subscribe = useMemo(() => {
    return (id: WidgetEvent, callback: CallbackFunction) => {
      setSubscribers((prev) => ({ ...prev, [id]: [...prev[id], callback] }));
      return () => {
        setSubscribers((prev) => ({ ...prev, [id]: prev[id].filter((fn) => fn !== callback) }));
      };
    };
  }, [setSubscribers]);

  const setActionState = useMemo(() => {
    return (id: WidgetEvent, state: ActionState) => {
      setActionsState((prev) => ({ ...prev, [id]: state }));
    };
  }, [setActionsState]);

  const handleSave = () => {
    subscribers.onSave.forEach((fn) => fn());
  };

  return (
    <WidgetContext.Provider value={{ subscribe, handleSave, actionsState, setActionState }}>
      {children}
    </WidgetContext.Provider>
  );
};

export const useWidgetContext = () => useContext(WidgetContext);
