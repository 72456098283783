import { useTranslation } from "@equiem/localisation-eq1";
import { CreditTransactionType } from "../../../generated/gateway-client";

export const useCreditsTypes = () => {
  const { t } = useTranslation();
  const types = {
    [CreditTransactionType.Charge]: t("credits.types.charge"),
    [CreditTransactionType.Purchase]: t("credits.types.purchase"),
    [CreditTransactionType.Refund]: t("credits.types.refund"),
    [CreditTransactionType.Adjustment]: t("credits.types.adjustment"),
  };

  return types;
};
