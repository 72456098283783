import React, { useContext, useState, useMemo } from "react";
import { useTheme } from "../../contexts/Theme";
import { stringNotEmpty } from "../../util/stringNotEmpty";
import { Dropdown } from "../Dropdown";
import { DropdownMenu } from "../Dropdown/DropdownMenu/DropdownMenu";
import * as Form from "../Form";
import { FilterChip } from "./FilterChip";
import { ComplexFilterContext } from "./ComplexFilterContext";
import { Text } from "../Text/Text";
import type { FilterItemProps, FilterItemSelect } from "./ComplexFilter.types";
import { FilterSelectModifier, FilterType } from "./ComplexFilter.types";
import { useTranslation } from "@equiem/localisation-eq1";
import { ModifierSelect } from "./ModifierSelect";
import { RiSearchLine } from "react-icons/ri";

export const defaultModifier = FilterSelectModifier.is;

export const FilterSelect: React.FC<FilterItemProps<FilterItemSelect>> = ({
  title,
  name,
  options,
  modifiers,
  icon,
  showSearch,
}) => {
  const { t } = useTranslation();
  const { colors } = useTheme();
  const { values, setValue, removeValue } = useContext(ComplexFilterContext);
  const [selectedModifier, setSelectedModifier] = useState<FilterSelectModifier>(
    (values[name]?.modifier ?? modifiers?.[0] ?? defaultModifier) as FilterSelectModifier,
  );
  const [prevSelectedModifier, setPrevSelectedModifier] = useState(selectedModifier);
  const [search, setSearch] = useState("");

  if (selectedModifier !== prevSelectedModifier) {
    setPrevSelectedModifier(selectedModifier);
    setValue(name, {
      type: FilterType.select,
      modifier: selectedModifier,
      value: undefined,
    });
  }

  const handleChange = (value: string) => () => {
    setValue(name, {
      type: FilterType.select,
      modifier: selectedModifier,
      value,
    });
  };

  const getLocalizedTitle = (modifier: FilterSelectModifier) => {
    switch (modifier) {
      case FilterSelectModifier.is:
        return t("common.is");
      default:
        return modifier;
    }
  };

  const handleSearch = (searchValue: string) => {
    setSearch(searchValue);

    const filterValue = {
      type: FilterType.select,
      modifier: selectedModifier,
      value: (values[name]?.value ?? undefined) as string | undefined,
    } as const;
    setValue(name, searchValue.length > 0 ? { ...filterValue, search: searchValue } : filterValue);
  };

  const filteredOptions = useMemo(() => {
    const loweredSearch = search.toLowerCase();

    return options.filter((option) => {
      if (!stringNotEmpty(loweredSearch)) {
        return true;
      }

      const toSearch =
        option.searchKeywords != null && option.searchKeywords.length > 0 ? option.searchKeywords : [option.label];

      return toSearch.some((keyword) => keyword.toLowerCase().includes(loweredSearch));
    });
  }, [options, search]);

  const value = options.find((o) => o.value === values[name]?.value)?.label;

  return (
    <>
      <Dropdown placement="bottom-start" flip>
        <FilterChip
          title={title}
          value={value}
          name={name}
          type={FilterType.options}
          onClose={() => removeValue(name)}
          icon={icon}
        />
        <DropdownMenu width={252} mobileView="regular">
          {modifiers != null && (
            <div className="options-top">
              <Text variant="label" className="m-0">
                {title}
              </Text>
              <ModifierSelect
                options={modifiers.map((m) => ({
                  value: m,
                  label: getLocalizedTitle(m),
                }))}
                selected={selectedModifier}
                onChange={(v) => setSelectedModifier(v as FilterSelectModifier)}
              />
            </div>
          )}
          {showSearch === true && (
            <div className="search-wrapper">
              <Form.InputGroup.Group className="search-input">
                <Form.InputGroup.Prefix>
                  <RiSearchLine color={colors.muted1} size={16} />
                </Form.InputGroup.Prefix>
                <Form.Input
                  placeholder={`${t("common.search")}...`}
                  autoComplete="off"
                  onChange={(e) => handleSearch(e.target.value)}
                  type="text"
                />
              </Form.InputGroup.Group>
            </div>
          )}
          <div className="options-list">
            {filteredOptions.map((option) => (
              <div
                className={values[name]?.value === option.value ? "option option-active" : "option"}
                key={option.value}
                onClick={handleChange(option.value)}
              >
                <input
                  type="radio"
                  name={name}
                  value={option.value}
                  onChange={handleChange(option.value)}
                  checked={values[name]?.value === option.value}
                  className="m-0"
                />
                <Text variant="text" component="label" size="small" className="ml-3">
                  {option.label}
                </Text>
              </div>
            ))}
          </div>
        </DropdownMenu>
      </Dropdown>
      <style jsx>{`
        .options-list {
          display: flex;
          flex-direction: column;
          padding: 0 0.5rem;
          gap: 0.125rem;
        }

        .search-wrapper :global(.search-input) {
          margin: 0 0.5rem;
        }

        .options-top {
          padding: 0 1rem 0.25rem;
          display: flex;
          flex-direction: row;
          align-items: center;
          color: ${colors.grayscale[100]};
        }

        .option {
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 0.5rem;
          cursor: pointer;
        }

        .option-active {
          background-color: ${colors.blue[10]};
          border-radius: 0.25rem;
        }

        input[type="radio"] {
          width: 16px;
          height: 16px;
          background: #ffffff;
          border: 1px solid ${colors.grayscale[20]};
          border-radius: 50%;
          appearance: none;
          position: relative;
          margin: 0.25rem;
          flex-shrink: 0;
        }

        input[type="radio"]:checked {
          border-color: ${colors.blue[60]};
        }

        input[type="radio"]:checked:before {
          content: "";
          position: absolute;
          transform: translate(50%, 50%);
          top: 0;
          left: 0;
          width: 50%;
          height: 50%;
          border-radius: 50%;
          background-color: ${colors.blue[60]};
        }
      `}</style>
    </>
  );
};
