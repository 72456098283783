/* eslint-disable @typescript-eslint/no-floating-promises */
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";

import { useSiteContext } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import { Button, Modal, ProgressCircle } from "@equiem/react-admin-ui";

import { Modal as ModalContext } from "../../../contexts/ModalContext";
import { useVisitorReceptionsQuery, VisitorRole } from "../../../generated/visitors-client";
import { filterReceptionList } from "../../../helpers/filterReceptionList";
import { ReceptionItem } from "../../receptions/components/ReceptionItem";
import { useReceptionDate } from "../hooks/useReceptionDate";

export const ReceptionsListWidget: React.FC = () => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const { pushUrl } = useReceptionDate();
  const modal = useContext(ModalContext);
  const { name } = useSiteContext();
  const {
    data: receptionsData,
    loading,
    fetchMore,
  } = useVisitorReceptionsQuery({
    variables: { as: VisitorRole.Receptionist, first: 1000 },
    skip: !showModal,
  });

  const onClose = useCallback(() => {
    setShowModal(false);
    modal.close();
  }, [setShowModal, modal]);

  useEffect(() => {
    if (modal.activeModal === "ReceptionList") {
      setShowModal(true);
    }
  }, [modal.activeModal]);

  const receptions = useMemo(() => filterReceptionList(receptionsData, t), [receptionsData, t]);
  const isReceptionistMultipleBuildings = useMemo(
    () => receptions.filter((reception) => reception.company == null).length > 1,
    [receptions],
  );

  const handleNextPage = useCallback(
    async () =>
      fetchMore({
        variables: {
          after: receptionsData?.visitorReceptions.pageInfo.endCursor,
        },
      }),
    [receptionsData?.visitorReceptions.pageInfo.endCursor],
  );

  const hasNextPage = receptionsData?.visitorReceptions.pageInfo.hasNextPage ?? false;

  return (
    <>
      <Modal.Dialog
        title={t("visitors.common.visitorManagement")}
        show={showModal}
        onHide={onClose}
        hideOnEsc={true}
        hideOnClick={false}
        size="md"
        centered
      >
        <Modal.Header closeButton={true} noBorder={true} intro={t("visitors.reception.selectIntro")} />
        <Modal.Body>
          <div className="d-flex flex-column list receptions-list">
            {receptions.map((reception) => (
              <ReceptionItem
                key={reception.uuid}
                type={reception.company != null ? "tenant" : "building"}
                uuid={reception.uuid}
                buildingName={reception.building?.name}
                buildingLevelName={reception.buildingLevel?.name}
                companyName={reception.company?.name}
                suffix={reception.suffix}
              />
            ))}
            <div className="mt-3 p-3 d-flex align-items-center justify-content-center">
              {loading ? (
                <ProgressCircle className="d-flex align-items-center justify-content-center" size={"sm"} />
              ) : (
                <>
                  <Button
                    variant={!hasNextPage ? "ghost" : "outline"}
                    size="sm"
                    onClick={() => {
                      handleNextPage();
                    }}
                    disabled={!hasNextPage}
                  >
                    {!hasNextPage ? t("common.endOfList") : t("common.loadMore")}
                  </Button>
                </>
              )}
            </div>
          </div>
          {isReceptionistMultipleBuildings && (
            <Button
              className="d-flex w-100 mt-3"
              variant="secondary"
              size="md"
              onClick={() => {
                void pushUrl("/visitor-management/building-receptions");
              }}
            >
              {t("visitors.receptions.allVisitorsForSiteName", { siteName: name })}
            </Button>
          )}
          <style jsx>{`
            .list {
              gap: 8px;
              max-height: 400px;
              overflow: auto;
            }
            .font-weight-bold {
              line-height: 28px;
            }
          `}</style>
        </Modal.Body>
      </Modal.Dialog>
    </>
  );
};
