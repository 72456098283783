import { stringIsEmpty } from "@equiem/lib";
import { DateTime } from "luxon";

export const dateFormat = "yyyy-LL-dd";
export const timeFormat = "HH:mm";

const intervalSize = 5;
export const toDefaultTime = (time: number | null | undefined, timezone: string, isEndTime: boolean): string => {
  if (time == null) {
    return "";
  }

  let dt = DateTime.fromMillis(time, { zone: timezone }).startOf("minute");

  // @todo: I am not 100% convince with this function but it
  // is what it is, I have done a lot of other cleanup. #futureProblem
  // we now only let users select times in 15 minute intervals
  const modulo = dt.minute % intervalSize;
  if (modulo > 0) {
    const adjustment = isEndTime ? intervalSize - modulo : -modulo;
    dt = dt.set({ minute: dt.minute + adjustment });
  }

  return dt.toFormat(timeFormat);
};

export const formatSelectedTime = (
  timezone: string,
  selectedDate?: string | null,
  selectedStartTime?: string | null,
  selectedEndTime?: string | null,
) => {
  if (stringIsEmpty(selectedDate) || (stringIsEmpty(selectedStartTime) && stringIsEmpty(selectedEndTime))) {
    return null;
  }

  const format = `${dateFormat} ${timeFormat}`;

  const start = DateTime.fromFormat(`${selectedDate} ${selectedStartTime}`, format, { zone: timezone });
  const end = DateTime.fromFormat(`${selectedDate} ${selectedEndTime}`, format, { zone: timezone });

  const startValid = selectedStartTime !== "" && start.isValid;
  const endValid = selectedEndTime !== "" && end.isValid && (!startValid || start < end);

  return startValid || endValid
    ? {
        start: startValid ? start.toMillis() : undefined,
        end: endValid ? end.toMillis() : undefined,
      }
    : null;
};
