import { useTranslation } from "@equiem/localisation-eq1";
import type { Placement } from "@floating-ui/react-dom";
import { isEmpty } from "lodash";
import isEqual from "lodash/isEqual";
import { compressToEncodedURIComponent, decompressFromEncodedURIComponent } from "lz-string";
import React, { useEffect, useMemo, useState } from "react";
import {
  RiAddLine,
  RiCalendar2Line,
  RiEqualizerLine,
  RiHashtag,
  RiListCheck,
  RiText,
  RiTimeLine,
  RiTimerLine,
} from "react-icons/ri";
import { useIsMobileWidth } from "../../hooks";
import { AdminButton as Button } from "../Button/AdminButton";
import { AdminDropdownButton as DropdownButton } from "../Dropdown/DropdownButton/AdminDropdownButton";
import { DropdownItem } from "../Dropdown/DropdownItem/DropdownItem";
import type {
  FilterItem,
  FilterItemOptions,
  FilterItemSelect,
  FilterItemText,
  FilterValue,
} from "./ComplexFilter.types";
import {
  FilterDateModifier,
  FilterDateMonthModifier,
  FilterDurationModifier,
  FilterIsModifier,
  FilterNumberModifier,
  FilterOptionsModifier,
  FilterSelectModifier,
  FilterTextModifier,
  FilterTimeModifier,
  FilterType,
} from "./ComplexFilter.types";
import { ComplexFilterContext } from "./ComplexFilterContext";
import { FilterIs } from "./FilterIs";
import { FilterOptionsV2 } from "./FilterOptionsV2";
import { FilterDate, defaultModifier as defaultDateModifier } from "./FilterDate";
import { FilterDateMonth, defaultModifier as defaultDateMonthModifier } from "./FilterDateMonth";
import { FilterDuration, defaultModifier as defaultDurationModifier } from "./FilterDuration";
import { FilterNumber, defaultModifier as defaultNumberModifier } from "./FilterNumber";
import { FilterOptions, defaultModifier as defaultOptionsModifier } from "./FilterOptions";
import { FilterSelect, defaultModifier as defaultSelectModifier } from "./FilterSelect";
import { FilterText, defaultModifier as defaultTextModifier } from "./FilterText";
import { FilterTime, defaultModifier as defaultTimeModifier } from "./FilterTime";

type FilterButtonStyle = "primary" | "secondary";

interface FilterProps {
  renderChips: () => React.ReactNode;
  renderFilterButton: (style?: FilterButtonStyle, placement?: Placement) => React.ReactNode;
  renderClearButton: () => React.ReactNode;
}

interface ComplexFilterProps {
  filters: Record<string, FilterItem>;
  loading?: boolean;
  initialValues?: Record<string, FilterValue>;
  defaultValues?: Record<string, FilterValue>;
  onChange?: (values: Record<string, FilterValue>) => void;
  children: (props: FilterProps) => React.ReactNode;
  language?: string;
  autoShow?: boolean;
  additionalFilterDropdownContent?: React.ReactNode;
  disabled?: boolean;
  searchCallback?: (search: string) => void;
  urlParamsProvider?: {
    setParam: (key: string, value: string) => void;
    deleteParam: (key: string) => void;
    currentUrlParams: Record<string, string | string[] | undefined>;
  };
}

const defaultModifiers: Record<FilterType, string> = {
  [FilterType.datemonth]: defaultDateMonthModifier,
  [FilterType.date]: defaultDateModifier,
  [FilterType.time]: defaultTimeModifier,
  [FilterType.duration]: defaultDurationModifier,
  [FilterType.number]: defaultNumberModifier,
  [FilterType.options]: defaultOptionsModifier,
  [FilterType.infiniteOptions]: defaultOptionsModifier,
  [FilterType.text]: defaultTextModifier,
  [FilterType.select]: defaultSelectModifier,
  [FilterType.is]: "",
};

const excludeInvalid = (
  values: Record<string, FilterValue>,
  filters: Record<string, FilterItem>,
): Record<string, FilterValue> => {
  const entries = Object.entries(values)
    .filter(([key, value]) => {
      const filter = filters[key];
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (filter == null || value == null || value.type !== filter.type) {
        return false;
      }

      const modifiers = filter.modifiers ?? [defaultModifiers[filter.type]];
      if (!modifiers.includes(value.modifier)) {
        return false;
      }

      return true;
    })
    .map(([key, value]) => {
      if (value?.value == null) {
        return [key, value] as const;
      }
      const filter = filters[key];

      let mappedValue: FilterValue = value;
      if (filter.type === "select" && !filter.options.some((option) => option.value === value.value)) {
        // wipe the value if it's not currently available
        mappedValue = { ...value, value: undefined };
      }

      if (value.type === "options") {
        // only load the options that are currently available, and update the label to the current value
        const available = Object.fromEntries(
          (filter as FilterItemOptions).options.map((opt) => [opt.value, opt.label]),
        );
        mappedValue = {
          ...value,
          value: value.value
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            .filter((selected) => available[selected.value] != null)
            .map((selected) => ({ ...selected, label: available[selected.value] })),
        };
      }

      return [key, mappedValue] as const;
    });

  return Object.fromEntries(entries);
};

const EMPTY_FILTERS: Record<string, FilterValue> = {};

const shouldOverride = (prev: FilterValue, curr: FilterValue) => {
  if (prev == null) {
    return true; // no prev value, use the current one
  }
  if (curr == null || curr.value == null || (Array.isArray(curr.value) && curr.value.length === 0)) {
    return false; // no current value, or value is empty, use the prev one
  }

  // override if the current value is different from prev (excluding the
  // `defaultValue` flag so we correctly identify when the value is set to the
  // default)
  const { defaultValue: _, ...prevRest } = prev;
  const { defaultValue: __, ...currRest } = curr;
  return !isEqual(prevRest, currRest);
};

const withDefaultFilters = (filters: Record<string, FilterValue>, defaultFilters: Record<string, FilterValue>) =>
  [
    ...Object.entries(defaultFilters)
      .filter(([_key, value]) => value != null)
      .map(([key, value]) => [key, { ...value, defaultValue: true } as FilterValue] as const),
    ...Object.entries(filters)
      .filter(([_key, value]) => value != null && value.defaultValue !== true) // ignore default values from saved states
      .map(([key, value]) => [key, { ...value, defaultValue: false } as FilterValue] as const),
  ].reduce<Record<string, FilterValue>>(
    (acc, [key, value]) => (shouldOverride(acc[key], value) ? { ...acc, [key]: value } : acc),
    {},
  );

export const ComplexFilter: React.FC<ComplexFilterProps> = ({
  children,
  filters,
  loading = false,
  initialValues = EMPTY_FILTERS,
  defaultValues = EMPTY_FILTERS,
  onChange,
  language,
  autoShow,
  additionalFilterDropdownContent,
  disabled = false,
  urlParamsProvider = {
    currentUrlParams: {
      filters: undefined,
    },
    setParam: () => {
      // do nothing
    },
    deleteParam: () => {
      // do nothing
    },
  },
}) => {
  const isMobile = useIsMobileWidth();
  const { t } = useTranslation();
  const emptyFilters = loading ? EMPTY_FILTERS : excludeInvalid(initialValues, filters);
  const initialFilters = useMemo(() => {
    const decompressFiltersValues = decompressFromEncodedURIComponent(
      urlParamsProvider.currentUrlParams.filters as string,
    );
    return decompressFiltersValues === "" || decompressFiltersValues == null
      ? emptyFilters
      : (JSON.parse(decompressFiltersValues) as Record<string, FilterValue>);
  }, []);
  const [valuesRaw, setValues] = useState<Record<string, FilterValue>>(initialFilters);
  const [initialValuesApplied, setInitialValuesApplied] = useState(!loading);
  const [lastAddedFilter, setLastAddedFilter] = useState<string>();

  const values = useMemo(() => withDefaultFilters(valuesRaw, defaultValues), [valuesRaw, defaultValues]);

  const filtersDisabled = disabled || loading;
  const allValuesAreDefault = Object.values(values).every((value) => value?.defaultValue === true);

  // handle filters query params
  useEffect(() => {
    if (!initialValuesApplied) {
      return;
    }
    if (isEmpty(values)) {
      if (urlParamsProvider.currentUrlParams.filters != null) {
        urlParamsProvider.deleteParam("filters");
      }
    } else {
      const newFiltersString = JSON.stringify(values);
      const compressedFilters = compressToEncodedURIComponent(newFiltersString);
      if (compressedFilters !== urlParamsProvider.currentUrlParams.filters) {
        urlParamsProvider.setParam("filters", compressedFilters);
      }
    }
  }, [initialValuesApplied, values]);

  useEffect(() => {
    if (loading) {
      setValues(EMPTY_FILTERS);
      setInitialValuesApplied(false);
    } else if (!initialValuesApplied) {
      const newValues = excludeInvalid(initialValues, filters);
      if (!isEqual(values, newValues)) {
        setValues(newValues);
      }
      setInitialValuesApplied(true);
    }
  }, [loading, initialValuesApplied, filters, initialValues, onChange, values]);

  useEffect(() => {
    if (!loading && initialValuesApplied) {
      onChange?.(values);
    }
  }, [loading, initialValuesApplied, values, onChange]);

  useEffect(() => {
    if (isMobile) {
      // no auto-open the modal with selection, mobile uses modals, not dropdowns
      setLastAddedFilter(undefined);
    }
  }, [isMobile]);

  const setValue = (name: string, value: FilterValue) => {
    if (filtersDisabled || (isMobile && value?.value == null && values[name]?.value == null)) {
      return;
    }

    // only trigger an update if anything actually changed
    setValues((prev) => (!isEqual(prev[name], value) ? { ...prev, [name]: value } : prev));
  };

  const removeValue = (name: string) => {
    if (filtersDisabled) {
      return;
    }

    setValues((prev) => {
      const { [name]: _, ...rest } = prev;
      return rest;
    });
  };

  const handleAddFilter = (name: string) => () => {
    if (filtersDisabled) {
      return;
    }

    const filter = filters[name];

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (filter != null) {
      if (autoShow === true && !isMobile) {
        // auto-open dropdown for desktop only, mobile uses modals instead and auto-open disrupts its ux
        setLastAddedFilter(name);
      }

      switch (filter.type) {
        case "datemonth":
          setValue(name, {
            type: FilterType.datemonth,
            modifier: FilterDateMonthModifier.is,
            value: undefined,
          });
          break;
        case "date":
          setValue(name, {
            type: FilterType.date,
            modifier: FilterDateModifier.is,
            value: undefined,
          });
          break;
        case "time":
          setValue(name, {
            type: FilterType.time,
            modifier: FilterTimeModifier.is,
            value: undefined,
          });
          break;
        case "duration":
          setValue(name, {
            type: FilterType.duration,
            modifier: FilterDurationModifier.is,
            value: undefined,
          });
          break;
        case "number":
          setValue(name, {
            type: FilterType.number,
            modifier: FilterNumberModifier.is,
            value: undefined,
          });
          break;
        case "text":
          setValue(name, {
            type: FilterType.text,
            modifier: FilterTextModifier.includes,
            value: undefined,
          });
          break;
        case "options":
          setValue(name, {
            type: FilterType.options,
            modifier: FilterOptionsModifier.includes,
            value: undefined,
          });
          break;
        case "infinite-options":
          setValue(name, {
            type: FilterType.infiniteOptions,
            modifier: FilterOptionsModifier.includes,
            value: undefined,
          });
          break;
        case "select":
          setValue(name, {
            type: FilterType.select,
            modifier: FilterSelectModifier.is,
            value: undefined,
          });
          break;
        case "is":
          setValue(name, {
            type: FilterType.is,
            modifier: FilterIsModifier.is,
            value: undefined,
          });
          break;
        default:
          break;
      }
    }
  };

  const renderChip = (filter: FilterItem, name: string) => {
    switch (filter.type) {
      case "datemonth":
        return (
          <FilterDateMonth
            key={name}
            title={filter.title}
            name={name}
            modifiers={filter.modifiers as FilterDateMonthModifier[]}
            icon={filter.icon}
            min={filter.min}
            max={filter.max}
          />
        );
      case "date":
        return (
          <FilterDate
            key={name}
            title={filter.title}
            name={name}
            modifiers={filter.modifiers as FilterDateModifier[]}
            icon={filter.icon}
            min={filter.min}
            max={filter.max}
          />
        );
      case "time":
        return (
          <FilterTime
            key={name}
            title={filter.title}
            name={name}
            modifiers={filter.modifiers as FilterTimeModifier[]}
            icon={filter.icon}
            diffMinutes={filter.diffMinutes}
          />
        );
      case "duration":
        return (
          <FilterDuration
            key={name}
            title={filter.title}
            name={name}
            modifiers={filter.modifiers as FilterDurationModifier[]}
            icon={filter.icon}
          />
        );
      case "number":
        return (
          <FilterNumber
            key={name}
            title={filter.title}
            name={name}
            modifiers={filter.modifiers as FilterNumberModifier[]}
            icon={filter.icon}
          />
        );
      case "text":
        return (
          <FilterText
            key={name}
            title={filter.title}
            name={name}
            modifiers={filter.modifiers as FilterTextModifier[]}
            icon={filter.icon}
            multiline={(filter as FilterItemText).multiline}
            placeholder={(filter as FilterItemText).placeholder}
          />
        );
      case "infinite-options":
        return (
          <FilterOptionsV2
            key={name}
            title={filter.title}
            name={name}
            loading={(filter as FilterItemOptions).loading}
            fetchMore={(filter as FilterItemOptions).fetchMore}
            options={(filter as FilterItemOptions).options}
            modifiers={filter.modifiers as FilterOptionsModifier[]}
            icon={filter.icon}
            searchCallback={filter.searchCallback}
          />
        );
      case "options":
        return (
          <FilterOptions
            key={name}
            title={filter.title}
            name={name}
            fetchMore={(filter as FilterItemOptions).fetchMore}
            options={(filter as FilterItemOptions).options}
            modifiers={filter.modifiers as FilterOptionsModifier[]}
            icon={filter.icon}
            searchCallback={filter.searchCallback}
          />
        );
      case "select":
        return (
          <FilterSelect
            key={name}
            title={filter.title}
            name={name}
            options={(filter as FilterItemSelect).options}
            modifiers={filter.modifiers as FilterSelectModifier[]}
            icon={filter.icon}
            showSearch={filter.showSearch}
          />
        );
      case "is":
        return (
          <FilterIs
            key={name}
            title={filter.title}
            name={name}
            modifiers={filter.modifiers as FilterIsModifier[]}
            icon={filter.icon}
          />
        );
      default:
        return null;
    }
  };

  const renderFilterButton = (style: FilterButtonStyle = "primary", placement?: Placement) => {
    const availableFilters = Object.fromEntries(
      Object.entries(filters).filter(([key]) => (isMobile ? true : !Object.keys(values).includes(key))),
    );

    const getIcon = (filter: FilterItem) => {
      if (filter.icon != null) {
        return filter.icon;
      }

      switch (filter.type) {
        case "date":
          return RiCalendar2Line;
        case "time":
          return RiTimeLine;
        case "duration":
          return RiTimerLine;
        case "number":
          return RiHashtag;
        case "text":
          return RiText;
        case "options":
          return RiListCheck;
        case "select":
          return RiListCheck;
        default:
          return undefined;
      }
    };

    const getVariant = () => {
      if (isMobile && Object.values(values).filter((c) => c?.value).length > 0) {
        return "secondary";
      }

      return style === "primary" ? "outline" : "ghost";
    };

    const title = style === "primary" ? t("common.filters") : t("common.addFilter");

    const displayFilters = Object.entries(availableFilters).filter(([_, filter]) => filter.disabled !== true);

    return (
      <>
        <DropdownButton
          title={!isMobile ? title : undefined}
          variant={getVariant()}
          disabled={filtersDisabled || Object.keys(availableFilters).length === 0}
          placement={placement}
          shape={isMobile ? "round" : "default"}
          dropdownTitle={t("common.filters") ?? ""}
          className={isMobile ? "filter-dropdown-toggle--mobile" : undefined}
          supportsMobile
          icon={style === "primary" ? <RiEqualizerLine size={16} /> : <RiAddLine size={16} />}
        >
          {additionalFilterDropdownContent}
          {isMobile ? (
            <div className="chips">{displayFilters.map(([key, filter]) => renderChip(filter, key))}</div>
          ) : (
            displayFilters.map(([key, filter]) => (
              <DropdownItem key={key} disabled={filtersDisabled} icon={getIcon(filter)} onClick={handleAddFilter(key)}>
                {filter.title}
              </DropdownItem>
            ))
          )}
        </DropdownButton>
        <style jsx>
          {`
            :global(.filter-dropdown-toggle--mobile) {
              gap: 0 !important;
            }

            .chips {
              display: grid;
              grid-template-columns: 1fr;
              gap: 0.5rem;
              padding: 0.5rem;
            }
          `}
        </style>
      </>
    );
  };

  const renderChips = () => {
    return (
      <div className="chips">
        {Object.entries(values).map(([key]) => {
          const filter = filters[key];

          if (filter == null) {
            return null;
          }

          return renderChip(filter, key);
        })}
        {renderFilterButton("secondary")}
        <style jsx>{`
          .chips {
            display: flex;
            flex-wrap: wrap;
            gap: 0.5rem;
          }
        `}</style>
      </div>
    );
  };

  const renderClearButton = () => {
    return (
      <Button
        variant="ghost"
        size="md"
        disabled={filtersDisabled || allValuesAreDefault}
        onClick={() => setValues(EMPTY_FILTERS)}
      >
        {t("common.reset")}
      </Button>
    );
  };

  return (
    <ComplexFilterContext.Provider
      value={{
        disabled: filtersDisabled,
        values,
        setValue,
        removeValue,
        language,
        lastAddedFilter,
      }}
    >
      {children({
        renderChips,
        renderFilterButton,
        renderClearButton,
      })}
    </ComplexFilterContext.Provider>
  );
};
