import React from "react";
import { SettingsTab } from "./components/SettingsTab";
import { useTranslation } from "@equiem/localisation-eq1";
import { BlockoutContext, BlockoutContextProvider } from "./components/blockout/BlockoutProvider";
import { List } from "./components/blockout/List";
import { AddEditForm } from "./components/blockout/AddEditForm";
import { Button } from "@equiem/react-admin-ui";
import { RiAddLine } from "@equiem/react-admin-ui/icons";

export const BlockoutDatesPage = () => {
  const { t } = useTranslation();

  return (
    <BlockoutContextProvider>
      <BlockoutContext.Consumer>
        {({ setShowModal }) => (
          <SettingsTab
            prefixTopMenu={
              <div className="w-100 text-right">
                <Button
                  size="md"
                  variant="primary"
                  onClick={() => {
                    setShowModal(true);
                  }}
                >
                  <RiAddLine size={16} />
                  {t("bookings.settings.newBlockOutDate")}
                </Button>
              </div>
            }
          >
            <List className="blocks-list" />
            <AddEditForm className="blocks-add-form" />
          </SettingsTab>
        )}
      </BlockoutContext.Consumer>
    </BlockoutContextProvider>
  );
};
