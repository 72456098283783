import React from "react";
import { Skeleton, useTheme } from "@equiem/react-admin-ui";

export const ArticleCardLoading: React.FC = () => {
  const { spacers, borderRadius } = useTheme();

  return (
    <>
      <div className="article-card">
        <Skeleton.Line width="100%" height="181px" borderRadius={borderRadius} />
        <Skeleton.Line width="40%" height="16px" borderRadius={borderRadius} />
        <Skeleton.Line width="100%" height="20px" borderRadius={borderRadius} />
        <Skeleton.Line width="100%" height="32px" borderRadius={borderRadius} />
      </div>
      <style jsx>{`
        .article-card {
          display: flex;
          flex-direction: column;
          min-width: 272px;
          padding: ${spacers.s3};
          border-radius: ${borderRadius};
          gap: ${spacers.s2};
        }
      `}</style>
    </>
  );
};
