import React, { forwardRef, useContext, useMemo } from "react";
import type { ComponentProps } from "react";

import { useTheme } from "../../../contexts/Theme";
import { FormGroupContext } from "../../../contexts/FormGroupContext";

// eslint-disable-next-line @typescript-eslint/no-type-alias
type Props = Omit<ComponentProps<"input">, "type"> & {
  label: React.ReactNode;
  extraInfo?: string;
  labelStyle?: React.CSSProperties;
};

export const FormRadioButton = forwardRef<HTMLInputElement, Props>(
  ({ label, extraInfo, className, labelStyle, disabled = false, ...props }, ref) => {
    const { hasError, id, hintId } = useContext(FormGroupContext);
    const { colors, spacers } = useTheme();

    const inputId = useMemo(
      () => props.id ?? id ?? `${props.name}-${props.defaultValue}`,
      [props.id, props.name, props.defaultValue, id],
    );

    return (
      <>
        <label
          style={labelStyle}
          className={`label ${disabled ? "label--disabled" : ""} ${hasError ? "label--error" : ""} ${
            className ?? ""
          }`.trim()}
          htmlFor={inputId}
        >
          <input {...props} ref={ref} id={inputId} aria-describedby={hintId} type="radio" disabled={disabled} />
          <div className="info">
            {label}
            {extraInfo != null && <div className="extraInfo">{extraInfo}</div>}
          </div>
        </label>
        <style jsx>{`
          input[type="radio"] {
            width: 16px;
            height: 16px;
            background: #ffffff;
            border: 1px solid ${colors.grayscale[20]};
            border-radius: 50%;
            appearance: none;
            position: relative;
            margin: ${extraInfo != null ? "0 0.25rem" : "0.25rem"};
            cursor: pointer;
          }

          input[type="radio"]:checked {
            border-color: ${colors.blue[60]};
          }

          input[type="radio"]:checked:before {
            content: "";
            position: absolute;
            transform: translate(50%, 50%);
            top: 0;
            left: 0;
            width: 50%;
            height: 50%;
            border-radius: 50%;
            background-color: ${colors.blue[60]};
          }

          .label {
            display: inline-flex;
            align-items: ${extraInfo != null ? "flex-start" : "center"};
            white-space: nowrap;
            max-width: 100%;
            user-select: none;
            border-radius: 4px;
            gap: 0.5rem;
            cursor: pointer;
          }

          .label--error,
          .label-error input {
            border-color: ${colors.status.danger.primary};
          }

          .label--active input {
            cursor: default;
          }

          .label--disabled,
          .label--disabled:not(.label--active):hover {
            cursor: auto;
          }
          .label--disabled.label--active {
            background-color: ${colors.grayscale[10]};
            border-color: ${colors.grayscale[60]};
          }
          .label--disabled input {
            cursor: not-allowed;
            background-color: ${colors.grayscale[3]};
            border-color: ${colors.grayscale[20]};
          }

          .info {
            flex: 1;
            min-width: 0;
            overflow: hidden;
            display: flex;
            flex-direction: column;
          }
          .extraInfo {
            color: ${colors.muted0};
            font-size: 12px;
            padding: ${spacers.s2} 0 0;
            white-space: wrap;
          }
        `}</style>
      </>
    );
  },
);

FormRadioButton.displayName = "FormRadioButton";
