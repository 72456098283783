import React from "react";

import { useTranslation } from "@equiem/localisation-eq1";
import { Text, useTheme } from "@equiem/react-admin-ui";
import { RiCheckLine } from "@equiem/react-admin-ui/icons";
import type { IFileV2 } from "@equiem/uploader";

import { useGetFileSize, useGetFileType } from "../hooks";

export const FileDetails = ({ file, progress }: { progress: number; file: File | IFileV2 }) => {
  const { t } = useTranslation();
  const { colors } = useTheme();
  const isIFile = "key" in file || "temporaryUuid" in file || "value" in file || "temporaryImage" in file;
  const fileName = isIFile ? file.value?.filename : (file as File).name;

  const fileType = useGetFileType(((file as IFileV2).value?.mimeType as string) ?? (file as File).type);
  const fileSize = useGetFileSize(((file as IFileV2).value?.size as number) ?? (file as File).size);

  return (
    <>
      <Text variant="text" size="small" className="filename">
        {fileName}
      </Text>
      {progress === 100 ? (
        <Text
          variant="text"
          style={{ margin: 0, gap: "4px" }}
          size="extra-small"
          className="d-flex align-items-center"
          color={colors.success}
        >
          {t("common.uploaded")} <RiCheckLine size={14} />
        </Text>
      ) : (
        <div className="file-details-container">
          <Text
            variant="text"
            size="extra-small"
            className="file-details"
            color={colors.grayscale[60]}
            style={{ margin: 0 }}
          >
            {fileType} <span>•</span>
            <span>{fileSize}</span>
          </Text>
        </div>
      )}
      <style jsx>
        {`
          .file-details-container :global(.file-details) {
            display: flex;
            gap: 4px;
          }
        `}
      </style>
    </>
  );
};
