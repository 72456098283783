import React, { useContext } from "react";
import { useTranslation } from "@equiem/localisation-eq1";
import Link from "next/link";
import { AiFillInstagram } from "react-icons/ai";
import { FaEnvelope, FaFacebookF, FaLink, FaLinkedinIn, FaPhoneAlt, FaTwitter } from "react-icons/fa";
import { Session, Site, stringNotEmpty } from "@equiem/lib";
import { EscapedHtmlDiv, useTheme } from "@equiem/react-admin-ui";
import { Button } from "./sidemenu/Button";
import { CookiePreferences } from "../sidemenu/CookiePreferences";

export const Footer: React.FC = () => {
  const { t, ready: translationsReady } = useTranslation();
  const { authenticated } = useContext(Session);
  const site = useContext(Site);
  const { breakpoints, spacers } = useTheme();

  const socialLinks = site.socialLinks.map((sc) => {
    let icon = <FaFacebookF size="22px" />;
    if (sc.type === "EMAIL") {
      icon = <FaEnvelope size="22px" className="mb-0" />;
    } else if (sc.type === "INSTAGRAM") {
      icon = <AiFillInstagram size="26px" />;
    } else if (sc.type === "LINKEDIN") {
      icon = <FaLinkedinIn size="24px" />;
    } else if (sc.type === "PHONE") {
      icon = <FaPhoneAlt size="22px" />;
    } else if (sc.type === "TWITTER") {
      icon = <FaTwitter size="24px" />;
    } else if (sc.type === "OTHER") {
      icon = <FaLink size="21px" />;
    }

    return {
      ...sc,
      icon,
    };
  });

  const webHost = `https://${site.webAppUrl}`;

  return (
    <div className="footer-container">
      <div className="container">
        <div className="row">
          <div className="left-section">
            {stringNotEmpty(site.footerLogo) ? (
              <div className="pb-6">
                <img src={site.footerLogo} />
              </div>
            ) : null}
            <div className="pb-6 legal-links">
              {translationsReady && (
                <>
                  <Link href={`${webHost}/legal`}>{t("webng.terms")}</Link>
                  <Link href={`${webHost}/privacy`}>{t("webng.privacy")}</Link>
                  {authenticated && <CookiePreferences />}
                </>
              )}
            </div>
          </div>
          <div className="middle-section">
            <EscapedHtmlDiv className="pb-6 site-address" html={site.address.replace(/,\s/gu, "<br/>")} />

            {socialLinks.length > 0 ? (
              <div className="pb-6 social-links">
                {socialLinks.map((sc, i) => (
                  <a
                    key={`${sc.type}-${i}`}
                    href={sc.value}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={`social-${sc.type.toLowerCase()}`}
                  >
                    {sc.icon}
                  </a>
                ))}
              </div>
            ) : null}
          </div>
          <div className="right-section pt-2 pt-md-0">
            {translationsReady && stringNotEmpty(site.iosAppId) ? (
              <div className="pb-3">
                <Button
                  href={`https://apps.apple.com/app/id${site.iosAppId}`}
                  variant="outline"
                  contrast
                  className="text-nowrap"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t("webng.getIosApp")}
                </Button>
              </div>
            ) : null}
            {translationsReady && stringNotEmpty(site.androidAppId) ? (
              <Button
                href={`https://play.google.com/store/apps/details?id=${site.androidAppId}`}
                variant="outline"
                contrast
                className="text-nowrap"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t("webng.getAndroidApp")}
              </Button>
            ) : null}
          </div>
        </div>
      </div>

      <style jsx>{`
        .pb-4 {
          padding-bottom: 1.5rem;
        }
        .pb-3 {
          padding-bottom: 1rem;
        }
        .footer-container {
          background-color: ${site.primaryColour};
          color: ${site.primaryContrastColour};
          min-height: 250px;
          padding: 3rem 0;
          text-align: center;
          line-height: 1.5;
          font-size: 16px;
          font-weight: 300;
        }
        .footer-container :global(a) {
          color: ${site.primaryContrastColour};
        }
        .footer-container img {
          max-width: 150px;
        }
        .container {
          width: 100%;
          padding-right: 8px;
          padding-left: 8px;
          margin-right: auto;
          margin-left: auto;
        }
        .row {
          display: flex;
          flex-wrap: wrap;
          margin-right: -8px;
          margin-left: -8px;
        }
        .left-section,
        .middle-section,
        .right-section {
          position: relative;
          width: 100%;
          padding-right: 8px;
          padding-left: 8px;
        }
        .legal-links :global(a:first-child) {
          margin-left: 0;
        }
        .legal-links :global(a) {
          margin-left: ${spacers.s5};
        }
        .legal-links :global(a:hover) {
          text-decoration: underline;
        }
        .social-links a {
          display: inline-block;
          margin-left: ${spacers.s5};
          margin-right: ${spacers.s5};
          margin-bottom: ${spacers.s5};
        }
        @media (min-width: ${breakpoints.sm + 1}) {
          .container {
            max-width: ${breakpoints.sm + 1};
          }
        }
        @media (min-width: ${breakpoints.md + 1}px) {
          .container {
            max-width: ${breakpoints.md + 1}px;
          }
          .left-section {
            flex: 0 0 41.66666667%;
            max-width: 41.66666667%;
          }
          .middle-section {
            flex: 0 0 33.33333333%;
            max-width: 33.33333333%;
          }
          .right-section {
            flex: 0 0 25%;
            max-width: 25%;
          }
          .footer-container {
            text-align: left;
          }
          .legal-links :global(a:first-child) {
            margin-left: 0;
          }
          .legal-links :global(a) {
            margin-left: ${spacers.s6};
          }
          .social-links a {
            margin-left: 0;
            margin-right: ${spacers.s6};
            margin-bottom: ${spacers.s6};
          }
        }
        @media (min-width: ${breakpoints.lg + 1}px) {
          .container {
            max-width: ${breakpoints.lg + 1}px;
          }
          .legal-links :global(a) {
            margin-left: ${spacers.s9};
          }
          .social-links a {
            padding-right: ${spacers.s6};
          }
        }
        @media (min-width: ${breakpoints.xl + 1}px) {
          .container {
            max-width: ${breakpoints.xl + 1}px;
          }
        }
      `}</style>
    </div>
  );
};
