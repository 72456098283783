import type { ReactNode } from "react";
import React from "react";
import { useTheme } from "@equiem/react-admin-ui";

export const ValuePills: React.FC<{ children: ReactNode }> = ({ children }) => {
  const { spacers } = useTheme(true);
  return (
    <>
      <span className="value-pills">{children}</span>
      <style jsx>{`
        .value-pills > :global(*:not(:last-child)::after) {
          content: "•";
          display: inline-block;
          margin-left: ${spacers.s3};
          margin-right: ${spacers.s3};
        }
      `}</style>
    </>
  );
};
