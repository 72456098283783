import { Button, Form as EqForm, Modal, useTheme } from "@equiem/react-admin-ui";
import React, { useContext, useState } from "react";
import * as yup from "yup";
import { Field, Formik, Form } from "formik";
import type { ShortcutInput } from "../../../generated/gateway-client";
import { useSaveEquiemOneShortcutsMutation } from "../../../generated/gateway-client";
import { CurrentRole } from "@equiem/lib";
import { dynamicIcon } from "../dynamicIcon";
import { useTranslation } from "@equiem/localisation-eq1";

const icons = [
  "RiBookmarkLine",
  "RiUserSmileLine",
  "RiHandbagLine",
  "RiBuildingLine",
  "RiLinksFill",
  "RiMessage2Line",
  "RiBillLine",
  "RiContactsBook2Line",
  "RiHeartLine",
  "RiMapPinLine",
  "RiImageLine",
  "RiEyeLine",
  "RiAdminLine",
  "RiSunLine",
  "RiSearchLine",
  "RiCheckboxLine",
  "RiFunctionLine",
  "RiStarSLine",
  "RiGlobalLine",
  "RiBriefcase4Line",
  "RiServiceFill",
  "RiTrainLine",
  "RiTaxiLine",
  "RiRocketLine",
  "RiBusLine",
  "RiBikeLine",
  "RiFlightTakeoffFill",
  "RiMusicLine",
  "RiNotificationLine",
  "RiThumbsUpLine",
];

const validationSchema = () =>
  yup.object().shape({
    title: yup.string().required().max(30),
    url: yup.string().required(),
  });

const iconGroup = (i: number) => {
  const start = i * 6;
  return icons.slice(start, start + 6);
};

export const ShortcutModal: React.FC<{ shortcuts: ShortcutInput[]; close: () => void }> = ({ shortcuts, close }) => {
  const [mutation] = useSaveEquiemOneShortcutsMutation();
  const { currentRole } = useContext(CurrentRole);
  const { colors, spacers } = useTheme();
  const [selectedIcon, setSelectedIcon] = useState<string>(icons[0]);
  const initialValues = {
    title: "",
    url: "",
  };
  const { t } = useTranslation();

  return (
    <>
      <Modal.Header closeButton={true} noBorder={true} />
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema()}
        onSubmit={async (values, actions) => {
          actions.setSubmitting(true);
          await mutation({
            variables: {
              input: {
                role: currentRole,
                shortcuts: [
                  ...shortcuts.map((sc) => ({
                    icon: sc.icon,
                    title: sc.title,
                    url: sc.url,
                  })),
                  {
                    icon: selectedIcon,
                    title: values.title,
                    url: values.url,
                  },
                ],
              },
            },
            refetchQueries: ["MyProfile"],
          });
          actions.setSubmitting(false);
          close();
        }}
      >
        {({ submitForm, isSubmitting, isValid, dirty, errors, touched }) => (
          <>
            <Modal.Body>
              <Form>
                <h1>{t("home.shortcuts.followTheBelowSteps")}</h1>
                <EqForm.Group
                  label={t("home.shortcuts.title")}
                  required
                  error={touched.title ? errors.title : undefined}
                >
                  <Field
                    as={EqForm.Input}
                    id="title"
                    name="title"
                    placeholder={t("home.shortcuts.linkTitle")}
                    maxLength={30}
                  />
                </EqForm.Group>
                <EqForm.Group
                  label={t("common.url")}
                  required
                  error={touched.url ? errors.url : undefined}
                  showTooltip={true}
                  tooltipText={t("home.shortcuts.toLinkPage")}
                >
                  <Field as={EqForm.Input} id="url" name="url" placeholder={t("home.shortcuts.linkUrl")} />
                </EqForm.Group>
                <EqForm.Group label={t("common.icon")} required>
                  <>
                    {Array.from(Array(icons.length / 6).keys()).map((i) => {
                      return (
                        <div className="iconcontainer" key={i}>
                          {iconGroup(i).map((ic) => {
                            const Icon = dynamicIcon(ic);
                            return (
                              <div
                                className={`icon ${ic} ${ic === selectedIcon ? "selected" : ""}`}
                                key={ic}
                                onClick={() => setSelectedIcon(ic)}
                              >
                                <Icon size={20} />
                              </div>
                            );
                          })}
                        </div>
                      );
                    })}
                  </>
                </EqForm.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button size="md" variant="ghost" className="me-2" onClick={close}>
                {t("common.cancel")}
              </Button>
              <Button
                size="md"
                className="create-shortcut"
                onClick={submitForm}
                disabled={isSubmitting || !isValid || !dirty}
              >
                {t("common.create")}
              </Button>
            </Modal.Footer>
          </>
        )}
      </Formik>
      <style jsx>{`
        h1 {
          font-weight: 700;
          margin-bottom: ${spacers.s5};
        }
        .iconcontainer {
          display: flex;
          justify-content: space-between;
        }
        .icon {
          width: 62px;
          height: 62px;
          display: flex;
          align-items: center;
          justify-content: space-around;
          border-radius: 62px;
          margin: ${spacers.s1};
          cursor: pointer;
          color: ${colors.primary};
        }
        .icon:hover,
        .icon.selected {
          background: ${colors.lightHover};
        }
      `}</style>
    </>
  );
};
