import React from "react";
import { useTheme } from "@equiem/react-admin-ui";

import { usePageGradient } from "../../hooks";
import { GradientContainer } from "../GradientContainer";
import { SideMenu } from "./SideMenu";
import { TopMenu } from "./TopMenu";

export const SiteLoading: React.FC = () => {
  const { spacers } = useTheme();

  usePageGradient();

  return (
    <>
      <GradientContainer>
        <div className="container">
          <SideMenu />
          <div className="content">
            <TopMenu />
          </div>
        </div>
      </GradientContainer>
      <style jsx>{`
        .container {
          display: flex;
          width: 100%;
          height: 100vh;
        }
        .content {
          width: 100%;
          height: 400px;
          padding: 0 ${spacers.s4};
        }
      `}</style>
    </>
  );
};
