import { useTheme } from "@equiem/react-admin-ui";
import React from "react";

import { Menu } from "../components/credits/Menu";
import { CreditsFilterProvider } from "../contexts/CreditsFilterContext";
import { CreditsTableContainer } from "../components/credits/CreditsTable/CreditsTableContainer";

export const CreditsActivityPage: React.FC = () => {
  const { colors } = useTheme();

  return (
    <CreditsFilterProvider>
      <div className="credits d-flex flex-column flex-grow-1 flex-shrink-1 flex-basis-0">
        <Menu />
        <CreditsTableContainer />
        <style jsx>
          {`
            .credits {
              background: ${colors.white};
            }
          `}
        </style>
      </div>
    </CreditsFilterProvider>
  );
};
