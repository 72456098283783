import React from "react";
import Link from "next/link";
import { useRouter } from "next/router";

import { useTranslation } from "@equiem/localisation-eq1";
import { ButtonLink, Dropdown } from "@equiem/react-admin-ui";
import { RiAddLine, RiGroup2Line, RiWalkLine } from "@equiem/react-admin-ui/icons";

import { useReceptionDate } from "../hooks/useReceptionDate";

interface Props {
  appointmentDisabled?: boolean;
  walkinDisabled?: boolean;
}

export const NewDropdownButton: React.FC<Props> = ({ appointmentDisabled = false, walkinDisabled = false }) => {
  const router = useRouter();
  const { t } = useTranslation();
  const { getUrl } = useReceptionDate();

  const { uuid } = router.query;
  const newWalkInUrl =
    uuid != null ? `/visitor-management/receptions/${uuid}/walk_in` : "/visitor-management/building-receptions/walk_in";
  const newAppointmentUrl =
    uuid != null
      ? `/visitor-management/receptions/${uuid}/appointment`
      : getUrl("/visitor-management/building-receptions/appointment");

  if (!appointmentDisabled && walkinDisabled) {
    return (
      <Link href={newAppointmentUrl} passHref legacyBehavior>
        <ButtonLink size="sm" variant="secondary" className="create-button">
          <RiAddLine />
          {t("visitors.appointments.newAppointment")}
        </ButtonLink>
      </Link>
    );
  }

  if (appointmentDisabled && !walkinDisabled) {
    return (
      <Link href={newWalkInUrl} passHref legacyBehavior>
        <ButtonLink size="sm" variant="secondary" className="create-button">
          <RiAddLine />
          {t("visitors.walkIn.newWalkIn")}
        </ButtonLink>
      </Link>
    );
  }

  return (
    <Dropdown.Button
      size="sm"
      variant="secondary"
      className="create-button"
      disabled={walkinDisabled && appointmentDisabled}
      title={t("visitors.reception.createNew")}
    >
      <Dropdown.Item
        onClick={() => {
          void router.push(newWalkInUrl);
        }}
        icon={RiWalkLine}
      >
        {t("visitors.reception.walkIn")}
      </Dropdown.Item>
      <Dropdown.Item
        onClick={() => {
          void router.push(newAppointmentUrl);
        }}
        icon={RiGroup2Line}
      >
        {t("visitors.reception.appointment")}
      </Dropdown.Item>
    </Dropdown.Button>
  );
};
