import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import type { WeekdayNumbers } from "luxon";
import { DateTime } from "luxon";
import { useTranslation } from "@equiem/localisation-eq1";
import { Button, Form, Modal, ProgressCircle, useTheme } from "@equiem/react-admin-ui";
import {
  MAX_ENDS_AFTER_OCCURRENCES,
  MIN_ENDS_AFTER_OCCURRENCES,
  useBookingRecurringModalContext,
} from "../contexts/BookingRecurringModalProvider";
import {
  useValidateBookingTimesLazyQuery,
  ValidateBookingTimesStatus,
  Weekday,
} from "../../../generated/gateway-client";
import type { RecurringDate, RecurringSettings } from "../models/RecurringSettings";
import { RecurringType } from "../models/RecurringSettings";
import { getWeekdayOccuranceOfWeekdayInMonth } from "../libs/RecurringSettingsAuxiliary";
import { formatWeekday, formatWeekdayByNum, weekdayMap } from "../../../lib/formatWeekday";
import { BookingRecurringSummary } from "./BookingRecurringSummary";
import {
  defaultLimit,
  generateRecurringDates,
  numberToRecurringType,
  RecurringError,
} from "../libs/generateRecurringDates";
import { BookingModalInfo } from "../contexts/BookingModalInfoProvider";
import { notAvailableStatuses } from "../hooks/useBookResource";

const MAX_MONTH = 12;
const MAX_WEEK = 52;

export const BookingRecurringModalContent: React.FC = () => {
  const { resource, timezone } = useContext(BookingModalInfo);
  const { t, i18n } = useTranslation();
  const { breakpoints, spacers } = useTheme();
  const {
    recurringSettings,
    setRecurringType,
    setRepeatEvery,
    setLastWeekDayEachMonth,
    setSameWeekDayEachMonth,
    setRepeatEndsType,
    setRepeatEndsTimes,
    setRepeatEndsUntil,
    setRepeatOn,
    setValidatedRecurringDates,
    close,
    submit,
    hasSuperPower,
  } = useBookingRecurringModalContext();

  const [query, { loading }] = useValidateBookingTimesLazyQuery({ fetchPolicy: "no-cache" });
  const [recurringError, setRecurringError] = useState<string | null>(null);
  const [recurringDates, setRecurringDates] = useState<RecurringDate[]>([]);

  const hasAvailableTimes = useMemo(
    () => recurringSettings.validatedRecurringDates.some((a) => a.status === ValidateBookingTimesStatus.Available),
    [recurringSettings.validatedRecurringDates],
  );

  const update = useCallback(() => {
    if (
      (recurringSettings.repeatEndsTimes == null && recurringSettings.repeatEndsUntil == null) ||
      (recurringSettings.repeatEndsType === "occurrences" && recurringSettings.repeatEndsTimes == null) ||
      (recurringSettings.repeatEndsType === "date" && recurringSettings.repeatEndsUntil == null)
    ) {
      return;
    }

    const { result, error } = generateRecurringDates({
      t,
      startDate: recurringSettings.start,
      selectedStartTime: recurringSettings.selectedStartTime,
      selectedEndTime: recurringSettings.selectedEndTime,
      recurringType: numberToRecurringType[recurringSettings.recurringType],
      repeatEvery: recurringSettings.repeatEvery,
      lastWeekDayEachMonth:
        recurringSettings.recurringType === RecurringType.Monthly
          ? !recurringSettings.lastWeekDayEachMonth
            ? undefined
            : true
          : undefined,
      repeatOn: recurringSettings.recurringType === RecurringType.Weekly ? recurringSettings.repeatOn : undefined,
      repeatEndsTimes:
        recurringSettings.repeatEndsType === "occurrences" ? recurringSettings.repeatEndsTimes : undefined,
      repeatEndsUntil:
        recurringSettings.repeatEndsType === "date" && recurringSettings.repeatEndsUntil != null
          ? recurringSettings.repeatEndsUntil
          : undefined,
      sameDayEachMonth:
        recurringSettings.recurringType === RecurringType.Monthly
          ? !recurringSettings.sameWeekDayEachMonth
            ? undefined
            : true
          : undefined,
    });

    setRecurringDates(result);
    setRecurringError(
      error === RecurringError.NO_LIMIT ? t("bookings.repeat.maximumRecurring", { count: defaultLimit }) : null,
    );
    if (error != null) {
      console.error(error);
    }
  }, [
    recurringSettings.repeatEvery,
    recurringSettings.recurringType,
    recurringSettings.sameWeekDayEachMonth,
    recurringSettings.lastWeekDayEachMonth,
    recurringSettings.repeatEndsTimes,
    recurringSettings.repeatEndsUntil,
    recurringSettings.repeatOn,
    recurringSettings.repeatEndsType,
    recurringSettings.start,
    t,
    recurringSettings.selectedStartTime,
    recurringSettings.selectedEndTime,
  ]);

  useEffect(() => {
    update();
    // Reset the validated recurring dates when something changed.
    if (recurringSettings.version.before !== recurringSettings.version.current) {
      setValidatedRecurringDates([], true);
    }
    // We don't want update and setValidatedRecurring to be included.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recurringSettings.version]);

  const processSubmit = useCallback(
    (rs: RecurringSettings) => {
      const endDate = recurringDates.at(recurringDates.length - 1);

      if (endDate != null && recurringDates.length > 0) {
        submit({
          ...rs,
          end: endDate.endTime,
          repeatEndsTimes: rs.repeatEndsType === "occurrences" ? rs.repeatEndsTimes : undefined,
          repeatEndsUntil: rs.repeatEndsType === "date" && rs.repeatEndsUntil != null ? rs.repeatEndsUntil : undefined,
          lastWeekDayEachMonth: rs.recurringType === RecurringType.Monthly ? rs.lastWeekDayEachMonth : false,
          sameWeekDayEachMonth: rs.recurringType === RecurringType.Monthly ? rs.sameWeekDayEachMonth : false,
          repeatOn: rs.recurringType === RecurringType.Weekly ? rs.repeatOn : [],
        });
      }
    },
    [recurringDates, submit],
  );

  const confirm = useCallback(() => {
    processSubmit(recurringSettings);
  }, [processSubmit, recurringSettings]);

  const checkAvailabilityCb = useCallback(() => {
    if (recurringDates.length > 0) {
      void query({
        variables: {
          input: {
            resourceUuid: resource.uuid,
            times: recurringDates.map((d) => ({ startTime: d.startTime.toMillis(), endTime: d.endTime.toMillis() })),
          },
        },
      }).then((r) => {
        if (r.error == null) {
          const data = r.data?.validateBookingTimes ?? [];
          setValidatedRecurringDates(data, true);

          // When all data available then we just save the modal.
          const statuses = notAvailableStatuses(hasSuperPower);
          const anyTaken = data.some((d) => statuses.includes(d.status));
          if (!anyTaken) {
            processSubmit({ ...recurringSettings, validatedRecurringDates: data });
          }
        }
      });
    }
  }, [
    hasSuperPower,
    processSubmit,
    query,
    recurringDates,
    recurringSettings,
    resource.uuid,
    setValidatedRecurringDates,
  ]);

  const onDayClicked = (val: Weekday) => {
    const value = weekdayMap[val];
    if (!recurringSettings.repeatOn.includes(value)) {
      setRepeatOn([...recurringSettings.repeatOn, value]);
    } else {
      setRepeatOn(recurringSettings.repeatOn.filter((x) => x !== value));
    }
  };

  const weekdayOccuranceOfWeekdayInMonth = useMemo(
    () => getWeekdayOccuranceOfWeekdayInMonth(recurringSettings.start),
    [recurringSettings.start],
  );

  const onMonthlyRepeatSubtypeChanged = (value: "sameDay" | "sameOccurance" | "lastOccurance") => {
    setSameWeekDayEachMonth(value === "sameOccurance");
    setLastWeekDayEachMonth(value === "lastOccurance");
  };

  return (
    <>
      <Modal.Body>
        <Form.Group>
          <div className="item-cont">
            <div className="ends-on">
              <Form.Label noMargin>{t("common.repeatEvery")}</Form.Label>
            </div>
            <div className="mr-3">
              <Form.Input
                id="recurring-days"
                type="number"
                min={1}
                max={recurringSettings.recurringType === RecurringType.Monthly ? MAX_MONTH : MAX_WEEK}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setRepeatEvery(Number(e.target.value));
                }}
                value={recurringSettings.repeatEvery.toString()}
              />
            </div>
            <div>
              <Form.Select
                id="recurring-type"
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                  setRecurringType(Number(e.target.value));
                }}
                value={recurringSettings.recurringType.toString()}
              >
                <option value={RecurringType.Daily.toString()}>
                  {t("common.repeatEveryDay", { count: recurringSettings.repeatEvery }).toLocaleLowerCase()}
                </option>
                <option value={RecurringType.Weekly.toString()}>
                  {t("common.repeatEveryWeek", { count: recurringSettings.repeatEvery }).toLocaleLowerCase()}
                </option>
                <option value={RecurringType.Monthly.toString()}>
                  {t("common.repeatEveryMonth", { count: recurringSettings.repeatEvery }).toLocaleLowerCase()}
                </option>
              </Form.Select>
            </div>
          </div>
        </Form.Group>
        {recurringSettings.recurringType === RecurringType.Monthly && (
          <Form.Group>
            <div>
              <Form.RadioButton
                id="repeat-sameDay"
                label={t("bookings.repeat.endsOnDay", { dayNumber: recurringSettings.start.day })}
                checked={!recurringSettings.sameWeekDayEachMonth && !recurringSettings.lastWeekDayEachMonth}
                onChange={() => onMonthlyRepeatSubtypeChanged("sameDay")}
              />
            </div>

            <div>
              <Form.RadioButton
                id="repeat-sameOccurance"
                label={t("bookings.repeat.endsOnSameOccurance", {
                  number: t("common.number", { ordinal: true, count: weekdayOccuranceOfWeekdayInMonth }),
                  weekday: formatWeekdayByNum(recurringSettings.start.weekday as WeekdayNumbers, i18n.language, "long"),
                })}
                checked={recurringSettings.sameWeekDayEachMonth}
                onChange={() => onMonthlyRepeatSubtypeChanged("sameOccurance")}
              />
            </div>

            {weekdayOccuranceOfWeekdayInMonth > 4 ? (
              <div>
                <Form.RadioButton
                  id="repeat-lastOccurance"
                  label={t("bookings.repeat.endsOnLastOccurance", {
                    weekday: formatWeekdayByNum(
                      recurringSettings.start.weekday as WeekdayNumbers,
                      i18n.language,
                      "long",
                    ),
                  })}
                  checked={recurringSettings.lastWeekDayEachMonth}
                  onChange={() => onMonthlyRepeatSubtypeChanged("lastOccurance")}
                />
              </div>
            ) : null}
          </Form.Group>
        )}
        {recurringSettings.recurringType === RecurringType.Weekly && (
          <Form.Group label={t("common.repeatOn")}>
            <div className="week-display">
              {[Weekday.Mon, Weekday.Tue, Weekday.Wed, Weekday.Thu, Weekday.Fri, Weekday.Sat, Weekday.Sun].map(
                (weekDay) => (
                  <Form.ToggleButton
                    className={`btn-day-${weekDay.toLowerCase()}`}
                    key={weekDay}
                    value={recurringSettings.repeatOn.includes(weekdayMap[weekDay])}
                    onClickCapture={() => onDayClicked(weekDay)}
                    label={formatWeekday(weekDay, i18n.language, "narrow")[0]}
                  />
                ),
              )}
            </div>
          </Form.Group>
        )}
        <Form.Group label={t("common.ends")}>
          <div className="item-cont">
            <div className="ends-on">
              <Form.RadioButton
                id="ends-date"
                name="ends"
                label={t("common.repeatOn")}
                onChange={() => setRepeatEndsType("date")}
                checked={recurringSettings.repeatEndsType === "date"}
              />
            </div>
            <div>
              <Form.Input
                type="date"
                disabled={recurringSettings.repeatEndsType === "occurrences"}
                value={
                  recurringSettings.repeatEndsUntil?.toFormat("yyyy-LL-dd") ??
                  recurringSettings.selectedStartTime.toFormat("yyyy-LL-dd")
                }
                min={recurringSettings.selectedStartTime.toFormat("yyyy-LL-dd")}
                max={DateTime.now().setZone(timezone).plus({ months: 12 }).startOf("day").toFormat("yyyy-LL-dd")}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setRepeatEndsUntil(DateTime.fromISO(e.target.value));
                }}
              />
            </div>
          </div>
          <div className="item-cont">
            <div className="ends-on">
              <Form.RadioButton
                id="ends-occurrences"
                name="ends"
                label={t("common.after")}
                checked={recurringSettings.repeatEndsType === "occurrences"}
                onChange={() => setRepeatEndsType("occurrences")}
              />
            </div>
            <div>
              <Form.Input
                type="number"
                disabled={recurringSettings.repeatEndsType === "date"}
                id="ends-occurrences-input"
                min={MIN_ENDS_AFTER_OCCURRENCES}
                max={MAX_ENDS_AFTER_OCCURRENCES}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setRepeatEndsTimes(Number(e.target.value));
                }}
                value={recurringSettings.repeatEndsTimes?.toString() ?? MIN_ENDS_AFTER_OCCURRENCES}
              />
            </div>
            <span className="ml-3">
              {t("common.occurrence", { count: recurringSettings.repeatEndsTimes ?? 0 }).toLocaleLowerCase()}
            </span>
          </div>
        </Form.Group>
        <BookingRecurringSummary
          error={recurringError}
          loading={false}
          recurringDates={recurringDates.map((d) => d.startTime)}
        />
      </Modal.Body>
      <Modal.Footer hideBreakline={true}>
        <Button type="button" className="mr-4" variant="ghost" onClick={close}>
          {t("common.cancel")}
        </Button>
        {hasAvailableTimes ? (
          <Button type="button" variant="primary" disabled={recurringDates.length <= 1 || loading} onClick={confirm}>
            {t("common.confirm")}
          </Button>
        ) : (
          <Button
            type="button"
            className="mr-4"
            variant="secondary"
            disabled={recurringDates.length <= 1 || loading}
            onClick={checkAvailabilityCb}
          >
            {loading && <ProgressCircle size={18} className="mr-1" />}
            {t("bookings.operations.checkAvailability")}
          </Button>
        )}
      </Modal.Footer>
      <style jsx>{`
        .item-cont {
          display: flex;
          margin-bottom: ${spacers.s4};
          align-items: center;
        }
        .week-display {
          display: flex;
          justify-content: space-between;
        }
        .ends-on {
          display: flex;
          padding-right: ${spacers.s3};
        }
        @media (min-width: ${breakpoints.md + 1}px) {
          .ends-on {
            width: 120px;
            padding: 0;
          }
        }
        @media (max-width: ${breakpoints.sm + 1}px) {
          .week-display :global(button) {
            display: block;
            padding: 0;
            height: 35px;
            width: 35px;
            font-size: 14px;
          }
        }
      `}</style>
    </>
  );
};
