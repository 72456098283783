import React, { useContext, useMemo } from "react";
import { Form as FormikForm, Formik, useFormikContext } from "formik";

import { Session } from "@equiem/lib";
import { AudienceModal } from "@equiem/segmentation-widget";

import type { SpaceSiteAudienceInput } from "../../generated/settings-client";
import { useBuildingData } from "../../pages/building/hooks/useBuildingData";

import type { FormValues } from "./formValidation";

type PermissionsModalFormValues = {
  siteAudience: SpaceSiteAudienceInput;
};

interface Props {
  showModal: boolean;
  setShowModal: (_state: boolean) => void;
  onEdit: (siteAudience: SpaceSiteAudienceInput) => void;
}

export const AddAudienceModal: React.FC<Props> = ({ showModal, setShowModal, onEdit }) => {
  const { authenticatedClient: client } = useContext(Session);
  const fm = useFormikContext<FormValues>();
  const { siteUuid } = useBuildingData();
  const initialValues = useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (fm.values.siteAudiences != null && fm.values.siteAudiences.length > 0) {
      return {
        siteUuid: fm.values.siteAudiences[0].siteUuid,
        segmentIds: fm.values.siteAudiences[0].segmentIds,
        segmentSummary: fm.values.siteAudiences[0].segmentSummary,
      };
    }
    return {
      siteUuid: siteUuid ?? "",
      segmentIds: [],
      segmentSummary: "",
    };
  }, [siteUuid, fm.values.siteAudiences]);

  return (
    <Formik<PermissionsModalFormValues>
      initialValues={{ siteAudience: initialValues }}
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onSubmit={() => {}}
    >
      {({ setFieldValue, values }) => (
        <FormikForm>
          <AudienceModal
            connection={{ client }}
            site={values.siteAudience.siteUuid}
            segmentIds={values.siteAudience.segmentIds ?? undefined}
            callback={(segmentIds, segmentSummary) => {
              setShowModal(false);

              Promise.all([
                setFieldValue("siteAudience.segmentSummary", segmentSummary),
                setFieldValue("siteAudience.segmentIds", segmentIds),
              ])
                .then(() => {
                  onEdit({ ...values.siteAudience, segmentIds, segmentSummary });
                })
                .catch(console.error);
            }}
            modal={{
              showModal: showModal,
              onHide: () => setShowModal(false),
            }}
          />
        </FormikForm>
      )}
    </Formik>
  );
};
