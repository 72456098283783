import React, { useState } from "react";

import type { BorderCssProps } from "../useInputBorderCss";
import type { ComponentProps } from "react";
import { FormInputGroupContext } from "./FormInputGroupContext";
import type { FormInputGroupDirection } from "./FormInputGroupContext";
import { useInputBorderCss } from "../useInputBorderCss";
import { useTheme } from "../../../contexts/Theme";

// eslint-disable-next-line @typescript-eslint/no-type-alias
type Props = ComponentProps<"div"> &
  // eslint-disable-next-line @typescript-eslint/no-type-alias
  Pick<BorderCssProps, "rounded"> & {
    direction?: FormInputGroupDirection;
  };

export const FormInputGroup = React.forwardRef<HTMLDivElement, Props>(
  ({ children, className = "", rounded, direction = "row", ...props }, ref) => {
    const borderCss = useInputBorderCss({ rounded });
    const [focused, setFocused] = useState(false);
    const [hasPrefix, setHasPrefix] = useState(false);
    const [hasSuffix, setHasSuffix] = useState(false);
    const { colors } = useTheme();

    return (
      <div
        {...props}
        className={`${className} ${focused ? "focus-in" : "focus-out"} ${borderCss.className}`}
        onFocus={setFocused.bind(null, true)}
        onBlur={setFocused.bind(null, false)}
        ref={ref}
      >
        <FormInputGroupContext.Provider
          value={{
            inGroup: true,
            hasPrefix,
            hasSuffix,
            setHasPrefix,
            setHasSuffix,
            rounded,
            direction,
          }}
        >
          {children}
        </FormInputGroupContext.Provider>
        <style jsx>{`
          div {
            display: flex;
            align-items: center;
            flex-direction: ${direction};
            background: ${colors.white};
          }
        `}</style>
        {borderCss.styles}
      </div>
    );
  },
);

FormInputGroup.displayName = "FormInputGroup";
