import React, { useContext } from "react";
import { Formik } from "formik";
import { useRouter } from "next/router";
import * as yup from "yup";

import { ButtonTray, stringIsEmpty } from "@equiem/lib";
import type { TFunction } from "@equiem/localisation-eq1";
import { useTranslation } from "@equiem/localisation-eq1";
import { Button, useConfirmer, useIsMobileWidth, useTheme, useToast } from "@equiem/react-admin-ui";
import { RiInformationLine } from "@equiem/react-admin-ui/icons";
import type { IFileV2 } from "@equiem/uploader";

import { RequestTabs } from "../../components/RequestTabs";
import { RequestsMenuProvider } from "../../contexts/RequestsMenuContext";
import type { ProfileFragmentFragment } from "../../generated/requests-client";
import { useCreateFullRequestMutation } from "../../generated/requests-client";
import { NewRequestActivity } from "../new-request-details/components/NewRequestActivity";
import { NewRequestDetails } from "../new-request-details/components/NewRequestDetails";
import { NewRequestContext } from "../requests/contexts/NewRequestContext";
import { RequestsAccessProvider } from "../requests/contexts/RequestsAccessContext";
import { RequestsScopeContext, RequestsScopeProvider } from "../requests/contexts/RequestsScopeContext";
import { usePagedRequests } from "../requests/hooks/usePagedRequests";
import { ModalProvider } from "../settings/contexts/ModalContext";

export interface NewRequestFormValues {
  buildingUuid?: string;
  buildingLevelUuid?: string;
  description?: string;
  space?: {
    uuid: string;
    name: string;
    ownerCompany?: {
      uuid: string;
    };
  };
  queue?: string;
  status?: string;
  reporter?: ProfileFragmentFragment;
  assignee?: ProfileFragmentFragment;
  dateReported?: number;
  category?: string;
  subCategory?: string;
  watchers: string[];
  attachments: IFileV2[];
}

const initialValues: NewRequestFormValues = {
  buildingUuid: undefined,
  buildingLevelUuid: undefined,
  space: undefined,
  status: undefined,
  reporter: undefined,
  assignee: undefined,
  category: undefined,
  description: undefined,
  subCategory: undefined,
  watchers: [],
  attachments: [],
};

const getValidationSchema = (t: TFunction) => {
  return yup.object().shape({
    buildingUuid: yup.string().required(t("requests.validation.building")),
    buildingLevelUuid: yup.string().required(t("requests.validation.buildingLevel")),
    space: yup.object().shape({
      uuid: yup.string().required(t("requests.validation.space")),
    }),
    status: yup.string().required(t("requests.validation.status")),
    category: yup.string().required(t("requests.validation.category")),
    reporter: yup.object().required(t("requests.validation.reporter")),
    description: yup.string().required(t("requests.validation.description")),
  });
};

const NewRequestContainer = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [createFullRequest, { loading }] = useCreateFullRequestMutation();
  const { currentScope } = useContext(RequestsScopeContext);
  const { refetch } = usePagedRequests(currentScope);
  const { requestForm, closeNewRequest } = useContext(NewRequestContext);
  const toast = useToast();
  const isMobile = useIsMobileWidth();
  const router = useRouter();

  const handleSubmit = (values: NewRequestFormValues) => {
    if (
      values.space?.uuid != null &&
      values.category != null &&
      values.description != null &&
      values.reporter != null
    ) {
      createFullRequest({
        variables: {
          input: {
            assignee: values.assignee?.uuid,
            category: values.category,
            description: values.description,
            persistAttachments:
              values.attachments.map((file) => ({
                temporaryUuid: file.temporaryUuid,
                filename: file.value?.filename ?? (file as { filename: string }).filename,
              })) ?? [],
            reported: values.dateReported,
            reporter: values.reporter.uuid,
            space: values.space.uuid,
            status: values.status,
            subCategory: values.subCategory,
            watchers: values.watchers,
          },
        },
      })
        .then((result) => {
          if (result.errors != null) {
            toast.negative(result.errors[0].message);
            return;
          }

          if (result.data?.reqMgt.createFullRequest.reference != null) {
            void router.push("/request-management").then(() => {
              toast.positive(
                <div className="d-flex align-items-center">
                  <RiInformationLine size={20} className="mr-2" />
                  {t("requests.newRequest.createdSuccessfully")}
                </div>,
              );
              void refetch();
              closeNewRequest();
            });
          }
        })
        .catch((e: Error) => {
          toast.negative(e.message ?? t("requests.create.error"));
        });
    }
  };
  const { withConfirmation } = useConfirmer();
  const { push } = useRouter();

  const isOpenRequestDisabled = (values: NewRequestFormValues) => {
    return (
      stringIsEmpty(values.category) ||
      stringIsEmpty(values.space?.uuid) ||
      stringIsEmpty(values.description?.trim()) ||
      values.reporter == null ||
      values.status == null
    );
  };

  return (
    <>
      <Formik
        initialValues={requestForm ?? initialValues}
        enableReinitialize
        validationSchema={getValidationSchema(t)}
        onSubmit={handleSubmit}
      >
        {({ submitForm, values }) => (
          <>
            <div className="container">
              <div className="details">
                <NewRequestDetails isSaving={loading} />
              </div>
              <div className="activity">
                <NewRequestActivity />
              </div>
            </div>
            <ButtonTray>
              <div className="primary-actions">
                {!isMobile && (
                  <Button
                    variant="ghost"
                    disabled={loading}
                    onClick={() =>
                      withConfirmation({
                        title: t("common.areYouSure"),
                        message: t("requests.newRequest.cancelMessage"),
                        confirmButtonText: t("common.yesCancel"),
                        confirmButtonVariant: "danger",
                        onConfirm: () => {
                          void push("/request-management").then(() => {
                            closeNewRequest();
                          });
                        },
                      })()
                    }
                  >
                    {t("requests.newRequest.cancel")}
                  </Button>
                )}
                <Button
                  type="submit"
                  className="save-button-appointment"
                  disabled={loading || isOpenRequestDisabled(values)}
                  onClick={() => {
                    void submitForm();
                  }}
                >
                  {t("requests.newRequest.open")}
                </Button>
              </div>
            </ButtonTray>
            <div></div>
          </>
        )}
      </Formik>
      <style jsx>{`
        .container {
          background: #fff;
          min-height: calc(100vh - 177px);
        }

        .primary-actions {
          display: flex;
          gap: 8px;
          width: 100%;
          justify-content: flex-end;
        }

        .details {
          padding: ${theme.spacers.s6};
          overflow-y: auto;
          display: flex;
          flex-direction: column;
          gap: ${theme.spacers.s7};
        }
        .activity {
          height: 100%;
        }

        :global(.primary-actions .save-button-appointment) {
          width: ${isMobile ? "100%" : "auto"};
        }

        @media (max-width: ${theme.breakpoints.lg}px) {
          .container {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            min-height: calc(100vh - 113px);
          }
          .activity {
            border-top: 1px solid ${theme.colors.border};
          }
        }

        @media (min-width: ${theme.breakpoints.lg}px) {
          .container {
            display: grid;
            grid-template-columns: 70% 30%;
          }
          .activity {
            border-left: 1px solid ${theme.colors.border};
          }
        }
      `}</style>
    </>
  );
};

export const NewRequest = () => {
  const { t } = useTranslation();
  return (
    <ModalProvider>
      <RequestsMenuProvider>
        <RequestsAccessProvider>
          <RequestsScopeProvider>
            <RequestTabs heading={t("requests.newRequest.title")}>
              <NewRequestContainer />
            </RequestTabs>
          </RequestsScopeProvider>
        </RequestsAccessProvider>
      </RequestsMenuProvider>
    </ModalProvider>
  );
};
