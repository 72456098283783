import type { FC, ReactElement } from "react";
import React, { useState, useMemo, useContext, useCallback } from "react";
import Link from "next/link";
import { useRouter } from "next/router";
import { NavItem, NavLink, useTheme } from "@equiem/react-admin-ui";
import type { IconType } from "@equiem/react-admin-ui/icons";
import { UrlParams } from "@equiem/lib";

export interface ShortcutItemProps {
  onClick?: () => void;
  routerLink?: string;
  link?: string;
  icon: IconType;
  text: string;
  actionButton?: ReactElement;
  pathFullExact?: boolean;
}

const ItemChildren: React.FC<{ text: string }> = ({ text }) => {
  return (
    <>
      <div className="contents">
        <span>{text}</span>
        {/* Pin icon for menu item is removed in https://github.com/Equiem/equiem-one-ui/pull/1885
            until it's needed and is fully functional. When needed, please refer to the PR to
            put the code back in.
        */}
      </div>
      <style jsx>{`
        .contents {
          width: 100%;
          display: flex;
          flex-wrap: none;
          align-items: center;
        }
        span {
          white-space: wrap;
        }
      `}</style>
    </>
  );
};

export const ShortcutItem: FC<ShortcutItemProps> = ({
  link,
  pathFullExact,
  routerLink,
  icon,
  text,
  onClick,
  actionButton,
}) => {
  const router = useRouter();
  const [hovered, setHovered] = useState(false);
  const { clearParams } = useContext(UrlParams);
  const { colors, borderRadius } = useTheme();
  const handleClickLink = useCallback(() => {
    if (routerLink === router.pathname) {
      // Clear params to refresh filters even when navigating to the same page
      clearParams();
    }
  }, [router]);
  const active = useMemo(() => {
    if (routerLink === "/") {
      return router.pathname === routerLink || router.pathname === "";
    }

    if (pathFullExact) {
      /* eslint-disable no-useless-escape */
      return new RegExp(`^${routerLink}(?!\/)`, "u").test(router.pathname);
    }

    return new RegExp(`^${routerLink}\\b`, "u").test(router.pathname);
  }, [routerLink, router.pathname]);

  if (link) {
    return (
      <>
        <NavLink href={link} target="_blank" noMargin icon={icon} actionButton={actionButton} onClick={handleClickLink}>
          <ItemChildren text={text} />
        </NavLink>
      </>
    );
  }

  if (routerLink) {
    return (
      <Link className="shortcut-link" passHref href={routerLink} legacyBehavior>
        <NavLink
          onMouseLeave={() => setHovered(false)}
          onMouseOver={() => setHovered(true)}
          onClick={handleClickLink}
          noMargin
          icon={icon}
          actionButton={actionButton}
          style={{
            backgroundColor: active && !hovered ? colors.lightHover : undefined,
            borderRadius,
          }}
        >
          <ItemChildren text={text} />
        </NavLink>
      </Link>
    );
  }

  return (
    <>
      <NavItem onClick={onClick} noMargin icon={icon} actionButton={actionButton}>
        <ItemChildren text={text} />
      </NavItem>
    </>
  );
};
