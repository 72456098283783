import { stringIsEmpty } from "@equiem/lib/stringNotEmpty";
import type { TFunction } from "@equiem/localisation-eq1";

// This function is used to validate the name of the user. It checks if the name contains any invalid characters.
function parseName(nameToValidate: string): { name: string; errorMessage?: string } {
  // Change all whitespace characters in text and whitespaces (spaces, tabs, newlines, etc.) and dot to single spaces
  const name = nameToValidate
    .replace(
      /(\\r|\\n|\\t|\\f|\\v|\r|\n|\t|\f|\v|\.| |\\u00a0|\\u1680|\\u2000-|\\u200a|\\u2028|\\u2029|\\u202f|\\u205f|\\u3000|\\ufeff|\u00a0|\u1680|\u2000-|\u200a|\u2028|\u2029|\u202f|\u205f|\u3000|\ufeff)/gmu,
      " ",
    )
    .replace(/ +/gmu, " "); // Replace multiple spaces with a single space
  const regex = /[^\p{L}\p{Cf}\-/\\' ]/gmu;

  if (regex.test(name)) {
    return { name, errorMessage: "Name contains invalid sign" };
  }
  return { name, errorMessage: undefined };
}

export default function parseAndValidateName(
  value: string,
  t?: TFunction, // It's optional because it's used also in the context, where I don't have access to the t function as long as it's hook, not a provider.
): { errorMessage?: string; parsedName?: string } {
  try {
    const requiredErrorMessage = t != null ? t("common.required") : "Required";
    const errorMaxLength =
      t != null ? t("settings.editProfile.charactersMaximumNum", { maxNum: "100" }) : "100 characters maximum";
    if (stringIsEmpty(value)) {
      return { parsedName: "", errorMessage: requiredErrorMessage };
    }
    const { name, errorMessage } = parseName(value);
    if (name.length > 100) {
      return { parsedName: name, errorMessage: errorMaxLength };
    }
    return {
      parsedName: name,
      errorMessage: errorMessage != null && t != null ? t("settings.editProfile.invalidName") : errorMessage,
    };
  } catch (e: unknown) {
    const error = e as Error;
    return {
      errorMessage: t != null ? t("common.unknownErrorMessage") : error.message,
    };
  }
}
