import { stringNotEmpty } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import { Skeleton, useTheme, useWindowDimensions } from "@equiem/react-admin-ui";
import React, { useEffect, useRef, useState } from "react";
import { useNotBokable } from "../hooks/useNotBookable";
import { getCreditCurrency } from "../../operations/libs/getCreditCurrency";
import { ResouceCardImage } from "../../resources/components/ResourceCardImage";
import { ResourceCardCapacity } from "../../../components/ResourceCardCapacity";
import { ResourceCardDiscountAvailableIndicator } from "../../../components/ResourceCardDiscountAvailableIndicator";
import { ValuePills } from "../../../components/ValuePills";
import type { ResourceFragment } from "../../../generated/gateway-client";
import { CurrencyAmount } from "../../../lib/CurrencyAmount";
import { resourceTypeToString } from "../../../lib/resourceTypeToString";
import { useRouter } from "next/router";

const imgWidthS = 180;
const imgWidthL = 307;
const imgHeightS = 100;
const imgHeightL = 170;
const imgMobileCoefficient = 2.5;

export const CatalogueCardLoading: React.FC<{
  size?: "small" | "large";
  showThumbnail?: boolean;
}> = ({ size = "large", showThumbnail = true }) => {
  const { width } = useWindowDimensions();
  const { borderRadius, breakpoints } = useTheme(true);

  const imgWidth = size === "small" ? imgWidthS : imgWidthL;
  const imgHeight = size === "small" ? imgHeightS : imgHeightL;

  return (
    <>
      <div className="card-container">
        <div className="mb-4">
          {showThumbnail && (
            <Skeleton.Line
              width={width > breakpoints.md || size === "small" ? `${imgWidth}px` : "100%"}
              height={`${imgHeight}px`}
              borderRadius={borderRadius}
            />
          )}
        </div>
        <div className="mb-3">
          <Skeleton.Line width={size === "small" ? "150px" : "250px"} height="18px" />
        </div>
        <div>
          <Skeleton.Line width={size === "small" ? "80px" : "120px"} height="10px" />
        </div>
        <div>
          <Skeleton.Line width="150px" height="10px" />
        </div>
      </div>
      <style jsx>{`
        @media screen and (max-width: ${breakpoints.md}px) {
          .card-container {
            display: block;
            width: 100%;
          }
        }
      `}</style>
    </>
  );
};

interface Props {
  resource: ResourceFragment;
  size?: "small" | "large";
  showThumbnail?: boolean;
}

// eslint-disable-next-line complexity
export const CatalogueCard: React.FC<Props> = ({ resource, size = "large", showThumbnail = true }) => {
  const { t } = useTranslation();
  const { width } = useWindowDimensions();
  const { breakpoints, colors } = useTheme(true);
  const notBookable = useNotBokable(resource);
  const router = useRouter();

  const isMobile = width <= breakpoints.md;

  const imgWidth = size === "small" ? imgWidthS : imgWidthL;
  const imgHeight = size === "small" ? imgHeightS : imgHeightL;

  const url = stringNotEmpty(resource.images[0])
    ? `${resource.images[0]}?auto=compress&fit=crop&crop=entropy&h=${imgHeight * imgMobileCoefficient}&w=${
        imgWidth * imgMobileCoefficient
      }`
    : "";

  const [nameOverflowed, setNameOverflowed] = useState(false);
  const nameRef = useRef<HTMLInputElement>(null);

  // only show the discount flag if there are resource charges (excluding extras, etc.)
  const hasDiscounts =
    resource.discounts.length > 0 &&
    (resource.paymentRateHourly > 0 ||
      resource.paymentRateHalfDay > 0 ||
      resource.paymentRateFullDay > 0 ||
      resource.paymentRateHourlyAfterHours > 0 ||
      resource.paymentRateHourlyWeekend > 0);

  useEffect(() => {
    if (size === "small" && nameRef.current != null) {
      const isOverflowing = nameRef.current.scrollWidth > nameRef.current.clientWidth;
      setNameOverflowed(isOverflowing);
    }
  }, [size]);

  const handleCardClick = async () => {
    const newUrl = new URL(router.asPath, window.location.origin);
    newUrl.searchParams.set("resource", resource.uuid);
    const newAsPath = `${newUrl.pathname}${newUrl.search}`;
    if (newAsPath !== router.asPath) {
      await router.push(newAsPath, undefined, { scroll: false });
    }
  };

  return (
    <>
      <a
        className="catalogue-card"
        style={{ width: isMobile && size !== "small" ? "100%" : undefined }}
        onClick={notBookable ? (e) => e.preventDefault() : handleCardClick}
      >
        {showThumbnail ? (
          <ResouceCardImage
            url={url}
            rounded
            width={width > breakpoints.md || size === "small" ? `${imgWidth}px` : "auto"}
            height={width > breakpoints.md || size === "small" ? `${imgHeight}px` : "auto"}
            topFloatingEl={
              <div className="d-flex justify-content-end">
                <ResourceCardDiscountAvailableIndicator hasDiscounts={hasDiscounts} iconOnly={size === "small"} />
                <ResourceCardCapacity capacity={resource.displayCapacity} />
              </div>
            }
          />
        ) : (
          <div></div>
        )}
        <div className="body">
          <span className="title pb-2" ref={nameRef} title={nameOverflowed ? resource.name : ""}>
            {resource.name}
          </span>
          <ValuePills>
            <span>{resourceTypeToString(resource.typeInfo.name, t)}</span>
          </ValuePills>
          <ValuePills>
            <span>
              {resource.destination.name}
              {resource.building != null && `, ${resource.building.name}`}
              {resource.building != null && resource.level != null && `, ${resource.level.name}`}
            </span>
          </ValuePills>
          <ValuePills>
            {resource.paymentRateHourly > 0 && (
              <span className="mr-4">
                <CurrencyAmount
                  currencyCode={getCreditCurrency(resource.paymentMethods)}
                  amount={resource.paymentRateHourly}
                  buildingUuid={resource.building?.uuid ?? null}
                />{" "}
                <span>/ {t("common.hour")}</span>
              </span>
            )}
          </ValuePills>
        </div>
      </a>
      <style jsx>{`
        .catalogue-card {
          display: flex;
          flex-direction: column;
          position: relative;
          width: ${imgWidth}px;
          height: 100%;
          overflow: hidden;
          text-decoration: none;
          gap: 12px;
          opacity: ${notBookable ? "0.5" : "1"};
          cursor: ${notBookable ? "not-allowed" : "pointer"};
        }
        .body {
          display: flex;
          flex-direction: column;
          position: relative;
          gap: 4px;
        }
        .title {
          color: ${colors.dark};
          font-size: ${size === "small" ? "14px" : "16px"};
          font-weight: ${size === "small" ? "600" : "700"};
          overflow: ${size === "small" ? "hidden" : "auto"};
          white-space: ${size === "small" ? "nowrap" : "auto"};
          text-overflow: ${size === "small" ? "ellipsis" : "initial"};
        }
        .catalogue-card:hover .title {
          text-decoration: ${notBookable ? "none" : "underline"};
        }
        .catalogue-card :global(.value-pills) {
          font-size: 12px;
          color: ${colors.muted0};
        }
      `}</style>
    </>
  );
};
