import React, { createContext, useContext, useEffect, useMemo, useState } from "react";

import { CurrentProfile, UrlParams, useSiteContext } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import { ProgressCircle, useTheme } from "@equiem/react-admin-ui";

import { RequestsAccessContext } from "./RequestsAccessContext";

export type RequestPageScope = "my" | "all" | "assigned" | "requestManager";
export const isRequestPageScope = (value: string): value is RequestPageScope => {
  return ["my", "all", "assigned", "requestManager"].includes(value as RequestPageScope);
};
interface RequestsScopeContext {
  currentScope: RequestPageScope;
  setScope: (value: RequestPageScope) => void;
  clearLastVisitedScope: () => void;
  setLastVisitedScope: (value: RequestPageScope) => void;
  getLastVisitedScope: () => string | null;
}
export const RequestsScopeContext = createContext<RequestsScopeContext>({
  currentScope: "my",
  setScope: () => {
    throw new Error("Not init");
  },
  setLastVisitedScope: () => {
    throw new Error("Not init");
  },
  clearLastVisitedScope: () => {
    throw new Error("Not init");
  },
  getLastVisitedScope: () => {
    throw new Error("Not init");
  },
});

interface P {
  children?: React.ReactNode;
}
export const RequestsScopeProvider: React.FC<P> = ({ children }) => {
  const accessInfo = useContext(RequestsAccessContext);
  const { clearParams, currentUrlParams } = useContext(UrlParams);
  const { colors } = useTheme();
  const { t } = useTranslation();
  const site = useSiteContext();
  const { profile } = useContext(CurrentProfile);
  const sessionName = useMemo(() => `REQUEST_LAST_SCOPE_${site.uuid}_${profile?.uuid}`, [site.uuid, profile?.uuid]);

  // beause complex filters also use UrlParams context and there is a bunch of useEffects bouncing off each other
  // in case we have filters or search params in our URL, we will have to clear URL params first and then re-render child components
  const [requestedScope, setRequestedScope] = useState<RequestPageScope>();
  const [currentScope, setCurrentScope] = useState<RequestPageScope>(() => {
    const lastVisitedScope = sessionStorage.getItem(sessionName);
    if (lastVisitedScope != null && isRequestPageScope(lastVisitedScope)) {
      return lastVisitedScope;
    }

    // user can be property/workplace manager and request manager at the same time
    // prioritize those roles over request manager
    let result: RequestPageScope = "my";
    if (accessInfo.workplaceManager || accessInfo.propertyManager || accessInfo.requestManager) {
      result = "all";
    } else if (accessInfo.requestAssignee) {
      result = "assigned";
    }
    sessionStorage.setItem(sessionName, result);
    return result;
  });

  const handleScopeChange = (value: RequestPageScope) => {
    if (currentUrlParams.filters != null || currentUrlParams.search != null) {
      clearParams();
      setRequestedScope(value);
    } else {
      setCurrentScope(value);
    }

    sessionStorage.setItem(sessionName, value);
  };

  useEffect(() => {
    if (requestedScope != null && currentUrlParams.filters == null && currentUrlParams.search == null) {
      setCurrentScope(requestedScope);
      setRequestedScope(undefined);
    }
  }, [currentUrlParams.filters, currentUrlParams.search, requestedScope]);

  if (requestedScope != null) {
    return (
      <>
        <div className="container">
          <ProgressCircle size="xs" className="mr-3" />
          {t("requests.list.loadingScope")}...
        </div>
        <style jsx>{`
          .container {
            display: flex;
            height: 100%;
            width: 100%;
            align-items: center;
            justify-content: center;
            background: ${colors.white};
          }
        `}</style>
      </>
    );
  }

  return (
    <RequestsScopeContext.Provider
      value={{
        currentScope: currentScope,
        setScope: handleScopeChange,
        getLastVisitedScope: () => sessionStorage.getItem(sessionName),
        setLastVisitedScope: (value) => sessionStorage.setItem(sessionName, value),
        clearLastVisitedScope: () => sessionStorage.removeItem(sessionName),
      }}
    >
      {children}
    </RequestsScopeContext.Provider>
  );
};
