import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  _FieldSet: { input: any; output: any; }
};

/** Query campaigns on certain fields */
export type CampaignQueryInput = {
  /** Find campaigns tagged as engage */
  engage?: InputMaybe<Scalars['Boolean']['input']>;
  /** The ID of the campaign */
  id?: InputMaybe<Scalars['ID']['input']>;
  /** Find campaigns by name */
  search_string?: InputMaybe<Scalars['String']['input']>;
};

/** Latitude and longitude values for a location */
export type Coordinate = {
  __typename?: 'Coordinate';
  latitude: Scalars['Float']['output'];
  longitude: Scalars['Float']['output'];
};

/** Autogenerated input type of CreateBuildingInfoPost */
export type CreateBuildingInfoPostInput = {
  body: Scalars['String']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  downloads?: InputMaybe<Array<InputMaybe<FileInput>>>;
  draft: Scalars['Boolean']['input'];
  excerpt: Scalars['String']['input'];
  image: ImageInput;
  links?: InputMaybe<Array<InputMaybe<LinkInput>>>;
  postAudience: EquiemOnePostAudience;
  publishAt: Scalars['String']['input'];
  role: EquiemOneUserRole;
  title: Scalars['String']['input'];
};

/** Autogenerated return type of CreateBuildingInfoPost */
export type CreateBuildingInfoPostPayload = {
  __typename?: 'CreateBuildingInfoPostPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  post?: Maybe<Building_Info_Post>;
};

/** Autogenerated input type of CreateEventPost */
export type CreateEventPostInput = {
  body: Scalars['String']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  dateRange: DateRangeInput;
  draft: Scalars['Boolean']['input'];
  images: Array<ImageInput>;
  location: LocationInput;
  postAudience: EquiemOnePostAudience;
  publishAt: Scalars['String']['input'];
  role: EquiemOneUserRole;
  rsvpLimit: Scalars['Int']['input'];
  title: Scalars['String']['input'];
};

/** Autogenerated return type of CreateEventPost */
export type CreateEventPostPayload = {
  __typename?: 'CreateEventPostPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  post?: Maybe<Event_Post>;
};

/** Autogenerated input type of CreateNewsPost */
export type CreateNewsPostInput = {
  body: Scalars['String']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  downloads?: InputMaybe<Array<InputMaybe<FileInput>>>;
  draft: Scalars['Boolean']['input'];
  excerpt: Scalars['String']['input'];
  images: Array<ImageInput>;
  postAudience: EquiemOnePostAudience;
  publishAt: Scalars['String']['input'];
  role: EquiemOneUserRole;
  title: Scalars['String']['input'];
};

/** Autogenerated return type of CreateNewsPost */
export type CreateNewsPostPayload = {
  __typename?: 'CreateNewsPostPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  post?: Maybe<News_Post>;
};

/** Autogenerated input type of CreatePresignedURL */
export type CreatePresignedUrlInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  contentType: Scalars['String']['input'];
  filename: Scalars['String']['input'];
};

/** Autogenerated return type of CreatePresignedURL */
export type CreatePresignedUrlPayload = {
  __typename?: 'CreatePresignedURLPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  key: Scalars['String']['output'];
  presignedUrl: Scalars['String']['output'];
};

/** Autogenerated input type of CreateSecurePresignedURL */
export type CreateSecurePresignedUrlInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  contentType: Scalars['String']['input'];
  filename: Scalars['String']['input'];
};

/** Autogenerated return type of CreateSecurePresignedURL */
export type CreateSecurePresignedUrlPayload = {
  __typename?: 'CreateSecurePresignedURLPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  key: Scalars['String']['output'];
  securePresignedUrl: Scalars['String']['output'];
};

/** A single date range */
export type DateRange = {
  __typename?: 'DateRange';
  end: Scalars['String']['output'];
  start: Scalars['String']['output'];
  timezone?: Maybe<Scalars['String']['output']>;
};

/** date range input */
export type DateRangeInput = {
  end?: InputMaybe<Scalars['String']['input']>;
  start?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated input type of DeletePost */
export type DeletePostInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of DeletePost */
export type DeletePostPayload = {
  __typename?: 'DeletePostPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  deleted?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of EditBuildingInfoPost */
export type EditBuildingInfoPostInput = {
  body: Scalars['String']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  downloads?: InputMaybe<Array<InputMaybe<FileInput>>>;
  draft: Scalars['Boolean']['input'];
  excerpt: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  image: ImageInput;
  links?: InputMaybe<Array<InputMaybe<LinkInput>>>;
  publishAt: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

/** Autogenerated return type of EditBuildingInfoPost */
export type EditBuildingInfoPostPayload = {
  __typename?: 'EditBuildingInfoPostPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  post?: Maybe<Building_Info_Post>;
};

/** Autogenerated input type of EditEventPost */
export type EditEventPostInput = {
  body: Scalars['String']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  dateRange: DateRangeInput;
  draft: Scalars['Boolean']['input'];
  id: Scalars['ID']['input'];
  images: Array<ImageInput>;
  location: LocationInput;
  publishAt: Scalars['String']['input'];
  rsvpLimit: Scalars['Int']['input'];
  title: Scalars['String']['input'];
};

/** Autogenerated return type of EditEventPost */
export type EditEventPostPayload = {
  __typename?: 'EditEventPostPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  post?: Maybe<Event_Post>;
};

/** Autogenerated input type of EditNewsPost */
export type EditNewsPostInput = {
  body: Scalars['String']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  downloads?: InputMaybe<Array<InputMaybe<FileInput>>>;
  draft: Scalars['Boolean']['input'];
  excerpt: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  images: Array<ImageInput>;
  publishAt: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

/** Autogenerated return type of EditNewsPost */
export type EditNewsPostPayload = {
  __typename?: 'EditNewsPostPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  post?: Maybe<News_Post>;
};

/** Edm Query Input */
export type EdmQueryInput = {
  format?: InputMaybe<Scalars['String']['input']>;
  portals?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  schedule_at?: InputMaybe<DateRangeInput>;
  search_string?: InputMaybe<Scalars['String']['input']>;
  statuses?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

/** A single Email notification */
export type EmailNotification = {
  __typename?: 'EmailNotification';
  /** List of attachments of an email */
  attachments?: Maybe<Array<Maybe<File>>>;
  /** The body of the Email */
  body: Scalars['String']['output'];
  /** The id of the Email */
  id: Scalars['ID']['output'];
  /** Optional banner image url */
  image_key?: Maybe<Scalars['String']['output']>;
  /** Flag to override subscription */
  override_unsubscribe?: Maybe<Scalars['Boolean']['output']>;
  /** Ther Portal the Email belongs to */
  portal: Portal;
  /** The reply to email address of this Email */
  reply_to?: Maybe<Scalars['String']['output']>;
  /** The time this Email needs to be sent */
  schedule_at?: Maybe<Scalars['String']['output']>;
  /** Optional segment ids of Email */
  segment_ids?: Maybe<Array<Scalars['String']['output']>>;
  /** Optional segment summary of Email */
  segment_summary?: Maybe<Scalars['String']['output']>;
  /** The status of the Email */
  status: NotificationStatus;
  /** The subject of the Email */
  subject: Scalars['String']['output'];
  /** Count of users Email is sent to */
  user_count?: Maybe<Scalars['Int']['output']>;
  /** UUID of this Email */
  uuid?: Maybe<Scalars['String']['output']>;
};

/** The connection type for EmailNotification. */
export type EmailNotificationConnection = {
  __typename?: 'EmailNotificationConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<EmailNotificationEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type EmailNotificationEdge = {
  __typename?: 'EmailNotificationEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<EmailNotification>;
};

/** Email Notification Query Input */
export type EmailNotificationQueryInput = {
  portals?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  schedule_at?: InputMaybe<DateRangeInput>;
  search_string?: InputMaybe<Scalars['String']['input']>;
  statuses: Array<Scalars['Int']['input']>;
};

/** Audience of a post published via Equiem One */
export type EquiemOnePostAudience =
  /** Building */
  | 'BUILDING'
  /** Workplace */
  | 'WORKPLACE';

/** Role of the user in Equiem One */
export type EquiemOneUserRole =
  /** Property manager */
  | 'PROPERTY_MANAGER'
  /** Workplace manager */
  | 'WORKPLACE_MANAGER';

/** A single link */
export type File = {
  __typename?: 'File';
  key: Scalars['String']['output'];
  title: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

/** file input */
export type FileInput = {
  key: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

/** A single log of history against a model */
export type HistoryItem = {
  __typename?: 'HistoryItem';
  description: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  model: Scalars['String']['output'];
  timestamp: Scalars['Int']['output'];
  title: Scalars['String']['output'];
  user_email: Scalars['String']['output'];
  user_id?: Maybe<Scalars['Int']['output']>;
};

/** A single image */
export type Image = {
  __typename?: 'Image';
  alt?: Maybe<Scalars['String']['output']>;
  display_caption?: Maybe<Scalars['Boolean']['output']>;
  key: Scalars['String']['output'];
  thumbnail_key: Scalars['String']['output'];
  thumbnail_url: Scalars['String']['output'];
  title: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

/** image input */
export type ImageInput = {
  alt: Scalars['String']['input'];
  display_caption?: InputMaybe<Scalars['Boolean']['input']>;
  key: Scalars['String']['input'];
  thumbnail_key?: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
};

/** A single link */
export type Link = {
  __typename?: 'Link';
  title: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

/** link input */
export type LinkInput = {
  title: Scalars['String']['input'];
  url: Scalars['String']['input'];
};

/** A google location */
export type Location = {
  __typename?: 'Location';
  additional_info?: Maybe<Scalars['String']['output']>;
  address: Scalars['String']['output'];
  coordinates: Coordinate;
  google_place: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

/** Location input */
export type LocationInput = {
  additional_info?: InputMaybe<Scalars['String']['input']>;
  google_place: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

/** Status of the Notification */
export type NotificationStatus =
  /** Notification is Draft */
  | 'DRAFT'
  /** Notification is Scheduled */
  | 'SCHEDULED'
  /** Notification is Sent */
  | 'SENT';

/** A single event occurrence */
export type Occurrence = {
  __typename?: 'Occurrence';
  date_range: DateRange;
  parent_event: Post;
  uuid: Scalars['String']['output'];
};

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']['output']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']['output']>;
};

/** A single permission */
export type Permission = {
  __typename?: 'Permission';
  en: Scalars['String']['output'];
  group: Scalars['String']['output'];
  machine_name: Scalars['String']['output'];
  scope: Scalars['String']['output'];
};

/** Query portal campaigns on certain fields */
export type PortalCampaignQueryInput = {
  /** Find campaigns with an end date inside this date range */
  end?: InputMaybe<DateRangeInput>;
  /** The ID of the portal campaign */
  id?: InputMaybe<Scalars['ID']['input']>;
  /** Find campaigns that belong to portals */
  portals?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Find campaigns by the specified name */
  search_string?: InputMaybe<Scalars['String']['input']>;
  /** Find campaigns with a start date inside this date range */
  start?: InputMaybe<DateRangeInput>;
};

/** Query posts on certain fields */
export type PostQueryInput = {
  /** The ID of the assignee of the post */
  assignee?: InputMaybe<Scalars['ID']['input']>;
  /** The ID of the author of the post */
  author?: InputMaybe<Scalars['ID']['input']>;
  /** The name of the portal campaign */
  campaign?: InputMaybe<Scalars['String']['input']>;
  /** Category IDs */
  categories?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** The city location of the post */
  city?: InputMaybe<Scalars['String']['input']>;
  /** If Post flagged as featured */
  featured_news?: InputMaybe<Scalars['Boolean']['input']>;
  /** The ID of the post */
  id?: InputMaybe<Scalars['ID']['input']>;
  /** Return only content type post */
  is_content_type?: InputMaybe<Scalars['Boolean']['input']>;
  /** Search a post that can be copied */
  is_copyable?: InputMaybe<Scalars['Boolean']['input']>;
  /** Search for a post that is locked */
  is_locked?: InputMaybe<Scalars['Boolean']['input']>;
  /** Whether the post is published on portals or not */
  is_published?: InputMaybe<Scalars['Boolean']['input']>;
  /** UUIDS of the portals */
  portal_uuids?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** The ID of the portal of the post */
  portals?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Premium Category IDs */
  premium_subcategories?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** The publish date */
  published?: InputMaybe<DateRangeInput>;
  /** A string to search for */
  search_string?: InputMaybe<Scalars['String']['input']>;
  /** Segments associated with posts */
  segment_ids?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Return only content that is segmented */
  segmented?: InputMaybe<Scalars['Boolean']['input']>;
  /** The state location of the post */
  state?: InputMaybe<Scalars['String']['input']>;
  /** The status of the post */
  status?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** The IDs of tags of the post */
  tags?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** The ID of a task of the post */
  task?: InputMaybe<Scalars['ID']['input']>;
  /** Search for a template or post */
  template?: InputMaybe<Scalars['Boolean']['input']>;
  /** The type of post you're looking for */
  type?: InputMaybe<Scalars['String']['input']>;
  /** The UUID of the post */
  uuid?: InputMaybe<Scalars['String']['input']>;
};

/** A single Push notification */
export type PushNotification = {
  __typename?: 'PushNotification';
  /** The body of the PN */
  body: Scalars['String']['output'];
  /** The id of the PN */
  id: Scalars['ID']['output'];
  /** The image in the PN */
  image_key?: Maybe<Scalars['String']['output']>;
  /** The link in the PN */
  link?: Maybe<Scalars['String']['output']>;
  /** Flag to override subscription */
  override_unsubscribe?: Maybe<Scalars['Boolean']['output']>;
  /** Ther Portal the PN belongs to */
  portal: Portal;
  /** The time this PN needs to be sent */
  schedule_at?: Maybe<Scalars['String']['output']>;
  /** Optional segment ids of PN */
  segment_ids?: Maybe<Array<Scalars['String']['output']>>;
  /** Optional segment summary of PN */
  segment_summary?: Maybe<Scalars['String']['output']>;
  /** The status of the PN */
  status: NotificationStatus;
  /** The subject of the PN */
  subject: Scalars['String']['output'];
  /** Count of users PN is sent to */
  user_count?: Maybe<Scalars['Int']['output']>;
  /** UUID of this PN */
  uuid?: Maybe<Scalars['String']['output']>;
};

/** The connection type for PushNotification. */
export type PushNotificationConnection = {
  __typename?: 'PushNotificationConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<PushNotificationEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type PushNotificationEdge = {
  __typename?: 'PushNotificationEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<PushNotification>;
};

/** Push Notification Query Input */
export type PushNotificationQueryInput = {
  portals?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  schedule_at?: InputMaybe<DateRangeInput>;
  search_string?: InputMaybe<Scalars['String']['input']>;
  statuses: Array<Scalars['Int']['input']>;
};

/** A repeating date range */
export type RepeatingDateRange = {
  __typename?: 'RepeatingDateRange';
  occurrences?: Maybe<Array<Maybe<Occurrence>>>;
  rrule?: Maybe<Scalars['String']['output']>;
};

/** A single event RSVP data */
export type Rsvp = {
  __typename?: 'Rsvp';
  allow_checkin: Scalars['Boolean']['output'];
  attendee_limit?: Maybe<Scalars['Int']['output']>;
  can_rsvp?: Maybe<Scalars['Boolean']['output']>;
  restrict_attendees?: Maybe<Scalars['Boolean']['output']>;
  rsvp_email?: Maybe<Scalars['String']['output']>;
  rsvp_terms?: Maybe<Scalars['String']['output']>;
};

/** Portal building info category */
export type Building_Info_Category = {
  __typename?: 'building_info_category';
  /** Dd of category */
  id: Scalars['ID']['output'];
  /** Name of category */
  name: Scalars['String']['output'];
  /** UUID of category */
  uuid: Scalars['String']['output'];
  weight: Scalars['Int']['output'];
};

/** A single building info post */
export type Building_Info_Post = Post & {
  __typename?: 'building_info_post';
  /** The user assigned to the post */
  assignee: User;
  /** The author email of the post */
  author?: Maybe<Scalars['String']['output']>;
  /** The body of the post */
  body: Scalars['String']['output'];
  /** Campaign posts */
  campaign_posts?: Maybe<Array<Maybe<CampaignPost>>>;
  /** Whether the post is deletable or not */
  can_destroy: Scalars['Boolean']['output'];
  /** Whether the user has permissions to edit the post content */
  can_edit_content: Scalars['Boolean']['output'];
  /** Whether the user has permissions to edit the post metadata */
  can_edit_metadata: Scalars['Boolean']['output'];
  /** Whether the user has permissions to view the post content */
  can_view_content: Scalars['Boolean']['output'];
  /** The category this post belongs to */
  category?: Maybe<Category>;
  /** The client the post belongs to */
  client: Client;
  comments?: Maybe<Array<Maybe<Comment>>>;
  /** Content types */
  content_types?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** Whether the post can be copied */
  copyable: Scalars['Boolean']['output'];
  /** The timestamp the post was created at */
  created_at: Scalars['Int']['output'];
  /** Downloads for the building post */
  downloads?: Maybe<Array<Maybe<File>>>;
  /** The audience this post is meant for */
  equiem_one_post_audience?: Maybe<EquiemOnePostAudience>;
  /** The optional Cortex role used to create post */
  equiem_one_user_role?: Maybe<EquiemOneUserRole>;
  /** The excerpt of the post */
  excerpt: Scalars['String']['output'];
  /** Timestamp when post will unsubscribe */
  expire_at?: Maybe<Scalars['String']['output']>;
  /** The history of the post */
  history?: Maybe<Array<Maybe<HistoryItem>>>;
  /** This id of this post */
  id: Scalars['ID']['output'];
  /** Image for the post */
  image: Image;
  /** Whether the post was created in Cortex or not */
  is_cortex: Scalars['Boolean']['output'];
  /** Whether the post was created from an OccasionGenius feed or not */
  is_og_feed: Scalars['Boolean']['output'];
  /** Whether the status is a published status */
  is_published: Scalars['Boolean']['output'];
  /** Related links to the building post */
  links?: Maybe<Array<Maybe<Link>>>;
  /** Whether the post is locked for editing */
  locked: Scalars['Boolean']['output'];
  /** Post this was copied from */
  master?: Maybe<Post>;
  /** The Portal side nodes for the post */
  nodes?: Maybe<Array<Maybe<Node>>>;
  /** The campaign this post belongs to (optional) */
  portal_campaign?: Maybe<Portal_Campaign>;
  portal_posts?: Maybe<Array<Maybe<Portal_Post>>>;
  /** The portals of the post */
  portals?: Maybe<Array<Maybe<Portal>>>;
  post_tasks?: Maybe<Array<Maybe<Post_Task>>>;
  premium_subcategory?: Maybe<Premium_Subcategory>;
  /** The timestamp the post is scheduled to be published at */
  publish_at: Scalars['String']['output'];
  /** The reporting region the post belongs to */
  reporting_region: Scalars['String']['output'];
  /** Whether this event is shareable */
  shareable_content: Scalars['Boolean']['output'];
  /** The current status of the post */
  status: Scalars['String']['output'];
  /** The translated status of the post */
  status_label: Scalars['String']['output'];
  /** A collection of tags */
  tags?: Maybe<Array<Maybe<Tag>>>;
  /** Whether the post is a template */
  template: Scalars['Boolean']['output'];
  /** The title of this post */
  title: Scalars['String']['output'];
  /** The type of post */
  type: Scalars['String']['output'];
  /** The timestamp the post was last updated at */
  updated_at: Scalars['Int']['output'];
  /** The UUID of the post */
  uuid: Scalars['String']['output'];
  /** The current version id of the post */
  version_id: Scalars['Int']['output'];
};


/** A single building info post */
export type Building_Info_PostStatus_LabelArgs = {
  lang?: InputMaybe<Scalars['String']['input']>;
};

/** A single campaign template */
export type Campaign = {
  __typename?: 'campaign';
  campaign_posts?: Maybe<Array<Maybe<CampaignPost>>>;
  /** Owning client for the campaign */
  client: Client;
  /** Whether the campaign is by Equiem Engage */
  engage: Scalars['Boolean']['output'];
  /** This id of this campaign */
  id: Scalars['ID']['output'];
  /** The campaign name */
  name: Scalars['String']['output'];
  /** Notes about the campaign */
  notes?: Maybe<Scalars['String']['output']>;
  /** Post templates belonging to this campaign */
  posts?: Maybe<Array<Maybe<Post>>>;
};

/** The connection type for campaign. */
export type CampaignConnection = {
  __typename?: 'campaignConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<CampaignEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type CampaignEdge = {
  __typename?: 'campaignEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<Campaign>;
};

/** A single campaign post association */
export type CampaignPost = {
  __typename?: 'campaignPost';
  /** The owning campaign */
  campaign: Campaign;
  /** Number of days after campaign it should be published */
  days_offset: Scalars['Int']['output'];
  /** This id of this campaign */
  id: Scalars['ID']['output'];
  /** The post */
  post: Post;
  /** The time this post should be published at */
  publish_time: Scalars['String']['output'];
};

/** A single post category */
export type Category = {
  __typename?: 'category';
  /** This id of this category */
  id: Scalars['ID']['output'];
  /** The category label */
  label: Scalars['String']['output'];
};

/** A single client */
export type Client = {
  __typename?: 'client';
  calendar_posts?: Maybe<PostConnection>;
  campaigns?: Maybe<CampaignConnection>;
  client_edms?: Maybe<EdmConnection>;
  client_email_notifications?: Maybe<EmailNotificationConnection>;
  client_push_notifications?: Maybe<PushNotificationConnection>;
  /** Default tasks for each post type */
  default_post_tasks?: Maybe<Array<Maybe<DefaultPostTask>>>;
  /** Federated gateway graphql endpoint */
  gateway_endpoint: Scalars['String']['output'];
  /** This id of this client */
  id: Scalars['ID']['output'];
  /** The name of the client */
  name: Scalars['String']['output'];
  portal_campaigns?: Maybe<Portal_CampaignConnection>;
  /** The portals associated with the client */
  portals?: Maybe<Array<Maybe<Portal>>>;
  posts?: Maybe<PostConnection>;
  /** Whether the client can create premium content */
  premium_content_client: Scalars['Boolean']['output'];
  /** The region the client is based */
  region: Scalars['String']['output'];
  /** The cortex reporting region */
  reporting_region: Scalars['String']['output'];
  /** Styleguide for the client */
  styleguide?: Maybe<Styleguide>;
  /** The list of tags associated with the client */
  tags?: Maybe<Array<Maybe<Tag>>>;
  /** The list of tasks associated with the client */
  tasks?: Maybe<Array<Maybe<Task>>>;
  /** The users associated with the client */
  users?: Maybe<Array<Maybe<User>>>;
  /** All users */
  users_index?: Maybe<Array<Maybe<User>>>;
  /** Th uuid of this client */
  uuid: Scalars['String']['output'];
};


/** A single client */
export type ClientCalendar_PostsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
  query?: InputMaybe<PostQueryInput>;
};


/** A single client */
export type ClientCampaignsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
  query?: InputMaybe<CampaignQueryInput>;
};


/** A single client */
export type ClientClient_EdmsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  client_id?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
  query?: InputMaybe<EdmQueryInput>;
};


/** A single client */
export type ClientClient_Email_NotificationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  client_id?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
  query?: InputMaybe<EmailNotificationQueryInput>;
};


/** A single client */
export type ClientClient_Push_NotificationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  client_id?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
  query?: InputMaybe<PushNotificationQueryInput>;
};


/** A single client */
export type ClientDefault_Post_TasksArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


/** A single client */
export type ClientPortal_CampaignsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
  query?: InputMaybe<PortalCampaignQueryInput>;
};


/** A single client */
export type ClientPortalsArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


/** A single client */
export type ClientPostsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
  query?: InputMaybe<PostQueryInput>;
};


/** A single client */
export type ClientTasksArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


/** A single client */
export type ClientUsersArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


/** A single client */
export type ClientUsers_IndexArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

/** A comment on a post */
export type Comment = {
  __typename?: 'comment';
  /** The author of the comment */
  author: User;
  /** The content of the comment */
  body: Scalars['String']['output'];
  /** The time the comment was created */
  created_at: Scalars['Int']['output'];
  /** The ID of the comment */
  id: Scalars['ID']['output'];
  /** The post the comment is about */
  post: Post;
};

/** The connection type for comment. */
export type CommentConnection = {
  __typename?: 'commentConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<CommentEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type CommentEdge = {
  __typename?: 'commentEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<Comment>;
};

/** Default post tasks for a client */
export type DefaultPostTask = {
  __typename?: 'defaultPostTask';
  /** The client the default task relates to */
  client: Client;
  /** This id of this default task */
  id: Scalars['ID']['output'];
  /** The type of post it relates to */
  post_type: Scalars['String']['output'];
  /** The task this default task relates to */
  task: Task;
};

/** A single edm */
export type Edm = {
  __typename?: 'edm';
  /** Posts on the edm */
  edm_posts?: Maybe<Array<Maybe<EdmPost>>>;
  /** This is the excerpt of the edm */
  excerpt?: Maybe<Scalars['String']['output']>;
  /** Defines if a Newsletter or Notification */
  format?: Maybe<Scalars['String']['output']>;
  /** This id of this edm */
  id: Scalars['ID']['output'];
  /** Override unsubscribe flag */
  override_unsubscribe?: Maybe<Scalars['Boolean']['output']>;
  /** Ther Portal that edm belongs to */
  portal: Portal;
  /** UUID of portal posts for the edm */
  portal_post_uuids?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  post_count?: Maybe<Scalars['Int']['output']>;
  /** The time this edm needs to be sent */
  schedule_at?: Maybe<Scalars['String']['output']>;
  /** Optional segment ids of the edm */
  segment_ids?: Maybe<Array<Scalars['String']['output']>>;
  /** Optional segment summary of the edm */
  segment_summary?: Maybe<Scalars['String']['output']>;
  /** The status of the edm eg: 0=draft,1=Scheduled */
  status?: Maybe<Scalars['Int']['output']>;
  /** This is the subject of the edm */
  subject: Scalars['String']['output'];
  /** The user who created this edm */
  user?: Maybe<User>;
  /** Count of users emd is sent to */
  user_count?: Maybe<Scalars['Int']['output']>;
  /** UUID of this edm */
  uuid?: Maybe<Scalars['String']['output']>;
};

/** The connection type for edm. */
export type EdmConnection = {
  __typename?: 'edmConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<EdmEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type EdmEdge = {
  __typename?: 'edmEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<Edm>;
};

/** A single edm post association */
export type EdmPost = {
  __typename?: 'edmPost';
  /** The owning edm */
  edm: Edm;
  /** This id of this post */
  id: Scalars['ID']['output'];
  /** Position of the post */
  position: Scalars['Int']['output'];
  /** The post */
  post: Post;
};

/** A single event post */
export type Event_Post = Post & {
  __typename?: 'event_post';
  /** The user assigned to the post */
  assignee: User;
  /** The author email of the post */
  author?: Maybe<Scalars['String']['output']>;
  /** The body of the post */
  body: Scalars['String']['output'];
  /** Campaign posts */
  campaign_posts?: Maybe<Array<Maybe<CampaignPost>>>;
  /** Whether the post is deletable or not */
  can_destroy: Scalars['Boolean']['output'];
  /** Whether the user has permissions to edit the post content */
  can_edit_content: Scalars['Boolean']['output'];
  /** Whether the user has permissions to edit the post metadata */
  can_edit_metadata: Scalars['Boolean']['output'];
  /** Whether the user has permissions to view the post content */
  can_view_content: Scalars['Boolean']['output'];
  /** The category this post belongs to */
  category?: Maybe<Category>;
  /** The client the post belongs to */
  client: Client;
  comments?: Maybe<Array<Maybe<Comment>>>;
  /** Content types */
  content_types?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** Whether the post can be copied */
  copyable: Scalars['Boolean']['output'];
  /** Cost of the event */
  cost?: Maybe<Scalars['String']['output']>;
  /** The timestamp the post was created at */
  created_at: Scalars['Int']['output'];
  /** The audience this post is meant for */
  equiem_one_post_audience?: Maybe<EquiemOnePostAudience>;
  /** The optional Cortex role used to create post */
  equiem_one_user_role?: Maybe<EquiemOneUserRole>;
  /** The date range for the event */
  event_date: DateRange;
  /** Timestamp when post will unsubscribe */
  expire_at?: Maybe<Scalars['String']['output']>;
  feature_event: Scalars['Boolean']['output'];
  /** A downloadable floorplan image */
  floorplan?: Maybe<Image>;
  /** The history of the post */
  history?: Maybe<Array<Maybe<HistoryItem>>>;
  /** This id of this post */
  id: Scalars['ID']['output'];
  /** Images for the event post */
  images: Array<Maybe<Image>>;
  /** Whether to include in digest */
  include_in_digest: Scalars['Boolean']['output'];
  /** Whether to include in RSS feed */
  include_in_rss: Scalars['Boolean']['output'];
  /** Whether the post was created in Cortex or not */
  is_cortex: Scalars['Boolean']['output'];
  /** Whether the post was created from an OccasionGenius feed or not */
  is_og_feed: Scalars['Boolean']['output'];
  /** Whether the status is a published status */
  is_published: Scalars['Boolean']['output'];
  /** Related link for the event */
  link?: Maybe<Link>;
  /** The event location */
  location: Location;
  /** Whether the post is locked for editing */
  locked: Scalars['Boolean']['output'];
  /** Post this was copied from */
  master?: Maybe<Post>;
  /** The Portal side nodes for the post */
  nodes?: Maybe<Array<Maybe<Node>>>;
  /** The campaign this post belongs to (optional) */
  portal_campaign?: Maybe<Portal_Campaign>;
  portal_posts?: Maybe<Array<Maybe<Portal_Post>>>;
  /** The portals of the post */
  portals?: Maybe<Array<Maybe<Portal>>>;
  post_tasks?: Maybe<Array<Maybe<Post_Task>>>;
  premium_subcategory?: Maybe<Premium_Subcategory>;
  /** The timestamp the post is scheduled to be published at */
  publish_at: Scalars['String']['output'];
  repeats?: Maybe<RepeatingDateRange>;
  /** The reporting region the post belongs to */
  reporting_region: Scalars['String']['output'];
  /** RSVP related data for the event */
  rsvp?: Maybe<Rsvp>;
  /** Whether the content is to be sent via notify */
  send_as_notify?: Maybe<Scalars['Boolean']['output']>;
  /** Whether this event is shareable */
  shareable_content: Scalars['Boolean']['output'];
  /** The current status of the post */
  status: Scalars['String']['output'];
  /** The translated status of the post */
  status_label: Scalars['String']['output'];
  /** A collection of tags */
  tags?: Maybe<Array<Maybe<Tag>>>;
  /** Whether the post is a template */
  template: Scalars['Boolean']['output'];
  /** Ticket link for the event */
  tickets?: Maybe<Link>;
  /** The title of this post */
  title: Scalars['String']['output'];
  /** The type of post */
  type: Scalars['String']['output'];
  /** The timestamp the post was last updated at */
  updated_at: Scalars['Int']['output'];
  /** The UUID of the post */
  uuid: Scalars['String']['output'];
  /** The current version id of the post */
  version_id: Scalars['Int']['output'];
};


/** A single event post */
export type Event_PostStatus_LabelArgs = {
  lang?: InputMaybe<Scalars['String']['input']>;
};

export type Mutation = {
  __typename?: 'mutation';
  /** Create a new building info post */
  createBuildingInfoPost?: Maybe<CreateBuildingInfoPostPayload>;
  /** Create a new event post */
  createEventPost?: Maybe<CreateEventPostPayload>;
  /** Create a new news post */
  createNewsPost?: Maybe<CreateNewsPostPayload>;
  /** Create a new presigned url */
  createPresignedUrl?: Maybe<CreatePresignedUrlPayload>;
  /** Create a new presigned url */
  createSecurePresignedUrl?: Maybe<CreateSecurePresignedUrlPayload>;
  /** Delete a post */
  deletePost?: Maybe<DeletePostPayload>;
  /** Edit a cortex building info post */
  editBuildingInfoPost?: Maybe<EditBuildingInfoPostPayload>;
  /** Edit an equiem one event post */
  editEventPost?: Maybe<EditEventPostPayload>;
  /** Edit a cortex news post */
  editNewsPost?: Maybe<EditNewsPostPayload>;
};


export type MutationCreateBuildingInfoPostArgs = {
  input: CreateBuildingInfoPostInput;
};


export type MutationCreateEventPostArgs = {
  input: CreateEventPostInput;
};


export type MutationCreateNewsPostArgs = {
  input: CreateNewsPostInput;
};


export type MutationCreatePresignedUrlArgs = {
  input: CreatePresignedUrlInput;
};


export type MutationCreateSecurePresignedUrlArgs = {
  input: CreateSecurePresignedUrlInput;
};


export type MutationDeletePostArgs = {
  input: DeletePostInput;
};


export type MutationEditBuildingInfoPostArgs = {
  input: EditBuildingInfoPostInput;
};


export type MutationEditEventPostArgs = {
  input: EditEventPostInput;
};


export type MutationEditNewsPostArgs = {
  input: EditNewsPostInput;
};

/** A single news post */
export type News_Post = Post & {
  __typename?: 'news_post';
  /** The user assigned to the post */
  assignee: User;
  /** The author email of the post */
  author?: Maybe<Scalars['String']['output']>;
  /** The body of the post */
  body: Scalars['String']['output'];
  /** Campaign posts */
  campaign_posts?: Maybe<Array<Maybe<CampaignPost>>>;
  /** Whether the post is deletable or not */
  can_destroy: Scalars['Boolean']['output'];
  /** Whether the user has permissions to edit the post content */
  can_edit_content: Scalars['Boolean']['output'];
  /** Whether the user has permissions to edit the post metadata */
  can_edit_metadata: Scalars['Boolean']['output'];
  /** Whether the user has permissions to view the post content */
  can_view_content: Scalars['Boolean']['output'];
  /** The category this post belongs to */
  category?: Maybe<Category>;
  /** The client the post belongs to */
  client: Client;
  comments?: Maybe<Array<Maybe<Comment>>>;
  /** Content types */
  content_types?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** Whether the post can be copied */
  copyable: Scalars['Boolean']['output'];
  /** The timestamp the post was created at */
  created_at: Scalars['Int']['output'];
  /** Downloads for the news post */
  downloads?: Maybe<Array<Maybe<File>>>;
  /** The audience this post is meant for */
  equiem_one_post_audience?: Maybe<EquiemOnePostAudience>;
  /** The optional Cortex role used to create post */
  equiem_one_user_role?: Maybe<EquiemOneUserRole>;
  /** The excerpt of the news post */
  excerpt: Scalars['String']['output'];
  /** Timestamp when post will unsubscribe */
  expire_at?: Maybe<Scalars['String']['output']>;
  /** Whether the news post is featured */
  feature_news: Scalars['Boolean']['output'];
  /** The history of the post */
  history?: Maybe<Array<Maybe<HistoryItem>>>;
  /** This id of this post */
  id: Scalars['ID']['output'];
  /** Images for the news post */
  images: Array<Maybe<Image>>;
  /** Whether to include in digest */
  include_in_digest: Scalars['Boolean']['output'];
  /** Whether to include in RSS feed */
  include_in_rss: Scalars['Boolean']['output'];
  /** Whether the post was created in Cortex or not */
  is_cortex: Scalars['Boolean']['output'];
  /** Whether the post was created from an OccasionGenius feed or not */
  is_og_feed: Scalars['Boolean']['output'];
  /** Whether the status is a published status */
  is_published: Scalars['Boolean']['output'];
  /** Related links to the news post */
  links?: Maybe<Array<Maybe<Link>>>;
  /** Whether the post is locked for editing */
  locked: Scalars['Boolean']['output'];
  /** Post this was copied from */
  master?: Maybe<Post>;
  /** The Portal side nodes for the post */
  nodes?: Maybe<Array<Maybe<Node>>>;
  /** The campaign this post belongs to (optional) */
  portal_campaign?: Maybe<Portal_Campaign>;
  portal_posts?: Maybe<Array<Maybe<Portal_Post>>>;
  /** The portals of the post */
  portals?: Maybe<Array<Maybe<Portal>>>;
  post_tasks?: Maybe<Array<Maybe<Post_Task>>>;
  premium_subcategory?: Maybe<Premium_Subcategory>;
  /** The timestamp the post is scheduled to be published at */
  publish_at: Scalars['String']['output'];
  /** The reporting region the post belongs to */
  reporting_region: Scalars['String']['output'];
  /** Whether the content is to be sent via notify */
  send_as_notify?: Maybe<Scalars['Boolean']['output']>;
  /** Whether this event is shareable */
  shareable_content: Scalars['Boolean']['output'];
  /** The source of the news post */
  source?: Maybe<Link>;
  /** The current status of the post */
  status: Scalars['String']['output'];
  /** The translated status of the post */
  status_label: Scalars['String']['output'];
  /** A collection of tags */
  tags?: Maybe<Array<Maybe<Tag>>>;
  /** Whether the post is a template */
  template: Scalars['Boolean']['output'];
  /** The title of this post */
  title: Scalars['String']['output'];
  /** The type of post */
  type: Scalars['String']['output'];
  /** The timestamp the post was last updated at */
  updated_at: Scalars['Int']['output'];
  /** The UUID of the post */
  uuid: Scalars['String']['output'];
  /** The current version id of the post */
  version_id: Scalars['Int']['output'];
};


/** A single news post */
export type News_PostStatus_LabelArgs = {
  lang?: InputMaybe<Scalars['String']['input']>;
};

/** The Portal side node with url and UUIDs for the post */
export type Node = {
  __typename?: 'node';
  domain_uuid: Scalars['String']['output'];
  portal_id?: Maybe<Scalars['ID']['output']>;
  url: Scalars['String']['output'];
  uuid: Scalars['String']['output'];
};

/** An OccasionGenius area */
export type Og_Area = {
  __typename?: 'og_area';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  timezone: Scalars['String']['output'];
  uuid: Scalars['String']['output'];
};

/** An OccasionGenius feed */
export type Og_Feed = {
  __typename?: 'og_feed';
  area: Og_Area;
  defaultPostStatus: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  portals: Array<Portal>;
  radius: Scalars['Int']['output'];
};

/** A single portal */
export type Portal = {
  __typename?: 'portal';
  brandingColours?: Maybe<Portal_Branding_Colours>;
  /** Building info categories for a portal */
  building_info_categories?: Maybe<Array<Maybe<Building_Info_Category>>>;
  /** The city the Portal is located in */
  city?: Maybe<Scalars['String']['output']>;
  /** The client associated with the Portal */
  client: Client;
  /** The domain UUID of the Portal */
  domain_uuid: Scalars['String']['output'];
  /** Email footer image url of the portal */
  email_footer_image_url?: Maybe<Scalars['String']['output']>;
  /** Email footer subtext of the portal */
  email_footer_subtext?: Maybe<Scalars['String']['output']>;
  /** Email footer text of the portal */
  email_footer_text?: Maybe<Scalars['String']['output']>;
  /** Email header image url of the portal */
  email_header_image_url?: Maybe<Scalars['String']['output']>;
  /** The id of this Portal */
  id: Scalars['ID']['output'];
  /** The name of the Portal */
  name: Scalars['String']['output'];
  /** The premium subcategories enabled for this portal */
  premium_subcategories: Array<Maybe<Premium_Subcategory>>;
  site_post_count?: Maybe<Site_Post_Count>;
  /** The social links for the site */
  social_links?: Maybe<Array<Maybe<Social_Link>>>;
  /** The state the Portal is located in */
  state?: Maybe<Scalars['String']['output']>;
  /** The timezone of the Portal */
  timezone?: Maybe<Scalars['String']['output']>;
  /** The base URL of the Portal */
  url: Scalars['String']['output'];
  /** The users associated with the Portal */
  users?: Maybe<Array<Maybe<User>>>;
};


/** A single portal */
export type PortalBuilding_Info_CategoriesArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


/** A single portal */
export type PortalUsersArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

/** Branding colours for the portal */
export type Portal_Branding_Colours = {
  __typename?: 'portal_branding_colours';
  background: Scalars['String']['output'];
  color: Scalars['String']['output'];
};

/** A single portal campaign */
export type Portal_Campaign = {
  __typename?: 'portal_campaign';
  /** The client associated with the campaign */
  client: Client;
  /** Metric comparison range end */
  comparison_end_date?: Maybe<Scalars['String']['output']>;
  /** Metric comparison range start */
  comparison_start_date?: Maybe<Scalars['String']['output']>;
  /** When the portal campaign ends */
  end_date: Scalars['String']['output'];
  /** This id of this portal campaign */
  id: Scalars['ID']['output'];
  /** The name of the campaign */
  name: Scalars['String']['output'];
  /** Notes about the campaign */
  notes?: Maybe<Scalars['String']['output']>;
  /** The portal associated with the campaign */
  portal: Portal;
  posts: Array<Maybe<Post>>;
  /** When the portal campaign starts */
  start_date: Scalars['String']['output'];
  /** The UUID of the campaign */
  uuid: Scalars['String']['output'];
};


/** A single portal campaign */
export type Portal_CampaignPostsArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
};

/** The connection type for portal_campaign. */
export type Portal_CampaignConnection = {
  __typename?: 'portal_campaignConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<Portal_CampaignEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type Portal_CampaignEdge = {
  __typename?: 'portal_campaignEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<Portal_Campaign>;
};

/** A single portal post */
export type Portal_Post = {
  __typename?: 'portal_post';
  /** The building info category for this building info post */
  building_info_category?: Maybe<Building_Info_Category>;
  /** The timestamp this portal post was created at */
  created_at: Scalars['Int']['output'];
  /** This id of this portal post */
  id: Scalars['ID']['output'];
  /** If the post is live on portal */
  live_on_portal?: Maybe<Scalars['Boolean']['output']>;
  /** Sort order of the portal post */
  order?: Maybe<Scalars['Int']['output']>;
  /** The portal associated with the post */
  portal: Portal;
  /** The post the post task relates to */
  post: Post;
  /** If the post is published on portal */
  published_on_portal?: Maybe<Scalars['Boolean']['output']>;
  /** Optional segment ids */
  segment_ids?: Maybe<Array<Scalars['String']['output']>>;
  /** Optional segment summary */
  segment_summary?: Maybe<Scalars['String']['output']>;
  /** unique part of the shareable link */
  shareable_link_id: Scalars['String']['output'];
  /** The UUID of the portal post */
  uuid: Scalars['String']['output'];
};

/** A single post */
export type Post = {
  /** The user assigned to the post */
  assignee: User;
  /** The author email of the post */
  author?: Maybe<Scalars['String']['output']>;
  /** The body of the post */
  body: Scalars['String']['output'];
  /** Campaign posts */
  campaign_posts?: Maybe<Array<Maybe<CampaignPost>>>;
  /** Whether the post is deletable or not */
  can_destroy: Scalars['Boolean']['output'];
  /** Whether the user has permissions to edit the post content */
  can_edit_content: Scalars['Boolean']['output'];
  /** Whether the user has permissions to edit the post metadata */
  can_edit_metadata: Scalars['Boolean']['output'];
  /** Whether the user has permissions to view the post content */
  can_view_content: Scalars['Boolean']['output'];
  /** The category this post belongs to */
  category?: Maybe<Category>;
  /** The client the post belongs to */
  client: Client;
  comments?: Maybe<Array<Maybe<Comment>>>;
  /** Content types */
  content_types?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** Whether the post can be copied */
  copyable: Scalars['Boolean']['output'];
  /** The timestamp the post was created at */
  created_at: Scalars['Int']['output'];
  /** The audience this post is meant for */
  equiem_one_post_audience?: Maybe<EquiemOnePostAudience>;
  /** The optional Cortex role used to create post */
  equiem_one_user_role?: Maybe<EquiemOneUserRole>;
  /** Timestamp when post will unsubscribe */
  expire_at?: Maybe<Scalars['String']['output']>;
  /** The history of the post */
  history?: Maybe<Array<Maybe<HistoryItem>>>;
  /** This id of this post */
  id: Scalars['ID']['output'];
  /** Whether the post was created in Cortex or not */
  is_cortex: Scalars['Boolean']['output'];
  /** Whether the post was created from an OccasionGenius feed or not */
  is_og_feed: Scalars['Boolean']['output'];
  /** Whether the status is a published status */
  is_published: Scalars['Boolean']['output'];
  /** Whether the post is locked for editing */
  locked: Scalars['Boolean']['output'];
  /** Post this was copied from */
  master?: Maybe<Post>;
  /** The Portal side nodes for the post */
  nodes?: Maybe<Array<Maybe<Node>>>;
  /** The campaign this post belongs to (optional) */
  portal_campaign?: Maybe<Portal_Campaign>;
  portal_posts?: Maybe<Array<Maybe<Portal_Post>>>;
  /** The portals of the post */
  portals?: Maybe<Array<Maybe<Portal>>>;
  post_tasks?: Maybe<Array<Maybe<Post_Task>>>;
  premium_subcategory?: Maybe<Premium_Subcategory>;
  /** The timestamp the post is scheduled to be published at */
  publish_at: Scalars['String']['output'];
  /** The reporting region the post belongs to */
  reporting_region: Scalars['String']['output'];
  /** The current status of the post */
  status: Scalars['String']['output'];
  /** The translated status of the post */
  status_label: Scalars['String']['output'];
  /** A collection of tags */
  tags?: Maybe<Array<Maybe<Tag>>>;
  /** Whether the post is a template */
  template: Scalars['Boolean']['output'];
  /** The title of this post */
  title: Scalars['String']['output'];
  /** The type of post */
  type: Scalars['String']['output'];
  /** The timestamp the post was last updated at */
  updated_at: Scalars['Int']['output'];
  /** The UUID of the post */
  uuid: Scalars['String']['output'];
  /** The current version id of the post */
  version_id: Scalars['Int']['output'];
};


/** A single post */
export type PostStatus_LabelArgs = {
  lang?: InputMaybe<Scalars['String']['input']>;
};

/** The connection type for post. */
export type PostConnection = {
  __typename?: 'postConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<PostEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type PostEdge = {
  __typename?: 'postEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<Post>;
};

/** A single post task */
export type Post_Task = {
  __typename?: 'post_task';
  /** The user that this post task is assigned to */
  assignee: User;
  /** The timestamp this post task was completed at */
  completed?: Maybe<Scalars['Int']['output']>;
  /** The timestamp this post task was created at */
  created_at: Scalars['Int']['output'];
  /** This id of this post task */
  id: Scalars['ID']['output'];
  /** The post the post task relates to */
  post: Post;
  /** The user that requested the post task */
  requester: User;
  /** The task this post task relates to */
  task: Task;
};

/** A single premium subcategory */
export type Premium_Subcategory = {
  __typename?: 'premium_subcategory';
  /** The category it belongs to */
  category: Category;
  /** This id of this subcategory */
  id: Scalars['ID']['output'];
  /** The subcategory label */
  label: Scalars['String']['output'];
};

export type Query = {
  __typename?: 'query';
  myPosts: Array<Post>;
  ogFeeds: Array<Og_Feed>;
};


export type QueryOgFeedsArgs = {
  clientId: Scalars['ID']['input'];
};

/** Site post counts */
export type Site_Post_Count = {
  __typename?: 'site_post_count';
  feedback_post: Scalars['Int']['output'];
  nps_post: Scalars['Int']['output'];
};

/** A single social link for a site */
export type Social_Link = {
  __typename?: 'social_link';
  icon: Scalars['String']['output'];
  type: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

/** Styleguide for a client */
export type Styleguide = {
  __typename?: 'styleguide';
  /** The contents of the styleguide */
  body?: Maybe<Scalars['String']['output']>;
  /** The client associated with the styleguide */
  client?: Maybe<Client>;
};

/** A single tag */
export type Tag = {
  __typename?: 'tag';
  /** The client the tag belongs to */
  client: Client;
  /** This id of this tag */
  id: Scalars['ID']['output'];
  /** The tag label */
  label: Scalars['String']['output'];
};

/** A single task */
export type Task = {
  __typename?: 'task';
  /** The client the task belongs to */
  client: Client;
  /** The timestamp the task was created at */
  created_at: Scalars['Int']['output'];
  /** This id of this task */
  id: Scalars['ID']['output'];
  /** The task label */
  label: Scalars['String']['output'];
};

/** A single user */
export type User = {
  __typename?: 'user';
  /** The active client for this user */
  active_client?: Maybe<Client>;
  assigned_comments?: Maybe<CommentConnection>;
  /** Post tasks the user is assigned to */
  assigned_post_tasks?: Maybe<Array<Maybe<Post_Task>>>;
  assigned_posts?: Maybe<PostConnection>;
  /** The auth0 id of the user */
  auth0_id: Scalars['String']['output'];
  /** The clients the user is assigned to */
  clients?: Maybe<Array<Maybe<Client>>>;
  cortex_jwt: Scalars['String']['output'];
  /** The timestamp the user was created at */
  created_at: Scalars['Int']['output'];
  /** The users email address */
  email: Scalars['String']['output'];
  /** The first name of the user */
  first_name: Scalars['String']['output'];
  /** The first and last name of the user */
  full_name: Scalars['String']['output'];
  /** This id of this user */
  id: Scalars['ID']['output'];
  /** The last name of the user */
  last_name: Scalars['String']['output'];
  /** The client permissions for this user */
  permissions: Array<Maybe<Permission>>;
  /** The portals the user is assigned to */
  portals?: Maybe<Array<Maybe<Portal>>>;
  /** Post tasks the user has requested */
  requested_post_tasks?: Maybe<Array<Maybe<Post_Task>>>;
};


/** A single user */
export type UserActive_ClientArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


/** A single user */
export type UserAssigned_CommentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  client_id: Scalars['ID']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


/** A single user */
export type UserAssigned_Post_TasksArgs = {
  client_id?: InputMaybe<Scalars['ID']['input']>;
  completed?: InputMaybe<Scalars['Boolean']['input']>;
};


/** A single user */
export type UserAssigned_PostsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
  query?: InputMaybe<PostQueryInput>;
};


/** A single user */
export type UserClientsArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


/** A single user */
export type UserPermissionsArgs = {
  client_id?: InputMaybe<Scalars['ID']['input']>;
};


/** A single user */
export type UserPortalsArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


/** A single user */
export type UserRequested_Post_TasksArgs = {
  client_id?: InputMaybe<Scalars['ID']['input']>;
  completed?: InputMaybe<Scalars['Boolean']['input']>;
};

export type BuildingInfoPostFragmentFragment = { __typename?: 'building_info_post', id: string, uuid: string, title: string, publish_at: string, is_published: boolean, status: string, equiem_one_post_audience?: EquiemOnePostAudience | null, excerpt: string, body: string, image: { __typename?: 'Image', key: string, url: string, alt?: string | null }, links?: Array<{ __typename?: 'Link', title: string, url: string } | null> | null, downloads?: Array<{ __typename?: 'File', title: string, key: string, url: string } | null> | null };

export type CreateBuildingInfoPostMutationVariables = Exact<{
  input: CreateBuildingInfoPostInput;
}>;


export type CreateBuildingInfoPostMutation = { __typename?: 'mutation', createBuildingInfoPost?: { __typename?: 'CreateBuildingInfoPostPayload', post?: { __typename?: 'building_info_post', id: string, uuid: string, title: string, publish_at: string, is_published: boolean, status: string, equiem_one_post_audience?: EquiemOnePostAudience | null, excerpt: string, body: string, image: { __typename?: 'Image', key: string, url: string, alt?: string | null }, links?: Array<{ __typename?: 'Link', title: string, url: string } | null> | null, downloads?: Array<{ __typename?: 'File', title: string, key: string, url: string } | null> | null } | null } | null };

export type CreateEventPostMutationVariables = Exact<{
  createEventInput: CreateEventPostInput;
}>;


export type CreateEventPostMutation = { __typename?: 'mutation', createEventPost?: { __typename?: 'CreateEventPostPayload', post?: { __typename?: 'event_post', id: string, uuid: string, title: string, publish_at: string, is_published: boolean, status: string, equiem_one_post_audience?: EquiemOnePostAudience | null, body: string, images: Array<{ __typename?: 'Image', key: string, url: string, alt?: string | null } | null>, repeats?: { __typename?: 'RepeatingDateRange', occurrences?: Array<{ __typename?: 'Occurrence', uuid: string, date_range: { __typename?: 'DateRange', end: string, timezone?: string | null, start: string } } | null> | null } | null, location: { __typename?: 'Location', google_place: string, name: string }, rsvp?: { __typename?: 'Rsvp', attendee_limit?: number | null } | null } | null } | null };

export type CreateNewsPostMutationVariables = Exact<{
  input: CreateNewsPostInput;
}>;


export type CreateNewsPostMutation = { __typename?: 'mutation', createNewsPost?: { __typename?: 'CreateNewsPostPayload', post?: { __typename?: 'news_post', id: string, uuid: string, title: string, publish_at: string, is_published: boolean, status: string, equiem_one_post_audience?: EquiemOnePostAudience | null, excerpt: string, body: string, images: Array<{ __typename?: 'Image', key: string, url: string, alt?: string | null } | null>, downloads?: Array<{ __typename?: 'File', title: string, key: string, url: string } | null> | null } | null } | null };

export type CreatePresignedUrlMutationVariables = Exact<{
  input: CreatePresignedUrlInput;
}>;


export type CreatePresignedUrlMutation = { __typename?: 'mutation', createPresignedUrl?: { __typename?: 'CreatePresignedURLPayload', presignedUrl: string, key: string } | null };

export type CreateSecurePresignedUrlMutationVariables = Exact<{
  input: CreateSecurePresignedUrlInput;
}>;


export type CreateSecurePresignedUrlMutation = { __typename?: 'mutation', createSecurePresignedUrl?: { __typename?: 'CreateSecurePresignedURLPayload', securePresignedUrl: string, key: string } | null };

export type PostDeleteMutationVariables = Exact<{
  deletePostInput: DeletePostInput;
}>;


export type PostDeleteMutation = { __typename?: 'mutation', deletePost?: { __typename?: 'DeletePostPayload', deleted?: boolean | null } | null };

export type EditBuildingInfoPostMutationVariables = Exact<{
  input: EditBuildingInfoPostInput;
}>;


export type EditBuildingInfoPostMutation = { __typename?: 'mutation', editBuildingInfoPost?: { __typename?: 'EditBuildingInfoPostPayload', post?: { __typename?: 'building_info_post', id: string, uuid: string, title: string, publish_at: string, is_published: boolean, status: string, equiem_one_post_audience?: EquiemOnePostAudience | null, excerpt: string, body: string, image: { __typename?: 'Image', key: string, url: string, alt?: string | null }, links?: Array<{ __typename?: 'Link', title: string, url: string } | null> | null, downloads?: Array<{ __typename?: 'File', title: string, key: string, url: string } | null> | null } | null } | null };

export type EditEventPostMutationVariables = Exact<{
  updateEventInput: EditEventPostInput;
}>;


export type EditEventPostMutation = { __typename?: 'mutation', editEventPost?: { __typename?: 'EditEventPostPayload', post?: { __typename?: 'event_post', id: string, uuid: string, title: string, publish_at: string, is_published: boolean, status: string, equiem_one_post_audience?: EquiemOnePostAudience | null, body: string, images: Array<{ __typename?: 'Image', key: string, url: string, alt?: string | null } | null>, repeats?: { __typename?: 'RepeatingDateRange', occurrences?: Array<{ __typename?: 'Occurrence', uuid: string, date_range: { __typename?: 'DateRange', end: string, timezone?: string | null, start: string } } | null> | null } | null, location: { __typename?: 'Location', google_place: string, name: string }, rsvp?: { __typename?: 'Rsvp', attendee_limit?: number | null } | null } | null } | null };

export type EditNewsPostMutationVariables = Exact<{
  input: EditNewsPostInput;
}>;


export type EditNewsPostMutation = { __typename?: 'mutation', editNewsPost?: { __typename?: 'EditNewsPostPayload', post?: { __typename?: 'news_post', id: string, uuid: string, title: string, publish_at: string, is_published: boolean, status: string, equiem_one_post_audience?: EquiemOnePostAudience | null, excerpt: string, body: string, images: Array<{ __typename?: 'Image', key: string, url: string, alt?: string | null } | null>, downloads?: Array<{ __typename?: 'File', title: string, key: string, url: string } | null> | null } | null } | null };

export type EventPostFragmentFragment = { __typename?: 'event_post', id: string, uuid: string, title: string, publish_at: string, is_published: boolean, status: string, equiem_one_post_audience?: EquiemOnePostAudience | null, body: string, images: Array<{ __typename?: 'Image', key: string, url: string, alt?: string | null } | null>, repeats?: { __typename?: 'RepeatingDateRange', occurrences?: Array<{ __typename?: 'Occurrence', uuid: string, date_range: { __typename?: 'DateRange', end: string, timezone?: string | null, start: string } } | null> | null } | null, location: { __typename?: 'Location', google_place: string, name: string }, rsvp?: { __typename?: 'Rsvp', attendee_limit?: number | null } | null };

export type MyPostsQueryVariables = Exact<{ [key: string]: never; }>;


export type MyPostsQuery = { __typename?: 'query', myPosts: Array<{ __typename?: 'building_info_post', id: string, uuid: string, title: string, publish_at: string, is_published: boolean, status: string, equiem_one_post_audience?: EquiemOnePostAudience | null, excerpt: string, body: string, image: { __typename?: 'Image', key: string, url: string, alt?: string | null }, links?: Array<{ __typename?: 'Link', title: string, url: string } | null> | null, downloads?: Array<{ __typename?: 'File', title: string, key: string, url: string } | null> | null } | { __typename?: 'event_post', id: string, uuid: string, title: string, publish_at: string, is_published: boolean, status: string, equiem_one_post_audience?: EquiemOnePostAudience | null, body: string, images: Array<{ __typename?: 'Image', key: string, url: string, alt?: string | null } | null>, repeats?: { __typename?: 'RepeatingDateRange', occurrences?: Array<{ __typename?: 'Occurrence', uuid: string, date_range: { __typename?: 'DateRange', end: string, timezone?: string | null, start: string } } | null> | null } | null, location: { __typename?: 'Location', google_place: string, name: string }, rsvp?: { __typename?: 'Rsvp', attendee_limit?: number | null } | null } | { __typename?: 'news_post', id: string, uuid: string, title: string, publish_at: string, is_published: boolean, status: string, equiem_one_post_audience?: EquiemOnePostAudience | null, excerpt: string, body: string, images: Array<{ __typename?: 'Image', key: string, url: string, alt?: string | null } | null>, downloads?: Array<{ __typename?: 'File', title: string, key: string, url: string } | null> | null }> };

export type NewsPostFragmentFragment = { __typename?: 'news_post', id: string, uuid: string, title: string, publish_at: string, is_published: boolean, status: string, equiem_one_post_audience?: EquiemOnePostAudience | null, excerpt: string, body: string, images: Array<{ __typename?: 'Image', key: string, url: string, alt?: string | null } | null>, downloads?: Array<{ __typename?: 'File', title: string, key: string, url: string } | null> | null };

export const BuildingInfoPostFragmentFragmentDoc = gql`
    fragment BuildingInfoPostFragment on building_info_post {
  id
  uuid
  title
  publish_at
  is_published
  status
  equiem_one_post_audience
  excerpt
  body
  image {
    key
    url
    alt
  }
  links {
    title
    url
  }
  downloads {
    title
    key
    url
  }
}
    `;
export const EventPostFragmentFragmentDoc = gql`
    fragment EventPostFragment on event_post {
  id
  uuid
  title
  publish_at
  is_published
  status
  equiem_one_post_audience
  body
  images {
    key
    url
    alt
  }
  repeats {
    occurrences {
      uuid
      date_range {
        end
        timezone
        start
      }
    }
  }
  location {
    google_place
    name
  }
  rsvp {
    attendee_limit
  }
}
    `;
export const NewsPostFragmentFragmentDoc = gql`
    fragment NewsPostFragment on news_post {
  id
  uuid
  title
  publish_at
  is_published
  status
  equiem_one_post_audience
  excerpt
  body
  images {
    key
    url
    alt
  }
  downloads {
    title
    key
    url
  }
}
    `;
export const CreateBuildingInfoPostDocument = gql`
    mutation CreateBuildingInfoPost($input: CreateBuildingInfoPostInput!) {
  createBuildingInfoPost(input: $input) {
    post {
      ...BuildingInfoPostFragment
    }
  }
}
    ${BuildingInfoPostFragmentFragmentDoc}`;
export type CreateBuildingInfoPostMutationFn = Apollo.MutationFunction<CreateBuildingInfoPostMutation, CreateBuildingInfoPostMutationVariables>;

/**
 * __useCreateBuildingInfoPostMutation__
 *
 * To run a mutation, you first call `useCreateBuildingInfoPostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBuildingInfoPostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBuildingInfoPostMutation, { data, loading, error }] = useCreateBuildingInfoPostMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateBuildingInfoPostMutation(baseOptions?: Apollo.MutationHookOptions<CreateBuildingInfoPostMutation, CreateBuildingInfoPostMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateBuildingInfoPostMutation, CreateBuildingInfoPostMutationVariables>(CreateBuildingInfoPostDocument, options);
      }
export type CreateBuildingInfoPostMutationHookResult = ReturnType<typeof useCreateBuildingInfoPostMutation>;
export type CreateBuildingInfoPostMutationResult = Apollo.MutationResult<CreateBuildingInfoPostMutation>;
export type CreateBuildingInfoPostMutationOptions = Apollo.BaseMutationOptions<CreateBuildingInfoPostMutation, CreateBuildingInfoPostMutationVariables>;
export const CreateEventPostDocument = gql`
    mutation CreateEventPost($createEventInput: CreateEventPostInput!) {
  createEventPost(input: $createEventInput) {
    post {
      ...EventPostFragment
    }
  }
}
    ${EventPostFragmentFragmentDoc}`;
export type CreateEventPostMutationFn = Apollo.MutationFunction<CreateEventPostMutation, CreateEventPostMutationVariables>;

/**
 * __useCreateEventPostMutation__
 *
 * To run a mutation, you first call `useCreateEventPostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEventPostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEventPostMutation, { data, loading, error }] = useCreateEventPostMutation({
 *   variables: {
 *      createEventInput: // value for 'createEventInput'
 *   },
 * });
 */
export function useCreateEventPostMutation(baseOptions?: Apollo.MutationHookOptions<CreateEventPostMutation, CreateEventPostMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateEventPostMutation, CreateEventPostMutationVariables>(CreateEventPostDocument, options);
      }
export type CreateEventPostMutationHookResult = ReturnType<typeof useCreateEventPostMutation>;
export type CreateEventPostMutationResult = Apollo.MutationResult<CreateEventPostMutation>;
export type CreateEventPostMutationOptions = Apollo.BaseMutationOptions<CreateEventPostMutation, CreateEventPostMutationVariables>;
export const CreateNewsPostDocument = gql`
    mutation CreateNewsPost($input: CreateNewsPostInput!) {
  createNewsPost(input: $input) {
    post {
      ...NewsPostFragment
    }
  }
}
    ${NewsPostFragmentFragmentDoc}`;
export type CreateNewsPostMutationFn = Apollo.MutationFunction<CreateNewsPostMutation, CreateNewsPostMutationVariables>;

/**
 * __useCreateNewsPostMutation__
 *
 * To run a mutation, you first call `useCreateNewsPostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNewsPostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNewsPostMutation, { data, loading, error }] = useCreateNewsPostMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateNewsPostMutation(baseOptions?: Apollo.MutationHookOptions<CreateNewsPostMutation, CreateNewsPostMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateNewsPostMutation, CreateNewsPostMutationVariables>(CreateNewsPostDocument, options);
      }
export type CreateNewsPostMutationHookResult = ReturnType<typeof useCreateNewsPostMutation>;
export type CreateNewsPostMutationResult = Apollo.MutationResult<CreateNewsPostMutation>;
export type CreateNewsPostMutationOptions = Apollo.BaseMutationOptions<CreateNewsPostMutation, CreateNewsPostMutationVariables>;
export const CreatePresignedUrlDocument = gql`
    mutation CreatePresignedURL($input: CreatePresignedURLInput!) {
  createPresignedUrl(input: $input) {
    presignedUrl
    key
  }
}
    `;
export type CreatePresignedUrlMutationFn = Apollo.MutationFunction<CreatePresignedUrlMutation, CreatePresignedUrlMutationVariables>;

/**
 * __useCreatePresignedUrlMutation__
 *
 * To run a mutation, you first call `useCreatePresignedUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePresignedUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPresignedUrlMutation, { data, loading, error }] = useCreatePresignedUrlMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePresignedUrlMutation(baseOptions?: Apollo.MutationHookOptions<CreatePresignedUrlMutation, CreatePresignedUrlMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePresignedUrlMutation, CreatePresignedUrlMutationVariables>(CreatePresignedUrlDocument, options);
      }
export type CreatePresignedUrlMutationHookResult = ReturnType<typeof useCreatePresignedUrlMutation>;
export type CreatePresignedUrlMutationResult = Apollo.MutationResult<CreatePresignedUrlMutation>;
export type CreatePresignedUrlMutationOptions = Apollo.BaseMutationOptions<CreatePresignedUrlMutation, CreatePresignedUrlMutationVariables>;
export const CreateSecurePresignedUrlDocument = gql`
    mutation CreateSecurePresignedURL($input: CreateSecurePresignedURLInput!) {
  createSecurePresignedUrl(input: $input) {
    securePresignedUrl
    key
  }
}
    `;
export type CreateSecurePresignedUrlMutationFn = Apollo.MutationFunction<CreateSecurePresignedUrlMutation, CreateSecurePresignedUrlMutationVariables>;

/**
 * __useCreateSecurePresignedUrlMutation__
 *
 * To run a mutation, you first call `useCreateSecurePresignedUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSecurePresignedUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSecurePresignedUrlMutation, { data, loading, error }] = useCreateSecurePresignedUrlMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSecurePresignedUrlMutation(baseOptions?: Apollo.MutationHookOptions<CreateSecurePresignedUrlMutation, CreateSecurePresignedUrlMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSecurePresignedUrlMutation, CreateSecurePresignedUrlMutationVariables>(CreateSecurePresignedUrlDocument, options);
      }
export type CreateSecurePresignedUrlMutationHookResult = ReturnType<typeof useCreateSecurePresignedUrlMutation>;
export type CreateSecurePresignedUrlMutationResult = Apollo.MutationResult<CreateSecurePresignedUrlMutation>;
export type CreateSecurePresignedUrlMutationOptions = Apollo.BaseMutationOptions<CreateSecurePresignedUrlMutation, CreateSecurePresignedUrlMutationVariables>;
export const PostDeleteDocument = gql`
    mutation PostDelete($deletePostInput: DeletePostInput!) {
  deletePost(input: $deletePostInput) {
    deleted
  }
}
    `;
export type PostDeleteMutationFn = Apollo.MutationFunction<PostDeleteMutation, PostDeleteMutationVariables>;

/**
 * __usePostDeleteMutation__
 *
 * To run a mutation, you first call `usePostDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePostDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [postDeleteMutation, { data, loading, error }] = usePostDeleteMutation({
 *   variables: {
 *      deletePostInput: // value for 'deletePostInput'
 *   },
 * });
 */
export function usePostDeleteMutation(baseOptions?: Apollo.MutationHookOptions<PostDeleteMutation, PostDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PostDeleteMutation, PostDeleteMutationVariables>(PostDeleteDocument, options);
      }
export type PostDeleteMutationHookResult = ReturnType<typeof usePostDeleteMutation>;
export type PostDeleteMutationResult = Apollo.MutationResult<PostDeleteMutation>;
export type PostDeleteMutationOptions = Apollo.BaseMutationOptions<PostDeleteMutation, PostDeleteMutationVariables>;
export const EditBuildingInfoPostDocument = gql`
    mutation EditBuildingInfoPost($input: EditBuildingInfoPostInput!) {
  editBuildingInfoPost(input: $input) {
    post {
      ...BuildingInfoPostFragment
    }
  }
}
    ${BuildingInfoPostFragmentFragmentDoc}`;
export type EditBuildingInfoPostMutationFn = Apollo.MutationFunction<EditBuildingInfoPostMutation, EditBuildingInfoPostMutationVariables>;

/**
 * __useEditBuildingInfoPostMutation__
 *
 * To run a mutation, you first call `useEditBuildingInfoPostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditBuildingInfoPostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editBuildingInfoPostMutation, { data, loading, error }] = useEditBuildingInfoPostMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditBuildingInfoPostMutation(baseOptions?: Apollo.MutationHookOptions<EditBuildingInfoPostMutation, EditBuildingInfoPostMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditBuildingInfoPostMutation, EditBuildingInfoPostMutationVariables>(EditBuildingInfoPostDocument, options);
      }
export type EditBuildingInfoPostMutationHookResult = ReturnType<typeof useEditBuildingInfoPostMutation>;
export type EditBuildingInfoPostMutationResult = Apollo.MutationResult<EditBuildingInfoPostMutation>;
export type EditBuildingInfoPostMutationOptions = Apollo.BaseMutationOptions<EditBuildingInfoPostMutation, EditBuildingInfoPostMutationVariables>;
export const EditEventPostDocument = gql`
    mutation EditEventPost($updateEventInput: EditEventPostInput!) {
  editEventPost(input: $updateEventInput) {
    post {
      ...EventPostFragment
    }
  }
}
    ${EventPostFragmentFragmentDoc}`;
export type EditEventPostMutationFn = Apollo.MutationFunction<EditEventPostMutation, EditEventPostMutationVariables>;

/**
 * __useEditEventPostMutation__
 *
 * To run a mutation, you first call `useEditEventPostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditEventPostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editEventPostMutation, { data, loading, error }] = useEditEventPostMutation({
 *   variables: {
 *      updateEventInput: // value for 'updateEventInput'
 *   },
 * });
 */
export function useEditEventPostMutation(baseOptions?: Apollo.MutationHookOptions<EditEventPostMutation, EditEventPostMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditEventPostMutation, EditEventPostMutationVariables>(EditEventPostDocument, options);
      }
export type EditEventPostMutationHookResult = ReturnType<typeof useEditEventPostMutation>;
export type EditEventPostMutationResult = Apollo.MutationResult<EditEventPostMutation>;
export type EditEventPostMutationOptions = Apollo.BaseMutationOptions<EditEventPostMutation, EditEventPostMutationVariables>;
export const EditNewsPostDocument = gql`
    mutation EditNewsPost($input: EditNewsPostInput!) {
  editNewsPost(input: $input) {
    post {
      ...NewsPostFragment
    }
  }
}
    ${NewsPostFragmentFragmentDoc}`;
export type EditNewsPostMutationFn = Apollo.MutationFunction<EditNewsPostMutation, EditNewsPostMutationVariables>;

/**
 * __useEditNewsPostMutation__
 *
 * To run a mutation, you first call `useEditNewsPostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditNewsPostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editNewsPostMutation, { data, loading, error }] = useEditNewsPostMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditNewsPostMutation(baseOptions?: Apollo.MutationHookOptions<EditNewsPostMutation, EditNewsPostMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditNewsPostMutation, EditNewsPostMutationVariables>(EditNewsPostDocument, options);
      }
export type EditNewsPostMutationHookResult = ReturnType<typeof useEditNewsPostMutation>;
export type EditNewsPostMutationResult = Apollo.MutationResult<EditNewsPostMutation>;
export type EditNewsPostMutationOptions = Apollo.BaseMutationOptions<EditNewsPostMutation, EditNewsPostMutationVariables>;
export const MyPostsDocument = gql`
    query MyPosts {
  myPosts {
    ... on news_post {
      ...NewsPostFragment
    }
    ... on event_post {
      ...EventPostFragment
    }
    ... on building_info_post {
      ...BuildingInfoPostFragment
    }
  }
}
    ${NewsPostFragmentFragmentDoc}
${EventPostFragmentFragmentDoc}
${BuildingInfoPostFragmentFragmentDoc}`;

/**
 * __useMyPostsQuery__
 *
 * To run a query within a React component, call `useMyPostsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyPostsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyPostsQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyPostsQuery(baseOptions?: Apollo.QueryHookOptions<MyPostsQuery, MyPostsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MyPostsQuery, MyPostsQueryVariables>(MyPostsDocument, options);
      }
export function useMyPostsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MyPostsQuery, MyPostsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MyPostsQuery, MyPostsQueryVariables>(MyPostsDocument, options);
        }
export function useMyPostsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<MyPostsQuery, MyPostsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<MyPostsQuery, MyPostsQueryVariables>(MyPostsDocument, options);
        }
export type MyPostsQueryHookResult = ReturnType<typeof useMyPostsQuery>;
export type MyPostsLazyQueryHookResult = ReturnType<typeof useMyPostsLazyQuery>;
export type MyPostsSuspenseQueryHookResult = ReturnType<typeof useMyPostsSuspenseQuery>;
export type MyPostsQueryResult = Apollo.QueryResult<MyPostsQuery, MyPostsQueryVariables>;