import { useState } from "react";

import { notNullOrUndefined } from "@equiem/lib";

import type { CompanyListQuery } from "../../../generated/settings-client";
import { CompanyV2SortField, useCompanyListQuery } from "../../../generated/settings-client";

export type CompanyListItem = NonNullable<CompanyListQuery["companiesV2"]["edges"][number]["node"]>;

interface Settings {
  search?: string;
  destinationUuid?: string;
  excludeDestination?: boolean;
  pageSize?: number;
  buildingUuids?: string[];
  withFlexTenants: boolean;
}

export function usePagedCompanyList({
  search,
  destinationUuid,
  excludeDestination,
  pageSize,
  buildingUuids,
  withFlexTenants,
}: Settings) {
  const [currentPage, setCurrentPage] = useState(1);
  const { data, loading, error, refetch, fetchMore } = useCompanyListQuery({
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
    variables: {
      withBuildingLevels: true,
      withFlexTenants,
      destinationUuid,
      excludeDestination,
      first: pageSize,
      search: search != null && search.length > 0 ? search : undefined,
      buildingUuids,
      sort: {
        asc: true,
        field: CompanyV2SortField.CompanyName,
      },
    },
  });

  return {
    error,
    loading,
    companies: data?.companiesV2.edges.map(({ node }) => node).filter(notNullOrUndefined) ?? [],
    totalCount: data?.companiesV2.totalCount ?? 0,
    hasMoreData: data?.companiesV2.pageInfo.hasNextPage ?? false,
    refetch,
    fetchMore: () => {
      fetchMore({
        variables: {
          first: pageSize,
          after: data?.companiesV2.pageInfo.endCursor,
        },
        updateQuery(prev, { fetchMoreResult }) {
          return {
            ...fetchMoreResult,
            companiesV2: {
              ...fetchMoreResult.companiesV2,
              edges: [...prev.companiesV2.edges, ...fetchMoreResult.companiesV2.edges],
            },
          };
        },
      }).catch((e) => console.error(e));
    },
    currentPage,
    setCurrentPage,
  };
}
