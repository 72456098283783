import type { ColumnOption } from "csv-stringify/sync";
import { stringify } from "csv-stringify/sync";

export interface CsvColumn<Row> extends ColumnOption {
  key: Extract<keyof Row, string>;
}

export interface CsvParams<Row> {
  fileName: string;
  columns?: Array<CsvColumn<Row>>;
  rows: Row[];
}

/** UTF-8 byte-order marker to explicitly flag the file as utf-8 encoded */
const UTF8_BOM = "\ufeff";

export const downloadCsv = <Row>({ fileName, columns, rows }: CsvParams<Row>) => {
  const csv = stringify(rows, { header: true, columns });
  const file = new File([UTF8_BOM, csv], fileName, { type: "text/plain;charset=utf-8;" });
  const objectUrl = URL.createObjectURL(file);

  const link = document.createElement("a");
  link.download = fileName;
  link.href = objectUrl;

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
