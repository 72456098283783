import React, { useContext, useEffect, useState } from "react";
import type { FormikHelpers } from "formik";
import { Field, Formik } from "formik";
import { isEqual } from "lodash";
import { useRouter } from "next/router";
import * as yup from "yup";

import { ButtonTray, CurrentRole, Role, Site, SubTopMenu, useShowError } from "@equiem/lib";
import type { TFunction } from "@equiem/localisation-eq1";
import { useTranslation } from "@equiem/localisation-eq1";
import { Button, Form as EqForm, Modal, ProgressCircle, Text, useTheme, useToast } from "@equiem/react-admin-ui";

import type { VisitorTypesInput } from "../../../generated/visitors-client";
import {
  useUpsertVisitorManagementSiteSettingsMutation,
  useVisitorManagementSiteSettingsQuery,
  VisitorManagementSiteSettingsDocument,
} from "../../../generated/visitors-client";
import { Menu } from "../components/NestedMenu";

import { AccessPasses } from "./components/AccessPasses";
import { VisitorTypesTable } from "./components/VisitorTypes";
import type { GeneralSettingsFormValues } from "./GeneralSettingsValues";

const hexColorRegex = /^#[0-9A-F]{6}$/i;

const hexColorValidation = (errorString: string) =>
  yup.string().test("hex-check", errorString, (value: string | undefined) => {
    return value != null && hexColorRegex.test(value);
  });

const getValidationSchema = (t: TFunction) =>
  yup.object().shape({
    newVisitorType: yup
      .string()
      .test("is-unique", t("visitors.appointmentForm.timeValidation"), function (value: string | undefined, context) {
        return (
          (context.parent as GeneralSettingsFormValues).visitorTypes.every(
            ({ name }) => name.toLowerCase() !== value?.toLowerCase(),
          ) || this.createError({ message: t("visitors.visitorTypes.visitorTypeExists", { name: value as string }) })
        );
      }),
    walletPassBackgroundColor: hexColorValidation(t("visitors.settings.walletPass.colorValidation")),
    walletPassForegroundColor: hexColorValidation(t("visitors.settings.walletPass.colorValidation")),
    walletPassLabelColor: hexColorValidation(t("visitors.settings.walletPass.colorValidation")),
  });

export const GeneralSettings: React.FC = () => {
  const { t } = useTranslation();
  const theme = useTheme(true);
  const toast = useToast();
  const router = useRouter();
  const showError = useShowError();
  const { currentRole } = useContext(CurrentRole);
  const { uuid: siteUuid } = useContext(Site);
  const [isAddingType, setIsAddingType] = useState(false);
  const [maxAppointmentCreationMonths, setMaxAppointmentCreationMonths] = useState<number | undefined | null>();
  const [customMaxAppointmentCreationMonthsSelected, setCustomMaxAppointmentCreationMonthsSelected] = useState(false);
  const defaultSiteSettingsMaxAppointmentCreationMonths = "12";
  const customMaxAppointmentCreationMonthsOptions: Array<{
    value: string;
    label: string;
  }> = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((i) => ({
    label: (i + 1).toString(),
    value: (i + 1).toString(),
  }));

  const { data, refetch, loading } = useVisitorManagementSiteSettingsQuery({
    variables: {
      siteUuid: siteUuid,
    },
    fetchPolicy: "no-cache",
  });
  const [updateSiteSettings] = useUpsertVisitorManagementSiteSettingsMutation();

  const initialFormValues = {
    visitorTypes: (data?.visitorManagementSiteSettings.visitorTypes ?? []).map((item) => ({
      ...item,
      isEditing: false,
    })),
    newVisitorAppointmentType: [],
    newVisitorType: "",
    isHostEmailMandatory: data?.visitorManagementSiteSettings.isHostEmailMandatory ?? true,
    isVisitorTypesEnabled: data?.visitorManagementSiteSettings.isVisitorTypesEnabled ?? false,
    maxAppointmentCreationMonths: data?.visitorManagementSiteSettings.maxAppointmentCreationMonths ?? 12,
    walletPassBackgroundColor: data?.visitorManagementSiteSettings.walletPassBackgroundColor ?? "#EEEEEE",
    walletPassForegroundColor: data?.visitorManagementSiteSettings.walletPassForegroundColor ?? "#000000",
    walletPassLabelColor: data?.visitorManagementSiteSettings.walletPassLabelColor ?? "#000000",
  };

  const [showModal, setShowModal] = useState<boolean>(false);

  useEffect(() => {
    if (currentRole !== Role.PropertyManager) {
      void router.replace("/visitor-management/settings/receptions");
    }
  }, [currentRole, router]);

  const resetVmSettings = () => {
    setMaxAppointmentCreationMonths(
      data?.visitorManagementSiteSettings.maxAppointmentCreationMonths ??
        Number.parseInt(defaultSiteSettingsMaxAppointmentCreationMonths),
    );
    setCustomMaxAppointmentCreationMonthsSelected(
      data?.visitorManagementSiteSettings.maxAppointmentCreationMonths != null &&
        data.visitorManagementSiteSettings.maxAppointmentCreationMonths !==
          Number.parseInt(defaultSiteSettingsMaxAppointmentCreationMonths),
    );
  };

  const handleCancel = () => {
    resetVmSettings();
    setShowModal(false);
  };

  useEffect(() => {
    resetVmSettings();
  }, [data]);

  const handleSubmit = async (values: GeneralSettingsFormValues, actions: FormikHelpers<GeneralSettingsFormValues>) => {
    try {
      await updateSiteSettings({
        variables: {
          input: {
            maxAppointmentCreationMonths: Number(values.maxAppointmentCreationMonths),
            visitorTypes: values.visitorTypes.map((item) => ({
              uuid: item.uuid,
              isDefault: item.isDefault,
              name: item.name,
              appointmentTypes: item.appointmentTypes,
            })) as VisitorTypesInput[],
            isVisitorTypeFeatureEnabled: values.isVisitorTypesEnabled,
            isHostEmailMandatory: values.isHostEmailMandatory,
            walletPassBackgroundColor: values.walletPassBackgroundColor,
            walletPassForegroundColor: values.walletPassForegroundColor,
            walletPassLabelColor: values.walletPassLabelColor,
          },
        },
        refetchQueries: [VisitorManagementSiteSettingsDocument],
      });

      toast.positive(t("visitors.settings.changesSaved"));
      void refetch();
    } catch (e: unknown) {
      showError(e);
    } finally {
      actions.setSubmitting(false);
    }
  };

  const maxAppointmentCreationMonthsChanged = (values: GeneralSettingsFormValues) => {
    if (
      (data?.visitorManagementSiteSettings.maxAppointmentCreationMonths == null &&
        maxAppointmentCreationMonths != null &&
        maxAppointmentCreationMonths !== Number.parseInt(defaultSiteSettingsMaxAppointmentCreationMonths)) ||
      (data?.visitorManagementSiteSettings.maxAppointmentCreationMonths != null &&
        maxAppointmentCreationMonths != null &&
        values.maxAppointmentCreationMonths !== data.visitorManagementSiteSettings.maxAppointmentCreationMonths &&
        maxAppointmentCreationMonths !== data.visitorManagementSiteSettings.maxAppointmentCreationMonths)
    ) {
      return true;
    }

    return false;
  };

  const isValueChanged = (values: GeneralSettingsFormValues) => {
    return values.isVisitorTypesEnabled !== data?.visitorManagementSiteSettings.isVisitorTypesEnabled;
  };

  const isHostEmailMandatoryChanged = (values: GeneralSettingsFormValues) => {
    return values.isHostEmailMandatory !== data?.visitorManagementSiteSettings.isHostEmailMandatory;
  };

  const isVisitorTypesChanged = (values: GeneralSettingsFormValues) => {
    const visitorTypesFormValues = values.visitorTypes.map((item) => ({
      isDefault: item.isDefault,
      name: item.name,
      apppointmentTypes: item.appointmentTypes,
    }));

    const visitorTypesData = data?.visitorManagementSiteSettings.visitorTypes?.map((item) => ({
      isDefault: item.isDefault,
      name: item.name,
      apppointmentTypes: item.appointmentTypes,
    }));
    const isValuesEqual = isEqual(visitorTypesFormValues, visitorTypesData);

    return !isValuesEqual || values.newVisitorType.length > 0 || values.newVisitorAppointmentType.length > 0;
  };

  const isColorsChanged = (values: GeneralSettingsFormValues) => {
    return (
      values.walletPassBackgroundColor !== initialFormValues.walletPassBackgroundColor ||
      values.walletPassForegroundColor !== initialFormValues.walletPassForegroundColor ||
      values.walletPassLabelColor !== initialFormValues.walletPassLabelColor
    );
  };

  return (
    <>
      <div className="page-container">
        <SubTopMenu btmPadding={false} topPadding={false}>
          <h1 className="font-weight-bold mb-2">{t("common.settings")}</h1>
        </SubTopMenu>
        <SubTopMenu btmPadding={false} topPadding={false} minHeight={false} alignItems="flex-end" sticky>
          <Menu />
        </SubTopMenu>
        <div className="d-flex flex-column p-7 main-container">
          <Text variant="heading" size="large" className="mb-6">
            {t("visitors.settings.manageGeneralSettings")}
          </Text>
          <hr className="mx-0 my-2 mb-6" />
          {!loading && (
            <div className="duration-select w-100">
              <Text variant="heading" size="small" className="mb-6">
                {t("visitors.appointments.appointmentsTab")}
              </Text>
              <Formik<GeneralSettingsFormValues>
                enableReinitialize
                validationSchema={getValidationSchema(t)}
                initialValues={initialFormValues}
                onSubmit={handleSubmit}
              >
                {({ isSubmitting, values, setFieldValue, resetForm, submitForm }) => (
                  <>
                    <EqForm.Group
                      label={t("visitors.settings.maximumCreationDuration")}
                      showTooltip={true}
                      tooltipText={t("visitors.settings.maximumCreationDurationTooltip")}
                    >
                      <div className="label-container">
                        <label className="label-item">
                          <Field
                            as={() => (
                              <input
                                name="maxAppointmentCreationMonthsDefault"
                                className="radio-input"
                                checked={!customMaxAppointmentCreationMonthsSelected}
                                onChange={(_) => {
                                  setCustomMaxAppointmentCreationMonthsSelected(false);
                                  setMaxAppointmentCreationMonths(
                                    Number.parseInt(defaultSiteSettingsMaxAppointmentCreationMonths),
                                  );
                                  void setFieldValue(
                                    "maxAppointmentCreationMonths",
                                    defaultSiteSettingsMaxAppointmentCreationMonths,
                                  );
                                }}
                                type="radio"
                              />
                            )}
                          ></Field>
                          <span>{t("common.defaultOption")}</span>
                        </label>
                        <label className="label-item">
                          <Field
                            as={() => (
                              <input
                                name="maxAppointmentCreationMonthsCustom"
                                className="radio-input"
                                checked={customMaxAppointmentCreationMonthsSelected}
                                onChange={() => {
                                  setCustomMaxAppointmentCreationMonthsSelected(true);
                                }}
                                type="radio"
                              />
                            )}
                          ></Field>
                          <span>{t("common.customOption")}</span>
                        </label>
                        <div className="d-flex flex-row">
                          <label className="custom-select-text mr-3">
                            {t("visitors.settings.maximumCreationDurationUpToNumberOfMonths")}
                          </label>
                          <div className="month-select">
                            <EqForm.Select
                              id="vmSettingsSiteMaxAppCreationMonthsSelect"
                              disabled={!customMaxAppointmentCreationMonthsSelected}
                              onChange={(e) => {
                                setMaxAppointmentCreationMonths(Number(e.target.value));
                                void setFieldValue("maxAppointmentCreationMonths", Number(e.target.value), false);
                              }}
                              value={
                                maxAppointmentCreationMonths?.toString() ??
                                defaultSiteSettingsMaxAppointmentCreationMonths
                              }
                            >
                              {customMaxAppointmentCreationMonthsOptions.map((option) => (
                                <option
                                  key={option.value}
                                  hidden={defaultSiteSettingsMaxAppointmentCreationMonths === option.value}
                                  value={option.value}
                                >
                                  {option.label}
                                </option>
                              ))}
                            </EqForm.Select>
                          </div>

                          <span className="custom-select-text ml-3">
                            {t("visitors.settings.maximumCreationDurationUpMonths")}
                          </span>
                        </div>
                      </div>
                    </EqForm.Group>
                    <EqForm.Group label={t("visitors.settings.mandatoryHostEmail")}>
                      <div className="mandatory-email">
                        <Field type="checkbox" id="isHostEmailMandatory" name="isHostEmailMandatory" />
                        <label htmlFor="isHostEmailMandatory">
                          {t("visitors.settings.mandatoryHostEmailCheckbox")}
                        </label>
                      </div>
                    </EqForm.Group>
                    <VisitorTypesTable isAddingType={isAddingType} setIsAddingType={setIsAddingType} />
                    <hr className="mx-0 my-6" />
                    <AccessPasses />
                    <ButtonTray>
                      <Button
                        variant="ghost"
                        size="md"
                        className="mr-5"
                        disabled={isSubmitting}
                        onClick={() => setShowModal(true)}
                      >
                        {t("common.cancel")}
                      </Button>
                      <Button
                        variant="primary"
                        size="md"
                        disabled={
                          loading ||
                          (!maxAppointmentCreationMonthsChanged(values) &&
                            !isValueChanged(values) &&
                            !isVisitorTypesChanged(values) &&
                            !isColorsChanged(values) &&
                            !isHostEmailMandatoryChanged(values))
                        }
                        onClick={() => {
                          submitForm().catch(showError);
                        }}
                      >
                        {isSubmitting && <ProgressCircle size="xs" className="mr-2" />}
                        {t("common.save")}
                      </Button>
                    </ButtonTray>
                    <Modal.Dialog centered show={showModal} title={t("common.areYouSure")} hideOnEsc size="sm">
                      <Modal.Header closeButton={false} noBorder />
                      <Modal.Body>{t("visitors.settings.cancelChangesBody")}</Modal.Body>
                      <Modal.Footer>
                        <Button variant="ghost" className="mr-2" onClick={() => setShowModal(false)}>
                          {t("visitors.appointmentForm.cancelNo")}
                        </Button>
                        <Button
                          variant="danger"
                          className="mr-2"
                          onClick={() => {
                            handleCancel();
                            resetForm();
                            setIsAddingType(false);
                          }}
                        >
                          {t("common.yesCancel")}
                        </Button>
                      </Modal.Footer>
                    </Modal.Dialog>
                  </>
                )}
              </Formik>
            </div>
          )}
        </div>
      </div>

      <style jsx>{`
        .page-container {
          height: 100%;
          width: 100%;
          background: ${theme.colors.white};
        }
        .main-container {
          max-width: 664px;
        }
        .label-container {
        }
        .label-item {
          display: flex;
          width: fit-content;
          margin-bottom: 12px;
        }
        .duration-select {
          width: 50%;
        }

        .mandatory-email {
          display: flex;
          align-items: center;
          gap: 8px;
        }

        .month-select {
          width: 68px;
        }
        .custom-select-text {
          align-self: center;
          color: rgba(102, 102, 102, 1);
        }
        hr {
          border: 0;
          border-top: 1px solid ${theme.colors.border};
        }
      `}</style>
    </>
  );
};
