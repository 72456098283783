import type { ReactNode } from "react";
import React, { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useRouter } from "next/router";

import { UrlParams } from "@equiem/lib";
import type { FilterValue } from "@equiem/react-admin-ui";
import { FilterOptionsModifier, useDebounced } from "@equiem/react-admin-ui";

// There is no real filters at this moment, but we can add them later
type CompanyFilterType = "name" | "building";

export type CompanyFiltersResult = Partial<Record<CompanyFilterType, FilterValue>>;

export type PredefinedTabFilters = "all";

export const companiesStatusTabs: PredefinedTabFilters[] = ["all"];

export interface CompaniesFilterContext {
  filters: CompanyFiltersResult;
  setFilters?: (filters: CompanyFiltersResult) => void;
  search?: string;
  setSearch?: (search: string) => void;
  selectedTab: PredefinedTabFilters;
  setSelectedTab?: (tab: PredefinedTabFilters) => void;
  filterBuildingUuids?: string[];
}

export const CompaniesFilterContext = createContext<CompaniesFilterContext>({
  filters: {},
  search: undefined,
  setSearch: undefined,
  selectedTab: companiesStatusTabs[0],
  setSelectedTab: undefined,
  setFilters: undefined,
});

export const CompaniesFilterProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const router = useRouter();
  const searchFromQueryParams = router.query.search as string | undefined;
  const { setParam, deleteParam } = useContext(UrlParams);
  const [filters, setFilters] = useState<CompanyFiltersResult>({});
  const [search, setSearch] = useState<string>(searchFromQueryParams ?? "");
  const [selectedTab, setSelectedTab] = useState<PredefinedTabFilters>(
    router.query.tab != null && router.query.tab.length > 0
      ? (router.query.tab as PredefinedTabFilters)
      : companiesStatusTabs[0],
  );

  const filterBuildingUuids = useMemo(() => {
    if (filters.building?.modifier === FilterOptionsModifier.empty) {
      return [];
    } else if (
      filters.building?.type === "options" &&
      filters.building.value != null &&
      filters.building.value.length > 0
    ) {
      return filters.building.value.map((v) => v.value);
    } else {
      return undefined;
    }
  }, [filters.building?.modifier, filters.building?.type, filters.building?.value]);

  const debouncedBuildingUuids = useDebounced(filterBuildingUuids, 500);

  // react on tab reset
  useEffect(() => {
    if (router.query.tab === undefined) {
      setSelectedTab(companiesStatusTabs[0]);
    }
  }, [router.query.tab]);

  // react on tab selection change
  useEffect(() => {
    if (router.query.tab !== selectedTab) {
      setParam("tab", selectedTab);
    }
  }, [selectedTab]);

  useEffect(() => {
    if (search.length > 0) {
      if (search !== router.query.search) {
        setParam("search", search);
      }
    } else if (router.query.search != null) {
      deleteParam("search");
    }
  }, [search]);

  return (
    <CompaniesFilterContext.Provider
      value={{
        filters,
        setFilters,
        search,
        setSearch,
        selectedTab,
        setSelectedTab,
        filterBuildingUuids: debouncedBuildingUuids,
      }}
    >
      {children}
    </CompaniesFilterContext.Provider>
  );
};
