import React from "react";

export const EquiemOne: React.FC<{ width?: string; height?: string }> = ({ width = "167", height = "20" }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 167 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M119.54 18.657C117.058 18.657 115.111 17.8295 113.65 16.1502C112.166 14.4952 111.436 12.3048 111.436 9.60324C111.436 6.92606 112.166 4.73563 113.65 3.05631C115.111 1.40132 117.058 0.549493 119.54 0.549493C121.974 0.549493 123.945 1.40132 125.43 3.05631C126.89 4.73563 127.645 6.92606 127.645 9.60324C127.645 12.3048 126.89 14.4952 125.43 16.1502C123.945 17.8295 121.974 18.657 119.54 18.657ZM114.746 15.201C115.914 16.6369 117.52 17.3427 119.54 17.3427C121.56 17.3427 123.167 16.6369 124.359 15.201C125.527 13.8137 126.136 11.9397 126.136 9.60324C126.136 7.29113 125.527 5.4171 124.359 3.98116C123.142 2.56955 121.536 1.86375 119.54 1.86375C117.52 1.86375 115.914 2.56955 114.746 3.98116C113.529 5.44144 112.945 7.31547 112.945 9.60324C112.945 11.9154 113.529 13.7894 114.746 15.201ZM142.107 10.2117V0.938902H143.543V18.2676H141.767L134.368 7.26679C133.005 5.22239 132.056 3.6891 131.52 2.71558C131.545 4.83299 131.569 6.92606 131.569 8.99479V18.2676H130.133V0.938902H131.91L139.308 11.9397C140.671 14.0084 141.621 15.5174 142.156 16.4909C142.107 14.3978 142.107 12.3048 142.107 10.2117ZM158.381 0.938902V2.25315H148.135V8.5567H157.408V9.87096H148.135V16.9533H158.381V18.2676H146.699V0.938902H158.381Z"
        fill="#363640"
      />
      <g clipPath="url(#clip0_1_3191)">
        <path
          d="M3.69934 8.43919H11.8131V11.765H3.69934V15.2942H12.8595V18.62H0V1.35522H12.8595V4.68107H3.69934V8.43817V8.43919Z"
          fill="#363640"
        />
        <path
          d="M56.1794 10.7742C56.1794 15.9277 53.0662 18.9999 48.3205 18.9999C43.5748 18.9999 40.5132 15.9277 40.5132 10.7742V1.35522H44.1867V10.7998C44.1867 13.6687 45.845 15.5735 48.3205 15.5735C50.796 15.5735 52.5048 13.6698 52.5048 10.7998V1.35522H56.1794V10.7742Z"
          fill="#363640"
        />
        <path d="M65.6202 18.62H61.9209V1.35522H65.6202V18.619V18.62Z" fill="#363640" />
        <path
          d="M75.29 8.43919H83.4037V11.765H75.29V15.2942H84.4491V18.62H71.5896V1.35522H84.4491V4.68107H75.29V8.43817V8.43919Z"
          fill="#363640"
        />
        <path
          d="M108 18.62H104.301V9.2514L99.9635 16.0304H97.7945V16.0047L93.4317 9.17542L93.4822 18.62H89.7571V1.35522H92.4101L98.8904 11.5104L105.372 1.35522H108V18.619V18.62Z"
          fill="#363640"
        />
        <path
          d="M29.8145 10H26.3586C26.3586 14.9708 30.4078 19 35.4032 19V15.5612C32.3168 15.5612 29.8145 13.0712 29.8145 10Z"
          fill="#363640"
        />
        <path
          d="M26.3588 15.5612C23.2724 15.5612 20.77 13.0712 20.77 10C20.77 6.92881 23.2724 4.43879 26.3588 4.43879C29.4451 4.43879 31.9475 6.92881 31.9475 10H35.4033C35.4033 5.02921 31.3531 1 26.3588 1C21.3644 1 17.3142 5.02921 17.3142 10C17.3142 14.9708 21.3634 19 26.3588 19V15.5612Z"
          fill="#363640"
        />
      </g>
      <defs>
        <clipPath id="clip0_1_3191">
          <rect width="108" height="18" fill="white" transform="translate(0 1)" />
        </clipPath>
      </defs>
    </svg>
  );
};
