import { useTranslation } from "@equiem/localisation-eq1";
import { Form, ProgressCircle, useToast } from "@equiem/react-admin-ui";
import React, { useCallback, useState } from "react";
import type { BookingSettingFragmentFragment } from "../../../generated/gateway-client";
import { useUpdateBookingPaymentAdjustmentMaxDaysMutation } from "../../../generated/gateway-client";

interface P {
  setting?: BookingSettingFragmentFragment | null;
}
export const PaymentAdjustmentDayMax: React.FC<P> = ({ setting }) => {
  const { t } = useTranslation();
  const [days, setDays] = useState(setting?.paymentAdjustmentMaxDays);
  const [mutation, { loading }] = useUpdateBookingPaymentAdjustmentMaxDaysMutation();
  const toast = useToast();

  const onBlur = useCallback(async () => {
    try {
      if (days == null) {
        throw new Error(t("common.required"));
      }
      await mutation({ variables: { days } });
    } catch (e: unknown) {
      console.log(e);
      toast.negative(e instanceof Error ? e.message : t("common.unknownErrorMessage"));
    }
  }, [days, mutation, t, toast]);

  return (
    <Form.Group
      label={t("bookings.settings.paymentAdjustmentMax")}
      showTooltip
      tooltipText={t("bookings.settings.paymentAdjustmentMaxHelp")}
    >
      <>
        <Form.Input
          type="number"
          disabled={loading}
          value={days}
          onChange={(e) => {
            setDays(Number(e.currentTarget.value));
          }}
          onBlur={() => {
            onBlur().catch(console.log);
          }}
          min={1}
          max={9999}
          style={{ width: "100px" }}
        />
        {loading && <ProgressCircle mode="indeterminate" size="xs" className="ml-3" />}
      </>
    </Form.Group>
  );
};
