import type { TFunction } from "@equiem/localisation-eq1";

import { CancellingUserRole } from "../../../generated/gateway-client";

export const getCancelledMessage = (cancelledByRole: CancellingUserRole | null | undefined, t: TFunction) => {
  switch (cancelledByRole) {
    case CancellingUserRole.WorkplaceManager:
      return t("bookings.operations.cancelledByWorkplaceManager");
    case CancellingUserRole.Host:
      return t("bookings.operations.cancelledByHost");
    case CancellingUserRole.System:
      return t("bookings.operations.cancelledBySystem");
    case CancellingUserRole.User:
      return t("bookings.operations.cancelledByUser");
    default:
      return t("bookings.operations.cancelled");
  }
};
