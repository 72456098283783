import { useCompanyDetailsQuery } from "../../../generated/settings-client";

export function useCompanyDetails({ companyUuid, siteUuid }: { companyUuid?: string; siteUuid: string }) {
  const {
    loading: companyLoading,
    data: companyData,
    refetch: companyRefetch,
  } = useCompanyDetailsQuery({
    fetchPolicy: "cache-and-network",
    variables: { uuid: companyUuid ?? "", siteUuid },
    skip: companyUuid == null,
  });

  return {
    companyLoading,
    companyData: companyData?.companyV2,
    companyRefetch,
  };
}
