import React, { useContext, useMemo } from "react";
import { useRouter } from "next/router";

import { CurrentRole, Role, useSiteContext } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import type { TabItem } from "@equiem/react-admin-ui";
import { Tabs } from "@equiem/react-admin-ui";

import { useSiteDetailsQuery } from "../generated/settings-client";

import { OwnCompanySettings } from "./OwnCompanySettings";

export const SettingsMenu: React.FC = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const { currentRole } = useContext(CurrentRole);
  const site = useSiteContext();

  const siteData = useSiteDetailsQuery({
    variables: {
      uuid: site.uuid,
    },
  });

  const tabs: TabItem[] = useMemo(() => {
    const _tabs = [
      {
        title: t("settings.users"),
        key: "/settings/users",
      },
    ];

    if (currentRole === Role.PropertyManager || currentRole === Role.FlexManager) {
      _tabs.push({
        title: t("settings.building"),
        key: "/settings/building",
      });
      _tabs.push({
        title: t("settings.companies"),
        key: "/settings/companies",
      });
    }

    if (
      currentRole === Role.PropertyManager &&
      siteData.data?.destination.settings.registration.residentialSignupEnabled === true
    ) {
      _tabs.push({
        title: t("settings.apartments"),
        key: "/settings/apartments",
      });
    }

    return _tabs;
  }, [currentRole, siteData, t]);

  const handleTabSelect = (key: string) => {
    void router.replace(key);
  };

  return (
    <div className="d-flex w-100">
      <Tabs items={tabs} selected={router.asPath} tabLinks onSelect={handleTabSelect} />
      {currentRole === Role.WorkplaceManager ? <OwnCompanySettings /> : null}
    </div>
  );
};
