import React, { useMemo } from "react";
import { Field } from "formik";
import { omit, pick } from "lodash";

import type { TFunction } from "@equiem/localisation-eq1";
import { Form as EqForm } from "@equiem/react-admin-ui";

import { UserNotificationFieldTooltipWrapper } from "../components/UserNotificationFieldTooltipWrapper";
import type { CurrentUserRoleContext } from "../contexts/CurrentUserRoleContext";
import type { FormValues } from "../types";

const allEndUserFields: Array<keyof FormValues["notificationPreferences"]> = [
  "endUserAwaitingApprovalEmail",
  "endUserCancellationEmail",
  "endUserChargedAdjustmentEmail",
  "endUserConfirmationEmail",
  "endUserDeclinedBookingEmail",
  "endUserReminderEmail",
  "endUserUpdateEmail",
];
const allAdminFields: Array<keyof FormValues["notificationPreferences"]> = [
  "adminConfirmationEmail",
  "adminUpdateEmail",
  "adminApprovalRequestEmail",
  "adminDeclinedBookingEmail",
  "adminCancellationEmail",
];
const propertyManagerFields: Array<keyof FormValues["notificationPreferences"]> = allAdminFields;
const workplaceManagerFields: Array<keyof FormValues["notificationPreferences"]> = allAdminFields;
const bookingManagerFields: Array<keyof FormValues["notificationPreferences"]> = allAdminFields;
const observerFields: Array<keyof FormValues["notificationPreferences"]> = [
  "adminConfirmationEmail",
  "adminUpdateEmail",
  "adminCancellationEmail",
];

const AllAdminFormFields = (t: TFunction) => (
  <>
    <UserNotificationFieldTooltipWrapper title={t("settings.notifications.toolTips.bookings.admin.confirmation")}>
      <Field
        className="booking-preference-checkbox"
        as={EqForm.Checkbox}
        name="notificationPreferences.adminConfirmationEmail"
        label={t("settings.notifications.bookingPreferences.adminConfirmationEmail")}
      />
    </UserNotificationFieldTooltipWrapper>
    <UserNotificationFieldTooltipWrapper title={t("settings.notifications.toolTips.bookings.admin.update")}>
      <Field
        className="booking-preference-checkbox"
        as={EqForm.Checkbox}
        name="notificationPreferences.adminUpdateEmail"
        label={t("settings.notifications.bookingPreferences.adminUpdateEmail")}
      />
    </UserNotificationFieldTooltipWrapper>
    <UserNotificationFieldTooltipWrapper title={t("settings.notifications.toolTips.bookings.admin.cancellation")}>
      <Field
        className="booking-preference-checkbox"
        as={EqForm.Checkbox}
        name="notificationPreferences.adminCancellationEmail"
        label={t("settings.notifications.bookingPreferences.adminCancellationEmail")}
      />
    </UserNotificationFieldTooltipWrapper>
    <UserNotificationFieldTooltipWrapper title={t("settings.notifications.toolTips.bookings.admin.approvalRequest")}>
      <Field
        className="booking-preference-checkbox"
        as={EqForm.Checkbox}
        name="notificationPreferences.adminApprovalRequestEmail"
        label={t("settings.notifications.bookingPreferences.adminApprovalRequestEmail")}
      />
    </UserNotificationFieldTooltipWrapper>
    <UserNotificationFieldTooltipWrapper title={t("settings.notifications.toolTips.bookings.admin.decline")}>
      <Field
        className="booking-preference-checkbox"
        as={EqForm.Checkbox}
        name="notificationPreferences.adminDeclinedBookingEmail"
        label={t("settings.notifications.bookingPreferences.adminDeclinedBookingEmail")}
      />
    </UserNotificationFieldTooltipWrapper>
  </>
);

const PropertyManagerFormFields = AllAdminFormFields;
const BookingManagerFormFields = AllAdminFormFields;
const WorkplaceManagerFormFields = AllAdminFormFields;
const ObserverFormFields = (t: TFunction) => (
  <>
    <UserNotificationFieldTooltipWrapper title={t("settings.notifications.toolTips.bookings.admin.confirmation")}>
      <Field
        className="booking-preference-checkbox"
        as={EqForm.Checkbox}
        name="notificationPreferences.adminConfirmationEmail"
        label={t("settings.notifications.bookingPreferences.adminConfirmationEmail")}
      />
    </UserNotificationFieldTooltipWrapper>
    <UserNotificationFieldTooltipWrapper title={t("settings.notifications.toolTips.bookings.admin.update")}>
      <Field
        className="booking-preference-checkbox"
        as={EqForm.Checkbox}
        name="notificationPreferences.adminUpdateEmail"
        label={t("settings.notifications.bookingPreferences.adminUpdateEmail")}
      />
    </UserNotificationFieldTooltipWrapper>
    <UserNotificationFieldTooltipWrapper title={t("settings.notifications.toolTips.bookings.admin.cancellation")}>
      <Field
        className="booking-preference-checkbox"
        as={EqForm.Checkbox}
        name="notificationPreferences.adminCancellationEmail"
        label={t("settings.notifications.bookingPreferences.adminCancellationEmail")}
      />
    </UserNotificationFieldTooltipWrapper>
  </>
);

const getAdminFields = (currentRoleContext: CurrentUserRoleContext) => {
  const {
    canManageCurrentSite,
    canManageCurrentSiteCompany,
    canObserveBuildingCompanyBookings,
    canManageBuildingCompanyBookings,
  } = currentRoleContext;

  if (canManageCurrentSite) {
    return {
      adminFields: propertyManagerFields,
      Components: PropertyManagerFormFields,
    };
  }
  if (canManageCurrentSiteCompany) {
    return {
      adminFields: bookingManagerFields,
      Components: BookingManagerFormFields,
    };
  }
  if (canManageBuildingCompanyBookings) {
    return {
      adminFields: workplaceManagerFields,
      Components: WorkplaceManagerFormFields,
    };
  }
  if (canObserveBuildingCompanyBookings) {
    return {
      adminFields: observerFields,
      Components: ObserverFormFields,
    };
  }

  return {
    adminFields: [],
    Components: (_t: TFunction) => null,
  };
};

export const useBookingNotificationPreferencesDisplay = (
  currentRoleContext: CurrentUserRoleContext,
  values: FormValues,
) => {
  const {
    canManageCurrentSite,
    canManageCurrentSiteCompany,
    canObserveBuildingCompanyBookings,
    canManageBuildingCompanyBookings,
  } = currentRoleContext;
  const showAdminPreferences =
    canManageCurrentSite ||
    canManageCurrentSiteCompany ||
    canObserveBuildingCompanyBookings ||
    canManageBuildingCompanyBookings;
  const { adminFields, Components } = getAdminFields(currentRoleContext);

  const optionsToBeShown: Partial<FormValues["notificationPreferences"]> = useMemo(
    () =>
      showAdminPreferences
        ? {
            ...pick(values.notificationPreferences, allEndUserFields),
            ...pick(values.notificationPreferences, adminFields),
          }
        : omit(values.notificationPreferences, allAdminFields),
    [values.notificationPreferences, showAdminPreferences, adminFields],
  );
  const allSelected = useMemo(() => Object.values(optionsToBeShown).every((pref) => pref), [optionsToBeShown]);
  const allDeselected = useMemo(() => Object.values(optionsToBeShown).every((pref) => !pref), [optionsToBeShown]);

  const isAdminPreferenceDisplayed = (preference: keyof FormValues["notificationPreferences"]) =>
    Object.keys(optionsToBeShown).includes(preference);

  return {
    allSelected,
    allDeselected,
    showAdminPreferences,
    Components,
    isAdminPreferenceDisplayed,
  };
};
