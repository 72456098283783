import type { FC, MutableRefObject } from "react";
import React, { useCallback, useRef, useEffect } from "react";
import type { Stack, StackItem, StickyElementOptions, StickyRef } from "./StickyContext";
import { StickyContext } from "./StickyContext";

const getMarginHeight = (el: Element) => {
  try {
    const styles = window.getComputedStyle(el);
    return parseFloat(styles.marginTop) + parseFloat(styles.marginBottom);
  } catch (e: unknown) {
    // can't really recover.
    return 0;
  }
};

export const nextTop = (stack: Stack, { shouldStack }: StickyElementOptions) => {
  const lastNonStackingEl = stack.filter((item) => !item.stack).pop();

  if (lastNonStackingEl?.ref?.current == null) {
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    return -1;
  }

  if (shouldStack) {
    return lastNonStackingEl.top;
  } else {
    const innerHeight = lastNonStackingEl.ref.current.getBoundingClientRect().height;
    const marginHeight = getMarginHeight(lastNonStackingEl.ref.current);
    return lastNonStackingEl.top + innerHeight + marginHeight;
  }
};

export const StickyContainerWithRef: FC<{
  useIframeViewport?: boolean;
  children?: React.ReactNode;
  ref?: MutableRefObject<HTMLElement | null>;
}> = ({ children, useIframeViewport = false, ref }) => {
  const stack = useRef<Stack>([]);

  useEffect(() => {
    if (useIframeViewport) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
      const refEl = (window.frameElement as any)?.contentDocument ?? undefined;

      if (ref != null) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        ref.current = refEl;
      }
    }
  }, [useIframeViewport, ref]);

  const push = useCallback(
    (itemRef: StickyRef, opts: StickyElementOptions) => {
      const item: StackItem = {
        ref: itemRef,
        top: nextTop(stack.current, opts),
        stack: opts.shouldStack,
      };
      stack.current.push(item);

      return item;
    },
    [stack],
  );

  const pop = useCallback(
    (itemRef: StickyRef) => {
      const index = stack.current.findIndex((item) => item.ref === itemRef);
      if (index >= 0) {
        stack.current.splice(index, 1);
      }
    },
    [stack],
  );

  return (
    <StickyContext.Provider
      value={{
        push,
        pop,
        stack,
        rootRef: ref ?? null,
      }}
    >
      {children}
    </StickyContext.Provider>
  );
};

export const StickyContainer = <T extends HTMLElement>({
  children,
  useIframeViewport,
}: {
  children: React.ReactNode | ((args: { ref: MutableRefObject<T | null> }) => React.ReactElement);
  useIframeViewport?: boolean;
}) => {
  const rootRef = useRef<T | null>(null);
  return (
    <StickyContainerWithRef ref={rootRef} useIframeViewport={useIframeViewport}>
      {typeof children === "function" ? children({ ref: rootRef }) : children}
    </StickyContainerWithRef>
  );
};
