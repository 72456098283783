import React from "react";

export const ShortcutIcon = () => {
  return (
    <>
      <div className="shimmer mb-3"></div>
      <style jsx>{`
        div {
          width: 216px;
          height: 24px;
          border-radius: 4px;
        }
      `}</style>
    </>
  );
};
