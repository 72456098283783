import { useCallback } from "react";
import { useTranslation, formatters } from "@equiem/localisation-eq1";

export const useSummaryForUserTypeFilter = (
  commercialSelected: boolean,
  visitorsSelected: boolean,
  residentialSelected: boolean,
): [() => string | null, () => number] => {
  const { t, i18n } = useTranslation();

  const activeFilters = useCallback(() => {
    const active = [
      commercialSelected ? t("segmentation.userType.commercial") : null,
      residentialSelected ? t("segmentation.userType.residential") : null,
      visitorsSelected ? t("segmentation.userType.visitors") : null,
    ];

    return active.filter((a): a is "string" => a !== null);
  }, [commercialSelected, visitorsSelected, residentialSelected, t]);

  const count = useCallback(() => {
    return activeFilters().length;
  }, [activeFilters]);

  const summary = useCallback(() => {
    const active = activeFilters();

    if (active.length === 0) {
      return null;
    }

    return formatters.list(active, i18n.language);
  }, [activeFilters, i18n.language]);

  return [summary, count];
};
