import type { TransformedSite } from "@equiem/lib";

export const fetchSiteByHost = async (host: string) => {
  const hostname = host.split(":")[0];
  const fetchSiteUrl = new URL("/api/site", `https://${host}`);
  fetchSiteUrl.searchParams.set("host", hostname);
  const start = Date.now();
  try {
    return (await (await fetch(fetchSiteUrl)).json()) as TransformedSite;
  } finally {
    console.log("Fetched", { fetchSiteUrl: fetchSiteUrl.toString(), tookMs: Date.now() - start });
  }
};
