import React from "react";
import { useRouter } from "next/router";

import { useSaferFormikContext } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import { Button, MemberCard } from "@equiem/react-admin-ui";

import { useVisitorReceptionQuery } from "../../../../generated/visitors-client";
import { useWarningLimitReachVisitorsAlert } from "../../../../hooks/useWarningLimitReachVisitorsAlert";
import type { FormValues, FormVisitor } from "../../../appointment/types";
import { AddVisitorWidget } from "../../../appointment/widgets/VisitorWidget";

interface Props {
  isActive?: boolean;
  handleStep: (step: number) => void;
}

export const WalkInStep1: React.FC<Props> = ({ isActive, handleStep }) => {
  const { t } = useTranslation();
  const { values } = useSaferFormikContext<FormValues>();
  const router = useRouter();
  const { data: receptionData } = useVisitorReceptionQuery({
    variables: { uuid: router.query.uuid as string },
  });
  const { isDisabled } = useWarningLimitReachVisitorsAlert<FormVisitor>(values.visitors);

  return (
    <div
      className={`step-container pr-0 pt-5 pb-5 mt-5 d-flex ${
        isActive !== true ? "flex-row justify-content-between pr-5" : "flex-column  step-shadow"
      }`}
    >
      <div className={`px-7 pt-2 step-head ${isActive === true ? "mb-7" : ""}`}>
        <p className="step-title mb-1 mt-0">{t("visitors.walkIn.step", { step: 1 })}</p>
        <h2>{t("visitors.walkIn.addVisitors")}</h2>
      </div>
      {isActive === true ? (
        <div>
          <hr className="custom-liner" />
          <AddVisitorWidget mode="walkin" />
          <hr className="custom-liner" />
          <div className="footer d-flex px-6 pt-5 justify-content-end">
            <Button
              variant="secondary"
              size="md"
              onClick={() => handleStep(2)}
              disabled={values.visitors.length === 0 || isDisabled}
            >
              {t("visitors.walkIn.nextStep")}
            </Button>
          </div>
        </div>
      ) : (
        <div className="d-flex flex-column preview-container">
          {values.visitors.length > 0 ? (
            values.visitors.map((visitor, index) => (
              <MemberCard.Card
                key={`visitor_${index}`}
                firstName={visitor.firstName}
                lastName={visitor.lastName}
                email={visitor.email ?? ""}
                companyName={visitor.companyName ?? ""}
                showBorder={false}
                buttonVariant="outline"
                buttonText={t("common.change")}
                showButton={true}
                onButtonClick={() => {
                  handleStep(1);
                }}
                showCardId={receptionData?.visitorReception.enableAccessCard === true}
                cardIdDisabled
                defaultCardId={visitor.cardId}
              />
            ))
          ) : (
            <p className="step-empty ml-6">{t("visitors.walkIn.emptyVisitors")}</p>
          )}
        </div>
      )}
      <style jsx>
        {`
          h2 {
            font-weight: 600;
          }
          .step-container {
            border: 1px solid #e6e6e6;
            border-radius: 8px;
          }
          .step-shadow {
            box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
          }
          .step-title {
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
            text-transform: uppercase;
            color: rgba(0, 0, 0, 0.6);
          }
          .custom-liner {
            margin: 0px;
            border-top: 1px solid rgba(0, 0, 0, 0.1);
          }
          .step-head {
            flex: 4;
          }
          .preview-container {
            position: relative;
            border: 1px solid #e6e6e6;
            border-radius: 4px;
            flex: 3;
          }
        `}
      </style>
    </div>
  );
};
