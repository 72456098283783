import React, { useEffect, useContext } from "react";
import { useFormikContext } from "formik";
import { Site } from "@equiem/lib";
import { Text, useToast } from "@equiem/react-admin-ui";

import type { FormValues } from "../../lib/formValidation";
import { useTranslation } from "@equiem/localisation-eq1";

export const ResourceMultiSiteWarning: React.FC = () => {
  const { t } = useTranslation();
  const fm = useFormikContext<FormValues>();
  const site = useContext(Site);
  const toast = useToast();

  const isMultiSite = fm.values.siteAudiences.some((audience) => audience.site !== site.uuid);

  useEffect(() => {
    if (isMultiSite) {
      const msg = toast.positive(
        <Text variant="text" size="small" className="resource-multi-site-warning">
          <b>{t("common.note")}:</b> {t("bookings.resources.multiSiteWarning")}
        </Text>,
      );
      return msg.remove;
    }
    return () => undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMultiSite]);

  return null;
};
