import type { ApolloQueryResult } from "@apollo/client";
import { ButtonTray } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import { Button, Dropdown, ProgressCircle, useTheme } from "@equiem/react-admin-ui";
import { RiDeleteBinLine, RiEyeOffLine, RiMoreLine } from "@equiem/react-admin-ui/icons";
import { useFormikContext } from "formik";
import { useRouter } from "next/router";
import React, { useEffect, useMemo, useState } from "react";
import { DeleteResourceModal } from "../delete/DeleteResourceModal";
import type { BookableResourceEditQuery } from "../../../../generated/gateway-client";
import { BookableResourceStatus } from "../../../../generated/gateway-client";
import { useCancelResourceDeletion } from "../../../../hooks/useCancelResourceDeletion";
import type { FormValues } from "../../../../lib/formValidation";
import { resourceCreateAndEditFormvalidationSchema } from "../../../../lib/validationSchema";

interface Props {
  resourceUuid?: string;
  resourceName?: string;
  loading: boolean;
  triggerRefresh?: () => Promise<ApolloQueryResult<BookableResourceEditQuery>>;
}

export const ResourceCreateAndEditFormButtonTray: React.FC<Props> = ({
  resourceUuid,
  resourceName,
  loading,
  triggerRefresh,
}) => {
  const { t } = useTranslation();
  const fm = useFormikContext<FormValues>();
  const router = useRouter();
  const { breakpoints, colors, spacers } = useTheme(true);
  const [formInvalid, setFormInvalid] = useState(true);

  useEffect(() => {
    if (Object.keys(fm.errors).length > 0) {
      setFormInvalid(true);
      return;
    }

    async function validate() {
      const schema = resourceCreateAndEditFormvalidationSchema(t);
      const valid = await schema.isValid(fm.values);
      setFormInvalid(!valid);
    }
    validate().catch(console.log);
  }, [fm.errors, fm.values, setFormInvalid]);

  const cancelDeletion = useCancelResourceDeletion({
    uuid: resourceUuid ?? "",
    name: resourceName ?? "",
    onSuccess: triggerRefresh ?? (() => undefined),
  });
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);

  const isUserEditable = useMemo(() => {
    return (
      fm.values.status == null ||
      [BookableResourceStatus.Draft, BookableResourceStatus.Published].includes(fm.values.status)
    );
  }, [fm.values.status]);

  const isDeletable = useMemo(() => {
    return (
      fm.values.status != null &&
      ![BookableResourceStatus.PendingDeletion, BookableResourceStatus.Deleted].includes(fm.values.status)
    );
  }, [fm.values.status]);

  return (
    <>
      <ButtonTray>
        <div className="primary-actions">
          <Button
            variant="ghost"
            disabled={fm.isSubmitting}
            onClick={() => {
              router.back();
            }}
          >
            {t("common.cancel")}
          </Button>
          {fm.isSubmitting ? (
            <Button disabled={true}>
              <ProgressCircle size="xs" />
              {t("bookings.resources.saveAndPublish")}
            </Button>
          ) : (
            <Button
              disabled={formInvalid || fm.isSubmitting || !isUserEditable || loading}
              onClick={() => {
                fm.setFieldValue("status", BookableResourceStatus.Published).catch(console.error);
                fm.submitForm().catch(console.log);
              }}
            >
              {t("bookings.resources.saveAndPublish")}
            </Button>
          )}
          <Dropdown.Icon icon={RiMoreLine} size="md" placement="left-end">
            {isUserEditable && (
              <Dropdown.Item
                disabled={formInvalid || fm.isSubmitting}
                onClick={() => {
                  fm.setFieldValue("status", BookableResourceStatus.Draft).catch(console.error);
                  fm.submitForm().catch(console.log);
                }}
              >
                <RiEyeOffLine size="16" color={colors.primary} className="mr-3" />
                {t("bookings.resources.saveAndDraft")}
              </Dropdown.Item>
            )}
            {isDeletable && (
              <Dropdown.Item onClick={() => setDeleteModalVisible(true)}>
                <RiDeleteBinLine size="16" color={colors.danger} className="mr-3" />
                {t("bookings.resources.scheduleDeletion")}
              </Dropdown.Item>
            )}
            {fm.values.status === BookableResourceStatus.PendingDeletion && (
              <Dropdown.Item
                onClick={() => {
                  void cancelDeletion();
                }}
              >
                <RiDeleteBinLine size="16" color={colors.primary} className="mr-3" />
                {t("bookings.resources.cancelDeletion")}
              </Dropdown.Item>
            )}
          </Dropdown.Icon>
        </div>
        {resourceUuid != null && (
          <DeleteResourceModal
            resourceUuid={resourceUuid}
            visible={deleteModalVisible}
            onClose={() => {
              setDeleteModalVisible(false);
              void triggerRefresh?.();
            }}
          />
        )}
      </ButtonTray>
      <style jsx>{`
        .primary-actions {
          display: flex;
          justify-content: flex-end;
          gap: ${spacers.s3};
          width: 100%;
        }
        .primary-actions :global(.nav-item) {
          margin-bottom: ${spacers.s0};
        }
        @media screen and (max-width: ${breakpoints.md}px) {
          .primary-actions {
            justify-content: space-between;
          }
          .primary-actions :global(button) {
            flex: 1;
          }
        }
      `}</style>
    </>
  );
};
