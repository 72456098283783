import { useContext } from "react";
import { Button, Modal } from "@equiem/react-admin-ui";
import { RiShareBoxLine } from "react-icons/ri";
import { useTranslation } from "@equiem/localisation-eq1";
import { ModalContent } from "./ContentProvider";
import { NewsPostForm, type Props as NewsPost } from "./new_news_post/Form";
import { EventPostForm, type Props as EventPost } from "./new_event_post/Form";
import { BuildingInfoPostForm, type Props as BuildingInfoPost } from "./building_info_post/Form";

interface P {
  post: NewsPost["post"] | EventPost["post"] | BuildingInfoPost["post"] | null;
}

export const PostModal: React.FC<P> = ({ post }) => {
  const { t } = useTranslation();
  const { modalShown, contentUrl, showSiteLink, isDraft, modalBodyTitle, modalTitle, contentSaved, closeModal } =
    useContext(ModalContent);

  return (
    <Modal.Dialog
      show={modalShown}
      title={modalTitle}
      onHide={closeModal}
      hideOnEsc={true}
      hideOnClick={false}
      scrollable={!contentSaved}
      key={contentSaved ? "m1" : "m2"}
      size={contentSaved ? "sm" : "lg"}
    >
      <Modal.Header intro={modalBodyTitle} closeButton={contentSaved} noBorder={true} />
      {post?.type === "news_post" && <NewsPostForm post={post} />}
      {post?.type === "event_post" && <EventPostForm post={post} />}
      {post?.type === "building_info_post" && <BuildingInfoPostForm post={post} />}
      {contentSaved && (
        <Modal.Body>
          {isDraft ? (
            <div>{t("home.widgets.postInDraft")}</div>
          ) : (
            <>
              {showSiteLink ? (
                <div>{t("home.widgets.postInPublished")}</div>
              ) : (
                <div>{t("home.widgets.postInApprovalQueue")}</div>
              )}
            </>
          )}
        </Modal.Body>
      )}
      {contentSaved && (
        <Modal.Footer>
          {showSiteLink && contentUrl != null && (
            <a href={contentUrl} target="_blank" rel="noreferrer" style={{ textDecoration: "none" }}>
              <Button variant="ghost" className="mr-3">
                {t("home.widgets.viewOnSite")} <RiShareBoxLine size={12} />
              </Button>
            </a>
          )}
          <Button variant="primary" onClick={closeModal}>
            {t("common.done")}
          </Button>
        </Modal.Footer>
      )}
    </Modal.Dialog>
  );
};
