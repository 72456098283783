import { Form as EqForm, remainingCharacters, Modal, Button } from "@equiem/react-admin-ui";
import type { FormikHelpers } from "formik";
import { Field, FieldArray, Form, Formik } from "formik";
import type { FC } from "react";
import { useCallback, useContext, useMemo, useState } from "react";
import { Session } from "@equiem/lib/context/SessionContext";
import type { EditBuildingInfoPostMutationVariables, EquiemOnePostAudience } from "../generated/iris-client";
import {
  MyPostsDocument,
  useCreateBuildingInfoPostMutation,
  useEditBuildingInfoPostMutation,
} from "../generated/iris-client";
import { RiTeamFill, RiBuilding4Fill } from "@equiem/react-admin-ui/icons";
import { ImageUploader } from "../lib/ImageUploader";
import * as yup from "yup";
import { Role, Site } from "@equiem/lib";
import { getBuildingInfoPostUrl } from "../hooks/useContentUrl";
import { DateTime } from "luxon";
import { dateTimeLocaleInputDisplayFormat } from "../lib/dateFormats";
import { FormFooter } from "../FormFooter";
import { useModalDescription } from "../lib/useModalDescription";
import { FileUploader } from "../lib/FileUploader";
import { ModalContent } from "../ContentProvider";
import { RelatedInformation } from "./RelatedInformation";
import { useTranslation } from "@equiem/localisation-eq1";
import type { FormValues } from "./FormValues";
import dynamic from "next/dynamic";

export type BuildingInfoPost = { type: "building_info_post" };

export type EditBuildingInfoPostInput = BuildingInfoPost &
  Pick<
    EditBuildingInfoPostMutationVariables["input"],
    "id" | "title" | "excerpt" | "body" | "image" | "downloads" | "links"
  > & {
    postAudience?: EquiemOnePostAudience | undefined;
    publishAt?: string;
    draft: boolean;
  };

const CKEditorNoSSR = dynamic(async () => import("../lib/CustomCkEditor"), {
  ssr: false,
});

const MAX_TITLE_LENGTH = 100;
const MAX_EXCERPT_LENGTH = 150;
const validationSchema = () =>
  yup.object().shape({
    title: yup.string().max(MAX_TITLE_LENGTH).required(),
    excerpt: yup.string().max(MAX_EXCERPT_LENGTH).required(),
    publishAt: yup.string().required(),
    body: yup.string().required(),
    image: yup.array().of(
      yup.object().shape({
        alt: yup.string().required(),
        key: yup.string().required(),
        title: yup.string().required(),
        thumbnail_key: yup.string().required(),
      }),
    ),
    links: yup.array().of(
      yup.object().shape({
        url: yup.string().required(),
        title: yup.string().required(),
      }),
    ),
    downloads: yup.array().of(
      yup.object().shape({
        key: yup.string().required(),
        title: yup.string().required(),
      }),
    ),
  });

export interface Props {
  post: EditBuildingInfoPostInput | BuildingInfoPost;
}
export const BuildingInfoPostForm: FC<Props> = ({ post }) => {
  const { t } = useTranslation();
  const session = useContext(Session);
  const { webAppUrl, timezone, name: siteName } = useContext(Site);
  const now = DateTime.local({ zone: timezone });

  const {
    closeModal,
    contentSaved,
    currentRole,
    setContentSaved,
    setContentUrl,
    setIsDraft,
    setModalBodyTitle,
    setModalTitle,
    setShowSiteLink,
  } = useContext(ModalContent);

  const [saving, setSaving] = useState(false);
  const [isDraft, _setIsDraft] = useState(false);
  const { modalDescription } = useModalDescription();

  const [createBuildingInfo] = useCreateBuildingInfoPostMutation({
    client: session.irisClient?.cortex,
  });
  const [editBuildingInfo] = useEditBuildingInfoPostMutation({
    client: session.irisClient?.cortex,
  });

  const buildingInfo = useMemo(() => ("id" in post && post.id != null ? post : null), [post]);
  const isNewPost = buildingInfo == null;

  const audienceWithFallback = buildingInfo != null ? buildingInfo.postAudience ?? "WORKPLACE" : "WORKPLACE";
  const [selectedPostAudience, setSelectedPostAudience] = useState(audienceWithFallback);
  const modalTitle = useMemo(() => {
    if (isDraft) {
      return t("common.saved");
    }
    if (selectedPostAudience === "WORKPLACE") {
      return t("home.widgets.published");
    }
    return t("common.submitted");
  }, [t, selectedPostAudience, isDraft]);

  const modalDesc = useMemo(() => {
    return modalDescription(selectedPostAudience, isDraft, buildingInfo != null);
  }, [selectedPostAudience, isDraft, buildingInfo, modalDescription]);

  const toggleDraft = useCallback(
    (draft: boolean) => {
      _setIsDraft(draft);
      setShowSiteLink(!draft && selectedPostAudience === "WORKPLACE");
      setIsDraft(draft);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedPostAudience],
  );

  const initialValues: FormValues = {
    id: buildingInfo?.id ?? undefined,
    title: buildingInfo?.title ?? "",
    excerpt: buildingInfo?.excerpt ?? "",
    body: buildingInfo?.body ?? "",
    draft: buildingInfo?.draft ?? false,
    postAudience: audienceWithFallback,
    publishAt: buildingInfo?.publishAt ?? now.toFormat(dateTimeLocaleInputDisplayFormat),
    role: currentRole === Role.PropertyManager ? "PROPERTY_MANAGER" : "WORKPLACE_MANAGER",
    image: [
      {
        alt: buildingInfo?.image.alt ?? "",
        key: buildingInfo?.image.key ?? "",
        title: buildingInfo?.image.title ?? "",
        thumbnail_key: buildingInfo?.image.key ?? "",
      },
    ],
    downloads: buildingInfo?.downloads ?? [],
    links: buildingInfo?.links ?? [],
  };

  const onSubmit = useCallback(
    async (values: FormValues, actions: FormikHelpers<FormValues>) => {
      setSaving(true);

      const input = {
        title: values.title,
        excerpt: values.excerpt,
        body: values.body,
        image: values.image[0],
        publishAt: values.publishAt,
        draft: isDraft,
        downloads: values.downloads,
        links: values.links.flatMap((link) =>
          link != null
            ? {
                url: link.url,
                title: link.title,
              }
            : [],
        ),
      };

      if ("id" in values && values.id != null) {
        const result = await editBuildingInfo({
          client: session.irisClient?.cortex,
          variables: {
            input: {
              ...input,
              id: (values as unknown as EditBuildingInfoPostMutationVariables["input"]).id,
            },
          },
          refetchQueries: [{ query: MyPostsDocument }],
        });

        if (result.data?.editBuildingInfoPost?.post != null && result.errors == null) {
          setModalBodyTitle(modalDesc);
          setModalTitle(modalTitle);
          setContentUrl(getBuildingInfoPostUrl(result.data.editBuildingInfoPost.post.uuid, webAppUrl));
        }
      } else {
        const result = await createBuildingInfo({
          client: session.irisClient?.cortex,
          variables: {
            input: {
              ...input,
              postAudience: values.postAudience,
              role: values.role,
            },
          },
          refetchQueries: [{ query: MyPostsDocument }],
        });

        if (result.data?.createBuildingInfoPost?.post != null && result.errors == null) {
          actions.setSubmitting(false);
          setModalBodyTitle(modalDesc);
          setModalTitle(modalTitle);
          setContentUrl(getBuildingInfoPostUrl(result.data.createBuildingInfoPost.post.uuid, webAppUrl));
        }
      }
      setSaving(false);
      setContentSaved(true);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isDraft, modalDesc, modalTitle, session.irisClient?.cortex, webAppUrl],
  );

  if (contentSaved) {
    return null;
  }

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema()} onSubmit={onSubmit}>
      {({ isValid, isSubmitting, dirty, values, submitForm }) => (
        <>
          <Modal.Body>
            <Form>
              {isNewPost && (
                <EqForm.Group label={t("common.audience")} required>
                  <div className="d-flex flex-row">
                    <Field
                      label={t("common.company")}
                      className="w-50 mr-2"
                      component={EqForm.HeroRadioSelect}
                      id="WORKPLACE"
                      name="postAudience"
                      icon={RiTeamFill}
                      title={t("common.workplace")}
                      description={t("home.widgets.postWorkplaceHint")}
                      onClick={() => {
                        setSelectedPostAudience("WORKPLACE");
                      }}
                    ></Field>
                    <Field
                      label={t("visitors.common.building")}
                      className="w-50 ml-2"
                      component={EqForm.HeroRadioSelect}
                      id="BUILDING"
                      icon={RiBuilding4Fill}
                      name="postAudience"
                      title={t("home.widgets.siteCommunity")}
                      description={t("home.widgets.buildingHint", { siteName })}
                      onClick={() => {
                        setSelectedPostAudience("BUILDING");
                        setShowSiteLink(false);
                      }}
                    ></Field>
                  </div>
                </EqForm.Group>
              )}

              <EqForm.Group label={t("common.image")} required>
                <Field id="image" name="image" placeholder={t("common.image")} as={ImageUploader} />
              </EqForm.Group>
              <EqForm.Group
                label={t("visitors.appointmentForm.title")}
                required
                hint={t("common.remainingCharacters", {
                  count: remainingCharacters(MAX_TITLE_LENGTH, values.title.length),
                })}
              >
                <Field
                  id="title"
                  name="title"
                  placeholder={t("home.widgets.postTitleHint")}
                  as={EqForm.Input}
                  disabled={saving}
                  maxLength={100}
                />
              </EqForm.Group>
              <EqForm.Group
                label={t("home.widgets.introduction")}
                required
                hint={t("common.remainingCharacters", {
                  count: remainingCharacters(MAX_EXCERPT_LENGTH, values.excerpt.length),
                })}
              >
                <Field
                  id="excerpt"
                  name="excerpt"
                  placeholder={t("home.widgets.introductionHint")}
                  as={EqForm.Textarea}
                  disabled={saving}
                  maxLength={150}
                />
              </EqForm.Group>
              <EqForm.Group label={t("common.body")} required>
                <Field id="body" name="body" placeholder={t("common.body")} as={CKEditorNoSSR} disabled={saving} />
              </EqForm.Group>
              <EqForm.Group label={t("common.files")} showTooltip={true} tooltipText={t("home.widgets.fileLimit")}>
                <Field id="downloads" name="downloads" placeholder={t("common.file")} as={FileUploader} />
              </EqForm.Group>

              <EqForm.Group label={t("home.widgets.relatedInformation")}>
                <FieldArray name="links">
                  {(arrayHelpers) => (
                    <>
                      {(values.links ?? []).map((_link, index) => (
                        <RelatedInformation key={index} arrayHelpers={arrayHelpers} index={index} />
                      ))}
                      <Button variant="outline" onClick={() => arrayHelpers.push({ title: "", url: "" })}>
                        Add Link
                      </Button>
                    </>
                  )}
                </FieldArray>
              </EqForm.Group>

              <EqForm.Group
                label={`${selectedPostAudience === "BUILDING" ? t("home.widgets.preferred") : ""} ${t(
                  "home.widgets.publicationDateTime",
                )}`}
                required
              >
                <Field
                  id="publication-date"
                  name="publishAt"
                  type="datetime-local"
                  min={now.toFormat(dateTimeLocaleInputDisplayFormat)}
                  as={EqForm.Input}
                />
              </EqForm.Group>
            </Form>
          </Modal.Body>
          <FormFooter
            close={closeModal}
            saveDraft={() => {
              toggleDraft(true);
              submitForm().catch((e) => {
                console.error(e);
              });
            }}
            savePublished={() => {
              toggleDraft(false);
              submitForm().catch((e) => {
                console.error(e);
              });
            }}
            postAudience={selectedPostAudience}
            disabled={!isValid || isSubmitting || (!dirty && buildingInfo == null)}
            isPublished={buildingInfo?.draft === false}
            saving={saving}
          />
        </>
      )}
    </Formik>
  );
};
