import React, { useContext, useMemo } from "react";
import { useApolloClient } from "@apollo/client";
import { Field } from "formik";

import { notNullOrUndefined, useSaferFormikContext, useShortcuts } from "@equiem/lib";
import { dataIdFromObject } from "@equiem/lib/clients/gatewayUtils";
import { useTranslation } from "@equiem/localisation-eq1";
import { Form, MemberCard, UserSearch } from "@equiem/react-admin-ui";

import type { VisitorProfileShortFragment } from "../generated/visitors-client";
import { VisitorProfileShortFragmentDoc } from "../generated/visitors-client";
import { NoUsersPlaceholder } from "../pages/settings/components/NoUsersPlaceholder";

import { ReceptionFormContext } from "./ReceptionFormContext";
import type { ReceptionFormValues } from "./ReceptionFormValues";

export const ReceptionFormUserSearch: React.FC = () => {
  const { t } = useTranslation();
  const client = useApolloClient();
  const { values, touched, errors, setFieldValue } = useSaferFormikContext<ReceptionFormValues>();
  const { searchUsers, searchUsersLoading } = useContext(ReceptionFormContext);
  const { dispatch } = useShortcuts<string>();
  const searchUserItems = useMemo(() => searchUsers.map((user) => ({ value: "", user })), [searchUsers]);

  const disabled = useMemo(
    () => values.companyUuid === "" && values.receptionType === "tenant",
    [values.companyUuid, values.receptionType],
  );

  return (
    <div className="p-3" style={disabled ? { filter: "opacity(0.5)" } : {}}>
      <Field name="receptionists">
        {() => (
          <Form.Group
            label={t("visitors.common.receptionists")}
            showTooltip={true}
            tooltipText={t("visitors.settings.receptionistsTooltip")}
            hasError={touched.receptionists != null && Boolean(errors.receptionists)}
            error={touched.receptionists != null ? (errors.receptionists as string) : ""}
            style={{ marginBottom: 0 }}
          >
            <UserSearch
              disabled={disabled}
              loading={searchUsersLoading}
              onSearch={(search) => {
                setFieldValue("receptionistsSearch", search).catch(console.error);
              }}
              variant="md"
              onSelect={({ user }) => {
                if (!user.selected) {
                  setFieldValue("receptionists", [...values.receptionists, { userUuid: user.uuid }]).catch(
                    console.error,
                  );
                } else {
                  setFieldValue(
                    "receptionists",
                    values.receptionists.filter((r) => r.userUuid !== user.uuid),
                  ).catch(console.error);
                }
              }}
              items={searchUserItems}
              onAddUser={() => {
                dispatch("addUserAsUnknownRole");
              }}
              buttonText={(item) => (item.user.selected ? t("common.remove") : t("common.add"))}
            />
          </Form.Group>
        )}
      </Field>
      {values.receptionists.length > 0 ? (
        [...values.receptionists]
          .reverse()
          .map((r) =>
            client.cache.readFragment<VisitorProfileShortFragment>({
              id: dataIdFromObject({ __typename: "Profile", uuid: r.userUuid }),
              fragment: VisitorProfileShortFragmentDoc,
            }),
          )
          .filter(notNullOrUndefined)
          .map((profile) => (
            <MemberCard.Card
              style={{
                marginTop: "12px",
              }}
              buttonVariant="ghost"
              showButton={true}
              buttonText={t("common.remove")}
              onButtonClick={() => {
                setFieldValue(
                  "receptionists",
                  values.receptionists.filter((r) => r.userUuid !== profile.uuid),
                ).catch(console.error);
              }}
              key={profile.uuid}
              firstName={profile.firstName ?? ""}
              lastName={profile.lastName ?? ""}
              email={profile.email}
              companyName={profile.companyV2?.name ?? ""}
              profileIconUrl={profile.avatar ?? undefined}
            />
          ))
      ) : (
        <NoUsersPlaceholder
          text={
            <>
              {t("visitors.settings.receptionistsEmptyList")} <br />
              {t("visitors.settings.receptionistsGetStarted")}
            </>
          }
        />
      )}
    </div>
  );
};
