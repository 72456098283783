import type { FC } from "react";
import React, { useContext, useEffect, useMemo, useState } from "react";

import { Site, stringIsEmpty, useSaferFormikContext } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import { Alert, Form, ListGroup, Skeleton, Tag, Text } from "@equiem/react-admin-ui";
import { RiInformationLine } from "@equiem/react-admin-ui/icons";

import { useWidgetContext } from "../../../contexts/WidgetContext";
import type { ProfileDetailsQuery } from "../../../generated/settings-client";
import { useGetProfileRoleTuplesQuery, useRolesICanGrantQuery } from "../../../generated/settings-client";
import type { FormValues } from "../types";

import { filterTree } from "./filterTree";
import { findLeaves } from "./findLeaves";
import { RoleView } from "./RoleView";

interface Props {
  user: NonNullable<ProfileDetailsQuery["profile"]>;
}

export const UserRoles: FC<Props> = ({ user }) => {
  const { memberSites, uuid: siteUuid } = useContext(Site);
  const [selectedSite, setSelectedSite] = useState("");
  const [siteSearchKeyword, setSiteSearchKeyword] = useState("");
  const { submitForm } = useSaferFormikContext<FormValues>();
  const { subscribe } = useWidgetContext();
  const { t } = useTranslation();
  const [rolesValid, setRolesValid] = useState<Record<string, boolean>>({});
  const [submitted, setSubmitted] = useState(false);
  const { data: profileData } = useGetProfileRoleTuplesQuery({
    variables: {
      uuid: user.uuid,
    },
    fetchPolicy: "cache-and-network",
  });
  const granteeCompanyUuid = profileData?.profile?.companyV2?.uuid;

  const selectedSiteUuid = useMemo(
    () => (stringIsEmpty(selectedSite) || selectedSite === "global" ? null : selectedSite),
    [selectedSite],
  );

  const { data: grantableRolesSite, loading: grantableRolesSiteLoading } = useRolesICanGrantQuery({
    variables: {
      siteUuid: selectedSiteUuid,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      granteeCompanyUuid: granteeCompanyUuid!,
    },
    skip: granteeCompanyUuid == null || selectedSiteUuid == null,
  });
  const { data: grantableRolesGlobal, loading: grantableRolesGlobalLoading } = useRolesICanGrantQuery({
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    variables: { granteeCompanyUuid: granteeCompanyUuid! },
    skip: granteeCompanyUuid == null,
  });

  const grantableRolesLoading = grantableRolesSiteLoading || grantableRolesGlobalLoading;
  const grantableRolesGlobalCount = grantableRolesGlobal?.rolesICanGrant.length ?? 0;

  const selectedUser = profileData?.profile;
  const siteOptions = useMemo(
    () =>
      memberSites.map((site) => {
        const roleCount =
          selectedUser?.grantedRoleTuples.reduce((sum, role) => {
            const leafs = findLeaves(
              filterTree(
                role.resources,
                ({ type, id }, ancestors) =>
                  (type === "site" && id === site.uuid) ||
                  ancestors.some((node) => node.type === "site" && node.id === site.uuid),
              ),
            );
            return sum + (leafs.length > 0 ? 1 : 0);
          }, 0) ?? 0;

        return {
          value: site.uuid,
          hasRoles: roleCount > 0,
          name: site.name,
          group:
            roleCount === 0
              ? { weight: 2, name: t("settings.roles.sitesWhereUserHasNoRoles") }
              : { weight: 1, name: t("settings.roles.sitesWhereUserHasRoles") },
          label: (
            <span>
              {site.name}
              {roleCount > 0 && (
                <Tag variant="primary" type="counter" size="large" className="ml-2" value={roleCount} />
              )}
            </span>
          ),
        };
      }),
    [memberSites, selectedUser?.grantedRoleTuples, t],
  );

  const globalRoleCount = useMemo(
    () =>
      grantableRolesGlobalCount === 0
        ? 0
        : selectedUser?.grantedRoleTuples.reduce((sum, role) => {
            const leaves = findLeaves(role.resources).filter(
              (leaf) => leaf.type === "region" || leaf.type === "flexOperator",
            );
            return sum + (leaves.length > 0 ? 1 : 0);
          }, 0) ?? 0,
    [grantableRolesGlobalCount, selectedUser?.grantedRoleTuples],
  );

  useEffect(() => {
    if (globalRoleCount > 0) {
      setSelectedSite("global");
    } else {
      setSelectedSite(siteUuid);
    }
  }, [globalRoleCount, siteUuid]);

  const options = useMemo(
    () =>
      [
        grantableRolesGlobalCount > 0
          ? [
              {
                value: "global",
                name: t("settings.roles.global"),
                group: { weight: 0, name: t("settings.roles.global") },
                label: (
                  <span>
                    {t("settings.roles.global")}
                    {globalRoleCount > 0 && (
                      <Tag variant="primary" type="counter" size="large" className="ml-2" value={globalRoleCount} />
                    )}
                  </span>
                ),
              },
            ]
          : [],
        ...[siteOptions],
      ].flat(),
    [globalRoleCount, grantableRolesGlobalCount, siteOptions, t],
  );

  const filteredOptions = useMemo(
    () =>
      options.flatMap((site) =>
        site.value === selectedSite || site.name.toLowerCase().includes(siteSearchKeyword.toLowerCase()) ? [site] : [],
      ),
    [options, selectedSite, siteSearchKeyword],
  );

  const grantableRoles = useMemo(
    () => (selectedSite === "global" ? grantableRolesGlobal : grantableRolesSite),
    [grantableRolesGlobal, grantableRolesSite, selectedSite],
  );

  useEffect(() => {
    return subscribe("onSave", () => {
      setSubmitted(true);
      if (Object.values(rolesValid).every(Boolean)) {
        void submitForm();
      }
    });
  }, [rolesValid, submitForm, subscribe]);

  return (
    <div className="mb-5">
      <Text variant="heading">{t("settings.roles.tab")}</Text>
      <Text variant="text" size="small" className="mt-2 mb-6">
        {t("settings.roles.info")}
      </Text>
      <Alert
        variant="gray"
        className="my-6 pl-3"
        icon={<RiInformationLine size={24} />}
        message={t("settings.roles.delayWarning")}
      />
      {selectedUser == null ? (
        <div className="mt-4">
          <Skeleton.Line width="5rem" height="1.5rem" className="mb-2" />
          <Skeleton.Line width="100%" height="3rem" />
          <Skeleton.Line width="100%" height="3rem" />
        </div>
      ) : (
        <>
          {options.length < 2 ? null : (
            <Form.Group>
              <Form.Label>{t("common.site")}</Form.Label>
              <Form.DynamicSelect
                options={filteredOptions}
                value={selectedSite}
                name="Site"
                onChange={(e) => setSelectedSite(e.target.value ?? "")}
                search
                mobileView="full"
                showSelectedOnList
                searchPlaceholder={t("common.search")}
                searchCb={async (keyword) => {
                  setSiteSearchKeyword(keyword);
                  return Promise.resolve();
                }}
              ></Form.DynamicSelect>
            </Form.Group>
          )}
          <Form.Group>
            <Form.Label>{t("common.roles")}</Form.Label>
            {grantableRolesLoading ? (
              <>
                <Skeleton.Line width="100%" height="3rem" />
                <Skeleton.Line width="100%" height="3rem" />
              </>
            ) : grantableRoles == null ? (
              <Alert message={t("settings.roles.noGrantableRoles")} />
            ) : (
              <ListGroup.Group>
                {grantableRoles.rolesICanGrant.map((role) => (
                  <RoleView
                    key={`${selectedSite}${role.role.tuple}`}
                    role={role}
                    siteUuid={selectedSiteUuid}
                    userTuples={selectedUser.grantedRoleTuples}
                    onValidChange={(valid) => setRolesValid((existing) => ({ ...existing, [role.role.tuple]: valid }))}
                    submitted={submitted}
                  />
                ))}
              </ListGroup.Group>
            )}
          </Form.Group>
        </>
      )}
    </div>
  );
};
