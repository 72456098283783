import { DateTime } from "luxon";

import { useSiteContext } from "@equiem/lib";

export const useIsExpiredAppointmentByDateAndTime = (startTime?: number, date?: string | null): boolean => {
  const { timezone } = useSiteContext();

  const now = DateTime.local({ zone: timezone });

  if (startTime == null || date == null || date.length === 0) {
    return true;
  }

  const { minute, second, hour } = DateTime.fromSeconds(Number(startTime)).toObject();
  const { day, month, year } = DateTime.fromFormat(date, "yyyy-MM-dd");
  const startDateMillis = now.set({ minute, second, hour, day, month, year }).toMillis();

  return startDateMillis < now.toMillis();
};
