import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { DateTime } from "luxon";

import { ProgressCircle } from "@equiem/react-admin-ui";

import type { Visitor } from "../../../generated/visitors-client";

import { EmptyList } from "./EmptyList";

export const InfiniteScrollAppointmentTable = ({
  loading,
  children,
  handleNextPage,
  date,
  visitors,
  hasMoreAppointments,
}: {
  children: React.ReactNode;
  loading: boolean;
  handleNextPage: () => void;
  date: DateTime;
  visitors: Visitor[];
  hasMoreAppointments: boolean;
}) => {
  const isToday = date.hasSame(DateTime.local(), "day");

  if (loading && visitors.length === 0) {
    return (
      <div className="mt-6 mb-6 d-flex justify-content-center">
        <ProgressCircle size="md" />
      </div>
    );
  }

  return (
    <InfiniteScroll
      next={handleNextPage}
      dataLength={visitors.length}
      hasMore={hasMoreAppointments}
      className="p-7 visitors"
      style={{ overflow: undefined }}
      loader={
        <div className="mt-6 mb-6 d-flex justify-content-center">
          <ProgressCircle size="md" />
        </div>
      }
    >
      {visitors.length === 0 ? (
        <div className="d-flex align-items-center justify-content-center">
          <EmptyList walkInDisabled={!isToday} />
        </div>
      ) : (
        children
      )}
    </InfiniteScroll>
  );
};
