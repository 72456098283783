import React from "react";
import type { ReactElement } from "react";

// eslint-disable-next-line @typescript-eslint/no-type-alias
type Props = {
  inputs: Array<ReactElement<HTMLInputElement | HTMLSelectElement>>;
};

export const FormInputBar: React.FC<Props> = ({ inputs }) => {
  return (
    <div>
      {inputs.map((Input, i) => {
        return <span key={i}>{Input}</span>;
      })}
      <style jsx>{`
        div {
          display: flex;
        }
        span {
          flex-basis: calc(100% / ${inputs.length});
          flex-grow: 0;
        }
        span:last-of-type > :global(input),
        span:last-of-type > :global(select),
        span:last-of-type > :global(* > input),
        span:last-of-type > :global(* > select) {
          border-top-left-radius: 0px !important;
          border-bottom-left-radius: 0px !important;
        }
        span:last-of-type > :global(input:not(:focus)),
        span:last-of-type > :global(select:not(:focus)),
        span:last-of-type > :global(* > input:not(:focus)),
        span:last-of-type > :global(* > select:not(:focus)) {
          border-left: 1px solid rgba(0, 0, 0, 0) !important;
        }
        span:first-of-type > :global(input),
        span:first-of-type > :global(select),
        span:first-of-type > :global(* > input),
        span:first-of-type > :global(* > select) {
          border-top-right-radius: 0px !important;
          border-bottom-right-radius: 0px !important;
        }
        span:not(:first-of-type):not(:last-of-type) > :global(input:not(:focus)),
        span:not(:first-of-type):not(:last-of-type) > :global(select:not(:focus)),
        span:not(:first-of-type):not(:last-of-type) > :global(* > input:not(:focus)),
        span:not(:first-of-type):not(:last-of-type) > :global(* > select:not(:focus)) {
          border-left: 1px solid rgba(0, 0, 0, 0) !important;
        }
        span:not(:first-of-type):not(:last-of-type) > :global(input),
        span:not(:first-of-type):not(:last-of-type) > :global(select),
        span:not(:first-of-type):not(:last-of-type) > :global(* > input),
        span:not(:first-of-type):not(:last-of-type) > :global(* > select) {
          border-radius: 0px !important;
        }
      `}</style>
    </div>
  );
};
