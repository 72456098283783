import React from "react";
import { Alert, Button, Form as EqForm, Modal, Text, useTheme } from "@equiem/react-admin-ui";
import { useTranslation } from "@equiem/localisation-eq1";
import { Field, Form, Formik } from "formik";
import { useCompanyFormSubmit, useCompanyIndustries, useShowError } from "../hooks";
import type { SearchCompaniesQuery } from "../generated/gateway-client";
import { RiErrorWarningLine, RiInformationLine } from "@equiem/react-admin-ui/icons";
import { FormList } from "./FormList";
import { companyValidationSchema } from "../formik";

export type CompanySearchCompany = NonNullable<SearchCompaniesQuery["companiesV2"]["edges"][number]["node"]>;

interface Props {
  name: string;
  onSubmitComplete: (company?: CompanySearchCompany) => void;
}

export const CreateCompanyForm: React.FC<Props> = ({ name, onSubmitComplete }) => {
  const { t } = useTranslation();
  const { onSubmit } = useCompanyFormSubmit();
  const industries = useCompanyIndustries(false);
  const { colors } = useTheme();
  const showError = useShowError();

  const initialValues = {
    name,
    industry: "",
    autoApproveRegistrations: true,
    accessGroupsUuids: [],
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        isInitialValid={false}
        validationSchema={companyValidationSchema(t, false)}
        onSubmit={async (values, actions) => {
          actions.setSubmitting(true);
          const result = await onSubmit(values, initialValues);
          actions.setSubmitting(false);
          if (result?.createOrUpdateCompanyV2.__typename === "CompanyV2SyncSuccess") {
            onSubmitComplete(result.createOrUpdateCompanyV2.companyV2);
          }
          onSubmitComplete();
        }}
      >
        {({ submitForm, isValid, values, setFieldValue, errors }) => (
          <>
            <Modal.Body>
              <Form>
                <Alert
                  size="large"
                  variant="gray"
                  icon={<RiErrorWarningLine size={18} color={colors.grayscale[50]} />}
                  message={t("settings.editCompany.addCompanyInfo")}
                  className="mb-6"
                />
                <EqForm.Group label={t("settings.createCompany.companyName")} required>
                  <Field as={EqForm.Input} name="name" required disabled />
                </EqForm.Group>
                <EqForm.Group
                  required
                  label={t("settings.createCompany.industry")}
                  showTooltip
                  tooltipText={t("settings.createCompany.industryTooltips")}
                >
                  <Field
                    as={EqForm.DynamicSelect}
                    name="industry"
                    mobileView="regular"
                    title={t("settings.createCompany.industry")}
                    noneLabel={<span>&nbsp;</span>}
                    searchPlaceholder={t("settings.createCompany.searchIndustries")}
                    search
                    options={industries.map((industry) => {
                      return { value: industry.uuid, label: industry.name };
                    })}
                  />
                </EqForm.Group>
                <hr className="mt-6 mb-6 divider" />
                <div>
                  <h4 className="font-weight-bold">{t("settings.editCompany.autoApproveRegistrationsHeading")}</h4>
                  <div className="pt-4 pb-3">
                    <Text variant="label" color={colors.muted0}>
                      {t("settings.editCompany.autoApproveRegistrationsLabel")}
                    </Text>
                  </div>
                  <EqForm.RadioButton
                    name="autoApproveRegistrations"
                    className="mb-3"
                    label={t("settings.editCompany.autoApproveRegistrationsNoLabel")}
                    extraInfo={t("settings.editCompany.autoApproveRegistrationsNoExtraInfo")}
                    checked={!values.autoApproveRegistrations}
                    onChange={() => {
                      setFieldValue("autoApproveRegistrations", false).catch(showError);
                    }}
                  />
                  <EqForm.RadioButton
                    name="autoApproveRegistrations"
                    label={t("settings.editCompany.autoApproveRegistrationsYesLabel")}
                    extraInfo={t("settings.editCompany.autoApproveRegistrationsYesExtraInfo")}
                    className="pb-3"
                    checked={values.autoApproveRegistrations}
                    onChange={() => {
                      setFieldValue("autoApproveRegistrations", true).catch(showError);
                    }}
                  />
                </div>
                {values.autoApproveRegistrations && (
                  <>
                    <Alert
                      size="large"
                      variant="gray"
                      icon={<RiInformationLine size={18} color={colors.grayscale[50]} />}
                      message={t("settings.createCompany.validationHint")}
                      className="mb-6"
                    />
                    <EqForm.Group
                      label={t("settings.createCompany.approvedDomains")}
                      error={errors.autoApproveRegistrations}
                      showTooltip
                      tooltipText={t("settings.createCompany.validationTooltips")}
                    >
                      <Field
                        tooltipText={t("settings.editCompany.valuesToolTip")}
                        errorMessage={t("settings.createCompany.validationValuesErrorMessage")}
                        as={FormList}
                        name="validationValues"
                        buttonText={`+ ${t("settings.createCompany.addDomain")}`}
                      />
                    </EqForm.Group>
                  </>
                )}
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Form>
                <Button
                  variant="ghost"
                  className="mr-2"
                  onClick={() => {
                    void onSubmitComplete();
                  }}
                >
                  {t("common.cancel")}
                </Button>
                <Button
                  type="submit"
                  className="mr-2"
                  disabled={!isValid}
                  onSubmit={() => {
                    void submitForm();
                  }}
                >
                  {t("settings.createCompany.createCompany")}
                </Button>
              </Form>
            </Modal.Footer>
          </>
        )}
      </Formik>
      <style jsx>
        {`
          .divider {
            border: none;
            border-top: 1px solid ${colors.grayscale[10]};
          }
        `}
      </style>
    </>
  );
};
