import React from "react";

interface P {
  name: string;
  humanName?: string;
  className?: string;
  fontSize?: string;
  size?: "sm" | "md";
  color?: string;
}
export const Icon: React.FC<P> = ({ name, humanName, className = "", fontSize, size = "md", color }) => {
  const finalSize = fontSize != null ? fontSize : size === "sm" ? "16px" : null;

  return (
    <span
      title={humanName ?? undefined}
      className={`material-symbols-outlined ${className}`}
      style={{ fontSize: finalSize ?? undefined, color: color ?? undefined }}
    >
      {name}
    </span>
  );
};
