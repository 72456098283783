import React, { useContext, useEffect } from "react";
import { useApolloClient } from "@apollo/client";

import { useSiteContext } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import { FilterTab, Form, useTheme } from "@equiem/react-admin-ui";
import { RiSearchLine } from "@equiem/react-admin-ui/icons";

import type { ApartmentListQuery, ApartmentListQueryVariables } from "../../../generated/settings-client";
import { ApartmentListDocument } from "../../../generated/settings-client";
import type { PredefinedTabFilters } from "../contexts/ApartmentsFilterContext";
import { ApartmentsFilterContext, ApartmentsStatusTabs } from "../contexts/ApartmentsFilterContext";

import { AddApartment } from "./AddApartment";

export const ApartmentsFilter: React.FC = () => {
  const { t } = useTranslation();
  const client = useApolloClient();
  const site = useSiteContext();
  const { breakpoints } = useTheme();
  const { search, setSearch, selectedTab, setSelectedTab } = useContext(ApartmentsFilterContext);
  const [ApartmentsStats, setApartmentsStats] = React.useState<Record<PredefinedTabFilters, number>>({
    all: 0,
  });

  useEffect(() => {
    for (const status of ApartmentsStatusTabs) {
      void client
        .query<ApartmentListQuery, ApartmentListQueryVariables>({
          query: ApartmentListDocument,
          fetchPolicy: "network-only",
          variables: {
            destinationUuid: site.uuid,
            first: 0,
          },
        })
        .then((result) => {
          setApartmentsStats((prev) => ({
            ...prev,
            [status]: result.data.apartments.totalCount,
          }));
        })
        .catch((e) => console.error(e));
    }
  }, [client, site.uuid]);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch?.(e.target.value);
  };

  const clearSearch = () => {
    setSearch?.("");
  };

  const getTabTitle = (tab: PredefinedTabFilters) => {
    switch (tab) {
      case "all":
        return t("common.all");
      default:
        return "";
    }
  };

  const renderQuickFilters = () => (
    <>
      {ApartmentsStatusTabs.map((tab) => (
        <FilterTab
          title={getTabTitle(tab)}
          value={ApartmentsStats[tab]}
          isActive={selectedTab === tab}
          onClick={() => setSelectedTab?.(tab)}
          key={tab}
        />
      ))}
    </>
  );

  return (
    <>
      <div className="filters">
        <div className="main-row">
          <div className="left-side">{renderQuickFilters()}</div>
          <div className="right-side">
            <div className="search-container">
              <Form.InputExtended
                className="search-input"
                icon={RiSearchLine}
                placeholder={`${t("common.search")}...`}
                variant="sm"
                value={search}
                onChange={handleSearch}
                onClear={clearSearch}
                clearable
              />
            </div>
            <AddApartment />
          </div>
        </div>
      </div>
      <style jsx>{`
        .filters {
          display: flex;
          flex-direction: column;
          width: 100%;
        }

        .main-row {
          display: flex;
          justify-content: space-between;
          gap: 8px;
        }

        .right-side,
        .left-side {
          display: flex;
          gap: 8px;
          align-items: center;
        }

        .search-container {
          width: 270px;
        }

        @media (max-width: ${breakpoints.lg}px) {
          .main-row {
            flex-direction: column;
            max-width: 100vw;
          }

          .main-row .left-side {
            overflow-x: auto;
            padding: 0 16px;
            display: none;
          }

          .main-row .left-side::-webkit-scrollbar {
            display: none;
          }

          .main-row .right-side {
            display: grid;
            grid-template-columns: auto 32px;
            align-items: center;
          }

          .search-container {
            width: 100%;
          }
        }

        @media (max-width: ${breakpoints.md}px) {
          .main-row .right-side {
            padding: 0 32px;
          }
        }

        @media (max-width: ${breakpoints.sm}px) {
          .main-row .right-side {
            padding: 0 16px;
          }
        }
      `}</style>
    </>
  );
};
