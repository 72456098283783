import React, { useMemo } from "react";
import { utilityClasses as getUtilityClasses } from "../util/utilityClasses";
import { useDefaultFont, fontImports } from "../hooks/useDefaultFont";
import type { ThemeContext, AdminThemeContext } from "./Theme";

interface Props {
  theme: ThemeContext | AdminThemeContext;
  useUtilityClasses: boolean;
  useWebSafeFontOnly?: boolean;
}

export const GlobalStyles: React.FC<React.PropsWithChildren<Props>> = ({
  theme,
  useUtilityClasses,
  useWebSafeFontOnly = false,
  children,
}) => {
  const utilityClasses = useMemo(() => getUtilityClasses(theme), [theme]);
  const font = useDefaultFont();

  return (
    <>
      {children}
      <link href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined" rel="stylesheet" />
      {/* eslint-disable-next-line react/no-unknown-property */}
      <style jsx global>{`
        ${!useWebSafeFontOnly ? fontImports : ""}
        *,
        *::before,
        *::after {
          box-sizing: border-box;
        }
        * {
          font-family: ${!useWebSafeFontOnly ? `${font}, ` : ""}Arial, sans-serif;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
        }
        body {
          font-size: ${theme.fontSize};
        }
        p {
          line-height: 1.5;
        }
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          font-weight: 400;
        }
        h1 {
          font-size: 24px;
        }
        h2 {
          font-size: 22px;
        }
        h3 {
          font-size: 20px;
        }
        h4 {
          font-size: 18px;
        }
        /*
         * Utility classess stop working when this is placed at the end of this
         *  block. Ensure there's always some styles after this line.
         */
        ${useUtilityClasses ? utilityClasses : ""}
        svg {
          vertical-align: middle !important;
        }
        input:read-only {
          cursor: default;
        }
      `}</style>
    </>
  );
};
