import { stringIsEmpty, stringNotEmpty } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import { Text, useTheme } from "@equiem/react-admin-ui";
import { RiPlayCircleFill } from "@equiem/react-admin-ui/icons";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { BookingResourceVideoThumbnail, getVideoThumbnail } from "./BookingResourceVideoThumbnail";
import { BookingModalInfo } from "../contexts/BookingModalInfoProvider";
import { getVideoUrl } from "../../../lib/getVideoUrl";

const VIDEO_THUMBNAIL_WIDTH = 270;
const VIDEO_THUMBNAIL_HEIGHT = 150;

interface P {
  className?: string;
}

export const BookingResourceVideo: React.FC<P> = ({ className = "" }) => {
  const { t } = useTranslation();
  const { resource } = useContext(BookingModalInfo);
  const [videoThumbnailUrl, setVideoThumbnailUrl] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const { colors } = useTheme(true);

  const videoUrl = useMemo(() => getVideoUrl(resource.video), [resource.video]);

  useEffect(() => {
    let active = true;
    setLoading(true);

    getVideoThumbnail(videoUrl ?? "")
      .then((result) => {
        if (active) {
          setVideoThumbnailUrl(result);
          setLoading(false);
        }
      })
      .catch((e) => {
        if (active) {
          console.error(e);
          setLoading(false);
        }
      });

    return () => {
      active = false;
    };
  }, [videoUrl, setVideoThumbnailUrl]);

  if (stringIsEmpty(videoUrl)) {
    return null;
  }

  return (
    <div className={`preview-video-cont ${className}`}>
      <Text variant="label" color={colors.grayscale[60]}>
        {stringNotEmpty(resource.videoTitle) ? resource.videoTitle : t("bookings.resources.takeAVideoTour")}
      </Text>
      <div className="video-info mt-3">
        <a className="video-thumbnail" href={videoUrl ?? ""} target="_blank" rel="noreferrer">
          <BookingResourceVideoThumbnail
            imageUrl={videoThumbnailUrl}
            loading={loading}
            thumbnailWidth={VIDEO_THUMBNAIL_WIDTH}
            thumbnailHeight={VIDEO_THUMBNAIL_HEIGHT}
          />
          <RiPlayCircleFill className="play-icon" size={64} color="white" />
        </a>
      </div>
      <style jsx>{`
        .video-info {
          display: flex;
          flex-direction: column;
        }
        .video-thumbnail {
          align-self: flex-start;
          position: relative;
          width: auto;
        }
        .video-thumbnail :global(.play-icon) {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      `}</style>
    </div>
  );
};
