import DOMPurify from "isomorphic-dompurify";

// Allow `target="_blank"` but require `rel="noopener"` and/or `rel="noreferrer"`.
// See:
//   - https://developer.chrome.com/docs/lighthouse/best-practices/external-anchors-use-rel-noopener/
//   - https://github.com/cure53/DOMPurify/issues/317
//
// This is conditional because dompurify doesn't work serverside.
let _sanitiseOptions: DOMPurify.Config = {};
if (typeof DOMPurify.addHook === "function") {
  const isAnchorTag = (node: Element): node is HTMLAnchorElement => node.tagName === "A";

  DOMPurify.addHook("afterSanitizeAttributes", (node) => {
    const target = node.getAttribute("target");
    if (!isAnchorTag(node) || target !== "_blank") {
      node.removeAttribute("target");
      node.removeAttribute("rel");
      return;
    }

    const rel = node.getAttribute("rel");
    if (!/\b(noopener|noreferrer)\b/u.test(rel ?? "")) {
      node.setAttribute("rel", "noopener noreferrer");
    }
  });

  _sanitiseOptions = {
    ADD_ATTR: ["target", "rel"],
  };
}

export const sanitiseOptions = _sanitiseOptions;
