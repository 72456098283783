import React, { useContext, useEffect, useMemo, useState } from "react";
import { useApolloClient } from "@apollo/client";

import { UrlParams, useSiteContext } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import { ComplexFilter, FilterOptionsModifier, FilterTab, Form, useTheme } from "@equiem/react-admin-ui";
import { RiSearchLine } from "@equiem/react-admin-ui/icons";

import type { CompanyListQuery, CompanyListQueryVariables } from "../../../generated/settings-client";
import { CompanyListDocument, useDestinationBuildingNamesQuery } from "../../../generated/settings-client";
import type { PredefinedTabFilters } from "../contexts/CompaniesFilterContext";
import { CompaniesFilterContext, companiesStatusTabs } from "../contexts/CompaniesFilterContext";

import { AddCompany } from "./AddCompany";

export const CompaniesFilter: React.FC = () => {
  const { t } = useTranslation();
  const client = useApolloClient();
  const site = useSiteContext();
  const urlParamsProvider = useContext(UrlParams);
  const { breakpoints } = useTheme();
  const { search, setSearch, selectedTab, setSelectedTab, filters, setFilters, filterBuildingUuids } =
    useContext(CompaniesFilterContext);
  const buildings = useDestinationBuildingNamesQuery({ variables: { uuid: site.uuid } });
  const [companiesStats, setCompaniesStats] = useState<Record<PredefinedTabFilters, number>>({
    all: 0,
  });

  useEffect(() => {
    for (const status of companiesStatusTabs) {
      void client
        .query<CompanyListQuery, CompanyListQueryVariables>({
          query: CompanyListDocument,
          fetchPolicy: "network-only",
          variables: {
            withBuildingLevels: false,
            destinationUuid: site.uuid,
            buildingUuids: filterBuildingUuids,
            first: 0,
            withFlexTenants: false,
          },
        })
        .then((result) => {
          setCompaniesStats((prev) => ({
            ...prev,
            [status]: result.data.companiesV2.totalCount,
          }));
        })
        .catch((e) => console.error(e));
    }
  }, [client, site.uuid, filterBuildingUuids]);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch?.(e.target.value);
  };

  const clearSearch = () => {
    setSearch?.("");
  };

  const getTabTitle = (tab: PredefinedTabFilters) => {
    switch (tab) {
      case "all":
        return t("common.all");
      default:
        return "";
    }
  };

  const renderQuickFilters = () => (
    <>
      {companiesStatusTabs.map((tab) => (
        <FilterTab
          title={getTabTitle(tab)}
          value={companiesStats[tab]}
          isActive={selectedTab === tab}
          onClick={() => setSelectedTab?.(tab)}
          key={tab}
        />
      ))}
    </>
  );

  const showFilters = useMemo(() => Object.keys(filters).length > 0, [filters]);

  return (
    <>
      <ComplexFilter
        filters={{
          building: {
            options: buildings.data?.destination.buildings?.map((b) => ({ value: b.uuid, label: b.name })) ?? [],
            title: t("settings.building"),
            type: "options",
            modifiers: [FilterOptionsModifier.includes, FilterOptionsModifier.empty],
            disabled: buildings.loading,
          },
        }}
        urlParamsProvider={urlParamsProvider}
        onChange={setFilters}
      >
        {({ renderChips, renderClearButton, renderFilterButton }) => (
          <div className="filters">
            <div className="main-row">
              <div className="left-side">{renderQuickFilters()}</div>
              <div className="right-side">
                <div className="search-container">
                  <Form.InputExtended
                    className="search-input"
                    icon={RiSearchLine}
                    placeholder={`${t("common.search")}...`}
                    variant="sm"
                    value={search}
                    onChange={handleSearch}
                    onClear={clearSearch}
                    clearable
                  />
                </div>
                {renderFilterButton()}
                <AddCompany />
              </div>
            </div>
            {showFilters && (
              <div className="filters-selected d-flex mt-6">
                {renderChips()}
                {renderClearButton()}
              </div>
            )}
          </div>
        )}
      </ComplexFilter>
      <style jsx>{`
        .filters {
          display: flex;
          flex-direction: column;
          width: 100%;
        }

        .main-row {
          display: flex;
          justify-content: space-between;
          gap: 8px;
        }

        .right-side,
        .left-side {
          display: flex;
          gap: 8px;
          align-items: center;
        }

        .search-container {
          width: 270px;
        }

        @media (max-width: ${breakpoints.lg}px) {
          .filters-selected {
            display: none !important;
          }
          .main-row {
            flex-direction: column;
            max-width: 100vw;
          }

          .main-row .left-side {
            overflow-x: auto;
            display: none;
          }

          .main-row .left-side::-webkit-scrollbar {
            display: none;
          }

          .main-row .right-side {
            display: grid;
            grid-template-columns: auto 32px 32px;
          }

          .search-container {
            flex-grow: 1;
            width: 100%;
          }
        }

        @media (max-width: ${breakpoints.md}px) {
          .filters {
            padding: 0 32px;
          }
        }

        @media (max-width: ${breakpoints.sm}px) {
          .filters {
            padding: 0 16px;
          }
        }
      `}</style>
    </>
  );
};
