import React, { createContext, useCallback } from "react";
import Head from "next/head";

interface Iframely {
  load: ((containerElement?: HTMLElement, url?: string) => void) &
    ((linkElement?: HTMLAnchorElement) => void) &
    (() => void);
}

declare global {
  interface Window {
    iframely?: Iframely;
  }
}

export const IframelyContext = createContext<Iframely>({ load: () => undefined });

export const IframelyProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const load = useCallback((element?: HTMLElement, url?: string) => {
    window.iframely?.load(element, url);
  }, []);

  return (
    <>
      <Head>
        <script src={`//cdn.iframe.ly/embed.js?key=${process.env.iframelyApiKey}`} async></script>
      </Head>
      <IframelyContext.Provider value={{ load }}>{children}</IframelyContext.Provider>
    </>
  );
};
