import type { ComponentProps, FC } from "react";
import React from "react";

import { Avatar } from "@equiem/react-admin-ui";

export type Props = ComponentProps<"div"> & {
  firstName: string;
  lastName: string;
  email: string;
  profileIconUrl?: string;
  companyName: string;
  className?: string;
  hideDetails?: boolean;
};

export const ProfileTag: FC<Props> = ({
  firstName,
  lastName,
  companyName,
  email,
  profileIconUrl,
  hideDetails = false,
  ...props
}) => (
  <div {...props} className={`profile ${props.className ?? ""}`}>
    <div className="header">
      <Avatar size="small" imageUrl={profileIconUrl} firstName={firstName} lastName={lastName} />
    </div>
    <div className="body">
      <div className="name">
        {firstName} {lastName}
      </div>
      {!hideDetails && (
        <div className="details">
          {companyName} {companyName.length > 0 && email.length > 0 && " • "} {email}
        </div>
      )}
    </div>
    <style jsx>
      {`
        .profile {
          display: grid;
          grid-template-columns: 24px 1fr;
          align-items: center;
          justify-content: flex-start;
          grid-column-gap: 8px;
        }

        .body {
          display: flex;
          flex-direction: column;
          justify-content: center;
          overflow: hidden;
        }

        .name,
        .details {
          line-height: 16px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .name {
          font-weight: ${hideDetails ? 400 : 500};
          font-size: 14px;
        }

        .details {
          color: #666;
          font-size: 12px;
        }
      `}
    </style>
  </div>
);
