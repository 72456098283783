import type { ReactNode } from "react";
import React, { createContext, useCallback, useContext, useEffect, useState } from "react";
import { useRouter } from "next/router";

import { useShowError } from "@equiem/lib";

import { RequestsMenuContext } from "../../../contexts/RequestsMenuContext";
import type { ReqMgtFullRequestInput, RequestQuery } from "../../../generated/requests-client";
import { useUpdateRequestMutation } from "../../../generated/requests-client";

interface RequestDetailsContext {
  loading: boolean;
  lastSaved: number | null;
  update: (request: RequestQuery["reqMgt"]["request"], input: ReqMgtFullRequestInput) => Promise<void>;
  isFileUploading: boolean;
  setIsFileUploading: (value: boolean) => void;
}

export const RequestDetailsContext = createContext<RequestDetailsContext>({
  loading: false,
  isFileUploading: false,
  setIsFileUploading: () => {
    throw new Error("Not implemented");
  },
  lastSaved: null,
  update: () => {
    throw new Error("Not implemented");
  },
});

export const RequestDetailsProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const showError = useShowError();
  const router = useRouter();
  const { setDisabled } = useContext(RequestsMenuContext);
  const [isFileUploading, setIsFileUploading] = useState(false);
  const [lastSaved, setLastSaved] = useState<number | null>(null);
  const [mutation, { loading }] = useUpdateRequestMutation({
    refetchQueries: ["Request", "Activities"],
  });

  useEffect(() => {
    const timer = setTimeout(() => {
      setLastSaved(null);
    }, 5000);

    return () => clearTimeout(timer);
  }, [lastSaved]);

  useEffect(() => {
    setLastSaved(null);
  }, [router.query.uuid]);

  const update = useCallback(
    async (request: RequestQuery["reqMgt"]["request"], input: ReqMgtFullRequestInput) => {
      try {
        setDisabled(true);
        await mutation({ variables: { uuid: request.uuid, version: request.updated, input } });
        setLastSaved(Date.now());
      } catch (e: unknown) {
        showError(e);
      } finally {
        setDisabled(false);
      }
    },
    [mutation, showError, setDisabled],
  );

  return (
    <RequestDetailsContext.Provider
      value={{
        lastSaved,
        loading: loading || isFileUploading,
        update,
        isFileUploading,
        setIsFileUploading,
      }}
    >
      {children}
    </RequestDetailsContext.Provider>
  );
};
