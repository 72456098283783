/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useCallback, useState } from "react";
import type { ApolloError } from "@apollo/client";
import type { FormValues } from "./helpers/FormValues";
import type { BookingBlockOutFragmentFragment } from "../../../../generated/gateway-client";
import {
  useBookingBlockOutsQuery,
  useCreateUpdateBookingBlockOutMutationMutation,
} from "../../../../generated/gateway-client";
import type { FormikHelpers } from "formik";
import { getEndTime, getStartTime } from "./helpers/validateAddForm";
import { useToast } from "@equiem/react-admin-ui";
import { stringNotEmpty, useSiteContext } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";

interface BlockoutContext {
  bookingBlockOuts: BookingBlockOutFragmentFragment[];
  loading: boolean;
  error: ApolloError | null | undefined;
  refetchBlockOuts: () => Promise<void>;
  value: FormValues;
  setValue: (_val: FormValues) => void;
  showModal: boolean;
  setShowModal: (val: boolean) => void;
  cancel: () => void;
  edit: (val: FormValues) => void;
  createOrUpdate: (values: FormValues, fm: FormikHelpers<FormValues>) => Promise<void>;
}
const defaultValue = {
  title: "",
  startDate: "",
  startTime: "",
  endDate: "",
  endTime: "",
  resources: [],
};
export const BlockoutContext = React.createContext<BlockoutContext>({
  bookingBlockOuts: [],
  loading: false,
  error: null,
  refetchBlockOuts: async () => {},
  value: defaultValue,
  setValue: () => {},
  showModal: false,
  setShowModal: () => {},
  cancel: () => {},
  edit: () => {},
  createOrUpdate: async () => {},
});

interface P {
  children?: React.ReactNode;
  showModalInit?: boolean;
  valuesInit?: FormValues;
}
export const BlockoutContextProvider: React.FC<P> = ({
  children,
  showModalInit = false,
  valuesInit = defaultValue,
}) => {
  const [value, setValue] = useState<FormValues>(valuesInit);
  const [showModal, setShowModal] = useState(showModalInit);
  const toast = useToast();
  const { timezone } = useSiteContext();
  const { t } = useTranslation();

  const { data, loading, error, refetch } = useBookingBlockOutsQuery({ fetchPolicy: "network-only" });
  const [createUpdateMutation] = useCreateUpdateBookingBlockOutMutationMutation();

  const cancel = useCallback(() => {
    setValue(defaultValue);
    setShowModal(false);
  }, []);

  const createOrUpdate = useCallback(
    async (values: FormValues, fm: FormikHelpers<FormValues>) => {
      const start = getStartTime(timezone, values.startDate, values.startTime);
      const end = getEndTime(timezone, values.endDate, values.endTime);

      const result = await createUpdateMutation({
        variables: {
          input: {
            name: values.title,
            resourceUuids: values.resources,
            startDate: start.valueOf(),
            endDate: end.valueOf(),
            bundleUuid: stringNotEmpty(values.bundle) ? values.bundle : undefined,
          },
        },
      });

      if (result.data?.createUpdateBookingBlockOut.__typename === "CreateBookingBlockOutFailureResponse") {
        toast.negative(result.data.createUpdateBookingBlockOut.reason);
      } else {
        fm.resetForm();
        toast.positive(t("bookings.settings.successfullyCreateBlockOutDate"));
        cancel();
        refetch().catch(console.error);
      }
    },
    [refetch, cancel, createUpdateMutation, t, timezone, toast],
  );

  const edit = useCallback((values: FormValues) => {
    setValue(values);
    setShowModal(true);
  }, []);

  return (
    <BlockoutContext.Provider
      value={{
        bookingBlockOuts: data?.bookingBlockOuts ?? [],
        loading,
        error,
        refetchBlockOuts: async () => {
          await refetch();
        },
        value,
        setValue,
        showModal,
        setShowModal,
        edit,
        cancel,
        createOrUpdate,
      }}
    >
      {children}
    </BlockoutContext.Provider>
  );
};
