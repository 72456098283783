import React, { useContext, useMemo } from "react";
import { Menu } from "../shared/Menu";
import { ArticleForm } from "./ArticleForm";
import { useArticleQuery } from "../../generated/gateway-client";
import { useRouter } from "next/router";
import { SideModalProvider } from "../../contexts/SideModalProvider";
import { ArticleProvider } from "../../contexts/ArticleProvider";
import { CurrentPortfolio } from "../../contexts/PortfolioContext";

const maxTitleLength = 17;

export const EditArticle = () => {
  const { currentPortfolio } = useContext(CurrentPortfolio);
  const router = useRouter();
  const { slug } = router.query as { id: string; slug: string };

  const { data } = useArticleQuery({
    variables: {
      uuid: slug,
    },
    fetchPolicy: "network-only",
  });

  const truncatedTitle = useMemo(() => {
    const title = data?.cmsArticle.draftContent.title;
    if (title != null) {
      if (title.length > maxTitleLength) {
        return `${title.slice(0, maxTitleLength)}...`;
      } else {
        return title;
      }
    }
    return undefined;
  }, [data?.cmsArticle.draftContent.title]);

  if (data?.cmsArticle == null) {
    return (
      <>
        <Menu title={truncatedTitle} />
      </>
    );
  }

  return (
    <>
      <ArticleProvider article={data.cmsArticle}>
        <Menu title={truncatedTitle} />
        <SideModalProvider>
          {currentPortfolio != null && <ArticleForm portfolioUuid={currentPortfolio.uuid} />}
        </SideModalProvider>
      </ArticleProvider>
    </>
  );
};
