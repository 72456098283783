import { Literal, Union } from "runtypes";

// this is a list of localised keys that exists in e1 for each category type from request-management-api
// the localisation are in packages/localisation/src/locales/en/equiem-one.ui.json under requests.category.types
// the category types are defined in src/modules/category/categoryTypes.ts under request-management-api
const CategoryTypeKey = Union(
  Literal("accessSecurity"),
  Literal("amenities"),
  Literal("buildingMaintenance"),
  Literal("buildingManagement"),
  Literal("cctv"),
  Literal("cleaning"),
  Literal("customerService"),
  Literal("electrical"),
  Literal("escalators"),
  Literal("fabric"),
  Literal("fireSafety"),
  Literal("furnitureEquipment"),
  Literal("generalMaintenance"),
  Literal("healthSafety"),
  Literal("heatingCooling"),
  Literal("itSystems"),
  Literal("landscaping"),
  Literal("lifts"),
  Literal("lighting"),
  Literal("mechanicalElectrical"),
  Literal("miscellaneousOther"),
  Literal("parking"),
  Literal("pestControl"),
  Literal("plants"),
  Literal("plumbingDrainage"),
  Literal("toilets"),
  Literal("wasteRecycling"),
  Literal("waterManagement"),
);

export default CategoryTypeKey;
