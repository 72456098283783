import React, { useMemo } from "react";
import { Form } from "@equiem/react-admin-ui";
import { useFormikContext } from "formik";
import type { FlexTenantInput, UpdateFlexTenantInput } from "../../generated/gateway-client";
import { DateTime } from "luxon";

interface Props {
  name: "expirationDate" | "moveInDate";
  readOnly?: boolean;
}

const DateFormat = "yyyy-LL-dd";

export const MembershipDateField: React.FC<Props> = ({ name, readOnly }) => {
  const fm = useFormikContext<FlexTenantInput | UpdateFlexTenantInput>();

  const localValue = useMemo(() => {
    const numVal = fm.values[name];

    return DateTime.fromMillis(numVal).toFormat(DateFormat);
  }, [fm.values, name]);

  return (
    <Form.Input
      readOnly={readOnly}
      showChrome={readOnly !== true}
      type="date"
      name={name}
      value={localValue}
      onChange={(e) => {
        // need to convert a string to number.
        const numberVal = DateTime.fromFormat(e.target.value, DateFormat, {
          zone: "Etc/UTC",
        }).toMillis();
        fm.setFieldValue(name, numberVal).catch(console.error);
      }}
    />
  );
};
