import type { TFunction } from "@equiem/localisation-eq1";
import { formatters, useTranslation } from "@equiem/localisation-eq1";
import { Tag, Tooltip, useTheme } from "@equiem/react-admin-ui";
import { RiInformationLine } from "@equiem/react-admin-ui/icons";
import { Duration } from "luxon";
import React from "react";
import { formatPaymentMethodLocalised } from "../utils/formatPaymentMethodLocalised";
import type { Booking, Totals } from "../utils/ReportParams";
import { formatCurrency } from "../../../lib/formatCurrency";

const formatDuration = (hours: number): string => Duration.fromObject({ hours }).toFormat("hh:mm");

export const reconciliationReportTableRowHeaders = (t: TFunction) => [
  "",
  t("bookings.reports.bookingCreated"),
  t("bookings.reports.bookingStartDate"),
  t("bookings.operations.duration"),
  t("bookings.operations.bookingCost"),
  t("bookings.operations.addOnCost"),
  t("bookings.operations.additionalCharges"),
  t("bookings.operations.refunds"),
  t("bookings.operations.cancellationCost"),
  t("bookings.operations.tax"),
  t("bookings.operations.totalCost"),
  t("bookings.operations.paymentMethod"),
];

export const ReconciliationReportTotalsRow: React.FC<{
  numBookings: number;
  totals: Totals;
  currencyCode: string | null;
}> = ({ numBookings, totals, currencyCode }) => {
  const { t, i18n } = useTranslation();

  return (
    <>
      <tr>
        <td className="totals-count">
          <strong>( {t("bookings.reports.bookingCount", { count: numBookings })} )</strong>
        </td>
        <td className="totals-created"></td>
        <td className="totals-startdate"></td>
        <td className="totals-duration">
          <strong>{formatDuration(totals.durationHours)}</strong>
        </td>
        <td className="totals-resource">
          <strong>
            {currencyCode != null ? formatCurrency(totals.resourcePrice, currencyCode, i18n.language) : "-"}
          </strong>
        </td>
        <td className="totals-addons">
          <strong>
            {currencyCode != null ? formatCurrency(totals.addOnsPrice, currencyCode, i18n.language) : "-"}
          </strong>
        </td>
        <td className="totals-additonalCharges">
          <strong>
            {currencyCode != null ? formatCurrency(totals.adjustmentsTotalPrice, currencyCode, i18n.language) : "-"}
          </strong>
        </td>
        <td className="totals-refunds">
          <strong>
            {currencyCode != null
              ? formatCurrency(totals.allPartialRefundsTotalPrice, currencyCode, i18n.language)
              : "-"}
          </strong>
        </td>
        <td className="totals-cancellation">
          <strong>
            {currencyCode != null ? formatCurrency(totals.cancellationPrice, currencyCode, i18n.language) : "-"}
          </strong>
        </td>
        <td className="totals-tax">
          <strong>{currencyCode != null ? formatCurrency(totals.totalTax, currencyCode, i18n.language) : "-"}</strong>
        </td>
        <td className="totals-price">
          <strong>{currencyCode != null ? formatCurrency(totals.totalPrice, currencyCode, i18n.language) : "-"}</strong>
        </td>
        <td className="totals-paymentmethod"></td>
      </tr>
      <style jsx>{`
        td {
          word-break: break-word;
        }
      `}</style>
    </>
  );
};

export const ReconciliationReportTableRow: React.FC<{
  booking: Booking;
  currencyCode: string;
  timezone: string;
}> = ({ booking, currencyCode, timezone }) => {
  const { t, i18n } = useTranslation();
  const { colors } = useTheme(true);

  return (
    <>
      <tr className="booking-row">
        <td className="booking-reference">
          <Tag size="large">{booking.reference}</Tag>
        </td>
        <td className="booking-created">
          {formatters.dateshort(booking.created, i18n.language, { timezone })}{" "}
          {formatters.timeshort(booking.created, i18n.language, { timezone })}
        </td>
        <td className="booking-startdate">
          {formatters.dateshort(booking.startDate, i18n.language, { timezone })}{" "}
          {formatters.timeshort(booking.startDate, i18n.language, { timezone })}
        </td>
        <td className="booking-duration">{formatDuration(booking.durationHours)}</td>
        <td className="booking-resource">{formatCurrency(booking.resourcePrice, currencyCode, i18n.language)}</td>
        <td className="booking-addons">{formatCurrency(booking.addOnsPrice, currencyCode, i18n.language)}</td>
        <td className="booking-additonalCharges">
          {formatCurrency(booking.adjustmentsTotalPrice, currencyCode, i18n.language)}
        </td>
        <td className="booking-refunds">
          {formatCurrency(booking.allPartialRefundsTotalPrice, currencyCode, i18n.language)}
        </td>
        <td className="booking-cancellation">
          {formatCurrency(booking.cancellationPrice, currencyCode, i18n.language)}
        </td>
        <td className="booking-tax">{formatCurrency(booking.totalTax, currencyCode, i18n.language)}</td>
        <td
          className="booking-price"
          style={{
            whiteSpace: booking.originalTotalPrice != null ? "nowrap" : "normal",
          }}
        >
          <div className="d-flex">
            <span>{formatCurrency(booking.totalPrice, currencyCode, i18n.language)}</span>
            {booking.originalTotalPrice != null && (
              <Tooltip
                title={t("bookings.reports.totalPriceOverrideTooltip", {
                  originalTotalPrice: formatCurrency(booking.originalTotalPrice, currencyCode, i18n.language),
                })}
                showArrow
              >
                <span className="ml-2" style={{ display: "inline-flex", fontSize: 16 }}>
                  <RiInformationLine size={16} color={colors.grayscale[50]} />
                </span>
              </Tooltip>
            )}
          </div>
        </td>
        <td className="booking-paymentmethod">{formatPaymentMethodLocalised(booking.paymentMethod, t)}</td>
      </tr>
      <style jsx>{`
        td {
          word-break: break-word;
        }
      `}</style>
    </>
  );
};
