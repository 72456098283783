import { useTranslation } from "@equiem/localisation-eq1";
import { RiUserAddLine } from "@equiem/react-admin-ui/icons";
import { useShortcuts, Role } from "@equiem/lib";

import type { ShortcutEvents } from "./ShortcutEvents";

export const Shortcuts = (siteName: string, currentRole: Role) => {
  const { t } = useTranslation();
  const { dispatch } = useShortcuts<ShortcutEvents>();

  return currentRole === Role.Unknown
    ? []
    : [
        {
          onClick: () => dispatch("addUserAsKnownRole"),
          icon: RiUserAddLine,
          text: t("home.shortcuts.inviteUsers", { siteName }),
        },
      ];
};
