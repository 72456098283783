import { ButtonTray } from "@equiem/lib";
import { Button, Dropdown, useConfirmer, useToast } from "@equiem/react-admin-ui";
import React, { useContext, type FC, useEffect, useState } from "react";
import { SideModalContext } from "../../contexts/SideModalContext";
import { ArticleContext } from "../../contexts/ArticleContext";
import {
  RiArchiveLine,
  RiArrowGoBackLine,
  RiBuildingLine,
  RiCalendarLine,
  RiCloudLine,
  RiEyeOffLine,
  RiMoreFill,
} from "@equiem/react-admin-ui/icons";
import { useTranslation, formatters } from "@equiem/localisation-eq1";
import { CurrentPortfolio } from "../../contexts/PortfolioContext";
import {
  useArchiveArticleMutation,
  useRestoreArticleMutation,
  useRevertDraftMutation,
  useUnpublishArticleMutation,
} from "../../generated/gateway-client";

const ArticleAutosave: FC = () => {
  const { article } = useContext(ArticleContext);
  const { t, i18n } = useTranslation();
  const [savedLabel, setSavedLabel] = useState<string | null>(null);

  useEffect(() => {
    const int = setInterval(() => {
      if (article?.draftVersionLastSaved != null) {
        const formatted = formatters.relativeshort(article.draftVersionLastSaved, i18n.language);
        setSavedLabel(formatted);
      }
    }, 1000);

    return () => clearInterval(int);
  }, [article, i18n.language]);

  return (
    <>
      {article?.draftVersionLastSaved != null && (
        <>
          <Button variant="ghost" disabled={true}>
            <RiCloudLine size={16} className="mr-2" />{" "}
            {t("contentManagement.savedTime", { ago: savedLabel ?? "" }).toUpperCase()}
          </Button>
        </>
      )}
    </>
  );
};

interface Props {
  isSubmitting: boolean;
  submitForm: () => Promise<boolean>;
  inputIsFocused: boolean;
  formIsDirty: boolean;
  allowAutosave?: boolean;
}

export const ArticleFormBottomBar: FC<Props> = ({
  isSubmitting,
  submitForm,
  inputIsFocused,
  formIsDirty,
  allowAutosave = true,
}) => {
  const { t } = useTranslation();
  const { article, setArticle } = useContext(ArticleContext);
  const { currentPortfolio } = useContext(CurrentPortfolio);
  const { openTab } = useContext(SideModalContext);
  const [needsAutoSaving, setNeedsAutosaving] = useState(false);
  const [archiveMutation] = useArchiveArticleMutation();
  const [revertMutation] = useRevertDraftMutation();
  const [unpublishMutation] = useUnpublishArticleMutation();
  const [restoreMutation] = useRestoreArticleMutation();
  const { withConfirmation } = useConfirmer();
  const toast = useToast();

  const isPublished = article?.publishedContent != null;
  const isArchived = article?.isArchived === true;

  const saveArticle = async () => {
    const savedSuccessfully = await submitForm();
    if (savedSuccessfully) {
      toast.positive(t("contentManagement.articles.bottomBar.saveDraftSuccess"));
    }
  };

  const archiveArticle = async () => {
    if (currentPortfolio != null && article != null) {
      const rs = await archiveMutation({
        variables: {
          input: {
            article: article.uuid,
          },
        },
      });
      if (rs.data != null) {
        setArticle(rs.data.archiveCMSArticle);
        toast.neutral(t("contentManagement.articles.bottomBar.archiveSuccess"));
      }
    }
  };

  const restoreArticle = async () => {
    if (currentPortfolio != null && article != null) {
      const rs = await restoreMutation({
        variables: {
          input: {
            article: article.uuid,
          },
        },
      });
      if (rs.data != null) {
        setArticle(rs.data.restoreArchivedCMSArticle);
        toast.neutral(t("contentManagement.articles.bottomBar.restoreSuccess"));
      }
    }
  };

  const revertDraft = async () => {
    if (article != null) {
      const rs = await revertMutation({
        variables: {
          input: {
            article: article.uuid,
          },
        },
      });
      if (rs.data != null) {
        setArticle(rs.data.revertDraftCMSArticle);
        toast.neutral(t("contentManagement.articles.bottomBar.revertDraftSuccess"));
      }
    }
  };

  const unpublishArticle = async () => {
    if (article != null) {
      const rs = await unpublishMutation({
        variables: {
          input: {
            uuid: article.uuid,
          },
        },
      });
      if (rs.data != null) {
        setArticle(rs.data.unpublishCMSArticle);
        toast.neutral(t("contentManagement.articles.bottomBar.unpublishSuccess"));
      }
    }
  };

  useEffect(() => {
    // When an input is focused, set an interval to autosave the article.
    const interval = setInterval(() => {
      if (inputIsFocused && formIsDirty) {
        setNeedsAutosaving(true);
      }
    }, 30000);

    return () => {
      if (inputIsFocused && formIsDirty) {
        setNeedsAutosaving(true);
      }
      clearInterval(interval);
    };
  }, [inputIsFocused, formIsDirty]);

  useEffect(() => {
    if (needsAutoSaving && allowAutosave && !isArchived) {
      setNeedsAutosaving(false);
      void submitForm();
    }
  }, [needsAutoSaving, submitForm, allowAutosave, isArchived]);

  return (
    <ButtonTray>
      <div className="bar d-flex align-items-center justify-content-between">
        <div>
          <ArticleAutosave />
        </div>
        <div className="d-flex align-items-center">
          <Button
            disabled={isSubmitting || isArchived}
            variant="ghost"
            onClick={() => {
              void saveArticle();
            }}
          >
            {t("contentManagement.articles.bottomBar.saveDraft")}
          </Button>
          <Button
            className="ml-2"
            disabled={
              isSubmitting ||
              article == null ||
              !article.isPublishable ||
              !article.draftIsDifferentToPublished ||
              isArchived
            }
            onClick={() => {
              if (isPublished) {
                openTab("publishing");
              } else {
                openTab("sites");
              }
            }}
          >
            {isPublished
              ? t("contentManagement.articles.bottomBar.publishChanges")
              : t("contentManagement.articles.bottomBar.prepareForPublishing")}
          </Button>
          <Dropdown.Icon className="ml-2" icon={RiMoreFill} size="md" placement="left-end">
            {isPublished && !isArchived && (
              <>
                <Dropdown.Item
                  key="revert"
                  icon={RiArrowGoBackLine}
                  disabled={!article.draftIsDifferentToPublished}
                  onClick={withConfirmation({
                    title: t("contentManagement.articles.bottomBar.revertDraft"),
                    message: t("contentManagement.articles.bottomBar.revertDraftConfirmation"),
                    confirmButtonText: t("contentManagement.articles.bottomBar.revertDraftConfirm"),
                    confirmButtonVariant: "danger",
                    onConfirm: revertDraft,
                    cancelButtonText: t("common.cancelNo") ?? undefined,
                  })}
                >
                  {t("contentManagement.articles.bottomBar.revertDraft")}
                </Dropdown.Item>
                <Dropdown.Item key="publish" icon={RiCalendarLine} onClick={() => openTab("publishSettings")}>
                  {t("contentManagement.articles.bottomBar.changePublishSettings")}
                </Dropdown.Item>
                <Dropdown.Item key="sites" icon={RiBuildingLine} onClick={() => openTab("sites")}>
                  {t("contentManagement.articles.bottomBar.changeSites")}
                </Dropdown.Item>
                <Dropdown.Item
                  key="unpublish"
                  icon={RiEyeOffLine}
                  onClick={withConfirmation({
                    title: t("contentManagement.articles.bottomBar.unpublish"),
                    message: t("contentManagement.articles.bottomBar.unpublishConfirmation"),
                    confirmButtonText: t("contentManagement.articles.bottomBar.unpublishConfirm"),
                    confirmButtonVariant: "danger",
                    onConfirm: unpublishArticle,
                    cancelButtonText: t("common.cancelNo") ?? undefined,
                  })}
                >
                  {t("contentManagement.articles.bottomBar.unpublish")}
                </Dropdown.Item>
              </>
            )}
            {!isArchived ? (
              <Dropdown.Item
                key="archive"
                icon={RiArchiveLine}
                onClick={withConfirmation({
                  title: t("contentManagement.articles.bottomBar.archive"),
                  message: t("contentManagement.articles.bottomBar.archiveDescription"),
                  confirmButtonText: t("common.yesArchive"),
                  confirmButtonVariant: "danger",
                  onConfirm: archiveArticle,
                  cancelButtonText: t("common.cancelNo") ?? undefined,
                })}
              >
                {t("contentManagement.articles.bottomBar.archive")}
              </Dropdown.Item>
            ) : (
              <Dropdown.Item
                key="restore"
                icon={RiArrowGoBackLine}
                onClick={withConfirmation({
                  title: t("contentManagement.articles.bottomBar.restore"),
                  message: t("contentManagement.articles.bottomBar.restoreDescription"),
                  confirmButtonText: t("common.yesRestore"),
                  onConfirm: restoreArticle,
                  cancelButtonText: t("common.cancelNo") ?? undefined,
                })}
              >
                {t("contentManagement.articles.bottomBar.restore")}
              </Dropdown.Item>
            )}
          </Dropdown.Icon>
        </div>
      </div>
      <style jsx>{`
        .bar {
          width: 100%;
        }
      `}</style>
    </ButtonTray>
  );
};
