import { useTranslation } from "@equiem/localisation-eq1";

export const useGetFileType = (mimeType: string) => {
  const { t } = useTranslation();

  // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
  if (!mimeType) {
    return undefined;
  }

  if (mimeType.includes("image")) {
    return t("common.image");
  }

  return "PDF";
};
