import React, { useContext, useState } from "react";

import { Site } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import { Button } from "@equiem/react-admin-ui";

import { Modal } from "../../../contexts/ModalContext";
import { type CompanyDetailsQuery } from "../../../generated/settings-client";

import { BillingDetails } from "./BillingDetail";
import { CompanyBillingForm } from "./CompanyBillingForm";

interface P {
  company: NonNullable<CompanyDetailsQuery["companyV2"]>;
  modalClose: () => void;
}
export const CompanyBilling: React.FC<P> = ({ company, modalClose }) => {
  const modal = useContext(Modal);
  const site = useContext(Site);
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  if (modal.id == null) {
    return <></>;
  }

  return (
    <div>
      {company.billingCustomers.map((bc) => {
        return <BillingDetails key={bc.id} billingCustomer={bc} companyUuid={modal.id!} siteUuid={site.uuid} />;
      })}
      <div className="mt-4">
        {open ? (
          <CompanyBillingForm companyName={company.name} modalClose={modalClose} companyUuid={company.uuid} />
        ) : (
          <Button variant="secondary" onClick={() => setOpen(!open)}>
            {t("common.add")}
          </Button>
        )}
      </div>
    </div>
  );
};
