import { useTranslation } from "@equiem/localisation-eq1";
import { Alert, Button, Form, Modal, useTheme } from "@equiem/react-admin-ui";
import { RiErrorWarningLine } from "@equiem/react-admin-ui/icons";
import getSymbolFromCurrency from "currency-symbol-map";
import { type FormikErrors, Field, Form as FormikForm, Formik } from "formik";
import React, { useCallback } from "react";
import { convertInputMoneyToNumber } from "../../../../lib/convertInputMoneyToNumber";
import { CurrencyAmount } from "../../../../lib/CurrencyAmount";

interface CustomPriceModalFormValues {
  customPrice: number | null;
}

interface P {
  showModal: boolean;
  setShowModal: (show: boolean) => void;
  customPrice: number | null;
  originalTotalPrice: number;
  currencyCode: string;
  onEdit: (_customPrice: number | null) => void;
}
export const BookingPaymentCustomPriceModal: React.FC<P> = ({
  showModal,
  setShowModal,
  customPrice,
  originalTotalPrice,
  currencyCode,
  onEdit,
}) => {
  const { t } = useTranslation();
  const { breakpoints, colors, spacers } = useTheme(true);

  const validate = useCallback(
    (values: CustomPriceModalFormValues) => {
      const errors: FormikErrors<CustomPriceModalFormValues> = {};
      if (convertInputMoneyToNumber(values.customPrice) == null) {
        errors.customPrice = t("common.required");
      }
      return errors;
    },
    [t],
  );

  return (
    <Modal.Dialog
      title={t("bookings.operations.booking")}
      show={showModal}
      size="lg"
      onHide={() => setShowModal(false)}
      supportsMobile={true}
      focusTrapOptions={{ initialFocus: false }}
      hideOnClick={false}
      centered
    >
      <div className="custom-price-modal">
        <Modal.Header closeButton={true} noBorder={false} intro={t("bookings.operations.editTotalCost")} />
        <Formik<CustomPriceModalFormValues>
          initialValues={{ customPrice }}
          validate={validate}
          validateOnMount={true}
          onSubmit={(values, actions) => {
            actions.setSubmitting(true);
            onEdit(values.customPrice);
            actions.setSubmitting(false);
          }}
        >
          {({ errors, isValid, submitForm, touched }) => {
            const customPriceError = touched.customPrice === true ? errors.customPrice : undefined;

            return (
              <FormikForm>
                <Modal.Body>
                  <Alert
                    size="large"
                    variant="gray"
                    icon={<RiErrorWarningLine size={18} color={colors.grayscale[50]} />}
                    message={t("bookings.operations.editTotalCostHint")}
                  />
                  <Form.Group label={t("bookings.operations.currentTotalCost")}>
                    <CurrencyAmount
                      className="current-total-price"
                      amount={originalTotalPrice}
                      currencyCode={currencyCode}
                    />
                  </Form.Group>
                  <Form.Group label={t("bookings.operations.newTotalCost")} error={customPriceError} required>
                    <Field
                      as={Form.Money}
                      currency={getSymbolFromCurrency(currencyCode)}
                      name="customPrice"
                      min={0}
                      max={9999.99}
                      placeholder="0.00"
                    />
                  </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                  <Button className="mr-2" size="md" variant="ghost" onClick={() => setShowModal(false)}>
                    {t("common.cancel")}
                  </Button>
                  <Button
                    size="md"
                    variant="primary"
                    disabled={!isValid}
                    onClick={() => {
                      void submitForm();
                    }}
                  >
                    {t("common.confirm")}
                  </Button>
                </Modal.Footer>
              </FormikForm>
            );
          }}
        </Formik>
      </div>
      <style jsx>
        {`
          .custom-price-modal :global(.modal-body) {
            display: flex;
            flex-direction: column;
            padding: ${spacers.s6} ${spacers.s6} ${spacers.s7};
            margin: ${spacers.s0};
            gap: ${spacers.s7};
          }
          .custom-price-modal :global(.modal-body .form-group) {
            margin: ${spacers.s0};
          }
          .custom-price-modal :global(.modal-body .form-group .input-money) {
            width: 25%;
          }
          .custom-price-modal :global(.modal-body .current-total-price) {
            font-size: 14px;
            line-height: 24px;
            font-weight: 400;
          }
          .custom-price-modal :global(.footer) {
            padding: ${spacers.s5} ${spacers.s6} !important;
            gap: ${spacers.s3};
            border-top: 1px solid ${colors.grayscale[10]};
          }
          @media screen and (max-width: ${breakpoints.md}px) {
            .custom-price-modal :global(.modal-body) {
              padding: ${spacers.s5};
              gap: ${spacers.s5};
            }
            .custom-price-modal :global(.modal-body .form-group .input-money) {
              width: 100%;
            }
            .custom-price-modal :global(.footer button) {
              flex: 1;
            }
          }
        `}
      </style>
    </Modal.Dialog>
  );
};
