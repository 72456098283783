import { useTranslation } from "@equiem/localisation-eq1";
import { Tag, Text } from "@equiem/react-admin-ui";
import React, { useContext } from "react";
import { convertInputAddOnsToSummaryView } from "../libs/convertInputAddOnsToSummaryView";
import { BookableResourceAddOnType } from "../../../generated/gateway-client";
import { BookingModalInfo } from "../contexts/BookingModalInfoProvider";
import { convertBookingAddOnsToInputAddOns } from "../libs/convertBookingAddOnsToInputAddOns";

interface P {
  className?: string;
}
export const BookingViewExtras: React.FC<P> = ({ className = "" }) => {
  const { t } = useTranslation();
  const { resource, booking } = useContext(BookingModalInfo);

  if (booking == null) {
    return null;
  }

  const addOns = convertBookingAddOnsToInputAddOns(resource.addOns, booking.addOns);
  const inputAddOns = convertInputAddOnsToSummaryView(resource.addOns, addOns);
  if (inputAddOns == null || inputAddOns.length === 0) {
    return null;
  }

  return (
    <div className={`summary-extras-cont ${className}`}>
      <Text variant="heading" size="medium" className=" pb-4">
        {t("bookings.operations.addOns")}
      </Text>
      <div className="summary-extras-body">
        {inputAddOns.map((inputAddOn) =>
          inputAddOn.addOnType === BookableResourceAddOnType.FreeText ? (
            <div className="pb-6" key={inputAddOn.addOnUuid}>
              <div className="font-weight-bold pb-2">{inputAddOn.addOnName}</div>
              <span>{inputAddOn.value}</span>
            </div>
          ) : (
            <div className="pb-6" key={`${inputAddOn.addOnUuid}|${inputAddOn.optionUuid}`}>
              <div className="font-weight-bold pb-2">{inputAddOn.addOnName}</div>
              <Tag variant="default">
                {`
                  ${inputAddOn.optionName?.toUpperCase()}
                  ${inputAddOn.quantity != null && inputAddOn.quantity > 0 ? ` (x${inputAddOn.quantity})` : ""}
                `}
              </Tag>
            </div>
          ),
        )}
      </div>
    </div>
  );
};
