import React, { useContext } from "react";

import { useTranslation } from "@equiem/localisation-eq1";
import { AdvancedButton, ProgressCircle, Text, Tooltip, useTheme } from "@equiem/react-admin-ui";

import { CreateRequestContext } from "../CreateRequestContext";
import { useBuildingData } from "../hooks/useBuildingData";

export const CategoryStep: React.FC = () => {
  const { categories, loading } = useBuildingData();
  const { breakpoints } = useTheme();
  const { step, setValues, nextStep } = useContext(CreateRequestContext);
  const { t } = useTranslation();

  const handleSelect = (uuid: string) => () => {
    setValues((prev) => ({ ...prev, categoryUuid: uuid }));
    nextStep();
  };

  return (
    <div className="container">
      <Text variant="label">{t("common.step", { step })}</Text>
      <Text variant="heading" size="small">
        {t("requests.create.selectCategory")}
      </Text>
      <div className="list">
        {loading && <ProgressCircle size="md" className="w-100 d-flex justify-content-center mt-5" />}
        {!loading &&
          categories.map((category) => (
            <Tooltip title={category.name} key={category.uuid} placement="top" showArrow>
              <AdvancedButton
                title={category.name}
                materialIcon={category.iconName}
                className="mx-0"
                onClick={handleSelect(category.uuid)}
              />
            </Tooltip>
          ))}
      </div>
      <style jsx>
        {`
          .container {
            display: flex;
            flex-direction: column;
          }

          .list {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap: 8px;
            margin-top: 24px;
          }

          @media (max-width: ${breakpoints.md}px) {
            .list {
              grid-template-columns: 1fr;
            }
          }
        `}
      </style>
    </div>
  );
};
