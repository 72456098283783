import type { ReactNode } from "react";
import React from "react";

export interface FlexMembershipsContext {
  flexTenantUuids: string[] | null;
  setFlexTenantUuids: (flexTenantUuids: string[]) => void;
}

export const FlexMembershipsContext = React.createContext<FlexMembershipsContext>({
  flexTenantUuids: null,
  setFlexTenantUuids: () => undefined,
});

export const FlexMembershipsProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [flexTenantUuids, setFlexTenantUuids] = React.useState<string[] | null>(null);

  return (
    <FlexMembershipsContext.Provider
      value={{
        flexTenantUuids,
        setFlexTenantUuids,
      }}
    >
      {children}
    </FlexMembershipsContext.Provider>
  );
};
