import { SubTopMenu } from "@equiem/lib";
import type { TabItem } from "@equiem/react-admin-ui";
import { Tabs, Text } from "@equiem/react-admin-ui";
import { useRouter } from "next/router";
import React, { useMemo } from "react";
import { useTranslation } from "@equiem/localisation-eq1";

interface Props {
  title?: string;
}

export const Menu: React.FC<Props> = () => {
  const router = useRouter();
  const { t } = useTranslation();

  const tabs = useMemo<TabItem[]>(() => {
    const currentTab = router.pathname;

    const _tabs = [
      {
        title: t("flex.shortcuts.members"),
        key: "/flex/members",
      },
      {
        title: t("flex.shortcuts.memberships"),
        key: "/flex/memberships",
      },
    ];

    return _tabs.filter((tab) => tab.key === currentTab);
  }, [t, router.pathname]);

  const handleTabSelect = (key: string) => {
    void router.push({
      pathname: key,
    });
  };

  return (
    <>
      <SubTopMenu className="title-container mb-0">
        <Text variant="heading" size="large">
          {t("flex.title")}
        </Text>
      </SubTopMenu>
      <SubTopMenu btmPadding={false} topPadding={false} minHeight={false} alignItems="flex-end" sticky>
        <Tabs items={tabs} selected={router.pathname} tabLinks onSelect={handleTabSelect} />
      </SubTopMenu>
    </>
  );
};
