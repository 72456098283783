import { CurrentProfile, Site, stringNotEmpty } from "@equiem/lib";
import classNames from "classnames";
import Link from "next/link";
import { useRouter } from "next/router";
import React, { useCallback, useContext } from "react";
import { ParentLink } from "./ParentLink";
import { useTranslation } from "@equiem/localisation-eq1";
import { useTheme } from "@equiem/react-admin-ui";

interface Props {
  className?: string;
}

export const MenuLinks: React.FC<Props> = ({ className = "" }) => {
  const { t, ready } = useTranslation();

  const site = useContext(Site);
  const { profile } = useContext(CurrentProfile);
  const { breakpoints } = useTheme();
  const { asPath } = useRouter();
  const nsnx = "?ns=right_nav&nx=1";

  const subject =
    profile?.email != null
      ? encodeURIComponent(`${t("webng.webFeedback")} - ${site.name} - ${profile.email}`)
      : encodeURIComponent(`${t("webng.webFeedback")} - ${site.name}`);
  const mailTo = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      e.preventDefault();
      window.open(`mailto:${site.email ?? "support@getequiem.com"}?subject=${subject}`, "mail");
    },
    [site.email, subject],
  );

  const webHost = `https://${site.webAppUrl}`;

  return !ready ? null : (
    <div className={`menu-links ${className}`}>
      <Link href={`${webHost}/${nsnx}`} passHref legacyBehavior>
        <a className={classNames({ active: asPath.endsWith("/") })}>{t("webng.home")}</a>
      </Link>
      <Link href={`${webHost}/news${nsnx}`} passHref legacyBehavior>
        <a className={classNames({ active: /\/news(\/.+)?/u.test(asPath) })}>{t("webng.news")}</a>
      </Link>
      <Link href={`${webHost}/events${nsnx}`} passHref legacyBehavior>
        <a
          className={classNames({
            active: /\/(events|event\/.+)/u.test(asPath),
          })}
        >
          {t("webng.events")}
        </a>
      </Link>
      {site.buildingInfo.categories[0] == null ? (
        <Link href={`${webHost}/info${nsnx}`} passHref legacyBehavior>
          <a className={classNames({ active: /\/info(\?.+)?$/u.test(asPath) })}>{site.buildingInfo.title}</a>
        </Link>
      ) : (
        <ParentLink title={site.buildingInfo.title} openInit={/\/info(\/u.+)?/u.test(asPath)}>
          {site.buildingInfo.categories.map((c, i) => (
            <Link key={i} href={`${webHost}/info-category/${c.slug}${nsnx}`} passHref legacyBehavior>
              <a
                className={`small text-capitalize ${classNames({
                  active: new RegExp(`/info-category/${c.slug}`, "u").test(asPath),
                })}`}
              >
                {c.name}
              </a>
            </Link>
          ))}
          <Link href={`${webHost}/info${nsnx}`} passHref legacyBehavior>
            <a
              className={`small ${classNames({
                active: /\/info(\?.+)?$/u.test(asPath),
              })}`}
            >
              {t("webng.all")}
            </a>
          </Link>
        </ParentLink>
      )}
      {site.featureModules.bookings.enabled && site.featureModules.bookings.enableMenu !== false ? (
        <Link href={`/bookings${nsnx}`} passHref legacyBehavior>
          <a className={classNames({ active: /\/bookings(\/.+)?/u.test(asPath) })}>{t("webng.bookings")}</a>
        </Link>
      ) : null}
      {site.store.navEnabled ? (
        <Link href={`${webHost}/store${nsnx}`} passHref legacyBehavior>
          {/* Do not remove store-link class name. It's used in external automated tests */}
          <a id="store-link" className={classNames({ active: /\/store(\/.+)?/u.test(asPath) })}>
            {site.store.title}
          </a>
        </Link>
      ) : null}
      {site.featureModules.visitors.enabled && site.featureModules.visitors.enableMenu !== false ? (
        <Link href={`${webHost}/visitor-management${nsnx}`} passHref legacyBehavior>
          <a
            className={classNames({
              active: /\/visitor-management(\/.+)?/u.test(asPath),
            })}
          >
            {t("webng.visitorAppointments")}
          </a>
        </Link>
      ) : null}
      {site.featureModules.requests.enabled && site.featureModules.requests.enableMenu !== false ? (
        <Link href={`${webHost}/request-management${nsnx}`} passHref legacyBehavior>
          <a
            className={classNames({
              active: /\/request-management(\/.+)?/u.test(asPath),
            })}
          >
            {t("webng.requestManagement")}
          </a>
        </Link>
      ) : null}
      {stringNotEmpty(site.email) ? (
        <a
          onClick={mailTo}
          target="_blank"
          href={`mailto:${site.email ?? "support@getequiem.com"}?subject=${subject}`}
          rel="noreferrer"
        >
          {t("webng.contactUs")}
        </a>
      ) : null}
      {profile != null ? (
        <Link href={`${webHost}/profile${nsnx}`} passHref legacyBehavior>
          <a className={classNames({ active: /\/profile(\/.+)?/u.test(asPath) })}>{t("webng.myAccount")}</a>
        </Link>
      ) : null}
      <style jsx>{`
        .menu-links {
          padding: 32px;
        }
        a {
          text-decoration: none;
          color: ${site.primaryContrastColour};
          display: block;
          margin: 0 0 18px;
          width: 100%;
          opacity: 0.7;
          transition: 0.25s ease-in-out;
          font-size: 18px;
          font-weight: 300;
          line-height: 1.5;
        }
        a.small {
          margin: 0 0 12px;
          font-size: 12px;
        }
        a:hover,
        a.active {
          opacity: 1;
          text-decoration: none;
        }
        @media (min-width: ${breakpoints.md}px) {
          a {
            margin: 0 0 24px;
            font-size: 24px;
          }
          a.small {
            margin: 0 0 16px;
            font-size: 16px;
          }
        }
      `}</style>
    </div>
  );
};
