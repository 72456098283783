import { Form as EqForm, remainingCharacters, Modal } from "@equiem/react-admin-ui";
import { Field, Form, Formik } from "formik";
import type { FC } from "react";
import { useContext, useMemo, useState } from "react";
import { Session } from "@equiem/lib/context/SessionContext";
import type {
  CreateNewsPostMutationVariables,
  EditNewsPostMutationVariables,
  EquiemOnePostAudience,
} from "../generated/iris-client";
import { MyPostsDocument, useCreateNewsPostMutation, useEditNewsPostMutation } from "../generated/iris-client";
import { RiTeamFill, RiBuilding4Fill } from "@equiem/react-admin-ui/icons";

import dynamic from "next/dynamic";
import { ImageUploader } from "../lib/ImageUploader";
import * as yup from "yup";
import { Role, Site } from "@equiem/lib";
import { getNewsPostUrl } from "../hooks/useContentUrl";
import { DateTime } from "luxon";
import { dateTimeLocaleInputDisplayFormat } from "../lib/dateFormats";
import { FormFooter } from "../FormFooter";
import { useModalDescription } from "../lib/useModalDescription";
import { FileUploader } from "../lib/FileUploader";
import { useTranslation } from "@equiem/localisation-eq1";
import { ModalContent } from "../ContentProvider";

type FormValues = CreateNewsPostMutationVariables["input"] | EditNewsPostMutationVariables["input"];

export type NewsPost = { type: "news_post" };

export type EditNewsPostInput = NewsPost &
  Pick<EditNewsPostMutationVariables["input"], "id" | "title" | "excerpt" | "body" | "images" | "downloads"> & {
    postAudience?: EquiemOnePostAudience | undefined;
    publishAt?: string;
    draft: boolean;
  };

const CKEditorNoSSR = dynamic(async () => import("../lib/CustomCkEditor"), {
  ssr: false,
});

const validationSchema = () =>
  yup.object().shape({
    title: yup.string().max(100).required(),
    excerpt: yup.string().max(150).required(),
    publishAt: yup.string().required(),
    body: yup.string().required(),
    images: yup.array().of(
      yup
        .object()
        .shape({
          alt: yup.string().required(),
          key: yup.string().required(),
          title: yup.string().required(),
          thumbnail_key: yup.string().required(),
        })
        .required(),
    ),
    downloads: yup.array().of(
      yup.object().shape({
        key: yup.string().required(),
        title: yup.string().required(),
      }),
    ),
  });

export interface Props {
  post: EditNewsPostInput | NewsPost;
}

export const NewsPostForm: FC<Props> = ({ post }) => {
  const { t } = useTranslation();
  const { webAppUrl, timezone } = useContext(Site);

  const {
    closeModal,
    contentSaved,
    currentRole,
    setContentSaved,
    setContentUrl,
    setIsDraft,
    setModalBodyTitle,
    setModalTitle,
    setShowSiteLink,
  } = useContext(ModalContent);

  const news = useMemo(() => ("id" in post && post.id != null ? post : null), [post]);

  const audienceWithFallback = news != null ? news.postAudience ?? "WORKPLACE" : "WORKPLACE";
  const session = useContext(Session);
  const [saving, setSaving] = useState(false);
  const [isDraft, _setIsDraft] = useState(false);
  const { modalDescription } = useModalDescription();
  const [selectedPostAudience, setSelectedPostAudience] = useState(audienceWithFallback);
  const [mutation] = useCreateNewsPostMutation({
    client: session.irisClient?.cortex,
  });
  const { name: siteName } = useContext(Site);

  const modalTitle = useMemo(() => {
    if (isDraft) {
      return t("common.saved");
    }
    if (selectedPostAudience === "WORKPLACE") {
      return t("home.widgets.published");
    } else {
      return t("common.submitted");
    }
  }, [t, selectedPostAudience, isDraft]);

  const modalDesc = useMemo(() => {
    return modalDescription(selectedPostAudience, isDraft, news != null);
  }, [selectedPostAudience, isDraft, news, modalDescription]);

  const now = DateTime.local({ zone: timezone });

  const toggleDraft = (draft: boolean) => {
    _setIsDraft(draft);
    setShowSiteLink(!draft && selectedPostAudience === "WORKPLACE");
    setIsDraft(draft);
  };

  const [editMutation] = useEditNewsPostMutation({
    client: session.irisClient?.cortex,
  });
  const isNewPost = news == null;

  const initialValues: FormValues = {
    title: "",
    excerpt: "",
    body: "",
    draft: false,
    postAudience: audienceWithFallback,
    publishAt: news?.publishAt ?? now.toFormat(dateTimeLocaleInputDisplayFormat),
    role: currentRole === Role.PropertyManager ? "PROPERTY_MANAGER" : "WORKPLACE_MANAGER",
    ...news,
    // TODO, handle multiple images.
    images: [
      {
        alt: news?.images[0].alt ?? "",
        key: news?.images[0].key ?? "",
        title: news?.images[0].title ?? "",
        thumbnail_key: news?.images[0].key ?? "",
      },
    ],
  };

  return (
    <>
      {!contentSaved && (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema()}
          onSubmit={async (values, actions) => {
            setSaving(true);
            if ("id" in values) {
              const result = await editMutation({
                client: session.irisClient?.cortex,
                variables: {
                  input: {
                    id: (values as unknown as EditNewsPostMutationVariables["input"]).id,
                    title: values.title,
                    excerpt: values.excerpt,
                    body: values.body,
                    publishAt: values.publishAt,
                    images: values.images,
                    draft: isDraft,
                    downloads: values.downloads,
                  },
                },
                refetchQueries: [{ query: MyPostsDocument }],
              });

              if (result.data?.editNewsPost?.post != null && result.errors == null) {
                setModalBodyTitle(modalDesc);
                setModalTitle(modalTitle);
                setContentUrl(getNewsPostUrl(result.data.editNewsPost.post.uuid, webAppUrl));
              }
            } else {
              const result = await mutation({
                client: session.irisClient?.cortex,
                variables: {
                  input: {
                    title: values.title,
                    excerpt: values.excerpt,
                    body: values.body,
                    images: values.images,
                    postAudience: values.postAudience,
                    publishAt: values.publishAt,
                    role: values.role,
                    draft: isDraft,
                    downloads: values.downloads,
                  },
                },
                refetchQueries: [{ query: MyPostsDocument }],
              });
              if (result.data?.createNewsPost?.post != null && result.errors == null) {
                actions.setSubmitting(false);
                setModalBodyTitle(modalDesc);
                setModalTitle(modalTitle);
                setContentUrl(getNewsPostUrl(result.data.createNewsPost.post.uuid, webAppUrl));
              }
            }
            setSaving(false);
            setContentSaved(true);
          }}
        >
          {({ isValid, isSubmitting, dirty, values, submitForm }) => (
            <>
              <Modal.Body>
                <Form>
                  {isNewPost && (
                    <EqForm.Group label={t("common.audience")} required>
                      <div className="d-flex flex-row">
                        <Field
                          label={t("common.company")}
                          className="w-50 mr-2"
                          component={EqForm.HeroRadioSelect}
                          id="WORKPLACE"
                          name="postAudience"
                          icon={RiTeamFill}
                          title={t("common.workplace")}
                          description={t("home.widgets.postWorkplaceHint")}
                          onClick={() => {
                            setSelectedPostAudience("WORKPLACE");
                          }}
                        ></Field>
                        <Field
                          label={t("visitors.common.building")}
                          className="w-50 ml-2"
                          component={EqForm.HeroRadioSelect}
                          id="BUILDING"
                          icon={RiBuilding4Fill}
                          name="postAudience"
                          title={t("home.widgets.siteCommunity")}
                          description={t("home.widgets.buildingHint", { siteName })}
                          onClick={() => {
                            setSelectedPostAudience("BUILDING");
                            setShowSiteLink(false);
                          }}
                        ></Field>
                      </div>
                    </EqForm.Group>
                  )}

                  <EqForm.Group label={t("common.image")} required>
                    <Field id="images" name="images" placeholder={t("common.image")} as={ImageUploader} />
                  </EqForm.Group>
                  <EqForm.Group
                    label={t("visitors.appointmentForm.title")}
                    required
                    hint={t("common.remainingCharacters", { count: remainingCharacters(100, values.title.length) })}
                  >
                    <Field
                      id="title"
                      name="title"
                      placeholder={t("home.widgets.postTitleHint")}
                      as={EqForm.Input}
                      disabled={saving}
                      maxLength={100}
                    />
                  </EqForm.Group>
                  <EqForm.Group
                    label={t("home.widgets.introduction")}
                    required
                    hint={t("common.remainingCharacters", { count: remainingCharacters(150, values.excerpt.length) })}
                  >
                    <Field
                      id="excerpt"
                      name="excerpt"
                      placeholder={t("home.widgets.introductionHint")}
                      as={EqForm.Textarea}
                      disabled={saving}
                      maxLength={150}
                    />
                  </EqForm.Group>
                  <EqForm.Group label={t("common.body")} required>
                    <Field id="body" name="body" placeholder={t("common.body")} as={CKEditorNoSSR} disabled={saving} />
                  </EqForm.Group>
                  <EqForm.Group label={t("common.files")} showTooltip={true} tooltipText={t("home.widgets.fileLimit")}>
                    <Field id="downloads" name="downloads" placeholder={t("common.file")} as={FileUploader} />
                  </EqForm.Group>
                  <EqForm.Group
                    label={`${selectedPostAudience === "BUILDING" ? t("home.widgets.preferred") : ""} ${t(
                      "home.widgets.publicationDateTime",
                    )}`}
                    required
                  >
                    <Field
                      id="publication-date"
                      name="publishAt"
                      type="datetime-local"
                      min={now.toFormat(dateTimeLocaleInputDisplayFormat)}
                      as={EqForm.Input}
                    />
                  </EqForm.Group>
                </Form>
              </Modal.Body>
              <FormFooter
                close={closeModal}
                saveDraft={() => {
                  toggleDraft(true);
                  submitForm().catch((e) => {
                    console.error(e);
                  });
                }}
                savePublished={() => {
                  toggleDraft(false);
                  submitForm().catch((e) => {
                    console.error(e);
                  });
                }}
                postAudience={selectedPostAudience}
                disabled={!isValid || isSubmitting || (!dirty && news == null)}
                isPublished={news?.draft === false}
                saving={saving}
              />
            </>
          )}
        </Formik>
      )}
    </>
  );
};
