import type { ChangeEvent, Ref } from "react";
import React, { useCallback, forwardRef, useState } from "react";
import type { IconType } from "../../../admin-icons";
import { RiCloseCircleLine } from "../../../admin-icons";

import { useTheme } from "../../../contexts/Theme";
import { ProgressCircle } from "../../ProgressCircle/ProgressCircle";
import * as FormInputGroup from "../FormInputGroup";
import { FormInput } from "../FormInput/FormInput";
import { useAsRef } from "../../../util/useAsRef";
import { useForwardedRef } from "../../../util/useForwardedRef";
import { setControlledInputValue } from "../../../util/setControlledInputValue";
import type { Props as FormInputProps } from "../FormInput/FormInput";

// eslint-disable-next-line @typescript-eslint/no-type-alias
export type Props = Omit<FormInputProps, "value"> & {
  loading?: boolean;
  clearable?: boolean;
  value?: string;
  icon?: IconType;
  outerRef?: Ref<HTMLDivElement>;
  onClear?: () => void;
  undeletablePrefix?: string;
};

export interface DebouncedSearchArgs {
  cb: (value: string) => void;
  e: ChangeEvent<HTMLInputElement>;
}

export const FormInputExtended = forwardRef<HTMLInputElement, Props>(
  ({ loading, clearable = false, icon: Icon, outerRef, onClear: onClearInput, ...props }, forwardedRef) => {
    const { colors } = useTheme();
    const ref = useForwardedRef(forwardedRef);
    const onClearRef = useAsRef(onClearInput);
    const [localValue, setLocalValue] = useState(props.value ?? "");

    const onClear = useCallback(() => {
      if (ref.current != null) {
        setControlledInputValue(ref.current, "");
        onClearRef.current?.();
      }
    }, [ref, onClearRef]);

    return (
      <>
        <FormInputGroup.Group ref={outerRef} rounded={props.rounded}>
          {Icon == null && (loading === false || loading == null) ? null : (
            <FormInputGroup.Prefix>
              {loading === true ? (
                <ProgressCircle size={15} style={{ color: colors.muted1 }} />
              ) : Icon == null ? null : (
                <Icon color={colors.muted1} size={15} />
              )}
            </FormInputGroup.Prefix>
          )}
          <FormInput
            {...props}
            onKeyDown={(e) => {
              if (
                props.undeletablePrefix != null &&
                localValue.length === 1 &&
                (e.key === "Delete" || e.key === "Backspace")
              ) {
                e.preventDefault();
              }
            }}
            onChange={(e) => {
              if (props.undeletablePrefix == null || e.target.value.startsWith(props.undeletablePrefix)) {
                setLocalValue(e.target.value);
                props.onChange?.(e);
              }
            }}
            ref={ref}
          />
          {!clearable || localValue === "" ? null : (
            <FormInputGroup.Suffix>
              <RiCloseCircleLine
                className="clear-button"
                tabIndex={0}
                style={{
                  display: props.value === "" ? "none" : "block",
                  cursor: "pointer",
                }}
                onClick={onClear}
                onKeyDown={(e) => {
                  if (e.key === "Enter" || e.key === "Space") {
                    onClear();
                  }
                }}
                color={colors.muted1}
                size={15}
              />
            </FormInputGroup.Suffix>
          )}
        </FormInputGroup.Group>
      </>
    );
  },
);

FormInputExtended.displayName = "FormInputExtended";
