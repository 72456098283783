import React, { useCallback, useMemo } from "react";

import { stringIsEmpty, stringNotEmpty, useSaferFormikContext } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import { Dropdown, Form, Skeleton, Text, useTheme, useToast } from "@equiem/react-admin-ui";
import { RiMapPinLine } from "@equiem/react-admin-ui/icons";

import type { Space } from "../../../generated/requests-client";
import type { NewRequestFormValues } from "../../create-request/NewRequest";
import { useAutoWidth } from "../../request-details/hooks/useAutoWidth";
import { useLocationData } from "../hooks/useLocationData";

export const NewRequestLocation = ({ disabled }: { disabled: boolean }) => {
  const { ref, width } = useAutoWidth();
  const theme = useTheme();
  const { t } = useTranslation();
  const { values, setValues, resetForm } = useSaferFormikContext<NewRequestFormValues>();
  const toast = useToast();
  const { spaces, buildings, levels } = useLocationData();

  const resetSelection = useCallback(() => {
    resetForm({
      values: {
        ...values,
        buildingLevelUuid: undefined,
        buildingUuid: undefined,
        space: undefined,
        subCategory: undefined,
        category: undefined,
        queue: undefined,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const buildingsData = useMemo(() => buildings(values), [buildings, values]);
  const levelsData = useMemo(() => levels(values), [levels, values]);
  const spacesData = useMemo(() => spaces(values), [spaces, values]);

  const building = useMemo(
    () => buildingsData.find(({ uuid }) => uuid === values.buildingUuid),
    [buildingsData, values],
  );
  const level = useMemo(() => levelsData.find(({ uuid }) => uuid === values.buildingLevelUuid), [levelsData, values]);
  const space = useMemo(() => spacesData.find(({ uuid }) => uuid === values.space?.uuid), [spacesData, values]);

  const location = useMemo(() => {
    if (stringIsEmpty(values.buildingUuid) || stringIsEmpty(values.buildingLevelUuid)) {
      return t("requests.newRequest.selectLocation");
    }

    return [building?.name, level?.name, space?.name].filter(stringNotEmpty).join(", ");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [building, level, space, values.buildingUuid, values.buildingLevelUuid, values.space?.uuid]);

  const buildingOptions = useMemo(
    () =>
      buildingsData.map((b) => ({
        value: b.uuid,
        label: b.name,
      })),
    [buildingsData],
  );

  const spacesOptions = useMemo(
    () =>
      spacesData.map((s) => ({
        value: s.uuid,
        label: s.name,
      })),
    [spacesData],
  );

  const levelsOptions = useMemo(
    () =>
      levelsData.map((l) => ({
        value: l.uuid,
        label: l.name,
      })),
    [levelsData],
  );

  return (
    <>
      <div ref={ref} className="building-container">
        <Dropdown.Container
          mobileView="minimal"
          maxHeight={240}
          placement="bottom-start"
          width={width}
          trigger={
            <Form.SelectFacade disabled={disabled} showChrome>
              <div className={"d-flex align-items-center location"}>
                <RiMapPinLine className="mr-2" size="16px" /> {location}
              </div>
            </Form.SelectFacade>
          }
          onClose={() => {
            if (stringIsEmpty(values.space?.uuid)) {
              resetSelection();
              toast.negative(t("requests.details.spaceIsRequired"));
            }
          }}
        >
          <div className="inside">
            {buildings.length === 0 ? (
              <Skeleton.Line width="100%" height="40px" borderRadius={theme.borderRadius} />
            ) : (
              <>
                <div>
                  <div className="pb-3">
                    <Text variant="label" color={theme.colors.grayscale["50"]}>
                      {t("common.building")}
                    </Text>
                  </div>
                  <Form.DynamicSelect
                    className="building"
                    value={values.buildingUuid ?? ""}
                    mobileView="minimal"
                    name="building"
                    options={buildingOptions}
                    onChange={(e) => {
                      void setValues((prev) => ({
                        ...prev,
                        buildingUuid: e.target.value as string,
                        buildingLevelUuid: undefined,
                        space: undefined,
                        subCategory: undefined,
                        category: undefined,
                        queue: undefined,
                      }));
                    }}
                  />
                </div>
                {values.buildingUuid != null && building != null && (
                  <>
                    <div>
                      <div className="pb-3">
                        <Text variant="label" color={theme.colors.grayscale["50"]}>
                          {t("requests.details.level")}
                        </Text>
                      </div>
                      <Form.DynamicSelect
                        className="level"
                        disabled={values.buildingUuid == null}
                        value={values.buildingLevelUuid ?? ""}
                        mobileView="minimal"
                        name="level"
                        options={levelsOptions}
                        onChange={(e) => {
                          void setValues((prev) => ({
                            ...prev,
                            buildingLevelUuid: e.target.value as string,
                            space: undefined,
                            subCategory: undefined,
                            category: undefined,
                            queue: undefined,
                          }));
                        }}
                      />
                    </div>

                    {values.buildingLevelUuid != null && level != null && (
                      <div>
                        <div className="pb-3">
                          <Text variant="label" color={theme.colors.grayscale["50"]}>
                            {t("requests.details.space")}
                          </Text>
                        </div>
                        <Form.DynamicSelect
                          className="space"
                          disabled={values.buildingLevelUuid == null}
                          value={values.space?.uuid ?? ""}
                          mobileView="minimal"
                          name="level"
                          options={spacesOptions}
                          onChange={(e) => {
                            void setValues((prev) => ({
                              ...prev,
                              space: spacesData.find((s) => s.uuid === e.target.value) as Space,
                              subCategory: undefined,
                              category: undefined,
                              queue: undefined,
                            }));
                          }}
                        />
                      </div>
                    )}
                  </>
                )}
              </>
            )}
          </div>
        </Dropdown.Container>
      </div>

      <style jsx>{`
        .location {
          color: ${theme.colors.grayscale["60"]};
        }
        .building-container {
          margin-left: -${theme.spacers.s3};
        }
        .inside {
          display: flex;
          flex-direction: column;
          gap: ${theme.spacers.s4};
          padding: ${theme.spacers.s3};
        }
        .disabled {
          color: ${theme.colors.grayscale["40"]};
        }
      `}</style>
    </>
  );
};
