"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ForcedLocaleProvider = void 0;
var react_1 = __importStar(require("react"));
var react_i18next_1 = require("react-i18next");
var initializeFrontendReact_1 = require("../lib/initializeFrontendReact");
var config_1 = require("../lib/config");
/**
 * This provider is for standalone `equiem-one-ui` React components that are
 * rendered inside **non-React** front-ends, e.g. Segmentation `AudienceWidget`
 * in `vms-ui`, where there is no existing `I18nextProvider` React context.
 *
 * It does not obey our normal locale preference rules and should not be used in
 * an existing `react-i18next` localisation context.
 *
 * You probably want the `<TranslationProvider>` component instead of this one.
 */
var ForcedLocaleProvider = function (_a) {
    var locale = _a.locale, children = _a.children;
    var initResult = (0, react_1.useState)(function () { return (0, initializeFrontendReact_1.initialize)({ localePreferenceScope: {}, gqlRequester: null }); })[0];
    (0, react_1.useEffect)(function () {
        initResult.instance.changeLanguage(locale !== null && locale !== void 0 ? locale : config_1.defaultLocale).catch(console.error);
    }, [initResult.instance, locale]);
    if (locale == null) {
        return react_1.default.createElement(react_1.default.Fragment, null, children);
    }
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(react_i18next_1.I18nextProvider, { i18n: initResult.instance }, children)));
};
exports.ForcedLocaleProvider = ForcedLocaleProvider;
