import { useErrorTranslation, useServerMessageTranslation, useTranslation } from "@equiem/localisation-eq1";
import { Button, Form, Modal, ProgressCircle, TermsModal, useTheme, useToast } from "@equiem/react-admin-ui";
import React, { useCallback, useContext, useState } from "react";
import { BookingModal } from "../contexts/BookingModalContext";
import { createPortal } from "react-dom";
import { BookingModalInfo } from "../contexts/BookingModalInfoProvider";
import {
  useCancelResourceBookingMutation,
  BookableResourceAvailabilityCalendarDocument,
} from "../../../generated/gateway-client";
import { canCancelBooking } from "../libs/canCancelBooking";
import { canEditBooking } from "../libs/canEditBooking";
import { useCancelBookingCharges } from "../hooks/useBookResource";
import { stringNotEmpty } from "@equiem/lib";
import { useCurrencyCode } from "../../../hooks/useCurrency";
import { formatCurrency } from "../../../lib/formatCurrency";

// eslint-disable-next-line complexity
const Local: React.FC = () => {
  const toast = useToast();
  const { tServer } = useServerMessageTranslation();
  const { tError } = useErrorTranslation();
  const { t, i18n } = useTranslation();
  const { colors } = useTheme(true);

  const { booking } = useContext(BookingModalInfo);
  const modal = useContext(BookingModal);
  const [mutation, { loading: cancelling }] = useCancelResourceBookingMutation();
  const charges = useCancelBookingCharges({
    bookingReference: booking?.reference as string,
    skip: booking?.reference == null,
  });
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showTermsAndConditionsModal, setShowTermsAndConditionsModal] = useState(false);
  const [hasAcceptedCancellationCharge, setHasAcceptedCancellationCharge] = useState(false);
  const [hasAcceptedTerms, setHasAcceptedTerms] = useState(false);

  const cancellationPrice = charges.data?.bookingCharges.cancellationPrice?.total ?? 0;
  const hasCancellationPrice = cancellationPrice > 0;
  const hasTerms = stringNotEmpty(booking?.resource.editBookingTermsAndConditions);
  const currency = useCurrencyCode(booking?.resource.building?.uuid, booking?.currencyCode ?? null);

  const submitButtonDisabled =
    cancelling || (hasCancellationPrice && !hasAcceptedCancellationCharge) || (hasTerms && !hasAcceptedTerms);
  const acceptTerms =
    (hasTerms || hasCancellationPrice) &&
    (!hasTerms || hasAcceptedTerms) &&
    (!hasCancellationPrice || hasAcceptedCancellationCharge);

  const onClose = useCallback(() => {
    if (cancelling) {
      return;
    }
    setShowCancelModal(false);
  }, [cancelling]);

  const cancelBooking = useCallback(async () => {
    try {
      if (booking == null) {
        return;
      }
      const doIt = await mutation({
        variables: {
          uuid: booking.uuid,
          acceptTerms,
        },
        refetchQueries: [BookableResourceAvailabilityCalendarDocument],
      });
      const result = doIt.data?.cancelResourceBooking;
      if (result == null) {
        const message = t("bookings.operations.unableCancelBooking");
        throw new Error(message);
      }
      if (result.__typename === "BookingSuccessResponse") {
        toast.neutral(t("bookings.operations.bookingCancelledSuccess"));
        setShowCancelModal(false);
        modal.close(true);
      } else {
        toast.negative(tServer(result.localisedReason));
      }
    } catch (e: unknown) {
      toast.negative(tError(e));
    }
  }, [booking, modal, mutation, t, tError, tServer, toast, acceptTerms]);

  const cancel = useCallback(() => {
    setShowCancelModal(true);
  }, []);
  const edit = useCallback(() => {
    modal.setDisplayMode("selfForm");
  }, [modal]);

  if (booking == null || !booking.isEditable) {
    return null;
  }

  return (
    <>
      <div className="primary-actions">
        {canCancelBooking(booking) && (
          <Button variant="danger" disabled={cancelling} onClick={cancel}>
            {t("common.cancel")}
          </Button>
        )}
        {canEditBooking(booking) && (
          <Button variant="secondary" disabled={cancelling} onClick={edit}>
            {t("common.edit")}
          </Button>
        )}
      </div>

      {showCancelModal && (
        <Modal.Dialog
          title={t("bookings.operations.cancelBooking")}
          show
          onHide={onClose}
          hideOnEsc={true}
          supportsMobile={true}
          hideOnClick={false}
          mobileView
          size="md"
          centered
        >
          <Modal.Header closeButton={false} noBorder={true} />
          <Modal.Body>
            {t("bookings.operations.areYouSureCancel", { bookingReference: booking.reference })}
            {hasCancellationPrice && (
              <Form.Checkbox
                id="acceptPrice"
                name="acceptPrice"
                className="mt-4"
                label={
                  <>
                    {t("bookings.resources.cancellationFeeConsent", {
                      fee: formatCurrency(cancellationPrice, currency, i18n.language),
                    })}
                  </>
                }
                checked={hasAcceptedCancellationCharge}
                onChange={(e) => setHasAcceptedCancellationCharge(e.target.checked)}
              />
            )}
            {hasTerms && (
              <Form.Checkbox
                id="acceptTerms"
                name="acceptTerms"
                className="mt-4"
                label={
                  <>
                    {t("common.iAcceptThe")}{" "}
                    <a onClick={() => setShowTermsAndConditionsModal(true)} className="edit-cancel-tc-link">
                      {t("bookings.operations.editOrCancelPolicy").toLowerCase()}
                    </a>
                  </>
                }
                checked={hasAcceptedTerms}
                onChange={(e) => setHasAcceptedTerms(e.target.checked)}
              />
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="ghost" className="mr-4" onClick={onClose} disabled={cancelling}>
              {t("bookings.operations.noTakeMeBack")}
            </Button>
            <Button
              variant="danger"
              disabled={submitButtonDisabled}
              onClick={() => {
                cancelBooking().catch(console.error);
              }}
            >
              {cancelling && <ProgressCircle mode="indeterminate" size="xs" />}
              {t("bookings.operations.yesCancelBooking")}
            </Button>
          </Modal.Footer>
        </Modal.Dialog>
      )}
      <TermsModal
        type="termsAndConditions"
        terms={booking.resource.editBookingTermsAndConditions ?? ""}
        show={showTermsAndConditionsModal}
        siteName={booking.resource.name}
        headerOverride={`${booking.resource.name} ${t("bookings.operations.editOrCancelPolicy").toLowerCase()}`}
        onHide={() => setShowTermsAndConditionsModal(false)}
      />
      <style jsx>{`
        .primary-actions {
          display: flex;
          gap: 8px;
        }
        .primary-actions :global(button) {
          width: 100%;
        }
        .edit-cancel-tc-link {
          text-decoration: none;
          color: ${colors.blue[100]};
        }
        .edit-cancel-tc-link:hover {
          text-decoration: underline;
          cursor: pointer;
        }
      `}</style>
    </>
  );
};

export const BookingViewButton: React.FC = () => {
  const modal = useContext(BookingModal);

  return modal.footerRef?.current == null ? null : createPortal(<Local />, modal.footerRef.current);
};
