import { useApolloErrorTranslation, useTranslation } from "@equiem/localisation-eq1";
import { Form, ProgressCircle } from "@equiem/react-admin-ui";
import type { FieldProps } from "formik";
import { Field, useFormikContext } from "formik";
import React from "react";
import { useBookableResourceTypesQuery } from "../../../../generated/gateway-client";
import type { FormValues } from "../../../../lib/formValidation";
import { resourceTypeToString } from "../../../../lib/resourceTypeToString";

interface P {
  className?: string;
}

export const ResourceTypes: React.FC<P> = ({ className }) => {
  const { t } = useTranslation();
  const { tError } = useApolloErrorTranslation();
  const fm = useFormikContext<FormValues>();
  const { data, loading, error } = useBookableResourceTypesQuery({
    variables: { siteUuid: fm.values.site },
    fetchPolicy: "cache-and-network",
  });

  const typeError = fm.touched.typeV2 === true ? fm.errors.typeV2 : undefined;

  if (loading) {
    return (
      <Form.Group label={t("bookings.resources.resourceType")} className={className} error={typeError} required>
        <ProgressCircle size="sm" />
      </Form.Group>
    );
  }

  if (error != null) {
    return <span>{tError(error)}</span>;
  }

  const resourceTypes = data?.bookableResourceTypes ?? [];
  if (resourceTypes.length === 0) {
    return null;
  }

  return (
    <Form.Group label={t("bookings.resources.resourceType")} className={className} error={typeError} required>
      <Field name="typeV2" placeholder={t("common.select")} className="resource-type" disabled={fm.isSubmitting}>
        {({ field }: FieldProps<string, FormValues>) => (
          <Form.Select {...field}>
            <option value="">{t("common.pleaseSelect")}</option>
            {resourceTypes.map((rtype) => (
              <option key={rtype.uuid} value={rtype.uuid}>
                {resourceTypeToString(rtype.name, t)}
              </option>
            ))}
          </Form.Select>
        )}
      </Field>
    </Form.Group>
  );
};
