import { type RefObject, useContext } from "react";
import { useApolloClient } from "@apollo/client";
import type { FormikProps } from "formik";
import { uniq } from "lodash";

import { stringNotEmpty } from "@equiem/lib";

import type {
  AdminProfileStatus,
  AdminRegistrationType,
  ProfileDetailsQuery,
  SegmentationUserEdgeFlexMembershipsFragmentFragment,
  SegmentationUserEdgeRolesFragmentFragment,
  UpdateUserSiteProfileInputV2,
} from "../../../generated/settings-client";
import {
  ProfileRegistrationType,
  SegmentationUserEdgeFlexMembershipsFragmentFragmentDoc,
  SegmentationUserEdgeRolesFragmentFragmentDoc,
  useAssignUserToApartmentMutation,
  useUpdateApprovalPreferencesMutation,
  useUpdatePreferencesMutation,
  useUpdateProfileFlexTenantsMutation,
  useUpdateUserMutation,
} from "../../../generated/settings-client";
import { FlexMembershipsContext } from "../contexts/FlexMembershipsContext";
import type { RoleTuple } from "../roles/RolesContext";
import { Roles } from "../roles/RolesContext";
import type { FormValues } from "../types";

export const calculateGroups = (existingGroups?: string[], newGroups?: string[]) => {
  if (newGroups == null) {
    return {
      groupsToAdd: [],
      groupsToRemove: [],
    };
  }

  if (existingGroups == null) {
    return {
      groupsToAdd: newGroups,
      groupsToRemove: [],
    };
  }

  const groupsToAdd = newGroups.filter((ng) => existingGroups.find((eg) => eg === ng) == null);
  const groupsToRemove = existingGroups.filter((ng) => newGroups.find((eg) => eg === ng) == null);

  return {
    groupsToAdd,
    groupsToRemove,
  };
};

export function useUserFormSubmit(
  userUuid: string,
  siteUuid: string,
  user: ProfileDetailsQuery["profile"],
  profileFormRef: RefObject<FormikProps<FormValues>>,
  showBookingNotificationPreferences: boolean,
  showApprovalNotificationsPreferences: boolean,
) {
  const [updateUserMutation, updateUser] = useUpdateUserMutation({});
  const [updateBookingPreferencesMutation, updateNotificationPreferences] = useUpdatePreferencesMutation({});
  const [updateApprovalPreferencesMutation, updateApprovalNotificationsPreferences] =
    useUpdateApprovalPreferencesMutation({});
  const [updateProfileFlexTenantsMutation, updateProfileFlexTenants] = useUpdateProfileFlexTenantsMutation({});
  const [assignUserToApartmentMutation, updateApartment] = useAssignUserToApartmentMutation({});
  const { cache } = useApolloClient();
  const rolesCtx = useContext(Roles);
  const flexMembershipsCtx = useContext(FlexMembershipsContext);

  const onSubmit = async (input: FormValues) => {
    const uuid = userUuid;

    const registrationType = input.userType != null ? input.userType : ProfileRegistrationType.Commercial;

    const currentSiteProfile = user?.siteProfiles.edges[0]?.node;

    const groups = calculateGroups(
      currentSiteProfile?.groups?.map((g) => g.uuid),
      input.groups,
    );

    const siteProfile: UpdateUserSiteProfileInputV2 = {
      profileUUID: uuid,
      siteUUID: siteUuid,
      registrationType: registrationType as unknown as AdminRegistrationType,
      siteAttributes: input.attributes,
      siteGroupsToAdd: groups.groupsToAdd,
      siteGroupsToRemove: groups.groupsToRemove,
      subscribedToEmails: input.subscribedToEmails ?? undefined,
      subscribedToNotifications: input.subscribedToNotifications ?? undefined,
    };

    const mutations: Array<Promise<unknown>> = [];

    if (profileFormRef.current?.dirty === true) {
      mutations.push(
        updateUserMutation({
          variables: {
            input: {
              profile: {
                uuid,
                firstName: input.firstName,
                lastName: input.lastName,
                email: input.email,
                mobileNumber: stringNotEmpty(input.mobileNumber) ? input.mobileNumber : null,
                public: user?.public ?? false,
                status: input.status as unknown as AdminProfileStatus,
                avatar: input.avatar ?? undefined,
                company: input.company ?? undefined,
                siteProfileV2: siteProfile,
              },
            },
          },
          refetchQueries: ["UserList"],
        }).then((r) => {
          if (r.data?.updateUser.__typename === "UpdateUserFailure") {
            throw new Error(r.data.updateUser.reason);
          }
        }),
      );

      if (showBookingNotificationPreferences) {
        mutations.push(
          updateBookingPreferencesMutation({
            variables: {
              input: {
                endUser: {
                  updateEmail: input.notificationPreferences.endUserUpdateEmail,
                  awaitingApprovalEmail: input.notificationPreferences.endUserAwaitingApprovalEmail,
                  cancellationEmail: input.notificationPreferences.endUserCancellationEmail,
                  chargedAdjustmentEmail: input.notificationPreferences.endUserChargedAdjustmentEmail,
                  confirmationEmail: input.notificationPreferences.endUserConfirmationEmail,
                  declinedBookingEmail: input.notificationPreferences.endUserDeclinedBookingEmail,
                  reminderEmail: input.notificationPreferences.endUserReminderEmail,
                },
                admin: {
                  approvalRequestEmail: input.notificationPreferences.adminApprovalRequestEmail,
                  confirmationEmail: input.notificationPreferences.adminConfirmationEmail,
                  updateEmail: input.notificationPreferences.adminUpdateEmail,
                  cancellationEmail: input.notificationPreferences.adminCancellationEmail,
                  declinedBookingEmail: input.notificationPreferences.adminDeclinedBookingEmail,
                },
              },
              profileUuid: uuid,
            },
            refetchQueries: ["ProfileDetails"],
          }),
        );
      }

      if (showApprovalNotificationsPreferences) {
        mutations.push(
          updateApprovalPreferencesMutation({
            variables: {
              input: {
                awaitingApproval: input.approvalNotificationsPreferences.awaitingApproval,
              },
              profileUuid: uuid,
            },
            refetchQueries: ["ProfileDetails"],
          }),
        );
      }
    }

    if (input.apartment != null && registrationType === ProfileRegistrationType.Residential) {
      mutations.push(
        assignUserToApartmentMutation({
          variables: {
            input: {
              apartmentUuid: input.apartment,
              destinationUuid: siteUuid,
              userUuid: uuid,
              overrideExistingUser: false,
            },
          },
        }).then((r) => {
          if (r.data?.assignUserToApartment?.__typename === "FailureResponse") {
            throw new Error(r.data.assignUserToApartment.reason);
          }
        }),
      );
    }

    if (flexMembershipsCtx.flexTenantUuids != null) {
      mutations.push(
        updateProfileFlexTenantsMutation({
          variables: {
            input: {
              profileUuid: uuid,
              flexTenantUuids: flexMembershipsCtx.flexTenantUuids,
            },
          },
        }).then((r) => {
          const id = cache.identify({ __typename: "FullProfile", uuid: userUuid });
          cache.updateFragment<SegmentationUserEdgeFlexMembershipsFragmentFragment>(
            {
              fragment: SegmentationUserEdgeFlexMembershipsFragmentFragmentDoc,
              id,
            },
            (prev) => ({
              ...prev,
              flexMemberships: r.data?.updateProfileFlexTenants ?? [],
            }),
          );
        }),
      );
    }

    if (rolesCtx.unsavedChanges) {
      mutations.push(
        rolesCtx.save(userUuid).then(() => {
          const id = cache.identify({ __typename: "SegmentationUserEdge", userUuid, siteUuid });
          const fmCb = (r: RoleTuple) =>
            r.siteUuid === siteUuid || r.siteUuid == null ? [`${r.type}#${r.relation}`] : [];
          const added = rolesCtx.updates.toAdd.flatMap(fmCb);
          const removed = rolesCtx.updates.toRemove.flatMap(fmCb);
          cache.updateFragment<SegmentationUserEdgeRolesFragmentFragment>(
            {
              fragment: SegmentationUserEdgeRolesFragmentFragmentDoc,
              id,
            },
            (prev) =>
              prev == null
                ? null
                : {
                    ...prev,
                    authorizationRoles: uniq([
                      ...prev.authorizationRoles.filter((r) => !removed.includes(r)),
                      ...added,
                    ]),
                  },
          );
        }),
      );
    }

    return Promise.all(mutations);
  };

  return {
    loading:
      updateUser.loading ||
      updateApartment.loading ||
      updateNotificationPreferences.loading ||
      updateApprovalNotificationsPreferences.loading ||
      updateProfileFlexTenants.loading,
    onSubmit,
  };
}
