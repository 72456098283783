import { useCallback } from "react";
import { useTranslation, formatters } from "@equiem/localisation-eq1";

import type { FilterType } from "../contexts/SegmentProvider";
import { groupItems } from "../lib/groupItems";
import { mapFilterToFilterType } from "../lib/mapGraphqlFilterType";
import type { SFI } from "../lib/sfi";

const SUMMARY_LIMIT = 4;

export const useSummaryFilter = (
  items: SFI[],
): [(filterType: FilterType) => string, (filterType: FilterType) => number] => {
  const { t, i18n } = useTranslation();

  const filteredItems = useCallback(
    (filterType: FilterType) => {
      return groupItems(items.filter((i) => i.filterType === mapFilterToFilterType(filterType) && i.checked));
    },
    [items],
  );

  const count = useCallback(
    (filterType: FilterType) => {
      return filteredItems(filterType).length;
    },
    [filteredItems],
  );

  const summary = useCallback(
    (filterType: FilterType) => {
      const it = filteredItems(filterType);
      if (it.length === 0) {
        return t("common.emptyNone");
      }

      const summaryItems = it.slice(0, SUMMARY_LIMIT).map((i) => i.name.trim());
      if (it.length > SUMMARY_LIMIT) {
        summaryItems.push(t("segmentation.plusMore", { count: it.length - SUMMARY_LIMIT }));
      }

      return formatters.list(summaryItems, i18n.language);
    },
    [filteredItems, t, i18n.language],
  );

  return [summary, count];
};
