export const fileToImageUrl = async (
  file: File
): Promise<string | ArrayBuffer> => {
  return new Promise((resolve, _reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result!);
    };

    reader.readAsDataURL(file);
  });
};
