import type { TFunction } from "@equiem/localisation-eq1";

import { BookingStatus } from "../../../generated/gateway-client";

export const formatBookingStatusLocalised = (status: BookingStatus, t: TFunction) => {
  switch (status) {
    case BookingStatus.PendingApproval:
      return t("bookings.operations.status.pendingApproval");
    case BookingStatus.PendingWorkplaceManagerApproval:
      return t("bookings.operations.status.pendingWorkplaceManagerApproval");
    case BookingStatus.Approved:
      return t("bookings.operations.status.approved");
    case BookingStatus.Declined:
      return t("bookings.operations.status.declined");
    case BookingStatus.Cancelled:
      return t("bookings.reports.cancelled");
    case BookingStatus.PendingPayment:
      return t("bookings.operations.status.pendingPayment");
    case BookingStatus.PaymentFailed:
      return t("bookings.operations.status.paymentFailed");
    default:
      return "";
  }
};
