import React, { useContext, useEffect, useMemo, useState } from "react";
import { useTheme } from "../../contexts/Theme";
import { Dropdown } from "../Dropdown";
import { DropdownMenu } from "../Dropdown/DropdownMenu/DropdownMenu";
import { FilterChip } from "./FilterChip";
import { ComplexFilterContext } from "./ComplexFilterContext";
import { Text } from "../Text/Text";
import * as Form from "../Form";
import { RiSearchLine } from "../../admin-icons";
import type { FilterItemOptions, FilterItemProps, FilterOption } from "./ComplexFilter.types";
import { FilterOptionsModifier, FilterType } from "./ComplexFilter.types";
import { useTranslation } from "@equiem/localisation-eq1";
import { ModifierSelect } from "./ModifierSelect";
import { stringNotEmpty } from "../../util/stringNotEmpty";
import { useDebounced } from "../../hooks";

export const defaultModifier = FilterOptionsModifier.includes;

const saveWaitTimeMs = 500;
export const FilterOptions: React.FC<FilterItemProps<FilterItemOptions>> = ({
  title,
  name,
  options,
  modifiers,
  icon,
  searchCallback,
}) => {
  const { t } = useTranslation();
  const { colors } = useTheme();
  const { values, setValue, removeValue } = useContext(ComplexFilterContext);
  const [search, setSearch] = useState("");
  const [selectedModifier, setSelectedModifier] = useState<FilterOptionsModifier>(
    (values[name]?.modifier ?? modifiers?.[0] ?? defaultModifier) as FilterOptionsModifier,
  );
  const debouncedSearch = useDebounced(search, saveWaitTimeMs);
  const [prevSelectedModifier, setPrevSelectedModifier] = useState(selectedModifier);

  if (selectedModifier !== prevSelectedModifier) {
    setPrevSelectedModifier(selectedModifier);
    setValue(name, {
      type: FilterType.options,
      modifier: selectedModifier,
      value: undefined,
    });
  }

  useEffect(() => {
    searchCallback?.(debouncedSearch).catch((e) => {
      // eslint-disable-next-line no-console
      console.error(e);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearch]);

  const filteredOptions = useMemo(() => {
    const loweredSearch = search.toLowerCase();

    return options.filter((option) => {
      if (!stringNotEmpty(loweredSearch)) {
        return true;
      }

      const toSearch =
        option.searchKeywords != null && option.searchKeywords.length > 0 ? option.searchKeywords : [option.label];

      return toSearch.some((keyword) => keyword.toLowerCase().includes(loweredSearch));
    });
  }, [options, search]);

  const handleChange = (option: FilterOption) => () => {
    const currentValue = (values[name]?.value ?? []) as FilterOption[];
    const checked = !currentValue.map((c) => c.value).includes(option.value);

    if (checked) {
      setValue(name, {
        type: FilterType.options,
        modifier: selectedModifier,
        value: [...currentValue, option],
      });
    } else {
      setValue(name, {
        type: FilterType.options,
        modifier: selectedModifier,
        value: currentValue.filter((v) => v.value !== option.value),
      });
    }
  };

  const handleSearch = (searchValue: string) => {
    const value = (values[name]?.value ?? []) as FilterOption[];
    setSearch(searchValue);

    const filterValue = {
      type: FilterType.options,
      modifier: selectedModifier,
      value,
    } as const;
    setValue(name, searchValue.length > 0 ? { ...filterValue, search: searchValue } : filterValue);
  };

  const handleClose = () => {
    handleSearch("");
  };

  const isEmpty = selectedModifier === FilterOptionsModifier.empty;

  const getLocalizedTitle = (modifier: FilterOptionsModifier) => {
    switch (modifier) {
      case FilterOptionsModifier.includes:
        return t("common.includes");
      case FilterOptionsModifier.excludes:
        return t("common.excludes");
      case FilterOptionsModifier.empty:
        return t("common.isEmpty");
      default:
        return modifier;
    }
  };

  const valueTitle = isEmpty ? t("common.isEmpty") : values[name]?.value;

  return (
    <>
      <Dropdown placement="bottom-start" flip onClose={handleClose}>
        <FilterChip
          title={title}
          value={valueTitle}
          name={name}
          type={FilterType.options}
          onClose={() => removeValue(name)}
          icon={icon}
        />
        <DropdownMenu width={252} mobileView="regular">
          <div className="options-top">
            {modifiers != null && (
              <>
                <Text variant="label" className="m-0">
                  {title}
                </Text>
                <ModifierSelect
                  options={modifiers.map((m) => ({
                    value: m,
                    label: getLocalizedTitle(m),
                  }))}
                  selected={selectedModifier}
                  onChange={(v) => setSelectedModifier(v as FilterOptionsModifier)}
                />
              </>
            )}
          </div>
          {!isEmpty && (
            <>
              <div className="search-wrapper">
                <Form.InputGroup.Group className="search-input">
                  <Form.InputGroup.Prefix>
                    <RiSearchLine color={colors.muted1} size={16} />
                  </Form.InputGroup.Prefix>
                  <Form.Input
                    placeholder={`${t("common.search")}...`}
                    autoComplete="off"
                    onChange={(e) => handleSearch(e.target.value)}
                    type="text"
                  />
                </Form.InputGroup.Group>
              </div>
              <div className="options-list">
                {filteredOptions.map((option) => (
                  <div
                    className={
                      ((values[name]?.value ?? []) as FilterOption[]).map((c) => c.value).includes(option.value)
                        ? "option option-active"
                        : "option"
                    }
                    key={option.value}
                    onClick={handleChange(option)}
                  >
                    <input
                      type="checkbox"
                      name={name}
                      onChange={handleChange(option)}
                      checked={((values[name]?.value ?? []) as FilterOption[])
                        .map((c) => c.value)
                        .includes(option.value)}
                      className="m-0"
                    />
                    <Text variant="text" component="label" size="small" className="ml-3">
                      {option.label}
                    </Text>
                  </div>
                ))}
              </div>
            </>
          )}
        </DropdownMenu>
      </Dropdown>
      <style jsx>{`
        .options-list {
          display: flex;
          flex-direction: column;
          gap: 0.125rem;
          padding: 0.5rem 0.5rem 0;
          overflow-y: auto;
        }
        .search-wrapper :global(.search-input) {
          margin: 0 0.5rem;
        }
        .options-top {
          padding: 0 1rem ${isEmpty ? "0" : "0.25rem"};
          display: flex;
          flex-direction: row;
          align-items: center;
          color: ${colors.grayscale[100]};
          min-height: 40px; // options top should have a minimum height to create a space for "Done" button which sticks to the top right corner.
        }

        .option {
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 0.5rem;
          cursor: pointer;
        }

        .option-active {
          background-color: ${colors.blue[10]};
          border-radius: 0.25rem;
        }

        input[type="checkbox"] {
          width: 1rem;
          height: 1rem;
          background: #ffffff;
          border: 1px solid ${colors.grayscale[20]};
          border-radius: 0.25rem;
          appearance: none;
          position: relative;
          margin: 0;
          flex-shrink: 0;
        }

        input[type="checkbox"]:checked {
          border: none;
        }

        input[type="checkbox"]:checked:before {
          content: "";
          background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAACXBIWXMAABYlAAAWJQFJUiTwAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACHSURBVHgB7ZPRDYAgDEQ7giM4ghs6GiM4AiMwwlmiH4YgqIEzIX1JwxfcC21FDMMwOgNg1nLxFDZnuMeBp0ok4ZFNaxIGY4R/6VfL8EUraK0v7rT79uShqgRa9/zyA1UJ9Bq4JxLoPe0lCbBWLScB9p5nJAItvCDBC7+R4IYnEu6XcMMwhmQHt/C0plArFXgAAAAASUVORK5CYII=");
          background-size: 1rem 1rem;
          background-repeat: no-repeat;
          background-position: center;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          border-radius: 0.25rem;
          background-color: ${colors.blue[60]};
          color: #fff;
        }
      `}</style>
    </>
  );
};
