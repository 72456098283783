import type { Tree } from "./Tree";

export const filterTree = (
  tree: Tree[],
  keep: (subTree: Tree, ancestors: Tree[]) => boolean,
  ancestors: Tree[] = [],
): Tree[] => {
  return tree.flatMap((subTree) => {
    const newSubTree: Tree = {
      ...subTree,
      children: filterTree(subTree.children ?? [], keep, [...ancestors, subTree]),
    };

    return (newSubTree.children?.length ?? 0) > 0 || keep(newSubTree, ancestors) ? [newSubTree] : [];
  });
};
