import React from "react";
import { Skeleton, useTheme } from "@equiem/react-admin-ui";

interface Props {
  showImages?: boolean;
  bodyParagraphs?: number;
}

export const ArticleLoading: React.FC<Props> = ({ showImages = true, bodyParagraphs = 5 }) => {
  const { spacers, colors, borderRadius } = useTheme();

  return (
    <>
      <div className="article">
        <div className="byline">
          <div className="avatar">
            <Skeleton.Line width="32px" height="32px" borderRadius="50%" />
          </div>
          <div className="details">
            <Skeleton.Line className="mb-2" width="100%" height="16px" />
            <Skeleton.Line className="mb-2" width="100%" height="12px" />
          </div>
          <div className="categories">
            <Skeleton.Line className="mb-2" width="50%" height="16px" />
          </div>
        </div>
        <hr className="my-6" />
        <Skeleton.Line width="67%" height="28px" />
        <Skeleton.Line className="mt-3 mb-5" width="100%" height="72px" />
        {showImages && (
          <Skeleton.Line className="image-carousel mb-6" width="100%" height="auto" borderRadius={borderRadius} />
        )}
        {[...Array(bodyParagraphs).keys()].map((key) => (
          <Skeleton.Line key={key} className="mb-4" width="100%" height="150px" />
        ))}
      </div>
      <style jsx>{`
        .article :global(span) {
          display: block;
        }
        .byline {
          display: flex;
          flex-direction: row;
          gap: ${spacers.s3};
        }
        .avatar {
          flex: 0;
        }
        .details {
          flex: 1;
        }
        .categories {
          flex: 1;
          display: flex;
          justify-content: flex-end;
        }
        hr {
          border: 0;
          border-bottom: 1px solid ${colors.border};
        }
        .article :global(.image-carousel) {
          aspect-ratio: 3 / 2;
        }
      `}</style>
    </>
  );
};
