import React from "react";
import type { IconType } from "react-icons";

import { useTheme } from "../../contexts/Theme";
import { Tooltip } from "../Tooltip/Tooltip";

/* eslint-disable @typescript-eslint/no-type-alias */
export type RadioIconOption<T extends string | number = string> = {
  icon: IconType;
  value: T;
  tooltipText?: string;
  className?: string;
};

export type RadioIconsProps<T extends string | number = string> = {
  value: T;
  options: Array<RadioIconOption<T>>;
  onChange: (value: T) => unknown;
};

const WithOptionalTooltip: React.FC<React.PropsWithChildren<{ tooltipText?: string }>> = ({
  tooltipText,
  children,
}) => {
  if (tooltipText == null) {
    return <>{children}</>;
  }

  return <Tooltip title={tooltipText}>{children}</Tooltip>;
};

export const RadioIcons = <T extends string | number = string>({
  value,
  options,
  onChange,
}: RadioIconsProps<T>): React.ReactElement => {
  const theme = useTheme(true);
  return (
    <>
      <div className="radio-icons">
        {options.map(({ icon: Icon, value: optionValue, tooltipText, className }) => (
          <WithOptionalTooltip key={optionValue} tooltipText={tooltipText}>
            <button
              className={`option ${value === optionValue ? "selected" : ""} ${className ?? ""}`}
              onClick={() => onChange(optionValue)}
            >
              <Icon size={20} />
            </button>
          </WithOptionalTooltip>
        ))}
      </div>
      <style jsx>{`
        .radio-icons {
          display: flex;
          flex-shrink: 0;
          border: 1px solid ${theme.colors.border};
          border-radius: ${theme.borderRadius};
          padding: 2px;
        }
        .option {
          display: inline-block;
          padding: 5px;
          margin: 2px;
          border: none;
          border-radius: ${theme.borderRadius};
          background: none;
          color: ${theme.colors.dark};
          cursor: pointer;
        }
        .option:hover {
          background: ${theme.colors.secondary};
        }
        .option.selected {
          background: ${theme.colors.secondary};
          color: ${theme.colors.primary};
          cursor: unset;
        }
      `}</style>
    </>
  );
};
