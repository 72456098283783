import type { ReactNode } from "react";
import React, { useContext } from "react";
import { CurrentRole, CurrentProfile } from "@equiem/lib";
import { Usetiful } from "./UsetifulContext";
import Script from "next/script";

export const UsetifulProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const { currentRole } = useContext(CurrentRole);
  const { profile } = useContext(CurrentProfile);

  return (
    <Usetiful.Provider value={{}}>
      {profile != null && currentRole != null && process.env.usetifulToken != null && (
        <>
          <Script
            id="usetiful-segmentation"
            strategy="afterInteractive"
            dangerouslySetInnerHTML={{
              __html: `
            window.usetifulTags = { userId : "${profile.uuid}", segment: "${currentRole}"};
            `,
            }}
          />
          <Script
            id="usetiful"
            strategy="afterInteractive"
            dangerouslySetInnerHTML={{
              __html: `
            (function (w, d, s) {
              var a = d.getElementsByTagName('head')[0];
              var r = d.createElement('script');
              r.async = 1;
              r.src = s;
              r.setAttribute('id', 'usetifulScript');
              r.dataset.token = "${process.env.usetifulToken}";
                                  a.appendChild(r);
            })(window, document, "https://www.usetiful.com/dist/usetiful.js");
            `,
            }}
          />
        </>
      )}
      {children}
    </Usetiful.Provider>
  );
};
