import { useContext, useMemo } from "react";
import { FormInputGroupContext } from "./FormInputGroup/FormInputGroupContext";

export const useInputPadding = ({
  top = "8px",
  right = "16px",
  left = "16px",
  bottom = "8px",
}: {
  top?: string;
  right?: string;
  bottom?: string;
  left?: string;
} = {}) => {
  const { hasSuffix, hasPrefix, direction } = useContext(FormInputGroupContext);

  const shorthand = useMemo(() => {
    if (direction === "row") {
      return `${top} ${hasSuffix ? "0" : right} ${bottom} ${hasPrefix ? "0" : left}`;
    } else {
      return `0 ${right} 0 ${left}`;
    }
  }, [hasSuffix, hasPrefix, direction, top, right, bottom, left]);

  return { shorthand, top, right, bottom, left };
};
