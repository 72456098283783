import type { PropsWithChildren } from "react";
import React, { useEffect, useState } from "react";
import { SelectedContext } from "../../../contexts/SelectedContext";

export interface Props {
  children: React.ReactElement[];
  defaultTab: string;
  onSelect?: (eventKey: string) => void;
}

export const ModalHeaderTabs: React.FC<PropsWithChildren<Props>> = ({ children, defaultTab, onSelect }) => {
  const [selectedTab, setSelectedTab] = useState(defaultTab);

  useEffect(() => {
    if (onSelect != null) {
      onSelect(selectedTab);
    }
  }, [onSelect, selectedTab]);

  return (
    <div className="d-flex">
      <SelectedContext.Provider
        value={{
          selected: selectedTab,
          setSelected: setSelectedTab,
        }}
      >
        {children}
      </SelectedContext.Provider>
    </div>
  );
};
