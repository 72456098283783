import React from "react";
import { BookingActivityList } from "./BookingActivityList";
import { BookingActivityLogChange } from "./BookingActivityLogChange";
import { BookingActivityLogChangeRow } from "./BookingActivityLogChangeRow";
import type { BookingFragmentFragment } from "../../../generated/gateway-client";
import { useCurrencyCode } from "../../../hooks/useCurrency";

interface Props {
  booking: BookingFragmentFragment;
}

export const BookingActivityLog: React.FC<Props> = ({ booking }) => {
  const buildingCurrency = useCurrencyCode(booking.resource.building?.uuid);
  const currencyCode = booking.currencyCode ?? buildingCurrency;

  return (
    <BookingActivityList>
      {booking.updateHistory.map((change, i) => (
        <BookingActivityLogChange key={i} change={change} timezone={booking.timezone} currency={currencyCode} />
      ))}
      <BookingActivityLogChangeRow
        changeInitiator={`${booking.createdBy.profile?.firstName} ${booking.createdBy.profile?.lastName}`}
        timestamp={booking.created}
        timezone={booking.timezone}
        changeType="created"
      />
    </BookingActivityList>
  );
};
