import { useMemo } from "react";
import { DateTime } from "luxon";

import { formatters, useTranslation } from "@equiem/localisation-eq1";

import { getAppointmentIntervals } from "../utils/appointmentIntervals";

export const useDurationPreview = (values: { duration: string; date: string; startTime: string | number }) => {
  const { i18n } = useTranslation();
  const durationPreview = useMemo(() => {
    const startTime = formatters.timeshort(DateTime.fromSeconds(Number(values.startTime)), i18n.language);
    const endTime = formatters.timeshort(
      DateTime.fromFormat(`${values.date} ${values.duration}`, "yyyy-LL-dd h:mma"),
      i18n.language,
    );
    const date = DateTime.fromFormat(values.date, "yyyy-MM-dd");
    const { year, month, day } = date.toObject();
    const { minute, second, hour } = DateTime.fromSeconds(Number(values.startTime)).toObject();
    const {
      minute: nextMinute,
      second: nextSecond,
      hour: nextHour,
    } = DateTime.fromFormat(`${values.date} ${values.duration}`, "yyyy-LL-dd h:mma").toObject();

    const minDate = DateTime.fromObject({
      hour,
      minute,
      second,
      year,
      month,
      day,
    });

    const nextDate = DateTime.fromObject({
      hour: nextHour,
      minute: nextMinute,
      second: nextSecond,
      month,
      year,
      day,
    });

    const duration = nextDate.diff(minDate).as("minutes");

    return `${startTime} - ${endTime} (${formatters.durationshort(duration, i18n.language)})`;
  }, [values.startTime, values.date, values.duration, getAppointmentIntervals, i18n.language]);

  return durationPreview;
};
