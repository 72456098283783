import { get } from "lodash";
import { DateTime } from "luxon";
import * as yup from "yup";

import type { TFunction } from "@equiem/localisation-eq1";

import type { FormValues } from "../types";

export const titleCharsCount = 100;

export const getValidationSchema = (timezone: string, t: TFunction, isWalkIn?: boolean) =>
  yup.object().shape({
    title: yup
      .string()
      .required()
      .max(titleCharsCount, t("visitors.appointmentForm.titleValidation", { value: 100 })),
    holdInLobby: yup
      .boolean()
      .optional()
      .test(
        "is-email-required",
        t("visitors.settings.holdInLobbyHostEmailRequired"),
        (value, { parent }: yup.TestContext) => {
          const isEnabledHoldInLobby = (parent as FormValues).enabledHoldInLobby === true;
          const isHostEmailRequired =
            isEnabledHoldInLobby && (parent as FormValues).host.email == null && value === true;
          return !isHostEmailRequired;
        },
      ),
    date: yup
      .date()
      .required()
      .min(DateTime.now().plus({ days: -1 }).toJSDate(), t("visitors.appointmentForm.dateValidation")),
    startTime: yup.number().test("is-valid-time", t("visitors.appointmentForm.timeValidation"), function (value) {
      const { date } = this.parent as { date?: Date };

      if (value == null || date == null) {
        return false;
      }

      const startDate = DateTime.fromJSDate(date);
      const time = DateTime.fromSeconds(value);
      const startTime = DateTime.fromObject({
        ...startDate.toObject(),
        hour: time.hour,
        minute: time.minute,
        second: time.second,
      }).setZone(timezone);

      return isWalkIn === true || startTime >= DateTime.local({ zone: timezone });
    }),
    duration: yup.string().required().notOneOf([""]),
    visitors: yup.array().min(1, t("visitors.appointmentForm.visitorsValidation")),
    location: yup.string().test("is-location-required", function (value) {
      const { bookingInfo } = this.parent as FormValues;
      return bookingInfo?.uuid == null ? value != null : true;
    }),
    additionalDates: yup.array().of(
      yup.object().shape({
        date: yup
          .date()
          .required()
          .test("is-valid-date", t("visitors.appointmentForm.dateValidation"), function (value) {
            const date = get(this, "from[1].value.date") as unknown as string | null;

            if (value == null || date == null) {
              return false;
            }

            const currentDate = DateTime.fromJSDate(value);
            const startTimeDate = DateTime.fromISO(date);

            return currentDate >= startTimeDate;
          }),
        startTime: yup.string().test("is-valid-time", t("visitors.appointmentForm.timeValidation"), function (value) {
          const { date } = this.parent as { date?: Date };

          if (value == null || date == null) {
            return false;
          }

          const time = DateTime.fromSeconds(Number(value)).toFormat("h:mma");
          const startDate = DateTime.fromJSDate(date);
          const startTime = DateTime.fromFormat(`${startDate.toISODate()} ${time}`, "yyyy-MM-dd h:mma", {
            zone: timezone,
          });

          if (startTime < DateTime.local({ zone: timezone })) {
            return false;
          }
          return true;
        }),
      }),
    ),
  });

export const visitorValidationSchema = (t: TFunction) =>
  yup.object().shape({
    firstName: yup
      .string()
      .max(100, t("settings.editProfile.charactersMaximumNum", { maxNum: "100" }))
      .required(t("visitors.appointmentForm.firstNameValidation")),
    lastName: yup
      .string()
      .max(100, t("settings.editProfile.charactersMaximumNum", { maxNum: "100" }))
      .required(t("visitors.appointmentForm.lastNameValidation")),
    companyName: yup.string().max(100, t("settings.editProfile.charactersMaximumNum", { maxNum: "100" })),
    email: yup.string().email(),
  });

export const getHostValidationSchema = (isEmailRequired: boolean, t: TFunction) =>
  yup.object().shape({
    firstName: yup
      .string()
      .max(100, t("settings.editProfile.charactersMaximumNum", { maxNum: "100" }))
      .required(t("visitors.appointmentForm.firstNameValidation")),
    lastName: yup
      .string()
      .max(100, t("settings.editProfile.charactersMaximumNum", { maxNum: "100" }))
      .required(t("visitors.appointmentForm.lastNameValidation")),
    company: yup.object().shape({
      uuid: yup
        .string()
        .min(1)
        .max(100, t("settings.editProfile.charactersMaximumNum", { maxNum: "100" }))
        .required(t("visitors.appointmentForm.companyValidation")),
    }),
    email: isEmailRequired ? yup.string().email().required() : yup.string().email(),
  });
