import React from "react";
import { stringNotEmpty, useSiteContext, useViewerTokens } from "@equiem/lib";
import { SiteHeroImageLayout, ButtonLink } from "@equiem/react-admin-ui";
import { useTranslation } from "@equiem/localisation-eq1";

export const WelcomePage: React.FC = () => {
  const {
    loggedOutHome: { heroImage, heading, subHeading },
    name,
    logo,
    showLogoOnWhiteBackground,
    primaryColour,
    termsAndConditions,
    privacyPolicy,
    siteLoaderMode: { inSiteMode },
  } = useSiteContext();
  const tokenReplacer = useViewerTokens();
  const { t } = useTranslation();

  return (
    <>
      <SiteHeroImageLayout
        heroImage={heroImage}
        name={name}
        logo={logo}
        showLogoOnWhiteBackground={showLogoOnWhiteBackground}
        primaryColour={primaryColour}
        termsAndConditions={tokenReplacer(termsAndConditions ?? "")}
        privacyPolicy={tokenReplacer(privacyPolicy ?? "")}
      >
        <h1 className="mb-2">{stringNotEmpty(heading) ? heading : t("homepage.welcomeHeadingFallback")}</h1>
        <h2 className="mb-6">{stringNotEmpty(subHeading) ? subHeading : t("homepage.welcomeSubHeadingFallback")}</h2>
        <ButtonLink className="w-100 mb-3" size="lg" variant="primary" href="/api/login">
          {t("homepage.logIn")}
        </ButtonLink>
        {inSiteMode && (
          <ButtonLink className="w-100 mb-3" size="lg" variant="outline" href="/api/login?screen_hint=signup">
            {t("homepage.register")}
          </ButtonLink>
        )}
      </SiteHeroImageLayout>
      <style jsx>{`
        h1 {
          font-size: 24px;
          line-height: 32px;
          font-weight: 700;
          text-align: center;
        }
        h2 {
          font-size: 16px;
          line-height: 24px;
          font-weight: 400;
          color: #666;
          text-align: center;
        }
      `}</style>
    </>
  );
};
