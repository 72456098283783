import { useTranslation, formatters } from "@equiem/localisation-eq1";
import { Activity, Avatar, Tag, useTheme } from "@equiem/react-admin-ui";
import { RiAccountCircleFill, RiCalendarEventLine } from "@equiem/react-admin-ui/icons";
import { DateTime } from "luxon";
import React from "react";
import type { InternalRecord, Matcher, PairCase } from "runtypes";
import { match, Null, Unknown, when } from "runtypes";
import { type ActivityFragmentFragment } from "../generated/gateway-client";
import {
  attachmentsRemoved,
  attachmentsUpdated,
  buildingLevelsUpdated,
  comment,
  dateTimeCreated,
  dateTimeDeleted,
  dateTimeUpdated,
  flexTenantCreated,
  numberCreated,
  numberDeleted,
  numberUpdated,
  profilesUpdated,
  profileUpdated,
  stringCreated,
  stringDeleted,
  stringsUpdated,
  stringUpdated,
} from "../activityMappings/generalMappings";

const formatDateTime = (timestamp: DateTime, language: string) =>
  `${formatters.dateshort(timestamp, language)} ${formatters.timeshort(timestamp, language)}`;

export interface FieldMapping {
  title: string;
  from?: React.ReactNode;
  to?: React.ReactNode;
  message?: string;
}

const NameDisplay: React.FC<{
  "data-eq-test"?: string;
  "grey"?: boolean;
  "children": React.ReactNode;
  "className"?: string;
}> = ({ "data-eq-test": dataEqTest, grey = false, className, children }) => {
  const { colors } = useTheme();

  return (
    <span
      data-eq-test={dataEqTest}
      className={className}
      style={{ whiteSpace: "nowrap", color: grey ? colors.grayscale["50"] : undefined }}
    >
      {children}
    </span>
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type CustomMapping = PairCase<InternalRecord<any, any, any>, FieldMapping>;

export type FieldNameMapping = Record<string, string>;

export const useMappings = (
  activity: ActivityFragmentFragment,
  customMappings: CustomMapping[] = [],
  customFieldNames: FieldNameMapping = {},
) => {
  const { t, i18n } = useTranslation();
  const { colors } = useTheme();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const m: Matcher<any, FieldMapping | null> = match(
    when(Null, (_a) => null),
    ...customMappings,
    /**
     * IMPORTANT: This should always be at the end so that the more specific matchers run first.
     *
     * Generic pattern matching to handle default use cases.
     */
    when(stringCreated, (a) => ({
      title: t("activity.created", { fieldName: customFieldNames[a.change.field] ?? a.change.field }),
      to: a.change.to.value,
    })),
    when(stringUpdated, (a) => ({
      title: t("activity.updated", { fieldName: customFieldNames[a.change.field] ?? a.change.field }),
      from: a.change.from.value,
      to: a.change.to.value,
    })),
    when(stringsUpdated, (a) => ({
      title: t("activity.updated", { fieldName: customFieldNames[a.change.field] ?? a.change.field }),
      from: a.change.fromValues.map((v, i) => <Tag key={i}>{v.value}</Tag>),
      to: a.change.toValues.map((v, i) => <Tag key={i}>{v.value}</Tag>),
    })),
    when(stringDeleted, (a) => ({
      title: t("activity.deleted", { fieldName: customFieldNames[a.change.field] ?? a.change.field }),
      from: a.change.from.value,
    })),
    when(numberCreated, (a) => ({
      title: t("activity.created", { fieldName: customFieldNames[a.change.field] ?? a.change.field }),
      to: a.change.to.integer,
    })),
    when(numberUpdated, (a) => ({
      title: t("activity.updated", { fieldName: customFieldNames[a.change.field] ?? a.change.field }),
      from: a.change.from.integer,
      to: a.change.to.integer,
    })),
    when(numberDeleted, (a) => ({
      title: t("activity.deleted", { fieldName: customFieldNames[a.change.field] ?? a.change.field }),
      from: a.change.from.integer,
    })),
    when(dateTimeCreated, (a) => ({
      title: t("activity.created", { fieldName: customFieldNames[a.change.field] ?? a.change.field }),
      to: (
        <span data-eq-test="datetime-to">
          <RiCalendarEventLine />
          {` ${formatDateTime(DateTime.fromMillis(a.change.to.dateTime), i18n.language)}`}
          <br />
        </span>
      ),
    })),
    when(dateTimeUpdated, (a) => ({
      title: t("activity.updated", { fieldName: customFieldNames[a.change.field] ?? a.change.field }),
      from: (
        <span data-eq-test="datetime-from">
          <RiCalendarEventLine />
          {` ${formatDateTime(DateTime.fromMillis(a.change.from.dateTime), i18n.language)}`}
          <br />
        </span>
      ),
      to: (
        <span data-eq-test="datetime-to">
          <RiCalendarEventLine />
          {` ${formatDateTime(DateTime.fromMillis(a.change.to.dateTime), i18n.language)}`}
          <br />
        </span>
      ),
    })),
    when(dateTimeDeleted, (a) => ({
      title: t("activity.deleted", { fieldName: customFieldNames[a.change.field] ?? a.change.field }),
      from: (
        <span data-eq-test="datetime-from">
          <RiCalendarEventLine />
          {` ${formatDateTime(DateTime.fromMillis(a.change.from.dateTime), i18n.language)}`}
          <br />
        </span>
      ),
    })),
    when(comment, (a) => ({
      title: a.audience === "INTERNAL" ? t("activity.toInternalTeam") : t("activity.toEveryone"),
      message: a.comment,
    })),
    when(profileUpdated, (a) => {
      const { from, to } = a.change;

      return {
        title: t("activity.updated", { fieldName: customFieldNames[a.change.field] ?? a.change.field }),
        from:
          from != null ? (
            <NameDisplay data-eq-test="name-from">
              <Avatar
                imageUrl={from.profile.avatar}
                firstName={from.profile.firstName}
                lastName={from.profile.lastName}
                size="small"
                className="mr-2"
              />
              {from.profile.firstName} {from.profile.lastName}
            </NameDisplay>
          ) : (
            <NameDisplay data-eq-test="name-from" grey>
              <RiAccountCircleFill color={colors.grayscale["40"]} className="mr-2" size="24px" />
              {t("requests.unassigned")}
            </NameDisplay>
          ),
        to:
          to != null ? (
            <NameDisplay data-eq-test="name-to">
              <Avatar
                imageUrl={to.profile.avatar}
                firstName={to.profile.firstName}
                lastName={to.profile.lastName}
                size="small"
                className="mr-2"
              />
              {to.profile.firstName} {to.profile.lastName}
            </NameDisplay>
          ) : (
            <NameDisplay data-eq-test="name-from" grey>
              <RiAccountCircleFill color={colors.grayscale["40"]} className="mr-2" size="24px" />
              {t("requests.unassigned")}
            </NameDisplay>
          ),
      };
    }),
    when(profilesUpdated, (a) => {
      const oldProfiles = a.change.fromValues.map((value) => value.profile);
      const newProfiles = a.change.toValues.map((value) => value.profile);
      const removed = oldProfiles.filter((profile) => !newProfiles.some(({ uuid }) => profile.uuid === uuid));
      const added = newProfiles.filter((profile) => !oldProfiles.some(({ uuid }) => profile.uuid === uuid));

      return {
        title: t("activity.updated", { fieldName: customFieldNames[a.change.field] ?? a.change.field }),
        to: (
          <>
            {added.map((profile) => (
              <Activity
                key={profile.uuid}
                date={new Date(activity.timestamp)}
                profile={profile}
                title={t("activity.added")}
                small
              />
            ))}
            {removed.map((profile) => (
              <Activity
                key={profile.uuid}
                date={new Date(activity.timestamp)}
                profile={profile}
                title={t("activity.removed")}
                small
              />
            ))}
          </>
        ),
      };
    }),
    when(buildingLevelsUpdated, (a) => {
      return {
        title: t("activity.updated", { fieldName: customFieldNames[a.change.field] ?? a.change.field }),
        from:
          a.change.fromValues.length > 0 ? (
            a.change.fromValues.map((v, i) => (
              <Tag key={i}>
                {v.buildingLevel.name} ({v.buildingLevel.building.name}, {v.buildingLevel.building.destination.name})
              </Tag>
            ))
          ) : (
            <Tag>{t("common.none")}</Tag>
          ),
        to:
          a.change.toValues.length > 0 ? (
            a.change.toValues.map((v, i) => (
              <Tag key={i}>
                {v.buildingLevel.name} ({v.buildingLevel.building.name}, {v.buildingLevel.building.destination.name})
              </Tag>
            ))
          ) : (
            <Tag>{t("common.none")}</Tag>
          ),
      };
    }),
    when(attachmentsUpdated, (a) => ({
      title: t("activity.attachmentAdded", { name: a.change.to.attachments.key }),
    })),
    when(attachmentsRemoved, (a) => ({
      title: t("activity.attachmentRemoved", { name: a.change.from.attachments.key }),
    })),
    when(flexTenantCreated, (a) => ({
      title: t("activity.created", { fieldName: t("activity.membership") }),
    })),
    when(Unknown, (_a) => null),
  );

  return m(activity);
};
