import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import type { FormikProps } from "formik";
import { type FormikHelpers } from "formik";

import type { CompanyFormValues } from "@equiem/lib";
import { CurrentRole, Role, Site, useCompanyFormSubmit, useShowError } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import { Modal, useConfirmer, useToast } from "@equiem/react-admin-ui";

import { Modal as ModalContext } from "../../../contexts/ModalContext";
import { useWidgetContext } from "../../../contexts/WidgetContext";
import type { CompanyListQueryVariables } from "../../../generated/settings-client";
import { useSiteDetailsQuery } from "../../../generated/settings-client";
import { CompanyDetailsForm } from "../components/CompanyDetailsForm";
import { editCompanyTabs } from "../utils/editCompanyTabs";

interface Props {
  companyListRefetchData?: {
    refetch: (_args?: Partial<CompanyListQueryVariables>) => Promise<unknown>;
    pageSize: number;
    currentPage: number;
  };
}

export const CompanyDetailsWidget: React.FC<Props> = ({ companyListRefetchData }) => {
  const { t } = useTranslation();
  const modal = useContext(ModalContext);
  const [showModal, setShowModal] = useState(false);
  const { withConfirmation } = useConfirmer();
  const { setActionState } = useWidgetContext();
  const site = useContext(Site);
  const showError = useShowError();
  const toast = useToast();
  const { currentRole } = useContext(CurrentRole);
  const isPropertyManager = currentRole === Role.PropertyManager;

  const { onSubmit } = useCompanyFormSubmit(modal.id, site.uuid);
  const tabs = editCompanyTabs(t, currentRole, true);

  const [selectedTab, setSelectedTab] = useState(tabs[0].key);

  const formInnerRef = useRef<FormikProps<CompanyFormValues>>(null);

  const isEdit = modal.id != null;
  const { data: siteDetails } = useSiteDetailsQuery({
    variables: {
      uuid: site.uuid,
    },
  });
  const isSiteIntegrationsEnabled = siteDetails?.destination.integrations?.evolution.enabled ?? false;
  const isIntegrationsEnabled = isSiteIntegrationsEnabled && isPropertyManager;

  useEffect(() => {
    if (["general", "permissions", "billing", "groups"].includes(selectedTab)) {
      setActionState("onSave", "normal");
      return;
    }

    setActionState("onSave", "hidden");
  }, [selectedTab, setActionState]);

  useEffect(() => {
    setSelectedTab(tabs[0].key);
  }, [showModal]);

  useEffect(() => {
    if (modal.activeModal === "CompanyDetails") {
      setShowModal(true);
    } else {
      setShowModal(false);
    }
  }, [modal.activeModal]);

  const onClose = useCallback(() => {
    setShowModal(false);
    modal.close();
  }, [setShowModal, modal]);

  const onCloseModal = useCallback(() => {
    formInnerRef.current?.dirty === true
      ? withConfirmation({
          title: t("common.areYouSure"),
          message: t("settings.createCompany.cancellingCreation"),
          confirmButtonText: t("common.yesCancel"),
          cancelButtonText: t("home.widgets.cancelNo"),
          confirmButtonVariant: "danger",
          onConfirm: onClose,
        })()
      : onClose();
  }, [withConfirmation, t, onClose, formInnerRef]);

  const handleSubmit =
    (initialValues: CompanyFormValues) =>
    async (values: CompanyFormValues, { setSubmitting }: FormikHelpers<CompanyFormValues>) => {
      try {
        setSubmitting(true);
        setActionState("onSave", "loading");
        await onSubmit(values, initialValues);
        if (companyListRefetchData != null) {
          const { refetch, currentPage, pageSize } = companyListRefetchData;
          await refetch({
            first: currentPage * pageSize,
          });
        }
        setShowModal(false);
        modal.close();
        toast.positive(
          isEdit
            ? t("settings.createCompany.companySuccessfullyUpdated", { companyName: values.name })
            : t("settings.createCompany.companySuccessfullyCreated", { companyName: values.name }),
        );
      } catch (e: unknown) {
        showError(e);
      }
      setSubmitting(false);
      setActionState("onSave", "normal");
    };

  const enabledTabs = isIntegrationsEnabled ? tabs : tabs.filter((tab) => tab.key !== "integrations");

  return (
    <>
      <Modal.Dialog
        title={isEdit ? t("settings.editCompany.editCompany") : t("settings.createCompany.createNew")}
        show={showModal}
        sideModal={true}
        onHide={onCloseModal}
        hideOnEsc={true}
        hideOnClick={true}
        size="md"
      >
        {showModal && (
          <CompanyDetailsForm
            handleSubmit={handleSubmit}
            isEdit={isEdit}
            showModal={showModal}
            onCloseModal={onCloseModal}
            onClose={onClose}
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
            enabledTabs={enabledTabs}
            formInnerRef={formInnerRef}
          />
        )}
      </Modal.Dialog>
    </>
  );
};
