import type { ReactNode } from "react";
import React, { createContext, useContext, useMemo } from "react";
import { IframeResizerContext } from "./IframePageInfoContext";

export interface VisibleAreaContext {
  visibleArea?: {
    top: number;
    bottom: number;
    left: number;
    right: number;
  };
}

export const VisibleAreaContext = createContext<VisibleAreaContext>({});

export const VisibleAreaProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const { pageInfo, inIframe } = useContext(IframeResizerContext);

  const visibleArea = useMemo(
    () =>
      !inIframe
        ? undefined
        : {
            bottom: Math.min(pageInfo.scrollTop - pageInfo.offsetTop + pageInfo.clientHeight, pageInfo.iframeHeight),
            top: Math.max(pageInfo.scrollTop, 0),
            right: Math.min(pageInfo.scrollLeft - pageInfo.offsetLeft + pageInfo.clientWidth, pageInfo.iframeWidth),
            left: Math.max(pageInfo.scrollLeft, 0),
          },
    [inIframe, pageInfo],
  );
  return <VisibleAreaContext.Provider value={{ visibleArea }}>{children}</VisibleAreaContext.Provider>;
};
