import React, { forwardRef, useState, useEffect } from "react";
import type { ComponentProps } from "react";
import { RiCheckLine, RiFileCopyLine } from "../../admin-icons";
import { useTranslation } from "@equiem/localisation-eq1";
import { useInputBorderCss } from "../Form/useInputBorderCss";
import { Tooltip } from "../Tooltip/Tooltip";
import { AdminButton as Button } from "../Button/AdminButton";
import { FormInput } from "../Form/FormInput/FormInput";
import { useTheme } from "../../contexts";

// eslint-disable-next-line @typescript-eslint/no-type-alias
export type CopyableValueProps = Pick<ComponentProps<"div">, "style" | "className"> & {
  label?: string;
  value: string;
  width?: string | number;
};

const showTickDuration = 2000;

export const CopyableValue = forwardRef<HTMLDivElement, CopyableValueProps>(
  ({ value, label, width, className, style }, ref) => {
    useTheme(true);
    const [copied, setCopied] = useState(false);
    const borderCss = useInputBorderCss();
    const { t } = useTranslation();
    const [id] = useState(() => `copyable-value-${Math.round(Math.random() * 10000000000)}`);

    useEffect(() => {
      const to = setTimeout(setCopied.bind(null, false), showTickDuration);

      return clearTimeout.bind(null, to);
    }, [copied]);

    return (
      <div
        className={`${borderCss.className} ${className ?? ""} pl-4`}
        ref={ref}
        style={{
          display: "inline-flex",
          justifyContent: "space-between",
          alignItems: "center",
          width,
          ...style,
        }}
      >
        {label != null && (
          <label style={{ whiteSpace: "nowrap", fontWeight: "bold" }} className="pr-4" htmlFor={id}>
            {label}
          </label>
        )}
        <FormInput
          id={id}
          value={value}
          style={{ padding: "0 2px" }}
          showChrome={false}
          onSelect={(e) => {
            e.currentTarget.select();
          }}
        />
        <Tooltip
          title={copied ? t("developer.copiedToClipboard") : t("developer.copyToClipboard")}
          showArrow
          placement="top"
        >
          <Button
            variant="ghost"
            className="p-4"
            style={{ width: 50 }}
            onClick={() => {
              window.navigator.clipboard
                .writeText(value)
                .then(() => {
                  setCopied(true);
                })
                .catch((e) => {
                  console.error(e);
                });
            }}
          >
            {copied ? <RiCheckLine size={18} /> : <RiFileCopyLine size={18} />}
          </Button>
        </Tooltip>
        {borderCss.styles}
      </div>
    );
  },
);

CopyableValue.displayName = "CopyableValue";
