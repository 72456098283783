import React from "react";

import { SubTopMenu } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import { Text, useIsMobileWidth, useTheme } from "@equiem/react-admin-ui";

import { SettingsMenu } from "./SettingsMenu";

export const SettingsHead: React.FC = () => {
  const { t } = useTranslation();
  const { breakpoints } = useTheme();
  const isMobile = useIsMobileWidth();

  return (
    <div className="page-head">
      <SubTopMenu btmPadding={false} topPadding={false} minHeight={!isMobile}>
        <Text variant="heading" size="large">
          {t("common.settings")}
        </Text>
      </SubTopMenu>

      <SubTopMenu btmPadding={false} topPadding={false} minHeight={false} alignItems="flex-end" sticky>
        <SettingsMenu />
      </SubTopMenu>

      <style jsx>{`
        .page-head {
          display: unset;
        }

        @media (max-width: ${breakpoints.lg}px) {
          .page-head :global(.main-container) {
            min-height: 48px;
          }
        }
      `}</style>
    </div>
  );
};
