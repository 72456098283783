import React from "react";
import { useSiteContext } from "@equiem/lib";
import { DateTime as DateTimeUi, Button, ProgressCircle, useTheme } from "@equiem/react-admin-ui";
import { useTranslation } from "@equiem/localisation-eq1";

import type { SiteCalendarBookingFragment } from "../../../generated/gateway-client";
import type { SiteCalendarInterval } from "../../../hooks/useSingleDayBookingsCalendar";
import { OperationsCalendarBooking, OperationsCalendarBookingShimmer } from "./OperationsCalendarBooking";

interface Props {
  loading: boolean;
  calendar: SiteCalendarInterval[];
  onBookingClicked: (booking: SiteCalendarBookingFragment) => unknown;
}

interface IntervalProps {
  dayLoading: boolean;
  interval: SiteCalendarInterval;
  onBookingClicked: (booking: SiteCalendarBookingFragment) => unknown;
}

const EmptyDay: React.FC = () => {
  const { colors, spacers } = useTheme();
  const { t } = useTranslation();

  return (
    <>
      <div className="empty">{t("bookings.operations.noBookingsOnDay")}</div>
      <style jsx>{`
        .empty {
          /* match the height of a day with 1 booking */
          padding: 45px ${spacers.s5};
          text-align: center;
          font-weight: 500;
          text-transform: uppercase;
          color: ${colors.grayscale[60]};
          border-top: 1px solid ${colors.border};
        }
      `}</style>
    </>
  );
};

const DayInterval: React.FC<IntervalProps> = ({ dayLoading, interval, onBookingClicked }) => {
  const { timezone } = useSiteContext();
  const { colors } = useTheme();
  const { t, i18n } = useTranslation();
  return (
    <>
      <div className="interval p-5">
        <div className="time mb-3">
          <DateTimeUi.TimeRange
            start={interval.startTime}
            end={interval.endTime}
            timezone={timezone}
            language={i18n.language}
          />
        </div>
        <div className="bookings">
          {dayLoading ? (
            <>
              <OperationsCalendarBookingShimmer />
              <OperationsCalendarBookingShimmer />
              <OperationsCalendarBookingShimmer />
              <OperationsCalendarBookingShimmer />
            </>
          ) : (
            interval.bookings.map((booking) => (
              <OperationsCalendarBooking
                key={booking.uuid}
                booking={booking}
                onBookingClicked={() => onBookingClicked(booking)}
              />
            ))
          )}
        </div>
        {!dayLoading && interval.hasMore && (
          <div className="more-pages mt-3">
            {interval.loading ? (
              <div className="py-2">
                <ProgressCircle size="sm" />
              </div>
            ) : (
              <Button variant="ghost" onClick={interval.fetchMore}>
                {t("bookings.operations.showMore")}
              </Button>
            )}
          </div>
        )}
      </div>
      <style jsx>{`
        .interval {
          border-top: 1px solid ${colors.border};
        }
        .time {
          font-weight: 500;
          color: ${colors.grayscale[60]};
        }
        .bookings {
          display: grid;
          grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
          grid-gap: 4px;
        }
        .more-pages {
          text-align: center;
        }
      `}</style>
    </>
  );
};

export const OperationsCalendarDay: React.FC<Props> = ({ loading, calendar, onBookingClicked }) => {
  const dayIsEmpty = calendar.every((interval) => interval.bookings.length === 0);
  return (
    <div className="day">
      {!loading && dayIsEmpty && <EmptyDay />}
      {calendar
        .filter((interval) => loading || interval.bookings.length > 0)
        .map((interval) => (
          <DayInterval
            key={interval.startTime}
            dayLoading={loading}
            interval={interval}
            onBookingClicked={onBookingClicked}
          />
        ))}
    </div>
  );
};
