import React from "react";

export const EmptyAudienceIcon = () => {
  return (
    <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="56" height="56" rx="28" fill="#F2F2F2" />
      <path
        d="M28 38C22.477 38 18 33.523 18 28C18 22.477 22.477 18 28 18C33.523 18 38 22.477 38 28C38 33.523 33.523 38 28 38ZM23.013 34.256C24.4285 35.3877 26.1877 36.0029 28 36C29.97 36 31.773 35.288 33.167 34.108C32.5157 33.4397 31.7371 32.9089 30.877 32.5468C30.017 32.1847 29.0931 31.9988 28.16 32C27.1926 31.9989 26.2355 32.1989 25.3495 32.5872C24.4634 32.9756 23.6678 33.5439 23.013 34.256ZM21.616 32.82C22.4565 31.9281 23.4707 31.2177 24.5961 30.7327C25.7216 30.2477 26.9345 29.9984 28.16 30C29.3417 29.9985 30.512 30.2304 31.6037 30.6824C32.6955 31.1344 33.6873 31.7976 34.522 32.634C35.3781 31.4291 35.8836 30.0106 35.9826 28.5359C36.0815 27.0612 35.77 25.5879 35.0825 24.2795C34.395 22.971 33.3585 21.8786 32.088 21.1234C30.8174 20.3683 29.3625 19.9798 27.8846 20.0013C26.4067 20.0227 24.9637 20.4531 23.7155 21.2448C22.4674 22.0364 21.463 23.1584 20.8137 24.4862C20.1644 25.814 19.8957 27.2957 20.0373 28.7669C20.1789 30.2382 20.7254 31.6414 21.616 32.821V32.82ZM28 29C26.9391 29 25.9217 28.5786 25.1716 27.8284C24.4214 27.0783 24 26.0609 24 25C24 23.9391 24.4214 22.9217 25.1716 22.1716C25.9217 21.4214 26.9391 21 28 21C29.0609 21 30.0783 21.4214 30.8284 22.1716C31.5786 22.9217 32 23.9391 32 25C32 26.0609 31.5786 27.0783 30.8284 27.8284C30.0783 28.5786 29.0609 29 28 29ZM28 27C28.5304 27 29.0391 26.7893 29.4142 26.4142C29.7893 26.0391 30 25.5304 30 25C30 24.4696 29.7893 23.9609 29.4142 23.5858C29.0391 23.2107 28.5304 23 28 23C27.4696 23 26.9609 23.2107 26.5858 23.5858C26.2107 23.9609 26 24.4696 26 25C26 25.5304 26.2107 26.0391 26.5858 26.4142C26.9609 26.7893 27.4696 27 28 27Z"
        fill="#999999"
      />
    </svg>
  );
};
