import React, { useContext, useState } from "react";
import { useTheme } from "../../contexts/Theme";
import { Dropdown } from "../Dropdown";
import { DropdownMenu } from "../Dropdown/DropdownMenu/DropdownMenu";
import { FilterChip } from "./FilterChip";
import { ComplexFilterContext } from "./ComplexFilterContext";
import { Text } from "../Text/Text";
import type { FilterItemProps, FilterIsSelect } from "./ComplexFilter.types";
import { FilterIsModifier, FilterType } from "./ComplexFilter.types";
import { useTranslation } from "@equiem/localisation-eq1";
import { ModifierSelect } from "./ModifierSelect";

export const FilterIs: React.FC<FilterItemProps<FilterIsSelect>> = ({ title, name, modifiers, icon }) => {
  const { t } = useTranslation();
  const { colors } = useTheme();
  const { values, setValue, removeValue } = useContext(ComplexFilterContext);
  const [selectedModifier, setSelectedModifier] = useState<FilterIsModifier>(FilterIsModifier.empty);
  const [prevSelectedModifier, setPrevSelectedModifier] = useState(selectedModifier);

  if (selectedModifier !== prevSelectedModifier) {
    setPrevSelectedModifier(selectedModifier);
    setValue(name, {
      type: FilterType.is,
      modifier: selectedModifier,
      value: selectedModifier,
    });
  }

  const getLocalizedTitle = (modifier: FilterIsModifier) => {
    switch (modifier) {
      case FilterIsModifier.is:
        return t("common.is");
      case FilterIsModifier.isnt:
        return t("common.isNot");
      case FilterIsModifier.empty:
        return t("common.pleaseSelect");
      default:
        return modifier;
    }
  };

  const value =
    values[name]?.value === FilterIsModifier.is
      ? t("common.is")
      : values[name]?.value === FilterIsModifier.isnt
      ? t("common.isNot")
      : undefined;

  return (
    <>
      <Dropdown placement="bottom-start" flip>
        <FilterChip
          value={value}
          title={title}
          name={name}
          type={FilterType.is}
          onClose={() => removeValue(name)}
          icon={icon}
        />
        <DropdownMenu width={252} mobileView="regular">
          {modifiers != null && (
            <div className="options-top">
              <Text variant="label" className="m-0">
                {title}
              </Text>
              <ModifierSelect
                options={modifiers.map((m) => ({
                  value: m,
                  label: getLocalizedTitle(m),
                }))}
                selected={selectedModifier}
                onChange={(v) => setSelectedModifier(v as FilterIsModifier)}
              />
            </div>
          )}
        </DropdownMenu>
      </Dropdown>
      <style jsx>{`
        .options-list {
          display: flex;
          flex-direction: column;
          padding: 0 0.5rem;
          gap: 0.125rem;
        }

        .options-top {
          padding: 0 1rem 0.25rem;
          display: flex;
          flex-direction: row;
          align-items: center;
          color: ${colors.grayscale[100]};
        }

        .option {
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 0.5rem;
          cursor: pointer;
        }

        .option-active {
          background-color: ${colors.blue[10]};
          border-radius: 0.25rem;
        }

        input[type="radio"] {
          width: 16px;
          height: 16px;
          background: #ffffff;
          border: 1px solid ${colors.grayscale[20]};
          border-radius: 50%;
          appearance: none;
          position: relative;
          margin: 0.25rem;
        }

        input[type="radio"]:checked {
          border-color: ${colors.blue[60]};
        }

        input[type="radio"]:checked:before {
          content: "";
          position: absolute;
          transform: translate(50%, 50%);
          top: 0;
          left: 0;
          width: 50%;
          height: 50%;
          border-radius: 50%;
          background-color: ${colors.blue[60]};
        }
      `}</style>
    </>
  );
};
