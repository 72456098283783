import { useRouter } from "next/router";
import { useToast } from "@equiem/react-admin-ui";

import { useCloneBookableResourceMutation } from "../generated/gateway-client";
import { useTranslation } from "@equiem/localisation-eq1";

interface Params {
  uuid: string;
  name: string;
}

export const useCloneResource = ({ uuid, name }: Params) => {
  const router = useRouter();
  const toast = useToast();
  const [cloneResourceMutation, { loading: cloneLoading }] = useCloneBookableResourceMutation();
  const { t } = useTranslation();

  return async () => {
    try {
      if (cloneLoading) {
        return;
      }

      const result = await cloneResourceMutation({ variables: { uuid } });
      const cloneUuid = result.data?.cloneBookableResource.uuid;
      if (result.errors != null || cloneUuid == null) {
        throw new Error(`failed to duplicate resource: ${JSON.stringify({ result, cloneUuid })}`);
      }

      toast.positive(t("bookings.operations.resourceHasBeenDuplicated", { name }));
      await router.push(`/bookings/resources/${cloneUuid}`);
    } catch (e: unknown) {
      console.error(e);
      toast.negative(t("bookings.operations.failedToDuplicate", { name }));
    }
  };
};
