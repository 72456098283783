import React from "react";

import { useTranslation } from "@equiem/localisation-eq1";
import { IconButton, Tooltip, useConfirmer, useTheme } from "@equiem/react-admin-ui";
import { RiDeleteBin6Line } from "@equiem/react-admin-ui/icons";

interface Props {
  cardDescription: string;
  disabled: boolean;
  onDelete: () => void;
}

export const DeleteCardButton: React.FC<Props> = ({ cardDescription, disabled, onDelete }) => {
  const { withConfirmation } = useConfirmer();
  const { colors } = useTheme();
  const { t } = useTranslation();

  return (
    <IconButton
      className="card-delete"
      disabled={disabled}
      hover={{ background: colors.status.danger.accent }}
      onClick={withConfirmation({
        title: t("settings.payment.deleteCreditCardTitle"),
        message: t("common.deleteConfirmationWithTitle", { title: cardDescription }),
        confirmButtonText: t("common.yesDelete"),
        confirmButtonVariant: "danger",
        onConfirm: onDelete,
      })}
    >
      <Tooltip title={t("common.delete")} placement="bottom">
        <span>
          <RiDeleteBin6Line size={16} color={colors.danger} />
        </span>
      </Tooltip>
    </IconButton>
  );
};
