import React, { useCallback, useMemo } from "react";
import {
  useBookableResourceRoomConfigurationsQuery,
  useCreateUpdateResourceRoomConfigurationMutation,
} from "../../../../generated/gateway-client";
import { useToast } from "@equiem/react-admin-ui";
import type { FormValues } from "./helpers/FormValues";
import { RoomConfigurationDelete } from "./RoomConfigurationDelete";
import type { Item } from "./helpers/Item";
import { ResourceConfigurationList } from "./ResourceConfigurationList";
import { useTranslation, useErrorTranslation } from "@equiem/localisation-eq1";
import { resourceConfigurationToString } from "../../../../lib/resourceTypeToString";

export const RoomConfigurationList: React.FC = () => {
  const { data, loading, refetch } = useBookableResourceRoomConfigurationsQuery({
    fetchPolicy: "network-only",
  });
  const [mutation] = useCreateUpdateResourceRoomConfigurationMutation();
  const toast = useToast();
  const { t } = useTranslation();
  const { tError } = useErrorTranslation();

  const submitIt = useCallback(
    async (values: FormValues) => {
      try {
        await mutation({
          variables: {
            input: {
              name: values.name,
              uuid: values.uuid,
            },
          },
        });
        await refetch();

        return true;
      } catch (e: unknown) {
        toast.negative(tError(e), { autoDismiss: true });
      }

      return false;
    },
    [mutation, refetch, tError, toast],
  );

  const deleteComponent = (p: { item: Item; disable: boolean }) => {
    return (
      <RoomConfigurationDelete
        item={p.item}
        disable={p.disable}
        refetch={async () => {
          await refetch();
        }}
      />
    );
  };

  const roomConfigurations = useMemo(() => {
    return (data?.bookableResourceRoomConfigurations ?? []).map((roomConfig) => {
      return roomConfig.locked
        ? {
            ...roomConfig,
            name: resourceConfigurationToString(roomConfig.name, t),
          }
        : roomConfig;
    });
  }, [data?.bookableResourceRoomConfigurations, t]);

  return (
    <ResourceConfigurationList
      data={roomConfigurations}
      deleteComponent={deleteComponent}
      submitIt={submitIt}
      dataLoading={loading}
      createBtnTitle={t("bookings.settings.addConfiguration")}
    />
  );
};
