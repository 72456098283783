import { Skeleton, useTheme, useWindowDimensions } from "@equiem/react-admin-ui";
import React from "react";

export const ResourceCreateAndEditLoading: React.FC = () => {
  const { breakpoints } = useTheme(true);
  const { width } = useWindowDimensions();

  const isMobile = width <= breakpoints.md;

  return (
    <div className="loading-container">
      <div className={isMobile ? "p-5" : "px-6 py-7"}>
        <Skeleton.Line width="100%" height="468px" />
      </div>
      {!isMobile && (
        <div>
          <Skeleton.Line width="100%" height="500px" />
        </div>
      )}

      <style jsx>{`
        .loading-container {
          display: flex;
          justify-content: space-between;
        }
        .loading-container div {
          flex: 1;
        }
      `}</style>
    </div>
  );
};
