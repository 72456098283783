import React, { useContext, useMemo } from "react";
import { useRouter } from "next/router";
import { type FieldProps, Field, useFormikContext } from "formik";
import { stringIsEmpty, stringNotEmpty, CurrentProfile } from "@equiem/lib";
import { Form, useTheme, useConfirmer } from "@equiem/react-admin-ui";
import { useApolloErrorTranslation, useTranslation } from "@equiem/localisation-eq1";

import type { FormValues } from "../../../../../lib/formValidation";
import type { BookingsBuilding } from "../../../hooks/useAuthorizedDestinationDetails";
import { ResourceCreateAndEditSiteContext } from "../ResourceCreateAndEditSiteContext";
import {
  resourceCreateFormInitialValues,
  siteSpecificFields,
} from "../../../../../lib/resourceCreateFormInitialValues";

const getBuildingFields = (newBuilding: BookingsBuilding | undefined): Partial<FormValues> => {
  const newLevel = newBuilding?.buildingLevels[0];
  return {
    building: newBuilding?.uuid ?? "",
    buildingName: newBuilding?.name ?? "",
    buildingAddress: newBuilding?.address ?? "",
    level: newLevel?.uuid,
    levelName: newLevel?.name,
  };
};

export const ResourceCreateAndEditFormLocation: React.FC = () => {
  const { t } = useTranslation();
  const { tError } = useApolloErrorTranslation();
  const profile = useContext(CurrentProfile);
  const fm = useFormikContext<FormValues>();
  const { breakpoints, spacers } = useTheme(true);
  const { withConfirmation } = useConfirmer();

  const router = useRouter();
  const thisResourceUuid = router.query.uuid as string | undefined;
  const isCreate = thisResourceUuid == null;

  const { siteUuid, viewerLocations, locationsError } = useContext(ResourceCreateAndEditSiteContext);
  const selectedSite = viewerLocations.find((s) => s.uuid === siteUuid);
  const buildings = useMemo(() => selectedSite?.buildings ?? [], [selectedSite?.buildings]);
  const selectedBuilding = buildings.find((b) => b.uuid === fm.values.building);
  const levels = selectedBuilding?.buildingLevels ?? [];

  const siteSpecificFieldsDirty = siteSpecificFields.some((field) => fm.touched[field]);

  const handleBuildingChange = (newBuildingUuid: string) => {
    const newBuilding = buildings.find((b) => b.uuid === newBuildingUuid);
    if (newBuilding == null) {
      return;
    }

    fm.setValues((prevValues) => ({ ...prevValues, ...getBuildingFields(newBuilding) })).catch(console.error);
  };

  const handleSiteChange = (newSiteUuid: string) => {
    const newSite = viewerLocations.find((s) => s.uuid === newSiteUuid);
    if (newSite == null) {
      return;
    }

    const initialValues = resourceCreateFormInitialValues(newSiteUuid, newSite.name, profile, viewerLocations);
    const siteSpecifiicInitialValues = Object.fromEntries(
      siteSpecificFields.map((field) => [field, initialValues[field]]),
    ) as Partial<FormValues>;

    fm.setValues((prevValues) => ({
      ...prevValues,
      ...siteSpecifiicInitialValues,
      site: newSiteUuid,
      ...getBuildingFields(newSite.buildings[0]),
    })).catch(console.error);
  };

  if (locationsError != null) {
    return <div className="mb-6">{tError(locationsError)}</div>;
  }

  if (viewerLocations.length === 0) {
    return <div className="mb-6">{t("bookings.resources.noRolesWarning")}</div>;
  }

  const buildingHasError =
    (fm.touched.building === true || stringNotEmpty(fm.values.building)) && fm.errors.building != null;
  const levelHasError = (fm.touched.level === true || stringNotEmpty(fm.values.level)) && fm.errors.level != null;

  return (
    <>
      {viewerLocations.length > 1 && (
        <Form.Group label={t("common.site")} hasError={fm.errors.site != null}>
          {isCreate ? (
            <Field id="site" name="site" placeholder={t("bookings.resources.selectSite")} disabled={fm.isSubmitting}>
              {({ field }: FieldProps) => (
                <Form.Select
                  {...field}
                  name="site"
                  value={fm.values.site}
                  onChange={(e) => {
                    const newSite = e.currentTarget.value;
                    if (siteSpecificFieldsDirty) {
                      withConfirmation({
                        title: t("common.areYouSure"),
                        message: t("bookings.resources.changeSiteWarning"),
                        confirmButtonText: t("bookings.resources.changeSiteConfirmation"),
                        cancelButtonText: t("home.widgets.cancelNo"),
                        confirmButtonVariant: "danger",
                        size: "md",
                        onConfirm: () => handleSiteChange(newSite),
                      })();
                    } else {
                      handleSiteChange(newSite);
                    }
                  }}
                >
                  {viewerLocations.map((site) => (
                    <option key={site.uuid} value={site.uuid}>
                      {site.name}
                    </option>
                  ))}
                </Form.Select>
              )}
            </Field>
          ) : (
            <span>{selectedSite?.name ?? fm.values.siteName}</span>
          )}
        </Form.Group>
      )}
      <Form.Group label={t("bookings.resources.resourceAddress")}>
        <span>{stringNotEmpty(fm.values.buildingAddress) ? fm.values.buildingAddress : "-"}</span>
      </Form.Group>
      <div className="form-group-container">
        {buildings.length > 0 ? (
          <>
            <Form.Group
              label={t("bookings.resources.resourceBuilding")}
              hasError={buildingHasError}
              error={buildingHasError ? fm.errors.building : undefined}
            >
              <Field
                id="building"
                name="building"
                placeholder={t("bookings.resources.resourceBuildingHint")}
                disabled={fm.isSubmitting}
              >
                {({ field }: FieldProps) => (
                  <Form.Select
                    {...field}
                    name="building"
                    onChange={(e) => handleBuildingChange(e.currentTarget.value)}
                    value={fm.values.building}
                    disabled={fm.isSubmitting}
                  >
                    {stringIsEmpty(fm.values.building) && (
                      <option disabled value="">
                        {t("bookings.resources.resourceBuildingHint")}
                      </option>
                    )}
                    {!stringIsEmpty(fm.values.building) &&
                      buildings.every((building) => building.uuid !== fm.values.building) && (
                        <option key={fm.values.building}>{fm.values.buildingName}</option>
                      )}
                    {buildings.map((b) => (
                      <option key={b.uuid} value={b.uuid}>
                        {b.name}
                      </option>
                    ))}
                  </Form.Select>
                )}
              </Field>
            </Form.Group>
            <Form.Group
              label={t("bookings.resources.resourceLevel")}
              hasError={levelHasError}
              error={levelHasError ? fm.errors.level : undefined}
            >
              <Field
                id="level"
                name="level"
                placeholder={t("bookings.resources.selectLevel")}
                disabled={fm.isSubmitting}
              >
                {({ field }: FieldProps) => (
                  <Form.Select
                    {...field}
                    name="level"
                    onChange={(e) => {
                      fm.setFieldValue("level", e.currentTarget.value).catch(console.error);
                    }}
                    value={fm.values.level ?? ""}
                    disabled={fm.isSubmitting}
                  >
                    {stringIsEmpty(fm.values.level) && (
                      <option disabled value="">
                        {t("bookings.resources.selectLevel")}
                      </option>
                    )}
                    {!stringIsEmpty(fm.values.level) && levels.every((level) => level.uuid !== fm.values.level) && (
                      <option value={fm.values.level}>{fm.values.levelName}</option>
                    )}
                    {levels.map((l) => (
                      <option key={l.uuid} value={l.uuid}>
                        {l.name}
                      </option>
                    ))}
                  </Form.Select>
                )}
              </Field>
            </Form.Group>
          </>
        ) : (
          t("bookings.resources.noRolesWarning")
        )}
      </div>
      <style jsx>{`
        .form-group-container {
          display: flex;
          gap: ${spacers.s5};
        }
        .form-group-container :global(.form-header label) {
          line-height: 14px;
        }
        @media screen and (max-width: ${breakpoints.md}px) {
          .form-group-container {
            flex-direction: column;
          }
        }
      `}</style>
    </>
  );
};
