import { Form, ProgressCircle, useTheme } from "@equiem/react-admin-ui";
import type { FC } from "react";
import { useState, useCallback, useContext } from "react";
import type { Invite } from "./NewInvitesContext";
import { NewInvites } from "./NewInvitesContext";
import { useTranslation } from "@equiem/localisation-eq1";
import { useAllCompanies } from "@equiem/lib";

export const InviteCompanyWidget: FC<{ invite: Invite; disabled: boolean }> = ({ invite, disabled }) => {
  const { colors } = useTheme(true);
  const { updateCompany } = useContext(NewInvites);
  const [search, setSearch] = useState<string | undefined>(undefined);
  const { companies, loading } = useAllCompanies({ search });

  const { t } = useTranslation();

  const onChange = useCallback(
    (e: { target: { value?: string } }) => {
      updateCompany(invite.id, e.target.value);
    },
    [updateCompany, invite.id],
  );

  return (
    <Form.DynamicSelect
      name={t("home.widgets.selectCompany")}
      size="sm"
      disabled={loading || disabled}
      noneLabel={
        <span style={{ color: colors.muted1 }}>
          {loading && <ProgressCircle mode="indeterminate" size="xs" className="mr-2" />}
          <span>{t("home.widgets.selectCompany")}</span>
        </span>
      }
      showChrome={"onInteraction"}
      showSelectedOnList
      supportsMobile
      allowClear
      options={companies}
      search
      searchCb={async (term) => {
        setSearch(term);
        return Promise.resolve();
      }}
      value={invite.companyUuid ?? ""}
      onChange={onChange}
      className="company-selector"
      minWidth={240}
    />
  );
};
