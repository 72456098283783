import React, { useCallback, useContext } from "react";

import { useShowError } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import { Button, ColumnView, Tooltip, useConfirmer, useTheme } from "@equiem/react-admin-ui";
import {
  RiAddCircleLine,
  RiAddLine,
  RiArrowDownLine,
  RiArrowUpLine,
  RiDeleteBinLine,
  RiEditLine,
} from "@equiem/react-admin-ui/icons";

import { StatusTypeIcon } from "../../components/StatusTypeIcon";

import { ModalContext } from "./contexts/ModalContext";
import { withContexts } from "./contexts/withContexts";
import { useStatusData } from "./hooks/useStatusData";
import { StatusWidget } from "./widgets/StatusWidget";
import { SettingsTabs } from "./SettingsTabs";

const StatusComponent: React.FC = () => {
  const theme = useTheme();
  const { withConfirmation } = useConfirmer();
  const modal = useContext(ModalContext);
  const { t } = useTranslation();
  const showError = useShowError();
  const {
    mutationLoading,
    orderingLoading,
    deleteLoading,
    createLoading,
    updateLoading,
    deleteStatus,
    editingStatus,
    setEditingStatus,
    selectedType,
    setSelectedType,
    statuses,
    statusTypes,
    statusesLoading,
    reorder,
  } = useStatusData();

  const handleReset = useCallback(() => {
    setEditingStatus({});
  }, [setEditingStatus]);

  const statusLastIndex = statuses.length - 1;

  return (
    <>
      <div className="container">
        <SettingsTabs>
          <>
            <h3 className="font-weight-bold pb-3">{t("requests.status.configureStatuses")}</h3>
            <ColumnView.Container>
              <ColumnView.Column
                className="scol"
                id="status-type"
                title={t("requests.status.statusType")}
                empty={statusTypes.length === 0 || statusesLoading}
                loading={statusesLoading}
              >
                {statusTypes.map((st) => (
                  <ColumnView.Item
                    className="status-type"
                    value={st.title}
                    icon={<StatusTypeIcon type={st.id} />}
                    key={st.id}
                    onSelect={() => {
                      setSelectedType(st.id);
                      handleReset();
                    }}
                    selected={selectedType === st.id}
                  />
                ))}
              </ColumnView.Column>
              <ColumnView.Column
                className="scol"
                id="status"
                title={t("requests.status.statuses")}
                empty={statuses.length === 0}
                emptySettings={{
                  message:
                    selectedType == null
                      ? t("requests.status.pleaseSelectAStatusType")
                      : t("requests.status.noStatusHasBeenAdded"),
                  action: (
                    <>
                      {selectedType != null && (
                        <Button
                          variant="secondary"
                          onClick={() => {
                            setEditingStatus({ type: selectedType });
                            modal.open("StatusWidget", undefined);
                          }}
                        >
                          <RiAddLine /> {t("requests.status.addStatus")}
                        </Button>
                      )}
                    </>
                  ),
                }}
                actions={[
                  <Tooltip title={t("requests.status.addStatus")} focusable={false} key="add">
                    <Button
                      variant="ghost"
                      className="add-new-status"
                      onClick={() => {
                        setEditingStatus({ type: selectedType ?? undefined });
                        modal.open("StatusWidget", undefined);
                      }}
                      disabled={selectedType == null}
                      aria-label={t("requests.status.addStatus")}
                      round
                    >
                      <RiAddCircleLine size={16} />
                    </Button>
                  </Tooltip>,
                ]}
              >
                {statuses.map((status, index) => (
                  <ColumnView.Item
                    className="status"
                    value={status.title}
                    key={status.uuid}
                    isNew={false}
                    isEditing={false}
                    onReset={handleReset}
                    loading={createLoading || updateLoading}
                    actions={[
                      {
                        title: t("common.up"),
                        icon: RiArrowUpLine,
                        onClick: () => reorder("up", status.uuid),
                        disabled: index === 0 || mutationLoading,
                        loading: orderingLoading,
                        className: "item-up",
                      },
                      {
                        title: t("common.down"),
                        icon: RiArrowDownLine,
                        onClick: () => reorder("down", status.uuid),
                        disabled: index === statusLastIndex || mutationLoading,
                        loading: orderingLoading,
                        className: "item-down",
                      },
                      {
                        title: t("common.edit"),
                        disabled: mutationLoading,
                        icon: RiEditLine,
                        onClick: () => {
                          setEditingStatus({
                            type: status.type,
                            uuid: status.uuid,
                            name: status.title,
                            ownerCompanyUuid: status.ownerCompanyUuid,
                          });
                          modal.open("StatusWidget", undefined);
                        },
                      },
                      {
                        title: t("common.delete"),
                        disabled: mutationLoading,
                        icon: RiDeleteBinLine,
                        loading: deleteLoading,
                        color: theme.colors.status.danger.primary,
                        onClick: withConfirmation({
                          onConfirm: () => {
                            deleteStatus(status.uuid).catch(showError);
                          },
                          title: t("common.deleteConfirmationTitleWithTitle", { title: status.title }) as string,
                          message: t("common.deleteConfirmationWithTitle", { title: status.title }) as string,
                          confirmButtonText: t("common.yesDelete"),
                          confirmButtonVariant: "danger",
                        }),
                      },
                    ]}
                  />
                ))}
              </ColumnView.Column>
            </ColumnView.Container>
          </>
        </SettingsTabs>
        {modal.activeModal === "StatusWidget" && <StatusWidget editingStatus={editingStatus} />}
      </div>
      <style jsx>{`
        .container :global(.status-type.item--icon *:first-child) {
          justify-self: left;
        }
        .container :global(.scol) {
          height: calc(100vh - 300px);
        }
        @media (max-width: ${theme.breakpoints.md}px) {
          .container :global(.scol) {
            height: calc(100vh - 250px);
          }
        }
      `}</style>
    </>
  );
};

export const Status = withContexts(StatusComponent);
