import React from "react";

import { useSaferFormikContext } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import { Button, MemberCard, useTheme } from "@equiem/react-admin-ui";

import type { FormHost, FormValues } from "../../../appointment/types";
import { ChangeHostWidget } from "../../../appointment/widgets/ChangeHostWidget";

interface Props {
  isActive?: boolean;
  handleStep: (step: number) => void;
  receptionUuid?: string;
}

export const WalkInStep2: React.FC<Props> = ({ isActive, handleStep, receptionUuid }) => {
  const { t } = useTranslation();
  const { colors } = useTheme(true);
  const { setFieldValue, values } = useSaferFormikContext<FormValues>();

  const handleHostSelect = (host: FormHost) => {
    setFieldValue("title", t("visitors.walkIn.titleTemplate", { name: `${host.firstName} ${host.lastName}` })).catch(
      console.error,
    );
    handleStep(3);
  };

  return (
    <div
      className={`step-container step-2 pr-0 pt-5 pb-5 mt-5 d-flex ${
        isActive !== true ? "flex-row justify-content-between pr-5" : "flex-column step-shadow"
      }`}
    >
      <div className={`px-7 pt-2 step-head ${isActive === true ? "mb-7" : ""}`}>
        <p className="step-title mb-1 mt-0">{t("visitors.walkIn.step", { step: 2 })}</p>
        <h2>{t("visitors.walkIn.selectHost")}</h2>
      </div>
      {isActive === true ? (
        <div>
          <hr className="custom-liner" />
          <ChangeHostWidget
            mode="walkin"
            onHostSelect={handleHostSelect}
            receptionUuid={receptionUuid === "undefined" ? undefined : receptionUuid}
          />
          <hr className="custom-liner" />
          <div className="footer d-flex px-6 pt-5 justify-content-end">
            <Button
              variant="secondary"
              size="md"
              onClick={() => handleStep(3)}
              disabled={values.host.firstName.length === 0}
            >
              {t("visitors.walkIn.nextStep")}
            </Button>
          </div>
        </div>
      ) : (
        values.host.firstName.length > 0 && (
          <div className="d-flex flex-column preview-container">
            <MemberCard.Card
              firstName={values.host.firstName}
              lastName={values.host.lastName}
              email={values.host.email}
              companyName={values.host.company?.name ?? ""}
              showBorder={false}
              buttonVariant="outline"
              buttonText={t("common.change")}
              showButton={true}
              onButtonClick={() => {
                handleStep(2);
              }}
            />
          </div>
        )
      )}
      <style jsx>
        {`
          h2 {
            font-weight: 600;
          }
          .step-container {
            border: 1px solid #e6e6e6;
            border-radius: 8px;
          }
          .step-shadow {
            box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
          }
          .step-title {
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
            text-transform: uppercase;
            color: rgba(0, 0, 0, 0.6);
          }
          .custom-liner {
            margin: 0px;
            border-top: 1px solid rgba(0, 0, 0, 0.1);
          }
          .step-head {
            flex: 4;
          }
          .preview-container {
            position: relative;
            border: 1px solid #e6e6e6;
            border-radius: 4px;
            flex: 3;
          }
          .step-empty {
            color: ${colors.medium};
          }
        `}
      </style>
    </div>
  );
};
