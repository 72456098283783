import type { ComponentProps } from "react";
import React from "react";
import { useTheme } from "../../../contexts/Theme";

export const DropdownDivider: React.FC<ComponentProps<"hr">> = (props) => {
  const { colors, spacers } = useTheme();

  return (
    <>
      <hr {...props} />
      <style jsx>{`
        hr {
          border: none;
          border-top: 1px solid ${colors.border};
          margin: ${spacers.s3} 0;
        }
      `}</style>
    </>
  );
};
