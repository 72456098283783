"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getLocalePreference = exports.getUninitializedLocalePreference = exports.isSupported = exports.getCachedPreference = exports.LOCALE_PREFERENCE_CACHE_KEY = void 0;
var gateway_client_1 = require("../generated/gateway-client");
var config_1 = require("./config");
exports.LOCALE_PREFERENCE_CACHE_KEY = "localePreference";
function getScopedPreference(gqlSdk, _a) {
    var siteUuid = _a.siteUuid, userUuid = _a.userUuid, useViewer = _a.useViewer;
    return __awaiter(this, void 0, void 0, function () {
        var result, e_1;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    if (userUuid == null && siteUuid == null && useViewer !== true) {
                        return [2 /*return*/, null];
                    }
                    _b.label = 1;
                case 1:
                    _b.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, gqlSdk.LocalePreference({ userUuid: userUuid, siteUuid: siteUuid, useViewer: useViewer })];
                case 2:
                    result = _b.sent();
                    return [2 /*return*/, result.localePreference];
                case 3:
                    e_1 = _b.sent();
                    console.error("Failed to load scoped locale preferences:", { userUuid: userUuid, siteUuid: siteUuid, useViewer: useViewer }, e_1);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/, null];
            }
        });
    });
}
function getBrowserPreference() {
    try {
        if (typeof navigator !== "undefined") {
            return navigator.language;
        }
    }
    catch (e) {
        console.error("Failed to load browser locale preferences:", e);
    }
    return null;
}
function getCachedPreference() {
    if (typeof localStorage !== "undefined") {
        return localStorage.getItem(exports.LOCALE_PREFERENCE_CACHE_KEY);
    }
    return null;
}
exports.getCachedPreference = getCachedPreference;
function isSupported(lng, supportedLanguages) {
    var supported = new Set(supportedLanguages.map(function (sl) { return sl.toLowerCase(); }));
    var parts = lng.toLowerCase().split("-");
    while (parts.length > 0) {
        if (supported.has(parts.join("-"))) {
            return true;
        }
        parts.pop();
    }
    return false;
}
exports.isSupported = isSupported;
function getUninitializedLocalePreference(supportedLanguages) {
    if (supportedLanguages === void 0) { supportedLanguages = []; }
    // don't use the browser language if it's not one we support
    var cachedPreference = getCachedPreference();
    if (cachedPreference != null && isSupported(cachedPreference, supportedLanguages)) {
        return cachedPreference;
    }
    // don't use the browser language if it's not one we support
    var browserPreference = getBrowserPreference();
    if (browserPreference != null && isSupported(browserPreference, supportedLanguages)) {
        return browserPreference;
    }
    return config_1.defaultLocale;
}
exports.getUninitializedLocalePreference = getUninitializedLocalePreference;
/** If no scopes are provided, the browser language (or default locale) is used. */
function getLocalePreference(gqlRequester, scope, supportedLanguages) {
    if (scope === void 0) { scope = {}; }
    return __awaiter(this, void 0, void 0, function () {
        var preference;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (scope.preferredLanguage != null) {
                        return [2 /*return*/, scope.preferredLanguage];
                    }
                    return [4 /*yield*/, getScopedPreference((0, gateway_client_1.getSdk)(gqlRequester), scope)];
                case 1:
                    preference = _a.sent();
                    if (preference != null) {
                        return [2 /*return*/, preference];
                    }
                    console.warn("Falling back to uninitialized locale preference");
                    return [2 /*return*/, getUninitializedLocalePreference(supportedLanguages)];
            }
        });
    });
}
exports.getLocalePreference = getLocalePreference;
