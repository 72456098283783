import React, { useContext, useEffect, useMemo } from "react";
import type { IconType } from "react-icons";
import {
  RiListCheck,
  RiCalendar2Line,
  RiTimeLine,
  RiTimerLine,
  RiHashtag,
  RiText,
  RiFilterLine,
  RiCloseCircleLine,
} from "react-icons/ri";

import { useTheme } from "../../contexts/Theme";
import { useIsMobileWidth } from "../../hooks";
import { DropdownContext } from "../Dropdown/Dropdown";
import { Text } from "../Text/Text";
import { ComplexFilterContext } from "./ComplexFilterContext";
import type { FilterOption } from "./ComplexFilter.types";
import { FilterType } from "./ComplexFilter.types";

interface Props {
  title: string;
  type: FilterType;
  name: string;
  value?: string | string[] | FilterOption[];
  onClose?: () => void;
  icon?: IconType;
  size?: "md" | "lg";
}

export const FilterChip: React.FC<Props> = ({ title, value, name, type, onClose, size = "md", icon: Icon }) => {
  const { show, setShow, reference } = useContext(DropdownContext);
  const { disabled, lastAddedFilter, values } = useContext(ComplexFilterContext);
  const { colors, mutedOpacity, breakpoints } = useTheme();
  const isMobile = useIsMobileWidth();

  const handleClick = () => {
    if (!disabled) {
      setShow(!show);
    }
  };

  useEffect(() => {
    if (lastAddedFilter === name && !isMobile && !disabled) {
      setTimeout(() => setShow(true), 100);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastAddedFilter, name, setShow]);

  const ChipIcon = useMemo(() => {
    switch (type) {
      case FilterType.date:
        return RiCalendar2Line;
      case FilterType.time:
        return RiTimeLine;
      case FilterType.duration:
        return RiTimerLine;
      case FilterType.options:
        return RiListCheck;
      case FilterType.number:
        return RiHashtag;
      case FilterType.text:
        return RiText;
      default:
        return RiFilterLine;
    }
  }, [type]);

  const handleClose = (e: React.MouseEvent) => {
    e.stopPropagation();
    onClose?.();
  };

  const textValue = useMemo(() => {
    if (Array.isArray(value) && value.length > 0 && typeof value[0] === "object" && "label" in value[0]) {
      return value.map((v) => (v as FilterOption).label).join(", ");
    }

    if (Array.isArray(value)) {
      return value.join(", ");
    }

    if (typeof value === "string") {
      return value;
    }
    return "";
  }, [value]);

  const isDefaultValue = values[name]?.defaultValue === true;

  return (
    <>
      <div className={`chip${disabled ? " disabled" : ""}`} role="button" onClick={handleClick} ref={reference}>
        <div className="chip-inner">
          {Icon != null ? (
            <Icon color={colors.blue[60]} size={16} className="mr-3" />
          ) : (
            <ChipIcon color={colors.blue[60]} size={16} className="mr-3" />
          )}
          <Text variant="button" size="small" className="chip-title mr-1">
            {title + (value != null && value.length > 0 ? ":" : "")}
          </Text>
          <Text variant="button" size="small" className="chip-value">
            {textValue}
          </Text>
        </div>
        {(!isMobile || value != null) && !isDefaultValue && (
          <div className="chip-close close" role="button" onClick={handleClose}>
            <RiCloseCircleLine size={16} color={colors.grayscale[60]} />
          </div>
        )}
      </div>
      <style jsx>{`
        .chip {
          display: flex;
          align-items: center;
          padding: 0.25rem 0.5rem;
          border: 1px solid ${colors.grayscale[10]};
          border-radius: 4px;
          width: fit-content;
          max-width: ${size === "md" ? 252 : 352}px;
          cursor: pointer;
        }
        .chip-inner {
          display: flex;
          padding: 0.25rem 0;
          white-space: nowrap;
          overflow: hidden;
        }
        .chip :global(.chip-title) {
          color: ${colors.grayscale[60]};
        }
        .chip :global(.chip-value) {
          color: ${colors.grayscale[100]};
          flex: 1;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .chip-close {
          display: inline-flex;
          padding: 0.25rem;
          margin-left: 0.125rem;
        }
        .chip-close:hover {
          background: ${colors.transparent.black[5]};
          border-radius: 50%;
        }

        .chip.disabled {
          opacity: ${mutedOpacity};
          cursor: default;
        }
        .chip.disabled .chip-close:hover {
          background: none;
        }

        @media screen and (max-width: ${breakpoints.lg}px) {
          .chip {
            max-width: 100%;
            width: 100%;
            padding: 0.5rem;
            justify-content: space-between;
            overflow: hidden;
          }
        }
      `}</style>
    </>
  );
};
