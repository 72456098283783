import type { Static } from "runtypes";
import { Number, Partial, Record, String } from "runtypes";

export const Session = Record({
  expiresAt: Number,
}).And(
  Partial({
    accessToken: String,
    idToken: String,
  }),
);

export type SessionType = Static<typeof Session>;
