export const useGetFileSize = (size: number) => {
  // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
  if (!size) {
    return undefined;
  }

  const currentSize = size / 1000;

  if (currentSize > 1000) {
    const mb = currentSize / 1000;
    return `${mb.toFixed(2)} MB`;
  }

  return `${currentSize.toFixed(2)} KB`;
};
