/* eslint-disable no-unused-vars */
import { FC, Ref } from "react";
import { Button, ProgressCircle } from "@equiem/react-admin-ui";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { useTranslation } from "@equiem/localisation-eq1";

export const CropperUi: FC<{
  localAttachedImage: { file: File; img: string };
  onCrop: () => void;
  cropperRef: Ref<HTMLImageElement>;
  onSaveCropped: () => Promise<void>;
  progress: number;
  clearCropper: () => void;
  uploading: boolean;
  error: any;
  aspectRatio?: number;
  disableAspectRatio?: boolean;
  autoCropArea?: number;
  id?: string;
}> = ({
  localAttachedImage,
  onCrop,
  cropperRef,
  onSaveCropped,
  progress,
  clearCropper,
  uploading,
  error,
  aspectRatio,
  disableAspectRatio,
  autoCropArea,
  id,
}) => {
  const inProgress = progress !== 0 || uploading;
  const { t } = useTranslation();

  return (
    <div style={{ display: "block" }}>
      <Cropper
        src={localAttachedImage.img}
        style={{ height: 400, width: "100%" }}
        // Cropper.js options
        aspectRatio={disableAspectRatio ? undefined : aspectRatio ?? 16 / 9}
        guides={false}
        crop={onCrop}
        ref={cropperRef}
        preview={`.img-preview${id != null ? "-" + id : ""}`}
        autoCropArea={autoCropArea}
      />
      <div className="center">
        <div
          className={`img-preview${id != null ? "-" + id : ""} my-3`}
          style={{ width: "150px", height: "100px", overflow: "hidden" }}
        />
        <div>
          <Button onClick={onSaveCropped} disabled={inProgress} className="my-3 upload-image" size="sm">
            {inProgress ? (
              <>
                <ProgressCircle size="xs" /> {progress !== 0 ? t("uploader.uploading") : t("uploader.processing")}
              </>
            ) : (
              <>{t("uploader.uploadImage")}</>
            )}
          </Button>
          <Button onClick={clearCropper} disabled={inProgress} variant="secondary" className="ml-2" size="sm">
            {t("common.cancel")}
          </Button>
        </div>
      </div>
      {error && <div>{error.message}</div>}
    </div>
  );
};
