import type { FC } from "react";
import React, { useEffect, useRef, useState } from "react";
import { Form as EqForm, useDebounced } from "@equiem/react-admin-ui";
import type { GoogleMapsServices } from "../hooks/useGooglePlacesService";
import { useGooglePlacesService } from "../hooks/useGooglePlacesService";
import type { FieldProps } from "formik";

export type Props = FieldProps;

interface SearchResultItem {
  placeId: string;
  address: string;
  value: string;
}

export const AddressAutoCompleteField: FC<Props> = ({ form, field }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [services, setServices] = useState<GoogleMapsServices | null>(null);
  const gService = useGooglePlacesService();
  const ref = useRef(null);

  const debouncedSearchTerm = useDebounced(searchTerm, 500);
  const [addressSuggestions, setAddressSuggestions] = useState<SearchResultItem[]>([]);

  const getFormattedAddress = (googlePlaceJson: string): string => {
    if (googlePlaceJson === "") {
      return "";
    }

    const parsed = JSON.parse(googlePlaceJson) as { formatted_address?: string };

    return parsed.formatted_address ?? "";
  };

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    (async () => {
      if (gService == null) {
        return;
      }

      const googleMapsServices = await gService.loadServices(ref);
      if (googleMapsServices == null) {
        return;
      }

      setServices(googleMapsServices);
    })();
  }, []);

  const onSelected = (item: SearchResultItem) => {
    services?.placesService.getDetails(
      {
        placeId: item.placeId,
      },
      (result) => {
        form.setFieldValue(field.name, JSON.stringify(result)).catch(console.error);
      },
    );
  };

  useEffect(() => {
    if (debouncedSearchTerm === "") {
      return;
    }

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    services?.autoCompleteService.getPlacePredictions(
      {
        input: debouncedSearchTerm,
        location: services.siteCoordinates,
        radius: 200_000, // 200km
      },
      (results, status) => {
        if (status !== "OK") {
          return;
        }

        const resultOptions: SearchResultItem[] =
          results?.map((result) => ({
            placeId: result.place_id ?? "",
            address: result.description ?? "",
            value: result.description ?? "",
          })) ?? [];

        setAddressSuggestions(resultOptions);
      },
    );
  }, [debouncedSearchTerm, services]);

  return (
    <>
      <EqForm.ComboBox.ComboBox
        items={addressSuggestions}
        defaultValue={{
          value: getFormattedAddress(field.value),
          address: "",
          placeId: "",
        }}
        onChange={(e) => {
          setSearchTerm(e.target.value);
        }}
        onSelect={(item) => {
          onSelected(item);
        }}
      >
        {({ inputProps, floating }) => (
          <div>
            <EqForm.InputGroup.Group ref={floating?.reference}>
              <EqForm.Input placeholder="Address" ref={ref} {...inputProps} />
            </EqForm.InputGroup.Group>
            <EqForm.ComboBox.Menu height="10rem" className="address-options">
              <EqForm.ComboBox.Options />
            </EqForm.ComboBox.Menu>
          </div>
        )}
      </EqForm.ComboBox.ComboBox>
    </>
  );
};
