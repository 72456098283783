import React from "react";
import { ProgressCircle } from "../ProgressCircle/ProgressCircle";
import InfiniteScroll from "react-infinite-scroll-component";

import type { TableSort } from "./StickyHeaderTable";
import { StickyHeaderTable } from "./StickyHeaderTable";
import { useTranslation } from "@equiem/localisation-eq1";

interface Props {
  loading: boolean;
  hasMoreData: boolean;
  scrollDownToLoad?: string;
  className?: string;

  rowHeaders: string[] | React.ReactNode[];
  overlayHeader?: React.ReactNode;
  sort?: TableSort;
  rowCount: number;

  children?: React.ReactNode;

  fetchMore: () => void;
}

interface LoaderProps {
  loading: boolean;
  hasMoreData: boolean;
  colSpan?: number;
  scrollDownToLoad?: string;
}

const Loader: React.FC<LoaderProps> = ({ loading, hasMoreData, colSpan, scrollDownToLoad }) => {
  const { t } = useTranslation();
  return loading || hasMoreData ? (
    <tr>
      <td colSpan={colSpan}>
        <div className="mt-6 mb-6 d-flex justify-content-center align-items-center">
          {loading ? (
            <>
              <ProgressCircle size="md" />
              <span className="ml-4">{`${t("common.loading")}...`}</span>
            </>
          ) : (
            // `react-infinite-scroll-component` only checks if it needs to request
            // more data on scroll, not on initial data load, so if the first page of
            // data doesn't fill the screen it won't immediately load more data.
            //
            // In that scenario, we should show them some indication that there's
            // more data available and how to get it.
            //
            // This should never be visible to the user unless they've got a
            // *really* tall screen.
            scrollDownToLoad ?? t("common.scrollDownToLoad")
          )}
        </div>
      </td>
    </tr>
  ) : null;
};

export const InfiniteScrollTable: React.FC<Props> = ({
  loading,
  hasMoreData,
  scrollDownToLoad,
  className,
  rowHeaders,
  overlayHeader,
  sort,
  rowCount,
  children,
  fetchMore,
}) => {
  return (
    <InfiniteScroll
      next={fetchMore}
      dataLength={rowCount}
      hasMore={hasMoreData}
      loader={null}
      style={{ overflow: undefined }}
      className={className}
    >
      <StickyHeaderTable rowHeaders={rowHeaders} overlayHeader={overlayHeader} loading={false} sort={sort} responsive>
        {children}
        <Loader
          loading={loading}
          hasMoreData={hasMoreData}
          colSpan={rowHeaders.length}
          scrollDownToLoad={scrollDownToLoad}
        />
      </StickyHeaderTable>
    </InfiniteScroll>
  );
};
