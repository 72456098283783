import {
  useFloating,
  arrow,
  flip,
  offset,
  shift,
  useHover,
  useFocus,
  useRole,
  useInteractions,
  safePolygon,
  useDismiss,
} from "@floating-ui/react-dom-interactions";
import type { Placement } from "@floating-ui/react-dom-interactions";
import React, { useMemo, useRef } from "react";
import { Text } from "../Text/Text";
import { useTheme } from "../../contexts/Theme";
import { useIsMobileWidth } from "../../hooks";

type TooltipVariant = "basic" | "advanced";

interface Props {
  title?: string;
  placement?: Placement;
  variant?: TooltipVariant;
  showArrow?: boolean;
  imageUrl?: string;
  description?: string | React.ReactNode;
  primaryAction?: React.ReactNode;
  secondaryAction?: React.ReactNode;
  manualInteractionsControl?: boolean;
  focusable?: boolean;
  limitMobileWidth?: boolean;
}

const getArrowOffset = (variant: TooltipVariant, showArrow: boolean) =>
  showArrow || variant === "advanced" ? (variant === "basic" ? 6 : 10) : 4;

export const Tooltip: React.FC<React.PropsWithChildren<Props>> = ({
  title,
  placement = "bottom",
  showArrow = false,
  variant = "basic",
  imageUrl,
  description,
  primaryAction,
  secondaryAction,
  children,
  manualInteractionsControl = false,
  focusable = true,
  limitMobileWidth = false,
}) => {
  const { zIndexes } = useTheme();
  const [isActive, setActive] = React.useState(false);
  const arrowRef = useRef<HTMLDivElement>(null);
  const {
    x,
    y,
    reference,
    floating,
    strategy,
    placement: actualPlacement,
    context,
    middlewareData: { arrow: { x: arrowX, y: arrowY } = {} },
    update,
  } = useFloating({
    open: isActive,
    onOpenChange: setActive,
    placement,
    middleware: [offset(getArrowOffset(variant, showArrow)), flip(), shift(), arrow({ element: arrowRef })],
  });

  const isMobile = useIsMobileWidth();
  const limitWidth = isMobile && limitMobileWidth;

  const arrowSide = useMemo(() => {
    return (
      {
        top: "bottom",
        right: "left",
        bottom: "top",
        left: "right",
      }[actualPlacement.split("-")[0]] ?? "top"
    );
  }, [actualPlacement]);

  const arrowRotateDegree = useMemo(() => {
    return {
      top: 0,
      right: 90,
      bottom: 180,
      left: 270,
    }[actualPlacement.split("-")[0]];
  }, [actualPlacement]);

  const hover = useHover(context, {
    handleClose: safePolygon({ blockPointerEvents: false }),
  });
  const focus = useFocus(context, {
    enabled: focusable,
  });
  const role = useRole(context, { role: "tooltip" });
  const dismiss = useDismiss(context, { ancestorScroll: true });

  const { getReferenceProps, getFloatingProps } = useInteractions([hover, focus, role, dismiss]);

  const basicTooltipContent = useMemo(() => {
    return (
      <Text variant="text" size="extra-small" className="title m-0">
        {title}
      </Text>
    );
  }, [title]);

  const advancesTooltipContent = useMemo(() => {
    return (
      <div className="advanced-container">
        {imageUrl != null && imageUrl.length > 0 && <img src={imageUrl} alt={title} onLoad={update} />}
        <div className="inner-content">
          <Text variant="text" size="small" className="title m-0">
            {title}
          </Text>
          {description != null && (
            <Text
              variant="text"
              size="extra-small"
              className={`m-0 ${title != null || imageUrl != null ? "mt-2" : ""}`}
            >
              {description}
            </Text>
          )}
          {(primaryAction != null || secondaryAction != null) && (
            <div className="actions">
              {secondaryAction != null && secondaryAction}
              {primaryAction != null && primaryAction}
            </div>
          )}
        </div>
        <style jsx>
          {`
            .advanced-container {
              display: flex;
              flex-direction: column;
              gap: 0.5rem;
            }

            .inner-content {
              padding: 0.5rem;
            }

            .inner-content :global(.title) {
              font-weight: 600 !important;
            }

            .actions {
              margin-top: 1rem;
              display: flex;
              justify-content: flex-end;
              gap: 0.5rem;
            }

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              border-radius: 2px;
              overflow: hidden;
              z-index: 1;
            }
          `}
        </style>
      </div>
    );
  }, [title, description, primaryAction, secondaryAction, imageUrl]);

  return (
    <>
      {manualInteractionsControl
        ? children
        : React.cloneElement(children as never, {
            ref: reference,
            ...getReferenceProps(),
          })}
      {isActive && (
        <div ref={floating} className={`tooltip tooltip--${variant}`} {...getFloatingProps}>
          {(showArrow || variant === "advanced") && <div className="arrow" ref={arrowRef} />}
          {variant === "advanced" ? advancesTooltipContent : basicTooltipContent}
        </div>
      )}
      <style jsx>{`
        .tooltip {
          position: ${strategy};
          top: ${y ?? 0}px;
          left: ${x ?? 0}px;
          width: ${isMobile ? "initial" : "max-content"};
          margin-${actualPlacement}: 0.25rem;
          max-width: ${limitWidth ? "50%" : "326px"};
          color: #fff;
          background: rgba(0, 0, 0, 0.8);
          backdrop-filter: blur(8px);
          border-radius: 4px;
          padding: 0.25rem 0.5rem;
          z-index: ${zIndexes.tooltips};
          text-transform: none;
          opacity: 1;
        }

        .tooltip--basic :global(.title) {
          margin: 0;
          text-align: ${["top", "bottom"].includes(actualPlacement) ? "center" : "left"};
        }

        .tooltip--advanced {
          width: 296px;
          padding: 0.5rem;
          border-radius: 8px;
        }
        .arrow {
          position: absolute;
          left: ${arrowX != null ? `${arrowX}px` : ""};
          top: ${arrowY != null ? `${arrowY}px` : ""};
          ${arrowSide}: -${["left", "right"].includes(arrowSide) ? 6 : 4}px;
          transform: rotate(${arrowRotateDegree}deg);
          border-color: transparent;
          border-top-color: rgba(0, 0, 0, 0.8);
          border-style: solid;
          border-width: 0.25rem 0.25rem 0;
          width: 0;
          height: 0;
          line-height: 0;
        }

        .tooltip--advanced .arrow {
          border-width: 0.5rem 0.5rem 0;
          ${arrowSide}: -${["left", "right"].includes(arrowSide) ? 12 : 8}px;
        }
      `}</style>
    </>
  );
};
