import { stringNotEmpty } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import { Form, Skeleton, useTheme } from "@equiem/react-admin-ui";
import React from "react";
import type { BookingSettingFragmentFragment } from "../../../generated/gateway-client";
import { useBookableResourcesPaymentMethodsQuery } from "../../../generated/gateway-client";
import { ConnectStripe } from "./ConnectStripe";
import { DisconnectStripe } from "./DisconnectStripe";

interface P {
  setting?: BookingSettingFragmentFragment | null;
}
export const PaymentGatewayConnect: React.FC<P> = ({ setting }) => {
  const { t } = useTranslation();
  const theme = useTheme(true);
  const { data, loading } = useBookableResourcesPaymentMethodsQuery({ fetchPolicy: "network-only" });

  if (loading) {
    return (
      <Form.Group label={t("bookings.settings.stripeAccount")}>
        <Skeleton.Line width="200px" height="25px" />
      </Form.Group>
    );
  }

  const accountId = setting?.paymentAccountId;

  return (
    <Form.Group label={t("bookings.settings.stripeAccount")}>
      <div>
        {stringNotEmpty(accountId) ? (
          <>
            <div className="mb-3">{t("bookings.settings.accountId", { accountId })}</div>
            <DisconnectStripe />
          </>
        ) : (
          <ConnectStripe />
        )}
        <div className="mt-3">
          <small>
            {t("bookings.settings.ccResourceWarn", { count: data?.siteCreditCardBookableResourceCount ?? 0 })}
          </small>
        </div>
      </div>
      <style jsx>{`
        small {
          color: ${theme.colors.danger};
        }
      `}</style>
    </Form.Group>
  );
};
