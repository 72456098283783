import { useTranslation } from "@equiem/localisation-eq1";
import React from "react";
import { Summary } from "./Summary";
import { RoomConfigurationList } from "./RoomConfigurationList";
import { SummaryRightLabel } from "./SummaryRightLabel";

export const RoomConfigurationSummary: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Summary
      left={
        <>
          <h3 className="font-weight-bold">{t("bookings.settings.roomConfigurations")}</h3>
          <p>{t("bookings.settings.roomConfigurationParagraph1")}</p>
          <p>{t("bookings.settings.roomConfigurationParagraph2")}</p>
        </>
      }
      right={
        <>
          <SummaryRightLabel title={t("bookings.settings.roomConfigurations")} />
          <RoomConfigurationList />
        </>
      }
    />
  );
};
