import { DateTime } from "luxon";

import { AppointmentWeekday } from "@equiem/lib/context/types";

import { VisitorAppointmentRecurringType, VisitorAppointmentWeekday } from "../../../../generated/visitors-client";

export const AllAppointmentWeekdays = [
  AppointmentWeekday.MONDAY,
  AppointmentWeekday.TUESDAY,
  AppointmentWeekday.WEDNESDAY,
  AppointmentWeekday.THURSDAY,
  AppointmentWeekday.FRIDAY,
  AppointmentWeekday.SATURDAY,
  AppointmentWeekday.SUNDAY,
];

export const RecurringAppointmentsDefaultLimit = 100;
export const RecurringDefaultIndexLimit = 356;

export const RecurringGraphQLToFormWeekdayMap: Record<VisitorAppointmentWeekday, AppointmentWeekday> = {
  MONDAY: AppointmentWeekday.MONDAY,
  TUESDAY: AppointmentWeekday.TUESDAY,
  WEDNESDAY: AppointmentWeekday.WEDNESDAY,
  THURSDAY: AppointmentWeekday.THURSDAY,
  FRIDAY: AppointmentWeekday.FRIDAY,
  SATURDAY: AppointmentWeekday.SATURDAY,
  SUNDAY: AppointmentWeekday.SUNDAY,
};

export const NumberToRecurringWeekdayGraphQLMap: Record<number, VisitorAppointmentWeekday> = {
  1: VisitorAppointmentWeekday.Monday,
  2: VisitorAppointmentWeekday.Tuesday,
  3: VisitorAppointmentWeekday.Wednesday,
  4: VisitorAppointmentWeekday.Thursday,
  5: VisitorAppointmentWeekday.Friday,
  6: VisitorAppointmentWeekday.Saturday,
  7: VisitorAppointmentWeekday.Sunday,
};

export const RecurringWeekdayToNumberMap: Record<VisitorAppointmentWeekday, number> = {
  MONDAY: 1,
  TUESDAY: 2,
  WEDNESDAY: 3,
  THURSDAY: 4,
  FRIDAY: 5,
  SATURDAY: 6,
  SUNDAY: 7,
};

export const NumberToRecurringTypeGraphQLMap: Record<number, VisitorAppointmentRecurringType> = {
  0: VisitorAppointmentRecurringType.Daily,
  1: VisitorAppointmentRecurringType.Weekly,
  2: VisitorAppointmentRecurringType.Monthly,
};

export const VisitorAppointmentRecurringTypeToNumberMap: Record<VisitorAppointmentRecurringType, number> = {
  DAILY: 0,
  WEEKLY: 1,
  MONTHLY: 2,
};

export const GetReceptionTimezoneAdjustedStartOfDay = (startDate: number, timezone?: string) => {
  const startDateTime = DateTime.fromMillis(startDate);
  return DateTime.fromObject(
    {
      year: startDateTime.year,
      month: startDateTime.month,
      day: startDateTime.day,
    },
    { zone: timezone },
  ).toMillis();
};
