import React from "react";
import { useTheme } from "@equiem/react-admin-ui";
import { useTranslation } from "@equiem/localisation-eq1";
import { NoAccessIcon } from "./NoAccessIcon";
import { Menu } from "./Menu";

export const NoAccess = () => {
  const { borderRadius } = useTheme();
  const { t } = useTranslation();

  return (
    <div className="page">
      <Menu />
      <div className="cont m-8 p-8">
        <div>
          <NoAccessIcon />
          <h2>{t("contentManagement.noPortfoliosTitle")}</h2>
          <h3>{t("contentManagement.noPortfoliosDescription")}</h3>
        </div>
      </div>
      <style jsx>{`
        div {
          text-align: center;
        }
        .page {
          min-height: 100vh;
        }
        h2 {
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
          color: #666666;
        }
        h3 {
          font-size: 12px;
          line-height: 16px;
          color: #666666;
        }
        .cont {
          background: rgba(242, 242, 242, 0.4);
          border: 1px dashed #cccccc;
          border-radius: ${borderRadius};
          display: flex;
          align-items: center;
          justify-content: center;
        }
      `}</style>
    </div>
  );
};
