import { BreadcrumbsUuidProvider, GradientProvider } from "@equiem/lib";
import type { PropsWithChildren } from "react";
import React, { useContext, Fragment } from "react";
import { General } from "./General";
import { BreadcrumbsProvider } from "../../contexts/BreadcrumbsProvider";
import { Web } from "../web/Web";
import { PageContext } from "@equiem/lib/context/PageContext";
import { Mobile } from "./Mobile";

interface P extends PropsWithChildren {
  authenticated: boolean;
}

export const LayoutProvider: React.FC<P> = ({ children, authenticated }) => {
  const { isInMobileLayout, isInWebNgLayout, noLayoutWhenUnauthenticated } = useContext(PageContext);

  if (!authenticated && (!isInWebNgLayout || noLayoutWhenUnauthenticated === true)) {
    return <Fragment>{children}</Fragment>;
  }

  if (isInWebNgLayout) {
    return <Web>{children}</Web>;
  }

  if (isInMobileLayout) {
    return <Mobile>{children}</Mobile>;
  }

  return (
    <BreadcrumbsUuidProvider>
      <BreadcrumbsProvider>
        <GradientProvider>
          <General>{children}</General>
        </GradientProvider>
      </BreadcrumbsProvider>
    </BreadcrumbsUuidProvider>
  );
};
