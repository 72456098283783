import React from "react";

import { stringNotEmpty } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import { Tag, Tooltip, useIsMobileWidth, useTheme } from "@equiem/react-admin-ui";

import type {
  VisitorForBuildingReceptionFragment,
  VisitorForReceptionFragment,
} from "../../../../generated/visitors-client";
import { VisitorInformationDialog } from "../../../appointment/components/VisitorInformationDialog";
import { DropdownRecurringInfo } from "../../../appointments/components/AppointmentRecurringInfoView";

type VisitorReception = VisitorForReceptionFragment | VisitorForBuildingReceptionFragment;

const ownHandIcon = (
  <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.28805 2.30435C8.10899 2.30435 7.96378 2.45034 7.96378 2.63043V8.82609H6.66667V3.93478C6.66667 3.75469 6.52148 3.6087 6.34239 3.6087C6.16329 3.6087 6.01811 3.75469 6.01811 3.93478V10.1304C5.78873 10.1304 4.72102 10.1304 4.72102 10.1304C4.47384 9.07339 3.84036 8.46133 3.16135 8.38365C3.34545 8.86939 3.65799 9.6473 4.18019 10.8475C4.73715 12.1275 5.29891 13.0794 6.0204 13.7165C6.71732 14.3317 7.61252 14.6957 8.93661 14.6957C10.9067 14.6957 12.5037 13.0898 12.5037 11.1088V5.56522C12.5037 5.38513 12.3585 5.23913 12.1794 5.23913C12.0003 5.23913 11.8551 5.38513 11.8551 5.56522V8.82609H10.558V3.6087C10.558 3.4286 10.4128 3.28261 10.2337 3.28261C10.0547 3.28261 9.90944 3.4286 9.90944 3.6087V8.82609H8.61233V2.63043C8.61233 2.45034 8.46712 2.30435 8.28805 2.30435ZM13.8008 11.1088C13.8008 13.8103 11.623 16 8.93661 16C6.98921 16 6.01638 15.5313 5.16458 14.6966C4.22414 13.8663 3.56986 12.6988 2.99185 11.3703C2.38861 9.98389 2.05533 9.13939 1.87365 8.64576C1.64229 8.0172 1.96201 7.13834 2.83086 7.07726C3.5799 7.02459 4.21539 7.26902 4.721 7.69046V3.93478C4.721 3.03432 5.44692 2.30435 6.34239 2.30435C6.46238 2.30435 6.57933 2.31746 6.69189 2.34232C6.82718 1.57928 7.49033 1 8.28805 1C8.97552 1 9.56305 1.43024 9.79893 2.03755C9.93727 1.99892 10.0831 1.97826 10.2337 1.97826C11.1292 1.97826 11.8551 2.70823 11.8551 3.6087V3.9674C11.9599 3.94601 12.0684 3.93478 12.1794 3.93478C13.0749 3.93478 13.8008 4.66475 13.8008 5.56522V11.1088Z"
      fill="#666666"
    />
  </svg>
);

export const VisitorDetails = ({ visitor }: { visitor: VisitorReception }) => {
  const { colors } = useTheme();
  const { t } = useTranslation();
  const isMobile = useIsMobileWidth();
  const isReccurringAppointment = visitor.appointment.recurringUuid != null;
  const isReceptionNote = stringNotEmpty(visitor.receptionNote?.value) || (visitor.visitorInfo?.length ?? 0) > 0;

  return (
    <>
      <div className="container">
        {visitor.appointment.isWalkIn === true && <Tag>{t("visitors.reception.walkIn")}</Tag>}
        {isReccurringAppointment && (
          <Tooltip placement="top" title={t("visitors.reception.recurringAppointment")}>
            <div className="d-flex align-items-center icon-container">
              <DropdownRecurringInfo appointmendUuid={visitor.appointment.uuid} />
            </div>
          </Tooltip>
        )}
        {isReceptionNote && (
          <div className="icon-container">
            <VisitorInformationDialog visitor={visitor} isCloseButton={false} isHoverTrigger={!isMobile} />
          </div>
        )}
        {Boolean(visitor.appointment.holdInLobby) && <div className="icon-container">{ownHandIcon}</div>}
      </div>
      <style jsx>{`
        .container {
          display: flex;
        }

        .icon-container {
          width: 32px;
          height: 32px;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .visitor-information-title {
          font-size: 14px;
          color: ${colors.grayscale[100]};
          font-weight: 500;
        }
      `}</style>
    </>
  );
};
