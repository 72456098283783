import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import React, { useCallback, useState } from "react";
import { CreditCardBrand } from "./CreditCardBrand";
import type { CardBrand } from "../../generated/gateway-client";
import { useMyCreditCardsQuery, useSaveCardMutation } from "../../generated/gateway-client";
import { useTranslation } from "@equiem/localisation-eq1";
import { Button, Form, ProgressCircle, useTheme, useToast } from "@equiem/react-admin-ui";
import { useShowError } from "../../hooks";

interface Props {
  className?: string;
  autofocus?: boolean;
  setDefault: boolean;
  onCardAdded?: (card: { uuid: string }) => void;
  onCancel?: () => void;
}

export const NewCreditCard: React.FC<Props> = ({
  className = "",
  autofocus = false,
  setDefault,
  onCardAdded = () => undefined,
  onCancel = () => undefined,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const stripe = useStripe();
  const elements = useElements();
  const toast = useToast();
  const showError = useShowError();
  const [saveCard] = useSaveCardMutation();
  const cardsQuery = useMyCreditCardsQuery({ skip: true });
  const [brand, setBrand] = useState<CardBrand>("UNKNOWN");
  const [focused, setFocused] = useState(false);
  const [cardComplete, setCardComplete] = useState(false);
  const [description, setDescription] = useState("");
  const [saving, setSaving] = useState(false);

  const onSaveCard = useCallback(async () => {
    try {
      const cardElement = elements?.getElement(CardElement);
      if (cardElement == null || stripe == null) {
        throw new Error(t("equiem-one-ui.creditcard.stripeElementIsNotInitialised").toString());
      }

      setSaving(true);
      const { token, error } = await stripe.createToken(cardElement, {
        name: description,
      });

      if (error != null) {
        toast.negative(error instanceof Error ? error.message : t("common.unknownError"));
        return;
      }

      if (token.id == null) {
        toast.negative(t("equiem-one-ui.creditcard.tokenisationFailed"));
        return;
      }

      const result = await saveCard({
        variables: {
          card: {
            newCardToken: token.id,
            description,
            setDefault,
          },
        },
      });

      const saved = result.data?.saveOwnCard;
      if (saved == null) {
        throw new Error(t("common.somethingWrong").toString());
      }

      if (saved.created < Date.now() - 60_000) {
        toast.positive(
          saved.default
            ? t("equiem-one-ui.creditcard.cardDetailsExistandDefault")
            : t("equiem-one-ui.creditcard.cardDetailsExist"),
        );
      } else {
        setDescription("");
        toast.positive(
          saved.default ? t("equiem-one-ui.creditcard.cardSavedAndDefault") : t("equiem-one-ui.creditcard.cardSaved"),
        );
      }

      await cardsQuery.refetch();
      onCardAdded({ uuid: saved.uuid });
      try {
        cardElement.clear();
      } catch (e: unknown) {
        // Element has probably been destroyed.
      }
    } catch (e: unknown) {
      console.error(e);
      throw e;
    } finally {
      setSaving(false);
    }
  }, [elements, stripe, description, saveCard, setDefault, cardsQuery, onCardAdded, t, toast]);

  return (
    <div className={`new-card ${className}`}>
      <Form.Input
        placeholder={t("equiem-one-ui.creditcard.addDescription")}
        required
        value={description}
        onChange={(e) => {
          setDescription(e.target.value);
        }}
      />
      <div className={`card-input ${focused ? "focused" : ""}`}>
        <CreditCardBrand brand={brand} />
        <div className="card-element hide-in-percy">
          <CardElement
            onReady={(e) => {
              if (autofocus) {
                e.focus();
              }
            }}
            onFocus={() => {
              setFocused(true);
            }}
            onBlur={() => {
              setFocused(false);
            }}
            onChange={(e) => {
              setBrand(
                {
                  amex: "AMERICAN_EXPRESS" as CardBrand,
                  diners: "DINERS_CLUB" as CardBrand,
                  discover: "DISCOVER" as CardBrand,
                  jcb: "JCB" as CardBrand,
                  mastercard: "MASTERCARD" as CardBrand,
                  unionpay: "UNIONPAY" as CardBrand,
                  visa: "VISA" as CardBrand,
                  unknown: "UNKNOWN" as CardBrand,
                }[e.brand],
              );
              setCardComplete(e.complete);
            }}
            options={{
              hideIcon: true,
              hidePostalCode: true,
              style: {
                base: {
                  "::placeholder": {
                    color: "#bbb",
                  },
                  "lineHeight": "22px",
                  "fontFamily": "Roboto",
                  "fontSize": "14px",
                  "color": "#495057",
                  "fontWeight": "300",
                },
              },
            }}
          />
        </div>
      </div>
      <div className="mt-3 text-right">
        <Button
          variant="ghost"
          className="mr-2"
          onClick={() => {
            onCancel();
          }}
        >
          {t("common.cancel")}
        </Button>
        <Button
          variant="primary"
          type="button"
          disabled={!cardComplete || description.trim() === "" || saving}
          onClick={(e) => {
            e.preventDefault();
            onSaveCard().catch(showError);
          }}
        >
          {saving ? <ProgressCircle size="sm" className="mr-1" /> : null}
          {t("equiem-one-ui.creditcard.addCard")}
        </Button>
      </div>
      <style jsx>{`
        .new-card {
          border: 1px solid ${theme.colors.border};
          border-radius: ${theme.borderRadius};
          padding: ${theme.spacers.s4};
        }
        .card-input {
          display: flex;
          align-items: center;
          gap: ${theme.spacers.s2};
          padding: ${theme.spacers.s3} 0 0;
        }
        .card-element {
          border: 1px solid ${theme.colors.border};
          padding: ${theme.spacers.s3};
          border-radius: ${theme.borderRadius};
          flex-grow: 1;
        }
        h2 {
          font-size: 1rem;
        }
        .card-input.focused .card-element {
          border-color: ${theme.colors.lightContrast} !important;
        }
        .new-card :global(.form-group) {
          margin-bottom: 0;
        }
        @media (max-width: 374px) {
          .card-input {
            display: block !important;
          }
        }
      `}</style>
    </div>
  );
};
