import React from "react";
import type { BaseButtonVariants } from "./AdminButton";
import { AdminButton as Button } from "./AdminButton";

interface P {
  children?: React.ReactNode;
  onClick?: () => void;
  className?: string;
  disabled?: boolean;
  variant?: BaseButtonVariants;
  type?: "submit" | "reset" | "button";
  size?: "sm" | "md" | "lg";
  hover?: {
    background: string;
  };
}
export const IconButton: React.FC<P> = ({
  children,
  onClick,
  disabled,
  variant = "ghost",
  className = "",
  type = "button",
  hover,
  size = "md",
}) => {
  return (
    <span>
      <Button
        type={type}
        variant={variant}
        size={size}
        disabled={disabled}
        onClick={onClick}
        shape="round"
        className={`btn-icon ${className}`}
      >
        {children}
      </Button>
      {hover?.background != null && (
        <style jsx>{`
          span :global(.btn-icon):hover {
            background: ${hover.background};
          }
        `}</style>
      )}
    </span>
  );
};
