import { useTranslation } from "@equiem/localisation-eq1";
import { RiHandHeartLine, RiVipCrownLine } from "@equiem/react-admin-ui/icons";

import type { VisitorInfo } from "../../../generated/visitors-client";

export const useVisitorInformation = () => {
  const { t } = useTranslation();

  const visitorInfoTypes = ["VIP", "REQUIRE_ASSISTANCE"] as VisitorInfo[];

  const getVisitorInfoIcons = (visitorInfo: VisitorInfo) => {
    const icons = {
      VIP: RiVipCrownLine,
      REQUIRE_ASSISTANCE: RiHandHeartLine,
    };

    return icons[visitorInfo];
  };

  const getVisitorInfoTranslation = (visitorInfo: VisitorInfo) => {
    const translations = {
      VIP: t("visitors.type.vip"),
      REQUIRE_ASSISTANCE: t("visitors.type.requireAssistance"),
    };
    return translations[visitorInfo];
  };

  return {
    visitorInfoTypes,
    getVisitorInfoTranslation,
    getVisitorInfoIcons,
  };
};
