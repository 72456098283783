import { stringNotEmpty } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import { Button, Form, handleMultilineTextAreaInput, remainingCharacters } from "@equiem/react-admin-ui";
import { RiStickyNoteLine } from "@equiem/react-admin-ui/icons";
import { useFormikContext } from "formik";
import React, { useState } from "react";
import type { BookingFormValue } from "../models/BookingFormValue";

const NOTE_MAX_LENGTH = 500;

interface Props {
  fieldName: "note" | "adminNote";
  addButtonText: string;
  label: string;
  tooltipText: string;
  placeholder: string;
}

export const BookingFormNote: React.FC<Props> = ({ fieldName, addButtonText, label, tooltipText, placeholder }) => {
  const { t } = useTranslation();
  const fm = useFormikContext<BookingFormValue>();
  const error = fm.touched[fieldName] === true ? fm.errors[fieldName] : undefined;
  const [showField, setShowField] = useState(stringNotEmpty(fm.values[fieldName]));

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    fm.setFieldValue(fieldName, e.target.value).catch(console.error);
    fm.setFieldTouched(fieldName).catch(console.error);
  };

  const handleEnter = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    handleMultilineTextAreaInput(e, (value) => {
      fm.setFieldValue(fieldName, value).catch(console.error);
    });
  };

  return (
    <>
      {!showField ? (
        <Button variant="ghost" className="p-3" onClick={() => setShowField(true)}>
          <RiStickyNoteLine size={20} />
          {addButtonText}
        </Button>
      ) : (
        <Form.Group
          label={label}
          showTooltip={true}
          tooltipText={tooltipText}
          error={error}
          hint={t("common.remainingCharacters", {
            count: remainingCharacters(NOTE_MAX_LENGTH, fm.values[fieldName]?.length ?? 0),
          })}
        >
          <Form.Textarea
            name={fieldName}
            rows={3}
            maxLength={NOTE_MAX_LENGTH}
            placeholder={placeholder}
            value={fm.values[fieldName] ?? ""}
            onChange={handleChange}
            onKeyDown={handleEnter}
          />
        </Form.Group>
      )}
    </>
  );
};
