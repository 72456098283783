import { useConnectBookingPaymentMutation } from "../../../generated/gateway-client";
import type { Static } from "runtypes";
import { Null, Optional, Record as RRecord, String, Undefined, Union } from "runtypes";
import { stringNotEmpty } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";

const Success = RRecord({
  scope: String,
  code: String,
  state: Optional(Union(String, Undefined, Null)),
});
type Success = Static<typeof Success>;

const Failure = RRecord({
  error: String,
  error_description: String,
  state: Optional(Union(String, Undefined, Null)),
});
type Failure = Static<typeof Failure>;

export const useCompleteStripeConnect = () => {
  const { t } = useTranslation();
  const [mutation] = useConnectBookingPaymentMutation();

  const handleSuccess = async (success: Success) => {
    const state = success.state;
    if (!stringNotEmpty(state)) {
      throw new Error(t("bookings.settings.stateNotProvided"));
    }

    if (!stringNotEmpty(success.code)) {
      throw new Error(t("bookings.settings.missingConnectionCode"));
    }

    const [token, site] = state.split("--");
    if (!stringNotEmpty(site) || !stringNotEmpty(token)) {
      throw new Error(t("bookings.settings.incompleteStateInformation"));
    }

    const expiry = Number(window.localStorage.getItem(state));
    if (expiry === 0 || Number.isNaN(expiry)) {
      throw new Error(t("bookings.settings.weAreUnableToFindYourStripeConnectionDetails"));
    }

    if (Date.now() > expiry) {
      throw new Error(t("bookings.settings.invalidToken"));
    }

    const result = await mutation({ variables: { code: success.code, site } });
    if (result.data?.connectBookingPayment.__typename === "ConnectDisconnectBookingPaymentFailureResponse") {
      throw new Error(result.data.connectBookingPayment.reason);
    }

    window.localStorage.removeItem(state);

    return true;
  };

  const handleFailure = (error: Failure) => {
    throw new Error(error.error_description);
  };

  return {
    processIt: async (query: Record<string, string | string[] | undefined>) => {
      if (Success.guard(query)) {
        return handleSuccess(query);
      }
      if (Failure.guard(query)) {
        handleFailure(query);
      }
      throw new Error(t("bookings.settings.unknownParametersOnStripeConnectInboundLink"));
    },
  };
};
