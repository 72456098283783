import React, { useCallback, useContext, useState } from "react";
import { formatters, useTranslation } from "@equiem/localisation-eq1";
import { AccordionList, AccordionListItem, Skeleton, useTheme, Button } from "@equiem/react-admin-ui";
import type { BookingChargesQuery } from "../../../../generated/gateway-client";
import { RiPriceTag3Line, RiEditLine } from "@equiem/react-admin-ui/icons";
import { centsToDollar, dollarToCents, stringNotEmpty } from "@equiem/lib";
import { useFormikContext } from "formik";
import { BookingPaymentCustomPriceModal } from "./BookingPaymentCustomPriceModal";
import { BookingChargesContext } from "../../contexts/BookingChargesProvider";
import { BookingModalInfo } from "../../contexts/BookingModalInfoProvider";
import type { BookingFormValue } from "../../models/BookingFormValue";
import { BookingStatus } from "../../../../generated/gateway-client";
import { BreakWordDiv } from "../../../../lib/BreakWordDiv";
import { CurrencyAmount } from "../../../../lib/CurrencyAmount";
import { convertInputMoneyToNumber } from "../../../../lib/convertInputMoneyToNumber";

interface DiscountPercentageLineItemProps {
  name: string;
  percentage: number;
  className?: string;
}

interface LineItemProps {
  title?: string;
  item: string;
  description?: string | null;
  amount: number;
  currencyCode: string;
  isTax?: boolean;
  isTotal?: boolean;
  isCustomTotal?: boolean;
  className?: string;
  priceOverridden?: boolean;
}

const LineItem: React.FC<LineItemProps> = ({
  title,
  item,
  description,
  amount,
  currencyCode,
  className = "",
  isTax = false,
  isTotal = false,
  isCustomTotal = false,
  priceOverridden = false,
}) => (
  <>
    <div
      className={`line-item flex-row d-flex justify-content-between ${className} ${isTax ? "text-muted" : ""} ${
        isTotal ? "font-weight-bold" : ""
      }`}
    >
      <BreakWordDiv className="flex-grow-1 mr-2">
        {stringNotEmpty(title) && <>{title}</>}
        {item}
        {description != null ? ` (${description})` : ""}
      </BreakWordDiv>
      {(amount > 0 || isCustomTotal) && (
        <CurrencyAmount
          className={`flex-shrink-0 ${isCustomTotal ? "font-weight-bold" : ""}`}
          amount={amount}
          currencyCode={currencyCode}
          overridden={priceOverridden}
        />
      )}
    </div>
  </>
);

export const DiscountPercentageLineItem: React.FC<DiscountPercentageLineItemProps> = ({
  name,
  percentage,
  className = "",
}) => (
  <>
    <div className={`line-item flex-row d-flex justify-content-between ${className}`}>
      <BreakWordDiv className="flex-grow-1 mr-2">{`${name} (${percentage}%)`}</BreakWordDiv>
    </div>
    <style jsx>{`
      .line-item {
        color: #008000;
      }
    `}</style>
  </>
);

export const DifferenceLineItem: React.FC<LineItemProps> = ({ item, amount, currencyCode, className = "" }) => (
  <>
    <div className={`line-item flex-row d-flex justify-content-between ${className}`}>
      <BreakWordDiv className="flex-grow-1 mr-2">
        {`${item} (`}
        {amount > 0 && "+"}
        <CurrencyAmount amount={amount} currencyCode={currencyCode} />
        {")"}
      </BreakWordDiv>
    </div>
    <style jsx>{`
      .line-item {
        color: #008000;
      }
    `}</style>
  </>
);

const LineItemAddOn: React.FC<LineItemProps> = ({ item, description, amount, currencyCode, className }) => {
  const items = item.split(":");
  const rest = items.length === 1 ? "" : item.replace(`${items[0]}:`, "");

  return (
    <LineItem
      className={className}
      title={items[0]}
      item={stringNotEmpty(rest) ? `: ${rest}` : ""}
      description={description}
      amount={amount}
      currencyCode={currencyCode}
    />
  );
};

interface CDP {
  charge: BookingChargesQuery["bookingCharges"];
}
const ChargeDisplay: React.FC<CDP> = ({ charge }) => {
  const { t } = useTranslation();
  const {
    resourcePriceDescription,
    resourcePrice,
    addOnPrices,
    taxes,
    subtotal,
    total,
    originalTotal,
    currencyCode,
    discountsV2: discounts,
    resourcePriceWithDiscount,
  } = charge;
  const hasDiscounts = discounts.length > 0;

  return (
    <>
      <LineItem
        className="pb-3"
        item={t("bookings.operations.roomBooking")}
        description={resourcePriceDescription}
        amount={resourcePrice}
        currencyCode={currencyCode}
        priceOverridden={hasDiscounts}
      />
      {hasDiscounts && (
        <>
          {discounts.map((d, key) => (
            <DiscountPercentageLineItem
              key={key}
              className="pl-4 pb-2 d-percentage"
              name={d.title}
              percentage={d.percentage}
            />
          ))}
          <LineItem
            className="pl-4 pb-2 resource-price-discounted"
            item={t("bookings.discounts.priceWithDiscount")}
            amount={resourcePriceWithDiscount}
            currencyCode={currencyCode}
          />
        </>
      )}
      {addOnPrices.map((ao, i) => (
        <LineItemAddOn
          className="pb-3"
          key={i}
          item={ao.description}
          description={(ao.quantity ?? 0) > 0 ? `x${ao.quantity}` : null}
          amount={ao.price}
          currencyCode={currencyCode}
        />
      ))}
      {taxes.map((tax, i) => (
        <LineItem
          className="pb-3"
          key={i}
          isTax
          item={tax.description}
          amount={tax.price}
          currencyCode={currencyCode}
        />
      ))}
      {(total > 0 || subtotal > 0 || (originalTotal != null && originalTotal > 0)) && (
        <>
          <LineItem
            className="pb-3"
            isTotal
            item={t("common.total")}
            amount={originalTotal ?? total}
            currencyCode={currencyCode}
            priceOverridden={originalTotal != null}
          />
          {originalTotal != null && (
            <>
              <DifferenceLineItem
                className="pl-4 pb-2"
                item={t("bookings.operations.difference")}
                amount={total - originalTotal}
                currencyCode={currencyCode}
              />
              <LineItem
                className="pl-4 pb-2"
                isCustomTotal
                item={t("bookings.operations.newTotalCost")}
                amount={total}
                currencyCode={currencyCode}
              />
            </>
          )}
        </>
      )}
    </>
  );
};

interface CPP {
  charge: BookingChargesQuery["bookingCharges"];
}
const CustomPayment: React.FC<CPP> = ({ charge }) => {
  const { colors } = useTheme(true);
  const { booking } = useContext(BookingModalInfo);
  const fm = useFormikContext<BookingFormValue>();
  const [showEditCustomPriceModal, setShowEditCustomPriceModal] = useState(false);
  const { total, originalTotal, currencyCode } = charge;

  const editCustomPrice = useCallback(
    (newCustomPrice: number | null) => {
      void fm.setFieldValue("customPrice", dollarToCents(convertInputMoneyToNumber(newCustomPrice)));
      setShowEditCustomPriceModal(false);
    },
    [fm],
  );

  if (
    booking == null ||
    booking.resource.viewerPermissions?.canManageBookings === false ||
    booking.status !== BookingStatus.PendingApproval
  ) {
    return null;
  }

  return (
    <>
      <Button
        className="override-custom-price-button"
        variant="ghost"
        shape="round"
        size="md"
        onClick={() => {
          setShowEditCustomPriceModal(true);
        }}
      >
        <RiEditLine size="16" color={colors.primary} />
      </Button>

      <BookingPaymentCustomPriceModal
        showModal={showEditCustomPriceModal}
        setShowModal={setShowEditCustomPriceModal}
        customPrice={fm.values.customPrice != null ? centsToDollar(fm.values.customPrice) : null}
        originalTotalPrice={originalTotal ?? total}
        currencyCode={currencyCode}
        onEdit={editCustomPrice}
      />
    </>
  );
};

interface P {
  className?: string;
}
export const BookingPaymentSummary: React.FC<P> = ({ className }) => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const { timezone } = useContext(BookingModalInfo);
  const { loading, charges } = useContext(BookingChargesContext);

  if (loading) {
    return (
      <BreakWordDiv className={className}>
        <h4 className="font-weight-bold mb-4">{t("bookings.operations.costs")}</h4>
        <Skeleton.Line width="100%" height="24px" />
        <Skeleton.Line width="100%" height="24px" />
        <Skeleton.Line width="100%" height="24px" />
      </BreakWordDiv>
    );
  }

  if (charges.length === 0) {
    return null;
  }

  if (charges.length === 1) {
    return (
      <BreakWordDiv className={className}>
        <div className="d-flex align-items-center justify-content-between">
          <h4 className="font-weight-bold mb-4">{t("bookings.operations.costs")}</h4>
          <CustomPayment charge={charges[0]} />
        </div>
        <ChargeDisplay charge={charges[0]} />
      </BreakWordDiv>
    );
  }

  const total = charges.reduce((prev, curr) => prev + curr.total, 0);

  return (
    <BreakWordDiv className={className}>
      <h4 className="font-weight-bold mb-4">{t("bookings.operations.costs")}</h4>
      <AccordionList
        HeaderIcon={RiPriceTag3Line}
        headerText={`${t("bookings.repeat.costsBreakdown")} ${t("bookings.repeat.bookingAppearanceTotal", {
          count: charges.length,
        })}`}
        showCollapseButton
        showCollapsed={false}
        colorTheme="white"
      >
        <AccordionListItem>
          {charges.map((c, key) => (
            <div className="section" key={key}>
              <strong>
                <span className="mr-1">{formatters.datelong(c.startDate, i18n.language, { timezone })}: </span>
                {formatters.timeshort(c.startDate, i18n.language, { timezone })} -{" "}
                {formatters.timeshort(c.endDate, i18n.language, { timezone })}
              </strong>
              <ChargeDisplay charge={c} />
            </div>
          ))}
        </AccordionListItem>
      </AccordionList>
      <div className="recurring-total">
        {t("common.total")}
        <CurrencyAmount className={"flex-shrink-0"} amount={total} currencyCode={charges[0].currencyCode} />
      </div>
      <style jsx>{`
        .recurring-total {
          padding: ${theme.spacers.s5} 0;
          font-weight: 600;
          display: flex;
          justify-content: space-between;
        }
        .section {
          border-bottom: 1px solid ${theme.colors.border};
          padding: ${theme.spacers.s3};
          margin-bottom: ${theme.spacers.s3};
        }
        .section:last-child {
          border: 0;
          margin-bottom: 0;
        }
      `}</style>
    </BreakWordDiv>
  );
};
