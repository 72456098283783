import { stringNotEmpty } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import { useTheme } from "@equiem/react-admin-ui";
import { RiCloseLine } from "@equiem/react-admin-ui/icons";
import type { IImage } from "@equiem/uploader";
import { useImageUploaderState, Dropzone } from "@equiem/uploader";
import type { FieldInputProps, FieldProps } from "formik";
import type { FC } from "react";
import React, { useEffect, useState } from "react";
import SortableList, { SortableItem } from "react-easy-sort";
import { ResourceCreateAndEditFormGalleryUploaderModal } from "./ResourceCreateAndEditFormGalleryUploaderModal";
import { useCreateBookableResourceUploadUrlMutation, AdminImageType } from "../../../../../generated/gateway-client";

const IMG_WIDTH = 143;
const IMG_HEIGHT = 80;

const getContentType = (type?: string): AdminImageType | undefined => {
  switch (type?.toLowerCase()) {
    case "png":
      return AdminImageType.Png;
    case "gif":
      return AdminImageType.Gif;
    default:
      return AdminImageType.Jpeg;
  }
};

interface ExtendedFieldInputProps extends FieldInputProps<IImage[]> {
  showThumbnail: boolean;
}

interface ExtendedFieldProps extends Omit<FieldProps<IImage[]>, "field"> {
  field: ExtendedFieldInputProps;
}

export const ResourceCreateAndEditFormGalleryUploader: FC<ExtendedFieldProps["field"]> = (props) => {
  const { t } = useTranslation();
  const { colors, spacers } = useTheme(true);

  const [showUploaderModal, setShowUploaderModal] = useState(false);

  const [createPresignedUrl] = useCreateBookableResourceUploadUrlMutation();
  const {
    localAttachedImage,
    attachedImages,
    dragover,
    uploading,
    setDragover,
    onFileChange,
    progress,
    onCrop,
    onSaveCropped,
    cropperRef,
    clearCropper,
    removeImage,
    sortOrder,
  } = useImageUploaderState(
    props.value,
    (imgs) =>
      props.onChange({
        target: {
          name: props.name,
          value: imgs,
        },
      }),
    async (input) =>
      createPresignedUrl({
        variables: {
          input: {
            filename: input.filename,
            contentType: getContentType(input.contentType),
          },
        },
      }).then((r) => {
        if (r.errors != null || r.data == null) {
          console.error(r.errors);
          throw new Error("Presigned URL request failed");
        }

        return r.data.createBookableResourceUploadUrl;
      }),
    true,
  );

  useEffect(() => {
    if (localAttachedImage != null) {
      setShowUploaderModal(true);
    }
    return () => setShowUploaderModal(false);
  }, [localAttachedImage]);

  return (
    <>
      {localAttachedImage == null ? (
        <Dropzone
          dragover={dragover}
          uploading={uploading}
          setDragover={setDragover}
          onFileChange={onFileChange}
          progress={progress}
        />
      ) : (
        <ResourceCreateAndEditFormGalleryUploaderModal
          showUploadModal={showUploaderModal}
          localAttachedImage={localAttachedImage}
          onCrop={onCrop}
          cropperRef={cropperRef}
          onSaveCropped={onSaveCropped}
          clearCropper={clearCropper}
          progress={progress}
          uploading={uploading}
        />
      )}
      {attachedImages.length > 0 && (
        <SortableList onSortEnd={sortOrder} draggedItemClassName="img-dragged">
          <div className="imgs-container mt-5">
            {attachedImages.map((img, index) => (
              <SortableItem key={index}>
                <div className="img-cont">
                  {index === 0 && <div className="thumbnail">{t("bookings.operations.thumbnail").toLowerCase()}</div>}
                  <img
                    src={
                      stringNotEmpty(img.url)
                        ? `${img.url}?auto=compress&fit=crop&crop=entropy&w=${IMG_WIDTH}&h=${IMG_HEIGHT}`
                        : ""
                    }
                    alt=""
                  />
                  <div className="delete-img">
                    <RiCloseLine onClick={() => removeImage(img.key)} />
                  </div>
                </div>
              </SortableItem>
            ))}
          </div>
        </SortableList>
      )}
      <style jsx>{`
        .imgs-container {
          display: flex;
          flex-wrap: wrap;
          gap: ${spacers.s3};
          user-select: none;
        }
        .thumbnail {
          position: absolute;
          display: ${props.showThumbnail ? "block" : "none"};
          left: ${spacers.s0};
          top: ${spacers.s0};
          font-size: 10px;
          font-weight: 500;
          line-height: 24px;
          padding: ${spacers.s0} ${spacers.s2};
          background: ${colors.transparent.white[80]};
          border-radius: 4px;
        }
        .img-cont {
          user-select: none;
          position: relative;
          cursor: grab;
        }
        .img-cont img {
          pointer-events: none;
          width: ${IMG_WIDTH}px;
          height: ${IMG_HEIGHT}px;
          border-radius: 4px;
          border: 1px solid ${colors.border};
        }
        .img-cont :global(.delete-img) {
          position: absolute;
          top: ${spacers.s3};
          right: ${spacers.s3};
          font-size: 20px;
          line-height: 20px;
          cursor: pointer;
          background: ${colors.white};
          border-radius: 16px;
        }
        .img-dragged {
          z-index: 1001;
          opacity: 0.7 !important;
        }
      `}</style>
    </>
  );
};
