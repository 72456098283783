/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import type { FormikHelpers, FormikProps } from "formik";
import { Formik } from "formik";

import { CurrentProfile, CurrentRole, Role, Site, useShowError } from "@equiem/lib";
import { useApolloErrorTranslation, useTranslation } from "@equiem/localisation-eq1";
import { Button, Modal, ProgressCircle, Tabs, useConfirmer, useTheme, useToast } from "@equiem/react-admin-ui";

import { ModalChild } from "../../../contexts/ModalChildContext";
import { Modal as ModalContext } from "../../../contexts/ModalContext";
import { useWidgetContext } from "../../../contexts/WidgetContext";
import { useProfileDetailsQuery, useSiteDetailsQuery } from "../../../generated/settings-client";
import { EditProfile } from "../components/EditProfileForm";
import { UserCardHeader } from "../components/UserCardHeader";
import { UserFlexMemberships } from "../components/UserFlexMemberships";
import { UserHistory } from "../components/UserHistory";
import { UserIntegrations } from "../components/UserIntegrations";
import { UserNotifications } from "../components/UserNotifications";
import { UserPaymentSettings } from "../components/UserPaymentSettings";
import { UserProfileDangerZone } from "../components/UserProfileDangerZone";
import { CurrentUserRoleProvider } from "../contexts/CurrentUserRoleContext";
import { UsersFilterContext } from "../contexts/UsersFilterContext";
import { useUserFormSubmit } from "../hooks/useUserFormSubmit";
import { Roles } from "../roles/RolesContext";
import { UserRoles } from "../roles/UserRoles";
import type { FormValues } from "../types";
import type { ProfileTabKey } from "../utils/editProfileTabs";
import { editProfileTabs } from "../utils/editProfileTabs";
import { getValidationSchema } from "../utils/validationSchema";

import { AvatarUploadWidget } from "./AvatarUploadWidget";

// eslint-disable-next-line complexity
export const UserProfileWidget: React.FC = () => {
  const { t } = useTranslation();
  const { tError } = useApolloErrorTranslation();
  const [showModal, setShowModal] = useState(false);
  const showError = useShowError();
  const modal = useContext(ModalContext);
  const childModal = useContext(ModalChild);
  const { profile: ownProfile, isFlexRelated, canManageCurrentSite } = useContext(CurrentProfile);
  const { spacers, colors } = useTheme();
  const { currentRole } = useContext(CurrentRole);
  const site = useContext(Site);
  const toast = useToast();
  const { withConfirmation } = useConfirmer();
  const formInnerRef = useRef<FormikProps<FormValues>>(null);
  const { setFilters } = useContext(UsersFilterContext);
  const { handleSave, actionsState, setActionState } = useWidgetContext();
  const rolesCtx = useContext(Roles);

  const adminView = modal.id != null;
  const profileUuid = modal.id ?? ownProfile?.uuid;

  const { data: siteDetails } = useSiteDetailsQuery({
    variables: {
      uuid: site.uuid,
    },
  });

  const tabs = editProfileTabs(t);

  const isIntegrationsEnabled = siteDetails?.destination.integrations?.evolution.enabled ?? false;
  const enabledTabs = tabs.filter((tab) => {
    switch (tab.key) {
      case "integrations":
        return adminView && isIntegrationsEnabled;
      case "payment":
        return !adminView;
      case "roles":
        return adminView;
      case "history":
        return adminView && canManageCurrentSite;
      case "dangerZone":
        return !adminView;
      case "flexMembership":
        return (
          adminView && isFlexRelated && (currentRole === Role.WorkplaceManager || currentRole === Role.FlexManager)
        );
      case "notifications":
        return currentRole !== Role.FlexManager;
      case "profile":
        return currentRole !== Role.FlexManager;
      default:
        return true;
    }
  });

  const [selectedTab, setSelectedTab] = useState<ProfileTabKey>(enabledTabs[0].key);
  useEffect(() => {
    setSelectedTab(enabledTabs[0].key);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModal]);

  const { data, loading, error, refetch } = useProfileDetailsQuery({
    variables: { uuid: profileUuid ?? "", withPermissions: selectedTab === "notifications" },
    skip: !showModal,
    fetchPolicy: "network-only",
  });
  const profile = data?.profile;
  const showBookingNotificationPreferences = site.featureModules.bookings.enabled;
  const showApprovalNotificationsPreferences = profile?.canManageAnySite === true && canManageCurrentSite;
  const { onSubmit } = useUserFormSubmit(
    profileUuid ?? "",
    site.uuid,
    profile,
    formInnerRef,
    showBookingNotificationPreferences,
    showApprovalNotificationsPreferences,
  );

  const onClose = useCallback(() => {
    setShowModal(false);
    modal.close();
    childModal.close();
    rolesCtx.clear();
  }, [modal, childModal, rolesCtx]);

  const onCloseModal = useCallback(() => {
    formInnerRef.current?.dirty === true || rolesCtx.unsavedChanges
      ? withConfirmation({
          title: t("common.areYouSure"),
          message: t("settings.editProfile.cancellingAgreement"),
          confirmButtonText: t("common.yesCancel"),
          cancelButtonText: t("home.widgets.cancelNo"),
          confirmButtonVariant: "danger",
          onConfirm: onClose,
        })()
      : onClose();
  }, [rolesCtx.unsavedChanges, withConfirmation, t, onClose]);

  useEffect(() => {
    if (modal.activeModal === "EditProfile") {
      setShowModal(true);
    }
  }, [modal.activeModal]);

  useEffect(() => {
    if (
      selectedTab === "profile" ||
      selectedTab === "notifications" ||
      selectedTab === "roles" ||
      selectedTab === "flexMembership"
    ) {
      setActionState("onSave", "normal");
      return;
    }

    setActionState("onSave", "hidden");
  }, [selectedTab, setActionState]);

  const currentTab = useMemo(() => {
    if (profile == null) {
      return <></>;
    }

    switch (selectedTab) {
      case "profile":
        return <EditProfile profile={profile} adminView={adminView} />;
      case "history":
        return <UserHistory profile={profile} />;
      case "notifications":
        return (
          <CurrentUserRoleProvider>
            <UserNotifications
              adminView={adminView}
              showBookingNotificationPreferences={showBookingNotificationPreferences}
              showApprovalNotificationsPreferences={showApprovalNotificationsPreferences}
            />
          </CurrentUserRoleProvider>
        );
      case "flexMembership":
        return <UserFlexMemberships user={profile} />;
      case "integrations":
        return <UserIntegrations />;
      case "payment":
        return <UserPaymentSettings profile={profile} onCardListChanged={refetch} />;
      case "roles":
        return <UserRoles user={profile} />;
      case "dangerZone":
        return <UserProfileDangerZone />;
      default:
        return <></>;
    }
  }, [
    profile,
    selectedTab,
    adminView,
    showBookingNotificationPreferences,
    refetch,
    showApprovalNotificationsPreferences,
  ]);

  const groups = profile != null ? profile.siteProfiles.edges.flatMap((edge) => edge.node?.groups ?? []) : [];
  const type = profile?.siteProfiles.edges[0]?.node?.registrationType;
  const currentSiteProfile = profile?.siteProfiles.edges[0]?.node;
  const attributes = profile != null ? currentSiteProfile?.attributes : [];
  const subscribedToNotifications = currentSiteProfile?.subscribedToNotifications;
  const subscribedToEmails = currentSiteProfile?.subscribedToEmails;
  const apartment = currentSiteProfile?.apartment?.apartment;
  const notificationPreferences = profile?.bookingNotificationPreferences;
  const approvalNotificationsPreferences = profile?.approvalNotificationsPreferences;
  const flexTenantUuids = profile?.flexMemberships.map((fm) => fm.flexTenant.uuid);

  const initialValues: FormValues = {
    firstName: profile?.firstName ?? "",
    lastName: profile?.lastName ?? "",
    email: profile?.email ?? "",
    mobileNumber: profile?.mobileNumber ?? "",
    status: profile?.status,
    userType: type ?? "",
    company: profile?.companyV2?.uuid ?? "",
    groups: groups.map((group) => group.uuid),
    attributes: attributes?.map((attr) => attr.uuid),
    subscribedToNotifications,
    subscribedToEmails,
    apartment: apartment?.uuid,
    notificationPreferences: {
      endUserConfirmationEmail: notificationPreferences?.endUser.confirmationEmail ?? true,
      endUserUpdateEmail: notificationPreferences?.endUser.updateEmail ?? true,
      endUserCancellationEmail: notificationPreferences?.endUser.cancellationEmail ?? true,
      endUserAwaitingApprovalEmail: notificationPreferences?.endUser.awaitingApprovalEmail ?? true,
      endUserDeclinedBookingEmail: notificationPreferences?.endUser.declinedBookingEmail ?? true,
      endUserChargedAdjustmentEmail: notificationPreferences?.endUser.chargeAdjustmentEmail ?? true,
      endUserReminderEmail: notificationPreferences?.endUser.reminderEmail ?? true,
      adminConfirmationEmail: notificationPreferences?.admin.confirmationEmail ?? true,
      adminUpdateEmail: notificationPreferences?.admin.updateEmail ?? true,
      adminCancellationEmail: notificationPreferences?.admin.cancellationEmail ?? true,
      adminApprovalRequestEmail: notificationPreferences?.admin.approvalRequestEmail ?? true,
      adminDeclinedBookingEmail: notificationPreferences?.admin.declinedBookingEmail ?? true,
    },
    approvalNotificationsPreferences: {
      awaitingApproval: approvalNotificationsPreferences?.awaitingApproval ?? true,
    },
    flexTenantUuids,
  };

  const handleSubmit = async (values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
    try {
      setSubmitting(true);
      setActionState("onSave", "loading");
      await onSubmit(values);
      setFilters?.({});
      onClose();
      toast.positive(
        adminView
          ? t("settings.editProfile.changesHaveBeenSaved", { firstName: values.firstName, lastName: values.lastName })
          : t("settings.editProfile.changesHaveBeenSavedEndUser"),
      );
    } catch (e: unknown) {
      showError(e);
    }
    setSubmitting(false);
    setActionState("onSave", "normal");
  };

  return (
    <>
      <Modal.Dialog
        title={t("settings.editProfile.editProfile")}
        show={showModal}
        sideModal={true}
        onHide={onCloseModal}
        hideOnEsc={true}
        hideOnClick={true}
        containerClassName="edit-profile-modal"
        size="md"
      >
        <Modal.Header closeButton noBorder={true} onClose={onCloseModal} />
        {profile != null && error == null ? (
          <Formik
            initialValues={initialValues}
            innerRef={formInnerRef}
            enableReinitialize={true}
            validationSchema={getValidationSchema(t)}
            onSubmit={handleSubmit}
          >
            {({ setFieldValue, values }) => (
              <>
                <Modal.Body noPadding>
                  <UserCardHeader
                    firstName={profile.firstName ?? ""}
                    lastName={profile.lastName ?? ""}
                    company={profile.companyV2?.name ?? ""}
                    created={profile.created}
                    avatar={profile.avatar}
                  />
                  <div className="px-6">
                    <Tabs
                      items={enabledTabs}
                      selected={selectedTab}
                      onSelect={(key) => {
                        setSelectedTab(key as ProfileTabKey);
                      }}
                    />
                  </div>
                  <hr className="mt-0" />
                  <div className="content">{currentTab}</div>
                </Modal.Body>
                <AvatarUploadWidget setFieldValue={setFieldValue} value={values.avatar} />
                {actionsState.onSave !== "hidden" && (
                  <Modal.Footer>
                    <Button variant="ghost" className="mr-4" onClick={onCloseModal}>
                      {t("common.cancel")}
                    </Button>
                    <Button
                      disabled={actionsState.onSave === "loading"}
                      type="submit"
                      variant="primary"
                      onClick={handleSave}
                    >
                      {actionsState.onSave === "loading" && <ProgressCircle size="xs" />}
                      {t("settings.editProfile.saveChanges")}
                    </Button>
                  </Modal.Footer>
                )}
              </>
            )}
          </Formik>
        ) : (
          <>
            <Modal.Body>
              <div className="text-center pt-8">
                {loading ? (
                  <ProgressCircle size="lg" />
                ) : error != null ? (
                  tError(error)
                ) : (
                  t("common.unknownErrorPleaseTryAgain")
                )}
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="ghost" className="mr-4" onClick={onClose}>
                {t("common.cancel")}
              </Button>
              <Button disabled type="submit" variant="primary">
                {t("settings.editProfile.saveChanges")}
              </Button>
            </Modal.Footer>
          </>
        )}
      </Modal.Dialog>

      <style jsx>{`
        .title {
          font-weight: 400;
          font-size: 18px;
          line-height: 28px;
        }
        .content {
          padding: 0 ${spacers.s6} 0;
        }
        hr {
          border: none;
          border-top: 1px solid ${colors.border};
          margin-bottom: 16px;
        }
      `}</style>
    </>
  );
};
