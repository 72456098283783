import React from "react";

import { useTheme } from "@equiem/react-admin-ui";

import { SettingsHead } from "../../components/SettingsHead";
import { WidgetProvider } from "../../contexts/WidgetContext";
import { withContexts } from "../../contexts/withContexts";

import { CompaniesInner } from "./components/CompaniesInner";
import { CompaniesFilterProvider } from "./contexts/CompaniesFilterContext";

const CompaniesBase: React.FC = () => {
  const { colors } = useTheme();

  return (
    <CompaniesFilterProvider>
      <WidgetProvider>
        <div className="page-container">
          <SettingsHead />
          <CompaniesInner />
        </div>
        <style jsx>{`
          .page-container {
            height: 100%;
            width: 100%;
            background: ${colors.white};
          }
        `}</style>
      </WidgetProvider>
    </CompaniesFilterProvider>
  );
};

export const Companies = withContexts(CompaniesBase);
