import React, { createContext, useEffect, useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import type { Stripe, StripeElementLocale } from "@stripe/stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { mainFont } from "@equiem/react-admin-ui";
import { useTranslation } from "@equiem/localisation-eq1";

import { usePaymentGatewayQuery } from "../generated/gateway-client";

export interface StripeContext {
  stripe: Promise<Stripe | null> | null;
}

export const StripeContext = createContext<StripeContext>({
  stripe: null,
});

let stripeCache: Promise<Stripe | null> | null = null;

export const StripeProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const { i18n } = useTranslation();
  const [stripe, setStripe] = useState(stripeCache);

  const { data } = usePaymentGatewayQuery({ skip: stripeCache != null });
  useEffect(() => {
    if (stripeCache == null && data?.paymentGatewayPublicKey != null) {
      const loadingPromise = loadStripe(data.paymentGatewayPublicKey);
      stripeCache = loadingPromise;
      setStripe(loadingPromise);
    }
  }, [data?.paymentGatewayPublicKey]);

  return stripe == null ? null : (
    <Elements
      stripe={stripe}
      options={{
        locale: i18n.language as StripeElementLocale,
        fonts: [{ cssSrc: mainFont }],
      }}
    >
      {children}
    </Elements>
  );
};
