import { serialize } from "cookie";
import { APP_MODE_COOKIE_NAME } from "./cookies/constants";
import { CookieJar } from "./cookies/CookieJar";

export const makeModeCookie = (mode: "webng" | "mobile" | "default", hostname: string) => {
  if (mode === "default") {
    return serialize(APP_MODE_COOKIE_NAME, "", { expires: new Date(0) });
  } else {
    return serialize(APP_MODE_COOKIE_NAME, CookieJar.wrap(mode, hostname));
  }
};

export const clearModeCookie = () => {
  document.cookie = makeModeCookie("default", window.location.hostname);
};
