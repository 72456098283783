import type { ButtonHTMLAttributes } from "react";
import React, { forwardRef } from "react";

import { useTheme } from "../../contexts/Theme";
import type { BaseButtonVariants, BaseButtonSize, BaseButtonShape } from "./renderAdminButtonStyles";
import { renderAdminButtonStyles } from "./renderAdminButtonStyles";
import { AdminButtonDropdownToggle } from "./AdminButtonDropdownToggle";

export type { BaseButtonVariants, BaseButtonSize } from "./renderAdminButtonStyles";

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: BaseButtonVariants;
  size?: BaseButtonSize;
  inverted?: boolean;
  // @deprecated, use shape.
  round?: boolean;
  shape?: BaseButtonShape;
  dropdownToggle?: boolean;
  dropdownToggleColor?: string;
}

export const AdminButton = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      children,
      variant = "primary",
      size = "md",
      shape = "default",
      round,
      dropdownToggleColor,
      dropdownToggle = false,
      inverted = false,
      type,
      ...props
    },
    ref,
  ) => {
    const theme = useTheme(true);
    const { className: buttonClassName, styles } = renderAdminButtonStyles({
      element: "button",
      variant,
      shape: round === true && shape === "default" ? "round" : shape,
      size,
      inverted,
      theme,
    });

    return (
      <button ref={ref} className={`${className ?? ""} ${buttonClassName}`} type={type ?? "button"} {...props}>
        {children}
        {dropdownToggle && <AdminButtonDropdownToggle size={size} color={dropdownToggleColor} />}
        {styles}
      </button>
    );
  },
);

AdminButton.displayName = "Button";
