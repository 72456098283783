import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import type { ReactNode, RefCallback } from "react";

import { useTheme } from "../../contexts/Theme";
import { IframeResizerContext } from "../../contexts/IframePageInfoContext";
import { Transition, TransitionGroup } from "react-transition-group";
import { BodyPortal } from "../BodyPortal/BodyPortal";
import { ToastContext } from "./ToastContext";
import type { ToastOptions } from "./ToastContext";
import type { ToastModel } from "./ToastModel";
import { useFloatingInIframe } from "../../hooks/useFloatingInIframe";
import type { Ref } from "./AlertToast";
import { AlertToast } from "./AlertToast";
import { TOAST_TIMEOUT, TOASTS_LIMIT, TOAST_ANIMATION_DURATION_MS } from "./Toast.constants";

export type ToastExitDirection = "top" | "bottom";

let lastId = 0;

export const ToastProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const { zIndexes } = useTheme();
  const [toasts, setToasts] = useState<ToastModel[]>([]);
  const { inIframe } = useContext(IframeResizerContext);
  const { floating, top, left, position } = useFloatingInIframe({
    placement: "bottom",
    fixedSize: true,
  });

  const exitDirectionRef = useRef<ToastExitDirection>("bottom");
  const internalRefs = useRef<Record<number, Ref | null>>({});

  useEffect(() => {
    internalRefs.current = { ...internalRefs.current };
  }, [toasts]);

  const refs = useMemo(
    () =>
      toasts.reduce<Record<number, RefCallback<Ref>>>(
        (prev, nextToast) => ({
          ...prev,
          [nextToast.id]: (el) => {
            internalRefs.current[nextToast.id] = el;
          },
        }),
        {},
      ),
    [toasts],
  );

  useEffect(() => {
    floating.update();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toasts]);

  const removeToast = useCallback(
    (id: number) => {
      exitDirectionRef.current = "bottom";
      setToasts(toasts.filter((toast) => toast.id !== id));
    },
    [toasts],
  );

  const showToast = useCallback(
    (type: ToastModel["type"], message: string | React.ReactNode, options?: ToastOptions) => {
      const id = lastId++;
      const remove = () => {
        internalRefs.current[id]?.remove();
      };

      // If a notification exists of the same name, remove it from the existing
      // toasts array.
      const filteredToasts = toasts.filter(
        (toast) => toast.name == null || options?.name == null || toast.name !== options.name,
      );

      const newToast: ToastModel = {
        id,
        message,
        type,
        opacity: 1,
        remove,
        ...options,
      };

      exitDirectionRef.current = filteredToasts.length === TOASTS_LIMIT ? "top" : "bottom";

      setToasts([newToast, ...filteredToasts.slice(0, TOASTS_LIMIT - 1)]);
      if (newToast.type !== "negative" && newToast.autoDismiss !== false) {
        setTimeout(() => {
          newToast.remove();
        }, TOAST_TIMEOUT);
      }

      return { remove };
    },
    [toasts, internalRefs],
  );

  const value = useRef({
    provided: true,
    neutral: showToast.bind(null, "neutral"),
    negative: showToast.bind(null, "negative"),
    positive: showToast.bind(null, "positive"),
  });

  useEffect(() => {
    value.current.neutral = showToast.bind(null, "neutral");
    value.current.negative = showToast.bind(null, "negative");
    value.current.positive = showToast.bind(null, "positive");
  }, [showToast]);

  return (
    <>
      <ToastContext.Provider value={value.current}>{children}</ToastContext.Provider>
      <BodyPortal>
        <div ref={floating.floating}>
          <TransitionGroup>
            {toasts.map((toast, index) => (
              <Transition
                key={toast.id}
                timeout={{ enter: 0, exit: TOAST_ANIMATION_DURATION_MS }}
                onExited={() => {
                  // Update the position of the the floating toast container element since its size will have changed.
                  setTimeout(() => {
                    floating.update();
                  });
                }}
              >
                {(status) => (
                  <AlertToast
                    key={toast.id}
                    index={index}
                    message={toast.message}
                    variant={toast.type}
                    icon={toast.icon}
                    size={toast.size}
                    primaryAction={toast.primaryAction}
                    secondaryAction={toast.secondaryAction}
                    remove={() => {
                      removeToast(toast.id);
                    }}
                    onClose={toast.showClose !== false ? () => removeToast(toast.id) : undefined}
                    ref={refs[toast.id]}
                    status={status}
                    exitDirection={index > 0 ? exitDirectionRef.current : "bottom"}
                  />
                )}
              </Transition>
            ))}
          </TransitionGroup>
        </div>
      </BodyPortal>
      <style jsx>{`
        div {
          position: ${position ?? "fixed"};
          pointer-events: none;
          width: 100%;
          bottom: ${inIframe ? "auto" : "0"};
          top: ${inIframe ? top : "auto"};
          left: ${left};
          display: flex;
          flex-direction: column;
          align-items: center;
          z-index: ${zIndexes.toast};
        }
      `}</style>
    </>
  );
};
