import type { RefObject } from "react";
import { createContext } from "react";

export interface FocusTrapContext<T extends HTMLElement = HTMLElement> {
  ref: RefObject<T | null>;
}

export const FocusTrapContext = createContext<FocusTrapContext>({
  ref: { current: null },
});
