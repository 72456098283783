import React from "react";
import { useTheme } from "../../contexts";
import { Text } from "../Text/Text";
import { RiEmotionUnhappyLine } from "react-icons/ri";
import { useTranslation } from "@equiem/localisation-eq1";

interface Props {
  message?: string;
}

export const EmptyState: React.FC<Props> = ({ message }) => {
  const { t } = useTranslation();
  const { colors } = useTheme();

  return (
    <div className="empty-table">
      <div className="icon-container">
        <RiEmotionUnhappyLine size={24} color={colors.grayscale[40]} />
      </div>
      <Text variant="text" size="small">
        {message ?? t("equiem-one-ui.common.noResultsState")}
      </Text>
      <style jsx>
        {`
          .empty-table {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            color: ${colors.grayscale[60]};
            padding: 32px 0;
            text-align: center;
          }

          .empty-table .icon-container {
            padding: 16px;
            background: ${colors.grayscale[5]};
            border-radius: 50%;
          }
        `}
      </style>
    </div>
  );
};
