import React from "react";

import { CategoryModal } from "../../../components/CategoryModal";
import { withContexts } from "../contexts/withContexts";
import { SettingsTabs } from "../SettingsTabs";

import { CategoriesFilter } from "./components/CategoriesFilter";
import { CategoriesList } from "./components/CategoriesList";
import { CategoriesFilterProvider } from "./context/CategoriesFilterContext";

const CategoryComponent: React.FC = () => {
  return (
    <>
      <CategoryModal />
      <div className="container">
        <SettingsTabs>
          <CategoriesFilterProvider>
            <CategoriesFilter />
            <CategoriesList />
          </CategoriesFilterProvider>
        </SettingsTabs>
      </div>
    </>
  );
};

export const Category = withContexts(CategoryComponent);
