import React from "react";

import type { BorderCssProps } from "../useInputBorderCss";
import type { ComponentProps } from "react";
import { useCapacityIconBackground } from "./useCapacityIconBackground";
import { FormInput } from "../FormInput/FormInput";

// eslint-disable-next-line @typescript-eslint/no-type-alias
type Props = ComponentProps<"input"> & {
  variant?: "md" | "lg";
  rounded?: BorderCssProps["rounded"];
};

export const FormCapacitySelect = React.forwardRef<HTMLInputElement, Props>(({ children: _, ...props }, ref) => {
  return (
    <FormInput
      {...props}
      type="number"
      placeholder="Capacity"
      min="1"
      max="999"
      ref={ref}
      dropdownIcon={useCapacityIconBackground}
    />
  );
});

FormCapacitySelect.displayName = "FormCapacityInput";
