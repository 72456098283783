import type { HTMLAttributes } from "react";
import React from "react";

import { Tag, Text, useTheme } from "@equiem/react-admin-ui";

interface Props extends HTMLAttributes<HTMLDivElement> {
  value: number;
  title: string;
  isActive: boolean;
}

export const FilterTab: React.FC<Props> = ({ value, title, isActive, ...props }) => {
  const { colors } = useTheme();

  return (
    <>
      <div {...props} className={!isActive ? "tab" : "tab tab-active"}>
        <Text variant="navigation">{title}</Text>
        <Tag type="counter" value={value} variant={!isActive ? "default" : "primary"} />
      </div>
      <style jsx>{`
        .tab {
          display: flex;
          align-items: center;
          gap: 4px;
          padding: 8px;
          color: ${colors.grayscale[60]};
          cursor: pointer;
          white-space: nowrap;
        }
        .tab-active {
          background-color: ${colors.blue.transparent[10]};
          border-radius: 4px;
          color: ${colors.grayscale[100]};
        }
        .tab:hover {
          border-radius: 4px;
          background: ${colors.transparent.black[5]};
        }
      `}</style>
    </>
  );
};
