import React, { useMemo, useState } from "react";

import { Form, useDebounced, useTheme } from "@equiem/react-admin-ui";

interface ChangeEvent {
  target: Pick<HTMLInputElement, "name"> & Partial<EventTarget & HTMLInputElement>;
}

type Item = Form.ComboBox.Item;

interface Props {
  name: string;
  items: Item[];
  onSelect?: (item: Item) => void;
  onChange?: (event: ChangeEvent) => void;
  debounce?: number;
  value?: string;
}

export const CreatableSelect: React.FC<Props> = ({ name, items, onSelect, onChange, debounce = 200, value }) => {
  const { colors } = useTheme();
  const [searchTerm, setSearchTerm] = useState("");
  const debouncedSearchTerm = useDebounced(searchTerm.trim(), debounce);

  const filteredItems = useMemo(() => {
    if (debouncedSearchTerm === "") {
      return items;
    }

    const filtered = items.filter((item) => item.value.toLowerCase().includes(debouncedSearchTerm.toLowerCase()));

    if (items.some((item) => item.value.toLowerCase() === debouncedSearchTerm.toLowerCase())) {
      return filtered;
    }

    filtered.push({ el: <>{`Create "${debouncedSearchTerm}"`}</>, value: debouncedSearchTerm });
    return filtered;
  }, [debouncedSearchTerm, items]);

  const updateValue = (newValue: string) =>
    onChange?.({
      target: { name, value: newValue },
    });

  return (
    <>
      <Form.ComboBox.ComboBox
        menuWidth="matchReference"
        items={filteredItems}
        onSelect={(item: Item) => {
          setSearchTerm("");
          onSelect?.(item);
          updateValue(item.value);
        }}
        onChange={({ target }) => {
          setSearchTerm(target.value);
          updateValue(target.value);
        }}
      >
        {({ inputProps, floating }) => (
          <div>
            <Form.Input {...inputProps} name={name} autoComplete="off" ref={floating?.reference} value={value} />
            <Form.ComboBox.Menu maxHeight="calculated">
              <Form.ComboBox.Options highlightColor={colors.grayscale_5} noBorder />
            </Form.ComboBox.Menu>
          </div>
        )}
      </Form.ComboBox.ComboBox>
    </>
  );
};
