import React, { useEffect, useMemo } from "react";
import { DateTime } from "luxon";

import { stringNotEmpty, useSaferFormikContext, useSiteContext } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import { Form, useIsMobileWidth } from "@equiem/react-admin-ui";

import {
  useVisitorManagementSiteSettingsQuery,
  VisitorAppointmentType,
  VisitorRole,
} from "../../../../generated/visitors-client";
import { useAppointmentFormMetadata } from "../../../appointment/hooks/useAppointmentFormMetadata";
import type { FormValues } from "../../../appointment/types";
import { getAppointmentIntervals } from "../../../appointment/utils/appointmentIntervals";

interface Props {
  isActive?: boolean;
  receptionUuid?: string;
}

export const WalkInStep3: React.FC<Props> = ({ isActive, receptionUuid }) => {
  const { t, i18n } = useTranslation();
  const { uuid: siteUuid } = useSiteContext();
  const isMobile = useIsMobileWidth();

  const { handleChange, setFieldValue, values } = useSaferFormikContext<FormValues>();
  const { receptions, receptionsLoading } = useAppointmentFormMetadata({
    as: VisitorRole.Receptionist,
    companyUuid: values.host.company?.uuid,
    receptionUuid,
    skip: isActive !== true,
  });

  const { data: vmSiteSettings, loading: vmSiteSettingsLoading } = useVisitorManagementSiteSettingsQuery({
    variables: { siteUuid: siteUuid },
    fetchPolicy: "network-only",
  });

  const isVisitorTypeFeatureEnabled = vmSiteSettings?.visitorManagementSiteSettings.isVisitorTypesEnabled === true;

  const durationDates = useMemo(() => {
    const [startTime, date] =
      values.startTime !== 0 && stringNotEmpty(values.date)
        ? [values.startTime, values.date]
        : [DateTime.local().toSeconds(), DateTime.local().toISODate()];

    return Array.from(getAppointmentIntervals(startTime, date, i18n.language).values());
  }, [values.startTime, values.date, i18n.language]);

  const visitorTypesWalkin = vmSiteSettings?.visitorManagementSiteSettings.visitorTypes?.filter((item) =>
    item.appointmentTypes?.includes(VisitorAppointmentType.WalkIn),
  );

  useEffect(() => {
    if (isVisitorTypeFeatureEnabled) {
      const defaultValue = vmSiteSettings.visitorManagementSiteSettings.visitorTypes?.find((item) => item.isDefault);
      setFieldValue("visitorTypeUuid", values.visitorTypeUuid ?? (defaultValue?.uuid as string)).catch(console.error);
    }
  }, [
    values.visitorTypeUuid,
    isVisitorTypeFeatureEnabled,
    vmSiteSettings?.visitorManagementSiteSettings.visitorTypes,
    setFieldValue,
  ]);

  return (
    <div className={`step-container pr-0 pt-5 pb-5 mt-5 d-flex flex-column ${isActive === true ? "step-shadow" : ""}`}>
      <div className="px-7 mb-6 pt-2 step-head">
        <p className="step-title mb-1 mt-0">{t("visitors.walkIn.step", { step: 3 })}</p>
        <h2>{t("visitors.walkIn.additionalDetails")}</h2>
      </div>
      {isActive === true && isVisitorTypeFeatureEnabled && (
        <div className={`${isMobile ? "px-7" : "pl-7"} form-container`}>
          <Form.Group label={t("visitors.common.visitorsType")} required>
            <Form.Select
              name="visitorTypeUuid"
              onChange={handleChange}
              value={values.visitorTypeUuid as string}
              disabled={vmSiteSettingsLoading}
            >
              {vmSiteSettingsLoading ? (
                <option value="">{t("common.loading")}...</option>
              ) : (
                <>
                  {visitorTypesWalkin?.map((item) => (
                    <option key={item.uuid} value={item.uuid as string}>
                      {item.name}
                    </option>
                  ))}
                </>
              )}
            </Form.Select>
          </Form.Group>
        </div>
      )}
      {isActive === true && (
        <div className={`${isMobile ? "px-7" : "pl-7"} form-container`}>
          <Form.Group label={t("visitors.walkIn.visitDuration")} required>
            <Form.Select name="duration" value={values.duration} onChange={handleChange}>
              <option value="" disabled>
                {t("visitors.appointmentForm.durationSelect")}
              </option>
              {durationDates.map((date) => (
                <option key={`${date.label}_${date.value}`} value={date.value}>
                  {date.label}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        </div>
      )}
      {isActive === true && receptions.length > 0 && (
        <div className={`${isMobile ? "px-7" : "pl-7"} form-container`}>
          <Form.Group label={t("visitors.receptions.selectReception")} required>
            <Form.Select
              name="location"
              value={values.location}
              onChange={handleChange}
              disabled={receptions.length < 2}
            >
              {receptionsLoading ? (
                <option value="">{t("common.loading")}</option>
              ) : (
                <>
                  <option value="">{t("visitors.receptions.selectReception")}</option>
                  {receptions.length === 1 && <option value={receptions[0].uuid}>{receptions[0].name}</option>}
                  {receptions.length > 1 &&
                    receptions.map((rec) => (
                      <option key={rec.uuid} value={rec.uuid}>
                        {rec.name}
                      </option>
                    ))}
                </>
              )}
            </Form.Select>
          </Form.Group>
        </div>
      )}
      <style jsx>
        {`
          h2 {
            font-weight: 600;
          }
          .step-container {
            border: 1px solid #e6e6e6;
            border-radius: 8px;
          }
          .step-shadow {
            box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
          }
          .step-title {
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
            text-transform: uppercase;
            color: rgba(0, 0, 0, 0.6);
          }
          .form-container {
            max-width: 500px;
          }
        `}
      </style>
    </div>
  );
};
