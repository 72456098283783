import React from "react";
import { useTranslation } from "@equiem/localisation-eq1";

import { EmptyFiltersIcon } from "./EmptyFiltersIcon";

export const EmptyFilters: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="empty-filters text-center text-muted py-9">
        <EmptyFiltersIcon />
        <p className="mt-5">{t("segmentation.emptyFilters")}</p>
        <p>{t("segmentation.emptyFiltersDescription")}</p>
      </div>
      <style jsx>{`
        p {
          padding: 0;
          margin: 0;
        }
      `}</style>
    </>
  );
};
