import { Role } from "@equiem/lib";
import type { TFunction } from "@equiem/localisation-eq1";
import type { TabItem } from "@equiem/react-admin-ui/.";

export const editCompanyTabs = (t: TFunction, currentRole: Role, enableAccessGroups: boolean): TabItem[] => {
  const shouldAddGroupsForPropertyManager = currentRole === Role.PropertyManager && enableAccessGroups;
  const shouldAddGroupsForWorkplaceManager = currentRole === Role.WorkplaceManager && enableAccessGroups;

  const defaults = [
    {
      title: t("settings.notifications.general"),
      key: "general",
    },
    {
      title: t("settings.editProfile.integrations"),
      key: "integrations",
    },
    {
      title: t("common.permissions"),
      key: "permissions",
    },
  ];
  if (shouldAddGroupsForPropertyManager != null || shouldAddGroupsForWorkplaceManager != null) {
    defaults.push({
      title: t("common.accessControlGroups"),
      key: "groups",
    });
  }
  if (currentRole === Role.WorkplaceManager) {
    defaults.push({
      title: t("common.billing"),
      key: "billing",
    });
  }
  return defaults;
};
