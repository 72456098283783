import React from "react";

import { Tooltip } from "../Tooltip/Tooltip";
import { ProgressCircle } from "../ProgressCircle/ProgressCircle";
import { RiInformationLine } from "../../admin-icons";
import { useTheme } from "../../contexts";
import type { MetricValue } from "./types";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  primaryValue: MetricValue;
  secondaryValue: MetricValue;
  loading?: boolean;
}

export const DoubleMetric: React.FC<Props> = ({ primaryValue, secondaryValue, loading = false, ...rest }) => {
  const { colors } = useTheme();

  const displayValue = ({ value, decimalPlaces, unit }: MetricValue, valueDecimalPlaces = 0) => {
    const formattedValue = typeof value === "string" ? value : value.toFixed(decimalPlaces ?? valueDecimalPlaces);
    return formattedValue + (unit === "Percentage" ? "%" : "");
  };

  const Value = ({ value }: { value: MetricValue }) => {
    return (
      <div className="row d-flex flex-column justify-content-between">
        <div className="d-flex align-items-baseline justify-content-between">
          <span className="title">{value.title}</span>
          {value.tooltip != null && (
            <Tooltip placement="bottom-start" title={value.tooltip}>
              <div className="ms-1" style={{ display: "inline-block" }}>
                <RiInformationLine
                  style={{
                    color: colors.medium,
                    cursor: "pointer",
                  }}
                />
              </div>
            </Tooltip>
          )}
        </div>
        {loading && <ProgressCircle size="sm" />}
        {!loading && (
          <>
            <h2>{displayValue(value)}</h2>
          </>
        )}
        <style jsx>{`
          .row {
            padding: 1rem 0;
          }

          .row:first-child {
            border-bottom: 1px solid ${colors.border};
            padding-top: 0;
          }

          .row:last-child {
            padding-bottom: 0;
          }

          .title {
            font-weight: 600;
            font-size: 0.75rem;
            line-height: 1rem;
            text-transform: uppercase;
            color: rgba(0, 0, 0, 0.6);
          }
          h2 {
            font-weight: 700;
            font-size: 1.5rem;
            line-height: 1.5rem;
            margin-bottom: 0.5rem;
          }
        `}</style>
      </div>
    );
  };

  return (
    <div className="metric p-5" {...rest}>
      <Value value={primaryValue} />
      <Value value={secondaryValue} />
      <style jsx>
        {`
          .metric {
            display: grid;
            grid-template-rows: 1fr 1fr;
            border: 1px solid #e6e6e6;
            border-radius: 4px;
            min-height: 232px;
            height: 100%;
          }
          .title {
            font-weight: 600;
            font-size: 0.75rem;
            line-height: 1rem;
            text-transform: uppercase;
            color: rgba(0, 0, 0, 0.6);
          }
          .h2 {
            font-weight: 700;
            font-size: 1.5rem;
            line-height: 1.5rem;
            margin-bottom: 0.5rem;
            text-align: right;
          }
        `}
      </style>
    </div>
  );
};
