import { ApolloClient, HttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { typePolicies } from "./typePolicies";
import { defaultOptions } from "./defaultOptions";
import { authenticate, dataIdFromObject, errorLink, headers, withAuth } from "./gatewayUtils";

const authenticatedGateways: Record<string, ApolloClient<object> | undefined> = {};
let cachedLocale: string;

export const getAuthenticatedGateway = (endpoint: string, getTokenFromPostMessage: boolean, locale = "en") => {
  cachedLocale = locale;
  const client =
    authenticatedGateways[endpoint] ??
    new ApolloClient({
      name: "equiem-one-ui",
      cache: new InMemoryCache({
        dataIdFromObject,
        typePolicies,
      }),
      link: errorLink
        .concat(
          setContext((_, ctx) => ({
            headers: {
              ...(ctx.headers as Record<string, string>),
              "X-Equiem-Client-Locale": cachedLocale,
            },
          })),
        )
        .concat(
          new HttpLink({
            uri: endpoint,
            fetch: withAuth(fetch, getTokenFromPostMessage),
            headers,
          }),
        ),
      defaultOptions,
    });

  if (authenticatedGateways[endpoint] == null) {
    authenticatedGateways[endpoint] = client;
  }

  return { authenticate, client };
};
