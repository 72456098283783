import type { FC } from "react";
import React, { useMemo, useState } from "react";
import { EntitySearch, Modal } from "@equiem/react-admin-ui";
import { useSearchCompaniesQuery } from "../generated/gateway-client";
import { useTranslation } from "@equiem/localisation-eq1";
import type { CompanySearchCompany } from "./CreateCompanyForm";
import { CreateCompanyForm } from "./CreateCompanyForm";

interface Props {
  onSelect?: (item: CompanySearchCompany) => void;
  disabled?: boolean;
  placeholder?: string;
  value?: CompanySearchCompany;
  allowCreate?: boolean;
}

export const CompanySearchHOC: FC<Props> = ({ onSelect, disabled, placeholder, value, allowCreate = false }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [showForm, setShowForm] = useState(false);
  const { t } = useTranslation();
  const { data, loading } = useSearchCompaniesQuery({
    variables: {
      search: searchTerm,
    },
    skip: searchTerm === "",
  });
  const companyData = data?.companiesV2.edges;

  const searchCompanies = useMemo(
    () =>
      (companyData ?? [])
        .flatMap((edge) => (edge.node == null ? [] : [edge.node]), [])
        .map((company) => ({ value: "", entity: company })),
    [companyData],
  );

  return (
    <>
      <EntitySearch.EntitySearch
        disabled={disabled}
        items={searchCompanies}
        loading={loading}
        onSearch={setSearchTerm}
        onSelect={({ entity }) => {
          if (onSelect != null) {
            onSelect(entity);
          }
        }}
        onAdd={
          allowCreate
            ? () => {
                setShowForm(true);
              }
            : undefined
        }
        buttonText={t("common.add")}
        addButtonText={t("settings.createCompany.createNew")}
        placeholder={placeholder}
        variant="md"
        autoComplete="off"
        defaultValue={
          value != null
            ? {
                value: value.uuid,
                entity: value,
              }
            : undefined
        }
      />
      <Modal.Dialog
        title={t("settings.createCompany.newCompany")}
        show={showForm}
        onHide={() => setShowForm(false)}
        size="lg"
        centered
        withFooter
        hideOnClick={false}
        scrollable={true}
      >
        <Modal.Header noBorder={true} />
        <CreateCompanyForm
          name={searchTerm}
          onSubmitComplete={(company?: CompanySearchCompany) => {
            if (company != null && onSelect != null) {
              onSelect(company);
            }
            setShowForm(false);
          }}
        />
      </Modal.Dialog>
    </>
  );
};
