import React, { createContext, useState } from "react";

export type GradientStyle = "eq1" | "essentials";

interface GradientContextType {
  showGradient: boolean;
  gradientStyle: "eq1" | "essentials";
  setPageGradient: (showGradient: boolean, gradientStyle?: GradientStyle) => void;
}

const defaultShowGradient = false;
const defaultGradientStyle: GradientStyle = "eq1";

export const GradientContext = createContext<GradientContextType>({
  showGradient: defaultShowGradient,
  gradientStyle: defaultGradientStyle,
  setPageGradient: () => undefined,
});

export const GradientProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const [showGradient, setShowGradient] = useState(defaultShowGradient);
  const [gradientStyle, setGradientStyle] = useState<GradientStyle>(defaultGradientStyle);

  const setPageGradient = (pageShowGradient: boolean, pageGradientStyle: GradientStyle = "eq1") => {
    setShowGradient(pageShowGradient);
    setGradientStyle(pageGradientStyle);
  };

  return (
    <GradientContext.Provider value={{ showGradient, gradientStyle, setPageGradient }}>
      {children}
    </GradientContext.Provider>
  );
};
