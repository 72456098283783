// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import React from "react";
import { useStickyElement } from "./useStickyElement";

export const StickyElement = <T extends HTMLElement>({
  children,
  stack = false,
}: {
  children?: (args: ReturnType<typeof useStickyElement<T>>) => React.ReactElement;
  stack?: boolean;
}) => {
  const args = useStickyElement<T>({ stack });

  return children?.(args) ?? null;
};
