import React from "react";
import { CompleteStripeConnect } from "./components/CompleteStripeConnect";
import { useTranslation } from "@equiem/localisation-eq1";
import { SettingsTab } from "./components/SettingsTab";

export const PaymentSetupPage = () => {
  const { t } = useTranslation();

  return (
    <SettingsTab title={t("bookings.settings.completingStripeConnect")}>
      <CompleteStripeConnect />
    </SettingsTab>
  );
};
