import React from "react";
import type { HttpLink, ApolloClient } from "@apollo/client";
import { Modal } from "@equiem/react-admin-ui";
import { useTranslation } from "@equiem/localisation-eq1";

import type { AudienceProps } from "./AudienceWidget";
import { AudienceWidget } from "./AudienceWidget";

interface P extends AudienceProps {
  modal: {
    showModal: boolean;
    onHide: () => void;
  };
  connection: {
    link?: HttpLink;
    client?: ApolloClient<object>;
    gatewayEndpoint?: string;
  };
}

export const AudienceModal: React.FC<P> = (props) => {
  const { t } = useTranslation();

  const { modal, ...segmentationProps } = props;
  const { client, link } = props.connection;

  if (client == null && link == null) {
    return null;
  }

  return (
    <Modal.Dialog
      title={t("common.audience")}
      show={modal.showModal}
      onHide={modal.onHide}
      hideOnEsc={true}
      size="lg"
      className="segmentation-modal"
    >
      <Modal.Header closeButton={true} noBorder={true} />
      <Modal.Body>
        <AudienceWidget {...segmentationProps} hideModal={modal.onHide} />
      </Modal.Body>
    </Modal.Dialog>
  );
};
