import { Role } from "@equiem/lib";
import type { Dispatch, SetStateAction } from "react";
import React, { createContext, useEffect, useState } from "react";

export interface ModalContentContext {
  modalShown: boolean;
  closeModal: () => void;
  currentRole: Role;
  contentSaved: boolean;
  setContentSaved: Dispatch<SetStateAction<boolean>>;
  modalTitle: string;
  setModalTitle: Dispatch<SetStateAction<string>>;
  modalBodyTitle: string;
  setModalBodyTitle: Dispatch<SetStateAction<string>>;
  contentUrl: string | null;
  setContentUrl: Dispatch<SetStateAction<string | null>>;
  showSiteLink: boolean;
  setShowSiteLink: Dispatch<SetStateAction<boolean>>;
  isDraft: boolean;
  setIsDraft: Dispatch<SetStateAction<boolean>>;
}

export const ModalContent = createContext<ModalContentContext>({
  modalShown: false,
  closeModal: () => {
    throw new Error("No implemented");
  },
  currentRole: Role.Unknown,
  contentSaved: false,
  setContentSaved: () => {
    throw new Error("No implementation");
  },
  modalTitle: "",
  setModalTitle: () => {
    throw new Error("No implemented");
  },
  modalBodyTitle: "",
  setModalBodyTitle: () => {
    throw new Error("No implemented");
  },
  contentUrl: "",
  setContentUrl: () => {
    throw new Error("No implemented");
  },
  showSiteLink: false,
  setShowSiteLink: () => {
    throw new Error("No implemented");
  },
  isDraft: false,
  setIsDraft: () => {
    throw new Error("No implemented");
  },
});

interface P {
  currentRole: Role;
  children: React.ReactNode;
  title?: string;
  bodyTitle?: string;
  showModal: boolean;
  onHideCb?: () => void;
}
export const ModalContentProvider: React.FC<P> = ({ currentRole, children, title, bodyTitle, showModal, onHideCb }) => {
  const [modalTitle, setModalTitle] = useState<string>(title ?? "");
  const [modalBodyTitle, setModalBodyTitle] = useState<string>(bodyTitle ?? "");
  const [modalShown, setModalShown] = useState<boolean>(showModal);
  const [contentSaved, setContentSaved] = useState(false);
  const [contentUrl, setContentUrl] = useState<string | null>(null);
  const [showSiteLink, setShowSiteLink] = useState(true);
  const [isDraft, setIsDraft] = useState(true);

  useEffect(() => {
    setModalShown(showModal);
  }, [showModal]);
  useEffect(() => {
    setModalTitle(title ?? "");
  }, [title]);
  useEffect(() => {
    setModalBodyTitle(bodyTitle ?? "");
  }, [bodyTitle]);

  const closeModal = () => {
    setModalShown(false);
    setContentSaved(false);
    setModalBodyTitle("");
    setModalTitle("");
    onHideCb?.();
  };

  return (
    <ModalContent.Provider
      value={{
        modalShown,
        closeModal,
        currentRole,
        contentSaved,
        setContentSaved,
        modalTitle,
        setModalTitle,
        modalBodyTitle,
        setModalBodyTitle,
        contentUrl,
        setContentUrl,
        showSiteLink,
        setShowSiteLink,
        isDraft,
        setIsDraft,
      }}
    >
      {children}
    </ModalContent.Provider>
  );
};
