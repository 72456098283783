import { useTranslation } from "@equiem/localisation-eq1";
import { Toggler, Form as EqForm, Tooltip } from "@equiem/react-admin-ui";
import { Field } from "formik";
import type { FC } from "react";
import React, { useMemo } from "react";
import { AudienceField } from "./AudienceField";
import type { PortfolioSiteFragmentFragment } from "../../generated/gateway-client";
import {
  PublicationAuthorType,
  type CmsSiteArticleInput,
  useAuthorUuidToEmailQuery,
} from "../../generated/gateway-client";
import { RiFocus2Line, RiTeamLine, RiUserFollowLine } from "@equiem/react-admin-ui/icons";
import { UserSelectField } from "../shared/UserSelectField";

interface Props {
  siteUuid: string;
  site?: PortfolioSiteFragmentFragment;
  formValue?: CmsSiteArticleInput;
  disabled?: boolean;
}

const AudienceIcon = () => {
  const { t } = useTranslation();

  return (
    <Tooltip title={t("contentManagement.articles.form.audienceTooltip")} placement="right">
      <span>
        <RiFocus2Line className="ml-2" size={14} />
      </span>
    </Tooltip>
  );
};

const AuthorIcon: FC<{ siteDefault?: boolean; uuid: string; gatewayEndpoint?: string | null }> = ({
  uuid,
  gatewayEndpoint,
  siteDefault = true,
}) => {
  const { data } = useAuthorUuidToEmailQuery({
    variables: {
      uuid,
    },
    context: {
      uri: gatewayEndpoint,
    },
  });

  const { t } = useTranslation();

  if (siteDefault) {
    return (
      <Tooltip
        title={t("contentManagement.articles.form.defaultAuthorTooltip", { email: data?.profile?.email ?? "" })}
        placement="right"
      >
        <span>
          <RiUserFollowLine className="ml-2" size={14} />
        </span>
      </Tooltip>
    );
  }

  return (
    <Tooltip
      title={t("contentManagement.articles.form.customAuthorTooltip", { email: data?.profile?.email ?? "" })}
      placement="right"
    >
      <span>
        <RiTeamLine className="ml-2" size={14} />
      </span>
    </Tooltip>
  );
};

export const SiteSettingToggle: FC<Props> = ({ site, siteUuid, formValue, disabled }) => {
  const { t } = useTranslation();

  const hasAudience = useMemo(() => formValue?.audience != null, [formValue?.audience]);

  const header = (
    <>
      {site?.destination.name}
      {hasAudience && <AudienceIcon />}
      <AuthorIcon
        siteDefault={formValue?.publicationAuthorType !== PublicationAuthorType.CustomAuthor}
        uuid={formValue?.publicationAuthor?.uuid ?? site?.destination.defaultIrisAuthor?.uuid ?? siteUuid}
        gatewayEndpoint={site?.destination.gatewayEndpoint}
      />
    </>
  );

  return (
    <Toggler heading={header} className="mb-2" disabled={disabled} chevronButtonPosition="right">
      <EqForm.Group label={t("contentManagement.articles.form.audience")}>
        <Field
          name={`sites[${siteUuid}].audience`}
          gatewayEndpoint={site?.destination.gatewayEndpoint}
          as={AudienceField}
          siteUuid={siteUuid}
        />
      </EqForm.Group>
      <EqForm.Group
        label={t("contentManagement.articles.form.author")}
        showTooltip={true}
        tooltipText={t("contentManagement.articles.form.authorTooltip")}
      >
        <div className="d-flex flex-row">
          <Field
            className="w-50 mr-2"
            component={EqForm.HeroRadioSelect}
            id={PublicationAuthorType.SiteDefaultAuthor}
            name={`sites[${siteUuid}].publicationAuthorType`}
            icon={RiUserFollowLine}
            title={t("contentManagement.articles.form.authorDefaultTitle")}
            description={t("contentManagement.articles.form.authorDefaultSubtext")}
            default
          ></Field>
          <Field
            className="w-50 ml-2"
            component={EqForm.HeroRadioSelect}
            id={PublicationAuthorType.CustomAuthor}
            name={`sites[${siteUuid}].publicationAuthorType`}
            icon={RiTeamLine}
            title={t("contentManagement.articles.form.authorCustomTitle")}
            description={t("contentManagement.articles.form.authorCustomSubtext")}
          ></Field>
        </div>
      </EqForm.Group>
      {formValue?.publicationAuthorType === PublicationAuthorType.CustomAuthor && (
        <EqForm.Group
          label={t("contentManagement.articles.form.authorCustom")}
          required
          showTooltip={true}
          tooltipText={t("contentManagement.articles.form.authorCustomTooltip")}
        >
          <Field
            name={`sites[${siteUuid}].publicationAuthor`}
            as={UserSelectField}
            placeholder={t("contentManagement.articles.form.authorCustomPlaceholder")}
            siteUuid={siteUuid}
          />
        </EqForm.Group>
      )}
    </Toggler>
  );
};
