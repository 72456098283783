import type { FormikHelpers } from "formik";
import { useRouter } from "next/router";

import { useShowError } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import { useToast } from "@equiem/react-admin-ui";

import type { VisitorAppointmentFragment, VisitorReception } from "../../../generated/visitors-client";
import {
  useCreateVisitorAppointmentMutation,
  useUpdateVisitorAppointmentMutation,
} from "../../../generated/visitors-client";
import { initialValues } from "../../appointment/initialValues";
import { mapFormToDTO } from "../../appointment/mappings";
import type { FormValues } from "../../appointment/types";

export const useAppointmentSubmitCallback = ({
  appointmentUuid,
  setPrintingAppointment,
  receptionUuid,
  isAllBuildingReceptions,
  settings,
  reception,
}: {
  appointmentUuid?: string;
  setPrintingAppointment: (appointment: VisitorAppointmentFragment) => void;
  isAllBuildingReceptions: boolean;
  receptionUuid?: string;
  settings?: { enableAutomaticPassPrinting: boolean };
  reception?: VisitorReception;
}) => {
  const toast = useToast();
  const showError = useShowError();
  const { t } = useTranslation();
  const [createVisitorAppointment] = useCreateVisitorAppointmentMutation();
  const [updateVisitorAppointment] = useUpdateVisitorAppointmentMutation();
  const router = useRouter();

  const callback = async (values: FormValues, { setSubmitting, resetForm }: FormikHelpers<FormValues>) => {
    try {
      const input = mapFormToDTO(values);

      if (typeof appointmentUuid === "string") {
        const result = await updateVisitorAppointment({
          variables: { input, uuid: appointmentUuid },
        });
        if (result.data?.updateVisitorAppointment != null) {
          const updateUrl = isAllBuildingReceptions
            ? `/visitor-management/building-receptions/pre_booked?date=${values.date}`
            : `/visitor-management/receptions/${receptionUuid}/pre_booked?date=${values.date}`;

          toast.positive(t("visitors.reception.appointmentUpdated"));
          void router.replace(updateUrl).then(() => {
            resetForm({ values: initialValues });
          });
          return;
        } else {
          throw new Error(t("common.somethingWrong"));
        }
      } else {
        const result = await createVisitorAppointment({
          variables: {
            input: mapFormToDTO({ ...values, isMadeByReceptionist: true }),
          },
        });

        if (result.data?.createVisitorAppointment != null && result.errors == null) {
          if (values.isWalkIn) {
            const checkInUrl = isAllBuildingReceptions
              ? "/visitor-management/building-receptions/checked_in"
              : `/visitor-management/receptions/${receptionUuid}/checked_in`;

            if (values.visitors.length > 1) {
              toast.positive(
                t("visitors.reception.multipleWalkInSuccess", {
                  name: `${values.visitors[0].firstName} ${values.visitors[0].lastName}`,
                  count: values.visitors.length - 1,
                }),
              );
            } else {
              toast.positive(
                t("visitors.reception.singleWalkInSuccess", {
                  name: `${values.visitors[0].firstName} ${values.visitors[0].lastName}`,
                }),
              );
            }

            if (reception?.enableAutomaticPassPrinting != null || settings?.enableAutomaticPassPrinting === true) {
              /* istanbul ignore next */
              setPrintingAppointment(result.data.createVisitorAppointment);
            }
            void router.replace(checkInUrl).then(() => {
              resetForm({ values: initialValues });
            });
          } else {
            const preBookedUrl = isAllBuildingReceptions
              ? `/visitor-management/building-receptions/pre_booked?date=${values.date}`
              : `/visitor-management/receptions/${receptionUuid}/pre_booked?date=${values.date}`;

            toast.positive(t("visitors.reception.appointmentCreated"));
            void router.replace(preBookedUrl).then(() => {
              resetForm({ values: initialValues });
            });
          }
        } else {
          throw new Error(t("common.somethingWrong"));
        }
      }
    } catch (e: unknown) {
      showError(e);
    }

    setSubmitting(false);
  };

  return callback;
};
