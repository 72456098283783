import React from "react";
import type { NextPage } from "next";
import { BookingsAuthContextProvider } from "./BookingsAuthContext";
import type { DestinationTierLevel } from "@equiem/lib/generated/gateway-client";

export type Relation =
  | "member"
  | "canManage"
  | "canManageSiteCompany"
  | "canManageBuildingCompanyBookings"
  | "canObserveBuildingCompanyBookings"
  | "canManageCompanyBookingSettings";

export interface PageInput {
  conditions?: Array<{ relation: Relation; tierLevel?: DestinationTierLevel }>;
}

export const withAuthGuard = <P,>(Page: NextPage<P>, input: PageInput = {}): NextPage<P> => {
  const PageWithPageGuards: NextPage<P> = (props) => {
    return (
      <BookingsAuthContextProvider {...input}>
        <Page {...(props as P & React.JSX.IntrinsicAttributes)} />
      </BookingsAuthContextProvider>
    );
  };

  return PageWithPageGuards;
};
