import React, { useState } from "react";
import { useApolloClient } from "@apollo/client";
import { DateTime } from "luxon";

import { formatters, useTranslation } from "@equiem/localisation-eq1";
import { Dropdown, Form as EqForm, Text, useTheme } from "@equiem/react-admin-ui";
import { RiRepeat2Line } from "@equiem/react-admin-ui/icons";

import type {
  VisitorAppointmentQuery,
  VisitorAppointmentQueryVariables,
  VisitorAppointmentRecurringInfo,
  VisitorAppointmentWeekday,
} from "../../../generated/visitors-client";
import { VisitorAppointmentDocument, VisitorAppointmentRecurringType } from "../../../generated/visitors-client";

export const DropdownRecurringInfo: React.FC<{ appointmendUuid: string }> = ({ appointmendUuid }) => {
  const { t, i18n } = useTranslation();
  const client = useApolloClient();
  const weekDayMap: Record<VisitorAppointmentWeekday, string> = {
    MONDAY: t("visitors.appointmentForm.appointmentRecurringSettingsMondayLong"),
    TUESDAY: t("visitors.appointmentForm.appointmentRecurringSettingsTuesdayLong"),
    WEDNESDAY: t("visitors.appointmentForm.appointmentRecurringSettingsWednesdayLong"),
    THURSDAY: t("visitors.appointmentForm.appointmentRecurringSettingsThursdayLong"),
    FRIDAY: t("visitors.appointmentForm.appointmentRecurringSettingsFridayFriday"),
    SATURDAY: t("visitors.appointmentForm.appointmentRecurringSettingsSaturdayLong"),
    SUNDAY: t("visitors.appointmentForm.appointmentRecurringSettingsSundayLong"),
  };
  const { colors } = useTheme();
  const [data, setData] = useState<VisitorAppointmentRecurringInfo>();

  const handleClick = () => {
    if (data != null) {
      return;
    }

    void client
      .query<VisitorAppointmentQuery, VisitorAppointmentQueryVariables>({
        query: VisitorAppointmentDocument,
        variables: {
          uuid: appointmendUuid,
        },
        fetchPolicy: "network-only",
      })
      .then((result) => {
        if (result.errors == null || result.errors.length === 0) {
          setData(result.data.visitorAppointment.recurringInfo ?? undefined);
        }
      });
  };

  const loadingStr = `${t("common.loading")}...`;

  const getRecurrenceText = () => {
    let resultStr = loadingStr;
    if (data == null) {
      return resultStr;
    }

    switch (data.recurringType) {
      case VisitorAppointmentRecurringType.Daily:
        resultStr = t("visitors.appointmentForm.appointmentRepeatTypeDaily");
        break;
      case VisitorAppointmentRecurringType.Weekly:
        resultStr = t("visitors.appointmentForm.appointmentRepeatTypeWeekly");
        break;
      case VisitorAppointmentRecurringType.Monthly:
        resultStr = t("visitors.appointmentForm.appointmentRepeatTypeMonthly");
        break;
      default:
        return resultStr;
    }

    if (data.recurringType === VisitorAppointmentRecurringType.Weekly) {
      const weekDays = data.repeatOn
        ?.map((x, i, arr) => (i === arr.length - 1 && arr.length !== 1 ? `and ${weekDayMap[x]}` : `${weekDayMap[x]} `))
        .join("");

      return `${resultStr} ${
        data.repeatOn?.length === 7
          ? ""
          : t("visitors.appointmentForm.appointmentRecurringOccursEndsOn").toLocaleLowerCase()
      } ${weekDays}`;
    }

    return resultStr;
  };

  const getOccurance = () => {
    if (data == null) {
      return loadingStr;
    }

    const sortedAppointments = [...data.appointments].sort((a, b) => (a.startDate < b.startDate ? -1 : 1));
    const idx = sortedAppointments.findIndex((a) => a.uuid === appointmendUuid);

    return idx === -1 ? "" : `${idx + 1}/${sortedAppointments.length}`;
  };

  return (
    <>
      <div className="recurring-appointment">
        <Dropdown.Container
          width={240}
          placement="bottom-start"
          mobileView="regular"
          fixedHeader={
            <div className="px-4 pb-4 pt-2">
              <Text variant="text" className="recurring-appointment-title" size="extra-small" weight="medium">
                {t("visitors.appointmentForm.appointmentRecurring")}
              </Text>
              <EqForm.Group
                noHeaderMargin={true}
                className="mt-2 remove-margin-from-labels"
                label={t("visitors.common.start")}
              >
                <Text variant="navigation">
                  {data != null ? formatters.dateshort(DateTime.fromMillis(data.startDate), i18n.language) : loadingStr}
                </Text>
              </EqForm.Group>
              <EqForm.Group noHeaderMargin={true} label={t("visitors.common.end")}>
                <Text variant="navigation">
                  {data != null ? formatters.dateshort(DateTime.fromMillis(data.endDate), i18n.language) : loadingStr}
                </Text>
              </EqForm.Group>
              <EqForm.Group noHeaderMargin={true} label={t("visitors.appointmentForm.appointmentRecurrence")}>
                <Text variant="navigation">{getRecurrenceText()}</Text>
              </EqForm.Group>
              <EqForm.Group
                noHeaderMargin={true}
                noGroupMargin={true}
                label={t("visitors.appointmentForm.appointmentOccurence")}
              >
                <Text variant="navigation">{getOccurance()}</Text>
              </EqForm.Group>
            </div>
          }
          maxHeight={300}
          trigger={
            <RiRepeat2Line
              onClick={handleClick}
              size={16}
              color={colors.grayscale[60]}
              style={{ overflow: "hidden", outline: "none" }}
              tabIndex={0}
              className="cursor-pointer"
            />
          }
        >
          {" "}
        </Dropdown.Container>
      </div>
      <style jsx>{`
        .recurring-appointment :global(.section-mobile) {
          max-height: 100%;
          margin-top: 0;
          display: flex;
          flex-direction: column;
          overflow: hidden;
        }
        .recurring-appointment :global(section) {
          padding-top: 0;
        }
        .recurring-appointment :global(.recurring-appointment-title) {
          text-transform: uppercase;
        }
        @media (max-width: 768px) {
          .recurring-appointment :global(.header-mobile--short) {
            top: 12px;
          }
          .recurring-appointment :global(section.section-mobile .content) {
            max-height: 100%;
            height: 100%;
            flex-grow: 1;
          }
        }
      `}</style>
    </>
  );
};
