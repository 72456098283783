import { useState, useCallback } from "react";

export const optionalCookiesAcceptedKey = "_eq_opt_cookies";
export const cookiesPreferencesSavedKey = "_eq_cookies";

export const getCookiesPreferencesSaved = () => {
  if (typeof window === "undefined") {
    return true;
  }
  const val = window.localStorage.getItem(cookiesPreferencesSavedKey);
  if (val != null) {
    try {
      return JSON.parse(val) as boolean;
    } catch {
      // do nothing, return default
    }
  }

  return false;
};

export const getOptionalCookiesEnabled = () => {
  if (typeof window === "undefined") {
    return false;
  }
  const val = window.localStorage.getItem(optionalCookiesAcceptedKey);
  if (val != null) {
    try {
      return JSON.parse(val) as boolean;
    } catch {
      // do nothing, return default
    }
  }

  return false;
};

export const useCookieSettings = () => {
  const [cookiePreferencesSaved, _setCookiePreferencesSaved] = useState(getCookiesPreferencesSaved());
  const [optionalCookiesEnabled, _setOptionalCookiesEnabled] = useState(getOptionalCookiesEnabled());

  const setCookiePreferencesSaved = useCallback(() => {
    window.localStorage.setItem(cookiesPreferencesSavedKey, "true");
    _setCookiePreferencesSaved(true);
  }, []);

  const setOptionalCookiesEnabled = useCallback((accepted: boolean) => {
    window.localStorage.setItem(optionalCookiesAcceptedKey, JSON.stringify(accepted));
    _setOptionalCookiesEnabled(accepted);
  }, []);

  return {
    cookiePreferencesSaved,
    optionalCookiesEnabled,
    setCookiePreferencesSaved,
    setOptionalCookiesEnabled,
  };
};

export type CookieSettings = ReturnType<typeof useCookieSettings>;
