import React from "react";

interface Props {
  size?: number;
}

export const SvgNotStarted: React.FC<Props> = ({ size = 16 }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21.8097 13.9516L19.8477 13.5635C19.9474 13.0594 20 12.5368 20 12C20 11.4632 19.9474 10.9406 19.8477 10.4365L21.8097 10.0484C21.9345 10.6796 22 11.3321 22 12C22 12.6679 21.9345 13.3204 21.8097 13.9516Z"
        fill="#666666"
      />
      <path
        d="M20.3157 6.44384L18.6537 7.55648C18.0691 6.68321 17.3168 5.93092 16.4435 5.34628L17.5562 3.68435C18.6467 4.41443 19.5856 5.35333 20.3157 6.44384Z"
        fill="#666666"
      />
      <path
        d="M13.9516 2.19034L13.5635 4.15232C13.0594 4.0526 12.5368 4 12 4C11.4632 4 10.9406 4.0526 10.4365 4.15232L10.0484 2.19034C10.6796 2.06547 11.3321 2 12 2C12.6679 2 13.3204 2.06547 13.9516 2.19034Z"
        fill="#666666"
      />
      <path
        d="M6.44384 3.68435L7.55648 5.34628C6.68321 5.93093 5.93092 6.68321 5.34628 7.55648L3.68435 6.44384C4.41443 5.35333 5.35333 4.41443 6.44384 3.68435Z"
        fill="#666666"
      />
      <path
        d="M2.19034 10.0484C2.06547 10.6796 2 11.3321 2 12C2 12.6679 2.06547 13.3204 2.19034 13.9516L4.15232 13.5635C4.0526 13.0594 4 12.5368 4 12C4 11.4632 4.0526 10.9406 4.15232 10.4365L2.19034 10.0484Z"
        fill="#666666"
      />
      <path
        d="M3.68435 17.5562L5.34628 16.4435C5.93093 17.3168 6.68321 18.0691 7.55648 18.6537L6.44384 20.3157C5.35333 19.5856 4.41443 18.6467 3.68435 17.5562Z"
        fill="#666666"
      />
      <path
        d="M10.0484 21.8097L10.4365 19.8477C10.9406 19.9474 11.4632 20 12 20C12.5368 20 13.0594 19.9474 13.5635 19.8477L13.9516 21.8097C13.3204 21.9345 12.6679 22 12 22C11.3321 22 10.6796 21.9345 10.0484 21.8097Z"
        fill="#666666"
      />
      <path
        d="M17.5562 20.3157L16.4435 18.6537C17.3168 18.0691 18.0691 17.3168 18.6537 16.4435L20.3157 17.5562C19.5856 18.6467 18.6467 19.5856 17.5562 20.3157Z"
        fill="#666666"
      />
    </svg>
  );
};
