import type React from "react";
import type { ReactNode } from "react";
import { useState, useEffect } from "react";
import { createPortal } from "react-dom";

export const BodyPortal: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [modalContainer, setModalContainer] = useState<HTMLDivElement | null>(null);

  useEffect(() => {
    // access `document` safely in here since useEffect runs on client side only
    const modalElement = document.createElement("div");
    document.body.appendChild(modalElement);
    setModalContainer(modalElement);
  }, []);

  return modalContainer == null ? null : createPortal(children, modalContainer);
};
