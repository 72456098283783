import type { ComponentProps } from "react";
import React from "react";

export const BreakWordDiv: React.FC<ComponentProps<"div">> = (props) => {
  return (
    <>
      <div className={props.className}>{props.children}</div>
      <style jsx>{`
        div {
          word-break: break-word;
        }
        div :global(.form-group label) {
          white-space: normal;
        }
        div :global(.form-group .tooltip) {
          width: auto;
        }
      `}</style>
    </>
  );
};
