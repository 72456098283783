import React, { useContext } from "react";
import { Dropdown, useConfirmer, useTheme } from "@equiem/react-admin-ui";
import { RiDeleteBinLine, RiMoreLine } from "@equiem/react-admin-ui/icons";
import { useTranslation } from "@equiem/localisation-eq1";

import { CurrentSegment } from "../contexts/SegmentProvider";
import { translateFilterType } from "../lib/mapGraphqlFilterType";

interface Props {
  open: boolean;
  id: string;
  remove: (id: string) => void;
}

export const SegmentSuffix: React.FC<Props> = ({ open, id, remove }) => {
  const { t } = useTranslation();
  const { colors } = useTheme();
  const { withConfirmation } = useConfirmer();
  const { toggleFilter, inactiveFilters } = useContext(CurrentSegment);

  return (
    <div className="ml-auto text-muted d-flex align-items-center">
      {open && (
        <Dropdown.Button
          placement="bottom-end"
          className="segmentation-filter"
          title={t("segmentation.addFilter")}
          variant="secondary"
          disabled={inactiveFilters.length === 0}
        >
          {inactiveFilters.map((f, i) => {
            return (
              <Dropdown.Item
                key={i}
                onClick={() => {
                  toggleFilter(f.type, true);
                }}
                className={`${f.type}-filter`}
              >
                {translateFilterType(f.type, t)}
              </Dropdown.Item>
            );
          })}
        </Dropdown.Button>
      )}
      <Dropdown.Icon placement="bottom-end" className="segment-more ml-2" icon={RiMoreLine}>
        <Dropdown.Item
          className="remove-segment"
          onClick={withConfirmation({
            title: t("segmentation.deleteSegment"),
            message: t("segmentation.deleteSegmentMessage"),
            confirmButtonText: t("segmentation.deleteSegmentConfirmation"),
            confirmButtonVariant: "danger",
            onConfirm: () => remove(id),
          })}
        >
          <RiDeleteBinLine color={colors.status.danger.primary} className="me-2" /> {t("common.delete")}
        </Dropdown.Item>
      </Dropdown.Icon>
    </div>
  );
};
