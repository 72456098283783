import type { ReactNode } from "react";
import React, { useState, type FC, useCallback, useMemo } from "react";
import type { SideModalTab } from "./SideModalContext";
import { SideModalContext } from "./SideModalContext";
import { Modal } from "@equiem/react-admin-ui";
import { useTranslation } from "@equiem/localisation-eq1";
import { SiteSettingsInner } from "../components/articles/SiteSettingsInner";
import { PublishingInner } from "../components/articles/PublishingInner";
import { PublishingSettingsInner } from "../components/articles/PublishingSettingsInner";

export const SideModalProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [currentTab, _setCurrentTab] = useState<SideModalTab>("sites");
  const { t } = useTranslation();

  const closeModal = useCallback(() => {
    setOpen(false);
  }, []);

  const openTab = useCallback((tab: SideModalTab) => {
    _setCurrentTab(tab);
    setOpen(true);
  }, []);

  const title = useMemo(() => {
    if (currentTab === "publishing") {
      return t("contentManagement.articles.modals.publishingTitle");
    }
    if (currentTab === "publishSettings") {
      return t("contentManagement.articles.modals.publishSettingsTitle");
    }
    return t("contentManagement.articles.modals.sitesTitle");
  }, [currentTab, t]);

  return (
    <>
      <SideModalContext.Provider
        value={{
          openTab,
          currentTab,
          closeModal,
        }}
      >
        {children}
        <Modal.Dialog
          title={title}
          show={open}
          onHide={closeModal}
          hideOnEsc={true}
          hideOnClick={true}
          focusTrapOptions={{ allowOutsideClick: () => true }}
          size={currentTab === "publishing" ? "xl" : "md"}
          scrollable={true}
          sideModal={currentTab !== "publishing"}
        >
          {currentTab === "sites" && <SiteSettingsInner />}
          {currentTab === "publishSettings" && <PublishingSettingsInner />}
          {currentTab === "publishing" && <PublishingInner />}
        </Modal.Dialog>
      </SideModalContext.Provider>
    </>
  );
};
