import React, { useContext, useEffect, useState } from "react";

import { useSiteContext } from "@equiem/lib";
import { useApolloErrorTranslation, useTranslation } from "@equiem/localisation-eq1";
import { EmptyState, Table, useDebounced, useTheme } from "@equiem/react-admin-ui";
import { RiFileDownloadLine } from "@equiem/react-admin-ui/icons";

import { ApartmentSortField } from "../../../generated/settings-client";
import { ApartmentsFilterContext } from "../contexts/ApartmentsFilterContext";
import { useExportApartmentList } from "../hooks/useExportApartmentList";
import { usePagedApartmentList } from "../hooks/usePagedApartmentList";

import { ApartmentListRow } from "./ApartmentListRow";

export const ApartmentsList: React.FC<{ className?: string }> = ({ className }) => {
  const { t } = useTranslation();
  const { breakpoints, colors } = useTheme();
  const { tError } = useApolloErrorTranslation();
  const site = useSiteContext();
  const { search } = useContext(ApartmentsFilterContext);
  const debouncedSearch = useDebounced(search, 500);
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("asc");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const csvExport = useExportApartmentList({ destinationUuid: site.uuid });
  const { apartments, error, loading, totalCount, refetch, fetchMore } = usePagedApartmentList({
    search: debouncedSearch,
    destinationUuid: site.uuid,
    pageSize,
  });

  useEffect(() => {
    void refetch({
      sort: {
        field: ApartmentSortField.ApartmentName,
        asc: sortDirection === "asc",
      },
    });
  }, [sortDirection, refetch]);

  useEffect(() => {
    setSortDirection("asc");
    setCurrentPage(1);
  }, [debouncedSearch]);

  const handleCsvExport = () => {
    void csvExport();
  };

  const defaultHeaders = [
    t("common.apartment"),
    <RiFileDownloadLine size={16} color={colors.grayscale[60]} key="csv" onClick={handleCsvExport} />,
  ];

  if (error != null) {
    return <>{tError(error)}</>;
  }

  const isEmpty = apartments.length === 0 && !loading;

  const hideHeader = isEmpty || (loading && apartments.length === 0);

  const handleSort = (index: number) => {
    if (index !== 0) {
      return;
    }

    setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    setCurrentPage(1);
  };

  const onPageChange = (page: number, size: number) => {
    if (page > currentPage) {
      fetchMore();
    }

    setCurrentPage(page);
    setPageSize(size);
  };

  return (
    <div className={`${className} apartment-list ${hideHeader && "apartment-list--empty"}`}>
      <Table.StickyHeaderTable
        loading={loading}
        rowHeaders={defaultHeaders}
        sort={{
          columnIndex: 0,
          direction: sortDirection,
          handleSort,
        }}
        pagination={{
          totalCount,
          currentPage,
          pageSize,
          onPageChange,
        }}
      >
        {isEmpty && (
          <tr>
            <td colSpan={defaultHeaders.length}>
              <EmptyState />
            </td>
          </tr>
        )}
        {!loading &&
          apartments
            .slice((currentPage - 1) * pageSize, currentPage * pageSize)
            .map((apartment) => <ApartmentListRow key={apartment.uuid} apartment={apartment} />)}
      </Table.StickyHeaderTable>
      <style jsx>{`
        .apartment-list :global(thead) {
          cursor: pointer;
        }

        .apartment-list :global(thead) :global(th:last-child) {
          width: 5%;
        }

        .apartment-list :global(thead) :global(th:last-child),
        .apartment-list :global(tbody) :global(td:last-child) {
          text-align: right;
        }

        @media (max-width: ${breakpoints.md}px) {
          .apartment-list {
            overflow: auto;
            padding: 0 32px;
          }

          .apartment-list :global(th) {
            position: relative !important;
            top: 0 !important;
          }
        }

        @media (max-width: ${breakpoints.sm}px) {
          .apartment-list {
            padding: 0 16px;
          }
        }
      `}</style>
    </div>
  );
};
