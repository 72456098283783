import { useSiteContext } from "@equiem/lib";
import { Button, ProgressCircle, useToast } from "@equiem/react-admin-ui";
import React, { useCallback } from "react";
import { useDisconnectBookingPaymentMutation } from "../../../generated/gateway-client";
import { useTranslation } from "@equiem/localisation-eq1";

export const DisconnectStripe: React.FC = () => {
  const { t } = useTranslation();
  const site = useSiteContext();
  const toast = useToast();
  const [mutation, { loading }] = useDisconnectBookingPaymentMutation({
    variables: { site: site.uuid },
  });

  const disconnect = useCallback(async () => {
    if (loading) {
      return;
    }

    const result = await mutation();
    if (result.data?.disconnectBookingPayment.__typename === "ConnectDisconnectBookingPaymentFailureResponse") {
      toast.negative(result.data.disconnectBookingPayment.reason);
    } else {
      toast.positive(t("bookings.settings.successfullyDisconnectedStripeAccount"), { autoDismiss: false });
    }
  }, [loading, mutation, toast, t]);

  return (
    <div>
      <Button
        variant="outline"
        disabled={loading}
        onClick={() => {
          disconnect().catch(console.log);
        }}
      >
        {loading && <ProgressCircle size="xs" />}
        {t("bookings.settings.disconnectStripeAccount")}
      </Button>
    </div>
  );
};
