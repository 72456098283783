import React, { useContext, useState } from "react";
import { DateTime } from "luxon";

import { useSiteContext } from "@equiem/lib";
import { formatters, useTranslation } from "@equiem/localisation-eq1";
import { Form, useTheme, useToast } from "@equiem/react-admin-ui";
import { RiCalendarEventLine } from "@equiem/react-admin-ui/icons";

import type { RequestQuery } from "../../../generated/requests-client";
import { RequestDetailsContext } from "../contexts/RequestDetailsContext";

interface P {
  request: RequestQuery["reqMgt"]["request"];
}

const RequestDateReportedEdit: React.FC<P & { date: DateTime }> = ({ request, date }) => {
  const { timezone } = useSiteContext();
  const theme = useTheme();
  const [time, setTime] = useState(date);
  const toast = useToast();
  const { t } = useTranslation();
  const { loading, update } = useContext(RequestDetailsContext);

  return (
    <>
      <div className="date-reported-container">
        <Form.Input
          className="date-reported"
          disabled={loading}
          showChrome
          type="datetime-local"
          calendarIconPosition="left"
          value={time.toFormat("yyyy-LL-dd'T'HH:mm")}
          onChange={(e) => {
            const conversion = DateTime.fromISO(e.target.value, {
              zone: timezone,
            });
            if (conversion.isValid) {
              setTime(conversion);
            }
          }}
          onBlur={() => {
            const reported = time.toMillis();
            if (request.reported === reported) {
              return;
            }
            if (isNaN(reported)) {
              toast.negative(t("common.invalidDateFormat"));
              return;
            }
            update(request, { reported }).catch(console.log);
          }}
        />
      </div>
      <style jsx>{`
        .date-reported-container {
          overflow: hidden;
          margin-left: -${theme.spacers.s3};
        }
      `}</style>
    </>
  );
};

export const RequestDateReported: React.FC<P> = ({ request }) => {
  const { timezone } = useSiteContext();
  const { i18n } = useTranslation();
  const date = DateTime.fromMillis(request.reported, { zone: timezone });

  return request.viewerRelations.canManage ? (
    <RequestDateReportedEdit date={date} request={request} />
  ) : (
    <div className="d-flex align-itemse-center py-3 date-readonly">
      <RiCalendarEventLine size={16} className="mr-4" />
      {`${formatters.dateshort(date, i18n.language)} ${formatters.timeshort(date, i18n.language)}`}
    </div>
  );
};
