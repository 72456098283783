import { v4 as uuidv4, validate as isValidUuid } from "uuid";

export const getStripeConnectState = (siteUuid: string) => `${uuidv4()}--${siteUuid}`;

export const parseStripeConnectState = (state: string) => {
  const stateParts = state.split("--");
  if (stateParts.length !== 2 || !stateParts.every((part) => isValidUuid(part))) {
    throw new Error("Unable to parse stripe connect state");
  }

  return {
    stateUuid: stateParts[0],
    siteUuid: stateParts[1],
  };
};
