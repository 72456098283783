/* eslint-disable @typescript-eslint/strict-boolean-expressions */

import React from "react";

import { BulkTable } from "@equiem/react-admin-ui";

import type {
  VisitorForBuildingReceptionFragment,
  VisitorReception,
  VisitorsByAllBuildingReceptionsQuery,
} from "../../../../generated/visitors-client";
import { VisitorStatus } from "../../../../generated/visitors-client";
import { CardIdStatusWidget } from "../../../appointment/widgets/CardIdStatusWidget";
import { BulkOperations } from "../../components/BulkOperations";
import { useAppointmentsBodyRows } from "../../hooks/useAppointmentsBodyRows";
import { useAppointmentsHeaders } from "../../hooks/useAppointmentsHeaders";
import { useAppointmentTableData } from "../../hooks/useAppointmentTableData";

interface Props {
  visitors: VisitorForBuildingReceptionFragment[];
  receptionType: "building" | "tenant";
  updateEdges: (
    cb: (
      edges: VisitorsByAllBuildingReceptionsQuery["visitorsByAllBuildingReceptions"]["edges"],
    ) => VisitorsByAllBuildingReceptionsQuery["visitorsByAllBuildingReceptions"]["edges"],
  ) => void;
  visitorsLoading: boolean;
}

export const AppointmentsTable: React.FC<Props> = ({ visitors, receptionType, updateEdges, visitorsLoading }) => {
  const {
    handleAction,
    handleNotes,
    handleSetCardStatusReturned,
    handleCardIdBlur,
    selectedVisitorIds,
    setSelectedVisitorIds,
    cardStatusUpdatingVisitors,
    handleNotify,
    setPrintingVisitors,
    spacers,
    top,
    ref,
    isStuck,
    setCardIdLoading,
    setCardStatusLoading,
    modalCallback,
    cardIds,
    setCardIds,
    statusUpdatingVisitors,
    cardIdUpdatingVisitors,
    setReception,
    router,
    modal,
  } = useAppointmentTableData({ updateEdges });

  const handleActivity = (uuid: string) => () => {
    modal.open("VisitorActivity", uuid);
  };

  const handleEdit = (uuid: string) => () => {
    void router.push(`/visitor-management/building-receptions/appointment?appointmentUuid=${uuid}`);
  };

  const handleResendCode = (uuid: string) => () => {
    modal.open("VisitorResendCode", uuid);
  };

  const handlePassPrint = (visitor: VisitorForBuildingReceptionFragment) => {
    setReception?.(visitor.appointment.reception as VisitorReception);
    setPrintingVisitors?.([visitor]);
  };

  const enableAccessCard =
    visitors.length > 0 && visitors.some((item) => Boolean(item.appointment.reception?.enableAccessCard));

  const isCardIdDisabled = (visitor: VisitorForBuildingReceptionFragment) =>
    visitor.status === VisitorStatus.CheckedOut ||
    setCardIdLoading ||
    setCardStatusLoading ||
    visitor.cardReturned ||
    !visitor.appointment.reception?.enableAccessCard;

  const enablePassPrinting = (visitor?: VisitorForBuildingReceptionFragment) =>
    visitor?.appointment.reception?.enablePassPrinting as boolean;

  const onClickBulk = () => {
    setPrintingVisitors?.(visitors.filter((visitor) => selectedVisitorIds.includes(visitor.uuid)));
  };

  const bodyRows = useAppointmentsBodyRows({
    data: visitors,
    handleAction,
    handleNotes,
    handleSetCardStatusReturned,
    handleCardIdBlur,
    selectedVisitorIds,
    cardStatusUpdatingVisitors,
    handleNotify,
    setCardIdLoading,
    setCardStatusLoading,
    cardIds,
    setCardIds,
    statusUpdatingVisitors,
    cardIdUpdatingVisitors,
    receptionType,
    enableAccessCard,
    handleActivity,
    handleEdit,
    handlePassPrint,
    handleResendCode,
    isCardIdDisabled,
    enablePassPrinting,
    visitorsLoading,
  });

  const headers = useAppointmentsHeaders(enableAccessCard);

  return (
    <>
      <>
        <BulkTable
          sticky={{ top, isStuck }}
          ref={ref}
          className="w-100"
          onSelected={(ids) => {
            setSelectedVisitorIds(ids);
          }}
          bulkOperations={<BulkOperations onClick={onClickBulk} />}
          headers={headers}
          bodyRows={bodyRows}
        />
        <CardIdStatusWidget handleAction={modalCallback} />
      </>
      <style jsx>
        {`
          .table-head {
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
            text-transform: uppercase;
            color: rgba(0, 0, 0, 0.6);
          }
          :global(.table) {
            border-style: hidden;
            box-shadow: 0 0 0 1px #e6e6e6;
            border-radius: 8px;
            border-collapse: collapse;
            width: 100%;
            padding-bottom: ${spacers.s10};
          }
          .table-body {
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
          }
          .table-body tr {
            border-bottom: 1px solid #e6e6e6;
          }
          :global(.table) th,
          :global(.table) td {
            padding: ${spacers.s3} ${spacers.s5};
          }
          :global(.table) .row:hover {
            background: #f2f2f2;
          }
          .large-column {
            width: 20%;
          }
          :global(.notes-button) {
            overflow: unset !important;
          }
          :global(.add-notes) {
          }
        `}
      </style>
    </>
  );
};
