/* istanbul ignore file */
import React, { useCallback, useContext, useEffect, useState } from "react";
import { Field } from "formik";
import { DateTime } from "luxon";

import { CurrentProfile, stringIsEmpty, stringNotEmpty } from "@equiem/lib";
import { formatters, useTranslation } from "@equiem/localisation-eq1";
import { Button, Form as EqForm, IconButton, Modal, useConfirmer, useTheme, useToast } from "@equiem/react-admin-ui";
import { RiDeleteBin6Line, RiStickyNoteLine } from "@equiem/react-admin-ui/icons";

import { Modal as ModalContext } from "../../../contexts/ModalContext";
import type { VisitorForReceptionFragment } from "../../../generated/visitors-client";
import {
  useCreateReceptionistNoteMutation,
  useDeleteReceptionistNoteMutation,
  useReceptionistNotesByVisitorUuidLazyQuery,
  VisitorForReceptionFragmentDoc,
} from "../../../generated/visitors-client";

interface Props {
  organizerName: string;
  notes: string;
  id: string;
  visitor: VisitorForReceptionFragment;
  initialStep?: ReceptionNotesStep;
}

interface NoteProps {
  id?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  date?: number;
  value?: string | null;
  userId?: string | null;
  onDelete: (id?: string | null) => Promise<void>;
}

export enum ReceptionNotesStep {
  View = 0,
  AddNew = 1,
  Confirm = 2,
}

export const ReceptionistNote: React.FC<NoteProps> = ({ userId, firstName, lastName, date, value, onDelete }) => {
  const { t, i18n } = useTranslation();
  const { withConfirmation } = useConfirmer();
  const { colors } = useTheme();
  const userCtx = useContext(CurrentProfile);

  return (
    <>
      <div className="d-flex justify-content-between align-items-center">
        <label className="label">
          {t("visitors.reception.receptionistNote", {
            name: `${firstName} ${lastName}`,
            date: formatters.dateshort(DateTime.fromMillis(date ?? 0), i18n.language),
          })}
        </label>
        {userCtx.profile?.uuid === userId && (
          <IconButton
            className="mr-n3"
            onClick={withConfirmation({
              title: t("common.areYouSure"),
              message: t("visitors.reception.deleteReceptionistNoteConfirm"),
              confirmButtonText: t("common.yesDelete"),
              confirmButtonVariant: "danger",
              onConfirm: () => {
                void onDelete();
              },
              cancelButtonText: t("home.widgets.cancelNo"),
            })}
            hover={{
              background: colors.status.danger.primary,
            }}
          >
            <RiDeleteBin6Line size={16} color={colors.danger} />
          </IconButton>
        )}
      </div>

      <div className="pt-2 pl-3 pr-3 pb-6">
        <span>{value}</span>
      </div>
      <style jsx>
        {`
          label {
            flex: 1;
            font-weight: 500;
            font-size: 12px;
            color: ${colors.medium};
            margin: 0;
            text-overflow: ellipsis;
            overflow: hidden;
            display: inline-block;
            white-space: nowrap;
            text-transform: uppercase;
          }
        `}
      </style>
    </>
  );
};

export const ReceptionNotesWidget: React.FC<Props> = ({ organizerName, notes, id, visitor, initialStep }) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [step, setStep] = useState(initialStep ?? ReceptionNotesStep.View);
  const [newNote, setNewNote] = useState<string>("");
  const toast = useToast();
  const [create, { loading: creating }] = useCreateReceptionistNoteMutation({
    variables: {
      input: {
        value: newNote,
        visitorUuid: visitor.uuid,
      },
    },
    update: (cache, { data }) => {
      if (data?.createReceptionistNote.uuid != null) {
        cache.writeFragment<VisitorForReceptionFragment>({
          fragment: VisitorForReceptionFragmentDoc,
          fragmentName: "VisitorForReception",
          data: {
            ...visitor,
            receptionistNotes: [...visitor.receptionistNotes, { uuid: data.createReceptionistNote.uuid, viewed: true }],
          },
        });
      }
    },
  });

  const [deleteMutation] = useDeleteReceptionistNoteMutation({
    update: (cache, _, { variables }) => {
      if (variables != null) {
        cache.writeFragment<VisitorForReceptionFragment>({
          fragment: VisitorForReceptionFragmentDoc,
          fragmentName: "VisitorForReception",
          data: {
            ...visitor,
            receptionistNotes: visitor.receptionistNotes.filter(
              (receptionistNote) => receptionistNote.uuid !== variables.uuid,
            ),
          },
        });
      }
    },
  });

  const [loadReceptionistNotes, receptionistNotes] = useReceptionistNotesByVisitorUuidLazyQuery({
    fetchPolicy: "network-only",
    variables: {
      visitorUuid: visitor.uuid,
    },
  });

  const modal = useContext(ModalContext);

  const onCreate = useCallback(async () => {
    if (creating) {
      return;
    }

    try {
      await create();
      setNewNote("");
      setStep(ReceptionNotesStep.View);
      modal.close();
      setShowModal(false);
    } catch (e: unknown) {
      toast.negative(e instanceof Error ? e.message : "Unknown error", { autoDismiss: true });
    }
  }, [create, creating, modal, toast]);

  const onDelete = useCallback(
    async (uuid?: string | null) => {
      try {
        await deleteMutation({ variables: { uuid: uuid ?? "" } });
        await loadReceptionistNotes();
      } catch (e: unknown) {
        toast.negative(e instanceof Error ? e.message : "Unknown error", { autoDismiss: true });
      }
    },
    [deleteMutation, loadReceptionistNotes, toast],
  );

  const onClose = useCallback(() => {
    const receptionistNotesAmount = receptionistNotes.data?.getReceptionistNotes.length ?? 0;

    setNewNote("");
    setShowModal(false);
    modal.close();
    setStep(receptionistNotesAmount > 0 || stringNotEmpty(notes) ? ReceptionNotesStep.View : ReceptionNotesStep.AddNew);
  }, [setShowModal, modal, notes, receptionistNotes.data?.getReceptionistNotes]);

  useEffect(() => {
    if (modal.activeModal === "ReceptionNotes" && modal.id === id) {
      void loadReceptionistNotes();
      setShowModal(true);
    }
  }, [modal.activeModal, modal.id, id, loadReceptionistNotes]);

  return (
    <>
      <Modal.Dialog
        title={t("visitors.reception.receptionNotes")}
        show={showModal}
        onHide={onClose}
        hideOnEsc={true}
        hideOnClick={false}
        onBack={() => {
          setStep(ReceptionNotesStep.View);
        }}
        size="md"
        centered
        scrollable={true}
      >
        {step === ReceptionNotesStep.View && (
          <Modal.Header
            closeButton={true}
            noBorder={true}
            intro={t("visitors.reception.notesIntro", { organizerName })}
          ></Modal.Header>
        )}
        {step === ReceptionNotesStep.AddNew && (
          <Modal.Header closeButton={true} backButton={true} noBorder={true}></Modal.Header>
        )}

        <Modal.Body>
          {step === ReceptionNotesStep.View && (
            <div className="body m-0 pb-5">
              {!stringIsEmpty(notes) && <span className="notes">{notes}</span>}
              {receptionistNotes.data?.getReceptionistNotes.map((note) => {
                return (
                  <ReceptionistNote
                    key={note?.uuid}
                    date={note?.date}
                    firstName={note?.profile?.firstName}
                    lastName={note?.profile?.lastName}
                    value={note?.value}
                    userId={note?.profile?.uuid}
                    onDelete={async () => onDelete(note?.uuid)}
                  ></ReceptionistNote>
                );
              })}
            </div>
          )}
          {step === ReceptionNotesStep.AddNew ? (
            <div>
              {receptionistNotes.data != null && receptionistNotes.data.getReceptionistNotes.length > 0 ? (
                receptionistNotes.data.getReceptionistNotes.map((note) => {
                  return (
                    <ReceptionistNote
                      key={note?.uuid}
                      date={note?.date}
                      firstName={note?.profile?.firstName}
                      lastName={note?.profile?.lastName}
                      value={note?.value}
                      userId={note?.profile?.uuid}
                      onDelete={async () => onDelete(note?.uuid)}
                    ></ReceptionistNote>
                  );
                })
              ) : (
                <EqForm.Group label={t("visitors.reception.createNote")}>
                  <h2 className="intro">{t("visitors.reception.createNoteForAppointment")}</h2>
                </EqForm.Group>
              )}

              <EqForm.Group
                label={t("common.description")}
                showTooltip={true}
                tooltipText={t("visitors.reception.receptionistNoteTooltip")}
              >
                <Field
                  as={EqForm.Textarea}
                  style={{
                    minHeight: "112px",
                  }}
                  maxLength={500}
                  name="uploadStr"
                  variant="lg"
                  value={newNote}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setNewNote(e.target.value)}
                />
              </EqForm.Group>
            </div>
          ) : null}
        </Modal.Body>
        <Modal.Footer>
          {step === ReceptionNotesStep.View && (
            <div className="footer-buttons">
              <Button variant="outline" onClick={() => setStep(ReceptionNotesStep.AddNew)} className="mr-3">
                <RiStickyNoteLine />
                {t("visitors.reception.createNote")}
              </Button>
              <Button variant="primary" onClick={onClose}>
                {t("common.done")}
              </Button>
            </div>
          )}
          {step === ReceptionNotesStep.AddNew && (
            <div>
              <Button variant="outline" onClick={onClose} className="mr-3">
                {t("common.cancel")}
              </Button>
              <Button
                variant="primary"
                onClick={() => {
                  void onCreate();
                }}
                disabled={stringIsEmpty(newNote) || creating}
              >
                {t("common.save")}
              </Button>
            </div>
          )}
        </Modal.Footer>
      </Modal.Dialog>
      <style jsx>
        {`
          .body {
            min-height: 200px;
          }
          .notes {
            font-weight: 400 !important;
            font-size: 1rem !important;
            line-height: 1.5rem !important;
            height: 2.5rem !important;
            display: block;
            height: 100% !important;
          }
          .footer-buttons {
            display: inline-flex !important;
          }
          .intro {
            font-weight: 700;
            font-size: "1.5rem";
            line-height: "1.75rem";
            margin: 0 0 0 0;
            transition: all 300ms !important;
            transition: opacity 0ms !important;
            transform: "none";
          }
        `}
      </style>
    </>
  );
};
