import React, { type FC, useState, useContext } from "react";
import { useTranslation, formatters, type TFunction } from "@equiem/localisation-eq1";
import { DateTime } from "luxon";

import { useTheme } from "../../contexts/Theme";
import * as Dropdown from "../Dropdown";
import { FormTimeSelect, type Time } from "../Form/FormTimeSelect/FormTimeSelect";
import { Text } from "../Text/Text";
import { type FilterItemProps, type FilterItemTime, FilterType, FilterTimeModifier } from "./ComplexFilter.types";
import { ComplexFilterContext } from "./ComplexFilterContext";
import { FilterChip } from "./FilterChip";
import { ModifierSelect } from "./ModifierSelect";

const DAY_START: Time = { hour: 0, minute: 0 };
const DAY_END: Time = { hour: 23, minute: 59 };

export const defaultModifier = FilterTimeModifier.is;

const format = (time: string, language: string | undefined) =>
  formatters.timeshort(DateTime.fromFormat(time, "HH:mm"), language);

const toSeconds = (time: string | null | undefined) =>
  time != null && time !== "" ? DateTime.fromFormat(time, "HH:mm").toSeconds() : undefined;

const fromSeconds = (seconds: string) => DateTime.fromSeconds(Number(seconds)).toFormat("HH:mm");

const toTimeObject = (time: string | null | undefined): Time | undefined => {
  if (time == null || time === "") {
    return undefined;
  }

  const dt = DateTime.fromFormat(time, "HH:mm");
  return { hour: dt.hour, minute: dt.minute };
};

const parseValue = (value: string | string[] | undefined, modifier: FilterTimeModifier) => {
  const isRange = modifier === FilterTimeModifier.between;

  let rangeStart: string | undefined;
  let rangeEnd: string | undefined;
  if (Array.isArray(value)) {
    rangeStart = value[0];
    rangeEnd = isRange ? value[1] : value[0];
  } else {
    rangeStart = value;
    rangeEnd = value;
  }

  return { isRange, rangeStart, rangeEnd };
};

const modifierLabel = (modifier: FilterTimeModifier, t: TFunction) => {
  switch (modifier) {
    case FilterTimeModifier.is:
      return t("common.is");
    case FilterTimeModifier.before:
      return t("common.before");
    case FilterTimeModifier.after:
      return t("common.after");
    case FilterTimeModifier.between:
      return t("common.between");
    default:
      return "";
  }
};

const chipLabel = (
  modifier: FilterTimeModifier,
  rangeStart: string | undefined,
  rangeEnd: string | undefined,
  t: TFunction,
  language: string | undefined,
) => {
  if (rangeStart == null) {
    return undefined;
  }

  if (modifier === FilterTimeModifier.is) {
    return `${t("common.is")} ${format(rangeStart, language)}`;
  }
  if (modifier === FilterTimeModifier.before) {
    return `${t("common.before")} ${format(rangeStart, language)}`;
  }
  if (modifier === FilterTimeModifier.after) {
    return `${t("common.after")} ${format(rangeStart, language)}`;
  }

  if (modifier === FilterTimeModifier.between && rangeEnd != null) {
    return `${format(rangeStart, language)} - ${format(rangeEnd, language)}`;
  }

  return undefined;
};

export const FilterTime: FC<FilterItemProps<FilterItemTime>> = ({ title, name, modifiers, diffMinutes = 15, icon }) => {
  const { values, setValue, removeValue, language } = useContext(ComplexFilterContext);
  const { colors } = useTheme();
  const { t } = useTranslation();

  const [selectedModifier, setSelectedModifier] = useState<FilterTimeModifier>(
    (values[name]?.modifier ?? modifiers?.[0] ?? defaultModifier) as FilterTimeModifier,
  );
  const [prevSelectedModifier, setPrevSelectedModifier] = useState(selectedModifier);

  const { isRange, rangeStart, rangeEnd } = parseValue(
    values[name]?.value as string | string[] | undefined,
    selectedModifier,
  );

  if (selectedModifier !== prevSelectedModifier) {
    setPrevSelectedModifier(selectedModifier);
    setValue(name, {
      type: FilterType.time,
      modifier: selectedModifier,
      value: undefined,
    });
  }

  const handleStartChange = (newStartSeconds: string) => {
    const newStart = fromSeconds(newStartSeconds);
    if (selectedModifier === FilterTimeModifier.between) {
      setValue(name, { type: FilterType.time, modifier: selectedModifier, value: [newStart, rangeEnd ?? ""] });
    } else {
      setValue(name, { type: FilterType.time, modifier: selectedModifier, value: newStart });
    }
  };

  const handleEndChange = (newEndSeconds: string) => {
    const newEnd = fromSeconds(newEndSeconds);
    if (selectedModifier === FilterTimeModifier.between) {
      setValue(name, { type: FilterType.time, modifier: selectedModifier, value: [rangeStart ?? "", newEnd] });
    }
  };

  return (
    <>
      <Dropdown.Dropdown placement="bottom-start" flip>
        <FilterChip
          title={title}
          value={chipLabel(selectedModifier, rangeStart, rangeEnd, t, language)}
          type={FilterType.time}
          name={name}
          onClose={() => removeValue(name)}
          icon={icon}
          size="lg"
        />
        <Dropdown.Menu width={isRange ? 320 : 252} mobileView="regular">
          {modifiers != null && (
            <div className="time-top">
              <Text variant="label" className="m-0">
                {title}
              </Text>
              <ModifierSelect
                options={modifiers.map((m) => ({ value: m, label: modifierLabel(m, t) }))}
                selected={selectedModifier}
                onChange={(v) => setSelectedModifier(v as FilterTimeModifier)}
              />
            </div>
          )}
          <div className={isRange ? "range-input" : "time-input"}>
            <FormTimeSelect
              startTime={DAY_START}
              endTime={isRange ? toTimeObject(rangeEnd) ?? DAY_END : DAY_END}
              diffMinutes={diffMinutes}
              value={toSeconds(rangeStart)}
              onChange={(e) => handleStartChange(e.target.value)}
            />
            {isRange && (
              <FormTimeSelect
                startTime={toTimeObject(rangeStart) ?? DAY_START}
                endTime={DAY_END}
                diffMinutes={diffMinutes}
                value={toSeconds(rangeEnd)}
                onChange={(e) => handleEndChange(e.target.value)}
              />
            )}
          </div>
        </Dropdown.Menu>
      </Dropdown.Dropdown>
      <style jsx>{`
        .time-top {
          padding: 0 1rem 0.25rem;
          display: flex;
          flex-direction: row;
          align-items: center;
          color: ${colors.grayscale[100]};
        }
        .time-input {
          display: flex;
          padding: 0 0.5rem;
        }
        .range-input {
          padding: 0 0.5rem;
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-gap: 0.5rem;
        }
      `}</style>
    </>
  );
};
