import React, { useState } from "react";

import { useTranslation } from "@equiem/localisation-eq1";
import { Text, useIsMobileWidth, useTheme } from "@equiem/react-admin-ui";

import { SettingsHead } from "../../components/SettingsHead";
import { WidgetProvider } from "../../contexts/WidgetContext";
import { withContexts } from "../../contexts/withContexts";

import { UserList } from "./components/UserList";
import { UsersFilter } from "./components/UsersFilter";
import { FlexMembershipsProvider } from "./contexts/FlexMembershipsContext";
import { UsersFilterProvider } from "./contexts/UsersFilterContext";
import { RolesProvider } from "./roles/RolesProvider";
import { UserProfileWidget } from "./widgets/UserProfileWidget";

const UsersBase: React.FC = () => {
  const { t } = useTranslation();
  const { colors, breakpoints } = useTheme();
  const isMobile = useIsMobileWidth();
  const [loading, setLoading] = useState(false);

  return (
    <UsersFilterProvider>
      <WidgetProvider>
        <div className="page-container">
          <SettingsHead />
          <div className="main-container">
            {!isMobile && (
              <div className="d-flex align-items-center mb-5">
                <Text variant="heading" size="medium">
                  {t("settings.users")}
                </Text>
              </div>
            )}
            <UsersFilter showLoading={loading} />
            <UserList className="mt-5" setLoading={setLoading} />
          </div>
        </div>
        <RolesProvider>
          <FlexMembershipsProvider>
            <UserProfileWidget />
          </FlexMembershipsProvider>
        </RolesProvider>
        <style jsx>{`
          .page-container {
            height: 100%;
            width: 100%;
            background: ${colors.white};
          }
          .main-container {
            display: flex;
            flex-direction: column;
            padding: 32px;
          }

          @media screen and (max-width: ${breakpoints.sm}px) {
            .main-container {
              padding: 16px;
            }
          }
        `}</style>
      </WidgetProvider>
    </UsersFilterProvider>
  );
};

export const Users = withContexts(UsersBase);
