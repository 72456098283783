import React from "react";
import Link from "next/link";

import { useTranslation } from "@equiem/localisation-eq1";
import { Button, Tooltip, useConfirmer, useIsMobileWidth, useTheme } from "@equiem/react-admin-ui";
import { RiCloseLine, RiDeleteBin6Line, RiFile2Line } from "@equiem/react-admin-ui/icons";
import type { IFileV2 } from "@equiem/uploader";

import { FileDetails } from "./FileDetails";

interface Props {
  file: File | IFileV2;
  index: number;
  onRemove?: (_index: number) => void;
  showImg?: boolean;
  isPreview?: boolean;
  progress?: number;
  showRemove?: boolean;
  isFullWidthAttachment?: boolean;
  onCancelLoading?: () => void;
}

export const FileItem: React.FC<Props> = ({
  file,
  index,
  onRemove,
  onCancelLoading,
  showImg = false,
  progress = 1,
  showRemove = false,
  isFullWidthAttachment = false,
}) => {
  const isIFile = "key" in file || "temporaryUuid" in file || "value" in file || "temporaryImage" in file;
  const fileName = isIFile ? file.value?.filename : (file as File).name;
  const imageUrl = (file as IFileV2).value?.imageUrl;
  const url = (file as IFileV2).value?.imageUrl ?? (file as IFileV2).value?.url;

  const { withConfirmation } = useConfirmer();
  const { t } = useTranslation();
  const isMobile = useIsMobileWidth();

  const { colors, spacers, borderRadius } = useTheme();

  return (
    <>
      <div className="attachment">
        <div className="attachment-item">
          <Link href={url ?? "#"} legacyBehavior>
            <a onClick={(e) => url == null && e.preventDefault()} target={url} rel="noopener noreferrer">
              <div className="item-container">
                {imageUrl != null ? (
                  <div className="item-body">
                    <div className="thumbnail">
                      <img src={imageUrl} alt={fileName} loading="lazy" />
                    </div>
                    <div className="details">
                      <FileDetails file={file} progress={progress} />
                    </div>
                  </div>
                ) : (
                  <div className="item-body">
                    <div className="item-body-icon">
                      <RiFile2Line size={16} color={colors.grayscale[60]} style={{ alignSelf: "center" }} />
                    </div>
                    <div className="details">
                      <FileDetails file={file} progress={progress} />
                    </div>
                  </div>
                )}
              </div>
            </a>
          </Link>

          {onCancelLoading != null && (
            <div className="actions d-flex">
              <Tooltip placement="left" focusable={false} title={t("contentManagement.deleteFileAttachment")}>
                <Button size="sm" className="remove-file" shape="round" variant="ghost" onClick={onCancelLoading}>
                  <RiCloseLine size={16} color={colors.medium} />
                </Button>
              </Tooltip>
            </div>
          )}

          {showRemove && onRemove != null && (
            <div className="actions d-flex">
              <Tooltip placement="left" focusable={false} title={t("contentManagement.deleteFileAttachment")}>
                <Button
                  size="sm"
                  className="remove-file"
                  shape="round"
                  variant="ghost"
                  onClick={withConfirmation({
                    title: t("contentManagement.deleteFileAttachment"),
                    message: t("contentManagement.deleteFileAttachmentMessage"),
                    confirmButtonText: t("common.yesRemove"),
                    confirmButtonVariant: "danger",
                    onConfirm: () => onRemove(index),
                  })}
                >
                  <RiDeleteBin6Line size={16} color={colors.danger} />
                </Button>
              </Tooltip>
            </div>
          )}
        </div>
        <div className={`progress ${progress > 1 && progress < 100 ? "progress--loading" : "progress--loaded"}`}></div>
      </div>
      <style jsx>
        {`
          .item-body {
            display: flex;
            gap: 8px;
            width: 100%;
          }
          .attachment-item a {
            text-decoration: none;
            width: 100%;
          }
          .item-body-icon {
            border-radius: 50%;
            background: ${colors.grayscale[5]};
            padding: 8px;
            display: flex;
            height: fit-content;
          }
          .details {
            width: 100%;
            overflow: hidden;
          }
          .details :global(.filename) {
            margin: 0;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 90%;
          }
          .attachment {
            display: flex;
            align-items: center;
            gap: 8px;
            flex: 1 0 100%;
            overflow: ${isMobile ? "unset" : "hidden"};
            width: ${isFullWidthAttachment ? "100%" : isMobile ? "50%" : "33%"};
            max-width: ${isFullWidthAttachment ? "100%" : isMobile ? "50%" : "calc(33% - 4px)"};
            border: ${showImg ? "default" : "1px solid #e6e6e6"};
            border-radius: ${borderRadius};
            user-select: none;
            position: relative;
            cursor: pointer;
          }
          img {
            pointer-events: none;
            border-radius: ${borderRadius};
            height: 32px;
            width: 32px;
            object-position: center;
            object-fit: cover;
          }
          .thumbnail {
            width: 32px;
            height: 32px;
            display: flex;
          }
          .progress--loading {
            position: absolute;
            bottom: 0;
            border-bottom: ${spacers.s2} solid ${colors.primary};
            border-bottom-left-radius: ${borderRadius};
            border-bottom-right-radius: ${progress < 100 ? spacers.s0 : borderRadius};
            width: ${progress}%;
          }
          .attachment-item {
            display: flex;
            justify-content: space-between;
            padding: 10px;
            width: 100%;
          }
          .item-container {
            width: 100%;
          }
          .item-caption {
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
          }
          .delete-icon {
            margin-top: ${spacers.s1};
          }
          .actions {
            gap: ${spacers.s4};
            align-items: center;
          }
          .attachment :global(.actions) {
            align-self: center;
            position: absolute;
            right: ${spacers.s2};
            z-index: 1;
            font-size: 20px;
            line-height: 20px;
            cursor: pointer;
            background: rgba(255, 255, 255, 0.7);
            border-top-right-radius: ${borderRadius};
          }
        `}
      </style>
    </>
  );
};
