import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useFormikContext } from "formik";

import { Session } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import { Table, Text, useTheme } from "@equiem/react-admin-ui";
import { RiEditLine } from "@equiem/react-admin-ui/icons";

import type { SpaceSiteAudienceInput } from "../../generated/settings-client";
import { useDestinationSitesQuery } from "../../generated/settings-client";
import { useBuildingData } from "../../pages/building/hooks/useBuildingData";

import { AddAudienceModal } from "./AddAudienceModal";
import { AudienceInfo } from "./AudienceInfo";
import type { FormValues } from "./formValidation";

export const AddAudience: React.FC = () => {
  const { t } = useTranslation();
  const fm = useFormikContext<FormValues>();
  const [showModal, setShowModal] = useState(false);
  const { colors } = useTheme(true);
  const session = useContext(Session);
  const { siteUuid } = useBuildingData();
  const { data: siteListData, loading } = useDestinationSitesQuery({
    client: session.globalClient,
    fetchPolicy: "cache-first",
  });
  const sitesList = useMemo(() => {
    if (siteListData?.viewer.destinationsWithRelation.member.edges == null) {
      return {};
    }
    const nodes = siteListData.viewer.destinationsWithRelation.member.edges as [
      { node: { uuid: string; name: string } },
    ];
    return nodes.reduce((acc: Record<string, string>, edge) => {
      acc[edge.node.uuid] = edge.node.name;
      return acc;
    }, {});
  }, [siteListData]);

  const editSiteAudience = useCallback(
    (siteAudience: SpaceSiteAudienceInput) => {
      fm.setFieldValue("siteAudiences", [siteAudience]).catch(console.error);

      setShowModal(false);
    },
    [fm],
  );

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (fm.values.siteAudiences == null || fm.values.siteAudiences.length === 0) {
      fm.setFieldValue("siteAudiences", [
        {
          siteUuid,
          segmentIds: [],
          segmentSummary: "",
        },
      ]).catch(console.error);
    }
  }, [fm, siteUuid, fm.values.siteAudiences]);

  if (siteListData == null && loading) {
    return null;
  }

  return (
    <div className="site-audiences-table-container">
      <AddAudienceModal showModal={showModal} setShowModal={setShowModal} onEdit={editSiteAudience} />
      <Table.Table className="w-100">
        <thead>
          <tr>
            <Table.Header label={t("common.site")} />
            <Table.Header label={t("bookings.resources.audience")} />
            <Table.Header label="" />
          </tr>
        </thead>
        <tbody>
          {/*eslint-disable-next-line @typescript-eslint/no-unnecessary-condition*/}
          {fm.values.siteAudiences != null &&
            fm.values.siteAudiences.length > 0 &&
            fm.values.siteAudiences.map((audience) => {
              return (
                <tr key={audience.siteUuid}>
                  <td>
                    <Text variant="text" component="span" size="small">
                      {sitesList[audience.siteUuid]}
                    </Text>
                  </td>
                  <td>
                    <Text variant="text" component="span" size="small">
                      <AudienceInfo audience={audience} />
                    </Text>
                  </td>
                  <td>
                    <RiEditLine
                      className="edit-audience-button"
                      size="16"
                      color={colors.primary}
                      cursor="pointer"
                      onClick={() => {
                        setShowModal(true);
                      }}
                    />
                  </td>
                </tr>
              );
            })}
        </tbody>
      </Table.Table>
    </div>
  );
};
