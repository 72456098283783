import React, { useEffect, useState } from "react";

import { autoUpdate, flip as flipMw, size, shift, useFloating, offset as offsetMw } from "@floating-ui/react-dom";
import type { Placement, Strategy } from "@floating-ui/react-dom";
import { useFloatingHideListeners } from "../../hooks/useFloatingHideListeners";
import { NavContext } from "../../contexts/NavContext";

type DropdownEvents = "show" | "hide";

// eslint-disable-next-line @typescript-eslint/no-type-alias
export type DropdownContext = ReturnType<typeof useFloating> & {
  show: boolean;
  placement: Placement;
  toggleId?: string;
  setToggleId: (id: string) => void;
  setShow: (show: boolean) => void;
  onClose: () => void;
  onSelect: (eventKey: string) => void;
  autoClose: () => void;
  subscribe: (id: DropdownEvents, callback: VoidFunction) => VoidFunction;
};

export const DropdownContext = React.createContext<DropdownContext>({
  show: true,
  placement: "bottom-start",
  setToggleId: () => undefined,
  setShow: () => undefined,
  onSelect: () => undefined,
  onClose: () => undefined,
  autoClose: () => undefined,
  subscribe: () => () => undefined,
  floating: () => undefined,
  middlewareData: {},
  reference: () => undefined,
  refs: { floating: { current: null }, reference: { current: null } },
  strategy: "absolute",
  update: () => undefined,
  x: null,
  y: null,
});

export interface Props {
  placement?: Placement;
  flip?: boolean;
  onSelect?: (eventKey: string) => void;
  onToggle?: (show: boolean) => void;
  onClose?: () => void;
  onAutoClose?: () => void;
  fontWeight?: NavContext["fontWeight"];
  sameWidth?: boolean;
  offset?: number;
  strategy?: Strategy;
}

export const Dropdown: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  placement = "bottom-start",
  flip = false,
  onSelect = () => undefined,
  onToggle = () => undefined,
  onAutoClose = () => undefined,
  onClose = () => undefined,
  fontWeight = "light",
  sameWidth = false,
  offset,
  strategy,
}) => {
  const [show, setShow] = useState(false);
  const [toggleId, setToggleId] = useState<string>();
  const [subscribers, setSubscribers] = useState<{ [key in DropdownEvents]: Array<() => void> }>({
    show: [],
    hide: [],
  });

  useEffect(() => {
    onToggle(show);
  }, [show, onToggle]);

  const floating = useFloating({
    strategy,
    placement,
    middleware: [
      shift(),
      ...(flip ? [flipMw()] : []),
      ...(offset != null ? [offsetMw(offset)] : []),
      ...(sameWidth
        ? [
            size({
              apply({ rects, elements }) {
                Object.assign(elements.floating.style, {
                  width: `${rects.reference.width}px`,
                });
              },
            }),
          ]
        : []),
    ],
    whileElementsMounted: autoUpdate,
  });

  useEffect(() => {
    if (show) {
      subscribers.show.forEach((callback) => callback());
    }
  }, [show, subscribers]);

  const onHide = () => {
    if (subscribers.hide.length > 0) {
      subscribers.hide.forEach((callback) => callback());
    } else {
      setShow(false);
      onClose();
    }
  };

  useFloatingHideListeners({
    onHide,
    floating,
  });

  const subscribe = (id: DropdownEvents, callback: () => void) => {
    setSubscribers((prev) => ({
      ...prev,
      [id]: [...prev[id], callback],
    }));

    return () => {
      setSubscribers((prev) => ({
        ...prev,
        [id]: prev[id].filter((cb) => cb !== callback),
      }));
    };
  };

  const autoClose = () => {
    setShow(false);
    onClose();
    onAutoClose();
  };

  return (
    <DropdownContext.Provider
      value={{
        show,
        toggleId,
        setToggleId,
        setShow,
        onSelect,
        onClose,
        autoClose,
        subscribe,
        ...floating,
      }}
    >
      <NavContext.Provider value={{ fontWeight }}>{children}</NavContext.Provider>
    </DropdownContext.Provider>
  );
};
