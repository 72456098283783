import type { Dispatch, SetStateAction } from "react";
import { createContext } from "react";
import type { Building } from "../components/lib/flattenLocations";

export const emptyLocation = {
  uuid: "",
  name: "",
  levels: [],
  destination: {
    uuid: "",
    name: "",
  },
  isNew: true,
};

export interface FlexTenantLocationContext {
  locations: Building[];
  setLocations: Dispatch<SetStateAction<Building[]>>;
  clearLocations: () => void;
  hasChanged: boolean;
}

export const FlexTenantLocationContext = createContext<FlexTenantLocationContext>({
  locations: [],
  setLocations: () => ({}),
  clearLocations: () => ({}),
  hasChanged: false,
});
