import { useShortcuts } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import { useConfirmer } from "@equiem/react-admin-ui";
import type { ReactNode } from "react";
import { useCallback, createContext, useEffect, useState } from "react";
import type { ShortcutEvents } from "./ShortcutEvents";

export interface WidgetContext {
  showModal: boolean;
  closeModal: () => void;
  openModal: () => void;
  confirmCancel: (_onConfirm?: () => void) => void;
}

export const Widget = createContext<WidgetContext>({
  showModal: false,
  closeModal: () => {
    //to be implemented
  },
  openModal: () => {
    //to be implemented
  },
  confirmCancel: () => {
    //to be implemented
  },
});

export const WidgetProvider: React.FC<{ showModalInit?: boolean; children: ReactNode }> = ({
  children,
  showModalInit = false,
}) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(showModalInit);
  const { withConfirmation } = useConfirmer();
  const { on } = useShortcuts<ShortcutEvents>();

  const closeModal = useCallback(() => {
    setShowModal(false);
  }, []);
  const openModal = useCallback(() => {
    setShowModal(true);
  }, []);

  useEffect(() => {
    on((shortcut) => {
      if (shortcut === "addUserAsKnownRole") {
        openModal();
      }
    });
    // Include on in here will cause multiple time of call.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openModal]);

  const confirmCancel = useCallback(
    (onConfirm?: () => void) => {
      withConfirmation({
        title: t("common.areYouSure"),
        message: t("home.widgets.inviteCancelModal"),
        confirmButtonText: t("common.yesCancel"),
        cancelButtonText: t("home.widgets.cancelNo"),
        confirmButtonVariant: "danger",
        onConfirm: () => {
          onConfirm?.();
          closeModal();
        },
      })();
    },
    [closeModal, t, withConfirmation],
  );

  return <Widget.Provider value={{ showModal, closeModal, openModal, confirmCancel }}>{children}</Widget.Provider>;
};
