import { stringNotEmpty, useSiteContext } from "@equiem/lib";
import { formatters, useTranslation } from "@equiem/localisation-eq1";
import type { FilterValueDate } from "@equiem/react-admin-ui";
import { Button, ComplexFilterContext, FilterDateModifier, FilterType, IconButton } from "@equiem/react-admin-ui";
import { RiArrowLeftSLine, RiArrowRightSLine } from "@equiem/react-admin-ui/icons";
import { DateTime } from "luxon";
import React, { useCallback, useContext, useRef } from "react";

export type Props = {
  startDate: number;
};

export const CatalogueCalendarNavigation: React.FC<Props> = ({ startDate }) => {
  const { i18n, t } = useTranslation();
  const { timezone } = useSiteContext();
  const { values, setValue, removeValue } = useContext(ComplexFilterContext);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const dateInputRef = useRef<HTMLInputElement>(null);

  const currentDate = DateTime.fromMillis(startDate, { zone: timezone }).toFormat("yyyy-MM-dd");
  const today = DateTime.local({ zone: timezone });
  const isToday = DateTime.fromMillis(startDate, { zone: timezone }).hasSame(today, "day");

  const handleButtonClick = () => {
    if (dateInputRef.current != null) {
      dateInputRef.current.showPicker();
    }
  };

  const onPrev = useCallback(() => {
    setValue("date", {
      type: FilterType.date,
      modifier: FilterDateModifier.is,
      value: (values.date != null
        ? DateTime.fromFormat(values.date.value as string, "yyyy-MM-dd", { zone: timezone })
        : DateTime.local({ zone: timezone })
      )
        .minus({ days: 1 })
        .toFormat("yyyy-MM-dd"),
    } as FilterValueDate);
  }, [setValue, timezone, values.date]);

  const onNext = useCallback(() => {
    setValue("date", {
      type: FilterType.date,
      modifier: FilterDateModifier.is,
      value: (values.date != null
        ? DateTime.fromFormat(values.date.value as string, "yyyy-MM-dd", { zone: timezone })
        : DateTime.local({ zone: timezone })
      )
        .plus({ days: 1 })
        .toFormat("yyyy-MM-dd"),
    } as FilterValueDate);
  }, [setValue, timezone, values.date]);

  const onPick = useCallback(
    (selectedDate: string) => {
      if (stringNotEmpty(selectedDate)) {
        setValue("date", {
          type: FilterType.date,
          modifier: FilterDateModifier.is,
          value: DateTime.fromFormat(selectedDate, "yyyy-MM-dd", { zone: timezone })
            .startOf("day")
            .toFormat("yyyy-MM-dd"),
        } as FilterValueDate);
      } else {
        removeValue("date");
      }
    },
    [removeValue, setValue, timezone],
  );

  return (
    <>
      <div className="navigation">
        <input
          type="date"
          min={DateTime.local({ zone: timezone }).toFormat("yyyy-MM-dd")}
          max="9999-12-31"
          ref={dateInputRef}
          value={currentDate}
          onChange={(e) => onPick(e.target.value)}
          style={{
            position: "absolute",
            zIndex: -1,
          }}
        />
        <IconButton onClick={onPrev} disabled={isToday}>
          <RiArrowLeftSLine size={24} />
        </IconButton>
        <Button variant="ghost" size="lg" ref={buttonRef} onClick={handleButtonClick}>
          {isToday ? t("common.today") : formatters.dateshort(startDate, i18n.language, { timezone })}
        </Button>
        <IconButton onClick={onNext}>
          <RiArrowRightSLine size={24} />
        </IconButton>
      </div>
      <style jsx>{`
        .navigation {
          display: flex;
          align-items: center;
        }
      `}</style>
    </>
  );
};
