import React, { useContext } from "react";

import { type CompanyFormValues, CurrentRole, Role } from "@equiem/lib";

import type { CompanyDetailsQuery } from "../../../generated/settings-client";

import { DefaultAccessGroupPicker } from "./DefaultAccessGroupPicker";
import { MultipleAccessGroupSelect } from "./MultipleAccessGroupSelect";

interface Props {
  company: NonNullable<CompanyDetailsQuery["companyV2"]>;
  accessGroups: Array<{ uuid: string; name: string }>;
  onAccessPassesChange: (values: CompanyFormValues) => void;
}

export const CompanyAccessGroups: React.FC<Props> = ({ company, accessGroups, onAccessPassesChange }) => {
  const { currentRole } = useContext(CurrentRole);
  const companyAccessGroups = company.companyDestination?.ac1Config?.accessGroups;

  return (
    <>
      {accessGroups != null && (
        <div>
          {currentRole === Role.PropertyManager && (
            <MultipleAccessGroupSelect
              accessGroups={accessGroups}
              company={company}
              onAccessPassesChange={onAccessPassesChange}
            />
          )}
          {currentRole === Role.WorkplaceManager && companyAccessGroups != null && companyAccessGroups.length > 0 && (
            <DefaultAccessGroupPicker
              accessGroups={accessGroups}
              company={company}
              onAccessPassesChange={onAccessPassesChange}
            />
          )}
        </div>
      )}
    </>
  );
};
