import React, { Children, useContext, useEffect, useRef, useState } from "react";

import { useTheme } from "../../contexts/Theme";
import { IframeResizerContext } from "../../contexts/IframePageInfoContext";
import { Backdrop } from "../Backdrop/Backdrop";
import { BodyPortal } from "../BodyPortal/BodyPortal";
import type { Props as DialogProps } from "./ModalInner/ModalInner";
import FocusTrap from "focus-trap-react";
import { ModalContext } from "./ModalContext";
import { ModalInner } from "./ModalInner/ModalInner";
import { SideModalInner } from "./SideModalInner/SideModalInner";
import { ModalFooter } from "./ModalFooter/ModalFooter";
import { FocusTrapContext } from "../../contexts/FocusTrapContext";

export interface BaseProps {
  onHide?: () => void;
  onBack?: () => void;
  backdrop?: boolean;
  title: string;
  show: boolean;
  focusTrapOptions?: FocusTrap.Props["focusTrapOptions"];
  size?: ModalContext["size"];
  withFooter?: boolean;
  sideModal?: boolean;
  mobileView?: boolean;
  zIndex?: number;
}

export interface DefaultProps extends BaseProps, DialogProps {
  sideModal?: false | undefined;
  scrollable?: boolean;
}

export interface SideModalProps extends BaseProps, Omit<DialogProps, "centered"> {
  sideModal: true;
  expansion?: React.ReactNode;
  expansionBackground?: string;
}

export type Props = DefaultProps | SideModalProps;

export const ModalDialog: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const { onHide = () => undefined, title, show, size, withFooter, onBack, sideModal } = props;
  const { inIframe } = useContext(IframeResizerContext);
  const theme = useTheme();
  const [headerBorder, setHeaderBorder] = useState(!theme.isAdmin);
  const [stickyHeader, setStickyHeader] = useState(false);
  const [stickyFooter, setStickyFooter] = useState(true);
  const focusRef = useRef<HTMLDivElement | null>(null);
  const modalBodyRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const isSideModal = sideModal === true;
    if (!inIframe && !isSideModal) {
      return () => undefined;
    }

    const orig = document.body.style.overflow;
    document.body.style.overflow = show ? "hidden" : orig;

    return () => {
      document.body.style.overflow = orig;
    };
  }, [show, inIframe, sideModal]);

  return !show ? null : (
    <ModalContext.Provider
      value={{
        onHide,
        onBack,
        title,
        scrollable: sideModal === true ? true : props.scrollable ?? false,
        show,
        modalBodyRef,
        headerBorder,
        setHeaderBorder,
        stickyHeader,
        setStickyHeader,
        stickyFooter,
        setStickyFooter,
        size: size ?? "md",
        withFooter:
          withFooter ??
          Children.toArray(props.children).find((c) => (c as React.ReactElement<unknown>).type === ModalFooter) != null,
      }}
    >
      <>
        {props.backdrop !== false ? <Backdrop zIndex={props.zIndex != null ? props.zIndex - 1 : undefined} /> : null}
        <FocusTrapContext.Provider value={{ ref: focusRef }}>
          <BodyPortal>
            <FocusTrap
              focusTrapOptions={{
                allowOutsideClick: (e) => {
                  return (
                    e.target instanceof Element &&
                    // pac-container for google maps autocomplete
                    (e.target.matches("[data-escape-focus-trap] *") || e.target.matches(".pac-container *"))
                  );
                },
                ...props.focusTrapOptions,
                escapeDeactivates: false,
              }}
            >
              {sideModal === true ? (
                <SideModalInner {...props} ref={focusRef} />
              ) : (
                <ModalInner {...props} ref={focusRef} />
              )}
            </FocusTrap>
          </BodyPortal>
        </FocusTrapContext.Provider>
      </>
    </ModalContext.Provider>
  );
};
