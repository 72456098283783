import { stringNotEmpty } from "@equiem/lib";
import { useTheme } from "@equiem/react-admin-ui";
import React from "react";

interface P {
  mode?: "adjustment" | "refund";
  name: string;
  description: string;
  amount: string;
  className?: string;
}

export const BookingChargeAdjustmentAndRefund: React.FC<P> = ({
  className = "",
  mode = "refund",
  name,
  description,
  amount,
}) => {
  const theme = useTheme();

  return (
    <>
      <div className={`info ${mode} ${className}`}>
        <div className="left-cont">
          <div className="title">{name}</div>
          {stringNotEmpty(description) && <div className="description">{description}</div>}
        </div>
        <div>
          {mode === "refund" && "-"}
          {amount}
        </div>
      </div>
      <style jsx>{`
        .info {
          border-radius: ${theme.borderRadius};
          background: ${theme.colors.grayscale[10]};
          padding: ${theme.spacers.s3};
          margin: 0 -${theme.spacers.s3};
          display: flex;
          justify-content: space-between;
        }
        .title {
          font-weight: 500;
        }
        .left-cont {
          font-size: 12px;
          max-width: 80%;
          word-break: break-word;
        }
        .adjustment {
          background: ${theme.colors.blue[10]};
        }
      `}</style>
    </>
  );
};
