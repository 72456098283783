/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import type { TFunction } from "@equiem/localisation-eq1";

export const resourceTypeToString = (resourceType: string, t: TFunction): string => {
  // @todo: need to check the is locked then translate.
  switch (resourceType) {
    case "Amenities":
      return t("bookings.resources.amenities");
    case "Boardroom":
      return t("bookings.resources.boardroom");
    case "Classroom":
      return t("bookings.resources.classroom");
    case "EventSpace":
      return t("bookings.resources.eventSpace");
    case "Facilities":
      return t("bookings.resources.facilities");
    case "MeetingRoom":
      return t("bookings.resources.meetingRoom");
    case "ProjectSpace":
      return t("bookings.resources.projectSpace");
    case "Workstation":
      return t("bookings.resources.workstation");
    default: {
      return resourceType;
    }
  }
};

export const resourceConfigurationToString = (type: string, t: TFunction): string => {
  const resourceConfigurationMapLocales: { [key in string]: string } = {
    "Boardroom": t("bookings.resources.boardroom"),
    "Cabaret": t("bookings.resources.cabaret"),
    "Cafe": t("bookings.resources.cafe"),
    "Classroom": t("bookings.resources.classroom"),
    "Cocktail": t("bookings.resources.cocktail"),
    "Dance floor": t("bookings.resources.danceFloor"),
    "Theatre": t("bookings.resources.theatre"),
    "U-shaped": t("bookings.resources.uShaped"),
  };

  return resourceConfigurationMapLocales[type] ?? type;
};

export const resourceFeatureGroupToString = (type: string, t: TFunction): string => {
  const resourceFeatureGroupMapLocales: { [key in string]: string } = {
    ROOM_FEATURES: t("bookings.resources.features"),
    SHARED_FACILITIES: t("bookings.resources.sharedFacilities"),
  };

  return resourceFeatureGroupMapLocales[type] ?? type;
};

export const resourceFeatureToString = (type: string, t: TFunction): string => {
  const resourceFeatureMapLocales: { [key in string]: string } = {
    "Accessibility": t("bookings.resources.accessibility"),
    "Audio visual display": t("bookings.resources.audioVisualDisplay"),
    "Clickshare": t("bookings.resources.clickshare"),
    "Hearing impairment loop": t("bookings.resources.hearingImpairmentLoop"),
    "High speed wifi": t("bookings.resources.highSpeedWifi"),
    "Microphone": t("bookings.resources.microphone"),
    "Phone chargers": t("bookings.resources.phoneChargers"),
    "Projector": t("bookings.resources.projector"),
    "Teleconferencing": t("bookings.resources.teleconferencing"),
    "Television": t("bookings.resources.television"),
    "Video conferencing": t("bookings.resources.videoConferencing"),
    "Whiteboard": t("bookings.resources.whiteboard"),
    "Break-out area": t("bookings.resources.breakOutArea"),
    "Business lounge": t("bookings.resources.businessLounge"),
    "Kitchenette": t("bookings.resources.kitchenette"),
    "Printer": t("bookings.resources.printer"),
    "Restroom": t("bookings.resources.restroom"),
    "Tea/coffee": t("bookings.resources.teaCoffee"),
  };

  return resourceFeatureMapLocales[type] ?? type;
};
