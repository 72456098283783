import React, { useContext, useEffect, useMemo, useState } from "react";
import type { FormikHelpers } from "formik";
import { Formik } from "formik";

import { AppointmentsMenuContext, Site, useBcUuidContext, useShowError } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import { ProgressCircle, useTheme, useToast } from "@equiem/react-admin-ui";

import { EditAppointmentsMenu } from "../../components/EditAppointmentsMenu";
import { Modal } from "../../contexts/ModalContext";
import { withContexts } from "../../contexts/withContexts";
import {
  useUpdateVisitorAppointmentMutation,
  useVisitorAppointmentQuery,
  useVisitorAppointmentsQuery,
} from "../../generated/visitors-client";

import { EditAppointmentProvider } from "./context/EditAppointmentContext";
import { getValidationSchema } from "./utils/validationSchema";
import { AppointmentDeleteWidget } from "./widgets/AppointmentDeleteWidget";
import { AppointmentUpdatedWidget } from "./widgets/AppointmentUpdatedWidget";
import { Appointment } from "./Appointment";
import { initialValues as emptyValues } from "./initialValues";
import { mapAppointmentToForm, mapFormToDTO } from "./mappings";
import type { FormValues } from "./types";

interface Props {
  uuid: string;
}

const EditAppointmentBase: React.FC<Props> = ({ uuid }) => {
  const { i18n, t } = useTranslation();
  const theme = useTheme(true);
  const modal = useContext(Modal);
  const appointmentsMenu = useContext(AppointmentsMenuContext);
  const showError = useShowError();
  const { updateBcUuid } = useBcUuidContext();
  const [initialValues, setInitialValues] = useState<FormValues>(emptyValues);
  const {
    loading,
    data,
    refetch: refetchAppointment,
  } = useVisitorAppointmentQuery({ variables: { uuid }, fetchPolicy: "network-only" });

  const { refetch: refetchAppointments } = useVisitorAppointmentsQuery({ skip: true });
  const [updateVisitorAppointment] = useUpdateVisitorAppointmentMutation();
  const { timezone } = useContext(Site);
  const toast = useToast();

  useEffect(() => {
    if (data?.visitorAppointment != null) {
      setInitialValues(mapAppointmentToForm(data.visitorAppointment, i18n.language));
      updateBcUuid(uuid, data.visitorAppointment.title != null ? data.visitorAppointment.title : "");
    }
  }, [data]);

  useEffect(() => {
    appointmentsMenu.editAppointment(uuid);
  }, [uuid]);

  const handleCancel = () => {
    modal.open("CancelForm");
  };

  const showRecurringOptions = useMemo(() => {
    const appointments = { ...data }.visitorAppointment?.recurringInfo?.appointments ?? [];
    const sortedAppointments = [...appointments].sort((a, b) => a.startDate - b.startDate);
    return (
      sortedAppointments != null &&
      sortedAppointments.length !== 0 &&
      sortedAppointments.findIndex((x) => data?.visitorAppointment.uuid === x.uuid) !== sortedAppointments.length - 1
    );
  }, [data?.visitorAppointment.recurringInfo?.appointments]);

  const handleSubmit = async (values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
    if (values.recurringInfo != null && showRecurringOptions) {
      modal.open("AppointmentRecurringUpdate");
    } else {
      try {
        const result = await updateVisitorAppointment({
          variables: {
            uuid,
            input: mapFormToDTO(values),
          },
        });

        if (result.data?.updateVisitorAppointment != null && result.errors == null) {
          modal.open("AppointmentUpdated");
          toast.neutral(t("visitors.appointmentForm.changesHaveBeenSaved"));
          void refetchAppointments();
          void refetchAppointment();
        } else {
          throw new Error("Failed to update appointment.");
        }
      } catch (e: unknown) {
        showError(e);
      } finally {
        setSubmitting(false);
      }
    }
  };

  const validationSchema = useMemo(() => getValidationSchema(timezone, t), [timezone, t]);

  if (loading || (data?.visitorAppointment != null && initialValues === emptyValues)) {
    return (
      <div className="text-center pt-8">
        <ProgressCircle size="md" />
      </div>
    );
  }

  if (data?.visitorAppointment == null) {
    return <h2 className="text-center pt-8">{t("common.noContent")}</h2>;
  }

  return (
    <EditAppointmentProvider>
      <div className="container">
        <EditAppointmentsMenu />
        <div className="form-wrapper">
          <div style={{ background: theme.colors.white }} className="mb-4 form">
            <Formik
              initialValues={appointmentsMenu.cachedAppointmentForm ?? initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ isSubmitting, submitForm }) => (
                <Appointment
                  uuid={uuid}
                  isSubmitting={isSubmitting}
                  submitForm={submitForm}
                  handleCancel={handleCancel}
                />
              )}
            </Formik>
          </div>
        </div>
        <AppointmentUpdatedWidget />
        <AppointmentDeleteWidget showRecurringOptions={showRecurringOptions} />
      </div>
      <style jsx>
        {`
          .container {
            height: 100%;
            display: flex;
            flex-direction: column;
          }

          .form-wrapper,
          .form {
            height: 100%;
          }
        `}
      </style>
    </EditAppointmentProvider>
  );
};

export const EditAppointment = withContexts(EditAppointmentBase);
