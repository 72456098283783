import * as yup from "yup";

import type { TFunction } from "@equiem/localisation-eq1";

export const companyValidationSchema = (t: TFunction, withLevels = true) =>
  yup.object().shape({
    name: yup.string().required(t("settings.createCompany.companyNameRequired")),
    industry: yup.string().required(t("settings.createCompany.industryRequired")),
    attributes: yup.array().of(yup.string()),
    autoApproveRegistrations: yup.boolean(),
    validationValues: yup.array().of(yup.string()),
    levels: yup
      .array()
      .of(yup.string())
      .when([], {
        is: () => withLevels,
        then: () => yup.array().min(1, t("settings.levelsRequired")).required(t("settings.levelsRequired")),
      }),
  });
