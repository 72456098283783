import { createContext } from "react";
import type { FilterValue } from "./ComplexFilter.types";

export interface ComplexFilterContext {
  disabled: boolean;
  values: Record<string, FilterValue>;
  setValue: (name: string, value: FilterValue) => void;
  removeValue: (name: string) => void;
  language?: string;
  lastAddedFilter?: string;
}

export const ComplexFilterContext = createContext<ComplexFilterContext>({
  disabled: true,
  values: {},
  setValue: () => undefined,
  removeValue: () => undefined,
});
