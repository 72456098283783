import React, { useContext, useEffect } from "react";
import { BookingModal } from "../../operations/contexts/BookingModalContext";
import { useTranslation, formatters } from "@equiem/localisation-eq1";
import { BookingDiscountAddEditContext } from "./BookingDiscountAddEditProvider";
import { Modal, Alert, Tag } from "@equiem/react-admin-ui";
import { RiInformationLine } from "@equiem/react-admin-ui/icons";
import { ConfiguredActivityFeed } from "@equiem/lib";
import { discountCreated, discountDeleted, percentage, resources } from "../typeguards/activityRunTypes";
import { when } from "runtypes";

export const BookingDiscountActivityHistory: React.FC = () => {
  const { selectedDiscount: discount } = useContext(BookingDiscountAddEditContext);
  const modal = useContext(BookingModal);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    modal.setTitle(t("common.activity"));
  }, [modal, t]);

  if (discount == null) {
    return <></>;
  }

  const fieldNameMappings = {
    title: t("common.title"),
    startDate: t("common.startDate"),
    endDate: t("common.endDate"),
    startTime: t("common.startTime"),
    endTime: t("common.endTime"),
    daysBeforeBookingToApply: t("bookings.discounts.activity.numberOfDays"),
    availabilityDays: t("bookings.discounts.activity.availabilityDays"),
  };

  const customMappings = [
    when(resources, (a) => ({
      title: t("bookings.discounts.activity.resourcesChanged"),
      from: a.change.fromValues.map((from, i) => (
        <Tag className="m-2" key={i}>
          {from.bookableResource.name}
        </Tag>
      )),
      to: a.change.toValues.map((from, i) => (
        <Tag className="m-2" key={i}>
          {from.bookableResource.name}
        </Tag>
      )),
    })),
    when(percentage, (a) => ({
      title: t("bookings.discounts.activity.percentageChanged"),
      from: <Tag>{formatters.percentage(a.change.from.integer / 100, i18n.language)}</Tag>,
      to: <Tag>{formatters.percentage(a.change.to.integer / 100, i18n.language)}</Tag>,
    })),
    when(discountCreated, (_a) => ({
      title: t("bookings.discounts.activity.discountCreated"),
    })),
    when(discountDeleted, (_a) => ({
      title: t("bookings.discounts.activity.discountDeleted"),
    })),
  ];

  return (
    <>
      <Modal.Body>
        <ConfiguredActivityFeed
          entityUuid={discount.uuid}
          customMappings={customMappings}
          fieldNameMappings={fieldNameMappings}
        />
      </Modal.Body>
      <Modal.Footer>
        <Alert
          className="w-100"
          icon={<RiInformationLine size={20} />}
          variant="gray"
          message={t("bookings.discounts.activityHistoryLimitWarning")}
        />
      </Modal.Footer>
    </>
  );
};
