import type { IconType } from "react-icons";
import React from "react";
import { useTheme } from "../../contexts/Theme";
import { Text } from "../Text/Text";

interface Props {
  text: string;
  icon: IconType;
}

export const NoResults: React.FC<Props> = ({ text, ...props }) => {
  const theme = useTheme();
  const { colors } = theme;

  return (
    <>
      <div className="empty-view d-flex flex-column justify-content-center align-items-center">
        <div className="icon">
          <props.icon size={20} color={colors.grayscale[40]} />
        </div>
        <Text variant="text" size="extra-small" className="mt-5 text">
          {text}
        </Text>
      </div>
      <style jsx>{`
        .empty-view {
          width: 100%;
          height: calc(100% - 6rem);
          font-size: 14px;
          color: ${colors.grayscale[60]};
        }
        .icon {
          border-radius: 50%;
          height: 56px;
          width: 56px;
          line-height: 56px;
          background-color: ${colors.grayscale[5]};
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      `}</style>
    </>
  );
};
