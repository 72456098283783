import React, { useMemo } from "react";
import type { ComponentProps } from "react";

import { useTheme } from "../../contexts/Theme";
import { Text } from "../Text/Text";
import { RiCloseFill } from "react-icons/ri";

export type TagSize = "small" | "large";
export type TagVariant =
  | "default"
  | "dark"
  | "bright"
  | "positive"
  | "negative"
  | "primary"
  | "warning"
  | "primary-counter";

export interface TagDefaultProps {
  variant?: TagVariant;
  size?: TagSize;
  type?: "default";
  value?: never;
  limit?: never;
  closeButton?: boolean;
  onClose?: () => void;
}
export interface TagCounterProps {
  variant?: TagVariant;
  size?: TagSize;
  limit?: number;
  type?: "counter";
  value: number | string;
  onClose?: () => void;
}

// eslint-disable-next-line @typescript-eslint/no-type-alias
type Props = Omit<ComponentProps<"span">, "ref"> & (TagDefaultProps | TagCounterProps);

export const Tag = React.forwardRef<HTMLSpanElement, Props>(
  (
    {
      className = "",
      size = "small",
      variant = "default",
      type = "default",
      value,
      limit,
      children,
      onClose,
      ...props
    },
    ref,
  ) => {
    const { breakpoints, colors } = useTheme();

    const sizeVariant = size === "large" ? "default" : "small";
    const borderRadius = type === "default" ? "2px" : size === "large" ? "16px" : "8px";

    const closeButtonSize = size === "large" ? 18 : 15;
    const closeButtonClass = size === "large" ? "close-tag-large" : "close-tag";

    const closeButtonColor = useMemo(() => {
      let color: string;
      switch (variant) {
        case "default":
          color = colors.grayscale[60];
          break;
        case "positive":
          color = colors.status.positive.primary;
          break;
        case "negative":
          color = colors.status.danger.primary;
          break;
        case "warning":
          color = colors.status.warning.primary;
          break;
        case "bright":
          color = colors.dark;
          break;
        case "dark":
          color = colors.white;
          break;
        case "primary":
          color = colors.blue[60];
          break;
        default:
          color = colors.grayscale[60];
          break;
      }
      return color;
    }, [colors, variant]);

    const closeHoverColor = useMemo(() => {
      let color: string;
      switch (variant) {
        case "default":
          color = colors.grayscale[20];
          break;
        case "positive":
          color = colors.transparent.positive[10];
          break;
        case "negative":
          color = colors.transparent.danger[10];
          break;
        case "warning":
          color = colors.transparent.warning[10];
          break;
        case "bright":
          color = colors.transparent.black[10];
          break;
        case "dark":
          color = colors.transparent.white[10];
          break;
        case "primary":
          color = colors.blue[20];
          break;
        default:
          color = colors.grayscale[20];
          break;
      }
      return color;
    }, [colors, variant]);

    const formattedValue: string = useMemo(() => {
      if (typeof value === "number" && value != null && limit != null && value > 0 && limit > 0) {
        if (value.toString().length > limit) {
          return `${Array(limit + 1).join("9")}+`;
        }
      }

      return (value ?? 0).toString();
    }, [value, limit]);

    return (
      <span className={`tag tag-${variant} tag-${size} ${className}`} ref={ref} {...props}>
        <Text variant="label" size={sizeVariant} className="tag-label">
          {type === "counter" ? formattedValue : children}
          {onClose != null && (
            <span
              className={closeButtonClass}
              onClick={(e) => {
                e.stopPropagation();
                onClose();
              }}
            >
              <RiCloseFill size={closeButtonSize} color={closeButtonColor} />
            </span>
          )}
        </Text>
        <style jsx>{`
          .tag {
            display: inline-flex;
            align-items: center;
            white-space: wrap;
            vertical-align: middle;
            border-radius: ${borderRadius};
          }
          .tag :global(.tag-label) {
            display: inline-flex;
            align-items: center;
            white-space: wrap;
            vertical-align: middle;
          }
          @media screen and (min-width: ${breakpoints.md}px) {
            .tag {
              white-space: nowrap;
            }
            .tag :global(.tag-label) {
              white-space: nowrap;
            }
          }
          .tag-large {
            padding: 0.25rem 0.5rem;
          }
          .tag-small {
            padding: 0 0.25rem;
          }
          .tag-default {
            background: ${colors.transparent.black[5]};
            color: ${colors.grayscale[60]};
          }
          .tag-dark {
            background: ${colors.transparent.black[60]};
            color: ${colors.white};
          }
          .tag-bright {
            background: ${colors.transparent.white[80]};
            color: ${colors.dark};
          }
          .tag-primary-counter {
            background: ${colors.blue[60]};
            color: ${colors.white};
          }
          .tag-positive {
            background: ${colors.status.positive.accent};
            color: ${colors.status.positive.primary};
          }
          .tag-negative {
            background: ${colors.status.danger.accent};
            color: ${colors.status.danger.primary};
          }
          .tag-warning {
            background: ${colors.status.warning.accent};
            color: ${colors.status.warning.primary};
          }
          .tag-primary {
            background: ${colors.blue.transparent[10]};
            color: ${colors.blue[60]};
          }
          .close-tag {
            margin-left: 3px;
            padding: 0px 2px 0 2px;
            margin-right: -4px;
            display: flex;
            border-radius: 0 ${borderRadius} ${borderRadius} 0;
            cursor: pointer;
          }
          .close-tag-large {
            padding: 3px;
            margin: -4px -8px -4px 4px;
            display: flex;
            border-radius: 0 ${borderRadius} ${borderRadius} 0;
            cursor: pointer;
          }
          .close-tag-large:hover {
            background-color: ${closeHoverColor};
          }
          .close-tag:hover {
            background-color: ${closeHoverColor};
          }
        `}</style>
      </span>
    );
  },
);

Tag.displayName = "Tag";
