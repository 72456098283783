import type { PropsWithChildren } from "react";
import React, { useContext, useEffect, useState, useCallback } from "react";
import { Session, CurrentProfile, homeApplicationId, useSiteContext } from "@equiem/lib";

import {
  PageViewPageType,
  DataType,
  usePageViewMetricMutation,
  useMetricMutation,
} from "../../../generated/gateway-client";
import { ContentEndSensor } from "./ContentEndSensor";

interface Props {
  articleUuid: string;
  articleTitle: string;
}

const isNewRelicMonitor = () => /\bnewrelic-monitor=true\b/u.test(window.location.search);

export const ArticleMetrics: React.FC<PropsWithChildren<Props>> = ({ articleUuid, articleTitle, children }) => {
  const session = useContext(Session);
  const { profile } = useContext(CurrentProfile);
  const site = useSiteContext();

  const [sendPageViewMetric] = usePageViewMetricMutation();
  useEffect(() => {
    if (session.side !== "client" || isNewRelicMonitor()) {
      return;
    }

    sendPageViewMetric({
      variables: {
        pageViewInput: {
          timestamp: Date.now(),
          siteUuid: site.uuid,
          pageType: PageViewPageType.Article,
          pageUrl: window.location.href,
          pageTitle: articleTitle,
          crossReference: articleUuid,
        },
      },
    }).catch(console.error);

    // this should run exactly once per page
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [sendMetric] = useMetricMutation();
  const [hasSentContentEnd, setHasSentContentEnd] = useState(false);
  const onContentEnd = useCallback(() => {
    if (session.side !== "client" || isNewRelicMonitor() || hasSentContentEnd) {
      return;
    }

    sendMetric({
      variables: {
        input: {
          ME: "content_end",
          V: 9,
          TS: Date.now(),
          PI: site.uuid,
          UI: profile?.uuid,
          AI: homeApplicationId,
          TT: PageViewPageType.Article.toLowerCase(),
          TR: articleUuid,
          TD: articleTitle,
          DT: DataType.Count,
          VL: 1,
        },
      },
    }).catch(console.error);

    setHasSentContentEnd(true);
  }, [session.side, hasSentContentEnd]);

  return (
    <>
      {children}
      <ContentEndSensor onContentEnd={onContentEnd} />
    </>
  );
};
