import React, { useState } from "react";

import { useSiteContext } from "@equiem/lib";

import { AddFromCatalogue } from "../../../components/AddFromCatalogue";
import { useAddCompanyV2DestinationMutation, useDeleteCompanyMutation } from "../../../generated/settings-client";
import { usePagedCompanyList } from "../hooks/usePagedCompanyList";

export const AddCompanyCatalogueWidget: React.FC = () => {
  const site = useSiteContext();
  const [search, setSearch] = useState<string>();
  const { companies, loading, hasMoreData, fetchMore, refetch } = usePagedCompanyList({
    search,
    destinationUuid: site.uuid,
    excludeDestination: true,
    pageSize: 20,
    withFlexTenants: false,
  });
  const [addMutation] = useAddCompanyV2DestinationMutation();
  const [deleteMutation] = useDeleteCompanyMutation();

  const handleMore = () => {
    if (hasMoreData && !loading) {
      fetchMore();
    }
  };

  const handleAdd = async (uuid: string) => {
    await addMutation({
      variables: { destination: site.uuid, companies: [uuid] },
      refetchQueries: ["CompanyList"],
      onQueryUpdated: async (observableQuery) => {
        if (observableQuery.variables?.excludeDestination !== true) {
          return observableQuery.refetch();
        }

        return undefined;
      },
    });
  };

  const handleRemove = async (uuid: string) => {
    await deleteMutation({
      variables: { uuid, destinationUuid: site.uuid },
      refetchQueries: ["CompanyList"],
      onQueryUpdated: async (observableQuery) => {
        if (observableQuery.variables?.excludeDestination !== true) {
          return observableQuery.refetch();
        }

        return undefined;
      },
    });
  };

  const handleHide = () => {
    refetch().catch((e) => console.error(e));
  };

  return (
    <AddFromCatalogue
      type="company"
      loading={loading}
      items={companies.map((c) => ({
        uuid: c.uuid,
        name: c.name,
      }))}
      onSearch={setSearch}
      onFetchMore={handleMore}
      onAdd={handleAdd}
      onRemove={handleRemove}
      onHide={handleHide}
    />
  );
};
