import type { Dispatch, FC, SetStateAction } from "react";
import React from "react";

import { useTranslation } from "@equiem/localisation-eq1";
import { Button, Modal } from "@equiem/react-admin-ui";

interface Props {
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  action?: () => void | null;
}

export const ConfirmationWidget: FC<Props> = ({ showModal, setShowModal, action }) => {
  const { t } = useTranslation();

  return (
    <Modal.Dialog
      title={t("common.areYouSure")}
      show={showModal}
      onHide={() => {
        /* istanbul ignore next */
        setShowModal(false);
      }}
      hideOnEsc={true}
      hideOnClick={false}
      size={"sm"}
    >
      <Modal.Header closeButton={true} noBorder={true} />
      <Modal.Body>
        <span>{t("visitors.settings.permissionSettingsCancelBody")}</span>
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex">
          <Button size="sm" onClick={() => setShowModal(false)} className="action-cancel mr-3" variant="ghost">
            {t("visitors.appointmentForm.cancelNo")}
          </Button>
          <Button
            onClick={() => {
              action?.();
              setShowModal(false);
            }}
            size="sm"
            variant="danger"
            className="action-submit"
          >
            {t("common.yes")}
          </Button>
        </div>
      </Modal.Footer>
    </Modal.Dialog>
  );
};
