import type { ReactNode } from "react";
import React, { useContext, useMemo } from "react";

import { AppointmentsMenuContext } from "@equiem/lib";

import { Modal } from "../../../contexts/ModalContext";

export interface EditAppointmentContext {
  isEditView: boolean;
  onDeleteAppointment: (uuid: string) => void;
}

export const EditAppointmentContext = React.createContext<EditAppointmentContext>({
  isEditView: false,
  /* istanbul ignore next */
  onDeleteAppointment: () => undefined,
});

export const EditAppointmentProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const modal = useContext(Modal);
  const { isEditAppointment, isNewAppointment, isPreview } = useContext(AppointmentsMenuContext);

  const onDeleteAppointment = (uuid: string) => {
    modal.open("DeleteAppointment", uuid);
  };

  const isEditView = useMemo(
    () => (!isPreview && isEditAppointment) || isNewAppointment,
    [isEditAppointment, isNewAppointment, isPreview],
  );

  return (
    <EditAppointmentContext.Provider
      value={{
        isEditView,
        onDeleteAppointment,
      }}
    >
      {children}
    </EditAppointmentContext.Provider>
  );
};
