import { useToast } from "@equiem/react-admin-ui";

import {
  BookableResourceStatus,
  useBookableResourceEditLazyQuery,
  useUpdateResourceMutation,
} from "../generated/gateway-client";
import { toResourceFormValues, toResourceEditInput } from "../lib/resourceEditForm";
import { useTranslation } from "@equiem/localisation-eq1";

interface Params {
  uuid: string;
  name: string;
  onSuccess: () => unknown;
}

export const useUpdateResourceStatus = ({ uuid, name, onSuccess }: Params) => {
  const toast = useToast();
  const { t } = useTranslation();
  const [fetchResourceDetails, { loading: detailsLoading }] = useBookableResourceEditLazyQuery({
    variables: { uuid },
    fetchPolicy: "network-only",
  });
  const [updateResource, { loading: updateLoading }] = useUpdateResourceMutation();

  return async (newStatus: BookableResourceStatus.Published | BookableResourceStatus.Draft) => {
    try {
      if (detailsLoading || updateLoading) {
        return;
      }

      const resource = await fetchResourceDetails();
      if (resource.error != null || resource.data == null) {
        console.error(resource.error);
        throw new Error("failed to load resource");
      }

      const values = toResourceFormValues(resource.data.bookableResource);
      const input = toResourceEditInput({ ...values, status: newStatus });

      const result = await updateResource({ variables: { uuid, input } });
      if (result.errors != null) {
        console.error(result.errors);
        throw new Error("failed to update resource");
      }

      if (newStatus === BookableResourceStatus.Published) {
        toast.positive(t("bookings.operations.hasBeenPublished", { name }));
      } else {
        toast.neutral(t("bookings.operations.hasBeenSavedToDraft", { name }));
      }

      onSuccess();
    } catch (e: unknown) {
      console.error(e);
      toast.negative(`Failed to ${newStatus === BookableResourceStatus.Published ? "publish" : "draft"} ${name}.`);
    }
  };
};
