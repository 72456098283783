import type { ComponentProps } from "react";
import React, { useMemo, useState, useEffect, forwardRef, useRef } from "react";
import { useTheme } from "../../../contexts/Theme";
import { DropdownContainer } from "../../Dropdown/DropdownContainer";
import { AdminButton as Button } from "../../Button/AdminButton";
import { Tag } from "../../Tags/Tags";
import * as FormInputGroup from "../FormInputGroup";
import { FormInput } from "../FormInput/FormInput";
import { RiArrowDownSLine, RiArrowUpSLine, RiCloseCircleLine, RiSearchLine } from "react-icons/ri";
import { groupBy, includes, sortBy, uniq } from "lodash";
import { SelectItem } from "../../SelectItem/SelectItem";
import { useAsRef } from "../../../util/useAsRef";
import { optionLabel, optionSearchText, type Option } from "./model/Option";
import type { OnChange } from "./model/OnChange";
import { onChangeObject } from "./model/OnChange";
import { FormFacadeButtonGhost } from "./FormFacadeButtonGhost";
import { useTranslation } from "@equiem/localisation-eq1";
import type { DropdownMobileView } from "../../Dropdown/DropdownMenu/DropdownMenu";
import { FormFacadeSimple } from "./FormFacadeSimple";
import { ProgressCircle } from "../../ProgressCircle/ProgressCircle";
import { useDebounced } from "../../../hooks";
import { FormFacadeSummary } from "./FormFacadeSummary";
import { FormGroupContext } from "../../../contexts/FormGroupContext";
import { useInputBorderCss } from "../useInputBorderCss";
import { useDropdownChevronBackground } from "../../../hooks/useDropdownChevronBackground";
import { stringNotEmpty } from "../../../util/stringNotEmpty";

const NO_GROUP = "__NO_GROUP__";

const MultiSelectInput = forwardRef<
  HTMLDivElement,
  {
    name?: string;
    className?: string;
    localValue: string[];
    setLocalValue: React.Dispatch<React.SetStateAction<string[]>>;
    onChange: (event: OnChange) => void;
    options: Option[];
    danger?: boolean;
    size?: "small" | "large";
    placeholder?: string | React.ReactNode | null;
    emptyLabel?: React.ReactNode;
    variant?: "initial" | "wrap";
    noBorder?: boolean;
    disabled?: boolean;
    maxHeight?: string;
  }
>(
  (
    {
      name,
      className,
      localValue,
      options,
      danger = false,
      setLocalValue,
      onChange,
      size,
      placeholder,
      emptyLabel,
      variant,
      noBorder = false,
      disabled = false,
      maxHeight = "220px",
    },
    ref,
  ) => {
    const onChangeRef = useAsRef(onChange);
    const { colors, focusOutline, spacers } = useTheme();
    const borderCss = useInputBorderCss({
      showBorder: !noBorder,
    });
    const dropdownBg = useDropdownChevronBackground({
      color: colors.grayscale[100],
    });

    const contentDanger = danger
      ? 'url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0nMTUnIGhlaWdodD0nMTUnIHZpZXdCb3g9JzAgMCAxNCAxNCcgZmlsbD0nbm9uZScgeG1sbnM9J2h0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnJz48cGF0aCBkPSdNNy4wMDAxNiAxMy42NjY2QzMuMzE4MTYgMTMuNjY2NiAwLjMzMzQ5NiAxMC42ODE5IDAuMzMzNDk2IDYuOTk5OTJDMC4zMzM0OTYgMy4zMTc5MiAzLjMxODE2IDAuMzMzMjUyIDcuMDAwMTYgMC4zMzMyNTJDMTAuNjgyMiAwLjMzMzI1MiAxMy42NjY4IDMuMzE3OTIgMTMuNjY2OCA2Ljk5OTkyQzEzLjY2NjggMTAuNjgxOSAxMC42ODIyIDEzLjY2NjYgNy4wMDAxNiAxMy42NjY2Wk03LjAwMDE2IDEyLjMzMzNDOC40MTQ2NSAxMi4zMzMzIDkuNzcxMiAxMS43NzEzIDEwLjc3MTQgMTAuNzcxMkMxMS43NzE2IDkuNzcwOTYgMTIuMzMzNSA4LjQxNDQxIDEyLjMzMzUgNi45OTk5MkMxMi4zMzM1IDUuNTg1NDMgMTEuNzcxNiA0LjIyODg4IDEwLjc3MTQgMy4yMjg2OEM5Ljc3MTIgMi4yMjg0OSA4LjQxNDY1IDEuNjY2NTkgNy4wMDAxNiAxLjY2NjU5QzUuNTg1NjcgMS42NjY1OSA0LjIyOTEyIDIuMjI4NDkgMy4yMjg5MyAzLjIyODY4QzIuMjI4NzMgNC4yMjg4OCAxLjY2NjgzIDUuNTg1NDMgMS42NjY4MyA2Ljk5OTkyQzEuNjY2ODMgOC40MTQ0MSAyLjIyODczIDkuNzcwOTYgMy4yMjg5MyAxMC43NzEyQzQuMjI5MTIgMTEuNzcxMyA1LjU4NTY3IDEyLjMzMzMgNy4wMDAxNiAxMi4zMzMzWk02LjMzMzUgOC45OTk5Mkg3LjY2NjgzVjEwLjMzMzNINi4zMzM1VjguOTk5OTJaTTYuMzMzNSAzLjY2NjU4TDcuNjY2ODMgMy42NjY1OFY3LjY2NjU4SDYuMzMzNVYzLjY2NjU4WicgZmlsbD0nI0U2MDAwRScvPjwvc3ZnPg==")'
      : "";

    const emptyState =
      emptyLabel != null ? <>{emptyLabel}</> : <span className="mt-1 placeholder-text">{placeholder}</span>;

    return (
      <>
        <div
          ref={ref}
          tabIndex={0}
          className={`form-multi-select ${disabled ? "disabled" : ""} input-custom d-flex ${className ?? ""} ${
            borderCss.className
          } ${noBorder ? "border-none" : ""}`}
        >
          <div className="tags-container d-flex">
            {localValue.length > 0 ? (
              localValue.map((val) => {
                const valOption = options.find((opt) => opt.value === val);
                const optionDisabled = disabled || valOption?.disabled === true;
                return (
                  <div tabIndex={1} key={val} className="tag-wrapper">
                    <Tag
                      size={size}
                      onClose={
                        !optionDisabled
                          ? () => {
                              const newVal = localValue.filter((p) => p !== val);
                              setLocalValue(newVal);
                              onChangeRef.current(onChangeObject(name, newVal));
                            }
                          : undefined
                      }
                    >
                      {optionLabel(valOption, true)}
                    </Tag>
                  </div>
                );
              })
            ) : (
              <>{emptyState}</>
            )}
          </div>
        </div>
        <style jsx>{`
          .form-multi-select {
            padding: 0.5rem;
            line-height: ${size === "small" ? "14px" : "19px"} !important;
            appearance: none;
            cursor: pointer;
            float: none;
            outline: none;
            width: 100%;
            min-height: ${size === "small" ? "34px" : "42px"};
            font-size: ${size === "small" ? "14px" : "16px"} !important;
            font-weight: 400;
            color: ${colors.muted1};
            position: relative;
            background: white;
            -webkit-touch-callout: none;
            -webkit-user-select: none;
            -khtml-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            max-height: ${maxHeight};
            overflow: auto;
          }
          .tags-container {
            gap: ${spacers.s2};
            flex-wrap: ${variant};
            overflow: scroll;
            padding-right: 1.5rem;
            -ms-overflow-style: none;
            scrollbar-width: none;
            width: 100%;
          }
          .tags-container::-webkit-scrollbar {
            display: none;
          }
          .input-custom:focus {
            border: 1px solid ${danger ? colors.danger : colors.blue[60]} !important;
            box-shadow: 0px 0px 0px ${4}px ${danger ? focusOutline.colors.error : colors.blue[10]} !important;
          }
          .tags-container:before {
            position: absolute;
            right: 6px;
            z-index: 1;
            top: 0;
            bottom: 0;
            height: 1rem;
            margin: auto 0;
            background-position: center;
            float: right;
            content: ${contentDanger};
          }
          .tags-container:after,
          .border-none .tags-container:hover:after {
            content: "";
            position: absolute;
            top: 2px;
            right: 2px;
            width: 1.5rem;
            height: 85%;
            pointer-events: none;
            background: ${dropdownBg} center center no-repeat;
            display: ${disabled ? "none" : "block"};
          }
          .border-none .tags-container:after {
            background: none;
          }
          .error-tag {
            margin-left: auto;
            z-index: 4;
          }
          .disabled {
            background-color: ${colors.grayscale[3]};
          }
        `}</style>
        {borderCss.styles}
      </>
    );
  },
);

MultiSelectInput.displayName = "MultiSelectInput";

const ItemGroup: React.FC<{
  options: Option[];
  name: string;
  selected: string[];
  expandAll?: boolean;
  onChangeOne: (arg: { value: string; checked: boolean }) => void;
  onChangeAll: (arg: { checked: boolean }) => void;
}> = ({ options, name, selected, expandAll = false, onChangeOne, onChangeAll }) => {
  const ungrouped = name === NO_GROUP;
  const [open, setOpen] = useState(ungrouped || options.some((o) => selected.includes(o.value)));
  const enabledOptions = useMemo(() => options.filter((o) => o.disabled !== true), [options]);
  const enabled = useMemo(() => enabledOptions.length > 0, [enabledOptions]);
  const checked = useMemo(
    () => enabled && !enabledOptions.some((o) => !selected.includes(o.value)),
    [enabled, enabledOptions, selected],
  );
  const indeterminate = useMemo(
    () => !checked && enabledOptions.some((o) => selected.includes(o.value)),
    [checked, enabledOptions, selected],
  );

  useEffect(() => {
    setOpen(expandAll || ungrouped || options.some((o) => selected.includes(o.value)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expandAll]);

  const items = options.map((option) => {
    const isSelected = selected.find((val) => val === option.value) != null;
    return (
      <SelectItem
        role="option"
        indeterminate={option.indeterminate ?? false}
        key={option.value}
        selected={isSelected}
        label={optionLabel(option)}
        disabled={option.disabled}
        className={ungrouped ? "" : "ml-6"}
        onChange={(e) => {
          onChangeOne({ value: option.value, checked: e.target.checked });
        }}
      />
    );
  });

  return (
    <>
      {name === NO_GROUP ? (
        items
      ) : (
        <div role="group" aria-label={name}>
          <SelectItem
            role="presentation"
            selected={checked}
            indeterminate={indeterminate}
            disabled={!enabled}
            itemSuffix={
              <span style={{ pointerEvents: "none" }}>
                {open ? <RiArrowUpSLine size={16} /> : <RiArrowDownSLine size={16} />}
              </span>
            }
            onClickContainer={(e) => {
              e.stopPropagation();
              e.preventDefault();
              setOpen(!open);
            }}
            onChange={(e) => {
              onChangeAll({ checked: e.target.checked });
              if (e.target.checked) {
                setOpen(true);
              }
            }}
            label={name}
          />
          {open && items}
        </div>
      )}
    </>
  );
};

export interface Props extends Omit<ComponentProps<"select">, "onChange" | "size" | "placeholder"> {
  onChange: (event: OnChange) => void;
  options: Option[];
  variant?: "initial" | "wrap";
  defaultValue?: string[];
  value?: string[];
  size?: "small" | "large";
  disabled?: boolean;
  facade?: "input" | "input-no-border" | "ghost" | "simple" | "summary";
  enableSelectAll?: boolean;
  searchPlaceholder?: string;
  searchLoading?: boolean;
  hasMoreSearchResults?: boolean;
  searchCb?: (keyword: string) => Promise<void>;
  onClose?: (selected: string[]) => void;
  withSearch?: boolean;
  emptyLabel?: React.ReactNode;
  title?: string;
  mobileView?: DropdownMobileView;
  maxHeight?: string;
  placeholder?: string | React.ReactNode | null;
  showChrome?: boolean;
}
// eslint-disable-next-line complexity
export const FormMultiSelect: React.FC<Props> = ({
  className,
  placeholder,
  options,
  value,
  defaultValue,
  onChange,
  onClose = () => undefined,
  disabled = false,
  size = "large",
  variant = "initial",
  facade = "input",
  enableSelectAll = false,
  searchPlaceholder,
  withSearch = true,
  searchLoading = false,
  hasMoreSearchResults = false,
  searchCb,
  name,
  emptyLabel,
  title,
  mobileView,
  maxHeight,
  showChrome,
}) => {
  const { t } = useTranslation();
  const onChangeRef = useAsRef(onChange);
  const { colors } = useTheme();
  const [localValue, setLocalValue] = useState<string[]>(defaultValue ?? value ?? []);
  const [width, setWidth] = useState<number>();

  const FILTER_DEBOUNCE_MS = 500;
  const [filterText, setFilterText] = useState<string | null>(null);
  const debouncedFilter = useDebounced(filterText, FILTER_DEBOUNCE_MS);
  const [searching, setSearching] = useState(false);
  // Handle the search callback when exist.
  useEffect(() => {
    searchCb?.(debouncedFilter ?? "").finally(() => setSearching(false));
  }, [debouncedFilter, searchCb]);

  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (ref.current == null) {
      return;
    }
    const resizeObserver = new ResizeObserver(() => {
      const newWidth = ref.current?.getBoundingClientRect().width;
      setWidth(newWidth);
    });
    resizeObserver.observe(ref.current);

    // eslint-disable-next-line consistent-return
    return () => resizeObserver.disconnect();
  }, []);

  useEffect(() => {
    if (
      value == null ||
      (localValue.length === value.length && localValue.sort().toString() === value.sort().toString())
    ) {
      return;
    }

    setLocalValue(value);
  }, [localValue, value]);

  const filteredOptions = useMemo(
    () =>
      options.filter((opt) => {
        return filterText != null && filterText !== ""
          ? includes(optionSearchText(opt).toLowerCase(), filterText.toLowerCase())
          : true;
      }),
    [filterText, options],
  );

  const allSelected = useMemo(() => {
    const optionsEnabled = filteredOptions.filter((v) => v.disabled !== true);
    if (optionsEnabled.length === 0) {
      return false;
    }

    const localValueEnabled = localValue.filter((v) => optionsEnabled.some((o) => o.value === v));

    return optionsEnabled.length === localValueEnabled.length;
  }, [filteredOptions, localValue]);
  const noErrorFormGroupContext = useMemo(() => ({ hasError: false, error: undefined }), []);

  const groupedFilteredOptions = useMemo(() => {
    return groupBy(
      sortBy(filteredOptions, (o) => o.group?.weight ?? -1),
      (o) => o.group?.name ?? NO_GROUP,
    );
  }, [filteredOptions]);

  return (
    <div className={`${disabled ? "disabled" : ""} multi-select-dropdown`}>
      <DropdownContainer
        placement="bottom-start"
        trigger={
          <>
            {facade === "simple" && (
              <FormFacadeSimple
                ref={ref}
                localValue={localValue}
                options={options}
                disabled={disabled}
                emptyLabel={emptyLabel ?? placeholder}
                className={className}
              />
            )}
            {facade === "summary" && (
              <FormFacadeSummary
                ref={ref}
                localValue={localValue}
                options={options}
                disabled={disabled}
                emptyLabel={emptyLabel ?? placeholder}
                className={className}
                showChrome={showChrome}
              />
            )}
            {(facade === "input" || facade === "input-no-border") && (
              <MultiSelectInput
                noBorder={facade === "input-no-border"}
                {...{
                  ref,
                  className,
                  localValue,
                  options,
                  onChange,
                  setLocalValue,
                  size,
                  placeholder,
                  variant,
                  disabled,
                  name,
                  maxHeight,
                  emptyLabel,
                }}
              />
            )}
            {facade === "ghost" && (
              <FormFacadeButtonGhost placeholder={placeholder} className={className} size={size} />
            )}
          </>
        }
        width={width}
        mobileView={mobileView}
        title={title}
        onClose={() => onClose(localValue)}
      >
        {withSearch && (
          <FormGroupContext.Provider value={noErrorFormGroupContext}>
            <FormInputGroup.Group className="mr-3 ml-3 mt-2 mb-4">
              <FormInputGroup.Prefix>
                {searching || searchLoading ? (
                  <ProgressCircle size={15} style={{ color: colors.muted1 }} />
                ) : (
                  <RiSearchLine color={colors.muted1} size={15} />
                )}
              </FormInputGroup.Prefix>
              <FormInput
                placeholder={searchPlaceholder ?? (t("common.searchAttributes") as string)}
                autoFocus
                onChange={(e) => {
                  searchCb != null && setSearching(true);
                  setFilterText(e.target.value);
                }}
                className="search-input"
                style={{ userSelect: "none" }}
                value={filterText ?? ""}
              />
              <FormInputGroup.Suffix>
                <RiCloseCircleLine
                  style={{
                    display: filterText == null || filterText.length === 0 ? "none" : "block",
                    cursor: "pointer",
                  }}
                  onClick={() => setFilterText("")}
                  color={colors.muted1}
                  size={15}
                />
              </FormInputGroup.Suffix>
            </FormInputGroup.Group>
          </FormGroupContext.Provider>
        )}
        {enableSelectAll && filteredOptions.length > 0 && (
          <div>
            <Button
              className="mb-2"
              variant="ghost"
              size="sm"
              onClick={() => {
                let newValue;

                if (allSelected) {
                  // Retain all selected options which are disabled.
                  newValue = options.flatMap((o) =>
                    o.disabled === true && localValue.includes(o.value) ? [o.value] : [],
                  );
                } else {
                  newValue = uniq([
                    // Retain existing selected values.
                    ...localValue,
                    // Append all visible options which are not disabled.
                    ...filteredOptions.flatMap((o) => (o.disabled === true ? [] : [o.value])),
                  ]);
                }

                setLocalValue(newValue);
                onChangeRef.current(onChangeObject(name, newValue));
              }}
            >
              {allSelected ? t("common.deselectAll") : t("common.selectAll")}
            </Button>
          </div>
        )}
        {Object.entries(groupedFilteredOptions).map(([groupName, groupOptions]) => (
          <ItemGroup
            key={groupName}
            options={groupOptions}
            name={groupName}
            selected={localValue}
            expandAll={stringNotEmpty(filterText)}
            onChangeOne={(e) => {
              const newVal = e.checked ? [...localValue, e.value] : localValue.filter((p) => p !== e.value);
              setLocalValue(newVal);
              onChangeRef.current(onChangeObject(name, newVal));
            }}
            onChangeAll={({ checked }) => {
              const enabledOptions = groupOptions.filter((o) => o.disabled !== true);
              const newVal = checked
                ? uniq([...localValue, ...enabledOptions.map((o) => o.value)])
                : localValue.filter((p) => !enabledOptions.some((o) => o.value === p));
              setLocalValue(newVal);
              onChangeRef.current(onChangeObject(name, newVal));
            }}
          />
        ))}
        {!searching && !searchLoading && filteredOptions.length === 0 && (
          <div className="text-center my-4">{t("common.noOptions")}</div>
        )}
        {withSearch && !searching && !searchLoading && hasMoreSearchResults && filteredOptions.length > 0 && (
          <div className="text-center my-4">{t("common.refineYourSearch")}</div>
        )}
      </DropdownContainer>
      <style jsx>{`
        input {
          cursor: pointer;
        }
        label {
          cursor: pointer;
        }
        .disabled {
          pointer-events: none;
          opacity: 0.9 !important;
        }
        :global(.search-input) {
          padding: 0px !important;
        }
      `}</style>
    </div>
  );
};
