import React from "react";
import Link from "next/link";

import { Tag, Text, useTheme } from "@equiem/react-admin-ui";

import { StatusTypeIcon } from "../../../components/StatusTypeIcon";
import type { RequestsQuery } from "../../../generated/requests-client";

interface Props {
  request: NonNullable<RequestsQuery["reqMgt"]["requests"]["edges"][number]["node"]>;
}

export const RequestItem: React.FC<Props> = ({ request }) => {
  const { colors } = useTheme();

  return (
    <Link href={`/request-management/request/${request.uuid}`} passHref legacyBehavior>
      <a className="item">
        <div className="head">
          <Text variant="text" size="extra-small" weight="medium" className="m-0">
            {request.reference}
          </Text>
          <Tag>
            <div className="mr-1 d-flex">
              <StatusTypeIcon type={request.status.type} size={12} />
            </div>
            {request.status.name}
          </Tag>
        </div>
        <div className="body">
          <Text variant="text" size="extra-small" className="m-0">
            {request.description}
          </Text>
        </div>
        <style jsx>{`
          .item {
            display: flex;
            flex-direction: column;
            padding: 8px;
            gap: 4px;
            border: 1px solid ${colors.grayscale[10]};
            cursor: pointer;
            text-decoration: none;
          }

          .item:first-child {
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;
          }

          .item:last-child {
            border-bottom-left-radius: 4px;
            border-bottom-right-radius: 4px;
          }

          .item + .item {
            border-top: none;
          }

          .item:hover {
            box-shadow: inset 0px 0px 0px 1px ${colors.blue[60]};
          }

          .head {
            display: flex;
            justify-content: space-between;
            align-items: center;
          }

          .body {
            color: ${colors.grayscale[60]};
          }

          .body :global(p) {
            text-overflow: ellipsis;
            overflow: hidden;
            max-height: 2rem;
            /* this legacy thing is only working solution for ellipsis */
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }
        `}</style>
      </a>
    </Link>
  );
};
