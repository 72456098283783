import React from "react";

import { stringNotEmpty } from "@equiem/lib";
import { Material, useTheme } from "@equiem/react-admin-ui";

interface P {
  name: string;
  icon?: string;
  subcategory?: string;
  disabled?: boolean;
}
export const CategoryDisplay: React.FC<P> = ({ name, icon, subcategory, disabled = false }) => {
  const theme = useTheme();

  return (
    <>
      <div className={`d-flex justify-align-center align-items-center ${disabled ? "disabled" : ""}`}>
        {icon != null && (
          <Material.Icon
            name={icon}
            size="sm"
            color={disabled ? theme.colors.grayscale["20"] : undefined}
            className="mr-2"
          />
        )}
        {name}
        {stringNotEmpty(subcategory) && <>{`, ${subcategory}`}</>}
      </div>
      <style jsx>{`
        div {
          color: ${theme.colors.grayscale["60"]};
        }
        .disabled {
          color: ${theme.colors.grayscale["40"]};
        }
      `}</style>
    </>
  );
};
