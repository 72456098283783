import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import type { FormikHelpers, FormikProps } from "formik";
import { Formik } from "formik";

import { useServerMessageTranslation, useTranslation } from "@equiem/localisation-eq1";
import { Button, Modal, useConfirmer, useIsMobileWidth, useTheme, useToast } from "@equiem/react-admin-ui";

import { Modal as ModalContext } from "../../../contexts/ModalContext";
import type { Building } from "../../../generated/settings-client";
import { BuildingForm } from "../components/BuildingForm";
import { useBuildingData } from "../hooks/useBuildingData";
import type { BuildingFormValues } from "../types";
import { defaultAddressInfo } from "../utils/address";
import { getValidationSchema } from "../utils/validationSchema";

export const BuildingDetailsWidget: React.FC = () => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const modal = useContext(ModalContext);
  const { spacers, colors } = useTheme();
  const toast = useToast();
  const { withConfirmation } = useConfirmer();
  const { createOrUpdateBuilding, destinationBuildings } = useBuildingData();
  const formInnerRef = useRef<FormikProps<BuildingFormValues>>(null);
  const isUpdateModal = modal.id != null;
  const isMobile = useIsMobileWidth();
  const { tServer } = useServerMessageTranslation();

  const selectedBuilding = useMemo(() => {
    return destinationBuildings?.find((building) => building?.uuid === modal.id) as Building;
  }, [destinationBuildings, modal.id]);

  useEffect(() => {
    if (modal.activeModal === "BuildingDetails") {
      setShowModal(true);
    } else {
      setShowModal(false);
    }
  }, [modal.activeModal]);

  const initialValues = useMemo<BuildingFormValues>(() => {
    if (selectedBuilding != null && isUpdateModal) {
      return {
        uuid: selectedBuilding.uuid,
        name: selectedBuilding.name,
        area: selectedBuilding.area,
        occupants: selectedBuilding.occupants,
        units: selectedBuilding.units as BuildingFormValues["units"],
        addressStatus: selectedBuilding.hasBuildingAddress ? "enabled" : "disabled",
        address: selectedBuilding.hasBuildingAddress
          ? {
              address: selectedBuilding.address,
              city: selectedBuilding.city,
              country: selectedBuilding.country,
              latitude: selectedBuilding.latitude,
              longitude: selectedBuilding.longitude,
              postcode: selectedBuilding.postcode,
              state: selectedBuilding.state,
              streetName: selectedBuilding.streetName,
              timezone: selectedBuilding.timezone,
            }
          : defaultAddressInfo,
      };
    }

    return {
      name: "",
      addressStatus: "disabled",
      units: "square feet",
      address: defaultAddressInfo,
    };
  }, [selectedBuilding, isUpdateModal]);

  const onClose = useCallback(() => {
    setShowModal(false);
    modal.close();
  }, [setShowModal, modal]);

  const onCloseModal = useCallback(() => {
    formInnerRef.current?.dirty === true
      ? withConfirmation({
          title: t("common.areYouSure"),
          message: t("settings.build.cancelMessage"),
          confirmButtonText: t("common.yesCancel"),
          cancelButtonText: t("home.widgets.cancelNo"),
          confirmButtonVariant: "danger",
          onConfirm: onClose,
        })()
      : onClose();
  }, [withConfirmation, t, onClose, formInnerRef]);

  const handleSubmit = async (values: BuildingFormValues, { setSubmitting }: FormikHelpers<BuildingFormValues>) => {
    try {
      setSubmitting(true);
      const result = await createOrUpdateBuilding(values);

      if (result.data?.createOrUpdateBuilding.__typename === "BuildingSyncFailure") {
        const reason =
          result.data.createOrUpdateBuilding.localisedReason != null
            ? tServer(result.data.createOrUpdateBuilding.localisedReason)
            : result.data.createOrUpdateBuilding.reason;

        toast.negative(reason);
      }
    } finally {
      setSubmitting(false);
      setShowModal(false);
      modal.close();
    }
  };

  return (
    <>
      <Modal.Dialog
        title={isUpdateModal ? t("settings.build.editBuilding") : t("common.createNew")}
        show={showModal}
        sideModal={true}
        onHide={onCloseModal}
        hideOnEsc={true}
        hideOnClick={true}
        size="md"
      >
        <Modal.Header
          intro={!isMobile ? (isUpdateModal ? selectedBuilding.name : t("settings.build.addNewBuilding")) : null}
          closeButton
          noBorder={!isMobile}
          onClose={onCloseModal}
          supportsMobile
        />
        <Formik
          initialValues={initialValues}
          innerRef={formInnerRef}
          validationSchema={getValidationSchema(t)}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({ submitForm, isSubmitting, isValid }) => (
            <>
              <Modal.Body noPadding>
                <div className="content">
                  <BuildingForm />
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="ghost" className="mr-4" onClick={onCloseModal}>
                  {t("common.cancel")}
                </Button>
                <Button
                  disabled={isSubmitting || !isValid}
                  type="submit"
                  variant="primary"
                  onClick={() => {
                    void submitForm();
                  }}
                >
                  {isUpdateModal ? t("common.saveChanges") : t("settings.build.createBuilding")}
                </Button>
              </Modal.Footer>
            </>
          )}
        </Formik>
      </Modal.Dialog>

      <style jsx>{`
        .title {
          font-weight: 400;
          font-size: 18px;
          line-height: 28px;
        }
        .content {
          padding: 0 ${spacers.s6} 0;
        }
        hr {
          border: none;
          border-top: 1px solid ${colors.border};
          margin-bottom: 16px;
        }
      `}</style>
    </>
  );
};
