import { useContext, useEffect } from "react";

import type { useFloating, UseFloatingReturn } from "@floating-ui/react-dom";
import { FocusTrapContext } from "../contexts/FocusTrapContext";

export const useFloatingHideListeners = ({
  onHide,
  floating,
}: {
  onHide: () => void;
  floating: ReturnType<typeof useFloating>;
}) => {
  const { ref: focusRef } = useContext(FocusTrapContext);

  useEffect(() => {
    const onClick = (e: MouseEvent) => {
      const target = e.target as unknown as Node;
      const f = floating as UseFloatingReturn<HTMLElement>;

      // ignore clicks on detached elements
      if (!target.isConnected) {
        return;
      }

      if (
        f.refs.reference.current !== target &&
        f.refs.reference.current?.contains(target) === false &&
        f.refs.floating.current?.contains(target) === false
      ) {
        onHide();
      }
    };

    const onEsc = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        onHide();
      }
    };

    const focusEl = focusRef.current;

    if (focusEl != null) {
      focusEl.addEventListener("click", onClick);
      focusEl.addEventListener("keyup", onEsc);
    }

    window.addEventListener("click", onClick);
    window.addEventListener("keyup", onEsc);

    return () => {
      window.removeEventListener("click", onClick);
      window.removeEventListener("keyup", onEsc);

      if (focusEl != null) {
        focusEl.removeEventListener("click", onClick);
        focusEl.removeEventListener("keyup", onEsc);
      }
    };
  }, [onHide, floating, focusRef]);
};
