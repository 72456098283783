import { useEffect, useState } from "react";

import type { CardFragmentFragment as Card } from "../../../generated/settings-client";
import { useDeleteCardMutation as useBaseDeleteCardMutation } from "../../../generated/settings-client";

type HookReturn = ReturnType<typeof useBaseDeleteCardMutation>;
type MutationFn = HookReturn[0];
/**
 * A better version of the `useSetDefaultCardMutation` hook generated by
 * codegen, working around some cache delay issues.
 *
 * Doesn't unset the `loading` flag until the card list is *actually* updated in
 * the list.
 */
export const useDeleteCardMutation = (cards: Card[]): HookReturn => {
  const [mutation, { loading, ...rest }] = useBaseDeleteCardMutation();

  const [deletedCard, setDeletedCard] = useState<string | null>(null);
  const waitingForCacheUpdate = deletedCard != null && cards.some((card) => card.uuid === deletedCard);

  useEffect(() => {
    if (!waitingForCacheUpdate && deletedCard != null) {
      setDeletedCard(null);
    }
  }, [deletedCard, waitingForCacheUpdate]);

  const wrappedMutation: MutationFn = async (options, ...args) => {
    try {
      setDeletedCard(options?.variables?.uuid ?? null);

      const result = await mutation(options, ...args);

      return result;
    } catch (e: unknown) {
      setDeletedCard(null);
      throw e;
    }
  };

  return [wrappedMutation, { ...rest, loading: loading || waitingForCacheUpdate }];
};
