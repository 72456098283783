import {
  BookableResourceBookingApprovalType,
  type BookableResourceFragmentFragment,
} from "../../../generated/gateway-client";

export type ResourceApprovalFields = Pick<
  BookableResourceFragmentFragment,
  "viewerPermissions" | "bookingApprovalType" | "manualApprovalCompanies"
>;

export const isAutoApproveBookings = (resource: ResourceApprovalFields, companyUuid?: string) =>
  resource.viewerPermissions?.canManageBookings === true ||
  resource.bookingApprovalType === BookableResourceBookingApprovalType.AutoForAll ||
  (resource.bookingApprovalType === BookableResourceBookingApprovalType.ManualForCompanies &&
    !resource.manualApprovalCompanies.includes(companyUuid ?? ""));
