import React from "react";

import { useTranslation } from "@equiem/localisation-eq1";
import { Button, useConfirmer } from "@equiem/react-admin-ui";

import { type RequestQuery, useResendCafmMessageMutation } from "../../../generated/requests-client";

interface P {
  request: RequestQuery["reqMgt"]["request"];
}
export const RequestResendCafm: React.FC<P> = ({ request }) => {
  const { t } = useTranslation();
  const { withConfirmation } = useConfirmer();
  const [mutation] = useResendCafmMessageMutation({
    variables: {
      requestUuid: request.uuid,
    },
  });

  return (
    <Button
      type="button"
      style={{ width: "fit-content" }}
      variant="outline"
      size="sm"
      onClick={withConfirmation({
        confirmButtonText: t("common.confirm"),
        message: t("requests.details.retryCafmText"),
        title: t("common.areYouSure"),
        confirmButtonVariant: "primary",
        onConfirm: async () => {
          await mutation();
        },
      })}
    >
      {t("requests.details.retryCafm")}
    </Button>
  );
};
