import React, { useCallback, useEffect, useState } from "react";

import { useRequestEnabled } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import { Alert, Form, useTheme } from "@equiem/react-admin-ui";
import { RiErrorWarningLine } from "@equiem/react-admin-ui/icons";

import { type CompanyDetailsQuery, useUpdateCompanyPermissionsMutation } from "../../../generated/settings-client";

interface P {
  company: NonNullable<CompanyDetailsQuery["companyV2"]>;
  registerSaveHandler: (handler: () => Promise<boolean>) => void;
}
export const RequestManagementPermission: React.FC<P> = ({ company, registerSaveHandler }) => {
  const { t } = useTranslation();
  const { colors } = useTheme();
  const [everyoneCanOpenRequest, setEveryoneCanOpenRequest] = useState(
    company.reqMgtRequestPermissions.everyoneCanOpenRequest,
  );
  const [mutation, { loading }] = useUpdateCompanyPermissionsMutation();
  const isRequestManagementEnabled = useRequestEnabled();

  const saveChanges = useCallback(async () => {
    if (loading) {
      return false;
    }

    if (company.reqMgtRequestPermissions.everyoneCanOpenRequest !== everyoneCanOpenRequest) {
      await mutation({
        variables: {
          companyUuid: company.uuid,
          input: { everyoneCanOpenRequest },
        },
      });
      return true;
    }

    return false;
  }, [company, everyoneCanOpenRequest, loading, mutation]);

  useEffect(() => {
    registerSaveHandler(saveChanges);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveChanges]);

  return (
    <div className="permission-form">
      <h4>{t("settings.editCompany.requestManagement")}</h4>
      {!isRequestManagementEnabled ? (
        <Alert
          size="large"
          variant="gray"
          icon={<RiErrorWarningLine size={18} color={colors.grayscale[50]} />}
          message={t("settings.moduleNotEnabled")}
          className="my-3 w-100"
        />
      ) : (
        <Form.Group label={t("settings.editCompany.requestManagementIntro")}>
          <>
            <Form.RadioButton
              name="whoCanOpenRequests"
              disabled={loading}
              defaultValue="all"
              label={t("settings.editCompany.requestManagementAllCan")}
              className="pb-3"
              defaultChecked={everyoneCanOpenRequest}
              onChange={() => setEveryoneCanOpenRequest(true)}
            />
            <Form.RadioButton
              name="whoCanOpenRequests"
              disabled={loading}
              defaultValue="selected"
              label={t("settings.editCompany.requestManagementSelectedUser")}
              extraInfo={t("settings.editCompany.requestManagementSelectedUserAdditionalInfo")}
              defaultChecked={!everyoneCanOpenRequest}
              onChange={() => setEveryoneCanOpenRequest(false)}
            />
          </>
        </Form.Group>
      )}
    </div>
  );
};
