import { stringNotEmpty } from "@equiem/lib";
import type { ArrayHelpers } from "formik";
import { useFormikContext } from "formik";
import React, { useCallback, useContext, useState } from "react";
import type { BookableResourceAddOn } from "../../../../generated/gateway-client";
import { BreakWordDiv } from "../../../../lib/BreakWordDiv";
import { CurrencyAmount } from "../../../../lib/CurrencyAmount";
import type { AddOnCheckboxOrRadio, BookingFormValue } from "../../models/BookingFormValue";
import { AddOnQuantity } from "./AddOnQuantity";
import { BookingModalInfo } from "../../contexts/BookingModalInfoProvider";

interface P {
  option: NonNullable<BookableResourceAddOn["options"]>[number];
  index: number;
  arrayHelpers: ArrayHelpers;
  hideCost?: boolean;
}
export const AddOnRadioOption: React.FC<P> = ({ option, index, arrayHelpers, hideCost = false }) => {
  const fm = useFormikContext<BookingFormValue>();
  const value = fm.values.addOns[index] as AddOnCheckboxOrRadio | null;
  const checked = value?.[option.uuid] != null;
  const [quantity, setQuantity] = useState(
    value?.[option.uuid]?.quantity != null ? `${value[option.uuid]?.quantity}` : option.hasQuantity ? "1" : "",
  );
  const { buildingUuid, currency } = useContext(BookingModalInfo);

  const updateFormik = useCallback(
    (select: boolean, qty: string) => {
      arrayHelpers.replace(index, {
        [option.uuid]: select
          ? {
              type: "radio",
              option: option.uuid,
              quantity: qty,
            }
          : null,
      });
      fm.setFieldTouched(`addOns[${index}]`).catch(console.error);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [arrayHelpers, index, option.uuid],
  );

  return (
    <BreakWordDiv>
      <div className="d-flex justify-content-between">
        <label className="d-flex align-items-start">
          <input
            type="radio"
            id={option.uuid}
            checked={checked}
            onClick={() => {
              updateFormik(checked ? false : true, quantity);
            }}
            onChange={() => {
              // Do nothing to remove warning.
            }}
          />
          <span>{stringNotEmpty(option.name) ? option.name : "No name"}</span>
        </label>

        {!hideCost && option.unitPrice > 0 && (
          <span className="font-weight-normal flex-shrink-0">
            <CurrencyAmount
              amount={option.unitPrice}
              currencyCode={currency}
              buildingUuid={buildingUuid}
              priceIsPerItem={option.hasQuantity}
            />
          </span>
        )}
      </div>

      {option.hasQuantity && checked && (
        <AddOnQuantity
          onChange={(event) => {
            setQuantity(event.target.value);
            updateFormik(true, event.target.value);
          }}
          value={quantity}
          className="mt-2 mb-3 w-25"
          max={option.maxQuantity}
        />
      )}
    </BreakWordDiv>
  );
};
