"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.isLocalisableError = exports.isLocalisationParams = exports.makeLocalisableGraphQLErrorClass = exports.LocalisableGraphQLError = void 0;
var graphql_1 = require("graphql");
var i18next_1 = require("i18next");
var getSafeTOptions_1 = require("../wrappers/getSafeTOptions");
var initializeNodejs_1 = require("./initializeNodejs");
var config_1 = require("./config");
/**
 * Extend this class in each back-end service and provide a concrete value for
 * the `ErrorKey` generic, e.g.:
 *
 * ```ts
 * import type { TFuncKey } from "i18next";
 * import { LocalisableGraphQLError } from "@equiem/localisation";
 *
 * export class LocalisableError extends LocalisableGraphQLError<TFuncKey<"server-messages">> {}
 * ```
 */
var LocalisableGraphQLError = /** @class */ (function (_super) {
    __extends(LocalisableGraphQLError, _super);
    function LocalisableGraphQLError(params) {
        return _super.call(this, params.fallback, undefined, undefined, undefined, undefined, undefined, {
            code: "LOCALISABLE_SERVER_ERROR",
            localisableErrorParams: params,
        }) || this;
    }
    return LocalisableGraphQLError;
}(graphql_1.GraphQLError));
exports.LocalisableGraphQLError = LocalisableGraphQLError;
/**
 * Creates a localisable GraphQL error class in client locale.
 *
 * @param languages - The server languages.
 * @returns A class that extends LocalisableGraphQLError.
 */
var makeLocalisableGraphQLErrorClass = function (languages) {
    var instance = (0, i18next_1.createInstance)();
    (0, initializeNodejs_1.initialize)(instance, { defaultNamespace: config_1.serverMessagesNamespace, languages: languages }).catch(console.error);
    return /** @class */ (function (_super) {
        __extends(class_1, _super);
        /**
         * Constructs a new instance of the localisable GraphQL error class.
         *
         * @param params - The localisation parameters.
         * @param [clientLocale] - The client locale, defaults to "en"
         * @throws {Error} There's a minuscule chance that i18next may not be initialised yet on service boot up.
         */
        function class_1(_a, clientLocale) {
            var key = _a.key, variables = _a.variables;
            if (clientLocale === void 0) { clientLocale = config_1.defaultLocale; }
            if (!instance.isInitialized) {
                throw new Error("Localiser not initialised");
            }
            var t = instance.getFixedT(clientLocale);
            var fallback = t(key, (0, getSafeTOptions_1.getSafeTOptions)(variables));
            return _super.call(this, { fallback: fallback, key: key, variables: variables }) || this;
        }
        return class_1;
    }(LocalisableGraphQLError));
};
exports.makeLocalisableGraphQLErrorClass = makeLocalisableGraphQLErrorClass;
function isLocalisationParams(params) {
    var unsafelyCasted = params;
    return (
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    unsafelyCasted != null &&
        typeof unsafelyCasted.fallback === "string" &&
        typeof unsafelyCasted.key === "string" &&
        (unsafelyCasted.variables == null || typeof unsafelyCasted.variables === "object"));
}
exports.isLocalisationParams = isLocalisationParams;
function isLocalisableError(error) {
    var _a;
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    var params = (_a = error === null || error === void 0 ? void 0 : error.extensions) === null || _a === void 0 ? void 0 : _a.localisableErrorParams;
    return isLocalisationParams(params);
}
exports.isLocalisableError = isLocalisableError;
