import React, { useCallback, useContext } from "react";
import { SelectedContext } from "../../../contexts/SelectedContext";

export const ModalHeaderTab: React.FC<
  React.PropsWithChildren<{
    eventKey: string;
    className?: string;
  }>
> = ({ children, className, eventKey }) => {
  const ctx = useContext(SelectedContext);
  const selectedClass = ctx.selected === eventKey ? "selected" : "";
  const onSelect = useCallback(() => {
    ctx.setSelected(eventKey);
  }, [ctx, eventKey]);

  return (
    <a tabIndex={0} className={`text-dark ${className ?? ""} ${selectedClass}`} onClick={onSelect}>
      {children}
      <style jsx>{`
        a {
          display: inline-block;
          font-size: 14px;
          font-weight: bold;
          cursor: pointer;
          opacity: 0.5;
          border-bottom: 1px solid #fff;
          padding: 0.5rem 0;
          margin: 0 0.5rem;
        }
        a,
        a:hover,
        a:focus,
        a:active,
        a:visited {
          text-decoration: none;
        }
        a:first-child {
          margin-left: 0;
        }
        a:hover {
          opacity: 1;
        }
        .selected {
          opacity: 1;
          border-color: #000;
        }
      `}</style>
    </a>
  );
};
