import { optionalCookiesAcceptedKey, cookiesPreferencesSavedKey } from "@equiem/lib";
import { clearModeCookie } from "./modeCookie";
import { serialize } from "cookie";

export const onLogout = () => {
  const optionalCookiesAccepted = localStorage.getItem(optionalCookiesAcceptedKey);
  const cookiesPreferencesSaved = localStorage.getItem(cookiesPreferencesSavedKey);

  localStorage.clear();

  // Retain cookie preferences on logout.
  if (optionalCookiesAccepted != null) {
    localStorage.setItem(optionalCookiesAcceptedKey, optionalCookiesAccepted);
  }
  if (cookiesPreferencesSaved != null) {
    localStorage.setItem(cookiesPreferencesSavedKey, cookiesPreferencesSaved);
  }

  clearModeCookie();
  document.cookie = serialize("auth", "", { expires: new Date(0) });
};
