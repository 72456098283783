/* eslint-disable @typescript-eslint/no-misused-promises */

import React, { useCallback, useEffect, useState } from "react";
import { DateTime } from "luxon";
import { useRouter } from "next/router";

import { getReceptionName, notNullOrUndefined, useBcUuidContext, useSiteContext } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import { useDebounced, useTheme, useToast } from "@equiem/react-admin-ui";

import { withContexts } from "../../contexts/withContexts";
import type { Visitor, VisitorsByReceptionQuery, VisitorStatus } from "../../generated/visitors-client";
import {
  useVisitorAppointmentExportCsvMutation,
  useVisitorReceptionQuery,
  useVisitorsByReceptionQuery,
} from "../../generated/visitors-client";
import type { ReceptionType } from "../../settings/ReceptionFormValues";

import { AppointmentsTable } from "./components/AppointmentsTable";
import { DeskMenu } from "./components/DeskMenu";
import { DeskMenuBar } from "./components/DeskMenuBar";
import { InfiniteScrollAppointmentTable } from "./components/InfinitScrollAppointmentTable";
import { useReceptionDate } from "./hooks/useReceptionDate";

interface Props {
  visitorStatus: "PRE_BOOKED" | "VISITOR_IN_LOBBY" | "CHECKED_IN" | "CHECKED_OUT";
  uuid: string;
}

export const ReceptionDeskBase: React.FC<Props> = ({ visitorStatus, uuid }) => {
  const { t } = useTranslation();
  const router = useRouter();
  const { colors } = useTheme(true);
  const [search, setSearch] = useState("");
  const { date, setDate } = useReceptionDate();
  const { uuid: siteUuid } = useSiteContext();
  const { updateBcUuid } = useBcUuidContext();
  const debounceTimeout = 500;
  const debouncedSearch = useDebounced(search, debounceTimeout);
  const toaster = useToast();

  const { data: receptionData } = useVisitorReceptionQuery({
    variables: { uuid },
  });
  const variables = {
    receptionUuid: uuid,
    beginDate: date.startOf("day").toMillis(),
    endDate: date.endOf("day").toMillis(),
    visitorStatus: visitorStatus as VisitorStatus,
  };

  const {
    data: visitorsData,
    refetch: refetchAppointments,
    stopPolling,
    fetchMore,
    loading,
    updateQuery,
  } = useVisitorsByReceptionQuery({
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
    variables: {
      ...variables,
      search: debouncedSearch.length > 0 ? debouncedSearch : undefined,
      visitorStatus: debouncedSearch.length > 0 ? undefined : (visitorStatus as VisitorStatus),
      includeCardId: receptionData?.visitorReception.enableAccessCard,
      first: 25,
    },
  });

  const handleRefetchAppointments: typeof refetchAppointments = async (...args) => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    return refetchAppointments(...args);
  };

  useEffect(() => {
    setSearch("");
  }, [router.query]);

  useEffect(() => {
    return () => stopPolling();
  }, [stopPolling]);

  useEffect(() => {
    if (receptionData != null) {
      updateBcUuid(uuid, getReceptionName(receptionData.visitorReception, t));
    }
  }, [receptionData, uuid, t, updateBcUuid]);

  const receptionType: ReceptionType = receptionData?.visitorReception.company !== null ? "tenant" : "building";

  const [exportAppointmentsCsv, { loading: reportLoading }] = useVisitorAppointmentExportCsvMutation({
    variables: {
      input: {
        receptionUuid: uuid,
        beginDate: date.startOf("day").toMillis(),
        endDate: date.endOf("day").toMillis(),
        siteUuid,
      },
    },
  });

  const visitors = visitorsData?.visitorsByReception.edges.map((e) => e.node).filter(notNullOrUndefined) ?? [];

  const exportCsv = () => {
    exportAppointmentsCsv()
      .then(() => {
        toaster.positive(t("visitors.common.csvExportSuccess"), { autoDismiss: true });
      })
      .catch(console.error);
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    void setDate(DateTime.fromISO(e.target.value));
  };

  const hasMoreAppointments = visitorsData?.visitorsByReception.pageInfo.hasNextPage ?? false;

  const handleNextPage = async () =>
    fetchMore({
      variables: {
        after: visitorsData?.visitorsByReception.pageInfo.endCursor,
      },
    });

  const updateEdges = useCallback(
    (
      cb: (
        edges: VisitorsByReceptionQuery["visitorsByReception"]["edges"],
      ) => VisitorsByReceptionQuery["visitorsByReception"]["edges"],
    ) => {
      updateQuery((existing) => ({
        ...existing,
        visitorsByReception: {
          ...existing.visitorsByReception,
          edges: cb(existing.visitorsByReception.edges),
        },
      }));
      void refetchAppointments();
    },
    [updateQuery, refetchAppointments],
  );

  return (
    <div className="reception d-flex flex-column">
      <DeskMenu uuid={uuid} search={search}>
        <DeskMenuBar
          handleDateChange={handleDateChange}
          onClearSearch={() => setSearch("")}
          handleRefetchAppointments={async () => handleRefetchAppointments()}
          reportLoading={reportLoading}
          exportCsv={exportCsv}
          handleSearch={handleSearch}
          search={search}
          date={date}
          loading={loading}
        />
      </DeskMenu>
      <InfiniteScrollAppointmentTable
        handleNextPage={handleNextPage}
        date={date}
        loading={loading}
        visitors={visitors as Visitor[]}
        hasMoreAppointments={hasMoreAppointments}
      >
        <AppointmentsTable
          receptionUuid={uuid}
          visitors={visitors}
          receptionType={receptionType}
          enableAccessCard={receptionData?.visitorReception.enableAccessCard}
          updateEdges={updateEdges}
          visitorsLoading={loading}
        />
      </InfiniteScrollAppointmentTable>
      <style jsx>
        {`
          .reception {
            flex-grow: 1;
            background: ${colors.white};
            min-height: 100%;
          }
          :global(.search-container) {
            width: 320px !important;
          }
          :global(.date) {
            width: 140px !important;
          }
          .filters {
            gap: 8px;
            position: relative;
            align-items: center;
          }
          .spin {
            animation-name: spin;
            animation-duration: 1000ms;
            animation-iteration-count: infinite;
            animation-timing-function: linear;
          }
          @keyframes spin {
            from {
              transform: rotate(0deg);
            }
            to {
              transform: rotate(360deg);
            }
          }
        `}
      </style>
    </div>
  );
};

export const ReceptionDesk = withContexts(ReceptionDeskBase);
