// @ts-expect-error This file actually *does* depend on `React` being in scope,
// because the result of `css.resolve` gets inlined when this file is built for
// *publishing* (different to the EQ1 build!), and it uses React.createElement.
//
// Without this import, trying to render `Button` from `@equiem/react-admin-ui`
// outside of the EQ1 monorepo will throw an exception.
//
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from "react";

import css from "styled-jsx/css";

import type { AdminThemeContext } from "../../contexts/Theme";

export type BaseButtonVariants = "primary" | "secondary" | "outline" | "toggle" | "ghost" | "danger" | "transparent";
export type BaseButtonSize = "sm" | "md" | "lg";
export type BaseButtonShape = "round" | "capsule" | "default";

export interface AdminButtonStyleParams {
  theme: AdminThemeContext;
  element: "a" | "button";
  variant: BaseButtonVariants;
  size: BaseButtonSize;
  shape: BaseButtonShape;
  inverted: boolean;
}

// eslint-disable-next-line complexity
export const renderAdminButtonStyles = ({ element, variant, size, inverted, shape, theme }: AdminButtonStyleParams) => {
  const { animationDuration, focusOutline, mutedOpacity, spacers } = theme;

  const disabled = element === "button" ? ":disabled" : ".disabled";

  const paddingVariants =
    variant === "outline" || variant === "toggle"
      ? { sm: "3px 7px", md: "7px 11px", lg: "7px 15px" }
      : { sm: "4px 8px", md: "8px 12px", lg: "8px 16px" };

  const btnBorders = {
    border: {
      primary: "none",
      secondary: "none",
      outline: `1px solid ${inverted ? theme.colors.transparent.white[50] : theme.colors.transparent.black[10]}`,
      toggle: `1px solid ${inverted ? theme.colors.transparent.black[5] : theme.colors.transparent.black[10]}`,
      ghost: "none",
      danger: theme.colors.danger,
      transparent: inverted ? theme.colors.transparent.white[60] : theme.colors.transparent.black[60],
    }[variant],
    hover: {
      border: {
        primary: "none",
        secondary: "none",
        outline: `1px solid ${inverted ? theme.colors.white : theme.colors.primary}`,
        toggle: `1px solid ${theme.colors.transparent.black[5]}`,
        ghost: "none",
        danger: "none",
        transparent: "none",
      }[variant],
    },
  };

  const btnBackgrounds = {
    background: {
      primary: inverted ? theme.colors.white : theme.colors.primary,
      secondary: inverted ? theme.colors.transparent.white[15] : theme.colors.secondary,
      outline: "none",
      toggle: "none",
      ghost: "none",
      danger: theme.colors.danger,
      transparent: inverted ? theme.colors.transparent.white[60] : theme.colors.transparent.black[60],
    }[variant],
    hover: {
      background: {
        primary: inverted ? theme.colors.transparent.white[80] : theme.colors.blue[80],
        secondary: inverted ? theme.colors.white : theme.colors.blue.transparent[20],
        outline: "none",
        toggle: theme.colors.grayscale_5,
        ghost: inverted ? theme.colors.transparent.white[15] : theme.colors.transparent.black[5],
        danger: theme.colors.status.danger.dark,
        transparent: inverted ? theme.colors.transparent.white[80] : theme.colors.transparent.black[80],
      }[variant],
    },
  };

  const btnColors = {
    color: {
      primary: inverted ? theme.colors.primary : theme.colors.white,
      secondary: inverted ? theme.colors.white : theme.colors.primary,
      outline: inverted ? theme.colors.white : theme.colors.primary,
      toggle: inverted ? theme.colors.white : theme.colors.black,
      ghost: inverted ? theme.colors.white : theme.colors.medium,
      danger: theme.colors.white,
      transparent: inverted ? theme.colors.black : theme.colors.white,
    }[variant],
    hover: {
      color: {
        primary: inverted ? theme.colors.primary : theme.colors.white,
        secondary: theme.colors.primary,
        outline: inverted ? theme.colors.white : theme.colors.primary,
        toggle: inverted ? theme.colors.white : theme.colors.black,
        ghost: inverted ? theme.colors.white : theme.colors.medium,
        danger: theme.colors.white,
        transparent: inverted ? theme.colors.black : theme.colors.white,
      }[variant],
    },
  };

  const round = shape === "round";
  const btnRadius = {
    default: "4px",
    round: "50%",
    capsule: { sm: "24px", md: "32px", lg: "40px" }[size],
  };

  return css.resolve`
    ${element} {
      cursor: pointer;
      font-weight: ${variant === "toggle" ? 500 : 700};
      text-transform: uppercase;
      display: inline-flex;
      gap: ${spacers.s2};
      align-items: center;
      justify-content: center;
      border-radius: ${btnRadius[shape]};
      white-space: ${round ? "auto" : "nowrap"};
      overflow: hidden;
      width: ${!round ? "auto" : { sm: "24px", md: "32px", lg: "40px" }[size]};
      height: ${!round
        ? "auto"
        : {
            sm: "24px",
            md: "32px",
            lg: "40px",
          }[size]};
      background: ${btnBackgrounds.background};
      border: ${btnBorders.border};
      color: ${btnColors.color};
      font-size: ${round ? "12px" : { sm: "12px", md: "12px", lg: "14px" }[size]};
      line-height: ${round ? "12px" : { sm: "16px", md: "16px", lg: "24px" }[size]};
      padding: ${round ? "0" : paddingVariants[size]};
      outline: none;
      transition: box-shadow ${animationDuration};
      text-transform: "uppercase";
      text-decoration: none;
      letter-spacing: 0.02rem;
    }
    ${element}:hover,
    ${element}:active,
    ${element}:active:focus {
      background: ${btnBackgrounds.hover.background};
      border: ${btnBorders.hover.border};
      color: ${btnColors.hover.color};
      text-underline-position: under;
    }
    ${element}:focus-visible {
      box-shadow: 0px 0px 0px ${focusOutline.size} ${focusOutline.colors.default};
    }
    ${element}${disabled},
    ${element}${disabled} button:hover,
      ${element}${disabled} button:focus,
      ${element}${disabled} button:active,
      ${element}${disabled} button:active:focus {
      opacity: ${mutedOpacity};
      cursor: default;
      color: ${btnColors.color};
      text-decoration: none;
      background: ${btnBackgrounds.background};
      border: ${btnBorders.border};
      box-shadow: none;
      pointer-events: none;
    }
  `;
};
