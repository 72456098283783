import React from "react";

export interface FormGroupContext {
  label?: string | null;
  error?: string | null;
  required?: boolean;
  hint?: string;
  id?: string;
  labelId?: string;
  hintId?: string;
  hasError: boolean;
}

export const FormGroupContext = React.createContext<FormGroupContext>({
  hasError: false,
});
