import type { ApolloClient } from "@apollo/client";
import type { PropsWithChildren } from "react";
import React, { useEffect, useState } from "react";
import type { TransformedSite } from "@equiem/lib";
import { Site, defaultSite } from "@equiem/lib";
import { setLastSiteCookie } from "../lib/lastSiteCookie";
import { useSiteLoaderMode } from "../hooks/useSiteLoaderMode";
import { SiteLoading } from "../components/layout/SiteLoading";

interface Props {
  isAuthenticated: boolean;
  client: ApolloClient<unknown>;
  site?: TransformedSite | null;
  loading: boolean;
}

export const SiteProvider: React.FC<PropsWithChildren<Props>> = ({ children, loading, site: siteInput }) => {
  const siteLoaderMode = useSiteLoaderMode();
  const site = siteInput ?? defaultSite;
  const [siteSwitchingInProgress, setSiteSwitchingInProgress] = useState(false);
  const [memberSites, setMemberSites] = useState<Array<{ uuid: string; name: string }>>([]);

  useEffect(() => {
    /**
     * Update the uuid in the auth:[domain]:last_site cookie every time the window is focused, so that we can remember
     * the last site the user logged into, if they ever visit the root defaultEquiemOneHost again.
     *
     * See also the getSiteFromRequest() logic in the api/login route.
     */
    const listener = () => {
      setLastSiteCookie(site.uuid);
    };

    listener();
    window.addEventListener("focus", listener);
    return () => {
      window.removeEventListener("focus", listener);
    };
  }, [site.uuid]);

  return loading ? (
    <SiteLoading />
  ) : (
    <Site.Provider
      value={{
        ...site,
        siteLoaderMode,
        siteSwitchingInProgress,
        setSiteSwitchingInProgress,
        memberSites,
        setMemberSites,
      }}
    >
      {children}
    </Site.Provider>
  );
};
