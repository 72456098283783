import { stringIsEmpty } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import { Form, TermsModal, useTheme } from "@equiem/react-admin-ui";
import React, { useContext, useState } from "react";
import { BookingModalInfo } from "../contexts/BookingModalInfoProvider";
import { useFormikContext } from "formik";
import type { BookingFormValue } from "../models/BookingFormValue";

export const BookingFormTermsAndConditions: React.FC = () => {
  const { t } = useTranslation();
  const { booking, resource } = useContext(BookingModalInfo);
  const { colors } = useTheme(true);
  const fm = useFormikContext<BookingFormValue>();
  const [showTermsAndConditions, setShowTermsAndConditions] = useState(false);

  if (booking != null && stringIsEmpty(resource.editBookingTermsAndConditions)) {
    return null;
  }

  if (booking == null && stringIsEmpty(resource.termsAndConditions)) {
    return null;
  }

  const terms = booking != null ? resource.editBookingTermsAndConditions : resource.termsAndConditions;

  return (
    <>
      <Form.Group label={t("common.termsAndConditions")} required>
        <Form.Checkbox
          required
          label={
            <>
              {t("bookings.operations.acceptTheTerms")}{" "}
              <span onClick={() => setShowTermsAndConditions(true)}>
                {booking == null
                  ? t("bookings.operations.acceptTheTermsConditions")
                  : t("bookings.operations.editOrCancelPolicy")}
              </span>
            </>
          }
          id="termsAccepted"
          name="termsAccepted"
          onChange={(e) => {
            fm.setFieldValue("termsAccepted", e.target.checked).catch(console.error);
          }}
        />
      </Form.Group>
      <TermsModal
        type="termsAndConditions"
        terms={terms ?? ""}
        show={showTermsAndConditions}
        siteName={resource.name}
        onHide={() => setShowTermsAndConditions(false)}
      />
      <style jsx>{`
        span {
          text-decoration: none;
          color: ${colors.blue[100]};
        }
        span:hover {
          text-decoration: underline;
          cursor: pointer;
        }
      `}</style>
    </>
  );
};
