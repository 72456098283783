import { useSiteContext } from "@equiem/lib";

import { useCreateOrUpdateApartmentMutation } from "../../../generated/settings-client";
import type { FormValues } from "../types";

export function useApartmentFormSubmit(apartmentUuid?: string) {
  const site = useSiteContext();
  const [mutation, { loading }] = useCreateOrUpdateApartmentMutation();

  const onSubmit = async (values: FormValues) => {
    const destinations: string[] | undefined = [site.uuid];

    return mutation({
      variables: {
        input: {
          uuid: apartmentUuid,
          name: values.name,
          destinations,
          apartmentDestination: {
            destinationUuid: site.uuid,
            buildingLevels: values.levels != null && values.levels.length > 0 ? values.levels : undefined,
          },
        },
      },
      refetchQueries: ["ApartmentList"],
    });
  };

  return {
    loading,
    onSubmit,
  };
}
