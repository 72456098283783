import React from "react";
import Link from "next/link";
import { DateTime as DateTimeUi, useTheme } from "@equiem/react-admin-ui";
import { useSiteContext } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";

import type { SiteCalendarBookingFragment } from "../../../generated/gateway-client";

interface Props {
  className?: string;
  booking: SiteCalendarBookingFragment;
  onBookingClicked: () => unknown;
}

export const OperationsCalendarBookingShimmer: React.FC = () => {
  const { borderRadius } = useTheme();
  return (
    <>
      <div className="booking">
        <div className="shimmer"></div>
      </div>
      <style jsx>{`
        .booking {
          border-radius: ${borderRadius};
          overflow: hidden;
        }
        .shimmer {
          height: 48px;
        }
      `}</style>
    </>
  );
};

export const OperationsCalendarBooking: React.FC<Props> = ({ className, booking, onBookingClicked }) => {
  const { colors, spacers, borderRadius } = useTheme();
  const { timezone } = useSiteContext();
  const { i18n } = useTranslation();
  const time = DateTimeUi.toTimeRangeString({
    start: booking.startDate,
    end: booking.endDate,
    timezone: timezone,
    language: i18n.language,
  });
  return (
    <div className="tile p-3">
      <Link
        className={`booking overflow-ellipsis ${className ?? ""}`}
        role="button"
        onClick={onBookingClicked}
        href={`/bookings/operations/${booking.reference}`}
      >
        <small className="time" title={time}>
          {time}
        </small>
        <div className="resource-name overflow-ellipsis" title={booking.resource.name}>
          {booking.resource.name}
        </div>
      </Link>
      <style jsx>{`
        .tile {
          cursor: pointer;
          background: ${colors.transparent.black[5]};
          font-weight: 500;
          border-radius: ${borderRadius};
          margin-bottom: ${spacers.s1};
        }
        .time {
          color: ${colors.grayscale[60]};
        }
        .overflow-ellipsis {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      `}</style>
    </div>
  );
};
