import { useState, useCallback, useContext } from "react";
import { useRouter } from "next/router";
import { useToast } from "@equiem/react-admin-ui";
import { useTranslation, useErrorTranslation } from "@equiem/localisation-eq1";
import { useSiteContext } from "@equiem/lib";
import { PageContext } from "@equiem/lib/context/PageContext";

import {
  type BookingFragmentFragment,
  BookingStatus,
  useCreateVisitorAppointmentForBookingMutation,
} from "../../../generated/gateway-client";
import { formatBookingStatusLocalised } from "../../reports/utils/formatBookingStatusLocalised";

export const useEditAppointmentRoute = () => {
  const { isInWebNgLayout } = useContext(PageContext);
  const { webAppUrl } = useSiteContext();

  return useCallback(
    (uuid: string) => {
      const path = `/visitor-management/edit-appointment/${uuid}`;
      return isInWebNgLayout ? new URL(path, `https://${webAppUrl}`).toString() : path;
    },
    [isInWebNgLayout, webAppUrl],
  );
};

export const useLazyBookingAppointment = () => {
  const router = useRouter();
  const toast = useToast();
  const { t } = useTranslation();
  const { tError } = useErrorTranslation();
  const [loading, setLoading] = useState(false);
  const [createAppointmentForBooking] = useCreateVisitorAppointmentForBookingMutation();
  const editAppointmentRoute = useEditAppointmentRoute();

  const goToAppointment = useCallback(
    async (booking: BookingFragmentFragment): Promise<boolean> => {
      if (loading) {
        return false;
      }
      setLoading(true);

      try {
        if (booking.status !== BookingStatus.Approved) {
          toast.neutral(
            t("bookings.visitors.appointmentCreateInvalidBookingStatus", {
              status: formatBookingStatusLocalised(booking.status, t),
            }),
          );
          return false;
        }

        if (booking.appointment != null) {
          await router.push(editAppointmentRoute(booking.appointment.uuid));
          return true;
        }

        const { data, errors } = await createAppointmentForBooking({ variables: { bookingUuid: booking.uuid } });
        if (data?.createVisitorAppointmentForBooking == null || errors != null) {
          toast.negative(t("bookings.visitors.appointmentCreateError"));
          return false;
        }

        await router.push(editAppointmentRoute(data.createVisitorAppointmentForBooking.uuid));
        return true;
      } catch (e: unknown) {
        console.error(e);
        toast.negative(tError(e));
        return false;
      } finally {
        setLoading(false);
      }
    },
    [loading, createAppointmentForBooking, editAppointmentRoute, router, toast, t, tError],
  );

  return { goToAppointment, loading };
};
