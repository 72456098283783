import { useTranslation } from "@equiem/localisation-eq1";
import { FilterDateMonthModifier, type FilterItem } from "@equiem/react-admin-ui";
import { RiCalendarLine } from "@equiem/react-admin-ui/icons";

export function useCreditsFilterCreditMonth() {
  const { t } = useTranslation();

  const filter: FilterItem = {
    title: t("credits.creditMonth"),
    type: "datemonth",
    icon: RiCalendarLine,
    modifiers: [FilterDateMonthModifier.is, FilterDateMonthModifier.between],
  };

  return {
    filter,
  };
}
