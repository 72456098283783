import type { LazyQueryHookOptions, LazyQueryResultTuple, OperationVariables } from "@apollo/client";
import { useMemo } from "react";

type LazyQueryHook<Query, Variables extends OperationVariables> = (
  options?: LazyQueryHookOptions<Query, Variables>,
) => LazyQueryResultTuple<Query, Variables>;

const ONE_SECOND_MS = 1000;

function debounce<Args extends unknown[], RetVal>(
  fn: (...args: Args) => Promise<RetVal>,
  timeoutMs: number,
): (...args: Args) => Promise<RetVal> {
  let cancelPending: (() => void) | null = null;

  return async (...args: Args): Promise<RetVal> =>
    new Promise<RetVal>((resolve) => {
      if (cancelPending != null) {
        cancelPending();
      }

      const timer = setTimeout(() => {
        cancelPending = null;
        resolve(fn(...args));
      }, timeoutMs);

      // if this call gets cancelled, just never resolve/reject the promise
      cancelPending = () => clearTimeout(timer);
    });
}

export function useDebouncedLazyQuery<Query, Variables extends OperationVariables>(
  lazyQueryHook: LazyQueryHook<Query, Variables>,
  options?: LazyQueryHookOptions<Query, Variables>,
  debounceTimeoutMs = ONE_SECOND_MS,
): LazyQueryResultTuple<Query, Variables> {
  const [execFn, { refetch, fetchMore, ...result }] = lazyQueryHook(options);

  const debouncedExecFn = useMemo(() => debounce(execFn, debounceTimeoutMs), [execFn, debounceTimeoutMs]);
  const debouncedRefetch = useMemo(() => debounce(refetch, debounceTimeoutMs), [refetch, debounceTimeoutMs]);
  const debouncedFetchMore = useMemo(() => debounce(fetchMore, debounceTimeoutMs), [fetchMore, debounceTimeoutMs]);

  return [debouncedExecFn, { refetch: debouncedRefetch, fetchMore: debouncedFetchMore, ...result }];
}
