import React, { useContext } from "react";
import { useTranslation } from "@equiem/localisation-eq1";
import { Tooltip, useTheme } from "@equiem/react-admin-ui";
import { RiInformationLine } from "@equiem/react-admin-ui/icons";
import { CurrentRole, Role } from "@equiem/lib";

export const ActivityHeader = () => {
  const { t } = useTranslation();
  const { colors } = useTheme();
  const { currentRole } = useContext(CurrentRole);
  const isFlexManager = currentRole === Role.FlexManager;

  return (
    <>
      <div className="header">
        <div className="sticky-title">
          <h2 className="header-title">{t("credits.recent.title")}</h2>
        </div>
        <div className="header-info">
          <Tooltip
            title={isFlexManager ? t("credits.recent.tooltipFlexManager") : t("credits.recent.tooltip")}
            placement="left"
          >
            <div
              style={{
                display: "inline-block",
              }}
            >
              <RiInformationLine
                style={{
                  color: colors.medium,
                  cursor: "pointer",
                  display: "block",
                }}
              />
            </div>
          </Tooltip>
        </div>
      </div>
      <style jsx>
        {`
          .header {
            display: flex;
            padding: 0 16px;
            align-items: center;
            justify-content: space-between;
          }

          .header h2 {
            text-transform: uppercase;
            font-size: 12px;
            font-weight: 500;
            line-height: 24px;
            padding: 0;
            margin: 0;
            color: ${colors.transparent.black[60]};
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .header-info {
            display: flex;
            align-items: center;
            gap: 8px;
          }
        `}
      </style>
    </>
  );
};
