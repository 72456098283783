import React, { useContext } from "react";

import { useTranslation } from "@equiem/localisation-eq1";
import { Text, Toggler } from "@equiem/react-admin-ui";

import { Modal as ModalContext } from "../../../contexts/ModalContext";

import { JLLEvolutionIntegration } from "./integrations/JLLEvolutionIntegration";

export const UserIntegrations: React.FC = () => {
  const modal = useContext(ModalContext);
  const { t } = useTranslation();

  return (
    <>
      <div className="integrations-container">
        <Text variant="heading" className=" mb-6">
          {t("settings.integrations.title")}
        </Text>
        <div className="settings-container">
          <Toggler heading="JLL Evolution" defaultOpen>
            <JLLEvolutionIntegration userUuid={modal.id ?? ""} />
          </Toggler>
        </div>
      </div>
      <style jsx>{`
        .integrations-container {
          margin: 24px 0;
        }

        .settings-container :global(h4) {
          font-size: 14px;
          line-height: 24px;
        }

        .settings-container :global(.head) {
          align-items: center;
        }
      `}</style>
    </>
  );
};
