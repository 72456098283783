import type { TFunction } from "@equiem/localisation-eq1";
import type { TabItem } from "@equiem/react-admin-ui";

export type CategoryTabKey = "general" | "integrations" | "subcategories";

interface ProfileTab extends TabItem {
  key: CategoryTabKey;
}

export const categoryTabs = (t: TFunction): ProfileTab[] => [
  {
    title: t("requests.category.tabs.general"),
    key: "general",
  },
  {
    title: t("requests.category.tabs.subcategories"),
    key: "subcategories",
  },
  {
    title: t("requests.category.tabs.integrations"),
    key: "integrations",
  },
];
