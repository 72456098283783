import { createContext } from "react";
import type { InviteRoleTupleInput, InviteUsersResult } from "./generated/gateway-client";

export interface SelectedGroup {
  value: string;
  label: string;
}

export interface Invite {
  id: string;
  isChecked: boolean;
  email?: string;
  companyUuid?: string;
  groups: string[];
  roles: InviteRoleTupleInput[];
  flexTenantUuid?: string;
}

export interface ValidatedInvite extends Invite {
  error?: string;
}

export interface GroupInfo {
  group: string;
  mixedSelection: boolean;
}

export interface RoleInfo {
  role: InviteRoleTupleInput;
  mixedSelection: boolean;
}

export type Screen = "table" | "bulk" | "error";

export interface NewInvitesContext {
  invites: Invite[];
  validatedInvites: ValidatedInvite[];
  allValidEmails: string[];
  selectedGroups: GroupInfo[];
  selectedRoles: RoleInfo[];
  addInviteRow: () => void;
  updateInviteRow: (_id: string, _email: string) => void;
  disableSubmit: boolean;
  submit: (_close: () => void) => Promise<void>;
  isSubmitting: boolean;
  close: (_close?: () => void) => void;
  selectRows: (_ids: string[]) => void;
  removeRows: (_ids: string[]) => void;
  updateCompany: (_id: string, _company?: string) => void;
  updateGroups: (_id: string, _groups: string[]) => void;
  updateRoles: (_id: string, _roles: InviteRoleTupleInput[]) => void;
  updateFlexTenant: (_id: string, _flexTenant?: string) => void;
  extendGroupsBulk: (_groups: string[]) => void;
  extendRolesBulk: (_roles: InviteRoleTupleInput[]) => void;
  rowsAreSelected: boolean;
  hasAnyFilledRow: boolean;
  screen: Screen;
  setScreen: (_screen: Screen) => void;
  addBulkEmails: (_emails: string[]) => void;
  submissionResponse?: InviteUsersResult;
}

export const NewInvites = createContext<NewInvitesContext>({
  invites: [],
  validatedInvites: [],
  allValidEmails: [],
  selectedGroups: [],
  selectedRoles: [],
  addInviteRow: () => {
    //to be implemented
  },
  hasAnyFilledRow: false,
  updateInviteRow: () => {
    //to be implemented
  },
  disableSubmit: true,
  submit: async () => Promise.resolve(),
  isSubmitting: false,
  close: () => {
    //to be implemented
  },
  selectRows: () => {
    //to be implemented
  },
  removeRows: () => {
    //to be implemented
  },
  updateCompany: () => {
    //to be implemented
  },
  updateGroups: () => {
    //to be implemented
  },
  updateRoles: () => {
    //to be implemented
  },
  updateFlexTenant: () => {
    //to be implemented
  },
  extendGroupsBulk: () => {
    //to be implemented
  },
  extendRolesBulk: () => {
    //to be implemented
  },
  rowsAreSelected: false,
  screen: "table",
  setScreen: () => {
    //to be implemented
  },
  addBulkEmails: () => {
    //to be implemented
  },
});
