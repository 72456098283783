import React from "react";
import Link from "next/link";

import { useTranslation } from "@equiem/localisation-eq1";
import { ButtonLink, useTheme } from "@equiem/react-admin-ui";
import { RiTeamLine } from "@equiem/react-admin-ui/icons";

export const NoReceptions: React.FC = () => {
  const { t } = useTranslation();
  const theme = useTheme(true);

  return (
    <div className="container">
      <div className="inner">
        <div className="icon">
          <RiTeamLine size={24} style={{ color: theme.colors.medium }} />
        </div>
        <h2 className="info mt-5">
          {t("visitors.receptions.emptyList")} <br />
          {t("visitors.receptions.emptyListCreate")}
        </h2>
        <Link href="create-reception" passHref legacyBehavior>
          <ButtonLink variant="secondary" size="md" className="empty-action mt-5">
            {t("visitors.receptions.createReception")}
          </ButtonLink>
        </Link>
      </div>
      <style jsx>{`
        .container {
          height: 80vh;
          align-items: center;
          display: flex;
          justify-content: center;
        }
        .inner {
          display: flex;
          align-items: center;
          flex-direction: column;
        }
        .info {
          display: block;
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          padding: 0;
          margin: 0;
          color: rgba(0, 0, 0, 0.4);
          text-align: center;
        }
        .icon {
          border-radius: 50%;
          height: 64px;
          width: 64px;
          line-height: 64px;
          background-color: #f2f2f2;
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      `}</style>
    </div>
  );
};
