import { stringNotEmpty } from "@equiem/lib";
import { Form } from "@equiem/react-admin-ui";
import type { ArrayHelpers } from "formik";
import { useFormikContext } from "formik";
import React, { useCallback, useContext, useState } from "react";
import type { BookableResourceAddOn } from "../../../../generated/gateway-client";
import { BreakWordDiv } from "../../../../lib/BreakWordDiv";
import { CurrencyAmount } from "../../../../lib/CurrencyAmount";
import type { AddOnCheckboxOrRadio, BookingFormValue } from "../../models/BookingFormValue";
import { AddOnQuantity } from "./AddOnQuantity";
import { BookingModalInfo } from "../../contexts/BookingModalInfoProvider";

interface Selection {
  checked: boolean;
  qty: string;
}

interface P {
  option: NonNullable<BookableResourceAddOn["options"]>[number];
  index: number;
  arrayHelpers: ArrayHelpers;
  hideCost?: boolean;
}
export const AddOnCheckboxOption: React.FC<P> = ({ option, index, arrayHelpers, hideCost = false }) => {
  const fm = useFormikContext<BookingFormValue>();
  const initVal = fm.values.addOns[index] as AddOnCheckboxOrRadio | null;
  const [selection, setSelection] = useState<Selection>({
    checked: initVal?.[option.uuid] != null,
    qty: initVal?.[option.uuid]?.quantity != null ? `${initVal[option.uuid]?.quantity}` : option.hasQuantity ? "1" : "",
  });

  const { buildingUuid, currency } = useContext(BookingModalInfo);

  const updateFormik = useCallback(
    (newSelection: Selection) => {
      arrayHelpers.replace(index, {
        ...fm.values.addOns[index],
        [option.uuid]: newSelection.checked
          ? {
              option: option.uuid,
              quantity: newSelection.qty,
            }
          : null,
      });
      fm.setFieldTouched(`addOns[${index}]`).catch(console.error);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [arrayHelpers, fm.values.addOns, index, option.uuid],
  );

  return (
    <BreakWordDiv className="pb-3">
      <div className="d-flex justify-content-between">
        <Form.Checkbox
          className="d-flex justify-content-between align-items-start"
          label={stringNotEmpty(option.name) ? option.name : "Unknown"}
          id={option.uuid}
          defaultValue={option.uuid}
          value={selection.checked}
          onChange={(event) => {
            const newSelection = { ...selection, checked: event.target.checked };
            setSelection(newSelection);
            updateFormik(newSelection);
          }}
        />

        {!hideCost && option.unitPrice > 0 && (
          <span className="font-weight-normal flex-shrink-0">
            <CurrencyAmount
              amount={option.unitPrice}
              buildingUuid={buildingUuid}
              currencyCode={currency}
              priceIsPerItem={option.hasQuantity}
            />{" "}
          </span>
        )}
      </div>

      {option.hasQuantity && selection.checked && (
        <AddOnQuantity
          max={option.maxQuantity}
          className="mt-2 mb-3 w-25"
          value={selection.qty}
          onChange={(event) => {
            const newSelection = { ...selection, qty: event.target.value };
            setSelection(newSelection);
            updateFormik(newSelection);
          }}
        />
      )}
    </BreakWordDiv>
  );
};
