import type { Item } from "./Item";

interface Grouping {
  locked: Item[];
  custom: Item[];
}

export const GroupIt = (data: Item[]) => {
  const rconfigs = data.reduce<Grouping>(
    (prev, d) => {
      if (d.locked) {
        return { locked: prev.locked.concat(d), custom: prev.custom };
      }

      return { locked: prev.locked, custom: prev.custom.concat(d) };
    },
    { locked: [], custom: [] },
  );

  return rconfigs.locked
    .sort((a, b) => a.name.localeCompare(b.name))
    .concat(rconfigs.custom.sort((a, b) => a.name.localeCompare(b.name)));
};
