import { DateTime as DateTimeUi } from "@equiem/react-admin-ui";

import type { SiteCalendarBookingFragment } from "../../../generated/gateway-client";
import { matchesSearchText } from "../../../lib/matchesSearch";
import type { Booking } from "../../../hooks/usePagedSiteBookings";

export function getSearchTokens(b: Booking, language: string) {
  return [
    b.reference,
    b.resource.name,
    DateTimeUi.toDateString({ datetime: b.startDate, timezone: b.timezone }),
    DateTimeUi.toTimeRangeString({ start: b.startDate, end: b.endDate, timezone: b.timezone, language }),
    `${b.user.profile?.firstName} ${b.user.profile?.lastName}`,
    b.user.profile?.companyV2?.name ?? "",
    b.status.toLowerCase().replaceAll("_", " "),
  ];
}

function getCalendarSearchTokens(b: SiteCalendarBookingFragment, timezone: string, language: string) {
  return [
    b.reference,
    b.resource.name,
    DateTimeUi.toDateString({ datetime: b.startDate, timezone }),
    DateTimeUi.toTimeRangeString({ start: b.startDate, end: b.endDate, timezone, language }),
    b.status.toLowerCase().replaceAll("_", " "),
  ];
}

export function filterCalendarBookings(
  bookings: SiteCalendarBookingFragment[],
  searchText: string,
  timezone: string,
  language: string,
): SiteCalendarBookingFragment[] {
  return bookings.filter((b) => matchesSearchText(searchText, getCalendarSearchTokens(b, timezone, language)));
}
