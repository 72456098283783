import type { PropsWithChildren } from "react";
import React, { createContext, useState } from "react";

type SettingsModal = "CategoryModal" | "QueueModal" | "StatusWidget";

interface Modal {
  activeModal?: SettingsModal;
  id?: string;
  open: (type: SettingsModal, id?: string) => void;
  close: () => void;
}

export const ModalContext = createContext<Modal>({
  open: (_type: SettingsModal) => undefined,
  close: () => undefined,
});

interface Props {
  testActiveModal?: SettingsModal;
  testId?: string;
}

export const ModalProvider: React.FC<PropsWithChildren<Props>> = ({ children, testActiveModal, testId }) => {
  const [activeModal, setActiveModal] = useState<SettingsModal | undefined>(testActiveModal);
  const [id, setId] = useState<string | undefined>(testId);

  const open = (type: SettingsModal, _id?: string) => {
    setActiveModal(type);
    setId(_id);
  };

  const close = () => {
    setActiveModal(undefined);
    setId(undefined);
  };

  return (
    <ModalContext.Provider
      value={{
        open,
        close,
        activeModal,
        id,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};
