/* eslint-disable complexity */
import React, { useContext, useEffect, useMemo, useState } from "react";
import type { FieldProps } from "formik";
import { Field, isEmptyArray } from "formik";
import { DateTime } from "luxon";

import { CurrentProfile, Site, stringIsEmpty, stringNotEmpty, useSaferFormikContext } from "@equiem/lib";
import type { AppointmentWeekday } from "@equiem/lib/context/types";
import { AppointmentRecurringType } from "@equiem/lib/context/types";
import { formatters, useTranslation } from "@equiem/localisation-eq1";
import { Alert, Dropdown, Form as EqForm, Text, useIsMobileWidth, useTheme } from "@equiem/react-admin-ui";
import { RiInformationLine } from "@equiem/react-admin-ui/icons";

import { Modal } from "../../../contexts/ModalContext";
import {
  type VisitorReception,
  type VisitorReceptionQuery,
  useGetOperationsCompanyQuery,
  useVisitorManagementSiteSettingsQuery,
  VisitorAppointmentType,
} from "../../../generated/visitors-client";
import { ReceptionContext } from "../../reception/ReceptionContext";
import { initialValues } from "../initialValues";
import type { DurationDate, FormValues } from "../types";
import { getAppointmentIntervals } from "../utils/appointmentIntervals";
import { getLocalisedDurationTimeShort } from "../utils/date";
import { getMaxAllowedAppointmentDateTime } from "../utils/recurringAppointmentHelpers";
import { titleCharsCount } from "../utils/validationSchema";

import { getWeekdayOccuranceOfWeekdayInMonth } from "./recurring-settings/RecurringSettingsAuxiliaryFunctions";

export const AppointmentFormEditable = ({
  type,
  receptions,
  reception,
  receptionsLoading,
}: {
  type: "create" | "update";
  receptions: Array<VisitorReception & { name: string }>;
  reception?: VisitorReceptionQuery["visitorReception"];
  receptionsLoading: boolean;
}) => {
  const { colors, breakpoints } = useTheme();
  const isMobile = useIsMobileWidth();
  const { uuid: siteUuid, timezone } = useContext(Site);
  const modal = useContext(Modal);
  const profile = useContext(CurrentProfile);
  const { setIsWalkIn } = useContext(ReceptionContext);
  const [durationDates, setDurationDates] = useState<Map<string, DurationDate[]>>(new Map());
  const { t, i18n } = useTranslation();
  const { setFieldValue, values, errors, touched } = useSaferFormikContext<FormValues>();
  const getFieldError = (field: keyof typeof values) =>
    touched[field] === true || Array.isArray(touched[field]) ? errors[field]?.toString() : undefined;
  const shouldDisplayInformationAboutSharingNotifications =
    Boolean(values.location) && Boolean(reception?.recipients) && !isEmptyArray(reception?.recipients);
  const enableHoldInLobby =
    Boolean(values.location) &&
    (Boolean(reception?.enableHoldInLobby) || (type === "update" && Boolean(values.holdInLobby)));

  const isBookingAppointment = values.bookingInfo?.uuid != null;
  const currentDate = useMemo(() => DateTime.now().toISODate(), []);
  const { data: companySettings } = useGetOperationsCompanyQuery({
    variables: { companyUuid: values.host.uuid ?? values.organizer.uuid },
    fetchPolicy: "cache-and-network",
  });
  const { data: vmSiteSettings, loading: vmSiteSettingsLoading } = useVisitorManagementSiteSettingsQuery({
    variables: {
      // for booking appointments we should use resource's destination site
      siteUuid:
        values.bookingInfo?.resourceDestinationUuid != null ? values.bookingInfo.resourceDestinationUuid : siteUuid,
    },
    fetchPolicy: "network-only",
  });
  const maxAllowedDate = useMemo(
    () =>
      getMaxAllowedAppointmentDateTime(
        companySettings?.operationsCompany?.maxAppointmentCreationMonths,
        vmSiteSettings?.visitorManagementSiteSettings.maxAppointmentCreationMonths,
        timezone,
      ),
    [
      companySettings?.operationsCompany?.maxAppointmentCreationMonths,
      timezone,
      vmSiteSettings?.visitorManagementSiteSettings.maxAppointmentCreationMonths,
    ],
  );

  const getBookingAppointmentLocation = () => {
    const result = [];

    if (values.bookingInfo?.resource?.building?.name != null) {
      result.push(values.bookingInfo.resource.building.name);
    }

    if (values.bookingInfo?.resource?.level?.name != null) {
      result.push(values.bookingInfo.resource.level.name);
    }

    return result.join(", ");
  };

  const isTimeBeyondIntervals = (date: DateTime) => {
    const intervals = getAppointmentIntervals(values.startTime, values.date, i18n.language);
    const currentLocalTime = DateTime.local();
    const startTimeObj = DateTime.fromSeconds(Number(values.startTime)).toObject();
    const startDateTime = DateTime.fromObject({ ...date.toObject(), ...startTimeObj });

    const isSameDate = date.hasSame(currentLocalTime, "day");
    const isLaterTime = currentLocalTime.toSeconds() > startDateTime.toSeconds();
    const isFieldPristine = type === "create" && touched.date !== true && touched.startTime !== true;

    return intervals.size <= 1 && isSameDate && isLaterTime && isFieldPristine;
  };

  useEffect(() => {
    if (!enableHoldInLobby) {
      void setFieldValue("holdInLobby", false);
    }
  }, [setFieldValue, enableHoldInLobby]);

  useEffect(() => {
    void setFieldValue("enabledHoldInLobby", enableHoldInLobby);
  }, [setFieldValue, enableHoldInLobby]);

  useEffect(() => {
    setIsWalkIn?.(values.isWalkIn);
  }, [values.isWalkIn, setIsWalkIn]);

  const visitorTypesOptions = useMemo(() => {
    const appointmentType = values.isWalkIn ? VisitorAppointmentType.WalkIn : VisitorAppointmentType.PreBooked;
    return vmSiteSettings?.visitorManagementSiteSettings.visitorTypes?.filter((item) =>
      item.appointmentTypes?.includes(appointmentType),
    );
  }, [vmSiteSettings?.visitorManagementSiteSettings.visitorTypes, values.isWalkIn]);

  const onRecurringTypeChangedFromForm = (value?: AppointmentRecurringType | undefined | null | "current") => {
    if (value == null) {
      void setFieldValue("recurringSettings", undefined, false);
      void setFieldValue("recurringType", undefined, false);
      return;
    }

    if (value === "current") {
      modal.open("AppointmentRecurringSettings");
    } else {
      void setFieldValue("recurringType", value, false).then(() => modal.open("AppointmentRecurringSettings"));
    }
  };

  useEffect(() => {
    if (
      profile.profile == null ||
      stringNotEmpty(values.organizer.userUuid) ||
      stringNotEmpty(values.host.userUuid) ||
      type === "update"
    ) {
      return;
    }

    void setFieldValue("organizer", {
      firstName: profile.profile.firstName ?? "",
      lastName: profile.profile.lastName ?? "",
      company: {
        uuid: profile.profile.companyV2?.uuid ?? "",
        name: profile.profile.companyV2?.name ?? "",
      },
      email: profile.profile.email,
      avatar: profile.profile.avatar ?? "",
      userUuid: profile.profile.uuid,
    }).catch(console.error);

    void setFieldValue("host", {
      firstName: profile.profile.firstName ?? "",
      lastName: profile.profile.lastName ?? "",
      company: {
        uuid: profile.profile.companyV2?.uuid ?? "",
        name: profile.profile.companyV2?.name ?? "",
      },
      email: profile.profile.email,
      avatar: profile.profile.avatar ?? "",
      userUuid: profile.profile.uuid,
    }).catch(console.error);
  }, [profile, setFieldValue, values.host.userUuid, values.organizer.userUuid, type]);

  useEffect(() => {
    if (type === "create" && touched.date !== true && stringIsEmpty(values.date)) {
      setFieldValue("date", currentDate).catch(console.error);
    }
  }, [type, currentDate, touched, setFieldValue]);

  useEffect(() => {
    if (
      vmSiteSettings?.visitorManagementSiteSettings.isVisitorTypesEnabled === true &&
      (visitorTypesOptions?.length ?? 0) > 0
    ) {
      const defaultValue = visitorTypesOptions?.find((item) => item.isDefault);
      const isVisitorTypesDefined =
        visitorTypesOptions?.find((item) => item.uuid === values.visitorTypeUuid) != null &&
        values.visitorTypeUuid != null;

      setFieldValue(
        "visitorTypeUuid",
        isVisitorTypesDefined ? values.visitorTypeUuid : (defaultValue?.uuid as string),
      ).catch(console.error);
    }
  }, [
    values.visitorTypeUuid,
    vmSiteSettings?.visitorManagementSiteSettings.isVisitorTypesEnabled,
    visitorTypesOptions,
    setFieldValue,
  ]);

  useEffect(() => {
    const date = DateTime.fromFormat(values.date, "yyyy-MM-dd");

    if (isTimeBeyondIntervals(date)) {
      const newDate = date.plus({ days: 1 });
      const startTime = DateTime.fromObject({
        hour: 0,
        minute: 0,
        second: 0,
        year: newDate.year,
        month: newDate.month,
        day: newDate.day,
      });

      setFieldValue("date", newDate.toFormat("yyyy-MM-dd")).catch(console.error);
      setFieldValue("startTime", startTime.toSeconds()).catch(console.error);
    }
  }, [values.startTime, touched.date, touched.startTime, type, values.date, setFieldValue]);

  useEffect(() => {
    if (values.startTime === initialValues.startTime && values.date === initialValues.date) {
      return;
    }
    const intervals = getAppointmentIntervals(values.startTime, values.date, i18n.language);

    const endTime = DateTime.fromFormat(`${values.date} ${values.duration}`, "yyyy-LL-dd h:mma").toMillis();
    const duration = intervals.get(endTime)?.value;

    const durations = Array.from(intervals.values());
    setDurationDates((dates) => {
      dates.set(values.date, durations);
      return new Map(dates);
    });

    if (duration == null) {
      const defaultEndTime = DateTime.local().set({ hour: 18, minute: 0, second: 0, millisecond: 0 });
      if (intervals.size > 0 && defaultEndTime.diffNow().as("milliseconds") > 0) {
        setFieldValue("duration", intervals.get(defaultEndTime.toMillis())?.value ?? "").catch(console.error);
      } else {
        setFieldValue("duration", "").catch(console.error);
      }
    }
  }, [values.startTime, values.date, i18n.language, values.duration, setFieldValue]);

  useEffect(() => {
    values.additionalDates.forEach(({ startTime, date, duration }, index) => {
      const intervals = getAppointmentIntervals(startTime, date, i18n.language);
      const durations = Array.from(intervals.values());

      let durationValue = durations[0].value;

      if (duration.length > 0) {
        const value = durations.find((d) => d.value === duration)?.value;
        if (value != null) {
          durationValue = value;
        }
      }

      setDurationDates((dates) => {
        dates.set(date, durations);
        return new Map(dates);
      });

      // eslint-disable-next-line @typescript-eslint/prefer-ts-expect-error
      // @ts-ignore
      void setFieldValue(`additionalDates.${index}.duration`, durationValue, false);
    });
  }, [values.additionalDates, i18n.language, setFieldValue]);

  const weekDayMapShortened: Record<AppointmentWeekday, string> = {
    "1": t("visitors.appointmentForm.appointmentRecurringSettingsMondayShortened"),
    "2": t("visitors.appointmentForm.appointmentRecurringSettingsTuesdayShortened"),
    "3": t("visitors.appointmentForm.appointmentRecurringSettingsWednesdayShortened"),
    "4": t("visitors.appointmentForm.appointmentRecurringSettingsThursdayShortened"),
    "5": t("visitors.appointmentForm.appointmentRecurringSettingsFridayFriday"),
    "6": t("visitors.appointmentForm.appointmentRecurringSettingsSaturdayShortened"),
    "7": t("visitors.appointmentForm.appointmentRecurringSettingsSundayShortened"),
  };

  const weekDayMapShortenedNumber: Record<AppointmentWeekday, string> = {
    1: t("visitors.appointmentForm.appointmentRecurringSettingsMondayShortened"),
    2: t("visitors.appointmentForm.appointmentRecurringSettingsTuesdayShortened"),
    3: t("visitors.appointmentForm.appointmentRecurringSettingsWednesdayShortened"),
    4: t("visitors.appointmentForm.appointmentRecurringSettingsThursdayShortened"),
    5: t("visitors.appointmentForm.appointmentRecurringSettingsFridayFriday"),
    6: t("visitors.appointmentForm.appointmentRecurringSettingsSaturdayShortened"),
    7: t("visitors.appointmentForm.appointmentRecurringSettingsSundayShortened"),
  };

  const getAppointmentRecurringTypeTitle = () => {
    if (values.recurringType == null || values.recurringSettings?.recurringType == null) {
      return t("visitors.appointmentForm.appointmentRepeatTypeNone");
    }

    if (values.recurringSettings.recurringType === AppointmentRecurringType.Weekly) {
      return t("visitors.appointmentForm.appointmentRecurringSettingsSelectorWeekly", {
        repeatType: t("visitors.appointmentForm.appointmentRepeatTypeWeekly"),
        days: values.recurringSettings.repeatOn?.map((x: AppointmentWeekday) => weekDayMapShortened[x]).join(" ") ?? "",
        recurringEndDate: formatters.dateshort(DateTime.fromMillis(values.recurringSettings.endDate), i18n.language),
      });
    } else {
      return t("visitors.appointmentForm.appointmentRecurringSettingsSelector", {
        repeatType:
          values.recurringSettings.recurringType === AppointmentRecurringType.Daily
            ? t("visitors.appointmentForm.appointmentRepeatTypeDaily")
            : t("visitors.appointmentForm.appointmentRepeatTypeMonthly"),
        recurringEndDate: formatters.dateshort(DateTime.fromMillis(values.recurringSettings.endDate), i18n.language),
      });
    }
  };

  const getSummaryText = (): string => {
    if (values.recurringSettings?.recurringType == null || values.recurringSettings.recurringDates.length === 0) {
      return "";
    }

    const date = DateTime.fromSeconds(Number(values.date));

    const isSingular = values.recurringSettings.repeatEvery === 1;

    let repeatTypeEndStr: string =
      values.recurringSettings.recurringType === AppointmentRecurringType.Daily
        ? isSingular
          ? t("visitors.appointmentForm.appointmentRecurringOccursEveryDay").toLocaleLowerCase()
          : t("visitors.appointmentForm.appointmentRecurringOccursEveryDays").toLocaleLowerCase()
        : values.recurringSettings.recurringType === AppointmentRecurringType.Weekly
        ? isSingular
          ? t("visitors.appointmentForm.appointmentRecurringOccursEveryWeek").toLocaleLowerCase()
          : t("visitors.appointmentForm.appointmentRecurringOccursEveryWeeks").toLocaleLowerCase()
        : values.recurringSettings.recurringType === AppointmentRecurringType.Monthly
        ? isSingular
          ? t("visitors.appointmentForm.appointmentRecurringOccursEveryMonth").toLocaleLowerCase()
          : t("visitors.appointmentForm.appointmentRecurringOccursEveryMonths").toLocaleLowerCase()
        : "placeholder";

    const repeatTypeEndStrWeekly = values.recurringSettings.repeatOn
      ?.sort((a, b) => a - b)
      .map((x, i, arr) =>
        i === arr.length - 1 && arr.length !== 1
          ? `and ${weekDayMapShortened[x as AppointmentWeekday]}`
          : `${weekDayMapShortened[x as AppointmentWeekday]} `,
      )
      .join("");

    let repeatEveryStr = values.recurringSettings.repeatEvery === 1 ? "" : values.recurringSettings.repeatEvery;

    if (values.recurringSettings.sameWeekDayEachMonth === true) {
      repeatTypeEndStr =
        weekDayMapShortenedNumber[
          DateTime.fromMillis(values.recurringSettings.startDate).weekday as AppointmentWeekday
        ];
      repeatEveryStr = getWeekdayOccuranceOfWeekdayInMonth(DateTime.fromMillis(values.recurringSettings.startDate));
    }

    if (values.recurringSettings.lastWeekDayEachMonth === true) {
      repeatTypeEndStr =
        weekDayMapShortenedNumber[
          DateTime.fromMillis(values.recurringSettings.startDate).weekday as AppointmentWeekday
        ];
    }

    const repeatType: string =
      values.recurringSettings.recurringType === AppointmentRecurringType.Daily ||
      values.recurringSettings.recurringType === AppointmentRecurringType.Monthly
        ? `${
            values.recurringSettings.lastWeekDayEachMonth === true
              ? t("visitors.appointmentForm.appointmentRecurringLast")
              : repeatEveryStr
          } ${repeatTypeEndStr}`
        : `${repeatEveryStr} ${repeatTypeEndStrWeekly}`;

    return t("visitors.appointmentForm.appointmentRecurringSettingsText", {
      repeatType: repeatType,
      startTime: formatters.timeshort(DateTime.fromSeconds(Number(values.startTime)), i18n.language),
      endTime: getLocalisedDurationTimeShort(values.duration, i18n.language),
      recurringEndDate: formatters.datelong(
        type === "update"
          ? values.recurringInfo?.appointments
              .map((x) => x.startDate)
              .sort()
              .at(-1) ?? date
          : values.recurringSettings.recurringDates.at(-1) ?? date,
        i18n.language,
      ),
      times: values.recurringSettings.recurringDates.length + 1,
    });
  };

  const isRecurringSettingsDisabled = (): boolean => {
    return stringIsEmpty(values.duration) || isNaN(values.startTime);
  };

  return (
    <>
      <EqForm.Group
        label={t("visitors.appointmentForm.title")}
        error={getFieldError("title")}
        required
        hint={t("common.remainingCharacters", { count: titleCharsCount - values.title.length })}
      >
        <Field
          placeholder={t("visitors.appointmentForm.titlePlaceholder")}
          type="text"
          name="title"
          as={EqForm.Input}
          maxLength={titleCharsCount}
        />
      </EqForm.Group>
      <EqForm.Group
        label={t("visitors.appointmentForm.descrption")}
        showTooltip={true}
        tooltipText={t("visitors.appointmentForm.descriptionTooltip")}
      >
        <Field
          as={EqForm.Textarea}
          name="description"
          placeholder={t("visitors.appointmentForm.descriptionPlaceholder")}
        />
      </EqForm.Group>
      <EqForm.Group
        label={t("visitors.common.location")}
        error={
          receptions.length === 0 && !receptionsLoading
            ? t("visitors.appointmentForm.noBuildingsError")
            : getFieldError("location")
        }
        required={!isBookingAppointment}
      >
        {isBookingAppointment ? (
          getBookingAppointmentLocation()
        ) : (
          <>
            <Field name="location" value={values.location}>
              {({ field }: FieldProps<string, FormValues>) => (
                <EqForm.Select {...field} disabled={receptionsLoading}>
                  {receptionsLoading ? (
                    <option value="">{t("common.loading")}...</option>
                  ) : (
                    <>
                      <option value="">
                        {receptions.length === 0 ? t("common.emptyNone") : t("visitors.appointmentForm.locationSelect")}
                      </option>
                      {receptions.map((rec) => (
                        <option key={rec.uuid} value={rec.uuid}>
                          {rec.name}
                        </option>
                      ))}
                    </>
                  )}
                </EqForm.Select>
              )}
            </Field>
            {shouldDisplayInformationAboutSharingNotifications && (
              <Text className="info-share-notifications" variant="text" size="small">
                <div className="text-info">
                  <RiInformationLine size={20} color={colors.grayscale["50"]} />
                  <span>{t("visitors.appointmentForm.sharedNotifications")}</span>
                </div>
              </Text>
            )}
          </>
        )}
      </EqForm.Group>

      <div className="d-flex datetime-picker">
        <EqForm.Group label={t("common.date")} error={getFieldError("date")} required>
          {isBookingAppointment || values.isWalkIn ? (
            DateTime.fromFormat(values.date, "yyyy-MM-dd").toFormat("dd/MM/yyyy")
          ) : (
            <Field
              type="date"
              name="date"
              value={values.date}
              as={EqForm.Input}
              disabled={isBookingAppointment}
              min={currentDate}
              max={maxAllowedDate.toFormat("yyyy-MM-dd")}
            />
          )}
        </EqForm.Group>

        <EqForm.Group label={t("visitors.appointmentForm.startTime")} error={getFieldError("startTime")} required>
          {isBookingAppointment || values.isWalkIn ? (
            formatters.timeshort(DateTime.fromSeconds(values.startTime), i18n.language)
          ) : (
            <Field
              as={EqForm.TimeSelect}
              language={i18n.language}
              setFieldValue={setFieldValue}
              type={type}
              diffMinutes={15}
              startTime={{ hour: 0, minute: 0 }}
              endTime={{ hour: 23, minute: 45 }}
              name="startTime"
              defaultOptionValue={t("visitors.appointmentForm.startTime")}
            />
          )}
        </EqForm.Group>

        <EqForm.Group label={t("visitors.appointmentForm.duration")} error={getFieldError("duration")} required>
          {isBookingAppointment ? (
            durationDates.get(values.date)?.find(({ value }) => value === values.duration)?.label
          ) : (
            <Field as={EqForm.Select} name="duration">
              <option value="">{t("visitors.appointmentForm.durationSelect")}</option>
              {durationDates.get(values.date)?.map((date) => (
                <option key={`${date.label}_${date.value}`} value={date.value}>
                  {date.label}
                </option>
              ))}
            </Field>
          )}
        </EqForm.Group>
      </div>

      {!values.isWalkIn && (
        <>
          {!isBookingAppointment && (
            <>
              <div className="d-flex align-items-start mb-4">
                <Dropdown.Button
                  size="sm"
                  title={<span className="recurring-button-title">{getAppointmentRecurringTypeTitle()}</span>}
                  disabled={isRecurringSettingsDisabled()}
                  variant="ghost"
                >
                  <Dropdown.Item
                    selected={values.recurringSettings?.recurringType == null}
                    onClick={() => onRecurringTypeChangedFromForm()}
                    disabled={values.recurringInfo?.uuid != null}
                  >
                    {t("visitors.appointmentForm.appointmentRepeatTypeNone")}
                  </Dropdown.Item>
                  <Dropdown.Item
                    disabled={values.recurringInfo?.uuid != null}
                    onClick={() => onRecurringTypeChangedFromForm(AppointmentRecurringType.Daily)}
                  >
                    {t("visitors.appointmentForm.appointmentRepeatTypeDaily")}
                  </Dropdown.Item>
                  <Dropdown.Item
                    disabled={values.recurringInfo?.uuid != null}
                    onClick={() => onRecurringTypeChangedFromForm(AppointmentRecurringType.Weekly)}
                  >
                    {t("visitors.appointmentForm.appointmentRepeatTypeWeekly")}
                  </Dropdown.Item>
                  <Dropdown.Item
                    disabled={values.recurringInfo?.uuid != null}
                    onClick={() => onRecurringTypeChangedFromForm(AppointmentRecurringType.Monthly)}
                  >
                    {t("visitors.appointmentForm.appointmentRepeatTypeMonthly")}
                  </Dropdown.Item>
                  {values.recurringSettings != null ? (
                    <Dropdown.Item selected={true} onClick={() => onRecurringTypeChangedFromForm("current")}>
                      {getAppointmentRecurringTypeTitle()}
                    </Dropdown.Item>
                  ) : null}
                </Dropdown.Button>
              </div>
              <div className="recurring-text">
                <label className="summary-label">{getSummaryText()}</label>
              </div>
            </>
          )}
          {isBookingAppointment && (
            <Alert
              variant="gray"
              className="p-4"
              message={t("visitors.appointmentForm.cannotEditBookingInformation")}
              icon={<RiInformationLine size={24} color={colors.grayscale[60]} />}
            />
          )}
          {enableHoldInLobby && (
            <>
              <div className="mb-6">
                <EqForm.Group
                  key="holdInLobby"
                  label={t("visitors.settings.holdInLobby")}
                  className="mb-0"
                  showTooltip={true}
                  tooltipText={t("visitors.settings.holdInLobbyTooltip")}
                  error={getFieldError("holdInLobby")}
                  hasError={errors.holdInLobby != null}
                >
                  <label htmlFor="holdInLobby" className="d-flex hold-in-lobby">
                    <Field
                      type="checkbox"
                      id="holdInLobby"
                      name="holdInLobby"
                      label={t("visitors.settings.holdInLobbyLabel")}
                      disabled={type === "update"}
                    />
                    <span className="ml-3 d-flex align-self-center">{t("visitors.settings.holdInLobbyLabel")}</span>
                  </label>
                </EqForm.Group>
                {values.host.email == null && (
                  <Alert
                    variant="gray"
                    className="p-4 mt-5"
                    message={t("visitors.settings.holdInLobbyHostEmailAlert")}
                    icon={<RiInformationLine size={24} color={colors.grayscale[60]} />}
                  />
                )}
              </div>
              <hr className="custom-liner" />
            </>
          )}
        </>
      )}

      {vmSiteSettings?.visitorManagementSiteSettings.isVisitorTypesEnabled === true && (
        <EqForm.Group label={t("visitors.common.visitorsType")} className="pt-5" required>
          <Field>
            {({ field }: FieldProps<string, FormValues>) => (
              <EqForm.Select
                {...field}
                className="visitor-type"
                name="visitorTypeUuid"
                value={values.visitorTypeUuid as string}
                disabled={vmSiteSettingsLoading || (values.isWalkIn && type === "update")}
              >
                {vmSiteSettingsLoading ? (
                  <option value="">{t("common.loading")}...</option>
                ) : (
                  <>
                    {visitorTypesOptions?.map((item) => (
                      <option key={item.uuid} value={item.uuid as string}>
                        {item.name}
                      </option>
                    ))}
                  </>
                )}
              </EqForm.Select>
            )}
          </Field>
        </EqForm.Group>
      )}

      <div className="pt-2">
        <EqForm.Group
          label={t("visitors.appointmentForm.receptionNotes", {
            prefix: reception?.building?.name ?? values.bookingInfo?.resource?.building?.name ?? "",
          })}
          showTooltip={true}
          tooltipText={t("visitors.appointmentForm.receptionNotesTooltip")}
        >
          <Field
            as={EqForm.Textarea}
            name="buildingReceptionNotes"
            placeholder={t("visitors.appointmentForm.receptionNotesPlaceholder")}
          />
        </EqForm.Group>

        {!isBookingAppointment && (
          <EqForm.Group
            label={t("visitors.appointmentForm.receptionNotes", { prefix: values.host.company?.name ?? "" })}
            showTooltip={true}
            tooltipText={t("visitors.appointmentForm.companyReceptionNotesTooltip")}
          >
            <Field
              as={EqForm.Textarea}
              name="companyReceptionNotes"
              placeholder={t("visitors.appointmentForm.receptionNotesPlaceholder")}
            />
          </EqForm.Group>
        )}
        <EqForm.Group
          label={t("visitors.appointmentForm.visitorNotes")}
          showTooltip={true}
          tooltipText={t("visitors.appointmentForm.visitorNotesTooltip")}
        >
          <Field
            as={EqForm.Textarea}
            name="visitorNotes"
            placeholder={t("visitors.appointmentForm.visitorNotesPlaceholder")}
          />
        </EqForm.Group>
      </div>
      <style jsx>
        {`
        .hold-in-lobby :global(input:disabled + span) {
          color: ${colors.grayscale[20]};
        }
        .custom-liner {
          border: 0.01rem solid ${colors.grayscale[10]};
        }

        .text-info {
          background: #f2f2f2;
          border-radius: 4px;
          padding: 0.75rem;
          margin-bottom: 0.5rem;
          display: flex;
          align-items: center;
        }

        .text-info :global(svg) {
          flex-shrink: 0;
          margin-right: 0.75rem;
        }

        .preview-date {
          line-height: 22px;
          padding: 8px 0;
        }

        .duration-group-main,
        .duration-group {
          gap: 8px;
          display: grid;
          align-items: center;
        }

        .duration-group-main {
          grid-template-columns: 1fr;
        }

        .duration-group {
          grid-template-columns: 1fr 32px;
        }

        .datetime-picker {
          flex-wrap: nowrap;
          gap: 8px;
        }

        .datetime-picker :global(.form-group) {
          margin-bottom: 18px;
        }

        .visitor-notes {
          border-top: 1px solid #e6e6e6;
        }

        .check-notify {
          font-size: 14px;
          display: flex;
          align-items: center;
        }

        .title-hint {
          font-weight: 400;
          font-size: 12px;
          color: #666666;
          margin-top: 5px;
        }

        .text-overflow {
          width: 255px !important;
          overflow: hidden;
          white-space: nowrap;
          display: block;
          text-overflow: ellipsis;
          text-wrap: nowrap;
          text-align: left;
        }

        .recurring-button-title {
          text-align: left;
          text-wrap: wrap;
          flex-wrap: ${isMobile ? "wrap" : "no-wrap"};
        }

        .summary-label {
          flex: 1;
          font-weight: 400;
          color: rgba(102, 102, 102, 1);
        }

        @media (max-width: ${breakpoints.md}px) {
          .datetime-picker {
            flex-wrap: wrap;
          }
          .datetime-picker :global(.form-group:nth-child(2)),
          .datetime-picker :global(.form-group:nth-child(3)) {
            width: 50%;
            flex: 1;
          }

          :global(.visitor-type:disabled) {
            background-color: ${colors.grayscale[3]};
          }
        `}
      </style>
    </>
  );
};
