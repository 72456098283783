import type { ReactNode } from "react";
import React, { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useRouter } from "next/router";

import { UrlParams, useSiteContext } from "@equiem/lib";
import type { ProfileStatus } from "@equiem/lib/generated/gateway-client";
import type { FilterValue } from "@equiem/react-admin-ui";
import { useDebounced } from "@equiem/react-admin-ui";

import type { UserFilterV2 } from "../../../generated/settings-client";
import { mapFiltersToUserFilter } from "../utils/mapping";

type UserFilterType =
  | "name"
  | "email"
  | "userType"
  | "emailVerified"
  | "company"
  | "active"
  | "status"
  | "profileLastActiveAt"
  | "profileCreatedAt"
  | "emailSubscription"
  | "group"
  | "interests"
  | "site"
  | "authorizationRoles"
  | "regionalFlexTenants";

export type UserFiltersResult = Partial<Record<UserFilterType, FilterValue>>;

export type PredefinedTabFilters = ProfileStatus | "all";

export const userStatusTabs: PredefinedTabFilters[] = ["all", "ACTIVE", "DEACTIVATED", "PENDING_APPROVAL"];

export interface UsersFilterContext {
  filters: UserFiltersResult;
  userFilter: UserFilterV2;
  setFilters?: (filters: UserFiltersResult) => void;
  search?: string;
  debouncedSearch?: string;
  setSearch?: (search: string) => void;
  selectedTab: PredefinedTabFilters;
  setSelectedTab?: (tab: PredefinedTabFilters) => void;
}

export const UsersFilterContext = createContext<UsersFilterContext>({
  filters: {},
  userFilter: {},
  setFilters: undefined,
  search: undefined,
  debouncedSearch: undefined,
  setSearch: undefined,
  selectedTab: userStatusTabs[0],
  setSelectedTab: undefined,
});

export const UsersFilterProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const router = useRouter();
  const searchFromQueryParams = router.query.search as string | undefined;
  const { setParam, deleteParam } = useContext(UrlParams);
  const { uuid } = useSiteContext();
  const [filters, setFilters] = useState<UserFiltersResult>({});
  const [search, setSearch] = useState<string>(searchFromQueryParams ?? "");
  const [selectedTab, setSelectedTab] = useState<PredefinedTabFilters>(
    router.query.tab != null && router.query.tab.length > 0
      ? (router.query.tab as PredefinedTabFilters)
      : userStatusTabs[0],
  );
  const debouncedSearch = useDebounced(search, 500);
  const debouncedFilters = useDebounced(filters, 500);

  // react on tab reset
  useEffect(() => {
    if (router.query.tab === undefined) {
      setSelectedTab(userStatusTabs[0]);
    }
  }, [router.query.tab]);

  // react on tab selection change
  useEffect(() => {
    if (router.query.tab !== selectedTab) {
      setParam("tab", selectedTab);
    }
  }, [selectedTab]);

  useEffect(() => {
    if (debouncedSearch.length > 0) {
      if (debouncedSearch !== router.query.search) {
        setParam("search", search);
      }
    } else if (router.query.search != null) {
      deleteParam("search");
    }
  }, [debouncedSearch]);

  const userFilter: UserFilterV2 = useMemo(() => {
    const f = mapFiltersToUserFilter(debouncedFilters, debouncedSearch, selectedTab);

    return {
      ...f,
      site: uuid,
    };
  }, [debouncedSearch, debouncedFilters, selectedTab, uuid]);

  return (
    <UsersFilterContext.Provider
      value={{
        filters,
        userFilter,
        setFilters,
        search,
        debouncedSearch,
        setSearch,
        selectedTab,
        setSelectedTab,
      }}
    >
      {children}
    </UsersFilterContext.Provider>
  );
};
