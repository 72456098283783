import { Site } from "@equiem/lib";
import { useTheme } from "@equiem/react-admin-ui";
import { QRCodeCanvas } from "qrcode.react";
import React, { useContext, useRef } from "react";
import { useReactToPrint } from "react-to-print";

export const useResourceQrCode = ({ resourceName, resourceUuid }: { resourceName: string; resourceUuid: string }) => {
  const site = useContext(Site);
  const qrCodeRef = useRef<HTMLDivElement | null>(null);
  const { colors, sizes } = useTheme();

  const generateQrCode = () => {
    return (
      <div className="parent">
        <div ref={qrCodeRef} style={{ display: "flex", alignItems: "center", height: "100%" }}>
          <div
            style={{
              border: `1px ${colors.dark}  solid`,
              width: "550px",
              borderRadius: "5px",
              margin: "auto",
              padding: sizes.lg,
              verticalAlign: "middle",
            }}
          >
            <div className="mb-4" style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
              <QRCodeCanvas
                value={`https://${site.equiemOneUrl}/links/resource/${resourceUuid}?booking=1`}
                size={300}
              />
            </div>
            <div className="pt-6" style={{ fontSize: "50px", textAlign: "center", fontWeight: 600 }}>
              {resourceName}
            </div>
          </div>
        </div>
        <style jsx>{`
          .parent {
            position: absolute;
            top: -5000px;
            left: -5000px;
          }
        `}</style>
      </div>
    );
  };

  const downloadQrCode = useReactToPrint({
    content: () => qrCodeRef.current,
    documentTitle: `${resourceName}-QR-Code`,
  });

  return {
    generateQrCode,
    downloadQrCode,
  };
};
