import React, { useCallback, useContext, useEffect, useState } from "react";
import type { FormikProps } from "formik";

import { useTranslation } from "@equiem/localisation-eq1";
import { Button, Modal, useTheme } from "@equiem/react-admin-ui";
import type { IImage } from "@equiem/uploader";

import { ImageUploader } from "../../../components/ImageUploader";
import { ModalChild as ModalContext } from "../../../contexts/ModalChildContext";
import type { FormValues } from "../types";

export const AvatarUploadWidget: React.FC<{
  setFieldValue: FormikProps<FormValues>["setFieldValue"];
  value?: string;
}> = ({ setFieldValue, value }) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const modal = useContext(ModalContext);
  const { spacers } = useTheme();

  useEffect(() => {
    if (modal.activeModal === "AvatarUpload") {
      setShowModal(true);
    }
  }, [modal.activeModal]);

  const initialValues: IImage[] = [
    {
      alt: "",
      key: "",
      title: "",
      thumbnail_key: "",
    },
  ];

  const onClose = useCallback(() => {
    setShowModal(false);
    modal.close();
  }, [setShowModal, modal]);

  const updateAvatar = (img: IImage[]) => {
    setFieldValue("avatar", img[0].url).catch(console.error);
  };

  return (
    <>
      <Modal.Dialog
        title={t("common.image")}
        show={showModal}
        onHide={onClose}
        hideOnEsc={true}
        hideOnClick={true}
        size="md"
        containerClassName="avatar-upload-modal"
      >
        <Modal.Header closeButton={false} noBorder={true} />
        <Modal.Body>
          <ImageUploader value={initialValues} onChange={updateAvatar} />
        </Modal.Body>
        <Modal.Footer>
          {value != null && value !== "" ? (
            <Button variant="primary" className="mr-4" onClick={onClose}>
              {t("common.done")}
            </Button>
          ) : (
            <Button variant="ghost" className="mr-4" onClick={onClose}>
              {t("common.cancel")}
            </Button>
          )}
        </Modal.Footer>
      </Modal.Dialog>

      <style jsx>{`
        .content {
          padding: 0 ${spacers.s6} 0;
        }
      `}</style>
    </>
  );
};
