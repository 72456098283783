import React, { useEffect, useRef } from "react";

import { useTranslation } from "@equiem/localisation-eq1";
import { useToast } from "@equiem/react-admin-ui";
import { RiErrorWarningLine } from "@equiem/react-admin-ui/icons";

export const MAX_LIMIT_REACH = 200;

export function useWarningLimitReachVisitorsAlert<T = unknown>(visitors: T[], limitAmount = MAX_LIMIT_REACH) {
  const { t } = useTranslation();
  const toast = useToast();
  const toastRef = useRef<ReturnType<typeof toast.negative>>();

  useEffect(() => {
    if (visitors.length > MAX_LIMIT_REACH) {
      toastRef.current = toast.negative(t("visitors.csv.maximumLimitReached", { amount: MAX_LIMIT_REACH }), {
        icon: <RiErrorWarningLine size={18} />,
      });
    }

    if (visitors.length <= MAX_LIMIT_REACH && toastRef.current != null) {
      toastRef.current.remove();
      toastRef.current = undefined;
    }
  }, [visitors.length]);

  useEffect(() => {
    return () => {
      toastRef.current?.remove();
    };
  }, []);

  return { limitAmount, isDisabled: visitors.length > limitAmount };
}
