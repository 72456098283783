import { Form } from "@equiem/react-admin-ui";
import type { FC } from "react";
import { useCallback, useContext, useMemo } from "react";
import type { Invite } from "./NewInvitesContext";
import { NewInvites } from "./NewInvitesContext";
import { useTranslation } from "@equiem/localisation-eq1";
import { useSiteGroups } from "./hooks/useSiteGroups";

export const InviteGroupWidget: FC<{ invite: Invite; disabled: boolean }> = ({ invite, disabled }) => {
  const { updateGroups } = useContext(NewInvites);
  const options = useSiteGroups();
  const { t } = useTranslation();

  const onChange = useCallback(
    (e: { target: { value: string[] } }) => {
      updateGroups(invite.id, e.target.value);
    },
    [updateGroups, invite.id],
  );

  const getValue = useMemo(() => {
    if (options.length > 0) {
      return options.filter((option) => invite.groups.find((r) => r === option.value)).map((opt) => opt.value);
    } else {
      return [];
    }
  }, [options, invite.groups]);

  return (
    <Form.MultiSelect
      size="small"
      placeholder={t("home.widgets.addGroups")}
      disabled={disabled}
      options={options}
      value={getValue}
      onChange={onChange}
      className="group-selector"
      facade="input-no-border"
    />
  );
};
