import React, { useCallback, useContext, useEffect, useState } from "react";

import { useTranslation } from "@equiem/localisation-eq1";
import { Button, Form as EqForm, Form, Modal, useTheme, useToast } from "@equiem/react-admin-ui";

import { Modal as ModalContext } from "../../../contexts/ModalContext";
import { useResendAppointmentCodeEmailMutation } from "../../../generated/visitors-client";
import validateEmail from "../../../helpers/validateEmail";

interface Props {
  id: string;
  email?: string;
  fullName: string;
}

export const VisitorResendCodeWidget: React.FC<Props> = ({ id, email, fullName }) => {
  const { t } = useTranslation();
  const { spacers } = useTheme();
  const [visitorEmail, setVisitorEmail] = useState(email);
  const [showModal, setShowModal] = useState(false);
  const modal = useContext(ModalContext);
  const toast = useToast();
  const [mutation] = useResendAppointmentCodeEmailMutation();

  useEffect(() => {
    if (modal.activeModal === "VisitorResendCode" && modal.id === id) {
      setShowModal(true);
    }
  }, [modal.activeModal, modal.id, id]);

  const onClose = useCallback(() => {
    setShowModal(false);
    modal.close();
  }, [setShowModal, modal]);

  const handleChange = (event: string) => {
    setVisitorEmail(event);
  };

  const onResend = useCallback(async () => {
    const result = await mutation({
      variables: {
        visitorUuid: id,
        email: visitorEmail,
      },
    });

    if (
      result.data?.resendAppointmentCodeEmail != null &&
      result.errors == null &&
      result.data.resendAppointmentCodeEmail.success
    ) {
      toast.positive(
        t("visitors.reception.resendAppointmentCodeEmailSuccess", {
          email: visitorEmail ?? "",
        }),
      );
    } else {
      toast.negative(t("common.unknownError"));
    }

    onClose();
  }, [email, id, mutation, onClose, t, toast, visitorEmail]);

  return (
    <>
      <Modal.Dialog
        title={t("visitors.reception.resendAppointmentCodeEmail")}
        show={showModal}
        hideOnEsc={false}
        hideOnClick={false}
        focusTrapOptions={{ initialFocus: false }}
        size="md"
        centered
        scrollable
      >
        <>
          <Modal.Header noBorder={true} intro={fullName} />
          <Modal.Body>
            <div className="email">
              <EqForm.Group
                label={t("common.email")}
                error={visitorEmail == null || !validateEmail(visitorEmail) ? t("common.invalidEmailAddress") : null}
              >
                <Form.Input
                  onChange={(e) => handleChange(e.target.value)}
                  name="email"
                  value={visitorEmail}
                  placeholder={t("common.email")}
                />
              </EqForm.Group>
            </div>
          </Modal.Body>
        </>

        <Modal.Footer>
          <Button variant="ghost" onClick={onClose}>
            {t("common.cancel")}
          </Button>
          <Button
            disabled={visitorEmail == null || !validateEmail(visitorEmail)}
            variant="primary"
            onClick={() => {
              void onResend();
            }}
          >
            {t("common.confirm")}
          </Button>
        </Modal.Footer>
      </Modal.Dialog>
      <style jsx>
        {`
          .email {
            padding: ${spacers.s4} ${spacers.s0} ${spacers.s4};
          }
        `}
      </style>
    </>
  );
};
