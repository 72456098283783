import { Button, Modal, useTheme } from "@equiem/react-admin-ui";
import { useCallback, useContext, useEffect } from "react";
import { RiAddLine, RiFileUploadLine } from "@equiem/react-admin-ui/icons";
import { NewInvites } from "./NewInvitesContext";
import { useTranslation } from "@equiem/localisation-eq1";
import { Widget } from "./WidgetProvider";
import { InviteTable } from "./InviteTable";

export const InviteInner: React.FC = () => {
  const { t } = useTranslation();
  const { breakpoints } = useTheme(true);
  const { addInviteRow, hasAnyFilledRow, isSubmitting, disableSubmit, submit, close, setScreen } =
    useContext(NewInvites);
  const { closeModal, confirmCancel } = useContext(Widget);

  const localSubmit = async () => {
    await submit(closeModal);
  };

  const cancel = useCallback(() => {
    if (hasAnyFilledRow) {
      confirmCancel(close);
    } else {
      closeModal();
    }
  }, [close, closeModal, confirmCancel, hasAnyFilledRow]);

  useEffect(() => {
    document.querySelector<HTMLInputElement>("input.invite-email")?.focus();
  }, []);

  return (
    <>
      <Modal.Header closeButton intro={t("home.widgets.addNewUsers")} noBorder={true}>
        <div style={{ float: "right", marginTop: "-2.8rem", marginBottom: "1rem" }}>
          <Button type="button" variant="secondary" className="mr-auto bulk-btn" onClick={() => setScreen("bulk")}>
            <RiFileUploadLine />
            <span className="txt">{t("common.bulkUpload")}</span>
          </Button>
        </div>
      </Modal.Header>
      <Modal.Body noPadding>
        <InviteTable />
        <Button type="button" variant="ghost" className="my-2" onClick={addInviteRow}>
          <RiAddLine size={16} /> {t("home.widgets.inviteUserAdd")}
        </Button>
      </Modal.Body>
      <Modal.Footer>
        <Button type="button" variant="ghost" className="me-2 cancel-btn" onClick={cancel} disabled={isSubmitting}>
          {t("common.cancel")}
        </Button>
        <Button type="button" variant="primary" className="invite-btn" onClick={localSubmit} disabled={disableSubmit}>
          {t("home.widgets.inviteUsers")}
        </Button>
      </Modal.Footer>
      <style jsx>{`
        @media (max-width: ${breakpoints.sm}px) {
          .txt {
            display: none;
          }
        }
      `}</style>
    </>
  );
};
