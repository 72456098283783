import React from "react";

import type {
  VisitorForBuildingReceptionFragment,
  VisitorForReceptionFragment,
} from "../../../../generated/visitors-client";

export const VisitorHostData = ({
  visitor,
}: {
  visitor: VisitorForReceptionFragment | VisitorForBuildingReceptionFragment;
}) => {
  return (
    <>
      <>
        <div className="d-flex flex-column">
          <span>{`${visitor.appointment.host.firstName} ${visitor.appointment.host.lastName}`}</span>
          <span className="company">{visitor.appointment.host.company?.name ?? ""}</span>
        </div>
      </>
      <style jsx>
        {`
          .company {
            font-size: 12px;
            line-height: 16px;
            color: #666666;
          }
        `}
      </style>
    </>
  );
};
