import React from "react";

export interface Props {
  width: string;
  height?: string;
  className?: string;
  dark?: boolean;
  borderRadius?: string;
  block?: boolean;
}

export const SkeletonLine: React.FC<Props> = ({
  width = "100%",
  height = "0.8rem",
  className = "",
  dark = false,
  block = false,
  borderRadius,
}) => (
  <>
    <span
      className={`${dark ? "dark" : ""} ${className}`}
      style={{
        width,
        height,
        borderRadius,
      }}
    ></span>
    <style jsx>{`
      span {
        display: ${block ? "block" : "inline-block"};
        background: linear-gradient(to right, #f5f5f5 0%, #efefef 15%, #f5f5f5 30%);
        animation: skeletonAnimation 3s linear infinite;
        background-size: 1200px 100%;
        max-width: 100%;
      }
      span.dark {
        background-image: linear-gradient(to right, #d0d0d0 0%, #c1c0c0 15%, #d0d0d0 30%);
      }

      @keyframes skeletonAnimation {
        0% {
          background-position: -1200px 0%;
        }
        100% {
          background-position: 1200px 0%;
        }
      }
    `}</style>
  </>
);
