import { useCallback, useEffect, useRef, useState } from "react";

export function useAutoWidth() {
  const ref = useRef<HTMLDivElement>(null);
  const DEFAULT_WIDTH = 300;
  const [width, setWidth] = useState<number | undefined>(DEFAULT_WIDTH);
  const adjustWidth = useCallback(() => {
    if (ref.current != null) {
      const newWidth = ref.current.getBoundingClientRect().width;
      setWidth(newWidth);
    }
  }, []);

  useEffect(() => {
    window.addEventListener("resize", adjustWidth);
    adjustWidth();

    return () => window.removeEventListener("resize", adjustWidth);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { ref, width };
}
