import React, { useContext } from "react";

import { DropdownContext } from "../Dropdown";
import type { NavItemProps } from "../../NavItem/NavItem";
import { NavItem } from "../../NavItem/NavItem";

interface DropdownItemProps extends NavItemProps {
  autoClose?: boolean;
}

export const DropdownItem: React.FC<DropdownItemProps> = ({ children, autoClose = true, ...props }) => {
  const { autoClose: triggerAutoClose } = useContext(DropdownContext);

  return (
    <NavItem
      {...props}
      onClick={(e) => {
        if (autoClose) {
          triggerAutoClose();
        }
        props.onClick?.(e);
      }}
    >
      {children}
    </NavItem>
  );
};
