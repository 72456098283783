import React from "react";
import { Text } from "../Text/Text";
import { RiArrowLeftSLine, RiArrowRightSLine } from "react-icons/ri";
import { Select } from "../Form";
import { AdminButton } from "../Button/AdminButton";
import { useTranslation } from "@equiem/localisation-eq1";
import { useTheme } from "../../contexts";

export interface TablePaginationProps {
  totalCount: number;
  currentPage: number;
  pageSize: number;
  onPageChange: (page: number, pageSize: number) => void;
}

export const TablePagination: React.FC<TablePaginationProps> = ({
  totalCount,
  currentPage,
  pageSize,
  onPageChange,
}) => {
  const { t } = useTranslation();
  const { colors, breakpoints } = useTheme();

  return (
    <div className="pagination">
      <Text variant="label">
        {t("common.xOfYResults", {
          x: `${currentPage * pageSize - pageSize + 1}-${Math.min(totalCount, currentPage * pageSize)}`,
          y: totalCount,
        })}
      </Text>
      <div className="arrows">
        <AdminButton
          variant="outline"
          shape="round"
          disabled={currentPage === 1}
          onClick={() => onPageChange(currentPage - 1, pageSize)}
          className="page-prev"
        >
          <RiArrowLeftSLine size={16} />
        </AdminButton>
        <AdminButton
          variant="outline"
          shape="round"
          disabled={currentPage === Math.ceil(totalCount / pageSize) || totalCount < pageSize}
          onClick={() => onPageChange(currentPage + 1, pageSize)}
          className="page-next"
        >
          <RiArrowRightSLine size={16} />
        </AdminButton>
      </div>
      <div className="page-size">
        <Text variant="label">{t("common.resultsPerPage")}</Text>
        <Select value={pageSize.toString()} onChange={(e) => onPageChange(1, parseInt(e.target.value, 10))}>
          {[20, 50, 100].map((value) => (
            <option key={value} value={value}>
              {value}
            </option>
          ))}
        </Select>
      </div>
      <style jsx>{`
        .pagination {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 16px;
          color: ${colors.grayscale[60]};
        }

        .pagination .arrows {
          display: flex;
          gap: 4px;
        }

        .pagination .page-size {
          display: flex;
          gap: 8px;
          align-items: center;
          white-space: nowrap;
        }

        @media (max-width: ${breakpoints.md}px) {
          .pagination .page-size {
            display: none;
          }
        }
      `}</style>
    </div>
  );
};
