import { useTheme } from "@equiem/react-admin-ui";
import type { ComponentProps } from "react";
import React from "react";

export const TopMenuBar: React.FC<ComponentProps<"div">> = ({ children, ...props }) => {
  const { spacers } = useTheme();

  return (
    <>
      <div {...props}>{children}</div>
      <style jsx>{`
        div {
          display: flex;
          align-items: center;
          padding: 0 ${spacers.s2};
          height: 64px;
          width: 100%;
        }
      `}</style>
    </>
  );
};
