import React from "react";

import type {
  VisitorForBuildingReceptionFragment,
  VisitorForReceptionFragment,
} from "../../../../generated/visitors-client";

export const VisitorPersonalData = ({
  visitor,
}: {
  visitor: VisitorForReceptionFragment | VisitorForBuildingReceptionFragment;
}) => {
  return (
    <>
      <>
        <div className="d-flex flex-column">
          <span>{`${visitor.firstName} ${visitor.lastName}`}</span>
          <span className="company">{visitor.companyName}</span>
        </div>
      </>
      <style jsx>
        {`
          .company {
            font-size: 12px;
            line-height: 16px;
            color: #666666;
          }
        `}
      </style>
    </>
  );
};
