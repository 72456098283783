import { useMemo } from "react";
import { useSiteGroupsQuery } from "../generated/gateway-client";

export const useSiteGroups = () => {
  const { data } = useSiteGroupsQuery();

  return useMemo(() => {
    const groups = data?.viewer.currentDestination?.destination.groups ?? [];

    return groups.map((g) => {
      return {
        label: {
          searchText: g.name,
          element: <span>{g.name}</span>,
        },
        value: g.uuid,
      };
    });
  }, [data?.viewer.currentDestination?.destination.groups]);
};
