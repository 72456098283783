import React, { useContext, useEffect, useMemo, useState } from "react";
import { Formik } from "formik";
import { useRouter } from "next/router";
import * as yup from "yup";

import {
  ButtonTray,
  CurrentProfile,
  CurrentRole,
  notNullOrUndefined,
  Role,
  stringNotEmpty,
  SubTopMenu,
  useBcUuidContext,
  useShowError,
  useSiteContext,
} from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import { Button, Modal, ProgressCircle, useToast } from "@equiem/react-admin-ui";

import {
  AccessControlSolution,
  useCreateReceptionMutation,
  useUpdateReceptionMutation,
} from "../generated/visitors-client";

import { useReceptionFormReception } from "./hooks/useReceptionFormReception";
import { ReceptionForm } from "./ReceptionForm";
import { ReceptionFormProvider } from "./ReceptionFormContext";
import type { ReceptionFormValues } from "./ReceptionFormValues";

export const ReceptionFormPage: React.FC<{ receptionUuid?: string }> = (props) => {
  const { t } = useTranslation();
  const { currentRole } = useContext(CurrentRole);
  const { reception, loading: receptionLoading } = useReceptionFormReception({ uuid: props.receptionUuid });

  const profile = useContext(CurrentProfile);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [receptionName, setReceptionName] = useState("Reception");

  const { accessControl } = useSiteContext();
  /** use standard Barrier Control or AC1? default to Barrier Control */
  const isBarrierControl = accessControl.solution === AccessControlSolution.Vicinitee;

  // handle initial recipients
  const recipients: Array<{ userUuid: string; email: string }> = useMemo(() => {
    if (reception?.recipients == null) {
      return [];
    }
    return reception.recipients
      .map((r) => r?.profile ?? null)
      .filter(notNullOrUndefined)
      .map((p) => ({ userUuid: p.uuid, email: p.email }));
  }, [reception]);

  const initialFormikValues: ReceptionFormValues | null = useMemo(
    () =>
      props.receptionUuid == null
        ? {
            receptionType: currentRole === Role.PropertyManager ? "building" : "tenant",
            suffix: "",
            buildingUuid: "",
            buildingLevelUuid: "",
            companyUuid: profile.profile?.companyV2?.uuid ?? "",
            receptionists: [],
            receptionistsSearch: "",
            recipients: [],
            recipientsSearch: "",
            enableAccessCard: false,
            sendPassOnCheckIn: false,
            enablePassPrinting: false,
            enableHoldInLobby: false,
            enableAutomaticPassPrinting: false,
            barrierControlAccessUuid: "",
          }
        : reception != null
        ? {
            suffix: reception.suffix,
            receptionType: reception.company != null ? "tenant" : "building",
            buildingUuid: reception.building?.uuid ?? "",
            buildingLevelUuid: reception.buildingLevel?.uuid ?? "",
            companyUuid: reception.company?.uuid ?? "",
            enableAccessCard: reception.enableAccessCard ?? false,
            sendPassOnCheckIn: reception.sendPassOnCheckIn ?? false,
            enableHoldInLobby: reception.enableHoldInLobby ?? false,
            enablePassPrinting: reception.enablePassPrinting ?? false,
            enableAutomaticPassPrinting: reception.enableAutomaticPassPrinting ?? false,
            receptionists: reception.receptionists
              .map((r) => r.profile)
              .filter(notNullOrUndefined)
              .map((p) => ({ userUuid: p.uuid })),
            receptionistsSearch: "",
            recipients,
            recipientsSearch: "",
            barrierControlAccessUuid: isBarrierControl
              ? reception.barrierControlAccess?.uuid ?? ""
              : reception.accessGroup?.uuid ?? "",
          }
        : null,
    [props.receptionUuid, currentRole, profile.profile?.companyV2?.uuid, reception, recipients, isBarrierControl],
  );

  const toast = useToast();
  const showError = useShowError();
  const [createReception] = useCreateReceptionMutation();
  const [updateReception] = useUpdateReceptionMutation();
  const { updateBcUuid } = useBcUuidContext();
  const validationSchema = yup.lazy((values: ReceptionFormValues) =>
    yup.object().shape({
      receptionType: yup.string().required(t("visitors.settings.selectReceptionType")),
      buildingUuid: yup.string().required(t("visitors.settings.selectBuilding")),
      receptionists: yup.array().of(
        yup.object().shape({
          userId: yup.string(),
        }),
      ),
      recipients: yup.array().of(
        yup.object().shape({
          userId: yup.string(),
        }),
      ),
      suffix: yup.string(),
      ...(values.receptionType === "tenant"
        ? {
            companyUuid: yup.string().required(t("common.selectCompany")),
            buildingLevelUuid: yup.string().required(t("visitors.settings.selectLevel")),
          }
        : {}),
    }),
  );

  const router = useRouter();

  const cancelBtnHandler = (isDirty: boolean) => {
    if (isDirty) {
      setShowModal(true);
    } else {
      router.back();
    }
  };

  useEffect(() => {
    if (props.receptionUuid != null) {
      updateBcUuid(props.receptionUuid, `Edit ${receptionName}`);
    }
  }, [props.receptionUuid, receptionName, updateBcUuid]);

  return (
    <div className="main-container">
      {initialFormikValues == null ? (
        <div className="spinner-container">
          {receptionLoading ? <ProgressCircle size="md" /> : <p>{t("visitors.settings.receptionLoadFailed")}</p>}
        </div>
      ) : (
        <Formik<ReceptionFormValues>
          enableReinitialize
          initialValues={initialFormikValues}
          validationSchema={validationSchema}
          onSubmit={async (values, actions) => {
            const receptionInput = {
              building: values.buildingUuid,
              suffix: values.suffix,
              enableAccessCard: values.enableAccessCard,
              sendPassOnCheckIn: values.sendPassOnCheckIn,
              enablePassPrinting: values.enablePassPrinting,
              enableHoldInLobby: values.enableHoldInLobby,
              enableAutomaticPassPrinting: values.enableAutomaticPassPrinting,
              receptionists: values.receptionists,
              recipients: values.recipients.map((r) => ({ userUuid: r.userUuid })),
              ...(isBarrierControl
                ? {
                    barrierControlAccessUuid: stringNotEmpty(values.barrierControlAccessUuid)
                      ? values.barrierControlAccessUuid
                      : null,
                  }
                : {
                    accessGroupUuid: stringNotEmpty(values.barrierControlAccessUuid)
                      ? values.barrierControlAccessUuid
                      : null,
                  }),
              ...(values.buildingLevelUuid !== ""
                ? {
                    buildingLevel: values.buildingLevelUuid,
                  }
                : {}),
              ...(values.receptionType === "tenant"
                ? {
                    company: values.companyUuid,
                  }
                : {}),
            };

            try {
              if (props.receptionUuid == null) {
                await createReception({
                  variables: {
                    input: receptionInput,
                  },
                });

                toast.positive(t("visitors.settings.receptionCreated", { name: receptionName }));
              } else {
                await updateReception({
                  variables: {
                    uuid: props.receptionUuid,
                    input: receptionInput,
                  },
                });
                toast.positive(t("visitors.settings.receptionUpdated"));
              }
              await router.push("/visitor-management/settings/receptions");
            } catch (e: unknown) {
              showError(e);
            } finally {
              actions.setSubmitting(false);
            }

            return undefined;
          }}
        >
          {({ submitForm, setTouched, isSubmitting, dirty }) => (
            <>
              <SubTopMenu>
                <h1 className="font-weight-bold">
                  {props.receptionUuid != null ? t("common.edit") : t("common.create")} {receptionName}
                </h1>
              </SubTopMenu>
              <ReceptionFormProvider uuid={props.receptionUuid} setReceptionName={setReceptionName}>
                <ReceptionForm />
              </ReceptionFormProvider>
              <ButtonTray>
                <Button
                  variant="ghost"
                  size="md"
                  className="mr-5"
                  disabled={isSubmitting}
                  onClick={() => cancelBtnHandler(dirty)}
                >
                  {t("common.cancel")}
                </Button>
                <Button
                  variant="primary"
                  size="md"
                  disabled={isSubmitting}
                  onClick={() => {
                    void setTouched(
                      {
                        buildingLevelUuid: true,
                        buildingUuid: true,
                        companyUuid: true,
                        receptionists: [],
                        receptionistsSearch: true,
                        recipients: [],
                        recipientsSearch: true,
                        receptionType: true,
                        suffix: true,
                      },
                      true,
                    ).catch(console.error);
                    submitForm().catch(showError);
                  }}
                >
                  {isSubmitting && <ProgressCircle size="xs" className="mr-2" />}
                  {t("common.save")}
                </Button>
              </ButtonTray>
              <Modal.Dialog centered={true} show={showModal} title={t("common.areYouSure")} hideOnEsc={true} size="sm">
                <Modal.Header closeButton={false} noBorder={true} />
                <Modal.Body>{t("visitors.settings.receptionEditCancelBody")}</Modal.Body>
                <Modal.Footer>
                  <Button size="sm" variant="ghost" className="mr-2" onClick={() => setShowModal(false)}>
                    {t("visitors.appointmentForm.cancelNo")}
                  </Button>
                  <Button size="sm" variant="danger" className="mr-2" onClick={() => router.back()}>
                    {t("common.yesCancel")}
                  </Button>
                </Modal.Footer>
              </Modal.Dialog>
            </>
          )}
        </Formik>
      )}
      <style jsx>
        {`
          .spinner-container {
            display: flex;
            height: calc(100vh - 128px);
            justify-content: center;
            align-items: center;
            background-color: white;
            color: gray;
          }
          .main-container {
            flex-grow: 1;
          }
        `}
      </style>
    </div>
  );
};
