import type { FC } from "react";
import { SideNav } from "@equiem/react-admin-ui";
import { RiTeamLine } from "@equiem/react-admin-ui/icons";
import { useTranslation } from "@equiem/localisation-eq1";
import { ShortcutItem } from "../ShortcutItem";

export const FlexTenantMenu: FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <SideNav isExpandable={true} title={t("flex.shortcuts.title")}>
        <ShortcutItem icon={RiTeamLine} text={t("flex.shortcuts.memberships")} routerLink={"/flex/memberships"} />
      </SideNav>
    </>
  );
};
