import { DateTime } from "luxon";
import React, { useCallback, useContext } from "react";
import { BookingModal } from "../../../operations/contexts/BookingModalContext";
import type { ResourceFragment } from "../../../../generated/gateway-client";

export type TimeRange = {
  start: number;
  end: number;
};

const MINUTES_IN_HOUR = 60;

export const BaseTimeRange: React.FC<{
  start: number;
  end: number;
  timezone: string;
  className?: string;
  style?: React.CSSProperties;
  resource?: ResourceFragment;
  notBookable?: boolean;
  setReloadUuid?: (_uuid: string) => void;
  isSlot?: boolean;
  overflowAvailabilities?: number;
  children?: React.ReactNode;
}> = ({
  start,
  end,
  timezone,
  className = "",
  style = {},
  resource,
  notBookable,
  setReloadUuid,
  isSlot = false,
  overflowAvailabilities = 0,
  children,
}) => {
  const { open: modalOpen } = useContext(BookingModal);

  const startTime = DateTime.fromMillis(start, { zone: timezone });
  const endTime = DateTime.fromMillis(end, { zone: timezone });

  const gridRowStart = startTime.hour * MINUTES_IN_HOUR + startTime.minute + 1;
  const gridRowEnd = endTime.hour * MINUTES_IN_HOUR + endTime.minute + 1;

  const handleRangeClick = useCallback(() => {
    if (resource != null) {
      setReloadUuid?.(resource.uuid);
      modalOpen(resource.uuid, start, isSlot && overflowAvailabilities === 0 ? end : undefined);
    }
  }, [resource, setReloadUuid, isSlot, overflowAvailabilities, modalOpen, start, end]);

  return (
    <div
      className={`time-slot ${startTime.toMillis()}-${endTime.toMillis()} ${className}`}
      style={{ gridRowStart, gridRowEnd, ...style }}
      onClick={notBookable != null && notBookable ? (e) => e.preventDefault() : handleRangeClick}
    >
      {children}
    </div>
  );
};
