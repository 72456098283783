import React, { useState } from "react";

import { useTranslation } from "@equiem/localisation-eq1";
import { Button, Form as EqForm, useToast } from "@equiem/react-admin-ui";

import type { CompanyFragment } from "../../../../generated/settings-client";
import { useUpdateCompanyV2IntegrationsMutation } from "../../../../generated/settings-client";

interface Props {
  company: CompanyFragment;
}

export const JLLEvolutionIntegration: React.FC<Props> = ({ company }) => {
  const toast = useToast();
  const { t } = useTranslation();
  const [tenantCode, setTenantCode] = useState<string>();
  const [mutation, { loading: saving }] = useUpdateCompanyV2IntegrationsMutation();

  const isLoading = saving;

  const handleTenantCode = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTenantCode(e.target.value);
  };

  const handleSave = () => {
    if (!isLoading && tenantCode != null) {
      void mutation({
        variables: {
          integrations: {
            companyUuid: company.uuid,
            evolution: {
              tenancyId: tenantCode,
            },
          },
        },
      }).then((result) => {
        if (result.data?.updateCompanyV2Integrations.success === false) {
          toast.negative(result.data.updateCompanyV2Integrations.message);
        }
      });
    }
  };

  return (
    <>
      <div className="form">
        <EqForm.Group
          label={t("settings.editCompany.evolutionTenantCode")}
          showTooltip
          tooltipText={t("settings.editCompany.evolutionTenantCodeTooltip")}
          className="mb-0"
        >
          <EqForm.Input
            type="text"
            value={company.integrations?.evolution.tenancyId ?? undefined}
            onChange={handleTenantCode}
            autoComplete="off"
            id="evolution-account-id"
          />
        </EqForm.Group>
        <div className="row">
          <Button size="sm" variant="secondary" disabled={isLoading} onClick={handleSave}>
            {t("common.save")}
          </Button>
        </div>
      </div>
      <style jsx>{`
        .form {
          padding: 8px;
          display: flex;
          flex-direction: column;
          gap: 16px;
        }
        .row {
          display: flex;
          justify-content: flex-end;
          gap: 8px;
          align-items: center;
        }
        .loading {
          display: flex;
          align-items: center;
          align-self: center;
          gap: 8px;
        }
      `}</style>
    </>
  );
};
