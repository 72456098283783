import { useEffect, useMemo, useState } from "react";
import type { FlexTenantsICanAssignQuery } from "../generated/gateway-client";
import { useFlexTenantsICanAssignLazyQuery } from "../generated/gateway-client";
import { useShowError } from "@equiem/lib";

export const useFlexTenants = ({ granteeCompanyUuids = [] }: { granteeCompanyUuids?: string[] }) => {
  const showError = useShowError();
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState<FlexTenantsICanAssignQuery["companyV2"] | undefined>(undefined);
  const [query] = useFlexTenantsICanAssignLazyQuery();

  useEffect(() => {
    if (granteeCompanyUuids.length !== 1) {
      setResult(null);
      return;
    }

    setLoading(true);
    query({
      variables: {
        granteeCompanyUuid: granteeCompanyUuids[0]!,
      },
    })
      .then((queryResult) => {
        setResult(queryResult.data?.companyV2);
      })
      .catch((e) => showError(e))
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [granteeCompanyUuids.join(), query, showError, setLoading]);

  return useMemo(() => {
    return {
      loading,
      flexTenants:
        result?.flexTenants.map((ft) => ({
          value: ft.uuid,
          label: ft.name,
        })) ?? [],
    };
  }, [loading, result?.flexTenants]);
};
