import type { ReactNode } from "react";
import React from "react";

export const GradientContainer: React.FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <>
      <div className="container">
        <div className="gradient-container">
          <div className="stackable rectangle"></div>
          <div className="stackable elipsis elipsis-1"></div>
          <div className="stackable elipsis elipsis-2"></div>
        </div>
        {children}
      </div>
      <style jsx>{`
        .container {
          position: relative;
          display: flex;
          min-height: 100%;
          min-width: 100%;
        }

        .gradient-container {
          position: absolute;
          top: 0;
          left: 0;
          min-height: 100%;
          min-width: 100%;
          background-size: 100% 400px;
          background-repeat: no-repeat;
          background-color: white;
          overflow-y: hidden;
        }

        .stackable::before {
          content: "";
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 400px;
        }

        .rectangle::before {
          background-color: #bceae2;
          background-image: linear-gradient(top, transparent 0%, white 400px, white 100%);
        }

        .elipsis::before {
          border-radius: 1000px;
          opacity: 0.4;
          background: linear-gradient(90deg, #fff 0%, rgba(255, 255, 255, 0) 100%);
          width: 1000px;
          height: 1180px;
        }

        .elipsis-1::before {
          left: calc(100vw - 820px);
          top: 50px;
        }

        .elipsis-2::before {
          left: calc(100vw - 500px);
          top: -900px;
        }
      `}</style>
    </>
  );
};
