import React, { useMemo } from "react";
import type { DateTime, WeekdayNumbers } from "luxon";
import type { TFunction } from "@equiem/localisation-eq1";
import { formatters, useTranslation } from "@equiem/localisation-eq1";
import { AccordionList, AccordionListItem, Alert, Skeleton, useTheme } from "@equiem/react-admin-ui";
import type { RecurringSettings } from "../models/RecurringSettings";
import { RecurringType } from "../models/RecurringSettings";
import { RiErrorWarningLine } from "@equiem/react-admin-ui/icons";
import { formatWeekdayByNum } from "../../../lib/formatWeekday";
import { getWeekdayOccuranceOfWeekdayInMonth } from "../libs/RecurringSettingsAuxiliary";
import { ValidateBookingTimesStatus } from "../../../generated/gateway-client";
import { useBookingRecurringModalContext } from "../contexts/BookingRecurringModalProvider";
import { stringNotEmpty } from "@equiem/lib";

const DEFAULT_EXPANDED_MIN_TOTAL = 5;
const TOTAL_DAYS_IN_A_WEEK = 7;

type Time = {
  startTime: number;
  endTime: number;
};

type TOptions = {
  repeatType: string;
  startTime: string;
  endTime: string;
  recurringEndDate: string;
  times: number;
};
const getTOption = (
  recurringDates: DateTime[],
  recurringSettings: RecurringSettings,
  t: TFunction,
  language: string,
): TOptions | null => {
  // We included the original selection by the user, so don't count it.
  // We just display the summary for the recurring selection.
  if (recurringDates.length === 1) {
    return null;
  }

  const { start, end, recurringType, repeatEvery, repeatOn, sameWeekDayEachMonth, lastWeekDayEachMonth } =
    recurringSettings;

  switch (recurringType) {
    case RecurringType.Daily: {
      return {
        repeatType: `${repeatEvery === 1 ? "" : `${repeatEvery} `}${t("common.repeatEveryDay", {
          count: repeatEvery,
        }).toLocaleLowerCase()}`,
        startTime: formatters.timeshort(start, language),
        endTime: formatters.timeshort(end, language),
        recurringEndDate: formatters.dateshort(recurringDates.at(-1) ?? end, language),
        times: recurringDates.length,
      };
    }

    case RecurringType.Monthly: {
      const selection = sameWeekDayEachMonth
        ? t("bookings.repeat.endsOnSameOccurance", {
            number: t("common.number", { ordinal: true, count: getWeekdayOccuranceOfWeekdayInMonth(start) }),
            weekday: formatWeekdayByNum(start.weekday as WeekdayNumbers, language, "long"),
          })
        : !lastWeekDayEachMonth
        ? t("bookings.repeat.endsOnDay", { dayNumber: start.day })
        : t("bookings.repeat.endsOnLastOccurance", {
            weekday: formatWeekdayByNum(start.weekday as WeekdayNumbers, language, "long"),
          });
      return {
        repeatType: `${repeatEvery === 1 ? "" : `${repeatEvery} `}${t("common.repeatEveryMonth", {
          count: repeatEvery,
        }).toLocaleLowerCase()}, ${selection.charAt(0).toLocaleLowerCase()}${selection.slice(1)},`,
        startTime: formatters.timeshort(start, language),
        endTime: formatters.timeshort(end, language),
        recurringEndDate: formatters.dateshort(recurringDates.at(-1) ?? end, language),
        times: recurringDates.length,
      };
    }

    case RecurringType.Weekly: {
      const daysArray = repeatOn.sort((a, b) => a - b).map((x) => formatWeekdayByNum(x, language, "long"));
      const days =
        daysArray.length === TOTAL_DAYS_IN_A_WEEK
          ? t("common.repeatEveryWeek", { count: 1 }).toLocaleLowerCase()
          : formatters.list(daysArray, language);

      return {
        repeatType: `${
          repeatEvery === 1 ? "" : `${t("common.number", { ordinal: true, count: repeatEvery })} `
        }${days}`,
        startTime: formatters.timeshort(start, language),
        endTime: formatters.timeshort(end, language),
        recurringEndDate: formatters.dateshort(recurringDates.at(-1) ?? end, language),
        times: recurringDates.length,
      };
    }

    default:
      return null;
  }
};

const Unavailable: React.FC<{ taken: Time[]; outOfAvailability: Time[] }> = ({ taken, outOfAvailability }) => {
  const { t, i18n } = useTranslation();
  const { hasSuperPower } = useBookingRecurringModalContext();

  // When user is property manager, then out of availability is availble for them.
  const extra = hasSuperPower ? 0 : outOfAvailability.length;
  const total = extra + taken.length;
  const expandTakenOrOut = total <= DEFAULT_EXPANDED_MIN_TOTAL;

  if (total === 0) {
    return null;
  }

  return (
    <AccordionList
      className="result-unavailable"
      HeaderIcon={RiErrorWarningLine}
      headerText={`${t("bookings.repeat.unavailableTitle")} ${t("bookings.repeat.occurrenceTotal", {
        count: total,
      })}. ${t("bookings.repeat.expandToSeeDetails")}`}
      showCollapseButton
      showCollapsed={expandTakenOrOut}
      colorTheme="warning"
    >
      {taken.length > 0 && (
        <>
          <AccordionListItem>
            <strong>{t("bookings.repeat.unavailableDates")}</strong>
          </AccordionListItem>
          {taken.map((av, i) => (
            <AccordionListItem className="taken" key={i}>
              {formatters.datelong(av.startTime, i18n.language)}
            </AccordionListItem>
          ))}
        </>
      )}
      {!hasSuperPower && outOfAvailability.length > 0 && (
        <>
          <AccordionListItem>
            <div className="d-block pt-5">
              <strong>{t("bookings.repeat.dateBeyondAvailability")}</strong>
            </div>
          </AccordionListItem>
          {outOfAvailability.map((av, i) => (
            <AccordionListItem className="beyond-availability" key={i}>
              {formatters.datelong(av.startTime, i18n.language)}
            </AccordionListItem>
          ))}
        </>
      )}
    </AccordionList>
  );
};

const Available: React.FC<{ option: TOptions; outOfAvailability: Time[]; available: Time[]; taken: Time[] }> = ({
  option,
  outOfAvailability,
  available,
  taken,
}) => {
  const { hasSuperPower } = useBookingRecurringModalContext();
  const { i18n, t } = useTranslation();
  // When user is property manager, then out of availability is availble for them.
  const extra = hasSuperPower ? outOfAvailability.length : 0;
  const total = extra + available.length;
  const expandAvailability = total <= DEFAULT_EXPANDED_MIN_TOTAL;

  if (total === 0) {
    return null;
  }
  const allTimesAvailable = outOfAvailability.length === 0 && taken.length === 0;

  const availabilityKeyT = allTimesAvailable
    ? "bookings.repeat.selectionResultAllAvailable"
    : "bookings.repeat.selectionResultSomeAvailable";

  return (
    <AccordionList
      className="result-available"
      HeaderIcon={RiErrorWarningLine}
      headerText={`${t(availabilityKeyT, option)} ${t("bookings.repeat.occurrenceTotal", {
        count: total,
      })}. ${t("bookings.repeat.expandToSeeDetails")}`}
      showCollapseButton
      showCollapsed={expandAvailability}
      colorTheme="success"
    >
      {available.map((av, i) => (
        <AccordionListItem key={i}>{formatters.datelong(av.startTime, i18n.language)}</AccordionListItem>
      ))}
      {hasSuperPower && outOfAvailability.length > 0 && (
        <>
          <AccordionListItem>
            <div className="d-block pt-5">
              <strong>{t("bookings.repeat.dateBeyondAvailability")}</strong>
            </div>
          </AccordionListItem>
          {outOfAvailability.map((av, i) => (
            <AccordionListItem className="beyond-availability" key={i}>
              {formatters.datelong(av.startTime, i18n.language)}
            </AccordionListItem>
          ))}
        </>
      )}
    </AccordionList>
  );
};

interface P {
  loading: boolean;
  // Generated dates from the user selection recurring setting.
  recurringDates: DateTime[];
  error?: string | null;
  className?: string;
}
export const BookingRecurringSummary: React.FC<P> = ({ loading, recurringDates, className, error }) => {
  const { t, i18n } = useTranslation();
  const { colors, borderRadius, spacers } = useTheme();
  const { recurringSettings } = useBookingRecurringModalContext();

  const availableTimes = useMemo(
    () => recurringSettings.validatedRecurringDates.filter((a) => a.status === ValidateBookingTimesStatus.Available),
    [recurringSettings.validatedRecurringDates],
  );
  const outOfAvailability = useMemo(
    () =>
      recurringSettings.validatedRecurringDates.filter(
        (a) => a.status === ValidateBookingTimesStatus.OutOfAvailability,
      ),
    [recurringSettings.validatedRecurringDates],
  );
  const taken = useMemo(
    () => recurringSettings.validatedRecurringDates.filter((a) => a.status === ValidateBookingTimesStatus.Taken),
    [recurringSettings.validatedRecurringDates],
  );

  if (loading) {
    return <Skeleton.Line width="100%" height="64px" borderRadius={borderRadius} />;
  }

  if (stringNotEmpty(error)) {
    return (
      <div className={className}>
        <Alert
          size="large"
          variant="gray"
          icon={<RiErrorWarningLine size={18} color={colors.grayscale[50]} />}
          message={error}
        />
      </div>
    );
  }

  const tOptions = getTOption(recurringDates, recurringSettings, t, i18n.language);
  if (tOptions == null) {
    return null;
  }

  if (recurringSettings.validatedRecurringDates.length === 0) {
    return (
      <div className={className}>
        <Alert
          size="large"
          variant="gray"
          icon={<RiErrorWarningLine size={18} color={colors.grayscale[50]} />}
          message={`${t("bookings.repeat.selectionSummary", tOptions)} ${t("bookings.repeat.occurrenceTotal", {
            count: recurringDates.length,
          })}`}
        />
      </div>
    );
  }

  return (
    <>
      <div className={className}>
        <Unavailable outOfAvailability={outOfAvailability} taken={taken} />
        <Available available={availableTimes} outOfAvailability={outOfAvailability} taken={taken} option={tOptions} />
      </div>
      <style jsx>{`
        div {
          display: flex;
          flex-direction: column;
          gap: ${spacers.s5};
        }
      `}</style>
    </>
  );
};
