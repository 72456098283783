import React from "react";

import { useTranslation } from "@equiem/localisation-eq1";
import { Button, Modal, useTheme } from "@equiem/react-admin-ui";

import type { VisitorTypeFormValue } from "../GeneralSettingsValues";

interface Props {
  showModal: boolean;
  onClose: () => void;
  onAgree: () => void;
  asDefault: VisitorTypeFormValue | null;
}

export const ChangeDefaultModal: React.FC<Props> = ({ showModal, asDefault, onClose, onAgree }) => {
  const { t } = useTranslation();
  const { colors } = useTheme();

  return (
    <>
      <Modal.Dialog
        centered
        className="set-default-modal"
        title={t("visitors.settings.areYouSureDefault", { name: asDefault?.name as string })}
        supportsMobile
        show={showModal}
        onHide={onClose}
        hideOnEsc
        hideOnClick={false}
        size="sm"
        focusTrapOptions={{ allowOutsideClick: () => true }}
      >
        <Modal.Header noBorder />
        <Modal.Body>
          <span className="description">{t("visitors.settings.setDefaultDescriptionModal")}</span>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={onClose} variant="ghost" size="md">
            {t("common.cancel")}
          </Button>
          <Button onClick={onAgree} className="ml-2" size="md">
            {t("visitors.settings.setAsDefault")}
          </Button>
        </Modal.Footer>
      </Modal.Dialog>
      <style jsx>{`
        :global(.set-default-modal .title-row .title) {
          font-weight: 500;
          color: ${colors.grayscale[100]};
          font-size: 18px;
          line-height: 24px;
          text-transform: none;
        }

        :global(.set-default-modal .description) {
          font-size: 18px;
          line-height: 24px;
        }
      `}</style>
    </>
  );
};
