import React from "react";
import { Skeleton } from "@equiem/react-admin-ui";

const initNumbersOfLines = 5;
export const LoadingSkeleton: React.FC<{ numberOfLines?: number }> = ({ numberOfLines = initNumbersOfLines }) => {
  return (
    <>
      <div className="skeleton-loading">
        {[...Array<number>(numberOfLines)].map((_, index) => (
          <Skeleton.Line key={index} width="100%" height="18px" />
        ))}
      </div>
      <style jsx>
        {`
          .skeleton-loading {
            padding: 16px;
            display: flex;
            flex-direction: column;
            gap: 8px;
          }
        `}
      </style>
    </>
  );
};
