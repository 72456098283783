import type { TFunction } from "@equiem/localisation-eq1";
import { formatters, useTranslation } from "@equiem/localisation-eq1";
import { Button, Table, Tag, Text, useTheme } from "@equiem/react-admin-ui";
import { RiCloseLine, RiEditLine } from "@equiem/react-admin-ui/icons";
import { useFormikContext } from "formik";
import React, { useCallback, useState } from "react";
import { ResourceCreateAndEditFormExtrasModal } from "./ResourceCreateAndEditFormExtrasModal";
import {
  BookableResourceAddOnType,
  type BookableResourceAddOnFragmentFragment as BookableResourceExtras,
} from "../../../../../generated/gateway-client";
import { useCurrency, useCurrencyCode } from "../../../../../hooks/useCurrency";
import type { FormValues } from "../../../../../lib/formValidation";
import { convertInputNumberToNumber } from "../../../../../lib/convertNumberStringToNumber";

const extrasTypeToString = (type: BookableResourceAddOnType, t: TFunction): string => {
  const extrasTypeMapLocales: { [key in BookableResourceAddOnType]: string } = {
    [BookableResourceAddOnType.FreeText]: t("bookings.resources.freeText"),
    [BookableResourceAddOnType.SingleChoice]: t("bookings.resources.singleChoice"),
    [BookableResourceAddOnType.SingleOption]: t("bookings.resources.singleOption"),
    [BookableResourceAddOnType.MultiOption]: t("bookings.resources.multiChoice"),
  };

  return extrasTypeMapLocales[type];
};

export const ResourceCreateAndEditFormExtras: React.FC = () => {
  const { i18n, t } = useTranslation();
  const fm = useFormikContext<FormValues>();
  const { colors, spacers } = useTheme(true);
  const { currency } = useCurrency(fm.values.building);
  const currencyCode = useCurrencyCode(fm.values.building);

  const [showModal, setShowModal] = useState(false);
  const [modalIndex, setModalIndex] = useState<number | null>(null);

  const addExtras = useCallback(
    (extras: BookableResourceExtras) => {
      fm.setFieldValue("addOns", [...fm.values.addOns, extras]).catch(console.error);

      setShowModal(false);
    },
    [fm],
  );

  const editExtras = useCallback(
    (extras: BookableResourceExtras, index: number) => {
      const newExtras = [...fm.values.addOns];
      newExtras[index] = extras;
      fm.setFieldValue("addOns", newExtras).catch(console.error);

      setShowModal(false);
      setModalIndex(null);
    },
    [fm],
  );

  const removeExtras = useCallback(
    (index: number) => {
      const newExtras = [...fm.values.addOns];
      newExtras.splice(index, 1);
      fm.setFieldValue("addOns", newExtras).catch(console.error);
    },
    [fm],
  );

  return (
    <>
      {fm.values.addOns.map((extras, extras_index) => (
        <div key={`extras_${extras_index}`} className="extras-container">
          <div className="extras-title">
            <Text variant="label" color={colors.grayscale[60]}>
              {extras.name}
            </Text>
            <div className="extras-title-actions">
              <RiEditLine
                size="16"
                color={colors.primary}
                cursor="pointer"
                onClick={() => {
                  setModalIndex(extras_index);
                  setShowModal(true);
                }}
              />
              <RiCloseLine
                size="16"
                color={colors.danger}
                cursor="pointer"
                onClick={() => removeExtras(extras_index)}
              />
            </div>
          </div>

          <div className="extras-type">
            <Tag variant="default">{`${extrasTypeToString(extras.type, t)}${
              extras.mandatory ? ` (${t("common.required")})` : ""
            }`}</Tag>
          </div>

          {extras.options != null && extras.options.length > 0 && (
            <Table.Table className="w-100">
              <thead>
                <tr>
                  <Table.Header label={t("bookings.resources.extrasName")} />
                  <Table.Header label={t("bookings.resources.maxQuantity")} />
                  <Table.Header label={t("bookings.resources.priceEach")} />
                </tr>
              </thead>
              <tbody>
                {extras.options.map((option, option_index) => (
                  <tr key={`option_${option_index}`}>
                    <td>{option.name}</td>
                    <td>{convertInputNumberToNumber(option.maxQuantity) ?? "-"}</td>
                    <td>
                      {formatters.currency(Number(option.unitPrice), i18n.language, {
                        currency: currencyCode,
                      })}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table.Table>
          )}
        </div>
      ))}

      <Button type="button" variant="secondary" size="lg" style={{ width: "100%" }} onClick={() => setShowModal(true)}>
        {t("bookings.resources.createExtra")}
      </Button>

      <ResourceCreateAndEditFormExtrasModal
        showModal={showModal}
        setShowModal={setShowModal}
        modalIndex={modalIndex}
        setModalIndex={setModalIndex}
        extras={modalIndex != null ? fm.values.addOns[modalIndex] : undefined}
        currency={currency}
        onAdd={addExtras}
        onEdit={editExtras}
      />

      <style jsx>{`
        .extras-container {
          display: flex;
          flex-direction: column;
          gap: ${spacers.s5};
          border: 1px solid ${colors.border};
          border-radius: 4px;
          padding: ${spacers.s4};
        }
        .extras-title {
          display: flex;
          justify-content: space-between;
        }
        .extras-title-actions {
          display: flex;
          gap: ${spacers.s5};
        }
        .extras-container .extras-type {
          align-self: left;
        }
        .extras-container :global(.tag) {
          padding: ${spacers.s2} ${spacers.s3};
          font-size: 12px;
          line-height: 16px;
        }
      `}</style>
    </>
  );
};
