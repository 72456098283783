import type { ArticleFragmentFragment } from "../../../generated/gateway-client";
import type { Article } from "./Article";

export function mungeArticle(article: ArticleFragmentFragment): Article & Required<Pick<Article, "uuid" | "title">> {
  return {
    type: "Article",
    uuid: article.uuid,
    title: article.title,
    publishDate: article.publishDate,
    excerpt: article.excerpt ?? undefined,
    featuredImageUrl: null,
  };
}
