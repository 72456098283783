import React from "react";
import { useRouter } from "next/router";
import { useSiteContext } from "@equiem/lib";
import { Form } from "@equiem/react-admin-ui";
import { useTranslation, useApolloErrorTranslation } from "@equiem/localisation-eq1";
import { Field, useFormikContext } from "formik";

import { usePagedResources } from "../hooks/usePagedResources";

type FormValuesType = Record<string, string[]>;

interface Props {
  className?: string;
  fieldName?: string;
  showErrorCheck?: boolean;
}

export const ResourceSelector: React.FC<Props> = ({ className, fieldName = "resources", showErrorCheck = true }) => {
  const { t } = useTranslation();
  const { tError } = useApolloErrorTranslation();
  const site = useSiteContext();
  const fm = useFormikContext<FormValuesType>();
  const router = useRouter();

  const { resources, loading, error } = usePagedResources(
    {
      filters: { siteUuids: [site.uuid], excludeParentResources: true },
      permissionFilters: { canManageBookings: true },
    },
    { forceLoadAllPages: true, pageSize: 100 },
  );

  if (error != null) {
    return <div>{tError(error)}</div>;
  }

  const thisResourceUuid = router.query.uuid as string;
  const options = resources
    .filter((resource) => resource.uuid !== thisResourceUuid)
    .map((resource) => ({ value: resource.uuid, label: resource.name }));

  const rawError = fm.errors[fieldName];
  const formError = rawError == null ? undefined : Array.isArray(rawError) ? rawError.join(", ") : rawError;

  return (
    <Form.Group
      className={className}
      label={t("bookings.settings.resources")}
      required
      error={showErrorCheck ? formError : undefined}
    >
      <Field
        name={fieldName}
        className="resources"
        options={options}
        as={Form.MultiSelect}
        variant="wrap"
        placeholder={t("bookings.resources.searchForResources")}
        searchPlaceholder={t("bookings.resources.resourceName")}
        searchLoading={loading}
        isMulti
        disabled={fm.isSubmitting}
      />
    </Form.Group>
  );
};
