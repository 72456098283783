import React from "react";

import { useTheme } from "@equiem/react-admin-ui";
import { RiTeamLine } from "@equiem/react-admin-ui/icons";

interface Props {
  text: string;
}

export const EmptyWidget: React.FC<Props> = ({ text }) => {
  const { colors } = useTheme();

  return (
    <div className="container">
      <div className="inner">
        <div className="icon">
          <RiTeamLine size={24} style={{ color: colors.medium }} />
        </div>
        <h2 className="info mt-5">{text}</h2>
      </div>
      <style jsx>{`
        .container {
          height: 100%;
          align-items: center;
          display: flex;
        }
        .inner {
          display: flex;
          align-items: center;
          flex-direction: column;
        }
        .info {
          display: block;
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          padding: 0;
          margin: 0;
          color: rgba(0, 0, 0, 0.4);
          text-align: center;
        }
        .icon {
          border-radius: 50%;
          height: 64px;
          width: 64px;
          line-height: 64px;
          background-color: #f2f2f2;
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      `}</style>
    </div>
  );
};
