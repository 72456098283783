"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useApolloErrorTranslation = void 0;
var react_1 = require("react");
var LocalisableError_1 = require("./LocalisableError");
var useServerMessageTranslation_1 = require("./useServerMessageTranslation");
function useApolloErrorTranslation() {
    var _a = (0, useServerMessageTranslation_1.useServerMessageTranslation)(), tServer = _a.tServer, i18n = _a.i18n, ready = _a.ready;
    var tError = (0, react_1.useCallback)(function (error) {
        var localisableErrors = error.graphQLErrors.filter(LocalisableError_1.isLocalisableError);
        if (localisableErrors.length === 0) {
            return error.message;
        }
        if (localisableErrors.length > 1) {
            console.warn("Multiple localisable errors detected:", error);
        }
        return tServer(localisableErrors[0].extensions.localisableErrorParams);
    }, [tServer]);
    return {
        tError: tError,
        i18n: i18n,
        ready: ready,
    };
}
exports.useApolloErrorTranslation = useApolloErrorTranslation;
