import React, { useContext } from "react";
import type { NextPage } from "next";
import type { PageProps } from "@equiem/lib";
import { Session, useSiteContext } from "@equiem/lib";
import { WelcomePage, Homepage } from "@equiem/homepage";
import { useRouter } from "next/router";

const Page: NextPage<PageProps> = () => {
  const { authenticated } = useContext(Session);
  const { tierLevel } = useSiteContext();
  const router = useRouter();

  if (!authenticated) {
    return <WelcomePage />;
  }

  if (tierLevel !== "Essentials") {
    void router.replace("/insights");
    return null;
  }

  return <Homepage />;
};

Page.getInitialProps = () =>
  ({
    noLayoutWhenUnauthenticated: true,
  } as PageProps);

export default Page;
