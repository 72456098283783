import React from "react";

import Credits from "../components/credits/CreditsPage";
import { Menu } from "../components/credits/Menu";

export const CreditsPage = () => {
  return (
    <>
      <Menu />
      <Credits />
    </>
  );
};
