import React from "react";
import { Field, Formik } from "formik";
import * as yup from "yup";

import { stringNotEmpty, useSaferFormikContext } from "@equiem/lib";
import type { TFunction } from "@equiem/localisation-eq1";
import { useTranslation } from "@equiem/localisation-eq1";
import { Button, Form as EqForm, useToast } from "@equiem/react-admin-ui";
import { RiCheckLine, RiCloseLine } from "@equiem/react-admin-ui/icons";

import type { VisitorAppointmentType } from "../../../../generated/visitors-client";
import type { GeneralSettingsFormValues, VisitorTypeFormValue } from "../GeneralSettingsValues";
import { useAppointmentTypes } from "../hooks/useAppointmentTypes";

export const getValidationSchema = (values: VisitorTypeFormValue[], visitorType: VisitorTypeFormValue, t: TFunction) =>
  yup.object().shape({
    name: yup
      .string()
      .required()
      .test("is-unique", t("visitors.appointmentForm.timeValidation"), function (value: string | undefined) {
        return (
          values.every((item) => item.name.toLowerCase() !== value?.toLowerCase() || item === visitorType) ||
          this.createError({ message: t("visitors.visitorTypes.visitorTypeExists", { name: value as string }) })
        );
      }),
  });

const EditingVisitorType = ({ onClose }: { onClose: () => void }) => {
  const { t } = useTranslation();
  const { values, submitForm, errors } = useSaferFormikContext<{ name: string; appointmentTypes: string[] }>();
  const { appointmentTypeOptions } = useAppointmentTypes();
  const toast = useToast();

  return (
    <>
      <div className="editing-type">
        <Field
          variant="sm"
          id="name"
          placeholder={t("visitors.settings.addVisitorTypePlaceholder")}
          name="name"
          type="text"
          as={EqForm.Input}
        />
        <Field
          name="appointmentTypes"
          options={appointmentTypeOptions}
          as={EqForm.MultiSelect}
          size="small"
          withSearch={false}
          mobileView
          variant="wrap"
          placeholder={t("visitors.settings.chooseAppointmentType")}
          isMulti
        />
        <Button
          variant="primary"
          round
          type="submit"
          className="ml-4"
          disabled={values.name.trim().length === 0}
          onClick={() => {
            if (stringNotEmpty(errors.name)) {
              toast.negative(errors.name, { showClose: true });
              return;
            }

            submitForm().catch(console.error);
          }}
        >
          <RiCheckLine size={16} />
        </Button>
        <Button variant="outline" round onClick={onClose} className="ml-2">
          <RiCloseLine size={16} />
        </Button>
      </div>
      <style jsx>
        {`
          .editing-type {
            width: 100%;
            display: grid;
            gap: 4px;
            align-items: center;
            grid-template-columns: 1fr 1fr auto auto;
          }
        `}
      </style>
    </>
  );
};

export const EditingTypeForm = ({
  visitorType,
  onClose,
  onEditSubmit,
}: {
  visitorType: VisitorTypeFormValue;
  onEditSubmit: (
    values: { name: string; appointmentTypes: VisitorAppointmentType[] | null | undefined },
    visitorType: VisitorTypeFormValue,
  ) => void;
  onClose: () => void;
}) => {
  const { t } = useTranslation();
  const { values } = useSaferFormikContext<GeneralSettingsFormValues>();
  return (
    <Formik
      enableReinitialize
      validationSchema={getValidationSchema(values.visitorTypes, visitorType, t)}
      onSubmit={(visitorTypeValue) => onEditSubmit(visitorTypeValue, visitorType)}
      initialValues={{ name: visitorType.name, appointmentTypes: visitorType.appointmentTypes }}
    >
      <EditingVisitorType onClose={onClose} />
    </Formik>
  );
};
