import { stringIsEmpty } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import { Form, useDebounced, useTheme } from "@equiem/react-admin-ui";
import { RiCloseLine } from "@equiem/react-admin-ui/icons";
import type { FieldInputProps } from "formik";
import { useFormikContext } from "formik";
import React, { useState, useCallback, useEffect } from "react";
import { Record, String, Literal, Union } from "runtypes";
import type { GalleryModalFormValues } from "../pages/resources/components/create-and-edit/main/ResourceCreateAndEditFormGalleryModal";

const EmbedResult = Record({
  version: Literal("1.0"),
  type: Literal("video"),
  provider_name: Union(Literal("Vimeo"), Literal("YouTube")),
  url: String,
  thumbnail_url: String,
});

export interface IVideo {
  videoUrl: string;
  thumbnailUrl: string;
}

interface ThumbnailProps {
  className?: string;
  imageUrl: string | null;
  validating: boolean;
  onDelete: () => unknown;
}

const DEBOUNCE_MS = 500;
const IMG_WIDTH = 143;
const IMG_HEIGHT = 80;

const validateLink = async (link: string): Promise<IVideo | null> => {
  if (stringIsEmpty(link)) {
    return null;
  }

  const res = await fetch(
    `https://iframe.ly/api/oembed?key=${process.env.iframelyApiKey}&url=${encodeURIComponent(link)}`,
  );
  if (!res.ok) {
    throw new Error(`failed to find oembed: ${res.status}`);
  }

  const result = EmbedResult.check(await res.json());

  return {
    videoUrl: result.url,
    thumbnailUrl: result.thumbnail_url,
  };
};

const Thumbnail: React.FC<ThumbnailProps> = ({ className, imageUrl, validating, onDelete }) => {
  const { colors, spacers } = useTheme();

  if (!validating && imageUrl == null) {
    return null;
  }

  return (
    <>
      <div className={`img-cont ${className ?? ""}`}>
        {validating ? <div className="shimmer"></div> : <img src={imageUrl ?? ""} alt="" />}
        <div className="delete-img">
          <RiCloseLine onClick={onDelete} />
        </div>
      </div>
      <style jsx>{`
        .img-cont {
          position: relative;
          height: ${IMG_HEIGHT}px;
          width: ${IMG_WIDTH}px;
          border-radius: 4px;
          border: 1px solid ${colors.border};
          overflow: hidden;
        }
        img,
        .shimmer {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        .delete-img {
          position: absolute;
          top: ${spacers.s3};
          right: ${spacers.s3};
          z-index: 1;
          font-size: 20px;
          line-height: 20px;
          cursor: pointer;
          background: white;
          border-radius: 16px;
        }
      `}</style>
    </>
  );
};

export const VideoLinkInputNew: React.FC<FieldInputProps<IVideo | null | undefined>> = ({ name, value }) => {
  const { t } = useTranslation();
  const fm = useFormikContext<GalleryModalFormValues>();

  const [liveVideoUrlValue, setLiveVideoUrlValue] = useState(value?.videoUrl ?? "");
  const debouncedVideoUrlValue = useDebounced(liveVideoUrlValue, DEBOUNCE_MS);

  const [validating, setValidating] = useState(false);

  const setVideo = useCallback(
    (result: IVideo | null) => {
      fm.setFieldValue(name, result).catch(console.error);
      setValidating(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [name],
  );

  const setError = useCallback(() => {
    fm.setFieldValue(`${name}.thumbnailUrl`, null).catch(console.error);
    setValidating(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name]);

  useEffect(() => {
    let active = true;

    if (fm.values.video != null) {
      fm.setFieldValue(`${name}.thumbnailUrl`, null).catch(console.error);
    }
    setValidating(true);

    validateLink(debouncedVideoUrlValue)
      .then((result) => {
        if (active) {
          setVideo(result);
        }
      })
      .catch((e) => {
        if (active) {
          console.error(e);
          setError();
        }
      });

    return () => {
      active = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, debouncedVideoUrlValue, setVideo, setError]);

  return (
    <div className="video-link-input">
      <Form.Input
        name={name}
        placeholder={t("bookings.resources.videoLinkPlaceholder")}
        value={liveVideoUrlValue}
        onChange={(e) => setLiveVideoUrlValue(e.currentTarget.value)}
      />
      <Thumbnail
        className="mt-4"
        imageUrl={fm.values.video?.thumbnailUrl ?? null}
        validating={validating}
        onDelete={() => {
          setVideo(null);
          setLiveVideoUrlValue("");
        }}
      />
    </div>
  );
};
