import type { ComponentProps } from "react";
import React, { forwardRef, useState, useContext, useEffect, useRef, useImperativeHandle } from "react";
import PhoneInput from "react-phone-input-2";

import { useTheme } from "../../../contexts/Theme";
import { FormGroupContext } from "../../../contexts/FormGroupContext";
import type { BorderCssProps } from "../useInputBorderCss";
import { useInputBorderCss } from "../useInputBorderCss";
import { useInputPadding } from "../useInputPadding";
import { usePhoneNumberLocalization } from "./usePhoneNumberLocalization";

// eslint-disable-next-line @typescript-eslint/no-type-alias
export type Props = ComponentProps<"input"> & {
  siteTimezone?: string | null;
  variant?: "md" | "lg" | "sm";
  rounded?: BorderCssProps["rounded"];
  showChrome?: boolean | "onInteraction";
};

const DEFAULT_DROPDOWN_WIDTH_PX = 300;

const valueIfString = (val: unknown): string | null => (typeof val === "string" && val !== "" ? val : null);

const getLineHeight = (variant: Props["variant"]) => {
  switch (variant) {
    case "lg":
      return "24px";
    case "sm":
      return "16px";
    default:
      return "22px";
  }
};

const getCountryGuess = (timezone: string | null | undefined) => {
  if (timezone == null) {
    return "au";
  }

  if (timezone.startsWith("Europe/Dublin")) {
    return "ie";
  }
  if (timezone.startsWith("Europe")) {
    return "gb";
  }
  if (timezone.startsWith("Australia")) {
    return "au";
  }
  if (timezone.startsWith("America")) {
    return "us";
  }
  if (timezone.startsWith("Canada")) {
    return "ca";
  }

  return "au";
};

const getPlaceholder = (countryGuess: string) => {
  switch (countryGuess) {
    case "ie":
      return "Eg. +35312345678";
    case "gb":
      return "Eg. +447911123456";
    case "us":
      return "Eg. +12005550000";
    case "ca":
      return "Eg. +12005550000";
    default:
      return "Eg. +61444123456";
  }
};

const getPreferredCountries = (countryGuess: string) => {
  switch (countryGuess) {
    case "ie":
      return ["ie", "au", "gb", "us", "ca"];
    case "gb":
      return ["gb", "au", "us", "ie", "ca"];
    case "us":
      return ["us", "au", "gb", "ie", "ca"];
    case "ca":
      return ["ca", "us", "au", "gb", "ie"];
    default:
      return ["au", "gb", "us", "ie", "ca"];
  }
};

/**
 * PhoneInput generates some fairly unhelpful change events. Fiddle with the
 * event object to make it useful for external consumers.
 */
const sanitiseChangeEvent = (
  e: React.ChangeEvent<HTMLInputElement>,
  newValue: string,
  { dialCode = "" }: { dialCode?: string },
  inputElement: HTMLInputElement | null,
): React.ChangeEvent<HTMLInputElement> => {
  if (inputElement != null && e.target !== inputElement) {
    // The user has made a selection from the country dropdown and the
    // event target is the dropdown list item (which is not an input element, so
    // the type of the event is a lie...).
    //
    // Formik (or anything else...) won't know what to do with this event
    // because its target doesn't have the `id` / `name` / `value` attributes of
    // the form field, so manually update the event target to the input element.
    e.target = inputElement;
  }

  // Properly format the phone number with leading `+`, dial code, and no
  // leading zeroes.
  const sanitised = newValue.replace(new RegExp(`^\\+?${dialCode}0?`, "u"), `+${dialCode}`);
  e.target.value = sanitised === `+${dialCode}` ? "" : sanitised;

  return e;
};

export const FormPhoneNumber = forwardRef<HTMLInputElement, Props>(
  (
    {
      siteTimezone,
      value: valueProp,
      variant,
      rounded,
      showChrome,
      disabled = false,
      onChange,
      onFocus,
      onBlur,
      ...inputProps
    },
    outerRef,
  ) => {
    const { colors, animationDuration, borderRadius } = useTheme();
    const borderCss = useInputBorderCss({ selector: ".phone-input", rounded, showChrome });
    const padding = useInputPadding();
    const { id, hintId, hasError = false } = useContext(FormGroupContext);
    const locale = usePhoneNumberLocalization();

    const [localValue, setLocalValue] = useState<string>(valueIfString(valueProp) ?? "");
    const [focused, setFocused] = useState(false);

    const ref = useRef<HTMLDivElement>(null);
    useEffect(() => {
      // Work around to get elements in correct order for keyboard nav.
      if (ref.current != null) {
        try {
          const flags = ref.current.querySelector(".flag-dropdown");
          const input = ref.current.querySelector("input");
          if (input != null && flags != null) {
            flags.parentElement?.insertBefore(flags, input);
          }
        } catch {
          // Meh... I tried.
        }
      }
    }, []);

    const [dropdownWidth, setDropdownWidth] = useState(DEFAULT_DROPDOWN_WIDTH_PX);
    useEffect(() => {
      const element = ref.current;
      if (element == null) {
        setDropdownWidth(DEFAULT_DROPDOWN_WIDTH_PX);
        return () => undefined;
      }

      const observer = new ResizeObserver((entries) =>
        entries.forEach((entry) => setDropdownWidth(entry.contentRect.width)),
      );
      observer.observe(element);

      return () => observer.unobserve(element);
    }, []);

    const inputRef = useRef<HTMLInputElement>(null);
    useImperativeHandle(outerRef, () => inputRef.current ?? document.createElement("input"), []);

    const countryGuess = getCountryGuess(siteTimezone);

    return (
      <>
        <div ref={ref} className={`phone-input ${focused ? "focused" : ""} ${disabled ? "disabled" : ""}`}>
          {locale.ready && (
            <PhoneInput
              country={countryGuess}
              placeholder={getPlaceholder(countryGuess)}
              preferredCountries={getPreferredCountries(countryGuess)}
              preserveOrder={["preferredCountries"]}
              localization={locale.localization}
              autoFormat={false}
              countryCodeEditable={false}
              specialLabel=""
              inputProps={{
                ...inputProps,
                "id": inputProps.id ?? id,
                "aria-describedby": hintId,
                "ref": inputRef,
              }}
              disabled={disabled}
              value={localValue}
              onChange={(newValue, data, e) => {
                setLocalValue(newValue);
                onChange?.(sanitiseChangeEvent(e, newValue, data, inputRef.current));
              }}
              onFocus={(e) => {
                setFocused(true);
                onFocus?.(e);
              }}
              onBlur={(e) => {
                setFocused(false);
                onBlur?.(e);
              }}
            />
          )}
        </div>
        <style jsx>{`
          .phone-input {
            transition: ${animationDuration} box-shadow, border;
            width: 100%;
            height: 40px;
            opacity: ${disabled ? "0.5" : "1"};
            border: 1px solid ${colors.border};
            border-radius: ${borderRadius};
            background: ${hasError
              ? `url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0nMTQnIGhlaWdodD0nMTQnIHZpZXdCb3g9JzAgMCAxNCAxNCcgZmlsbD0nbm9uZScgeG1sbnM9J2h0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnJz48cGF0aCBkPSdNNy4wMDA2NSAxMy42NjY3QzMuMzE4NjUgMTMuNjY2NyAwLjMzMzk4NCAxMC42ODIgMC4zMzM5ODQgNy4wMDAwNEMwLjMzMzk4NCAzLjMxODA0IDMuMzE4NjUgMC4zMzMzNzQgNy4wMDA2NSAwLjMzMzM3NEMxMC42ODI3IDAuMzMzMzc0IDEzLjY2NzMgMy4zMTgwNCAxMy42NjczIDcuMDAwMDRDMTMuNjY3MyAxMC42ODIgMTAuNjgyNyAxMy42NjY3IDcuMDAwNjUgMTMuNjY2N1pNNy4wMDA2NSAxMi4zMzM0QzguNDE1MTQgMTIuMzMzNCA5Ljc3MTY5IDExLjc3MTUgMTAuNzcxOSAxMC43NzEzQzExLjc3MjEgOS43NzEwOCAxMi4zMzQgOC40MTQ1MyAxMi4zMzQgNy4wMDAwNEMxMi4zMzQgNS41ODU1NSAxMS43NzIxIDQuMjI5IDEwLjc3MTkgMy4yMjg4QzkuNzcxNjkgMi4yMjg2MSA4LjQxNTE0IDEuNjY2NzEgNy4wMDA2NSAxLjY2NjcxQzUuNTg2MTYgMS42NjY3MSA0LjIyOTYxIDIuMjI4NjEgMy4yMjk0MSAzLjIyODhDMi4yMjkyMiA0LjIyOSAxLjY2NzMyIDUuNTg1NTUgMS42NjczMiA3LjAwMDA0QzEuNjY3MzIgOC40MTQ1MyAyLjIyOTIyIDkuNzcxMDggMy4yMjk0MSAxMC43NzEzQzQuMjI5NjEgMTEuNzcxNSA1LjU4NjE2IDEyLjMzMzQgNy4wMDA2NSAxMi4zMzM0Wk02LjMzMzk4IDkuMDAwMDRINy42NjczMlYxMC4zMzM0SDYuMzMzOThWOS4wMDAwNFpNNi4zMzM5OCAzLjY2NjcxTDcuNjY3MzIgMy42NjY3MVY3LjY2NjcxSDYuMzMzOThWMy42NjY3MVonIGZpbGw9JyNFNjAwMEUnLz48L3N2Zz4=")
              no-repeat calc(100% - 14px) center`
              : disabled
              ? colors.grayscale[3]
              : "white"};
          }
          .phone-input.focused {
            cursor: auto;
            border: 1px solid ${colors.inputBorder} !important;
            box-shadow: 0 0 0 4px rgba(48, 48, 252, 0.1) !important;
          }
          .phone-input :global(.flag-dropdown) {
            z-index: 1;
            background: inherit;
            border-width: 0;
            border-right-width: 1px;
          }
          .phone-input :global(.flag-dropdown:before) {
            display: none;
          }
          .phone-input :global(.country-list) {
            width: ${dropdownWidth}px;
            margin-top: 4px;
            padding: 8px 0;
            border-radius: ${borderRadius};
          }
          .phone-input :global(.country) {
            margin: 0 8px;
            padding: 4px 8px;
            font-size: 0.8rem;
          }
          .phone-input :global(.selected-flag) {
            width: 40px;
            background: inherit !important;
          }
          .phone-input :global(input) {
            font-size: ${variant === "lg" ? "16px" : "14px"} !important;
            line-height: ${getLineHeight(variant)} !important;
            appearance: none;
            float: none;
            outline: none;
            border: none;
            background: none;
            width: 100%;
            height: unset;
            padding: ${padding.shorthand};
            padding-left: 46px;
            cursor: pointer;
          }
          .phone-input :global(input::placeholder) {
            color: ${colors.muted1};
          }
          .phone-input.disabled :global(.flag-dropdown),
          .phone-input.disabled :global(input) {
            cursor: unset;
          }
        `}</style>
        {borderCss.styles}
      </>
    );
  },
);

FormPhoneNumber.displayName = "FormPhoneNumber";
