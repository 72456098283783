import type { PropsWithChildren } from "react";
import React, { useMemo, useState } from "react";
import { DateTime } from "luxon";

import { useSaferFormikContext, useSiteContext } from "@equiem/lib";
import { AppointmentRecurringType } from "@equiem/lib/context/types";

import type { AppointmentRecurringSettings, FormValues } from "../../types";

import { GetReceptionTimezoneAdjustedStartOfDay } from "./RecurringSettingsAuxiliary";

interface Props {
  confirmCancel: () => void;
  onClose: () => void;
}

export interface RecurringSettingsModalContext {
  close: () => void;
  submit: (value: AppointmentRecurringSettings) => void;
  readonly recurringUuid?: string | null;
  recurringSettings: AppointmentRecurringSettings;
  companyUuid: string | undefined;
}

export const RecurringSettingsModalContext = React.createContext<RecurringSettingsModalContext>(
  {} as RecurringSettingsModalContext,
);

export function useRecurringSettingsWidgetContext() {
  return React.useContext(RecurringSettingsModalContext);
}

export const RecurringSettingsModalProvider: React.FC<PropsWithChildren<Props>> = ({
  children,
  onClose,
  confirmCancel,
}) => {
  const { timezone } = useSiteContext();
  const { values, setFieldValue } = useSaferFormikContext<FormValues>();
  const [recurringType] = useState<AppointmentRecurringType>(
    values.recurringType === "current" && values.recurringSettings?.recurringType != null
      ? values.recurringSettings.recurringType
      : values.recurringType != null
      ? (values.recurringType as AppointmentRecurringType)
      : AppointmentRecurringType.Daily,
  );
  const defaultRecurringSettings = {
    repeatUntil: DateTime.fromFormat(values.date, "yyyy-MM-dd")
      .plus({
        days:
          values.recurringType === AppointmentRecurringType.Daily ||
          values.recurringType === AppointmentRecurringType.Weekly
            ? 13
            : 0,
        months: values.recurringType === AppointmentRecurringType.Monthly ? 1 : 0,
      })
      .toMillis(),
    recurringType: recurringType,
    startDate: DateTime.fromFormat(values.date, "yyyy-MM-dd").toMillis(),
    repeatOn: [DateTime.fromFormat(values.date, "yyyy-MM-dd").weekday],
    repeatEvery: 1,
    endDate: DateTime.fromFormat(values.date, "yyyy-MM-dd")
      .plus({
        days:
          values.recurringType === AppointmentRecurringType.Daily ||
          values.recurringType === AppointmentRecurringType.Weekly
            ? 13
            : 0,
        months: values.recurringType === AppointmentRecurringType.Monthly ? 1 : 0,
      })
      .toMillis(),
    recurringDates: [],
  };
  const initialRecurringSettings = useMemo(() => {
    return values.recurringSettings;
  }, []);

  const [recurringSettings, setRecurringSettings] = useState<AppointmentRecurringSettings>(
    values.recurringSettings ?? defaultRecurringSettings,
  );

  useMemo(() => {
    if (values.recurringInfo?.uuid == null && values.recurringType !== "current" && values.recurringType != null) {
      setRecurringSettings(defaultRecurringSettings);
    }
  }, [values.recurringType]);

  const submit = (value: AppointmentRecurringSettings) => {
    setRecurringSettings(value);
    void setFieldValue("recurringType", "current", false);
    void setFieldValue(
      "recurringSettings",
      {
        ...value,
        startDate: GetReceptionTimezoneAdjustedStartOfDay(value.startDate, timezone),
        repeatUntil:
          value.repeatUntil != null ? GetReceptionTimezoneAdjustedStartOfDay(value.repeatUntil, timezone) : undefined,
      },
      false,
    ).then(() => {
      onClose();
    });
  };

  const cancel = () => {
    void setFieldValue("recurringSettings", initialRecurringSettings, false).then(() => {
      confirmCancel();
    });
  };

  return (
    <RecurringSettingsModalContext.Provider
      value={{
        close: () => cancel(),
        submit: (value) => submit(value),
        companyUuid: undefined,
        recurringSettings: recurringSettings,
        recurringUuid: values.recurringInfo?.uuid,
      }}
    >
      {children}
    </RecurringSettingsModalContext.Provider>
  );
};
