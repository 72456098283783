import React, { useContext, useEffect, useMemo, useState } from "react";
import { useApolloClient } from "@apollo/client";

import { CurrentProfile, CurrentRole, Role, UrlParams, useAuthorizationRoles, useSiteContext } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import type { FilterItem } from "@equiem/react-admin-ui";
import {
  ComplexFilter,
  FilterDateModifier,
  FilterOptionsModifier,
  FilterSelectModifier,
  FilterTab,
  FilterTextModifier,
  Form,
  useDebounced,
  useIsMobileWidth,
  useTheme,
} from "@equiem/react-admin-ui";
import {
  RiAccountCircleLine,
  RiAtLine,
  RiBarcodeBoxLine,
  RiCalendarLine,
  RiCheckboxCircleLine,
  RiHistoryLine,
  RiSearchLine,
  RiSettings3Line,
  RiShieldUserLine,
  RiTeamLine,
  RiUserHeartLine,
  RiUserSettingsLine,
} from "@equiem/react-admin-ui/icons";

import type { UserListStatsQuery, UserListStatsQueryVariables } from "../../../generated/settings-client";
import {
  useCompaniesV2Query,
  UserListStatsDocument,
  useSiteDetailsQuery,
  useSiteGroupsQuery,
  useUserSiteAttributesQuery,
} from "../../../generated/settings-client";
import type { PredefinedTabFilters } from "../contexts/UsersFilterContext";
import { UsersFilterContext, userStatusTabs } from "../contexts/UsersFilterContext";
import { useFlexTenantsList } from "../hooks/useFlexTenantsList";

export const UsersFilter: React.FC<{ showLoading: boolean }> = ({ showLoading }) => {
  const isMobile = useIsMobileWidth();
  const { t, i18n } = useTranslation();
  const client = useApolloClient();
  const site = useSiteContext();
  const urlParamsProvider = useContext(UrlParams);
  const [flexSearch, setFlexSearch] = useState<string | undefined>();
  const { currentRole } = useContext(CurrentRole);
  const { isFlexRelated } = useContext(CurrentProfile);
  const { colors, breakpoints } = useTheme();
  const { filters, setFilters, search, setSearch, selectedTab, setSelectedTab, userFilter } =
    useContext(UsersFilterContext);
  const debouncedFilters = useDebounced(filters, 300);
  const authorizationRoles = useAuthorizationRoles();
  const [userStats, setUserStats] = useState<Record<PredefinedTabFilters, number>>({
    ACTIVE: 0,
    DEACTIVATED: 0,
    PENDING_APPROVAL: 0,
    all: 0,
  });

  const groupsData = useSiteGroupsQuery({
    variables: {
      destinationUuid: site.uuid,
    },
  });

  const attributesData = useUserSiteAttributesQuery({
    variables: {
      destinationUuid: site.uuid,
    },
  });

  const siteData = useSiteDetailsQuery({
    variables: {
      uuid: site.uuid,
    },
  });

  const { data: companiesData, refetch: searchCompanies } = useCompaniesV2Query({
    variables: {
      destinationUuid: site.uuid,
      first: 100,
    },
  });

  const flexTenantData = useFlexTenantsList(flexSearch);

  useEffect(() => {
    for (const status of userStatusTabs) {
      void client
        .query<UserListStatsQuery, UserListStatsQueryVariables>({
          query: UserListStatsDocument,
          fetchPolicy: "network-only",
          variables: {
            filter: {
              ...userFilter,
              site: site.uuid,
              status: status === "all" ? undefined : status,
            },
            page: {
              first: 0,
            },
          },
        })
        .then((result) => {
          if (result.data.usersV4 != null) {
            setUserStats((prev) => ({
              ...prev,
              [status]: result.data.usersV4.totalCount,
            }));
          }
        })
        .catch((e) => console.error(e));
    }
  }, [client, site.uuid, userFilter]);

  useEffect(() => {
    if (debouncedFilters.company?.type === "options") {
      void searchCompanies({
        search: debouncedFilters.company.search,
      });
    }
  }, [debouncedFilters.company, searchCompanies]);

  const userTypeOptions = useMemo(() => {
    const options = [];
    if (siteData.data?.destination.settings.registration.commercialSignupEnabled === true) {
      options.push({ label: t("settings.userType.commercial"), value: "commercial" });
    }
    if (siteData.data?.destination.settings.registration.visitorSignupEnabled === true) {
      options.push({ label: t("settings.userType.visitor"), value: "visitor" });
    }
    if (siteData.data?.destination.settings.registration.residentialSignupEnabled === true) {
      options.push({ label: t("settings.userType.residential"), value: "residential" });
    }

    return options;
  }, [siteData, t]);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch?.(e.target.value);
  };

  const clearSearch = () => {
    setSearch?.("");
  };

  const getTabTitle = (tab: PredefinedTabFilters) => {
    switch (tab) {
      case "ACTIVE":
        return t("settings.profileStatus.active");
      case "DEACTIVATED":
        return t("settings.profileStatus.deactivated");
      case "PENDING_APPROVAL":
        return t("settings.profileStatus.pendingApproval");
      case "all":
        return t("settings.allUsers");
      default:
        return "";
    }
  };

  const renderQuickFilters = () => (
    <>
      {userStatusTabs.map((tab) => (
        <FilterTab
          title={getTabTitle(tab)}
          value={userStats[tab]}
          isActive={selectedTab === tab}
          onClick={() => setSelectedTab?.(tab)}
          key={tab}
        />
      ))}
    </>
  );

  const complexFilters = useMemo(() => {
    const f: Record<string, FilterItem> = {
      name: {
        title: t("common.name"),
        type: "text",
        icon: RiAccountCircleLine,
        modifiers: [FilterTextModifier.includes],
      },
      email: {
        title: t("common.email"),
        type: "text",
        icon: RiAtLine,
        modifiers: [FilterTextModifier.includes],
        multiline: true,
        placeholder: "Enter a single email address or multiple separated by commas",
      },
      userType: {
        title: t("settings.userFilters.userType"),
        type: "select",
        options: userTypeOptions,
        icon: RiUserSettingsLine,
        modifiers: [FilterSelectModifier.is],
      },
      emailVerified: {
        title: t("settings.userFilters.verifiedEmail"),
        type: "select",
        options: [
          { label: t("common.yes"), value: "yes" },
          { label: t("common.no"), value: "no" },
        ],
        icon: RiCheckboxCircleLine,
        modifiers: [FilterSelectModifier.is],
      },
      company: {
        title: t("common.company"),
        type: "options",
        options:
          companiesData?.companiesV2.edges.map((edge) => ({
            label: edge.node?.name ?? "",
            value: edge.node?.uuid ?? "",
          })) ?? [],
        icon: RiTeamLine,
        disabled: currentRole !== Role.PropertyManager,
        modifiers: [FilterOptionsModifier.includes],
      },
      profileLastActiveAt: {
        title: t("settings.userFilters.lastSeen"),
        type: "date",
        icon: RiCalendarLine,
        modifiers: [FilterDateModifier.before, FilterDateModifier.after],
      },
      profileCreatedAt: {
        title: t("settings.userFilters.memberSince"),
        type: "date",
        icon: RiHistoryLine,
        modifiers: [FilterDateModifier.before, FilterDateModifier.after],
      },
      emailSubscription: {
        title: t("settings.userFilters.emailSubscription"),
        type: "select",
        options: [
          { label: t("common.yes"), value: "yes" },
          { label: t("common.no"), value: "no" },
        ],
        icon: RiAtLine,
        modifiers: [FilterSelectModifier.is],
      },
      group: {
        title: t("common.groups"),
        type: "options",
        options:
          groupsData.data?.siteGroups.map((group) => ({
            label: group.name,
            value: group.uuid,
          })) ?? [],
        icon: RiSettings3Line,
        modifiers: [FilterOptionsModifier.includes],
      },
      interests: {
        title: t("settings.userFilters.interests"),
        type: "options",
        options:
          attributesData.data?.userSiteAttributes.map((attribute) => ({
            label: attribute.name,
            value: attribute.uuid,
          })) ?? [],
        icon: RiUserHeartLine,
        modifiers: [FilterOptionsModifier.includes],
      },
      authorizationRoles: {
        title: t("settings.userFilters.authorizationRoles"),
        type: "options",
        options: Object.entries(authorizationRoles ?? {}).map(([value, label]) => ({ value, label })),
        icon: RiShieldUserLine,
        modifiers: [FilterOptionsModifier.includes],
      },
    };
    if (isFlexRelated) {
      f.regionalFlexTenants = {
        title: t("settings.flexMembership.tab"),
        type: "options",
        options: flexTenantData,
        searchCallback: async (searchCB: string) => Promise.resolve(setFlexSearch(searchCB)),
        icon: RiTeamLine,
      };
    }
    if (selectedTab === "all") {
      f.active = {
        title: t("common.status"),
        type: "select",
        options: [
          { label: t("settings.profileStatus.active"), value: "ACTIVE" },
          { label: t("settings.profileStatus.deactivated"), value: "DEACTIVATED" },
          { label: t("settings.profileStatus.pendingApproval"), value: "PENDING_APPROVAL" },
        ],
        icon: RiBarcodeBoxLine,
        modifiers: [FilterSelectModifier.is],
      };
    }

    return f;
  }, [
    selectedTab,
    attributesData,
    companiesData,
    currentRole,
    groupsData,
    t,
    userTypeOptions,
    flexTenantData,
    isFlexRelated,
  ]);

  return (
    <>
      <div className="filters">
        <ComplexFilter
          filters={complexFilters}
          onChange={setFilters}
          language={i18n.language}
          urlParamsProvider={urlParamsProvider}
          additionalFilterDropdownContent={
            isMobile ? <div className="quick-filters-dropdown">{renderQuickFilters()}</div> : undefined
          }
          autoShow
        >
          {({ renderChips, renderFilterButton, renderClearButton }) => (
            <>
              <div className="main-row">
                <div className="left-side">{renderQuickFilters()}</div>
                <div className="right-side">
                  <div className="search-container">
                    <Form.InputExtended
                      className="search-input"
                      icon={RiSearchLine}
                      placeholder={`${t("common.search")}...`}
                      variant="sm"
                      value={search}
                      onChange={handleSearch}
                      onClear={clearSearch}
                      loading={showLoading}
                      clearable
                    />
                  </div>
                  {renderFilterButton("primary", "bottom-end")}
                </div>
              </div>
              {!isMobile && Object.keys(filters).length > 0 && (
                <div className="chips-row">
                  <div className="left-side">{renderChips()}</div>
                  <div className="right-side">{renderClearButton()}</div>
                </div>
              )}
            </>
          )}
        </ComplexFilter>
      </div>
      <style jsx>{`
        .filters {
          display: flex;
          flex-direction: column;
          width: 100%;
        }
        .main-row {
          display: flex;
          justify-content: space-between;
          gap: 8px;
        }
        .right-side,
        .left-side {
          display: flex;
          gap: 8px;
          align-items: flex-start;
        }
        .chips-row {
          display: flex;
          justify-content: space-between;
          gap: 8px;
          border-top: 1px solid ${colors.grayscale[10]};
          padding: 8px 0;
          margin-top: 8px;
        }
        .search-container {
          width: 270px;
        }

        @media (max-width: ${breakpoints.lg}px) {
          .main-row {
            flex-direction: column;
            max-width: 100vw;
          }

          .main-row .left-side {
            overflow-x: auto;
            display: none;
          }

          .main-row .left-side::-webkit-scrollbar,
          .quick-filters-dropdown::-webkit-scrollbar {
            display: none;
          }

          .main-row .right-side {
            display: grid;
            grid-template-columns: auto 32px;
            align-items: center;
          }

          .search-container {
            width: 100%;
          }

          .quick-filters-dropdown {
            display: flex;
            gap: 8px;
            align-items: flex-start;
            padding: 8px;
            overflow-x: auto;
            min-height: 48px;
          }
        }
      `}</style>
    </>
  );
};
