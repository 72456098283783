import React, { forwardRef } from "react";
import DOMPurify from "isomorphic-dompurify";

import { sanitiseOptions } from "./domPurifyHook";

interface Props {
  className?: string;
  html: string;
  minHeight?: number;
}

export const EscapedHtmlDiv = forwardRef<HTMLDivElement, Props>(({ className, html, minHeight }, ref) => {
  return (
    <>
      <div
        ref={ref}
        className={`content ${className ?? ""}`}
        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(html, sanitiseOptions) }}
      ></div>
      <style jsx>{`
        .content {
          min-height: ${minHeight ?? 0}px;
        }
        .content :global(img) {
          max-width: 100%;
        }
      `}</style>
    </>
  );
});

EscapedHtmlDiv.displayName = "EscapedHtmlDiv";
