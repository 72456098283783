import React, { useMemo, useState } from "react";
import { Formik } from "formik";
import { DateTime } from "luxon";
import { useRouter } from "next/router";

import { useTranslation } from "@equiem/localisation-eq1";
import { ConfirmerProvider } from "@equiem/react-admin-ui";

import type {
  VisitorAppointmentFragment,
  VisitorForBuildingReceptionFragment,
  VisitorReception,
} from "../../../generated/visitors-client";
import { initialValues } from "../../appointment/initialValues";
import { getValidationSchema } from "../../appointment/utils/validationSchema";
import { AppointmentPage } from "../Appointment";
import { useAppointmentSubmitCallback } from "../hooks/useAppointmentSubmitCallback";
import { ReceptionProvider } from "../ReceptionContext";
import { WalkIn } from "../WalkIn";

import { ReceptionBuildingDesk } from "./BuildingDesk";

export const AllBuildingsReception: React.FC = () => {
  const { t } = useTranslation();
  const router = useRouter();

  const { page, appointmentUuid } = router.query;

  const [printingAppointment, setPrintingAppointment] = useState<VisitorAppointmentFragment | null>();
  const [printingVisitors, setPrintingVisitors] = useState<VisitorForBuildingReceptionFragment[] | undefined>([]);
  const [reception, setReception] = useState<VisitorReception>();
  const [isWalkIn, setIsWalkIn] = useState(false);

  const settings = useMemo(
    () => ({
      enablePassPrinting: Boolean(reception?.enablePassPrinting),
      enableAutomaticPassPrinting: Boolean(reception?.enableAutomaticPassPrinting),
      reception,
      printingAppointment,
      printingVisitors,
      setPrintingAppointment,
      setPrintingVisitors,
      setReception,
    }),
    [printingVisitors, printingAppointment, reception, setPrintingAppointment, setPrintingVisitors],
  );

  const handleSubmit = useAppointmentSubmitCallback({
    appointmentUuid: appointmentUuid as string,
    setPrintingAppointment,
    isAllBuildingReceptions: true,
    reception,
  });

  const renderActivePage = () => {
    switch (page) {
      case "walk_in":
        return <WalkIn isAllBuildingsReceptions />;
      case "appointment":
        return (
          <AppointmentPage
            isAllBuildingsReceptions
            appointmentUuid={typeof appointmentUuid === "string" ? appointmentUuid : undefined}
          />
        );
      case "checked_in":
        return <ReceptionBuildingDesk visitorStatus="CHECKED_IN" />;
      case "checked_out":
        return <ReceptionBuildingDesk visitorStatus="CHECKED_OUT" />;
      default:
        return <ReceptionBuildingDesk visitorStatus="PRE_BOOKED" />;
    }
  };

  return (
    <ReceptionProvider {...settings} isWalkIn setIsWalkIn={setIsWalkIn}>
      <Formik
        initialValues={initialValues}
        validationSchema={getValidationSchema(DateTime.local().zoneName, t, isWalkIn)}
        onSubmit={handleSubmit}
      >
        <ConfirmerProvider>{renderActivePage()}</ConfirmerProvider>
      </Formik>
    </ReceptionProvider>
  );
};
