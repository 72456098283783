import type { PropsWithChildren } from "react";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import type { Invite, Screen, GroupInfo, RoleInfo } from "./NewInvitesContext";
import { NewInvites } from "./NewInvitesContext";
import { v4 as uuidv4 } from "uuid";
import type { InviteRoleTupleInput, InviteUsersResult, InviteUserV3Input } from "./generated/gateway-client";
import { useInviteBulkUsersMutation, UserInvitesDocument } from "./generated/gateway-client";
import { useToast } from "@equiem/react-admin-ui";
import { stringNotEmpty, validEmail } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import uniq from "lodash.uniq";
import { tupleToKey } from "./utils/tupleToKey";

const MIN_INVITE_FIELDS = 1;

const initInvite = ({
  email,
  groups = [],
  roles = [],
  flexTenantUuid,
  isChecked = false,
  companyUuid,
}: {
  email?: string;
  groups?: Invite["groups"];
  roles?: Invite["roles"];
  isChecked?: boolean;
  companyUuid?: string;
  flexTenantUuid?: string;
} = {}): Invite => ({
  id: uuidv4(),
  email,
  groups,
  roles,
  flexTenantUuid,
  isChecked,
  companyUuid,
});

const initInvites = (args: { companyUuid?: string }) => {
  return Array.from(Array(MIN_INVITE_FIELDS)).map(() => initInvite(args));
};

export const NewInvitesProvider: React.FC<
  PropsWithChildren<{
    siteUuid: string;
    viewerFirstName: string;
    viewerLastName: string;
    companyUuid?: string;
  }>
> = ({ children, siteUuid, viewerFirstName, viewerLastName, companyUuid }) => {
  const { t } = useTranslation();
  const [mutation, { loading: isSubmitting }] = useInviteBulkUsersMutation();
  const [submissionResponse, setSubmissionResponse] = useState<InviteUsersResult>();
  const [screen, setScreen] = useState<Screen>("table");
  const toast = useToast();
  const [invites, setInvites] = useState<Invite[]>(initInvites({ companyUuid }));

  const addInviteRow = useCallback(() => {
    setInvites((prev) => [
      ...prev,
      { id: uuidv4(), isChecked: false, groups: [], roles: [], flexTenants: [], companyUuid },
    ]);
  }, [companyUuid]);

  useEffect(() => {
    const keyDownHandler = (event: KeyboardEvent) => {
      if (event.key === "Enter" && screen === "table") {
        event.preventDefault();
        addInviteRow();
      }
    };

    document.addEventListener("keydown", keyDownHandler);

    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, [addInviteRow, screen]);

  const rowsAreSelected = useMemo(() => {
    return invites.filter((i) => i.isChecked).length > 0;
  }, [invites]);

  const updateInviteRow = useCallback((id: string, email: string) => {
    setInvites((prev) =>
      prev.map((p) => ({
        ...p,
        email: p.id === id ? email : p.email,
      })),
    );
  }, []);

  const selectRows = useCallback((ids: string[]) => {
    setInvites((prev) =>
      prev.map((p) => {
        return { ...p, isChecked: ids.includes(p.id) };
      }),
    );
  }, []);

  const removeRows = useCallback(
    (ids: string[]) => {
      setInvites((prev) => {
        const filtered = prev.filter((p) => !ids.includes(p.id));
        const hasMinFields = filtered.length > MIN_INVITE_FIELDS;
        const extended = filtered.concat(hasMinFields ? [] : initInvites({ companyUuid }));
        extended.length = hasMinFields ? filtered.length : MIN_INVITE_FIELDS;

        return extended;
      });
    },
    [companyUuid],
  );

  const updateCompany = useCallback((id: string, newCompanyUuid?: string) => {
    setInvites((prev) =>
      prev.map((p) => {
        if (p.id === id) {
          return {
            ...p,
            companyUuid: newCompanyUuid,
          };
        } else {
          return p;
        }
      }),
    );
  }, []);

  const updateGroups = useCallback((id: string, groups: string[]) => {
    setInvites((prev) =>
      prev.map((p) => {
        if (p.id === id) {
          return {
            ...p,
            groups,
          };
        } else {
          return p;
        }
      }),
    );
  }, []);

  const updateRoles = useCallback((id: string, roles: InviteRoleTupleInput[]) => {
    setInvites((prev) =>
      prev.map((p) => {
        if (p.id === id) {
          return {
            ...p,
            roles,
          };
        } else {
          return p;
        }
      }),
    );
  }, []);

  const updateFlexTenant = useCallback((id: string, flexTenant?: string) => {
    setInvites((prev) =>
      prev.map((p) => {
        if (p.id === id) {
          return {
            ...p,
            flexTenantUuid: flexTenant,
          };
        } else {
          return p;
        }
      }),
    );
  }, []);

  const selectedGroups = useMemo(() => {
    const selected = invites.filter((invite) => invite.isChecked);
    const counting = selected.reduce<Record<string, number>>((prev, curr) => {
      curr.groups.forEach((group) => {
        prev[group] = prev[group] != null ? prev[group] + 1 : 1;
      });

      return prev;
    }, {});

    return Object.keys(counting).map<GroupInfo>((group) => {
      return {
        group,
        mixedSelection: counting[group] !== selected.length,
      };
    });
  }, [invites]);

  const selectedRoles = useMemo(() => {
    const selected = invites.filter((invite) => invite.isChecked);
    const counting = selected.reduce<Record<string, { count: number; role: InviteRoleTupleInput } | undefined>>(
      (prev, curr) => {
        curr.roles.forEach((role) => {
          const key = `${role.type}:${role.id}#${role.relation}`;
          prev[key] = { count: (prev[key]?.count ?? 0) + 1, role };
        });

        return prev;
      },
      {},
    );

    return Object.entries(counting).flatMap<RoleInfo>(([_key, info]) => {
      return info == null
        ? []
        : [
            {
              role: info.role,
              mixedSelection: info.count !== selected.length,
            },
          ];
    });
  }, [invites]);

  const extendGroupsBulk = useCallback(
    (groups: string[]) => {
      setInvites((prev) => {
        const nonPartialGroups = selectedGroups.filter((r) => !r.mixedSelection);
        const removedNonPartialGroups = nonPartialGroups.filter((r) => !groups.includes(r.group)).map((r) => r.group);

        return prev.map((p) => ({
          ...p,
          groups: p.isChecked
            ? uniq(p.groups.filter((r) => !removedNonPartialGroups.includes(r)).concat(groups))
            : p.groups,
        }));
      });
    },
    [selectedGroups],
  );

  const extendRolesBulk = useCallback(
    (rolesInput: InviteRoleTupleInput[]) => {
      const roles = rolesInput.map((r) => ({ ...r, key: tupleToKey(r) }));

      setInvites((prev) => {
        const nonPartialRoles = selectedRoles
          .filter((r) => !r.mixedSelection)
          .map((r) => ({ ...r, key: tupleToKey(r.role) }));
        const removedNonPartialRoles = nonPartialRoles.filter((r) => !roles.some(({ key }) => r.key === key));

        return prev.map((p) => ({
          ...p,
          roles: p.isChecked
            ? p.roles
                .map((r) => ({ ...r, key: tupleToKey(r) }))
                .filter(
                  (r) =>
                    !removedNonPartialRoles.some(({ key }) => r.key === key) && !roles.some(({ key }) => r.key === key),
                )
                .concat(roles)
                // drop the key field.
                .map(({ key: _key, ...role }) => role)
            : p.roles,
        }));
      });
    },
    [selectedRoles],
  );

  const hasAnyFilledRow = useMemo(() => {
    return invites.some(
      (invite) =>
        stringNotEmpty(invite.email) ||
        invite.groups.length > 0 ||
        invite.roles.length > 0 ||
        invite.flexTenantUuid != null ||
        invite.companyUuid != null,
    );
  }, [invites]);

  const allValidEmails = useMemo(
    () => invites.flatMap((invite) => (stringNotEmpty(invite.email) && validEmail(invite.email) ? [invite.email] : [])),
    [invites],
  );

  const validatedInvites = useMemo(
    () =>
      invites.map((invite) => {
        if (!stringNotEmpty(invite.email)) {
          return { ...invite, error: undefined };
        }
        if (!validEmail(invite.email)) {
          return { ...invite, error: `${t("common.invalidEmailAddress")}.` };
        }
        const target = invites.filter((inviteCheck) => inviteCheck.email === invite.email);
        if (target.length > 1) {
          return { ...invite, error: `${t("home.widgets.welcomeEmailAlreadyEntered")}.` };
        }

        return { ...invite, error: undefined };
      }),
    [invites, t],
  );

  const addBulkEmails = useCallback(
    (emails: string[]) => {
      setInvites((prev) => {
        const newInvites = uniq(emails).flatMap<Invite>((emailInput) => {
          const email = emailInput.toLowerCase();
          const exist = allValidEmails.findIndex((existingEmail) => existingEmail === email) > -1;

          return exist ? [] : [initInvite({ email, companyUuid })];
        });

        const processed = prev
          .filter((p) => stringNotEmpty(p.email) || p.groups.length > 0 || p.roles.length > 0)
          .concat(newInvites);
        const hasMinFields = processed.length > MIN_INVITE_FIELDS;
        const extended = processed.concat(hasMinFields ? [] : initInvites({ companyUuid }));
        extended.length = hasMinFields ? extended.length : MIN_INVITE_FIELDS;

        return extended;
      });
    },
    [allValidEmails, companyUuid],
  );

  const close = useCallback(
    (closeModal?: () => void) => {
      setInvites(initInvites({ companyUuid }));
      setScreen("table");
      closeModal?.();
    },
    [companyUuid],
  );

  const disableSubmit = useMemo(() => {
    const allEmails = validatedInvites
      .filter((invite) => stringNotEmpty(invite.email))
      .map((invite) => invite.error == null);

    return isSubmitting || allEmails.length === 0 || allEmails.some((valid) => !valid);
  }, [isSubmitting, validatedInvites]);

  const submit = useCallback(
    async (closeModal: () => void) => {
      if (disableSubmit) {
        toast.negative(t("home.widgets.inviteHasError"));
        return;
      }

      const users = invites
        .filter((i) => stringNotEmpty(i.email))
        .map(
          (i): InviteUserV3Input => ({
            email: i.email!,
            groupUuids: i.groups,
            roleTuples: i.roles,
            companyUuid: i.companyUuid,
            flexTenantUuid: i.flexTenantUuid,
          }),
        );

      const result = await mutation({
        variables: {
          users,
          destinationUuid: siteUuid,
          fromFirstName: viewerFirstName,
          fromLastName: viewerLastName,
        },
        refetchQueries: [UserInvitesDocument],
      });
      if (result.data?.inviteUsersV3.existingEmails !== "" || result.data.inviteUsersV3.existingInviteEmails !== "") {
        setSubmissionResponse(result.data?.inviteUsersV3);
        setScreen("error");
      } else if (result.errors != null) {
        toast.negative(t("home.widgets.inviteError"));
        close(closeModal);
      } else {
        toast.positive(t("home.widgets.inviteSuccess", { count: users.length }));
        close(closeModal);
      }
    },
    [close, invites, mutation, siteUuid, t, toast, disableSubmit, viewerFirstName, viewerLastName],
  );

  return (
    <NewInvites.Provider
      value={{
        invites,
        validatedInvites,
        allValidEmails,
        selectedGroups,
        selectedRoles,
        addInviteRow,
        updateInviteRow,
        disableSubmit,
        submit,
        isSubmitting,
        close,
        selectRows,
        removeRows,
        extendGroupsBulk,
        extendRolesBulk,
        updateGroups,
        updateRoles,
        updateFlexTenant,
        rowsAreSelected,
        hasAnyFilledRow,
        screen,
        setScreen,
        addBulkEmails,
        submissionResponse,
        updateCompany,
      }}
    >
      {children}
    </NewInvites.Provider>
  );
};
