import React, { useContext, useEffect, useState } from "react";
import { Modal, Text, useTheme, useToast } from "@equiem/react-admin-ui";
import { CookiePreferencesForm, withDefaultPrevented } from "@equiem/lib";
import { CookieSettings } from "../../contexts/CookieContext";
import { useTranslation } from "@equiem/localisation-eq1";
import { PageContext } from "@equiem/lib/context/PageContext";

export const CookiePreferences = () => {
  const context = useContext(CookieSettings);
  const { isInWebNgLayout } = useContext(PageContext);
  const { cookiePreferencesSaved, setOptionalCookiesEnabled, setCookiePreferencesSaved } = context;

  const { spacers } = useTheme();
  const toast = useToast();
  const { t } = useTranslation();

  const [showModal, setShowModal] = useState(false);
  const [showBasic, setShowBasic] = useState(!cookiePreferencesSaved);
  const [shown, setShown] = useState(false);

  const hide = () => {
    setShowModal(false);
  };

  const show = () => {
    setShowModal(true);
  };

  useEffect(() => {
    const titleAccept = t("home.cookiePreference.acceptCookies");
    const titleManage = t("home.cookiePreference.managePreferences");
    if (showBasic && !shown) {
      setShown(true);
      const cookieToast = toast.neutral(
        <div className="cookiebanner">
          <Text variant="text" size="small" className="m-0">
            {t("home.cookiePreference.weUseCookiesToImprove")}
            <br />
            {t("home.cookiePreference.forMoreInformationSee")}
            <a href="/cookiepolicy" target="_blank" style={{ color: "white" }}>
              {t("home.cookiePreference.cookiePolicy")}
            </a>
          </Text>
        </div>,
        {
          name: "cookieTray",
          size: "large",
          autoDismiss: false,
          primaryAction: {
            title: titleAccept,
            onClick: () => {
              setOptionalCookiesEnabled(true);
              setCookiePreferencesSaved();
              setShowBasic(false);
              cookieToast.remove();
            },
          },
          secondaryAction: {
            title: titleManage,
            onClick: () => {
              setShowBasic(false);
              show();
              cookieToast.remove();
            },
          },
        },
      );
    }
  }, [setCookiePreferencesSaved, setOptionalCookiesEnabled, showBasic, t, toast, shown]);

  return (
    <>
      <Modal.Dialog
        title={t("home.cookiePreference.managePreferences")}
        show={showModal}
        onHide={hide}
        hideOnEsc={false}
        size="lg"
        className="cookiemodal"
        centered
        withFooter
        hideOnClick={false}
        scrollable={true}
      >
        <Modal.Header closeButton={false} noBorder={true} />
        <CookiePreferencesForm context={context} onSubmitComplete={hide} />
      </Modal.Dialog>
      <a href="#" onClick={withDefaultPrevented(() => show())}>
        {isInWebNgLayout ? t("webng.cookieSettings") : t("home.cookiePreference.cookiePreferences")}
      </a>
      <style jsx>{`
        h1 {
          font-weight: 700;
          margin-bottom: ${spacers.s3};
        }
        label {
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
        }
        .sub-text {
          font-size: 14px;
        }
      `}</style>
    </>
  );
};
