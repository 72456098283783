/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { useContext, useEffect, useState } from "react";

import { useTranslation } from "@equiem/localisation-eq1";
import { Button, Modal } from "@equiem/react-admin-ui";

import { Modal as ModalContext } from "../../../contexts/ModalContext";

export const CardIdStatusWidget: React.FC<{ handleAction: ((wasReturned: boolean) => Promise<void>) | undefined }> = ({
  handleAction,
}) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const modal = useContext(ModalContext);

  useEffect(() => {
    if (modal.activeModal === "CardIdStatus") {
      setShowModal(true);
    }
  }, [modal.activeModal, setShowModal]);

  const handleNo = async () => {
    if (handleAction !== undefined) {
      await handleAction(false);
    }
    setShowModal(false);
    modal.close();
  };

  const handleYes = async () => {
    if (handleAction !== undefined) {
      await handleAction(true);
    }
    setShowModal(false);
    modal.close();
  };

  return (
    <>
      <Modal.Dialog
        title={t("visitors.reception.checkOut")}
        show={showModal}
        hideOnEsc
        hideOnClick={false}
        focusTrapOptions={{ allowOutsideClick: () => true }}
        size="sm"
        centered
      >
        <Modal.Header intro={t("visitors.reception.accessCardReturn")} closeButton={false} noBorder />
        <Modal.Body>
          <p className="body m-0">{t("visitors.reception.cardReturnBody")}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="ghost" className="mr-4" onClick={async () => handleNo()}>
            {t("common.no")}
          </Button>
          <Button variant="primary" onClick={async () => handleYes()}>
            {t("common.yes")}
          </Button>
        </Modal.Footer>
      </Modal.Dialog>
      <style jsx>
        {`
          .body p {
            font-weight: 400;
            font-size: 16px;
          }
          .font-weight-bold {
            line-height: 28px;
          }
        `}
      </style>
    </>
  );
};
