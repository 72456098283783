import React, { useCallback, useMemo } from "react";
import type { BookableResourceFeatureType } from "../../../../generated/gateway-client";
import {
  useBookableResourceFeaturesQuery,
  useCreateUpdateResourceFeatureMutation,
} from "../../../../generated/gateway-client";
import { useToast } from "@equiem/react-admin-ui";
import type { FormValues } from "./helpers/FormValues";
import { ResourceConfigurationList } from "./ResourceConfigurationList";
import { ResourceFeatureDelete } from "./ResourceFeatureDelete";
import type { Item } from "./helpers/Item";
import { useTranslation, useErrorTranslation } from "@equiem/localisation-eq1";
import { resourceFeatureToString } from "../../../../lib/resourceTypeToString";

interface P {
  featureType: BookableResourceFeatureType;
  createBtnTitle: string;
}
export const ResourceFeatureList: React.FC<P> = ({ featureType, createBtnTitle }) => {
  const { t } = useTranslation();
  const { tError } = useErrorTranslation();
  const { data, loading, refetch } = useBookableResourceFeaturesQuery({
    variables: { featureType },
    fetchPolicy: "network-only",
  });
  const [mutation] = useCreateUpdateResourceFeatureMutation();
  const toast = useToast();

  const submitIt = useCallback(
    async (values: FormValues) => {
      try {
        const iconName = values.iconName ?? undefined;
        if (iconName == null) {
          throw new Error(t("common.selectIcon"));
        }

        await mutation({
          variables: {
            input: {
              name: values.name,
              uuid: values.uuid,
              type: featureType,
              iconName,
            },
          },
        });
        await refetch();

        return true;
      } catch (e: unknown) {
        toast.negative(tError(e), { autoDismiss: true });
      }

      return false;
    },
    [featureType, mutation, refetch, t, tError, toast],
  );

  const deleteComponent = (p: { item: Item; disable: boolean }) => {
    return (
      <ResourceFeatureDelete
        item={p.item}
        featureType={featureType}
        disable={p.disable}
        refetch={async () => {
          await refetch();
        }}
      />
    );
  };

  const resourceFeatures = useMemo(() => {
    return (data?.bookableResourceFeatures ?? []).map((resourceFeature) => {
      return resourceFeature.locked
        ? {
            ...resourceFeature,
            name: resourceFeatureToString(resourceFeature.name, t),
          }
        : resourceFeature;
    });
  }, [data?.bookableResourceFeatures, t]);

  return (
    <ResourceConfigurationList
      data={resourceFeatures}
      initialValues={{ name: "" }}
      deleteComponent={deleteComponent}
      submitIt={submitIt}
      dataLoading={loading}
      createBtnTitle={createBtnTitle}
      enableIcon={true}
    />
  );
};
