import { useContext } from "react";

import { useMyFlexTenantsQuery } from "@equiem/flex/generated/gateway-client";
import { CurrentProfile } from "@equiem/lib";
import type { FilterOption } from "@equiem/react-admin-ui";

export const useFlexTenantsList = (search?: string): FilterOption[] => {
  const { profile, companyIsFlexOperator } = useContext(CurrentProfile);

  const { data } = useMyFlexTenantsQuery({
    variables: {
      first: 20,
      search,
    },
    skip: !companyIsFlexOperator,
  });

  return [...(profile?.companyV2?.flexTenants ?? []), ...(data?.myFlexTenants.edges.map((e) => e.node) ?? [])].flatMap(
    (ft) => (ft == null ? [] : [{ label: ft.name, value: ft.uuid }]),
  );
};
