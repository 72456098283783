import { useTranslation } from "@equiem/localisation-eq1";
import { useTheme } from "@equiem/react-admin-ui";
import React, { useContext } from "react";

import { ResourceEditAccessControlContext } from "../../../../../contexts/ResourceEditAccessControlContext";
import { ResourceCreateAndEditFormAccordion } from "./ResourceCreateAndEditFormAccordion";
import { ResourceCreateAndEditFormAdditionalSettings } from "./ResourceCreateAndEditFormAdditionalSettings";
import { ResourceCreateAndEditFormAvailability } from "./ResourceCreateAndEditFormAvailability";
import { ResourceCreateAndEditFormCancellationPermissions } from "./ResourceCreateAndEditFormCancellationPermissions";
import { ResourceCreateAndEditFormConfigurations } from "./ResourceCreateAndEditFormConfigurations";
import { ResourceCreateAndEditFormDetails } from "./ResourceCreateAndEditFormDetails";
import { ResourceCreateAndEditFormExtras } from "./ResourceCreateAndEditFormExtras";
import { ResourceCreateAndEditFormGallery } from "./ResourceCreateAndEditFormGallery";
import { ResourceCreateAndEditFormPermissions } from "./ResourceCreateAndEditFormPermissions";
import { ResourceCreateAndEditFormRates } from "./ResourceCreateAndEditFormRates";
import { ResourceCreateAndEditFormTermsAndConditions } from "./ResourceCreateAndEditFormTermsAndConditions";
import { ResourceCreateAndEditFormAccessControl } from "./ResourceCreateAndEditFormAccessControl";
import { ResourceCreateAndEditFormLocation } from "./ResourceCreateAndEditFormLocation";

export const ResourceCreateAndEditFormMain: React.FC = () => {
  const { t } = useTranslation();
  const { accessControlEnabledForBuilding } = useContext(ResourceEditAccessControlContext);
  const { breakpoints, spacers } = useTheme(true);

  const accordionItems = [
    { title: t("bookings.resources.location"), key: "location", content: <ResourceCreateAndEditFormLocation /> },
    { title: t("bookings.resources.details"), key: "details", content: <ResourceCreateAndEditFormDetails /> },
    {
      title: t("bookings.resources.photosAndVideos"),
      key: "photos-and-videos",
      content: <ResourceCreateAndEditFormGallery />,
    },
    { title: t("bookings.resources.rates"), key: "rates", content: <ResourceCreateAndEditFormRates /> },
    {
      title: t("bookings.resources.configurations"),
      key: "configurations",
      content: <ResourceCreateAndEditFormConfigurations />,
    },
    {
      title: t("bookings.resources.availability"),
      key: "availability",
      content: <ResourceCreateAndEditFormAvailability />,
    },
    { title: t("bookings.resources.extras"), key: "extras", content: <ResourceCreateAndEditFormExtras /> },
    {
      title: t("bookings.resources.permissions"),
      key: "permissions",
      content: <ResourceCreateAndEditFormPermissions />,
    },
    {
      title: t("bookings.resources.additionalSettings"),
      key: "additional-settings",
      content: <ResourceCreateAndEditFormAdditionalSettings />,
    },
    accessControlEnabledForBuilding
      ? {
          title: t("bookings.resources.accessControl"),
          key: "access-control",
          content: <ResourceCreateAndEditFormAccessControl />,
        }
      : [],
    {
      title: t("bookings.resources.termsAndConditions"),
      key: "terms-and-conditions",
      content: <ResourceCreateAndEditFormTermsAndConditions />,
    },
    {
      title: t("bookings.resources.cancellationPermissions"),
      key: "cancellation-permissions",
      content: <ResourceCreateAndEditFormCancellationPermissions />,
    },
  ];

  return (
    <div className="form-main-container">
      <ResourceCreateAndEditFormAccordion items={accordionItems.flat()} />
      <style jsx>{`
        .form-main-container {
          flex: 55;
          padding: ${spacers.s7} ${spacers.s6};
          max-height: 100vh;
          overflow-y: auto;
          z-index: 1;
        }
        @media screen and (max-width: ${breakpoints.md}px) {
          .form-main-container {
            flex: "auto";
            padding: ${spacers.s5};
          }
        }
      `}</style>
    </div>
  );
};
