import { String, Partial } from "runtypes";

const JWT = Partial({
  "http://getequiem.com/eqone_roles": String,
  "http://getequiem.com/portal": String,
});

export const parseJwt = (token: string) => {
  try {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const parsed = JSON.parse(atob(token.split(".")[1]));

    return JWT.check(parsed);
  } catch (e: unknown) {
    return null;
  }
};
