import React, { useContext, useEffect, useMemo } from "react";
import { Field, Form } from "formik";

import { notNullOrUndefined, useSaferFormikContext, useShowError, useSiteContext } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import { Button, Dropdown, Form as EqForm, Text, useDebounced, useTheme, useToast } from "@equiem/react-admin-ui";

import { LevelsList } from "../../../components/LevelsList";
import { Modal as ModalContext } from "../../../contexts/ModalContext";
import { useAddApartmentDestinationMutation, useApartmentListLazyQuery } from "../../../generated/settings-client";
import type { FormValues } from "../types";

interface Props {
  isEdit?: boolean;
}

export const ApartmentForm: React.FC<Props> = ({ isEdit }) => {
  const { colors } = useTheme();
  const site = useSiteContext();
  const { t } = useTranslation();
  const { values, errors, touched } = useSaferFormikContext<FormValues>();
  const debouncedValues = useDebounced(values, 500);
  const [getApartments, { data: suggestions }] = useApartmentListLazyQuery();
  const [addMutation] = useAddApartmentDestinationMutation();
  const toast = useToast();
  const showError = useShowError();
  const modal = useContext(ModalContext);

  const suggestedApartments = useMemo(() => {
    return suggestions?.apartments.edges.map((edge) => edge.node).filter(notNullOrUndefined) ?? [];
  }, [suggestions]);

  useEffect(() => {
    void getApartments({ variables: { search: values.name } });
  }, [debouncedValues.name]);

  const handleAdd = (uuid: string, name: string) => () => {
    addMutation({
      variables: { destination: site.uuid, apartments: [uuid] },
      refetchQueries: ["ApartmentList"],
    })
      .catch(showError)
      .finally(() => {
        toast.neutral(t("settings.companyAdded", { company: name }));
        modal.close();
      });
  };

  const getFieldError = (field: keyof typeof values) =>
    touched[field] === true || Array.isArray(touched[field]) ? errors[field]?.toString() : undefined;

  return (
    <div className="mb-4">
      <div className="main-container-inner">
        <Form>
          <EqForm.Group
            required
            label={t("settings.editApartment.apartmentName")}
            error={getFieldError("name")}
            showTooltip
            tooltipText={t("settings.editApartment.apartmentNameHint")}
          >
            <Dropdown.Container
              placement="bottom-start"
              trigger={<Field as={EqForm.Input} name="name" disabled={isEdit} autoComplete="off" />}
              width={100}
              sameWidth
              disableKeyboard
            >
              {isEdit === false && values.name.length > 0 && suggestedApartments.length > 0 ? (
                <div className="suggestions-list">
                  <Text variant="label" className="title" component="p">
                    {t("settings.editApartment.searchHint")}
                  </Text>
                  {suggestedApartments.map((node) => (
                    <div className="suggestion" key={node.uuid}>
                      <Text variant="text" weight="bold" component="span">
                        {node.name}
                      </Text>
                      <Button variant="outline" size="md" onClick={handleAdd(node.uuid, node.name)}>
                        {t("settings.addToSite")}
                      </Button>
                    </div>
                  ))}
                </div>
              ) : null}
            </Dropdown.Container>
          </EqForm.Group>
          <hr className="mt-6 mb-6 divider" />
          <Text variant="heading" size="small">
            {t("settings.levels")}
          </Text>
          <Text variant="text">{t("settings.levelsHintApartment")}</Text>
          <LevelsList name="levels" />
        </Form>
      </div>
      <style jsx>
        {`
          .main-container-inner {
            padding-right: 0px !important;
          }

          .divider {
            border: none;
            border-top: 1px solid ${colors.grayscale[10]};
          }

          .suggestions-list {
            width: 100%;
            padding: 8px 16px 0px;
          }

          .suggestions-list :global(.title) {
            margin: 0;
            color: ${colors.grayscale[60]};
          }

          .suggestion {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 8px 0;
          }
        `}
      </style>
    </div>
  );
};
