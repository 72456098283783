import type { PropsWithChildren } from "react";
import React, { useState, type FC, useEffect, useRef, useCallback } from "react";
import { ArticleContext } from "./ArticleContext";
import type { CmsArticleFragmentFragment } from "../generated/gateway-client";
import { useBcUuidContext } from "@equiem/lib";
import type { FormikProps } from "formik";

interface Props {
  article?: CmsArticleFragmentFragment;
}

export const ArticleProvider: FC<PropsWithChildren<Props>> = ({ article, children }) => {
  const [savedArticle, setArticle] = useState(article);

  const { updateBcUuid, bcUuid } = useBcUuidContext();
  useEffect(() => {
    if (article != null) {
      if (bcUuid == null || bcUuid[article.uuid] !== article.draftContent.title) {
        updateBcUuid(article.uuid, article.draftContent.title ?? article.uuid);
      }
    }
  }, [article, bcUuid, updateBcUuid]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const formRef = useRef<FormikProps<any>>(null);
  const forceAutoSave = useCallback(async () => {
    if (formRef.current?.dirty === true) {
      await formRef.current.submitForm();
    }
  }, []);

  return (
    <ArticleContext.Provider
      value={{
        article: savedArticle,
        setArticle,
        articleFormRef: formRef,
        forceAutoSave,
      }}
    >
      {children}
    </ArticleContext.Provider>
  );
};
