import { useEffect, useContext } from "react";
import { useRouter } from "next/router";
import mapValues from "lodash/mapValues";
import qs from "querystring";

import { useSiteContext } from "../context/SiteContext";
import { CurrentProfile } from "../context/ProfileContext";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface Params<T extends Record<string, any>> {
  initial: T;
  parse: { [P in keyof T]: (value: string) => T[P] };
  /** Clear all query variables on change, other than what is provided in `newState` */
  clearQueryOnChange?: boolean;
  /**
   * Store the most recent set of query parameters in LocalStorage, and fall
   * back to that if the page is loaded with no query parameters.
   */
  rememberLastState?: boolean;
  storeChangesInHistory?: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useQueryState<T extends Record<string, any>>({
  initial,
  parse,
  clearQueryOnChange = false,
  rememberLastState = false,
  storeChangesInHistory = false,
}: Params<T>): [T, (newState: Partial<T>) => void] {
  const router = useRouter();
  const site = useSiteContext();
  const { profile } = useContext(CurrentProfile);
  const storageKey = `_eq_pagestate_${site.uuid}_${profile?.uuid}_${router.pathname}`;

  const state = mapValues(initial, (value, name) => {
    const queryValue = router.query[name];
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return typeof queryValue === "string" ? parse[name](queryValue) : value;
  }) as T;

  const setState = (newState: Partial<T>) => {
    const newQuery = clearQueryOnChange ? newState : { ...router.query, ...newState };

    if (storeChangesInHistory) {
      router.push({ query: newQuery }, undefined, { shallow: true }).catch(console.error);
    } else {
      router.replace({ query: newQuery }, undefined, { shallow: true }).catch(console.error);
    }

    if (rememberLastState) {
      localStorage.setItem(storageKey, qs.encode(newQuery));
    }
  };

  useEffect(() => {
    if (rememberLastState && Object.keys(router.query).length === 0) {
      const stored = localStorage.getItem(storageKey);
      if (stored != null) {
        // make sure we uri-encode any junk that someone puts into local storage
        const parsed = new URLSearchParams(stored).toString();
        router.replace({ query: parsed }, undefined, { shallow: true }).catch(console.error);
      }
    }
    // no dependencies as this should run exactly once, on hook mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [state, setState];
}
