import React, { useContext, useMemo } from "react";
import { DateTime } from "luxon";

import { useSaferFormikContext } from "@equiem/lib";
import { formatters, useTranslation } from "@equiem/localisation-eq1";
import { Avatar, useTheme } from "@equiem/react-admin-ui";
import { RiEdit2Line } from "@equiem/react-admin-ui/icons";

import { ModalChild } from "../../../contexts/ModalChildContext";
import type { FormValues } from "../types";

interface UserCardHeader {
  firstName: string;
  lastName: string;
  company: string;
  avatar?: string | null;
  created?: number;
}
export const UserCardHeader: React.FC<UserCardHeader> = ({ firstName, lastName, company, created, avatar }) => {
  const { colors } = useTheme();
  const { t, i18n } = useTranslation();
  const modal = useContext(ModalChild);
  const { values } = useSaferFormikContext<FormValues>();
  const createdDate = useMemo(
    () => (created != null ? formatters.dateshort(DateTime.fromMillis(created), i18n.language) : ""),
    [created, i18n.language],
  );

  const imgUrl = useMemo(
    () => (values.avatar != null && values.avatar !== "" ? values.avatar : avatar),
    [values.avatar, avatar],
  );

  return (
    <>
      <div className="d-flex card">
        <div className="d-flex profile-image">
          <Avatar firstName={firstName} lastName={lastName} imageUrl={imgUrl} size="x-large" />
          <div onClick={() => modal.open("AvatarUpload")} className="edit-profile update-avatar">
            <RiEdit2Line />
          </div>
        </div>
        <div className="info-container pb-6">
          <h3 className="name-info">{`${firstName} ${lastName}`}</h3>
          <h5 className="additional-info"> {t("settings.editProfile.memberSince", { company, createdDate })}</h5>
        </div>
      </div>
      <style jsx>
        {`
          .card {
            width: 100%;
            height: 220px;
            background: rgb(143, 107, 93);
            background: radial-gradient(circle, rgba(143, 107, 93, 1) 0%, rgba(130, 126, 120, 1) 100%);
            flex-direction: column;
          }

          .profile-image {
            align-self: center;
            margin: auto;
            position: relative;
          }
          .edit-profile {
            position: absolute;
            width: 32px;
            height: 32px;
            background: white;
            border-radius: 50%;
            bottom: 0px;
            right: 2px;
            text-align: center;
            color: ${colors.primary};
            padding-top: 4px;
            cursor: pointer;
          }
          .info-container {
            text-align: center;
            color: white;
          }
          .name-info {
            font-weight: 700;
          }
          img {
            width: inherit;
            border-radius: 50%;
          }
        `}
      </style>
    </>
  );
};
