import type { ComponentProps, FormEvent, KeyboardEvent } from "react";
import React, { forwardRef, useCallback, useContext, useMemo, useState } from "react";
import { useTranslation } from "@equiem/localisation-eq1";
import { RiEyeLine, RiEyeOffLine } from "react-icons/ri";

import { useTheme } from "../../../contexts/Theme";
import { FormGroupContext } from "../../../contexts/FormGroupContext";
import { Tooltip } from "../../Tooltip/Tooltip";
import type { BorderCssProps } from "../useInputBorderCss";
import { useInputBorderCss } from "../useInputBorderCss";
import { useInputPadding } from "../useInputPadding";

// eslint-disable-next-line @typescript-eslint/no-type-alias
export type Props = ComponentProps<"input"> & {
  variant?: "md" | "lg" | "sm" | "sm-plus";
  rounded?: BorderCssProps["rounded"];
  dropdownIcon?: ({ color }: { color: string }) => string;
  disabled?: boolean;
  showChrome?: boolean | "onInteraction";
  calendarIconPosition?: "left" | "right";
};

const PasswordToggle: React.FC<
  React.PropsWithChildren<{
    showToggle: boolean;
    showPassword: boolean;
    setShowPassword: (value: boolean) => void;
  }>
> = ({ children, showToggle, showPassword, setShowPassword }) => {
  const { colors } = useTheme();
  const { t } = useTranslation();

  if (!showToggle) {
    // don't render any extra elements if we don't need them
    return <>{children}</>;
  }

  const Icon = showPassword ? RiEyeOffLine : RiEyeLine;
  const title = showPassword ? t("common.passwordHide") : t("common.passwordShow");

  return (
    <>
      <div className="password-container">
        {children}
        <Tooltip title={title}>
          <div
            className="show-hide"
            role="button"
            aria-label={title}
            tabIndex={0}
            onClick={() => setShowPassword(!showPassword)}
          >
            <Icon size="1.25rem" />
          </div>
        </Tooltip>
      </div>
      <style jsx>{`
        .password-container {
          position: relative;
        }
        .show-hide {
          position: absolute;
          top: calc(50% - 0.625rem);
          right: 0.625rem;
          cursor: pointer;
          color: ${colors.grayscale[40]};
        }
        .show-hide:hover {
          color: ${colors.grayscale[60]};
        }
      `}</style>
    </>
  );
};

// eslint-disable-next-line complexity
export const FormInput = forwardRef<HTMLInputElement, Props>((props, ref) => {
  const { disabled = false } = props;
  const [localValue, setLocalValue] = useState<string>(typeof props.value === "string" ? props.value : "");
  const [showPassword, setShowPassword] = useState(false);
  const { id, hintId, hasError = false } = useContext(FormGroupContext);
  const { colors, animationDuration } = useTheme();
  const borderCss = useInputBorderCss({
    rounded: props.rounded,
    showChrome: props.showChrome ?? (props.readOnly != null ? !props.readOnly : undefined),
  });
  const value = props.value ?? props.defaultValue ?? localValue;
  const padding = useInputPadding();
  const lineHeight = useMemo(() => {
    if (props.variant === "lg") {
      return "24px";
    }

    if (props.variant === "sm-plus") {
      return "18px";
    }

    if (props.variant === "sm") {
      return "16px";
    }
    return "22px";
  }, [props.variant]);

  const leftIndicator = props.type !== "password" && props.calendarIconPosition === "left";
  const indicatorWidth = "2.5rem";

  const allowOnlyNumbers = props.type === "number" && (props.step == null || Number.isInteger(props.step));

  const handleMinMax = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (props.type !== "number" || e.target.value == null || e.target.value === "") {
        return;
      }

      const val = Number(e.target.value);
      if (props.max != null) {
        const max = Number(props.max);
        if (val > max) {
          e.target.value = max.toString();
        }
      }

      if (props.min != null) {
        const min = Number(props.min);
        if (val < min) {
          e.target.value = min.toString();
        }
      }
    },
    [props.type, props.min, props.max],
  );

  // Catching each input press.
  const numbersOnlyKeyDownEventListener = (e: KeyboardEvent<HTMLInputElement>) => {
    if (
      // Allow ctrl/option/command keys.
      e.metaKey ||
      e.code === "Backspace" ||
      e.code === "ArrowUp" ||
      e.code === "ArrowDown" ||
      e.code === "ArrowLeft" ||
      e.code === "ArrowRight" ||
      e.code === "Tab"
    ) {
      return;
    }

    if (Number.isNaN(parseInt(e.key))) {
      e.preventDefault();
    }
  };

  // Catching paste operation.
  const numbersOnlyOnInputEventListener = (e: FormEvent<HTMLInputElement>) => {
    e.currentTarget.value = e.currentTarget.value.replace(/\D/gu, "");
  };

  return (
    <>
      <PasswordToggle
        showToggle={props.type === "password"}
        showPassword={showPassword}
        setShowPassword={setShowPassword}
      >
        <input
          {...props}
          ref={ref}
          id={props.id ?? id}
          aria-describedby={hintId}
          type={props.type === "password" && showPassword ? "text" : props.type}
          className={`${props.className ?? ""} ${props.type === "password" ? "password" : ""} ${borderCss.className}`}
          onChange={(e) => {
            handleMinMax(e);
            setLocalValue(e.target.value);
            if (props.onChange != null) {
              props.onChange(e);
            }
          }}
          onBlur={(e) => {
            if (props.onBlur != null) {
              props.onBlur(e);
            }
          }}
          onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
            if (allowOnlyNumbers) {
              numbersOnlyKeyDownEventListener(e);
            }

            if (props.onKeyDown != null) {
              props.onKeyDown(e);
            }
          }}
          onInput={(e: FormEvent<HTMLInputElement>) => {
            if (allowOnlyNumbers) {
              numbersOnlyOnInputEventListener(e);
            }

            if (props.onInput != null) {
              props.onInput(e);
            }
          }}
          disabled={disabled}
        />
      </PasswordToggle>
      <style jsx>{`
        @supports (-moz-appearance: none) {
          input[type="date"] {
            padding-right: 0.8em !important;
          }
        }

        input {
          padding: ${padding.shorthand};
          line-height: ${lineHeight} !important;
          appearance: none;
          float: none;
          outline: none;
          transition: ${animationDuration} box-shadow, border;
          width: 100%;
          font-size: ${props.variant === "lg" ? "16px" : "14px"} !important;
          color: ${value === "" ? colors.muted1 : "inherit"};
          position: relative;
          opacity: ${disabled ? "0.5" : "1"};
          cursor: ${disabled ? "not-allowed" : "pointer"};

          ${hasError && "padding-right: 40px"};

          background: ${hasError
            ? `url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0nMTQnIGhlaWdodD0nMTQnIHZpZXdCb3g9JzAgMCAxNCAxNCcgZmlsbD0nbm9uZScgeG1sbnM9J2h0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnJz48cGF0aCBkPSdNNy4wMDA2NSAxMy42NjY3QzMuMzE4NjUgMTMuNjY2NyAwLjMzMzk4NCAxMC42ODIgMC4zMzM5ODQgNy4wMDAwNEMwLjMzMzk4NCAzLjMxODA0IDMuMzE4NjUgMC4zMzMzNzQgNy4wMDA2NSAwLjMzMzM3NEMxMC42ODI3IDAuMzMzMzc0IDEzLjY2NzMgMy4zMTgwNCAxMy42NjczIDcuMDAwMDRDMTMuNjY3MyAxMC42ODIgMTAuNjgyNyAxMy42NjY3IDcuMDAwNjUgMTMuNjY2N1pNNy4wMDA2NSAxMi4zMzM0QzguNDE1MTQgMTIuMzMzNCA5Ljc3MTY5IDExLjc3MTUgMTAuNzcxOSAxMC43NzEzQzExLjc3MjEgOS43NzEwOCAxMi4zMzQgOC40MTQ1MyAxMi4zMzQgNy4wMDAwNEMxMi4zMzQgNS41ODU1NSAxMS43NzIxIDQuMjI5IDEwLjc3MTkgMy4yMjg4QzkuNzcxNjkgMi4yMjg2MSA4LjQxNTE0IDEuNjY2NzEgNy4wMDA2NSAxLjY2NjcxQzUuNTg2MTYgMS42NjY3MSA0LjIyOTYxIDIuMjI4NjEgMy4yMjk0MSAzLjIyODhDMi4yMjkyMiA0LjIyOSAxLjY2NzMyIDUuNTg1NTUgMS42NjczMiA3LjAwMDA0QzEuNjY3MzIgOC40MTQ1MyAyLjIyOTIyIDkuNzcxMDggMy4yMjk0MSAxMC43NzEzQzQuMjI5NjEgMTEuNzcxNSA1LjU4NjE2IDEyLjMzMzQgNy4wMDA2NSAxMi4zMzM0Wk02LjMzMzk4IDkuMDAwMDRINy42NjczMlYxMC4zMzM0SDYuMzMzOThWOS4wMDAwNFpNNi4zMzM5OCAzLjY2NjcxTDcuNjY3MzIgMy42NjY3MVY3LjY2NjcxSDYuMzMzOThWMy42NjY3MVonIGZpbGw9JyNFNjAwMEUnLz48L3N2Zz4=")
              no-repeat calc(100% - 14px) center`
            : disabled
            ? colors.grayscale[3]
            : "white"};
        }
        :focus {
          cursor: auto;
        }
        :first-child {
          padding-top: ${padding.top};
        }
        :last-child {
          padding-bottom: ${padding.bottom};
        }
        ::-webkit-calendar-picker-indicator {
          cursor: pointer;
          position: absolute;
          ${leftIndicator ? "left" : "right"}: 0;
          height: 100%;
          width: ${indicatorWidth};
          background: none;
          margin: 0;
        }
        ::after {
          content: " ";
          position: absolute;
          top: 0;
          ${leftIndicator ? "left" : "right"}: 0;
          width: ${indicatorWidth};
          height: 100%;
          pointer-events: none;
          background-repeat: no-repeat;
          background-position: center;
          opacity: ${value === "" ? "0.4" : "1"};
        }

        input[type="time"],
        input[type="date"],
        input[type="month"],
        input[type="datetime"],
        input[type="datetime-local"],
        input.password {
          background: ${hasError
            ? `url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0nMTQnIGhlaWdodD0nMTQnIHZpZXdCb3g9JzAgMCAxNCAxNCcgZmlsbD0nbm9uZScgeG1sbnM9J2h0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnJz48cGF0aCBkPSdNNy4wMDA2NSAxMy42NjY3QzMuMzE4NjUgMTMuNjY2NyAwLjMzMzk4NCAxMC42ODIgMC4zMzM5ODQgNy4wMDAwNEMwLjMzMzk4NCAzLjMxODA0IDMuMzE4NjUgMC4zMzMzNzQgNy4wMDA2NSAwLjMzMzM3NEMxMC42ODI3IDAuMzMzMzc0IDEzLjY2NzMgMy4zMTgwNCAxMy42NjczIDcuMDAwMDRDMTMuNjY3MyAxMC42ODIgMTAuNjgyNyAxMy42NjY3IDcuMDAwNjUgMTMuNjY2N1pNNy4wMDA2NSAxMi4zMzM0QzguNDE1MTQgMTIuMzMzNCA5Ljc3MTY5IDExLjc3MTUgMTAuNzcxOSAxMC43NzEzQzExLjc3MjEgOS43NzEwOCAxMi4zMzQgOC40MTQ1MyAxMi4zMzQgNy4wMDAwNEMxMi4zMzQgNS41ODU1NSAxMS43NzIxIDQuMjI5IDEwLjc3MTkgMy4yMjg4QzkuNzcxNjkgMi4yMjg2MSA4LjQxNTE0IDEuNjY2NzEgNy4wMDA2NSAxLjY2NjcxQzUuNTg2MTYgMS42NjY3MSA0LjIyOTYxIDIuMjI4NjEgMy4yMjk0MSAzLjIyODhDMi4yMjkyMiA0LjIyOSAxLjY2NzMyIDUuNTg1NTUgMS42NjczMiA3LjAwMDA0QzEuNjY3MzIgOC40MTQ1MyAyLjIyOTIyIDkuNzcxMDggMy4yMjk0MSAxMC43NzEzQzQuMjI5NjEgMTEuNzcxNSA1LjU4NjE2IDEyLjMzMzQgNy4wMDA2NSAxMi4zMzM0Wk02LjMzMzk4IDkuMDAwMDRINy42NjczMlYxMC4zMzM0SDYuMzMzOThWOS4wMDAwNFpNNi4zMzM5OCAzLjY2NjcxTDcuNjY3MzIgMy42NjY3MVY3LjY2NjcxSDYuMzMzOThWMy42NjY3MVonIGZpbGw9JyNFNjAwMEUnLz48L3N2Zz4K")
              no-repeat calc(100% - 38px) center`
            : "transparent"} !important;
          ${leftIndicator ? "padding-left" : "padding-right"}: ${indicatorWidth} !important;
        }
        input[type="month"]::after,
        input[type="date"]::after,
        input[type="datetime"]::after,
        input[type="datetime-local"]::after {
          background-image: url("data:image/svg+xml;base64,PHN2ZyBzdHJva2U9J2N1cnJlbnRDb2xvcicgZmlsbD0nY3VycmVudENvbG9yJyBzdHJva2Utd2lkdGg9JzAnIHZpZXdCb3g9JzAgMCAyNCAyNCcgaGVpZ2h0PScxZW0nIHdpZHRoPScxZW0nIHhtbG5zPSdodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2Zyc+PGc+PHBhdGggZmlsbD0nbm9uZScgZD0nTTAgMGgyNHYyNEgweic+PC9wYXRoPjxwYXRoIGQ9J00xNyAzaDRhMSAxIDAgMCAxIDEgMXYxNmExIDEgMCAwIDEtMSAxSDNhMSAxIDAgMCAxLTEtMVY0YTEgMSAwIDAgMSAxLTFoNFYxaDJ2Mmg2VjFoMnYyem0zIDZWNWgtM3YyaC0yVjVIOXYySDdWNUg0djRoMTZ6bTAgMkg0djhoMTZ2LTh6TTYgMTNoNXY0SDZ2LTR6Jz48L3BhdGg+PC9nPjwvc3ZnPgo=");
        }

        input[type="time"]::after {
          background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0nMTYnIGhlaWdodD0nMTYnIHZpZXdCb3g9JzAgMCAxNiAxNicgZmlsbD0nZ3JheScgeG1sbnM9J2h0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnJz48cGF0aCBkPSdNOC4wMTUxIDE0LjY4MDFDNC4zMzQwMiAxNC42ODAxIDEuMzUwMSAxMS42OTYyIDEuMzUwMSA4LjAxNTFDMS4zNTAxIDQuMzM0MDIgNC4zMzQwMiAxLjM1MDEgOC4wMTUxIDEuMzUwMUMxMS42OTYyIDEuMzUwMSAxNC42ODAxIDQuMzM0MDIgMTQuNjgwMSA4LjAxNTFDMTQuNjgwMSAxMS42OTYyIDExLjY5NjIgMTQuNjgwMSA4LjAxNTEgMTQuNjgwMVpNOC4wMTUxIDEzLjM0NzFDOS40MjkyMyAxMy4zNDcxIDEwLjc4NTQgMTIuNzg1MyAxMS43ODU0IDExLjc4NTRDMTIuNzg1MyAxMC43ODU0IDEzLjM0NzEgOS40MjkyMyAxMy4zNDcxIDguMDE1MUMxMy4zNDcxIDYuNjAwOTYgMTIuNzg1MyA1LjI0NDc1IDExLjc4NTQgNC4yNDQ4QzEwLjc4NTQgMy4yNDQ4NiA5LjQyOTIzIDIuNjgzMSA4LjAxNTEgMi42ODMxQzYuNjAwOTYgMi42ODMxIDUuMjQ0NzUgMy4yNDQ4NiA0LjI0NDggNC4yNDQ4QzMuMjQ0ODYgNS4yNDQ3NSAyLjY4MzEgNi42MDA5NiAyLjY4MzEgOC4wMTUxQzIuNjgzMSA5LjQyOTIzIDMuMjQ0ODYgMTAuNzg1NCA0LjI0NDggMTEuNzg1NEM1LjI0NDc1IDEyLjc4NTMgNi42MDA5NiAxMy4zNDcxIDguMDE1MSAxMy4zNDcxWk04LjY4MTYgOC4wMTUxSDExLjM0NzZWOS4zNDgxSDcuMzQ4NlY0LjY4MjZIOC42ODE2VjguMDE1MVonIGZpbGw9J2JsYWNrJy8+PC9zdmc+Cg==");
        }
        input[type="number"]::-webkit-inner-spin-button {
          -webkit-appearance: ${props.dropdownIcon != null ? "none" : "default"};
          margin: 0;
        }
        input[type="number"] {
          -moz-appearance: ${props.dropdownIcon != null ? "textfield" : "default"};
        }
        input[type="number"] {
          background-repeat: no-repeat;
          background-position: calc(100% - 12px) center;
          background-image: ${props.dropdownIcon != null
            ? props.dropdownIcon({
                color: value === "" ? colors.muted1 : "#333",
              })
            : ""};
        }
        ::placeholder {
          color: ${colors.muted1};
        }
        ::-webkit-datetime-edit {
          color: ${value === "" ? colors.muted1 : "inherit"};
          line-height: ${lineHeight} !important;
          height: ${lineHeight} !important;
        }
        ::-webkit-date-and-time-value {
          line-height: ${lineHeight} !important;
          height: ${lineHeight} !important;
          text-align: left !important;
          padding: 0 !important;
        }
        ::-webkit-datetime-edit,
        ::-webkit-calendar-picker-indicator {
          padding: 0 !important;
        }
        ::-webkit-datetime-edit-fields-wrapper,
        ::-webkit-datetime-edit-day-field,
        ::-webkit-datetime-edit-month-field,
        ::-webkit-datetime-edit-year-field,
        ::-webkit-datetime-edit-hour-field,
        ::-webkit-datetime-edit-minute-field,
        ::-webkit-datetime-edit-meridiem-field {
          padding: 0 1px !important;
          color: ${value === "" ? colors.muted1 : "inherit"};
        }
      `}</style>
      {borderCss.styles}
    </>
  );
});

FormInput.displayName = "FormInput";
