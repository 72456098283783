import { useContext, useMemo } from "react";

import { notNullOrUndefined, Site, useSaferFormikContext } from "@equiem/lib";

import { useCompaniesQuery } from "../../generated/visitors-client";
import type { ReceptionFormValues } from "../ReceptionFormValues";

export const useReceptionFormCompanies = () => {
  const { values } = useSaferFormikContext<ReceptionFormValues>();
  const { uuid } = useContext(Site);
  const { data: companiesData, loading } = useCompaniesQuery({ variables: { siteUuid: uuid } });

  // A list of all the companies at the current site.
  const companies = useMemo(
    () =>
      companiesData?.viewer.currentDestination?.destination.companiesV2?.edges
        .map((e) => e.node)
        .filter(notNullOrUndefined) ?? [],
    [companiesData?.viewer.currentDestination],
  );

  // The company selected in the current form state.
  const selectedCompany = useMemo(
    () => companies.find((company) => company.uuid === values.companyUuid),
    [values.companyUuid, companies],
  );

  return { companies, selectedCompany, loading };
};
