import type { FC } from "react";
import React, { useEffect } from "react";
import type { FieldProps } from "formik";
import _ from "lodash";

import { useSaferFormikContext } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import { Button } from "@equiem/react-admin-ui";
import type { IImage } from "@equiem/uploader";
import { CropperUi, Dropzone, useImageUploaderState } from "@equiem/uploader";

import type { CreateBuildingUploadUrlMutation } from "../../../generated/visitors-client";
import { AdminImageType, useCreateBuildingUploadUrlMutation } from "../../../generated/visitors-client";

import type { BuildingSettingsValues } from "./BuildingSettingsValues";

const getContentType = (type?: string): AdminImageType | null => {
  switch (type?.toLowerCase()) {
    case "image/png":
      return AdminImageType.Png;
    case "image/gif":
      return AdminImageType.Gif;
    case "image/jpeg":
      return AdminImageType.Jpeg;
    default:
      return null;
  }
};

type Upload = CreateBuildingUploadUrlMutation["createBuldingUploadUrl"];

export const ImageUploader: FC<FieldProps<IImage | null>["field"]> = (props) => {
  const { t } = useTranslation();
  const [createPresignedUrl, { error }] = useCreateBuildingUploadUrlMutation();
  const { setTouched, setFieldError } = useSaferFormikContext<BuildingSettingsValues>();

  const {
    localAttachedImage,
    attachedImages,
    dragover,
    uploading,
    setDragover,
    onFileChange,
    progress,
    onCrop,
    onSaveCropped,
    cropperRef,
    clearCropper,
    removeImages,
    setAttachedImages,
  } = useImageUploaderState(
    props.value != null ? [props.value] : [],
    (imgs) => {
      props.onChange({
        target: {
          name: props.name,
          value: imgs[0],
        },
      });
    },
    async (input) =>
      createPresignedUrl({
        variables: {
          input: {
            filename: input.filename,
            contentType: getContentType(input.contentType),
          },
        },
      }).then((r) => r.data?.createBuldingUploadUrl as Upload),
  );

  const onFileChangeWithValidation = async (files: FileList) => {
    setTouched(
      {
        emailFooterImage: false,
        emailHeaderImage: false,
      },
      false,
    ).catch(console.error);
    if (getContentType(files[0].type) == null || files[0].size / (1024 * 1024) > 4) {
      setTouched(
        {
          emailFooterImage: props.name === "emailFooterImage",
          emailHeaderImage: props.name === "emailHeaderImage",
        },
        false,
      ).catch(console.error);
      return setFieldError(props.name, t("visitors.settings.buildingImageUploadError"));
    } else {
      return onFileChange(files);
    }
  };

  useEffect(() => {
    setAttachedImages(props.value != null && props.value.key.length > 0 ? [props.value] : []);
  }, [props.value, setAttachedImages]);

  return (
    <>
      {localAttachedImage == null && attachedImages.length === 0 && (
        <Dropzone
          dragover={dragover}
          uploading={uploading}
          setDragover={setDragover}
          onFileChange={onFileChangeWithValidation}
          progress={progress}
        />
      )}
      {localAttachedImage != null && attachedImages.length === 0 && (
        <CropperUi
          localAttachedImage={localAttachedImage}
          onCrop={onCrop}
          cropperRef={cropperRef}
          onSaveCropped={onSaveCropped}
          progress={progress}
          uploading={uploading}
          clearCropper={clearCropper}
          disableAspectRatio={true}
          autoCropArea={1}
          error={error}
          id={_.random(1, 100000, false).toString()}
        />
      )}
      {localAttachedImage == null && attachedImages.length !== 0 && (
        <div className="text-center">
          <img src={attachedImages[0].url ?? ""} alt="" style={{ width: "100%" }} />
          <Button type="button" variant="outline" onClick={removeImages}>
            {t("bookings.operations.removeImage")}
          </Button>
        </div>
      )}
    </>
  );
};
