import React from "react";

import type { BorderCssProps } from "../useInputBorderCss";
import type { ComponentProps, PropsWithChildren } from "react";
import { useDurationIconBackground } from "./useDurationIconBackground";
import { FormSelect } from "../FormSelect/FormSelect";

// eslint-disable-next-line @typescript-eslint/no-type-alias
type Props = ComponentProps<"select"> & {
  rounded?: BorderCssProps["rounded"];
  placeholder?: string;
};

export const FormDurationSelect: React.FC<PropsWithChildren<Props>> = ({ children, ...props }) => {
  return (
    <FormSelect dropdownIcon={useDurationIconBackground} {...props}>
      {children}
    </FormSelect>
  );
};
