import React, { useCallback, useContext, useMemo, useState } from "react";

import { CurrentProfile, useShowError } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import { Button, ProgressCircle, Tag, useTheme } from "@equiem/react-admin-ui";
import { RiEyeLine, RiEyeOffLine } from "@equiem/react-admin-ui/icons";

import { type RequestQuery, useToggleWatchMutation } from "../../../generated/requests-client";

interface P {
  request: RequestQuery["reqMgt"]["request"];
}
export const RequestWatchUnwatch: React.FC<P> = ({ request }) => {
  const theme = useTheme();
  const profile = useContext(CurrentProfile);
  const existingWatchers = useMemo(
    () => request.watchers.flatMap((w) => (w == null ? [] : [w.uuid])),
    [request.watchers],
  );
  const { t } = useTranslation();
  const [processing, setProcessing] = useState(false);
  const watching = useMemo(() => {
    return existingWatchers.includes(profile.profile?.uuid ?? "");
  }, [existingWatchers, profile.profile?.uuid]);
  const showError = useShowError();
  const [mutation, { loading }] = useToggleWatchMutation({
    variables: { requestUuid: request.uuid },
    refetchQueries: ["Request", "AllRequests"],
  });

  const toggle = useCallback(() => {
    setProcessing(true);
    mutation()
      .catch(showError)
      .finally(() => {
        setProcessing(false);
      });
  }, [mutation, showError]);

  return (
    <>
      <div className="watch-toggle-cont">
        <Tag size="small" className="total-watchers">
          {request.watchers.length}
        </Tag>
        <Button variant="outline" size="sm" disabled={loading} onClick={toggle} className="toggle-watch">
          {watching ? (
            <>
              {processing ? <ProgressCircle size="xs" /> : <RiEyeOffLine />} {t("requests.details.unwatch")}
            </>
          ) : (
            <>
              {processing ? <ProgressCircle size="xs" /> : <RiEyeLine />} {t("requests.details.watch")}
            </>
          )}
        </Button>
      </div>
      <style jsx>{`
        .watch-toggle-cont {
          display: flex;
          gap: ${theme.spacers.s3};
          align-items: center;
          padding: ${theme.spacers.s3} 0;
        }
      `}</style>
    </>
  );
};
