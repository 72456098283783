import React from "react";

import type { useFloating } from "@floating-ui/react-dom";

// eslint-disable-next-line @typescript-eslint/no-type-alias
export type FormRichSelectContext = ReturnType<typeof useFloating> & {
  open: boolean;
  setOpen: (open: boolean) => void;
  disabled: boolean;
};

export const FormRichSelectContext = React.createContext<FormRichSelectContext>({
  floating: () => undefined,
  middlewareData: {},
  placement: "bottom-start",
  reference: () => undefined,
  refs: { floating: { current: null }, reference: { current: null } },
  setOpen: () => undefined,
  open: false,
  strategy: "absolute",
  update: () => undefined,
  x: null,
  y: null,
  disabled: false,
});
