import { useContext } from "react";
import { CurrentProfile, Session } from "./context";
import { useSiteContext } from "./context/SiteContext";

export const useViewerTokens = () => {
  const { uuid, name, webAppUrl } = useSiteContext();
  const session = useContext(Session);
  const { profile } = useContext(CurrentProfile);

  const fallback = profile == null ? "" : "Unknown";

  const tokens: Record<string, string> = {
    "equiem:site_uuid": uuid,
    "equiem:source": "equiem-one",
    "equiem:id_token": session.idToken ?? "",
    "equiem:first_name": profile?.firstName ?? fallback,
    "equiem:last_name": profile?.lastName ?? fallback,
    "equiem:email": profile?.email ?? fallback,
    "equiem:company": profile?.companyV2?.name ?? fallback,
    "equiem:sitename": name,
    "equiem:building_name": name,
    "equiem:siteurl": webAppUrl,
  };

  return (text: string) => {
    return Object.keys(tokens).reduce((a, token) => {
      return a.replace(new RegExp(`\\[${token}\\]`, "gu"), tokens[token as keyof typeof tokens]);
    }, text);
  };
};
