import React, { useCallback, useContext, useEffect, useState } from "react";

import { useSaferFormikContext } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import { Modal } from "@equiem/react-admin-ui";

import { Modal as ModalContext } from "../../../contexts/ModalContext";
import { RecurringSettingsModalProvider } from "../components/recurring-settings/RecurringSettingsModalContext";
import { RecurringSettingsModalView } from "../components/recurring-settings/RecurringSettingsModalView";
import type { FormValues } from "../types";

export const AppointmentRecurringSettingsWidget: React.FC = () => {
  const [showModal, setShowModal] = useState(false);
  const modal = useContext(ModalContext);
  const { t } = useTranslation();
  const { setFieldValue: setFormFieldValue } = useSaferFormikContext<FormValues>();

  const onClose = useCallback(() => {
    modal.close();
    setShowModal(false);
  }, [setFormFieldValue, modal]);

  useEffect(() => {
    if (modal.activeModal === "AppointmentRecurringSettings") {
      setShowModal(true);
    }
  }, [modal.activeModal, setShowModal]);

  const confirmCancel = useCallback(
    (onConfirm?: () => void) => {
      onConfirm?.();
      onClose();
    },
    [onClose],
  );

  return (
    <>
      <Modal.Dialog
        title={t("visitors.appointmentForm.appointmentRepeat")}
        show={showModal}
        scrollable={true}
        centered={true}
        onHide={() => confirmCancel()}
        hideOnEsc={true}
        hideOnClick={false}
        focusTrapOptions={{ allowOutsideClick: () => true }}
        size="md"
      >
        <Modal.Header
          closeButton={true}
          noBorder={true}
          noTitle={false}
          intro={t("visitors.appointmentForm.appointmentCreateRecurringItemTitle")}
        ></Modal.Header>
        <RecurringSettingsModalProvider confirmCancel={confirmCancel} onClose={onClose}>
          <RecurringSettingsModalView />
        </RecurringSettingsModalProvider>
      </Modal.Dialog>
    </>
  );
};
