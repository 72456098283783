import type { Tree } from "./Tree";

export const findLeaves = (tree: Tree[], keep?: (leaf: Tree) => boolean): Tree[] => {
  return tree.flatMap((subTree) => {
    if (subTree.children == null || subTree.children.length === 0) {
      return keep == null || keep(subTree) ? [subTree] : [];
    } else {
      return findLeaves(subTree.children, keep);
    }
  });
};
