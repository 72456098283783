import type { ReactNode } from "react";
import React from "react";
import { useTermsAcceptedForDestinationQuery } from "../generated/gateway-client";
import { Terms } from "./TermsContext";

export const TermsProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const { data } = useTermsAcceptedForDestinationQuery();

  return (
    <Terms.Provider
      value={{
        termsAccepted: data?.viewer.currentDestination?.termsAccepted,
      }}
    >
      {children}
    </Terms.Provider>
  );
};
