import { CurrentRole, Role } from "@equiem/lib";
import { useContext } from "react";
import { CreditTransactionsQueryRole } from "../../../generated/gateway-client";

export const useRoleMapping = (): CreditTransactionsQueryRole => {
  const { currentRole } = useContext(CurrentRole);
  const roleMapping: Record<Role, CreditTransactionsQueryRole> = {
    [Role.WorkplaceManager]: CreditTransactionsQueryRole.WorkplaceManager,
    [Role.FlexManager]: CreditTransactionsQueryRole.FlexManager,
    [Role.PropertyManager]: CreditTransactionsQueryRole.WorkplaceManager,
    [Role.Unknown]: CreditTransactionsQueryRole.FlexManager,
  };

  return roleMapping[currentRole];
};
