import type { AddressInfo } from "../types";

interface GoogleTimezoneResponse {
  timeZoneId?: string;
}

export const googleMapsApiKey = process.env.googleMapsApiKey;

const findAddressComponent =
  (desiredTypes: string[]) =>
  (components: google.maps.GeocoderAddressComponent[]): string | undefined => {
    const match = components.find((component) =>
      desiredTypes.some((desiredType) => component.types.includes(desiredType)),
    );
    return match?.long_name;
  };

const getCity = findAddressComponent(["locality", "postal_town"]);
const getPostcode = findAddressComponent(["postal_code"]);
const getCountry = findAddressComponent(["country"]);
const getState = findAddressComponent(["administrative_area_level_1"]);
const getStreetName = findAddressComponent(["route"]);
const getTimezone = async ({
  latitude,
  longitude,
}: Pick<AddressInfo, "latitude" | "longitude">): Promise<string | null> => {
  try {
    const coordinates = `${latitude},${longitude}`;
    const res = await fetch(
      `https://maps.googleapis.com/maps/api/timezone/json?location=${coordinates}&timestamp=0&key=${googleMapsApiKey}`,
    );
    const body = (await res.json()) as GoogleTimezoneResponse;
    return body.timeZoneId ?? null;
  } catch (e: unknown) {
    console.error(e);
    return null;
  }
};

export const defaultAddressInfo: AddressInfo = {
  address: "",
  city: "",
  country: "",
  latitude: 0,
  longitude: 0,
  postcode: "",
  state: "",
  streetName: "",
  timezone: "",
};

export async function formatPlaceResult(
  place: google.maps.places.PlaceResult,
  defaults: AddressInfo,
): Promise<AddressInfo> {
  const addressArray = place.address_components;
  if (addressArray == null) {
    throw new Error("Failed to parse address");
  }

  const latitude = place.geometry?.location?.lat() ?? defaults.latitude;
  const longitude = place.geometry?.location?.lng() ?? defaults.longitude;

  return {
    address: place.formatted_address ?? defaults.address,
    streetName: getStreetName(addressArray) ?? defaults.streetName,
    city: getCity(addressArray) ?? defaults.city,
    postcode: getPostcode(addressArray) ?? defaults.postcode,
    state: getState(addressArray) ?? defaults.state,
    country: getCountry(addressArray) ?? defaults.country,
    latitude,
    longitude,
    timezone: (await getTimezone({ latitude, longitude })) ?? defaults.timezone,
  };
}
