import type { ComponentProps, PropsWithChildren } from "react";
import React from "react";
import { useTheme } from "../../../contexts/Theme";

interface Props extends ComponentProps<"div"> {
  background?: string;
}

export const CardHeader: React.FC<PropsWithChildren<Props>> = ({ children, background, ...props }) => {
  const { colors, spacers } = useTheme();

  return (
    <>
      <div {...props}>{children}</div>
      <style jsx>{`
        div {
          border-bottom: solid 1px ${colors.border};
          padding: ${spacers.s2} ${spacers.s3};
          background: ${background ?? "none"};
        }
      `}</style>
    </>
  );
};
