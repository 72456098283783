import { TermsModal, useTheme } from "@equiem/react-admin-ui";
import type { FC } from "react";
import React, { useState } from "react";
import { SvgPoweredByEquiem } from "./SvgPoweredByEquiem";
import { useSiteContext, stringNotEmpty, withDefaultPrevented, useViewerTokens } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import { CookiePreferences } from "./sidemenu/CookiePreferences";

export const Footer: FC = () => {
  const { t } = useTranslation();
  const { colors, breakpoints, spacers } = useTheme();
  const [showTermsAndConditions, setShowTermsAndConditions] = useState(false);
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
  const { privacyPolicy, termsAndConditions, address, name } = useSiteContext();
  const tokenReplacer = useViewerTokens();

  return (
    <>
      <div className="container pb-4 pt-6 px-7 footer">
        {/* Empty component that leaves space for the Usetiful
            product tour button */}
        <div className="empty"></div>
        <div className="row">
          <div className="col links">
            {stringNotEmpty(termsAndConditions) && (
              <a href="#" onClick={withDefaultPrevented(() => setShowTermsAndConditions(true))}>
                {t("common.termsAndConditions")}
              </a>
            )}
            {stringNotEmpty(privacyPolicy) && (
              <a href="#" onClick={withDefaultPrevented(() => setShowPrivacyPolicy(true))}>
                {t("common.privacyPolicy")}
              </a>
            )}
            <CookiePreferences />
          </div>
          <div className="col powered-by-eq-logo">
            <SvgPoweredByEquiem />
          </div>
          <div className="col site-address">
            <div>{address}</div>
          </div>
        </div>
      </div>
      <TermsModal
        type="termsAndConditions"
        terms={tokenReplacer(termsAndConditions ?? "")}
        show={showTermsAndConditions}
        siteName={name}
        onHide={() => setShowTermsAndConditions(false)}
      />
      <TermsModal
        type="privacyPolicy"
        terms={tokenReplacer(privacyPolicy ?? "")}
        show={showPrivacyPolicy}
        siteName={name}
        onHide={() => setShowPrivacyPolicy(false)}
      />
      <style jsx>{`
        .container {
          font-size: 12px;
          background: ${colors.white};
          border-top: 1px solid ${colors.grayscale[10]};
          height: 100px;
        }
        .empty {
          height: 60%;
        }
        .row {
          height: 40%;
          display: flex;
          justify-content: space-between;
          align-items: end;
        }
        .col {
          display: flex;
          align-items: flex-start;
          gap: ${spacers.s4};
          color: ${colors.grayscale[60]};
          font-weight: 400;
        }
        .links :global(a) {
          text-decoration: none;
          color: ${colors.grayscale[60]};
        }

        @media (max-width: ${breakpoints.md}px) {
          .container {
            height: initial;
          }
          .empty {
            display: none;
          }
          .row {
            flex-direction: column;
            max-width: 100vw;
          }
          .site-address {
            order: 1;
          }
          .links {
            padding-top: ${spacers.s3};
            order: 2;
          }
          .powered-by-eq-logo {
            margin-top: ${spacers.s6};
            order: 3;
          }
          .row {
            align-items: flex-start;
          }
        }
      `}</style>
    </>
  );
};
