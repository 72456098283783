import React, { useCallback, useContext, useState } from "react";
import { Form, Tag, Toggler, useTheme } from "@equiem/react-admin-ui";
import { useTranslation } from "@equiem/localisation-eq1";

import type { FilterType } from "../contexts/SegmentProvider";
import { CurrentSegment } from "../contexts/SegmentProvider";
import { translateFilterType } from "../lib/mapGraphqlFilterType";
import { FilterSuffix } from "./FilterSuffix";
import { CurrentAudience } from "../contexts/AudienceProvider";
import { useSummaryForUserTypeFilter } from "../hooks/useSummaryForUserTypeFilter";

interface Props {
  filter: FilterType;
  segmentId: string;
}

export const UserTypeFilter: React.FC<Props> = ({ segmentId, filter }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { site } = useContext(CurrentAudience);
  const {
    commercialSelected,
    setCommercialSelected,
    residentialSelected,
    setResidentialSelected,
    visitorsSelected,
    setVisitorsSelected,
  } = useContext(CurrentSegment);
  const [filterSummary, count] = useSummaryForUserTypeFilter(commercialSelected, visitorsSelected, residentialSelected);
  const [allSelected, setAllSelected] = useState(commercialSelected && residentialSelected && visitorsSelected);

  const registration = site.destination.settings.registration;

  const selectAllFn = useCallback(
    (checked: boolean) => {
      setCommercialSelected(checked);
      setResidentialSelected(checked);
      setVisitorsSelected(checked);
      setAllSelected(checked);
    },
    [setCommercialSelected, setResidentialSelected, setVisitorsSelected],
  );

  const summary = useCallback(() => {
    const sum = filterSummary() ?? t("common.emptyNone");

    return <div className="filter-summary">{sum}</div>;
  }, [filterSummary, t]);

  return (
    <>
      <Toggler
        heading={
          <span className="heading">
            {translateFilterType(filter, t)} <Tag type="counter" className="ms-2" variant="dark" value={count()} />
          </span>
        }
        subheading={summary()}
        headingSuffix={<FilterSuffix filter={filter} />}
        className="filter-toggler mb-2"
      >
        <div className="select-all">
          <Form.Checkbox
            name={"all-userType"}
            id={`${segmentId}-all-userType`}
            label={t("common.selectAll")}
            value={allSelected}
            onChange={(e) => selectAllFn(e.target.checked)}
          />
        </div>
        <Form.Group className="selections mt-3">
          {registration.commercialSignupEnabled && (
            <Form.Checkbox
              label={t("segmentation.userType.commercial")}
              key="commercial"
              name="commercial"
              id={`${segmentId}-commercial`}
              value={commercialSelected}
              onChange={(e) => setCommercialSelected(e.target.checked)}
              className="mb-2"
            />
          )}
          {registration.residentialSignupEnabled && (
            <Form.Checkbox
              label={t("segmentation.userType.residential")}
              key="residential"
              name="residential"
              id={`${segmentId}-residential`}
              value={residentialSelected}
              onChange={(e) => setResidentialSelected(e.target.checked)}
              className="mb-2"
            />
          )}
          {registration.visitorSignupEnabled && (
            <Form.Checkbox
              label={t("segmentation.userType.visitors")}
              key="visitors"
              name="visitors"
              id={`${segmentId}-visitors`}
              value={visitorsSelected}
              onChange={(e) => setVisitorsSelected(e.target.checked)}
              className="mb-2"
            />
          )}
        </Form.Group>
      </Toggler>
      <style jsx>{`
        .heading {
          align-self: flex-start;
          display: flex;
          align-items: center;
        }
        .select-all {
          border-bottom: 1px solid ${theme.colors.border};
          padding: ${theme.spacers.s4} 0;
          margin-bottom: ${theme.spacers.s3};
        }
        .content :global(.selections) {
          overflow-y: scroll;
          max-height: 240px;
          padding: ${theme.spacers.s3} ${theme.spacers.s3} 0;
        }
      `}</style>
    </>
  );
};
