import React from "react";

interface Props {
  height: number;
  width?: number;
  disableAnimation?: boolean;
}

export const WidgetLoadingContent: React.FC<Props> = ({ height, width, disableAnimation }) => {
  return (
    <>
      <div className="skeleton"></div>
      <style jsx>{`
        .skeleton {
          height: ${height}px;
          width: ${width != null ? `${width}px` : "100%"};
          border-radius: 2px;
          flex: none;
          order: 0;
          flex-grow: 0;
          position: relative;
          background-color: #f5f5f5;
          overflow: hidden;
        }

        .skeleton:after {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          transform: translateX(-100%);
          background: linear-gradient(to right, #f5f5f5 0%, #efefef 15%, #f5f5f5 30%);
          animation: shimmer ${disableAnimation === true ? "0s" : "2s"} infinite;
          content: "";
        }

        @keyframes shimmer {
          100% {
            transform: translateX(100%);
          }
        }
      `}</style>
    </>
  );
};
