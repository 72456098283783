import React, { useContext } from "react";
import { useConfirmer } from "@equiem/react-admin-ui";
import { RiDeleteBinLine } from "@equiem/react-admin-ui/icons";
import { useTranslation } from "@equiem/localisation-eq1";

import type { FilterType } from "../contexts/SegmentProvider";
import { CurrentSegment } from "../contexts/SegmentProvider";

interface Props {
  filter: FilterType;
}

export const FilterSuffix: React.FC<Props> = ({ filter }) => {
  const { t } = useTranslation();
  const { toggleFilter } = useContext(CurrentSegment);
  const { withConfirmation } = useConfirmer();

  const onDelete = () => {
    toggleFilter(filter, false);
  };

  return (
    <div className="ml-auto text-muted d-flex align-items-center">
      <RiDeleteBinLine
        className="delete-filter cursor-pointer"
        onClick={withConfirmation({
          title: t("segmentation.deleteFilter"),
          message: t("segmentation.deleteFilterMessage"),
          confirmButtonText: t("segmentation.deleteFilterConfirmation"),
          confirmButtonVariant: "danger",
          onConfirm: onDelete,
        })}
      />
    </div>
  );
};
