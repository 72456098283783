import React, { useContext } from "react";
import { Site } from "@equiem/lib";
import { DateTime, Tag, useTheme } from "@equiem/react-admin-ui";
import { RiCalendarCheckLine, RiUserLine } from "@equiem/react-admin-ui/icons";
import Link from "next/link";
import type { BookableResource, Booking, Profile } from "../../../../generated/gateway-client";
import { useTranslation } from "@equiem/localisation-eq1";

export type BookingResult = Pick<Booking, "startDate" | "endDate" | "reference"> & {
  resource: Pick<BookableResource, "name">;
  user?: {
    profile?: Pick<Profile, "firstName" | "lastName"> | null;
  } | null;
};

interface Props {
  booking: BookingResult;
}

export const BookingRow: React.FC<Props> = ({ booking }) => {
  const { colors, borderRadius } = useTheme();
  const { timezone } = useContext(Site);
  const { i18n } = useTranslation();

  return (
    <>
      <div className={`pt-2 booking-${booking.reference}`}>
        <Link href={`/bookings/operations/${booking.reference}`} passHref legacyBehavior>
          <div className="p-4 mt-2 occurrence-view d-flex">
            <div className="d-flex flex-column column event-info">
              <div className="time mb-2">
                <DateTime.TimeRange
                  language={i18n.language}
                  start={booking.startDate}
                  end={booking.endDate}
                  timezone={timezone}
                />
              </div>
              <div className="mb-2">
                <div className="title">{booking.resource.name}</div>
              </div>
              <div className="d-flex flex-row">
                <div className="location d-flex flex-row align-items-center">
                  <Tag>
                    <RiCalendarCheckLine className="mr-1" /> {booking.reference}
                  </Tag>
                  <div className="ml-3 mr-2">&#183;</div>
                  <RiUserLine className="mr-1" /> {booking.user?.profile?.firstName} {booking.user?.profile?.lastName}
                </div>
              </div>
            </div>
          </div>
        </Link>
      </div>
      <style jsx>{`
        .occurrence-view {
          cursor: pointer;
          background-color: #f7f7f7;
          border-radius: ${borderRadius};
          width: 100%;
        }
        .time {
          font-weight: 500;
          font-size: 12px;
        }
        .title {
          font-weight: 600;
          text-decoration: none;
        }
        .occurrence-view:hover .title {
          text-decoration: underline;
        }
        .location {
          font-size: 12px;
        }
        .time,
        .location {
          color: ${colors.medium};
        }
        .event-option + .event-option {
          margin-left: 0px !important;
        }
      `}</style>
    </>
  );
};
