import { CurrentRole, Role } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import { useTheme } from "@equiem/react-admin-ui";
import React, { useContext } from "react";
import { PurchaseCreditsWidget } from "../PurchaseCreditsWidget/PurchaseCreditsWidget";

export const OverviewContainerTitle = () => {
  const { t } = useTranslation();
  const { colors } = useTheme();
  const { currentRole } = useContext(CurrentRole);

  const isFlexManager = currentRole === Role.FlexManager;

  return (
    <>
      <div className="header title">
        <h1>{isFlexManager ? t("credits.overview.creditsUsage") : t("credits.overview.balance")}</h1>
        <PurchaseCreditsWidget />
      </div>
      <style jsx>
        {`
          .header {
            display: flex;
            padding: 0 16px;
            align-items: center;
            justify-content: space-between;
          }

          :global(.error-toast) {
            display: flex;
            align-items: center;
            gap: 8px;
            width: 100%;
            justify-content: space-between;
          }

          .title {
            margin-top: 0.25rem;
          }

          .header h1 {
            margin: 0;
            font-weight: 700;
            font-size: 20px;
            line-height: 24px;
            color: ${colors.dark};
          }
        `}
      </style>
    </>
  );
};
