import React, { createRef, useContext, useEffect, useState } from "react";

import { Button } from "../../../Button/Button";
import type { ButtonProps } from "../../../Button/Button";
import { FormGroupContext } from "../../../../contexts/FormGroupContext";
import { FormRichSelectContext } from "../FormRichSelectContext";
import { useTheme } from "../../../../contexts/Theme";

export type FormRichSelectToggleProps = ButtonProps & {
  allowOverflow?: boolean;
};

export const FormRichSelectCurrent: React.FC<FormRichSelectToggleProps> = ({
  children,
  allowOverflow = false,
  ...props
}) => {
  const { colors, borderRadius, spacers, focusOutline } = useTheme();
  const ref = createRef<HTMLButtonElement>();
  const [focus, setFocus] = useState(false);
  const [hasBeenOpened, setHasBeenOpened] = useState(false);
  const { disabled, open, setOpen, reference } = useContext(FormRichSelectContext);
  const { error, hintId } = useContext(FormGroupContext);

  const showOutline = focus;

  useEffect(() => {
    if (open) {
      setHasBeenOpened(true);
    } else if (hasBeenOpened) {
      setHasBeenOpened(false);
      ref.current?.focus();
    }
  }, [hasBeenOpened, open, ref]);

  return (
    <div ref={reference} aria-describedby={hintId}>
      <span>{children}</span>
      <Button
        ref={ref}
        size="sm"
        variant="secondary"
        type="button"
        disabled={disabled}
        {...props}
        onFocus={() => {
          setFocus(true);
        }}
        onBlur={() => {
          setFocus(false);
        }}
        onClick={(e) => {
          if (props.onClick != null) {
            props.onClick(e);
          }
          setOpen(!open);
        }}
      >
        {open ? "Cancel" : "Edit"}
      </Button>
      <style jsx>{`
        div {
          display: flex;
          justify-content: space-between;
          align-items: center;
          border: 1px solid ${error != null ? colors.danger : showOutline ? colors.dark : colors.border};
          border-radius: ${borderRadius};
          padding: ${spacers.s3} ${spacers.s5};
          box-shadow: ${showOutline
            ? `0 0 0 ${focusOutline.size} ${
                error == null ? focusOutline.colors.default : focusOutline.colors.error
              } !important`
            : "none"};
        }
        span {
          flex-grow: 1;
          width: 100%;
          max-width: calc(100% - 4.5rem);
          overflow: ${allowOverflow ? "visible" : "hidden"};
          text-overflow: ${allowOverflow ? "initial" : "ellipsis"};
        }
      `}</style>
    </div>
  );
};

FormRichSelectCurrent.displayName = "FormRichSelectCurrent";
