import React from "react";
import { CreditsOverview } from "./CreditsOverview/CreditsOverview";
import { CreditsActivity } from "./CreditsActivity/CreditsActivity";
import { useTheme } from "@equiem/react-admin-ui";
import { ModalProvider } from "../../contexts/ModalContext";
import { CreditDashboardProvider } from "./context/CreditDashboardContext";

const CreditsPage = () => {
  const { colors, breakpoints } = useTheme();

  return (
    <>
      <CreditDashboardProvider>
        <div className="credits-page">
          <ModalProvider>
            <CreditsOverview />
            <CreditsActivity />
          </ModalProvider>
        </div>
      </CreditDashboardProvider>
      <style jsx>{`
        .credits-page {
          background: ${colors.white};
          padding: 32px;
          display: grid;
          gap: 16px;
        }
        @media screen and (max-width: ${breakpoints.sm}px) {
          .credits-page {
            padding: 16px;
          }
        }
      `}</style>
    </>
  );
};

export default CreditsPage;
