import React, { useCallback, useEffect, useState } from "react";
import { RiSearchLine } from "react-icons/ri";
import { useTranslation } from "@equiem/localisation-eq1";

import { useTheme } from "../../contexts/Theme";
import { useDebounced } from "../../hooks";
import { useAsRef } from "../../util/useAsRef";
import type { Form } from "../admin";
import { AdminButton as Button } from "../Button/AdminButton";
import * as MemberCard from "../MemberCard";
import type { Props as ComboBoxProps } from "../Form/FormComboBox/FormComboBox";
import { FormComboBox } from "../Form/FormComboBox/FormComboBox";
import { FormComboBoxMenu } from "../Form/FormComboBox/FormComboBoxMenu/FormComboBoxMenu";
import type { Props as FormInputExtendedProps } from "../Form/FormInputExtended/FormInputExtended";
import { FormInputExtended } from "../Form/FormInputExtended/FormInputExtended";

export interface User {
  uuid: string;
  email: string;
  firstName?: string | null;
  lastName?: string | null;
  company?: { uuid: string; name: string } | null;
  companyV2?: { uuid: string; name: string } | null;
  avatar?: string | null;
}

type Item = Form.ComboBox.Item & { user: User };

// eslint-disable-next-line @typescript-eslint/no-type-alias
type Props<T extends Item> = Omit<ComboBoxProps<T>, "placeholder" | "showMenuWhenEmpty" | "children"> &
  // eslint-disable-next-line @typescript-eslint/no-type-alias
  Pick<FormInputExtendedProps, "disabled" | "loading" | "placeholder" | "variant"> & {
    onAddUser?: () => void;
    onSearch: (value: string) => void;
    buttonText?: string | ((item: T) => string);
    debounce?: number;
    name?: string;
  };

export const UserSearch = <T extends Item>({
  disabled,
  items,
  loading,
  onSearch,
  onSelect,
  onAddUser,
  placeholder,
  buttonText: buttonTextInput,
  // eslint-disable-next-line @typescript-eslint/no-magic-numbers
  debounce = 500,
  name = "user-search",
  variant = "sm",
}: Props<T>) => {
  const { t } = useTranslation();
  const theme = useTheme(true);
  const [searchTerm, setSearchTerm] = useState("");
  const buttonText = useCallback(
    (item: T) => (typeof buttonTextInput === "function" ? buttonTextInput(item) : buttonTextInput),
    [buttonTextInput],
  );

  const debouncedSearchTerm = useDebounced(searchTerm, debounce);
  const onSearchRef = useAsRef(onSearch);

  useEffect(() => {
    onSearchRef.current(debouncedSearchTerm);
  }, [onSearchRef, debouncedSearchTerm]);

  return (
    <FormComboBox
      onChange={(e) => {
        setSearchTerm(e.target.value);
      }}
      onSelect={onSelect}
      items={items}
      menuWidth="matchReference"
    >
      {({ setSelected, setHighlighted, highlighted, itemRefs, inputProps, floating }) => (
        <div style={{ position: "relative" }}>
          <FormInputExtended
            placeholder={placeholder ?? (t("common.searchToAdd") as string)}
            disabled={disabled}
            loading={loading}
            variant={variant}
            icon={RiSearchLine}
            clearable
            outerRef={floating?.reference}
            name={name}
            className="search-input"
            {...inputProps}
          />
          <FormComboBoxMenu
            showWhenEmpty={loading !== true && debouncedSearchTerm !== ""}
            maxHeight="10rem"
            key={`items-${items.length}`}
          >
            {items.length === 0 && searchTerm !== "" && loading !== true ? (
              <div className="placeholder-container align-items-center flex-column p-3">
                <span className="placeholder-message">
                  {t("settings.emptyUsersTableSearch", { search: searchTerm })}
                </span>
                {onAddUser != null && (
                  <Button
                    onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                      e.preventDefault();
                      e.stopPropagation();
                      onAddUser();
                    }}
                    variant="secondary"
                    style={{ width: "100%" }}
                    className="mt-3"
                    size="sm"
                  >
                    {t("visitors.settings.inviteUser")}
                  </Button>
                )}
              </div>
            ) : (
              items.map((item, i) => (
                <MemberCard.Wrap key={item.user.uuid} highlight={highlighted === i} ref={itemRefs[i]}>
                  <MemberCard.Card
                    firstName={item.user.firstName ?? ""}
                    lastName={item.user.lastName ?? ""}
                    companyName={item.user.companyV2?.name ?? ""}
                    email={item.user.email}
                    profileIconUrl={item.user.avatar ?? undefined}
                    className="mt-2"
                    transparentBackground={true}
                    buttonVariant="outline"
                    buttonText={buttonText(item)}
                    showButton={true}
                    onButtonClick={() => {
                      setSelected(i);
                    }}
                    onButtonFocus={() => {
                      setHighlighted(i);
                    }}
                  />
                </MemberCard.Wrap>
              ))
            )}
          </FormComboBoxMenu>
          <style jsx>{`
            .placeholder-container {
              display: flex;
              text-align: center;
            }
            .placeholder-message {
              font-size: 12px;
              font-weight: 400;
              color: ${theme.colors.medium};
            }
          `}</style>
        </div>
      )}
    </FormComboBox>
  );
};
