import { useContext } from "react";
import { CurrentProfile } from "@equiem/lib";
import { useFormikContext } from "formik";

import { useCompanyFlexInfoQuery } from "../../../generated/gateway-client";
import type { FormValues } from "../../../lib/formValidation";

export const useCompanyIsFlexOperator = () => {
  const { profile, companyIsFlexOperator } = useContext(CurrentProfile);
  const fm = useFormikContext<FormValues>();

  const { data: companyFlexInfo } = useCompanyFlexInfoQuery({
    variables: { uuid: fm.values.ownerCompanyUuid },
    skip: fm.values.ownerCompanyUuid === profile?.companyV2?.uuid,
  });

  return fm.values.ownerCompanyUuid === profile?.companyV2?.uuid
    ? companyIsFlexOperator
    : companyFlexInfo?.companyV2?.hasFlexOperator ?? false;
};
