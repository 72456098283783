import React, { useState, useCallback, useMemo } from "react";
import { EmptyState, Button, InfiniteScrollTable } from "@equiem/react-admin-ui";
import { useTranslation } from "@equiem/localisation-eq1";
import { notNullOrUndefined } from "@equiem/lib";

import { bookingDiscountsTableHeaders } from "./components/BookingDiscountsTable";
import { BookingDiscountRow } from "./components/BookingDiscountRow";
import { RiAddLine } from "@equiem/react-admin-ui/icons";
import { useGetSiteBookingDiscountsQuery } from "../../generated/gateway-client";
import { SettingsTab } from "../settings/components/SettingsTab";
import { BookingDiscountFormModal } from "./components/BookingDiscountFormModal";
import { withContexts } from "../../contexts/withContexts";
import {
  BookingDiscountAddEditContext,
  BookingDiscountAddEditProvider,
} from "./components/BookingDiscountAddEditProvider";
import { ScrollBottomObserverWrapper } from "../../components/ScrollBottomObserverWrapper";

const PAGE_SIZE = 30;

export const BookingDiscountsLocal: React.FC = () => {
  const { t } = useTranslation();
  const {
    data,
    error,
    loading: loadingInitial,
    fetchMore,
  } = useGetSiteBookingDiscountsQuery({ variables: { page: { first: PAGE_SIZE } } });
  const [loadingMore, setLoadingMore] = useState(false);

  const discounts = data?.siteBookingDiscountsList.edges.map((edge) => edge.node).filter(notNullOrUndefined) ?? [];
  const endCursor = data?.siteBookingDiscountsList.pageInfo.endCursor;
  const loading = loadingInitial || loadingMore;

  const rowHeaders = useMemo(() => bookingDiscountsTableHeaders(t), [t]);

  const fetchNextPage = useCallback(() => {
    if (loading || endCursor == null) {
      return;
    }

    setLoadingMore(true);

    fetchMore({
      variables: { page: { first: PAGE_SIZE, after: endCursor } },
      updateQuery(prev, { fetchMoreResult }) {
        return {
          ...fetchMoreResult,
          siteBookingDiscountsList: {
            ...fetchMoreResult.siteBookingDiscountsList,
            edges: [...prev.siteBookingDiscountsList.edges, ...fetchMoreResult.siteBookingDiscountsList.edges],
          },
        };
      },
    })
      .catch(console.error)
      .finally(() => setLoadingMore(false));
  }, [loading, endCursor, fetchMore]);

  if (error != null) {
    return <div>{error.message}</div>;
  }

  return (
    <BookingDiscountAddEditProvider>
      <BookingDiscountAddEditContext.Consumer>
        {({ create }) => (
          <SettingsTab
            prefixTopMenu={
              <div className="w-100 text-right">
                <Button className="main-button" variant="primary" size="md" onClick={create}>
                  <RiAddLine size={16} />
                  {t("bookings.discounts.addNewDiscount")}
                </Button>
              </div>
            }
          >
            <ScrollBottomObserverWrapper onBottomVisible={fetchNextPage}>
              <InfiniteScrollTable
                loading={loading}
                hasMoreData={endCursor != null}
                rowHeaders={rowHeaders}
                rowCount={discounts.length}
                fetchMore={fetchNextPage}
              >
                {discounts.length === 0 && !loading && (
                  <tr>
                    <td colSpan={rowHeaders.length}>
                      <EmptyState message={t("bookings.discounts.emptyState")} />
                    </td>
                  </tr>
                )}
                {discounts.map((discount, i) => (
                  <BookingDiscountRow key={i} discount={discount} />
                ))}
              </InfiniteScrollTable>
            </ScrollBottomObserverWrapper>
            <BookingDiscountFormModal />
          </SettingsTab>
        )}
      </BookingDiscountAddEditContext.Consumer>
    </BookingDiscountAddEditProvider>
  );
};

export const BookingDiscountsPage = withContexts(BookingDiscountsLocal);
