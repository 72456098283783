import { useBookingsEnabled } from "@equiem/bookings";
import { CurrentProfile, CurrentRole, Site, useRequestEnabled } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import { SideNav } from "@equiem/react-admin-ui";
import {
  RiCalendarCheckLine,
  RiDashboardLine,
  RiHomeLine,
  RiQuestionnaireLine,
  RiSettings3Line,
  RiTeamLine,
} from "@equiem/react-admin-ui/icons";
import { useVisitorEnabled } from "@equiem/visitors";
import { useRouter } from "next/router";
import { useContext } from "react";
import { ShortcutItem } from "../ShortcutItem";
import { ShortcutsMenu } from "./ShortcutsMenu";

export const UnknownSideMenu = () => {
  const { isReceptionist, canManageCurrentSiteSettings } = useContext(CurrentProfile);
  const { currentRole } = useContext(CurrentRole);
  const site = useContext(Site);
  const showBookings = useBookingsEnabled();
  const showRequestManagement = useRequestEnabled();
  const showVisitorManagement = useVisitorEnabled();
  const { t } = useTranslation();
  const router = useRouter();

  return (
    <>
      <SideNav isExpandable={false}>
        {site.tierLevel === "Essentials" && (
          <ShortcutItem icon={RiHomeLine} routerLink={"/"} text={t("home.sideMenu.home")} />
        )}
        {(site.tierLevel !== "Essentials" || isReceptionist) && (
          <ShortcutItem icon={RiDashboardLine} routerLink={"/insights"} text={t("home.sideMenu.insights")} />
        )}
        {showBookings && (
          <ShortcutItem icon={RiCalendarCheckLine} routerLink="/bookings" text={t("home.sideMenu.bookings")} />
        )}
        {showRequestManagement && (
          <ShortcutItem
            icon={RiQuestionnaireLine}
            routerLink="/request-management"
            text={t("home.sideMenu.requestManagementShort")}
          />
        )}
        {site.featureModules.visitors.enabled && isReceptionist && (
          <ShortcutItem
            icon={RiTeamLine}
            routerLink="/visitor-management/receptions"
            text={t("home.shortcuts.visitorManagement")}
          />
        )}
        {showVisitorManagement && (
          <ShortcutItem
            icon={RiTeamLine}
            pathFullExact={router.pathname.includes("receptions")}
            routerLink="/visitor-management"
            text={t("home.shortcuts.appointments")}
          />
        )}
        {canManageCurrentSiteSettings && (
          <ShortcutItem icon={RiSettings3Line} routerLink="/settings" text={t("common.settings")} />
        )}
      </SideNav>
      <ShortcutsMenu currentRole={currentRole} className="mt-5" />
    </>
  );
};
