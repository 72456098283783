import React, { useContext, useEffect, useState } from "react";

import { useShowError } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import { Button, Modal, Skeleton, useToast } from "@equiem/react-admin-ui";

import { Modal as ModalContext } from "../../../contexts/ModalContext";
import {
  useDeleteVisitorReceptionMutation,
  useVisitorAppointmentsByReceptionQuery,
} from "../../../generated/visitors-client";
import { ReceptionDeleteModal } from "../components/ReceptionDeleteModal/ReceptionDeleteModal";

export const ReceptionDeleteWidget: React.FC<{ receptionName: string; uuid: string }> = ({ receptionName, uuid }) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const modal = useContext(ModalContext);
  const toast = useToast();
  const showError = useShowError();
  /* istanbul ignore next */
  const [deleteVisitorReception] = useDeleteVisitorReceptionMutation();
  const appointmentsByReceptionQuery = useVisitorAppointmentsByReceptionQuery({
    variables: { receptionUuid: uuid },
    skip: !showModal,
  });
  const appointments = appointmentsByReceptionQuery.data?.visitorAppointmentsByReception;

  useEffect(() => {
    if (modal.activeModal === "DeleteReception" && modal.id === uuid) {
      setShowModal(true);
    }
  }, [modal.activeModal, modal.id, setShowModal, uuid]);

  /* istanbul ignore next */
  const handleDelete = async () => {
    if (modal.id != null) {
      try {
        const result = await deleteVisitorReception({
          variables: { uuid: modal.id },
        });

        if (result.data?.deleteVisitorReception.success === true && result.errors == null) {
          toast.positive(t("visitors.reception.deleteSuccess", { receptionName }));
        } else {
          throw new Error("Failed to delete reception.");
        }
      } catch (e: unknown) {
        showError(e);
      }
    }
    setShowModal(false);
    modal.close();
  };

  const handleCancel = () => {
    setShowModal(false);
    modal.close();
  };

  return appointmentsByReceptionQuery.loading && appointments == null ? (
    <Modal.Dialog title={t("visitors.reception.deleteTitle")} show={showModal}>
      <Modal.Header closeButton={true} noBorder={true}></Modal.Header>
      <Modal.Body>
        <div className="pb-2">
          <Skeleton.Line width="100%" height="18px" />
        </div>
        <div className="pb-2">
          <Skeleton.Line width="100%" height="18px" />
        </div>
        <div className="pb-2">
          <Skeleton.Line width="100%" height="18px" />
        </div>
      </Modal.Body>
    </Modal.Dialog>
  ) : appointments != null ? (
    <ReceptionDeleteModal
      appointments={appointments}
      receptionName={receptionName}
      onClose={handleCancel}
      visible={showModal}
      receptionUuid={uuid}
    />
  ) : (
    <>
      <Modal.Dialog
        title={t("visitors.reception.deleteTitle")}
        show={showModal}
        onHide={handleCancel}
        hideOnEsc
        hideOnClick={false}
        focusTrapOptions={{ initialFocus: false }}
        size="sm"
        centered
      >
        <Modal.Header closeButton={false} noBorder>
          <h3 className="title m-0">{t("visitors.reception.deleteConfirm", { receptionName })}</h3>
        </Modal.Header>
        <Modal.Footer>
          <Button className="action-cancel mr-4" variant="ghost" onClick={handleCancel}>
            {t("common.cancel")}
          </Button>
          <Button
            variant="danger"
            className="delete-button"
            onClick={() => {
              void handleDelete();
            }}
          >
            {t("visitors.reception.deleteTitle")}
          </Button>
        </Modal.Footer>
      </Modal.Dialog>
      <style jsx>
        {`
          .title {
            font-weight: 400;
            font-size: 18px;
            line-height: 28px;
          }
          :global(.delete-button) {
            margin-right: 0.5rem;
          }
        `}
      </style>
    </>
  );
};
