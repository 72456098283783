import { formatters, useTranslation } from "@equiem/localisation-eq1";
import { DateTime } from "luxon";

const MONTH_RANGE = 12;

export const useMonthFilterOptions = () => {
  const { t, i18n } = useTranslation();
  const months = [{ value: 0, label: t("credits.thisMonth") }];

  for (let i = 1; i <= MONTH_RANGE; i++) {
    months.push({
      value: i,
      label: formatters.datemonth(DateTime.fromJSDate(new Date()).plus({ months: i }), i18n.language),
    });
    months.push({
      value: -i,
      label: formatters.datemonth(DateTime.fromJSDate(new Date()).minus({ months: i }), i18n.language),
    });
  }

  return months.sort((a, b) => a.value - b.value);
};
