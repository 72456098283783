import React, { useState, useEffect, useCallback } from "react";
import VisibilitySensor from "react-visibility-sensor";

export const ContentEndSensor: React.FC<{ onContentEnd: () => void }> = ({ onContentEnd }) => {
  const [pageLoaded, setPageLoaded] = useState(false);
  useEffect(() => setPageLoaded(true), []);

  const onVisibilityChange = useCallback(
    (isVisible: boolean) => {
      if (isVisible) {
        onContentEnd();
      }
    },
    [onContentEnd],
  );

  if (!pageLoaded) {
    // wait for the page to be fully laid out
    return null;
  }

  return (
    <VisibilitySensor delayedCall onChange={onVisibilityChange}>
      <div className="content-end-marker" style={{ width: "1px", height: "1px" }} />
    </VisibilitySensor>
  );
};
