export const EmptyIcon = () => {
  return (
    <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="56" height="56" rx="28" fill="#F2F2F2" />
      <path
        d="M36 33H38V35H18V33H20V26C20 23.8783 20.8429 21.8434 22.3431 20.3431C23.8434 18.8429 25.8783 18 28 18C30.1217 18 32.1566 18.8429 33.6569 20.3431C35.1571 21.8434 36 23.8783 36 26V33ZM34 33V26C34 24.4087 33.3679 22.8826 32.2426 21.7574C31.1174 20.6321 29.5913 20 28 20C26.4087 20 24.8826 20.6321 23.7574 21.7574C22.6321 22.8826 22 24.4087 22 26V33H34ZM25 37H31V39H25V37Z"
        fill="#999999"
      />
    </svg>
  );
};
