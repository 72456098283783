import type { IFileV2 } from "@equiem/uploader/IFileV2";

export enum StepType {
  SPACES = "SPACES",
  LEVELS = "LEVELS",
  BUILDINGS = "BUILDINGS",
}
export type RequestValues = {
  buildingUuid?: string;
  buildingLevelUuid?: string;
  spaceUuid?: string;
  categoryUuid?: string;
  description?: string;
  reference?: string;
  attachments?: IFileV2[];
};
