import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import type { FormikHelpers } from "formik";
import { Field, Form } from "formik";

import {
  AppointmentsMenuContext,
  CurrentProfile,
  CurrentRole,
  notNullOrUndefined,
  Role,
  sortByAlphabet,
  stringIsEmpty,
  stringNotEmpty,
  useSaferFormik,
  useSaferFormikContext,
} from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import {
  Button,
  Form as EqForm,
  MemberCard,
  Modal,
  useDebounced,
  useIsMobileWidth,
  useTheme,
  useToast,
} from "@equiem/react-admin-ui";
import { RiAddLine, RiCheckLine } from "@equiem/react-admin-ui/icons";

import { Modal as ModalContext } from "../../../contexts/ModalContext";
import type { VisitorProfileShortFragment } from "../../../generated/visitors-client";
import {
  useVisitorCompaniesQuery,
  useVisitorHostSuggestionsQuery,
  useVisitorReceptionQuery,
} from "../../../generated/visitors-client";
import type { FormHost, FormValues } from "../types";
import { getHostValidationSchema } from "../utils/validationSchema";

const initialValues: FormHost = {
  firstName: "",
  lastName: "",
  company: {
    uuid: "",
    name: "",
  },
  email: "",
};

interface Props {
  mode: "default" | "walkin";
  onHostSelect?: (host: FormHost) => void;
  isEmailRequired?: boolean;
  receptionUuid?: string;
  isNewAppointment?: boolean;
}

export const ChangeHostWidget: React.FC<Props> = ({
  mode,
  onHostSelect,
  isEmailRequired,
  isNewAppointment,
  receptionUuid,
}) => {
  const { cachedAppointmentForm, isPreview } = useContext(AppointmentsMenuContext);
  const [selectedCompany, setSelectedCompany] = useState<{ uuid: string; name: string } | undefined>(undefined);
  const { t } = useTranslation();
  const isMobile = useIsMobileWidth();
  const { breakpoints } = useTheme();
  const { currentRole } = useContext(CurrentRole);
  const modal = useContext(ModalContext);
  const [showModal, setShowModal] = useState(false);
  const [selectedHost, setSelectedHost] = useState<null | (FormHost & { userUuid?: string; avatar?: string })>(null);
  const { profile } = useContext(CurrentProfile);
  const toast = useToast();
  const { setFieldValue } = useSaferFormikContext<FormValues>();
  const { data: receptionData } = useVisitorReceptionQuery({
    variables: { uuid: receptionUuid as string },
    skip: receptionUuid == null,
  });
  const isWorkplaceManagerBuildingReceptionist =
    currentRole === Role.WorkplaceManager && (receptionUuid == null || receptionData?.visitorReception.company != null);

  const cannotSelectCompany = useMemo(
    () => isWorkplaceManagerBuildingReceptionist || selectedCompany != null,
    [mode, selectedCompany, isWorkplaceManagerBuildingReceptionist],
  );
  const skipQueries = mode === "default" && !showModal;
  const { data: companiesData, loading: companiesLoading } = useVisitorCompaniesQuery({
    skip: cannotSelectCompany || skipQueries,
    variables: { filter: { receptionUuid } },
  });

  const companies = sortByAlphabet(companiesData?.visitorCompanies.filter(notNullOrUndefined) ?? [], "name");

  const handleFormSubmit = (submitValues: FormHost, { resetForm }: FormikHelpers<FormHost>) => {
    setFieldValue("host", submitValues).catch(console.error);
    modal.close();
    setShowModal(false);
    resetForm();
    onHostSelect?.(submitValues);
  };

  const {
    dirty,
    values,
    isValid,
    isSubmitting,
    errors,
    touched,
    handleChange,
    handleSubmit,
    resetForm,
    setFieldValue: setHostFieldValue,
  } = useSaferFormik<FormHost>({
    initialValues,
    validationSchema: getHostValidationSchema(isEmailRequired ?? false, t),
    onSubmit: handleFormSubmit,
  });

  const debounceTimeout = 200;
  const debouncedValues = useDebounced(values, debounceTimeout);
  const hostSuggestionsCompanyUuid = useMemo(() => {
    if (stringNotEmpty(selectedCompany?.uuid)) {
      return selectedCompany?.uuid;
    }

    if (stringNotEmpty(values.company.uuid)) {
      return values.company.uuid;
    }

    return undefined;
  }, [selectedCompany?.uuid, values.company.uuid]);

  const { data: suggestedHostsData } = useVisitorHostSuggestionsQuery({
    skip: skipQueries,
    variables: {
      firstName: debouncedValues.firstName,
      lastName: debouncedValues.lastName,
      email: debouncedValues.email,
      companyUuid: hostSuggestionsCompanyUuid,
    },
  });

  const showCreateBtn =
    (dirty && currentRole !== Role.WorkplaceManager) ||
    (currentRole === Role.WorkplaceManager &&
      (values.firstName.length > 0 ||
        values.lastName.length > 0 ||
        (isEmailRequired === true && (values.email?.length ?? 0) > 0)));

  const onClose = useCallback(() => {
    setShowModal(false);
    modal.close();
    resetForm();
  }, [setShowModal, resetForm]);

  const setPredefinedCompany = (company?: { uuid: string; name: string }) => {
    void setHostFieldValue("company", {
      uuid: company?.uuid as string,
      name: company?.name as string,
    });
  };

  useEffect(() => {
    // reset if switch between the modes
    setSelectedCompany(undefined);
  }, [mode]);

  useEffect(() => {
    if (modal.activeModal === "ChangeHost") {
      setShowModal(true);
    }
  }, [modal.activeModal, setShowModal]);

  useEffect(() => {
    if (isPreview && isNewAppointment === false && selectedCompany == null) {
      setSelectedCompany(cachedAppointmentForm?.host.company ?? cachedAppointmentForm?.organizer.company);
    }
  }, [isPreview, selectedCompany, cachedAppointmentForm?.host.company, isNewAppointment, profile?.uuid]);

  useEffect(() => {
    if (selectedCompany != null && showModal) {
      setPredefinedCompany(selectedCompany);
    }
  }, [selectedCompany, showModal]);

  useEffect(() => {
    let newCompany = values.company;

    if (isWorkplaceManagerBuildingReceptionist) {
      newCompany = { uuid: profile?.companyV2?.uuid ?? "", name: profile?.companyV2?.name ?? "" };
    } else if (companies.length === 1 && values.company.uuid !== companies[0].uuid) {
      newCompany = companies[0];
    }
    if (newCompany.uuid !== values.company.uuid) {
      void setHostFieldValue("company", { uuid: newCompany.uuid, name: newCompany.name });
    }
  }, [
    currentRole,
    profile?.companyV2,
    setHostFieldValue,
    modal.activeModal,
    companies,
    isWorkplaceManagerBuildingReceptionist,
    values.company,
  ]);

  const handleChangeTrim = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.trim().length === 0) {
      e.target.value = e.target.value.trim();
    }
    handleChange(e);
  };

  const isHostSearchMode = useMemo(
    () =>
      debouncedValues.firstName.length > 1 ||
      debouncedValues.lastName.length > 1 ||
      (debouncedValues.email?.length ?? 0) > 0,
    [debouncedValues],
  );

  const selectHost = (host: VisitorProfileShortFragment) => {
    const companyItem = {
      uuid: host.companyV2?.uuid ?? "",
      name: host.companyV2?.name ?? "",
    };

    setSelectedHost({
      firstName: host.firstName ?? "",
      lastName: host.lastName ?? "",
      company: companyItem,
      email: stringNotEmpty(host.email) ? host.email : undefined,
      userUuid: host.uuid,
      avatar: host.avatar ?? "",
    });
  };

  const createHost = (host: FormHost) => {
    const companyItem = {
      uuid: host.company.uuid,
      name: host.company.name,
    };

    setSelectedHost({
      firstName: host.firstName,
      lastName: host.lastName,
      company: companyItem,
      email: stringNotEmpty(host.email) ? host.email : undefined,
    });
  };

  const selectHostWalkin = (host: VisitorProfileShortFragment) => {
    const companyItem = {
      uuid: host.companyV2?.uuid ?? "",
      name: host.companyV2?.name ?? "",
    };
    setFieldValue("host", {
      firstName: host.firstName ?? "",
      lastName: host.lastName ?? "",
      company: companyItem,
      email: stringNotEmpty(host.email) ? host.email : undefined,
      userUuid: host.uuid,
      avatar: host.avatar ?? "",
    }).catch(console.error);
    modal.close();
    toast.positive(t("visitors.appointmentForm.hostAdded", { name: `${host.firstName} ${host.lastName}` }));
    setShowModal(false);
    resetForm();
    onHostSelect?.({ ...host, company: host.companyV2 } as FormHost);
  };

  const submitHost = () => {
    if (selectedHost != null) {
      setFieldValue("host", {
        firstName: selectedHost.firstName,
        lastName: selectedHost.lastName,
        company: selectedHost.company,
        email: stringNotEmpty(selectedHost.email) ? selectedHost.email : undefined,
        userUuid: selectedHost.userUuid,
        avatar: selectedHost.avatar,
      }).catch(console.error);
      if (stringIsEmpty(selectedHost.email)) {
        setFieldValue("notifyHost", false).catch(console.error);
      }
      modal.close();
      toast.positive(
        t("visitors.appointmentForm.hostAdded", { name: `${selectedHost.firstName} ${selectedHost.lastName}` }),
      );
      setShowModal(false);
      resetForm();
      onHostSelect?.({ ...selectedHost, company: selectedHost.company } as FormHost);
      setSelectedHost(null);
    }
  };

  const suggestedHosts =
    suggestedHostsData?.visitorHostSuggestions
      .filter(notNullOrUndefined)
      .filter((host) => host.firstName !== "Deleted" && host.lastName !== "Deleted") ?? [];

  const renderForm = () => (
    <Form className="d-flex body">
      <div className="form-block px-6 mt-6">
        <EqForm.Group label={t("common.firstName")} required>
          <EqForm.Input
            placeholder={t("common.firstName") ?? ""}
            type="text"
            name="firstName"
            value={values.firstName}
            onChange={handleChangeTrim}
            autoComplete="off"
          />
        </EqForm.Group>
        <EqForm.Group label={t("common.lastName")} required>
          <EqForm.Input
            placeholder={t("common.lastName") ?? ""}
            type="text"
            name="lastName"
            value={values.lastName}
            onChange={handleChangeTrim}
            autoComplete="off"
          />
        </EqForm.Group>
        <EqForm.Group
          label={t("common.company")}
          required
          error={Boolean(touched.company) && stringNotEmpty(errors.company?.uuid) ? errors.company?.uuid : undefined}
          showTooltip
          tooltipText={t("visitors.appointmentForm.companyTooltip")}
        >
          {cannotSelectCompany ? (
            <Field disabled name="company" as={EqForm.Input} value={values.company.name} />
          ) : (
            <EqForm.Select
              name="company"
              value={values.company.uuid}
              onChange={(e) => {
                const company = companies.find((c) => c.uuid === e.currentTarget.value);
                void setHostFieldValue("company", { uuid: company?.uuid ?? "", name: company?.name ?? "" });
              }}
              disabled={companiesLoading || companies.length === 0}
            >
              <option>{t("visitors.appointmentForm.selectCompany")}...</option>
              {companiesLoading ? (
                <option value="">{t("common.loading")}...</option>
              ) : companies.length === 0 ? (
                <option value="">{t("common.emptyNone")}</option>
              ) : (
                companies.map((node) => (
                  <option key={node.uuid} value={node.uuid}>
                    {node.name}
                  </option>
                ))
              )}
            </EqForm.Select>
          )}
        </EqForm.Group>
        <EqForm.Group
          label={t("common.email")}
          error={values.email !== "" && errors.email !== undefined ? errors.email : undefined}
          required={isEmailRequired}
          showTooltip
          tooltipText={t("visitors.appointmentForm.emailTooltip")}
        >
          <EqForm.Input
            placeholder={t("common.email") ?? ""}
            type="text"
            name="email"
            value={values.email}
            onChange={handleChangeTrim}
            autoComplete="off"
          />
        </EqForm.Group>
      </div>
      <div className="visitors-block p-6 pt-0">
        {selectedHost != null ? (
          <EqForm.Group className="mb-0" label={t("visitors.appointmentForm.newHost")}>
            <div className="list">
              <MemberCard.Card
                className="visitor"
                buttonElement={isMobile ? <RiAddLine size={20} /> : undefined}
                showButtonOnHover={!isMobile}
                buttonSize={isMobile ? "md" : "sm"}
                showButton={isMobile}
                buttonRound={isMobile}
                firstName={selectedHost.firstName}
                lastName={selectedHost.lastName}
                companyName={selectedHost.company.name}
                profileIconUrl={selectedHost.avatar ?? undefined}
                email={selectedHost.email}
                transparentBackground={!isMobile}
                buttonType="submit"
                buttonVariant="ghost"
                isCardInModal={false}
                isDeleteVisibleAlways={isMobile}
                deleteButton={isMobile ? <RiCheckLine size={20} /> : undefined}
                deleteButtonVariant={isMobile ? "primary" : "ghost"}
                onDeleteButtonClick={() => {
                  setSelectedHost(null);
                  resetForm();
                }}
              />
            </div>
          </EqForm.Group>
        ) : (
          <>
            {showCreateBtn && (
              <EqForm.Group className="mb-0" label={t("visitors.appointmentForm.createNewHost")}>
                <div className="list">
                  <MemberCard.Card
                    className="visitor"
                    buttonElement={isMobile ? <RiAddLine size={20} /> : undefined}
                    showButtonOnHover={!isMobile}
                    buttonSize={isMobile ? "md" : "sm"}
                    buttonRound={isMobile}
                    firstName={values.firstName}
                    lastName={values.lastName}
                    companyName={values.company.name}
                    email={values.email}
                    showButton={true}
                    buttonText={t("common.create")}
                    isButtonDisabled={!isValid || isSubmitting}
                    buttonVariant={isMobile ? "secondary" : "primary"}
                    isCardInModal={true}
                    onClick={(e) => {
                      e.preventDefault();

                      if (isValid) {
                        mode === "default" ? createHost(values) : handleSubmit();
                      }
                    }}
                  />
                </div>
              </EqForm.Group>
            )}
            {suggestedHosts.length > 0 && (
              <EqForm.Group
                className="mb-0"
                label={
                  !isHostSearchMode
                    ? t("visitors.appointmentForm.suggestedHosts")
                    : t("visitors.appointmentForm.suggestedHostsEmpty")
                }
              >
                <div className="list">
                  {suggestedHosts.map((host) => (
                    <MemberCard.Card
                      buttonElement={isMobile ? <RiAddLine size={20} /> : undefined}
                      showButtonOnHover={!isMobile}
                      showButton={isMobile}
                      buttonSize={isMobile ? "md" : "sm"}
                      buttonRound={isMobile}
                      firstName={host.firstName ?? ""}
                      lastName={host.lastName ?? ""}
                      companyName={host.companyV2?.name ?? ""}
                      profileIconUrl={host.avatar ?? undefined}
                      email={host.email}
                      className="visitor mt-2"
                      transparentBackground={!isMobile}
                      isCardInModal={false}
                      key={host.uuid}
                      buttonVariant={isMobile ? "secondary" : "outline"}
                      buttonText={t("common.select")}
                      onButtonClick={() => (mode === "default" ? selectHost(host) : selectHostWalkin(host))}
                    />
                  ))}
                </div>
              </EqForm.Group>
            )}
          </>
        )}
      </div>
      <style jsx>
        {`
          .form-block {
            flex: 4;
          }

          .visitors-block {
            flex: 3;
            gap: 24px;
            background: #f7f7f7;
            max-height: 400px;
            display: flex;
            flex-direction: column;
            overflow: auto;
          }

          @media (max-width: ${breakpoints.md}px) {
            :global(.visitor-modal-root) {
              padding: 0 !important;
            }

            .visitors-block {
              position: sticky;
              bottom: 0;
              max-height: 140px;
            }

            :global(.body) {
              flex-direction: column;
              height: 100%;
            }

            .form-block {
              flex: 1;
              overflow: auto;
            }

            .preview {
              flex: 1;
              height: unset;
            }

            .list {
              display: flex;
              flex-direction: row;
              gap: 8px;
              overflow: auto;
            }

            .modal-body {
              overflow: auto;
            }

            .row {
              flex-direction: row;
              flex-wrap: nowrap;
            }

            :global(.visitor) {
              min-width: 200px;
              max-width: 200px;
            }
          }
        `}
      </style>
    </Form>
  );

  if (mode === "default") {
    return (
      <>
        <Modal.Dialog
          title={t("visitors.appointmentForm.changeHost")}
          show={showModal}
          onHide={onClose}
          supportsMobile
          hideOnEsc={true}
          hideOnClick={true}
          focusTrapOptions={{ allowOutsideClick: () => true }}
          size="lg"
          className="host-modal"
        >
          <Modal.Header
            intro={!isMobile ? t("visitors.appointmentForm.addVisitorsIntro") : undefined}
            closeButton={isMobile}
            noBorder={true}
          />
          <div className="modal-body">
            <hr className="custom-liner" />
            <div style={{ height: "100%" }}>{renderForm()}</div>
            <hr className="custom-liner" />
          </div>
          <Modal.Footer>
            <div className="footer">
              <Button size="md" onClick={onClose} variant="ghost">
                {t("common.cancel")}
              </Button>
              <Button size="md" onClick={submitHost} disabled={selectedHost == null}>
                {t("common.confirm")}
              </Button>
            </div>
          </Modal.Footer>
        </Modal.Dialog>
        <style jsx>
          {`
            .custom-liner {
              margin: 0px;
              border-top: 1px solid rgba(0, 0, 0, 0.1);
            }
            .footer {
              display: grid;
              grid-auto-flow: column;
              grid-column-gap: 8px;
              z-index: 999;
            }

            @media (max-width: ${breakpoints.sm}px) {
              :global(.host-modal) {
                height: 100% !important;
              }
            }

            @media (max-width: ${breakpoints.md}px) {
              .modal-body {
                height: 100%;
                max-height: 100%;
                overflow: auto;
              }

              :global(.host-modal) {
                max-height: 100% !important;
              }

              :global(.host-modal .title-row) {
                margin: 20px 0 !important;
              }

              :global(.host-modal .title-row .close) {
                margin-bottom: 0;
              }

              :global(.host-modal .title-row .title) {
                font-size: 24px;
                color: black;
                text-transform: none;
                font-weight: 700;
              }

              :global(.host-modal .footer) {
                padding: 8px !important;
              }
            }
          `}
        </style>
      </>
    );
  }

  return (
    <div className="inline-container">
      {renderForm()}
      <style jsx>
        {`
          .inline-container {
          }
        `}
      </style>
    </div>
  );
};
