import type { PropsWithChildren } from "react";
import React from "react";
import { useTheme } from "@equiem/react-admin-ui";

export const ArticleFooterSection: React.FC<PropsWithChildren<{ title: string }>> = ({ title, children }) => {
  const { colors } = useTheme();

  return (
    <>
      <div className="section mt-7 pt-7">
        <div className="title mb-3 text-muted">{title}</div>
        <div>{children}</div>
      </div>
      <style jsx>{`
        .section {
          border-top: 1px solid ${colors.border};
        }
        .title {
          font-weight: 500;
          font-size: 12px;
          line-height: 16px;
          text-transform: uppercase;
        }
      `}</style>
    </>
  );
};
