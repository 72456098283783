import type { PropsWithChildren } from "react";
import React, { createContext, useContext, useMemo, useState } from "react";
import type { WeekdayNumbers } from "luxon";
import { v4 as uuidv4 } from "uuid";
import { DateTime } from "luxon";
import { useSaferFormikContext } from "@equiem/lib";
import type { BookingFormValue } from "../models/BookingFormValue";
import { RecurringType } from "../models/RecurringSettings";
import type { EndsOnType, RecurringSettings, ValidatedRecurringDate, Version } from "../models/RecurringSettings";
import { BookingModalInfo } from "./BookingModalInfoProvider";

export interface RecurringSettingsModalContext {
  close: () => void;
  submit: (value: RecurringSettings) => void;
  recurringSettings: RecurringSettings;
  hasSuperPower: boolean;
  setRecurringType: (val: RecurringType) => void;
  setRepeatEvery: (val: number) => void;
  setRepeatEndsType: (val: EndsOnType) => void;
  setRepeatEndsTimes: (val: number) => void;
  setRepeatEndsUntil: (val?: DateTime) => void;
  setSameWeekDayEachMonth: (val: boolean) => void;
  setLastWeekDayEachMonth: (val: boolean) => void;
  setRepeatOn: (val: WeekdayNumbers[]) => void;
  setValidatedRecurringDates: (val: ValidatedRecurringDate[], resetVersion?: boolean) => void;
}

export const BookingRecurringModalContext = createContext<RecurringSettingsModalContext>(
  {} as RecurringSettingsModalContext,
);

export function useBookingRecurringModalContext() {
  return useContext(BookingRecurringModalContext);
}

export const MIN_ENDS_AFTER_OCCURRENCES = 2;
export const MAX_ENDS_AFTER_OCCURRENCES = 100;

interface Props {
  onClose: () => void;
}
export const BookingRecurringModalProvider: React.FC<PropsWithChildren<Props>> = ({ children, onClose }) => {
  const { values, setFieldValue } = useSaferFormikContext<BookingFormValue>();
  const { timezone } = useContext(BookingModalInfo);

  const defaultRecurringSettings = useMemo<RecurringSettings>(() => {
    const startTime = DateTime.fromFormat(`${values.date} ${values.start}`, "yyyy-MM-dd HH:mm", { zone: timezone });
    const endTime = DateTime.fromFormat(`${values.date} ${values.end}`, "yyyy-MM-dd HH:mm", { zone: timezone });

    return {
      repeatEndsType: "date",
      repeatEndsTimes: MIN_ENDS_AFTER_OCCURRENCES,
      repeatEndsUntil: startTime.plus({
        days: values.recurringType === RecurringType.Daily || values.recurringType === RecurringType.Weekly ? 13 : 0,
        months: values.recurringType === RecurringType.Monthly ? 1 : 0,
      }),
      recurringType: values.recurringType != null ? (values.recurringType as RecurringType) : RecurringType.Daily,
      sameWeekDayEachMonth: false,
      lastWeekDayEachMonth: false,
      repeatOn: [startTime.weekday as WeekdayNumbers],
      repeatEvery: 1,
      start: startTime,
      end: endTime.plus({
        days: values.recurringType === RecurringType.Daily || values.recurringType === RecurringType.Weekly ? 13 : 0,
        months: values.recurringType === RecurringType.Monthly ? 1 : 0,
      }),
      validatedRecurringDates: [],
      selectedStartTime: startTime,
      selectedEndTime: endTime,
      version: { before: undefined, current: undefined },
    };
  }, [timezone, values.date, values.end, values.recurringType, values.start]);

  const [recurringSettings, setRecurringSettings] = useState<RecurringSettings>(
    values.recurringSettings ?? defaultRecurringSettings,
  );

  const submit = (value: RecurringSettings) => {
    setRecurringSettings({ ...value, version: { before: undefined, current: undefined } });
    void setFieldValue("recurringSettings", value, false).then(() => {
      onClose();
    });
  };

  const vs = (prev: string, curr: string, currVersion: Version): Version =>
    prev === curr ? currVersion : { before: currVersion.current, current: uuidv4() };

  return (
    <BookingRecurringModalContext.Provider
      value={{
        close: () => onClose(),
        submit: (value) => submit(value),
        recurringSettings,
        hasSuperPower: values.hasSuperPower ?? false,
        setValidatedRecurringDates: (val, resetVersion = false) =>
          setRecurringSettings((v) => ({
            ...v,
            validatedRecurringDates: val,
            version: resetVersion ? { current: undefined, before: undefined } : v.version,
          })),
        setRecurringType: (val) =>
          setRecurringSettings((v) => ({
            ...v,
            recurringType: val,
            version: vs(`${v.recurringType}`, `${val}`, v.version),
          })),
        setRepeatEvery: (val) =>
          setRecurringSettings((v) => ({
            ...v,
            repeatEvery: val,
            version: vs(`${v.repeatEvery}`, `${val}`, v.version),
          })),
        setRepeatEndsType: (val) =>
          setRecurringSettings((v) => ({
            ...v,
            repeatEndsType: val,
            version: vs(`${v.repeatEndsType}`, `${val}`, v.version),
          })),
        setRepeatEndsTimes: (val) =>
          setRecurringSettings((v) => ({
            ...v,
            repeatEndsTimes: val,
            version: vs(`${v.repeatEndsTimes}`, `${val}`, v.version),
          })),
        setRepeatEndsUntil: (val) =>
          setRecurringSettings((v) => ({
            ...v,
            repeatEndsUntil: val,
            version: vs(`${v.repeatEndsUntil?.toMillis()}`, `${val?.toMillis()}`, v.version),
          })),
        setSameWeekDayEachMonth: (val) =>
          setRecurringSettings((v) => ({
            ...v,
            sameWeekDayEachMonth: val,
            version: vs(`${v.sameWeekDayEachMonth}`, `${val}`, v.version),
          })),
        setLastWeekDayEachMonth: (val) =>
          setRecurringSettings((v) => ({
            ...v,
            lastWeekDayEachMonth: val,
            version: vs(`${v.lastWeekDayEachMonth}`, `${val}`, v.version),
          })),
        setRepeatOn: (val) =>
          setRecurringSettings((v) => ({
            ...v,
            repeatOn: val,
            version: vs(`${v.repeatOn.join("")}`, `${val.join("")}`, v.version),
          })),
      }}
    >
      {children}
    </BookingRecurringModalContext.Provider>
  );
};
