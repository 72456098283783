import React, { useEffect, useState } from "react";
import { type FieldProps, Field } from "formik";
import * as QRCode from "qrcode";

import { useSaferFormikContext, useShowError } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import { Form as EqForm, IconButton, Text, useTheme } from "@equiem/react-admin-ui";
import { RiArrowLeftSLine, RiArrowRightSLine } from "@equiem/react-admin-ui/icons";

import type { GeneralSettingsFormValues } from "../GeneralSettingsValues";

import { ApplePreview } from "./ApplePreview";
import { GooglePreview } from "./GooglePreview";

export const AccessPasses = () => {
  const { t } = useTranslation();
  const { breakpoints, colors } = useTheme();
  const [applePreview, setApplePreview] = useState(true);
  const [qrCode, setQrCode] = useState<string>("");
  const { errors } = useSaferFormikContext<GeneralSettingsFormValues>();
  const showError = useShowError();

  useEffect(() => {
    QRCode.toDataURL("%123456789", { width: 120, margin: 0 }, (err, url) => {
      if (err != null) {
        showError(err);
        return;
      }

      setQrCode(url);
    });
  }, [showError]);

  return (
    <>
      <Text variant="heading" size="small" className="mb-6">
        {t("visitors.settings.walletPass.accessPasses")}
      </Text>
      <div className="w-100">
        <EqForm.Group label={t("visitors.settings.walletPass.mobileWalletPassConfiguration")}>
          <div className="pass-configuration">
            <div className="pass-configuration-preview">
              <div className="d-flex justify-content-between align-items-center mb-2">
                <Text variant="label" className="label">
                  {applePreview
                    ? t("visitors.settings.walletPass.previewHeaderApple")
                    : t("visitors.settings.walletPass.previewHeaderGoogle")}
                </Text>
                <div className="arrows">
                  <IconButton onClick={() => setApplePreview(!applePreview)}>
                    <RiArrowLeftSLine size={16} />
                  </IconButton>
                  <IconButton onClick={() => setApplePreview(!applePreview)}>
                    <RiArrowRightSLine size={16} />
                  </IconButton>
                </div>
              </div>
              {applePreview ? <ApplePreview qrCode={qrCode} /> : <GooglePreview qrCode={qrCode} />}
            </div>
            <div className="d-flex flex-column p-6">
              <EqForm.Group
                label={t("visitors.settings.walletPass.backgroundColor")}
                error={errors.walletPassBackgroundColor}
              >
                <Field name="walletPassBackgroundColor">
                  {({ field }: FieldProps<string>) => (
                    <EqForm.InputExtended
                      {...field}
                      placeholder={"#EEEEEE"}
                      undeletablePrefix="#"
                      variant="md"
                      icon={() => <div className="color-icon" style={{ background: field.value }}></div>}
                    />
                  )}
                </Field>
              </EqForm.Group>
              <span className="title mb-6">{t("visitors.settings.walletPass.additionalInputsInfo")}</span>
              <EqForm.Group label={t("visitors.settings.walletPass.labelColor")} error={errors.walletPassLabelColor}>
                <Field name="walletPassLabelColor">
                  {({ field }: FieldProps<string>) => (
                    <EqForm.InputExtended
                      {...field}
                      placeholder={"#000000"}
                      variant="md"
                      undeletablePrefix="#"
                      icon={() => <div className="color-icon" style={{ background: field.value }}></div>}
                    />
                  )}
                </Field>
              </EqForm.Group>
              <EqForm.Group
                label={t("visitors.settings.walletPass.foregroundColor")}
                error={errors.walletPassForegroundColor}
              >
                <Field name="walletPassForegroundColor">
                  {({ field }: FieldProps<string>) => (
                    <EqForm.InputExtended
                      {...field}
                      placeholder={"#000000"}
                      undeletablePrefix="#"
                      variant="md"
                      icon={() => <div className="color-icon" style={{ background: field.value }}></div>}
                    />
                  )}
                </Field>
              </EqForm.Group>
            </div>
          </div>
        </EqForm.Group>
      </div>
      <style jsx>{`
        .title {
          font-weight: 500;
          font-size: 12px;
          line-height: 16px;
          text-transform: uppercase;
          color: rgba(0, 0, 0, 0.6);
        }
        .color-icon {
          height: 16px;
          width: 16px;
          border-radius: 4px;
        }
        .pass-configuration {
          display: flex;
          border: 1px solid ${colors.grayscale[10]};
          border-radius: 4px;
          flex-direction: column-reverse;
        }
        .pass-configuration-preview {
          display: flex;
          padding: 24px;
          background: ${colors.grayscale[3]};
          flex-direction: column;
          gap: 8px;
        }
        .pass-configuration-preview :global(.label) {
          color: ${colors.transparent.black[60]};
        }
        @media screen and (min-width: ${breakpoints.md}px) {
          .pass-configuration {
            flex-direction: row;
          }
          .pass-configuration-preview {
            min-width: 361px;
          }
        }
      `}</style>
    </>
  );
};
