import { stringNotEmpty, useBcUuidContext } from "@equiem/lib";
import { useRouter } from "next/router";
import type { ReactNode } from "react";
import React, { useCallback, useMemo, useState } from "react";
import { validate as uuidValidate } from "uuid";
import { Breadcrumbs } from "./BreadcrumbsContext";

export const hiddenBreadcrumbs = [
  "permissions",
  "receptions",
  "pre_booked",
  "checked_in",
  "checked_out",
  "walk_in",
  "edit appointment",
  "edit reception",
  "building",
  "general",
  "dashboard",
  "articles",
  "links",
];
export const hiddenBreadcrumbsUrls = [
  "^/request-management/settings/categories",
  "^/request-management/settings/queues",
  "^/request-management/request$",
  "^/settings/users",
  "^/settings/companies",
  "^/settings/apartments",
  "^/settings/buildings",
  "^/settings/general",
  "^/bookings/my-bookings/[^/]+/view$",
  "^/bookings/my-bookings/[^/]+/edit$",
  "^/bookings/settings/resource-configuration$",
  "^/links/",
].map((r) => new RegExp(r, "u"));

export const customBreadcrumbs = ["building-receptions", "content-management"];

const generatePathParts = (pathStr: string): string[] => {
  const pathWithoutQuery = decodeURIComponent(pathStr).split("?")[0];
  return pathWithoutQuery.split("/").filter((v) => v.length > 0);
};

export const BreadcrumbsProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const router = useRouter();
  const { bcUuid } = useBcUuidContext();

  const breadcrumbs = useMemo(() => {
    const getDefaultTextGenerator = (path: string): string => {
      if (uuidValidate(path)) {
        if (bcUuid?.[path]) {
          return bcUuid[path];
        } else {
          return "";
        }
      }

      if (customBreadcrumbs.includes(path) && bcUuid != null && bcUuid[path]) {
        return bcUuid[path];
      }

      return path.replace("-", " ");
    };

    const asPathNestedRoutes = generatePathParts(router.asPath);
    const crumbList = asPathNestedRoutes
      .map((subpath, index) => {
        const currentParams = decodeURIComponent(router.asPath).split("?")[1] ?? "";
        const href = `/${asPathNestedRoutes.slice(0, index + 1).join("/")}${
          currentParams.length > 0 ? `?${currentParams}` : ""
        }`;
        return {
          href,
          text: getDefaultTextGenerator(subpath),
        };
      })
      .filter(
        (crumb) =>
          stringNotEmpty(crumb.text) &&
          !hiddenBreadcrumbs.includes(crumb.text) &&
          !hiddenBreadcrumbsUrls.some((r) => r.test(crumb.href.split("?")[0])),
      );
    return [{ text: "home", href: "/" }, ...crumbList];
  }, [router, bcUuid]);

  const [initialHistoryLength] = useState(typeof history === "undefined" ? 0 : history.length);
  const back = useCallback(() => {
    const historyDepth = history.length - initialHistoryLength;
    if (historyDepth > 0) {
      void router.back();
    } else if (breadcrumbs.length > 1) {
      void router.replace({
        pathname: breadcrumbs[breadcrumbs.length - 2].href,
      });
    } // else { we're on the home page so bread crumbs aren't shown }
  }, [breadcrumbs, initialHistoryLength, router]);

  return <Breadcrumbs.Provider value={{ breadcrumbs, back }}>{children}</Breadcrumbs.Provider>;
};
