import { uniqBy } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useUserCompaniesForSegments } from "./useUserCompaniesForSegments";
import type { BookableResourceSiteAudienceInput } from "../generated/gateway-client";
import {
  BookableResourceBookingApprovalType,
  useSiteCompaniesLazyQuery,
  useSiteCompaniesQuery,
} from "../generated/gateway-client";

type CompanyOption = {
  value: string;
  label: string;
};

export function useManualApprovalCompanyOptions(
  destinationUuid: string,
  selectedAudiences: Array<
    BookableResourceSiteAudienceInput & {
      siteName: string;
    }
  >,
  bookingApprovalType?: BookableResourceBookingApprovalType | null,
) {
  const isManualApproval = bookingApprovalType === BookableResourceBookingApprovalType.ManualForCompanies;

  // Fetch owner site companies options
  const shouldFetchOwnerSiteCompanies = isManualApproval && selectedAudiences.length === 0;
  const { data: ownerSiteCompaniesData, loading: ownerSiteCompaniesLoading } = useSiteCompaniesQuery({
    variables: { destinationUuid },
    skip: !shouldFetchOwnerSiteCompanies,
  });
  const ownerSiteCompanyOptions: CompanyOption[] = useMemo(
    () =>
      (ownerSiteCompaniesData?.destination.companiesV2?.edges ?? []).flatMap((edge) =>
        edge.node != null ? [{ value: edge.node.uuid, label: edge.node.name }] : [],
      ),
    [ownerSiteCompaniesData],
  );

  // Fetch audience sites companies options
  const [fetchAudienceSitesCompanies] = useSiteCompaniesLazyQuery();
  const [audienceSitesCompanyOptions, setAudienceSitesCompanyOptions] = useState<CompanyOption[]>([]);
  const [audienceSitesCompaniesLoading, setAudienceSitesCompaniesLoading] = useState(false);
  useEffect(() => {
    if (!isManualApproval || selectedAudiences.length === 0) {
      setAudienceSitesCompanyOptions([]);
      return;
    }

    const siteIds = selectedAudiences
      .filter((audience) => audience.segmentIds == null || audience.segmentIds.length === 0)
      .map((audience) => audience.site);

    const fetchDataForAllSites = async () => {
      setAudienceSitesCompaniesLoading(true);
      try {
        const results = await Promise.all(
          siteIds.map(async (siteId) =>
            fetchAudienceSitesCompanies({
              variables: { destinationUuid: siteId },
            }).then((response) =>
              (response.data?.destination.companiesV2?.edges ?? []).flatMap((edge) =>
                edge.node != null ? [{ value: edge.node.uuid, label: edge.node.name }] : [],
              ),
            ),
          ),
        );
        setAudienceSitesCompanyOptions(results.flat());
      } finally {
        setAudienceSitesCompaniesLoading(false);
      }
    };

    void fetchDataForAllSites();
  }, [selectedAudiences, isManualApproval, fetchAudienceSitesCompanies]);

  // Fetch audience segments companies options
  const segmentUuids = useMemo(
    () => [...new Set(selectedAudiences.flatMap((audience) => audience.segmentIds ?? []))],
    [selectedAudiences],
  );
  const shouldFetchSegmentCompanies = isManualApproval && segmentUuids.length > 0;
  const { companies: segmentCompanies, loading: audienceSegmentsCompaniesLoading } = useUserCompaniesForSegments(
    {
      segmentUuids,
      overrideUnsubscribes: true,
    },
    !shouldFetchSegmentCompanies,
  );
  const audienceSegmentsCompanyOptions: CompanyOption[] = useMemo(
    () =>
      segmentCompanies.flatMap((company) => (company != null ? [{ value: company.uuid, label: company.name }] : [])),
    [segmentCompanies],
  );

  // Combine, deduplicate, and sort the company options
  const companyOptions = useMemo(
    () =>
      uniqBy(
        [...ownerSiteCompanyOptions, ...audienceSitesCompanyOptions, ...audienceSegmentsCompanyOptions],
        "value",
      ).sort((a, b) => a.label.localeCompare(b.label)),
    [audienceSitesCompanyOptions, ownerSiteCompanyOptions, audienceSegmentsCompanyOptions],
  );

  return {
    companyOptions,
    companiesLoading: ownerSiteCompaniesLoading || audienceSitesCompaniesLoading || audienceSegmentsCompaniesLoading,
  };
}
