/* eslint-disable @typescript-eslint/no-magic-numbers */
import React, { useCallback, useContext, useEffect } from "react";
import { useFormikContext } from "formik";
import type { BookingInvoiceContactsQuery } from "../../../../generated/gateway-client";
import {
  BookingInvoiceContactTypeInput,
  useDeleteBookingInvoiceContactMutation,
} from "../../../../generated/gateway-client";
import { RiDeleteBinLine } from "@equiem/react-admin-ui/icons";
import { Form, IconButton, ProgressCircle, useTheme } from "@equiem/react-admin-ui";
import type { BookingFormValue } from "../../models/BookingFormValue";
import { BookingModal } from "../../contexts/BookingModalContext";

interface P {
  onDeleteFinish: (contactUuid: string) => void;
  list: BookingInvoiceContactsQuery["bookingInvoiceContacts"][number];
  index: number;
}

const ContactLabel: React.FC<{
  name: string;
  email: string;
  isDeleting: boolean;
  onDeleteClicked?: (() => unknown) | null;
}> = ({ name, email, isDeleting, onDeleteClicked }) => {
  const atSymbol = email.lastIndexOf("@");
  const mailbox = atSymbol !== -1 ? email.slice(0, atSymbol) : email;
  const domain = atSymbol !== -1 ? email.slice(atSymbol + 1) : null;

  return (
    <>
      <div className="label-container" title={`${name} (${email})`}>
        <strong className="mr-2">{name}</strong>
        <small className="muted">{mailbox}</small>
        {domain != null && <small className="muted">@{domain}</small>}
        <div className="delete-container">
          {onDeleteClicked != null && !isDeleting && (
            <IconButton
              className="delete"
              onClick={() => {
                onDeleteClicked();
              }}
            >
              <RiDeleteBinLine size={25} />
            </IconButton>
          )}
          {isDeleting && <ProgressCircle size="sm" />}
        </div>
      </div>
      <style jsx>{`
        .label-container {
          display: flex;
          align-items: center;
        }
        .label-container > * {
          max-width: 50%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .delete-container {
          min-width: 25px;
          margin-left: auto;
          cursor: pointer;
        }
      `}</style>
    </>
  );
};

export const BookingPaymentContact: React.FC<P> = ({ list, index, onDeleteFinish }) => {
  const fm = useFormikContext<BookingFormValue>();
  const contact = fm.values.contact;
  const [mutation, { loading: deleteLoading }] = useDeleteBookingInvoiceContactMutation();
  const theme = useTheme();
  const modal = useContext(BookingModal);

  useEffect(() => {
    // Default to self when contact is none.
    if (contact == null && list.__typename === "BookingInvoiceContactSelf") {
      void fm.setFieldValue("contact", {
        type: BookingInvoiceContactTypeInput.Self,
        email: list.email,
        fullName: list.fullName,
        uuid: undefined,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contact]);

  const deleteContact = useCallback(
    async (contactUuid: string) => {
      try {
        modal.setCanSubmitForm(false);
        await mutation({ variables: { uuid: contactUuid } });

        if (
          contact != null &&
          contact.type !== BookingInvoiceContactTypeInput.NewContact &&
          contact.uuid === contactUuid
        ) {
          await fm.setFieldValue("contact", undefined);
        }

        onDeleteFinish(contactUuid);
      } finally {
        modal.setCanSubmitForm(true);
      }
    },
    // We don't need to include [fm].
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [contact, mutation, onDeleteFinish],
  );

  return (
    <>
      <div className="invoice">
        <Form.RadioButton
          name="person"
          id={`person-${index}`}
          className="w-100"
          checked={
            contact != null &&
            contact.type !== BookingInvoiceContactTypeInput.NewContact &&
            contact.email === list.email &&
            contact.fullName === list.fullName
          }
          label={
            <ContactLabel
              name={list.fullName}
              email={list.email}
              isDeleting={deleteLoading}
              onDeleteClicked={
                list.__typename === "BookingInvoiceContactExisting"
                  ? async () => deleteContact(list.contactUuid).catch(console.log)
                  : null
              }
            />
          }
          onClick={() => {
            const isSelected =
              contact != null &&
              contact.type !== BookingInvoiceContactTypeInput.NewContact &&
              contact.email === list.email &&
              contact.fullName === list.fullName;

            if (isSelected) {
              void fm.setFieldValue("contact", undefined);
            } else {
              void fm.setFieldValue("contact", {
                type:
                  list.__typename === "BookingInvoiceContactSelf"
                    ? BookingInvoiceContactTypeInput.Self
                    : BookingInvoiceContactTypeInput.ExistingContact,
                email: list.email,
                fullName: list.fullName,
                uuid: list.__typename === "BookingInvoiceContactSelf" ? undefined : list.contactUuid,
              });
            }
          }}
          onChange={() => {
            // Do nothing to remove the warning.
          }}
        />
      </div>
      <style jsx>{`
        div {
          border: 1px solid ${theme.colors.border};
          border-radius: ${theme.borderRadius};
          padding: ${theme.spacers.s4};
          margin: 0 0 ${theme.spacers.s3};
          display: flex;
          align-items: center;
        }
        div :global(.checkbox) {
          width: 100%;
        }
        div :global(.form-check-label) {
          display: block;
        }
      `}</style>
    </>
  );
};
