import * as rt from "runtypes";

const ValueEntry = rt.Union(rt.String, rt.Record({ label: rt.String, value: rt.String }));

const FilterValue = rt.Union(
  rt.Record({
    type: rt.String,
    modifier: rt.String,
    value: rt.Optional(rt.Union(ValueEntry, rt.Array(ValueEntry))),
    search: rt.Optional(rt.String),
  }),
);

export const SavedReportsFilters = rt.Record({
  date: rt.Optional(FilterValue),
  company: rt.Optional(FilterValue),
  cancelled: rt.Optional(FilterValue),
  costOnly: rt.Optional(FilterValue),
  paymentMethod: rt.Optional(FilterValue),
});
