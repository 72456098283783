import type { FC } from "react";
import React, { useContext } from "react";
import { CurrentProfile } from "@equiem/lib";
import { Dropdown, Avatar, LocalePicker, useTheme } from "@equiem/react-admin-ui";
import { RiLogoutBoxRLine, RiUser3Line } from "@equiem/react-admin-ui/icons";
import { useTranslation } from "@equiem/localisation-eq1";
import { MyProfileModalContext } from "@equiem/settings";
import { onLogout } from "../../lib/auth/onLogout";

export const TopMenuDropdown: FC = () => {
  const { profile } = useContext(CurrentProfile);
  const modal = useContext(MyProfileModalContext);
  const { spacers } = useTheme();
  const { t } = useTranslation();

  return (
    <>
      <Dropdown.Container
        placement="bottom-end"
        trigger={
          <Avatar
            imageUrl={profile?.avatar}
            size="medium"
            firstName={profile?.firstName}
            lastName={profile?.lastName}
          />
        }
      >
        <div className="userMenuTop">
          <Avatar
            imageUrl={profile?.avatar}
            size="medium"
            firstName={profile?.firstName}
            lastName={profile?.lastName}
          />
          <h5>
            {profile?.firstName} {profile?.lastName}
          </h5>
        </div>
        <LocalePicker showDebugLanguages={process.env.debugLanguagesEnabled === "true"} />
        <Dropdown.Item icon={RiUser3Line} onClick={modal.open}>
          {t("home.manageAccount")}
        </Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Link href="/api/logout" icon={RiLogoutBoxRLine} onClick={onLogout}>
          {t("home.logOut")}
        </Dropdown.Link>
      </Dropdown.Container>
      <style jsx>{`
        .userMenuTop {
          padding: ${spacers.s0} ${spacers.s4} ${spacers.s4};
          display: flex;
          align-items: center;
          justify-content: left;
        }
        h5 {
          font-size: 12px;
          font-weight: bold;
          margin-left: ${spacers.s4};
          cursor: default;
          white-space: nowrap;
        }
      `}</style>
    </>
  );
};
