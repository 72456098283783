import React, { useCallback, useEffect, useMemo, useState } from "react";
import type { WidgetProps } from "@equiem/lib";
import { useShortcuts } from "@equiem/lib";
import type { ShortcutEvents } from "./lib/ShortcutEvents";
import { useTranslation } from "@equiem/localisation-eq1";
import { PostModal } from "./PostModal";
import { ModalContentProvider } from "./ContentProvider";
import type { EventPost } from "./new_event_post/Form";
import type { NewsPost } from "./new_news_post/Form";
import type { BuildingInfoPost } from "./building_info_post/Form";

interface ContentModalInfo {
  type: NewsPost["type"] | EventPost["type"] | BuildingInfoPost["type"];
  title: string;
  bodyTitle: string;
}

export const WidgetModal: React.FC<WidgetProps> = ({ currentRole }) => {
  const [modalDisplay, setModalDisplay] = useState<null | ContentModalInfo>(null);
  const { on } = useShortcuts<ShortcutEvents>();
  const { t } = useTranslation();

  useEffect(() => {
    on((shortcut) => {
      switch (shortcut) {
        case "createNewsPost":
          setModalDisplay({
            type: "news_post",
            title: t("home.widgets.newPost"),
            bodyTitle: t("home.widgets.postHint"),
          });
          break;
        case "createEventPost":
          setModalDisplay({
            type: "event_post",
            title: t("home.widgets.eventPost"),
            bodyTitle: t("home.widgets.eventHint"),
          });
          break;
        case "createBuildingInfoPost":
          setModalDisplay({
            type: "building_info_post",
            title: t("home.widgets.buildingInfoPost"),
            bodyTitle: t("home.widgets.buildingInfoPostHint"),
          });
          break;
        default:
          break;
      }
    });
    // We don't want the on to be included to avoid multiple triggers.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onHideCb = useCallback(() => {
    setModalDisplay(null);
  }, []);

  const post = useMemo<NewsPost | EventPost | BuildingInfoPost | null>(
    () => (modalDisplay == null ? null : { type: modalDisplay.type }),
    [modalDisplay],
  );

  return (
    <>
      <ModalContentProvider
        currentRole={currentRole}
        showModal={modalDisplay != null}
        title={modalDisplay?.title}
        bodyTitle={modalDisplay?.bodyTitle}
        onHideCb={onHideCb}
      >
        <PostModal post={post} />
      </ModalContentProvider>
    </>
  );
};
