import React, { useContext, useState, useMemo } from "react";
import { useTheme, EmptyState } from "@equiem/react-admin-ui";

import type { CmsArticle, CmsSearchFilters } from "../../generated/gateway-client";
import { useSearchArticlesQuery } from "../../generated/gateway-client";
import { CurrentPortfolio } from "../../contexts/PortfolioContext";
import { Menu } from "../shared/Menu";
import { ArticleCard } from "./ArticleCard";
import { ArticleSearch, defaultFilters } from "./filters/ArticleSearch";

export const Articles = () => {
  const { currentPortfolio } = useContext(CurrentPortfolio);
  const { spacers } = useTheme();

  const [filters, setFilters] = useState<CmsSearchFilters>(defaultFilters);
  const { data, loading } = useSearchArticlesQuery({
    variables: {
      portfolio: currentPortfolio?.uuid ?? "",
      filters,
      page: { first: 50 },
    },
    fetchPolicy: "network-only",
    skip: currentPortfolio == null,
  });

  const articles = useMemo(() => data?.searchCMSArticles.edges.map((edge) => edge.node) ?? [], [data]);

  return (
    <div className="page">
      <Menu />
      <ArticleSearch
        portfolioUuid={currentPortfolio?.uuid}
        portfolioSites={currentPortfolio?.portfolioSites ?? []}
        onFiltersChanged={setFilters}
      />
      {!loading && articles.length === 0 && (
        <div className="w-100">
          <EmptyState />
        </div>
      )}
      <div className="feed mx-7 mb-7 py-5">
        {articles
          .filter((a): a is CmsArticle => a != null)
          .map((a) => (
            <ArticleCard key={a.uuid} article={a} />
          ))}
      </div>
      <style jsx>{`
        .page {
          min-height: 100vh;
        }
        .feed {
          display: grid;
          grid-template-columns: repeat(auto-fit, minmax(272px, 1fr));
          gap: ${spacers.s3};
        }
      `}</style>
    </div>
  );
};
