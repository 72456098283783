import React, { useEffect, useMemo, useState } from "react";
import { useField } from "formik";

import { useSiteContext } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import { Form, ProgressCircle, Text, useTheme } from "@equiem/react-admin-ui";
import { RiErrorWarningLine, RiSearchLine } from "@equiem/react-admin-ui/icons";

import { useDestinationBuildingsLevelsListQuery } from "../generated/settings-client";

type DestinationLevels = {
  uuid: string;
  name: string;
};

type Destinations = {
  uuid: string;
  name: string;
  levels: DestinationLevels[];
};

interface Props {
  name: string;
}

export const LevelsList: React.FC<Props> = ({ name }) => {
  const [field, meta, helpers] = useField<string[] | undefined>(name);
  const { t } = useTranslation();
  const { colors, breakpoints } = useTheme();
  const site = useSiteContext();
  const [search, setSearch] = useState<string>("");
  const [destinations, setDestinations] = useState<Destinations[]>([]);
  const { data: buildings, loading } = useDestinationBuildingsLevelsListQuery({
    variables: {
      uuid: site.uuid,
    },
  });

  useEffect(() => {
    if (buildings?.destination.buildings != null) {
      const _destinations = buildings.destination.buildings.map((building) => ({
        uuid: building.uuid,
        name: building.name,
        levels: building.buildingLevels.map((level) => ({
          uuid: level.uuid,
          name: level.name,
        })),
      }));
      setDestinations(_destinations);
    }
  }, [buildings]);

  const filteredDestinations = useMemo(() => {
    if (search === "") {
      return destinations;
    }

    return destinations
      .filter((destination) => {
        const buildingMatch = destination.name.toLowerCase().includes(search.toLowerCase());
        const levelMatch = destination.levels.some((level) => level.name.toLowerCase().includes(search.toLowerCase()));

        return buildingMatch || levelMatch;
      })
      .map((destination) => {
        const buildingMatch = destination.name.toLowerCase().includes(search.toLowerCase());

        return {
          ...destination,
          levels: buildingMatch
            ? destination.levels
            : destination.levels.filter((level) => level.name.toLowerCase().includes(search.toLowerCase())),
        };
      });
  }, [destinations, search]);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const clearSearch = () => {
    setSearch("");
  };

  const handleSelect = (uuid: string) => {
    if (field.value == null) {
      helpers.setValue([uuid]).catch(console.error);
      return;
    }

    if (field.value.includes(uuid)) {
      helpers.setValue(field.value.filter((item) => item !== uuid)).catch(console.error);
      return;
    }

    helpers.setValue([...field.value, uuid]).catch(console.error);
  };

  const isError = meta.error != null && meta.touched;

  return (
    <>
      <div className="container">
        <div className="section search">
          <div className="title">
            <Text variant="label" weight="medium">
              {field.value != null && field.value.length > 0
                ? t("common.selected", { value: field.value.length })
                : t("settings.editApartment.selectAtLeastOne")}
            </Text>
            {isError && <RiErrorWarningLine size={16} color={colors.status.danger.primary} className="ml-2" />}
          </div>
          <Form.InputExtended
            className="search-input"
            icon={RiSearchLine}
            placeholder={t("settings.editApartment.findABuildingOrLevel")}
            variant="sm"
            value={search}
            onChange={handleSearch}
            onClear={clearSearch}
            clearable
          />
        </div>
        <div className="overflow">
          {filteredDestinations.map((destination) => (
            <div className="section" key={destination.uuid}>
              <Text variant="label" weight="medium" className="title">
                {destination.name}
              </Text>
              <div className="levels">
                {destination.levels.map((level) => (
                  <Form.Checkbox
                    key={level.uuid}
                    label={level.name}
                    checked={field.value?.includes(level.uuid)}
                    onChange={() => handleSelect(level.uuid)}
                  />
                ))}
              </div>
            </div>
          ))}
          {loading && (
            <div className="loading">
              <ProgressCircle size="md" />
            </div>
          )}
          {filteredDestinations.length === 0 && !loading && (
            <div className="empty-list">
              <div className="icon-container">
                <RiSearchLine size={24} color={colors.grayscale[40]} />
              </div>
              <Text variant="text" size="small">
                {search === ""
                  ? t("settings.editApartment.noBuildingsAtSite")
                  : t("settings.noResultsForSearch", { search })}
              </Text>
            </div>
          )}
        </div>
      </div>
      {isError && (
        <div className="error">
          <Text variant="text" size="small">
            {meta.error}
          </Text>
        </div>
      )}
      <style jsx>
        {`
          .container {
            display: flex;
            flex-direction: column;
            border: 1px solid ${isError ? colors.status.danger.primary : colors.transparent.black[10]};
            border-radius: 4px;
          }

          .loading {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100px;
          }

          .section {
            display: flex;
            flex-direction: column;
            padding: 1rem;
            gap: 1rem;
          }

          .overflow {
            max-height: 300px;
            overflow-y: auto;
          }

          .section.search {
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            padding: 0.5rem 0.5rem 0.5rem 1rem;
          }

          .section .title {
            color: ${colors.transparent.black[60]};
          }

          .section:not(:last-child) {
            border-bottom: 1px solid ${colors.transparent.black[10]};
          }

          .levels {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 1rem;
          }

          .empty-list {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            color: ${colors.grayscale[60]};
            padding: 64px 0;
          }

          .empty-list .icon-container {
            padding: 16px;
            background: ${colors.grayscale[5]};
            border-radius: 50%;
          }

          .error {
            color: ${colors.status.danger.primary};
          }

          @media (max-width: ${breakpoints.md}px) {
            .section.search {
              flex-direction: column;
              width: 100%;
              align-items: flex-start;
              padding: 0.75rem;
              gap: 0.75rem;
            }

            .section.search .title {
              padding-top: 0.25rem;
              padding-left: 0.25rem;
            }

            .section.search > :global(div) {
              width: 100%;
            }

            .levels {
              grid-template-columns: repeat(2, 1fr);
            }
          }
        `}
      </style>
    </>
  );
};
