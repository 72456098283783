import React from "react";
import { SiteLogo as Logo } from "@equiem/react-admin-ui";

import { useSiteContext } from "../context/SiteContext";

interface Props {
  className?: string;
  size?: string;
  logoSize?: string;
  otherSite?: {
    logo?: string | null;
    name: string;
    showLogoOnWhiteBackground: boolean;
    primaryColour: string;
  };
}

export const SiteLogo: React.FC<Props> = ({ className, size, logoSize, otherSite }) => {
  const thisSite = useSiteContext();
  const { logo, name, showLogoOnWhiteBackground, primaryColour } = otherSite ?? thisSite;

  return (
    <Logo
      className={className}
      logo={logo}
      name={name}
      size={size}
      logoSize={logoSize}
      showLogoOnWhiteBackground={showLogoOnWhiteBackground}
      primaryColour={primaryColour}
    />
  );
};
