import type { TFunction } from "@equiem/localisation-eq1";

import type { FilterType } from "../contexts/SegmentProvider";
import { SegmentationFilterItemType } from "../generated/gateway-client";

export const mapFilterTypeToFilter = (i: SegmentationFilterItemType): FilterType => {
  switch (i) {
    case SegmentationFilterItemType.Apartment:
      return "apartments";
    case SegmentationFilterItemType.Area:
      return "areas";
    case SegmentationFilterItemType.Building:
      return "buildings";
    case SegmentationFilterItemType.Level:
      return "levels";
    case SegmentationFilterItemType.Company:
      return "companies";
    case SegmentationFilterItemType.CompanyAttribute:
      return "companyAttributes";
    case SegmentationFilterItemType.Role:
      return "roles";
    case SegmentationFilterItemType.UserAttribute:
      return "userAttributes";
    case SegmentationFilterItemType.PermissionRole:
      return "permissionRoles";
    case SegmentationFilterItemType.FlexOperator:
      return "flexOperator";
    case SegmentationFilterItemType.FlexTenant:
      return "flexTenants";
    default:
      throw new Error(`Unmapped filter type: ${i}`);
  }
};

export const mapFilterToFilterType = (i: FilterType): SegmentationFilterItemType => {
  switch (i) {
    case "apartments":
      return SegmentationFilterItemType.Apartment;
    case "areas":
      return SegmentationFilterItemType.Area;
    case "buildings":
      return SegmentationFilterItemType.Building;
    case "levels":
      return SegmentationFilterItemType.Level;
    case "companies":
      return SegmentationFilterItemType.Company;
    case "companyAttributes":
      return SegmentationFilterItemType.CompanyAttribute;
    case "roles":
      return SegmentationFilterItemType.Role;
    case "userAttributes":
      return SegmentationFilterItemType.UserAttribute;
    case "permissionRoles":
      return SegmentationFilterItemType.PermissionRole;
    case "flexOperator":
      return SegmentationFilterItemType.FlexOperator;
    case "flexTenants":
      return SegmentationFilterItemType.FlexTenant;
    default:
      throw new Error(`Unmapped filter type: ${i}`);
  }
};

export const translateFilterType = (i: SegmentationFilterItemType | FilterType, t: TFunction): string => {
  switch (i) {
    case SegmentationFilterItemType.Apartment:
    case "apartments":
      return t("segmentation.filterType.apartments");
    case SegmentationFilterItemType.Area:
    case "areas":
      return t("segmentation.filterType.areas");
    case SegmentationFilterItemType.Building:
    case "buildings":
      return t("segmentation.filterType.buildings");
    case SegmentationFilterItemType.Level:
    case "levels":
      return t("segmentation.filterType.buildingLevels");
    case SegmentationFilterItemType.Company:
    case "companies":
      return t("segmentation.filterType.companies");
    case SegmentationFilterItemType.CompanyAttribute:
    case "companyAttributes":
      return t("segmentation.filterType.companyAttributes");
    case SegmentationFilterItemType.Role:
    case "roles":
      return t("segmentation.filterType.groups");
    case SegmentationFilterItemType.PermissionRole:
    case "permissionRoles":
      return t("segmentation.filterType.permissionRoles");
    case SegmentationFilterItemType.UserAttribute:
    case "userAttributes":
      return t("segmentation.filterType.userAttributes");
    case "userType":
      return t("segmentation.filterType.userType");
    case "flexOperator":
      return t("segmentation.filterType.flexOperator");
    case "flexTenants":
      return t("segmentation.filterType.flexTenants");
    default:
      throw new Error(`Unmapped filter type: ${i}`);
  }
};
