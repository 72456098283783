import type { ChangeEvent, KeyboardEvent } from "react";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { flip, offset, shift, size, useFloating } from "@floating-ui/react-dom";
import { FormComboBoxContext } from "./FormComboBoxContext";
import type { FormComboBoxItem } from "./FormComboBoxItem";
import { scrollIntoViewIfNeeded } from "../../../util/scrollIntoViewIfNeeded";
import { useFloatingHideListeners } from "../../../hooks/useFloatingHideListeners";
import { useTheme } from "../../../contexts/Theme";

// eslint-disable-next-line @typescript-eslint/no-type-alias
export type Props<T extends FormComboBoxItem> = {
  items: T[];
  onSelect?: (item: T) => void;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  children: (context: FormComboBoxContext) => React.ReactElement;
  defaultValue?: T;
  menuWidth?: "available" | "matchReference";
};

export const FormComboBox = <T extends FormComboBoxItem>({
  children,
  onSelect,
  items,
  onChange: onChangeInput,
  defaultValue,
  menuWidth = "available",
}: Props<T>) => {
  const [highlighted, setHighlighted] = useState<number>(0);
  const [focused, setFocused] = useState(false);
  const [value, setValue] = useState(defaultValue?.value ?? "");
  const { focusOutline } = useTheme();

  const floating = useFloating({
    placement: "bottom-start",
    middleware: [
      shift(),
      flip(),
      offset(parseInt(focusOutline.size, 10)),
      size({
        apply({ availableWidth, rects }) {
          if (floating.refs.floating.current != null) {
            const width = menuWidth === "available" ? availableWidth : rects.reference.width;
            floating.refs.floating.current.style.width = `${width}px`;
          }
        },
      }),
    ],
  });

  const internalRefs = useRef<HTMLElement[]>([]);

  useEffect(() => {
    internalRefs.current = internalRefs.current.slice(0, items.length);
    if (items.length > 0) {
      setHighlighted(0);
    }
  }, [items]);

  useFloatingHideListeners({
    onHide: setFocused.bind(null, false),
    floating,
  });

  const itemRefs = useMemo(() => {
    const refList: Array<(el: HTMLElement) => void> = [];
    for (let i = 0; i < items.length; i++) {
      refList.push((el) => {
        internalRefs.current[i] = el;
      });
    }

    return refList;
  }, [items]);

  const setSelected = useCallback(
    (selected: number) => {
      onSelect?.(items[selected]);
      setFocused(false);
      setValue(items[selected].value);
    },
    [onSelect, items, setValue, setFocused],
  );

  const clearValue = useCallback(() => {
    setValue("");
  }, [setValue]);

  const onKeyDown = useMemo(
    () => (e: KeyboardEvent) => {
      switch (e.key) {
        default:
          setFocused(true);
          return;
        case "Down":
        case "ArrowDown": {
          e.preventDefault();
          e.stopPropagation();
          if (!focused) {
            setHighlighted(0);
            setFocused(true);
            return;
          }

          const next = Math.min(items.length - 1, highlighted + 1);
          setHighlighted(next);
          scrollIntoViewIfNeeded(internalRefs.current[next]);
          break;
        }
        case "Up":
        case "ArrowUp": {
          e.preventDefault();
          e.stopPropagation();
          if (!focused) {
            return;
          }

          const prev = Math.max(0, highlighted - 1);
          setHighlighted(prev);
          scrollIntoViewIfNeeded(internalRefs.current[prev]);
          break;
        }
        case "Enter":
          e.preventDefault();
          e.stopPropagation();
          setSelected(highlighted);
          break;
        case "Tab":
          break;
      }
    },
    [items, highlighted, setHighlighted, setSelected, internalRefs, focused],
  );

  const onFocus = () => {
    setFocused(true);
  };

  const onChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setValue(e.target.value);
      onChangeInput?.(e);
    },
    [setValue, onChangeInput],
  );

  return (
    <>
      <FormComboBoxContext.Provider
        value={{
          highlighted,
          setHighlighted,
          setSelected,
          items,
          inputProps: {
            onKeyDown,
            onFocus,
            onChange,
            value,
            role: "combobox",
          },
          itemRefs,
          focused,
          setFocused,
          floating,
          clearValue,
        }}
      >
        <FormComboBoxContext.Consumer>{children}</FormComboBoxContext.Consumer>
      </FormComboBoxContext.Provider>
    </>
  );
};
