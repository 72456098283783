import { Array, Literal, Number, Record, String } from "runtypes";

export const resources = Record({
  change: Record({
    field: Literal("resources"),
    fromValues: Array(
      Record({ bookableResource: Record({ uuid: String, name: String, __typename: Literal("BookableResource") }) }),
    ),
    toValues: Array(
      Record({ bookableResource: Record({ uuid: String, name: String, __typename: Literal("BookableResource") }) }),
    ),
  }),
});

export const percentage = Record({
  change: Record({
    field: Literal("percentage"),
    from: Record({ integer: Number }),
    to: Record({ integer: Number }),
  }),
});

export const discountCreated = Record({
  change: Record({
    field: Literal("bookingDiscountCreated"),
  }),
});

export const discountDeleted = Record({
  change: Record({
    field: Literal("bookingDiscountDeleted"),
  }),
});
