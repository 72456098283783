import { Skeleton, Toggler } from "@equiem/react-admin-ui";
import React from "react";

export const SegmentSkeleton: React.FC = () => (
  <>
    <Toggler
      className="mb-2"
      heading={<Skeleton.Line width="140px" />}
      subheading={
        <>
          <Skeleton.Line width="100px" className="my-1" />
        </>
      }
    ></Toggler>
  </>
);
