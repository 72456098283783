/* eslint-disable @typescript-eslint/no-type-alias */
import React, { useState } from "react";
import { RiSendPlane2Line, type IconType } from "../../admin-icons";
import { AdminButton as Button } from "../Button/AdminButton";
import { Text } from "../Text/Text";
import * as Form from "../Form";
import * as Dropdown from "../Dropdown/admin";
import { useTheme } from "../../contexts";
import { useTranslation } from "@equiem/localisation-eq1";

export type ReceiverOption = {
  id: string;
  label: string;
  icon: IconType;
};

interface Props {
  receivers?: ReceiverOption[];
  onSubmit: (message: string, receiverId?: string) => void;
}

export const CreateActivity: React.FC<Props> = ({ receivers, onSubmit }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [selectedOption, setSelectedOption] = useState(receivers?.[0]);
  const [message, setMessage] = useState("");
  const [invalidMessage, setInvalidMessage] = useState(false);

  const handleSelect = (id: string) => (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault();
    setSelectedOption(receivers?.find((option) => option.id === id));
    setInvalidMessage(false);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setMessage(e.target.value);
    setInvalidMessage(false);
  };

  const sendMessage = () => {
    if (message.trim().length < 1) {
      setInvalidMessage(true);
      return;
    }

    onSubmit(message, selectedOption?.id);
    setMessage("");
    setInvalidMessage(false);
  };

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    sendMessage();
  };

  const handleKey = (e: React.KeyboardEvent<HTMLInputElement>) => {
    // cmd+enter does not send message
    if (e.key === "Enter" && !e.metaKey && !e.ctrlKey) {
      e.preventDefault();
      sendMessage();
    }
  };

  return (
    <>
      <div className="form">
        {receivers != null && receivers.length > 0 && (
          <div className="to">
            <Text variant="label" color={theme.colors.grayscale["40"]}>
              {t("common.toLabel")}
            </Text>
            <Dropdown.Button variant="ghost" title={selectedOption?.label} className="audience">
              {receivers.map(({ icon: Icon, label, id }) => (
                <Dropdown.Item key={id} onClick={handleSelect(id)}>
                  <Icon color={theme.colors.primary} size="16" /> {label}
                </Dropdown.Item>
              ))}
            </Dropdown.Button>
          </div>
        )}
        <div className="message">
          <Form.Group error={invalidMessage ? t("common.messageEmpty") : null}>
            <Form.Input
              showChrome={false}
              placeholder={t("settings.history.writeMessage") as string}
              value={message}
              onChange={handleChange}
              onKeyDown={handleKey}
              autoComplete="off"
            />
          </Form.Group>
          <div>
            <Button variant="primary" shape="round" disabled={message.trim().length === 0} onClick={handleSubmit}>
              <RiSendPlane2Line />
            </Button>
          </div>
        </div>
      </div>
      <style jsx>{`
        .form {
          border-top: 1px solid ${theme.colors.border};
          padding: 1.5rem;
        }
        .to {
          display: flex;
          align-items: center;
          gap: 0.5rem;
        }
        .message {
          display: flex;
          gap: 0.5rem;
          padding-top: 0.5rem;
        }
        .message :global(input) {
          padding: 0;
        }
      `}</style>
    </>
  );
};
