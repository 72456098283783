import React, { useCallback, useState } from "react";
import { Field } from "formik";

import { useSaferFormikContext } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import { Button, Form as EqForm, Table, useTheme } from "@equiem/react-admin-ui";
import { RiAddLine } from "@equiem/react-admin-ui/icons";

import type { VisitorAppointmentType } from "../../../../generated/visitors-client";
import type { GeneralSettingsFormValues, VisitorTypeFormValue } from "../GeneralSettingsValues";

import { AddingVisitorType } from "./AddingVisitorType";
import { ChangeDefaultModal } from "./ChangeDefaultModal";
import { EditingTypeForm } from "./EditingVisitorType";
import { PreviewVisitorType } from "./PreviewVisitorType";

interface Props {
  isAddingType: boolean;
  setIsAddingType: (value: boolean) => void;
}

export const VisitorTypesTable = ({ isAddingType, setIsAddingType }: Props) => {
  const { t } = useTranslation();
  const { values, setValues, setFieldValue } = useSaferFormikContext<GeneralSettingsFormValues>();
  const { colors } = useTheme();
  const [showModal, setShowModal] = useState(false);
  const [asDefault, setAsDefault] = useState<VisitorTypeFormValue | null>(null);

  const onEditSubmit = useCallback(
    (
      value: { name: string; appointmentTypes: VisitorAppointmentType[] | null | undefined },
      visitorType: VisitorTypeFormValue,
    ) => {
      setValues({
        ...values,
        visitorTypes: values.visitorTypes.map((item) =>
          item === visitorType ? { ...item, ...value, isEditing: false } : item,
        ),
      }).catch(console.error);
    },
    [values, setValues],
  );

  const onCloseVisitorTypeEditing = useCallback(
    (visitorType: VisitorTypeFormValue) => {
      const changedVisitorTypes = values.visitorTypes.map((item) =>
        item === visitorType ? { ...item, isEditing: false } : item,
      );

      setFieldValue("visitorTypes", changedVisitorTypes).catch(console.error);
    },
    [values, setFieldValue],
  );

  const onSaveAsDefault = useCallback(() => {
    setValues({
      ...values,
      visitorTypes: values.visitorTypes.map((item) => ({ ...item, isDefault: item === asDefault })),
    }).catch(console.error);
    setAsDefault(null);
    setShowModal(false);
  }, [values, setValues, asDefault, setAsDefault, setShowModal]);

  const onSaveAddVisitorType = useCallback(() => {
    setValues({
      ...values,
      visitorTypes: [
        ...values.visitorTypes,
        {
          isEditing: false,
          isDefault: false,
          name: values.newVisitorType,
          appointmentTypes: values.newVisitorAppointmentType as VisitorAppointmentType[],
        },
      ],
      newVisitorType: "",
      newVisitorAppointmentType: [],
    }).catch(console.error);
    setIsAddingType(false);
  }, [values, setValues, setIsAddingType]);

  const onCloseAddVisitorType = useCallback(() => {
    setIsAddingType(false);
    setValues({
      ...values,
      newVisitorType: "",
      newVisitorAppointmentType: [],
    }).catch(console.error);
  }, [values, setValues]);

  return (
    <>
      <div className="w-100">
        <EqForm.Group
          label={t("visitors.settings.visitorTypes")}
          showTooltip
          tooltipText={t("visitors.settings.visitorTypesTooltip")}
        >
          <div className="enable-visitors">
            <Field type="checkbox" id="isVisitorTypesEnabled" name="isVisitorTypesEnabled" />
            <label htmlFor="isVisitorTypesEnabled">{t("visitors.settings.enableVisitorTypes")}</label>
          </div>
        </EqForm.Group>
        {values.isVisitorTypesEnabled && (
          <>
            <Table.Table className="w-100">
              <tbody>
                {values.visitorTypes.map((visitorType, index) => {
                  return (
                    <>
                      <tr key={`${visitorType.name}-${index}`} className="visitor-type">
                        {visitorType.isEditing ? (
                          <td colSpan={2}>
                            <EditingTypeForm
                              visitorType={visitorType}
                              onEditSubmit={onEditSubmit}
                              onClose={() => onCloseVisitorTypeEditing(visitorType)}
                            />
                          </td>
                        ) : (
                          <PreviewVisitorType
                            visitorType={visitorType}
                            onShowModal={() => {
                              setShowModal(true);
                              setAsDefault(visitorType);
                            }}
                          />
                        )}
                      </tr>
                    </>
                  );
                })}

                {isAddingType && (
                  <tr>
                    <td colSpan={2}>
                      <AddingVisitorType onSave={onSaveAddVisitorType} onClose={onCloseAddVisitorType} />
                    </td>
                  </tr>
                )}
              </tbody>
            </Table.Table>
            <Button variant="secondary" disabled={isAddingType} onClick={() => setIsAddingType(true)} className="mt-4">
              <RiAddLine size={16} /> {t("visitors.settings.addType")}
            </Button>
          </>
        )}
        <ChangeDefaultModal
          showModal={showModal}
          asDefault={asDefault}
          onClose={() => {
            setShowModal(false);
          }}
          onAgree={onSaveAsDefault}
        />
      </div>
      <style jsx>{`
        .visitor-type:hover {
          background-color: ${colors.grayscale[3]};
        }

        .visitor-type:hover :global(.appointment-type) {
          display: none;
        }

        .visitor-type:hover :global(.controls) {
          display: flex;
        }

        .visitor-type {
          height: 50px;
        }

        .visitor-type :global(.controls) {
          display: none;
        }

        .visitor-type:hover :global(.appointment-type-icons) {
          padding: 8px 16px;
        }

        .visitor-type:hover :global(.lock-icon) {
          padding: 16px;
        }

        .enable-visitors {
          display: flex;
          align-items: center;
          gap: 8px;
        }
      `}</style>
    </>
  );
};
