import React from "react";

import { RiStickyNoteLine } from "@equiem/react-admin-ui/icons";

interface Props {
  counter: number;
  newNotes: boolean;
}

export const NotesCounter: React.FC<Props> = ({ counter, newNotes }) => {
  const newNotesBg = `linear-gradient(
    277.52deg,
    #ff794c -65.2%,
    rgba(225, 85, 162, 0.37) 76.96%,
    rgba(225, 85, 162, 0) 131.25%
  ),
  linear-gradient(119.58deg, rgba(0, 0, 170, 0.9) -22.08%, #c931ff 231.23%), #ffffff`;

  return (
    <div className="root">
      <div className="counter">{counter}</div>
      <RiStickyNoteLine size={16} />
      <style jsx>
        {`
          .root {
            position: relative;
            color: #666666;
          }
          .counter {
            top: -8px;
            left: 13px;
            padding: 2px 4px;
            position: absolute;
            width: 16px;
            height: 16px;
            background: ${newNotes ? newNotesBg : "#333333"};
            border-radius: 50%;
            color: #ffffff;
            font-size: 10px;
            line-height: 12px;
            text-align: center;
            text-transform: uppercase;
          }
        `}
      </style>
    </div>
  );
};
