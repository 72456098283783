import * as yup from "yup";

import type { TFunction } from "@equiem/localisation-eq1";

export const getValidationSchema = (t: TFunction) =>
  yup.object().shape({
    firstName: yup
      .string()
      .required(t("settings.editProfile.firstNameRequired"))
      .max(60, t("settings.editProfile.charactersMaximum")),
    lastName: yup
      .string()
      .required(t("settings.editProfile.lastNameRequired"))
      .max(60, t("settings.editProfile.charactersMaximum")),
    status: yup.string().required(),
    email: yup
      .string()
      .email(t("settings.editProfile.emailMustBeValid"))
      .required(t("settings.editProfile.emailRequired")),
    userType: yup.string().required(),
    company: yup.string().required(),
    groups: yup.array().of(yup.string()),
    attributes: yup.array().of(yup.string()),
    levels: yup.array().of(yup.string()).min(1, t("settings.levelsRequired")),
    flexTenantUuids: yup.array().of(yup.string()),
  });
