import React from "react";

export const RolesIcon = () => {
  return (
    <>
      <div className="shimmer"></div>
      <style jsx>{`
        div {
          width: 164px;
          height: 32px;
          border-radius: 4px;
        }
      `}</style>
    </>
  );
};
