import React, { useCallback, useEffect } from "react";
import { Field } from "formik";

import { useSaferFormikContext } from "@equiem/lib";
import type { FormValues } from "@equiem/lib/context/types";
import { useTranslation } from "@equiem/localisation-eq1";
import { Button, Form as EqForm, Modal, Text, useConfirmer, useTheme } from "@equiem/react-admin-ui";

import { useWarningLimitReachVisitorsAlert } from "../../../../hooks/useWarningLimitReachVisitorsAlert";

import type { BulkUploadVisitor } from "./BulkUploadVisitorsContext";
import { useBulkUploadVisitorsContext, VisitorBulkUploadStep } from "./BulkUploadVisitorsContext";
import { BulkUploadVisitorsFileUploader } from "./BulkUploadVisitorsFileUploader";
import { BulkUploadVisitorsTable } from "./BulkUploadVisitorsTable";

export const BulkUploadVisitorsView: React.FC = () => {
  const { close, submit, visitors, parseCsvString, setUploadVia, uploadVia, step, setStep } =
    useBulkUploadVisitorsContext();
  const { values: formValues, setFieldValue: setOuterFieldValue } = useSaferFormikContext<FormValues>();
  const { colors } = useTheme();
  const { withConfirmation } = useConfirmer();
  const { t } = useTranslation();
  const { isDisabled } = useWarningLimitReachVisitorsAlert<BulkUploadVisitor>(visitors);

  const handleSaveModal = useCallback(() => {
    setOuterFieldValue("visitors", [...formValues.visitors, ...visitors]).catch(console.error);
    submit();
  }, [visitors, formValues, setOuterFieldValue, submit]);

  useEffect(() => {
    if (visitors.length === 0) {
      setUploadVia(undefined);
      setStep(VisitorBulkUploadStep.Select);
    }
  }, [visitors, setStep, setUploadVia]);

  const confirm = useCallback(
    (count: number) => {
      withConfirmation({
        title: t("common.areYouSure"),
        message: t("visitors.appointments.visitorsBulkUploadConfirmation", { count }),
        confirmButtonText: t("common.yes"),
        confirmButtonVariant: "primary",
        onConfirm: () => handleSaveModal(),
      })();
    },
    [handleSaveModal, t, withConfirmation],
  );

  const downloadCsv = () => {
    const file = new File(["FirstName,LastName,Company,Email"], "example.csv", {
      type: "text/plain",
    });
    const objectUrl = URL.createObjectURL(file);

    const link = document.createElement("a");
    link.download = "example.csv";
    link.href = objectUrl;

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      <Modal.Body noPadding={step === VisitorBulkUploadStep.Edit}>
        {step === VisitorBulkUploadStep.Select ? (
          <>
            <div className="pt-4">
              <EqForm.Group label={t("visitors.csv.uploadCsvFile")}>
                <Field as={BulkUploadVisitorsFileUploader} name="upload"></Field>
                <Text
                  variant="text"
                  size="extra-small"
                  style={{
                    color: colors.grayscale[60],
                  }}
                >
                  {t("visitors.csv.downloadTemplateInfoLine1")}{" "}
                  <a className="download-link" onClick={downloadCsv}>
                    {t("visitors.csv.downloadTemplateInfoLine2")}
                  </a>
                </Text>
              </EqForm.Group>
            </div>
            <div className="custom-liner-container">
              <hr className="custom-liner" />
            </div>
            <div>
              <EqForm.Group
                label={t("visitors.csv.copyAndPasteColumnsHere")}
                showTooltip={true}
                tooltipText={t("visitors.csv.copyAndPasteInfoTooltip")}
              >
                <Field
                  as={EqForm.Textarea}
                  style={{
                    minHeight: "90px",
                  }}
                  name="uploadStr"
                  disabled={uploadVia === "File"}
                  variant="lg"
                  placeholder={t("visitors.csv.copyAndPasteInfoLine1")}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => parseCsvString(e.target.value)}
                />
              </EqForm.Group>
            </div>
          </>
        ) : null}
        {step === VisitorBulkUploadStep.Edit ? <BulkUploadVisitorsTable></BulkUploadVisitorsTable> : null}
        <Field type="hidden" name="steps" />
      </Modal.Body>
      <Modal.Footer>
        <Button type="button" className="mx-4" variant="ghost" onClick={close}>
          {t("common.cancel")}
        </Button>
        {step === VisitorBulkUploadStep.Edit ? (
          <Button
            disabled={
              visitors.filter(
                (x) =>
                  x.emailError != null ||
                  x.firstNameError != null ||
                  x.lastNameError != null ||
                  x.companyNameError != null,
              ).length > 0 || isDisabled
            }
            type="button"
            onClick={() => {
              confirm(visitors.length);
            }}
          >
            {t("visitors.appointmentForm.addVisitors")}
          </Button>
        ) : (
          <Button
            type="button"
            variant="primary"
            disabled={visitors.length === 0 || isDisabled}
            onClick={() => setStep(VisitorBulkUploadStep.Edit)}
          >
            {t("common.upload")}
          </Button>
        )}
      </Modal.Footer>
      <style jsx>
        {`
          .custom-header {
            height: 80px;
          }
          .custom-liner {
            border-top: 1px solid rgba(0, 0, 0, 0.1);
          }
          .custom-liner-container {
            padding-top: 0.1px;
            padding-bottom: 20px;
          }
          .title {
            font-weight: bold;
          }
          .download-link {
            text-decoration: underline;
            cursor: pointer;
          }
        `}
      </style>
    </>
  );
};
