import React, { useCallback, useEffect, useRef } from "react";

interface Props {
  onBottomVisible: () => void;
  children: React.ReactNode;
}

export const ScrollBottomObserverWrapper: React.FC<Props> = ({ onBottomVisible, children }) => {
  const bottomObserverRef = useRef<HTMLDivElement>(null);

  const handleIntersection = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      const [entry] = entries;
      if (entry.isIntersecting) {
        onBottomVisible();
      }
    },
    [onBottomVisible],
  );

  useEffect(() => {
    const observer = new IntersectionObserver(handleIntersection, {
      root: null,
      rootMargin: "0px",
      threshold: 1,
    });

    const currentElement = bottomObserverRef.current;
    if (currentElement != null) {
      observer.observe(currentElement);
    }

    return () => {
      if (currentElement != null) {
        observer.unobserve(currentElement);
      }
    };
  }, [handleIntersection]);

  return (
    <>
      {children}
      <div ref={bottomObserverRef} />
    </>
  );
};
