import { useApolloErrorTranslation, useTranslation } from "@equiem/localisation-eq1";
import { Material, Tag, Text, useTheme } from "@equiem/react-admin-ui";
import { useFormikContext } from "formik";
import React from "react";
import { ResourceDivider } from "../ResourceDivider";
import type { BookableResourceFeatureType } from "../../../../../generated/gateway-client";
import { useBookableResourceFeaturesQuery } from "../../../../../generated/gateway-client";
import type { FormValues } from "../../../../../lib/formValidation";
import { resourceFeatureGroupToString, resourceFeatureToString } from "../../../../../lib/resourceTypeToString";

interface Props {
  featureType: BookableResourceFeatureType;
}

export const ResourceCreateAndEditFormPreviewFeatures: React.FC<Props> = ({ featureType }) => {
  const { t } = useTranslation();
  const { tError } = useApolloErrorTranslation();
  const { spacers } = useTheme(true);

  const { values } = useFormikContext<FormValues>();

  const { data, loading, error } = useBookableResourceFeaturesQuery({
    variables: { siteUuid: values.site },
    fetchPolicy: "cache-and-network",
  });
  const selectedFeatures = (data?.bookableResourceFeatures ?? []).filter(
    (f) => f.type === featureType && values.features?.includes(f.uuid),
  );

  if (error != null) {
    return <div>{tError(error)}</div>;
  }

  if (loading || selectedFeatures.length === 0) {
    return <></>;
  }

  return (
    <div className="features-cont">
      <ResourceDivider />

      <Text variant="heading" size="small" className="mb-3">
        {resourceFeatureGroupToString(featureType, t)}
      </Text>

      <div className="features-tags">
        {selectedFeatures.map((feature) => (
          <Tag key={feature.uuid} variant="default">
            {feature.iconName != null && <Material.Icon className="mr-3" size="sm" name={feature.iconName} />}
            {resourceFeatureToString(feature.name, t)}
          </Tag>
        ))}
      </div>

      <style jsx>{`
        .features-tags {
          display: flex;
          flex-wrap: wrap;
          gap: ${spacers.s5};
        }
        .features-tags :global(.tag) {
          padding: ${spacers.s1} ${spacers.s4};
          font-size: 15px;
          line-height: 1.5em;
          text-transform: none;
          border-radius: 20px;
          color: #212529;
        }
        .features-cont :global(hr) {
          margin: ${spacers.s0};
          margin-bottom: ${spacers.s6};
        }
      `}</style>
    </div>
  );
};
