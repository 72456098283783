declare const newrelic:
  | undefined
  | {
      noticeError: (_error: unknown) => void;
    };

export const noticeError = (error: unknown) => {
  if (typeof newrelic !== "undefined") {
    newrelic.noticeError(error);
  }
};
