import { useMemo } from "react";

import { useTranslation } from "@equiem/localisation-eq1";
import type { FilterItem } from "@equiem/react-admin-ui";
import { FilterOptionsModifier } from "@equiem/react-admin-ui";
import { RiTeamLine } from "@equiem/react-admin-ui/icons";

interface Company {
  uuid: string;
  name: string;
}
export function useRequestFilterCompany(companies?: Company[]) {
  const { t } = useTranslation();

  const options = useMemo(
    () =>
      (companies ?? [])
        .map((rc) => ({
          label: rc.name,
          value: rc.uuid,
        }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    [companies],
  );

  const filter: FilterItem = {
    title: t("common.company"),
    type: "options",
    options,
    icon: RiTeamLine,
    modifiers: [FilterOptionsModifier.includes],
  };

  return {
    filter: filter.options.length > 0 ? filter : null,
  };
}
