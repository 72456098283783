import type { FC } from "react";
import React, { useContext } from "react";
import {
  BillingCustomerFragmentFragmentDoc,
  FlexTenantStatus,
  useSaveFlexTenantBillingCustomerMutation,
  useUpdateFlexTenantDefaultWorkplaceManagersMutation,
  type BillingCustomerInput,
  type FlexTenantFragmentFragment,
} from "../../generated/gateway-client";
import type { FormikHelpers } from "formik";
import { Field, FieldArray, Form, Formik, getIn } from "formik";
import { Button, Form as EqForm, Modal, ProgressCircle, Text } from "@equiem/react-admin-ui";
import { useTranslation } from "@equiem/localisation-eq1";
import { SideModalContext } from "../../contexts/SideModalContext";
import * as yup from "yup";
import { billingCustomerValidation } from "../lib/billingCustomerValidation";
import { RiAddLine } from "@equiem/react-admin-ui/icons";

interface Props {
  flexTenant: FlexTenantFragmentFragment;
  readonly?: boolean;
}

export const EditCompanyForm: FC<Props> = ({ flexTenant, readonly = false }) => {
  const { t } = useTranslation();
  const { closeModal } = useContext(SideModalContext);
  const [mutation] = useSaveFlexTenantBillingCustomerMutation();
  const [wmMutation] = useUpdateFlexTenantDefaultWorkplaceManagersMutation();

  const canEdit = flexTenant.status !== FlexTenantStatus.Expired && !readonly;
  const canCreate = flexTenant.status === FlexTenantStatus.PreMoveIn && !readonly;

  const validationSchema = () =>
    yup.object().shape({
      ...billingCustomerValidation(t),
      defaultWorkplaceManagerEmails: yup
        .array()
        .of(yup.string().email(t("common.invalidEmailAddress")))
        .required(t("common.validation.required", { path: t("flex.members.form.workplaceManager") })),
    });

  const handleSubmit = async (
    values: BillingCustomerInput & { defaultWorkplaceManagerEmails: string[] },
    { setSubmitting }: FormikHelpers<BillingCustomerInput & { defaultWorkplaceManagerEmails: string[] }>,
  ): Promise<boolean> => {
    setSubmitting(true);
    const { defaultWorkplaceManagerEmails, ...input } = values;

    await mutation({
      variables: {
        input,
      },
      update: (cache, result) => {
        if (flexTenant.billingCustomer != null) {
          cache.writeFragment({
            id: `BillingCustomer:${flexTenant.billingCustomer.id}`,
            fragment: BillingCustomerFragmentFragmentDoc,
            fragmentName: "BillingCustomerFragment",
            data: {
              ...result.data?.createOrUpdateFlexTenantBillingCustomer,
            },
          });
        }
      },
    });

    await wmMutation({
      variables: {
        input: {
          flexTenantUuid: flexTenant.uuid,
          defaultWorkplaceManagerEmails,
        },
      },
    });

    setSubmitting(false);
    closeModal();
    return true;
  };

  const initialValues: BillingCustomerInput & { defaultWorkplaceManagerEmails: string[] } = {
    flexTenantUuid: flexTenant.uuid,
    address: {
      line1: flexTenant.billingCustomer?.address?.line1 ?? "",
      postalCode: flexTenant.billingCustomer?.address?.postalCode ?? "",
      city: flexTenant.billingCustomer?.address?.city ?? "",
      state: flexTenant.billingCustomer?.address?.state ?? "",
      country: flexTenant.billingCustomer?.address?.country ?? "",
    },
    email: flexTenant.billingCustomer?.email ?? "",
    contactName: flexTenant.billingCustomer?.contactName ?? "",
    defaultWorkplaceManagerEmails: flexTenant.defaultWorkplaceManagerEmails,
  };

  return (
    <Formik<BillingCustomerInput & { defaultWorkplaceManagerEmails: string[] }>
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, submitForm, isValid, errors, dirty, values, touched }) => (
        <>
          <Modal.Body>
            <Form>
              <Text variant="heading" size="small" weight="bold" className="mb-2">
                {t("flex.members.form.contactInformation")}
              </Text>
              <EqForm.Group label={t("flex.members.form.workplaceManager")} required>
                <FieldArray
                  name="defaultWorkplaceManagerEmails"
                  render={(arrayHelpers) => (
                    <>
                      {values.defaultWorkplaceManagerEmails.map((email, i) => (
                        <EqForm.Group
                          error={(() => {
                            const isTouched = getIn(touched, `defaultWorkplaceManagerEmails[${i}]`) as boolean;
                            const err = getIn(errors, `defaultWorkplaceManagerEmails[${i}]`) as string;
                            return isTouched && err != null ? err : null;
                          })()}
                          key={i}
                        >
                          <Field
                            type="email"
                            name={`defaultWorkplaceManagerEmails[${i}]`}
                            as={EqForm.Input}
                            placeholder={t("common.email")}
                            readOnly={!canEdit || flexTenant.defaultWorkplaceManagerEmails.find((e) => e === email)}
                          />
                        </EqForm.Group>
                      ))}
                      {(canEdit || canCreate) && (
                        <Button
                          variant="secondary"
                          disabled={errors.defaultWorkplaceManagerEmails != null}
                          className="w-100 add-wm"
                          onClick={() => arrayHelpers.push(null)}
                        >
                          <RiAddLine /> {t("common.addAnother")}
                        </Button>
                      )}
                    </>
                  )}
                />
              </EqForm.Group>
              <Text variant="heading" size="small" weight="bold" className="mb-2">
                {t("flex.members.form.address")}
              </Text>
              <EqForm.Group error={errors.address?.line1} label={t("flex.members.form.address")} required={canCreate}>
                <Field type="text" readOnly={!canCreate && !canEdit} name="address.line1" as={EqForm.Input} />
              </EqForm.Group>
              <div className="d-flex">
                <EqForm.Group
                  error={errors.address?.postalCode}
                  className="mr-4"
                  label={t("flex.members.form.postalCode")}
                  required={canCreate || canEdit}
                >
                  <Field type="text" readOnly={!canCreate && !canEdit} name="address.postalCode" as={EqForm.Input} />
                </EqForm.Group>
                <EqForm.Group
                  error={errors.address?.city}
                  label={t("flex.members.form.city")}
                  required={canCreate || canEdit}
                >
                  <Field type="text" readOnly={!canCreate && !canEdit} name="address.city" as={EqForm.Input} />
                </EqForm.Group>
              </div>
              <div className="d-flex">
                <EqForm.Group className="mr-4" error={errors.address?.state} label={t("flex.members.form.state")}>
                  <Field type="text" readOnly={!canCreate && !canEdit} name="address.state" as={EqForm.Input} />
                </EqForm.Group>
                <EqForm.Group error={errors.address?.country} label={t("flex.members.form.country")} required>
                  <Field type="text" name="address.country" as={EqForm.Input} />
                </EqForm.Group>
              </div>
              <Text variant="heading" size="small" weight="bold" className="mb-2">
                {t("flex.members.form.billingInformation")}
              </Text>
              <EqForm.Group
                error={errors.email}
                label={t("flex.members.form.contactEmail")}
                required={canCreate || canEdit}
              >
                <Field type="email" readOnly={!canCreate && !canEdit} name="email" as={EqForm.Input} />
              </EqForm.Group>
              <EqForm.Group
                error={errors.contactName}
                label={t("flex.members.form.companyBusinessName")}
                required={canCreate || canEdit}
              >
                <Field type="text" readOnly={!canCreate && !canEdit} name="contactName" as={EqForm.Input} />
              </EqForm.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={closeModal} variant="ghost">
              {t("common.cancel")}
            </Button>
            <Button
              className="ml-2"
              disabled={isSubmitting || !isValid || !dirty}
              onClick={() => {
                void submitForm();
              }}
            >
              {isSubmitting && <ProgressCircle size="xs" />} {t("common.saveChanges")}
            </Button>
          </Modal.Footer>
        </>
      )}
    </Formik>
  );
};
