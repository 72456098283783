import { type Static, Array, Optional, Record, String, Union } from "runtypes";

const ValueEntry = Union(String, Record({ label: String, value: String }));

const FilterValue = Union(
  Record({
    type: String,
    modifier: String,
    value: Optional(Union(ValueEntry, Array(ValueEntry))),
    search: Optional(String),
  }),
);

export const SavedResourceFilters = Record({
  siteUuid: Optional(FilterValue),
  buildingUuid: Optional(FilterValue),
  levelUuid: Optional(FilterValue),
  ownerCompanyUuid: Optional(FilterValue),
  resourceType: Optional(FilterValue),
  resourceFeatures: Optional(FilterValue),
  resourceSharedFacilities: Optional(FilterValue),
  resourceName: Optional(FilterValue),
  date: Optional(FilterValue),
  startTime: Optional(FilterValue),
  durationMinutes: Optional(FilterValue),
  showPaidResources: Optional(FilterValue),
  capacity: Optional(FilterValue),
  managedBy: Optional(FilterValue),
});
export type SavedResourceFilters = Static<typeof SavedResourceFilters>;
