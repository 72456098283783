import type { PropsWithChildren } from "react";
import React, { useContext } from "react";
import { useStickyElement, useTheme } from "@equiem/react-admin-ui";
import { MainContainer } from "./MainContainer";
import { GradientContext } from "./GradientContext";

interface P {
  sticky?: boolean;
  className?: string;
  border?: boolean;
  topPadding?: boolean;
  btmPadding?: boolean;
  minHeight?: boolean;
  alignItems?: "center" | "flex-start" | "flex-end";
}

export const SubTopMenu: React.FC<PropsWithChildren<P>> = ({
  children,
  className = "",
  sticky = false,
  border = true,
  topPadding = true,
  btmPadding = true,
  alignItems = "center",
  minHeight = true,
}) => {
  const { colors, spacers } = useTheme();
  const { showGradient } = useContext(GradientContext);
  const { ref, top, isStuck } = useStickyElement<HTMLDivElement>({ enabled: sticky });

  return (
    <MainContainer
      ref={ref}
      style={{
        borderBottom: showGradient || !border ? undefined : `1px solid ${colors.border}`,
        background: showGradient ? undefined : colors.white,
        color: !showGradient ? colors.dark : colors.white,
        minHeight: minHeight ? spacers.s10 : undefined,
        alignItems,
        ...(sticky
          ? {
              position: "sticky",
              top,
              zIndex: 10,
            }
          : undefined),
      }}
      className={`${isStuck ? "stuck" : ""} d-flex justify-content-between ${topPadding ? " pt-4" : ""}${
        btmPadding ? " pb-4" : ""
      } ${className}`}
    >
      {children}
    </MainContainer>
  );
};
