import { useTranslation } from "@equiem/localisation-eq1";
import { Form } from "@equiem/react-admin-ui";
import React from "react";

const TOTHEMAX = 999;

interface P {
  className?: string;
  max?: number | null;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}
export const AddOnQuantity: React.FC<P> = ({ onChange, value, max, className = "" }) => {
  const { t } = useTranslation();
  return (
    <Form.Input
      type="number"
      min={1}
      max={max ?? TOTHEMAX}
      className={className}
      placeholder={t("common.quantity") ?? ""}
      value={value}
      onChange={onChange}
    />
  );
};
