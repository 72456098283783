import { useTranslation } from "@equiem/localisation-eq1";
import { ProgressCircle, useTheme } from "@equiem/react-admin-ui";

export const LoadingState = () => {
  const { colors, spacers } = useTheme();
  const { t } = useTranslation();

  return (
    <div className="container">
      <div className="loader">
        <ProgressCircle size="md" />
      </div>
      <p>{t("home.loadingNotifications")}</p>
      <style jsx>{`
        .container {
          cursor: default;
          text-align: center;
          padding: ${spacers.s6};
          font-size: 12px;
          font-weight: 400;
          line-height: 16px;
          color: ${colors.medium};
          height: 146px;
        }
        .loader {
          height: 56px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      `}</style>
    </div>
  );
};
