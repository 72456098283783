import React, { useContext, useEffect, useImperativeHandle } from "react";

import { AdminButton as Button } from "../../Button/AdminButton";
import type { ButtonProps } from "../../Button/AdminButton";
import { DropdownContext } from "../Dropdown";
import { useHtmlId } from "../../../hooks/useHtmlId";

export type DropdownButtonProps = ButtonProps & {
  icon?: React.ReactNode;
};

export interface DropdownButtonRef {
  click: () => void;
  focus: () => void;
}

export const AdminDropdownToggle = React.forwardRef<DropdownButtonRef, DropdownButtonProps>(
  ({ children, icon, ...props }, ref) => {
    const { show, setShow, reference, refs, setToggleId } = useContext(DropdownContext);
    const id = useHtmlId(props.id);

    useEffect(() => {
      setToggleId(id);
    }, [id, setToggleId]);

    useImperativeHandle(ref, () => ({
      click: () => {
        if (refs.reference.current instanceof HTMLButtonElement) {
          refs.reference.current.click();
        }
      },
      focus: () => {
        if (refs.reference.current instanceof HTMLButtonElement) {
          refs.reference.current.focus();
        }
      },
    }));

    return (
      <>
        <Button
          {...props}
          ref={reference}
          type="button"
          onClick={() => {
            setShow(!show);
          }}
          className={`align-items-center ${props.className}`}
          id={id}
          dropdownToggle={icon == null}
          aria-expanded={show ? "true" : "false"}
        >
          {icon}
          {children}
        </Button>
      </>
    );
  },
);

AdminDropdownToggle.displayName = "DropdownToggle";
