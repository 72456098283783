import React, { useContext, useEffect } from "react";
import { formatters, useTranslation } from "@equiem/localisation-eq1";
import { Site, stringNotEmpty } from "@equiem/lib";
import { Tag, DateTime as DT, useTheme, Modal, Button } from "@equiem/react-admin-ui";
import { RiCalendar2Line, RiDiscountPercentLine, RiHashtag, RiTimeLine } from "@equiem/react-admin-ui/icons";
import { DateTime } from "luxon";

import { toDateTime } from "../../../lib/dateTimeHelpers";
import { groupWeekday } from "../../../lib/formatWeekday";
import { BookingModal } from "../../operations/contexts/BookingModalContext";
import { transformDiscountType } from "../util/transformDiscountType";
import { BookingDiscountAddEditContext } from "./BookingDiscountAddEditProvider";
import { BookingDiscountStatusTag } from "./BookingDiscountStatusTag";

const getDateFromString = (dateString: string, timezone: string, language: string) =>
  formatters.dateshort(DateTime.fromFormat(dateString, "yyyy-LL-dd"), language, {
    timezone,
  });

export const BookingDiscountDisplay: React.FC = () => {
  const {
    selectedDiscount: discount,
    edit,
    deleteDiscount,
    canUpdateOrDeleteDiscount,
  } = useContext(BookingDiscountAddEditContext);
  const modal = useContext(BookingModal);
  const { t, i18n } = useTranslation();
  const { spacers, colors } = useTheme(true);
  const { timezone } = useContext(Site);

  useEffect(() => {
    modal.setTitle(t("bookings.discounts.discountDetails"));
  }, [modal, t]);

  if (discount == null) {
    return <></>;
  }

  return (
    <>
      <Modal.Body>
        <div className="mb-5">
          <h4 className="title mb-3">{discount.title}</h4>
          <div className="status">
            <BookingDiscountStatusTag size="large" status={discount.status} />
          </div>
        </div>

        <div className="mb-6">
          <div className="type row">
            <div className="icon col">
              <RiHashtag />
            </div>
            <div className="col">{transformDiscountType(discount.type, t)}</div>
          </div>
          <div className="type row">
            <div className="icon col">
              <RiCalendar2Line />
            </div>
            <div className="col">
              {discount.availability.__typename === "BookingDiscountPeriodicAvailability" && (
                <div className="mb-2">
                  {t("bookings.discounts.weekdayList", {
                    dayList: groupWeekday(
                      discount.availability.days ?? [],
                      i18n.language,
                      "long",
                      t("common.to").toLocaleLowerCase(),
                    ),
                  })}
                </div>
              )}
              <div>
                {getDateFromString(discount.availability.startDate, timezone, i18n.language)} -{" "}
                {getDateFromString(discount.availability.endDate, timezone, i18n.language)}
              </div>
            </div>
          </div>
          {discount.availability.__typename === "BookingDiscountEarlyBookingAvailability" && (
            <div className="type row">
              <div className="icon col">
                <RiTimeLine />
              </div>
              <div className="col">
                {t("bookings.discounts.daysBeforeBooking", {
                  count: discount.availability.daysBeforeBookingToApply,
                })}
              </div>
            </div>
          )}
          {discount.availability.__typename === "BookingDiscountPeriodicAvailability" && (
            <>
              {stringNotEmpty(discount.availability.startTime) && stringNotEmpty(discount.availability.endTime) && (
                <div className="type row">
                  <div className="icon col">
                    <RiTimeLine />
                  </div>
                  <div className="col">
                    <DT.TimeRange
                      language={i18n.language}
                      start={toDateTime(discount.availability.startTime)?.toMillis() ?? 0}
                      end={toDateTime(discount.availability.endTime)?.toMillis() ?? 0}
                      timezone={timezone}
                    />
                  </div>
                </div>
              )}
            </>
          )}
          <div className="type row">
            <div className="icon col">
              <RiDiscountPercentLine />
            </div>
            <div className="col">{discount.percentage}%</div>
          </div>
        </div>

        <div>
          <h4 className="mb-5">{t("bookings.discounts.resourceAssgined")}</h4>
          {discount.resources.map((resource, i) => (
            <Tag className="mr-4 mb-3" key={i} size="large">
              {resource.name}
            </Tag>
          ))}
        </div>
      </Modal.Body>
      {canUpdateOrDeleteDiscount(discount) && (
        <Modal.Footer>
          <div className="discount-buttons w-100">
            <div className="buttons-group">
              <Button className="w-100" variant="danger" onClick={() => deleteDiscount(discount)}>
                {t("common.delete")}
              </Button>
              <Button variant="primary" className="w-100" onClick={() => edit(discount)}>
                {t("common.edit")}
              </Button>
            </div>
          </div>
        </Modal.Footer>
      )}
      <style jsx>{`
        h4 {
          font-weight: 700;
        }
        .row {
          display: flex;
          margin-bottom: ${spacers.s4};
        }
        .col {
          display: flex;
          align-items: flex-start;
          flex-direction: column;
        }
        .icon {
          margin-right: ${spacers.s3};
          color: ${colors.grayscale[50]};
          font-size: ${spacers.s5};
          min-width: ${spacers.s5};
        }
        .discount-buttons :global(button) {
          width: 40%;
        }
        .discount-buttons .buttons-group {
          display: flex;
          gap: 8px;
        }
      `}</style>
    </>
  );
};
