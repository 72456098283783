import { useTranslation } from "@equiem/localisation-eq1";
import React from "react";
import { Summary } from "./Summary";
import { ResourceFeatureList } from "./ResourceFeatureList";
import { BookableResourceFeatureType } from "../../../../generated/gateway-client";
import { SummaryRightLabel } from "./SummaryRightLabel";

export const ResourceFeatureSummary: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Summary
      left={
        <>
          <h3 className="font-weight-bold">{t("bookings.settings.resourceFeatures")}</h3>
          <p>{t("bookings.settings.resourceFeaturesParagraph1")}</p>
          <p>{t("bookings.settings.resourceFeaturesParagraph2")}</p>
        </>
      }
      right={
        <>
          <SummaryRightLabel title={t("bookings.settings.resourceFeatures")} />
          <ResourceFeatureList
            featureType={BookableResourceFeatureType.RoomFeatures}
            createBtnTitle={t("bookings.settings.addFeature")}
          />
        </>
      }
    />
  );
};
