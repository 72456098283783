import type { AppointmentWeekday } from "@equiem/lib/context/types";
import { useTranslation } from "@equiem/localisation-eq1";

export const useWeekdayMapper = () => {
  const { t } = useTranslation();

  const weekDayMap: Record<AppointmentWeekday, string> = {
    "1": t("visitors.appointmentForm.appointmentRecurringSettingsMondayLong"),
    "2": t("visitors.appointmentForm.appointmentRecurringSettingsTuesdayLong"),
    "3": t("visitors.appointmentForm.appointmentRecurringSettingsWednesdayLong"),
    "4": t("visitors.appointmentForm.appointmentRecurringSettingsThursdayLong"),
    "5": t("visitors.appointmentForm.appointmentRecurringSettingsFridayFriday"),
    "6": t("visitors.appointmentForm.appointmentRecurringSettingsSaturdayLong"),
    "7": t("visitors.appointmentForm.appointmentRecurringSettingsSundayLong"),
  };
  const weekDayMapNumber: Record<number, string> = {
    1: t("visitors.appointmentForm.appointmentRecurringSettingsMondayLong"),
    2: t("visitors.appointmentForm.appointmentRecurringSettingsTuesdayLong"),
    3: t("visitors.appointmentForm.appointmentRecurringSettingsWednesdayLong"),
    4: t("visitors.appointmentForm.appointmentRecurringSettingsThursdayLong"),
    5: t("visitors.appointmentForm.appointmentRecurringSettingsFridayFriday"),
    6: t("visitors.appointmentForm.appointmentRecurringSettingsSaturdayLong"),
    7: t("visitors.appointmentForm.appointmentRecurringSettingsSundayLong"),
  };

  return {
    weekDayMap,
    weekDayMapNumber,
  };
};
