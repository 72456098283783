import { stringNotEmpty } from "@equiem/lib";
import type { BookableResourceAddOnFragmentFragment, BookingAddOnInput } from "../../../generated/gateway-client";
import { BookableResourceAddOnType } from "../../../generated/gateway-client";
import { convertInputNumberToNumber } from "../../../lib/convertNumberStringToNumber";
import type { AddOnCheckboxOrRadio, AddOnFreeText, BookingFormValue } from "../models/BookingFormValue";

export const convertInputAddOnsToBookingAddOnInput = (
  resourceAddOns: BookableResourceAddOnFragmentFragment[] | null,
  inputAddOns: BookingFormValue["addOns"] | null,
  skipTypes: BookableResourceAddOnType[] = [],
) => {
  if (resourceAddOns == null || resourceAddOns.length === 0 || inputAddOns == null) {
    return null;
  }

  return resourceAddOns.reduce<BookingAddOnInput[]>((prev, resourceAddOn, index) => {
    if (inputAddOns[index] == null) {
      return prev;
    }

    if (skipTypes.includes(resourceAddOn.type)) {
      return prev;
    }

    switch (resourceAddOn.type) {
      case BookableResourceAddOnType.FreeText: {
        const target = inputAddOns[index] as AddOnFreeText;
        return stringNotEmpty(target.value)
          ? [
              ...prev,
              {
                addOn: resourceAddOn.uuid,
                value: target.value,
              },
            ]
          : prev;
      }

      case BookableResourceAddOnType.SingleChoice:
      case BookableResourceAddOnType.SingleOption:
      case BookableResourceAddOnType.MultiOption: {
        const target = inputAddOns[index] as AddOnCheckboxOrRadio;

        const results = Object.keys(target).flatMap((uuid) => {
          const inputTarget = target[uuid];
          if (inputTarget == null) {
            return [];
          }

          return [
            {
              addOn: resourceAddOn.uuid,
              option: uuid,
              quantity: convertInputNumberToNumber(inputTarget.quantity, true) ?? undefined,
            },
          ];
        });

        return [...prev, ...results];
      }

      default:
        return prev;
    }
  }, []);
};
