import { useTranslation } from "@equiem/localisation-eq1";
import React, { useCallback, useMemo, useState } from "react";
import { Button, IconButton, ListGroup, Material, Skeleton, Tooltip, useTheme } from "@equiem/react-admin-ui";
import { RiAddLine, RiEditLine, RiLock2Line } from "@equiem/react-admin-ui/icons";
import { BreakWordDiv } from "../../../../lib/BreakWordDiv";
import { Form, Formik } from "formik";
import { FormField } from "./FormField";
import type { FormValues } from "./helpers/FormValues";
import { GroupIt } from "./helpers/GroupIt";
import type { Item } from "./helpers/Item";

type Mode = { mode: "create" } | { mode: "edit"; uuid: string };

interface P {
  data: Item[];
  dataLoading: boolean;
  submitIt: (values: FormValues) => Promise<boolean>;
  deleteComponent: (p: { item: Item; disable: boolean }) => React.ReactElement;
  initialValues?: FormValues;
  createBtnTitle: string;
  enableIcon?: boolean;
}
export const ResourceConfigurationList: React.FC<P> = ({
  data,
  dataLoading,
  submitIt,
  initialValues = { name: "" },
  deleteComponent: DeleteComponent,
  createBtnTitle,
  enableIcon = false,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [mode, setMode] = useState<Mode | null>(null);

  const cancelHandler = useCallback(() => {
    setMode(null);
  }, []);

  const onSubmit = useCallback(
    async (values: FormValues) => {
      const result = await submitIt(values);
      if (result) {
        cancelHandler();
      }
    },
    [cancelHandler, submitIt],
  );

  const combined = useMemo(() => {
    return GroupIt(data);
  }, [data]);

  if (dataLoading) {
    return (
      <>
        <div>
          <Skeleton.Line height="25px" width="100%" className="mb-2" />
          <Skeleton.Line height="25px" width="100%" className="mb-2" />
          <Skeleton.Line height="25px" width="100%" />
        </div>
        <style jsx>{`
          div {
            border: 1px solid ${theme.colors.border};
            border-radius: ${theme.borderRadius};
            padding: ${theme.spacers.s3};
          }
        `}</style>
      </>
    );
  }

  return (
    <Formik<FormValues> initialValues={initialValues} onSubmit={onSubmit}>
      {({ isSubmitting, setValues }) => (
        <Form>
          <ListGroup.Group maxHeight="800px">
            <>
              {combined.map((rc) => (
                <ListGroup.Item className={`entry ${rc.locked ? "locked" : ""}`} noMargin key={rc.uuid}>
                  <>
                    {mode?.mode === "edit" && mode.uuid === rc.uuid ? (
                      <FormField cancelHandler={cancelHandler} enableIcon={enableIcon} />
                    ) : (
                      <div className={`list-name d-flex align-items-center ${rc.locked ? "text-muted" : ""}`}>
                        {rc.iconName != null && (
                          <Material.Icon name={rc.iconName} size="sm" className="cursor-pinter" />
                        )}
                        <BreakWordDiv className="ml-3">{rc.name}</BreakWordDiv>
                      </div>
                    )}

                    {rc.locked ? (
                      <Tooltip title={t("bookings.settings.cannotEditDefault")}>
                        <span className="mr-2 locked-icon">
                          <RiLock2Line color={theme.colors.medium} size={16} />
                        </span>
                      </Tooltip>
                    ) : (
                      <div className="d-flex align-items-center">
                        {!(mode?.mode === "edit" && mode.uuid === rc.uuid) && (
                          <>
                            <IconButton
                              size="sm"
                              disabled={isSubmitting}
                              onClick={() => {
                                setValues({ name: rc.name, uuid: rc.uuid, iconName: rc.iconName }).catch(console.error);
                                setMode({ mode: "edit", uuid: rc.uuid });
                              }}
                              className="edit mr-2"
                              hover={{ background: theme.colors.grayscale_5 }}
                            >
                              <Tooltip title={t("common.edit")}>
                                <span>
                                  <RiEditLine size={16} />
                                </span>
                              </Tooltip>
                            </IconButton>
                            <DeleteComponent item={rc} disable={isSubmitting} />
                          </>
                        )}
                      </div>
                    )}
                  </>
                </ListGroup.Item>
              ))}
              {mode?.mode === "create" && (
                <ListGroup.Item noMargin>
                  <FormField cancelHandler={cancelHandler} enableIcon={enableIcon} />
                </ListGroup.Item>
              )}
            </>
          </ListGroup.Group>
          <Button
            variant="secondary"
            className="mt-5"
            disabled={mode != null}
            onClick={() => {
              setValues({ name: "", uuid: undefined, iconName: undefined }).catch(console.error);
              setMode({ mode: "create" });
            }}
          >
            <RiAddLine size={16} /> {createBtnTitle}
          </Button>
          <style jsx>
            {`
              .list-name {
                font-size: 14px;
              }
            `}
          </style>
        </Form>
      )}
    </Formik>
  );
};
