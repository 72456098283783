import React from "react";
import Link from "next/link";
import { useTheme } from "@equiem/react-admin-ui";
import { useTranslation, formatters } from "@equiem/localisation-eq1";
import type { ArticleFragmentFragment, ImageV2 } from "../../generated/gateway-client";

export const ArticleCard: React.FC<ArticleFragmentFragment> = ({ uuid, publishDate, title, excerpt, images }) => {
  const { colors, spacers, borderRadius } = useTheme();
  const { i18n } = useTranslation();

  return (
    <>
      <Link href={`/articles/${uuid}`} legacyBehavior>
        <a className="article-card">
          <img src={(images[0]?.value as ImageV2 | undefined)?.imageUrl ?? undefined} className="mb-2" />
          <small className="published text-muted mb-2">{formatters.relativelong(publishDate, i18n.language)}</small>
          <div className="title mb-2">{title}</div>
          <div className="excerpt text-muted">{excerpt}</div>
        </a>
      </Link>
      <style jsx>{`
        .article-card {
          display: block;
          position: relative;
          width: 272px;
          padding: ${spacers.s3};
          border-radius: ${borderRadius};
          cursor: pointer;
          text-decoration: none;
        }
        .article-card:hover {
          background-color: ${colors.transparent.black[5]};
        }

        img {
          width: 100%;
          aspect-ratio: 3 / 2;
          border-radius: ${borderRadius};
          object-position: center;
          object-fit: cover;
        }

        .published {
          font-weight: 500;
          font-size: 10px;
          line-height: 16px;
          text-transform: uppercase;
        }

        .title {
          font-weight: 700;
          font-size: 16px;
          line-height: 20px;
        }

        .excerpt {
          font-weight: 400px;
          font-size: 14px;
          line-height: 16px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
      `}</style>
    </>
  );
};
