import { useMemo, useState } from "react";
import type { FilterItem, FilterValue } from "@equiem/react-admin-ui";
import { FilterSelectModifier, useDebounced } from "@equiem/react-admin-ui";
import { RiBuildingLine } from "@equiem/react-admin-ui/icons";
import { useTranslation } from "@equiem/localisation-eq1";

import type { CmsSearchFilters, PortfolioSiteFragmentFragment } from "../../../generated/gateway-client";

type ComplexFilterName = "site";
type ComplexFilterValues = Partial<Record<ComplexFilterName, FilterValue>>;

const debounceMs = 500;

const parseComplexFilters = (values: ComplexFilterValues): CmsSearchFilters => {
  const parsed: CmsSearchFilters = {};

  if (values.site?.type === "select" && values.site.value != null) {
    parsed.sites = [values.site.value];
  }

  return parsed;
};

export const useArticleComplexFilters = (portfolioSites: PortfolioSiteFragmentFragment[]) => {
  const { t } = useTranslation();

  const filterDefinitions: Record<ComplexFilterName, FilterItem> = useMemo(
    () => ({
      site: {
        title: t("common.site"),
        type: "select",
        options: portfolioSites
          .map((site) => ({ label: site.destination.name, value: site.destination.uuid }))
          .sort((a, b) => a.label.localeCompare(b.label)),
        icon: RiBuildingLine,
        modifiers: [FilterSelectModifier.is],
      },
    }),
    [portfolioSites, t],
  );

  const [values, setValues] = useState<ComplexFilterValues>({});

  const filter = useMemo(() => parseComplexFilters(values), [values]);
  const debouncedFilter = useDebounced(filter, debounceMs);

  return {
    props: {
      filters: filterDefinitions,
      onChange: setValues,
    },
    filter: debouncedFilter,
    hasFilters: Object.keys(values).length > 0,
  };
};
