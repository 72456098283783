import React from "react";

import { useTheme } from "@equiem/react-admin-ui";
import type { IconType } from "@equiem/react-admin-ui/icons";

export const CalendarsCheckFillIcon: IconType = ({ size, color, ...props }) => {
  const { colors } = useTheme();

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} fill="none" viewBox="0 0 24 24" {...props}>
      <path
        fill={color ?? colors.blue[60]}
        d="M21 17V8H7V17H21ZM21 3C21.5304 3 22.0391 3.21071 22.4142 3.58579C22.7893 3.96086 23 4.46957 23 5V17C23 17.5304 22.7893 18.0391 22.4142 18.4142C22.0391 18.7893 21.5304 19 21 19H7C5.89 19 5 18.1 5 17V5C5 4.46957 5.21071 3.96086 5.58579 3.58579C5.96086 3.21071 6.46957 3 7 3H8V1H10V3H18V1H20V3H21ZM17.53 11.06L13.09 15.5L10.41 12.82L11.47 11.76L13.09 13.38L16.47 10L17.53 11.06ZM3 21H17V23H3C1.89 23 1 22.1 1 21V9H3V21Z"
      />
    </svg>
  );
};
