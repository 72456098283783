import React, { useState } from "react";

import { useTranslation } from "@equiem/localisation-eq1";
import { Button, Text, Tooltip, useIsMobileWidth, useTheme } from "@equiem/react-admin-ui";
import { RiArrowDownSLine, RiArrowUpSLine, RiInformationLine, RiStickyNoteLine } from "@equiem/react-admin-ui/icons";

import type { VisitorAppointmentBookingInfo, VisitorReceptionQuery } from "../../../generated/visitors-client";
import type { FormValues } from "../types";

export const Notes = ({
  values,
  reception,
  resource,
}: {
  values: FormValues;
  resource?: VisitorAppointmentBookingInfo["resource"];
  reception?: VisitorReceptionQuery["visitorReception"];
}) => {
  const [isNotesVisible, setIsNotesVisible] = useState(false);
  const isMobile = useIsMobileWidth();
  const { colors } = useTheme();
  const { t } = useTranslation();

  return (
    <>
      <div className="notes">
        {isMobile && (
          <div className="d-flex justify-content-between w-100">
            <div className="notes-title">
              <RiStickyNoteLine size={16} color={colors.grayscale[60]} />
              <Text variant="text" size="small" className="m-0">
                {t("visitors.appointments.notes")}
              </Text>
            </div>
            <Button
              variant="ghost"
              onClick={() => {
                setIsNotesVisible(!isNotesVisible);
              }}
            >
              {isNotesVisible ? (
                <>
                  {t("common.hide")}
                  <RiArrowUpSLine size={16} />
                </>
              ) : (
                <>
                  {t("visitors.common.showMore")} <RiArrowDownSLine size={16} />
                </>
              )}
            </Button>
          </div>
        )}
        {(isNotesVisible || !isMobile) && (
          <div className="notes--expanded">
            <div>
              <Text variant="text" size="extra-small" className="notes-item" color={colors.grayscale[60]}>
                {t("visitors.appointmentForm.receptionNotes", {
                  prefix: reception?.building?.name ?? resource?.building?.name ?? "",
                })}
                <Tooltip title={t("visitors.appointmentForm.receptionNotesTooltip")}>
                  <div className="tooltip">
                    <RiInformationLine
                      size={16}
                      color={colors.grayscale[60]}
                      style={{
                        cursor: "pointer",
                      }}
                    />
                  </div>
                </Tooltip>
              </Text>
              <Text variant="text" size="small">
                {values.buildingReceptionNotes}
              </Text>
            </div>

            {values.bookingInfo?.uuid == null && (
              <div>
                <Text variant="text" size="extra-small" className="notes-item" color={colors.grayscale[60]}>
                  {t("visitors.appointmentForm.receptionNotes", { prefix: values.host.company?.name ?? "" })}
                  <Tooltip title={t("visitors.appointmentForm.companyReceptionNotesTooltip")}>
                    <div className="tooltip">
                      <RiInformationLine
                        color={colors.grayscale[60]}
                        size={16}
                        style={{
                          cursor: "pointer",
                        }}
                      />
                    </div>
                  </Tooltip>
                </Text>
                <Text variant="text" size="small">
                  {values.companyReceptionNotes}
                </Text>
              </div>
            )}

            <div>
              <Text variant="text" size="extra-small" className="notes-item" color={colors.grayscale[60]}>
                {t("visitors.appointmentForm.visitorNotes")}
                <Tooltip
                  description="asdas"
                  placement="right"
                  title={t("visitors.appointmentForm.visitorNotesTooltip")}
                >
                  <div className="tooltip">
                    <RiInformationLine
                      color={colors.grayscale[60]}
                      size={16}
                      style={{
                        cursor: "pointer",
                      }}
                    />
                  </div>
                </Tooltip>
              </Text>
              <Text variant="text" size="small">
                {values.visitorNotes}
              </Text>
            </div>
          </div>
        )}
      </div>
      <style jsx>
        {`
          :global(.notes-item) {
            display: flex;
            gap: 4px;
            margin: 0;
            align-items: center;
          }

          .notes {
            display: flex;
            flex-direction: column;
            gap: 4px;
            margin-bottom: 12px;
          }

          .notes--expanded {
            display: flex;
            flex-direction: column;
            gap: 12px;
            visibility: ${isNotesVisible || !isMobile ? "visible" : "collapse"};
            height: ${isNotesVisible || !isMobile ? "100%" : "0"};
            margin-top: ${isNotesVisible && isMobile ? "12px" : "0"};
          }

          .notes-title {
            display: flex;
            gap: 12px;
            align-items: center;
          }
        `}
      </style>
    </>
  );
};
