"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.withTranslationGeneric = void 0;
var react_i18next_1 = require("react-i18next");
var withTranslationGeneric = function () {
    return function (Component) {
        return (0, react_i18next_1.withTranslation)()(Component);
    };
};
exports.withTranslationGeneric = withTranslationGeneric;
