import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import type { FormikHelpers, FormikProps } from "formik";
import { Formik } from "formik";

import { useShowError } from "@equiem/lib";
import { useServerMessageTranslation, useTranslation } from "@equiem/localisation-eq1";
import { Alert, Button, Modal, useConfirmer, useIsMobileWidth, useTheme, useToast } from "@equiem/react-admin-ui";
import { RiInformationLine } from "@equiem/react-admin-ui/icons";

import { Modal as ModalContext } from "../../../contexts/ModalContext";
import { useApartmentDetailsLazyQuery } from "../../../generated/settings-client";
import { ApartmentForm } from "../components/ApartmentForm";
import { useApartmentFormSubmit } from "../hooks/useApartmentFormSubmit";
import type { FormValues } from "../types";
import { getValidationSchema } from "../utils/validationSchema";

export const ApartmentDetailsWidget: React.FC = () => {
  const { t } = useTranslation();
  const { tServer } = useServerMessageTranslation();
  const [showModal, setShowModal] = useState(false);
  const showError = useShowError();
  const isMobile = useIsMobileWidth();
  const modal = useContext(ModalContext);
  const { spacers, colors } = useTheme();
  const { loading: saving, onSubmit } = useApartmentFormSubmit(modal.id);
  const [fetchApartmentDetails, { data: apartmentData }] = useApartmentDetailsLazyQuery();
  const toast = useToast();
  const { withConfirmation } = useConfirmer();
  const formInnerRef = useRef<FormikProps<FormValues>>(null);
  const isUpdateModal = modal.id != null;

  useEffect(() => {
    if (modal.activeModal === "ApartmentDetails") {
      setShowModal(true);
    } else {
      setShowModal(false);
    }
  }, [modal.activeModal]);

  useEffect(() => {
    if (modal.id != null) {
      void fetchApartmentDetails({ variables: { uuid: modal.id }, fetchPolicy: "network-only" });
    }
  }, [modal.id]);

  const initialValues: FormValues = useMemo(() => {
    if (apartmentData?.apartment != null && apartmentData.apartment.uuid === modal.id) {
      return {
        name: apartmentData.apartment.name,
        levels: apartmentData.apartment.apartmentDestinations
          .map((destination) => {
            return destination.buildingLevels.map((levels) => levels.uuid);
          })
          .flat(),
      };
    }
    return {
      name: "",
      levels: [],
    };
  }, [apartmentData, modal.id]);

  const onClose = useCallback(() => {
    setShowModal(false);
    modal.close();
  }, [setShowModal, modal]);

  const onCloseModal = useCallback(() => {
    formInnerRef.current?.dirty === true
      ? withConfirmation({
          title: t("common.areYouSure"),
          message: t("settings.editApartment.cancelMessage"),
          confirmButtonText: t("common.yesCancel"),
          cancelButtonText: t("home.widgets.cancelNo"),
          confirmButtonVariant: "danger",
          onConfirm: onClose,
        })()
      : onClose();
  }, [withConfirmation, t, onClose, formInnerRef]);

  const handleSubmit = async (values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
    try {
      setSubmitting(true);
      const result = await onSubmit(values);

      if (result.data?.createOrUpdateApartment.__typename === "ApartmentFailure") {
        const reason =
          result.data.createOrUpdateApartment.localisedReason != null
            ? tServer(result.data.createOrUpdateApartment.localisedReason)
            : result.data.createOrUpdateApartment.reason;
        toast.negative(reason);
        return;
      }

      toast.positive(
        isUpdateModal
          ? t("settings.editApartment.successUpdateMessage", { apartment: values.name })
          : t("settings.editApartment.successMessage", { apartment: values.name }),
      );
    } catch (e: unknown) {
      showError(e);
    } finally {
      setSubmitting(false);
      setShowModal(false);
      modal.close();
    }
  };

  return (
    <>
      <Modal.Dialog
        title={isUpdateModal ? t("settings.editApartment.editApartment") : t("common.createNew")}
        show={showModal}
        sideModal={true}
        onHide={onCloseModal}
        hideOnEsc={true}
        hideOnClick={true}
        size="md"
      >
        <Modal.Header
          intro={
            !isMobile
              ? isUpdateModal
                ? apartmentData?.apartment?.name
                : t("settings.editApartment.newApartment")
              : null
          }
          closeButton
          noBorder={!isMobile}
          onClose={onCloseModal}
          supportsMobile
        />
        <Formik
          initialValues={initialValues}
          innerRef={formInnerRef}
          validationSchema={getValidationSchema(t)}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({ submitForm }) => (
            <>
              <Modal.Body noPadding>
                <div className="content">
                  {isUpdateModal && (
                    <Alert
                      size="large"
                      variant="gray"
                      icon={<RiInformationLine size={18} color={colors.grayscale[50]} />}
                      message={t("settings.editApartment.editApartmentInfo")}
                      className="mb-6"
                    />
                  )}
                  <ApartmentForm isEdit={isUpdateModal} />
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="ghost" className="mr-4" onClick={onCloseModal}>
                  {t("common.cancel")}
                </Button>
                <Button
                  disabled={saving}
                  type="submit"
                  variant="primary"
                  onClick={() => {
                    void submitForm();
                  }}
                >
                  {isUpdateModal ? t("common.saveChanges") : t("settings.createApartment")}
                </Button>
              </Modal.Footer>
            </>
          )}
        </Formik>
      </Modal.Dialog>

      <style jsx>{`
        .title {
          font-weight: 400;
          font-size: 18px;
          line-height: 28px;
        }
        .content {
          padding: 0 ${spacers.s6} 0;
        }
        hr {
          border: none;
          border-top: 1px solid ${colors.border};
          margin-bottom: 16px;
        }
      `}</style>
    </>
  );
};
