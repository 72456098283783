import React from "react";
import { Modal, Skeleton, useToast } from "@equiem/react-admin-ui";
import { useSiteContext } from "@equiem/lib";

import type { DeleteBookableResourceMutationVariables } from "../../../../generated/gateway-client";
import {
  useBookableResourceDeletionInfoQuery,
  useDeleteBookableResourceMutation,
} from "../../../../generated/gateway-client";
import { DeleteResourceForm } from "./DeleteResourceForm";
import { useTranslation, formatters } from "@equiem/localisation-eq1";

interface Props {
  resourceUuid: string;
  visible: boolean;
  onClose: () => void;
}

const Loading: React.FC = () => (
  <>
    <div className="pb-2">
      <Skeleton.Line width="100%" height="18px" />
    </div>
    <div className="pb-2">
      <Skeleton.Line width="100%" height="18px" />
    </div>
    <div className="pb-2">
      <Skeleton.Line width="100%" height="18px" />
    </div>
    <Skeleton.Line width="100%" height="18px" />
  </>
);

export const DeleteResourceModal: React.FC<Props> = ({ resourceUuid, visible, onClose }) => {
  const { i18n, t } = useTranslation();
  const site = useSiteContext();
  const toast = useToast();

  const infoQuery = useBookableResourceDeletionInfoQuery({
    variables: { uuid: resourceUuid },
    skip: !visible,
  });
  const [mutation, { loading: isSubmitting }] = useDeleteBookableResourceMutation();

  const resource = infoQuery.data?.bookableResource;
  const isReady = !infoQuery.loading && infoQuery.error == null && resource != null;

  const scheduleDeletion = async (vars: Omit<DeleteBookableResourceMutationVariables, "uuid">) => {
    if (!isReady || isSubmitting) {
      return;
    }
    try {
      const result = await mutation({ variables: { uuid: resourceUuid, ...vars } });
      const deletionDate = result.data?.deleteBookableResource.deletedAfter;
      if (result.errors != null || deletionDate == null) {
        throw new Error(`failed to schedule deletion: ${JSON.stringify(result)}`);
      }

      const timezone = resource.building?.timezone ?? site.timezone;
      const date = formatters.dateshort(deletionDate, i18n.language, { timezone });
      toast.neutral(t("bookings.resources.resourceDeleteScheduled", { name: resource.name, date }));
    } catch (e: unknown) {
      console.error(e);
      toast.negative(t("bookings.resources.resourceDeleteFailed", { name: resource.name }));
    } finally {
      onClose();
    }
  };

  return (
    <Modal.Dialog
      className="delete-resource-modal"
      title={t("bookings.resources.scheduleResourceDeletion")}
      show={visible}
      onHide={onClose}
      hideOnEsc={true}
      hideOnClick={false}
      focusTrapOptions={{ allowOutsideClick: () => true }}
      size="lg"
    >
      <Modal.Header closeButton={true} noBorder={true}></Modal.Header>
      <Modal.Body>
        {isReady ? (
          <DeleteResourceForm
            info={resource}
            isSubmitting={isSubmitting}
            onCancel={onClose}
            onSubmit={(vars) => {
              void scheduleDeletion(vars);
            }}
          />
        ) : infoQuery.loading ? (
          <Loading />
        ) : (
          <div>{t("bookings.resources.failedToLoadInfo")}</div>
        )}
      </Modal.Body>
    </Modal.Dialog>
  );
};
