import { createContext } from "react";

export interface UrlParamsContext {
  setParam: (key: string, value: string) => void;
  deleteParam: (key: string) => void;
  clearParams: () => void;
  currentUrlParams: Record<string, string | string[] | undefined>;
}

export const UrlParams = createContext<UrlParamsContext>({
  currentUrlParams: {},
  setParam: () => {
    throw new Error("Not defined");
  },
  deleteParam: () => {
    throw new Error("Not defined");
  },
  clearParams: () => {
    throw new Error("Not defined");
  },
});
