import { notNullOrUndefined } from "@equiem/lib";

import type { Report, ReportParams } from "./ReportParams";
import { formatIsoDate } from "./formatters";

export const getCsvFileName = (
  report: Report,
  { startDate, endDate, timezone, companyName, cancelled, costOnly, paymentMethod }: ReportParams,
) => {
  const nameParts = [
    "bookings",
    report,
    formatIsoDate(startDate, timezone),
    formatIsoDate(endDate, timezone),
    companyName?.toLowerCase().replace(/\s+/gu, "-"),
    cancelled === true ? "cancelled-only" : null,
    cancelled === false ? "excluding-cancelled" : null,
    costOnly === true ? paymentMethod?.toLowerCase() ?? "cost-only" : null,
  ].filter(notNullOrUndefined);

  return `${nameParts.join("_")}.csv`;
};
