import { useTheme, Skeleton } from "@equiem/react-admin-ui";
import type { PropsWithChildren } from "react";
import React, { createContext } from "react";
import { SegmentSkeleton } from "../components/SegmentSkeleton";
import type { GetSiteQuery } from "../generated/gateway-client";
import { useGetSiteQuery } from "../generated/gateway-client";
import type { NotificationMethod } from "../state/NotificationMethod";
import type { NotificationType } from "../state/NotificationType";

export interface AudienceContext {
  notificationMethod?: NotificationMethod;
  notificationType?: NotificationType;
  initialSegmentIds: string[];
  site: GetSiteQuery;
  gatewayEndpoint?: string;
}

export const CurrentAudience = createContext<AudienceContext>({
  notificationMethod: undefined,
  notificationType: undefined,
  initialSegmentIds: [],
  site: {
    destination: {
      uuid: "",
      name: "",
      settings: {
        registration: {
          commercialSignupEnabled: false,
          residentialSignupEnabled: false,
          visitorSignupEnabled: false,
        },
      },
    },
  },
});

interface Props {
  notificationMethod?: NotificationMethod;
  notificationType?: NotificationType;
  initialSegmentIds?: string[];
  siteId: string;
  gatewayEndpoint?: string;
}

export const AudienceProvider: React.FC<PropsWithChildren<Props>> = ({
  siteId,
  notificationType,
  notificationMethod,
  initialSegmentIds,
  gatewayEndpoint,
  children,
}) => {
  const { spacers } = useTheme();
  const { data: site } = useGetSiteQuery({
    variables: {
      uuid: siteId,
    },
    context: {
      uri: gatewayEndpoint,
    },
  });

  if (site?.destination == null) {
    return (
      <>
        <h1>
          <Skeleton.Line width="200px" />
        </h1>
        <SegmentSkeleton />
        <style jsx>{`
          h1 {
            font-weight: 700;
            margin-bottom: ${spacers.s5};
          }
        `}</style>
      </>
    );
  }

  return (
    <CurrentAudience.Provider
      value={{
        initialSegmentIds: initialSegmentIds ?? [],
        notificationMethod,
        notificationType,
        site,
        gatewayEndpoint,
      }}
    >
      {children}
    </CurrentAudience.Provider>
  );
};
