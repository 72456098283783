import { formatters } from "@equiem/localisation-eq1";
import { DateTime } from "luxon";
import React from "react";

interface Props {
  datetime: number;
  timezone?: string;
  language?: string;
}

export const toDateString = ({ datetime, timezone, language }: Props) => {
  const date = DateTime.fromMillis(datetime, { zone: timezone });
  if (language != null) {
    return formatters.datelong(date, language);
  }
  return `${date.toFormat("ccc")}, ${date.toFormat("d")} ${date.toFormat("LLL")} ${date.toFormat("yyyy")}`;
};

export const DateDisplay: React.FC<Props> = (props) => <>{toDateString(props)}</>;
