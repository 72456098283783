import { useCallback } from "react";

import type { BuildingLevel, Space } from "../../../generated/requests-client";
import { MySpacesQueryRole, useMySpacesQuery } from "../../../generated/requests-client";
import { getUniqueArray } from "../../../utils/getUniqueArray";
import type { RequestValues } from "../../../utils/types";
import { StepType } from "../../../utils/types";

export function useLocationData() {
  const { data: mySpacesData } = useMySpacesQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      as: MySpacesQueryRole.RequestManager,
    },
  });

  const buildings = useCallback(
    (values: RequestValues) => getUniqueArray(mySpacesData?.mySpaces as Space[], values, StepType.BUILDINGS),
    [mySpacesData],
  );
  const levels = useCallback(
    (values: RequestValues) =>
      getUniqueArray(mySpacesData?.mySpaces as Space[], values, StepType.LEVELS) as BuildingLevel[],
    [mySpacesData],
  );
  const spaces = useCallback(
    (values: RequestValues) => {
      const buildingLevel = levels(values).find(
        (bl: { uuid: string }) => bl.uuid === values.buildingLevelUuid,
      ) as BuildingLevel | null;
      if (buildingLevel == null) {
        return [];
      }
      return getUniqueArray(mySpacesData?.mySpaces as Space[], values, StepType.SPACES);
    },
    [levels, mySpacesData],
  );

  return {
    buildings,
    levels,
    spaces,
  };
}
