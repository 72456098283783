import { useMemo, useState } from "react";

import { useTranslation } from "@equiem/localisation-eq1";
import type { FilterItem } from "@equiem/react-admin-ui";
import { FilterOptionsModifier } from "@equiem/react-admin-ui";
import { RiBuilding4Line } from "@equiem/react-admin-ui/icons";
import { useMyFlexTenantsQuery } from "../../../generated/gateway-client";
import { notNullOrUndefined, stringIsEmpty } from "@equiem/lib";

const DEFAULT_PAGE_SIZE = 5;

export function useCreditsFilterTenants(): { filter: FilterItem } {
  const { t } = useTranslation();
  const [loadingMore, setLoadingMore] = useState(false);

  const variables = {
    first: DEFAULT_PAGE_SIZE,
  };

  const { data, loading, fetchMore, refetch } = useMyFlexTenantsQuery({
    variables: { ...variables },
    fetchPolicy: "network-only",
  });

  const handleNextPage = () => {
    if (loading || loadingMore) {
      return;
    }

    if (data?.myFlexTenants.pageInfo.hasNextPage !== true) {
      return;
    }

    setLoadingMore(true);

    fetchMore({
      variables: {
        ...variables,
        after: data.myFlexTenants.pageInfo.endCursor,
      },
      updateQuery(prev, { fetchMoreResult }) {
        return {
          ...fetchMoreResult,
          myFlexTenants: {
            ...fetchMoreResult.myFlexTenants,
            edges: [...prev.myFlexTenants.edges, ...fetchMoreResult.myFlexTenants.edges],
          },
        };
      },
    })
      .catch((e) => console.error(e))
      .finally(() => {
        setLoadingMore(false);
      });
  };

  const options = useMemo(
    () =>
      data?.myFlexTenants.edges
        .filter(({ node }) => notNullOrUndefined(node))
        .map(({ node }) => ({
          label: node!.name,
          value: node!.uuid,
        })) ?? [],
    [data?.myFlexTenants.edges, data?.myFlexTenants.edges.length],
  );

  const filter: FilterItem = {
    title: t("credits.membership"),
    type: "infinite-options",
    hasMoreItems: data?.myFlexTenants.pageInfo.hasNextPage ?? false,
    fetchMore: () => {
      handleNextPage();
    },
    searchCallback: async (search) => {
      setLoadingMore(true);
      return refetch({ ...variables, search: stringIsEmpty(search) ? undefined : search }).finally(() => {
        setLoadingMore(false);
      });
    },
    options,
    loading: loading || loadingMore,
    icon: RiBuilding4Line,
    modifiers: [FilterOptionsModifier.includes],
  };

  return {
    filter,
  };
}
