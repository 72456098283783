import { useTheme } from "@equiem/react-admin-ui";
import type { PropsWithChildren } from "react";
import React from "react";

export const Mobile: React.FC<PropsWithChildren> = ({ children }) => {
  const { colors } = useTheme(true);

  return (
    <div className="mobile-layout">
      {children}
      <style jsx>{`
        .mobile-layout {
          min-height: 100vh;
          background: ${colors.white};
        }
      `}</style>
    </div>
  );
};
