import React from "react";
import { useTranslation } from "@equiem/localisation-eq1";
import { RiGlobalLine } from "react-icons/ri";

import { DropdownNestedMenu } from "../Dropdown/DropdownNestedMenu/DropdownNestedMenu";
import type { Props } from "./LocalePickerOptions";
import { LocalePickerOptions, getDisplayName } from "./LocalePickerOptions";

export const LocalePicker: React.FC<Props> = ({
  languages,
  debugLanguages,
  showSelected,
  showDebugLanguages = false,
}) => {
  const { t, i18n, ready } = useTranslation();

  return (
    <DropdownNestedMenu
      className="locale-picker"
      icon={RiGlobalLine}
      title={getDisplayName(i18n.language, showDebugLanguages) ?? (t("common.language") as string)}
      disabled={!ready}
      maxHeight={300}
    >
      <LocalePickerOptions
        languages={languages}
        debugLanguages={debugLanguages}
        showSelected={showSelected}
        showDebugLanguages={showDebugLanguages}
      />
    </DropdownNestedMenu>
  );
};
