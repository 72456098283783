import type { ReactNode } from "react";
import React, { useContext, useEffect, useState, type FC } from "react";
import { useDestinationDetailsQuery } from "../generated/gateway-client";
import { FlexTenantContext } from "./FlexTenantContext";
import { CurrentProfile, Site } from "@equiem/lib";
import type { CompanyFlexTenantsFragmentFragment } from "@equiem/lib/generated/gateway-client";

export const FlexTenantProvider: FC<{ children: ReactNode; selectedUuid?: string }> = ({ children, selectedUuid }) => {
  const [activeFlexTenant, setActiveFlexTenant] = useState<CompanyFlexTenantsFragmentFragment | null>(null);
  const [flexTenants, setFlexTenants] = useState<CompanyFlexTenantsFragmentFragment[]>([]);

  const site = useContext(Site);
  const { data } = useDestinationDetailsQuery({
    variables: {
      uuid: site.uuid,
    },
  });
  const { companyFlexTenants } = useContext(CurrentProfile);

  useEffect(() => {
    const siteFlexMemberships = companyFlexTenants.filter(
      (ft) => ft.flexOperator.uuid === data?.destination.siteFlexOperator?.uuid,
    );
    if (siteFlexMemberships.length > 0) {
      setFlexTenants(siteFlexMemberships);
      if (selectedUuid != null) {
        setActiveFlexTenant(siteFlexMemberships.find((fm) => fm.uuid === selectedUuid) ?? null);
      } else {
        setActiveFlexTenant(siteFlexMemberships[0]);
      }
    }
  }, [companyFlexTenants, selectedUuid, data]);

  return (
    <>
      <FlexTenantContext.Provider
        value={{
          activeFlexTenant,
          setActiveFlexTenant,
          flexTenants,
        }}
      >
        {children}
      </FlexTenantContext.Provider>
    </>
  );
};
