import React, { useCallback, useContext, useMemo } from "react";
import { NewInvites } from "./NewInvitesContext";
import { Form, ProgressCircle } from "@equiem/react-admin-ui";
import { useRoles } from "./hooks/useRoles";
import { useTranslation } from "@equiem/localisation-eq1";
import uniq from "lodash.uniq";
import { tupleToKey } from "./utils/tupleToKey";
import { Site, stringIsEmpty, stringNotEmpty } from "@equiem/lib";

export const BulkRoleSelection: React.FC = () => {
  const { selectedRoles, extendRolesBulk, invites } = useContext(NewInvites);
  const { uuid } = useContext(Site);
  const granteeCompanyUuids = invites.some((i) => i.isChecked && stringIsEmpty(i.companyUuid))
    ? []
    : uniq(invites.flatMap((i) => (stringNotEmpty(i.companyUuid) && i.isChecked ? [i.companyUuid] : [])));
  const { roles, loading } = useRoles({ granteeCompanyUuids, siteUuid: uuid });
  const { t } = useTranslation();

  const onChange = useCallback(
    (e: { target: { value: string[] } }) => {
      extendRolesBulk(roles.filter((r) => e.target.value.includes(r.value)).map((r) => r.tuple));
    },
    [extendRolesBulk, roles],
  );

  const options = useMemo(() => {
    return roles.map((role) => {
      return {
        ...role,
        indeterminate: selectedRoles.some((sr) => tupleToKey(sr.role) === role.value && sr.mixedSelection),
      };
    });
  }, [roles, selectedRoles]);

  const getValue = useMemo(() => {
    return selectedRoles.flatMap((role) => (!role.mixedSelection ? [tupleToKey(role.role)] : []));
  }, [selectedRoles]);

  return (
    <Form.MultiSelect
      size="small"
      placeholder={
        <>
          {loading && <ProgressCircle mode="indeterminate" size="xs" />}
          {t("home.widgets.welcomeAssignRoles")}
        </>
      }
      options={options}
      value={getValue}
      onChange={onChange}
      disabled={loading || options.length === 0}
      className="bulk-role-selector"
      key="bulkii"
      facade="ghost"
    />
  );
};
