import React, { useEffect, useMemo, useState } from "react";
import type { CardFragmentFragment } from "../../generated/gateway-client";
import { useTranslation } from "@equiem/localisation-eq1";
import { Button, Form, ProgressCircle } from "@equiem/react-admin-ui";
import { useShowError } from "../../hooks";
import { NewCreditCard } from "./NewCreditCard";
import { CardDisplay } from "./CardDisplay";

interface Props {
  selectedCard?: CardFragmentFragment;
  cards: CardFragmentFragment[];
  onHide: () => void;
  onSave: (card?: CardFragmentFragment) => Promise<void>;
  className?: string;
}

export const PaymentCardSelector: React.FC<Props> = ({
  cards,
  selectedCard: selectedCardInput,
  onHide,
  onSave,
  className = "",
}) => {
  const { t } = useTranslation();
  const showError = useShowError();
  const [addingCard, setAddingCard] = useState(false);
  const [saving, setSaving] = useState(false);
  const [selectedCardUuid, setSelectedCardUuid] = useState(selectedCardInput?.uuid);
  const selectedCard = useMemo(() => cards.find((c) => c.uuid === selectedCardUuid), [selectedCardUuid, cards]);

  useEffect(() => {
    if (selectedCardInput != null) {
      setSelectedCardUuid(selectedCardInput.uuid);
    }
  }, [selectedCardInput]);

  return (
    <div className={`payment-card-selector ${className}`}>
      {cards.length === 0 ? (
        <NewCreditCard
          autofocus={true}
          setDefault={true}
          onCancel={() => {
            onHide();
          }}
          onCardAdded={({ uuid }) => {
            setSelectedCardUuid(uuid);
            onHide();
          }}
        />
      ) : (
        <>
          {cards.map((card) => (
            <div
              key={card.uuid}
              className="card-radio d-flex pb-3 px-1 border-bottom"
              onClick={() => {
                setSelectedCardUuid(card.uuid);
              }}
            >
              <CardDisplay
                card={card}
                prefix={
                  <Form.RadioButton
                    label=""
                    name="selectedCard"
                    checked={selectedCardUuid === card.uuid}
                    onChange={() => {
                      setSelectedCardUuid(card.uuid);
                    }}
                  />
                }
              />
            </div>
          ))}
          <div>
            {addingCard && (
              <NewCreditCard
                className="mb-3"
                autofocus={true}
                setDefault={false}
                onCancel={() => {
                  setAddingCard(false);
                }}
                onCardAdded={(e) => {
                  setAddingCard(false);
                  setSelectedCardUuid(e.uuid);
                }}
              />
            )}
          </div>
          <div className="pt-2 d-flex justify-content-between">
            <div>
              <Button
                variant="outline"
                disabled={addingCard}
                onClick={() => {
                  setAddingCard(true);
                }}
              >
                {t("equiem-one-ui.creditcard.addNewCard")}
              </Button>
            </div>

            <div>
              <Button
                variant="outline"
                type="button"
                className="mr-3"
                disabled={addingCard || saving}
                onClick={() => {
                  setSelectedCardUuid(selectedCardInput?.uuid);
                  onHide();
                }}
              >
                {t("common.cancel")}
              </Button>
              <Button
                variant="primary"
                disabled={addingCard || saving}
                type="button"
                onClick={() => {
                  setSaving(true);
                  onSave(selectedCard)
                    .then(onHide)
                    .catch((e) => {
                      showError(e);
                      setSaving(false);
                    });
                }}
              >
                {saving ? <ProgressCircle size="sm" className="mr-1" /> : null}
                {t("common.save")}
              </Button>
            </div>
          </div>
        </>
      )}
      <style jsx>{`
        .card-radio {
          cursor: pointer;
        }
        .card-radio:hover {
          background: rgba(0, 0, 0, 0.025);
        }
      `}</style>
    </div>
  );
};
