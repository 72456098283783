import { stringIsEmpty } from "@equiem/lib";
import getSymbolFromCurrency from "currency-symbol-map";
import { useGetBuildingQuery } from "../generated/gateway-client";

const defaultCurrency = "AUD";

export const useCurrencyCode = (
  buildingUuid?: string | null | undefined,
  explicitCurrencyCode?: string | null | undefined,
) => {
  const building = useGetBuildingQuery({
    variables: { uuid: buildingUuid ?? "" },
    skip: stringIsEmpty(buildingUuid) || explicitCurrencyCode != null,
  });
  const buildingCurrency = building.data?.buildingPublic?.taxes.currency.code;

  return explicitCurrencyCode ?? buildingCurrency ?? defaultCurrency;
};

export const useCurrency = (buildingUuid?: string | null | undefined) => {
  const currencyCode = useCurrencyCode(buildingUuid);

  return { currency: getSymbolFromCurrency(currencyCode) };
};
