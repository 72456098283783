import React, { useContext } from "react";

import { useSiteContext } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import { Dropdown, useConfirmer, useTheme, useToast } from "@equiem/react-admin-ui";
import { RiDeleteBin5Line, RiEditLine, RiMoreFill } from "@equiem/react-admin-ui/icons";

import { Modal as ModalContext } from "../../../contexts/ModalContext";
import { useDeleteApartmentMutation } from "../../../generated/settings-client";
import type { ApartmentListItem } from "../hooks/usePagedApartmentList";

type Props = {
  apartment: ApartmentListItem;
};

export const ApartmentListRow: React.FC<Props> = ({ apartment }) => {
  const { t } = useTranslation();
  const { colors } = useTheme();
  const { withConfirmation } = useConfirmer();
  const [deleteApartment] = useDeleteApartmentMutation();
  const { uuid: siteUuid } = useSiteContext();
  const toast = useToast();
  const modal = useContext(ModalContext);

  const stopPropagationHandle = (e: React.MouseEvent) => {
    e.stopPropagation();
  };

  const handleEdit = () => {
    modal.open("ApartmentDetails", apartment.uuid);
  };

  const handleRemove = () => {
    withConfirmation({
      title: t("common.areYouSure"),
      message: t("settings.apartmentDeleteMessage"),
      confirmButtonText: t("common.yesRemove"),
      cancelButtonText: t("common.cancelNo"),
      confirmButtonVariant: "danger",
      onConfirm: () => {
        void deleteApartment({
          variables: {
            uuid: apartment.uuid,
            destinationUuid: siteUuid,
          },
          refetchQueries: ["ApartmentList"],
        }).then((result) => {
          if (result.data?.deleteApartment === true) {
            toast.positive(t("settings.apartmentDeleteSuccess"));
          }
        });
      },
    })();
  };

  return (
    <>
      <tr className="row" onClick={handleEdit}>
        <td>{apartment.name}</td>
        <td onClick={stopPropagationHandle} className="apartment-menu">
          <Dropdown.Icon icon={RiMoreFill} size="md" placement="bottom-end" supportsMobile>
            <Dropdown.Item onClick={handleEdit} icon={() => <RiEditLine />}>
              <span>{t("common.edit")}</span>
            </Dropdown.Item>
            <Dropdown.Item onClick={handleRemove} icon={() => <RiDeleteBin5Line color="#ff2e3b" />}>
              <span>{t("common.remove")}</span>
            </Dropdown.Item>
          </Dropdown.Icon>
        </td>
      </tr>
      <style jsx>{`
        .row:hover {
          background: ${colors.grayscale_5};
        }
      `}</style>
    </>
  );
};
