import { useTranslation } from "@equiem/localisation-eq1";
import { Form, Skeleton, useTheme } from "@equiem/react-admin-ui";
import type { FieldProps } from "formik";
import { Field } from "formik";
import React, { useContext } from "react";
import { useBookableResourceRoomConfigsQuery } from "../../../generated/gateway-client";
import { resourceConfigurationToString } from "../../../lib/resourceTypeToString";
import { BookingModalInfo } from "../contexts/BookingModalInfoProvider";
import type { BookingFormValue } from "../models/BookingFormValue";

export const BookingFormRoomConfig: React.FC = () => {
  const theme = useTheme();
  const { resource } = useContext(BookingModalInfo);
  const { t } = useTranslation();
  const { data, loading, error } = useBookableResourceRoomConfigsQuery({
    variables: {
      uuid: resource.uuid,
    },
  });

  if (loading) {
    return (
      <Form.Group label={t("bookings.operations.spaceConfig")}>
        <Skeleton.Line width="100%" height="30px" borderRadius={theme.borderRadius} />
      </Form.Group>
    );
  }

  if (error != null || data?.bookableResource.roomConfigurations == null) {
    return null;
  }

  return data.bookableResource.roomConfigurations.length > 0 ? (
    <Form.Group
      label={t("bookings.operations.spaceConfig")}
      tooltipText={t("bookings.operations.spaceConfigTooltip")}
      showTooltip
    >
      <Field name="roomConfiguration" placeholder={t("bookings.operations.roomConfiguration")}>
        {({ field }: FieldProps<string, BookingFormValue>) => (
          <Form.Select {...field}>
            <option value="">{t("common.pleaseSelect")}...</option>
            {data.bookableResource.roomConfigurations.map((rc) => (
              <option key={rc.uuid} value={rc.uuid}>
                {resourceConfigurationToString(rc.name, t)}
              </option>
            ))}
          </Form.Select>
        )}
      </Field>
    </Form.Group>
  ) : null;
};
