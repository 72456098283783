import React, { useContext, useMemo } from "react";
import { DateTime } from "luxon";
import Link from "next/link";

import { getReceptionName, useSiteContext } from "@equiem/lib";
import { formatters, useTranslation } from "@equiem/localisation-eq1";
import { ButtonLink, Dropdown, Tag, useTheme } from "@equiem/react-admin-ui";
import { RiDeleteBinLine, RiMoreFill } from "@equiem/react-admin-ui/icons";

import { Modal } from "../../../contexts/ModalContext";
import { ReceptionDeleteWidget } from "../../reception/widgets/ReceptionDeleteWidget";

interface Props {
  uuid: string;
  key: string;
  receptionName?: string;
  buildingName?: string;
  levelName?: string;
  companyName?: string;
  suffix?: string;
  deletionTime?: number | null;
  deleted?: boolean | null;
  handleCancelDelete: (uuid: string) => Promise<void>;
}

const ReceptionItem: React.FC<Props> = ({
  deleted,
  deletionTime,
  buildingName,
  levelName,
  companyName,
  uuid,
  suffix,
  handleCancelDelete,
}) => {
  const { i18n, t } = useTranslation();
  const type = companyName != null ? t("visitors.common.tenant") : t("visitors.common.building");
  const modal = useContext(Modal);
  const { colors } = useTheme(true);

  const site = useSiteContext();
  const timezone = site.timezone;

  const handleDelete = () => {
    /* istanbul ignore next */
    modal.open("DeleteReception", uuid);
  };

  const resourceStatusText = (deletedAfter?: number | null) => {
    if (deletedAfter != null) {
      const deletionDate = DateTime.fromMillis(deletedAfter ?? Date.now(), { zone: timezone });
      return t("visitors.settings.pendingDeletion", { date: formatters.dateshort(deletionDate, i18n.language) });
    }
    return "";
  };

  const receptionName = useMemo(() => {
    return getReceptionName(
      {
        company: { name: companyName },
        building: { name: buildingName },
        buildingLevel: { name: levelName },
        suffix,
      },
      t,
    );
  }, [suffix, companyName, buildingName, levelName, t]);

  return (
    <>
      <div className="item mt-4 align-items-center">
        <div className="item-container">
          <span className="item-caption">{receptionName}</span>
          <div className="list-container mt-3">
            <Tag className="mr-2">{type.toUpperCase()}</Tag>
            <ul className="list">
              {buildingName != null && <li>{buildingName}</li>}
              {levelName != null && <li>{levelName}</li>}
              {companyName != null && <li>{companyName}</li>}
            </ul>
          </div>
        </div>

        <div className="actions d-flex">
          {deleted === true && (
            <div className="resource-status">
              <span>{resourceStatusText(deletionTime)}</span>
            </div>
          )}
          <Link href={`edit-reception/${uuid}`} passHref legacyBehavior>
            <ButtonLink variant="outline" size="sm">
              {t("common.edit")}
            </ButtonLink>
          </Link>
          <Dropdown.Icon className="actions-menu" icon={RiMoreFill} size="md" placement="bottom-end">
            {deleted === true ? (
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              <Dropdown.Item onClick={async () => handleCancelDelete(uuid)} icon={() => <RiDeleteBinLine />}>
                <span className="delete-icon">{t("visitors.settings.cancelDeletion")}</span>
              </Dropdown.Item>
            ) : (
              <Dropdown.Item onClick={handleDelete} icon={() => <RiDeleteBinLine color={colors.danger} />}>
                <span className="delete-icon">{t("common.delete")}</span>
              </Dropdown.Item>
            )}
          </Dropdown.Icon>
        </div>
        <ReceptionDeleteWidget uuid={uuid} receptionName={receptionName} />
      </div>
      <style jsx>
        {`
          .item {
            display: flex;
            justify-content: space-between;
            margin-top: 8px;
            border: 1px solid #e6e6e6;
            border-radius: 4px;
            padding: 16px;
          }
          .item-caption {
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
          }
          .list-container {
            display: flex;
          }
          .list {
            margin: 0;
            padding: 0;
            display: flex;
          }
          .list li {
            font-size: 14px;
            margin-right: 4px;
            padding-left: 4px;
            list-style: none;
            color: rgba(0, 0, 0, 0.6);
          }
          .list li::before {
            font-size: 10px;
            padding-right: 4px;
            content: "•";
            color: rgba(0, 0, 0, 0.6);
          }
          .delete-icon {
            margin-top: 1px;
          }
          .actions {
            gap: 10px;
          }
          .resource-status {
            text-transform: uppercase;
            margin-top: 6px;
            padding-right: 2px;
            font-size: 10px;
            font-weight: 600;
          }
          .resource-status span {
            border-radius: 2px;
            display: inline-block;
            padding: 0.3rem 0.5rem;
            background: #ffe7e9;
            color: #bd000c;
          }
        `}
      </style>
    </>
  );
};

export default ReceptionItem;
