import React, { useContext, useEffect, useState } from "react";

import { useTranslation } from "@equiem/localisation-eq1";
import { Button, Dropdown, Modal, Text } from "@equiem/react-admin-ui";

import { CreateRequestContext } from "../CreateRequestContext";

interface Props {
  show: boolean;
}

export const RequestCreatedWidget: React.FC<Props> = ({ show }) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const { values } = useContext(CreateRequestContext);
  const { setShow } = useContext(Dropdown.Context);

  useEffect(() => {
    setShowModal(show);
  }, [show]);

  const handleDone = () => {
    setShowModal(false);
    setShow(false);
  };

  return (
    <>
      <Modal.Dialog
        title={t("requests.create.requestSubmitted")}
        show={showModal}
        size="md"
        zIndex={1020}
        hideOnEsc
        centered
        supportsMobile
      >
        <Modal.Header closeButton={false} noBorder />
        <Modal.Body>
          <Text variant="text" size="large" className="m-0">
            <span
              dangerouslySetInnerHTML={{
                __html: t("requests.create.requestCreatedMessage", {
                  reference: `<strong>${values.reference}</strong>`,
                }) as string,
              }}
            />
          </Text>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleDone}>
            {t("common.done")}
          </Button>
        </Modal.Footer>
      </Modal.Dialog>
    </>
  );
};
