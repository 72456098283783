import React from "react";

import { EmptyState } from "@equiem/react-admin-ui";

import { NewDropdownButton } from "./NewDropdownButton";

interface Props {
  walkInDisabled?: boolean;
}

export const EmptyList: React.FC<Props> = ({ walkInDisabled = false }) => {
  return (
    <div className="container d-flex align-items-center mt-8">
      <div className="d-flex align-items-center flex-column">
        <EmptyState />
        <NewDropdownButton walkinDisabled={walkInDisabled} appointmentDisabled={!walkInDisabled} />
      </div>
      <style jsx>{`
        .container {
          height: auto;
        }
        .info {
          display: block;
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          padding: 0;
          margin: 0;
          color: rgba(0, 0, 0, 0.4);
          text-align: center;
        }
        .icon {
          border-radius: 50%;
          height: 64px;
          width: 64px;
          line-height: 64px;
          background-color: #f2f2f2;
          text-align: center;
        }
      `}</style>
    </div>
  );
};
