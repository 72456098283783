import React from "react";
import { Tag, type TagSize } from "@equiem/react-admin-ui";
import { useTranslation } from "@equiem/localisation-eq1";

import { BookingDiscountStatus } from "../../../generated/gateway-client";
import { transformDiscountStatus } from "../util/transformDiscountStatus";

const positiveStatuses = [BookingDiscountStatus.Live, BookingDiscountStatus.Upcoming];

export const BookingDiscountStatusTag: React.FC<{ status: BookingDiscountStatus; size?: TagSize }> = ({
  size,
  status,
}) => {
  const { t } = useTranslation();
  return (
    <Tag size={size} variant={positiveStatuses.includes(status) ? "positive" : "negative"}>
      {transformDiscountStatus(status, t)}
    </Tag>
  );
};
