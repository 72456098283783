import React, { useCallback, useEffect } from "react";
import { Form as EqForm, IconButton, Material, ProgressCircle, useToast } from "@equiem/react-admin-ui";
import { Field, useFormikContext } from "formik";
import type { FormValues } from "./helpers/FormValues";
import { stringNotEmpty } from "@equiem/lib";
import { RiCheckLine, RiCloseLine } from "@equiem/react-admin-ui/icons";
import { useTranslation } from "@equiem/localisation-eq1";

const TITLE_MAX_LENGTH = 20;
interface P {
  cancelHandler: () => void;
  enableIcon: boolean;
}
export const FormField: React.FC<P> = ({ cancelHandler, enableIcon }) => {
  const fm = useFormikContext<FormValues>();
  const toast = useToast();
  const { t } = useTranslation();

  const onEsc = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        cancelHandler();
      }
    },
    [cancelHandler],
  );

  useEffect(() => {
    window.addEventListener("keyup", onEsc);

    return () => {
      window.removeEventListener("keyup", onEsc);
    };
  }, [onEsc]);

  const submitIt = useCallback(async () => {
    if (stringNotEmpty(fm.values.name)) {
      await fm.submitForm();
    } else {
      toast.negative(t("bookings.settings.pleaseFillTheTitle"), { autoDismiss: true });
    }
  }, [fm, t, toast]);

  const onSelect = useCallback(
    (name: string) => {
      fm.setFieldValue("iconName", name).catch(console.error);
    },
    [fm],
  );

  const handle = useCallback(
    async (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === "Enter") {
        await submitIt();
      }
      if (event.key === "Escape") {
        cancelHandler();
      }
    },
    [submitIt, cancelHandler],
  );

  return (
    <div className="d-flex align-items-center w-100">
      {enableIcon && (
        <Material.Selector
          className={"icon-selection"}
          onSelect={onSelect}
          selectedIcon={fm.values.iconName ?? undefined}
        />
      )}
      <Field
        as={EqForm.Input}
        variant="sm"
        maxLength={TITLE_MAX_LENGTH}
        name="name"
        autoFocus
        disabled={fm.isSubmitting}
        onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
          handle(e).catch(console.log);
        }}
        className={enableIcon ? "mx-2" : "mr-2"}
      />
      <IconButton
        disabled={fm.isSubmitting}
        variant="primary"
        onClick={() => {
          submitIt().catch(console.log);
        }}
        className="mr-2 save-action"
        size="sm"
      >
        <RiCheckLine size="18px" />
      </IconButton>
      <IconButton
        variant="outline"
        onClick={() => {
          cancelHandler();
        }}
        className="cancel-action"
        size="sm"
      >
        <RiCloseLine size="18px" />
      </IconButton>
      {fm.isSubmitting && <ProgressCircle className="ml-3" size="xs" />}
    </div>
  );
};
