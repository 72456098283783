import { stringNotEmpty } from "@equiem/lib";

import type { IVideo } from "../components/VideoLinkInputNew";

export const getVideoUrl = (video: string | IVideo | null | undefined) => {
  if (typeof video === "string" && stringNotEmpty(video)) {
    return video;
  }
  if (typeof video?.videoUrl === "string" && stringNotEmpty(video.videoUrl)) {
    return video.videoUrl;
  }
  return null;
};
