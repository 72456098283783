import React from "react";

import { ReqMgtStatusType } from "../generated/requests-client";

import { SvgCompleted } from "./SvgCompleted";
import { SvgInProgress } from "./SvgInProgress";
import { SvgNotStarted } from "./SvgNotStarted";

interface P {
  type: ReqMgtStatusType;
  className?: string;
  size?: number;
}
export const StatusTypeIcon: React.FC<P> = ({ type, className = "", size }) => {
  return (
    <span className={className}>
      {type === ReqMgtStatusType.Completed && <SvgCompleted size={size} />}
      {type === ReqMgtStatusType.InProgress && <SvgInProgress size={size} />}
      {type === ReqMgtStatusType.NotStarted && <SvgNotStarted size={size} />}
    </span>
  );
};
