import type { ComponentProps } from "react";
import React from "react";
import { useTheme } from "../../contexts/Theme";

// eslint-disable-next-line @typescript-eslint/no-type-alias
export type Props = ComponentProps<"table"> & {
  sideBorders?: boolean;
  responsive?: boolean;
  responsiveWidth?: number;
  sticky?: {
    top: number;
    isStuck: boolean;
  };
};

export const Table: React.FC<Props> = ({
  children,
  sticky,
  className = "",
  // eslint-disable-next-line @typescript-eslint/no-magic-numbers
  responsiveWidth = 1024,
  sideBorders = true,
  responsive = false,
  ...props
}) => {
  const { colors } = useTheme();
  const width = responsive ? `${responsiveWidth}px` : "0px";

  return (
    <>
      <div>
        <table
          {...props}
          className={`${className} ${sticky?.isStuck === true ? "stuck" : ""}`.trim()}
          cellSpacing="0"
          cellPadding="0"
        >
          {children}
        </table>
      </div>
      <style jsx>{`
        div {
          overflow-x: ${responsive ? "auto" : "visible"};
        }
        @media screen and (min-width: ${width}) {
          div {
            overflow-x: visible;
          }
        }
        table {
          background-color: ${colors.white};
          border: ${sideBorders ? `solid 1px ${colors.border}` : "none"};
          border-top: solid 1px ${colors.border};
          border-bottom: solid 1px ${colors.border};
          border-radius: ${sideBorders ? "8px" : "0"};
          border-collapse: ${sideBorders ? "separate" : "collapse"};
          position: relative;
        }
        table :global(td) {
          border-bottom: 1px solid ${colors.border};
          text-align: left;
          padding: 0.5rem 1rem;
          line-height: 1rem;
          font-size: 0.875rem;
          color: #000;
          z-index: 1;
        }
        @media screen and (min-width: ${width}) {
          table :global(th) {
            position: ${sticky?.top == null ? "static" : "sticky"};
            top: ${sticky?.top != null ? `${sticky.top}px` : "auto"};
            z-index: ${sticky?.isStuck === true ? "3" : "inherit"};
          }
          table.stuck :global(th) {
            transform: translate(0, -1px);
            border-radius: 0;
          }
        }
        table :global(tr:last-child td) {
          border-bottom: 0;
        }
      `}</style>
    </>
  );
};
