import type { PropsWithChildren } from "react";
import React, { useCallback, useMemo, useState } from "react";
import Papa from "papaparse";
import { v4 as uuidv4 } from "uuid";

import { stringIsEmpty } from "@equiem/lib";

import parseAndValidateName from "../../../../helpers/parseAndValidateName";
import validateEmail from "../../../../helpers/validateEmail";

export interface BulkUploadVisitor {
  index: string;
  isChecked: boolean;
  firstName: string;
  lastName: string;
  email?: string;
  companyName?: string;
  emailError?: string;
  companyNameError?: string;
  firstNameError?: string;
  lastNameError?: string;
}

export interface BulkUploadVisitorCsvImport {
  FirstName: string;
  LastName: string;
  Email?: string;
  Company?: string;
}

export enum VisitorBulkUploadStep {
  Select = 0,
  Edit = 1,
}

interface Props {
  confirmCancel: () => void;
  onClose: () => void;
}

export interface BulkUploadVisitorsContext {
  close: () => void;
  submit: () => void;
  uploadVia?: "File" | "String";
  visitors: BulkUploadVisitor[];
  updateVisitorRow: (params: {
    index: string;
    firstName: string;
    lastName: string;
    email?: string;
    companyName?: string;
    firstNameError?: string;
    lastNameError?: string;
    emailError?: string;
    companyNameError?: string;
  }) => void;
  rowsAreSelected: boolean;
  allRowsAreSelected: boolean;
  setVisitors: (_visitors: BulkUploadVisitor[]) => void;
  selectRows: (_ids: string[]) => void;
  removeRows: (_ids: string[]) => void;
  parseCsvString: (_string: string) => void;
  setUploadVia: (_value: "File" | "String" | undefined) => void;
  setStep: (_value: VisitorBulkUploadStep) => void;
  step: VisitorBulkUploadStep;
}

export const BulkUploadVisitorsContext = React.createContext<BulkUploadVisitorsContext>(
  {} as BulkUploadVisitorsContext,
);

export function useBulkUploadVisitorsContext() {
  return React.useContext(BulkUploadVisitorsContext);
}

export const BulkUploadProvider: React.FC<PropsWithChildren<Props>> = ({ children, onClose, confirmCancel }) => {
  const [visitors, setVisitors] = useState<BulkUploadVisitor[]>([]);
  const [uploadVia, setUploadVia] = useState<"File" | "String">();
  const [step, setStep] = useState<VisitorBulkUploadStep>(VisitorBulkUploadStep.Select);

  const allRowsAreSelected = useMemo(() => {
    return visitors.length > 0 && visitors.filter((i) => i.isChecked).length === visitors.length;
  }, [visitors]);

  const rowsAreSelected = useMemo(() => {
    return visitors.filter((i) => i.isChecked).length > 0;
  }, [visitors]);

  const selectRows = useCallback((ids: string[]) => {
    setVisitors((prev) =>
      prev.map((p) => {
        return { ...p, isChecked: ids.includes(p.index) };
      }),
    );
  }, []);

  const removeRows = useCallback((ids: string[]) => {
    setVisitors((prev) => {
      return prev.filter((p) => !ids.includes(p.index));
    });
  }, []);

  const updateVisitorRow = (params: {
    index: string;
    firstName: string;
    lastName: string;
    email?: string;
    companyName?: string;
    firstNameError?: string;
    lastNameError?: string;
    emailError?: string;
    companyNameError?: string;
  }) => {
    setVisitors((prev) =>
      prev.map((p) => {
        if (p.index === params.index) {
          return {
            ...p,
            firstName: params.firstName,
            lastName: params.lastName,
            email: params.email,
            companyName: params.companyName,
            firstNameError: params.firstNameError,
            lastNameError: params.lastNameError,
            emailError: params.emailError,
            companyNameError: params.companyNameError,
          };
        } else {
          return p;
        }
      }),
    );
  };

  const map = (x: { 0: string; 1: string; 2: string; 3: string }) => {
    const parsedFirstName = parseAndValidateName(x[0]);
    const parsedLastName = parseAndValidateName(x[1]);
    const _: BulkUploadVisitor = {
      firstName: parsedFirstName.parsedName ?? x[0],
      lastName: parsedLastName.parsedName ?? x[1],
      companyName: x[2],
      email: x[3],
      index: uuidv4(),
      isChecked: false,
      firstNameError: parsedFirstName.errorMessage,
      lastNameError: parsedLastName.errorMessage,
      companyNameError: stringIsEmpty(x[2])
        ? undefined
        : (x[2] as string).length > 100
        ? "100 characters maximum"
        : undefined,
    };

    if (_.email != null && !stringIsEmpty(_.email) && !validateEmail(_.email)) {
      _.emailError = "Invalid Email";
    }
    return _;
  };

  const parseCsvString = (str: string) => {
    setVisitors([]);
    setUploadVia(undefined);
    Papa.parse<{ 0: string; 1: string; 2: string; 3: string }>(str, {
      header: false,
      complete: function (results) {
        if (results.data.length > 0) {
          setUploadVia("String");
          setVisitors(results.data.map(map));
        }
      },
    });
  };

  return (
    <BulkUploadVisitorsContext.Provider
      value={{
        close: () => confirmCancel(),
        submit: onClose,
        visitors: visitors,
        removeRows: removeRows,
        selectRows: selectRows,
        updateVisitorRow: updateVisitorRow,
        allRowsAreSelected: allRowsAreSelected,
        rowsAreSelected: rowsAreSelected,
        setVisitors: setVisitors,
        parseCsvString: parseCsvString,
        uploadVia: uploadVia,
        setUploadVia: setUploadVia,
        step: step,
        setStep: setStep,
      }}
    >
      {children}
    </BulkUploadVisitorsContext.Provider>
  );
};
