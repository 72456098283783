import { DateTime } from "luxon";

import { useSiteContext } from "@equiem/lib";

export const useIsAppointmentExpiredByDate = (date: string | null): boolean => {
  const { timezone } = useSiteContext();

  if (date == null || date.length === 0) {
    return true;
  }

  const { day: nowDay, month: nowMonth, year: nowYear } = DateTime.local({ zone: timezone });

  const { day, month, year } = DateTime.fromFormat(date, "yyyy-MM-dd");

  return day < nowDay || month < nowMonth || year < nowYear;
};
