import * as yup from "yup";

import type { TFunction } from "@equiem/localisation-eq1";

const getPassAccessValidationSchema = (t: TFunction) => ({
  passAccessStartTime: yup.number().notOneOf([0], t("visitors.settings.validationActivationTime") ?? ""),
  passAccessEndTime: yup
    .number()
    .notOneOf([0], t("visitors.settings.validationDeactivationTime") ?? "")
    .test("is-after-start-time", t("visitors.settings.validationDeactivationTimeAfter") ?? "", function (value) {
      const { passAccessStartTime } = this.parent as { passAccessStartTime?: number };

      if (value != null && passAccessStartTime != null && value > 0 && passAccessStartTime > 0) {
        return value > passAccessStartTime;
      }

      return true;
    }),
});

export const getValidationSchema = (withPassAccessValidation: boolean, t: TFunction) =>
  yup.object().shape({
    emailFooterText: yup.string().test(
      "len",
      t("visitors.settings.validationEmailFooterMax", { max: 1500 }) ?? "",

      (val) => {
        // eslint-disable-next-line require-unicode-regexp
        return val !== undefined ? val.replace(/(<([^>]+)>)/gi, "").trim().length <= 1500 : true;
      },
    ),
    ...(withPassAccessValidation ? getPassAccessValidationSchema(t) : {}),
    passPrintingConfiguration: yup.object().shape({
      marginTop: yup
        .number()
        .min(0, t("visitors.settings.validationMarginTopMax", { max: 100 }) ?? "")
        .max(100, t("visitors.settings.validationMarginTopMax", { max: 100 }) ?? ""),
    }),
  });
