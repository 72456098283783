import { relayStylePagination } from "@apollo/client/utilities/policies/pagination";
import type { TypedTypePolicies } from "../generated/apollo-helpers";
import { type TypePolicies } from "@apollo/client";
import uniqBy from "lodash.uniqby";

export const commonTypePolicies: TypePolicies = {
  Destination: {
    fields: {
      infrastructure: {
        merge: true,
      },
      settings: {
        merge: true,
      },
      viewerRelations: {
        merge: true,
      },
    },
  },
  CompanyDestination: {
    fields: {
      buildingLevels: {
        merge: (existing: null | unknown[], incoming: null | unknown[]) => {
          return uniqBy([...(incoming != null ? incoming : []), ...(existing != null ? existing : [])], "__ref");
        },
      },
    },
    keyFields: ["companyUuid", "destinationUuid"],
  },
  CompanyV2: {
    fields: {
      companyDestination: {
        merge: true,
      },
    },
  },
  Query: {
    fields: {
      viewer: {
        merge: true,
      },
    },
  },
  SegmentationUserEdge: {
    keyFields: ["userUuid", "siteUuid"],
  },
  User: {
    fields: {
      currentDestination: {
        merge: true,
      },
    },
  },
};

export const typePolicies: TypedTypePolicies = {
  ...commonTypePolicies,
  Query: {
    fields: {
      reqMgt: { merge: true },
      visitorAppointments: relayStylePagination(["beginDate", "endDate", "as"]),
      visitorAppointmentsByReception: relayStylePagination([
        "as",
        "receptionUuid",
        "search",
        "visitorStatus",
        "beginDate",
        "endDate",
      ]),
      visitorsByReception: relayStylePagination([
        "as",
        "receptionUuid",
        "search",
        "visitorStatus",
        "beginDate",
        "endDate",
      ]),
      visitorsByAllBuildingReceptions: relayStylePagination([
        "as",
        "receptionUuid",
        "search",
        "visitorStatus",
        "beginDate",
        "endDate",
      ]),
      visitorReceptions: relayStylePagination(["search", "as"]),
      viewer: {
        merge: true,
      },
    },
  },
};
