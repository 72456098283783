import React from "react";
import { RiArrowLeftSLine, RiArrowRightSLine } from "react-icons/ri";
import { useTranslation } from "@equiem/localisation-eq1";

import { useTheme } from "../../contexts/Theme";
import { Button } from "../Button/Button";

// eslint-disable-next-line @typescript-eslint/no-type-alias
export type Props = {
  loading?: boolean;
  prev: () => unknown;
  next: () => unknown;
  reset: () => unknown;
};

export const CalendarNavigation: React.FC<Props> = ({ loading, prev, next, reset }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  return (
    <>
      <div className="navigation">
        <Button className="prev-week pr-0" type="button" variant="link" disabled={loading} onClick={prev}>
          <RiArrowLeftSLine />
        </Button>
        <Button className="today" type="button" variant="link" disabled={loading} onClick={reset}>
          <small>{t("common.today")}</small>
        </Button>
        <Button className="next-week pl-0" type="button" variant="link" disabled={loading} onClick={next}>
          <RiArrowRightSLine />
        </Button>
      </div>
      <style jsx>{`
        .navigation {
          display: inline-block;
          border: 1px solid ${theme.colors.border};
          border-radius: ${theme.borderRadius};
        }
        .navigation :global(button) {
          padding-top: 6px;
          padding-bottom: 6px;
        }
        .navigation small {
          text-transform: uppercase;
        }
      `}</style>
    </>
  );
};
