import React, { useMemo } from "react";

import { useSaferFormikContext, useSiteContext } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";

import type { GeneralSettingsFormValues } from "../GeneralSettingsValues";

const FONT_COLOR_LIGHT = "white";
const FONT_COLOR_DARK = "#202124";

const getFontColor = (color: string): string => {
  if (!/^#[0-9A-F]{6}$/i.test(color)) {
    return FONT_COLOR_DARK;
  }
  const redLumaCoefficient = 299;
  const greenLumaCoefficient = 587;
  const blueLumaCoefficient = 114;
  const lightLumaThreshold = 170;

  const hexRegExp = /#([0-9abcdef]{2})([0-9abcdef]{2})([0-9abcdef]{2})/i;
  const [, r, g, b] = hexRegExp.exec(color)!;
  const brightness =
    (parseInt(r, 16) * redLumaCoefficient +
      parseInt(g, 16) * greenLumaCoefficient +
      parseInt(b, 16) * blueLumaCoefficient) /
    1000;

  return brightness > lightLumaThreshold ? FONT_COLOR_DARK : FONT_COLOR_LIGHT;
};

export const GooglePreview = ({ qrCode }: { qrCode: string }) => {
  const { t } = useTranslation();
  const { values } = useSaferFormikContext<GeneralSettingsFormValues>();
  const site = useSiteContext();

  const fontColor = useMemo(() => getFontColor(values.walletPassBackgroundColor), [values.walletPassBackgroundColor]);

  return (
    <>
      <div className="pass-view-group">
        <div className="pass-view">
          <div className="head">
            <div className="title-bar">
              <div className="logo-wrapper">
                <div className="logo">
                  <img className="logo-image" src={site.logo ?? undefined} />
                </div>
              </div>
              <div className="logo-text">{t("visitors.settings.walletPass.preview.logoText")}</div>
            </div>
            <div className="dim-divider"></div>
            <div className="header">
              <div className="sub-header">{t("visitors.settings.walletPass.preview.appointmentTitle")}</div>
              <div className="main-header">{t("visitors.settings.walletPass.preview.appointmentTitle")}</div>
            </div>
          </div>
          <div className="row">
            <div className="row-item start-item">
              <div className="row-label">{t("visitors.settings.walletPass.preview.location")}</div>
              <div className="row-label-value">2 Kingdom Street, London, W2 6BD</div>
            </div>
          </div>
          <div className="row-dim-divider"></div>
          <div className="row">
            <div className="row-item start-item">
              <div className="row-label">{t("visitors.settings.walletPass.preview.host")}</div>
              <div className="row-label-value">Chet Manek</div>
            </div>
            <div className="row-item end-item">
              <div className="row-label">{t("visitors.settings.walletPass.preview.company")}</div>
              <div className="row-label-value">British Land</div>
            </div>
          </div>
          <div className="row-dim-divider"></div>
          <div className="row">
            <div className="row-item start-item">
              <div className="row-label">{t("visitors.settings.walletPass.preview.date")}</div>
              <div className="row-label-value">2.02.2023</div>
            </div>
            <div className="row-item end-item">
              <div className="row-label">{t("visitors.settings.walletPass.preview.time")}</div>
              <div className="row-label-value">11:30</div>
            </div>
          </div>
          <div className="barcode mt-5 mb-5">
            <div className="barcode-wrapper">
              <img className="barcode-img" src={qrCode} />
            </div>
          </div>
        </div>
      </div>
      <style jsx>{`
        .pass-view-group {
          align-self: center;
          position: relative;
          color: ${fontColor};
          width: 280px;
          border-radius: 24px;
          overflow: hidden;
          box-shadow: 0 1px 7px 1px rgb(0 0 0 / 10%), 0 3px 9px 2px rgb(0 0 0 / 12%), 0 4px 4px -3px rgb(0 0 0 / 20%);
        }
        .pass-view {
          display: flex;
          flex-direction: column;
          border-radius: 24px 24px 0 0;
          box-shadow: inset 0 0 0 1px #ffffff33;
          background-color: ${values.walletPassBackgroundColor};
        }
        .pass-view .head {
          font-weight: 500;
          text-align: left;
          margin-top: 3px;
          margin-bottom: 10px;
          border-radius: 24px 24px 6px 6px;
        }
        .pass-view .title-bar {
          display: flex;
          flex-direction: row;
          align-items: center;
          border-radius: 24px 24px 6px 6px;
        }
        .pass-view .logo-wrapper {
          padding: 6px;
          margin: 6px 0 6px 6px;
          border-radius: 100%;
        }
        .pass-view .logo {
          height: 24px;
          width: 24px;
          flex-grow: 0;
          flex-shrink: 0;
          background-color: #ffffffdd;
          overflow: hidden;
          border-radius: 100%;
        }
        .pass-view .logo-image {
          object-fit: cover;
          border: 0;
          height: 100%;
          max-width: 100%;
        }
        .pass-view .logo-text {
          flex: auto;
          padding: 6px;
          margin-right: 12px;
          font-size: 14px;
          letter-spacing: 0.2px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          border-radius: 6px;
          text-transform: uppercase;
        }
        .pass-view .dim-divider {
          height: 1px;
          background-color: #ffffff15;
          margin-bottom: 10px;
        }
        .pass-view .row-dim-divider {
          height: 1px;
          background-color: #ffffff15;
          margin: 9px;
        }
        .pass-view .sub-header {
          padding: 1px 12px;
          font-size: 14px;
        }
        .pass-view .main-header {
          font-size: 20px;
          padding: 1px 12px;
          letter-spacing: 0;
          font-weight: normal;
          line-height: 30px;
          word-break: break-word;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .pass-view .row {
          display: flex;
          flex-direction: row;
        }
        .pass-view .row-item {
          font-weight: 500;
          display: inline-flex;
          flex-direction: column;
          overflow: hidden;
          border-radius: 6px;
        }
        .pass-view .start-item {
          flex: 1 1 0;
          align-items: flex-start;
          text-align: left;
        }
        .pass-view .end-item {
          flex: 1 1 0;
          align-items: flex-end;
          text-align: right;
        }
        .pass-view .row-label {
          width: 100%;
          flex: 1 0 50%;
          padding: 6px 12px 0 12px;
          font-size: 12px;
          letter-spacing: 0;
          white-space: nowrap;
          overflow: hidden;
          line-height: 13px;
          margin-bottom: 1px;
        }
        .pass-view .row-label-value {
          width: 100%;
          flex: 1 0 50%;
          padding: 0 12px 6px 12px;
          font-size: 14px;
          letter-spacing: 0;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
        .pass-view .barcode {
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 5px 0;
          overflow: hidden;
        }
        .pass-view .barcode-wrapper {
          margin: 0 30px;
          background-color: white;
          border-radius: 12px;
          display: inline-flex;
        }
        .pass-view .barcode-img {
          display: block;
          margin: 14px;
          width: 110px;
          height: 110px;
        }
      `}</style>
    </>
  );
};
