import { useSiteContext } from "@equiem/lib";
import { formatters, useTranslation } from "@equiem/localisation-eq1";
import { Alert, useTheme } from "@equiem/react-admin-ui";
import { RiErrorWarningLine } from "@equiem/react-admin-ui/icons";
import React from "react";
import type { BookableResourceDeletionInfoQuery } from "../../../../generated/gateway-client";

interface Props {
  info: BookableResourceDeletionInfoQuery["bookableResource"];
}

export const DeleteResourceInfo: React.FC<Props> = ({ info }) => {
  const { i18n, t } = useTranslation();
  const { colors } = useTheme();
  const site = useSiteContext();
  const numChildren = info.children.length + 1;
  const numParents = info.parents.length + 1;
  const numBookings = info.totalActiveBookings;
  const timezone = info.building?.timezone ?? site.timezone;

  return (
    <>
      <div>
        <h1>{t("bookings.resources.deletionInfoTitle", { name: info.name })}</h1>
        <Alert
          size="large"
          variant="gray"
          icon={<RiErrorWarningLine size={18} color={colors.grayscale[50]} />}
          message={`${t("bookings.resources.deletionInfoActiveBookings", { count: numBookings, name: info.name })}
          ${
            info.lastActiveBookingEndDate != null
              ? t("bookings.resources.deletionInfoCanBeScheduled", {
                  date: formatters.datelong(info.lastActiveBookingEndDate, i18n.language, { timezone }),
                })
              : ""
          }`.trimEnd()}
          className="my-4"
        />
        <p>{t("bookings.resources.deletionInfoWillHappen")}:</p>
        <p>
          <strong>{t("bookings.resources.deletionInfoImmediately")}:</strong>
        </p>
        <ul>
          <li>{t("bookings.resources.deletionInfoImmediatelyNoCreateBookings")}</li>
          <li>{t("bookings.resources.deletionInfoImmediatelyNoEditBookings")}</li>
          <li>{t("bookings.resources.deletionInfoImmediatelyNoBrowsing")}</li>
          <li>{t("bookings.resources.deletionInfoImmediatelyViewAndCancelBookings")}</li>
        </ul>
        <p>
          <strong>{t("bookings.resources.deletionInfoAfterDate")}:</strong>
        </p>
        <ul>
          <li>{t("bookings.resources.deletionInfoAfterDateNoListing")}</li>
          <li>{t("bookings.resources.deletionInfoAfterDateNoCancelDeletion")}</li>
          {numChildren > 0 && <li>{t("bookings.resources.deletionInfoAfterDateChildren", { count: numChildren })}</li>}
          {numParents > 0 && <li>{t("bookings.resources.deletionInfoAfterDateParents", { count: numParents })}</li>}
          <li>{t("bookings.resources.deletionInfoAfterDateHistoricalBookings")}</li>
        </ul>
        <p>{t("bookings.resources.deletionInfoSummary")}</p>
      </div>
      <style jsx>{`
        ul {
          margin: 0;
          padding: 0;
          padding-inline-start: 1.5em;
        }
        li {
          line-height: 1.5;
        }
      `}</style>
    </>
  );
};
