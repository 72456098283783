import React from "react";
import type { ReactNode } from "react";
import { RiCloseLine } from "../../admin-icons";
import { useTheme } from "../../contexts/Theme";
import { Text } from "../Text/Text";

export interface TabItem {
  title: string;
  key: string;
  disabled?: boolean;
  onClose?: () => void;
}

export interface TabsProps {
  items: TabItem[];
  selected: string;
  onSelect: (key: string) => void;
  tabLinks?: boolean;
  actionComponent?: ReactNode;
  preventPropagateClick?: boolean;
}

interface TabProps {
  item: TabItem;
  selected: boolean;
  tabLinks: boolean;
  preventPropagateClick: boolean;
  handleSelect: () => void;
}

const Tab: React.FC<TabProps> = ({ item, selected, tabLinks, preventPropagateClick, handleSelect }) => {
  const { colors } = useTheme();

  const selectedClass = selected ? "tab--selected" : "";
  const disabledClass = item.disabled === true ? "tab--disabled" : "";

  const handleClose = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    item.onClose?.();
  };

  const handleAnchorClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    const modifierKeyPressed = e.ctrlKey || e.shiftKey || e.altKey || e.metaKey;
    if (tabLinks && modifierKeyPressed) {
      // use the default link-click behaviour
      return;
    }

    if (preventPropagateClick) {
      e.preventDefault();
      e.stopPropagation();
    }

    handleSelect();
  };

  return (
    <>
      <div className={`tab ${selectedClass} ${disabledClass}`}>
        <a
          role="tab"
          aria-selected={selected}
          className="tab-inner"
          href={tabLinks ? item.key : undefined}
          onClick={handleAnchorClick}
        >
          <Text variant="navigation">{item.title}</Text>
          {item.onClose != null && (
            <div onClick={handleClose} role="button" aria-label="close">
              <RiCloseLine size={16} className="close-icon" />
            </div>
          )}
        </a>
      </div>
      <style jsx>{`
        .tab {
          display: flex;
          align-items: center;
          position: relative;
          cursor: pointer;
          color: ${colors.grayscale[60]};
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .tab--selected {
          color: ${colors.grayscale[100]};
        }
        .tab--selected:after {
          content: "";
          background-color: ${colors.primary};
          position: absolute;
          bottom: 0;
          left: 0.5rem;
          right: 0.5rem;
          height: 2px;
        }
        .tab--disabled {
          color: ${colors.grayscale[60]};
          border-bottom: none;
        }
        .tab-inner {
          padding: 0.5rem;
          display: flex;
          align-items: center;
          color: unset;
          text-decoration: unset;
        }
        .tab:not(.tab--disabled) .tab-inner:hover {
          border-radius: 4px;
          background: ${colors.transparent.black[5]};
        }

        :global(.close-icon) {
          margin-left: 0.25rem;
          color: ${colors.grayscale[60]};
          display: block;
        }
        :global(.close-icon):hover {
          color: ${colors.grayscale[100]};
        }
      `}</style>
    </>
  );
};

export const Tabs: React.FC<TabsProps> = ({
  items,
  onSelect,
  selected,
  tabLinks = false,
  preventPropagateClick = true,
  actionComponent,
}) => {
  const { colors } = useTheme();

  const handleSelect = (item: TabItem) => () => item.disabled !== true ? onSelect(item.key) : undefined;

  const closeableTabs = items.filter((i) => i.onClose != null);

  return (
    <>
      <div className="tabs">
        <div className="tabs-group">
          {items
            .filter((i) => i.onClose == null)
            .map((item) => (
              <Tab
                item={item}
                selected={selected != null && selected.split("?")[0] === item.key}
                tabLinks={tabLinks}
                handleSelect={handleSelect(item)}
                preventPropagateClick={preventPropagateClick}
                key={item.key}
              />
            ))}
        </div>
        {(closeableTabs.length > 0 || actionComponent != null) && <div className="divider" />}
        {closeableTabs.length > 0 && (
          <div className="tabs-group">
            {items
              .filter((i) => i.onClose != null)
              .map((item) => (
                <Tab
                  item={item}
                  selected={selected != null && selected.split("?")[0] === item.key}
                  tabLinks={false}
                  preventPropagateClick={preventPropagateClick}
                  handleSelect={handleSelect(item)}
                  key={item.key}
                />
              ))}
          </div>
        )}
        {actionComponent}
      </div>
      <style jsx>{`
        .tabs {
          display: flex;
          align-items: center;
          height: 3rem;
          gap: 1rem;
          width: 100%;
        }

        .tabs-group {
          display: flex;
          height: 100%;
          margin: 0 -0.5rem;
        }

        .divider {
          width: 1px;
          height: calc(100% - 1rem);
          background: ${colors.grayscale[10]};
        }
      `}</style>
    </>
  );
};
