import { useTranslation } from "@equiem/localisation-eq1";
import type { ComponentProps, FC } from "react";
import React, { useState } from "react";
import { RiCloseFill, RiStarFill } from "../../admin-icons";
import { stringNotEmpty } from "../../util/stringNotEmpty";
import { Avatar } from "../Avatar/Avatar";
import type { BaseButtonSize } from "../Button/AdminButton";
import { AdminButton as Button } from "../Button/AdminButton";
import { FormInput } from "../Form/FormInput/FormInput";

// eslint-disable-next-line @typescript-eslint/no-type-alias
export type Props = ComponentProps<"div"> & {
  firstName: string;
  lastName: string;
  email?: string;
  profileIconUrl?: string;
  companyName: string;
  className?: string;
  showButton?: boolean;
  showButtonOnHover?: boolean;
  buttonSize?: BaseButtonSize;
  buttonVariant?: "ghost" | "secondary" | "primary" | "outline";
  buttonInverted?: boolean;
  buttonType?: "button" | "submit";
  defaultCardId?: string;
  buttonText?: string | null;
  additionalButtons?: React.ReactNode;
  onButtonClick?: () => void;
  onButtonFocus?: () => void;
  onDeleteButtonClick?: () => void;
  onSaveCardId?: (cardId: string) => void;
  buttonElement?: React.ReactNode;
  buttonRound?: boolean;
  isButtonDisabled?: boolean;
  isDeleteVisibleAlways?: boolean;
  deleteButtonVariant?: "ghost" | "secondary" | "primary" | "outline";
  deleteButton?: React.ReactNode;
  isCardInModal?: boolean;
  transparentBackground?: boolean;
  showStar?: boolean;
  showBorder?: boolean;
  showCardId?: boolean;
  cardIdDisabled?: boolean;
  children?: React.ReactNode;
};

// eslint-disable-next-line complexity
export const MemberCard: FC<Props> = ({
  firstName,
  lastName,
  companyName,
  email,
  profileIconUrl,
  buttonVariant,
  buttonType = "button",
  showButton = false,
  buttonSize,
  showButtonOnHover = false,
  isButtonDisabled = false,
  buttonText,
  defaultCardId,
  onButtonClick,
  onButtonFocus,
  onDeleteButtonClick,
  onSaveCardId,
  buttonInverted = false,
  additionalButtons,
  isCardInModal = false,
  transparentBackground = false,
  showStar = false,
  showBorder = true,
  showCardId = false,
  cardIdDisabled = false,
  buttonRound = false,
  deleteButtonVariant = "ghost",
  isDeleteVisibleAlways = false,
  deleteButton,
  buttonElement,
  children,
  ...props
}) => {
  const { t } = useTranslation();
  const [isHover, setHover] = useState(false);
  const [cardId, setCardId] = useState("");

  const className = `card ${props.className ?? ""} ${transparentBackground ? "card--no-background" : ""}`;

  const hasCompanyAndEmail = companyName.length > 0 && (email?.length ?? 0) > 0;
  const hasChildren = Boolean(children);
  return (
    <div {...props} className={className} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
      <div className="header">
        <Avatar
          size="medium"
          imageUrl={profileIconUrl}
          firstName={firstName}
          lastName={lastName}
          badge={showStar ? <RiStarFill size={10} /> : undefined}
        />
      </div>
      <div className="body">
        <div className="name">
          {firstName} {lastName}
        </div>
        <div className="details">
          {companyName} {hasCompanyAndEmail && " • "} {email}
        </div>
      </div>
      <div className="flex">
        {showCardId && cardIdDisabled && (
          <FormInput
            className={"card-input card-input-fill"}
            name={email}
            value={defaultCardId}
            placeholder={t("visitors.reception.enterCardId") as string}
            style={{ marginRight: "8px" }}
            onChange={(e: React.FormEvent<HTMLInputElement>) => {
              setCardId(e.currentTarget.value);
              onSaveCardId?.(e.currentTarget.value);
            }}
            disabled={cardIdDisabled}
          />
        )}
        {additionalButtons}
        {!hasChildren &&
          ((showButton && (stringNotEmpty(buttonText) || buttonElement != null)) || (showButtonOnHover && isHover)) && (
            <div className="action">
              <Button
                size={buttonSize ?? "sm"}
                variant={buttonVariant}
                type={buttonType}
                inverted={buttonInverted}
                shape={buttonRound ? "round" : "default"}
                disabled={isButtonDisabled}
                onClick={() => {
                  onButtonClick?.();
                }}
                onFocus={() => {
                  onButtonFocus?.();
                }}
              >
                {buttonElement != null ? buttonElement : buttonText}
              </Button>
            </div>
          )}
        {hasChildren && <div className="action d-flex">{children}</div>}
        {showCardId && !cardIdDisabled && (
          <FormInput
            className={`card-input ${cardId.length > 0 && "card-input-fill card-input-hover"}`}
            name={email}
            value={defaultCardId != null ? defaultCardId : cardId}
            placeholder={t("visitors.reception.enterCardId") as string}
            style={{ marginRight: "36px" }}
            onChange={(e: React.FormEvent<HTMLInputElement>) => {
              setCardId(e.currentTarget.value);
              onSaveCardId?.(e.currentTarget.value);
            }}
            onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) =>
              e.key === "Enter" && (e.target as HTMLElement).blur()
            }
            maxLength={20}
          />
        )}
        {onDeleteButtonClick != null && (isHover || isDeleteVisibleAlways) && (
          <div className="action delete-button delete delete-card">
            <Button size="md" variant={deleteButtonVariant} type="button" onClick={onDeleteButtonClick} shape="round">
              {deleteButton != null ? deleteButton : <RiCloseFill size={20} />}
            </Button>
          </div>
        )}
      </div>
      <style jsx>
        {`
          .card {
            display: grid;
            grid-template-columns: 24px 1fr auto;
            border-radius: 8px;
            background-color: #fff;
            padding: 12px 16px 12px 12px;
            border: ${showBorder ? "1px solid #e6e6e6" : "none"};
            align-items: center;
            justify-content: flex-start;
            grid-column-gap: 8px;
          }

          .card.card--no-background {
            background-color: unset;
            border: none;
            min-height: 33px;
            padding: unset;
            margin: 8px 0;
          }

          .body {
            display: flex;
            flex-direction: column;
            justify-content: center;
            overflow: hidden;
            padding-left: 8px;
          }

          :global(.card-input) {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            height: 30px !important;
          }
          :global(.card-input-fill) {
            background: #f2f2f2 !important;
            color: #666666 !important;
          }
          :global(.card-input-hover:hover) {
            background-repeat: no-repeat !important;
            background-position: calc(100% - 10px) center !important;
            background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0nMTgnIGhlaWdodD0nMTUnIHZpZXdCb3g9JzAgMCA0IDE0JyBmaWxsPScjNjY2NjY2JyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnPjxwYXRoIGQ9J00yLjI3NiA5LjY2NzM3TDkuMDM3MzMgMi45MDYwNEw4LjA5NDY3IDEuOTYzMzdMMS4zMzMzMyA4LjcyNDcxTDEuMzMzMzMgOS42NjczN0gyLjI3NlpNMi44Mjg2NyAxMS4wMDA3SDBMMCA4LjE3MjA0TDcuNjIzMzMgMC41NDg3MDdDNy43NDgzNSAwLjQyMzcyNiA3LjkxNzg5IDAuMzUzNTE2IDguMDk0NjcgMC4zNTM1MTZDOC4yNzE0NCAwLjM1MzUxNiA4LjQ0MDk4IDAuNDIzNzI2IDguNTY2IDAuNTQ4NzA3TDEwLjQ1MiAyLjQzNDcxQzEwLjU3NyAyLjU1OTczIDEwLjY0NzIgMi43MjkyNiAxMC42NDcyIDIuOTA2MDRDMTAuNjQ3MiAzLjA4MjgyIDEwLjU3NyAzLjI1MjM2IDEwLjQ1MiAzLjM3NzM3TDIuODI4NjcgMTEuMDAwN1pNMCAxMi4zMzRMMTIgMTIuMzM0VjEzLjY2NzRMMCAxMy42Njc0TDAgMTIuMzM0WicgZmlsbD0nIzY2NjY2NicvPjwvc3ZnPgo=") !important;
          }
          :global(.card-input-fill:focus) {
            background: initial !important;
            color: initial !important;
          }

          .delete-button {
            right: 0;
            align-self: center;
          }

          .flex {
            display: flex;
            position: relative;
          }

          .card-padding {
            padding-top: 8px;
            padding-bottom: 8px;
          }

          .name {
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: ${isCardInModal ? "180px" : "auto"};
          }

          .details {
            color: #666666;
            font-size: 12px;
            line-height: 16px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: ${isCardInModal ? "180px" : "auto"};
          }

          .header {
            position: relative;
            margin: auto auto auto 0;
            padding-right: 8px;
          }

          .star-icon {
            position: absolute;
            top: -4px;
            right: 4px;
          }

          .star-icon div {
            border-radius: 8px;
            width: 16px;
            height: 16px;
            background: red;
            display: flex;
            justify-content: center;
            align-items: center;
            background: linear-gradient(
                277.52deg,
                #ff794c -65.2%,
                rgba(225, 85, 162, 0.37) 76.96%,
                rgba(225, 85, 162, 0) 131.25%
              ),
              linear-gradient(119.58deg, rgba(0, 0, 170, 0.9) -22.08%, #c931ff 231.23%), #ffffff;
          }
        `}
      </style>
    </div>
  );
};
