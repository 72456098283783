import React, { useMemo, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { Field } from "formik";
import { DateTime } from "luxon";

import { CKEditorNoSSR, useSaferFormikContext } from "@equiem/lib";
import { formatters, useTranslation } from "@equiem/localisation-eq1";
import { Button, Form as EqForm, Text, useTheme } from "@equiem/react-admin-ui";

import { PrintedPass } from "../../../components/PrintedPass";
import type { BarcodeType } from "../../../generated/visitors-client";

import type { BuildingSettingsValues } from "./BuildingSettingsValues";
import { ImageUploader } from "./ImageUploader";

export interface PassDurationTime {
  label: string;
  value: number;
}

interface Props {
  codeType?: BarcodeType | null;
  prefix?: string | null;
}

export const BuildingSettingsForm: React.FC<Props> = ({ codeType, prefix }) => {
  const { i18n, t } = useTranslation();
  const { colors } = useTheme();
  const { values, errors, touched } = useSaferFormikContext<BuildingSettingsValues>();
  const passPreviewRef = useRef(null);

  /* istanbul ignore next */
  const handlePrint = useReactToPrint({
    content: () => passPreviewRef.current,
  });

  const passDurationRelativeStartTime = useMemo(() => {
    const result: PassDurationTime[] = [];
    for (let i = 15; i <= 60; i += 15) {
      result.push({
        value: -i,
        label: t("visitors.settings.minutesBeforeMeetingStartTime", { value: i }),
      });
    }
    return result;
  }, [t]);

  const passDurationRelativeEndTime = useMemo(() => {
    const result: PassDurationTime[] = [];
    for (let i = 15; i <= 60; i += 15) {
      result.push({
        value: -i,
        label: t("visitors.settings.minutesAfterMeetingEndTime", { value: i }),
      });
    }
    return result;
  }, [t]);

  const passDurationTime = useMemo(() => {
    const result: PassDurationTime[] = [];
    for (let i = 6 * 60; i <= 22 * 60; i += 30) {
      result.push({
        value: i,
        label: formatters.timeshort(DateTime.fromObject({ hour: Math.floor(i / 60), minute: i % 60 }), i18n.language),
      });
    }
    return result;
  }, [i18n.language]);

  const getFieldError = (field: keyof typeof values) =>
    touched[field] === true || Array.isArray(touched[field]) ? errors[field]?.toString() : undefined;

  return (
    <>
      <div className="settings-form">
        <EqForm.Group label={t("visitors.settings.passConfiguration")}>
          <div className="pass-configuration">
            <div className="pass-configuration__preview">
              <Text variant="label" className="label">
                {t("common.preview")}
              </Text>
              <PrintedPass
                className="preview-container"
                passes={[
                  {
                    visitor: {
                      uuid: "",
                      fullName: "John Doe",
                      companyName: "Equiem",
                      codeValue: `${prefix ?? ""}12345678`,
                    },
                    host: {
                      fullName: "Jane Doe",
                      companyName: "Equiem",
                    },
                    startDate: DateTime.now().toMillis(),
                    endDate: DateTime.now().plus({ hours: 1 }).toMillis(),
                    codeType: codeType,
                  },
                ]}
                ref={passPreviewRef}
                marginTop={values.passPrintingConfiguration.marginTop}
              />
            </div>
            <div className="d-flex flex-column p-6 justify-content-between">
              <EqForm.Group
                label={t("visitors.settings.passMarginTop")}
                showTooltip={true}
                tooltipText={t("visitors.settings.passMarginTopTooltip")}
                error={errors.passPrintingConfiguration?.marginTop}
              >
                <Field as={EqForm.Input} id="marginTop" name="passPrintingConfiguration.marginTop" type="number" />
              </EqForm.Group>
              <Button
                variant="secondary"
                className="align-self-start"
                onClick={handlePrint}
                disabled={
                  typeof errors.passPrintingConfiguration?.marginTop !== "undefined" &&
                  errors.passPrintingConfiguration.marginTop.length > 0
                }
              >
                {t("visitors.settings.printTestPass")}
              </Button>
            </div>
          </div>
        </EqForm.Group>
        {codeType != null && (
          <EqForm.Group
            label={t("visitors.settings.passAccessTimes")}
            showTooltip={true}
            tooltipText={t("visitors.settings.passAccessTimesTooltip")}
          >
            <EqForm.Group
              label={t("visitors.settings.activationTime")}
              error={getFieldError("passAccessStartTime")}
              required
            >
              <Field as={EqForm.Select} name="passAccessStartTime">
                <option value={0} disabled>
                  {t("visitors.settings.setTime")}
                </option>
                {passDurationRelativeStartTime.map((date) => (
                  <option key={`${date.label}_${date.value}`} value={date.value}>
                    {date.label}
                  </option>
                ))}
                <option disabled>---</option>
                {passDurationTime.map((date) => (
                  <option key={`${date.label}_${date.value}`} value={date.value}>
                    {date.label}
                  </option>
                ))}
              </Field>
            </EqForm.Group>
            <EqForm.Group
              label={t("visitors.settings.deactivationTime")}
              error={getFieldError("passAccessEndTime")}
              required
            >
              <Field as={EqForm.Select} name="passAccessEndTime">
                <option value={0} disabled>
                  {t("visitors.settings.setTime")}
                </option>
                {passDurationRelativeEndTime.map((date) => (
                  <option key={`${date.label}_${date.value}`} value={date.value}>
                    {date.label}
                  </option>
                ))}
                <option disabled>---</option>
                {passDurationTime.map((date) => (
                  <option key={`${date.label}_${date.value}`} value={date.value}>
                    {date.label}
                  </option>
                ))}
              </Field>
            </EqForm.Group>
          </EqForm.Group>
        )}
        <EqForm.Group
          label={t("visitors.settings.emailFooterText")}
          showTooltip={true}
          tooltipText={t("visitors.settings.emailFooterTextTooltip")}
          error={errors.emailFooterText}
        >
          <Field
            id="emailFooterText"
            name="emailFooterText"
            placeholder={t("visitors.settings.emailFooterTextPlaceholder")}
            height="150px"
            as={CKEditorNoSSR}
            toolbar={["bold", "italic", "underline", "|", "link", "|", "undo", "redo"]}
          />
        </EqForm.Group>
        <EqForm.Group
          label={t("visitors.settings.emailHeader")}
          showTooltip={true}
          tooltipText={t("visitors.settings.emailBrandingTooltip")}
          error={getFieldError("emailHeaderImage")}
        >
          <Field
            id="emailHeaderImage"
            name="emailHeaderImage"
            placeholder={t("visitors.settings.emailHeader")}
            as={ImageUploader}
          />
        </EqForm.Group>
        <EqForm.Group
          label={t("visitors.settings.emailFooter")}
          showTooltip={true}
          tooltipText={t("visitors.settings.emailBrandingTooltip")}
          error={getFieldError("emailFooterImage")}
        >
          <Field
            id="emailFooterImage"
            name="emailFooterImage"
            placeholder={t("visitors.settings.emailFooter")}
            as={ImageUploader}
            image={values.emailFooterImage}
          />
        </EqForm.Group>
      </div>
      <style jsx>{`
        .pass-configuration {
          display: grid;
          grid-template-columns: 330px auto;
          border: 1px solid ${colors.grayscale[10]};
          border-radius: 4px;
        }
        .pass-configuration__preview {
          padding: 1.5rem;
          background: ${colors.grayscale[3]};
          height: 300px;
          overflow-y: auto;
          display: flex;
          flex-direction: column;
          gap: 0.5rem;
        }
        .pass-configuration__preview :global(.label) {
          color: ${colors.transparent.black[60]};
        }
        .pass-configuration__preview :global(.preview-container) {
          border: 1px solid ${colors.grayscale[10]};
          border-radius: 4px;
        }
      `}</style>
    </>
  );
};
