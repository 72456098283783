import type { CompanyFlexTenantsFragmentFragment } from "@equiem/lib/generated/gateway-client";
import { createContext } from "react";

export interface FlexTenantContext {
  activeFlexTenant: CompanyFlexTenantsFragmentFragment | null;
  setActiveFlexTenant: (flexTenant: CompanyFlexTenantsFragmentFragment | null) => void;
  flexTenants: CompanyFlexTenantsFragmentFragment[];
}

export const FlexTenantContext = createContext<FlexTenantContext>({
  activeFlexTenant: null,
  setActiveFlexTenant: () => ({}),
  flexTenants: [],
});
