import type { FC } from "react";
import React, { useMemo, useState } from "react";
import { useEmployeesQuery } from "../../generated/gateway-client";
import { EntitySearch } from "@equiem/react-admin-ui";
import { useTranslation } from "@equiem/localisation-eq1";
import { notNullOrUndefined } from "@equiem/lib";

interface Profile {
  uuid: string;
  email: string;
  displayName?: string | null;
  firstName?: string | null;
  lastName?: string | null;
}

interface Props {
  onSelect?: (item: Profile) => void;
  flexTenantUuid: string;
}

export const CompanyEmployeeSelect: FC<Props> = ({ onSelect, flexTenantUuid }) => {
  const [searchTerm, setSearch] = useState<string>("");
  const { t } = useTranslation();
  const { data, loading } = useEmployeesQuery({
    variables: {
      page: {
        first: 20,
      },
      searchTerm,
    },
  });
  const employeeData = data?.myCompanyUsers.edges;

  const searchProfiles = useMemo(
    () =>
      (employeeData ?? [])
        .flatMap((edge) => (edge.node == null ? [] : [edge.node]), [])
        .filter(notNullOrUndefined)
        .map((p) => {
          const existing = p.flexMemberships.find((m) => m.flexTenant.uuid === flexTenantUuid);

          return {
            value: "",
            disabled: existing != null,
            entity: {
              name: `${p.displayName}${existing != null ? ` (${existing.flexTenant.name})` : ""}`,
              subtext: p.email,
              ...p,
            },
          };
        }),
    [employeeData, flexTenantUuid],
  );

  return (
    <>
      <EntitySearch.EntitySearch
        items={searchProfiles}
        loading={loading}
        onSearch={setSearch}
        onSelect={({ entity }) => {
          if (onSelect != null) {
            onSelect(entity);
          }
        }}
        buttonText={t("common.add")}
        variant="md"
        autoComplete="off"
      />
    </>
  );
};
