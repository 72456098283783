import React from "react";
import Link from "next/link";
import { useSiteContext, getLinkType } from "@equiem/lib";
import { useTheme } from "@equiem/react-admin-ui";
import { useTranslation } from "@equiem/localisation-eq1";

import type { ArticleDetail } from "../util/Article";
import { ArticleFooterSection } from "./ArticleFooterSection";

export const ArticleRelatedInfo: React.FC<{ relatedInfo: ArticleDetail["relatedInfo"] }> = ({ relatedInfo }) => {
  const { domainAliases } = useSiteContext();
  const { spacers } = useTheme();
  const { t } = useTranslation();

  const getTarget = (url: string) => (getLinkType(url, domainAliases) === "external" ? "_blank" : undefined);

  return (
    <>
      <ArticleFooterSection title={t("homepage.relatedInformation")}>
        <div className="container">
          {relatedInfo.map(({ title, url }, i) => (
            <Link key={i} href={url} legacyBehavior>
              <a target={getTarget(url)} rel="noopener noreferrer">
                {title}
              </a>
            </Link>
          ))}
        </div>
      </ArticleFooterSection>
      <style jsx>{`
        div {
          display: flex;
          flex-direction: column;
          gap: ${spacers.s3};
        }
      `}</style>
    </>
  );
};
