import type { IconType } from "react-icons";
import type { InputHTMLAttributes } from "react";
import React, { useContext, useMemo } from "react";
import { useTheme } from "../../../contexts/Theme";
import { Text } from "../../Text/Text";
import { FormGroupContext } from "../../../contexts/FormGroupContext";

// eslint-disable-next-line @typescript-eslint/no-type-alias
export type Props = InputHTMLAttributes<HTMLInputElement> & {
  field: InputHTMLAttributes<HTMLInputElement>;
  icon: IconType;
  title: string;
  description: string;
  default?: boolean;
};

export const FormHeroRadioSelect: React.FC<Props> = ({
  field: { name, value, onChange },
  icon: Icon,
  title,
  className,
  description,
  id,
  ...props
}) => {
  const { hintId, hasError } = useContext(FormGroupContext);
  const { colors } = useTheme();

  const checked = useMemo(() => {
    return value == null ? props.default ?? id === value : id === value;
  }, [id, value, props.default]);

  return (
    <>
      <label
        className={`label ${checked ? "label--active" : ""} ${hasError ? "label--error" : ""} ${
          className ?? ""
        }`.trim()}
        htmlFor={id}
      >
        <div className="control">
          <Icon size={24} fill={checked ? colors.blue[60] : "#000"} />
          <input
            name={name}
            id={id}
            value={id}
            checked={checked}
            aria-describedby={hintId}
            onChange={onChange}
            {...props}
            type="radio"
          />
        </div>
        <div className="info">
          <Text variant="heading" size="small" className="mb-2">
            {title}
          </Text>
          <Text variant="text" size="extra-small" className="description m-0">
            {description}
          </Text>
        </div>
      </label>
      <style jsx>{`
        input[type="radio"] {
          width: 24px;
          height: 24px;
          background: #ffffff;
          border: 1px solid ${colors.grayscale[20]};
          border-radius: 50%;
          appearance: none;
          position: relative;
          margin: 0;
        }

        input[type="radio"]:checked {
          border-color: ${colors.blue[60]};
        }

        input[type="radio"]:checked:before {
          content: "";
          position: absolute;
          transform: translate(50%, 50%);
          top: 0;
          left: 0;
          width: 50%;
          height: 50%;
          border-radius: 50%;
          background-color: ${colors.blue[60]};
        }

        .label {
          display: inline-flex;
          white-space: nowrap;
          max-width: 100%;
          cursor: pointer;
          user-select: none;
          flex-direction: column;
          border-radius: 4px;
          padding: 1rem;
          border: 1px solid ${colors.transparent.black[10]};
        }

        .label:not(.label--active):hover {
          background-color: ${colors.transparent.black[5]};
        }

        .label--error,
        .label-error input {
          border-color: ${colors.status.danger.primary};
        }

        .label--active {
          background-color: ${colors.blue[10]};
          border-color: ${colors.blue[60]};
        }

        .control {
          display: flex;
          justify-content: space-between;
          margin-bottom: 0.5rem;
        }

        .info {
          display: flex;
          flex-direction: column;
          overflow: hidden;
          white-space: normal;
        }

        .info :global(.description) {
          color: ${colors.transparent.black[60]};
        }
      `}</style>
    </>
  );
};
