import React, { useContext, useEffect, useState } from "react";

import { CurrentProfile, CurrentRole, notNullOrUndefined, Role } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import { BulkTable, EmptyState, ProgressCircle, useIsMobileWidth } from "@equiem/react-admin-ui";

import { Modal } from "../../../contexts/ModalContext";
import { UserSortFieldV2 } from "../../../generated/settings-client";
import { UsersFilterContext } from "../contexts/UsersFilterContext";
import { usePagedUserList } from "../hooks/usePagedUserList";
import { useUserList } from "../hooks/useUserList";

import { UserListBulkHeader } from "./UserListBulkHeader";

export type FullUser = NonNullable<ReturnType<typeof usePagedUserList>["users"][number]>;

export const UserList: React.FC<{ className?: string; setLoading: (loading: boolean) => void }> = ({
  className,
  setLoading,
}) => {
  const { t } = useTranslation();
  const { userFilter, debouncedSearch, selectedTab, filters } = useContext(UsersFilterContext);
  const modal = useContext(Modal);
  const { currentRole } = useContext(CurrentRole);
  const { isFlexRelated } = useContext(CurrentProfile);
  const { getColumns } = useUserList(isFlexRelated);
  const [selectedUsers, setSelectedUsers] = useState<FullUser[]>([]);
  const [sortColumnIndex, setSortColumnIndex] = useState<number>(0);
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("asc");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const { users, loading, totalCount, fetchMore, refetch } = usePagedUserList(userFilter, pageSize);
  const isMobile = useIsMobileWidth();

  const showCompany = currentRole === Role.PropertyManager;

  useEffect(() => {
    setSelectedUsers([]);
    setCurrentPage(1);
  }, [debouncedSearch, selectedTab, filters]);

  useEffect(() => {
    const fieldsIndex = [UserSortFieldV2.FirstNameKeyword, UserSortFieldV2.EmailKeyword];

    if (fieldsIndex[sortColumnIndex] != null) {
      void refetch({
        sort: {
          field: fieldsIndex[sortColumnIndex] as UserSortFieldV2,
          asc: sortDirection === "asc",
        },
      });
    }
  }, [sortColumnIndex, sortDirection, refetch]);

  useEffect(() => {
    setSortColumnIndex(0);
    setSortDirection("asc");
    setCurrentPage(1);
  }, [userFilter]);

  useEffect(() => {
    setLoading(loading);
  }, [loading, setLoading]);

  const handleSelect = (uuids: string[]) => {
    setSelectedUsers(users.filter((u) => uuids.includes(u.uuid)));
  };

  const handleSort = (index: number) => {
    if (index >= 2) {
      return;
    }

    setCurrentPage(1);

    if (sortColumnIndex === index) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortColumnIndex(index);
      setSortDirection("asc");
    }
  };

  const onPageChange = (page: number, size: number) => {
    if (page > currentPage) {
      fetchMore();
    }

    setCurrentPage(page);
    setPageSize(size);
  };

  const handleEdit = (uuid: string, e: React.MouseEvent) => {
    const td = (e.target as HTMLElement).closest("td");
    const tr = td?.parentNode;

    if (tr != null && td != null) {
      const clickedColumnIndex = Array.from(tr.children).indexOf(td);

      if (clickedColumnIndex === tr.children.length - 1) {
        return;
      }
    }

    modal.open("EditProfile", uuid);
  };

  const defaultHeaders = showCompany
    ? [
        t("common.name"),
        t("common.email"),
        t("common.groups"),
        t("common.roles"),
        isFlexRelated ? t("settings.flexMembership.tab") : null,
        t("common.company"),
        t("common.status"),
      ].filter(notNullOrUndefined)
    : [
        t("common.name"),
        t("common.email"),
        t("common.groups"),
        t("common.roles"),
        isFlexRelated ? t("settings.flexMembership.tab") : null,
        t("common.status"),
      ].filter(notNullOrUndefined);

  const isEmpty = users.length === 0 && !loading;

  const hideHeader = isEmpty || (loading && users.length === 0);

  return (
    <div className={`${className} user-list ${hideHeader && "user-list--empty"}`}>
      {loading && users.length === 0 && (
        <div className="mt-6 mb-6 d-flex justify-content-center align-items-center">
          <ProgressCircle size="md" />
          <span className="ml-4">{`${t("common.loading")}...`}</span>
        </div>
      )}
      {isEmpty && <EmptyState />}
      {users.length > 0 && (
        <BulkTable
          className="w-100"
          selectionEnabled={!isMobile}
          onSelected={handleSelect}
          onRowClick={handleEdit}
          headers={defaultHeaders.map((label) => ({ label }))}
          bulkOperations={[<UserListBulkHeader selectedUsers={selectedUsers} key="actions" />]}
          bodyRows={users
            .filter((c) => c.profile != null)
            .slice((currentPage - 1) * pageSize, currentPage * pageSize)
            .map((user) => {
              return {
                id: user.uuid,
                cols: getColumns(user, showCompany),
              };
            })}
          sort={{
            columnIndex: sortColumnIndex,
            direction: sortDirection,
            handleSort,
          }}
          pagination={{
            totalCount,
            currentPage,
            pageSize,
            onPageChange,
          }}
        />
      )}
      <style jsx>{`
        .user-list :global(thead th) {
          cursor: pointer;
        }

        /* name */
        .user-list :global(tbody) :global(td:nth-child(2)) {
          min-width: 20%;
          cursor: pointer;
        }

        /* company (pm) OR groups (wm) */
        .user-list :global(tbody) :global(td:nth-last-child(2)) {
          min-width: 20%;
        }

        /* status */
        .user-list :global(tbody) :global(td:last-child) {
          width: 160px;
        }

        .user-list {
          overflow: auto;
        }

        .user-list :global(thead th:nth-child(2)) {
          border-top-left-radius: 8px;
        }

        .user-list :global(th) {
          position: relative !important;
          top: 0 !important;
        }
      `}</style>
    </div>
  );
};
