import type { PropsWithChildren } from "react";
import React, { createContext } from "react";

import type {
  Building,
  VisitorAppointmentFragment,
  VisitorForBuildingReceptionFragment,
  VisitorForReceptionFragment,
  VisitorReception,
} from "../../generated/visitors-client";

export interface ReceptionContext {
  enablePassPrinting: boolean;
  enableAutomaticPassPrinting: boolean;
  reception?: VisitorReception;
  building?: Building;
  printingAppointment?: VisitorAppointmentFragment | null;
  printingVisitors?: VisitorForReceptionFragment[] | VisitorForBuildingReceptionFragment[];
  isWalkIn?: boolean;
  setReception?: (reception?: VisitorReception) => void;
  setBuilding?: (building: Building) => void;
  setPrintingAppointment?: (visitor: VisitorAppointmentFragment | null) => void;
  setPrintingVisitors?: (visitors: VisitorForReceptionFragment[] | VisitorForBuildingReceptionFragment[]) => void;
  setIsWalkIn?: (isWalkIn: boolean) => void;
}

export const ReceptionContext = createContext<ReceptionContext>({
  enablePassPrinting: false,
  enableAutomaticPassPrinting: false,
  isWalkIn: false,
  setReception: undefined,
  setBuilding: undefined,
  setPrintingAppointment: undefined,
  setPrintingVisitors: undefined,
  setIsWalkIn: undefined,
});

export const ReceptionProvider: React.FC<PropsWithChildren<ReceptionContext>> = ({ children, ...args }) => {
  return <ReceptionContext.Provider value={args}>{children}</ReceptionContext.Provider>;
};
