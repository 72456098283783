import { useRef } from "react";

export const useAbortController = () => {
  const ref = useRef(new AbortController());
  const onAbortLoading = () => {
    ref.current.abort();
  };

  const resetAbortController = () => {
    ref.current = new AbortController();
  };

  return {
    onAbortLoading,
    resetAbortController,
    signal: ref.current.signal,
  };
};
