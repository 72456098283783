import React from "react";

import { useTranslation } from "@equiem/localisation-eq1";
import { Form as EqForm, MemberCard, Tag, useTheme } from "@equiem/react-admin-ui";

import type { Queue } from "../pages/settings/hooks/useQueueData";

export const QueuePreview: React.FC<Queue> = (queue) => {
  const { t } = useTranslation();
  const { colors } = useTheme();
  return (
    <>
      <div className="d-flex" style={{ flexDirection: "column" }}>
        <div className="queue-header-container">
          <span className="queue-header">{queue.name}</span>
          <Tag size="large" variant={queue.active ? "positive" : "negative"} className="queue-tag">
            {t(queue.active ? "requests.queues.active" : "requests.queues.inactive")}
          </Tag>
        </div>
        <EqForm.Group label={t("requests.queues.owner")} className="queue-owner-section">
          {queue.ownerCompany.name}
        </EqForm.Group>
      </div>

      <div className="d-flex" style={{ flexDirection: "column" }}>
        <span className="queue-header">{t("requests.queues.queueAgents")}</span>
        <EqForm.Group label={t("requests.queues.requestManagers")} disabled={true}>
          {queue.managers.length > 0 &&
            queue.managers.map((u) => (
              <MemberCard.Card
                className="selected-member mt-2"
                showButton={false}
                buttonVariant="outline"
                email={u.email}
                companyName={u.companyV2?.name ?? ""}
                lastName={u.lastName ?? ""}
                firstName={u.firstName ?? ""}
                profileIconUrl={u.avatar ?? undefined}
                key={u.uuid}
              />
            ))}
        </EqForm.Group>
        <EqForm.Group label={t("requests.queues.requestAssignees")} disabled={true}>
          {queue.assignees.length > 0 &&
            queue.assignees.map((u) => (
              <MemberCard.Card
                className="selected-member mt-2"
                showButton={false}
                buttonVariant="outline"
                email={u.email}
                companyName={u.companyV2?.name ?? ""}
                lastName={u.lastName ?? ""}
                firstName={u.firstName ?? ""}
                profileIconUrl={u.avatar ?? undefined}
                key={u.uuid}
              />
            ))}
        </EqForm.Group>
      </div>

      <style jsx>
        {`
          :global(.queue-owner-section) {
            border-bottom: 1px solid ${colors.border};
            padding-bottom: 25px;
          }
          :global(.queue-header) {
            font-weight: 700;
            font-size: 20px;
            padding: 0 0 25px 0;
          }
          .queue-header-container {
            margin-bottom: 24px;
          }
          :global(.queue-tag) {
            margin-left: 12px;
          }
        `}
      </style>
    </>
  );
};
