import React, { useContext, useEffect, useMemo, useState } from "react";
import { useApolloClient } from "@apollo/client";

import { CurrentProfile, CurrentRole, notNullOrUndefined, Role, UrlParams, useSiteContext } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import type { FilterItem } from "@equiem/react-admin-ui";
import {
  Button,
  ComplexFilter,
  FilterDateModifier,
  FilterIsModifier,
  FilterOptionsModifier,
  Form,
  ProgressCircle,
  Tooltip,
  useIsMobileWidth,
  useTheme,
  useToast,
} from "@equiem/react-admin-ui";
import {
  RiAccountCircleLine,
  RiBuilding4Line,
  RiCalendarLine,
  RiFileDownloadLine,
  RiRepeat2Line,
  RiSearchLine,
  RiTeamLine,
} from "@equiem/react-admin-ui/icons";

import type {
  VisitorAppointmentsInfoQuery,
  VisitorAppointmentsInfoQueryVariables,
  VisitorAppointmentsQueryVariables,
  VisitorAppointmentsSortInput,
} from "../../../generated/visitors-client";
import {
  AppointmentsViewScope,
  useAppointmentsExportCsvMutation,
  useDestinationBuildingsQuery,
  useVisitorCompaniesQuery,
  useVisitorManagementSiteSettingsQuery,
  useVisitorReceptionsQuery,
  VisitorAppointmentsInfoDocument,
  VisitorAppointmentsQueryRole,
  VisitorAppointmentType,
  VisitorRole,
} from "../../../generated/visitors-client";
import { filterReceptionList } from "../../../helpers/filterReceptionList";
import type { PredefinedTabFilters } from "../contexts/AppointmentsFilterContext";
import {
  AppointmentsFilterContext,
  notReceptionistEndUserTabsTypes,
  tabsTypes,
} from "../contexts/AppointmentsFilterContext";

import { FilterTab } from "./FilterTab";

export const AppointmentsFilters: React.FC<{ variables: VisitorAppointmentsQueryVariables }> = ({ variables }) => {
  const isMobile = useIsMobileWidth();
  const { t, i18n } = useTranslation();
  const client = useApolloClient();
  const site = useSiteContext();
  const { profile, isReceptionist } = useContext(CurrentProfile);
  const { currentRole } = useContext(CurrentRole);
  const urlParamsProvider = useContext(UrlParams);
  const { colors, breakpoints } = useTheme();
  const toaster = useToast();
  const { filters, setFilters, search, setSearch, selectedTab, setSelectedTab } = useContext(AppointmentsFilterContext);
  const [userStats, setUserStats] = useState<Record<PredefinedTabFilters, number>>({
    my: 0,
    all: 0,
  });
  const { data: buildingData } = useDestinationBuildingsQuery({
    variables: { uuid: site.uuid },
  });
  const { data: companiesData } = useVisitorCompaniesQuery({
    variables: {},
  });
  const { data: vmSiteSettings } = useVisitorManagementSiteSettingsQuery({
    variables: { siteUuid: site.uuid },
    fetchPolicy: "network-only",
  });
  const { data: receptionsData } = useVisitorReceptionsQuery({
    variables: { as: VisitorRole.Receptionist, first: 1000 },
    skip: !isReceptionist,
  });
  const receptions = useMemo(() => filterReceptionList(receptionsData, t), [receptionsData, t]);
  const isBuildingReceptionist = useMemo(
    () => receptions.filter((reception) => reception.company == null).length > 0,
    [receptions],
  );

  const getCurrentRole = () => {
    if (currentRole === Role.PropertyManager) {
      return VisitorAppointmentsQueryRole.PropertyManager;
    }

    if (currentRole === Role.WorkplaceManager) {
      return VisitorAppointmentsQueryRole.WorkplaceManager;
    }

    return undefined;
  };

  const [exportCsv, { loading: isExportLoading }] = useAppointmentsExportCsvMutation({
    variables: {
      input: {
        buildingUuids: variables.buildingUuids as string[],
        beginDate: variables.beginDate,
        endDate: variables.endDate,
        hostCompanyUuids: variables.hostCompanyUuids as string[],
        isRecurring: variables.isRecurring,
        search: variables.search,
        sort: variables.sort as VisitorAppointmentsSortInput[],
        visitorTypeUuids: variables.visitorTypeUuids as string[],
        companyUuid: profile?.companyV2?.uuid,
        as: variables.as,
        viewRole: getCurrentRole(),
        viewScope: variables.viewScope,
      },
    },
  });

  useEffect(() => {
    const viewScopes = [AppointmentsViewScope.All, AppointmentsViewScope.My];

    for (const viewScope of viewScopes) {
      void client
        .query<VisitorAppointmentsInfoQuery, VisitorAppointmentsInfoQueryVariables>({
          query: VisitorAppointmentsInfoDocument,
          fetchPolicy: "network-only",
          variables: {
            ...variables,
            viewScope,
          },
        })
        .then((result) => {
          setUserStats(({ my, all }) => {
            const { totalCount } = result.data.visitorAppointments;

            return {
              my: viewScope === AppointmentsViewScope.My ? totalCount : my,
              all: viewScope === AppointmentsViewScope.All ? totalCount : all,
            };
          });
        });
    }
  }, [
    currentRole,
    site.uuid,
    variables.viewScope,
    variables.beginDate,
    variables.endDate,
    variables.search,
    variables.buildingUuids,
    variables.hostCompanyUuids,
    variables.isRecurring,
    variables.visitorTypeUuids,
    (variables.sort as VisitorAppointmentsSortInput[])[0].asc,
  ]);

  const buildingOptions = useMemo(
    () =>
      (buildingData?.destination.buildings ?? []).map((building) => ({
        label: building.name,
        value: building.uuid,
      })),
    [buildingData?.destination.buildings],
  );

  const visitorTypesOptions = useMemo(() => {
    return (vmSiteSettings?.visitorManagementSiteSettings.visitorTypes ?? [])
      .filter((item) => item.appointmentTypes?.includes(VisitorAppointmentType.PreBooked))
      .map((item) => ({
        label: item.name as string,
        value: item.uuid as string,
      }));
  }, [vmSiteSettings?.visitorManagementSiteSettings.visitorTypes]);

  const companyOptions = useMemo(
    () =>
      (companiesData?.visitorCompanies ?? []).filter(notNullOrUndefined).map((company) => ({
        label: company.name,
        value: company.uuid,
      })),
    [companiesData?.visitorCompanies],
  );

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch?.(e.target.value);
  };

  const clearSearch = () => {
    setSearch?.("");
  };

  const getTabTitle = (tab: PredefinedTabFilters) => {
    switch (tab) {
      case "all":
        return t("visitors.appointments.allAppointments");
      case "my":
        return t("visitors.appointments.myAppointments");
      default:
        return "";
    }
  };

  const renderQuickFilters = () => {
    const tabs = currentRole === Role.Unknown && !isReceptionist ? notReceptionistEndUserTabsTypes : tabsTypes;

    return (
      <>
        {tabs.map((tab) => (
          <FilterTab
            title={getTabTitle(tab)}
            value={userStats[tab]}
            isActive={selectedTab === tab}
            onClick={() => setSelectedTab?.(tab)}
            key={tab}
          />
        ))}
      </>
    );
  };

  const complexFilters = useMemo(() => {
    let f: Record<string, FilterItem> = {
      location: {
        title: t("visitors.common.location"),
        type: "options",
        options: buildingOptions,
        icon: RiBuilding4Line,
        modifiers: [FilterOptionsModifier.includes],
      },
      date: {
        title: t("common.date"),
        type: "date",
        icon: RiCalendarLine,
        modifiers: [FilterDateModifier.is, FilterDateModifier.between],
      },
      recurring: {
        title: t("visitors.common.recurring"),
        type: "is",
        icon: RiRepeat2Line,
        modifiers: [FilterIsModifier.is, FilterIsModifier.isnt],
      },
    };

    if ((currentRole === Role.PropertyManager || isBuildingReceptionist) && selectedTab === "all") {
      f = {
        company: {
          title: t("common.company"),
          type: "options",
          options: companyOptions,
          icon: RiTeamLine,
          modifiers: [FilterOptionsModifier.includes],
        },
        ...f,
      };
    }

    if (vmSiteSettings?.visitorManagementSiteSettings.isVisitorTypesEnabled === true) {
      f = {
        visitorType: {
          title: t("visitors.common.visitorType"),
          type: "options",
          options: visitorTypesOptions,
          icon: RiAccountCircleLine,
          modifiers: [FilterOptionsModifier.includes],
        },
        ...f,
      };
    }

    return f;
  }, [
    selectedTab,
    currentRole,
    t,
    buildingOptions,
    companyOptions,
    visitorTypesOptions,
    vmSiteSettings?.visitorManagementSiteSettings.isVisitorTypesEnabled,
  ]);

  return (
    <>
      <div className="filters">
        <ComplexFilter
          filters={complexFilters}
          onChange={setFilters}
          language={i18n.language}
          urlParamsProvider={urlParamsProvider}
          additionalFilterDropdownContent={
            isMobile ? <div className="quick-filters-dropdown">{renderQuickFilters()}</div> : undefined
          }
          autoShow
        >
          {({ renderChips, renderFilterButton, renderClearButton }) => (
            <>
              <div className="main-row">
                <div className="left-side">{!isMobile && renderQuickFilters()}</div>
                <div className="right-side">
                  <div className="search-container">
                    <Form.InputExtended
                      className="search-input"
                      icon={RiSearchLine}
                      placeholder={`${t("common.search")}...`}
                      variant="sm"
                      value={search}
                      onChange={handleSearch}
                      onClear={clearSearch}
                      clearable
                    />
                  </div>
                  {renderFilterButton("primary", "bottom-end")}
                  <div>
                    <Tooltip title={t("visitors.appointments.csvExport")} placement="bottom">
                      <Button
                        variant="ghost"
                        round
                        disabled={isExportLoading}
                        onClick={() => {
                          exportCsv()
                            .then(() => {
                              toaster.positive(t("visitors.common.csvExportSuccess"), { autoDismiss: true });
                            })
                            .catch(console.error);
                        }}
                        className="export-csv"
                      >
                        {isExportLoading ? <ProgressCircle size={16} /> : <RiFileDownloadLine size={16} />}
                      </Button>
                    </Tooltip>
                  </div>
                </div>
              </div>
              {!isMobile && Object.keys(filters).length > 0 && (
                <div className="chips-row">
                  <div className="left-side">{renderChips()}</div>
                  <div className="right-side">{renderClearButton()}</div>
                </div>
              )}
            </>
          )}
        </ComplexFilter>
      </div>
      <style jsx>{`
        .filters {
          display: flex;
          flex-direction: column;
          width: 100%;
        }
        .main-row {
          display: flex;
          justify-content: space-between;
          gap: 8px;
        }
        .right-side,
        .left-side {
          display: flex;
          gap: 8px;
          align-items: flex-start;
        }
        .chips-row {
          display: flex;
          justify-content: space-between;
          gap: 8px;
          border-top: 1px solid ${colors.grayscale[10]};
          padding: 8px 0;
          margin-top: 8px;
        }
        .search-container {
          width: 270px;
        }

        @media (max-width: ${breakpoints.lg}px) {
          .main-row {
            flex-direction: column;
            max-width: 100vw;
          }

          .main-row .left-side {
            overflow-x: auto;
            padding: 0;
          }

          .main-row .left-side::-webkit-scrollbar,
          .quick-filters-dropdown::-webkit-scrollbar {
            display: none;
          }

          .main-row .right-side {
            display: grid;
            grid-template-columns: auto 32px 32px;
            align-items: center;
            padding: 0;
          }

          .search-container {
            width: 100%;
          }

          .quick-filters-dropdown {
            display: flex;
            gap: 8px;
            align-items: flex-start;
            padding: 8px;
            overflow-x: auto;
            min-height: 48px;
          }
        }
      `}</style>
    </>
  );
};
