import { useContext, useMemo } from "react";

import { CurrentRole, notNullOrUndefined, Role, useSaferFormikContext, useSiteContext } from "@equiem/lib";

import { useListProfilesQuery } from "../../generated/visitors-client";
import type { ReceptionFormValues } from "../ReceptionFormValues";

export const useReceptionFormUserSearch = (searchKey?: string) => {
  const { values } = useSaferFormikContext<ReceptionFormValues>();
  const { uuid: siteUuid } = useSiteContext();
  const { currentRole } = useContext(CurrentRole);

  const { searchTerm, usersList } = useMemo(() => {
    switch (searchKey) {
      case "recipients":
        return {
          searchTerm: values.recipientsSearch,
          usersList: values.recipients,
        };
      default:
        return {
          searchTerm: values.receptionistsSearch,
          usersList: values.receptionists,
        };
    }
  }, [searchKey, values.recipientsSearch, values.recipients, values.receptionistsSearch, values.receptionists]);

  const { data: listProfilesData, loading } = useListProfilesQuery({
    variables: {
      page: {
        first: 10,
      },
      siteUuid,
      filter: {
        name: searchTerm,
        email: searchTerm,
        active: true,
        companyUuid:
          currentRole === Role.PropertyManager && values.receptionType === "tenant" ? values.companyUuid : undefined,
      },
    },
    skip: searchTerm === "",
  });

  // The list of users matching the current search term.
  const users = useMemo(
    () =>
      listProfilesData?.listProfiles.edges
        .map((edge) => edge.node)
        .filter(notNullOrUndefined)
        .map((node) => {
          return {
            uuid: node.uuid,
            value: `${node.firstName} ${node.lastName}`,
            firstName: node.firstName ?? "",
            lastName: node.lastName ?? "",
            companyV2: node.companyV2,
            email: node.email,
            selected: usersList.some((u) => u.userUuid === node.uuid),
            avatar: node.avatar ?? undefined,
          };
        }) ?? [],
    [listProfilesData, usersList],
  );

  return { users, loading };
};
