import React from "react";
import { WidgetProvider } from "./WidgetProvider";
import { InviteModal } from "./InviteModal";
import type { WidgetProps } from "@equiem/lib";

export const WidgetModal: React.FC<WidgetProps> = ({ viewer, siteUuid }) => {
  return (
    <WidgetProvider>
      <InviteModal viewer={viewer} siteUuid={siteUuid} />
    </WidgetProvider>
  );
};
