import React from "react";
import { Form } from "formik";

import { useTheme } from "@equiem/react-admin-ui";

import { ReceptionFormFields } from "./ReceptionFormFields";
import { ReceptionFormUserSearch } from "./ReceptionFormUserSearch";

export const ReceptionForm: React.FC = () => {
  const { colors } = useTheme();
  return (
    <>
      <Form>
        <div className="form-container">
          <div className="content-container pt-5 pl-8 pr-5">
            <ReceptionFormFields />
          </div>
          <div className="receptionists-container pt-5 pl-5 pr-5">
            <ReceptionFormUserSearch />
          </div>
        </div>
      </Form>
      <style jsx>
        {`
          .form-container {
            display: flex;
            min-height: 90vh;
          }
          .content-container {
            flex: 55;
            background: #fff;
          }
          .receptionists-container {
            flex: 45;
            padding-bottom: 40px;
            background-color: ${colors.grayscale[3]};
          }
        `}
      </style>
    </>
  );
};
