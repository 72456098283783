import React, { type ReactNode, createContext, useState, useEffect } from "react";
import { stringIsEmpty } from "@equiem/lib";
import type { ApolloError } from "@apollo/client";

import { useViewerSiteRelationsQuery, useBookingSettingQuery } from "../../../../generated/gateway-client";
import { useAuthorizedDestinationDetails } from "../../hooks/useAuthorizedDestinationDetails";
import { ResourceCreateAndEditLoading } from "./ResourceCreateAndEditLoading";

interface ResourceCreateAndEditSiteContext {
  setResourceSite: (siteUuid: string) => void;
  setResourceCompany: (companyUuid: string) => void;
  siteUuid: string;
  loading: boolean;
  viewerLocations: ReturnType<typeof useAuthorizedDestinationDetails>["viewerLocations"];
  ownerCompanyLocations: ReturnType<typeof useAuthorizedDestinationDetails>["ownerCompanyLocations"];
  locationsError: ApolloError | undefined;
  canManageSite: boolean;
  canManageSiteCompany: boolean;
  canManageBuildingCompany: boolean;
  canObserveBuildingCompany: boolean;
  canManageCompanyBookingSettings: boolean;
  creditCardAvailable: boolean;
}

export const ResourceCreateAndEditSiteContext = createContext<ResourceCreateAndEditSiteContext>({
  setResourceSite: () => undefined,
  setResourceCompany: () => undefined,
  siteUuid: "",
  loading: false,
  viewerLocations: [],
  ownerCompanyLocations: [],
  locationsError: undefined,
  canManageSite: false,
  canManageSiteCompany: false,
  canManageBuildingCompany: false,
  canObserveBuildingCompany: false,
  canManageCompanyBookingSettings: false,
  creditCardAvailable: false,
});

export const ResourceCreateAndEditSiteProvider: React.FC<{
  children: ReactNode;
  initialSiteUuid: string;
  initialCompanyUuid: string | undefined;
}> = ({ children, initialSiteUuid, initialCompanyUuid }) => {
  const [siteUuid, setResourceSite] = useState(initialSiteUuid);
  const [companyUuid, setResourceCompany] = useState(initialCompanyUuid);

  const relations = useViewerSiteRelationsQuery({
    variables: { uuid: siteUuid },
    skip: stringIsEmpty(siteUuid),
  });
  const viewerRelations = relations.data?.destination.viewerRelations;

  const setting = useBookingSettingQuery({
    variables: { site: siteUuid },
    fetchPolicy: "network-only",
    skip: viewerRelations?.canManage !== true,
  });
  const bookingSetting = setting.data?.bookingSetting;

  const locations = useAuthorizedDestinationDetails(companyUuid);

  const canManageSite = viewerRelations?.canManage ?? false;
  const canManageSiteCompany = viewerRelations?.company.canManage ?? false;
  const canManageBuildingCompany = viewerRelations?.canManageBuildingCompanyBookings ?? false;
  const canObserveBuildingCompany = viewerRelations?.canObserveBuildingCompanyBookings ?? false;
  const canManageCompanyBookingSettings = viewerRelations?.company.canManageCompanyBookingSettings ?? false;

  const creditCardAvailable = bookingSetting?.paymentAccountId != null;

  const [initialLoading, setInitialLoading] = useState(true);
  const loading = stringIsEmpty(siteUuid) || relations.loading || setting.loading || locations.loading;
  useEffect(() => {
    if (initialLoading && !loading) {
      setInitialLoading(false);
    }
  }, [initialLoading, loading]);

  if (initialLoading) {
    return <ResourceCreateAndEditLoading />;
  }

  return (
    <ResourceCreateAndEditSiteContext.Provider
      value={{
        setResourceSite,
        setResourceCompany,
        siteUuid,
        loading,
        viewerLocations: locations.viewerLocations,
        ownerCompanyLocations: locations.ownerCompanyLocations,
        locationsError: locations.error,
        canManageSite,
        canManageSiteCompany,
        canManageBuildingCompany,
        canObserveBuildingCompany,
        canManageCompanyBookingSettings,
        creditCardAvailable,
      }}
    >
      {children}
    </ResourceCreateAndEditSiteContext.Provider>
  );
};
