import React from "react";

import { useTranslation } from "@equiem/localisation-eq1";
import { useTheme } from "@equiem/react-admin-ui";

export const DefaultCardBadge: React.FC = () => {
  const { colors } = useTheme();
  const { t } = useTranslation();

  return (
    <>
      <div>
        <span>{t("common.defaultOption")}</span>
      </div>
      <style jsx>{`
        span {
          font-size: 0.6rem;
          font-weight: 500;
          padding: 0.35rem 0.4em;
          color: ${colors.white};
          background: ${colors.medium};
          border-radius: 0.25rem;
          white-space: nowrap;
          vertical-align: middle;
          flex: 0;
        }
      `}</style>
    </>
  );
};
