import type { ComponentProps, FC } from "react";
import React from "react";
import type { BaseButtonSize } from "../Button/renderAdminButtonStyles";
import { AdminButton } from "../Button/AdminButton";
import { Avatar } from "../Avatar/Avatar";

// eslint-disable-next-line @typescript-eslint/no-type-alias
export type Props = ComponentProps<"div"> & {
  label: string;
  buttonElement?: React.ReactNode;
  buttonRound?: boolean;
  buttonSize?: BaseButtonSize;
  buttonVariant?: "ghost" | "secondary" | "primary" | "outline";
  buttonType?: "button" | "submit";
  buttonText?: string | null;
  onButtonClick?: () => void;
  onButtonFocus?: () => void;
};

export const EntityCard: FC<Props> = ({
  buttonSize,
  buttonVariant,
  buttonType,
  onButtonClick,
  onButtonFocus,
  buttonElement,
  buttonRound,
  buttonText,
  ...props
}) => {
  const className = `card ${props.className ?? ""}`;

  return (
    <div {...props} className={className}>
      <div className="header">
        <Avatar size="medium" firstName={props.label} />
      </div>
      <div className="body">
        <div className="name">{props.label}</div>
      </div>
      <div className="flex">
        <div className="action">
          {onButtonClick != null && (
            <AdminButton
              size={buttonSize ?? "sm"}
              variant={buttonVariant}
              type={buttonType}
              shape={buttonRound != null && buttonRound ? "round" : "default"}
              onClick={() => {
                onButtonClick();
              }}
              onFocus={() => {
                onButtonFocus?.();
              }}
            >
              {buttonElement != null ? buttonElement : buttonText}
            </AdminButton>
          )}
        </div>
      </div>
      <style jsx>
        {`
          .card {
            display: grid;
            grid-template-columns: 24px 1fr auto;
            border-radius: 8px;
            background-color: #fff;
            padding: 12px 16px 12px 12px;
            border: "1px solid #e6e6e6";
            align-items: center;
            justify-content: flex-start;
            grid-column-gap: 8px;
          }

          .body {
            display: flex;
            flex-direction: column;
            justify-content: center;
            overflow: hidden;
            padding-left: 8px;
          }

          .delete-button {
            right: 0;
            align-self: center;
          }

          .flex {
            display: flex;
            position: relative;
          }

          .card-padding {
            padding-top: 8px;
            padding-bottom: 8px;
          }

          .name {
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: auto;
          }

          .details {
            color: #666666;
            font-size: 12px;
            line-height: 16px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: auto;
          }

          .header {
            position: relative;
            margin: auto auto auto 0;
            padding-right: 8px;
          }
        `}
      </style>
    </div>
  );
};
