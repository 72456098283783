/* eslint-disable complexity */
import { DateTime } from "luxon";

import { FilterDateModifier } from "@equiem/react-admin-ui";

import type { UserFilterV2 } from "../../../generated/settings-client";
import { DateFilterOperatorV3, SegmentationRegistrationType } from "../../../generated/settings-client";
import type { UserFiltersResult } from "../contexts/UsersFilterContext";

const mapUserTypeToRegistrationType = (userType?: string): SegmentationRegistrationType | undefined => {
  switch (userType) {
    case "commercial":
      return SegmentationRegistrationType.Commercial;
    case "visitor":
      return SegmentationRegistrationType.Visitor;
    case "residential":
      return SegmentationRegistrationType.Residential;
    default:
      return undefined;
  }
};

const mapOptionToBool = (option?: string): boolean | undefined => {
  switch (option) {
    case "yes":
      return true;
    case "no":
      return false;
    default:
      return undefined;
  }
};

export const mapFiltersToUserFilter = (filters: UserFiltersResult, search?: string, status?: string): UserFilterV2 => {
  const result: UserFilterV2 = {};

  const searchInput = search?.trim() ?? "";
  const searchTerm = searchInput.length > 0 ? searchInput : undefined;
  result.searchTerm = searchTerm;

  if (filters.email?.type === "text") {
    const emails =
      filters.email.value
        ?.split(",")
        .map((e) => e.trim())
        .flatMap((e) => (e === "" ? [] : [e])) ?? [];
    if (emails.length === 1) {
      result.email = emails[0];
    } else if (emails.length > 0) {
      result.emails = emails;
    }
  }

  if (filters.name?.type === "text") {
    result.name = filters.name.value;
  }

  if (filters.userType?.type === "select") {
    result.registrationType = mapUserTypeToRegistrationType(filters.userType.value);
  }

  if (filters.emailVerified?.type === "select") {
    result.emailVerified = mapOptionToBool(filters.emailVerified.value);
  }

  if (filters.company?.type === "options") {
    const companies = filters.company.value?.map((company) => company.value) ?? [];
    result.companies = companies.length > 0 ? companies : undefined;
  }

  if (filters.emailSubscription?.type === "select") {
    result.subscribedToEmails = mapOptionToBool(filters.emailSubscription.value);
  }

  if (filters.active?.type === "select") {
    result.status = filters.active.value;
  }

  if (status != null && status !== "all") {
    result.status = status;
  }

  if (
    filters.profileLastActiveAt?.type === "date" &&
    filters.profileLastActiveAt.modifier !== FilterDateModifier.between &&
    filters.profileLastActiveAt.value != null
  ) {
    result.profileLastActiveAt = {
      timestamp: DateTime.fromISO(filters.profileLastActiveAt.value).toMillis(),
      comparisonOperator:
        filters.profileLastActiveAt.modifier === FilterDateModifier.before
          ? DateFilterOperatorV3.Lte
          : DateFilterOperatorV3.Gte,
    };
  }

  if (
    filters.profileCreatedAt?.type === "date" &&
    filters.profileCreatedAt.modifier !== FilterDateModifier.between &&
    filters.profileCreatedAt.value != null
  ) {
    result.profileCreatedAt = {
      timestamp: DateTime.fromISO(filters.profileCreatedAt.value).toMillis(),
      comparisonOperator:
        filters.profileCreatedAt.modifier === FilterDateModifier.before
          ? DateFilterOperatorV3.Lte
          : DateFilterOperatorV3.Gte,
    };
  }

  if (filters.group?.type === "options") {
    const groups = filters.group.value?.map((g) => g.value) ?? [];
    result.groups = groups.length > 0 ? groups : undefined;
  }

  if (filters.interests?.type === "options") {
    const userAttributes = filters.interests.value?.map((interest) => interest.value) ?? [];
    result.userAttributes = userAttributes.length > 0 ? userAttributes : undefined;
  }

  if (filters.authorizationRoles?.type === "options") {
    const authorizationRoles = filters.authorizationRoles.value?.map((g) => g.value) ?? [];
    result.authorizationRoles = authorizationRoles.length > 0 ? authorizationRoles : undefined;
  }

  if (filters.regionalFlexTenants?.type === "options") {
    const flexTenants = filters.regionalFlexTenants.value?.map((g) => g.value) ?? [];
    result.regionalFlexTenants = flexTenants.length > 0 ? flexTenants : undefined;
  }

  return result;
};
