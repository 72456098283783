import type { ChangeEvent } from "react";
import React, { useCallback, useState } from "react";
import { stringIsEmpty, useSaferFormikContext } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import { Alert, Button, Form as EqForm, Table, Text, useTheme, useToast } from "@equiem/react-admin-ui";
import { RiAddLine, RiInformationLine } from "@equiem/react-admin-ui/icons";
import { Field } from "formik";

import type { CreditPackage, CreditsGeneralSettingsValues } from "../FormValues";
import { PreviewCreditPackage } from "./PreviewCreditPackage";
import { AddingCreditPackage } from "./AddingCreditPackage";
import { RemovCreditPackageModal } from "./RemoveCreditPackageModal";
import { DisableCreditPurchaseModal } from "./DisableCreditPurchaseModal";

interface Props {
  isAddingPackage: boolean;
  setIsAddingPackage: (value: boolean) => void;
}

export const CreditsPackagesTable = ({ isAddingPackage, setIsAddingPackage }: Props) => {
  const { t } = useTranslation();
  const toast = useToast();
  const { colors } = useTheme();
  const { values, setValues, setFieldValue } = useSaferFormikContext<CreditsGeneralSettingsValues>();
  const [showModal, setShowModal] = useState(false);
  const [creditPackageForDelete, setCreditPackageForDelete] = useState<CreditPackage | null>(null);
  const [showDisableModal, setShowDisableModal] = useState(false);

  const cleanUpCreditPackages = useCallback(() => {
    void setFieldValue("isEnableCreditsPurchase", false);
    void setFieldValue("newCreditsPackage", undefined);
    setShowDisableModal(false);
    setIsAddingPackage(false);
  }, []);

  const calculateDiscountedNetPrice = useCallback((creditPackage: CreditPackage) => {
    if (
      creditPackage.discount == null ||
      (typeof creditPackage.discount === "string" && stringIsEmpty(creditPackage.discount))
    ) {
      return creditPackage.netPrice;
    }

    const { netPrice, discount } = creditPackage;
    return netPrice - (netPrice * Number(discount)) / 100;
  }, []);

  const onSaveAddCreditPackage = useCallback(() => {
    if (values.newCreditsPackage == null) {
      return;
    }

    void setValues({
      ...values,
      creditsPackages: [
        ...values.creditsPackages,
        {
          creditsAmount: values.newCreditsPackage.creditsAmount,
          discount: values.newCreditsPackage.discount,
          discountedNetPrice: calculateDiscountedNetPrice(values.newCreditsPackage),
          netPrice: values.newCreditsPackage.netPrice,
        },
      ],
      newCreditsPackage: undefined,
    }).catch(console.error);
    setIsAddingPackage(false);
  }, [values, setValues, setIsAddingPackage]);

  const onCloseAddCreditPackage = useCallback(() => {
    setIsAddingPackage(false);
    void setValues({
      ...values,
      newCreditsPackage: undefined,
    }).catch(console.error);
  }, [values, setValues]);

  const isTableDataExist = isAddingPackage || values.creditsPackages.length > 0;
  const isEnableCreditsPurchaseEmptyPackage = values.isEnableCreditsPurchase && values.creditsPackages.length < 1;

  return (
    <>
      <div className="w-100">
        <EqForm.Group
          label={t("credits.additionalCreditsPurchase.title")}
          showTooltip
          tooltipPlacement="top"
          tooltipText={t("credits.additionalCreditsPurchase.tooltip")}
        >
          <div className="enable-credit-packages">
            <Field
              type="checkbox"
              id="isEnableCreditsPurchase"
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                if (!e.target.checked) {
                  setShowDisableModal(true);
                } else {
                  void setFieldValue("isEnableCreditsPurchase", true);
                  toast.positive(
                    <>
                      <RiInformationLine size={20} />
                      {t("credits.creditsEnabled.notificationTitle")}
                    </>,
                  );
                }
              }}
              name="isEnableCreditsPurchase"
            />
            <label htmlFor="isEnableCreditsPurchase">{t("credits.creditsEnabled.checkboxLabel")}</label>
          </div>
        </EqForm.Group>
        {isEnableCreditsPurchaseEmptyPackage && (
          <div className="alert-container">
            <Alert
              className="alert-credit-empty"
              icon={<RiInformationLine size={20} />}
              variant="gray"
              message={<span className="alert-description">{t("credits.creditsAlert")}</span>}
            />
          </div>
        )}

        {(values.isEnableCreditsPurchase || values.creditsPackages.length > 0) && (
          <div className="credit-package-section">
            <Text variant="heading" size="small">
              {t("credits.creditPackages")}
            </Text>
            <div className="credit-table-container">
              <div className="credit-table">
                <Text variant="label" color={colors.grayscale[60]} size="small">
                  {t("credits.creditPackages")}
                </Text>
              </div>

              {isTableDataExist && (
                <Table.Table className="credit-package-table w-100 mt-3">
                  <tbody>
                    {values.creditsPackages.map((creditPackage, index) => (
                      <tr
                        key={`${creditPackage.creditsAmount}-${creditPackage.netPrice}-${index}`}
                        className="credit-package-row"
                      >
                        <PreviewCreditPackage
                          creditPackage={creditPackage}
                          onRemove={() => {
                            if (values.creditsPackages.length === 1) {
                              setShowModal(true);
                              setCreditPackageForDelete(creditPackage);
                            } else {
                              void setValues({
                                ...values,
                                creditsPackages: values.creditsPackages.filter((item) => item !== creditPackage),
                              });
                            }
                          }}
                        />
                      </tr>
                    ))}

                    {isAddingPackage && (
                      <tr>
                        <td className="package-row" colSpan={3}>
                          <AddingCreditPackage onSave={onSaveAddCreditPackage} onClose={onCloseAddCreditPackage} />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table.Table>
              )}
            </div>
            <Button
              variant="secondary"
              disabled={!values.isEnableCreditsPurchase || isAddingPackage}
              onClick={() => setIsAddingPackage(true)}
              className="mt-4"
            >
              <RiAddLine size={16} /> {t("credits.addCreditPackage")}
            </Button>
          </div>
        )}

        <RemovCreditPackageModal
          showModal={showModal}
          onAgree={() => {
            void setValues({
              ...values,
              creditsPackages: values.creditsPackages.filter((item) => item !== creditPackageForDelete),
            }).then(() => {
              setShowModal(false);
              setCreditPackageForDelete(null);
              cleanUpCreditPackages();
              toast.negative(
                <>
                  <RiInformationLine size={20} />
                  {t("credits.creditsDisabled.notificationTitle")}
                </>,
              );
            });
          }}
          onClose={() => {
            setShowModal(false);
            setCreditPackageForDelete(null);
          }}
        />
        <DisableCreditPurchaseModal
          showModal={showDisableModal}
          onAgree={() => {
            cleanUpCreditPackages();
            toast.negative(
              <>
                <RiInformationLine size={20} />
                {t("credits.creditsDisabled.notificationTitle")}
              </>,
            );
          }}
          onClose={() => {
            setShowDisableModal(false);
          }}
        />
      </div>
      <style jsx>{`
        :global(.credit-package-table) {
          overflow: hidden;
        }
        .credit-package-row:hover {
          background-color: ${colors.grayscale[3]};
          border-radius: 8px;
        }

        .credit-package-row:hover :global(.discount) {
          display: none;
        }

        .credit-package-row:hover :global(.controls) {
          display: flex;
        }

        .credit-package-row {
          height: 50px;
        }

        .credit-package-row :global(.controls) {
          display: none;
        }

        .credit-package-section,
        :global(.credit-table) {
          margin-top: 24px;
        }

        .credit-table-container {
          opacity: ${values.isEnableCreditsPurchase ? "1" : "0.5"};
          pointer-events: ${values.isEnableCreditsPurchase ? "auto" : "none"};
        }

        .credit-package-row:hover :global(.lock-icon) {
          padding: 16px;
        }

        .enable-credit-packages {
          display: flex;
          align-items: center;
          gap: 8px;
        }

        .package-row {
          padding: 12px 8px;
        }

        .alert-container :global(.alert-credit-empty.alert) {
          padding: 12px;
          height: auto;
        }

        .alert-container :global(.alert-credit-empty .left-group) {
          gap: 12px;
        }

        .alert-container :global(.alert-credit-empty.alert .alert-description) {
          font-size: 14px;
          line-height: 20px;
        }
      `}</style>
    </>
  );
};
