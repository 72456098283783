import React, { forwardRef, useState, useContext, useEffect, useImperativeHandle } from "react";
import type { Placement } from "@floating-ui/react-dom";
import { RiArrowRightSLine } from "react-icons/ri";
import { BsChevronRight } from "react-icons/bs";

import type { NavItemProps } from "../../NavItem/NavItem";
import { NavItem } from "../../NavItem/NavItem";
import { Dropdown, DropdownContext } from "../Dropdown";
import type { DropdownMobileView } from "../DropdownMenu/DropdownMenu";
import { DropdownMenu } from "../DropdownMenu/DropdownMenu";
import { NavContext } from "../../../contexts/NavContext";
import { useTheme } from "../../../contexts";
import { useHtmlId } from "../../../hooks/useHtmlId";
import { useIsMobileWidth } from "../../../hooks/useIsMobileWidth";

export type DropdownNestedNavItemProps = NavItemProps & {
  isMobile: boolean;
};

// eslint-disable-next-line @typescript-eslint/no-type-alias
export type DropdownNestedMenuProps = Omit<NavItemProps, "onSelect"> & {
  placement?: Placement;
  mobileView?: DropdownMobileView;
  autoClose?: boolean;
  maxHeight?: number;
  onSelect?: (eventKey: string) => void;
};

export interface DropdownNestedMenu {
  click: () => void;
  focus: () => void;
}

const DropdownNestedNavItem = forwardRef<DropdownNestedMenu, DropdownNestedNavItemProps>(
  ({ children, isMobile, title, disabled = false, ...props }, ref) => {
    // nested dropdown context
    const { show, placement, setToggleId, setShow, reference, refs } = useContext(DropdownContext);

    const { isAdmin } = useTheme();
    const RightIcon = isAdmin ? RiArrowRightSLine : BsChevronRight;

    const id = useHtmlId();
    useEffect(() => setToggleId(id), [id, setToggleId]);

    const currentRef =
      refs.reference.current instanceof HTMLElement && refs.reference.current.id === id ? refs.reference.current : null;
    const floatingRef = refs.floating.current;
    useImperativeHandle(ref, () => ({
      click: () => currentRef?.click(),
      focus: () => currentRef?.focus(),
    }));

    const [itemHasFocus, setItemHasFocus] = useState(false);
    const [nestedItemHasFocus, setNestedItemHasFocus] = useState(false);

    // track if focus is inside the nested menu
    // note that unlike `focus`/`blur`, `focusin`/`focusout` events bubble
    useEffect(() => {
      const isInsideNestedMenu = (target: EventTarget | null) =>
        target instanceof HTMLElement && floatingRef instanceof HTMLElement && floatingRef.contains(target);

      const onFocusIn = (e: FocusEvent) => {
        if (isInsideNestedMenu(e.target)) {
          setNestedItemHasFocus(true);
        }
      };
      const onFocusOut = (e: FocusEvent) => {
        if (isInsideNestedMenu(e.target)) {
          setNestedItemHasFocus(false);
        }
      };

      floatingRef?.addEventListener("focusin", onFocusIn);
      floatingRef?.addEventListener("focusout", onFocusOut);
      return () => {
        floatingRef?.removeEventListener("focusin", onFocusIn);
        floatingRef?.removeEventListener("focusout", onFocusOut);
      };
    }, [floatingRef]);

    // close the nested menu when focus moves fully off this item and its nested
    // menu
    useEffect(() => {
      if (!isMobile && show && !itemHasFocus && !nestedItemHasFocus) {
        // It seems like focus has left this item entirely, but the `focusin`
        // arrives *after* this effect initially gets triggered.
        // Wait a little while to see if a submenu item gets focused before we
        // close the submenu.
        const timeout = setTimeout(() => setShow(false));
        return () => clearTimeout(timeout);
      }
      return () => undefined;
    }, [isMobile, show, itemHasFocus, nestedItemHasFocus, setShow]);

    return (
      <NavItem
        {...props}
        ref={reference}
        id={id}
        title={title}
        rightIcon={isMobile ? RightIcon : undefined}
        disabled={disabled}
        nestedMenu={children}
        nestedMenuPlacement={placement}
        nestedItemHasFocus={nestedItemHasFocus}
        aria-expanded={show ? "true" : "false"}
        onClick={(e) => {
          if (!disabled) {
            setShow(true);
          }
          props.onClick?.(e);
        }}
        onMouseEnter={(e) => {
          if (!disabled && !isMobile) {
            setShow(true);
            setItemHasFocus(true);
          }
          props.onMouseEnter?.(e);
        }}
        onMouseLeave={(e) => {
          if (!isMobile) {
            setShow(false);
            setItemHasFocus(false);
          }
          props.onMouseLeave?.(e);
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter" && !disabled && e.target === e.currentTarget) {
            setShow(!show);
          }
          props.onKeyDown?.(e);
        }}
        onFocus={(e) => {
          setItemHasFocus(true);
          props.onBlur?.(e);
        }}
        onBlur={(e) => {
          setItemHasFocus(false);
          props.onBlur?.(e);
        }}
      >
        {title}
      </NavItem>
    );
  },
);
DropdownNestedNavItem.displayName = "DropdownNestedNavItem";

export const DropdownNestedMenu = forwardRef<DropdownNestedMenu, DropdownNestedMenuProps>(
  (
    { children, title, placement = "right-start", mobileView, autoClose = true, maxHeight, onSelect, ...itemProps },
    ref,
  ) => {
    // parent dropdown context
    const { onSelect: parentOnSelect, autoClose: triggerAutoClose } = useContext(DropdownContext);
    const { fontWeight } = useContext(NavContext);

    const isMobileWidth = useIsMobileWidth();
    const isMobile = isMobileWidth && mobileView != null;

    return (
      <Dropdown
        fontWeight={fontWeight}
        placement={placement}
        offset={4}
        onSelect={onSelect ?? parentOnSelect}
        onAutoClose={autoClose ? triggerAutoClose : undefined}
        flip
      >
        <DropdownNestedNavItem {...itemProps} ref={ref} title={title} isMobile={isMobile}>
          <DropdownMenu isNested mobileView={mobileView} title={title} maxHeight={maxHeight}>
            {children}
          </DropdownMenu>
        </DropdownNestedNavItem>
      </Dropdown>
    );
  },
);
DropdownNestedMenu.displayName = "DropdownNestedMenu";
