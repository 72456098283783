"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TranslationProvider = void 0;
var react_1 = __importStar(require("react"));
var react_i18next_1 = require("react-i18next");
var initializeFrontendReact_1 = require("../lib/initializeFrontendReact");
var apolloRequester_1 = require("../lib/requesters/apolloRequester");
var TranslationProvider = function (_a) {
    var children = _a.children, defaultNamespace = _a.defaultNamespace, availableNamespaces = _a.availableNamespaces, languages = _a.languages, localePreferenceScope = _a.localePreferenceScope, gqlRequester = _a.gqlRequester, client = _a.client, _b = _a.onReadyStateChanged, onReadyStateChanged = _b === void 0 ? function () { return undefined; } : _b;
    var requester = (0, react_1.useMemo)(function () {
        if (gqlRequester != null) {
            return gqlRequester;
        }
        if (client != null) {
            return (0, apolloRequester_1.makeApolloRequester)(client);
        }
        throw new Error("You must provide `gqlRequester` or `client`.");
    }, [gqlRequester, client]);
    // handle the scope object maybe getting recreated on parent re-render
    var scope = (0, react_1.useMemo)(function () { return ({
        siteUuid: localePreferenceScope.siteUuid,
        userUuid: localePreferenceScope.userUuid,
        useViewer: localePreferenceScope.useViewer,
        preferredLanguage: localePreferenceScope.preferredLanguage,
    }); }, [
        localePreferenceScope.siteUuid,
        localePreferenceScope.userUuid,
        localePreferenceScope.useViewer,
        localePreferenceScope.preferredLanguage,
    ]);
    var _c = (0, react_1.useState)(function () {
        return (0, initializeFrontendReact_1.initialize)({
            defaultNamespace: defaultNamespace,
            availableNamespaces: availableNamespaces,
            languages: languages,
            localePreferenceScope: localePreferenceScope,
            gqlRequester: requester,
        });
    }), initResult = _c[0], setInitResult = _c[1];
    // handle setting updating the ready state for the *original* i18next instance
    // subsequent instances will be managed by the effect below
    // (the instance won't be set to `initResult` until after it's initialized)
    (0, react_1.useEffect)(function () {
        var callback = function () { return onReadyStateChanged(true); };
        initResult.instance.on("initialized", callback);
        return function () { return initResult.instance.off("initialized", callback); };
    }, [initResult.instance, onReadyStateChanged]);
    // If the `localePreferenceScope`/`requester` change, we want to update the
    // language. However, if the user has made a language selection this session,
    // we don't want to override it.
    //
    // Additionally, i18next may fire the `languageChanged` event during
    // initialization when the `LocaleDetector` provides it with the language to
    // use.
    //
    // Track langauge changes that hapen *only* when the instance is initialized.
    var _d = (0, react_1.useState)(null), instanceLanguage = _d[0], setInstanceLanguage = _d[1];
    (0, react_1.useEffect)(function () {
        var listen = function () { return initResult.instance.on("languageChanged", setInstanceLanguage); };
        if (initResult.isInitialized()) {
            listen();
        }
        else {
            initResult.initializedPromise.finally(listen);
        }
        return function () {
            initResult.instance.off("langaugeChanged", setInstanceLanguage);
        };
    }, [initResult]);
    // Set the page lang for all language changes, regardless of whether they're
    // from the `LocaleDetector` or user initiated.
    //
    // Note that we need to do this directly, rather than via Next, because Next
    // only supports SSR for custom documents:
    // https://nextjs.org/docs/pages/building-your-application/routing/custom-document
    (0, react_1.useEffect)(function () {
        var callback = function (language) { return document.documentElement.setAttribute("lang", language); };
        initResult.instance.on("languageChanged", callback);
        return function () { return initResult.instance.off("languageChanged", callback); };
    }, [initResult.instance]);
    (0, react_1.useEffect)(function () {
        var effectIsActive = true;
        onReadyStateChanged(false);
        var newInitResult = (0, initializeFrontendReact_1.initialize)({
            defaultNamespace: defaultNamespace,
            availableNamespaces: availableNamespaces,
            languages: languages,
            localePreferenceScope: scope,
            gqlRequester: requester,
        });
        // Since this is a whole new instance, we need to consider whether the user
        // has made a language selection this session. If they have, we need to make
        // sure the selection is echoed on the new instance and the selected
        // language is loaded before we bring it active.
        var readyPromise = instanceLanguage != null
            ? newInitResult.instance.changeLanguage(instanceLanguage)
            : newInitResult.initializedPromise;
        readyPromise
            .then(function () {
            if (effectIsActive) {
                setInitResult(newInitResult);
            }
        })
            .catch(function (e) { return console.error("Failed to initialize new i18n instance:", e); })
            .finally(function () { return onReadyStateChanged(true); });
        return function () {
            effectIsActive = false;
        };
        // `instanceLanguage` deliberately excluded from this dependency list
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [availableNamespaces, defaultNamespace, languages, scope, requester]);
    return react_1.default.createElement(react_i18next_1.I18nextProvider, { i18n: initResult.instance }, children);
};
exports.TranslationProvider = TranslationProvider;
