import type { ReactNode } from "react";
import React, { useEffect, useState } from "react";

import type { FilterValue } from "@equiem/react-admin-ui";

// There is no real filters at this moment, but we can add them later
type ApartmentFilterType = "name";

export type ApartmentFiltersResult = Partial<Record<ApartmentFilterType, FilterValue>>;

export type PredefinedTabFilters = "all";

export const ApartmentsStatusTabs: PredefinedTabFilters[] = ["all"];

export interface ApartmentsFilterContext {
  filters: ApartmentFiltersResult;
  setFilters?: (filters: ApartmentFiltersResult) => void;
  search?: string;
  setSearch?: (search: string) => void;
  selectedTab: PredefinedTabFilters;
  setSelectedTab?: (tab: PredefinedTabFilters) => void;
}

export const ApartmentsFilterContext = React.createContext<ApartmentsFilterContext>({
  filters: {},
  search: undefined,
  setSearch: undefined,
  selectedTab: ApartmentsStatusTabs[0],
  setSelectedTab: undefined,
  setFilters: undefined,
});

export const ApartmentsFilterProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [filters, setFilters] = useState<ApartmentFiltersResult>({});
  const [search, setSearch] = useState<string>();
  const [selectedTab, setSelectedTab] = useState<PredefinedTabFilters>(ApartmentsStatusTabs[0]);

  useEffect(() => {
    setSearch("");
  }, [selectedTab, setSearch]);

  return (
    <ApartmentsFilterContext.Provider
      value={{
        filters,
        setFilters,
        search,
        setSearch,
        selectedTab,
        setSelectedTab,
      }}
    >
      {children}
    </ApartmentsFilterContext.Provider>
  );
};
