import React, { useContext } from "react";
import { useTheme } from "../../../contexts/Theme";
import { ModalContext } from "../ModalContext";

export interface Props {
  hideBreakline?: boolean;
  ref?: React.RefObject<HTMLDivElement> | null;
}

export const ModalFooter: React.FC<React.PropsWithChildren<Props>> = ({ children, ref, hideBreakline = false }) => {
  const { stickyFooter, scrollable } = useContext(ModalContext);
  const { isAdmin } = useTheme();

  let classes = "";
  let styles: React.CSSProperties = {};
  if (isAdmin) {
    classes = scrollable && stickyFooter ? "sticky py-5 px-6" : "p-6";
  } else {
    styles = { padding: stickyFooter ? "0.75rem 1.5rem" : "1.5rem" };
  }

  return (
    <div ref={ref} style={{ ...styles, zIndex: 1 }} className={`${classes} footer`}>
      {children}
      <style jsx>{`
        div {
          display: flex;
          justify-content: flex-end;
          transition: all 300ms !important;
        }
        .sticky {
          box-shadow: ${hideBreakline ? undefined : "0 -1px 0 0 rgba(0, 0, 0, 0.1)"};
        }
      `}</style>
    </div>
  );
};
