import type { ComponentProps } from "react";
import React, { useEffect, useMemo, useState } from "react";
import { useTheme } from "../../contexts/Theme";

type AvatarSize = "small" | "medium" | "large" | "x-large";

// eslint-disable-next-line @typescript-eslint/no-type-alias
export type AvatarProps = ComponentProps<"span"> & {
  firstName?: string | null;
  lastName?: string | null;
  imageUrl?: string | null;
  badge?: React.ReactNode | string;
  size?: AvatarSize;
};

const backgroundColors = ["#67CD89", "#CD6767", "#D564DF", "#7C55EA", "#CDB067"];

const getBackgroundColor = (firstName: string, lastName: string) => {
  const res = firstName + lastName;

  const ascii = res
    .split("")
    .filter((char) => char !== " ")
    .reduce((accum, item) => {
      return accum + (!isNaN(item.charCodeAt(0)) ? item.charCodeAt(0) : 0);
    }, 0);

  return backgroundColors[ascii % backgroundColors.length];
};

export const Avatar: React.FC<AvatarProps> = ({
  firstName,
  lastName,
  imageUrl,
  badge,
  size = "medium",
  className = "",
}) => {
  const { colors, isAdmin } = useTheme();
  const [noImageFound, setNoImageFound] = useState(imageUrl == null);

  useEffect(() => {
    setNoImageFound(imageUrl == null);
  }, [imageUrl]);

  const content = useMemo(() => {
    if (imageUrl != null && !noImageFound) {
      return <img src={imageUrl} onError={() => setNoImageFound(true)} />;
    }

    return `${firstName?.[0] ?? ""}${lastName?.[0] ?? ""}`;
  }, [firstName, lastName, imageUrl, noImageFound, setNoImageFound]);

  return (
    <>
      <span className={`avatar avatar-${size} ${className}`}>
        {content}
        {badge != null && (
          <span className={`avatar-badge avatar-badge-${size} ${isAdmin && "avatar-badge-admin"}`}>{badge}</span>
        )}
      </span>
      <style jsx>{`
        .avatar {
          font-weight: 500;
          letter-spacing: 0.04em;
          border-radius: 50%;
          position: relative;
          display: inline-block;
          text-align: center;
          vertical-align: middle;
          background-color: ${getBackgroundColor(firstName ?? "", lastName ?? "")};
          color: ${colors.white};
          position: relative;
        }
        .avatar:after {
          content: "";
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          border: 1px solid ${colors.transparent.black[5]};
          border-radius: 50%;
          z-index: 0;
        }
        .avatar-x-large {
          width: 104px;
          height: 104px;
          line-height: 104px;
          font-size: 32px;
        }
        .avatar-large {
          width: 40px;
          height: 40px;
          line-height: 40px;
          font-size: 14px;
        }
        .avatar-medium {
          width: 32px;
          height: 32px;
          line-height: 32px;
          font-size: 12px;
        }
        .avatar-small {
          width: 24px;
          height: 24px;
          line-height: 24px;
          font-size: 10px;
        }
        .avatar-badge {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          width: 16px;
          height: 16px;
          border-radius: 50%;
          background: ${colors.blue[100]};
          z-index: 1;
        }
        .avatar-badge-admin {
          background: linear-gradient(
              277.52deg,
              #ff794c -65.2%,
              rgba(225, 85, 162, 0.37) 76.96%,
              rgba(225, 85, 162, 0) 131.25%
            ),
            linear-gradient(119.58deg, rgba(0, 0, 170, 0.9) -22.08%, #c931ff 231.23%), #ffffff;
        }
        .avatar-badge-small {
          transform: scale(0.75);
          top: -3px;
          right: -3px;
        }
        .avatar-badge-medium {
          transform: scale(1);
          top: -2px;
          right: -2px;
        }
        .avatar-badge-large {
          transform: scale(1);
          top: -2px;
          right: -2px;
        }
        .avatar :global(img) {
          width: 100%;
          height: 100%;
          border-radius: 50%;
          display: block;
        }
      `}</style>
    </>
  );
};
