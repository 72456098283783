import React, { useEffect } from "react";
import { useRouter } from "next/router";

import { useTheme } from "@equiem/react-admin-ui";

import { AppointmentsMenu } from "../../components/AppointmentsMenu";
import { withContexts } from "../../contexts/withContexts";

import { AppointmentsFilterProvider } from "./contexts/AppointmentsFilterContext";
import { VisitorAppointmentsTableContainer } from "./VisitorAppointmentsTableContainer";

export const VisitorAppointmentsBase: React.FC = () => {
  const router = useRouter();
  const { colors } = useTheme();

  useEffect(() => {
    void router.prefetch("/visitor-management/new-appointment");
    void router.prefetch("/visitor-management/settings/receptions");
  }, [router]);

  return (
    <AppointmentsFilterProvider>
      <div className="appointments d-flex flex-column flex-grow-1 flex-shrink-1 flex-basis-0">
        <AppointmentsMenu />
        <VisitorAppointmentsTableContainer />
        <style jsx>
          {`
            .appointments {
              background: ${colors.white};
            }
          `}
        </style>
      </div>
    </AppointmentsFilterProvider>
  );
};

export const VisitorAppointments = withContexts(VisitorAppointmentsBase);
