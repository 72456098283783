import { Form as EqForm, remainingCharacters, Modal } from "@equiem/react-admin-ui";
import { Field, Form, Formik } from "formik";
import type { FC } from "react";
import { useContext, useMemo, useState } from "react";
import type { EditEventPostInput as EditInput, EquiemOnePostAudience } from "../generated/iris-client";
import { useCreateEventPostMutation, MyPostsDocument, useEditEventPostMutation } from "../generated/iris-client";
import { DateTime } from "luxon";
import { RiBuilding4Fill, RiTeamFill } from "@equiem/react-admin-ui/icons";
import dynamic from "next/dynamic";
import * as yup from "yup";
import omit from "lodash.omit";

import { Role, Site, AddressAutoCompleteField } from "@equiem/lib";
import { ImageUploader } from "../lib/ImageUploader";

import { Session } from "@equiem/lib/context/SessionContext";
import type { EventFormValues } from "../lib/EventFormValues";
import { dateTimeLocaleInputDisplayFormat } from "../lib/dateFormats";
import { getEventPostUrl } from "../hooks/useContentUrl";
import { FormFooter } from "../FormFooter";
import { useModalDescription } from "../lib/useModalDescription";
import { useTranslation } from "@equiem/localisation-eq1";
import { ModalContent } from "../ContentProvider";

export type EventPost = {
  type: "event_post";
};

const CKEditorNoSSR = dynamic(async () => import("../lib/CustomCkEditor"), {
  ssr: false,
});

export type EditEventPostInput = EditInput & EventPost & { postAudience?: EquiemOnePostAudience | undefined };

export interface Props {
  post: EditEventPostInput | EventPost;
}

export const EventPostForm: FC<Props> = ({ post }) => {
  const { t } = useTranslation();
  const { webAppUrl } = useContext(Site);
  const {
    closeModal,
    contentSaved,
    currentRole,
    setContentSaved,
    setContentUrl,
    setIsDraft,
    setModalBodyTitle,
    setModalTitle,
    setShowSiteLink,
  } = useContext(ModalContent);

  const event = useMemo(() => ("id" in post && post.id != null ? post : null), [post]);
  const session = useContext(Session);
  const [saving, setSaving] = useState(false);
  const [isDraft, _setIsDraft] = useState(false);
  const { name: siteName, timezone } = useContext(Site);
  const audienceWithFallback = event != null ? event.postAudience ?? "WORKPLACE" : "WORKPLACE";

  const [selectedPostAudience, setSelectedPostAudience] = useState(audienceWithFallback);

  const { modalDescription } = useModalDescription();

  const modalTitle = useMemo(() => {
    if (isDraft) {
      return t("common.saved");
    }
    if (selectedPostAudience === "WORKPLACE") {
      return t("home.widgets.published");
    } else {
      return t("common.submitted");
    }
  }, [selectedPostAudience, isDraft]);

  const modalDesc = useMemo(() => {
    return modalDescription(selectedPostAudience, isDraft, event != null);
  }, [modalDescription, selectedPostAudience, isDraft, event]);

  const [createMutation] = useCreateEventPostMutation({
    client: session.irisClient?.cortex,
  });
  const [editMutation] = useEditEventPostMutation({
    client: session.irisClient?.cortex,
  });

  const toggleDraft = (draft: boolean) => {
    _setIsDraft(draft);
    setShowSiteLink(!draft && selectedPostAudience === "WORKPLACE");
    setIsDraft(draft);
  };

  const MAX_TITLE_LENGTH = 100;
  const validationSchema = () =>
    yup.object().shape({
      title: yup.string().max(MAX_TITLE_LENGTH).required(),
      body: yup.string().required(),
      publishAt: yup.string().required(),
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      dateRange: yup
        .object()
        .shape({
          start: yup.string().required(),
          end: yup.string().required(),
        })
        .required(),
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      location: yup
        .object()
        .shape({
          google_place: yup.string().required(),
          name: yup.string().required(),
        })
        .required(),
      images: yup.array().of(
        yup
          .object()
          .shape({
            alt: yup.string().required(),
            key: yup.string().required(),
            title: yup.string().required(),
            thumbnail_key: yup.string().required(),
          })
          .required(),
      ),
    });

  const now = DateTime.local({ zone: timezone });

  const initialValues: EventFormValues = {
    title: "",
    body: "",
    dateRange: {
      start: "",
      end: "",
      timezone,
    },
    draft: false,
    location: {
      google_place: "",
      name: "",
    },
    rsvpLimit: 0,
    postAudience: audienceWithFallback,
    publishAt: event?.publishAt ?? now.toFormat(dateTimeLocaleInputDisplayFormat),
    role: currentRole === Role.PropertyManager ? "PROPERTY_MANAGER" : "WORKPLACE_MANAGER",
    ...event,
    images: [
      {
        alt: event?.images[0].alt ?? "",
        key: event?.images[0].key ?? "",
        title: event?.images[0].title ?? "",
        thumbnail_key: event?.images[0].key ?? "",
      },
    ],
  };

  const isNewPost = event == null;

  return (
    <>
      {!contentSaved && (
        <Formik<EventFormValues>
          initialValues={initialValues}
          validationSchema={validationSchema()}
          onSubmit={async (values, actions) => {
            setSaving(true);

            const commonValues = {
              ...omit(values, "type", "postAudience", "role"),
              draft: isDraft,
              location: {
                ...omit(values.location, "__typename"),
              },
              dateRange: {
                ...values.dateRange,
                start: DateTime.fromISO(values.dateRange.start!, {
                  zone: timezone,
                })
                  .setZone("utc")
                  .toString(),
                end: DateTime.fromISO(values.dateRange.end!, {
                  zone: timezone,
                })
                  .setZone("utc")
                  .toString(),
              },
            };

            if ("id" in values) {
              const result = await editMutation({
                client: session.irisClient?.cortex,
                variables: {
                  updateEventInput: {
                    ...commonValues,
                    id: values.id,
                  },
                },
                refetchQueries: [{ query: MyPostsDocument }],
              });

              if (result.data?.editEventPost?.post != null && result.errors == null) {
                setContentUrl(getEventPostUrl(result.data.editEventPost.post.uuid ?? "", webAppUrl));
                setModalBodyTitle(modalDesc);
                setModalTitle(modalTitle);
              }
            } else {
              const result = await createMutation({
                client: session.irisClient?.cortex,
                variables: {
                  createEventInput: {
                    ...commonValues,
                    role: values.role,
                    postAudience: values.postAudience,
                  },
                },
                refetchQueries: [{ query: MyPostsDocument }],
              });

              if (result.data?.createEventPost?.post != null && result.errors == null) {
                setContentUrl(getEventPostUrl(result.data.createEventPost.post.uuid ?? "", webAppUrl));
                setModalBodyTitle(modalDesc);
                setModalTitle(modalTitle);
                actions.setSubmitting(false);
              }
            }
            setSaving(false);
            setContentSaved(true);
          }}
        >
          {({ isValid, isSubmitting, dirty, values, submitForm }) => (
            <>
              <Modal.Body>
                <Form>
                  {isNewPost && (
                    <EqForm.Group label={t("common.audience")} required>
                      <div className="d-flex flex-row">
                        <Field
                          label={t("common.company")}
                          className="w-50 mr-2"
                          component={EqForm.HeroRadioSelect}
                          id="WORKPLACE"
                          name="postAudience"
                          icon={RiTeamFill}
                          title={t("common.workplace")}
                          description={t("home.widgets.postWorkplaceHist")}
                          onClick={() => {
                            setSelectedPostAudience("WORKPLACE");
                          }}
                        ></Field>
                        <Field
                          label={t("visitors.common.building")}
                          className="w-50 mr-2"
                          component={EqForm.HeroRadioSelect}
                          id="BUILDING"
                          icon={RiBuilding4Fill}
                          name="postAudience"
                          title={t("home.widgets.siteCommunity")}
                          description={t("home.widgets.siteCommunityHint", { siteName })}
                          onClick={() => {
                            setShowSiteLink(false);
                            setSelectedPostAudience("BUILDING");
                          }}
                        ></Field>
                      </div>
                    </EqForm.Group>
                  )}
                  <EqForm.Group label={t("common.image")} required>
                    <Field id="images" name="images" placeholder={t("common.image")} as={ImageUploader} />
                  </EqForm.Group>
                  <EqForm.Group
                    label={t("home.widgets.postTitle")}
                    required
                    hint={t("common.remainingCharacters", {
                      count: remainingCharacters(MAX_TITLE_LENGTH, values.title.length),
                    })}
                  >
                    <Field
                      id="title"
                      name="title"
                      placeholder={t("home.widgets.postTitleHint")}
                      as={EqForm.Input}
                      disabled={saving}
                      maxLength={100}
                    />
                  </EqForm.Group>
                  <EqForm.Group label={t("common.description")} required>
                    <Field id="body" name="body" placeholder={t("common.body")} as={CKEditorNoSSR} disabled={saving} />
                  </EqForm.Group>
                  <div className="d-flex flex-row mb-5">
                    <EqForm.Group label={t("home.widgets.startDateAndTime")} required className="mr-3">
                      <Field
                        id="start-time"
                        name="dateRange.start"
                        type="datetime-local"
                        min={now.toFormat(dateTimeLocaleInputDisplayFormat)}
                        as={EqForm.Input}
                      />
                    </EqForm.Group>
                    <EqForm.Group label={t("home.widgets.finishDateAndTime")} required className="ml-3">
                      <Field
                        id="finish-time"
                        name="dateRange.end"
                        type="datetime-local"
                        min={now.toFormat(dateTimeLocaleInputDisplayFormat)}
                        as={EqForm.Input}
                      />
                    </EqForm.Group>
                  </div>
                  <EqForm.Group label={t("home.widgets.locationName")} required>
                    <Field
                      id="locationName"
                      name="location.name"
                      placeholder={t("home.widgets.locationNameHint")}
                      as={EqForm.Input}
                      disabled={saving}
                    />
                  </EqForm.Group>
                  <EqForm.Group label={t("home.widgets.locationAddress")} required>
                    <Field
                      id="locationAddress"
                      name="location.google_place"
                      placeholder={t("home.widgets.locationAddressHint")}
                      disabled={saving}
                      component={AddressAutoCompleteField}
                    />
                  </EqForm.Group>
                  <EqForm.Group label={t("home.widgets.capacityNumberOfTickets")} required>
                    <Field
                      id="rsvpLimit"
                      name="rsvpLimit"
                      type="number"
                      placeholder="300"
                      as={EqForm.Input}
                      disabled={saving}
                    />
                  </EqForm.Group>
                  <EqForm.Group
                    label={`${selectedPostAudience === "BUILDING" ? t("home.widgets.preferred") : ""} ${t(
                      "home.widgets.publicationDateTime",
                    )}`}
                    required
                  >
                    <Field
                      id="publication-date"
                      name="publishAt"
                      type="datetime-local"
                      min={now.toFormat(dateTimeLocaleInputDisplayFormat)}
                      as={EqForm.Input}
                    />
                  </EqForm.Group>
                </Form>
              </Modal.Body>
              <FormFooter
                close={closeModal}
                saveDraft={() => {
                  toggleDraft(true);
                  submitForm().catch((e) => {
                    console.error(e);
                  });
                }}
                savePublished={() => {
                  toggleDraft(false);
                  submitForm().catch((e) => {
                    console.error(e);
                  });
                }}
                postAudience={selectedPostAudience}
                disabled={!isValid || isSubmitting || (!dirty && event == null)}
                isPublished={event?.draft === false}
                saving={saving}
              />
            </>
          )}
        </Formik>
      )}
    </>
  );
};
