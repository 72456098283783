import React, { type FC, useState, useContext } from "react";
import { useTranslation, formatters, type TFunction } from "@equiem/localisation-eq1";

import { useTheme } from "../../contexts/Theme";
import * as Dropdown from "../Dropdown";
import * as Form from "../Form";
import { Text } from "../Text/Text";
import {
  type FilterItemProps,
  type FilterItemDuration,
  FilterType,
  FilterDurationModifier,
} from "./ComplexFilter.types";
import { ComplexFilterContext } from "./ComplexFilterContext";
import { FilterChip } from "./FilterChip";
import { ModifierSelect } from "./ModifierSelect";
import { toMinutes } from "../../util/durationString";

export const defaultModifier = FilterDurationModifier.is;

const format = (duration: string, language: string | undefined) => {
  const minutes = toMinutes(duration);
  return minutes != null ? formatters.durationshort(minutes, language) : "";
};

const parseValue = (value: string | string[] | undefined, modifier: FilterDurationModifier) => {
  const isRange = modifier === FilterDurationModifier.between;

  let rangeStart: string | undefined;
  let rangeEnd: string | undefined;
  if (Array.isArray(value)) {
    rangeStart = value[0];
    rangeEnd = isRange ? value[1] : value[0];
  } else {
    rangeStart = value;
    rangeEnd = value;
  }

  return { isRange, rangeStart, rangeEnd };
};

const modifierLabel = (modifier: FilterDurationModifier, t: TFunction) => {
  switch (modifier) {
    case FilterDurationModifier.is:
      return t("common.is");
    case FilterDurationModifier.less:
      return t("common.lessThan");
    case FilterDurationModifier.greater:
      return t("common.greaterThan");
    case FilterDurationModifier.between:
      return t("common.between");
    default:
      return "";
  }
};

const chipLabel = (
  modifier: FilterDurationModifier,
  rangeStart: string | undefined,
  rangeEnd: string | undefined,
  t: TFunction,
  language: string | undefined,
) => {
  if (rangeStart == null) {
    return undefined;
  }

  if (modifier === FilterDurationModifier.is) {
    return `${t("common.is")} ${format(rangeStart, language)}`;
  }
  if (modifier === FilterDurationModifier.less) {
    return `< ${format(rangeStart, language)}`;
  }
  if (modifier === FilterDurationModifier.greater) {
    return `> ${format(rangeStart, language)}`;
  }

  if (modifier === FilterDurationModifier.between && rangeEnd != null) {
    return `${format(rangeStart, language)} - ${format(rangeEnd, language)}`;
  }

  return undefined;
};

export const FilterDuration: FC<FilterItemProps<FilterItemDuration>> = ({ title, name, modifiers, icon }) => {
  const { values, setValue, removeValue, language } = useContext(ComplexFilterContext);
  const { colors } = useTheme();
  const { t } = useTranslation();

  const [selectedModifier, setSelectedModifier] = useState<FilterDurationModifier>(
    (values[name]?.modifier ?? modifiers?.[0] ?? defaultModifier) as FilterDurationModifier,
  );
  const [prevSelectedModifier, setPrevSelectedModifier] = useState(selectedModifier);

  const { isRange, rangeStart, rangeEnd } = parseValue(
    values[name]?.value as string | string[] | undefined,
    selectedModifier,
  );

  if (selectedModifier !== prevSelectedModifier) {
    setPrevSelectedModifier(selectedModifier);
    setValue(name, {
      type: FilterType.duration,
      modifier: selectedModifier,
      value: undefined,
    });
  }

  const handleStartChange = (newStart: string) => {
    if (selectedModifier === FilterDurationModifier.between) {
      setValue(name, { type: FilterType.duration, modifier: selectedModifier, value: [newStart, rangeEnd ?? ""] });
    } else {
      setValue(name, { type: FilterType.duration, modifier: selectedModifier, value: newStart });
    }
  };

  const handleEndChange = (newEnd: string) => {
    if (selectedModifier === FilterDurationModifier.between) {
      setValue(name, { type: FilterType.duration, modifier: selectedModifier, value: [rangeStart ?? "", newEnd] });
    }
  };

  return (
    <>
      <Dropdown.Dropdown placement="bottom-start" flip>
        <FilterChip
          title={title}
          value={chipLabel(selectedModifier, rangeStart, rangeEnd, t, language)}
          type={FilterType.duration}
          name={name}
          onClose={() => removeValue(name)}
          icon={icon}
          size="lg"
        />
        <Dropdown.Menu width={isRange ? 320 : 252} mobileView="regular">
          {modifiers != null && (
            <div className="duration-top">
              <Text variant="label" className="m-0">
                {title}
              </Text>
              <ModifierSelect
                options={modifiers.map((m) => ({ value: m, label: modifierLabel(m, t) }))}
                selected={selectedModifier}
                onChange={(v) => setSelectedModifier(v as FilterDurationModifier)}
              />
            </div>
          )}
          <div className={isRange ? "range-input" : "duration-input"}>
            <Form.Duration
              value={rangeStart ?? ""}
              max={isRange ? toMinutes(rangeEnd) ?? undefined : undefined}
              onChange={(e) => handleStartChange(e.target.value)}
            />
            {isRange && (
              <Form.Duration
                value={rangeEnd ?? ""}
                min={toMinutes(rangeStart) ?? undefined}
                onChange={(e) => handleEndChange(e.target.value)}
              />
            )}
          </div>
        </Dropdown.Menu>
      </Dropdown.Dropdown>
      <style jsx>{`
        .duration-top {
          padding: 0 1rem 0.25rem;
          display: flex;
          flex-direction: row;
          align-items: center;
          color: ${colors.grayscale[100]};
        }
        .duration-input {
          display: flex;
          padding: 0 0.5rem;
        }
        .range-input {
          padding: 0 0.5rem;
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-gap: 0.5rem;
        }
      `}</style>
    </>
  );
};
