export const unauthenticatedPaths = [
  "/",
  "/auth/error",
  "/cookiepolicy",
  "/cookies",
  "/favicon.ico",
  "/privacy",
  "/privacypolicy",
  "/termsandconditions",
  "/legal",
  "/user/register",
];
