import { Session } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import { Button, useTheme } from "@equiem/react-admin-ui";
import { AudienceModal } from "@equiem/segmentation-widget";
import type { FieldInputProps } from "formik";
import type { FC } from "react";
import React, { useContext, useState } from "react";

interface Value {
  segmentIds?: string[];
  segmentSummary?: string;
}

interface Props extends FieldInputProps<Value | undefined> {
  siteUuid: string;
  gatewayEndpoint?: string;
}

export const AudienceField: FC<Props> = ({ onChange, value, name, siteUuid, gatewayEndpoint }) => {
  const [showModal, setShowModal] = useState(false);
  const { colors, spacers, borderRadius } = useTheme();
  const { t } = useTranslation();
  const { authenticatedClient } = useContext(Session);

  const removeAudience = () => {
    onChange({
      target: {
        value: null,
        name,
      },
    });
  };

  return (
    <>
      <AudienceModal
        connection={{ client: authenticatedClient, gatewayEndpoint }}
        site={siteUuid}
        segmentIds={value?.segmentIds}
        callback={(segmentIds, segmentSummary) => {
          onChange({
            target: {
              value: {
                segmentIds,
                segmentSummary,
              },
              name,
            },
          });
          setShowModal(false);
        }}
        modal={{
          showModal,
          onHide: () => setShowModal(false),
        }}
      />
      <div className="audience-toggle d-flex align-items-center justify-content-between">
        <h4>{value?.segmentSummary ?? t("common.all")}</h4>
        {value == null ? (
          <Button variant="outline" onClick={() => setShowModal(true)}>
            {t("bookings.resources.selectAudience")}
          </Button>
        ) : (
          <Button variant="outline" onClick={removeAudience}>
            {t("common.remove")}
          </Button>
        )}
      </div>
      <style jsx>{`
        .audience-toggle {
          border: 1px solid ${colors.border};
          border-radius: ${borderRadius};
          padding: ${spacers.s4};
        }
        h4 {
          font-size: 14px;
        }
      `}</style>
    </>
  );
};
