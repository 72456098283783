import React, { forwardRef } from "react";
import { optionLabel, type Option } from "./model/Option";
import { useTheme } from "../../../contexts";
import { FormSelectFacade } from "../FormSelect/FormSelectFacade";

interface P {
  localValue: string[];
  options: Option[];
  disabled?: boolean;
  emptyLabel?: React.ReactNode | string;
  className?: string;
}
export const FormFacadeSimple = forwardRef<HTMLDivElement, P>(
  ({ localValue, options, disabled = false, emptyLabel, className = "" }, ref) => {
    const theme = useTheme();

    return (
      <>
        <FormSelectFacade disabled={disabled} className={className} ref={ref}>
          <>
            {localValue.length > 0 ? (
              <div className="multi">
                {localValue.map((value) => {
                  const option = options.find((o) => o.value === value);
                  return option == null ? null : <div key={option.value}>{optionLabel(option, true)}</div>;
                })}
              </div>
            ) : (
              <>{emptyLabel}</>
            )}
          </>
        </FormSelectFacade>
        <style jsx>{`
          .multi div {
            margin-bottom: ${theme.spacers.s3};
          }
          .multi div:last-child {
            margin-bottom: 0;
          }
        `}</style>
      </>
    );
  },
);

FormFacadeSimple.displayName = "FormFacadeSimple";
