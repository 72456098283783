import React from "react";
import { useTranslation } from "@equiem/localisation-eq1";
import { useTheme } from "@equiem/react-admin-ui";
import { WidgetEmpty } from "../images/widget-empty";

export const EmptyState = () => {
  const { colors } = useTheme();
  const { t } = useTranslation();
  return (
    <>
      <div className="empty-state">
        <div className="title">{t("credits.emptyState.title")}</div>
        <div className="image-container">
          <WidgetEmpty />
        </div>
        <div className="info">{t("credits.emptyState.description")}</div>
      </div>
      <style jsx>
        {`
          .title {
            color: ${colors.grayscale[50]};
            font-weight: 700;
            font-size: 20px;
            align-self: flex-start;
            position: relative;
            top: 32px;
            z-index: 1;
            line-height: 24px;
          }
          .image-container {
            position: absolute;
            bottom: 0;
          }
          .empty-state {
            overflow: hidden;
            display: flex;
            position: relative;
            justify-content: center;
            align-items: center;
            background: ${colors.grayscale[3]};
            height: 272px;
          }

          .info {
            position: absolute;
            bottom: 0;
            background: ${colors.white};
            padding: 16px;
            width: 100%;
            line-height: 32px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: ${colors.grayscale[60]};
          }
        `}
      </style>
    </>
  );
};
