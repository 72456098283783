import React, { useCallback, useContext, useEffect, useMemo } from "react";
import { DateTime } from "luxon";

import type { WidgetProps } from "@equiem/lib";
import { notNullOrUndefined, Site } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import { Button, ProgressCircle, Widget as WidgetUI } from "@equiem/react-admin-ui";
import { RiTeamLine } from "@equiem/react-admin-ui/icons";

import { useVisitorReceptionsStatsQuery, VisitorRole } from "../generated/visitors-client";
import { useReceptionDate } from "../pages/reception/hooks/useReceptionDate";
import { ReceptionItem } from "../pages/receptions/components/ReceptionItem";

import { EmptyWidget } from "./components/EmptyWidget";

type Props = Pick<WidgetProps, "setLoading">;

export const ReceptionSelectWidget: React.FC<Props> = ({ setLoading }) => {
  const { t } = useTranslation();
  const { timezone, name } = useContext(Site);
  const { pushUrl } = useReceptionDate();
  const {
    data: receptionsData,
    loading,
    fetchMore,
  } = useVisitorReceptionsStatsQuery({
    variables: {
      as: VisitorRole.Receptionist,
      dates: [
        DateTime.local().setZone(timezone).startOf("day").toMillis(),
        DateTime.local().setZone(timezone).plus({ days: 1 }).startOf("day").toMillis(),
      ],
    },
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    setLoading(loading);
  }, [loading, setLoading]);

  const receptions = useMemo(
    () => receptionsData?.visitorReceptions.edges.map((e) => e.node).filter(notNullOrUndefined) ?? [],
    [receptionsData],
  );

  const handleNextPage = useCallback(
    async () =>
      fetchMore({
        variables: {
          after: receptionsData?.visitorReceptions.pageInfo.endCursor,
        },
      }),
    [receptionsData?.visitorReceptions.pageInfo.endCursor],
  );

  const hasNextPage = receptionsData?.visitorReceptions.pageInfo.hasNextPage ?? false;
  const isEmptyWidget = receptions.length === 0 && !loading;
  const isReceptionistMultipleBuildings = useMemo(
    () => receptions.filter((reception) => reception.company == null).length > 1,
    [receptions],
  );

  return (
    <>
      <WidgetUI.Widget
        buttonText={t("visitors.widgets.backToTop")}
        icon={RiTeamLine}
        title={t("visitors.receptions.selectReception")}
      >
        {isEmptyWidget ? (
          <div className="empty d-flex align-items-center justify-content-center">
            <EmptyWidget text={t("visitors.widgets.noAccess")} />
          </div>
        ) : (
          <div className="d-flex flex-column pb-3 p-5 list">
            {receptions.map((reception) => (
              <ReceptionItem
                key={reception.uuid}
                type={reception.company != null ? "tenant" : "building"}
                uuid={reception.uuid}
                buildingName={reception.building?.name}
                buildingLevelName={reception.buildingLevel?.name}
                companyName={reception.company?.name}
                suffix={reception.suffix}
                visitorStats={[reception.dailyStats[0].preBookedCount, reception.dailyStats[1].preBookedCount]}
              />
            ))}
            <div className="mt-3 p-3 d-flex align-items-center justify-content-center">
              {loading ? (
                <ProgressCircle className="d-flex align-items-center justify-content-center" size={"sm"} />
              ) : (
                <>
                  <Button
                    variant={!hasNextPage ? "ghost" : "outline"}
                    size="sm"
                    onClick={() => {
                      void handleNextPage();
                    }}
                    disabled={!hasNextPage}
                  >
                    {!hasNextPage ? t("common.endOfList") : t("common.loadMore")}
                  </Button>
                </>
              )}
            </div>
            {isReceptionistMultipleBuildings && (
              <Button
                className="d-flex w-100"
                variant="secondary"
                size="md"
                onClick={() => {
                  void pushUrl("/visitor-management/building-receptions");
                }}
              >
                {t("visitors.receptions.allVisitorsForSiteName", { siteName: name })}
              </Button>
            )}
          </div>
        )}

        <style jsx>{`
          .empty {
            height: calc(100% - 100px);
          }
          .list {
            gap: 8px;
          }
        `}</style>
      </WidgetUI.Widget>
    </>
  );
};
