import React, { useEffect } from "react";
import { Field, FieldArray, Form } from "formik";

import type { CompanyFormValues } from "@equiem/lib";
import { notNullOrUndefined, useSaferFormikContext, useShowError } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import { Form as EqForm, useTheme } from "@equiem/react-admin-ui";

import { useWidgetContext } from "../../../contexts/WidgetContext";
import type { CompanyDetailsQuery } from "../../../generated/settings-client";
import { useAc1CompanySiteCredentialSetupConfigsFromAccessGroupsQuery } from "../../../generated/settings-client";

interface Props {
  accessGroups: Array<{ uuid: string; name: string }>;
  company: NonNullable<CompanyDetailsQuery["companyV2"]>;
  onAccessPassesChange: (values: CompanyFormValues) => void;
}

export const DefaultAccessGroupPicker: React.FC<Props> = ({ accessGroups, company, onAccessPassesChange }) => {
  const { t } = useTranslation();
  const { colors } = useTheme();
  const showError = useShowError();
  const { subscribe } = useWidgetContext();
  const { submitForm, setFieldValue, values } = useSaferFormikContext<CompanyFormValues>();
  const companyAccessGroupsUuids = company.companyDestination?.ac1Config?.accessGroups?.map((g) => g.uuid) ?? [];
  const companyAccessGroups = accessGroups.filter((group) => {
    return companyAccessGroupsUuids.includes(group.uuid);
  });
  const { data: accessPasses } = useAc1CompanySiteCredentialSetupConfigsFromAccessGroupsQuery({
    variables: { accessGroupUuids: companyAccessGroupsUuids, companyUuid: company.uuid },
    fetchPolicy: "network-only", // it prevents getting old values from cache if we edit company again without refreshing the page
  });

  useEffect(() => {
    const newAccessPasses =
      accessPasses?.ac1CompanySiteCredentialSetupConfigsFromAccessGroups.filter(notNullOrUndefined).map((data) => {
        return {
          accessPassesAvailableNumber: data.accessPassesAvailableNumber,
          accessPassesMaxNumber: data.accessPassesMaxNumber,
          autoAssignment: data.autoAssignment,
          category: data.credentialSetup.category,
          passName: data.credentialSetup.passName,
          uuid: data.credentialSetup.uuid,
          enabled: data.enabled,
        };
      }) ?? [];
    onAccessPassesChange({ ...values, accessPasses: newAccessPasses });
  }, [accessPasses]);

  useEffect(() => {
    return subscribe("onSave", () => {
      void submitForm();
    });
  }, [submitForm, subscribe]);

  return (
    <>
      <Form>
        <EqForm.Group
          required
          label={t("settings.editCompany.defaultGroupLabel")}
          showTooltip
          tooltipText={t("settings.editCompany.accessGroupTooltip")}
        >
          <Field as={EqForm.Select} name="defaultAccessGroupUuid">
            <option value="">{t("settings.editCompany.selectDefaultAccessGroupsPlaceholder")}</option>
            {companyAccessGroups.map((group) => (
              <option key={group.uuid} value={group.uuid}>
                {group.name}
              </option>
            ))}
          </Field>
        </EqForm.Group>
        <>
          {values.accessPasses != null && values.accessPasses.length > 0 && (
            <EqForm.Group
              className="form-box mb-0"
              required
              label={t("settings.editCompany.selectAccessPassesTitle")}
              showTooltip
              tooltipText={t("settings.editCompany.selectPassTooltip")}
            >
              <FieldArray
                name="accessPasses"
                render={() => (
                  <>
                    {values.accessPasses
                      ?.filter((pass) => pass.enabled)
                      .map((config, index) => {
                        const isFirst = index === 0;
                        const isLast = values.accessPasses != null && index === values.accessPasses.length - 1;
                        return (
                          <div
                            key={`accessPass-${index}`}
                            className={`access-pass-div ${isFirst ? "first" : ""} ${
                              isLast ? "last" : ""
                            } d-flex flex-column `}
                          >
                            <section className="d-flex align-items-center">
                              <span className="access-pass-label">{config.passName}</span>
                            </section>
                            {values.accessPasses?.length != null && (
                              <section className="d-flex access-passes-settings">
                                <EqForm.Group
                                  className="form-box mb-0"
                                  label={t("settings.editCompany.assigmentTitle")}
                                  showTooltip
                                  tooltipText={t("settings.editCompany.assigmentTooltip")}
                                >
                                  <div className="d-flex assigment">
                                    <EqForm.RadioButton
                                      name={`accessPasses[${index}].autoAssignment`}
                                      checked={values.accessPasses[index].autoAssignment}
                                      label={t("settings.editCompany.autoAssignment")}
                                      labelStyle={{ padding: "8px 16px 8px 8px", gap: "4px" }}
                                      onChange={() => {
                                        setFieldValue(
                                          `accessPasses[${index}].autoAssignment` as keyof CompanyFormValues,
                                          true,
                                        ).catch(showError);
                                      }}
                                    />

                                    <EqForm.RadioButton
                                      name={`accessPasses[${index}].autoAssignment`}
                                      checked={!values.accessPasses[index].autoAssignment}
                                      label={t("settings.editCompany.addingCardsManuallyLabel")}
                                      labelStyle={{ padding: "8px 16px 8px 8px", gap: "4px" }}
                                      onChange={() => {
                                        setFieldValue(
                                          `accessPasses[${index}].autoAssignment` as keyof CompanyFormValues,
                                          false,
                                        ).catch(showError);
                                      }}
                                    />
                                  </div>
                                </EqForm.Group>

                                <EqForm.Group
                                  className="form-box mb-0 d-flex flex-column"
                                  style={{ gap: "8px" }}
                                  label={t("settings.editCompany.availableAccessesTitle")}
                                  showTooltip
                                  tooltipText={t("settings.editCompany.maximumNumberOfAccessesTooltip")}
                                >
                                  <span className="access-count-label">
                                    {values.accessPasses[index].accessPassesAvailableNumber}/
                                    {values.accessPasses[index].accessPassesMaxNumber}
                                  </span>
                                </EqForm.Group>
                              </section>
                            )}
                          </div>
                        );
                      })}
                  </>
                )}
              />
            </EqForm.Group>
          )}
        </>
      </Form>
      <style jsx>
        {`
          .form-box {
            width: fit-content;
            border: 1px solid ${colors.grayscale[20]};
          }

          .access-pass-label {
            font-size: 16px;
          }

          .access-pass-div {
            gap: 16px;
            padding: 16px;
            border-left: 1px solid ${colors.grayscale[20]};
            border-right: 1px solid ${colors.grayscale[20]};
          }

          .access-pass-div:not(.first) {
            border-top: 1px solid ${colors.grayscale[20]};
          }

          .access-pass-div.last {
            border-bottom: 1px solid ${colors.grayscale[20]};
          }

          .access-pass-div.first {
            border-top: 1px solid ${colors.grayscale[20]};
            border-radius: 16px 16px 0 0;
          }

          .access-pass-div.last {
            border-radius: 0 0 16px 16px;
          }

          .access-pass-div.first.last {
            border-radius: 16px;
          }

          .access-passes-settings {
            gap: 40px;
          }

          .assigment {
            width: fit-content;
            gap: 8px;
          }

          .access-count-label {
            font-size: 16px;
          }
        `}
      </style>
    </>
  );
};
