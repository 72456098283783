import React from "react";

import { useTranslation } from "@equiem/localisation-eq1";
import { Text, useIsMobileWidth, useTheme } from "@equiem/react-admin-ui";

import { SettingsHead } from "../../components/SettingsHead";
import { withContexts } from "../../contexts/withContexts";

import { BuildConfiguration } from "./components/BuildConfiguration";
import { BuildingDataProvider } from "./contexts/BuildingDataContext";
import { BuildingDetailsWidget } from "./widgets/BuildingDetailsWidget";

const BuildingBase: React.FC = () => {
  const { t } = useTranslation();
  const { colors, breakpoints } = useTheme();
  const isMobile = useIsMobileWidth();

  return (
    <BuildingDataProvider>
      <div className="page-container">
        <SettingsHead />
        <div className="main-container">
          {!isMobile && (
            <div className="d-flex align-items-center mb-5">
              <Text variant="heading" size="medium">
                {t("settings.buildConfiguration")}
              </Text>
            </div>
          )}
          <BuildConfiguration />
          <BuildingDetailsWidget />
        </div>
      </div>
      <style jsx>{`
        .page-container {
          height: 100%;
          width: 100%;
          background: ${colors.white};
          overflow: auto;
        }
        .main-container {
          display: flex;
          flex-direction: column;
          padding: 32px;
        }

        @media (max-width: ${breakpoints.lg}px) {
          .main-container {
            padding: 24px 0;
          }
        }
      `}</style>
    </BuildingDataProvider>
  );
};

export const Building = withContexts(BuildingBase);
