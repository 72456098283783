import type { FieldProps } from "formik";
import type { ChangeEvent, FC } from "react";
import { useEffect, useRef, useState } from "react";
import type { IFile } from "@equiem/uploader";
import { RiFileUploadLine } from "react-icons/ri";
import { Button } from "@equiem/react-admin-ui";
import { FileItem } from "./FileItem";
import { useTranslation } from "@equiem/localisation-eq1";

export const FileUploader: FC<FieldProps<IFile[]>["field"]> = ({ value, onChange, name }) => {
  const { t } = useTranslation();
  const fileRef = useRef<HTMLInputElement>(null);
  const [files, setFiles] = useState<Array<File | IFile>>(value ?? []);

  useEffect(() => {
    onChange({
      target: {
        name,
        value: files.filter((f): f is IFile => "key" in f).map((f) => ({ key: f.key, title: f.title })),
      },
    });
  }, [files, onChange, name]);

  const onClick = () => {
    fileRef.current?.click();
  };

  const onRemove = (index: number) => {
    setFiles((prev) => prev.filter((_f, i) => i !== index));
  };

  const onUpload = (index: number, file: IFile) => {
    setFiles((prev) =>
      prev.map((f, i) => {
        if (i === index) {
          return file;
        } else {
          return f;
        }
      }),
    );
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newFiles = event.target.files;
    if (newFiles == null) {
      return;
    }
    setFiles((prev) => [...prev, ...Array.from(newFiles)]);
  };

  return (
    <>
      <input
        onChange={handleChange}
        ref={fileRef}
        hidden
        type="file"
        accept=".png,.jpg,.jpeg,.gif,.pdf"
        multiple={true}
        max={2}
      />
      {files.map((f, i) => {
        return (
          <FileItem
            file={f}
            key={`${"key" in f ? f.key : f.lastModified}-${i}`}
            index={i}
            onRemove={onRemove}
            onUpload={onUpload}
          />
        );
      })}
      <Button variant="outline" onClick={onClick}>
        <RiFileUploadLine /> {t("common.uploadFiles")}
      </Button>
      <style jsx>
        {`
          .center {
            display: flex;
            justify-content: space-between;
            font-size: 10pt;
            font-weight: 300;
          }
        `}
      </style>
    </>
  );
};
