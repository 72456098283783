import type { TFunction } from "@equiem/localisation-eq1";
import type { TabItem } from "@equiem/react-admin-ui";

export type ProfileTabKey =
  | "profile"
  | "notifications"
  | "roles"
  | "history"
  | "integrations"
  | "payment"
  | "flexMembership"
  | "dangerZone";

interface ProfileTab extends TabItem {
  key: ProfileTabKey;
}

export const editProfileTabs = (t: TFunction): ProfileTab[] => [
  {
    title: t("common.profile"),
    key: "profile",
  },
  {
    title: t("settings.notifications.tab"),
    key: "notifications",
  },
  {
    title: t("settings.payment.tab"),
    key: "payment",
  },
  {
    title: t("settings.roles.tab"),
    key: "roles",
  },
  {
    title: t("settings.flexMembership.tab"),
    key: "flexMembership",
  },
  {
    title: t("settings.editProfile.history"),
    key: "history",
  },
  {
    title: t("settings.editProfile.integrations"),
    key: "integrations",
  },
  {
    title: t("settings.dangerZone.tab"),
    key: "dangerZone",
  },
];
