import { useContext, useEffect } from "react";
import { useRouter } from "next/router";

import { Role, Site } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import { RiGroup2Line } from "@equiem/react-admin-ui/icons";

export const Shortcuts = (currentRole: Role) => {
  const router = useRouter();
  const site = useContext(Site);
  const { t } = useTranslation();

  useEffect(() => {
    void router.prefetch("/visitor-management/new-appointment");
  }, [router]);

  if (![Role.WorkplaceManager, Role.PropertyManager].includes(currentRole) || !site.featureModules.visitors.enabled) {
    return [];
  }

  return [
    {
      onClick: async () => router.push("/visitor-management/new-appointment"),
      icon: RiGroup2Line,
      text: t("visitors.appointments.createVisitorAppointment"),
    },
  ];
};
