import { useDisabledSiteSwitcher } from "@equiem/lib";
import React from "react";
import { Articles as ArticlesComponent } from "../components/articles/Articles";
import { PortfolioProvider } from "../contexts/PortfolioProvider";

export const Articles = () => {
  useDisabledSiteSwitcher();

  return (
    <PortfolioProvider>
      <ArticlesComponent />
    </PortfolioProvider>
  );
};
