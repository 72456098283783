import React, { useContext } from "react";

import { useTranslation } from "@equiem/localisation-eq1";
import { Button, Dropdown, Text, useTheme } from "@equiem/react-admin-ui";
import { RiInformationLine } from "@equiem/react-admin-ui/icons";

import type {
  VisitorForBuildingReceptionFragment,
  VisitorForReceptionFragment,
} from "../../../generated/visitors-client";
import { useVisitorInformation } from "../hooks/useVisitorInformation";
import type { FormVisitor } from "../types";

type VisitorForReception = VisitorForReceptionFragment | VisitorForBuildingReceptionFragment;

interface Props {
  isHoverTrigger?: boolean;
  visitor: FormVisitor | VisitorForReception;
  isCloseButton?: boolean;
}

const DropdownCloseButton = () => {
  const { setShow } = useContext(Dropdown.Context);
  const { t } = useTranslation();

  return (
    <Button
      variant="ghost"
      className="mx-0"
      onClick={() => {
        setShow(false);
      }}
    >
      {t("common.done")}
    </Button>
  );
};

export const VisitorInformationDialog = ({ visitor, isCloseButton = true, isHoverTrigger = false }: Props) => {
  const { colors } = useTheme();
  const { t } = useTranslation();
  const { getVisitorInfoTranslation, getVisitorInfoIcons } = useVisitorInformation();
  const receptionNoteMessage =
    (visitor as FormVisitor).receptionNoteMessage ?? (visitor as VisitorForReception).receptionNote?.value;

  return (
    <>
      <Dropdown.Icon
        icon={RiInformationLine}
        hoverIcon={RiInformationLine}
        placement="bottom-end"
        width={300}
        size="sm"
        className="d-flex align-self-center information-icon"
        supportsMobile
      >
        <div className="px-5 mt-2">
          <div className="d-flex align-items-center justify-content-between">
            <Text variant="label" className="visitor-information-title" color={colors.grayscale[60]}>
              {t("visitors.appointmentForm.visitorInformation")}
            </Text>

            {isCloseButton && <DropdownCloseButton />}
          </div>
          <div>
            {visitor.visitorInfo != null && visitor.visitorInfo.length > 0 ? (
              visitor.visitorInfo.map((visitorInfo) => (
                <Text key={visitorInfo} variant="text" className="d-flex mb-5 mt-3 align-items-center">
                  {getVisitorInfoIcons(visitorInfo)({ size: 20 })}
                  <Text variant="label" color={colors.grayscale[100]} className="ml-2">
                    {getVisitorInfoTranslation(visitorInfo)}
                  </Text>
                </Text>
              ))
            ) : (
              <Text variant="text" className="d-flex mb-5 mt-3 align-items-center">
                -
              </Text>
            )}
          </div>
          <div>
            <Text variant="label" weight="medium" color={colors.grayscale[60]}>
              {t("visitors.appointmentForm.noteToReception")}
            </Text>
            <Text variant="text" size="small" color={colors.grayscale[100]}>
              {receptionNoteMessage ?? "-"}
            </Text>
          </div>
        </div>
      </Dropdown.Icon>

      <style jsx>
        {`
          .visitor-information-title {
            font-size: 14px;
            color: ${colors.grayscale[100]};
            font-weight: 500;
          }
        `}
      </style>
    </>
  );
};
