import React from "react";
import { Text, useTheme } from "@equiem/react-admin-ui";

interface P {
  title: string;
}
export const SummaryRightLabel: React.FC<P> = ({ title }) => {
  const theme = useTheme();

  return (
    <div className="pb-3">
      <Text variant="label" style={{ color: theme.colors.grayscale[50] }}>
        {title}
      </Text>
    </div>
  );
};
