import React, { useContext, useEffect, useState } from "react";

import { AppointmentsMenuContext } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import { Button, Modal } from "@equiem/react-admin-ui";

import { Modal as ModalContext } from "../../../contexts/ModalContext";

export const AppointmentCreatedWidget: React.FC = () => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const modal = useContext(ModalContext);
  const appointmentsMenu = useContext(AppointmentsMenuContext);

  useEffect(() => {
    if (modal.activeModal === "AppointmentCreated") {
      setShowModal(true);
    }
  }, [modal.activeModal, setShowModal]);

  const handleDone = () => {
    setShowModal(false);
    modal.close();
    appointmentsMenu.navigatePreviousPage();
    appointmentsMenu.clearState();
  };

  return (
    <>
      <Modal.Dialog
        title={t("visitors.appointmentForm.appointmentCreated")}
        show={showModal}
        hideOnEsc
        hideOnClick={false}
        focusTrapOptions={{ allowOutsideClick: () => true }}
        size="sm"
        centered
      >
        <Modal.Header intro={t("visitors.appointmentForm.appointmentCreatedIntro")} closeButton={false} noBorder />
        <Modal.Body>
          <p className="body m-0">{t("visitors.appointmentForm.appointmentCreatedBody")}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleDone}>
            {t("common.done")}
          </Button>
        </Modal.Footer>
      </Modal.Dialog>
      <style jsx>
        {`
          .body p {
            font-weight: 400;
            font-size: 16px;
          }
          .font-weight-bold {
            line-height: 28px;
          }
        `}
      </style>
    </>
  );
};
