import type { PropsWithChildren } from "react";
import React, { useContext, Fragment } from "react";
import { PageContext } from "@equiem/lib/context/PageContext";

interface P extends PropsWithChildren {
  authenticated: boolean;
}

export const StandaloneProvider: React.FC<P> = ({ children, authenticated }) => {
  const { isInWebNgLayout, noLayoutWhenUnauthenticated } = useContext(PageContext);

  if (!authenticated && (!isInWebNgLayout || noLayoutWhenUnauthenticated === true)) {
    return <Fragment>{children}</Fragment>;
  }

  return <>{children}</>;
};
