import React, { useContext, useState } from "react";
import { Button, ProgressCircle, Text, useTheme, useToast } from "@equiem/react-admin-ui";
import { Formik } from "formik";
import type { CreditsGeneralSettingsValues } from "./FormValues";
import { CreditsPackagesTable } from "./CreditsPackages/CreditPackagesTable";
import { ButtonTray, CurrentProfile, stringNotEmpty } from "@equiem/lib";
import { useErrorTranslation, useTranslation } from "@equiem/localisation-eq1";
import { DiscardChangesModal } from "./DiscardChangesModal";
import { useCreditProviderUpdateMutation, useGetCreditProviderQuery } from "../../../generated/gateway-client";
import { convertCents } from "../utils/convertCents";
import { LoadingSkeleton } from "../CreditsTable/LoadingSkeleton";
import * as yup from "yup";
import { convertToCents } from "../utils/convertToCents";
import isEqual from "lodash/isEqual";
import { ApolloError } from "@apollo/client";

const MAX_SAFE_INTEGER_32 = Math.pow(2, 31) - 1;

const validationSchema = () =>
  yup.object().shape({
    newCreditsPackage: yup
      .object({
        // Since we multiply it by 100 (convert to cents) when sending to the server, we need to make sure it's not too big
        creditsAmount: yup
          .number()
          .moreThan(0)
          .max(MAX_SAFE_INTEGER_32 / 100),
        netPrice: yup.string().test((value?: string) => {
          if (value == null || value === "") {
            return true;
          }
          const numberValue = parseFloat(value);
          return (
            numberValue >= 1 && numberValue * 100 <= MAX_SAFE_INTEGER_32 && /^\d+(\.\d+)?$/.test(String(numberValue))
          );
        }),
      })
      .optional(),
  });

export const CreditsGeneralSettings: React.FC = () => {
  const { colors } = useTheme();
  const { t } = useTranslation();
  const toast = useToast();
  const { tError } = useErrorTranslation();
  const [isAddingPackage, setIsAddingPackage] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const { profile } = useContext(CurrentProfile);

  const flexOperatorUuid = profile?.companyV2?.flexOperator?.uuid as string;
  const {
    data: creditProviderData,
    refetch,
    loading: isCreditProviderLoading,
    previousData,
  } = useGetCreditProviderQuery({
    variables: { providerUuid: flexOperatorUuid },
    skip: flexOperatorUuid == null,
    fetchPolicy: "network-only",
  });
  const isPendingChange = isCreditProviderLoading || isAddingPackage;

  const [updateCreditProvider] = useCreditProviderUpdateMutation();

  const handleSubmit = async ({ creditsPackages, isEnableCreditsPurchase }: CreditsGeneralSettingsValues) => {
    try {
      await updateCreditProvider({
        variables: {
          input: {
            creditProviderUuid: creditProviderData?.creditProvider?.uuid as string,
            purchasableItems: creditsPackages.map((item) => ({
              creditValue: convertToCents(item.creditsAmount),
              discount:
                item.discount != null && stringNotEmpty(String(item.discount)) ? Number(item.discount) : undefined,
              netPrice: convertToCents(item.netPrice),
            })),
            creditsPurchaseEnabled: isEnableCreditsPurchase,
          },
        },
      });

      await refetch();
      toast.positive(t("credits.successfullyUpdated"));
    } catch (e: unknown) {
      if (e instanceof ApolloError) {
        toast.negative(tError(e));
      }
    }
  };

  const initialFormValues: CreditsGeneralSettingsValues = {
    isEnableCreditsPurchase: creditProviderData?.creditProvider?.creditsPurchaseEnabled ?? false,
    creditsPackages:
      creditProviderData?.creditProvider?.purchasableItems.map((item) => ({
        creditsAmount: convertCents(item.creditValue),
        netPrice: convertCents(item.netPrice),
        discountedNetPrice: convertCents(item.discountedNetPrice),
        discount: item.discount ?? undefined,
      })) ?? [],
  };

  return (
    <div className="general-settings">
      <Text variant="heading" size="large">
        {t("credits.manageCreditsSettings")}
      </Text>
      {isCreditProviderLoading && previousData == null ? (
        <LoadingSkeleton />
      ) : (
        <>
          <hr className="separator" />
          <div>
            <Formik<CreditsGeneralSettingsValues>
              enableReinitialize
              initialValues={initialFormValues}
              validationSchema={validationSchema()}
              onSubmit={handleSubmit}
              isInitialValid={false}
            >
              {({ isSubmitting, values, isValid, resetForm, submitForm, dirty }) => {
                const isCreditsPurchaseEnabledEmpty =
                  values.isEnableCreditsPurchase && values.creditsPackages.length === 0;
                const areValuesSame = isEqual(initialFormValues, {
                  creditsPackages: values.creditsPackages.map((item) => ({
                    ...item,
                    discount: item.discount != null ? Number(item.discount) : item.discount,
                    discountedNetPrice: Number(item.discountedNetPrice),
                    netPrice: Number(item.netPrice),
                  })),
                  isEnableCreditsPurchase: values.isEnableCreditsPurchase,
                });
                const isSaveDisabled =
                  !dirty || !isValid || isPendingChange || areValuesSame || isCreditsPurchaseEnabledEmpty;

                return (
                  <>
                    <CreditsPackagesTable isAddingPackage={isAddingPackage} setIsAddingPackage={setIsAddingPackage} />
                    <ButtonTray>
                      <Button
                        variant="ghost"
                        size="md"
                        className="mr-5"
                        disabled={isSubmitting || isCreditProviderLoading}
                        onClick={() => {
                          if (dirty || isAddingPackage) {
                            setShowModal(true);
                          }
                        }}
                      >
                        {t("common.cancel")}
                      </Button>
                      <Button
                        variant="primary"
                        size="md"
                        onClick={() => {
                          void submitForm();
                        }}
                        disabled={isSaveDisabled}
                      >
                        {isSubmitting && <ProgressCircle size="xs" className="mr-2" />}
                        {t("common.saveChanges")}
                      </Button>
                    </ButtonTray>
                    <DiscardChangesModal
                      showModal={showModal}
                      onAgree={() => {
                        resetForm();
                        setShowModal(false);
                        setIsAddingPackage(false);
                      }}
                      onClose={() => {
                        setShowModal(false);
                      }}
                    />
                  </>
                );
              }}
            </Formik>
          </div>
        </>
      )}

      <style jsx>
        {`
          .general-settings {
            margin: 32px;
            max-width: 560px;
          }
          .separator {
            margin: 24px 0;
            border: 0;
            border-top: 1px solid ${colors.border};
          }
        `}
      </style>
    </div>
  );
};
