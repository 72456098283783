/* eslint-disable @typescript-eslint/no-magic-numbers */
import type { CSSProperties } from "react";
import React from "react";
import type { sizes } from "../../util/sizes";
import { useSize } from "../../util/useSize";

type Props = {
  className?: string;
  size: number | keyof typeof sizes;
  style?: CSSProperties;
} & ({ mode?: "indeterminate"; progress?: number } | { mode: "determinate"; progress: number });

const offset = 187;

export const ProgressCircle: React.FC<Props> = ({ className = "", style, ...props }) => {
  const size = useSize(props.size);

  return (
    <span className={className} style={style}>
      <svg
        className={`progress-circle progress-${props.mode ?? "indeterminate"}`}
        width={size}
        height={size}
        viewBox="0 0 66 66"
      >
        <circle
          cx="33"
          cy="33"
          r="30"
          fill="none"
          strokeWidth="6"
          strokeMiterlimit="10"
          strokeLinecap="square"
          stroke="currentColor"
        />
      </svg>

      <style jsx>{`
        circle {
          stroke-dasharray: ${offset}px;
          stroke-dashoffset: ${props.mode === "determinate" ? (1 - props.progress / 100) * offset : 0.3 * offset}px;
          transform-origin: center;
        }

        .progress-indeterminate {
          animation: rotate 1.5s linear infinite;
        }

        .progress-indeterminate circle {
          animation: dash 1.5s ease-in-out infinite;
        }

        .progress-determinate circle {
          transition: stroke-dashoffset 350ms;
        }

        @keyframes rotate {
          0% {
            transform: rotate(0deg);
          }
          100% {
            transform: rotate(360deg);
          }
        }

        @keyframes dash {
          0% {
            stroke-dashoffset: ${offset}px;
          }
          50% {
            stroke-dashoffset: ${offset / 4}px;
            transform: rotate(135deg);
          }
          100% {
            stroke-dashoffset: ${offset}px;
            transform: rotate(360deg);
          }
        }
      `}</style>
    </span>
  );
};
