import React, { useContext, useMemo } from "react";

import { useTranslation } from "@equiem/localisation-eq1";
import { Button, InfiniteScrollTable, ProgressCircle, Tag, Text, useTheme } from "@equiem/react-admin-ui";
import { RiAddLine, RiEarthLine } from "@equiem/react-admin-ui/icons";

import { QueueModal } from "../../components/QueueModal";
import type { QueuesQuery } from "../../generated/requests-client";

import { ModalContext } from "./contexts/ModalContext";
import { withContexts } from "./contexts/withContexts";
import { useQueueData } from "./hooks/useQueueData";
import { SettingsTabs } from "./SettingsTabs";

interface RowProps {
  onClick: () => void;
  canCreateQueues: boolean;
  queue: {
    isActive: boolean;
    name: string;
    ownerCompanyName: string;
    managersCount: number;
    assigneesCount: number;
  };
}
const Row: React.FC<RowProps> = ({ queue, onClick, canCreateQueues }) => {
  const { t } = useTranslation();

  return (
    <>
      <tr onClick={onClick} style={{ cursor: "pointer" }}>
        <td>{queue.name}</td>
        {canCreateQueues && <td>{queue.ownerCompanyName}</td>}
        <td>{queue.managersCount}</td>
        <td>{queue.assigneesCount}</td>
        <td>
          <Tag variant={queue.isActive ? "positive" : "negative"}>
            {queue.isActive ? t("requests.queues.active") : t("requests.queues.inactive")}
          </Tag>
        </td>
      </tr>
    </>
  );
};

const Empty: React.FC<{ onClick: () => void; canCreateQueues: boolean }> = ({ onClick, canCreateQueues }) => {
  const { t } = useTranslation();
  const { colors } = useTheme();

  return (
    <div className="empty-table">
      <div className="icon-container">
        <RiEarthLine size={24} color={colors.grayscale[40]} />
      </div>
      <Text
        variant="text"
        size="extra-small"
        style={{
          marginBottom: "0px",
          color: "rgba(0, 0, 0, 0.4)",
        }}
      >
        {t("requests.queues.noQueuesAreAvailableYet")}
      </Text>
      <Text
        variant="text"
        size="extra-small"
        style={{
          marginTop: "0px",
          color: "rgba(0, 0, 0, 0.4)",
        }}
      >
        {t("requests.queues.getStartedByAddingNewQueue")}
      </Text>
      {canCreateQueues && (
        <Button variant="secondary" size="md" onClick={onClick}>
          <RiAddLine size={16} /> {t("requests.queues.addQueue")}
        </Button>
      )}
      <style jsx>
        {`
          .empty-table {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            color: ${colors.greyBackground};
            padding: 0 32px 0;
            text-align: center;
          }

          .empty-table .icon-container {
            padding: 16px;
            background: ${colors.grayscale[5]};
            border-radius: 50%;
          }
        `}
      </style>
    </div>
  );
};

export const QueueComponent: React.FC = () => {
  const { t } = useTranslation();
  const { queues, loading, canCreateQueues } = useQueueData();
  const modal = useContext(ModalContext);
  const { colors } = useTheme();
  const mapToRow = (q: NonNullable<QueuesQuery["reqMgt"]["queues"][number]>): RowProps => {
    return {
      onClick: () => modal.open("QueueModal", q.uuid),
      canCreateQueues: canCreateQueues,
      queue: {
        isActive: q.active,
        assigneesCount: q.assignees.length,
        managersCount: q.managers.length,
        name: q.name,
        ownerCompanyName: q.ownerCompany.name,
      },
    };
  };

  const rowHeaders = useMemo(() => {
    const headers = [t("common.name")];
    if (canCreateQueues) {
      headers.push(t("requests.queues.owner"));
    }
    headers.push(t("requests.queues.requestManagers"), t("requests.queues.assignees"), t("requests.queues.status"));
    return headers;
  }, [canCreateQueues, t]);

  return (
    <>
      <QueueModal />
      {loading ? (
        <div className="loading-container">
          <ProgressCircle size="lg" />
        </div>
      ) : (
        <div className={queues.length === 0 ? "" : "container"}>
          <SettingsTabs>
            {queues.length > 0 && rowHeaders.length > 0 && (
              <>
                <div
                  className="d-flex "
                  style={{ justifyContent: "space-between", alignItems: "center", paddingBottom: "15px" }}
                >
                  <h1 className="font-weight-bold pb-3">{t("requests.queues.manageQueues")}</h1>
                  {canCreateQueues && (
                    <Button
                      className="main-button"
                      variant="primary"
                      size="md"
                      onClick={() => modal.open("QueueModal")}
                    >
                      <RiAddLine size={16} />
                      {t("requests.queues.addQueue")}
                    </Button>
                  )}
                </div>
                <InfiniteScrollTable
                  loading={loading}
                  hasMoreData={false}
                  rowHeaders={rowHeaders}
                  rowCount={queues.length}
                  fetchMore={console.log}
                >
                  {queues.map((q) => (
                    <Row key={q.uuid} {...mapToRow(q)} />
                  ))}
                </InfiniteScrollTable>
              </>
            )}
          </SettingsTabs>
          {queues.length === 0 && <Empty onClick={() => modal.open("QueueModal")} canCreateQueues={canCreateQueues} />}
        </div>
      )}
      <style jsx>{`
        .container {
          flex-grow: 1;
        }
        .loading-container {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          background-color: ${colors.white};
          padding: 0 32px 0;
          text-align: center;
          width: 100%;
          height: 100%;
        }
      `}</style>
    </>
  );
};

export const Queue = withContexts(QueueComponent);
