import React, { useCallback, useContext, useEffect, useState } from "react";
import { Form, Formik } from "formik";

import { useSaferFormikContext } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import { Button, Modal, useIsMobileWidth, useTheme } from "@equiem/react-admin-ui";

import { Modal as ModalContext } from "../../../contexts/ModalContext";
import type { VisitorInfo } from "../../../generated/visitors-client";
import type { FormValues, FormVisitor } from "../types";

import { AppointmentVisitorInformationForm } from "./AppointmentVisitorInformationForm";

type VisitorFormValues = { visitorInfo?: VisitorInfo[]; receptionNoteMessage?: string };

export const AddVisitorInformationModal = () => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const modal = useContext(ModalContext);
  const { colors, breakpoints } = useTheme();
  const isMobile = useIsMobileWidth();
  const { values, setFieldValue } = useSaferFormikContext<FormValues>();
  const visitor = (modal as { options?: { visitor?: FormVisitor } }).options?.visitor;
  const currentVisitorIndex = values.visitors.findIndex((v) => v === visitor);

  const onClose = useCallback(() => {
    setShowModal(false);
    modal.close();
  }, [setShowModal, modal]);

  useEffect(() => {
    if (modal.activeModal === "AddVisitorInformation") {
      setShowModal(true);
    }
  }, [modal.activeModal, setShowModal]);

  const handleSubmit = useCallback(
    (formValues: VisitorFormValues) => {
      void setFieldValue(
        "visitors",
        values.visitors.map((v, i) => (i === currentVisitorIndex ? { ...v, ...formValues } : v)),
      );
      setShowModal(false);
      modal.close();
    },
    [modal, values],
  );

  return (
    <>
      <Modal.Dialog
        title={
          isMobile
            ? t("visitors.appointmentForm.addVisitorInformation")
            : t("visitors.appointmentForm.visitorInformation")
        }
        show={showModal}
        onHide={onClose}
        hideOnEsc
        supportsMobile
        mobileView
        hideOnClick={false}
        focusTrapOptions={{ allowOutsideClick: () => true }}
        size="md"
        className="appointment-visitor-modal"
        centered
      >
        <Formik
          enableReinitialize
          initialValues={{
            visitorInfo: visitor?.visitorInfo,
            receptionNoteMessage: visitor?.receptionNoteMessage,
          }}
          onSubmit={handleSubmit}
        >
          {({ isValid }) => (
            <Form className="appointment-form">
              <Modal.Header closeButton={true} noBorder={false}>
                {!isMobile && (
                  <h2 className="mb-6 header-title">{t("visitors.appointmentForm.addVisitorInformation")}</h2>
                )}
              </Modal.Header>
              <Modal.Body>
                <AppointmentVisitorInformationForm />
              </Modal.Body>

              <Modal.Footer>
                {!isMobile && (
                  <Button variant="ghost" className="mr-4" onClick={onClose}>
                    {t("common.cancel")}
                  </Button>
                )}
                <Button
                  disabled={!isValid}
                  variant="primary"
                  size={isMobile ? "lg" : "md"}
                  className={`${isMobile && "w-100"} save-button`}
                  type="submit"
                >
                  {t("common.save")}
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal.Dialog>
      <style jsx>
        {`
          @media (max-width: ${breakpoints.sm}px) {
            :global(.appointment-visitor-modal) {
              height: 100% !important;
            }

            :global(.appointment-visitor-modal .title-row .title) {
              font-size: 24px;
              color: black;
              text-transform: none;
              font-weight: 700;
            }
          }

          .header-title {
            font-size: 24px;
            color: black;
            text-transform: none;
            font-weight: 700;
          }

          :global(.appointment-visitor-modal .header .title-row) {
            margin-bottom: ${!isMobile ? "0 !important" : ""};
          }

          :global(.appointment-form) {
            height: 100%;
            display: flex;
            flex-direction: column;
          }

          :global(.appointment-form .modal-body) {
            padding-bottom: 0;
          }

          :global(.appointment-form .form-group:last-child) {
            margin-bottom: 0;
          }

          :global(.appointment-form .footer) {
            padding: 24px !important;
            border-top: ${isMobile ? `1px solid ${colors.border}` : 0};
          }

          hr {
            border: 0;
            border-top: 1px solid ${colors.border};
          }
        `}
      </style>
    </>
  );
};
