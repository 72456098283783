import { useContext, useEffect, useMemo } from "react";

import { CurrentRole, getReceptionName, Role, sortByAlphabet, useSaferFormikContext } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";

import type { VisitorReception } from "../../../generated/visitors-client";
import {
  useVisitorAllBuildingsReceptionsAvailableQuery,
  useVisitorReceptionsAvailableQuery,
  VisitorRole,
} from "../../../generated/visitors-client";
import type { FormValues } from "../types";

interface ReceptionInput {
  as?: VisitorRole;
  companyUuid?: string;
  receptionUuid?: string;
  skip?: boolean;
  isAllBuildingsReceptionsView?: boolean;
}

const useSortedReceptions = (data?: VisitorReception[]) => {
  const { t } = useTranslation();

  return useMemo(
    () =>
      sortByAlphabet(
        data?.map((reception) => ({
          ...reception,
          name: getReceptionName(reception, t),
        })) ?? [],
        "name",
      ),
    [data, t],
  );
};

const useVisitorReceptionsAvailable = (args: ReceptionInput) => {
  const { data, loading } = useVisitorReceptionsAvailableQuery({
    variables: {
      as: args.as,
      companyUuid: args.companyUuid,
      receptionUuid: args.receptionUuid === "undefined" ? undefined : args.receptionUuid,
    },
    fetchPolicy: "cache-and-network",
    skip: args.skip,
  });

  const receptions = useSortedReceptions(data?.visitorReceptionsAvailable as VisitorReception[]);

  return { receptions, loading };
};

const useVisitorAllBuildingsReceptionsAvaialble = (companyUuid: string | undefined, skip: boolean) => {
  const { data, loading } = useVisitorAllBuildingsReceptionsAvailableQuery({
    variables: {
      filters: { companyUuid },
    },
    skip,
  });

  const receptions = useSortedReceptions(data?.visitorAllBuildingsReceptionsAvailable as VisitorReception[]);

  return { receptions, loading };
};

export const useAppointmentFormMetadata = (args: ReceptionInput = {}) => {
  const { currentRole } = useContext(CurrentRole);
  const { setFieldValue, values } = useSaferFormikContext<FormValues>();
  const asRole = useMemo(
    () =>
      args.as != null
        ? args.as
        : currentRole === Role.PropertyManager
        ? VisitorRole.PropertyManager
        : currentRole === Role.WorkplaceManager
        ? VisitorRole.WorkplaceManager
        : null,
    [args.as, currentRole],
  );

  const allResult = useVisitorAllBuildingsReceptionsAvaialble(
    values.host.company?.uuid,
    args.isAllBuildingsReceptionsView !== true,
  );
  const notAllResult = useVisitorReceptionsAvailable({
    ...args,
    skip: args.skip === true || args.isAllBuildingsReceptionsView === true,
    as: asRole as VisitorRole,
  });
  const { receptions, loading } = args.isAllBuildingsReceptionsView === true ? allResult : notAllResult;

  const reception = useMemo(() => {
    const newReception = receptions.find((r) => r.uuid === values.location);

    return newReception;
  }, [values.location, receptions]);

  useEffect(() => {
    if (!loading && reception == null && values.location != null) {
      setFieldValue("location", "").catch(console.error);
    }
  }, [reception, values, setFieldValue, loading]);

  useEffect(() => {
    if (!loading && receptions.length === 1) {
      setFieldValue("location", receptions[0].uuid).catch(console.error);
    }
  }, [loading, receptions, setFieldValue]);
  return { receptions, reception, receptionsLoading: loading };
};

export type AppointmentFormMetadata = ReturnType<typeof useAppointmentFormMetadata>;
