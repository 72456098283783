import React, { useContext } from "react";
import { Menu } from "../shared/Menu";
import { ArticleForm } from "./ArticleForm";
import { SideModalProvider } from "../../contexts/SideModalProvider";
import { ArticleProvider } from "../../contexts/ArticleProvider";
import { CurrentPortfolio } from "../../contexts/PortfolioContext";

export const NewArticle = () => {
  const { currentPortfolio } = useContext(CurrentPortfolio);
  return (
    <>
      <ArticleProvider>
        <Menu />
        <SideModalProvider>
          {currentPortfolio != null && <ArticleForm portfolioUuid={currentPortfolio.uuid} />}
        </SideModalProvider>
      </ArticleProvider>
    </>
  );
};
