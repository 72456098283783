import type { TFunction } from "@equiem/localisation-eq1";
import { formatPaymentMethod as formatPaymentMethodUnlocalised } from "./formatters";
import { BookableResourcePaymentMethod } from "../../../generated/gateway-client";

export const formatPaymentMethodLocalised = (
  paymentMethod: BookableResourcePaymentMethod | null | undefined,
  t: TFunction,
) => {
  if (paymentMethod == null) {
    return "";
  }

  // This should produce a type-error if we add a new payment method, but also
  // deployed code should continue to function correctly (and fall back to
  // outputting the new payment method unlocalised).
  const keys = {
    [BookableResourcePaymentMethod.CreditCard]: t("common.creditCard"),
    [BookableResourcePaymentMethod.Invoice]: t("common.invoice"),
    [BookableResourcePaymentMethod.Credits]: t("common.credits"),
  } as const;
  return keys[paymentMethod] ?? formatPaymentMethodUnlocalised(paymentMethod);
};
