import React from "react";

export interface Props {
  size?: number;
  className?: string;
}

const defaultWidth = 387;
const defaultHeight = 108;
const aspectRatio = defaultHeight / defaultWidth;

export const NoSearchResults: React.FC<Props> = ({ size: width = defaultWidth, className }) => {
  const height = width * aspectRatio;

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 387 108"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_601_56991"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="10"
        width="387"
        height="79"
      >
        <rect y="10.4937" width="386.174" height="77.8536" fill="url(#paint0_linear_601_56991)" />
      </mask>
      <g mask="url(#mask0_601_56991)">
        <rect y="26.9922" width="117.556" height="41.7625" rx="2.35112" fill="#E6E6E6" />
        <rect x="124.259" y="26.9922" width="117.556" height="41.7625" rx="2.35112" fill="#E6E6E6" />
        <rect x="248.517" y="26.9922" width="117.556" height="41.7625" rx="2.35112" fill="#E6E6E6" />
      </g>
      <g opacity="0.2" filter="url(#filter0_f_601_56991)">
        <ellipse cx="180.02" cy="47.7305" rx="44.75" ry="44.4234" fill="#CCCCCC" />
        <path
          d="M220.523 47.7305C220.523 69.8904 202.419 87.9075 180.02 87.9075C157.621 87.9075 139.516 69.8904 139.516 47.7305C139.516 25.5706 157.621 7.55348 180.02 7.55348C202.419 7.55348 220.523 25.5706 220.523 47.7305Z"
          stroke="white"
          strokeWidth="8.4927"
        />
      </g>
      <g filter="url(#filter1_i_601_56991)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M212.741 47.7154C212.741 66.3359 197.646 81.4309 179.026 81.4309C160.405 81.4309 145.311 66.3359 145.311 47.7154C145.311 29.0949 160.405 14 179.026 14C197.646 14 212.741 29.0949 212.741 47.7154ZM206.491 84.264C198.845 90.0194 189.334 93.4309 179.026 93.4309C153.778 93.4309 133.311 72.9634 133.311 47.7154C133.311 22.4675 153.778 2 179.026 2C204.274 2 224.741 22.4675 224.741 47.7154C224.741 57.9244 221.395 67.3518 215.739 74.9605C215.776 74.9958 215.813 75.0316 215.849 75.068L236.285 95.5037C238.847 98.0656 238.847 102.219 236.285 104.781C233.723 107.343 229.569 107.343 227.007 104.781L206.572 84.3457C206.545 84.3187 206.518 84.2914 206.491 84.264Z"
          fill="#808080"
        />
      </g>
      <defs>
        <filter
          id="filter0_f_601_56991"
          x="132.656"
          y="0.693989"
          width="94.7263"
          height="94.073"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="1.30657" result="effect1_foregroundBlur_601_56991" />
        </filter>
        <filter
          id="filter1_i_601_56991"
          x="133.311"
          y="2"
          width="104.896"
          height="105.734"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1.03117" />
          <feGaussianBlur stdDeviation="0.515587" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_601_56991" />
        </filter>
        <linearGradient
          id="paint0_linear_601_56991"
          x1="343.638"
          y1="73.6531"
          x2="23.9748"
          y2="73.6531"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C4C4C4" stopOpacity="0" />
          <stop offset="0.295603" stopColor="#C4C4C4" />
          <stop offset="0.675448" stopColor="#C4C4C4" />
          <stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
};

NoSearchResults.displayName = "NoSearchResults";
