import { DateTime, Duration } from "luxon";

import { BookableResourcePaymentMethod } from "../../../generated/gateway-client";

const CREDITS_CURRENCY = "CREDITS";

/** NOTE: Unlocalised formatter! Only for use in CSV exports. */
export const formatDuration = (hours: number) => Duration.fromObject({ hours }).toFormat("hh:mm");

/** NOTE: Unlocalised formatter! Only for use in CSV exports. */
export const formatDateTime = (timestamp: number, timezone: string) =>
  DateTime.fromMillis(timestamp, { zone: timezone }).toISO() ?? "";

/** NOTE: Unlocalised formatter! Only for use in CSV exports. */
export const formatIsoDate = (timestamp: number, timezone: string) =>
  DateTime.fromMillis(timestamp, { zone: timezone }).toFormat("yyyy-MM-dd");

/** NOTE: Unlocalised formatter! Only for use in CSV exports. */
export const formatUSDate = (timestamp: number, timezone: string) =>
  DateTime.fromMillis(timestamp, { zone: timezone }).toFormat("MM/dd/yyyy");

/** NOTE: Unlocalised formatter! Only for use in CSV exports. */
export const formatUSShortDate = (timestamp: number, timezone: string) =>
  DateTime.fromMillis(timestamp, { zone: timezone }).toFormat("MM/dd/yy");

/** NOTE: Unlocalised formatter! Only for use in CSV exports. */
export const formatPaymentMethod = (paymentMethod: BookableResourcePaymentMethod | null | undefined): string => {
  if (paymentMethod === BookableResourcePaymentMethod.CreditCard) {
    return "Credit Card";
  }
  return paymentMethod ?? "";
};

/** NOTE: Unlocalised formatter! Only for use in CSV exports. */
export const formatCurrency = (amount: number, currency: string | null | undefined) => {
  if (currency === null) {
    return "";
  }

  const formatter = new Intl.NumberFormat("en", {
    style: "currency",
    currencyDisplay: "narrowSymbol",

    // If the user sets the currency to `CREDITS`, arbitrarily fall back to
    // `USD`. This shouldn't affect the output in most circumstances due to our
    // `currencyDisplay` setting.
    currency: currency === CREDITS_CURRENCY ? "USD" : currency,
  });

  if (currency !== CREDITS_CURRENCY) {
    return formatter.format(amount / 100);
  }

  // For credits, use the normal localised currency formatting, but strip the
  // currency symbol and any adjacent whitespace.
  return formatter
    .formatToParts(amount / 100)
    .filter((part) => part.type !== "currency")
    .map((part) => part.value)
    .join("")
    .trim();
};
