import { useContext, useEffect } from "react";
import { useRouter } from "next/router";

import { useSiteContext } from "../context";
import { GradientContext } from "../GradientContext";

export const usePageGradient = () => {
  const { pathname } = useRouter();
  const { tierLevel } = useSiteContext();
  const { setPageGradient } = useContext(GradientContext);

  const isEssentialsSite = tierLevel === "Essentials";
  const isHomePage = pathname === "/" || pathname === "";
  const useEssentialsGradient = isEssentialsSite || isHomePage;

  useEffect(() => {
    setPageGradient(true, useEssentialsGradient ? "essentials" : "eq1");

    return () => setPageGradient(false);
  }, [useEssentialsGradient, setPageGradient]);
};
