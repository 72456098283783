import React, { useEffect, useState } from "react";
import type { ApolloError } from "@apollo/client";

import { notNullOrUndefined, useSiteContext } from "@equiem/lib";
import { useApolloErrorTranslation, useTranslation } from "@equiem/localisation-eq1";
import { EmptyState, Table, useTheme } from "@equiem/react-admin-ui";
import { RiFileDownloadLine } from "@equiem/react-admin-ui/icons";

import type { CompanyListQueryVariables } from "../../../generated/settings-client";
import { CompanyV2SortField } from "../../../generated/settings-client";
import { useExportCompanyList } from "../hooks/useExportCompanyList";
import type { CompanyListItem } from "../hooks/usePagedCompanyList";

import { CompanyListRow } from "./CompanyListRow";

interface Props {
  className?: string;
  pageSize: number;
  setPageSize: (pageSize: number) => void;
  companies: CompanyListItem[];
  error?: ApolloError;
  loading: boolean;
  totalCount: number;
  refetch: (variables?: Partial<CompanyListQueryVariables>) => Promise<unknown>;
  fetchMore: () => void;
  debouncedSearch?: string;
  currentPage: number;
  setCurrentPage: (_page: number) => void;
  withFlexTenants: boolean;
}

export const CompaniesList: React.FC<Props> = ({
  className,
  pageSize,
  setPageSize,
  companies,
  error,
  loading,
  totalCount,
  refetch,
  fetchMore,
  debouncedSearch,
  currentPage,
  setCurrentPage,
  withFlexTenants,
}) => {
  const { t } = useTranslation();
  const { breakpoints, colors } = useTheme();
  const { tError } = useApolloErrorTranslation();
  const site = useSiteContext();
  const [sortColumnIndex, setSortColumnIndex] = useState<number>(0);
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("asc");
  const csvExport = useExportCompanyList({ destinationUuid: site.uuid });

  useEffect(() => {
    const fieldsIndex = [CompanyV2SortField.CompanyName, CompanyV2SortField.Industry];

    void refetch({
      sort: {
        field: fieldsIndex[sortColumnIndex],
        asc: sortDirection === "asc",
      },
    });
  }, [sortColumnIndex, sortDirection, refetch]);

  useEffect(() => {
    setSortColumnIndex(0);
    setSortDirection("asc");
    setCurrentPage(1);
  }, [debouncedSearch, setCurrentPage]);

  const handleCsvExport = () => {
    void csvExport();
  };

  const defaultHeaders = [
    t("common.company"),
    t("common.industry"),
    t("common.location"),
    withFlexTenants ? t("settings.flexMembership.tab") : null,
    t("common.users"),
    <RiFileDownloadLine size={16} color={colors.grayscale[60]} key="csv" onClick={handleCsvExport} />,
  ].filter(notNullOrUndefined);

  if (error != null) {
    return <>{tError(error)}</>;
  }

  const isEmpty = companies.length === 0 && !loading;

  const hideHeader = isEmpty || (loading && companies.length === 0);

  const handleSort = (index: number) => {
    if (index === 2 || index === 3 || index === 4) {
      return;
    }

    if (sortColumnIndex === index) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortColumnIndex(index);
      setSortDirection("asc");
    }

    setCurrentPage(1);
  };

  const onPageChange = (page: number, size: number) => {
    if (page > currentPage) {
      fetchMore();
    }

    setCurrentPage(page);
    setPageSize(size);
  };

  return (
    <div className={`${className} company-list ${hideHeader && "company-list--empty"}`}>
      <Table.StickyHeaderTable
        loading={loading}
        rowHeaders={defaultHeaders}
        sort={{
          columnIndex: sortColumnIndex,
          direction: sortDirection,
          handleSort,
        }}
        pagination={{
          totalCount,
          currentPage,
          pageSize,
          onPageChange,
        }}
      >
        {isEmpty && (
          <tr>
            <td colSpan={defaultHeaders.length}>
              <EmptyState />
            </td>
          </tr>
        )}
        {!loading &&
          companies
            .slice((currentPage - 1) * pageSize, currentPage * pageSize)
            .map((company) => (
              <CompanyListRow
                key={company.uuid}
                company={company}
                withFlexTenants={withFlexTenants}
                refetch={refetch}
              />
            ))}
      </Table.StickyHeaderTable>
      <style jsx>{`
        .company-list :global(thead) {
          cursor: pointer;
        }

        /* name and industry */
        .company-list :global(thead) :global(th:nth-child(1)) {
          width: 28%;
        }
        .company-list :global(thead) :global(th:nth-child(2)) {
          width: 28%;
        }

        /* user count and more */
        .company-list :global(thead) :global(th:nth-child(4)) {
          width: 10%;
        }

        .company-list :global(thead) :global(th:nth-child(5)) {
          width: 5%;
        }

        .company-list :global(thead) :global(th:nth-child(5)),
        .company-list :global(tbody) :global(td:nth-child(5)) {
          text-align: right;
        }

        @media (max-width: ${breakpoints.md}px) {
          .company-list {
            overflow: auto;
            padding: 0 32px;
          }

          .company-list :global(th) {
            position: relative !important;
            top: 0 !important;
          }
        }

        @media (max-width: ${breakpoints.sm}px) {
          .company-list {
            padding: 0 16px;
          }
        }
      `}</style>
    </div>
  );
};
