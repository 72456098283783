import React, { useContext, useState } from "react";

import { useTranslation } from "@equiem/localisation-eq1";
import { AdvancedButton, Button, Modal, useIsMobileWidth, useTheme } from "@equiem/react-admin-ui";
import { RiAddCircleLine, RiAddLine, RiBookletLine } from "@equiem/react-admin-ui/icons";

import { Modal as ModalContext } from "../../../contexts/ModalContext";

export const AddApartment = () => {
  const { t } = useTranslation();
  const isMobile = useIsMobileWidth();
  const { breakpoints } = useTheme();
  const [showModal, setShowModal] = useState(false);
  const modal = useContext(ModalContext);

  return (
    <>
      <Button onClick={() => setShowModal(true)} shape={isMobile ? "round" : "default"}>
        <RiAddLine size={16} />
        {!isMobile && t("settings.addApartment")}
      </Button>
      <Modal.Dialog
        title={t("settings.addApartment")}
        show={showModal}
        onHide={() => setShowModal(false)}
        hideOnEsc={true}
        hideOnClick={true}
        size="lg"
        centered
        supportsMobile
        className="add-apartment-modal"
      >
        <Modal.Header closeButton noBorder supportsMobile={isMobile} />
        <Modal.Body>
          <div className="apartment-actions">
            <AdvancedButton
              className="w-50 mr-2"
              title={t("settings.createCompany.createNew")}
              description={t("settings.editApartment.createHint")}
              icon={RiAddCircleLine}
              onClick={() => {
                setShowModal(false);
                modal.open("ApartmentDetails");
              }}
            />
            <AdvancedButton
              className="w-50 ml-2"
              title={t("settings.addFromCatalogue")}
              icon={RiBookletLine}
              description={t("settings.editApartment.catalogueHint")}
              onClick={() => {
                setShowModal(false);
                modal.open("AddCatalogue");
              }}
            />
          </div>
          <style jsx>{`
            .apartment-actions {
              display: flex;
              flex-direction: row;
            }

            @media (max-width: ${breakpoints.sm}px) {
              .apartment-actions {
                flex-direction: column;
                gap: 8px;
                width: 100%;
              }

              :global(.add-apartment-modal .modal-body) {
                padding: 1rem;
                padding-top: 0.75rem;
              }

              .apartment-actions :global(button) {
                width: 100% !important;
                margin: 0 !important;
              }
            }
          `}</style>
        </Modal.Body>
      </Modal.Dialog>
    </>
  );
};
