import { useTranslation } from "@equiem/localisation-eq1";
import { useGetRolesQuery } from "../generated/gateway-client";
import { toRoleName } from "../RoleName";

export const useAuthorizationRoles = () => {
  const { data } = useGetRolesQuery();

  return data?.getRolesV2 == null
    ? null
    : Object.fromEntries(data.getRolesV2.map((role) => [`${role.type}#${role.relation}`, toRoleName(role.name)]));
};

export const useRoleName = () => {
  const { t } = useTranslation();
  const roles = useAuthorizationRoles();

  const getRoleName = (role: { type: string; relation: string }) => {
    const roleName = roles?.[`${role.type}#${role.relation}`];

    return t(`settings.roles.roleNames.${roleName ?? "Unknown"}`, {
      count: 1,
    });
  };

  return {
    getRoleName,
  };
};
