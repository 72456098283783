import { formatters, useTranslation } from "@equiem/localisation-eq1";
import { Button, Form, Modal, Text, remainingCharacters, useTheme } from "@equiem/react-admin-ui";
import type { FormikErrors } from "formik";
import { Form as FormikForm, Formik, Field } from "formik";
import React from "react";
import { ResourceDivider } from "../ResourceDivider";
import type {
  BookableResourceAvailability,
  BookableResourceFlexibleAvailability,
  BookableResourceSlotsAvailability,
} from "../../../../../generated/gateway-client";
import { Weekday } from "../../../../../generated/gateway-client";
import { convertInputNumberToNumber } from "../../../../../lib/convertNumberStringToNumber";
import { parseTime, toSeconds } from "../../../../../lib/dateTimeHelpers";
import { AvailabilityType } from "../../../../../lib/formValidation";
import { formatWeekday } from "../../../../../lib/formatWeekday";
import { availabilityModalValidationSchema } from "../../../../../lib/validationSchema";

type AvailabilityModalFormValues = {
  availability: BookableResourceAvailability;
};

const week = [Weekday.Mon, Weekday.Tue, Weekday.Wed, Weekday.Thu, Weekday.Fri, Weekday.Sat, Weekday.Sun];

const TITLE_MAX_LENGTH = 100;

const SLOT_DURATION_MIN = 15;
const SLOT_DURATION_MAX = 1200;
const DURATION_LIMIT_MINUTES = 24 * 60;
const DURATION_INCREMENT_MINUTES = 15;
const DURATION_RAW_OPTIONS = Array(DURATION_LIMIT_MINUTES / DURATION_INCREMENT_MINUTES)
  .fill(null)
  .map((_, i) => (i + 1) * DURATION_INCREMENT_MINUTES);

const roundToFifteenMinutes = (value: string | number | null | undefined) => {
  const num = convertInputNumberToNumber(value, true);
  if (num == null) {
    return "";
  }

  const constrained = Math.min(num - (num % DURATION_INCREMENT_MINUTES), DURATION_LIMIT_MINUTES);
  return `${constrained}`;
};

const getDurationValue = (duration: number): number => {
  if (duration < SLOT_DURATION_MIN) {
    return SLOT_DURATION_MIN;
  } else if (duration > SLOT_DURATION_MAX) {
    return SLOT_DURATION_MAX;
  }
  return duration;
};

interface Props {
  showModal: boolean;
  setShowModal: (_state: boolean) => void;
  modalIndex: number | null;
  setModalIndex: (_index: number | null) => void;
  availability?: BookableResourceAvailability;
  availabilityType: AvailabilityType;
  onAdd: (_availability: BookableResourceAvailability) => void;
  onEdit: (_availability: BookableResourceAvailability, _index: number) => void;
}

export const ResourceCreateAndEditFormAvailabilityModal: React.FC<Props> = ({
  showModal,
  setShowModal,
  modalIndex,
  setModalIndex,
  availability,
  availabilityType,
  onAdd,
  onEdit,
}) => {
  const { i18n, t } = useTranslation();
  const { breakpoints, colors, spacers } = useTheme(true);

  const durationOptions = DURATION_RAW_OPTIONS.map((duration) => ({
    label: formatters.durationshort(duration, i18n.language),
    value: duration,
  }));

  return (
    <Modal.Dialog
      title={modalIndex != null ? t("bookings.resources.editSlot") : t("bookings.resources.addSlot")}
      show={showModal}
      size="xl"
      onHide={() => {
        setShowModal(false);
        setModalIndex(null);
      }}
      hideOnClick={false}
      hideOnEsc={false}
      supportsMobile={true}
      focusTrapOptions={{ initialFocus: false }}
      centered
    >
      <div className="availability-modal">
        <Modal.Header supportsMobile={true} closeButton={true} noBorder={false} />
        <Formik<AvailabilityModalFormValues>
          initialValues={{
            availability:
              availability ??
              (availabilityType === AvailabilityType.slots
                ? {
                    days: [],
                    title: "",
                    start: "09:00",
                    end: "18:00",
                    isFullSession: true,
                    durationInMinutes: SLOT_DURATION_MIN,
                  }
                : {
                    days: [],
                    title: "",
                    start: "09:00",
                    end: "18:00",
                  }),
          }}
          validationSchema={availabilityModalValidationSchema(t)}
          validateOnMount={true}
          onSubmit={(values, actions) => {
            actions.setSubmitting(true);
            modalIndex != null ? onEdit(values.availability, modalIndex) : onAdd(values.availability);
            actions.setSubmitting(false);
          }}
        >
          {({ isSubmitting, isValid, submitForm, setFieldValue, values, errors }) => (
            <FormikForm>
              <Modal.Body>
                <>
                  <Text variant="text" size="large" weight="bold">
                    {t("bookings.resources.timeSlotDaysLabel")}
                  </Text>

                  <Form.Group label={t("bookings.operations.availabilityDays")} required>
                    <div className="time-slot-days-container">
                      {week.map((weekDay) => (
                        <Field
                          id={weekDay}
                          key={weekDay}
                          type="button"
                          name="days"
                          disabled={isSubmitting}
                          label={formatWeekday(weekDay, i18n.language)}
                          as={Form.ToggleButton}
                          value={values.availability.days.includes(weekDay) ?? false}
                          onChange={(state: boolean) => {
                            const updatedWeekDays = state
                              ? [...(values.availability.days ?? []), weekDay]
                              : (values.availability.days ?? []).filter((day) => day !== weekDay);
                            const filteredWeekDays = week.filter((wd) => updatedWeekDays.includes(wd));
                            setFieldValue("availability.days", filteredWeekDays).catch(console.error);
                          }}
                        />
                      ))}
                    </div>
                  </Form.Group>

                  <Form.Group
                    className="availability-title"
                    label={t("bookings.resources.resourceTitle")}
                    hint={t("common.remainingCharacters", {
                      count: remainingCharacters(TITLE_MAX_LENGTH, values.availability.title?.length ?? 0),
                    })}
                  >
                    <Field
                      id="availability.title"
                      name="availability.title"
                      placeholder={t("bookings.resources.resourceTitleTimeHint")}
                      maxLength={TITLE_MAX_LENGTH}
                      as={Form.Input}
                      disabled={isSubmitting || values.availability.days.length === 0}
                    />
                  </Form.Group>

                  <Text variant="text" size="large" weight="bold" className="availability-modal-label">
                    {t("bookings.resources.timeSlotHoursLabel")}
                  </Text>

                  <div className="form-group-container">
                    <Form.Group label={t("bookings.resources.from")} required error={errors.availability?.start}>
                      <Field
                        id="start"
                        name="start"
                        defaultOptionValue={t("bookings.settings.startTime")}
                        value={toSeconds(values.availability.start)}
                        as={Form.TimeSelect}
                        disabled={isSubmitting || values.availability.days.length === 0}
                        startTime={{ hour: 0, minute: 0 }}
                        endTime={{ hour: 23, minute: 45 }}
                        diffMinutes={15}
                        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                          setFieldValue("availability.start", parseTime(e)).catch(console.error);
                        }}
                      />
                    </Form.Group>
                    <Form.Group label={t("bookings.resources.to")} required>
                      <Field
                        id="end"
                        name="end"
                        defaultOptionValue={t("bookings.settings.endTime")}
                        value={toSeconds(values.availability.end)}
                        as={Form.TimeSelect}
                        disabled={isSubmitting || values.availability.days.length === 0}
                        startTime={{ hour: 0, minute: 15 }}
                        endTime={{ hour: 23, minute: 59 }}
                        diffMinutes={15}
                        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                          setFieldValue("availability.end", parseTime(e)).catch(console.error);
                        }}
                      />
                    </Form.Group>
                  </div>

                  <ResourceDivider />

                  {availabilityType === AvailabilityType.slots && (
                    <>
                      <Form.Group
                        className="duration-type-group"
                        label={t("bookings.resources.durationType")}
                        required
                        hint={
                          (values.availability as BookableResourceSlotsAvailability).isFullSession
                            ? `${t("bookings.resources.fullSession")} = ${t("bookings.resources.fullSessionHint")}`
                            : ""
                        }
                      >
                        <div className="duration-type-container">
                          <div className="duration-type-radio">
                            <Field
                              label={t("bookings.resources.fullSession")}
                              component={Form.EnclosedRadioButton}
                              id="full-duration"
                              name="isFullSession"
                              value={(values.availability as BookableResourceSlotsAvailability).isFullSession}
                              disabled={isSubmitting || values.availability.days.length === 0}
                              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                if (e.currentTarget.checked) {
                                  setFieldValue("availability.isFullSession", true).catch(console.error);
                                }
                              }}
                            />
                          </div>
                          <div className="duration-type-radio">
                            <Field
                              label={t("bookings.resources.customSession")}
                              component={Form.EnclosedRadioButton}
                              id="custom-duration"
                              name="isFullSession"
                              value={!(values.availability as BookableResourceSlotsAvailability).isFullSession}
                              disabled={isSubmitting || values.availability.days.length === 0}
                              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                if (e.currentTarget.checked) {
                                  setFieldValue("availability.isFullSession", false).catch(console.error);
                                }
                              }}
                            />
                          </div>
                        </div>
                      </Form.Group>

                      {!(values.availability as BookableResourceSlotsAvailability).isFullSession && (
                        <Form.Group
                          className="slot-duration"
                          label={t("bookings.resources.durationInMinutes")}
                          showTooltip={true}
                          tooltipText={t("bookings.resources.durationInMinutesTooltip")}
                          error={
                            errors.availability != null
                              ? (errors.availability as FormikErrors<BookableResourceSlotsAvailability>)
                                  .durationInMinutes
                              : undefined
                          }
                          required
                        >
                          <Field
                            id="availability.durationInMinutes"
                            name="availability.durationInMinutes"
                            value={(values.availability as BookableResourceSlotsAvailability).durationInMinutes}
                            type="number"
                            min={0}
                            placeholder={t("bookings.resources.durationInMinutesHint")}
                            as={Form.Input}
                            disabled={isSubmitting || values.availability.days.length === 0}
                            onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                              if (
                                (e.key === "ArrowDown" &&
                                  Number((e.target as HTMLInputElement).value) === SLOT_DURATION_MIN) ||
                                (e.key === "ArrowUp" &&
                                  Number((e.target as HTMLInputElement).value) === SLOT_DURATION_MAX)
                              ) {
                                e.preventDefault();
                              }
                            }}
                            onKeyUp={(e: React.KeyboardEvent<HTMLInputElement>) => {
                              if (e.key === "ArrowDown" || e.key === "ArrowUp") {
                                setFieldValue(
                                  "availability.durationInMinutes",
                                  getDurationValue(Number((e.target as HTMLInputElement).value)),
                                ).catch(console.error);
                              }
                            }}
                            onMouseUp={(e: React.MouseEvent<HTMLInputElement>) => {
                              setFieldValue(
                                "availability.durationInMinutes",
                                getDurationValue(Number((e.target as HTMLInputElement).value)),
                              ).catch(console.error);
                            }}
                            onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                              setFieldValue(
                                "availability.durationInMinutes",
                                getDurationValue(Number((e.target as HTMLInputElement).value)),
                              ).catch(console.error);
                            }}
                          />
                        </Form.Group>
                      )}
                    </>
                  )}

                  {availabilityType === AvailabilityType.flexible && (
                    <div className="form-group-container">
                      <Form.Group
                        label={t("bookings.resources.minBookingTimeInMinutes")}
                        error={
                          errors.availability != null
                            ? (errors.availability as FormikErrors<BookableResourceFlexibleAvailability>)
                                .minTimeInMinutes
                            : undefined
                        }
                      >
                        <Field
                          id="minTimeInMinutes"
                          name="minTimeInMinutes"
                          disabled={isSubmitting || values.availability.days.length === 0}
                          as={Form.Select}
                          value={roundToFifteenMinutes(
                            (values.availability as BookableResourceFlexibleAvailability).minTimeInMinutes,
                          )}
                          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                            setFieldValue("availability.minTimeInMinutes", e.target.value).catch(console.error);
                          }}
                        >
                          <option value="">{t("visitors.appointmentForm.durationSelect")}</option>
                          {durationOptions.map(({ value, label }) => (
                            <option key={label} value={value}>
                              {label}
                            </option>
                          ))}
                        </Field>
                      </Form.Group>
                      <Form.Group label={t("bookings.resources.maxBookingTimeInMinutes")}>
                        <Field
                          id="maxTimeInMinutes"
                          name="maxTimeInMinutes"
                          disabled={isSubmitting || values.availability.days.length === 0}
                          as={Form.Select}
                          value={roundToFifteenMinutes(
                            (values.availability as BookableResourceFlexibleAvailability).maxTimeInMinutes,
                          )}
                          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                            setFieldValue("availability.maxTimeInMinutes", e.target.value).catch(console.error);
                          }}
                        >
                          <option value="">{t("visitors.appointmentForm.durationSelect")}</option>
                          {durationOptions.map(({ value, label }) => (
                            <option key={label} value={value}>
                              {label}
                            </option>
                          ))}
                        </Field>
                      </Form.Group>
                    </div>
                  )}
                </>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="ghost"
                  type="button"
                  onClick={() => {
                    setShowModal(false);
                    setModalIndex(null);
                  }}
                >
                  {t("common.cancel")}
                </Button>
                <Button
                  className="add-button"
                  type="submit"
                  variant="primary"
                  onSubmit={() => {
                    void submitForm();
                  }}
                  disabled={isSubmitting || !isValid}
                >
                  {modalIndex != null ? t("common.save") : t("common.add")}
                </Button>
              </Modal.Footer>
            </FormikForm>
          )}
        </Formik>
      </div>
      <style jsx>
        {`
          .availability-modal :global(.header) {
            padding: ${spacers.s6} !important;
          }
          .availability-modal :global(.header .title-row) {
            margin: ${spacers.s0} !important;
          }
          .availability-modal :global(.header .title) {
            color: ${colors.dark};
            font-size: 24px;
            font-weight: 700;
            line-height: 28px;
            text-transform: none;
            text-align: left;
            z-index: 1;
          }
          .availability-modal :global(.modal-body) {
            display: flex;
            flex-direction: column;
            padding: ${spacers.s7} ${spacers.s6};
            margin: ${spacers.s0};
            gap: ${spacers.s7};
            overflow-y: auto;
            max-height: 400px;
          }
          .availability-modal :global(.modal-body hr),
          .availability-modal :global(.modal-body p),
          .availability-modal :global(.modal-body .form-group) {
            margin: ${spacers.s0};
          }
          .availability-modal :global(.modal-body .time-slot-days-container) {
            display: flex;
            flex-wrap: wrap;
            gap: ${spacers.s3};
          }
          .availability-modal :global(.modal-body .time-slot-days-container button) {
            width: 126px;
          }
          .availability-modal :global(.modal-body .availability-title p),
          .availability-modal :global(.modal-body .duration-type-group p) {
            margin: ${spacers.s3} ${spacers.s0};
            color: ${colors.grayscale[60]} !important;
          }
          .availability-modal :global(.modal-body .form-group-container) {
            display: flex;
            gap: ${spacers.s7};
          }
          .availability-modal :global(.modal-body .form-group .form-header label) {
            line-height: 14px;
          }
          .availability-modal :global(.modal-body select:disabled) {
            color: ${colors.grayscale[20]};
            background-color: ${colors.grayscale[3]};
            cursor: not-allowed;
          }
          .availability-modal :global(.modal-body .duration-type-container) {
            display: flex;
            gap: ${spacers.s3};
          }
          .availability-modal :global(.modal-body .duration-type-radio) {
            flex: 1;
          }
          .availability-modal :global(.modal-body .duration-type-radio label) {
            width: 100%;
            height: 100%;
            justify-content: center;
          }
          .availability-modal :global(.modal-body .duration-type-radio label div) {
            flex: 0 0 auto;
          }
          .availability-modal :global(.modal-body .slot-duration) {
            width: 50%;
          }
          .availability-modal :global(.footer) {
            padding: ${spacers.s5} ${spacers.s7} !important;
            gap: ${spacers.s3};
            border-top: 1px solid ${colors.grayscale[10]};
          }
          .availability-modal :global(.footer .add-button) {
            width: 200px;
          }
          @media screen and (max-width: ${breakpoints.md}px) {
            .availability-modal :global(.header .title) {
              font-size: 20px;
            }
            .availability-modal :global(.header button) {
              z-index: 2;
            }
            .availability-modal :global(.modal-body) {
              padding: ${spacers.s5};
              gap: ${spacers.s5};
            }
            .availability-modal :global(.modal-body .form-group-container) {
              flex-direction: column;
              gap: ${spacers.s5};
            }
            .availability-modal :global(.modal-body .slot-duration) {
              width: 100%;
            }
            .availability-modal :global(.footer button) {
              flex: 1;
            }
          }
        `}
      </style>
    </Modal.Dialog>
  );
};
