import React, { useContext, useMemo } from "react";

import { companyNameTranslator, useSiteContext } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import { Dropdown, Tag, useConfirmer, useTheme, useToast } from "@equiem/react-admin-ui";
import { RiDeleteBin5Line, RiEditLine, RiMoreFill } from "@equiem/react-admin-ui/icons";

import { Modal as ModalContext } from "../../../contexts/ModalContext";
import type { CompanyListQueryVariables } from "../../../generated/settings-client";
import { useDeleteCompanyMutation } from "../../../generated/settings-client";
import type { CompanyListItem } from "../hooks/usePagedCompanyList";

type BuildingLevels = Record<string, { uuid: string; name: string; levels: string[] }>;

interface P {
  company: CompanyListItem;
  refetch: (variables?: Partial<CompanyListQueryVariables>) => Promise<unknown>;
  withFlexTenants: boolean;
}
export const CompanyListRow: React.FC<P> = ({ company, refetch, withFlexTenants }) => {
  const { t } = useTranslation();
  const { colors, spacers } = useTheme();
  const { withConfirmation } = useConfirmer();
  const [deleteCompany] = useDeleteCompanyMutation();
  const { uuid: siteUuid } = useSiteContext();
  const modal = useContext(ModalContext);
  const toast = useToast();

  const stopPropagationHandle = (e: React.MouseEvent) => {
    e.stopPropagation();
  };

  const handleEdit = () => {
    modal.open("CompanyDetails", company.uuid);
  };

  const buildingLevels = useMemo(() => {
    const bls = (company.companyDestination?.buildingLevels ?? []).reduce<BuildingLevels>((prev, bl) => {
      prev[bl.building.uuid] = {
        uuid: bl.building.uuid,
        name: bl.building.name,
        levels: prev[bl.building.uuid] == null ? [bl.name] : prev[bl.building.uuid].levels.concat(bl.name),
      };

      return prev;
    }, {});

    const vals = Object.values(bls).sort((a, b) => (b.name < a.name ? 1 : b.name > a.name ? -1 : 0));

    return vals.map((v) => ({ ...v, levels: [...v.levels].sort() }));
  }, [company.companyDestination?.buildingLevels]);

  const handleRemove = () => {
    withConfirmation({
      title: t("common.areYouSure"),
      message: t("settings.companyDeleteMessage"),
      confirmButtonText: t("common.yesRemove"),
      cancelButtonText: t("common.cancelNo"),
      confirmButtonVariant: "danger",
      onConfirm: async () => {
        const result = await deleteCompany({
          variables: {
            uuid: company.uuid,
            destinationUuid: siteUuid,
          },
        });
        await refetch();

        if (result.data?.deleteCompanyV2 === true) {
          toast.positive(t("settings.companyDeleteSuccess"));
        } else {
          toast.negative(t("settings.companyDeleteError"));
        }
      },
    })();
  };

  return (
    <>
      <tr className="row" onClick={handleEdit}>
        <td>{company.name}</td>
        <td>{companyNameTranslator(company.industry?.name ?? "", t)}</td>
        <td>
          {buildingLevels.length > 0 ? (
            buildingLevels.map((bl) => (
              <div key={bl.uuid}>
                {bl.name} <span>{bl.levels.join(", ")}</span>
              </div>
            ))
          ) : (
            <Tag variant="negative">{t("common.missing")}</Tag>
          )}
        </td>
        {withFlexTenants ? (
          <td>
            {company.flexTenants?.map((ft) => (
              <Tag key={ft.uuid} className="mr-2 role">
                {ft.name}
              </Tag>
            ))}
          </td>
        ) : null}
        <td>{company.companyDestination?.profiles?.totalCount}</td>
        <td onClick={stopPropagationHandle} className="company-menu">
          <Dropdown.Icon icon={RiMoreFill} size="md" placement="bottom-end" supportsMobile>
            <Dropdown.Item onClick={handleEdit} icon={() => <RiEditLine />}>
              <span>{t("common.edit")}</span>
            </Dropdown.Item>
            <Dropdown.Item onClick={handleRemove} icon={() => <RiDeleteBin5Line color="#ff2e3b" />}>
              <span>{t("common.remove")}</span>
            </Dropdown.Item>
          </Dropdown.Icon>
        </td>
      </tr>
      <style jsx>{`
        .row:hover {
          background: ${colors.grayscale_5};
        }
        .row div {
          padding: 0 0 ${spacers.s4};
        }
        .row div:last-child {
          padding: 0;
        }
        .row span {
          display: block;
          color: ${colors.grayscale["60"]};
          font-size: 13px;
        }
      `}</style>
    </>
  );
};
