import { Button, Modal, Form as EqForm, MemberCard } from "@equiem/react-admin-ui";
import React, { useContext, useState } from "react";
import { SideModalContext } from "../../contexts/SideModalContext";
import { useTranslation } from "@equiem/localisation-eq1";
import { FlexTenantContext } from "../../contexts/FlexTenantContext";
import {
  MembershipsForFlexTenantDocument,
  useAssignProfileToFlexTenantMutation,
  type AssignProfileToFlexTenantInput,
} from "../../generated/gateway-client";
import type { FormikHelpers } from "formik";
import { Form, Formik } from "formik";
import type { UserSearchUser } from "@equiem/lib";
import { CompanyEmployeeSelect } from "../lib/CompanyEmployeeSelect";

export const AssignProfileToFlexTenantForm = () => {
  const { closeModal } = useContext(SideModalContext);
  const { activeFlexTenant } = useContext(FlexTenantContext);
  const [profile, setProfile] = useState<UserSearchUser | null>();
  const { t } = useTranslation();
  const [mutation] = useAssignProfileToFlexTenantMutation();

  const initialValues: AssignProfileToFlexTenantInput = {
    flexTenantUuid: activeFlexTenant?.uuid ?? "",
    profileUuid: "",
  };

  const handleSubmit = async (
    values: typeof initialValues,
    { setSubmitting }: FormikHelpers<typeof initialValues>,
  ): Promise<boolean> => {
    if (activeFlexTenant == null || profile == null) {
      return false;
    }
    setSubmitting(true);
    await mutation({
      variables: {
        input: {
          ...values,
          profileUuid: profile.uuid,
        },
      },
      refetchQueries: [
        {
          query: MembershipsForFlexTenantDocument,
          variables: {
            flexTenantUuid: activeFlexTenant.uuid,
          },
        },
      ],
    });
    setSubmitting(false);
    closeModal();
    return true;
  };

  if (activeFlexTenant == null) {
    return <></>;
  }

  return (
    <>
      <Modal.Header intro={t("flex.members.addButtonTitle")} closeButton={true} />
      <Formik<AssignProfileToFlexTenantInput> initialValues={initialValues} onSubmit={handleSubmit}>
        {({ isSubmitting, submitForm, isValid }) => (
          <>
            <Modal.Body>
              <Form>
                <EqForm.Group label={t("common.profile")} required>
                  {profile != null ? (
                    <MemberCard.Card
                      className="selected-member mt-2"
                      buttonVariant="outline"
                      buttonText={t("common.remove")}
                      onButtonClick={() => {
                        setProfile(null);
                      }}
                      email={profile.email}
                      companyName={profile.companyV2?.name ?? ""}
                      lastName={profile.lastName ?? ""}
                      firstName={profile.firstName ?? ""}
                      profileIconUrl={profile.avatar ?? undefined}
                    />
                  ) : (
                    <CompanyEmployeeSelect onSelect={setProfile} flexTenantUuid={activeFlexTenant.uuid} />
                  )}
                </EqForm.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={closeModal} variant="ghost">
                {t("common.cancel")}
              </Button>
              <Button
                className="ml-2"
                disabled={!isValid || isSubmitting || profile == null}
                onClick={() => {
                  void submitForm();
                }}
              >
                {t("flex.members.addButtonTitle")}
              </Button>
            </Modal.Footer>
          </>
        )}
      </Formik>
    </>
  );
};
