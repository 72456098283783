/* eslint-disable @typescript-eslint/no-floating-promises */
import React, { useCallback, useEffect, useMemo } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useRouter } from "next/router";

import { SubTopMenu } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import { ProgressCircle, useTheme } from "@equiem/react-admin-ui";

import { useVisitorReceptionsQuery, VisitorRole } from "../../generated/visitors-client";
import { filterReceptionList } from "../../helpers/filterReceptionList";

import { EmptyList } from "./components/EmptyList";
import { ReceptionItem } from "./components/ReceptionItem";

export const Receptions: React.FC = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const { colors } = useTheme(true);
  const {
    data: receptionsData,
    fetchMore,
    loading,
  } = useVisitorReceptionsQuery({
    variables: { as: VisitorRole.Receptionist, first: 1000 },
  });

  const receptions = useMemo(() => filterReceptionList(receptionsData, t), [receptionsData, t]);

  useEffect(() => {
    if (receptions.length === 1) {
      router.replace(`/visitor-management/receptions/${receptions[0].uuid}`);
    }
  }, [receptions]);

  const handleNextPage = useCallback(
    async () =>
      fetchMore({
        variables: {
          after: receptionsData?.visitorReceptions.pageInfo.endCursor,
        },
      }),
    [receptionsData?.visitorReceptions.pageInfo.endCursor],
  );

  const hasMoreReceptions = receptionsData?.visitorReceptions.pageInfo.hasNextPage ?? false;

  return (
    <div className="receptions d-flex flex-column">
      <SubTopMenu className="mb-4">
        <h1 style={{ fontWeight: "700" }}>{t("visitors.receptions.selectReception")}</h1>
      </SubTopMenu>
      {loading && receptions.length === 0 ? (
        <div className="mt-6 mb-6 d-flex justify-content-center">
          <ProgressCircle size="md" />
        </div>
      ) : (
        <InfiniteScroll
          next={handleNextPage}
          dataLength={receptions.length}
          hasMore={hasMoreReceptions}
          style={{ overflow: undefined }}
          loader={
            <div className="mt-6 mb-6 d-flex justify-content-center">
              <ProgressCircle size="md" />
            </div>
          }
        >
          {receptions.length === 0 ? (
            <div className="d-flex align-items-center justify-content-center">
              <EmptyList />
            </div>
          ) : (
            <div className="list d-flex flex-column m-6">
              {receptions.map((reception) => (
                <ReceptionItem
                  key={reception.uuid}
                  type={reception.company != null ? "tenant" : "building"}
                  uuid={reception.uuid}
                  buildingName={reception.building?.name}
                  buildingLevelName={reception.buildingLevel?.name}
                  companyName={reception.company?.name}
                  suffix={reception.suffix}
                />
              ))}
            </div>
          )}
        </InfiniteScroll>
      )}
      <style jsx>
        {`
          .receptions {
            min-height: 100%;
            background: ${colors.white};
          }

          .list {
            gap: 8px;
          }
        `}
      </style>
    </div>
  );
};
