import React from "react";

import { useTranslation } from "@equiem/localisation-eq1";
import { Tag } from "@equiem/react-admin-ui";
import { RiEyeLine } from "@equiem/react-admin-ui/icons";

interface P {
  watchedByMe: boolean;
  className?: string;
}
export const RequestWatching: React.FC<P> = ({ watchedByMe, className }) => {
  const { t } = useTranslation();

  return watchedByMe ? (
    <Tag className={className}>
      <RiEyeLine className="mr-2" /> <span>{t("requests.list.watching")}</span>
    </Tag>
  ) : null;
};
