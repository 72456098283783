import { useDestinationDetailsQuery, type DestinationDetailsQuery } from "../../../generated/gateway-client";

type FeatureModule = DestinationDetailsQuery["destination"]["featureModules"][number];

interface HasFeatureModules {
  destination?: { featureModules: FeatureModule[] } | null;
}

export const isVisitorManagementEnabled = (data: HasFeatureModules | undefined) =>
  data?.destination?.featureModules.find((m) => m.type === "VISITORS")?.enableMenu ?? false;

export const useIsVisitorManagementEnabled = (siteUuid: string) => {
  const { data, loading } = useDestinationDetailsQuery({ variables: { uuid: siteUuid } });
  return { visitorsEnabled: isVisitorManagementEnabled(data), loading };
};
