import { getHostInfo } from "@equiem/lib";

interface SiteLoaderMode {
  appLoadedByDefaultUrl: boolean;
  appLoadedByLegacyUrl: boolean;
  hostUrl?: string;
  inSiteMode: boolean;
}

export const useSiteLoaderMode = (): SiteLoaderMode => {
  if (typeof window === "undefined") {
    return {
      appLoadedByDefaultUrl: true,
      appLoadedByLegacyUrl: true,
      inSiteMode: false,
    };
  }

  return { ...getHostInfo(window.location.hostname) };
};
