import React, { useContext } from "react";
import { Tooltip, Text, ValueMetric, useTheme } from "@equiem/react-admin-ui";
import type { GetCreditStatsQuery } from "../../../generated/gateway-client";
import { useTranslation } from "@equiem/localisation-eq1";
import { convertCents } from "../utils/convertCents";
import { RiInformationLine, RiWalletLine } from "@equiem/react-admin-ui/icons";
import { CurrentRole, Role } from "@equiem/lib";

export const OverviewWidgets = ({
  loading,
  creditStats,
  currentMonth,
}: {
  currentMonth?: { label: string; value: number };
  loading: boolean;
  creditStats?: GetCreditStatsQuery;
}) => {
  const { t } = useTranslation();
  const { colors } = useTheme();
  const { currentRole } = useContext(CurrentRole);
  const isPastMonthSelected = (currentMonth?.value ?? 0) < 0;
  const isFlexManager = currentRole === Role.FlexManager;

  const creditsBalance = convertCents(creditStats?.creditStats.creditsBalance ?? 0);
  const creditsTotal = convertCents(creditStats?.creditStats.creditsTotal ?? 0);
  const creditsAdditional = convertCents(creditStats?.creditStats.creditsAdditional ?? 0);
  return (
    <>
      <div className={isPastMonthSelected ? "empty-container" : ""}>
        <ValueMetric
          id="available-credits"
          title={t("credits.overview.availableCredits.title")}
          tooltip={t("credits.overview.availableCredits.tooltip")}
          value={
            <div className="balance-value" title={`${creditsBalance}/${creditsTotal}`}>
              {creditsBalance}
              <span className="total-value">/{creditsTotal}</span>
            </div>
          }
          loading={!isPastMonthSelected && loading}
          emptyTemplate={
            isPastMonthSelected ? (
              <>
                <div className="empty-icon">
                  <RiWalletLine size={24} color={colors.grayscale[40]} />
                </div>
                <Text size="small" color={colors.grayscale[60]} variant="text">
                  {t("credits.overview.noCreditsAvailable")}
                </Text>
              </>
            ) : null
          }
          description={
            !isFlexManager ? (
              <div className="total-credits-description">
                {t("credits.overview.totalCredits", {
                  credits: creditsTotal,
                })}
                <Tooltip
                  description={
                    <div className="details-container">
                      <div className="title-row">
                        <span>{t("credits.overview.total")}</span>
                        <span>{creditsTotal}</span>
                      </div>
                      <div className="details">
                        <div className="details-row">
                          <span className="name">{t("credits.overview.monthly")}</span>
                          <span className="value">{convertCents(creditStats?.creditStats.creditsMonthly ?? 0)}</span>
                        </div>
                        <div className="details-row">
                          <span className="name">{t("credits.overview.additional")}</span>
                          <span className="value">{creditsAdditional}</span>
                        </div>
                      </div>
                    </div>
                  }
                  variant="advanced"
                  placement="top"
                >
                  <div className="d-flex">
                    <RiInformationLine size={16} />
                  </div>
                </Tooltip>
              </div>
            ) : null
          }
        />
      </div>

      <ValueMetric
        id="additional-credits"
        title={t("credits.overview.additionalCredits.title")}
        tooltip={t("credits.overview.additionalCredits.tooltip")}
        value={
          <div className="additional-credits" title={`${creditsAdditional}`}>
            {creditsAdditional}
          </div>
        }
        loading={loading}
        description={
          !isFlexManager ? (
            <div className="additional-credits-description">
              {t("credits.overview.creditPreviousMonth", {
                credits: convertCents(creditStats?.creditStats.creditsCarriedOver ?? 0),
              })}
            </div>
          ) : null
        }
      />
      <style jsx>{`
        .balance-value {
          font-size: 64px;
          font-weight: 600;
          line-height: 50px;
        }

        .empty-container :global(.container) {
          height: 100%;
        }

        .empty-icon {
          background: ${colors.grayscale[5]};
          padding: 16px;
          border-radius: 50%;
          display: flex;
          align-self: center;
        }

        .title-row,
        .details-row {
          display: flex;
          justify-content: space-between;
          font-weight: 400;
        }

        .title-row {
          font-size: 14px;
        }

        .details-row {
          font-size: 12px;
        }

        .details-container {
          display: flex;
          flex-direction: column;
          gap: 8px;
        }

        .additional-credits {
          font-size: 48px;
          font-weight: 600;
          line-height: 37.5px;
        }

        .total-credits-description {
          font-size: 12px;
          font-weight: 500;
          color: ${colors.grayscale[60]};
          text-transform: uppercase;
          display: flex;
          align-items: center;
          gap: 4px;
        }

        .additional-credits-description {
          font-size: 14px;
          color: ${colors.grayscale[60]};
        }

        .total-credits-description :global(.tooltip--advanced) {
          max-width: 200px;
          width: max-content;
          min-width: 155px;
          padding: 4px 8px;
        }

        .total-value {
          color: ${colors.grayscale[50]};
          font-size: 32px;
          font-weight: 600;
          line-height: 37.5px;
        }

        .additional-credits,
        .balance-value {
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      `}</style>
    </>
  );
};
