import React, { useMemo } from "react";
import { useTranslation } from "@equiem/localisation-eq1";

import { DropdownItem } from "../Dropdown/DropdownItem/DropdownItem";

// eslint-disable-next-line @typescript-eslint/no-type-alias
export type Option = { value: string; label: string };

// eslint-disable-next-line @typescript-eslint/no-type-alias
export type Props = {
  languages?: Option[];
  debugLanguages?: Option[];
  showDebugLanguages?: boolean;
  showSelected?: boolean;
};

const defaultLanguages: Option[] = [
  { value: "en-US", label: "English US" },
  { value: "en-AU", label: "English AU" },
  { value: "en-CA", label: "English CA" },
  { value: "en-GB", label: "English UK" },
  { value: "de", label: "Deutsch" },
  { value: "es", label: "Español" },
  { value: "fr", label: "Français" },
  { value: "pl", label: "Polski" },
  { value: "hu", label: "Magyar" },
  { value: "nl", label: "Nederlands" },
  { value: "sk", label: "Slovenčina" },
  { value: "cs", label: "Čeština" },
  { value: "pt-PT", label: "Português" },
];

const defaultDebugLanguages: Option[] = [
  { value: "ja", label: "日本語" },
  { value: "en", label: "English (Base)" },
  { value: "en-US-debug", label: "English (Debug)" },
  { value: "en-US-debug-longer", label: "English (Debug +25%)" },
];

export const getDisplayName = (language: string, showDebugLanguages: boolean) => {
  const resolvedLanguage = language === "en" && !showDebugLanguages ? "en-US" : language;
  return [...defaultLanguages, ...defaultDebugLanguages].find((option) => option.value === resolvedLanguage)?.label;
};

export const LocalePickerOptions: React.FC<Props> = ({
  languages = defaultLanguages,
  debugLanguages = defaultDebugLanguages,
  showSelected = false,
  showDebugLanguages = false,
}) => {
  const { i18n } = useTranslation();

  const options = useMemo(() => {
    const list = showDebugLanguages ? [...languages, ...debugLanguages] : [...languages];
    return list.sort((a, b) => a.label.localeCompare(b.label, i18n.language));
  }, [debugLanguages, i18n.language, languages, showDebugLanguages]);

  const setLanguage = (newLanguage: string) => {
    i18n
      .changeLanguage(newLanguage)
      // eslint-disable-next-line no-console
      .catch((err) => console.error("Failed to set language:", err));
  };

  return (
    <>
      {options.map(({ value, label }) => (
        <DropdownItem key={value} selected={showSelected && value === i18n.language} onClick={() => setLanguage(value)}>
          {label}
        </DropdownItem>
      ))}
    </>
  );
};
