import React from "react";

export interface Props {
  children?: React.ReactNode;
  highlight?: boolean;
}

export const MemberCardWrap = React.forwardRef<HTMLDivElement, Props>(({ children, highlight = false }, ref) => (
  <>
    <div className={`m-3 p-2 wrap-container ${highlight ? "highlight" : ""}`} ref={ref}>
      <div className="m-2">{children}</div>
    </div>
    <style jsx>{`
      .wrap-container {
        border-radius: 4px;
      }
      .wrap-container:hover,
      .wrap-container.highlight {
        background: rgba(0, 0, 0, 0.05);
      }
    `}</style>
  </>
));

MemberCardWrap.displayName = "MemberCardWrap";
