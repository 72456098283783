import React, { useContext, useEffect, useMemo } from "react";
import type { FormikHelpers } from "formik";
import { Formik } from "formik";

import { AppointmentsMenuContext, Site, useShowError } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import { useTheme, useToast } from "@equiem/react-admin-ui";

import { EditAppointmentsMenu } from "../../components/EditAppointmentsMenu";
import { Modal } from "../../contexts/ModalContext";
import { withContexts } from "../../contexts/withContexts";
import { useCreateVisitorAppointmentMutation, useVisitorAppointmentsQuery } from "../../generated/visitors-client";

import { EditAppointmentProvider } from "./context/EditAppointmentContext";
import { getValidationSchema } from "./utils/validationSchema";
import { AppointmentCreatedWidget } from "./widgets/AppointmentCreatedWidget";
import { Appointment } from "./Appointment";
import { initialValues } from "./initialValues";
import { mapFormToDTO } from "./mappings";
import type { FormValues } from "./types";

const CreateAppointmentBase: React.FC = () => {
  const { t } = useTranslation();
  const theme = useTheme(true);
  const modal = useContext(Modal);
  const appointmentsMenu = useContext(AppointmentsMenuContext);
  const showError = useShowError();
  const { refetch: refetchAppointments } = useVisitorAppointmentsQuery({ skip: true });
  const [createVisitorAppointment] = useCreateVisitorAppointmentMutation();
  const { timezone } = useContext(Site);
  const toast = useToast();

  useEffect(() => {
    appointmentsMenu.createAppointment();
  }, []);

  const handleCancel = () => {
    modal.open("CancelForm");
  };

  const handleSubmit = async (values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
    try {
      const result = await createVisitorAppointment({
        variables: {
          input: mapFormToDTO(values),
        },
      });

      if (result.data?.createVisitorAppointment != null && result.errors == null) {
        modal.open("AppointmentCreated");
        toast.neutral(t("visitors.appointmentForm.changesHaveBeenSaved"));
        void refetchAppointments();
      } else {
        throw new Error("Failed to create appointment.");
      }
    } catch (e: unknown) {
      showError(e);
      throw e;
    }

    setSubmitting(false);
  };

  const validationSchema = useMemo(() => getValidationSchema(timezone, t), [timezone, t]);

  return (
    <EditAppointmentProvider>
      <div className="outer-container">
        <EditAppointmentsMenu />
        <div>
          <Formik
            initialValues={appointmentsMenu.cachedAppointmentForm ?? initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting, submitForm }) => (
              <Appointment isSubmitting={isSubmitting} submitForm={submitForm} handleCancel={handleCancel} />
            )}
          </Formik>
        </div>
        <AppointmentCreatedWidget />
        <style jsx>
          {`
            .outer-container {
              flex-grow: 1;
              background: linear-gradient(90deg, #fff 0%, #fff 55%, #f5f5f5 55%, #f5f5f5 100%);
            }
            @media all and (max-width: 1000px) {
              .outer-container {
                background: ${theme.colors.white};
                padding-bottom: ${theme.spacers.s5};
              }
            }
          `}
        </style>
      </div>
    </EditAppointmentProvider>
  );
};

export const CreateAppointment = withContexts(CreateAppointmentBase);
