import { useContext, useCallback } from "react";
import { Site, notNullOrUndefined } from "@equiem/lib";
import { type FilterItem, type FilterValue, FilterDateModifier } from "@equiem/react-admin-ui";
import { RiStore2Line, RiDeleteBinLine, RiMoneyDollarBoxLine, RiBankCardLine } from "@equiem/react-admin-ui/icons";
import { useTranslation } from "@equiem/localisation-eq1";
import { DateTime } from "luxon";
import { isEqual } from "lodash";
import type { Runtype } from "runtypes";

import {
  BookableResourcePaymentMethod,
  useReportingCompaniesQuery,
  useBookingsReconciliationReportQuery,
} from "../../../generated/gateway-client";
import { useLocalStorageState } from "../../../hooks/useLocalStorageState";
import { useCurrencyCode } from "../../../hooks/useCurrency";
import { SavedReportsFilters } from "../../../models/SavedReportsFilters";
import type { ReportParams, Totals, Booking } from "../utils/ReportParams";
import { formatPaymentMethodLocalised } from "../utils/formatPaymentMethodLocalised";

type FilterKey = "date" | "company" | "cancelled" | "costOnly" | "paymentMethod";
type ConcreteFilterValues = Partial<Record<FilterKey, FilterValue>>;

const getSelectValue = <T extends string = string>(v: FilterValue | undefined): T | undefined =>
  v?.type === "select" ? (v.value as T) : undefined;

const toBoolean = (str: string | undefined) => ({ true: true, false: false }[str ?? ""] ?? undefined);

const getFilters = (values: ConcreteFilterValues, timezone: string) => {
  let startDate: number;
  let endDate: number;
  if (values.date?.type === "date" && values.date.value != null) {
    const [fromIso, untilIso] = Array.isArray(values.date.value)
      ? values.date.value
      : ([values.date.value, values.date.value] as const);

    startDate = DateTime.fromISO(fromIso, { zone: timezone }).startOf("day").toMillis();
    endDate = DateTime.fromISO(untilIso, { zone: timezone }).endOf("day").toMillis();
  } else {
    startDate = DateTime.now().setZone(timezone).minus({ months: 1 }).startOf("day").toMillis();
    endDate = DateTime.now().setZone(timezone).endOf("day").toMillis();
  }

  return {
    startDate,
    endDate,
    companyUuid: getSelectValue(values.company),
    cancelled: toBoolean(getSelectValue(values.cancelled)),
    costOnly: toBoolean(getSelectValue(values.costOnly)),
    paymentMethod: getSelectValue<BookableResourcePaymentMethod>(values.paymentMethod),
  };
};

export const useReportData = () => {
  const { t } = useTranslation();
  const { timezone } = useContext(Site);
  const fallbackCurrencyCode = useCurrencyCode();

  const { data: companiesData, error: companiesError, loading: companiesLoading } = useReportingCompaniesQuery();
  const companies = Object.values(
    Object.fromEntries(
      (companiesData?.bookableResourceFilterOptions.sites ?? [])
        .flatMap((site) => site.companiesV2?.edges ?? [])
        .map((edge) => edge.node)
        .filter(notNullOrUndefined)
        .map((c) => [c.uuid, { value: c.uuid, label: c.name }]),
    ),
  ).sort((a, b) => a.label.localeCompare(b.label));

  const filterItems: Record<string, FilterItem> = {
    date: {
      title: t("common.date"),
      type: "date",
      modifiers: [FilterDateModifier.is, FilterDateModifier.between],
    },
    company: {
      title: t("common.company"),
      type: "select",
      icon: RiStore2Line,
      options: companies,
      showSearch: true,
    },
    cancelled: {
      title: t("bookings.reports.cancelled"),
      type: "select",
      icon: RiDeleteBinLine,
      options: [
        { value: "false", label: t("common.exclude") },
        { value: "true", label: t("common.only") },
      ],
    },
    costOnly: {
      title: t("bookings.reports.costOnly"),
      type: "select",
      icon: RiMoneyDollarBoxLine,
      options: [
        { value: "true", label: t("common.yes") },
        { value: "false", label: t("common.no") },
      ],
    },
    paymentMethod: {
      title: t("bookings.reports.paymentMethod"),
      type: "select",
      icon: RiBankCardLine,
      options: Object.values(BookableResourcePaymentMethod).map((value) => ({
        value,
        label: formatPaymentMethodLocalised(value, t),
      })),
    },
  };
  const [filterValues, setFilterValues] = useLocalStorageState<ConcreteFilterValues>(
    "booking-reports-filters",
    {},
    // This is not especially type-safe, but ComplexFilter validates internally
    // that `initialValues` matches the filter config in `filters` and drops
    // invalid saved state, so we only really need to do a basic structural
    // check here.
    SavedReportsFilters as Runtype<ConcreteFilterValues>,
  );
  const filters = getFilters(filterValues.state, timezone);

  const { data, error, loading } = useBookingsReconciliationReportQuery({ variables: { filters } });

  const initialLoading = filterValues.loading || companiesLoading;
  const anythingLoading = initialLoading || loading;

  const bookings: Booking[] = data?.bookingsReportReconciliation.bookings ?? [];
  const totals: Totals = data?.bookingsReportReconciliation.totals ?? {
    adjustmentsTotalPrice: 0,
    allPartialRefundsTotalPrice: 0,
    durationHours: 0,
    resourcePrice: 0,
    discountTotalAmount: 0,
    addOnsPrice: 0,
    cancellationPrice: 0,
    totalTax: 0,
    totalPrice: 0,
  };

  const currencyCode = data?.bookingsReportReconciliation.currencyCode ?? fallbackCurrencyCode;
  const singleCurrency = bookings.every((b) => b.currencyCode === currencyCode || b.currencyCode === "CREDITS");
  const yardiEnabled = data?.bookingsReportReconciliation.yardiEnabled ?? false;

  const reportParams: ReportParams = {
    companyName: companies.find(({ value }) => value === filters.companyUuid)?.label,
    costOnly: filters.costOnly,
    cancelled: filters.cancelled,
    paymentMethod: filters.paymentMethod,
    startDate: filters.startDate,
    endDate: filters.endDate,
    timezone,
    bookings,
    totals,
    currencyCode: singleCurrency ? currencyCode : null,
    fallbackCurrencyCode: currencyCode,
  };

  const onFilterChange = useCallback(
    (values: Record<string, FilterValue>): void => {
      if (!anythingLoading && !isEqual(values, filterValues.state)) {
        setFilterValues({ ...values } as ConcreteFilterValues);
      }
    },
    [anythingLoading, setFilterValues, filterValues.state],
  );

  return {
    filterItems,
    filterValues: filterValues.state,
    setFilterValues: onFilterChange,

    error: error ?? companiesError,
    loading: anythingLoading,
    initialLoading,

    bookings,
    totals,
    currencyCode: singleCurrency ? currencyCode : null,
    fallbackCurrencyCode,

    reportParams,
    yardiEnabled,
  };
};
