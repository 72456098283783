import type { FormikErrors } from "formik";
import type { PartialRefundFormValue } from "../models/PartialRefundFormValue";
import { stringIsEmpty, stringNotEmpty } from "@equiem/lib";
import type { TFunction } from "@equiem/localisation-eq1";

export const partialRefundFormValidation = (t: TFunction, values: PartialRefundFormValue) => {
  const errors: FormikErrors<PartialRefundFormValue> = {};

  if (stringIsEmpty(values.reason)) {
    errors.reason = t("common.required");
  }

  if (stringNotEmpty(values.booking)) {
    const bookingInput = Number(values.booking);
    if (isNaN(bookingInput) || bookingInput <= 0) {
      errors.booking = t("common.incorrectValue");
    }
  }

  const addOnsKey = Object.keys(values.addOns);
  if (addOnsKey.length > 0) {
    const errorsAddOns = addOnsKey.reduce<Record<string, string>>((prev, key) => {
      if (stringNotEmpty(values.addOns[key])) {
        const addOnInput = Number(values.addOns[key]);
        if (isNaN(addOnInput) || addOnInput <= 0) {
          prev[key] = t("common.incorrectValue");
        }
      }

      return prev;
    }, {});

    if (Object.keys(errorsAddOns).length > 0) {
      errors.addOns = errorsAddOns;
    }
  }

  const adjustmentKey = Object.keys(values.adjustments);
  if (adjustmentKey.length > 0) {
    const errorsAdjustment = adjustmentKey.reduce<Record<string, string>>((prev, key) => {
      if (stringNotEmpty(values.adjustments[key])) {
        const adjustmentInput = Number(values.adjustments[key]);
        if (isNaN(adjustmentInput) || adjustmentInput <= 0) {
          prev[key] = t("common.incorrectValue");
        }
      }

      return prev;
    }, {});

    if (Object.keys(errorsAdjustment).length > 0) {
      errors.adjustments = errorsAdjustment;
    }
  }

  return errors;
};
