import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useApolloClient } from "@apollo/client";
import { Site } from "../context";
import type { AllCompaniesQuery, AllCompaniesQueryVariables } from "../generated/gateway-client";
import { AllCompaniesDocument } from "../generated/gateway-client";

type Company = NonNullable<AllCompaniesQuery["companiesV2"]["edges"][number]["node"]>;

export const useAllCompanies = ({
  search,
  selected,
  siteUuid: siteUuidInput,
  skip = false,
}: { search?: string; selected?: string; siteUuid?: string; skip?: boolean } = {}) => {
  const [loading, setLoading] = useState(true);
  const [companies, setCompanies] = useState<Company[]>([]);
  const { uuid } = useContext(Site);
  const apollo = useApolloClient();
  const siteUuid = siteUuidInput ?? uuid;

  const loadCompanies = useCallback(
    async (after?: string): Promise<Company[]> => {
      if (skip) {
        return [];
      }

      const { data } = await apollo.query<AllCompaniesQuery | undefined, AllCompaniesQueryVariables>({
        query: AllCompaniesDocument,
        variables: { siteUuid, first: 1000, after },
      });

      const edges = data?.companiesV2.edges.flatMap((e) => (e.node == null ? [] : [e.node])) ?? [];

      if (data?.companiesV2.pageInfo.hasNextPage === true && data.companiesV2.pageInfo.endCursor != null) {
        return edges.concat(await loadCompanies(data.companiesV2.pageInfo.endCursor));
      }

      return edges;
    },
    [apollo, siteUuid, skip],
  );

  useEffect(() => {
    setLoading(true);
    loadCompanies()
      .then((c) => setCompanies(c))
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [loadCompanies]);

  return useMemo(
    () => ({
      loading,
      companies: companies
        .map((c) => ({ value: c.uuid, label: c.name }))
        .filter(
          (c) =>
            search == null || c.label.toLocaleLowerCase().includes(search.toLocaleLowerCase()) || c.value === selected,
        ),
    }),
    [companies, loading, search, selected],
  );
};
