import { useCallback } from "react";
import { useApolloClient } from "@apollo/client";
import { saveAs } from "file-saver";

import { useSiteContext } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import { useToast } from "@equiem/react-admin-ui";

import type { ExportApartmentsCsvQuery, ExportApartmentsCsvQueryVariables } from "../../../generated/settings-client";
import { ExportApartmentsCsvDocument } from "../../../generated/settings-client";

export const useExportApartmentList = (variables: ExportApartmentsCsvQueryVariables) => {
  const { t } = useTranslation();
  const client = useApolloClient();
  const toast = useToast();
  const site = useSiteContext();
  const func = useCallback(async () => {
    const result = await client.query<ExportApartmentsCsvQuery, ExportApartmentsCsvQueryVariables>({
      query: ExportApartmentsCsvDocument,
      variables,
    });
    if (result.data.apartmentsCSVForDestination == null) {
      toast.negative(t("settings.failedToExportApartments"));
      return;
    }
    saveAs(new Blob([result.data.apartmentsCSVForDestination]), `${site.name.toLowerCase()}_apartments.csv`, true);
  }, [variables, client]);

  return func;
};
