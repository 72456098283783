import React, { type FormEvent } from "react";
import { Field, useFormikContext } from "formik";

import { useTranslation } from "@equiem/localisation-eq1";
import { Form as EqForm } from "@equiem/react-admin-ui";

import type { VisitorInfo } from "../../../generated/visitors-client";
import { useVisitorInformation } from "../hooks/useVisitorInformation";

type FormValues = {
  visitorInfo: VisitorInfo[];
  receptionNoteMessage: string;
};

export const AppointmentVisitorInformationForm = () => {
  const { t } = useTranslation();
  const { values, errors, setFieldValue } = useFormikContext<FormValues>();
  const { visitorInfoTypes, getVisitorInfoTranslation, getVisitorInfoIcons } = useVisitorInformation();

  const onChangeType = (visitorInfo: VisitorInfo) => (e: boolean | FormEvent<HTMLButtonElement>) => {
    if (e === true) {
      void setFieldValue("visitorInfo", [...values.visitorInfo, visitorInfo]);
    } else {
      void setFieldValue(
        "visitorInfo",
        values.visitorInfo.filter((item) => item !== visitorInfo),
      );
    }
  };

  return (
    <>
      <EqForm.Group label={t("visitors.appointmentForm.setVisitorAs")} error={errors.visitorInfo as string}>
        <div className="d-flex" style={{ gap: "8px" }}>
          {visitorInfoTypes.map((visitorInfo: VisitorInfo) => (
            <EqForm.ToggleButton
              key={visitorInfo}
              type="button"
              label={getVisitorInfoTranslation(visitorInfo)}
              value={values.visitorInfo.includes(visitorInfo)}
              icon={getVisitorInfoIcons(visitorInfo)}
              onChange={onChangeType(visitorInfo)}
            />
          ))}
        </div>
      </EqForm.Group>
      <EqForm.Group label={t("visitors.appointmentForm.visitorNoteToReception")} error={errors.receptionNoteMessage}>
        <Field
          as={EqForm.Textarea}
          id="receptionNoteMessage"
          name="receptionNoteMessage"
          placeholder={t("visitors.appointmentForm.visitorNoteToReceptionPlaceholder")}
        />
      </EqForm.Group>
    </>
  );
};
