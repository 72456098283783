import { useTranslation } from "@equiem/localisation-eq1";
import { Activity } from "@equiem/react-admin-ui";
import type { FC } from "react";
import React from "react";

import { type ActivityFragmentFragment } from "../../generated/gateway-client";
import { useMappings, type CustomMapping, type FieldNameMapping } from "../../hooks/useMappings";

interface Props {
  activity: ActivityFragmentFragment;
  customMappings?: CustomMapping[];
  fieldNameMappings?: FieldNameMapping;
}

export const ConfiguredActivity: FC<Props> = ({ activity, customMappings, fieldNameMappings }) => {
  const { t } = useTranslation();
  const date = new Date(activity.timestamp);
  const mapping = useMappings(activity, customMappings, fieldNameMappings);

  if (mapping == null) {
    return <></>;
  }

  // if first name is missing (ie profile service down) set both lastName and avatar to null so that it displays "System"
  // and displays (S) where the avatar goes. <Activity /> handles missing avatar nicely :)
  const profile =
    activity.user?.firstName != null
      ? {
          firstName: activity.user.firstName,
          lastName: activity.user.lastName ?? "",
          avatar: activity.user.avatar,
        }
      : {
          firstName: t("common.system"),
          lastName: "",
        };

  return (
    <Activity
      profile={profile}
      title={mapping.title}
      date={date}
      previousValue={mapping.from}
      value={mapping.to}
      message={mapping.message}
    />
  );
};
