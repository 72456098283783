import React, { useState } from "react";
import { DateTime } from "luxon";

import { stringIsEmpty, useSaferFormikContext, useSiteContext } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import { Form, useTheme, useToast } from "@equiem/react-admin-ui";

import type { NewRequestFormValues } from "../../create-request/NewRequest";

export const NewRequestDateReported = ({ disabled }: { disabled: boolean }) => {
  const { timezone } = useSiteContext();
  const date = DateTime.fromMillis(DateTime.now().toMillis(), { zone: timezone });
  const theme = useTheme();
  const [time, setTime] = useState(date);
  const toast = useToast();
  const { t } = useTranslation();
  const { values, setFieldValue } = useSaferFormikContext<NewRequestFormValues>();
  const isDateReportedDisabled =
    stringIsEmpty(values.space?.uuid) || stringIsEmpty(values.category) || stringIsEmpty(values.status);

  return (
    <>
      <div>
        <Form.Input
          variant="sm-plus"
          className="date-reported"
          disabled={disabled || isDateReportedDisabled}
          showChrome
          type="datetime-local"
          calendarIconPosition="left"
          value={time.toFormat("yyyy-LL-dd'T'HH:mm")}
          onChange={(e) => {
            const conversion = DateTime.fromISO(e.target.value, {
              zone: timezone,
            });
            if (conversion.isValid) {
              setTime(conversion);
            }
          }}
          onBlur={() => {
            const reported = time.toMillis();

            if (isNaN(reported)) {
              toast.negative(t("common.invalidDateFormat"));
              return;
            }

            void setFieldValue("dateReported", reported);
          }}
        />
      </div>
      <style jsx>{`
        div {
          margin-left: -${theme.spacers.s3};
        }
      `}</style>
    </>
  );
};
