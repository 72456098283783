import type { ComponentProps } from "react";
import React, { useCallback, useState } from "react";

import { useTheme } from "../../contexts/Theme";
import { RiArrowDownSLine, RiArrowUpSLine } from "react-icons/ri";
import { AdminButton } from "../Button/AdminButton";

// eslint-disable-next-line @typescript-eslint/no-type-alias
export type Props = Omit<ComponentProps<"div">, "onToggle"> & {
  headingSuffix?: React.ReactNode;
  heading?: string | React.ReactNode;
  subheading?: string | React.ReactNode;
  className?: string;
  defaultOpen?: boolean;
  onToggle?: (open: boolean) => void;
  disabled?: boolean;
  chevronButtonPosition?: "left" | "right";
};

export const Toggler: React.FC<Props> = ({
  heading,
  subheading,
  headingSuffix,
  children,
  className,
  onToggle,
  defaultOpen = false,
  disabled = false,
  chevronButtonPosition = "left",
  ...props
}) => {
  const [open, setOpen] = useState(defaultOpen);
  const theme = useTheme();
  const toggleIt = useCallback(() => {
    const o = !open;
    setOpen(o);
    if (onToggle != null) {
      onToggle(o);
    }
  }, [onToggle, open]);

  const headerChevronButton = (
    <AdminButton
      variant="ghost"
      shape="round"
      size="md"
      onClick={toggleIt}
      className={`${chevronButtonPosition === "left" ? "mr-3" : "ml-3"} toggler-${open ? "close" : "open"}`}
      disabled={disabled}
    >
      {open ? (
        <RiArrowUpSLine size="23px" className="arrow" color={theme.colors.medium} />
      ) : (
        <RiArrowDownSLine size="23px" className="arrow" color={theme.colors.medium} />
      )}
    </AdminButton>
  );

  return (
    <>
      <div {...props} className={`toggler ${open ? "open" : ""} ${className ?? ""}`}>
        <div className="head">
          {chevronButtonPosition === "left" && headerChevronButton}
          <div className="cursor-pointer" onClick={toggleIt}>
            <h5 className="m-0">{heading}</h5>
            <small>{subheading}</small>
          </div>
          {headingSuffix}
          {chevronButtonPosition === "right" && headerChevronButton}
        </div>
        <div className="body">
          <div className="content">{children}</div>
        </div>
      </div>
      <style jsx>{`
        .toggler {
          border: 1px solid ${theme.colors.border};
          border-radius: 8px;
          padding: ${theme.spacers.s4};
        }
        h5 {
          font-weight: 700;
        }
        h5 {
          font-size: 14px;
        }
        .head {
          display: flex;
          align-items: ${subheading != null ? "start" : "center"};
          justify-content: ${chevronButtonPosition === "left" ? "flex-start" : "space-between"};
        }
        .body {
          overflow: hidden;
        }
        .content {
          max-height: ${open ? "777px" : "0"};
          padding-top: ${open ? theme.spacers.s3 : theme.spacers.s0};
          transition: all ${theme.animationDuration} ease-in-out !important;
          overflow-y: scroll;
        }
      `}</style>
    </>
  );
};
