import type { PropsWithChildren } from "react";
import React, { useEffect } from "react";
import { useSiteContext } from "@equiem/lib";
import { catalogueViewToMetricCategory, type CatalogueView } from "../models/CatalogueView";
import { PageViewPageType, usePageViewMetricMutation } from "../../../generated/gateway-client";

interface Props {
  view: CatalogueView;
  readyToSend: boolean;
}

export const CatalogueMetrics: React.FC<PropsWithChildren<Props>> = ({ view, children, readyToSend }) => {
  const site = useSiteContext();
  const [pageViewMetric] = usePageViewMetricMutation();

  useEffect(() => {
    if (!readyToSend) {
      return;
    }

    pageViewMetric({
      variables: {
        pageViewInput: {
          timestamp: new Date().getTime(),
          siteUuid: site.uuid,
          pageTitle: document.title,
          pageUrl: window.location.href,
          pageType: PageViewPageType.ResourceList,
          categories: [catalogueViewToMetricCategory(view)],
        },
      },
    }).catch((e: unknown) => {
      console.log("page_view metric send failed", e);
    });
  }, [view, readyToSend, pageViewMetric, site.uuid]);

  return <>{children}</>;
};
