import type { ReactNode } from "react";
import React, { useCallback, useRef, useState } from "react";
import { uniqWith } from "lodash";

import {
  GetProfileRoleTuplesDocument,
  useGrantRolesToUserMutation,
  useRevokeRolesFromUserMutation,
} from "../../../generated/settings-client";

import type { RoleTuple } from "./RolesContext";
import { Roles } from "./RolesContext";
import { rolesEqual } from "./rolesEqual";

export const RolesProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [saving, setSaving] = useState(false);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const { current: updates } = useRef({
    toAdd: [] as RoleTuple[],
    toRemove: [] as RoleTuple[],
  });

  const [addMutation] = useGrantRolesToUserMutation();
  const [removeMutation] = useRevokeRolesFromUserMutation();

  const updateUnsavedChanges = useCallback(() => {
    setUnsavedChanges(updates.toAdd.length > 0 || updates.toRemove.length > 0);
  }, [updates.toAdd, updates.toRemove]);

  const add = (tuple: RoleTuple) => {
    // Check if it's in the remove first.
    const isRemoved = updates.toRemove.find((re) => rolesEqual(tuple, re));
    if (isRemoved != null) {
      updates.toRemove = updates.toRemove.filter((re) => !rolesEqual(tuple, re));
    } else {
      updates.toAdd = [...updates.toAdd, tuple];
    }
    updateUnsavedChanges();
  };

  const remove = (tuple: RoleTuple) => {
    // Check if it's in the add first.
    const isAdded = updates.toAdd.find((re) => rolesEqual(tuple, re));
    if (isAdded != null) {
      updates.toAdd = updates.toAdd.filter((re) => !rolesEqual(tuple, re));
    } else {
      updates.toRemove = [...updates.toRemove, tuple];
    }
    updateUnsavedChanges();
  };

  const clear = useCallback(() => {
    updates.toAdd = [];
    updates.toRemove = [];
    updateUnsavedChanges();
    setSaving(false);
  }, [updateUnsavedChanges, updates]);

  const save = useCallback(
    async (userUuid: string) => {
      try {
        setSaving(true);
        const mutations: Array<Promise<unknown>> = [];
        if (updates.toAdd.length > 0) {
          mutations.push(
            addMutation({
              variables: {
                tuples: uniqWith(updates.toAdd, rolesEqual).map((re) => ({
                  userUuid,
                  relation: re.relation,
                  id: re.resource.id,
                  type: re.resource.type,
                })),
              },
              refetchQueries: [GetProfileRoleTuplesDocument],
            }),
          );
        }

        if (updates.toRemove.length > 0) {
          mutations.push(
            removeMutation({
              variables: {
                tuples: uniqWith(updates.toRemove, rolesEqual).map((re) => ({
                  userUuid,
                  relation: re.relation,
                  id: re.resource.id,
                  type: re.resource.type,
                })),
              },
              refetchQueries: [GetProfileRoleTuplesDocument],
            }),
          );
        }

        if (mutations.length > 0) {
          await Promise.all(mutations);
        }
      } finally {
        setSaving(false);
      }
    },
    [addMutation, removeMutation, updates.toAdd, updates.toRemove],
  );

  return (
    <Roles.Provider
      value={{
        add,
        remove,
        clear,
        save,
        saving,
        unsavedChanges,
        updates,
      }}
    >
      {children}
    </Roles.Provider>
  );
};
