import React, { useContext, useState } from "react";

import { CurrentRole, Role, useSiteContext } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import { Text, Tooltip, useDebounced, useIsMobileWidth, useTheme } from "@equiem/react-admin-ui";
import { RiErrorWarningLine } from "@equiem/react-admin-ui/icons";

import { CompaniesFilterContext } from "../contexts/CompaniesFilterContext";
import { usePagedCompanyList } from "../hooks/usePagedCompanyList";
import { AddCompanyCatalogueWidget } from "../widgets/AddCompanyCatalogueWidget";
import { CompanyDetailsWidget } from "../widgets/CompanyDetailsWidget";

import { CompaniesFilter } from "./CompaniesFilter";
import { CompaniesList } from "./CompaniesList";

export const CompaniesInner = () => {
  const { t } = useTranslation();
  const { colors, breakpoints } = useTheme();
  const isMobile = useIsMobileWidth();
  const [pageSize, setPageSize] = useState(20);
  const { search, filterBuildingUuids } = useContext(CompaniesFilterContext);
  const debouncedSearch = useDebounced(search, 500);
  const { currentRole } = useContext(CurrentRole);
  const site = useSiteContext();

  const withFlexTenants = currentRole === Role.FlexManager;

  const { companies, error, loading, totalCount, refetch, fetchMore, currentPage, setCurrentPage } =
    usePagedCompanyList({
      search: debouncedSearch,
      destinationUuid: site.uuid,
      pageSize,
      buildingUuids: filterBuildingUuids,
      withFlexTenants,
    });

  return (
    <>
      <div className="main-container">
        {!isMobile && (
          <div className="d-flex align-items-center mb-5">
            <Text variant="heading" size="medium">
              {t("settings.companies")}
            </Text>
            <Tooltip title={t("settings.companiesTooltip")} placement="right" showArrow>
              <div className="ml-2">
                <RiErrorWarningLine size={16} color={colors.grayscale[60]} />
              </div>
            </Tooltip>
          </div>
        )}
        <CompaniesFilter />
        <CompaniesList
          className="mt-5"
          pageSize={pageSize}
          setPageSize={setPageSize}
          companies={companies}
          error={error}
          loading={loading}
          totalCount={totalCount}
          refetch={refetch}
          fetchMore={fetchMore}
          debouncedSearch={debouncedSearch}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          withFlexTenants={withFlexTenants}
        />
        <CompanyDetailsWidget companyListRefetchData={{ refetch, pageSize, currentPage }} />
        <AddCompanyCatalogueWidget />
      </div>
      <style jsx>{`
        .main-container {
          display: flex;
          flex-direction: column;
          padding: 32px;
        }

        @media (max-width: ${breakpoints.md}px) {
          .main-container {
            padding: 16px 0;
          }
        }
      `}</style>
    </>
  );
};
