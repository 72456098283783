import { stringNotEmpty } from "@equiem/lib";
import React from "react";

interface P {
  mode?: "adjustment" | "refund";
  name: string;
  description: string;
  amount: React.ReactNode | null;
  className?: string;
}

export const MyBookingChargeAdjustmentAndRefund: React.FC<P> = ({
  className = "",
  mode = "refund",
  name,
  description,
  amount,
}) => {
  return (
    <>
      <div className={`info ${mode} ${className}`}>
        <div className="left-cont">
          <span className="title">{name}</span>
          {stringNotEmpty(description) && <> - {description}</>}
        </div>
        {amount != null && (
          <div>
            {mode === "refund" && "-"}
            {amount}
          </div>
        )}
      </div>
      <style jsx>{`
        .info {
          display: flex;
          justify-content: space-between;
        }
        .title {
          font-weight: 500;
        }
        .left-cont {
          max-width: 80%;
          word-break: break-word;
        }
      `}</style>
    </>
  );
};
