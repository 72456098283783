import React, { createContext, useContext } from "react";

import color from "color";
import { sizes as defaultSizes } from "../util/sizes";
import { GlobalStyles } from "./GlobalStyles";

const spacers = {
  s0: "0px",
  s1: "2px",
  s2: "4px",
  s3: "8px",
  s4: "12px",
  s5: "16px",
  s6: "24px",
  s7: "32px",
  s8: "40px",
  s9: "48px",
  s10: "64px",
} as const;

const defaultContext = {
  provided: false,
  borderRadius: "5px",
  isAdmin: false as const,
  colors: {
    greyBackground: "rgba(0, 0, 0, 0.04)" as const,
    border: "#e5e5e5" as const,
    danger: "#FF2E3B",
    light: "#fafafa" as const,
    medium: "#f2f2f2",
    dark: "#000",
    white: "#fff" as const,
    info: "#01C6D2" as const,
    warning: "#FF7A00" as const,
    success: "#28AE67" as const,
    buttonBorder: "#ccc" as const,
    inputBorder: "#5B38E8",
    lightHover: "#e9ecef",
    muted0: "#999" as const,
    muted1: "#ccc" as const,
    /* Derived from the primary color */
    primary: "#999",
    primaryContrast: "#fff",
    lightContrast: "#000",
    primaryShades: {
      darker: { bg: "#888", fg: "#fff" },
    },
    grayscale_5: "#F2F2F2",
    /* new pallete */
    grayscale: {
      100: "#000",
      80: "#333333",
      60: "#666666",
      50: "#808080",
      40: "#999999",
      20: "#CCCCCC",
      10: "#E6E6E6",
      5: "#F2F2F2",
      3: "#F7F7F7",
    },
    blue: {
      100: "#3D1DBE",
      90: "#3D1DBE",
      80: "#3D1DBE",
      70: "#5B38E8",
      60: "#5B38E8",
      50: "#896FEE",
      40: "#A899E5",
      30: "#B7A7F5",
      20: "#DED7FA",
      10: "#ECE8F9",
      transparent: {
        10: "rgba(61, 29, 190, 0.1)",
        20: "rgba(61, 29, 190, 0.2)",
      },
    },
    status: {
      danger: {
        primary: "#E6000E",
        accent: "#FFE7E9",
        dark: "#BD000C",
      },
      positive: {
        primary: "#008000",
        accent: "#EDFFED",
        dark: "#005700",
      },
      warning: {
        primary: "#FF7A00",
        accent: "#FF80001A",
        dark: "#005700",
      },
    },
    transparent: {
      black: {
        5: "rgba(0, 0, 0, 0.05)",
        10: "rgba(0, 0, 0, 0.1)",
        20: "rgba(0, 0, 0, 0.2)",
        40: "rgba(0, 0, 0, 0.4)",
        50: "rgba(0, 0, 0, 0.5)",
        60: "rgba(0, 0, 0, 0.6)",
        80: "rgba(0, 0, 0, 0.8)",
      },
      white: {
        10: "rgba(255, 255, 255, 0.10)",
        15: "rgba(255, 255, 255, 0.15)",
        25: "rgba(255, 255, 255, 0.25)",
        50: "rgba(255, 255, 255, 0.5)",
        60: "rgba(255, 255, 255, 0.6)",
        80: "rgba(255, 255, 255, 0.8)",
      },
      positive: {
        5: "rgba(0, 128, 0, 0.05)",
        10: "rgba(0, 128, 0, 0.1)",
        20: "rgba(0, 128, 0, 0.2)",
        40: "rgba(0, 128, 0, 0.4)",
        50: "rgba(0, 128, 0, 0.5)",
        60: "rgba(0, 128, 0, 0.6)",
        80: "rgba(0, 128, 0, 0.8)",
      },
      danger: {
        5: "rgba(230, 0, 14, 0.05)",
        10: "rgba(230, 0, 14, 0.1)",
        20: "rgba(230, 0, 14, 0.2)",
        40: "rgba(230, 0, 14, 0.4)",
        50: "rgba(230, 0, 14, 0.5)",
        60: "rgba(230, 0, 14, 0.6)",
        80: "rgba(230, 0, 14, 0.8)",
      },
      warning: {
        5: "rgba(255, 120, 0, 0.05)",
        10: "rgba(255, 120, 0, 0.1)",
        20: "rgba(255, 120, 0, 0.2)",
        40: "rgba(255, 120, 0, 0.4)",
        50: "rgba(255, 120, 0, 0.5)",
        60: "rgba(255, 120, 0, 0.6)",
        80: "rgba(255, 120, 0, 0.8)",
      },
    },
  },
  animationDuration: ".2s",
  focusOutline: {
    size: "4px",
    colors: {
      error: "rgba(255, 46, 59, 0.2)",
      default: "rgba(48, 48, 252, 0.1)",
    },
  },
  mutedOpacity: 0.4,
  sizes: defaultSizes,
  spacers,
  zIndexes: {
    modal: 1000,
    dropdown: 1010,
    toast: 2000,
    tooltips: 1011,
  },
  breakpoints: {
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
  },
  topMenuHeight: 65,
  fontSize: "1rem",
};

const adminDefaultContext = {
  ...defaultContext,
  isAdmin: true as const,
  colors: {
    ...defaultContext.colors,
    medium: "#666666",
    dark: "#333333",
    lightHover: "#EAEAFE",
    adminBackground: "#f2f2f2" as const,
    secondary: "#eaeaff" as const,
    black: "#000000" as const,
    danger: "#E6000E",
  },
  fontSize: "14px",
};

const minContrastRatio = 2.5;

// eslint-disable-next-line @typescript-eslint/no-type-alias
export type ThemeContext = typeof defaultContext;

// eslint-disable-next-line @typescript-eslint/no-type-alias
export type AdminThemeContext = typeof adminDefaultContext;

export const ThemeContext = createContext<ThemeContext>(defaultContext);
export const AdminThemeContext = createContext<AdminThemeContext>(adminDefaultContext);

export const AdminTheme: React.FC<{
  zIndexes?: Partial<ThemeContext["zIndexes"]>;
  breakpoints?: Partial<ThemeContext["breakpoints"]>;
  useUtilityClasses?: boolean;
  useWebSafeFontOnly?: boolean;
  children?: React.ReactNode;
}> = ({
  children,
  zIndexes: zIndexesInput = adminDefaultContext.zIndexes,
  breakpoints = adminDefaultContext.breakpoints,
  useUtilityClasses = false,
  useWebSafeFontOnly = false,
}) => {
  const primaryColor = "#5B38E8";

  const white = color("#fff");
  const primary = color(primaryColor);
  const primaryIsDark = primary.contrast(white) >= minContrastRatio;
  const minSaturationForColor = 20;
  const primaryIsColor = primary.saturationl() > minSaturationForColor;
  const primaryContrastColor = primaryIsDark ? "#fff" : "#000";
  let darkPrimaryColor = color(primaryColor);

  const darkenIncrement = 0.05;
  if (!primaryIsDark) {
    while (darkPrimaryColor.contrast(white) < minContrastRatio) {
      darkPrimaryColor = darkPrimaryColor.darken(darkenIncrement);
    }
  }

  const lightContrastColor = primaryIsDark ? primaryColor : primaryIsColor ? darkPrimaryColor.hex() : "#000";

  const darkerPrimaryBg = primary.darken(0.1);
  const darkerPrimaryFg = darkerPrimaryBg.isDark() ? "#fff" : "#000";
  const zIndexes = {
    ...adminDefaultContext.zIndexes,
    ...zIndexesInput,
  };

  const theme = {
    ...adminDefaultContext,
    provided: true,
    zIndexes,
    breakpoints: {
      ...adminDefaultContext.breakpoints,
      ...breakpoints,
    },
    colors: {
      ...adminDefaultContext.colors,
      primary: primaryColor,
      primaryContrast: primaryContrastColor,
      lightContrast: lightContrastColor,
      primaryShades: {
        darker: { bg: darkerPrimaryBg.hex(), fg: darkerPrimaryFg },
      },
      adminBackground: "#f2f2f2",
    },
  } as const;

  return (
    <AdminThemeContext.Provider value={theme}>
      <GlobalStyles
        theme={theme}
        useUtilityClasses={useUtilityClasses}
        useWebSafeFontOnly={useWebSafeFontOnly}
        key={primaryColor}
      >
        {children}
      </GlobalStyles>
    </AdminThemeContext.Provider>
  );
};

export const Theme: React.FC<{
  primaryColor: string;
  zIndexes?: Partial<ThemeContext["zIndexes"]>;
  breakpoints?: Partial<ThemeContext["breakpoints"]>;
  useUtilityClasses?: boolean;
  useWebSafeFontOnly?: boolean;
  children?: React.ReactNode;
}> = ({
  children,
  primaryColor = "#000",
  zIndexes: zIndexesInput = defaultContext.zIndexes,
  breakpoints = defaultContext.breakpoints,
  useUtilityClasses = false,
  useWebSafeFontOnly = false,
}) => {
  const white = color("#fff");
  const primary = color(primaryColor);
  const primaryIsDark = primary.contrast(white) >= minContrastRatio;
  const minSaturationForColor = 20;
  const primaryIsColor = primary.saturationl() > minSaturationForColor;
  const primaryContrastColor = primaryIsDark ? "#fff" : "#000";
  let darkPrimaryColor = color(primaryColor);

  const darkenIncrement = 0.05;
  if (!primaryIsDark) {
    while (darkPrimaryColor.contrast(white) < minContrastRatio) {
      darkPrimaryColor = darkPrimaryColor.darken(darkenIncrement);
    }
  }

  const lightContrastColor = primaryIsDark ? primaryColor : primaryIsColor ? darkPrimaryColor.hex() : "#000";

  const darkerPrimaryBg = primary.darken(0.1);
  const darkerPrimaryFg = darkerPrimaryBg.isDark() ? "#fff" : "#000";
  const zIndexes = {
    ...defaultContext.zIndexes,
    ...zIndexesInput,
  };

  const theme = {
    ...defaultContext,
    provided: true,
    zIndexes,
    breakpoints: {
      ...defaultContext.breakpoints,
      ...breakpoints,
    },
    colors: {
      ...defaultContext.colors,
      primary: primaryColor,
      primaryContrast: primaryContrastColor,
      lightContrast: lightContrastColor,
      primaryShades: {
        darker: { bg: darkerPrimaryBg.hex(), fg: darkerPrimaryFg },
      },
    },
  } as const;

  return (
    <ThemeContext.Provider value={theme}>
      <GlobalStyles
        theme={theme}
        useUtilityClasses={useUtilityClasses}
        useWebSafeFontOnly={useWebSafeFontOnly}
        key={primaryColor}
      >
        {children}
      </GlobalStyles>
    </ThemeContext.Provider>
  );
};

export const useTheme = <T extends boolean>(isAdmin?: T): T extends true ? AdminThemeContext : ThemeContext => {
  const admin = useContext(AdminThemeContext);
  const endUser = useContext(ThemeContext);

  if (admin.provided) {
    return admin as AdminThemeContext & ThemeContext;
  }

  if (isAdmin === true) {
    throw new Error("AdminThemeContext not provided");
  }

  if (!endUser.provided) {
    throw new Error("ThemeContext not provided");
  }

  return endUser as AdminThemeContext & ThemeContext; // Typescript :S
};
