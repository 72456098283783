import { Dropdown } from "@equiem/react-admin-ui";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import type { BookingFormValue } from "../models/BookingFormValue";
import { useFormikContext } from "formik";
import { BookingRecurringModal } from "./BookingRecurringModal";
import type { RecurringSettings } from "../models/RecurringSettings";
import { RecurringType } from "../models/RecurringSettings";
import { formatters, useTranslation } from "@equiem/localisation-eq1";
import { stringIsEmpty } from "@equiem/lib";
import { formatWeekdayByNum } from "../../../lib/formatWeekday";
import { BookingModalInfo } from "../contexts/BookingModalInfoProvider";
import type { WeekdayNumbers } from "luxon";

const changes = (date: string, start?: string, end?: string) => `${date}|${start}|${end}`;

const MIN_REPEAT_EVERY_MODE = 1;

export const BookingFormRecurring: React.FC = () => {
  const { resource } = useContext(BookingModalInfo);
  const { setFieldValue, values } = useFormikContext<BookingFormValue>();
  const [original, setOriginal] = useState(changes(values.date, values.start, values.end));
  const [show, setShow] = useState(false);
  const { i18n, t } = useTranslation();

  const onRecurringTypeChangedFromForm = useCallback(
    (value?: RecurringType) => {
      void setFieldValue("recurringSettings", undefined, false);
      if (value == null) {
        void setFieldValue("recurringType", undefined, false);
      } else {
        void setFieldValue("recurringType", value, false).then(() => setShow(true));
      }
    },
    [setFieldValue],
  );

  // We want to restart the recurring to don't repeat when date changed.
  useEffect(() => {
    if (!resource.allowRecurringBooking) {
      return;
    }

    const value = changes(values.date, values.start, values.end);
    if (original === value) {
      return;
    }

    setOriginal(value);
    if (values.recurringType != null || values.recurringSettings != null) {
      onRecurringTypeChangedFromForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.date, values.start, values.end]);

  const recurringWithRepeatTimes = useCallback(
    (recurringSettings: RecurringSettings) => {
      if (recurringSettings.recurringType === RecurringType.Weekly) {
        return t("bookings.repeat.settingsSelectorDropdownRepeatEveryTitleWeeks", {
          count: recurringSettings.repeatEvery,
          days: formatters.list(
            recurringSettings.repeatOn.map((x: WeekdayNumbers) => formatWeekdayByNum(x, i18n.language, "short")),
            i18n.language,
          ),
          recurringEndDate: formatters.dateshort(recurringSettings.end, i18n.language),
        });
      }

      if (recurringSettings.recurringType === RecurringType.Daily) {
        return t("bookings.repeat.settingsSelectorDropdownRepeatEveryTitleDays", {
          count: recurringSettings.repeatEvery,
          recurringEndDate: formatters.dateshort(recurringSettings.end, i18n.language),
        });
      }

      return t("bookings.repeat.settingsSelectorDropdownRepeatEveryTitleMonths", {
        count: recurringSettings.repeatEvery,
        recurringEndDate: formatters.dateshort(recurringSettings.end, i18n.language),
      });
    },
    [i18n.language, t],
  );

  const defaultTitle = useCallback(
    (recurringSettings: RecurringSettings) => {
      if (recurringSettings.recurringType === RecurringType.Weekly) {
        return t("bookings.repeat.settingsSelectorDropdownTitleWeekly", {
          repeatType: t("common.repeatWeekly"),
          days: formatters.list(
            recurringSettings.repeatOn.map((x: WeekdayNumbers) => formatWeekdayByNum(x, i18n.language, "short")),
            i18n.language,
          ),
          recurringEndDate: formatters.dateshort(recurringSettings.end, i18n.language),
        });
      }

      return t("bookings.repeat.settingsSelectorDropdownTitle", {
        repeatType:
          recurringSettings.recurringType === RecurringType.Daily ? t("common.repeatDaily") : t("common.repeatMonthly"),
        recurringEndDate: formatters.dateshort(recurringSettings.end, i18n.language),
      });
    },
    [i18n.language, t],
  );

  const recurringTitle = useMemo(() => {
    if (values.recurringType == null || values.recurringSettings?.recurringType == null) {
      return t("common.repeatNone");
    }

    if (values.recurringSettings.repeatEvery > MIN_REPEAT_EVERY_MODE) {
      return recurringWithRepeatTimes(values.recurringSettings);
    }

    return defaultTitle(values.recurringSettings);
  }, [defaultTitle, recurringWithRepeatTimes, t, values.recurringSettings, values.recurringType]);

  return resource.allowRecurringBooking ? (
    <>
      <div className="pb-4">
        <Dropdown.Button
          className="recurring-options"
          size="sm"
          title={recurringTitle}
          disabled={stringIsEmpty(values.date) || stringIsEmpty(values.start) || stringIsEmpty(values.end)}
          variant="ghost"
        >
          <Dropdown.Item
            className="repeat-none"
            selected={values.recurringSettings?.recurringType == null}
            onClick={() => onRecurringTypeChangedFromForm()}
          >
            {t("common.repeatNone")}
          </Dropdown.Item>
          <Dropdown.Item className="repeat-daily" onClick={() => onRecurringTypeChangedFromForm(RecurringType.Daily)}>
            {t("common.repeatDaily")}
          </Dropdown.Item>
          <Dropdown.Item className="repeat-weekly" onClick={() => onRecurringTypeChangedFromForm(RecurringType.Weekly)}>
            {t("common.repeatWeekly")}
          </Dropdown.Item>
          <Dropdown.Item
            className="repeat-monthly"
            onClick={() => onRecurringTypeChangedFromForm(RecurringType.Monthly)}
          >
            {t("common.repeatMonthly")}
          </Dropdown.Item>
          {values.recurringSettings != null ? (
            <Dropdown.Item className="repeat-current" selected={true} onClick={() => setShow(true)}>
              {recurringTitle}
            </Dropdown.Item>
          ) : null}
        </Dropdown.Button>
      </div>
      <BookingRecurringModal onHide={() => setShow(false)} showModal={show} />
    </>
  ) : null;
};
