import React from "react";

export interface Props {
  size?: number;
  className?: string;
}

const defaultWidth = 226;
const defaultHeight = 120;
const aspectRatio = defaultHeight / defaultWidth;

export const NoneHereYet: React.FC<Props> = ({ size: width = defaultWidth, className }) => {
  const height = width * aspectRatio;

  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox={`0 0 ${defaultWidth} ${defaultHeight}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect y="24" width="72" height="72" rx="4" fill="#E6E6E6" />
      <rect x="154" y="20" width="72" height="80" rx="4" fill="#E6E6E6" />
      <rect x="54" width="120" height="120" rx="4" fill="#808080" />
      <rect x="70" y="84" width="72" height="9" fill="#CCCCCC" />
      <rect x="70" y="98" width="40" height="6" fill="#999999" />
    </svg>
  );
};

NoneHereYet.displayName = "NoneHereYet";
