import type { FC } from "react";
import React from "react";
import { SideModalProvider } from "../../contexts/SideModalProvider";
import { MembersPageInner } from "../../components/members/MembersPageInner";
import { useTheme } from "@equiem/react-admin-ui";

// Members page is a Flex Manager view of all the Flex Tenants (Members) that they can manage.
export const MembersPage: FC = () => {
  const { colors } = useTheme();

  return (
    <div className="cont">
      <SideModalProvider>
        <MembersPageInner />
      </SideModalProvider>
      <style jsx>{`
        .cont {
          background: ${colors.white};
          min-height: calc(100vh - 65px);
        }
      `}</style>
    </div>
  );
};
