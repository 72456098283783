import { ApolloProvider } from "@apollo/react-hoc";
import type { ApolloClient } from "@apollo/client";
import type { PropsWithChildren } from "react";
import React, { useContext, useCallback, useMemo } from "react";
import type { SessionState } from "../clients/gatewayUtils";
import { getAuthenticatedIris } from "../clients/irisGateway";
import { getAuthenticatedGateway } from "../clients/regionGateway";
import type { AuthenticationContext, SessionContext } from "./SessionContext";
import { Session } from "./SessionContext";
import { useTranslation } from "@equiem/localisation-eq1";
import { useCachedLocalePreference } from "../hooks/useCachedLocalePreference";
import { PageContext } from "./PageContext";

interface Props {
  authState: AuthenticationContext;
  endpoint: string;
  globalClient: ApolloClient<object>;
  globalAuthenticate: (force?: boolean, getTokenFromPostMessage?: boolean) => Promise<SessionState>;
}

export const SessionProvider: React.FC<PropsWithChildren<Props>> = ({
  children,
  authState,
  endpoint,
  globalClient,
  globalAuthenticate,
}) => {
  const { i18n, ready: translationsReady } = useTranslation();
  const page = useContext(PageContext);
  const getTokenFromPostMessage = page.usingPostMessageAuth;

  const cachedLocalePreference = useCachedLocalePreference();
  const lang = useMemo(
    () => (translationsReady ? i18n.language : cachedLocalePreference ?? i18n.language),
    [cachedLocalePreference, i18n.language, translationsReady],
  );

  const { client: authenticatedClient, authenticate } = useMemo(
    () => getAuthenticatedGateway(endpoint, getTokenFromPostMessage, lang),
    [lang, endpoint, getTokenFromPostMessage],
  );

  const { client: irisClient, authenticate: irisAuthenticate } = getAuthenticatedIris(
    process.env.irisCortexUrl ?? "",
    getTokenFromPostMessage,
  );

  const refresh = useCallback(async () => {
    await authenticate(true, getTokenFromPostMessage);
    await irisAuthenticate(true, getTokenFromPostMessage);
    await globalAuthenticate(true, getTokenFromPostMessage);
  }, [authenticate, getTokenFromPostMessage, irisAuthenticate, globalAuthenticate]);

  const session: SessionContext =
    typeof window === "undefined"
      ? {
          ...authState,
          side: "server" as const,
        }
      : {
          ...authState,
          side: "client",
          authenticatedClient,
          irisClient: {
            cortex: irisClient,
          },
          globalClient,
          refresh,
        };

  return (
    <ApolloProvider client={authenticatedClient}>
      <Session.Provider value={session}>{children}</Session.Provider>
    </ApolloProvider>
  );
};
