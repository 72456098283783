import { stringNotEmpty } from "@equiem/lib";
import type { FormikErrors } from "formik";
import { DateTime } from "luxon";
import type { FormValues } from "./FormValues";
import type { TFunction } from "@equiem/localisation-eq1";

export const DATE_FORMAT = "yyyy-MM-dd";
export const TIME_FORMAT = "HH:mm";
export const DATETIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;

export const getStartTime = (timezone: string, startDate: string, startTime: string) =>
  stringNotEmpty(startTime)
    ? DateTime.fromFormat(`${startDate} ${startTime}`, DATETIME_FORMAT, { zone: timezone })
    : DateTime.fromFormat(`${startDate}`, DATE_FORMAT, { zone: timezone }).startOf("day");

export const getEndTime = (timezone: string, endDate: string, endTime: string) =>
  stringNotEmpty(endTime)
    ? DateTime.fromFormat(`${endDate} ${endTime}`, DATETIME_FORMAT, { zone: timezone })
    : DateTime.fromFormat(`${endDate}`, DATE_FORMAT, { zone: timezone }).endOf("day");

export const validateAddForm =
  (timezone: string, t: TFunction) =>
  (values: FormValues): FormikErrors<FormValues> => {
    const errors: FormikErrors<FormValues> = {};
    if (!stringNotEmpty(values.title)) {
      errors.title = t("bookings.settings.pleaseFillTheTitle");
    }
    if (!stringNotEmpty(values.startDate)) {
      errors.startDate = t("bookings.settings.pleaseSelectADate");
    }
    if (!stringNotEmpty(values.endDate)) {
      errors.endDate = t("bookings.settings.pleaseSelectADate");
    }
    if (values.resources.length === 0) {
      errors.resources = t("bookings.settings.pleaseSelectAResource");
    }
    if (stringNotEmpty(values.startDate) && stringNotEmpty(values.endDate)) {
      const start = getStartTime(timezone, values.startDate, values.startTime);
      const end = getEndTime(timezone, values.endDate, values.endTime);

      if (!start.isValid) {
        errors.startDate = t("bookings.settings.pleaseEnterAValidDate");
        errors.startTime = t("bookings.settings.pleaseEnterAValidTime");
      }
      if (!end.isValid) {
        errors.endDate = t("bookings.settings.pleaseEnterAValidDate");
        errors.endTime = t("bookings.settings.pleaseEnterAValidTime");
      }
      if (start.isValid && end.isValid && start >= end) {
        errors.startDate = t("bookings.settings.startShouldBeBeforeEndDateAndTime");
      }
    }

    return errors;
  };
