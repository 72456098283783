import type { FC } from "react";
import React from "react";
import type { BaseFieldProps } from "./BaseUploader";
import { BaseUploader } from "./BaseUploader";

interface Props extends BaseFieldProps {
  onUploading: (uploading: boolean) => void;
}

export const ImageUploader: FC<Props> = (props) => {
  return <BaseUploader {...props} showImages={true} accept=".png,.jpg,.jpeg,.gif" />;
};
