import { BookableResourceAddOnType } from "../../../generated/gateway-client";
import type {
  BookableResourceAddOnFragmentFragment,
  BookingAddOnFragmentFragment,
} from "../../../generated/gateway-client";
import type { AddOnFreeText, BookingFormValue, AddOnCheckboxOrRadio } from "../models/BookingFormValue";
import { stringNotEmpty } from "@equiem/lib";

// Convert booking addOns to initial value.
export const convertBookingAddOnsToInputAddOns = (
  resourceAddOns: BookableResourceAddOnFragmentFragment[],
  bookingAddOns: BookingAddOnFragmentFragment[],
): BookingFormValue["addOns"] => {
  if (bookingAddOns.length === 0 || resourceAddOns.length === 0) {
    return [];
  }

  return resourceAddOns.reduce<BookingFormValue["addOns"]>((prev, resourceAddOn) => {
    const selectedBookingAddOn: BookingAddOnFragmentFragment | undefined = bookingAddOns.find(
      (ba) => ba.addOnUuid === resourceAddOn.uuid,
    );
    if (selectedBookingAddOn == null) {
      return prev.concat([undefined]);
    }

    switch (resourceAddOn.type) {
      case BookableResourceAddOnType.FreeText: {
        const item: AddOnFreeText = { value: selectedBookingAddOn.description ?? "" };

        return prev.concat([stringNotEmpty(item.value) ? item : undefined]);
      }

      case BookableResourceAddOnType.SingleChoice: {
        if (selectedBookingAddOn.options.length === 0) {
          return prev;
        }
        const option = selectedBookingAddOn.options[0];
        if (option.optionUuid == null) {
          return prev;
        }

        const item: AddOnCheckboxOrRadio = {
          [option.optionUuid]: { option: option.optionUuid, quantity: option.quantity ?? null, type: "radio" },
        };
        return prev.concat([item]);
      }

      case BookableResourceAddOnType.SingleOption:
      case BookableResourceAddOnType.MultiOption: {
        if (selectedBookingAddOn.options.length === 0) {
          return prev;
        }
        const result = selectedBookingAddOn.options.reduce<NonNullable<AddOnCheckboxOrRadio>>((prevOption, option) => {
          return option.optionUuid == null
            ? prevOption
            : {
                ...prevOption,
                [option.optionUuid]: {
                  option: option.optionUuid,
                  quantity: option.quantity ?? null,
                  type: "checkbox",
                },
              };
        }, {});

        return prev.concat([result]);
      }

      default:
        return prev;
    }
  }, []);
};
