import type { CreditTransactionDetail } from "../../../generated/gateway-client";
import { CreditSubAccountType } from "../../../generated/gateway-client";

const MAXIMUM_TRANSACTION_DETAILS = 2;

// Maximum elemnts in the array is MAXIMUM_TRANSACTION_DETAILS
export const getTranscationDetailsByGroup = (
  transactionDetails: CreditTransactionDetail[],
): { latest?: CreditTransactionDetail; additional?: CreditTransactionDetail } => {
  if (transactionDetails.length < MAXIMUM_TRANSACTION_DETAILS) {
    return {
      latest:
        transactionDetails[0].subAccount.type === CreditSubAccountType.MonthlyRecurring
          ? transactionDetails[0]
          : undefined,
      additional:
        transactionDetails[0].subAccount.type === CreditSubAccountType.Purchased ? transactionDetails[0] : undefined,
    };
  }
  const latest = transactionDetails.find(
    (transactionDetail) => transactionDetail.subAccount.type === CreditSubAccountType.MonthlyRecurring,
  );
  const additional = transactionDetails.find(
    (transactionDetail) => transactionDetail.subAccount.type === CreditSubAccountType.Purchased,
  );

  return {
    latest: latest ?? transactionDetails[0],
    additional,
  };
};
