import React from "react";

import { BookableResourceStatus } from "../../../generated/gateway-client";
import type { TFunction } from "@equiem/localisation-eq1";
import { formatters, useTranslation } from "@equiem/localisation-eq1";

interface Props {
  status: BookableResourceStatus;
  deletedAfter: number | null | undefined;
  timezone: string;
  className?: string;
}

interface ResourceStatusTextProps extends Props {
  language: string;
  t: TFunction;
}

export const resourceStatusText = ({ status, deletedAfter, timezone, language, t }: ResourceStatusTextProps) => {
  const resourceStatusMapLocales: Record<BookableResourceStatus, string> = {
    DELETED: t("bookings.resources.deleted"),
    DRAFT: t("bookings.resources.draft"),
    PENDING_DELETION: t("bookings.resources.pendingDeletion"),
    PUBLISHED: t("bookings.resources.published"),
  };

  let statusText = "";

  if (resourceStatusMapLocales[status] != null) {
    statusText = resourceStatusMapLocales[status];
  } else {
    statusText = status.replace(/_/gu, " ");
  }

  return (
    statusText +
    (status === BookableResourceStatus.PendingDeletion
      ? ` (${formatters.dateshort(deletedAfter ?? Date.now(), language, { timezone })})`
      : "")
  );
};

export const ResourceStatus: React.FC<Props> = ({ className = "", ...props }) => {
  const { i18n, t } = useTranslation();
  const statusText = resourceStatusText({ ...props, language: i18n.language, t });
  return (
    <>
      <div className={`resource-status ${props.status.toLowerCase()} ${className}`}>
        <span>{statusText}</span>
      </div>
      <style jsx>{`
        .resource-status {
          text-transform: uppercase;
          font-size: 10px;
          font-weight: 600;
        }
        .resource-status span {
          border-radius: 2px;
          display: inline-block;
          padding: 0.3rem 0.5rem;
        }
        .resource-status.published span {
          background: #ecfdf3;
          color: #027a48;
        }
        .resource-status.draft span {
          background: #f2f2f2;
          color: #808080;
        }
        .resource-status.pending_deletion span {
          background: #ffe7e9;
          color: #bd000c;
        }
      `}</style>
    </>
  );
};
