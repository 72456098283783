import { useRouter } from "next/router";
import React, { useContext, useState } from "react";
import type { TermsAcceptedForDestinationQuery } from "../generated/gateway-client";
import { TermsAcceptedForDestinationDocument, useAcceptTermsForDestinationMutation } from "../generated/gateway-client";
import { Site, useShowError, useSiteContext, useViewerTokens } from "@equiem/lib";
import { Terms } from "../contexts/TermsContext";
import { Button, EscapedHtmlDiv, Modal, ProgressCircle, Toggler, useTheme, useToast } from "@equiem/react-admin-ui";
import { useTranslation } from "@equiem/localisation-eq1";

export const LegalCheck: React.FC = () => {
  const { termsAccepted } = useContext(Terms);
  const site = useContext(Site);
  const router = useRouter();
  const toast = useToast();
  const { spacers } = useTheme();
  const { t } = useTranslation();
  const { privacyPolicy, termsAndConditions } = useSiteContext();
  const tokenReplacer = useViewerTokens();
  const [declining, setDeclining] = useState(false);
  const [mutation, { loading }] = useAcceptTermsForDestinationMutation({
    variables: { input: { destination: site.uuid } },
  });
  const showError = useShowError();

  const onDecline = () => {
    setDeclining(true);
    router.push("/api/logout").catch((e) => {
      console.error(e);
    });
  };

  const onAccept = async () => {
    try {
      const mutationResult = await mutation({
        update: (cache, result) => {
          if (result.data?.acceptTermsForDestination !== true) {
            return;
          }

          cache.writeQuery<TermsAcceptedForDestinationQuery>({
            data: { viewer: { currentDestination: { termsAccepted: true } } },
            query: TermsAcceptedForDestinationDocument,
          });
        },
      });

      if (mutationResult.data?.acceptTermsForDestination === false) {
        toast.negative(t("common.unknownErrorPleaseTryAgain"));
      }
    } catch (err: unknown) {
      showError(err);
    }
  };

  return (
    <Modal.Dialog
      title={t("common.termsAndConditionsAndPrivacyPolicy")}
      key="legal-modal"
      size="md"
      show={termsAccepted != null ? !termsAccepted : false}
      scrollable={true}
      centered={true}
    >
      <Modal.Header intro={t("common.termsUpdatedHeader")} />
      <Modal.Body>
        <div className="pt-2 container mb-6">
          <p className="my-0">{t("common.termsUpdatedBody")}</p>
          {termsAndConditions && (
            <Toggler heading={t("common.termsAndConditions")}>
              <EscapedHtmlDiv html={tokenReplacer(termsAndConditions)} />
            </Toggler>
          )}
          {privacyPolicy && (
            <Toggler heading={t("common.privacyPolicy")}>
              <EscapedHtmlDiv html={tokenReplacer(privacyPolicy)} />
            </Toggler>
          )}
        </div>
        <style jsx>{`
          .container {
            max-height: 400px;
            overflow-y: auto;
          }
          :global(h4) {
            font-size: 14px;
            line-height: 30px;
          }
          :global(.toggler) {
            margin-top: ${spacers.s5};
          }
          :global(.toggler .open) {
            overflow-y: auto;
          }
        `}</style>
      </Modal.Body>
      <Modal.Footer>
        <Button className="mr-4" variant="ghost" onClick={onDecline} disabled={declining || loading}>
          {declining ? (
            <span className="mr-1">
              <ProgressCircle size={18} />
            </span>
          ) : null}
          {t("common.decline")}
        </Button>
        <Button onClick={onAccept} disabled={declining || loading} className="terms-accept-button">
          {loading ? (
            <span className="mr-1">
              <ProgressCircle size={18} />
            </span>
          ) : null}
          {t("common.accept")}
        </Button>
      </Modal.Footer>
    </Modal.Dialog>
  );
};
