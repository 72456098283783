import React, { useContext, useEffect, useMemo, useState } from "react";
import { difference, uniq } from "lodash";

import { useShowError, useSiteContext } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import { Button, Dropdown, Form, ProgressCircle, Text, useConfirmer, useTheme, useToast } from "@equiem/react-admin-ui";
import { RiSearchLine, RiUserFollowLine, RiUserUnfollowLine } from "@equiem/react-admin-ui/icons";

import { useBulkUpdateUsersStatusMutation, useSiteGroupsQuery } from "../../../generated/settings-client";
import { UsersFilterContext } from "../contexts/UsersFilterContext";

import type { FullUser } from "./UserList";

interface Props {
  selectedUsers: FullUser[];
}

type GroupItem = {
  label: string;
  value: string;
};

export const UserListBulkHeader: React.FC<Props> = ({ selectedUsers }) => {
  const { colors } = useTheme();
  const toast = useToast();
  const { t } = useTranslation();
  const site = useSiteContext();
  const { withConfirmation } = useConfirmer();
  const showError = useShowError();
  const [groupsSearch, setGroupsSearch] = useState("");
  const [selectedGroups, setSelectedGroups] = useState<string[]>([]);
  const [indeterminateGroups, setIndeterminateGroups] = useState<string[]>([]);
  const [unselectedGroups, setUnselectedSiteGroups] = useState<string[]>([]);
  const [selectedGroupsUpdated, setSelectedGroupsUpdated] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [bulkUsersUpdate] = useBulkUpdateUsersStatusMutation();
  const { selectedTab } = useContext(UsersFilterContext);
  const userUuids = uniq(selectedUsers.map(({ uuid }) => uuid));

  const { data: groups } = useSiteGroupsQuery({
    variables: {
      destinationUuid: site.uuid,
    },
  });

  // if all selected user has a group, preselect that group in assign groups dropdown
  useEffect(() => {
    const siteGroups = groups?.siteGroups.map(({ uuid }) => uuid) ?? [];
    const preSelectedGroups = siteGroups.filter((uuid) =>
      selectedUsers.every((user) => {
        const groupUuids = user.profile?.siteProfiles.edges.flatMap((siteProfile) => siteProfile.node?.groups);
        return groupUuids?.some((r) => r?.uuid === uuid);
      }),
    );
    setSelectedGroups(preSelectedGroups);
    setIndeterminateGroups(
      difference(siteGroups, preSelectedGroups).filter((uuid) =>
        selectedUsers.some((user) => {
          const groupUuids = user.profile?.siteProfiles.edges.flatMap((siteProfile) => siteProfile.node?.groups);
          return groupUuids?.some((r) => r?.uuid === uuid);
        }),
      ),
    );
    setSelectedGroupsUpdated(false);
  }, [groups?.siteGroups, selectedUsers]);

  useEffect(() => {
    const siteGroups = groups?.siteGroups.map(({ uuid }) => uuid) ?? [];
    setUnselectedSiteGroups(difference(siteGroups, selectedGroups, indeterminateGroups));
  }, [groups?.siteGroups, selectedGroups, indeterminateGroups]);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setGroupsSearch(e.target.value);
  };

  const handleChange = (option: GroupItem) => () => {
    if (selectedGroups.includes(option.value)) {
      setSelectedGroups(selectedGroups.filter((r) => r !== option.value));
    } else {
      setSelectedGroups([...selectedGroups, option.value]);
    }
    setIndeterminateGroups(indeterminateGroups.filter((r) => r !== option.value));
    setSelectedGroupsUpdated(true);
  };

  const filteredOptions: GroupItem[] = useMemo(
    () =>
      groups?.siteGroups
        .filter((r) => r.name.toLowerCase().includes(groupsSearch.toLowerCase()))
        .map((group) => ({
          label: group.name,
          value: group.uuid,
        })) ?? [],
    [groups, groupsSearch],
  );

  const handleApply = () => {
    setIsSubmitting(true);

    bulkUsersUpdate({
      variables: {
        userUuids,
        destinationUuid: site.uuid,
        groupUuidsToAdd: selectedGroups,
        groupUuidsToRemove: unselectedGroups,
      },
      refetchQueries: ["UserList", "SiteGroups"],
    })
      .then((result) => {
        const count = result.data?.updateUsersForDestination.updatedCount ?? 0;
        if (count > 0) {
          toast.positive(t("settings.updateRolesSuccess", { count }));
        }

        if (
          result.data?.updateUsersForDestination.errors != null &&
          result.data.updateUsersForDestination.errors.length > 0
        ) {
          showError(result.data.updateUsersForDestination.errors);
        }
      })
      .catch(showError)
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const handleActive = (active: boolean) => {
    return withConfirmation({
      title: active ? t("settings.activateUsers") : t("settings.deactivateUsers"),
      message: active
        ? t("settings.activateUsersConfirm", { count: selectedUsers.length })
        : t("settings.deactivateUsersConfirm", { count: selectedUsers.length }),
      confirmButtonText: active ? t("settings.activate") : t("settings.deactivate"),
      cancelButtonText: t("common.cancel"),
      confirmButtonVariant: active ? "primary" : "danger",
      onConfirm: () => {
        void bulkUsersUpdate({
          variables: {
            userUuids,
            destinationUuid: site.uuid,
            active,
          },
          refetchQueries: ["UserList"],
        }).then((result) => {
          if (active) {
            toast.positive(
              t("settings.activateUsersSuccess", { count: result.data?.updateUsersForDestination.updatedCount ?? 0 }),
            );
          } else {
            toast.positive(
              t("settings.deactivateUsersSuccess", { count: result.data?.updateUsersForDestination.updatedCount ?? 0 }),
            );
          }
        });
      },
    });
  };

  return (
    <div className="bulk-headers">
      <Dropdown.Button variant="ghost" size="sm" title={t("settings.assignGroups")}>
        <Form.InputGroup.Group className="search-input">
          <Form.InputGroup.Prefix>
            <RiSearchLine color={colors.muted1} size={16} />
          </Form.InputGroup.Prefix>
          <Form.Input placeholder={`${t("common.search")}...`} autoComplete="off" onChange={handleSearch} type="text" />
        </Form.InputGroup.Group>
        <div className="options-list">
          {filteredOptions.map((option) => (
            <Text variant="text" component="label" size="small" key={option.value}>
              <div className={selectedGroups.includes(option.value) ? "option option-active" : "option"} role="option">
                <Form.Checkbox
                  label=""
                  onChange={handleChange(option)}
                  checked={selectedGroups.includes(option.value)}
                  indeterminate={indeterminateGroups.includes(option.value)}
                />
                {option.label}
              </div>
            </Text>
          ))}
        </div>
        <div className="button-container">
          <Button variant="primary" size="sm" onClick={handleApply} disabled={!selectedGroupsUpdated || isSubmitting}>
            {isSubmitting && <ProgressCircle size="xs" className="mr-2" />}
            {!isSubmitting && t("common.applyChanges")}
          </Button>
        </div>
      </Dropdown.Button>
      <Dropdown.Button variant="ghost" size="sm" title={t("settings.editStatus")}>
        {selectedTab !== "ACTIVE" && (
          <Dropdown.Item onClick={handleActive(true)}>
            <div className="action-item">
              <RiUserFollowLine color={colors.blue[60]} size={16} />
              <Text variant="navigation">{t("settings.activateUsers")}</Text>
            </div>
          </Dropdown.Item>
        )}
        {selectedTab !== "DEACTIVATED" && (
          <Dropdown.Item onClick={handleActive(false)}>
            <div className="action-item">
              <RiUserUnfollowLine color={colors.status.danger.primary} size={16} />
              <Text variant="navigation">{t("settings.deactivateUsers")}</Text>
            </div>
          </Dropdown.Item>
        )}
      </Dropdown.Button>
      <style jsx>{`
        .bulk-headers {
          display: flex;
          align-items: center;
          gap: 8px;
        }

        .bulk-headers :global(.search-input) {
          margin: 0 0.5rem;
        }

        .button-container {
          position: absolute;
          right: 0;
          left: 0;
          bottom: 0;
          padding: 0.5rem 0;
          z-index: 1000;
          background-color: white;
          display: grid;
        }

        .options-list {
          display: flex;
          flex-direction: column;
          gap: 0.125rem;
          padding: 0.5rem 0.5rem 40px 0.5rem;
        }

        .action-item {
          display: flex;
          align-items: center;
          gap: 0.5rem;
        }

        .option {
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 0.5rem;
        }

        .option-active {
          background-color: ${colors.blue[10]};
          border-radius: 0.25rem;
        }
      `}</style>
    </div>
  );
};
