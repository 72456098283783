import { ImageCarousel } from "@equiem/lib";
import { useFormikContext } from "formik";
import React from "react";
import type { FormValues } from "../../../../../lib/formValidation";

export const ResourceCreateAndEditFormPreviewGallery: React.FC = () => {
  const { values } = useFormikContext<FormValues>();

  if (values.images.length === 0) {
    return <></>;
  }

  const images = values.images.map((img) => img.url).filter((img) => img !== undefined);

  return (
    <div>
      <ImageCarousel images={images as string[]} />
    </div>
  );
};
