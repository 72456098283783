import type { PropsWithChildren } from "react";
import React, { createContext, useState } from "react";

export type FormStep = "initial" | "payment";

export interface BookingFormContext {
  step: FormStep;
  setStep: (display: FormStep) => void;
}

export const BookingFormContext = createContext<BookingFormContext>({
  step: "initial",
  setStep: () => undefined,
});

export const BookingFormProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [step, setStep] = useState<FormStep>("initial");

  return <BookingFormContext.Provider value={{ step, setStep }}>{children}</BookingFormContext.Provider>;
};
