import { useTranslation } from "@equiem/localisation-eq1";
import { useTheme, useWindowDimensions } from "@equiem/react-admin-ui";
import { DragEvent, FC } from "react";

const prevent = (e: DragEvent<HTMLDivElement>) => {
  e.preventDefault();
  e.stopPropagation();
};

export const Dropzone: FC<{
  dragover: boolean;
  uploading: boolean;
  setDragover: (dragover: boolean) => void;
  onFileChange: (files: FileList) => Promise<void>;
  progress: number;
  accept?: string;
}> = ({ dragover, uploading, setDragover, onFileChange, progress, accept = "image/png,image/jpeg,image/gif" }) => {
  const { t } = useTranslation();

  const { breakpoints } = useTheme();
  const { width } = useWindowDimensions();

  const isMobile = width <= breakpoints.md;

  return (
    <div
      className={`uploader d-flex align-items-center justify-content-center ${dragover ? "dragover" : ""} ${
        uploading ? "uploading" : ""
      }`}
      onDrag={prevent}
      onDragStart={prevent}
      onDragOver={(e) => {
        prevent(e);
        setDragover(true);
      }}
      onDragEnter={(e) => {
        prevent(e);
        setDragover(true);
      }}
      onDragEnd={(e) => {
        prevent(e);
        setDragover(false);
      }}
      onDragLeave={(e) => {
        prevent(e);
        setDragover(false);
      }}
      onDrop={(e) => {
        prevent(e);
        setDragover(false);
        onFileChange(e.dataTransfer.files);
      }}
    >
      <input
        type="file"
        accept={accept}
        className="input"
        onChange={(e) => {
          if (e.target.files != null) {
            onFileChange(e.target.files);
          }
        }}
      />
      <div className="text-center">
        {!progress && (
          <p>
            {!isMobile && (
              <span className="text-muted">
                {accept.includes("pdf") ? t("uploader.dragAndDropFile") : t("uploader.dragAndDrop")}{" "}
              </span>
            )}
            <u>{t("uploader.clickToUpload")}</u>
          </p>
        )}
      </div>
      <style jsx>{`
        .uploader {
          background: #f2f2f2;
          min-height: 100px;
          border: dashed 1px #ced4da;
          border-radius: 4px;
          overflow: hidden;
          position: relative;
        }

        .dragover {
          border: dashed 1px green;
        }

        .uploading {
          border: solid 1px green;
        }

        .input {
          cursor: pointer;
          position: absolute;
          z-index: 2;
          width: 100%;
          height: 100%;
          opacity: 0;
        }

        .loading {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 2rem;
        }
      `}</style>
    </div>
  );
};
