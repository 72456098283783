import { Form, ProgressCircle } from "@equiem/react-admin-ui";
import type { FC } from "react";
import { useCallback, useContext, useMemo } from "react";
import type { Invite } from "./NewInvitesContext";
import { NewInvites } from "./NewInvitesContext";
import { useRoles } from "./hooks/useRoles";
import { useTranslation } from "@equiem/localisation-eq1";
import { Site, stringNotEmpty } from "@equiem/lib";

export const InviteRoleWidget: FC<{ invite: Invite; disabled: boolean }> = ({ invite, disabled }) => {
  const { updateRoles } = useContext(NewInvites);
  const { uuid } = useContext(Site);
  const { roles: options, loading } = useRoles({
    granteeCompanyUuids: stringNotEmpty(invite.companyUuid) ? [invite.companyUuid] : [],
    siteUuid: uuid,
  });
  const { t } = useTranslation();

  const onChange = useCallback(
    (e: { target: { value: string[] } }) => {
      const tuples = e.target.value.flatMap((v) => {
        const option = options.find((o) => o.value === v);
        return option == null ? [] : [option.tuple];
      });

      updateRoles(invite.id, tuples);
    },
    [updateRoles, invite.id, options],
  );

  const getValue = useMemo(() => {
    if (options.length > 0) {
      return options
        .filter(({ tuple }) =>
          invite.roles.find((r) => r.id === tuple.id && r.relation === tuple.relation && r.type === tuple.type),
        )
        .map((opt) => opt.value);
    } else {
      return [];
    }
  }, [options, invite.roles]);

  return (
    <Form.MultiSelect
      size="small"
      placeholder={
        <>
          <span>{t("home.widgets.addRoles")}</span>
          {loading && (
            <span>
              <ProgressCircle mode="indeterminate" className="ml-2" size="xs" />
            </span>
          )}
        </>
      }
      disabled={disabled || loading || options.length === 0}
      options={options}
      value={getValue}
      onChange={onChange}
      className="role-selector"
      facade="input-no-border"
      variant="wrap"
    />
  );
};
