import type { ReactNode } from "react";
import React from "react";

export const SrText: React.FC<{ children: ReactNode }> = ({ children }) => (
  <span>
    {children}
    <style jsx>{`
      span {
        position: absolute;
        width: 1px;
        height: 1px;
        padding: 0;
        margin: -1px;
        overflow: hidden;
        clip: rect(0, 0, 0, 0);
        white-space: nowrap;
        border: 0;
      }
    `}</style>
  </span>
);
