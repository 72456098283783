import type { DefaultOptions } from "@apollo/client";
import { ApolloClient, HttpLink, InMemoryCache } from "@apollo/client";
import { authenticate, dataIdFromObject, errorLink, headers, withAuth } from "./gatewayUtils";

const defaultOptions: DefaultOptions = {
  query: { errorPolicy: "all" },
  watchQuery: { errorPolicy: "all" },
};

const authenticatedGateway: Record<string, ApolloClient<object> | undefined> = {};

export const getAuthenticatedIris = (url: string, getTokenFromPostMessage: boolean) => {
  // eslint-disable-next-line no-return-assign
  return {
    authenticate,
    client:
      authenticatedGateway[url] ??
      (authenticatedGateway[url] = new ApolloClient({
        name: "equiem-one-ui",
        cache: new InMemoryCache({
          dataIdFromObject,
        }),
        link: errorLink.concat(
          new HttpLink({
            uri: url,
            fetch: withAuth(fetch, getTokenFromPostMessage),
            headers,
          }),
        ),
        defaultOptions,
      })),
  };
};
