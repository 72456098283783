import { useCallback, useEffect, useRef, useState } from "react";

const DEBOUNCE_TIMEOUT = 200;

export const useIntersectionObserver = () => {
  const observerRefs = useRef<Array<IntersectionObserver | null>>([]);
  const [loadedResources, setLoadedResources] = useState<Record<string, boolean>>({});

  const handleIntersect = useCallback(
    (uuid: string) => {
      if (!loadedResources[uuid]) {
        setLoadedResources((prevState) => ({ ...prevState, [uuid]: true }));
      }
    },
    [loadedResources],
  );

  const createObserver = useCallback(
    (uuid: string) => {
      let timeout: NodeJS.Timeout;

      return (node: Element | null) => {
        if (node !== null) {
          const observer = new IntersectionObserver(
            ([entry]) => {
              if (entry.isIntersecting) {
                clearTimeout(timeout);
                timeout = setTimeout(() => {
                  handleIntersect(uuid);
                  observer.disconnect();
                }, DEBOUNCE_TIMEOUT);
              }
            },
            {
              root: null,
              rootMargin: "0px",
              threshold: 0.1,
            },
          );
          observer.observe(node);
          observerRefs.current.push(observer);
        }
      };
    },
    [handleIntersect],
  );

  useEffect(() => {
    const currentObservers = observerRefs.current;

    return () => {
      currentObservers.forEach((observer) => observer?.disconnect());
    };
  }, []);

  return { createObserver, loadedResources, setLoadedResources };
};
