import type { ReactNode } from "react";
import React, { useState } from "react";

export type AppointmentModal =
  | "AddVisitor"
  | "BulkUploadVisitors"
  | "DeleteAppointment"
  | "DeleteReception"
  | "AppointmentCreated"
  | "AppointmentRecurringUpdate"
  | "AppointmentUpdated"
  | "ChangeHost"
  | "CancelForm"
  | "QuickViewAppointment"
  | "ReceptionNotes"
  | "VisitorActivity"
  | "ReceptionList"
  | "CardIdStatus"
  | "CloseWalkin"
  | "AddVisitorInformation"
  | "VisitorResendCode"
  | "AppointmentRecurringSettings";

export interface ModalContext {
  activeModal?: AppointmentModal;
  id?: string;
  options?: unknown;
  open: (type: AppointmentModal, id?: string, opt?: unknown) => void;
  close: () => void;
}

/* istanbul ignore next */
export const Modal = React.createContext<ModalContext>({
  open: (_type: AppointmentModal) => undefined,
  close: () => undefined,
  options: {},
});

export const ModalProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [activeModal, setActiveModal] = useState<AppointmentModal>();
  const [id, setId] = useState<string>();
  const [options, setOptions] = useState<unknown>();

  const open = (type: AppointmentModal, _id?: string, opt?: unknown) => {
    setActiveModal(type);
    setId(_id);
    setOptions(opt);
  };

  const close = () => {
    setActiveModal(undefined);
    setId(undefined);
  };

  return (
    <Modal.Provider
      value={{
        open,
        close,
        activeModal,
        options,
        id,
      }}
    >
      {children}
    </Modal.Provider>
  );
};
