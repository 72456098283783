import { useIsMobileWidth, useTheme } from "@equiem/react-admin-ui";
import type { ComponentProps } from "react";
import React, { forwardRef } from "react";

interface Props extends ComponentProps<"div"> {
  padding?: boolean;
}

export const MainContainer = forwardRef<HTMLDivElement, Props>(
  ({ children, className, padding = true, ...props }, ref) => {
    const isMobile = useIsMobileWidth("sm");
    const { breakpoints } = useTheme();
    const mobilePadding = isMobile ? "px-5" : "px-7";
    const paddingClass = padding ? mobilePadding : "";

    return (
      <div ref={ref} className={`main-container ${className} ${paddingClass}`} {...props}>
        {children}
        <style jsx>{`
          @media (max-width: ${breakpoints.md}px) {
            .main-container {
              max-width: 100%;
              overflow: auto;
            }
          }
        `}</style>
      </div>
    );
  },
);

MainContainer.displayName = "MainContainer";
