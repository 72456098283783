import React from "react";
import { TimeDisplay, toTimeString } from "./TimeDisplay";

interface Props {
  start: number;
  end: number;
  language: string;
  timezone?: string;
}

export const toTimeRangeString = ({ start, end, timezone, language }: Props) => {
  const startString = toTimeString({ datetime: start, timezone, language });
  const endString = toTimeString({ datetime: end, timezone, language });
  return `${startString} - ${endString}`;
};

export const TimeRange: React.FC<Props> = ({ start, end, timezone, language }) => {
  return (
    <>
      <TimeDisplay datetime={start} timezone={timezone} language={language} /> -{" "}
      <TimeDisplay datetime={end} timezone={timezone} language={language} />
    </>
  );
};
