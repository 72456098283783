import { useSiteContext } from "@equiem/lib";
import { formatters, useTranslation } from "@equiem/localisation-eq1";
import { Alert, Button, DateTime as DT, Form, Table, Text, useTheme } from "@equiem/react-admin-ui";
import { RiCloseLine, RiEditLine, RiErrorWarningLine } from "@equiem/react-admin-ui/icons";
import { Field, useFormikContext } from "formik";
import { DateTime } from "luxon";
import React, { useCallback, useEffect, useState } from "react";
import { ResourceCreateAndEditFormAvailabilityModal } from "./ResourceCreateAndEditFormAvailabilityModal";
import { ResourceDivider } from "../ResourceDivider";
import type {
  BookableResourceAvailability,
  BookableResourceFlexibleAvailability,
  BookableResourceSlotsAvailability,
} from "../../../../../generated/gateway-client";
import { useGetBuildingQuery } from "../../../../../generated/gateway-client";
import { AvailabilityType, type FormValues } from "../../../../../lib/formValidation";
import { groupWeekday } from "../../../../../lib/formatWeekday";

enum Units {
  HOURS = "hours",
  DAYS = "days",
}

interface TimeWithUnits {
  value: number;
  unit: Units;
}

const MINUTES_IN_HOUR = 60;
const MINUTES_IN_DAY = 1440;

const HOURLY_UPPER_LIMIT = 23.75;
const DAILY_UPPER_LIMIT = 999;
const HOURLY_STEP = 0.25;
const DAILY_STEP = 1;

const MAX_SLOTS_NUMBER = 10;

const getTimeWithUnits = (minutes: number): TimeWithUnits => {
  let value: number;
  let unit: Units;
  if (minutes > 0 && minutes % MINUTES_IN_DAY === 0) {
    value = minutes / MINUTES_IN_DAY;
    unit = Units.DAYS;
  } else {
    value = minutes / MINUTES_IN_HOUR;
    unit = Units.HOURS;
  }
  return { value, unit };
};

const getTimeInMinutes = (time: TimeWithUnits): number => {
  return time.value * (time.unit === Units.DAYS ? MINUTES_IN_DAY : MINUTES_IN_HOUR);
};

const strToTs = (time: string, zone: string) => {
  return DateTime.fromFormat(time, "HH:mm", { zone }).toMillis();
};

const getWindowUpperLimit = (units: Units) => {
  return units === Units.HOURS ? HOURLY_UPPER_LIMIT : DAILY_UPPER_LIMIT;
};

const getWindowStep = (units: Units) => {
  return units === Units.HOURS ? HOURLY_STEP : DAILY_STEP;
};

export const ResourceCreateAndEditFormAvailability: React.FC = () => {
  const { i18n, t } = useTranslation();
  const site = useSiteContext();
  const fm = useFormikContext<FormValues>();
  const [showModal, setShowModal] = useState(false);
  const [modalIndex, setModalIndex] = useState<number | null>(null);
  const { breakpoints, colors, spacers } = useTheme(true);

  const { data } = useGetBuildingQuery({
    variables: { uuid: fm.values.building },
    skip: fm.values.building === "",
  });

  const timezone = data?.buildingPublic?.timezone ?? site.timezone;

  const [bookingWindowMin, setBookingWindowMin] = useState<TimeWithUnits>(
    getTimeWithUnits(fm.values.bookingWindowMinInMinutes ?? 0),
  );
  const [bookingWindowMax, setBookingWindowMax] = useState<TimeWithUnits>(
    getTimeWithUnits(fm.values.bookingWindowMaxInMinutes ?? 0),
  );

  useEffect(() => {
    fm.setFieldValue("bookingWindowMinInMinutes", getTimeInMinutes(bookingWindowMin)).catch(console.error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fm.setFieldValue, bookingWindowMin]);
  useEffect(() => {
    fm.setFieldValue("bookingWindowMaxInMinutes", getTimeInMinutes(bookingWindowMax)).catch(console.error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fm.setFieldValue, bookingWindowMax]);

  const addAvailability = useCallback(
    (availability: BookableResourceAvailability) => {
      fm.setFieldValue("availability", [...fm.values.availability, availability]).catch(console.error);

      setShowModal(false);
    },
    [fm],
  );

  const editAvailability = useCallback(
    (availability: BookableResourceAvailability, index: number) => {
      const newAvailability = [...fm.values.availability];
      newAvailability[index] = availability;
      fm.setFieldValue("availability", newAvailability).catch(console.error);

      setShowModal(false);
      setModalIndex(null);
    },
    [fm],
  );

  const removeAvailability = useCallback(
    (index: number) => {
      const newAvailability = [...fm.values.availability];
      newAvailability.splice(index, 1);
      fm.setFieldValue("availability", newAvailability).catch(console.error);
    },
    [fm],
  );

  return (
    <>
      <div className="form-group-container">
        <Form.Group error={fm.errors.start} label={t("bookings.resources.startDate")}>
          <Field id="start" name="start" as={Form.Input} type="date" max="9999-12-31" disabled={fm.isSubmitting} />
        </Form.Group>
        <Form.Group error={fm.errors.end} label={t("bookings.resources.endDate")}>
          <Field id="end" name="end" as={Form.Input} type="date" max="9999-12-31" disabled={fm.isSubmitting} />
        </Form.Group>
      </div>

      <ResourceDivider />

      <div className="form-group-container">
        <Form.Group
          error={fm.errors.prepTimeBeforeInMinutes}
          label={t("bookings.resources.preparationTimeBefore")}
          showTooltip
          tooltipText={t("bookings.resources.preparationTimeHint")}
        >
          <div className="form-field-with-units">
            <div>
              <Field
                id="prepTimeBeforeInMinutes"
                name="prepTimeBeforeInMinutes"
                as={Form.Input}
                min={0}
                max={999}
                type="number"
                disabled={fm.isSubmitting}
              />
            </div>
            <div>
              <Text variant="text" size="small" className="mx-5">
                {t("common.minutes")}
              </Text>
            </div>
          </div>
        </Form.Group>
        <Form.Group
          error={fm.errors.prepTimeAfterInMinutes}
          label={t("bookings.resources.preparationTimeAfter")}
          showTooltip
          tooltipText={t("bookings.resources.preparationTimeHint")}
        >
          <div className="form-field-with-units">
            <div>
              <Field
                id="prepTimeAfterInMinutes"
                name="prepTimeAfterInMinutes"
                as={Form.Input}
                min={0}
                max={999}
                type="number"
                disabled={fm.isSubmitting}
              />
            </div>
            <div>
              <Text variant="text" size="small" className="mx-5">
                {t("common.minutes")}
              </Text>
            </div>
          </div>
        </Form.Group>
      </div>

      <div className="form-group-container">
        <Form.Group
          error={fm.errors.bookingWindowMinInMinutes}
          label={t("bookings.resources.minimumTimeBeforeStartOfBooking")}
          showTooltip
          tooltipText={t("bookings.resources.bookingWindowMinHintV2")}
        >
          <Field id="bookingWindowMinInMinutes" name="bookingWindowMinInMinutes">
            {() => (
              <div className="form-field-with-units">
                <div>
                  <Form.Input
                    name="bookingWindowMinValue"
                    min={0}
                    max={getWindowUpperLimit(bookingWindowMin.unit)}
                    step={getWindowStep(bookingWindowMin.unit)}
                    type="number"
                    disabled={fm.isSubmitting}
                    value={bookingWindowMin.value}
                    onChange={(e) => {
                      const newValue = parseFloat(e.target.value);
                      setBookingWindowMin({ value: !isNaN(newValue) ? newValue : 0, unit: bookingWindowMin.unit });
                    }}
                    onBlur={(e) => {
                      const step = getWindowStep(bookingWindowMin.unit);
                      const newValue = Math.round(parseFloat(e.target.value) / step) * step;
                      setBookingWindowMin({
                        value: !isNaN(newValue) ? newValue : 0,
                        unit: bookingWindowMin.unit,
                      });
                    }}
                  />
                </div>
                <div>
                  <Form.Select
                    name="bookingWindowMinUnit"
                    disabled={fm.isSubmitting}
                    value={bookingWindowMin.unit}
                    onChange={(e) => {
                      const newUnit = e.target.value as Units;
                      const upperLimit = getWindowUpperLimit(newUnit);
                      const step = getWindowStep(newUnit);
                      const newValue = Math.round(bookingWindowMin.value / step) * step;
                      setBookingWindowMin({ value: newValue > upperLimit ? upperLimit : newValue, unit: newUnit });
                    }}
                  >
                    <option value={Units.HOURS}>{t("common.hours")}</option>
                    <option value={Units.DAYS}>{t("common.days")}</option>
                  </Form.Select>
                </div>
              </div>
            )}
          </Field>
        </Form.Group>

        <Form.Group
          error={fm.errors.bookingWindowMaxInMinutes}
          label={t("bookings.resources.maximumTimeBeforeEndOfBooking")}
          showTooltip
          tooltipText={t("bookings.resources.bookingWindowMaxHintV2")}
        >
          <Field id="bookingWindowMaxInMinutes" name="bookingWindowMaxInMinutes">
            {() => (
              <div className="form-field-with-units">
                <div>
                  <Form.Input
                    name="bookingWindowMaxValue"
                    min={0}
                    max={getWindowUpperLimit(bookingWindowMax.unit)}
                    step={getWindowStep(bookingWindowMax.unit)}
                    type="number"
                    disabled={fm.isSubmitting}
                    value={bookingWindowMax.value}
                    onChange={(e) => {
                      const newValue = parseFloat(e.target.value);
                      setBookingWindowMax({ value: !isNaN(newValue) ? newValue : 0, unit: bookingWindowMax.unit });
                    }}
                    onBlur={(e) => {
                      const step = getWindowStep(bookingWindowMax.unit);
                      const newValue = Math.round(parseFloat(e.target.value) / step) * step;
                      setBookingWindowMax({
                        value: !isNaN(newValue) ? newValue : 0,
                        unit: bookingWindowMax.unit,
                      });
                    }}
                  />
                </div>
                <div>
                  <Form.Select
                    name="bookingWindowMaxUnit"
                    disabled={fm.isSubmitting}
                    value={bookingWindowMax.unit}
                    onChange={(e) => {
                      const newUnit = e.target.value as Units;
                      const upperLimit = getWindowUpperLimit(newUnit);
                      const step = getWindowStep(newUnit);
                      const newValue = Math.round(bookingWindowMax.value / step) * step;
                      setBookingWindowMax({ value: newValue > upperLimit ? upperLimit : newValue, unit: newUnit });
                    }}
                  >
                    <option value={Units.HOURS}>{t("common.hours")}</option>
                    <option value={Units.DAYS}>{t("common.days")}</option>
                  </Form.Select>
                </div>
              </div>
            )}
          </Field>
        </Form.Group>
      </div>

      <ResourceDivider />

      <Form.Group label={t("bookings.resources.bookingType")}>
        <div className="availability-type-container">
          <div className="availability-type-radio">
            <Field
              label={t("bookings.resources.slots")}
              component={Form.EnclosedRadioButton}
              id={AvailabilityType.slots}
              name="availabilityType"
              value={fm.values.availabilityType === AvailabilityType.slots}
              description={t("bookings.resources.slotsHint")}
              disabled={fm.isSubmitting || fm.values.availability.length > 0}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                if (e.currentTarget.checked) {
                  fm.setFieldValue("availabilityType", AvailabilityType.slots).catch(console.error);
                }
              }}
            />
          </div>
          <div className="availability-type-radio">
            <Field
              label={t("bookings.resources.flexibleBooking")}
              component={Form.EnclosedRadioButton}
              id={AvailabilityType.flexible}
              name="availabilityType"
              value={fm.values.availabilityType === AvailabilityType.flexible}
              description={t("bookings.resources.flexibleBookingHint")}
              disabled={fm.isSubmitting || fm.values.availability.length > 0}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                if (e.currentTarget.checked) {
                  fm.setFieldValue("availabilityType", AvailabilityType.flexible).catch(console.error);
                }
              }}
            />
          </div>
        </div>
      </Form.Group>

      {fm.values.availability.length === 0 && (
        <Alert
          size="large"
          variant="gray"
          icon={<RiErrorWarningLine size={18} color={colors.grayscale[50]} />}
          message={t("bookings.resources.timeSlotsAlert")}
        />
      )}

      <Form.Group label={t("bookings.resources.timeSlot")} required>
        <div className="availability-table-container">
          {fm.values.availability.length > 0 && (
            <Table.Table className="w-100">
              <thead>
                <tr>
                  <Table.Header label={t("common.days")} />
                  <Table.Header label={t("common.hours")} />
                  <Table.Header
                    label={
                      fm.values.availabilityType === AvailabilityType.slots
                        ? t("bookings.resources.durationType")
                        : t("bookings.operations.bookingTime")
                    }
                  />
                  <Table.Header label="" />
                  <Table.Header label="" />
                </tr>
              </thead>
              <tbody>
                {fm.values.availability.map((availability, i) => (
                  <tr key={i}>
                    <td>
                      <div className="d-flex flex-column">
                        <Text variant="text" component="span" size="small">
                          {groupWeekday(availability.days, i18n.language)}
                        </Text>
                        <Text
                          variant="text"
                          component="span"
                          size="extra-small"
                          style={{ color: colors.grayscale[60] }}
                        >
                          {availability.title}
                        </Text>
                      </div>
                    </td>
                    <td>
                      <DT.TimeRange
                        language={i18n.language}
                        start={strToTs(availability.start, timezone)}
                        end={strToTs(availability.end, timezone)}
                        timezone={timezone}
                      />
                    </td>
                    <td>
                      {fm.values.availabilityType === AvailabilityType.slots && (
                        <Text variant="text" component="span" size="small">
                          {(() => {
                            const slotsAvailability = availability as BookableResourceSlotsAvailability;
                            return slotsAvailability.isFullSession
                              ? t("bookings.resources.fullSession")
                              : `${t("bookings.resources.customSession")} - ${formatters.durationshort(
                                  slotsAvailability.durationInMinutes,
                                  i18n.language,
                                )}`;
                          })()}
                        </Text>
                      )}
                      {fm.values.availabilityType === AvailabilityType.flexible &&
                        (() => {
                          const flexibleAvailability = availability as BookableResourceFlexibleAvailability;
                          return (
                            <div className="d-flex flex-column">
                              {flexibleAvailability.minTimeInMinutes != null &&
                                flexibleAvailability.minTimeInMinutes > 0 && (
                                  <Text variant="text" component="span" size="small">
                                    {t("common.min")}:{" "}
                                    {formatters.durationshort(
                                      Number(flexibleAvailability.minTimeInMinutes),
                                      i18n.language,
                                    )}
                                  </Text>
                                )}
                              {flexibleAvailability.maxTimeInMinutes != null &&
                                flexibleAvailability.maxTimeInMinutes > 0 && (
                                  <Text variant="text" component="span" size="small">
                                    {t("common.max")}:{" "}
                                    {formatters.durationshort(
                                      Number(flexibleAvailability.maxTimeInMinutes),
                                      i18n.language,
                                    )}
                                  </Text>
                                )}
                            </div>
                          );
                        })()}
                    </td>
                    <td>
                      <RiEditLine
                        size="16"
                        color={colors.primary}
                        cursor="pointer"
                        onClick={() => {
                          setModalIndex(i);
                          setShowModal(true);
                        }}
                      />
                    </td>
                    <td>
                      <RiCloseLine
                        size="16"
                        color={colors.danger}
                        cursor="pointer"
                        onClick={() => removeAvailability(i)}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table.Table>
          )}

          {fm.values.availability.length < MAX_SLOTS_NUMBER && (
            <Button
              type="button"
              variant="secondary"
              size="lg"
              className="add-availability-hours-button"
              onClick={() => setShowModal(true)}
            >
              {t("bookings.resources.addHours")}
            </Button>
          )}
        </div>
      </Form.Group>

      <ResourceCreateAndEditFormAvailabilityModal
        showModal={showModal}
        setShowModal={setShowModal}
        modalIndex={modalIndex}
        setModalIndex={setModalIndex}
        availability={modalIndex != null ? fm.values.availability[modalIndex] : undefined}
        availabilityType={fm.values.availabilityType}
        onAdd={addAvailability}
        onEdit={editAvailability}
      />

      <style jsx>{`
        .form-group-container {
          display: flex;
          gap: ${spacers.s5};
        }
        .form-group-container :global(.form-group) {
          max-width: 49% !important;
        }
        .form-group-container :global(.form-header label) {
          text-wrap: wrap;
          line-height: 14px;
        }
        .form-field-with-units {
          display: flex;
          gap: ${spacers.s2};
        }
        .form-field-with-units > * {
          flex: 1;
        }
        .availability-type-container {
          display: flex;
          gap: ${spacers.s3};
        }
        .availability-type-radio {
          flex: 1;
        }
        .availability-type-radio :global(label) {
          width: 100%;
          height: 100%;
        }
        .availability-type-container :global(h1) {
          text-overflow: ellipsis;
          text-wrap: wrap;
        }
        .availability-type-container :global(p) {
          text-wrap: wrap;
        }
        .availability-table-container {
          display: flex;
          flex-direction: column;
          gap: ${spacers.s7};
        }
        @media screen and (max-width: ${breakpoints.md}px) {
          .form-group-container {
            flex-direction: column;
          }
          .form-group-container :global(.form-group) {
            max-width: unset !important;
          }
          .availability-type-container {
            flex-direction: column;
          }
          .availability-table-container {
            gap: ${spacers.s5};
          }
          .availability-table-container :global(th),
          .availability-table-container :global(td) {
            padding: ${spacers.s3};
          }
          .add-availability-hours-button {
            width: "100%";
          }
        }
      `}</style>
    </>
  );
};
