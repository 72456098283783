import { Button, Form } from "@equiem/react-admin-ui";
import { FieldArray } from "formik";
import React, { useCallback, useMemo, useState } from "react";
import type { BookableResourceAddOn } from "../../../../generated/gateway-client";
import { AddonFreeText } from "./AddOnFreeText";
import { AddonMultiOption } from "./AddonMultiOption";
import { AddonSingleChoice } from "./AddOnSingleChoice";
import { AddonSingleOption } from "./AddOnSingleOption";
import { RiArrowDownSLine, RiArrowUpSLine } from "@equiem/react-admin-ui/icons";
import { useTranslation } from "@equiem/localisation-eq1";

interface P {
  addOns: BookableResourceAddOn[];
  label?: string;
  hideCost?: boolean;
  enableExpander?: boolean;
  minExpanderTotal?: number;
}
export const Addons: React.FC<P> = ({
  addOns,
  label,
  hideCost = false,
  enableExpander = false,
  minExpanderTotal = 2,
}) => {
  const { t } = useTranslation();
  const showHideAndMore = useMemo(
    () => enableExpander && addOns.length > minExpanderTotal,
    [addOns.length, enableExpander, minExpanderTotal],
  );
  const [showAll, setShowAll] = useState(
    // When there is mandatory field, we should show more by default.
    addOns.some((addOn) => addOn.mandatory) ||
      // When the exander is not enabled, we should show more by default.
      !showHideAndMore,
  );
  const showLess = useCallback(() => setShowAll(false), [setShowAll]);
  const showMore = useCallback(() => setShowAll(true), [setShowAll]);
  const internalAddOns = useMemo(() => {
    return showAll ? addOns : addOns.slice(0, minExpanderTotal);
  }, [addOns, minExpanderTotal, showAll]);

  if (addOns.length === 0) {
    return null;
  }

  return (
    <>
      <Form.Group label={label} className="addons">
        <FieldArray name="addOns">
          {(arrayHelpers) => (
            <div className="add-on-list">
              {internalAddOns.map((addOn, index) => (
                <div key={addOn.uuid}>
                  <AddonSingleOption addOn={addOn} index={index} arrayHelpers={arrayHelpers} hideCost={hideCost} />
                  <AddonMultiOption addOn={addOn} index={index} arrayHelpers={arrayHelpers} hideCost={hideCost} />
                  <AddonFreeText addOn={addOn} index={index} />
                  <AddonSingleChoice addOn={addOn} index={index} arrayHelpers={arrayHelpers} hideCost={hideCost} />
                </div>
              ))}
            </div>
          )}
        </FieldArray>
        {showHideAndMore && (
          <div className="d-flex justify-content-center">
            {showAll ? (
              <Button variant="ghost" onClick={showLess}>
                {t("bookings.operations.showLess")} <RiArrowUpSLine />
              </Button>
            ) : (
              <Button variant="ghost" onClick={showMore}>
                {t("bookings.operations.showMore")} (+{addOns.length - internalAddOns.length}) <RiArrowDownSLine />
              </Button>
            )}
          </div>
        )}
      </Form.Group>
      <style jsx>{`
        .add-on-list :global(.form-header label) {
          white-space: normal;
          word-break: break-word;
        }
      `}</style>
    </>
  );
};
