import { useCallback, useEffect, useState } from "react";

export const useShift = (activate = true) => {
  const [shiftHeld, setShiftHeld] = useState(false);

  const downHandler = useCallback((ev: KeyboardEvent) => {
    if (ev.key === "Shift") {
      setShiftHeld(true);
    }
  }, []);

  const upHandler = useCallback((ev: KeyboardEvent) => {
    if (ev.key === "Shift") {
      setShiftHeld(false);
    }
  }, []);

  useEffect(() => {
    if (!activate) {
      return undefined;
    }

    window.addEventListener("keydown", downHandler);
    window.addEventListener("keyup", upHandler);

    return () => {
      window.removeEventListener("keydown", downHandler);
      window.removeEventListener("keyup", upHandler);
    };
  }, [activate, downHandler, upHandler]);

  return { shiftHeld };
};
