/* eslint-disable @typescript-eslint/no-type-alias */
import React from "react";
import { Text } from "../Text/Text";
import { SkeletonLine } from "../Skeleton/SkeletonLine/SkeletonLine";
import { RiInformationLine, RiListCheck } from "react-icons/ri";
import { useTheme } from "../../contexts";
import { Tooltip } from "../Tooltip/Tooltip";

type EmptyListSettings = {
  message?: string;
  action?: React.ReactNode;
};

interface Props {
  id: string;
  title: string;
  tooltip?: string;
  loading?: boolean;
  empty?: boolean;
  actions?: React.ReactNode;
  emptySettings?: EmptyListSettings;
  className?: string;
}

export const ColumnViewColumn: React.FC<React.PropsWithChildren<Props>> = ({ children, className, ...column }) => {
  const { colors } = useTheme();

  return (
    <>
      <div className="column" key={column.id}>
        <div className="head">
          <Text variant="label" color={colors.grayscale[60]}>
            {column.title}
          </Text>
          {column.tooltip != null && (
            <Tooltip title={column.tooltip} placement="right-start">
              <div>
                <RiInformationLine
                  size={16}
                  className="ml-2"
                  style={{
                    color: colors.medium,
                    cursor: "pointer",
                    display: "block",
                  }}
                />
              </div>
            </Tooltip>
          )}
          <div className="actions">{column.actions}</div>
        </div>
        <div className={`list ${className ?? ""}`.trim()}>
          {column.loading === true && (
            <>
              {[...(Array(5) as number[])].map((_, i) => (
                <div className="skeleton-row" key={i}>
                  <SkeletonLine height="1rem" width="100px" />
                  <SkeletonLine height="1rem" width="1rem" />
                </div>
              ))}
            </>
          )}
          {column.loading !== true && column.empty === true && column.emptySettings != null && (
            <div className="empty-container">
              <div className="icon-container">
                <RiListCheck size={24} color={colors.grayscale[40]} />
              </div>
              <Text variant="text" size="small" className="m-0">
                {column.emptySettings.message}
              </Text>
              {column.emptySettings.action}
            </div>
          )}
          {column.loading !== true && children}
        </div>
      </div>
      <style jsx>
        {`
          .column {
            display: flex;
            flex-direction: column;
            scroll-snap-align: left;
          }

          .column:not(:last-child) {
            border-right: 1px solid ${colors.grayscale[10]};
          }
          .column .head {
            display: flex;
            align-items: center;
            padding: 0.5rem 1rem;
            border-bottom: 1px solid ${colors.grayscale[10]};
            height: 3rem;
          }

          .column .head .actions {
            margin-left: auto;
          }

          .column .list {
            display: flex;
            flex-direction: column;
            padding: 0.5rem;
            overflow-y: auto;
            gap: 2px;
          }

          .empty-container {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            gap: 1rem;
            flex-direction: column;
            color: ${colors.grayscale[60]};
          }

          .empty-container .icon-container {
            padding: 1rem;
            background: ${colors.grayscale[5]};
            border-radius: 50%;
          }

          .skeleton-row {
            display: flex;
            justify-content: space-between;
            padding: 1rem 0.5rem;
          }
        `}
      </style>
    </>
  );
};
