import React from "react";
import { stringNotEmpty } from "@equiem/lib";
import { useTheme } from "@equiem/react-admin-ui";
import { useTranslation } from "@equiem/localisation-eq1";

import type { ArticleDetail } from "../util/Article";
import { FileCard } from "../util/FileCard";
import { ArticleFooterSection } from "./ArticleFooterSection";

export const ArticleDownloads: React.FC<{ downloads: ArticleDetail["downloads"] }> = ({ downloads }) => {
  const { spacers } = useTheme();
  const { t } = useTranslation();

  return (
    <>
      <ArticleFooterSection title={t("common.files")}>
        <div>
          {downloads.map(({ value }, i) => {
            if (value.__typename === "FileV2") {
              return (
                <FileCard
                  key={i}
                  name={stringNotEmpty(value.filename) ? value.filename : t("common.file")}
                  url={value.url}
                  mimeType={value.mimeType}
                  bytes={value.size}
                />
              );
            } else if (value.__typename === "ImageV2") {
              return (
                <FileCard
                  key={i}
                  name={stringNotEmpty(value.filename) ? value.filename : t("common.file")}
                  url={value.imageUrl}
                  mimeType={value.mimeType}
                  bytes={value.size}
                />
              );
            }
            return null;
          })}
        </div>
      </ArticleFooterSection>
      <style jsx>{`
        div {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          gap: ${spacers.s3};
        }
      `}</style>
    </>
  );
};
