import { formatters, useTranslation } from "@equiem/localisation-eq1";
import React from "react";

const MILLISECONDS_IN_MINUTE = 60000;

export const TimeRangeCaption: React.FC<{
  startTime: number;
  endTime: number;
  totalColumns: number;
  timezone: string;
  overflowAvailabilities?: number;
}> = ({ startTime, endTime, totalColumns, timezone, overflowAvailabilities = 0 }) => {
  const { i18n, t } = useTranslation();

  const shouldDisplay = endTime - startTime >= 14 * MILLISECONDS_IN_MINUTE;
  if (!shouldDisplay) {
    return null;
  }

  return (
    <>
      {overflowAvailabilities === 0 ? (
        <>
          {formatters.timeshort(startTime, i18n.language, { timezone })}
          {totalColumns === 1 && <>{` - ${formatters.timeshort(endTime, i18n.language, { timezone })}`}</>}
        </>
      ) : (
        <>{t("bookings.operations.availabilitiesOverflow", { count: overflowAvailabilities })}</>
      )}
    </>
  );
};
