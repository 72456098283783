import React, { useContext } from "react";
import { Session } from "@equiem/lib";

import { Widget } from "../widgets/Widget";
import { Widget as ManageUsersWidget } from "../widgets/manage_users_widget/Widget";
import { WidgetModal as InviteUserWidgetModal } from "../widgets/welcome_widget/WidgetModal";
import { WidgetModal as CreateContentsWidgetModal } from "../widgets/manage_content_widget/WidgetModal";
import { LegalCheck } from "./LegalCheck";

export const UtilityWidgets: React.FC = () => {
  const { authenticated } = useContext(Session);

  if (!authenticated) {
    return null;
  }

  // Any widget without UI (like modal only) goes here
  return (
    <div style={{ visibility: "hidden", overflow: "hidden", height: 0 }}>
      <Widget Component={InviteUserWidgetModal} />
      <Widget Component={CreateContentsWidgetModal} />
      <Widget Component={ManageUsersWidget} />
      <LegalCheck />
    </div>
  );
};
