import { useTranslation } from "@equiem/localisation-eq1";
import type { EquiemOnePostAudience } from "../generated/iris-client";

export const useModalDescription = () => {
  const { t } = useTranslation();

  const modalDescription = (selectedPostAudience: EquiemOnePostAudience, isDraft: boolean, hasPost: boolean) => {
    if (isDraft && hasPost) {
      return t("home.widgets.postEditSavedAsDraft");
    }
    if (isDraft) {
      return t("home.widgets.postSavedAsDraft");
    }
    if (selectedPostAudience === "WORKPLACE") {
      if (hasPost) {
        return t("home.widgets.postEditSavedPublished");
      }
      return t("home.widgets.postSavedPublished");
    } else {
      if (hasPost) {
        return t("home.widgets.postEditSavedSubmit");
      }
      return t("home.widgets.postSavedSubmit");
    }
  };

  return {
    modalDescription,
  };
};
