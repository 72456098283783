import type { FormValues } from "../../../../../lib/formValidation";
import { AvailabilityType } from "../../../../../lib/formValidation";
import type {
  BookableResourceFlexibleAvailability,
  BookableResourceSlotsAvailability,
} from "../../../../../generated/gateway-client";
import { convertInputNumberToNumber } from "../../../../../lib/convertNumberStringToNumber";

export const availabilityToInput = (values: FormValues) => {
  const availability = values.availability ?? [];
  if (availability.length === 0) {
    throw new Error("Please provide at least one availability.");
  }

  const flexibleAvailability =
    values.availabilityType === AvailabilityType.flexible
      ? (availability as BookableResourceFlexibleAvailability[]).map((t) => ({
          title: t.title,
          days: t.days,
          start: t.start,
          end: t.end,
          minTimeInMinutes: convertInputNumberToNumber(t.minTimeInMinutes, true),
          maxTimeInMinutes: convertInputNumberToNumber(t.maxTimeInMinutes, true),
        }))
      : [];
  const slotsAvailability =
    values.availabilityType === AvailabilityType.slots
      ? (availability as BookableResourceSlotsAvailability[]).map((t) => ({
          title: t.title,
          days: t.days,
          start: t.start,
          end: t.end,
          durationInMinutes: convertInputNumberToNumber(t.durationInMinutes) ?? 1,
          isFullSession: t.isFullSession ?? false,
        }))
      : [];

  return { flexibleAvailability, slotsAvailability };
};
