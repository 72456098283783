import { Button, useTheme } from "@equiem/react-admin-ui";
import React, { useContext } from "react";
import { BookingModalInfo } from "../contexts/BookingModalInfoProvider";
import { ResourceCardCapacity } from "../../../components/ResourceCardCapacity";
import { BookingResourceTimeFrame } from "./BookingResourceTimeFrame";
import { BookingResourceFeatures } from "./BookingResourceFeatures";
import { BookingModal } from "../contexts/BookingModalContext";
import { BookingResourceLocation } from "./BookingResourceLocation";
import { BookableResourceFeatureType } from "../../../generated/gateway-client";
import { BookingResourceRates } from "./BookingResourceRates";
import { useTranslation } from "@equiem/localisation-eq1";

interface P {
  className?: string;
}
export const BookingResourceSummary: React.FC<P> = ({ className }) => {
  const { resource, booking } = useContext(BookingModalInfo);
  const modal = useContext(BookingModal);
  const theme = useTheme(true);
  const { t } = useTranslation();

  return (
    <div className={`summary-cont ${className}`}>
      <div className="loc-and-capacity pb-4">
        <BookingResourceLocation className="location" />
        <ResourceCardCapacity className="ml-auto" capacity={resource.displayCapacity} />
      </div>
      <BookingResourceTimeFrame className="pb-4" />
      <BookingResourceRates className="pb-4" displayOne />
      <div className="pb-4 ml-6 d-flex">
        <BookingResourceFeatures
          showIconOnly={true}
          featureTypes={[BookableResourceFeatureType.RoomFeatures, BookableResourceFeatureType.SharedFacilities]}
        />
      </div>
      <Button
        variant="outline"
        size="lg"
        className="ml-3"
        disabled={modal.showExpansion != null}
        onClick={() => modal.setShowExpansion({ resource, booking })}
      >
        {t("common.moreDetails")}
      </Button>
      <style jsx>{`
        .summary-cont {
          background: ${theme.colors.grayscale_5};
          border-radius: ${theme.borderRadius};
          padding: ${theme.spacers.s5};
        }
        .loc-and-capacity {
          display: flex;
        }
        .loc-and-capacity :global(.location) {
          flex: 2;
        }
      `}</style>
    </div>
  );
};
