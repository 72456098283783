import React from "react";
import type { NextPage } from "next";

import { ModalProvider } from "./ModalContext";

export const withContexts = <P,>(Page: NextPage<P>): NextPage<P> => {
  const PageWithContext: NextPage<P> = (props) => (
    <ModalProvider>
      <Page {...(props as P & React.JSX.IntrinsicAttributes)} />
    </ModalProvider>
  );

  return PageWithContext;
};
