import { useTheme } from "@equiem/react-admin-ui";
import React, { useEffect, useState } from "react";

interface Props {
  url?: string | null;
  placeholder?: boolean;
  width?: string;
  height?: string;
  rounded?: boolean;
  className?: string;
  topFloatingEl?: React.ReactNode;
  bottomFloatingEl?: React.ReactNode;
}

const blank = "data:image/svg+xml,%3Csvg viewBox='0 0 300 200' xmlns='http://www.w3.org/2000/svg'%3E%3C/svg%3E";

export const ResouceCardImage: React.FC<Props> = ({
  rounded = true,
  url,
  placeholder = false,
  width = "auto",
  height = "auto",
  className = "",
  topFloatingEl,
  bottomFloatingEl,
}) => {
  const theme = useTheme(true);
  const [loading, setLoading] = useState(true);
  const [src, setSrc] = useState(blank);

  useEffect(() => {
    if (placeholder) {
      return;
    }

    if (url != null && src !== url) {
      const image = new Image();
      image.onload = () => {
        setSrc(url);
        setLoading(false);
      };
      image.onerror = () => {
        setLoading(false);
      };
      image.src = url;
    }
  }, [placeholder, src, url]);

  return (
    <div className={`image ${loading ? "shimmer loading" : "loaded"} ${className}`}>
      {topFloatingEl != null && <div className="topFloat">{topFloatingEl}</div>}
      {bottomFloatingEl != null && <div className="bottomFloat">{bottomFloatingEl}</div>}
      <img src={src} />
      <style jsx>{`
        .image {
          position: relative;
          flex-shrink: 0;
          width: ${width};
          height: ${height};
          border-radius: ${rounded ? theme.borderRadius : 0};
        }
        .topFloat,
        .bottomFloat {
          position: absolute;
          padding: ${theme.spacers.s3};
          z-index: 1;
          left: 0;
          width: 100%;
        }
        .topFloat {
          top: 0;
        }
        .bottomFloat {
          bottom: 0;
        }
        .image img {
          border-radius: ${rounded ? theme.borderRadius : 0};
        }
        .image.loaded {
          background: #efefef;
        }
        .image img {
          width: 100%;
          height: auto;
        }
      `}</style>
    </div>
  );
};
