import React, { forwardRef } from "react";
import type { ComponentProps } from "react";
import { useTheme } from "../../contexts";

export const AccordionListItem = forwardRef<HTMLDivElement, ComponentProps<"div">>(({ children, className }, ref) => {
  const { sizes } = useTheme();

  return (
    <div ref={ref} className={className}>
      {children}
      <style jsx>{`
        div {
          font-size: 14px;
          line-height: ${sizes.sm}px;
          padding-bottom: 5px;
        }
      `}</style>
    </div>
  );
});

AccordionListItem.displayName = "AccordionListItem";
