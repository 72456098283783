import { useEffect, useRef, useState } from "react";

export const useWidgetScroll = () => {
  const gridRef = useRef<HTMLDivElement>(null);
  const [scrollWidth, setScrollWidth] = useState<number>(0);
  const [gridWidth, setGridWidth] = useState<number>(0);
  const [scrollLeft, setScrollLeft] = useState<number>(0);

  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      setGridWidth(gridRef.current?.parentElement?.clientWidth ?? 0);
      setScrollWidth(gridRef.current?.parentElement?.scrollWidth ?? 0);
    });

    const scrollHandler = () => {
      setScrollLeft(gridRef.current?.parentElement?.scrollLeft ?? 0);
    };

    if (gridRef.current?.parentElement != null) {
      resizeObserver.observe(gridRef.current);
      gridRef.current.parentElement.addEventListener("scroll", scrollHandler);
    }

    return () => {
      if (gridRef.current != null) {
        resizeObserver.unobserve(gridRef.current);
        gridRef.current.parentElement?.removeEventListener("scroll", scrollHandler);
      }
    };
  }, []);

  const scrollWidget = (direction: "left" | "right") => {
    const grid = gridRef.current;

    if (grid != null) {
      const currentOffset = gridRef.current?.parentElement?.scrollLeft ?? 0;
      const indexDirection = direction === "left" ? -1 : 1;
      const index = Math.floor(currentOffset / grid.children[0].clientWidth) + indexDirection;
      const gap = 8;

      grid.parentElement?.scrollTo({
        left: index * grid.children[0].clientWidth + index * gap,
        behavior: "smooth",
      });
    }
  };

  const isLeftScrollPossible = scrollLeft > 0;
  const isRightScrollPossible = scrollLeft < scrollWidth - gridWidth;

  return {
    gridRef,
    scrollWidget,
    isLeftScrollPossible,
    isRightScrollPossible,
  };
};
