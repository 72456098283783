import React, { useCallback } from "react";
import { Modal, Form as EqForm, Button, useToast, ProgressCircle, remainingCharacters } from "@equiem/react-admin-ui";
import { useTranslation, useErrorTranslation } from "@equiem/localisation-eq1";
import { stringIsEmpty } from "@equiem/lib";
import getSymbolFromCurrency from "currency-symbol-map";

import type { BookingFragmentFragment } from "../../../generated/gateway-client";
import { useMakeBookingAdjustmentMutation } from "../../../generated/gateway-client";
import type { FormikErrors } from "formik";
import { Formik, Form, Field } from "formik";
import { useCurrencyCode } from "../../../hooks/useCurrency";

interface FormValues {
  reason: string;
  amount: string | number | null;
}

interface P {
  booking: BookingFragmentFragment;
  showModal: boolean;
  onHide: () => void;
}
export const BookingAdjustmentChargeModal: React.FC<P> = ({ booking, showModal, onHide }) => {
  const { t } = useTranslation();
  const { tError } = useErrorTranslation();
  const toast = useToast();
  const [mutation, { loading }] = useMakeBookingAdjustmentMutation();

  const buildingCurrency = useCurrencyCode(booking.resource.building?.uuid);
  const currencyCode = booking.currencyCode ?? buildingCurrency;
  const symbolCurrency = currencyCode === "CREDITS" ? null : getSymbolFromCurrency(currencyCode);

  const reasonLength = 50;

  const validate = useCallback(
    (values: FormValues) => {
      const errors: FormikErrors<FormValues> = {};

      if (stringIsEmpty(values.reason)) {
        errors.reason = t("common.required");
      }
      if (values.amount == null || values.amount === "") {
        errors.amount = t("common.required");
      } else {
        const amountInput = Number(values.amount);
        if (isNaN(amountInput) || amountInput <= 0) {
          errors.amount = t("common.incorrectValue");
        }
      }

      return errors;
    },
    [t],
  );

  const submit = async (values: FormValues) => {
    try {
      if (values.amount == null) {
        return;
      }

      await mutation({
        variables: {
          input: {
            booking: booking.uuid,
            // eslint-disable-next-line @typescript-eslint/no-magic-numbers
            amount: Math.round(parseFloat(Number(values.amount).toFixed(2)) * 100),
            reason: values.reason,
          },
        },
      });

      onHide();
    } catch (e: unknown) {
      console.error(e);
      toast.negative(tError(e));
    }
  };

  return (
    <Modal.Dialog
      title={t("bookings.operations.refundsAndAdditionalCharges")}
      show={showModal}
      onHide={onHide}
      hideOnEsc={true}
      centered
      scrollable
      size="md"
    >
      <Modal.Header closeButton={true} noBorder={false} intro={t("bookings.operations.addCharge")} />
      <Formik<FormValues> initialValues={{ amount: null, reason: "" }} validate={validate} onSubmit={submit}>
        {({ submitForm, errors, values }) => (
          <Form autoComplete="off">
            <Modal.Body>
              <EqForm.Group
                required
                error={errors.amount}
                className="amount"
                label={t("bookings.operations.chargeAmount")}
                style={{ width: "200px" }}
              >
                <Field
                  as={EqForm.Money}
                  currency={symbolCurrency}
                  id="amount"
                  name="amount"
                  min={0}
                  max={9999.99}
                  placeholder="0.00"
                />
              </EqForm.Group>
              <EqForm.Group
                required
                error={errors.reason}
                className="reason"
                hint={t("common.remainingCharacters", {
                  count: remainingCharacters(reasonLength, values.reason.length),
                })}
                label={t("bookings.operations.reasonForAdditionalCharge")}
              >
                <Field
                  as={EqForm.Input}
                  id="reason"
                  name="reason"
                  maxLength={reasonLength}
                  placeholder={t("bookings.operations.reasonsOfTheAdjustment")}
                />
              </EqForm.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button className="mr-2" size="md" variant="ghost" onClick={onHide}>
                {t("common.cancel")}
              </Button>
              <Button
                size="md"
                variant="primary"
                disabled={loading}
                onClick={() => {
                  submitForm().catch(console.log);
                }}
              >
                {loading && <ProgressCircle size="xs" className="mr-2" />} {t("bookings.operations.addCharge")}
              </Button>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal.Dialog>
  );
};
