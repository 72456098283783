import { useCallback, useContext, useState } from "react";
import { useRouter } from "next/router";

import { useShowError } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import { useStickyElement, useTheme, useToast } from "@equiem/react-admin-ui";

import { Modal } from "../../../contexts/ModalContext";
import type {
  VisitorForBuildingReceptionFragment,
  VisitorForReceptionFragment,
  VisitorsByAllBuildingReceptionsQuery,
  VisitorsByReceptionQuery,
} from "../../../generated/visitors-client";
import {
  useChangeVisitorStatusMutation,
  useSetCardIdMutation,
  useSetCardStatusMutation,
  VisitorStatus,
} from "../../../generated/visitors-client";
import { ReceptionContext } from "../ReceptionContext";

export const nextVisitorStatusMap = new Map<VisitorStatus, VisitorStatus>([
  [VisitorStatus.PreBooked, VisitorStatus.CheckedIn],
  [VisitorStatus.VisitorInLobby, VisitorStatus.CheckedIn],
  [VisitorStatus.CheckedIn, VisitorStatus.CheckedOut],
  [VisitorStatus.CheckedOut, VisitorStatus.CheckedIn],
]);

const routesByStatusMap = new Map<VisitorStatus, string>([
  [VisitorStatus.PreBooked, "pre_booked"],
  [VisitorStatus.CheckedIn, "checked_in"],
  [VisitorStatus.CheckedOut, "checked_out"],
]);

type Edges =
  | VisitorsByReceptionQuery["visitorsByReception"]["edges"]
  | VisitorsByAllBuildingReceptionsQuery["visitorsByAllBuildingReceptions"]["edges"];

export const useAppointmentTableData = ({
  updateEdges,
  receptionUuid,
}: {
  receptionUuid?: string;
  updateEdges: (cb: (edges: Edges) => Edges) => void;
}) => {
  const { t } = useTranslation();
  const toast = useToast();
  const showError = useShowError();
  const router = useRouter();
  const modal = useContext(Modal);
  const { enableAutomaticPassPrinting, enablePassPrinting, setPrintingVisitors, setReception } =
    useContext(ReceptionContext);
  const { spacers } = useTheme();
  const { top, ref, isStuck } = useStickyElement<HTMLTableCellElement>();

  const [changeVisitorStatusGql] = useChangeVisitorStatusMutation();
  const [setCardIdGql, { loading: setCardIdLoading }] = useSetCardIdMutation();
  const [setCardStatusGql, { loading: setCardStatusLoading }] = useSetCardStatusMutation();
  const [modalCallback, setModalCallBack] = useState<(wasReturned: boolean) => Promise<void>>();
  const [cardIds, setCardIds] = useState<Record<string, string | undefined>>({});
  const [statusUpdatingVisitors, setStatusUpdatingVisitors] = useState<Record<string, boolean | undefined>>({});
  const [cardIdUpdatingVisitors, setCardIdUpdatingVisitors] = useState<Record<string, boolean | undefined>>({});
  const [cardStatusUpdatingVisitors, setCardStatusUpdatingVisitors] = useState<Record<string, boolean | undefined>>({});
  const [selectedVisitorIds, setSelectedVisitorIds] = useState<string[]>([]);

  const nextStatusLabelMap = new Map<VisitorStatus, string>([
    [VisitorStatus.PreBooked, t("visitors.reception.statusPrebooked")],
    [VisitorStatus.CheckedIn, t("visitors.reception.statusCheckedIn")],
    [VisitorStatus.CheckedOut, t("visitors.reception.statusCheckedOut")],
  ]);

  const updateNodes = useCallback(
    (
      cb: (
        node: NonNullable<
          VisitorsByAllBuildingReceptionsQuery["visitorsByAllBuildingReceptions"]["edges"][number]["node"]
        >,
      ) => NonNullable<
        VisitorsByAllBuildingReceptionsQuery["visitorsByAllBuildingReceptions"]["edges"][number]["node"]
      >,
    ) => {
      updateEdges((edges) =>
        edges.map((edge) => ({
          ...edge,
          node: edge.node == null ? null : cb(edge.node),
        })),
      );
    },
    [updateEdges],
  );

  const handleCardIdBlur = async (cardId: string, visitorUuid: string) => {
    try {
      setCardIdUpdatingVisitors({ ...cardIdUpdatingVisitors, [visitorUuid]: true });
      const { data } = await setCardIdGql({ variables: { visitorUuid, cardId } });
      if (data?.setCardId?.uuid != null) {
        updateNodes((node) => (node.uuid !== visitorUuid ? node : { ...node, cardId }));
      }
    } catch (e: unknown) {
      showError(e);
    } finally {
      setCardIdUpdatingVisitors({ ...cardIdUpdatingVisitors, [visitorUuid]: false });
    }
  };

  const handleSetCardStatusReturned = async (visitorUuid: string, returned = true) => {
    try {
      setCardStatusUpdatingVisitors({ ...cardStatusUpdatingVisitors, [visitorUuid]: true });
      const { data } = await setCardStatusGql({ variables: { visitorUuid, returned } });
      if (data?.setCardStatus?.uuid != null) {
        updateNodes((node) => (node.uuid !== visitorUuid ? node : { ...node, cardReturned: returned }));
      }
    } catch (e: unknown) {
      showError(e);
    } finally {
      setCardStatusUpdatingVisitors({ ...cardStatusUpdatingVisitors, [visitorUuid]: false });
    }
  };

  const handleNotes = (visitor: VisitorForReceptionFragment) => () => {
    modal.open("ReceptionNotes", `${visitor.appointment.uuid}_${visitor.uuid}`);
  };

  const changeVisitorStatus = async (nextStatus: VisitorStatus, visitor: VisitorForReceptionFragment) => {
    const { uuid, firstName, lastName, status } = visitor;

    try {
      setStatusUpdatingVisitors({ ...statusUpdatingVisitors, [visitor.uuid]: true });

      const result = await changeVisitorStatusGql({
        variables: { uuid, status: nextStatus },
        update: (_cache, { data }) => {
          if (data?.changeVisitorStatus?.uuid != null) {
            updateEdges((edges) => edges.filter(({ node }) => node?.uuid !== data.changeVisitorStatus?.uuid));
          }
        },
      });

      if (result.data?.changeVisitorStatus?.uuid == null || result.errors != null) {
        throw new Error();
      }

      // eslint-disable-next-line @typescript-eslint/no-empty-function
      let toastRemoveHandler = () => {};

      const handleUndo = () => {
        toastRemoveHandler();
        void changeVisitorStatus(status, visitor);
      };

      const handleGoTo = () => {
        const url =
          receptionUuid != null
            ? `/visitor-management/receptions/${receptionUuid}/${routesByStatusMap.get(nextStatus)}`
            : `/visitor-management/building-receptions/${routesByStatusMap.get(nextStatus)}`;

        toastRemoveHandler();
        void router.push(url);
      };

      const { remove } = toast.neutral(
        t("visitors.reception.statusChanged", {
          name: `${firstName} ${lastName}`,
          status: nextStatusLabelMap.get(nextStatus)?.toLowerCase() ?? "",
        }),
        {
          primaryAction: {
            title: t("visitors.reception.goToStatus", {
              status: nextStatusLabelMap.get(nextStatus)?.toLowerCase() ?? "",
            }),
            onClick: handleGoTo,
          },
          secondaryAction: {
            title: t("common.undo"),
            onClick: handleUndo,
          },
        },
      );

      toastRemoveHandler = remove;
    } catch (e: unknown) {
      showError(e);
    } finally {
      setStatusUpdatingVisitors({ ...statusUpdatingVisitors, [visitor.uuid]: false });
    }
  };

  const handleNotify = async (visitor: VisitorForBuildingReceptionFragment) =>
    changeVisitorStatusGql({
      variables: { uuid: visitor.uuid, status: VisitorStatus.VisitorInLobby },
      update: (_cache, { data }) => {
        if (data?.changeVisitorStatus?.uuid != null) {
          updateEdges((edges) => edges.filter(({ node }) => node?.uuid !== data.changeVisitorStatus?.uuid));
        }
      },
    }).then((result) => {
      if (result.data?.changeVisitorStatus?.uuid == null || result.errors != null) {
        throw new Error();
      }
    });

  const handleAction = (visitor: VisitorForBuildingReceptionFragment) => async () => {
    const { status, cardId, cardReturned, uuid } = visitor;
    const nextStatus = nextVisitorStatusMap.get(status) ?? VisitorStatus.PreBooked;
    const isPassPrintingEnabled =
      receptionUuid != null
        ? enablePassPrinting && enableAutomaticPassPrinting
        : visitor.appointment.reception?.enablePassPrinting === true &&
          visitor.appointment.reception.enableAutomaticPassPrinting === true;

    const handleNextStep = async () => {
      await changeVisitorStatus(nextStatus, visitor);
    };

    /* istanbul ignore if */
    if (nextStatus === VisitorStatus.CheckedIn && isPassPrintingEnabled) {
      setPrintingVisitors?.([visitor]);
    }

    if (cardId != null && !cardReturned && nextStatus === VisitorStatus.CheckedOut) {
      const handleSetCardStatusReturnedCallback = async (wasReturned: boolean) => {
        if (wasReturned) {
          await handleSetCardStatusReturned(uuid);
        }
        await handleNextStep();
      };
      setModalCallBack(() => handleSetCardStatusReturnedCallback);
      modal.open("CardIdStatus");
    } else {
      await handleNextStep();
    }
  };

  return {
    handleAction,
    handleNotes,
    handleSetCardStatusReturned,
    handleCardIdBlur,
    selectedVisitorIds,
    setSelectedVisitorIds,
    cardStatusUpdatingVisitors,
    handleNotify,
    enablePassPrinting,
    setPrintingVisitors,
    spacers,
    top,
    ref,
    setReception,
    isStuck,
    setCardIdLoading,
    setCardStatusLoading,
    modalCallback,
    cardIds,
    setCardIds,
    statusUpdatingVisitors,
    cardIdUpdatingVisitors,
    router,
    modal,
  };
};
