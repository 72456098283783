import { Form } from "@equiem/react-admin-ui";
import type { FC } from "react";
import { useState, useCallback, useContext } from "react";
import { NewInvites } from "./NewInvitesContext";
import { useTranslation } from "@equiem/localisation-eq1";
import { useAllCompanies } from "@equiem/lib";

export const BulkCompanySelectionWidget: FC = () => {
  const { updateCompany, invites } = useContext(NewInvites);
  const [search, setSearch] = useState<string | undefined>(undefined);
  const { companies, loading } = useAllCompanies({ search });

  const { t } = useTranslation();

  const onChange = useCallback(
    (e: { target: { value?: string } }) => {
      invites.forEach((invite) => updateCompany(invite.id, e.target.value));
    },
    [updateCompany, invites],
  );

  return (
    <Form.DynamicSelect
      name={t("home.widgets.welcomeAssignCompany")}
      disabled={loading || companies.length === 0}
      showChrome={false}
      showSelectedOnList
      options={companies}
      search
      searchCb={async (term) => {
        setSearch(term);
        return Promise.resolve();
      }}
      value={""}
      onChange={onChange}
      facade="ghost"
      size="sm"
      className="bulk-company-selector"
      minWidth={240}
    />
  );
};
