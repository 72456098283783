import React from "react";
import { useTranslation } from "@equiem/localisation-eq1";
import { ProgressCircle } from "../ProgressCircle/ProgressCircle";
import { StickyElement } from "../Sticky/StickyElement";
import { Table } from "./Table";
import { Header } from "./Header";
import type { TablePaginationProps } from "./TablePagination";
import { TablePagination } from "./TablePagination";

export interface TableSort {
  columnIndex: number;
  direction: "asc" | "desc";
  handleSort: (index: number) => void;
}

interface Props {
  loading: boolean;
  rowHeaders: string[] | React.ReactNode[];
  overlayHeader?: React.ReactNode;
  children?: React.ReactNode;
  sort?: TableSort;
  pagination?: TablePaginationProps;
  responsive?: boolean;
  responsiveWidth?: number;
}

export const Loader: React.FC<{ loading: boolean; colSpan: number }> = ({ loading, colSpan }) => {
  const { t } = useTranslation();
  if (!loading) {
    return null;
  }
  return (
    <tr>
      <td colSpan={colSpan}>
        <div className="mt-6 mb-6 d-flex justify-content-center align-items-center">
          <ProgressCircle size="md" />
          <span className="ml-4">{`${t("common.loading")}...`}</span>
        </div>
      </td>
    </tr>
  );
};

export const StickyHeaderTable: React.FC<Props> = ({
  rowHeaders,
  overlayHeader,
  loading,
  children,
  sort,
  pagination,
  responsive,
  responsiveWidth,
}) => {
  return (
    <StickyElement<HTMLTableCellElement>>
      {({ ref, top, isStuck }) => (
        <>
          <Table className="w-100" sticky={{ top, isStuck }} responsive={responsive} responsiveWidth={responsiveWidth}>
            <thead>
              <tr>
                {rowHeaders.map((label, i) => (
                  <Header
                    key={i}
                    ref={i === 0 ? ref : null}
                    label={label}
                    direction={sort?.columnIndex === i ? sort.direction : undefined}
                    onClick={() => sort?.handleSort(i)}
                  />
                ))}
                {overlayHeader != null && <div className="overlay">{overlayHeader}</div>}
              </tr>
            </thead>
            <tbody>
              {children}
              {loading && <Loader loading={loading} colSpan={rowHeaders.length} />}
            </tbody>
          </Table>
          {pagination != null && <TablePagination {...pagination} />}
          <style jsx>
            {`
              thead {
                position: relative;
              }

              .overlay {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                z-index: 1;
                display: flex;
                align-items: center;
                background: white;
                border-top-right-radius: 8px;
                border-top-left-radius: 8px;
              }
            `}
          </style>
        </>
      )}
    </StickyElement>
  );
};
