import { stringNotEmpty } from "@equiem/lib";
import { BookableResourceAddOnType, type ExistingBookingChargesQuery } from "../../../generated/gateway-client";
import type { PartialRefunds } from "../models/PartialRefunds";
import type { TFunction } from "@equiem/localisation-eq1";

type AddOnType = NonNullable<ExistingBookingChargesQuery["siteBookingByReference"]>["addOns"];

export interface FlatBookingAddOns {
  uuid: string;
  description: string;
  amount: number;
  quantity: number;
  partialRefunds: PartialRefunds[];
}

export const flatExistingBookingAddOns = (addons: AddOnType, t: TFunction): FlatBookingAddOns[] =>
  addons.flatMap((addOn) => {
    if (addOn.addOnType === BookableResourceAddOnType.FreeText) {
      return {
        uuid: addOn.uuid,
        description: addOn.addOnName ?? addOn.description ?? "",
        amount: 0,
        quantity: 1,
        partialRefunds: [],
      };
    }

    return addOn.options.map((option) => {
      const quantity = option.hasQuantity ? option.quantity ?? 1 : 1;

      return {
        uuid: option.uuid,
        description: `${stringNotEmpty(addOn.addOnName) ? `${addOn.addOnName}: ` : ""}${option.description}`,
        amount: quantity * option.unitPrice,
        quantity,
        partialRefunds: option.partialRefunds.map((r) => ({
          uuid: r.uuid,
          reason: r.reason,
          amount: r.amount,
          user: r.user.profile?.displayName ?? t("common.unknown"),
        })),
      };
    });
  }, []);
