import type { Dispatch, SetStateAction } from "react";
import React, { createContext, useContext, useMemo, useState } from "react";
import type { CreditStatsQueryRole } from "../../../generated/gateway-client";
import {
  useFlexTenantsFiltersQuery,
  useGetCreditStatsQuery,
  useGetCreditTransactionsQuery,
} from "../../../generated/gateway-client";
import { useRoleMapping } from "../hooks/useRoleMapping";
import { CurrentRole, notNullOrUndefined, Role } from "@equiem/lib";
import { useMonthFilterOptions } from "../hooks/useMonthFilterOptions";

const RECENT_TRANSACTION_COUNT = 10;
const TENANTS_PAGE_SIZE = 50;

export const CreditDashboardContext = createContext<{
  creditsActivity?: ReturnType<typeof useGetCreditTransactionsQuery>;
  creditStats?: ReturnType<typeof useGetCreditStatsQuery>;
  currentMonth?: { label: string; value: number };
  currentTenant?: string[];
  setCurrentMonth: Dispatch<SetStateAction<{ label: string; value: number } | undefined>>;
  setCurrentTenant: Dispatch<SetStateAction<string[]>>;
  months: Array<{ label: string; value: number }>;
  tenants: Array<{ label: string; value: string }>;
  flexTenants?: ReturnType<typeof useFlexTenantsFiltersQuery>;
}>({
  creditsActivity: undefined,
  creditStats: undefined,
  currentMonth: undefined,
  currentTenant: undefined,
  setCurrentMonth: () => undefined,
  setCurrentTenant: () => undefined,
  flexTenants: undefined,
  months: [],
  tenants: [],
});

export const CreditDashboardProvider = ({ children }: React.PropsWithChildren) => {
  const role = useRoleMapping();
  const { currentRole } = useContext(CurrentRole);
  const isFlexManager = currentRole === Role.FlexManager;

  const flexTenants = useFlexTenantsFiltersQuery({
    skip: !isFlexManager,
    variables: { first: TENANTS_PAGE_SIZE },
  });

  const flexTenantsOptions = useMemo(
    () =>
      flexTenants.data?.myFlexTenants.edges
        .filter(notNullOrUndefined)
        .map(({ node }) => ({ name: node?.name as string, uuid: node?.uuid as string })) ?? [],
    [flexTenants.data?.myFlexTenants.edges],
  );

  const tenants = flexTenantsOptions.map((tenant) => ({ label: tenant.name, value: tenant.uuid }));
  const months = useMonthFilterOptions();

  const [currentMonth, setCurrentMonth] = useState(months.find((m) => m.value === 0));
  const [currentTenant, setCurrentTenant] = useState<string[]>([]);

  const creditStats = useGetCreditStatsQuery({
    variables: {
      monthOffset: currentMonth?.value ?? 0,
      as: role as unknown as CreditStatsQueryRole,
      flexTenantUuids: currentTenant.length > 0 ? currentTenant : undefined,
    },
    fetchPolicy: "network-only",
  });

  const creditsActivity = useGetCreditTransactionsQuery({
    variables: { as: role, first: RECENT_TRANSACTION_COUNT },
    fetchPolicy: "network-only",
  });

  return (
    <CreditDashboardContext.Provider
      value={{
        creditsActivity,
        creditStats,
        currentMonth,
        currentTenant,
        flexTenants,
        setCurrentMonth,
        setCurrentTenant,
        tenants,
        months,
      }}
    >
      {children}
    </CreditDashboardContext.Provider>
  );
};

export const useCreditDashboardContext = () => {
  return useContext(CreditDashboardContext);
};
