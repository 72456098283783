import { useRef, useState, useContext, useEffect } from "react";
import { StickyContext } from "./StickyContext";

export const useStickyElement = <T extends HTMLElement>({
  enabled = true,
  stack = false,
}: {
  enabled?: boolean;
  stack?: boolean;
} = {}) => {
  const ref = useRef<T>(null);
  const [isStuck, setIsStuck] = useState(false);
  const sticky = useContext(StickyContext);
  const [top, setTop] = useState(0);

  useEffect(() => {
    if (!enabled) {
      return undefined;
    }

    setTop(sticky.push(ref, { shouldStack: stack }).top);

    return () => {
      sticky.pop(ref);
    };
  }, [sticky, stack, enabled, ref]);

  useEffect(() => {
    if (!enabled) {
      return undefined;
    }

    if (ref.current != null) {
      const observer = new IntersectionObserver(
        (entries) => {
          setIsStuck(
            entries[0].rootBounds != null
              ? entries[0].boundingClientRect.y < entries[0].rootBounds.y
              : entries[0].intersectionRatio < 1,
          );
        },
        {
          threshold: 1,
          rootMargin: `${0 - (top + 1)}px 0px 0px 0px`,
          root: sticky.rootRef?.current ?? undefined,
        },
      );

      observer.observe(ref.current);

      return () => {
        observer.disconnect();
      };
    }

    return undefined;
  }, [enabled, ref, sticky.rootRef, top]);

  return { ref, top, isStuck };
};
