import type { PropsWithChildren } from "react";
import React, { useContext, useState } from "react";

import { Site } from "@equiem/lib";

import type { CafmConfigurationsBySiteUuidQuery } from "../../generated/requests-client";
import { useCafmConfigurationsBySiteUuidQuery } from "../../generated/requests-client";

type CafmIntegrationModal = "CafmIntegrationModal";

export interface CategoryCafmIntegrationModalContext {
  activeModal?: CafmIntegrationModal;
  id?: string;
  open: (type: CafmIntegrationModal, id?: string) => void;
  close: () => void;
  configurations?: CafmConfigurationsBySiteUuidQuery | undefined;
  loading: boolean;
}

export const CategoryCafmIntegrationModalContext = React.createContext<CategoryCafmIntegrationModalContext>({
  open: (_type: CafmIntegrationModal) => undefined,
  close: () => undefined,
} as CategoryCafmIntegrationModalContext);

export function useRecurringSettingsWidgetContext() {
  return React.useContext(CategoryCafmIntegrationModalContext);
}

interface Props {
  testActiveModal?: CafmIntegrationModal;
  testId?: string;
}

export const CategoryCafmIntegrationModalProvider: React.FC<PropsWithChildren<Props>> = ({
  children,
  testActiveModal,
  testId,
}) => {
  const [activeModal, setActiveModal] = useState<CafmIntegrationModal | undefined>(testActiveModal);
  const [id, setId] = useState<string | undefined>(testId);
  const { uuid: siteUuid } = useContext(Site);
  const { data, loading } = useCafmConfigurationsBySiteUuidQuery({
    variables: {
      siteUuid: siteUuid,
    },
    fetchPolicy: "network-only",
  });

  const open = (type: CafmIntegrationModal, _id?: string) => {
    setActiveModal(type);
    setId(_id);
  };

  const close = () => {
    setActiveModal(undefined);
    setId(undefined);
  };

  return (
    <CategoryCafmIntegrationModalContext.Provider
      value={{
        open,
        close,
        activeModal,
        id,
        configurations: data,
        loading: loading,
      }}
    >
      {children}
    </CategoryCafmIntegrationModalContext.Provider>
  );
};
