import { useApolloErrorTranslation } from "@equiem/localisation-eq1";
import { Skeleton, useTheme } from "@equiem/react-admin-ui";
import { RiBuilding4Fill } from "@equiem/react-admin-ui/icons";
import { useFormikContext } from "formik";
import React, { useContext } from "react";

import { ValuePills } from "../../../../../components/ValuePills";
import type { FormValues } from "../../../../../lib/formValidation";
import { ResourceCreateAndEditSiteContext } from "../ResourceCreateAndEditSiteContext";

export const ResourceCreateAndEditFormPreviewLocation: React.FC = () => {
  const { tError } = useApolloErrorTranslation();
  const { colors } = useTheme(true);

  const { values } = useFormikContext<FormValues>();

  const { viewerLocations, locationsError } = useContext(ResourceCreateAndEditSiteContext);
  const selectedSite = viewerLocations.find((s) => s.uuid === values.site);
  const selectedBuilding = selectedSite?.buildings.find((b) => b.uuid === values.building);
  const selectedLevel = (selectedBuilding?.buildingLevels ?? []).find((l) => l.uuid === values.level);

  if (locationsError != null) {
    return <div>{tError(locationsError)}</div>;
  }

  if (selectedSite == null || selectedBuilding == null) {
    return <Skeleton.Line width="100%" height="42px" />;
  }

  return (
    <div className="location-cont">
      <RiBuilding4Fill size={16} className="mr-5" />
      <ValuePills>
        <span className="site-name-pill">{selectedSite.name}</span>
        <span className="building-name-pill">{selectedBuilding.name}</span>
        {selectedLevel != null && <span className="level-name-pill">{selectedLevel.name}</span>}
        <span className="building-address-pill">{selectedBuilding.address}</span>
      </ValuePills>

      <style jsx>{`
        .location-cont {
          color: ${colors.grayscale[60]};
          font-weight: 300;
          line-height: 1.5em;
        }
      `}</style>
    </div>
  );
};
