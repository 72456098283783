import React, { useContext } from "react";
import { CurrentProfile, Role, Site, useRequestEnabled } from "@equiem/lib";
import { SideNav } from "@equiem/react-admin-ui";
import {
  RiHomeLine,
  RiSettings3Line,
  RiTeamLine,
  RiDashboardLine,
  RiQuestionnaireLine,
  RiCalendarCheckLine,
  RiWalletLine,
} from "@equiem/react-admin-ui/icons";
import { ShortcutsMenu } from "./ShortcutsMenu";
import { ShortcutItem } from "../ShortcutItem";
import { useTranslation } from "@equiem/localisation-eq1";
import { useBookingsEnabled } from "@equiem/bookings";
import { FlexTenantMenu } from "./FlexTenantMenu";
import { useDestinationDetailsQuery, useUserMembershipForCreditsMenuQuery } from "../../../generated/gateway-client";

export const WorkplaceManagerSideMenu = () => {
  const { companyFlexTenants, profile } = useContext(CurrentProfile);
  const site = useContext(Site);
  const { t } = useTranslation();
  const showBookings = useBookingsEnabled();
  const showRequestManagement = useRequestEnabled();
  const { data } = useDestinationDetailsQuery({
    variables: {
      uuid: site.uuid,
    },
  });
  const { data: membershipsForFlexSite } = useUserMembershipForCreditsMenuQuery({
    variables: { userUuid: profile?.uuid ?? "", siteUuid: site.uuid },
    skip: profile?.uuid == null,
  });
  const isFlexSite = data?.destination.siteFlexOperator != null;
  const hasFlexMembershipAtSite = isFlexSite
    ? Boolean(companyFlexTenants.find((ft) => ft.flexOperator.uuid === data.destination.siteFlexOperator?.uuid))
    : false;

  const hasFlexMembershipAtProfile = (membershipsForFlexSite?.userMembershipsForFlexSite ?? []).length > 0;

  return (
    <>
      <SideNav isExpandable={false}>
        {site.tierLevel === "Essentials" && (
          <ShortcutItem icon={RiHomeLine} routerLink={"/"} text={t("home.sideMenu.home")} />
        )}
        <ShortcutItem icon={RiDashboardLine} routerLink={"/insights"} text={t("home.sideMenu.insights")} />
        {showBookings && (
          <ShortcutItem icon={RiCalendarCheckLine} routerLink="/bookings" text={t("home.sideMenu.bookings")} />
        )}
        {showRequestManagement && (
          <ShortcutItem
            icon={RiQuestionnaireLine}
            routerLink="/request-management"
            text={t("home.sideMenu.requestManagement")}
          />
        )}
        {site.featureModules.visitors.enabled && (
          <ShortcutItem
            icon={RiTeamLine}
            routerLink="/visitor-management"
            text={t("home.shortcuts.visitorManagement")}
          />
        )}
        {hasFlexMembershipAtProfile && (
          <ShortcutItem icon={RiWalletLine} routerLink={"/credits"} text={t("home.sideMenu.credits")} />
        )}
        <ShortcutItem icon={RiSettings3Line} routerLink="/settings" text={t("common.settings")} />
      </SideNav>
      {hasFlexMembershipAtSite && <FlexTenantMenu />}
      <ShortcutsMenu currentRole={Role.WorkplaceManager} className="mt-5" />
    </>
  );
};
