import React, { createContext, useContext, useEffect, useMemo } from "react";
import { useFormikContext } from "formik";
import { type BookingChargesQuery } from "../../../generated/gateway-client";
import { useBookingCharges } from "../hooks/useBookResource";
import type { BookingFormValue } from "../models/BookingFormValue";
import { BookingModalInfo } from "./BookingModalInfoProvider";
import { stringIsEmpty } from "@equiem/lib";

interface BookingChargesContext {
  charges: Array<BookingChargesQuery["bookingCharges"]>;
  totalCharges: number;
  loading: boolean;
}
export const BookingChargesContext = createContext<BookingChargesContext>({
  charges: [],
  totalCharges: 0,
  loading: false,
});

interface P {
  children?: React.ReactNode;
}
export const BookingChargesProvider: React.FC<P> = ({ children }) => {
  const fm = useFormikContext<BookingFormValue>();
  const { resource, timezone, booking, autoApprove } = useContext(BookingModalInfo);

  const isHiddenCost = !autoApprove && resource.hiddenCost;

  const { data, loading } = useBookingCharges({
    resource,
    timezone,
    values: fm.values,
    bookingUuid: booking?.uuid,
    skip:
      stringIsEmpty(fm.values.date) || stringIsEmpty(fm.values.start) || stringIsEmpty(fm.values.end) || isHiddenCost,
  });

  useEffect(() => {
    void fm.setFieldValue(
      "totalCharges",
      data?.multipleBookingCharges.reduce((prev, curr) => prev + curr.total, 0) ?? undefined,
    );
    // We don't want [fm] to be inside deps to avoid unecessary update.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.multipleBookingCharges]);

  const totalCharges = useMemo(
    () => (data?.multipleBookingCharges ?? []).reduce((prev, curr) => prev + curr.total, 0),
    [data?.multipleBookingCharges],
  );

  return (
    <BookingChargesContext.Provider value={{ charges: data?.multipleBookingCharges ?? [], totalCharges, loading }}>
      {children}
    </BookingChargesContext.Provider>
  );
};
