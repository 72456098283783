import React from "react";

import { useTranslation } from "@equiem/localisation-eq1";
import { Modal } from "@equiem/react-admin-ui";
import { BookingRecurringModalProvider } from "../contexts/BookingRecurringModalProvider";
import { BookingRecurringModalContent } from "./BookingRecurringModalContent";

interface P {
  showModal: boolean;
  onHide: () => void;
}
export const BookingRecurringModal: React.FC<P> = ({ showModal, onHide }) => {
  const { t } = useTranslation();

  return showModal ? (
    <Modal.Dialog
      title={t("common.repeat")}
      show
      scrollable
      centered
      hideOnEsc
      onHide={onHide}
      hideOnClick={false}
      focusTrapOptions={{ allowOutsideClick: () => true }}
      size="md"
      supportsMobile
    >
      <Modal.Header
        closeButton={true}
        noBorder={true}
        noTitle={false}
        intro={t("bookings.operations.createRecurringBooking")}
      ></Modal.Header>
      <BookingRecurringModalProvider onClose={onHide}>
        <BookingRecurringModalContent />
      </BookingRecurringModalProvider>
    </Modal.Dialog>
  ) : null;
};
