import React, { useContext, useEffect } from "react";
import { DateTime } from "luxon";
import Link from "next/link";

import { CurrentProfile, useRequestEnabled, useSiteContext } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import { Button, ProgressCircle, Skeleton, Text, useTheme } from "@equiem/react-admin-ui";

import { ReqMgtSortField, useRequestsLazyQuery } from "../../generated/requests-client";
import { useRequestBuildingPermission } from "../requests/hooks/useRequestBuildingPermission";

import { RequestItem } from "./components/RequestItem";

interface Props {
  handleOpenRequest: () => void;
}

export const LastRequests: React.FC<Props> = ({ handleOpenRequest }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { timezone } = useSiteContext();
  const { profile } = useContext(CurrentProfile);
  const showRequest = useRequestEnabled();
  const [requestsQuery, { data: requestsData, loading }] = useRequestsLazyQuery();
  const access = useRequestBuildingPermission();

  useEffect(() => {
    if (profile?.uuid == null || !showRequest) {
      return;
    }

    void requestsQuery({
      fetchPolicy: "network-only",
      variables: {
        page: {
          first: 3,
        },
        sort: {
          field: ReqMgtSortField.Created,
          asc: false,
        },
        filter: {
          reporterUuids: [profile.uuid],
          forMenuDisplay: {
            start: DateTime.local().setZone(timezone).startOf("day").minus({ days: 3 }).toMillis(),
            end: DateTime.local().setZone(timezone).endOf("day").toMillis(),
          },
        },
      },
    });
  }, [profile?.uuid, timezone, requestsQuery, showRequest]);

  const requests = requestsData?.reqMgt.requests.edges.filter((c) => c.node != null) ?? [];

  return showRequest ? (
    <div className="request-container">
      {requests.length > 0 && (
        <>
          <div className="d-flex justify-content-between head">
            <Text variant="label" className="mb-3 ml-3">
              {t("requests.titleShort")}
            </Text>
            <Link href="/request-management" passHref legacyBehavior>
              <Text variant="label" component="a" className="mb-3 mr-3 cursor-pointer">
                {t("requests.allRequests")}
              </Text>
            </Link>
          </div>
          <div className="list">
            {!loading && requests.map(({ node }) => node != null && <RequestItem request={node} key={node.uuid} />)}
          </div>
        </>
      )}
      {loading && <ProgressCircle size="xs" className="pb-4 align-self-center" />}
      {access.loading ? (
        <Skeleton.Line height="32px" width="100%" />
      ) : (
        <>
          {(access.accessInfo?.canReportRequests === true || access.accessInfo?.requestManager === true) && (
            <Button variant="secondary" onClick={handleOpenRequest} className="mx-0">
              {t("requests.openRequest")}
            </Button>
          )}
        </>
      )}
      <style jsx>{`
        .request-container {
          display: flex;
          flex-direction: column;
          padding: ${theme.spacers.s2} ${theme.spacers.s3} ${theme.spacers.s3};
        }

        .head :global(a) {
          text-decoration: none;
          color: ${theme.colors.grayscale[60]};
        }

        .head :global(a:hover) {
          color: ${theme.colors.grayscale[80]};
        }

        .list {
          display: flex;
          flex-direction: column;
          border-radius: 4px;
          margin-bottom: 8px;
        }
      `}</style>
    </div>
  ) : null;
};
