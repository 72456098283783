import type { FC } from "react";
import React, { useContext, useMemo, useState } from "react";
import type { MyFlexBuildingsQuery } from "../../generated/gateway-client";
import { useMyFlexBuildingsQuery } from "../../generated/gateway-client";
import { Form as EqForm } from "@equiem/react-admin-ui";
import { useTranslation } from "@equiem/localisation-eq1";
import { Field } from "formik";
import { LevelSelect } from "./LevelSelect";
// eslint-disable-next-line node/no-extraneous-import
import type { OnChange } from "@equiem/react-ui/src/components/Form/FormMultiSelect/model/OnChange";
import { FlexTenantLocationContext, emptyLocation } from "../../contexts/FlexTenantLocationContext";

export type BuildingSearchBuilding = NonNullable<MyFlexBuildingsQuery["myFlexBuildings"]["edges"][number]["node"]>;

interface Props {
  selectedCompanyUuid?: string;
  locationIndex?: number;
  readOnly?: boolean;
  disableBuildingSelect?: boolean;
}

export const LocationSelect: FC<Props> = ({
  selectedCompanyUuid,
  locationIndex,
  readOnly,
  disableBuildingSelect = false,
}) => {
  const { locations, setLocations } = useContext(FlexTenantLocationContext);
  const [selectedBuilding, setSelectedBuilding] = useState<BuildingSearchBuilding | null>(
    locationIndex != null ? locations[locationIndex] : null,
  );
  const { t } = useTranslation();
  const { data } = useMyFlexBuildingsQuery({
    variables: {
      search: "",
    },
  });
  const buildingData = data?.myFlexBuildings.edges;

  const searchBuildings = useMemo(() => {
    return (buildingData ?? [])
      .flatMap((edge) => {
        // Empty node, return.
        if (edge.node == null) {
          return [];
        }
        // no available locations for building.
        if (!(!locations.map((l) => l.uuid).includes(edge.node.uuid) || selectedBuilding?.uuid === edge.node.uuid)) {
          return [];
        }
        return [{ value: edge.node.uuid, label: `${edge.node.name} (${edge.node.destination?.name})` }];
      }, [])
      .sort((a, b) => a.label.localeCompare(b.label));
  }, [buildingData, locations, selectedBuilding]);

  const selectedLocation = locations.find((l) => l.uuid === selectedBuilding?.uuid);

  return (
    <>
      <EqForm.DynamicSelect
        name="locationBuilding"
        value={selectedBuilding?.uuid ?? ""}
        options={searchBuildings}
        noneLabel={t("flex.members.form.buildingPlaceholder")}
        readOnly={readOnly === true || disableBuildingSelect}
        onChange={(e) => {
          const building = buildingData?.find((edge) => edge.node?.uuid === e.target.value)?.node;
          setSelectedBuilding(building ?? null);
          if (locations.length === 1) {
            setLocations([emptyLocation]);
          }

          if (building?.destination != null) {
            setLocations((prev) => {
              return prev.map((l) =>
                l.uuid === building.uuid || l.uuid === ""
                  ? {
                      uuid: building.uuid,
                      name: building.name,
                      destination: {
                        uuid: building.destination?.uuid ?? "",
                        name: building.destination?.name ?? "",
                      },
                      levels: [],
                      isNew: true,
                    }
                  : l,
              );
            });
          }
        }}
      />
      {selectedBuilding != null && (
        <EqForm.Group label={t("flex.members.form.levels")} className="mt-4" required>
          <Field
            placeholder={t("flex.members.form.levels")}
            as={LevelSelect}
            readOnly={readOnly}
            building={selectedBuilding.uuid}
            companyUuid={selectedCompanyUuid}
            key={selectedBuilding.uuid}
            defaultValues={selectedLocation?.levels.map((l) => l.uuid)}
            onChange={(e: OnChange) => {
              setLocations((prev) => {
                if (selectedBuilding.destination == null) {
                  return prev;
                }
                return prev.map((l) =>
                  l.uuid === selectedBuilding.uuid
                    ? {
                        ...l,
                        levels: [
                          ...e.target.value.map((lev) => ({
                            uuid: lev,
                            name: "",
                          })),
                        ],
                      }
                    : l,
                );
              });
            }}
          />
        </EqForm.Group>
      )}
    </>
  );
};
