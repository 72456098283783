import { useRouter } from "next/router";
import type { ReactNode } from "react";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { UrlParams } from "./UrlParamsContext";

const acceptedParams = ["filters", "search", "tab"];

export const UrlParamsProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const router = useRouter();
  const [currentUrl, setCurrentUrl] = useState(decodeURIComponent(router.asPath).split("?")[0]);

  useEffect(() => {
    const decodedPath = decodeURIComponent(router.asPath);
    if (decodedPath.split("?")[0] !== currentUrl) {
      setCurrentUrl(decodedPath.split("?")[0]);
    }
  }, [router.asPath]);

  const setParam = useCallback(
    (key: string, value: string) => {
      const decodedPath = decodeURIComponent(router.asPath);
      if (currentUrl !== decodedPath.split("?")[0] || value == null || value === "" || !acceptedParams.includes(key)) {
        return;
      }
      void router.replace({
        query: { ...router.query, [key]: value },
      });
    },
    [router, currentUrl],
  );

  const deleteParam = useCallback(
    (key: string) => {
      const decodedPath = decodeURIComponent(router.asPath);
      if (currentUrl !== decodedPath.split("?")[0]) {
        return;
      }
      const newQuery = { ...router.query };
      delete newQuery[key];
      void router.replace({
        query: newQuery,
      });
    },
    [router, currentUrl],
  );

  // we clean params only when chaning the page.
  const clearParams = useCallback(() => {
    const decodedPath = decodeURIComponent(router.asPath);
    if (currentUrl !== decodedPath.split("?")[0]) {
      return;
    }
    const newQuery = { ...router.query };
    acceptedParams.forEach((param) => {
      delete newQuery[param];
    });
    void router.replace({
      query: newQuery,
    });
  }, [router, currentUrl]);

  const currentUrlParams = useMemo(
    () =>
      Object.fromEntries(
        Object.entries(router.query).filter(
          ([key, value]) => acceptedParams.includes(key) && value != null && value !== "",
        ),
      ),
    [router],
  );

  return (
    <UrlParams.Provider
      value={{
        setParam,
        deleteParam,
        clearParams,
        currentUrlParams,
      }}
    >
      {children}
    </UrlParams.Provider>
  );
};
