import React from "react";
import { ImageCarousel, RichHtml, stringNotEmpty, useViewerTokens } from "@equiem/lib";
import { Skeleton, useTheme } from "@equiem/react-admin-ui";

import type { ArticleDetail } from "../util/Article";
import { ArticleByline } from "./ArticleByline";
import { ArticleDownloads } from "./ArticleDownloads";
import { ArticleRelatedInfo } from "./ArticleRelatedInfo";
import type { ImageV2 } from "../../../generated/gateway-client";

interface Props {
  article: ArticleDetail;
  showAuthorTooltip?: boolean;
}

export const ArticleContent: React.FC<Props> = ({ article, showAuthorTooltip }) => {
  const { title, excerpt, images, body, relatedInfo, downloads } = article;
  const tokenReplacer = useViewerTokens();
  const { colors, borderRadius } = useTheme();

  return (
    <>
      <article className="mb-7">
        <header>
          <ArticleByline article={article} showAuthorTooltip={showAuthorTooltip} />
          <hr className="my-6" />
          {stringNotEmpty(title) ? <h1>{title}</h1> : <Skeleton.Line width="67%" height="28px" />}
          <p className="excerpt text-muted mt-3 mb-5">{excerpt}</p>
          {images != null && images.length > 0 ? (
            <ImageCarousel
              images={images.filter((i) => i.value.__typename === "ImageV2").map((i) => (i.value as ImageV2).imageUrl)}
            />
          ) : (
            <Skeleton.Line width="100%" height="290px" borderRadius={borderRadius} />
          )}
        </header>
        <section className="my-6">
          {stringNotEmpty(body) ? (
            <RichHtml html={tokenReplacer(body)} minHeight={150} />
          ) : (
            <Skeleton.Line width="100%" height="144px" />
          )}
        </section>
        {(relatedInfo.length > 0 || downloads.length > 0) && (
          <footer>
            {relatedInfo.length > 0 && <ArticleRelatedInfo relatedInfo={relatedInfo} />}
            {downloads.length > 0 && <ArticleDownloads downloads={downloads} />}
          </footer>
        )}
      </article>
      <style jsx>{`
        hr {
          border: 0;
          border-bottom: 1px solid ${colors.border};
        }
        h1 {
          font-size: 24px;
          font-weight: 700;
          line-height: 32px;
        }
        .excerpt {
          font-size: 18px;
          line-height: 24px;
        }
        section {
          font-size: 16px;
          line-height: 24px;
        }
      `}</style>
    </>
  );
};
