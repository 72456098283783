import { formatters, useTranslation } from "@equiem/localisation-eq1";
import { DateTime as DT, useTheme } from "@equiem/react-admin-ui";
import { DateTime } from "luxon";
import React, { useContext } from "react";
import { groupWeekday } from "../../../lib/formatWeekday";
import { BookingModalInfo } from "../contexts/BookingModalInfoProvider";
import { RiTimeLine } from "@equiem/react-admin-ui/icons";

const strToTs = (time: string, zone: string) => {
  return DateTime.fromFormat(time, "HH:mm", { zone }).toMillis();
};

interface P {
  className?: string;
}
export const BookingResourceTimeFrame: React.FC<P> = ({ className = "" }) => {
  const { t, i18n } = useTranslation();
  const { resource, timezone } = useContext(BookingModalInfo);
  const theme = useTheme(true);

  if (resource.availability.length === 0) {
    return null;
  }

  return (
    <>
      <div className={`time-frame-cont ${className}`}>
        <RiTimeLine size={16} color={theme.colors.grayscale[50]} />
        <div className="time-frame-body">
          {resource.availability.flatMap((a, index) => (
            <div key={index} className="time-frame">
              <div>
                {groupWeekday(a.days, i18n.language)} (
                <DT.TimeRange
                  language={i18n.language}
                  start={strToTs(a.start, timezone)}
                  end={strToTs(a.end, timezone)}
                  timezone={timezone}
                />
                )
              </div>
              {a.__typename === "BookableResourceFlexibleAvailability" &&
                (a.minTimeInMinutes != null || a.maxTimeInMinutes != null) && (
                  <div>
                    {a.minTimeInMinutes != null && a.minTimeInMinutes > 0 && (
                      <>
                        {t("common.min")}: {formatters.durationshort(a.minTimeInMinutes, i18n.language)}
                        {a.maxTimeInMinutes != null && a.maxTimeInMinutes > 0 && ", "}
                      </>
                    )}
                    {a.maxTimeInMinutes != null && a.maxTimeInMinutes > 0 && (
                      <>
                        {t("common.max")}: {formatters.durationshort(a.maxTimeInMinutes, i18n.language)}
                      </>
                    )}
                  </div>
                )}
            </div>
          ))}
        </div>
      </div>
      <style jsx>{`
        .time-frame-cont {
          display: flex;
          gap: ${theme.spacers.s3};
        }
        .time-frame {
          padding-bottom: ${theme.spacers.s3};
        }
        .time-frame:last-child {
          padding: 0;
        }
      `}</style>
    </>
  );
};
