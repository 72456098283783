import React, { useContext } from "react";
import { Site } from "@equiem/lib";
import { DateTime, useTheme } from "@equiem/react-admin-ui";
import type { BookingResult } from "./BookingRow";
import { BookingRow } from "./BookingRow";
import { useTranslation } from "@equiem/localisation-eq1";

interface BookingDayViewProps {
  bookings: BookingResult[];
  date: number;
}

export const BookingDayView: React.FC<BookingDayViewProps> = ({ date, bookings }) => {
  const { colors } = useTheme();
  const { i18n } = useTranslation();
  const { timezone } = useContext(Site);

  return (
    <>
      <div className="px-5 pt-6">
        <div className="date">
          <DateTime.RelativeDate language={i18n.language} datetime={date} timezone={timezone} />
        </div>
        {bookings.map((booking, i) => (
          <BookingRow key={i} booking={booking} />
        ))}
      </div>
      <style jsx>{`
        .date {
          font-weight: 500;
          font-size: 12px;
          color: ${colors.medium};
        }
      `}</style>
    </>
  );
};
