import type { FieldProps } from "formik";
import type { FC } from "react";
import React from "react";
import { useTheme, useHtmlId } from "@equiem/react-admin-ui";
import type { IFileV2 } from "@equiem/uploader";
import { CropperUi, Dropzone, useUploaderState } from "@equiem/uploader";
import { useSignTemporaryUploadUrlMutation } from "../../generated/gateway-client";
import SortableList, { SortableItem } from "react-easy-sort";
import { FileItem } from "./FileItem";

export type BaseFieldProps = FieldProps<IFileV2[]>["field"];

interface Props extends BaseFieldProps {
  onUploading: (uploading: boolean) => void;
  showImages?: boolean;
  accept: string;
}

export const BaseUploader: FC<Props> = ({ value, onChange, name, onUploading, accept, showImages }) => {
  const { colors, spacers } = useTheme();
  const draggedItemClassName = useHtmlId();
  const [createPresignedUrl, { error }] = useSignTemporaryUploadUrlMutation();
  const {
    localAttachedFile,
    attachedFiles,
    dragover,
    uploading,
    setDragover,
    onFileChange,
    progress,
    onCrop,
    onSaveCropped,
    cropperRef,
    clearCropper,
    removeImage,
    sortOrder,
  } = useUploaderState(
    value,
    (files) => {
      onUploading(true);
      setTimeout(() => onUploading(false), 100);

      return onChange({
        target: {
          name: name,
          value: files,
        },
      });
    },
    async () => createPresignedUrl().then((r) => r.data?.signTemporaryUploadUrl),
    true,
    showImages,
  );

  return (
    <>
      {localAttachedFile?.img == null && (
        <Dropzone
          dragover={dragover}
          uploading={uploading}
          setDragover={setDragover}
          onFileChange={onFileChange}
          progress={progress}
          accept={accept}
        />
      )}
      {localAttachedFile?.img != null && (
        <CropperUi
          localAttachedImage={localAttachedFile as { file: File; img: string }}
          onCrop={onCrop}
          cropperRef={cropperRef}
          onSaveCropped={onSaveCropped}
          progress={progress}
          uploading={uploading}
          clearCropper={clearCropper}
          error={error}
          disableAspectRatio={false}
          aspectRatio={3 / 2}
        />
      )}
      {localAttachedFile?.file != null && localAttachedFile.img == null && (
        <div className="img-container">
          <FileItem
            index={0}
            progress={progress}
            showImg={showImages}
            onRemove={() => removeImage(0)}
            file={localAttachedFile.file}
          />
        </div>
      )}
      <SortableList draggedItemClassName={draggedItemClassName} onSortEnd={sortOrder}>
        <div className={showImages === true ? "imgs-container" : ""}>
          {attachedFiles.map((file, index) => (
            <SortableItem key={file.key ?? file.temporaryUuid}>
              <div className="img-container">
                <FileItem showImg={showImages} index={index} onRemove={() => removeImage(index)} file={file} />
              </div>
            </SortableItem>
          ))}
        </div>
      </SortableList>
      <style jsx>
        {`
          .center {
            display: flex;
            justify-content: space-between;
            font-size: 10pt;
            font-weight: 300;
          }
          .imgs-container {
            display: flex;
            flex-wrap: wrap;
            gap: ${spacers.s3};
            user-select: none;
          }
          .img-container {
            background: ${colors.white};
            margin-bottom: ${spacers.s3};
            margin-top: ${spacers.s3};
          }
          :global(.${draggedItemClassName}) {
            z-index: 999;
          }
        `}
      </style>
    </>
  );
};
