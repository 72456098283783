import React from "react";
import { SettingsTab } from "./components/SettingsTab";
import { Material } from "@equiem/react-admin-ui";
import { InvoiceSettingsSummary } from "./components/resourceConfiguration/InvoiceSettingsSummary";

export const InvoiceDetailsPage = () => {
  return (
    <SettingsTab>
      <Material.Provider>
        <InvoiceSettingsSummary />
      </Material.Provider>
    </SettingsTab>
  );
};
