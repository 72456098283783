import { type RefObject, createContext } from "react";
import type { FormikProps } from "formik";

import type { CmsArticleFragmentFragment } from "../generated/gateway-client";

export interface ArticleContext {
  article?: CmsArticleFragmentFragment;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  articleFormRef?: RefObject<FormikProps<any>>;
  setArticle: (article?: CmsArticleFragmentFragment) => void;
  forceAutoSave: () => Promise<void>;
}

export const ArticleContext = createContext<ArticleContext>({
  setArticle: () => ({}),
  forceAutoSave: async () => Promise.resolve(),
});
