import React from "react";

import { stringNotEmpty } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import type { BookableResourceSiteAudienceInput } from "../../../generated/gateway-client";

type BookableResourceSiteAudience = BookableResourceSiteAudienceInput & { siteName: string };

export const isAll = (audience: BookableResourceSiteAudience) =>
  audience.segmentIds == null || audience.segmentIds.length === 0;

interface AudienceInfoProps {
  audience: BookableResourceSiteAudience;
}

export const AudienceInfo: React.FC<AudienceInfoProps> = (props) => {
  const { t } = useTranslation();
  const { audience } = props;
  if (stringNotEmpty(audience.segmentSummary)) {
    return (
      <span
        dangerouslySetInnerHTML={{
          __html: audience.segmentSummary.replace(/: /gu, ":<br/>").replace(/, Segment /gu, "<br/>Segment "),
        }}
      />
    );
  } else {
    return <span>{isAll(audience) ? t("common.all") : "-"}</span>;
  }
};
