import React, { useCallback, useContext, useEffect, useState } from "react";

import { useTranslation } from "@equiem/localisation-eq1";
import { Button, Modal } from "@equiem/react-admin-ui";

import { Modal as ModalContext } from "../../../contexts/ModalContext";

export const CloseWalkinConfirmationWidget: React.FC<{
  handleClearWalkIn: () => void;
}> = ({ handleClearWalkIn }) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const modal = useContext(ModalContext);

  const onClose = useCallback(() => {
    setShowModal(false);
    modal.close();
  }, [setShowModal, modal]);

  const onClean = () => {
    handleClearWalkIn();
    setShowModal(false);
    modal.close();
  };

  useEffect(() => {
    if (modal.activeModal === "CloseWalkin") {
      setShowModal(true);
    }
  }, [modal.activeModal]);

  return (
    <>
      <Modal.Dialog
        title={t("common.areYouSure")}
        show={showModal}
        onHide={onClose}
        hideOnEsc={true}
        hideOnClick={false}
        size="md"
        centered
      >
        <Modal.Header closeButton={false} noBorder={true} />
        <Modal.Body>
          <h3 className="title m-0">{t("visitors.walkIn.walkInCancelBody")}</h3>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="ghost" className="mr-4" onClick={onClose}>
            {t("visitors.appointmentForm.cancelNo")}
          </Button>
          <Button variant="danger" onClick={onClean}>
            {t("visitors.appointmentForm.cancelYes")}
          </Button>
        </Modal.Footer>
      </Modal.Dialog>

      <style jsx>{`
        .title {
          font-weight: 400;
          font-size: 18px;
          line-height: 28px;
        }
      `}</style>
    </>
  );
};
