import type { ReactNode } from "react";
import React, { useState, type FC, useCallback, useMemo } from "react";
import type { SideModalTab } from "./SideModalContext";
import { SideModalContext } from "./SideModalContext";
import { Modal } from "@equiem/react-admin-ui";
import { useTranslation } from "@equiem/localisation-eq1";
import { CreateFlexTenantForm } from "../components/members/CreateFlexTenantForm";
import type { FlexTenantFragmentFragment } from "../generated/gateway-client";
import { AssignProfileToFlexTenantForm } from "../components/membership/AssignProfileToFlexTenantForm";
import { FlexTenantLocationProvider } from "./FlexTenantLocationProvider";
import { EditFlexTenantForm } from "../components/members/EditFlexTenantForm";
import { flattenGraphqlLocations } from "../components/lib/flattenLocations";

export const SideModalProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [selectedTenant, setSelectedTenant] = useState<FlexTenantFragmentFragment | null>(null);
  const [currentTab, _setCurrentTab] = useState<SideModalTab>("addFlexTenant");
  const [stepCount, _setStepCount] = useState(1);
  const [onBack, setOnBack] = useState<() => void>();
  const { t } = useTranslation();

  const closeModal = useCallback(() => {
    _setStepCount(1);
    setOpen(false);
  }, []);

  const openTab = useCallback((tab: SideModalTab, flexTenant?: FlexTenantFragmentFragment) => {
    setSelectedTenant(flexTenant ?? null);
    _setCurrentTab(tab);
    setOpen(true);
  }, []);

  const title = useMemo(() => {
    if (currentTab === "editFlexTenant") {
      return t("flex.members.editMembership");
    }
    return t("flex.members.addButtonTitle");
  }, [currentTab, t]);

  const setStepCount = (count: number) => {
    setOnBack(() => () => setStepCount(count - 1));
    _setStepCount(count);
  };

  return (
    <>
      <SideModalContext.Provider
        value={{
          openTab,
          currentTab,
          closeModal,
          stepCount,
          setStepCount,
        }}
      >
        {children}
        <Modal.Dialog
          title={title}
          show={open}
          onHide={closeModal}
          hideOnEsc={true}
          hideOnClick={false}
          focusTrapOptions={{ allowOutsideClick: () => true }}
          sideModal={true}
          onBack={onBack}
        >
          {currentTab === "addFlexTenant" && (
            <FlexTenantLocationProvider>
              <CreateFlexTenantForm />
            </FlexTenantLocationProvider>
          )}
          {currentTab === "editFlexTenant" && selectedTenant != null && (
            <FlexTenantLocationProvider defaultLocations={flattenGraphqlLocations(selectedTenant.locations)}>
              <EditFlexTenantForm flexTenant={selectedTenant} />
            </FlexTenantLocationProvider>
          )}
          {currentTab === "assignProfileToFlexTenant" && <AssignProfileToFlexTenantForm />}
          {currentTab === "viewFlexTenant" && selectedTenant != null && (
            <FlexTenantLocationProvider defaultLocations={flattenGraphqlLocations(selectedTenant.locations)}>
              <EditFlexTenantForm flexTenant={selectedTenant} readonly={true} />
            </FlexTenantLocationProvider>
          )}
        </Modal.Dialog>
      </SideModalContext.Provider>
    </>
  );
};
