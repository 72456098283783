import type { PropsWithChildren } from "react";
import React, { useRef } from "react";
import { SiteNavbar } from "./SiteNavbar";
import { useTheme } from "@equiem/react-admin-ui";
import { SideMenuProvider } from "../../contexts/SideMenuProvider";
import { Footer } from "./Footer";
import { ButtonTrayContext } from "@equiem/lib";

export const Web: React.FC<PropsWithChildren<{ noFooter?: boolean }>> = ({ children }) => {
  const { spacers, breakpoints } = useTheme();
  const buttonTrayRef = useRef<HTMLDivElement>(null);

  return (
    <SideMenuProvider>
      <div className="web-layout">
        <div ref={buttonTrayRef}>
          <ButtonTrayContext.Provider value={{ containerRef: buttonTrayRef }}>
            <SiteNavbar />
            <div className="page-body">{children}</div>
          </ButtonTrayContext.Provider>
        </div>
        <Footer />
      </div>
      <style jsx>{`
        .web-layout {
          background: #fff;
          min-height: 100vh;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        }
        .page-body {
          padding: ${spacers.s3};
          flex-grow: 1;
        }
        @media (min-width: ${breakpoints.md}px) {
          .page-body {
            padding: ${spacers.s3} ${spacers.s7};
          }
        }
      `}</style>
    </SideMenuProvider>
  );
};
