import type { ComponentProps } from "react";
import React from "react";
import { useTheme } from "../../contexts/Theme";

export const Card = React.forwardRef<HTMLDivElement, ComponentProps<"div">>(({ children, ...props }, ref) => {
  const { colors } = useTheme();

  return (
    <>
      <div {...props} ref={ref}>
        {children}
      </div>
      <style jsx>{`
        div {
          border-radius: 8px;
          background-color: ${colors.white};
          overflow: auto;
        }
      `}</style>
    </>
  );
});

Card.displayName = "Card";
