const internalHostnames = ["getequiem.com", "equiem.one"];
const dynamicHostnames = ["equiem.page.link", "equiem.mobi"];

export const getLinkType = (url: string, siteDomainAliases: string[]): "internal" | "dynamic" | "external" => {
  try {
    const { protocol, hostname } = new URL(url);

    if (
      !["https:", "http:"].includes(protocol) ||
      hostname === "" ||
      hostname === window.location.host.toLowerCase() ||
      internalHostnames.some((suffix) => hostname.endsWith(suffix)) ||
      siteDomainAliases.some((alias) => hostname === alias.toLowerCase())
    ) {
      return "internal";
    }

    if (dynamicHostnames.some((suffix) => hostname.endsWith(suffix))) {
      return "dynamic";
    }

    return "external";
  } catch {
    return "internal";
  }
};
