import type { ApolloQueryResult } from "@apollo/client";
import { notNullOrUndefined, stringNotEmpty, CurrentProfile, useSiteContext } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import { useTheme, useWindowDimensions } from "@equiem/react-admin-ui";
import type { FormikHelpers } from "formik";
import { Formik, Form as FormikForm, useFormikContext } from "formik";
import React, { useEffect, useContext, useMemo } from "react";
import { ResourceCreateAndEditFormMain } from "./main/ResourceCreateAndEditFormMain";
import { ResourceCreateAndEditFormPreview } from "./preview/ResourceCreateAndEditFormPreview";
import { ResourceCreateAndEditFormButtonTray } from "./ResourceCreateAndEditFormButtonTray";
import { ResourceMultiSiteWarning } from "../../ResourceMultiSiteWarning";
import { ResourceCompanyChangeWarning } from "../../ResourceCompanyChangeWarning";
import type { BookableResourceEditQuery } from "../../../../generated/gateway-client";
import { useSiteCompaniesQuery } from "../../../../generated/gateway-client";
import { formValidation, type FormValues } from "../../../../lib/formValidation";
import { resourceCreateAndEditFormvalidationSchema } from "../../../../lib/validationSchema";
import { ResourceEditAccessControlContextProvider } from "../../../../contexts/ResourceEditAccessControlContext";
import {
  ResourceCreateAndEditSiteProvider,
  ResourceCreateAndEditSiteContext,
} from "./ResourceCreateAndEditSiteContext";
import { resourceCreateFormInitialValues } from "../../../../lib/resourceCreateFormInitialValues";

interface Props {
  initialValues?: FormValues;
  resourceUuid?: string;
  resourceName?: string;
  resourceOwnerCompany: { uuid: string; name: string } | null | undefined;
  submit: (values: FormValues, actions: FormikHelpers<FormValues>) => Promise<void>;
  refetch?: () => Promise<ApolloQueryResult<BookableResourceEditQuery>>;
}

const FormContextHandlers: React.FC = () => {
  const { setResourceSite, setResourceCompany, loading } = useContext(ResourceCreateAndEditSiteContext);
  const profile = useContext(CurrentProfile);
  const fm = useFormikContext<FormValues>();
  const { t } = useTranslation();

  // inform the parent context about site/company selection changes
  useEffect(() => setResourceSite(fm.values.site), [fm.values.site, setResourceSite]);
  useEffect(() => setResourceCompany(fm.values.ownerCompanyUuid), [fm.values.ownerCompanyUuid, setResourceCompany]);

  // regional manager: check whether the owner company exists at the site
  const { data: companiesData, loading: companiesLoading } = useSiteCompaniesQuery({
    variables: { destinationUuid: fm.values.site },
    skip: !profile.canManageRegion,
  });
  useEffect(() => {
    const ownerCompany = fm.values.ownerCompanyUuid;
    if (!profile.canManageRegion || companiesLoading || !stringNotEmpty(ownerCompany)) {
      return;
    }

    const ownerCompanyExistsAtSite = (companiesData?.destination.companiesV2?.edges ?? [])
      .map((edge) => edge.node)
      .filter(notNullOrUndefined)
      .some((c) => c.uuid === ownerCompany);
    if (ownerCompanyExistsAtSite) {
      return;
    }

    // the resource's owner company has been deleted, or is not enabled for the site
    fm.setFieldValue("ownerCompanyUuid", "").catch(console.error);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile.canManageRegion, fm.values.ownerCompanyUuid, companiesLoading, companiesData]);

  // trigger form validation when the language changes (important for correctly
  // translating field error messages)
  useEffect(() => {
    fm.validateForm().catch(console.error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t]);

  // trigger form validation when the site/owner company changes (important for
  // checking company x buildingLevel associations and site-specific fields)
  useEffect(() => {
    if (!loading) {
      fm.validateForm().catch(console.error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  return <></>;
};

const ResourceCreateAndEditFormInner: React.FC<Props> = ({
  initialValues,
  resourceUuid,
  resourceName,
  resourceOwnerCompany,
  submit,
  refetch,
}) => {
  const { t } = useTranslation();
  const currentSite = useSiteContext();
  const profile = useContext(CurrentProfile);
  const { creditCardAvailable, canManageSite, viewerLocations, ownerCompanyLocations, loading } = useContext(
    ResourceCreateAndEditSiteContext,
  );
  const { breakpoints } = useTheme(true);
  const { width } = useWindowDimensions();

  const isMobile = width <= breakpoints.md;

  // don't produce credit card validation errors if the user doesn't have
  // permission to view/modify credit card settings
  const allowCreditCard = creditCardAvailable || !canManageSite;

  const fullInitialValues = useMemo(() => {
    return (
      initialValues ?? resourceCreateFormInitialValues(currentSite.uuid, currentSite.name, profile, viewerLocations)
    );
  }, [currentSite.name, currentSite.uuid, initialValues, profile, viewerLocations]);

  return (
    <>
      <Formik<FormValues>
        enableReinitialize
        initialValues={fullInitialValues}
        validationSchema={resourceCreateAndEditFormvalidationSchema(t)}
        validate={(values) => formValidation(values, allowCreditCard, ownerCompanyLocations, t)}
        validateOnMount={true}
        onSubmit={submit}
      >
        <ResourceEditAccessControlContextProvider>
          <FormikForm autoComplete="off">
            <div className="resource-form-grid">
              <ResourceCreateAndEditFormMain />
              {!isMobile && <ResourceCreateAndEditFormPreview />}
            </div>
            <ResourceCreateAndEditFormButtonTray
              resourceUuid={resourceUuid}
              resourceName={resourceName}
              loading={loading}
              triggerRefresh={refetch}
            />
          </FormikForm>
          <ResourceMultiSiteWarning />
          <ResourceCompanyChangeWarning originalCompany={resourceOwnerCompany} />
          <FormContextHandlers />
        </ResourceEditAccessControlContextProvider>
      </Formik>
      <style jsx>{`
        .resource-form-grid {
          display: flex;
          flex-direction: row;
        }
        @media screen and (max-width: ${breakpoints.md}px) {
          .resource-form-grid {
            flex-direction: column;
            min-height: 0;
          }
        }
      `}</style>
    </>
  );
};

export const ResourceCreateAndEditForm: React.FC<Props> = (props) => {
  const currentSite = useSiteContext();
  const profile = useContext(CurrentProfile);

  return (
    <ResourceCreateAndEditSiteProvider
      initialSiteUuid={props.initialValues?.site ?? currentSite.uuid}
      initialCompanyUuid={props.initialValues?.ownerCompanyUuid ?? profile.profile?.companyV2?.uuid}
    >
      <ResourceCreateAndEditFormInner {...props} />
    </ResourceCreateAndEditSiteProvider>
  );
};
