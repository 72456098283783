import React from "react";

import { useTranslation } from "@equiem/localisation-eq1";
import { Form, useTheme } from "@equiem/react-admin-ui";

import type { CardFragmentFragment as Card } from "../../../../generated/settings-client";

import { CreditCardBrand } from "./CreditCardBrand";
import { DefaultCardBadge } from "./DefaultCardBadge";
import { DeleteCardButton } from "./DeleteCardButton";

interface Props {
  card: Card;
  loading: boolean;
  onSelect: () => unknown;
  onDelete: () => unknown;
}

export const SavedCard: React.FC<Props> = ({ card, loading, onSelect, onDelete }) => {
  const { spacers } = useTheme();
  const { t } = useTranslation();

  return (
    <>
      <div className="card">
        <Form.EnclosedRadioButton
          className="w-100 mb-2"
          disabled={loading}
          value={card.default}
          tooltipText={card.default ? null : t("settings.payment.setAsDefaultCreditCard")}
          label={
            <div className="card-label">
              <div className="card-description">{card.description}</div>
              {card.default && <DefaultCardBadge />}
            </div>
          }
          description={
            <span className="card-number">
              <CreditCardBrand brand={card.brand} />
              <span className="ml-3">**** **** **** {card.last4}</span>
            </span>
          }
          rightButton={
            <DeleteCardButton
              cardDescription={card.description ?? `**** **** **** ${card.last4}`}
              disabled={loading}
              onDelete={onDelete}
            />
          }
          onChange={(e) => {
            if (e.currentTarget.checked) {
              onSelect();
            }
          }}
        />
      </div>
      <style jsx>{`
        .card :global(.card-label) {
          display: flex;
          gap: ${spacers.s3};
        }
        .card :global(.card-label .card-description) {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      `}</style>
    </>
  );
};
