import type { DocumentNode, NoInfer, OperationVariables, QueryHookOptions, TypedDocumentNode } from "@apollo/client";
import { useApolloClient, useQuery } from "@apollo/client";
import { useEffect, useState } from "react";

export const usePersistedQuery = <TData = unknown, TVariables extends OperationVariables = OperationVariables>(
  cacheKey: string,
  document: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: QueryHookOptions<NoInfer<TData>, NoInfer<TVariables>>,
) => {
  const [restored, setRestored] = useState(false);
  const client = useApolloClient();

  const cached =
    !restored && options?.skip !== true && typeof localStorage !== "undefined" ? localStorage.getItem(cacheKey) : null;

  if (cached != null) {
    try {
      console.debug("Restoring persisted query", { cacheKey });
      client.cache.writeQuery<TData, TVariables>({
        query: document,
        data: JSON.parse(cached) as TData,
      });
      setRestored(true);
    } catch (e: unknown) {
      // We tried...
      console.error("Failed to restore cache");
      console.error(e);
    }
  }

  const result = useQuery<TData, TVariables>(document, options);

  useEffect(() => {
    if (result.data != null) {
      try {
        console.debug("Persisting query", { cacheKey });
        localStorage.setItem(cacheKey, JSON.stringify(result.data));
      } catch (e: unknown) {
        // We tried...
        console.error("Failed to persist cache");
        console.error(e);
      }
    }
  }, [cacheKey, result.data]);

  return result;
};
