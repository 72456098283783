/* istanbul ignore file */

import React, { forwardRef, useEffect, useState } from "react";
import JsBarcode from "jsbarcode";
import * as QRCode from "qrcode";

import { useShowError } from "@equiem/lib";
import { formatters, useTranslation } from "@equiem/localisation-eq1";

import type { BarcodeType } from "../generated/visitors-client";

type Host = {
  fullName: string;
  companyName?: string;
};

type Visitor = {
  fullName: string;
  companyName?: string | null;
  codeValue?: string | null;
  uuid: string;
};

interface PrintedPassProps {
  className?: string;
  marginTop?: number | null;
  passes: Pass[];
}

interface Pass {
  visitor: Visitor;
  host: Host;
  startDate: number;
  endDate: number;
  codeType?: BarcodeType | null;
}

export const PrintedPass = forwardRef<HTMLDivElement, PrintedPassProps>(
  ({ className = "", passes, marginTop }, ref) => {
    const { t } = useTranslation();
    const showError = useShowError();
    const { i18n } = useTranslation();
    const [qrCodes, setQrCodes] = useState<Map<string, string>>(new Map());

    useEffect(() => {
      setQrCodes(new Map());

      for (const { visitor, codeType } of passes) {
        if (visitor.codeValue != null && codeType === "QR") {
          QRCode.toDataURL(visitor.codeValue, { width: 78, margin: 0 }, (err, url) => {
            if (err != null) {
              showError(err);
              return;
            }

            setQrCodes((prev) => new Map(prev).set(visitor.uuid, url));
          });
        }

        if (visitor.codeValue != null && codeType === "CODE128") {
          const barcodeCanvas = document.getElementById("barcode") as HTMLCanvasElement;
          JsBarcode("#barcode", visitor.codeValue, { height: 56, displayValue: false, width: 1.5, margin: 0 });

          const image = new Image();
          image.src = barcodeCanvas.toDataURL("image/png");

          // eslint-disable-next-line @typescript-eslint/no-loop-func
          image.onload = function () {
            const rotatedCanvas = document.createElement("canvas");
            const rotatedContext = rotatedCanvas.getContext("2d");
            rotatedCanvas.width = image.height;
            rotatedCanvas.height = image.width;
            rotatedContext?.translate(image.height, 0);
            rotatedContext?.rotate((90 * Math.PI) / 180);
            rotatedContext?.drawImage(image, 0, 0);
            setQrCodes((prev) => new Map(prev).set(visitor.uuid, rotatedCanvas.toDataURL("image/png")));
          };
        }
      }
    }, [passes]);

    return (
      <>
        <div ref={ref}>
          {passes.map(({ visitor, host, startDate, endDate, codeType }, index) => (
            <div className={`container ${className}`.trim()} key={index}>
              <div className="content">
                <div className="text-content">
                  <p>{visitor.fullName}</p>
                  <p className="small">{visitor.companyName}</p>
                  <p className="small host">{t("common.host")}</p>
                  <p>{host.fullName}</p>
                  <p className="small">{host.companyName}</p>
                  <p className="date">{formatters.dateshort(startDate, i18n.language)}</p>
                  <p className="medium">
                    {formatters.timeshort(startDate, i18n.language)}-{formatters.timeshort(endDate, i18n.language)}
                  </p>
                </div>
                <div className="code">
                  {codeType !== null && qrCodes.has(visitor.uuid) && <img src={qrCodes.get(visitor.uuid)} />}
                </div>
              </div>
              {index !== passes.length - 1 && <div className="page-break" />}
            </div>
          ))}
        </div>
        <canvas id="barcode"></canvas>
        <style jsx>
          {`
            .container {
              background-color: #fff;
              width: 280px;
              padding-top: ${marginTop != null ? marginTop : 0}mm;
            }
            .content {
              padding: 0.75rem;
              display: flex;
              gap: 0.5rem;
              justify-content: space-between;
            }
            .content p {
              margin: 0;
              font-size: 14px;
              line-height: 18px;
              color: #000000;
              text-overflow: ellipsis;
              overflow: hidden;
            }
            .content p.medium {
              font-size: 12px;
              line-height: 16px;
            }
            .content p.small {
              font-size: 10px;
              line-height: 14px;
            }
            .content p.host {
              margin-top: 8px;
              font-weight: bold;
              text-transform: uppercase;
            }
            .content p.date {
              margin-top: 8px;
            }
            .text-content {
              display: flex;
              flex-direction: column;
              overflow: hidden;
              white-space: nowrap;
            }
            .page-break {
              page-break-after: always;
            }
            #barcode {
              display: none;
            }
          `}
        </style>
      </>
    );
  },
);

PrintedPass.displayName = "PrintedPass";
