import type { SFI } from "./sfi";

type FilterItem = Pick<SFI, "uuid" | "checked" | "name">;

interface ReturnType {
  id: string;
  checked: boolean;
  name: string;
  values: FilterItem[];
}

/**
 * This function appears odd, and slightly unecessary. It's for handling the following scenario:
 *
 * If you have a site with 2 building (North Tower and South Tower), and each tower has levels 1,
 * 2, and 3. The "Levels" filter should only display each level once, and this function de-dupes
 * them so that this is true. The values property then contains the filter items for each building
 * ie (North Tower Level 1 AND South Tower Level 1).
 */
export const groupItems = (items: SFI[]): ReturnType[] => {
  const key = "name";
  const reduced = items.reduce<Record<string, FilterItem[]>>(
    (result, item) => ({
      ...result,
      [item[key]]: [
        // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions, @typescript-eslint/no-unnecessary-condition
        ...(result[item[key]] || []),
        {
          name: item.name,
          checked: item.checked,
          uuid: item.uuid,
          type: null,
        },
      ],
    }),
    {},
  );

  return Object.keys(reduced).map((k) => ({
    id: reduced[k][0].uuid,
    checked: reduced[k][0].checked,
    name: k,
    values: reduced[k],
  }));
};
