import React, { useEffect, useState } from "react";
import { DateTime } from "luxon";

import { useTranslation } from "@equiem/localisation-eq1";
import { Button, ProgressCircle } from "@equiem/react-admin-ui";
import { RiCheckFill, RiLogoutBoxRLine } from "@equiem/react-admin-ui/icons";

import type {
  VisitorForBuildingReceptionFragment,
  VisitorForReceptionFragment,
} from "../../../../generated/visitors-client";
import { VisitorStatus } from "../../../../generated/visitors-client";

type Visitor = VisitorForReceptionFragment | VisitorForBuildingReceptionFragment;

export const ActionButton = ({
  visitor,
  statusUpdatingVisitors,
  cardStatusUpdatingVisitors,
  cardIdUpdatingVisitors,
  selectedVisitorIds,
  handleAction,
  visitorsLoading,
}: {
  cardIdUpdatingVisitors: Record<string, boolean | undefined>;
  statusUpdatingVisitors: Record<string, boolean | undefined>;
  cardStatusUpdatingVisitors: Record<string, boolean | undefined>;
  visitor: Visitor;
  selectedVisitorIds: string[];
  handleAction: (visitor: Visitor) => () => void;
  visitorsLoading: boolean;
}) => {
  const { t } = useTranslation();
  const isFutureAppointment =
    DateTime.fromMillis(visitor.appointment.startTime).startOf("day").diff(DateTime.local().startOf("day"))
      .milliseconds > 0;
  const isStatusUpdating = statusUpdatingVisitors[visitor.uuid] === true;
  const isCardIdUpdating = cardIdUpdatingVisitors[visitor.uuid] === true;
  const isCardStatusUpdating = cardStatusUpdatingVisitors[visitor.uuid] === true;
  const anyLoading = isStatusUpdating || isCardStatusUpdating;
  const isDisabledAsLongAsVisitorIsNotInLobby =
    Boolean(visitor.appointment.holdInLobby) && visitor.status === VisitorStatus.PreBooked;
  const disabled =
    isDisabledAsLongAsVisitorIsNotInLobby || isFutureAppointment || anyLoading || selectedVisitorIds.length > 1;

  const isNotCheckInStatus = [VisitorStatus.PreBooked, VisitorStatus.CheckedOut, VisitorStatus.VisitorInLobby].includes(
    visitor.status,
  );

  const className = isNotCheckInStatus ? "action-green" : "action-black";
  const buttonText = isNotCheckInStatus ? t("visitors.reception.checkIn") : t("visitors.reception.checkOut");
  const buttonIcon = isNotCheckInStatus ? <RiCheckFill size={16} /> : <RiLogoutBoxRLine size={16} />;
  const [delayedCheckInRequested, setDelayedCheckinRequested] = useState<boolean>(false);

  const onClick = () => {
    if (isCardIdUpdating && isNotCheckInStatus && !delayedCheckInRequested) {
      setDelayedCheckinRequested(true);
    } else {
      handleAction(visitor)();
    }
  };

  useEffect(() => {
    if (delayedCheckInRequested && !isCardIdUpdating && !visitorsLoading) {
      setDelayedCheckinRequested(false);
      handleAction(visitor)();
    }
  }, [isCardIdUpdating, setDelayedCheckinRequested, visitorsLoading]);

  return (
    <>
      <>
        <Button onClick={onClick} size="md" variant="outline" className={`action ${className}`} disabled={disabled}>
          {!anyLoading && !isCardIdUpdating && !delayedCheckInRequested ? buttonIcon : <ProgressCircle size={16} />}
          <span className="action-label">{buttonText}</span>
        </Button>
      </>
      <style jsx>
        {`
          :global(.action-green) {
            color: #008000 !important;
          }
          :global(.action-green:hover),
          :global(.action-green:focus) {
            border-color: #008000 !important;
          }
          :global(.action-black) {
            color: #000 !important;
          }
          :global(.action-black:hover),
          :global(.action-black:focus) {
            border-color: #000 !important;
          }
          :global(.action-label) {
            font-weight: 600;
            font-size: 12px;
            line-height: 16px;
          }
        `}
      </style>
    </>
  );
};
