import React, { useContext, useMemo } from "react";
import Link from "next/link";
import { useRouter } from "next/router";

import { AppointmentsMenuContext, CurrentProfile, CurrentRole, Role, SubTopMenu } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import type { TabItem } from "@equiem/react-admin-ui";
import { Button, ButtonLink, Tabs, useIsMobileWidth, useTheme } from "@equiem/react-admin-ui";
import { RiAddLine, RiSettings3Line, RiUser2Line } from "@equiem/react-admin-ui/icons";

import { Modal } from "../contexts/ModalContext";
import { withContexts } from "../contexts/withContexts";
import { CancelFormWidget } from "../pages/appointment/widgets/CancelFormWidget";
import { ReceptionsListWidget } from "../pages/reception/widgets/ReceptionsListWidget";

export const AppointmentsMenuBase: React.FC = () => {
  const router = useRouter();
  const { isReceptionist } = useContext(CurrentProfile);
  const modal = useContext(Modal);
  const isMobile = useIsMobileWidth();
  const { currentRole } = useContext(CurrentRole);
  const { receptionCount } = useContext(CurrentProfile);
  const { colors } = useTheme(true);
  const { t } = useTranslation();
  const appointmentsMenu = useContext(AppointmentsMenuContext);
  const isEligibleCreateNewAppointment =
    appointmentsMenu.isEndUserHasEditableAccess || appointmentsMenu.hasEditableAccess;
  const isReceptionsVisible = !(currentRole === Role.Unknown && isReceptionist);
  const tabs = useMemo<TabItem[]>(() => {
    const _tabs = [
      {
        title: t("visitors.appointments.appointmentsTab"),
        key: "/visitor-management",
      },
    ];

    if (appointmentsMenu.isNewAppointment) {
      const closeableTab: TabItem = {
        title: t("visitors.appointments.newAppointment"),
        key: "/visitor-management/new-appointment",
        onClose: () => modal.open("CancelForm"),
      };

      return [..._tabs, closeableTab];
    }

    if (appointmentsMenu.isEditAppointment) {
      const closeableTab: TabItem = {
        title: appointmentsMenu.isPreview
          ? (appointmentsMenu.cachedAppointmentForm?.title as string)
          : t("visitors.appointments.editAppointmentWithName", {
              name: appointmentsMenu.cachedAppointmentForm?.title ?? "",
            }),
        key: `/visitor-management/edit-appointment/${appointmentsMenu.selectedAppointmentUuid}`,
        onClose: () => {
          if (!appointmentsMenu.isPreview) {
            modal.open("CancelForm");
          } else {
            appointmentsMenu.clearState();
            appointmentsMenu.navigatePreviousPage();
          }
        },
      };

      return [..._tabs, closeableTab];
    }

    return _tabs;
  }, [appointmentsMenu, t]);

  const handleTabSelect = (key: string) => {
    void router.push({
      pathname: key,
      query: router.query,
    });
  };

  return (
    <div style={{ background: colors.white }} className="menu">
      <SubTopMenu className="title-container mb-0">
        <h1 style={{ fontWeight: "700" }}>
          {currentRole === Role.Unknown
            ? t("visitors.appointments.viewBookVisitors")
            : t("visitors.appointments.visitorAppointments")}
        </h1>

        <div className="actions d-flex">
          {isReceptionsVisible && receptionCount === 1 && (
            <Link
              href={{
                pathname: "/visitor-management/receptions",
                query: router.query,
              }}
              passHref
              legacyBehavior
            >
              <ButtonLink shape={isMobile ? "round" : "default"} variant="outline" size="md">
                {isMobile ? <RiUser2Line size={16} /> : t("visitors.appointments.receptionView")}
              </ButtonLink>
            </Link>
          )}
          {isReceptionsVisible && receptionCount > 1 && (
            <Button
              variant="outline"
              shape={isMobile ? "round" : "default"}
              size="md"
              onClick={() => modal.open("ReceptionList")}
            >
              {isMobile ? <RiUser2Line size={16} /> : t("visitors.appointments.receptionView")}
            </Button>
          )}
          {currentRole !== Role.Unknown && (
            <Link
              href={{
                pathname:
                  currentRole === Role.PropertyManager
                    ? "/visitor-management/settings/general"
                    : "/visitor-management/settings/receptions",
                query: router.query,
              }}
              passHref
              legacyBehavior
            >
              <ButtonLink shape={isMobile ? "round" : "default"} variant="ghost" size="md">
                <RiSettings3Line size={16} />
                {!isMobile && t("common.settings")}
              </ButtonLink>
            </Link>
          )}
        </div>
      </SubTopMenu>
      <SubTopMenu className="menu-container py-0" sticky>
        <div className=" d-flex align-items-center">
          <div className="d-flex menu">
            <Tabs
              items={tabs}
              selected={router.asPath}
              tabLinks
              onSelect={handleTabSelect}
              actionComponent={
                <>
                  {isEligibleCreateNewAppointment && (
                    <Link href="/visitor-management/new-appointment" passHref legacyBehavior>
                      <ButtonLink
                        size="md"
                        shape={isMobile ? "round" : "default"}
                        variant="secondary"
                        className="create-button"
                        disabled={appointmentsMenu.isNewAppointment || appointmentsMenu.isEditAppointment}
                      >
                        <RiAddLine size={16} />
                        {!isMobile && t("visitors.appointments.newAppointment")}
                      </ButtonLink>
                    </Link>
                  )}
                </>
              }
            />
          </div>
        </div>
      </SubTopMenu>
      <CancelFormWidget />
      <ReceptionsListWidget />
      <style jsx>
        {`
          :global(.menu-container) {
            height: 48px;
            min-height: unset !important;
          }
          .menu {
            position: sticky;
            top: 0;
            z-index: 2;
          }
          .actions {
            gap: 10px;
          }
        `}
      </style>
    </div>
  );
};

export const AppointmentsMenu = withContexts(AppointmentsMenuBase);
