import React, { createContext, useCallback, useContext, useMemo } from "react";
import type { FC } from "react";
import { useViewerRelationsQuery } from "../generated/gateway-client";
import { Skeleton, useTheme } from "@equiem/react-admin-ui";
import { CurrentProfile, NoAccessPage, Site, SubTopMenu, stringNotEmpty } from "@equiem/lib";
import { Never } from "runtypes";
import type { PageInput } from "./withAuthGuard";
import { useTranslation } from "@equiem/localisation-eq1";

export interface BookingsAuthContext {
  member: boolean;
  canManageSite: boolean;
  canManageSiteCompany: boolean;
  canManageBuildingCompany: boolean;
  canObserveBuildingCompany: boolean;
  canManageCompanyBookingSettings: boolean;
}

export const BookingsAuthContext = createContext<BookingsAuthContext>({
  member: false,
  canManageBuildingCompany: false,
  canManageSite: false,
  canManageSiteCompany: false,
  canObserveBuildingCompany: false,
  canManageCompanyBookingSettings: false,
});

export const BookingsAuthContextProvider: FC<
  PageInput & {
    children: React.ReactNode;
  }
> = ({ children, conditions }) => {
  const site = useContext(Site);

  const featureEnabled = site.featureModules.bookings.enabled;

  const { data, loading } = useViewerRelationsQuery({ skip: !featureEnabled });
  const { canManageCurrentSite, canManageCurrentSiteCompany } = useContext(CurrentProfile);
  const { colors, borderRadius } = useTheme(true);
  const { t } = useTranslation();

  const relations = data?.viewer.currentDestination?.destination.viewerRelations;

  const hasCondition = useCallback(
    ({ relation, tierLevel }: NonNullable<PageInput["conditions"]>[number]) => {
      const isCorrectTier =
        tierLevel == null || (tierLevel === "Unlimited" && site.tierLevel == null) || tierLevel === site.tierLevel;

      switch (relation) {
        case "member":
          return stringNotEmpty(site.uuid) && isCorrectTier;
        case "canManage":
          return canManageCurrentSite && isCorrectTier;
        case "canManageSiteCompany":
          return canManageCurrentSiteCompany && isCorrectTier;
        case "canManageBuildingCompanyBookings":
          return (relations?.canManageBuildingCompanyBookings ?? false) && isCorrectTier;
        case "canObserveBuildingCompanyBookings":
          return (relations?.canObserveBuildingCompanyBookings ?? false) && isCorrectTier;
        case "canManageCompanyBookingSettings":
          return relations?.company.canManageCompanyBookingSettings ?? false;
        default:
          return Never.check(relation);
      }
    },
    [
      site.tierLevel,
      site.uuid,
      canManageCurrentSite,
      canManageCurrentSiteCompany,
      relations?.canManageBuildingCompanyBookings,
      relations?.canObserveBuildingCompanyBookings,
      relations?.company.canManageCompanyBookingSettings,
    ],
  );

  const allowed = useMemo(() => {
    if (loading) {
      return null;
    }

    if (!featureEnabled) {
      return false;
    }

    return conditions?.some(hasCondition) ?? true;
  }, [loading, featureEnabled, conditions, hasCondition]);

  if (loading) {
    return (
      <>
        <SubTopMenu btmPadding={false}>
          <h1 className="mb-3 font-weight-bold">{t("bookings.operations.bookings")}</h1>
        </SubTopMenu>
        <SubTopMenu btmPadding={false} topPadding={false} minHeight={false} alignItems="flex-end">
          <div style={{ height: "48px", display: "flex", alignItems: "center" }}>
            <Skeleton.Line width="6rem" className="mr-6" />
            <Skeleton.Line width="6rem" />
          </div>
        </SubTopMenu>
        <div className="p-6" style={{ background: colors.white }}>
          <Skeleton.Line width="307px" height="170px" borderRadius={borderRadius} />
        </div>
      </>
    );
  }

  if (allowed !== true) {
    return <NoAccessPage />;
  }

  return (
    <BookingsAuthContext.Provider
      value={{
        member: stringNotEmpty(site.uuid),
        canManageSite: canManageCurrentSite,
        canManageSiteCompany: canManageCurrentSiteCompany,
        canManageBuildingCompany: relations?.canManageBuildingCompanyBookings ?? false,
        canObserveBuildingCompany: relations?.canObserveBuildingCompanyBookings ?? false,
        canManageCompanyBookingSettings: relations?.company.canManageCompanyBookingSettings ?? false,
      }}
    >
      {children}
    </BookingsAuthContext.Provider>
  );
};
