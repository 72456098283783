import React from "react";
import { BookingPaymentContact } from "./BookingPaymentContact";
import { useTranslation } from "@equiem/localisation-eq1";
import { useBookingInvoiceContactsQuery } from "../../../../generated/gateway-client";
import { Form, Skeleton } from "@equiem/react-admin-ui";

export const BookingPaymentContacts: React.FC = () => {
  const { t } = useTranslation();
  const { data, loading, updateQuery } = useBookingInvoiceContactsQuery({ fetchPolicy: "network-only" });

  const onDeleteFinish = (contactUuid: string) => {
    updateQuery((prev) => {
      return {
        ...prev,
        bookingInvoiceContacts: prev.bookingInvoiceContacts.filter((c) => {
          if (c.__typename !== "BookingInvoiceContactExisting") {
            return true;
          }

          return c.contactUuid !== contactUuid;
        }),
      };
    });
  };

  if (loading) {
    return (
      <>
        <Skeleton.Line width="25%" height="25px" className="mb-1" />
        <Skeleton.Line width="100%" height="60px" className="d-block mb-3 rounded" />
      </>
    );
  }
  const lists = data?.bookingInvoiceContacts ?? [];

  return lists.length === 0 ? null : (
    <div className="invoice-lists">
      <Form.Group className="mb-3" label={t("bookings.operations.savedInvoiceDetails")}>
        {lists.map((list, i) => (
          <BookingPaymentContact key={i} index={i} list={list} onDeleteFinish={onDeleteFinish} />
        ))}
      </Form.Group>
    </div>
  );
};
