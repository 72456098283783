/**
 * Convert input money to number.
 */
export const convertInputMoneyToNumber = (input?: number | string | null, convertZeroToNull = false) => {
  if (input == null || input === "") {
    return null;
  }

  const target = `${input}`;

  // eslint-disable-next-line @typescript-eslint/no-magic-numbers
  const convert = Number((target.endsWith(".") ? target.slice(0, -1) : target).replace(",", ""));

  if (Number.isNaN(convert)) {
    return null;
  }

  if (convertZeroToNull && convert === 0) {
    return null;
  }

  return convert;
};
