import { getCookiesPreferencesSaved, getOptionalCookiesEnabled } from "@equiem/lib";
import React from "react";

export interface CookieContext {
  cookiePreferencesSaved: boolean;
  setCookiePreferencesSaved: () => void;
  optionalCookiesEnabled: boolean;
  setOptionalCookiesEnabled: (_enabled: boolean) => void;
}

export const CookieSettings = React.createContext<CookieContext>({
  cookiePreferencesSaved: getCookiesPreferencesSaved(),
  setCookiePreferencesSaved: () => {
    //no implementation
  },
  optionalCookiesEnabled: getOptionalCookiesEnabled(),
  setOptionalCookiesEnabled: (_enabled: boolean) => {
    //no implementation
  },
});
