import React, { useContext } from "react";
import { when } from "runtypes";

import { ConfiguredActivityFeed, CurrentProfile, CurrentRole, Role, useShowError } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import { Tag, useConfirmer } from "@equiem/react-admin-ui";
import { RiTeamLine, RiUserHeartLine } from "@equiem/react-admin-ui/icons";

import { StatusTag } from "../../../components/StatusTag";
import type { ReqMgtStatusType, RequestQuery } from "../../../generated/requests-client";
import { ActivityAudience, useCreateCommentMutation } from "../../../generated/requests-client";
import {
  category,
  locationUpdated,
  requestClosed,
  requestOpened,
  statusUpdated,
  subCategoryAdded,
  subCategoryRemoved,
  subCategoryUpdated,
} from "../typeguard/activityRuntypes";

interface Props {
  request: RequestQuery["reqMgt"]["request"];
}

export const Activities: React.FC<Props> = ({ request }) => {
  const [mutation] = useCreateCommentMutation({ refetchQueries: ["Activities"] });
  const { withConfirmation } = useConfirmer();
  const { currentRole } = useContext(CurrentRole);
  const { t } = useTranslation();
  const showError = useShowError();
  const { profile } = useContext(CurrentProfile);

  const receivers = [
    {
      id: "all",
      label: t("requests.activity.everyone"),
      icon: RiTeamLine,
    },
  ];

  if (request.viewerRelations.canManage || request.viewerRelations.assignee || currentRole === Role.PropertyManager) {
    receivers.push({
      id: "team",
      label: t("requests.activity.internalTeam"),
      icon: RiUserHeartLine,
    });
  }

  const isRequestOwner = profile?.uuid === request.reporter?.uuid;

  const onSubmit = (message: string, receiverId?: string) => {
    if (receiverId === "all") {
      withConfirmation({
        title: t("common.areYouSure"),
        message: isRequestOwner
          ? t("requests.activity.publicMessageConfirmationRM")
          : t("requests.activity.publicMessageConfirmation"),
        confirmButtonText: t("requests.activity.publicMessageConfirm"),
        cancelButtonText: t("common.cancelNo"),
        onConfirm: () => {
          mutation({
            variables: {
              input: {
                requestUuid: request.uuid,
                comment: message,
                audience: ActivityAudience.Everyone,
              },
            },
          }).catch(showError);
        },
      })();
    } else {
      mutation({
        variables: {
          input: {
            requestUuid: request.uuid,
            comment: message,
            audience: ActivityAudience.Internal,
          },
        },
      }).catch(showError);
    }
  };

  const customMappings = [
    when(category, (a) => {
      return {
        title: t("requests.activity.categoryChanged"),
        from: <Tag>{a.change.from.category.name}</Tag>,
        to: <Tag>{a.change.to.category.name}</Tag>,
      };
    }),
    when(subCategoryUpdated, (a) => {
      return {
        title: t("requests.activity.subCategoryChanged"),
        from: <Tag>{a.change.from.subCategory.name}</Tag>,
        to: <Tag>{a.change.to.subCategory.name}</Tag>,
      };
    }),
    when(subCategoryRemoved, (a) => {
      return {
        title: t("requests.activity.subCategoryRemoved"),
        to: <Tag>{a.change.from.subCategory.name}</Tag>,
      };
    }),
    when(subCategoryAdded, (a) => {
      return {
        title: t("requests.activity.subCategoryAdded"),
        to: <Tag>{a.change.to.subCategory.name}</Tag>,
      };
    }),
    when(requestOpened, (_a) => ({
      title: t("requests.activity.requestOpened"),
    })),
    when(requestClosed, (a) => {
      return {
        title: t("requests.activity.requestClosed"),
        from: <StatusTag name={a.change.from.status.name} type={a.change.from.status.type as ReqMgtStatusType} />,
        to: <StatusTag name={a.change.to.status.name} type={a.change.to.status.type as ReqMgtStatusType} />,
      };
    }),
    when(statusUpdated, (a) => {
      return {
        title: t("requests.activity.statusChanged"),
        from: <StatusTag name={a.change.from.status.name} type={a.change.from.status.type as ReqMgtStatusType} />,
        to: <StatusTag name={a.change.to.status.name} type={a.change.to.status.type as ReqMgtStatusType} />,
      };
    }),
    when(locationUpdated, (a) => {
      const fromBuilding = a.change.from.space.buildingLevel.building.name;
      const fromLevel = a.change.from.space.buildingLevel.name;
      const fromSpace = a.change.from.space.name;
      const toBuilding = a.change.to.space.buildingLevel.building.name;
      const toLevel = a.change.to.space.buildingLevel.name;
      const toSpace = a.change.to.space.name;

      return {
        title: t("requests.activity.locationChanged"),
        from: (
          <span data-eq-test="location-from">
            {`${fromBuilding}, ${fromLevel}, ${fromSpace}`}
            <br />
          </span>
        ),
        to: <span data-eq-test="location-to">{`${toBuilding}, ${toLevel}, ${toSpace}`}</span>,
      };
    }),
  ];

  const fieldNameMappings = {
    completed: t("requests.activity.dateCompleted"),
    reporter: t("requests.activity.reporter"),
    assignee: t("requests.activity.assignee"),
    reported: t("requests.activity.dateReported"),
    watchers: t("requests.activity.watchers"),
  };

  return (
    <ConfiguredActivityFeed
      customMappings={customMappings}
      fieldNameMappings={fieldNameMappings}
      entityUuid={request.uuid}
      messageOptions={{ receivers, onSubmit }}
    />
  );
};
