import type { ReactNode } from "react";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { Role, CurrentRole, CurrentProfile, Site, RoleFromJWTRole, stringNotEmpty } from "@equiem/lib";
import { authenticate } from "@equiem/lib/clients/gatewayUtils";
import { parseJwt } from "../lib/jwt";
import { SiteLoading } from "../components/layout/SiteLoading";
import { PageContext } from "@equiem/lib/context/PageContext";

export const RoleProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const { usingPostMessageAuth } = useContext(PageContext);
  const { uuid } = useContext(Site);
  const { profile, loading, canManageFlexTenants } = useContext(CurrentProfile);
  const [jwtRoles, setJwtRoles] = useState<string[]>([]);
  const [currentRole, _setCurrentRole] = useState(
    typeof localStorage === "undefined"
      ? Role.Unknown
      : (localStorage.getItem(`CURRENT_ROLE_${uuid}`) as Role | undefined) ?? Role.Unknown,
  );

  const setCurrentRole = useCallback(
    (role: Role) => {
      localStorage.setItem(`CURRENT_ROLE_${uuid}`, role);
      _setCurrentRole(role);
    },
    [uuid],
  );

  const currentDestination = profile?.siteProfiles.edges.find((e) => e.node?.site.destination?.uuid === uuid);

  useEffect(() => {
    const cb = async () => {
      const session = await authenticate(false, usingPostMessageAuth);
      const token = session.accessToken;
      if (token) {
        const decoded = parseJwt(token);

        /**
         * WARNING: Do not update the following url to be https. It's not a URL to be visited.
         * This is the key Equiem one roles are stored against in the JWT.
         */
        const _jwtRoles: string[] =
          decoded?.["http://getequiem.com/eqone_roles"]?.split(",").filter(stringNotEmpty) ?? [];
        if (canManageFlexTenants) {
          _jwtRoles.push("FLEX_MANAGER");
        }

        if (_jwtRoles.length === 0) {
          _jwtRoles.push("NONE");
        }

        const currRole: string | undefined =
          _jwtRoles.find((r) => r.replace("_", " ").toUpperCase() === currentRole.toUpperCase()) ?? _jwtRoles[0];
        setCurrentRole(RoleFromJWTRole(currRole));
        setJwtRoles(_jwtRoles);
      }
    };

    cb().catch((e) => {
      console.error(e);
    });
  }, [currentDestination, currentRole, setCurrentRole, canManageFlexTenants, usingPostMessageAuth]);

  if (loading) {
    return <SiteLoading />;
  }

  return (
    <CurrentRole.Provider
      value={{
        currentRole,
        setCurrentRole,
        jwtRoles,
      }}
    >
      {children}
    </CurrentRole.Provider>
  );
};
