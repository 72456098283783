import { useMemo } from "react";

import { stringNotEmpty } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import type { FilterItem } from "@equiem/react-admin-ui";
import { FilterOptionsModifier } from "@equiem/react-admin-ui";
import { RiAccountCircleLine } from "@equiem/react-admin-ui/icons";

interface Person {
  uuid: string;
  firstName?: string | null;
  lastName?: string | null;
  email: string;
}
export function useRequestFilterReporter(reporters?: Person[]) {
  const { t } = useTranslation();

  const options = useMemo(
    () =>
      (reporters ?? [])
        .map((r) => ({
          label: `${r.firstName} ${r.lastName}`,
          value: r.uuid,
          searchKeywords: [r.firstName ?? "", r.lastName ?? "", r.email].filter(stringNotEmpty),
        }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    [reporters],
  );

  const filter: FilterItem = {
    title: t("requests.reporter"),
    type: "options",
    options,
    icon: RiAccountCircleLine,
    modifiers: [FilterOptionsModifier.includes],
  };

  return {
    filter: filter.options.length > 0 ? filter : null,
  };
}
