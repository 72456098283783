import React from "react";
import { useTranslation } from "@equiem/localisation-eq1";
import { Button, useTheme } from "@equiem/react-admin-ui";
import { useRouter } from "next/router";

export const ActivityContainerTitle = ({ isViewActivityDisabled }: { isViewActivityDisabled: boolean }) => {
  const { t } = useTranslation();
  const { colors } = useTheme();
  const router = useRouter();

  return (
    <>
      <div className="header title">
        <h1>{t("credits.recent.creditsActivity")}</h1>
        <div className="button-container">
          <Button
            variant="outline"
            disabled={isViewActivityDisabled}
            onClick={() => {
              void router.push("/credits/credits-activity").catch(console.log);
            }}
          >
            {t("credits.recent.viewActivity")}
          </Button>
        </div>
      </div>
      <style jsx>{`
        .header {
          display: flex;
          padding: 0 16px;
          align-items: center;
          justify-content: space-between;
        }
        .title {
          margin-top: 0.25rem;
        }
        .header h1 {
          margin: 0;
          font-weight: 700;
          font-size: 20px;
          line-height: 24px;
          color: ${colors.dark};
        }
      `}</style>
    </>
  );
};
