"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.makeApolloRequester = void 0;
/* eslint-disable @typescript-eslint/no-explicit-any */
var client_1 = require("@apollo/client");
var validDocDefOps = ["mutation", "query", "subscription"];
var isOperationDefinition = function (d) {
    return d.kind === "OperationDefinition" && validDocDefOps.includes(d.operation);
};
/**
 * Adapted from: https://gist.github.com/akozhemiakin/731b0c1e99eb89b01f80f08f9146b6b6
 * Via: https://the-guild.dev/graphql/codegen/plugins/typescript/typescript-generic-sdk#usage
 */
var makeApolloRequester = function (client) {
    return (function (doc, variables, options) { return __awaiter(void 0, void 0, void 0, function () {
        var operationDefinitions, definition, result, _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    operationDefinitions = doc.definitions.filter(isOperationDefinition);
                    // Valid document should contain *single* query or mutation unless it's has a fragment
                    if (operationDefinitions.length !== 1) {
                        throw new Error("DocumentNode passed to Apollo Client must contain single query or mutation");
                    }
                    definition = operationDefinitions[0];
                    _a = definition.operation;
                    switch (_a) {
                        case "mutation": return [3 /*break*/, 1];
                        case "query": return [3 /*break*/, 3];
                        case "subscription": return [3 /*break*/, 5];
                    }
                    return [3 /*break*/, 6];
                case 1: return [4 /*yield*/, client.mutate(__assign({ mutation: doc, variables: variables }, options))];
                case 2:
                    result = _b.sent();
                    return [3 /*break*/, 7];
                case 3: return [4 /*yield*/, client.query(__assign({ query: doc, variables: variables }, options))];
                case 4:
                    result = _b.sent();
                    return [3 /*break*/, 7];
                case 5: throw new Error("Subscription requests through SDK interface are not supported");
                case 6: throw new Error("Unexpected operation");
                case 7:
                    if (result.errors != null) {
                        throw new client_1.ApolloError({ graphQLErrors: result.errors });
                    }
                    if (result.data === undefined || result.data === null) {
                        throw new Error("No data presented in the GraphQL response");
                    }
                    return [2 /*return*/, result.data];
            }
        });
    }); });
};
exports.makeApolloRequester = makeApolloRequester;
