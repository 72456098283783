import React from "react";
import { Field } from "formik";

import { stringNotEmpty, useSaferFormikContext } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import { Button, Form as EqForm, useToast } from "@equiem/react-admin-ui";
import { RiCheckLine, RiCloseLine } from "@equiem/react-admin-ui/icons";

import type { GeneralSettingsFormValues } from "../GeneralSettingsValues";
import { useAppointmentTypes } from "../hooks/useAppointmentTypes";

export const AddingVisitorType = ({ onSave, onClose }: { onSave: () => void; onClose: () => void }) => {
  const { t } = useTranslation();
  const { values, errors } = useSaferFormikContext<GeneralSettingsFormValues>();
  const { appointmentTypeOptions } = useAppointmentTypes();
  const toast = useToast();

  return (
    <>
      <div className="adding-type">
        <Field
          variant="sm"
          id="newVisitorType"
          placeholder={t("visitors.settings.addVisitorTypePlaceholder")}
          name="newVisitorType"
          type="text"
          as={EqForm.Input}
        />
        <Field
          name="newVisitorAppointmentType"
          options={appointmentTypeOptions}
          as={EqForm.MultiSelect}
          size="small"
          withSearch={false}
          mobileView
          variant="wrap"
          placeholder={t("visitors.settings.chooseAppointmentType")}
          isMulti
        />
        <Button
          variant="primary"
          round
          className="ml-4"
          disabled={values.newVisitorType.trim().length === 0}
          onClick={() => {
            if (stringNotEmpty(errors.newVisitorType)) {
              toast.negative(errors.newVisitorType, { showClose: true });
              return;
            }
            onSave();
          }}
        >
          <RiCheckLine size={16} />
        </Button>
        <Button variant="outline" round onClick={onClose} className="ml-2">
          <RiCloseLine size={16} />
        </Button>
      </div>
      <style jsx>
        {`
          .adding-type {
            width: 100%;
            display: grid;
            gap: 4px;
            align-items: center;
            grid-template-columns: 1fr 1fr auto auto;
          }
        `}
      </style>
    </>
  );
};
