import React, { useContext, forwardRef } from "react";
import type { NavLinkProps } from "../../NavLink/NavLink";
import { NavLink } from "../../NavLink/NavLink";

import { DropdownContext } from "../Dropdown";

export const DropdownLink = forwardRef<HTMLAnchorElement, NavLinkProps>(({ children, ...props }, ref) => {
  const { autoClose } = useContext(DropdownContext);

  return (
    <NavLink
      {...props}
      ref={ref}
      onClick={(e) => {
        autoClose();
        props.onClick?.(e);
      }}
    >
      {children}
    </NavLink>
  );
});

DropdownLink.displayName = "DropdownLink";
