import { Card } from "@equiem/react-admin-ui";
import React from "react";

export class ErrorBoundary extends React.Component<{ children?: React.ReactNode }, { hasError: boolean }> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  public static getDerivedStateFromError(_error: unknown) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  public componentDidCatch(error: unknown, errorInfo: unknown) {
    // You can also log the error to an error reporting service
    console.log(error, errorInfo);
  }

  // eslint-disable-next-line @typescript-eslint/promise-function-async
  public render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <Card.Card>
          <Card.CardBody>
            <h3>Something went wrong</h3>
          </Card.CardBody>
        </Card.Card>
      );
    }

    return this.props.children;
  }
}
