import React, { createContext, useState, useContext, useEffect } from "react";

export const SideMenuContext = createContext({
  setSiteSwitcherEnabled: (_enabled: boolean): void => undefined,
  siteSwitcherEnabled: true,
});

export const SideMenuProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const [siteSwitcherEnabled, setSiteSwitcherEnabled] = useState(true);

  return (
    <SideMenuContext.Provider
      value={{
        siteSwitcherEnabled,
        setSiteSwitcherEnabled,
      }}
    >
      {children}
    </SideMenuContext.Provider>
  );
};

export const useDisabledSiteSwitcher = () => {
  const { setSiteSwitcherEnabled } = useContext(SideMenuContext);
  useEffect(() => {
    setSiteSwitcherEnabled(false);
    return () => setSiteSwitcherEnabled(true);
  }, [setSiteSwitcherEnabled]);
};
