import React, { useCallback, useEffect, useState } from "react";

import { useShowError } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import { useTheme, useToast } from "@equiem/react-admin-ui";

import { useWidgetContext } from "../../../contexts/WidgetContext";
import type { CompanyDetailsQuery } from "../../../generated/settings-client";

import { CreditPaymentsPermission } from "./CreditPaymentsPermission";
import { RequestManagementPermission } from "./RequestManagementPermission";

interface P {
  company: NonNullable<CompanyDetailsQuery["companyV2"]>;
  modalClose: () => void;
}
export const CompanyPermissions: React.FC<P> = ({ company, modalClose }) => {
  const { t } = useTranslation();
  const { colors, spacers } = useTheme();
  const toast = useToast();
  const showError = useShowError();
  const { subscribe, setActionState } = useWidgetContext();
  const [saveHandlers, setSaveHandlers] = useState<Record<string, () => Promise<boolean>>>({});

  const registerSaveHandler = useCallback((key: string, handler: () => Promise<boolean>) => {
    setSaveHandlers((prev) => ({
      ...prev,
      [key]: handler,
    }));
  }, []);

  const handleSave = useCallback(async () => {
    setActionState("onSave", "loading");

    try {
      const results = await Promise.all(Object.values(saveHandlers).map(async (handler) => handler()));
      if (results.some((result) => result)) {
        toast.positive(t("settings.createCompany.companySuccessfullyUpdated", { companyName: company.name }));
      }
    } catch (error: unknown) {
      showError(error);
    } finally {
      setActionState("onSave", "normal");
      modalClose();
    }
  }, [company.name, modalClose, saveHandlers, setActionState, showError, t, toast]);

  useEffect(() => {
    return subscribe("onSave", () => {
      void handleSave();
    });
  }, [handleSave, subscribe]);

  return (
    <>
      <p>{t("settings.editCompany.permissionIntro")}</p>
      <div className="permissions-container">
        <RequestManagementPermission
          company={company}
          registerSaveHandler={(handler) => registerSaveHandler("requestManagement", handler)}
        />
        {company.flexTenants.length > 0 && (
          <>
            <hr />
            <CreditPaymentsPermission
              company={company}
              registerSaveHandler={(handler) => registerSaveHandler("creditPayments", handler)}
            />
          </>
        )}
      </div>
      <style jsx>
        {`
          .permissions-container hr {
            border: none;
            border-top: 1px solid ${colors.grayscale[10]};
            margin: ${spacers.s6} ${spacers.s0};
          }
          .permissions-container :global(h4) {
            font-weight: bold;
          }
          .permissions-container :global(.permission-form) {
            display: flex;
            flex-direction: column;
            gap: ${spacers.s5};
            align-items: flex-start;
          }
          .permissions-container :global(.permission-form .form-group) {
            margin-bottom: ${spacers.s0};
          }
        `}
      </style>
    </>
  );
};
