import { stringNotEmpty } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import { Form, useTheme, Skeleton, useDebounced } from "@equiem/react-admin-ui";
import { DateTime } from "luxon";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { BookingFormFlexibleTime } from "./BookingFormFlexibleTime";
import { BookingFormSlotsTime } from "./BookingFormSlotsTime";
import {
  BookableResourceAvailabilityType,
  useBookableResourceAvailabilityListingQuery,
} from "../../../generated/gateway-client";
import { getAvailabilityDateRange } from "../../../lib/getAvailabilityDateRange";
import { BookingModalInfo } from "../contexts/BookingModalInfoProvider";
import { dateFormat, timeFormat } from "../libs/formatSelectedTime";
import { BookingModal } from "../contexts/BookingModalContext";
import type { BookingFormValue } from "../models/BookingFormValue";
import { useFormikContext } from "formik";
import { BookingFormRecurring } from "./BookingFormRecurring";
import type { ListingTime } from "../models/ListingTime";

const debounceTime = 500;

type ProcessedListingTimes = Partial<Record<number, ListingTime>>;

const BookingFormAvailableTime: React.FC<{ selectedDate: string }> = ({ selectedDate }) => {
  const { t } = useTranslation();
  const { booking, resource, timezone } = useContext(BookingModalInfo);
  const { borderRadius, spacers } = useTheme();
  const modal = useContext(BookingModal);

  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const date = DateTime.fromFormat(selectedDate, dateFormat, { zone: timezone });
  const dateInfo = useMemo(() => {
    const dt = DateTime.fromFormat(selectedDate, dateFormat, { zone: timezone });
    const now = DateTime.fromObject({}, { zone: timezone });
    const isToday = now.hasSame(dt, "day");
    const start = isToday ? now.toMillis() : dt.startOf("day").toMillis();
    const end = isToday ? now.endOf("day").toMillis() : dt.endOf("day").toMillis();

    return { start, end, skip: !dt.isValid };
  }, [selectedDate, timezone]);

  const { start, end, skip } = useDebounced(dateInfo, debounceTime);

  const query = useBookableResourceAvailabilityListingQuery({
    variables: { uuid: resource.uuid, start, end, excludeBooking: booking?.uuid },
    fetchPolicy: "no-cache",
    skip,
  });

  const listingTimes = Object.values(
    (query.data?.bookableResource.availabilityCalendar ?? []).reduce<ProcessedListingTimes>((prev, curr) => {
      if (curr.__typename === "BookableResourceCalendarAvailable") {
        prev[curr.availabilityIndex] = {
          type: curr.type,
          title: curr.availability.title ?? "",
          minTimeInMinutes: curr.minTimeInMinutes,
          times: (prev[curr.availabilityIndex]?.times ?? []).concat([
            {
              start: curr.startTime,
              end: curr.endTime,
              startHourMin: DateTime.fromMillis(curr.startTime, { zone: timezone }).toFormat("HH:mm"),
              endHourMin: DateTime.fromMillis(curr.endTime, { zone: timezone }).toFormat("HH:mm"),
            },
          ]),
        };
      }

      return prev;
    }, {}),
  ).flatMap((x) => x ?? []);
  const flexibleMode = listingTimes.some((tl) => tl.type === BookableResourceAvailabilityType.Flexible);

  useEffect(() => {
    modal.setCanSubmitForm(!query.loading);
  }, [modal, query.loading]);

  useEffect(() => {
    if (query.error != null || listingTimes.length === 0) {
      modal.setCanSubmitForm(false);
      setErrorMessage(
        query.error != null ? t("bookings.operations.failedToLoad") : t("bookings.operations.noAvailability"),
      );
    } else {
      setErrorMessage(null);
    }
  }, [query.error, listingTimes.length, t, modal]);

  if (query.loading) {
    return (
      <div className="loading-skeleton">
        <Skeleton.Line height="30px" width="100px" borderRadius={borderRadius} />
        <Skeleton.Line height="30px" width="100px" borderRadius={borderRadius} />
        <Skeleton.Line height="30px" width="100px" borderRadius={borderRadius} />
        <Skeleton.Line height="30px" width="100px" borderRadius={borderRadius} />
        <style jsx>{`
          .loading-skeleton {
            display: flex;
            gap: ${spacers.s3};
            flex-wrap: wrap;
            padding: 0 0 ${spacers.s6};
          }
        `}</style>
      </div>
    );
  }

  if (errorMessage !== null) {
    return (
      <Form.Group label={t("common.time")} required>
        {errorMessage}
      </Form.Group>
    );
  }

  return (
    <>
      {flexibleMode ? (
        <BookingFormFlexibleTime
          date={date.toMillis()}
          listingTimes={listingTimes}
          selectFirstAvailability={modal.selectFirstAvailability}
        />
      ) : (
        <BookingFormSlotsTime
          date={date.toMillis()}
          listingTimes={listingTimes}
          selectFirstAvailability={modal.selectFirstAvailability}
        />
      )}
      <BookingFormRecurring />
    </>
  );
};

export const BookingFormTime: React.FC = () => {
  const { t } = useTranslation();
  const { resource, timezone } = useContext(BookingModalInfo);
  const fm = useFormikContext<BookingFormValue>();
  const modal = useContext(BookingModal);
  const hasSuperPower = fm.values.hasSuperPower === true;

  const dateInAvailabilityrange = useMemo(() => {
    if (fm.values.date == null) {
      return false;
    }
    if (hasSuperPower) {
      return true;
    }

    const date = DateTime.fromFormat(fm.values.date, dateFormat, { zone: timezone });
    const range = getAvailabilityDateRange(
      resource.availabilityDateRange,
      resource.bookingWindowMinInMinutes,
      resource.bookingWindowMaxInMinutes,
      timezone,
    );
    return date >= range.startDate.startOf("day") && (range.endDate == null || date <= range.endDate.endOf("day"));
  }, [
    fm.values.date,
    hasSuperPower,
    timezone,
    resource.availabilityDateRange,
    resource.bookingWindowMinInMinutes,
    resource.bookingWindowMaxInMinutes,
  ]);

  useEffect(() => {
    if (!dateInAvailabilityrange) {
      modal.setCanSubmitForm(false);
    }
  }, [dateInAvailabilityrange, modal]);

  useEffect(() => {
    if (stringNotEmpty(fm.values.date) && stringNotEmpty(fm.values.start) && stringNotEmpty(fm.values.end)) {
      const dateDT = DateTime.fromFormat(fm.values.date, dateFormat, { zone: timezone });
      const startDT = DateTime.fromFormat(fm.values.start, timeFormat);
      const endDT = DateTime.fromFormat(fm.values.end, timeFormat);
      modal.setStart(dateDT.plus({ hour: startDT.hour, minute: startDT.minute }).toMillis());
      modal.setEnd(dateDT.plus({ hour: endDT.hour, minute: endDT.minute }).toMillis());
    } else {
      modal.setStart(undefined);
      modal.setEnd(undefined);
    }
  }, [fm.values.start, fm.values.end, fm.values.date, modal, timezone]);

  return (
    <>
      {fm.values.date != null && dateInAvailabilityrange ? (
        <BookingFormAvailableTime selectedDate={fm.values.date} />
      ) : (
        <Form.Group label={t("common.time")} required>
          {t("bookings.operations.noAvailability")}
        </Form.Group>
      )}
    </>
  );
};
