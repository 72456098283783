import type { Dispatch, SetStateAction } from "react";
import React, { createContext, useState } from "react";

import type { NewRequestFormValues } from "../../create-request/NewRequest";

interface NewRequestContext {
  requestForm?: NewRequestFormValues | null;
  cacheRequestForm: (form: NewRequestFormValues) => void;
  isNewRequest: boolean;
  setIsNewRequest: (isNewRequest: boolean) => void;
  clearCacheRequestForm: () => void;
  isAttachmentUploading: boolean;
  setIsAttachmentUploading: Dispatch<SetStateAction<boolean>>;
  closeNewRequest: () => void;
}

export const NewRequestContext = createContext<NewRequestContext>({
  requestForm: undefined,
  isNewRequest: false,
  setIsNewRequest: () => {
    throw new Error("setIsNewRequest not implemented");
  },
  cacheRequestForm: () => {
    throw new Error("cacheRequestForm not implemented");
  },
  clearCacheRequestForm: () => {
    throw new Error("clearCacheRequestForm not implemented");
  },
  isAttachmentUploading: false,
  setIsAttachmentUploading: () => {
    throw new Error("setIsAttachmentUploading not implemented");
  },
  closeNewRequest: () => {
    throw new Error("closeNewRequest not implemented");
  },
});

export const NewRequestProvider = ({ children }: { children: React.ReactNode }) => {
  const [isNewRequest, setIsNewRequest] = useState(false);
  const [isAttachmentUploading, setIsAttachmentUploading] = useState(false);
  const [requestForm, cacheRequestForm] = useState<NewRequestFormValues | undefined>(undefined);

  const clearCacheRequestForm = () => {
    cacheRequestForm(undefined);
  };

  const closeNewRequest = () => {
    setIsNewRequest(false);
    clearCacheRequestForm();
  };

  return (
    <NewRequestContext.Provider
      value={{
        requestForm,
        isNewRequest,
        setIsNewRequest,
        cacheRequestForm,
        clearCacheRequestForm,
        isAttachmentUploading,
        setIsAttachmentUploading,
        closeNewRequest,
      }}
    >
      {children}
    </NewRequestContext.Provider>
  );
};
