import React from "react";
import { useTranslation } from "@equiem/localisation-eq1";

import * as Modal from "../Modal";
import { AdminButton as Button } from "../Button/AdminButton";
import { EscapedHtmlDiv } from "../EscapedHtml";
import { useTheme } from "../../contexts";

export type TermsModalType = "termsAndConditions" | "privacyPolicy";

interface Props {
  type: TermsModalType;
  siteName: string;
  terms: string;
  show: boolean;
  onHide: () => void;
  headerOverride?: string;
}

export const TermsModal: React.FC<Props> = ({ type, siteName, terms, show, onHide, headerOverride }) => {
  const { colors, borderRadius } = useTheme();
  const { t } = useTranslation();

  const title = type === "privacyPolicy" ? t("common.privacyPolicy") : t("common.termsAndConditions");
  const header =
    headerOverride ??
    (type === "privacyPolicy"
      ? t("common.platformPrivacyPolicy", { siteName })
      : t("common.platformTermsAndConditions", { siteName }));

  return (
    <>
      <Modal.Dialog title={title} size="lg" hideOnEsc hideOnClick show={show} onHide={onHide}>
        <Modal.Header intro={header} closeButton onClose={onHide} />
        <Modal.Body>
          <div>
            <EscapedHtmlDiv html={terms} />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={onHide}>
            {t("common.done")}
          </Button>
        </Modal.Footer>
      </Modal.Dialog>
      <style jsx>{`
        div {
          max-height: 400px;
          overflow-y: auto;
          padding: 8px;
          border: 1px solid ${colors.border};
          border-radius: ${borderRadius};
        }
      `}</style>
    </>
  );
};
