import React, { useState } from "react";

import { useTranslation } from "@equiem/localisation-eq1";
import { Avatar, Dropdown, Form as EqForm, Tag, Text, useTheme } from "@equiem/react-admin-ui";
import { RiSearchLine } from "@equiem/react-admin-ui/icons";

import type { Visitor, VisitorAppointment } from "../../../generated/visitors-client";

const VisitorItem = ({ item }: { item: Visitor }) => {
  return (
    <div className="visitor-item" key={item.uuid}>
      <div className="header">
        <Avatar size="medium" firstName={item.firstName} lastName={item.lastName} />
      </div>

      <Text color="black" weight="medium" variant="text" size="small">
        <span className="visitor-item--capitalize">{item.firstName}</span>{" "}
        <span className="visitor-item--capitalize">{item.lastName}</span>
      </Text>
      <style jsx>{`
        .visitor-item--capitalize {
          text-transform: capitalize;
        }
        .visitor-item {
          display: flex;
          align-items: center;
          gap: 8px;
        }
      `}</style>
    </div>
  );
};

export const DropdownGuest = ({ appointment }: { appointment: VisitorAppointment }) => {
  const { t } = useTranslation();
  const { colors } = useTheme();
  const visitorsCountText = t("visitors.appointments.visitors", { count: appointment.visitors.length });

  const [search, setSearch] = useState("");

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const visitors = appointment.visitors.filter((item) =>
    `${item.firstName} ${item.lastName}`.toLowerCase().includes(search.toLowerCase()),
  );

  return (
    <>
      <div className="guests">
        <Dropdown.Container
          width={240}
          placement="bottom-end"
          mobileView="regular"
          fixedHeader={
            <div className="px-4 pt-4 pb-2">
              <Text
                variant="text"
                color={colors.grayscale["60"]}
                className="my-2 guests-title"
                size="extra-small"
                weight="medium"
              >
                {t("visitors.appointments.guestsList", { count: appointment.visitors.length })}
              </Text>
              <div className="mt-4">
                <EqForm.InputExtended
                  className="search-input"
                  icon={RiSearchLine}
                  placeholder={`${t("visitors.appointments.findGuest")}`}
                  variant="sm"
                  value={search}
                  onChange={handleSearch}
                  clearable
                />
              </div>
            </div>
          }
          maxHeight={350}
          trigger={
            <Tag style={{ overflow: "hidden" }} tabIndex={0} className="mr-2 cursor-pointer">
              {visitorsCountText}
            </Tag>
          }
        >
          <div className="px-4">
            <div>
              {visitors.map((item) => (
                <VisitorItem item={item} key={item.uuid} />
              ))}
              {visitors.length === 0 && (
                <Text variant="text" size="small">
                  {t("visitors.appointments.notFound")}
                </Text>
              )}
            </div>
          </div>
        </Dropdown.Container>
      </div>
      <style jsx>{`
        .guests :global(.section-mobile) {
          max-height: 100%;
          margin-top: 0;
          display: flex;
          flex-direction: column;
          overflow: hidden;
        }
        .guests :global(section) {
          padding-top: 0;
        }
        .guests :global(.guests-title) {
          text-transform: uppercase;
        }
        @media (max-width: 768px) {
          .guests :global(.header-mobile--short) {
            top: 12px;
          }
          .guests :global(section.section-mobile .content) {
            max-height: 100%;
            height: 100%;
            flex-grow: 1;
          }
        }
      `}</style>
    </>
  );
};
