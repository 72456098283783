import React, { useContext, useEffect } from "react";

import { useTranslation } from "@equiem/localisation-eq1";
import { AdvancedButton, ProgressCircle, Text } from "@equiem/react-admin-ui";
import { RiBuilding4Fill } from "@equiem/react-admin-ui/icons";

import { CreateRequestContext } from "../CreateRequestContext";
import { useBuildingData } from "../hooks/useBuildingData";

export const BuildingStep: React.FC = () => {
  const { step, navigationDirection, nextStep, setValues } = useContext(CreateRequestContext);
  const { t } = useTranslation();
  const { buildings, loading } = useBuildingData();

  useEffect(() => {
    if (buildings.length === 1 && navigationDirection === "forward") {
      setValues((prev) => ({ ...prev, buildingUuid: buildings[0].uuid }));
      nextStep();
    }
  }, [buildings, navigationDirection, nextStep, setValues]);

  const handleSelect = (uuid: string) => () => {
    setValues((prev) => ({ ...prev, buildingUuid: uuid }));
    nextStep();
  };

  return (
    <div className="container">
      <Text variant="label">{t("common.step", { step })}</Text>
      <Text variant="heading" size="small">
        {t("common.selectBuilding")}
      </Text>
      <div className="list">
        {loading && <ProgressCircle size="md" className="w-100 d-flex justify-content-center mt-5" />}
        {!loading &&
          buildings.map((building) => (
            <AdvancedButton
              title={building.name}
              icon={RiBuilding4Fill}
              className="mx-0"
              key={building.uuid}
              onClick={handleSelect(building.uuid)}
            />
          ))}
      </div>
      <style jsx>
        {`
          .container {
            display: flex;
            flex-direction: column;
          }

          .list {
            display: flex;
            flex-direction: column;
            gap: 8px;
            margin-top: 24px;
          }
        `}
      </style>
    </div>
  );
};
