import React, { forwardRef, useContext } from "react";
import { useTheme } from "../../contexts/Theme";
import type { IconType } from "react-icons";
import { RiArrowRightLine } from "../../admin-icons";
import { Material, Text } from "..";
import { useInputBorderCss } from "../Form/useInputBorderCss";
import { FormGroupContext } from "../../contexts/FormGroupContext";

interface Props {
  title: string;
  description?: string;
  icon?: IconType;
  materialIcon?: string;
  className?: string;
  disabled?: boolean;
  children?: React.ReactNode;
  onClick?: () => void;
}

export const AdvancedButton = forwardRef<HTMLButtonElement, Props>(
  ({ title, description, icon: Icon, materialIcon, className = "", disabled, children, onClick }, ref) => {
    const { colors } = useTheme();
    const borderCss = useInputBorderCss();
    const { hasError } = useContext(FormGroupContext);

    const isNormalLayout = Icon != null || materialIcon != null;

    const renderIcon = () => {
      if (Icon != null) {
        return <Icon size={24} fill={colors.blue[60]} />;
      }

      if (materialIcon != null) {
        return <Material.Icon name={materialIcon} size="md" color={colors.blue[60]} />;
      }

      return null;
    };

    return (
      <>
        <button
          type="button"
          disabled={disabled}
          onClick={onClick}
          className={`${className} ${borderCss.className} ${hasError ? "error" : ""}`}
          ref={ref}
        >
          <div className="w-100 container">
            <div className="control">
              {isNormalLayout ? (
                renderIcon()
              ) : (
                <Text variant="heading" size="small">
                  {title}
                </Text>
              )}
              <div className="icon">
                <RiArrowRightLine size={18} />
              </div>
            </div>
            {isNormalLayout && (
              <div className="info">
                <Text variant="heading" size="small" className="mb-2">
                  {title}
                </Text>
                {description != null && (
                  <Text variant="text" size="extra-small" className="description m-0">
                    {description}
                  </Text>
                )}
              </div>
            )}
            {children}
          </div>
        </button>
        <style jsx>
          {`
            .container {
              padding: ${isNormalLayout ? "0.75rem" : "0.75rem 0.75rem 0.75rem 1rem"};
            }

            button {
              display: flex;
              flex-direction: column;
              text-align: left;
              cursor: pointer;
              background-color: ${colors.white};
              color: ${colors.transparent.black[80]};
              padding: 0;
              overflow: hidden;
            }
            button:hover {
              border-color: ${colors.primary} !important;
            }
            button.error {
              border-color: ${colors.status.danger.primary};
            }
            button .icon {
              display: flex;
              background-color: transparent;
              border-radius: 50%;
              height: 30px;
              width: 30px;
              text-align: center;
              align-items: center;
              justify-content: center;
            }
            button:hover .icon {
              background-color: ${colors.grayscale[5]};
            }
            .control {
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin-bottom: ${isNormalLayout ? "1rem" : "0"};
            }
            .info {
              display: flex;
              flex-direction: column;
            }
            .info :global(.description) {
              color: ${colors.transparent.black[60]};
            }
            .info :global(h1),
            .control :global(h1) {
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              max-width: calc(100% - 32px);
            }
          `}
        </style>
        {borderCss.styles}
      </>
    );
  },
);

AdvancedButton.displayName = "AdvancedButton";
