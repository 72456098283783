import React from "react";
import { MembershipPageInner } from "../../components/membership/MembershipPageInner";
import { SideModalProvider } from "../../contexts/SideModalProvider";
import { useTheme } from "@equiem/react-admin-ui";
import { FlexTenantProvider } from "../../contexts/FlexTenantProvider";

export const MembershipsPage = () => {
  const { colors } = useTheme();

  return (
    <div className="cont">
      <FlexTenantProvider>
        <SideModalProvider>
          <MembershipPageInner />
        </SideModalProvider>
      </FlexTenantProvider>
      <style jsx>{`
        .cont {
          background: ${colors.white};
          min-height: calc(100vh - 65px);
        }
      `}</style>
    </div>
  );
};
