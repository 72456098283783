import React from "react";
import { useTheme } from "@equiem/react-admin-ui";
import { SiteIcon } from "./SiteIcon";
import { RolesIcon } from "./RolesIcon";
import { ShortcutIcon } from "./ShortcutIcon";

export const SideMenu: React.FC = () => {
  const { colors, spacers } = useTheme();

  return (
    <>
      <div className="sidebar">
        <div className="site-section border">
          <SiteIcon />
          <RolesIcon />
        </div>
        <div className="section">
          <ShortcutIcon />
          <ShortcutIcon />
          <ShortcutIcon />
          <ShortcutIcon />
          <ShortcutIcon />
          <ShortcutIcon />
        </div>
      </div>
      <style jsx>
        {`
          .section {
            padding: ${spacers.s5} ${spacers.s4};
            min-height: 64px;
          }
          .site-section {
            border-bottom: solid 1px rgba(0, 0, 0, 0.1);
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: ${spacers.s2} ${spacers.s4};
            height: 62px;
          }
          .section.border {
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
          }
          .logo-section {
            display: flex;
            align-items: center;
          }
          .sidebar {
            position: sticky;
            top: 0;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            background: ${colors.white};
            width: 240px;
          }
          .bottom {
            margin-top: auto;
          }
        `}
      </style>
    </>
  );
};
