import type { ComponentProps, FC } from "react";
import React, { useMemo } from "react";
import type { CreateCmsArticleInput, DocumentV2FragmentFragment } from "../../generated/gateway-client";
import { ArticleContent } from "./render/ArticleContent";
import { DateTime } from "luxon";

export type Props = ComponentProps<"div"> & {
  publishTime?: string;
  publishDate?: string;
  article: Omit<CreateCmsArticleInput, "portfolio">;
  downloads?: DocumentV2FragmentFragment[];
  images?: DocumentV2FragmentFragment[];
};

export const PreviewCard: FC<Props> = ({ article, publishDate, publishTime, downloads, images, ...props }) => {
  const publishDateTime = useMemo(() => {
    const dt = DateTime.utc();
    if (publishDate != null && publishTime != null) {
      const dateParts = publishDate.split("-");
      const timeParts = publishTime.split(":");

      dt.set({
        year: parseInt(dateParts[0], 10),
        month: parseInt(dateParts[1], 10),
        day: parseInt(dateParts[2], 10),
        hour: parseInt(timeParts[0], 10),
        minute: parseInt(timeParts[1], 10),
        second: parseInt(timeParts[2], 10),
      });
    }
    return dt.valueOf();
  }, [publishDate, publishTime]);

  return (
    <>
      <div {...props} className="card">
        <ArticleContent
          showAuthorTooltip={true}
          article={{
            type: "Article",
            author: {
              displayName: "Author",
              firstName: "Author",
              lastName: null,
              avatarUrl: null,
            },
            publishDate: publishDateTime,
            title: article.title ?? undefined,
            excerpt: article.excerpt ?? undefined,
            body: article.body ?? undefined,
            categories: [],
            relatedInfo: [],
            downloads: downloads ?? [],
            images: images ?? [],
            featuredImageUrl: null,
          }}
        />
      </div>
      <style jsx>
        {`
          .card {
            border-radius: 8px;
            background-color: #fff;
            padding: 12px 16px 12px 12px;
            border: 1px solid #e6e6e6;
          }
        `}
      </style>
    </>
  );
};
