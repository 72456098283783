import React, { useContext, useEffect, useMemo } from "react";

import { useMyCompaniesFlexTenantsQuery, useMyFlexTenantsQuery } from "@equiem/flex/generated/gateway-client";
import { CurrentRole, Role, useSaferFormikContext } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import { Form as EqForm, Text, useTheme } from "@equiem/react-admin-ui";

import { useWidgetContext } from "../../../contexts/WidgetContext";
import type { ProfileDetailsQuery } from "../../../generated/settings-client";
import { FlexMembershipsContext } from "../contexts/FlexMembershipsContext";
import type { FormValues } from "../types";

interface Props {
  user: NonNullable<ProfileDetailsQuery["profile"]>;
}

export const UserFlexMemberships: React.FC<Props> = ({ user }) => {
  const { t } = useTranslation();
  const { currentRole } = useContext(CurrentRole);
  const { flexTenantUuids, setFlexTenantUuids } = useContext(FlexMembershipsContext);
  const { spacers, colors } = useTheme();
  const { submitForm } = useSaferFormikContext<FormValues>();
  const { subscribe } = useWidgetContext();
  const { data } = useMyCompaniesFlexTenantsQuery();
  const { data: flex } = useMyFlexTenantsQuery({
    variables: {
      filters: {
        buildings: [],
        sites: [],
        companies: user.companyV2 != null ? [user.companyV2.uuid] : [],
      },
    },
  });

  useEffect(() => {
    setFlexTenantUuids(user.flexMemberships.map((fm) => fm.flexTenant.uuid));
  }, [user, setFlexTenantUuids]);

  useEffect(() => {
    return subscribe("onSave", () => {
      void submitForm();
    });
  }, [submitForm, subscribe]);

  const companyFlexTenants = useMemo(() => {
    return currentRole === Role.WorkplaceManager
      ? data?.myCompaniesFlexTenants.map((ft) => ({
          value: ft.uuid,
          label: ft.name,
        })) ?? []
      : flex?.myFlexTenants.edges.flatMap((ft) =>
          ft.node != null
            ? {
                value: ft.node.uuid,
                label: ft.node.name,
              }
            : [],
        ) ?? [];
  }, [currentRole, data?.myCompaniesFlexTenants, flex?.myFlexTenants.edges]);

  return (
    <>
      <Text variant="heading">{t("settings.flexMembership.tab")}</Text>
      <Text variant="text" size="small" className="mt-2 mb-6">
        {t("settings.flexMembership.description")}
      </Text>
      <EqForm.Group className="pt-6" inline label={t("settings.flexMembership.tab")}>
        <EqForm.MultiSelect
          options={companyFlexTenants}
          value={flexTenantUuids ?? []}
          onChange={(value) => setFlexTenantUuids(value.target.value)}
          size="small"
          placeholder={t("settings.flexMembership.placeholder")}
        />
      </EqForm.Group>
      <div className="settings-container"></div>
      <style jsx>{`
        .settings-container :global(.form) {
          padding: ${spacers.s3};
          display: flex;
          flex-direction: column;
          gap: ${spacers.s5};
        }

        .settings-container :global(.form) :global(label) {
          gap: ${spacers.s3};
        }

        .settings-container :global(.toggler) {
          margin-bottom: ${spacers.s4};
        }

        .settings-container :global(.form-group) {
          margin-bottom: ${spacers.s3};
        }

        .settings-container :global(.booking-preference-checkbox) {
          padding-bottom: ${spacers.s4};
        }

        .settings-container :global(.select-all-button) {
          border-bottom: 1px solid ${colors.border};
          padding-bottom: ${spacers.s5};
        }
      `}</style>
    </>
  );
};
