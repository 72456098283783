import React, { forwardRef, useContext, useRef } from "react";
import { useTheme } from "../../contexts/Theme";
import type { IconType } from "react-icons";
import { Material, Text } from "..";
import { useInputBorderCss } from "../Form/useInputBorderCss";
import { FormGroupContext } from "../../contexts/FormGroupContext";

interface Props {
  title: string;
  description?: string;
  icon?: IconType;
  materialIcon?: string;
  className?: string;
  disabled?: boolean;
  children?: React.ReactNode;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>, shiftHeld?: boolean) => void;
  onClickContainer?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  selected?: boolean;
  indeterminate?: boolean;
}

export const AdvancedSelectorButton = forwardRef<HTMLButtonElement, Props>(
  ({
    title,
    description,
    icon: Icon,
    materialIcon,
    className = "",
    disabled,
    children,
    onChange,
    onClickContainer,
    indeterminate = false,
    selected = false,
  }) => {
    const { colors } = useTheme();
    const borderCss = useInputBorderCss();
    const { hasError } = useContext(FormGroupContext);
    const ref = useRef<HTMLDivElement>(null);

    const isNormalLayout = Icon != null || materialIcon != null;

    const renderIcon = () => {
      if (Icon != null) {
        return <Icon size={24} />;
      }

      if (materialIcon != null) {
        return <Material.Icon name={materialIcon} size="md" color={colors.blue[60]} />;
      }

      return null;
    };

    return (
      <>
        <div
          color="rgba(234, 234, 255, 1)"
          role={"button"}
          className={className}
          ref={ref}
          onClick={(e) => {
            if (e.currentTarget === ref.current) {
              onClickContainer?.(e);
            }
          }}
        >
          <button
            type="button"
            disabled={disabled}
            className={`${className} ${borderCss.className} ${hasError ? "error" : ""}`}
          >
            <div className="w-100 container">
              <div className="control">
                {isNormalLayout ? (
                  renderIcon()
                ) : (
                  <Text variant="heading" size="small">
                    {title}
                  </Text>
                )}
                <div>
                  <input size={24} type="radio" className="radio-button" onChange={onChange} checked={selected} />
                </div>
              </div>
              {isNormalLayout && (
                <div className="info">
                  <Text variant="heading" size="small" className="mb-2">
                    {title}
                  </Text>
                  {description != null && (
                    <Text variant="text" size="extra-small" className="description m-0">
                      {description}
                    </Text>
                  )}
                </div>
              )}
              {children}
            </div>
          </button>
          <style jsx>
            {`
              .container {
                padding: ${isNormalLayout ? "0.75rem" : "0.75rem 0.75rem 0.75rem 1rem"};
              }

              button {
                display: flex;
                flex: 1 1 0;
                flex-direction: column;
                text-align: left;
                cursor: pointer;
                background-color: ${selected ? "#EAEAFF" : colors.white} !important;
                color: ${colors.transparent.black[80]};
                padding: 0;
                overflow: hidden;
                border-color: ${selected ? colors.primary : ""} !important;
              }
              button:hover {
                border-color: ${colors.primary} !important;
              }
              button.error {
                border-color: ${colors.status.danger.primary};
              }
              button .icon {
                display: flex;
                background-color: transparent;
                border-radius: 50%;
                height: 30px;
                width: 30px;
                text-align: center;
                align-items: center;
                justify-content: center;
              }
              button:hover .icon {
                background-color: ${colors.grayscale[5]};
              }
              input:hover .radio-button {
                cursor: "none";
              }
              .radio-button {
                min-width: 24px;
                min-height: 24px;
              }
              .control {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: ${isNormalLayout ? "1rem" : "0"};
              }
              .info {
                display: flex;
                flex-direction: column;
              }
              .info :global(.description) {
                color: ${colors.transparent.black[60]};
              }
              .info :global(h1),
              .control :global(h1) {
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                max-width: calc(100% - 32px);
              }
            `}
          </style>
          {borderCss.styles}
        </div>
      </>
    );
  },
);

AdvancedSelectorButton.displayName = "AdvancedSelectorButton";
