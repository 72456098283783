import { useRouter } from "next/router";
import type { ReactNode } from "react";
import React, { useContext, useEffect, useMemo } from "react";
import { applicationId } from "../config/applicationId";
import { useMetricMutation } from "../generated/gateway-client";
import { Role, CurrentRole, CurrentProfile, Site, stringIsEmpty } from "@equiem/lib";
import { Metrics } from "./MetricsContext";

import { Session } from "@equiem/lib/context/SessionContext";

export const MetricsProvider: React.FC<{ children?: ReactNode }> = ({ children }) => {
  const session = useContext(Session);
  const site = useContext(Site);
  const role = useContext(CurrentRole);
  const { profile, isReceptionist } = useContext(CurrentProfile);
  const router = useRouter();
  const [mutation] = useMetricMutation();
  const receptionistRole = "Receptionist";

  const metricRole = useMemo(() => {
    if (profile == null) {
      return null;
    }

    switch (role.currentRole) {
      case Role.PropertyManager:
      case Role.WorkplaceManager:
        return role.currentRole;
      default:
        return isReceptionist ? receptionistRole : role.currentRole;
    }
  }, [role.currentRole, profile, isReceptionist]);

  useEffect(() => {
    if (
      !session.authenticated ||
      session.side !== "client" ||
      /\bnewrelic-monitor=true\b/u.test(window.location.search) ||
      profile == null ||
      metricRole === Role.Unknown ||
      metricRole == null ||
      stringIsEmpty(site.uuid)
    ) {
      return;
    }
    // Fire "Admin action" metric on page view.
    setTimeout(() => {
      mutation({
        variables: {
          input: {
            AI: applicationId,
            AV: metricRole,
            DT: "COUNT",
            VL: 1,
            ME: "admin_action",
            TT: "view",
            TD: document.title,
            TC: document.title.split(" - ").pop(),
            TR: router.pathname,
            PI: site.uuid,
            TS: new Date().getTime(),
            UI: profile.uuid,
          },
        },
      }).catch((e: unknown) => {
        console.log("admin_action metric send failed", e);
      });
    });
  }, [mutation, router.pathname, session.authenticated, session.side, site.uuid, profile, metricRole]);

  return <Metrics.Provider value={{}}>{children}</Metrics.Provider>;
};
