import { useTranslation } from "@equiem/localisation-eq1";
import { Form, ProgressCircle, useToast } from "@equiem/react-admin-ui";
import React, { useCallback, useState } from "react";
import type { BookingSettingFragmentFragment } from "../../../generated/gateway-client";
import { useToggleBookingPaymentAdjustmentMutation } from "../../../generated/gateway-client";

interface P {
  setting?: BookingSettingFragmentFragment | null;
}
export const PaymentAdjustmentToggle: React.FC<P> = ({ setting }) => {
  const { t } = useTranslation();
  const [enabled, setEnabled] = useState(setting?.paymentAdjustmentEnabled ?? false);
  const [mutation, { loading }] = useToggleBookingPaymentAdjustmentMutation();
  const toast = useToast();

  const onChange = useCallback(
    async (checked: boolean) => {
      try {
        setEnabled(checked);
        await mutation({ variables: { enable: checked } });
      } catch (e: unknown) {
        console.log(e);
        toast.negative(e instanceof Error ? e.message : t("common.unknownErrorMessage"));
        setEnabled(!checked);
      }
    },
    [mutation, t, toast],
  );

  return (
    <Form.Group
      label={t("bookings.settings.paymentAdjustment")}
      showTooltip
      tooltipText={t("bookings.settings.paymentAdjustmentHelp")}
    >
      {loading ? (
        <>
          <ProgressCircle mode="indeterminate" size="xs" className="mr-3" />
          {t("bookings.settings.enablePaymentAdjustment")}
        </>
      ) : (
        <Form.Checkbox
          disabled={loading}
          label={t("bookings.settings.enablePaymentAdjustment")}
          value={enabled}
          onChange={(e) => {
            onChange(e.currentTarget.checked).catch(console.log);
          }}
        />
      )}
    </Form.Group>
  );
};
