import type { FC } from "react";
import React, { useEffect, useState, useMemo, useContext } from "react";
import { SubTopMenu, UrlParams, stringIsEmpty, stringNotEmpty } from "@equiem/lib";
import { ComplexFilter, Form, useDebounced, useTheme, useIsMobileWidth } from "@equiem/react-admin-ui";
import { RiSearchLine } from "@equiem/react-admin-ui/icons";
import { useTranslation } from "@equiem/localisation-eq1";
import { compressToEncodedURIComponent, decompressFromEncodedURIComponent } from "lz-string";

import type { CmsSearchFilters, PortfolioSiteFragmentFragment } from "../../../generated/gateway-client";
import { ArticleTabs, defaultTabFilter } from "./ArticleTabs";
import { useArticleComplexFilters } from "./useArticleComplexFilters";
import { useRouter } from "next/router";

interface Props {
  portfolioUuid: string | undefined;
  portfolioSites: PortfolioSiteFragmentFragment[];
  onFiltersChanged: (queryFilters: CmsSearchFilters) => void;
}

const debounceMs = 500;

export const defaultFilters = defaultTabFilter;

export const ArticleSearch: FC<Props> = ({ portfolioUuid, portfolioSites, onFiltersChanged }) => {
  const urlParamsProvider = useContext(UrlParams);
  const isMobile = useIsMobileWidth();
  const router = useRouter();
  const { colors, spacers, breakpoints } = useTheme();
  const { t, i18n } = useTranslation();
  const complex = useArticleComplexFilters(portfolioSites);

  const [searchTerm, setSearchTerm] = useState((router.query.search as string) ?? "");
  const debouncedSearch = useDebounced(searchTerm, debounceMs);
  const searchFilter = useMemo<CmsSearchFilters | null>(
    () => (stringNotEmpty(debouncedSearch) ? { title: debouncedSearch } : null),
    [debouncedSearch],
  );

  const [tabFilter, setTabFilter] = useState(() => {
    if (router.query.tab != null && router.query.tab !== "") {
      const decompressedTabParam = decompressFromEncodedURIComponent(router.query.tab as string);
      return JSON.parse(decompressedTabParam) as CmsSearchFilters;
    }
    return defaultTabFilter;
  });
  const [tabFilterCompressed, setTabFilterCompressed] = useState(() =>
    stringIsEmpty(router.query.tab as string)
      ? compressToEncodedURIComponent(JSON.stringify(tabFilter))
      : (router.query.tab as string),
  );
  const handleTabFilterChange = (newTabFilter: CmsSearchFilters) => {
    setSearchTerm("");
    setTabFilter(newTabFilter);
  };

  useEffect(() => {
    const simpleFilter = searchFilter ?? tabFilter;
    onFiltersChanged({ ...simpleFilter, ...complex.filter });
  }, [searchFilter, tabFilter, complex.filter, onFiltersChanged]);

  // react on tab reset
  useEffect(() => {
    if (router.query.tab === undefined) {
      setTabFilter(defaultTabFilter);
    }
  }, [router.query.tab]);

  useEffect(() => {
    const newTabString = JSON.stringify(tabFilter);
    const compressedTabParam = compressToEncodedURIComponent(newTabString);
    if (router.query.tab === compressedTabParam) {
      return;
    }
    setTabFilterCompressed(compressedTabParam);
  }, [tabFilter]);

  useEffect(() => {
    if (router.query.tab === tabFilterCompressed) {
      return;
    }
    urlParamsProvider.setParam("tab", tabFilterCompressed);
  }, [tabFilterCompressed]);

  useEffect(() => {
    if (stringNotEmpty(debouncedSearch)) {
      if (debouncedSearch !== router.query.search) {
        urlParamsProvider.setParam("search", debouncedSearch);
      }
    } else if (router.query.search != null) {
      urlParamsProvider.deleteParam("search");
    }
  }, [debouncedSearch]);

  const tabs = <ArticleTabs tabFilter={tabFilter} portfolioUuid={portfolioUuid} setTabFilter={handleTabFilterChange} />;

  return (
    <>
      <SubTopMenu>
        <div className="filters">
          <ComplexFilter
            filters={complex.props.filters}
            onChange={complex.props.onChange}
            additionalFilterDropdownContent={
              isMobile ? <div className="quick-filters-dropdown">{tabs}</div> : undefined
            }
            urlParamsProvider={urlParamsProvider}
            language={i18n.language}
            autoShow
          >
            {({ renderChips, renderFilterButton, renderClearButton }) => (
              <>
                <div className="main-row">
                  <div className="left-side">{tabs}</div>
                  <div className="right-side">
                    <div className="search-container">
                      <Form.InputExtended
                        className="search-input"
                        icon={RiSearchLine}
                        placeholder={`${t("common.search")}...`}
                        variant="sm"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        onClear={() => setSearchTerm("")}
                        clearable
                      />
                    </div>
                    {renderFilterButton("primary", "bottom-end")}
                  </div>
                </div>
                {!isMobile && complex.hasFilters && (
                  <div className="chips-row">
                    <div className="left-side">{renderChips()}</div>
                    <div className="right-side">{renderClearButton()}</div>
                  </div>
                )}
              </>
            )}
          </ComplexFilter>
        </div>
      </SubTopMenu>
      <style jsx>{`
        .filters {
          display: flex;
          flex-direction: column;
          width: 100%;
        }
        .main-row {
          display: flex;
          width: 100%;
          justify-content: space-between;
          gap: ${spacers.s3};
        }
        .right-side,
        .left-side {
          display: flex;
          align-items: flex-start;
          gap: ${spacers.s3};
        }

        .chips-row {
          display: flex;
          justify-content: space-between;
          padding: ${spacers.s3} 0;
          margin-top: ${spacers.s3};
          gap: ${spacers.s3};
          border-top: 1px solid ${colors.grayscale[10]};
        }

        .search-container {
          width: 270px;
        }

        @media (max-width: ${breakpoints.xl}px) {
          .search-container {
            width: auto;
          }
        }

        @media (max-width: ${breakpoints.lg}px) {
          .search-container {
            width: 100%;
          }

          .main-row .left-side {
            display: none;
          }

          .main-row .right-side {
            width: 100%;
            display: grid;
            grid-template-columns: auto 32px;
            align-items: center;
            padding: 0;
          }
        }
      `}</style>
    </>
  );
};
