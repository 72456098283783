import React from "react";
import { DateTime } from "luxon";

import { useSiteContext } from "@equiem/lib";
import { formatters, useTranslation } from "@equiem/localisation-eq1";
import { Modal, Skeleton, useToast } from "@equiem/react-admin-ui";

import type {
  DeleteVisitorReceptionMutationVariables,
  VisitorAppointmentsByReceptionQuery,
} from "../../../../generated/visitors-client";
import { useDeleteVisitorReceptionMutation, useVisitorReceptionQuery } from "../../../../generated/visitors-client";

import { ReceptionDeleteForm } from "./ReceptionDeleteForm";

interface Props {
  appointments: VisitorAppointmentsByReceptionQuery["visitorAppointmentsByReception"];
  receptionName: string;
  receptionUuid: string;
  visible: boolean;
  onClose: () => void;
}

const Loading: React.FC = () => (
  <>
    <div className="pb-2">
      <Skeleton.Line width="100%" height="18px" />
    </div>
    <div className="pb-2">
      <Skeleton.Line width="100%" height="18px" />
    </div>
    <div className="pb-2">
      <Skeleton.Line width="100%" height="18px" />
    </div>
    <Skeleton.Line width="100%" height="18px" />
  </>
);

export const ReceptionDeleteModal: React.FC<Props> = ({
  appointments,
  receptionName,
  receptionUuid,
  visible,
  onClose,
}) => {
  const { i18n, t } = useTranslation();
  const site = useSiteContext();
  const toast = useToast();

  const visitorReceptionQuery = useVisitorReceptionQuery({
    variables: { uuid: receptionUuid },
    skip: !visible,
  });
  const [mutation, { loading: isSubmitting }] = useDeleteVisitorReceptionMutation();

  const reception = visitorReceptionQuery.data?.visitorReception;
  const isReady = !visitorReceptionQuery.loading && visitorReceptionQuery.error == null && reception != null;

  const scheduleDeletion = async (vars: Omit<DeleteVisitorReceptionMutationVariables, "uuid">) => {
    try {
      const result = await mutation({ variables: { uuid: receptionUuid, ...vars } });
      if (result.errors != null) {
        /* istanbul ignore next */
        throw new Error(`failed to schedule deletion: ${JSON.stringify(result.errors)}`);
      }

      const deletionDate = result.data?.deleteVisitorReception.deletionTime;

      const timezone = site.timezone;
      if (deletionDate != null) {
        const date = formatters.dateshort(DateTime.fromMillis(deletionDate, { zone: timezone }), i18n.language);
        toast.neutral(t("visitors.reception.deleteScheduleSuccess", { date, receptionName }));
      }
      /* istanbul ignore next */
    } catch (e: unknown) {
      console.error(e);
      toast.negative(t("visitors.reception.deleteScheduleError", { receptionName }));
    } finally {
      onClose();
    }
  };

  return (
    <Modal.Dialog
      className="delete-resource-modal"
      title={t("visitors.reception.deleteScheduleTitle")}
      show={visible}
      onHide={onClose}
      hideOnEsc={true}
      hideOnClick={false}
      size="lg"
    >
      <Modal.Header closeButton={true} noBorder={true}></Modal.Header>
      <Modal.Body>
        {isReady ? (
          <ReceptionDeleteForm
            appointments={appointments}
            info={reception}
            isSubmitting={isSubmitting}
            onCancel={onClose}
            receptionName={receptionName}
            onSubmit={(vars) => {
              void scheduleDeletion(vars);
            }}
          />
        ) : visitorReceptionQuery.loading ? (
          <Loading />
        ) : (
          <div>{t("visitors.reception.failedToLoad")}</div>
        )}
      </Modal.Body>
    </Modal.Dialog>
  );
};
