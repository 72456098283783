import React from "react";

import { StripeProvider, useShowError } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import { Text, useToast } from "@equiem/react-admin-ui";

import type {
  CardFragmentFragment as Card,
  ProfileFragmentFragment as Profile,
} from "../../../generated/settings-client";
import { useDeleteCardMutation } from "../hooks/useDeleteCardMutation";
import { useSetDefaultCardMutation } from "../hooks/useSetDefaultCardMutation";

import { AddCard } from "./payment/AddCard";
import { SavedCard } from "./payment/SavedCard";

interface Props {
  profile: Profile;
  onCardListChanged: () => void;
}

export const UserPaymentSettings: React.FC<Props> = ({ profile, onCardListChanged }) => {
  const { t } = useTranslation();
  const showError = useShowError();
  const toast = useToast();

  const [setDefaultCard, { loading: settingDefault }] = useSetDefaultCardMutation(profile.cards);
  const [deleteCard, { loading: deleting }] = useDeleteCardMutation(profile.cards);

  const handleSelectCard = async ({ uuid }: Card) => {
    await setDefaultCard({ variables: { uuid } });
  };
  const handleDeleteCard = async ({ uuid }: Card) => {
    await deleteCard({ variables: { uuid } });
    toast.neutral(t("settings.payment.deleteCreditCardSuccessNotification"));
    onCardListChanged();
  };

  return (
    <StripeProvider>
      <Text variant="heading">{t("settings.payment.tab")}</Text>
      <div className="cards my-6">
        {profile.cards.map((card) => (
          <SavedCard
            key={card.uuid}
            card={card}
            loading={settingDefault || deleting}
            onSelect={async () => handleSelectCard(card).catch(showError)}
            onDelete={async () => handleDeleteCard(card).catch(showError)}
          />
        ))}
      </div>
      <AddCard onSave={onCardListChanged} />
    </StripeProvider>
  );
};
