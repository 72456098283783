import React, { useState } from "react";
import { Form, Button } from "@equiem/react-admin-ui";
import { useSiteContext } from "@equiem/lib";
import { DateTime } from "luxon";

import type {
  BookableResourceDeletionInfoQuery,
  DeleteBookableResourceMutationVariables,
} from "../../../../generated/gateway-client";
import { DeleteResourceInfo } from "./DeleteResourceInfo";
import { useTranslation } from "@equiem/localisation-eq1";

interface Props {
  info: BookableResourceDeletionInfoQuery["bookableResource"];
  isSubmitting: boolean;
  onCancel: () => void;
  onSubmit: (variables: Omit<DeleteBookableResourceMutationVariables, "uuid">) => void;
}

export const DeleteResourceForm: React.FC<Props> = ({ info, isSubmitting, onCancel, onSubmit }) => {
  const { t } = useTranslation();
  const site = useSiteContext();
  const timezone = info.building?.timezone ?? site.timezone;

  const fromMillis = (date: number) => DateTime.fromMillis(date, { zone: timezone }).toFormat("yyyy-MM-dd");
  const toMillis = (date: string) => DateTime.fromFormat(date, "yyyy-MM-dd", { zone: timezone }).endOf("day").valueOf();

  const minTime = fromMillis(info.lastActiveBookingEndDate ?? Date.now());
  const [deletionTime, setDeletionTime] = useState(minTime);

  return (
    <>
      <DeleteResourceInfo info={info} />
      <div className="form-fields">
        <Form.Group
          className="deletion-time"
          label={t("bookings.resources.scheduleResourceForDeletionAfter")}
          tooltipText={t("bookings.resources.scheduleResourceForDeletionAfterHint")}
          showTooltip
        >
          <Form.Input
            type="date"
            min={minTime}
            value={deletionTime}
            onChange={(e) => setDeletionTime(e.target.value)}
          />
        </Form.Group>
      </div>
      <div className="d-flex justify-content-end mt-4">
        <Button className="mr-2" type="button" variant="ghost" disabled={isSubmitting} onClick={onCancel}>
          {t("common.cancel")}
        </Button>
        <Button
          type="button"
          variant="danger"
          disabled={isSubmitting}
          onClick={() => onSubmit({ deletionTime: toMillis(deletionTime) })}
        >
          {t("bookings.resources.scheduleDeletion")}
        </Button>
      </div>
      <style jsx>{`
        .form-fields {
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .form-fields :global(.deletion-time) {
          width: unset;
        }
      `}</style>
    </>
  );
};
