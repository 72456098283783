import React from "react";

import { useTranslation } from "@equiem/localisation-eq1";
import { Button, Modal, useTheme } from "@equiem/react-admin-ui";

interface Props {
  showModal: boolean;
  onClose: () => void;
  onAgree: () => void;
}

export const DisableCreditPurchaseModal: React.FC<Props> = ({ showModal, onClose, onAgree }) => {
  const { t } = useTranslation();
  const { colors } = useTheme();

  return (
    <>
      <Modal.Dialog
        centered
        className="disable-credit-modal"
        title={t("common.areYouSure")}
        supportsMobile
        show={showModal}
        onHide={onClose}
        hideOnEsc
        hideOnClick={false}
        size="md"
        focusTrapOptions={{ allowOutsideClick: () => true }}
      >
        <Modal.Header noBorder />
        <Modal.Body>
          <span className="description">{t("credits.disablePurchase.description")}</span>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={onClose} variant="ghost" size="lg">
            {t("common.cancelNo")}
          </Button>
          <Button onClick={onAgree} variant="danger" className="ml-2" size="lg">
            {t("common.disable")}
          </Button>
        </Modal.Footer>
      </Modal.Dialog>
      <style jsx>{`
        :global(.disable-credit-modal .title-row .title) {
          font-weight: 500;
          color: ${colors.grayscale[60]};
          font-size: 12px;
          line-height: 24px;
          text-transform: uppercase;
        }

        :global(.disable-credit-modal .description) {
          font-size: 18px;
          line-height: 28px;
        }
      `}</style>
    </>
  );
};
