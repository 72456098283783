import React, { useState } from "react";
import { DateTime } from "luxon";

import { useTranslation } from "@equiem/localisation-eq1";
import { Button, ProgressCircle } from "@equiem/react-admin-ui";
import { RiCheckFill } from "@equiem/react-admin-ui/icons";

import type {
  VisitorForBuildingReceptionFragment,
  VisitorForReceptionFragment,
} from "../../../../generated/visitors-client";

type Visitor = VisitorForReceptionFragment | VisitorForBuildingReceptionFragment;

export const NotifyButton = ({
  visitor,
  handleNotify,
}: {
  visitor: Visitor;
  handleNotify: (visitor: Visitor) => Promise<void>;
}) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const isFutureAppointment =
    DateTime.fromMillis(visitor.appointment.startTime).startOf("day").diff(DateTime.local().startOf("day"))
      .milliseconds > 0;
  const disabled = isLoading || isFutureAppointment;

  const handleNotifyWithLoading = async () => {
    try {
      setIsLoading(true);
      await handleNotify(visitor);
    } catch {
      setIsLoading(false);
    }
  };
  return (
    <>
      <div className="action-notify">
        <Button
          onClick={() => {
            void handleNotifyWithLoading();
          }}
          size="md"
          variant="outline"
          className={"action action-blue"}
          disabled={disabled}
        >
          {isLoading ? <ProgressCircle size={16} /> : <RiCheckFill size={16} />}
          <span className="action-label">{t("visitors.reception.notify")}</span>
        </Button>
      </div>
      <style jsx>
        {`
          .action-notify :global(.action-blue) {
            color: #5b38e8 !important;
          }
          .action-notify :global(.action-blue:hover),
          .action-notify :global(.action-blue:focus) {
            border-color: #5b38e8 !important;
          }
          .action-notify :global(.action-label) {
            font-weight: 600;
            font-size: 12px;
            line-height: 16px;
          }
        `}
      </style>
    </>
  );
};
