import { useEffect, useState } from "react";
import type { BookableResourceAvailabilityCalendarQuery } from "../../../generated/gateway-client";
import { useBookableResourceAvailabilityCalendarQuery } from "../../../generated/gateway-client";

export const useResourceData = (uuid: string, start: number, end: number, isLoaded: boolean, reload: boolean) => {
  const [availabilityCalendarData, setAvailabilityCalendarData] = useState<
    BookableResourceAvailabilityCalendarQuery | undefined
  >(undefined);
  const [loading, setLoading] = useState(true);

  const { refetch: refetchAvailabilityCalendar } = useBookableResourceAvailabilityCalendarQuery({
    variables: { uuid, start, end },
    fetchPolicy: "network-only",
    skip: !isLoaded,
  });

  useEffect(() => {
    if (isLoaded) {
      setLoading(true);
      refetchAvailabilityCalendar()
        .then((response) => {
          setAvailabilityCalendarData(response.data);
          setLoading(false);
        })
        .catch(() => setLoading(false));
    }
  }, [isLoaded, refetchAvailabilityCalendar, reload]);

  return { data: availabilityCalendarData, loading };
};
