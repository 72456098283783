import React, { useCallback, useMemo } from "react";
import material from "./material";

export interface Icon {
  name: string;
  human: string;
  tags: string[];
}

interface IconContext {
  icons: Icon[];
  filteredIcon: (search: string) => Icon[];
}
export const IconContext = React.createContext<IconContext>({
  icons: [],
  filteredIcon: (_s: string) => {
    throw new Error("filterIcon not implemented.");
  },
});

interface P {
  children?: React.ReactNode;
}
export const IconProvider: React.FC<P> = ({ children }) => {
  const icons = useMemo(
    () =>
      material.icons.map<Icon>((icon) => ({
        name: icon.name,
        human: `${icon.name.charAt(0).toUpperCase()}${icon.name.slice(1)}`.split("_").join(" "),
        tags: icon.tags,
      })),
    [],
  );

  const filteredIcon = useCallback(
    (search: string) => {
      return search === ""
        ? icons
        : icons.filter((i) => i.name.startsWith(search) || i.tags.filter((tag) => tag.startsWith(search)).length > 0);
    },
    [icons],
  );

  return (
    <IconContext.Provider
      value={{
        icons,
        filteredIcon,
      }}
    >
      {children}
    </IconContext.Provider>
  );
};
