import React from "react";
import type { FC } from "react";
import { SiteLogo } from "@equiem/lib";

import type { DestinationListQuery } from "../../generated/gateway-client";

type Destination = NonNullable<
  DestinationListQuery["viewer"]["destinationsWithRelation"]["member"]["edges"][number]["node"]
>;

export const SiteListItem: FC<{
  destination: Destination;
  size?: "small" | "large";
}> = ({ destination, size = "large" }) => {
  const otherSite = {
    logo: destination.settings.branding.web.siteLogoImage,
    name: destination.name,
    showLogoOnWhiteBackground: destination.settings.branding.web.showLogoOnWhiteBackground,
    primaryColour: destination.settings.branding.primaryColour,
  };

  const width = size === "small" ? "32px" : "40px";

  return (
    <>
      <div className="item">
        <div style={{ minWidth: width }} className="mr-3">
          <SiteLogo size={width} logoSize={size === "small" ? "28px" : "36px"} otherSite={otherSite} />
        </div>
        {destination.name}
      </div>
      <style jsx>{`
        .item {
          display: flex;
          align-items: center;
        }
      `}</style>
    </>
  );
};
