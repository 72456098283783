import type { PropsWithChildren } from "react";
import React, { useState } from "react";
import { useSiteContext } from "@equiem/lib";
import { Button, useTheme } from "@equiem/react-admin-ui";
import { RiFullscreenExitLine, RiFullscreenLine } from "@equiem/react-admin-ui/icons";

interface Props {
  className?: string;
  title?: string | null;
}

export const SiteBanner: React.FC<PropsWithChildren<Props>> = ({ className, title, children }) => {
  const { headerImage } = useSiteContext();
  const { colors, borderRadius } = useTheme();

  const [open, setOpen] = useState(true);

  return (
    <>
      <div className={`site-banner ${open ? "open" : "closed"} ${className}`}>
        {headerImage != null && <img src={headerImage} />}
        <div className="container">
          <div className="content">{open ? children : <span className="title">{title}</span>}</div>
          <div className="toggle">
            <Button variant="ghost" onClick={() => setOpen(!open)}>
              {open ? (
                <RiFullscreenExitLine size={16} color={colors.primaryContrast} />
              ) : (
                <RiFullscreenLine size={16} color={colors.primaryContrast} />
              )}
            </Button>
          </div>
        </div>
      </div>
      <style jsx>{`
        .site-banner {
          position: relative;
          overflow: hidden;
          width: 100%;
          height: 440px;
          max-height: 40vh;
          border-radius: ${borderRadius};
          background-color: ${colors.primary};
        }
        .site-banner.closed {
          height: 48px;
          display: flex;
          flex-direction: column;
          justify-content: center;
        }

        img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-position: center;
          object-fit: cover;
        }

        .container {
          position: relative;
          width: 100%;
          height: 100%;
          border-radius: ${borderRadius};
          padding: 16px 24px;
        }
        .closed .container {
          backdrop-filter: blur(10px);
        }

        .content {
          position: relative;
          width: 100%;
          height: 100%;
        }
        .title {
          font-size: 16px;
          line-height: 16px;
          font-weight: bold;
          text-transform: uppercase;
          color: ${colors.primaryContrast};
          text-shadow: 0 0 1rem rgba(0, 0, 0, 0.5);
        }
        .toggle {
          position: absolute;
          top: 8px;
          right: 12px;
        }
      `}</style>
    </>
  );
};
