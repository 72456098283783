import { Role, Site } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import { RiAddCircleLine } from "@equiem/react-admin-ui/icons";
import { useRouter } from "next/router";
import { useContext } from "react";

export const Shortcuts = (currentRole: Role) => {
  const { t } = useTranslation();
  const site = useContext(Site);
  const router = useRouter();

  if (currentRole !== Role.PropertyManager || !site.featureModules.bookings.enabled) {
    return [];
  }

  return [
    {
      onClick: () => {
        router.push("/bookings/resources/new-resource").catch(console.error);
      },
      icon: RiAddCircleLine,
      text: t("bookings.resources.createBookingResource"),
    },
  ];
};
