import type { ReactNode } from "react";
import React, { useContext } from "react";

import { FormGroupContext } from "../../../../contexts/FormGroupContext";
import { FormRichSelectContext } from "../FormRichSelectContext";
import { useTheme } from "../../../../contexts/Theme";

export const FormRichSelectMenu: React.FC<{ children: ReactNode }> = ({ children }) => {
  const { x, y, strategy, open: show, floating } = useContext(FormRichSelectContext);
  const { colors, borderRadius, zIndexes } = useTheme();
  const { id, labelId } = useContext(FormGroupContext);

  return (
    <div id={id} ref={floating} role="listbox" aria-labelledby={labelId}>
      {children}
      <style jsx>{`
        div {
          display: ${show ? "block" : "none"};
          background: ${colors.white};
          border: 1px solid ${colors.border};
          border-radius: ${borderRadius};
          color: ${colors.dark};
          max-height: 200px;
          overflow-y: auto;
          z-index: ${zIndexes.dropdown};
          position: ${strategy};
          top: ${y ?? ""};
          left: ${x ?? ""};
          margin-top: 4px;
          box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.25);
        }
      `}</style>
    </div>
  );
};

FormRichSelectMenu.displayName = "FormRichSelectMenu";
