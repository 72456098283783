"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useErrorTranslation = void 0;
var react_i18next_1 = require("react-i18next");
var useApolloErrorTranslation_1 = require("./useApolloErrorTranslation");
var isApolloError = function (error) {
    return error != null && Array.isArray(error.graphQLErrors);
};
var isGeneralError = function (error) {
    return error != null && typeof error.message === "string";
};
function useErrorTranslation() {
    var _a = (0, react_i18next_1.useTranslation)(), t = _a.t, i18n = _a.i18n, ready = _a.ready;
    var tApolloError = (0, useApolloErrorTranslation_1.useApolloErrorTranslation)().tError;
    return {
        tError: function (error) {
            if (isApolloError(error)) {
                return tApolloError(error);
            }
            if (isGeneralError(error)) {
                return error.message;
            }
            return t("common.unknownErrorMessage");
        },
        i18n: i18n,
        ready: ready,
    };
}
exports.useErrorTranslation = useErrorTranslation;
