import React, { useState } from "react";
import { Button, Text, Tooltip, useTheme, useConfirmer } from "@equiem/react-admin-ui";
import { RiCloseLine, RiFileLine } from "@equiem/react-admin-ui/icons";
import type { IFileV2 } from "@equiem/uploader";
import { useTranslation } from "@equiem/localisation-eq1";

interface Props {
  file: File | IFileV2;
  index: number;
  onRemove: (_index: number) => void;
  showImg?: boolean;
  progress?: number;
}

export const FileItem: React.FC<Props> = ({ file, index, onRemove, showImg = false, progress = 1 }) => {
  const isIFile = "key" in file || "temporaryUuid" in file || "value" in file || "temporaryImage" in file;
  const fileName = isIFile ? file.value?.filename : (file as File).name;
  const { withConfirmation } = useConfirmer();
  const { t } = useTranslation();

  const { colors, spacers, borderRadius } = useTheme();
  const [localImage] = useState<string | undefined>(isIFile ? file.value?.imageUrl ?? file.temporaryImage : undefined);

  return (
    <>
      <div className={`${showImg && "img-cont"} cont file-upload`}>
        <div className="item align-items-center">
          <div className="item-container">
            {showImg && localImage != null ? (
              <div>
                {index === 0 && <div className="thumbnail">{t("bookings.operations.thumbnail")}</div>}
                <img src={localImage} alt={fileName} loading="lazy" />
              </div>
            ) : (
              <Text variant="text">
                <RiFileLine size={20} /> {fileName}
              </Text>
            )}
          </div>

          <div className="actions d-flex">
            <Tooltip placement="left" title={t("contentManagement.deleteFileAttachment")}>
              <Button
                size="sm"
                className="remove-file"
                shape="round"
                variant="ghost"
                onClick={withConfirmation({
                  title: t("contentManagement.deleteFileAttachment"),
                  message: t("contentManagement.deleteFileAttachmentMessage"),
                  confirmButtonText: t("common.yesRemove"),
                  confirmButtonVariant: "danger",
                  onConfirm: () => onRemove(index),
                })}
              >
                <RiCloseLine size={16} color={colors.medium} />
              </Button>
            </Tooltip>
          </div>
        </div>
        <div className={`progress ${isIFile ? "ifile" : "file"}`}></div>
      </div>
      <style jsx>
        {`
          img {
            pointer-events: none;
            border-radius: ${borderRadius};
            width: 200px;
            aspect-ratio: 3 / 2;
            object-position: center;
            object-fit: cover;
          }
          .cont {
            border: ${showImg ? "default" : "1px solid #e6e6e6"};
            border-radius: ${borderRadius};
            user-select: none;
            position: relative;
            cursor: grab;
          }
          .thumbnail {
            position: absolute;
            left: 2px;
            top: 2px;
            font-size: 10px;
            padding: ${spacers.s1} ${spacers.s2};
            background: rgba(255, 255, 255, 0.7);
          }
          .progress.file {
            border-bottom: ${spacers.s2} solid ${colors.primary};
            border-bottom-left-radius: ${borderRadius};
            border-bottom-right-radius: ${progress < 100 ? spacers.s0 : borderRadius};
            width: ${progress}%;
          }
          .item {
            display: flex;
            justify-content: space-between;
            padding: ${showImg ? spacers.s0 : spacers.s5};
          }
          .item-caption {
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
          }
          .delete-icon {
            margin-top: ${spacers.s1};
          }
          .actions {
            gap: ${spacers.s4};
            align-items: center;
          }
          .img-cont :global(.actions) {
            position: absolute;
            top: ${spacers.s1};
            right: ${spacers.s1};
            z-index: 1;
            font-size: 20px;
            line-height: 20px;
            cursor: pointer;
            background: rgba(255, 255, 255, 0.7);
            border-top-right-radius: ${borderRadius};
          }
        `}
      </style>
    </>
  );
};
