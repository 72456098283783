import { getScrollableParent } from "./getScrollableParent";

export const scrollIntoViewIfNeeded = (el: HTMLElement) => {
  const scrollParent = getScrollableParent(el);

  if (scrollParent == null) {
    return;
  }

  try {
    const { height: parentHeight } = scrollParent.getBoundingClientRect();
    const { height: itemHeight } = el.getBoundingClientRect();

    const distanceFromScrollTop = el.offsetTop - scrollParent.scrollTop;
    if (distanceFromScrollTop < 0) {
      scrollParent.scrollTop = el.offsetTop;
    } else if (distanceFromScrollTop + itemHeight > parentHeight) {
      scrollParent.scrollTop = el.offsetTop - (parentHeight - itemHeight);
    }
  } catch {
    // Do nothing.
  }
};
