import { Array, Literal, Number, Record, String } from "runtypes";

export const memberLimit = Record({
  change: Record({
    field: Literal("memberLimit"),
    from: Record({ integer: Number.nullable() }).nullable(),
    to: Record({ integer: Number.nullable() }).nullable(),
  }),
});

const stringValue = Record({
  __typename: Literal("ActivityChangeStringValue"),
  value: String.nullable(),
});

export const workplaceManagersAdded = Record({
  change: Record({
    field: Literal("defaultWorkplaceManagerEmails"),
    fromValues: Array(
      stringValue.extend({
        value: String,
      }),
    ),
    toValues: Array(
      stringValue.extend({
        value: String,
      }),
    ),
  }),
});

export const spendingCreditsRequiresApprovalChanged = Record({
  change: Record({
    field: Literal("spendingCreditsRequiresApproval"),
    from: stringValue.nullable(),
    to: stringValue.nullable(),
  }),
});
