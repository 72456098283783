import * as Icons from "@equiem/react-admin-ui/icons";
import { RiLink } from "@equiem/react-admin-ui/icons";

export const dynamicIcon = (name: string) => {
  const icon = (Icons as Partial<Record<string, Icons.IconType>>)[name];

  if (icon == null) {
    return RiLink;
  }
  return icon;
};
