import _ from "lodash";

import { getReceptionName, notNullOrUndefined, sortByAlphabet } from "@equiem/lib";
import type { TFunction } from "@equiem/localisation-eq1";

import type { VisitorReceptionsQuery } from "../generated/visitors-client";

export const filterReceptionList = (query: VisitorReceptionsQuery | undefined, t: TFunction) => {
  return _(query?.visitorReceptions.edges.map((e) => e.node).filter(notNullOrUndefined) ?? [])
    .groupBy((r) => (r.company == null ? "" : r.company.name))
    .flatMap((value) => value.sort((a, b) => getReceptionName(a, t).localeCompare(getReceptionName(b, t))))
    .value();
};

export function filterReceptionSettingsList<T extends VisitorReceptionsQuery>(
  query: T | undefined,
  search?: string | null,
) {
  let receptions = _(query?.visitorReceptions.edges.map((e) => e.node) ?? []).filter(notNullOrUndefined);
  if (search != null && search !== "") {
    receptions = receptions.filter(
      (r) =>
        (r.company != null && r.company.name.toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) !== -1) ||
        (r.building != null && r.building.name.toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) !== -1),
    );
  }
  const receptionGroups = receptions.groupBy((r) => (r.company == null ? "" : r.company.name));

  const sortedBuildingReceptions = receptionGroups
    .filter((_value, key) => key === "")
    .flatMap((value) =>
      value.sort((a, b) => {
        const buildingSort = (a.building?.name ?? "").localeCompare(b.building?.name ?? "");
        const sortedLevels = sortByAlphabet(
          [{ name: a.buildingLevel?.name ?? "" }, { name: b.buildingLevel?.name ?? "" }],
          "name",
        );
        const buildingLevelSort = sortedLevels.at(0)?.name === a.buildingLevel?.name ? -1 : 1;
        return buildingSort === 0 ? buildingLevelSort : buildingSort;
      }),
    )
    .value();

  const sortedTenantReceptions = receptionGroups
    .filter((_value, key) => key !== "")
    .flatMap()
    .sort((a, b) => {
      const buildingSort = (a.building?.name ?? "").localeCompare(b.building?.name ?? "");
      const companySort = (a.company?.name ?? "").localeCompare(b.company?.name ?? "");
      const sortedLevels = sortByAlphabet(
        [{ name: a.buildingLevel?.name ?? "" }, { name: b.buildingLevel?.name ?? "" }],
        "name",
      );
      const buildingLevelSort = sortedLevels.at(0)?.name === a.buildingLevel?.name ? -1 : 1;
      const sameLevel = a.buildingLevel?.name === b.buildingLevel?.name;

      return buildingSort === 0 ? (sameLevel ? companySort : buildingLevelSort) : buildingSort;
    })
    .value();

  const result: T | undefined =
    query == null
      ? undefined
      : {
          ...query,
          visitorReceptions: {
            ...query.visitorReceptions,
            edges: sortedBuildingReceptions.concat(sortedTenantReceptions).map((r) => ({
              node: {
                ...r,
              },
            })),
          },
        };

  return result;
}
