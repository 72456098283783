import { Skeleton } from "@equiem/react-admin-ui";
import React from "react";

export const BookingModalContentLoading: React.FC = () => (
  <>
    <div className="pb-7">
      <Skeleton.Line width="100%" height="210px" />
    </div>
    <div className="mb-3">
      <Skeleton.Line width="120px" height="30px" />
    </div>
    <div className="mb-3">
      <Skeleton.Line width="50%" height="24px" />
    </div>
    <div className="mb-3">
      <Skeleton.Line width="50%" height="24px" />
    </div>
    <div className="mb-3">
      <Skeleton.Line width="350px" height="70px" />
    </div>
  </>
);
