import { CurrentProfile, CurrentRole, Site, useRequestEnabled } from "@equiem/lib";
import { SideNav } from "@equiem/react-admin-ui";
import {
  RiHomeLine,
  RiTeamLine,
  RiDashboardLine,
  RiQuestionnaireLine,
  RiCalendarCheckLine,
  RiBankCardLine,
  RiSettings3Line,
} from "@equiem/react-admin-ui/icons";
import { useContext } from "react";
import { ShortcutItem } from "../ShortcutItem";
import { ShortcutsMenu } from "./ShortcutsMenu";
import { useTranslation } from "@equiem/localisation-eq1";
import { useBookingsEnabled } from "@equiem/bookings";

export const FlexManagerSideMenu = () => {
  const { isReceptionist, canManageCurrentSiteSettings } = useContext(CurrentProfile);
  const { currentRole } = useContext(CurrentRole);
  const site = useContext(Site);
  const showBookings = useBookingsEnabled();
  const showRequestManagement = useRequestEnabled();
  const { t } = useTranslation();

  return (
    <>
      <SideNav isExpandable={false}>
        {site.tierLevel === "Essentials" && (
          <ShortcutItem icon={RiHomeLine} routerLink={"/"} text={t("home.sideMenu.home")} />
        )}
        {(site.tierLevel !== "Essentials" || isReceptionist) && (
          <ShortcutItem icon={RiDashboardLine} routerLink={"/insights"} text={t("home.sideMenu.insights")} />
        )}
        <ShortcutItem icon={RiTeamLine} text={t("flex.shortcuts.members")} routerLink={"/flex/members"} />
        <ShortcutItem icon={RiBankCardLine} text={t("flex.shortcuts.credits")} routerLink={"/credits"} />
        {showBookings && (
          <ShortcutItem icon={RiCalendarCheckLine} routerLink="/bookings" text={t("home.sideMenu.bookings")} />
        )}
        {showRequestManagement && (
          <ShortcutItem
            icon={RiQuestionnaireLine}
            routerLink="/request-management"
            text={t("home.sideMenu.requestManagement")}
          />
        )}
        {canManageCurrentSiteSettings && (
          <ShortcutItem icon={RiSettings3Line} routerLink="/settings" text={t("common.settings")} />
        )}
      </SideNav>
      <ShortcutsMenu currentRole={currentRole} className="mt-5" />
    </>
  );
};
