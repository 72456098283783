import React, { useState } from "react";
import { DateTime } from "luxon";

import { useSiteContext } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import { Button } from "@equiem/react-admin-ui";

import type {
  DeleteVisitorReceptionMutationVariables,
  VisitorAppointmentsByReceptionQuery,
  VisitorReceptionQuery,
} from "../../../../generated/visitors-client";

import { ReceptionDeleteInfo } from "./ReceptionDeleteInfo";

interface Props {
  info: VisitorReceptionQuery["visitorReception"];
  appointments: VisitorAppointmentsByReceptionQuery["visitorAppointmentsByReception"];
  isSubmitting: boolean;
  receptionName: string;
  onCancel: () => void;
  onSubmit: (variables: Omit<DeleteVisitorReceptionMutationVariables, "uuid">) => void;
}

export const ReceptionDeleteForm: React.FC<Props> = ({ appointments, info, isSubmitting, onCancel, onSubmit }) => {
  const { t } = useTranslation();
  const site = useSiteContext();
  const timezone = site.timezone;

  const fromMillis = (date: number) => DateTime.fromMillis(date, { zone: timezone }).toFormat("yyyy-MM-dd");
  const toMillis = (date: string) => DateTime.fromFormat(date, "yyyy-MM-dd", { zone: timezone }).endOf("day").valueOf();

  const minTime = fromMillis(Date.now());
  const [deletionTime, setDeletionTime] = useState(minTime);

  return (
    <>
      <ReceptionDeleteInfo
        appointments={appointments}
        info={info}
        deletionTime={deletionTime}
        setDeletionTime={setDeletionTime}
      />
      <div className="d-flex justify-content-end mt-4">
        <Button className="mr-2" type="button" variant="ghost" disabled={isSubmitting} onClick={onCancel}>
          {t("common.cancel")}
        </Button>
        <Button
          type="button"
          variant="danger"
          disabled={isSubmitting}
          onClick={() => onSubmit({ deletionTime: toMillis(deletionTime) })}
        >
          {t("visitors.reception.deleteSchedule")}
        </Button>
      </div>
      <style jsx>{`
        .form-fields :global(.deletion-time) {
          width: unset;
        }
      `}</style>
    </>
  );
};
