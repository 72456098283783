import { useCallback, useMemo } from "react";
import { DateTime } from "luxon";
import { useRouter } from "next/router";

const html5DateFormat = "yyyy-MM-dd";

export const useReceptionDate = () => {
  const router = useRouter();

  const date = useMemo(() => {
    if (typeof router.query.date === "string") {
      const inputDate = DateTime.fromFormat(router.query.date, html5DateFormat);
      if (inputDate.isValid) {
        return inputDate;
      }
    }

    return DateTime.local().startOf("day");
  }, [router.query.date]);

  const getUrl = useCallback(
    (path: string, queryDate?: DateTime) => {
      const url = new URL(path, window.location.origin);
      url.searchParams.set("date", (queryDate ?? date).toFormat(html5DateFormat));
      return url;
    },
    [date],
  );

  const setDate = useCallback(
    async (newDate: DateTime) => router.replace(getUrl(router.asPath, newDate)),
    [getUrl, router],
  );

  const pushUrl = useCallback(
    async (newUrl: string, newDate?: DateTime) => router.push(getUrl(newUrl, newDate)),
    [getUrl, router],
  );

  const replaceUrl = useCallback(
    async (newUrl: string, newDate?: DateTime) => router.replace(getUrl(newUrl, newDate)),
    [getUrl, router],
  );

  return { date, getUrl, setDate, pushUrl, replaceUrl };
};
