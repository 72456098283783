import type { Dispatch, SetStateAction } from "react";
import { createContext } from "react";

import type { IFileV2 } from "@equiem/uploader";

export type RequestValues = {
  buildingUuid?: string;
  buildingLevelUuid?: string;
  spaceUuid?: string;
  categoryUuid?: string;
  description?: string;
  reference?: string;
  attachments?: IFileV2[];
};
export interface CreateRequestContext {
  step: number;
  navigationDirection?: "forward" | "backward";
  nextStep: () => void;
  setStep: (value: number) => void;
  isAttachmentUploading: boolean;
  showModal: boolean;
  setIsAttachmentUploading: Dispatch<SetStateAction<boolean>>;
  values: RequestValues;
  setValues: Dispatch<SetStateAction<RequestValues>>;
}

export const CreateRequestContext = createContext<CreateRequestContext>({
  step: 1,
  values: {},
  isAttachmentUploading: false,
  showModal: false,
  setIsAttachmentUploading: () => undefined,
  nextStep: () => undefined,
  setStep: () => undefined,
  setValues: () => undefined,
});
