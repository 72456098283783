import { useDisabledSiteSwitcher } from "@equiem/lib";
import React from "react";
import { NewArticle as NewArticleComponent } from "../components/articles/NewArticle";
import { PortfolioProvider } from "../contexts/PortfolioProvider";

export const NewArticle = () => {
  useDisabledSiteSwitcher();

  return (
    <PortfolioProvider>
      <NewArticleComponent />
    </PortfolioProvider>
  );
};
