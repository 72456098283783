import React from "react";

import { Tag } from "@equiem/react-admin-ui";

import type { ReqMgtStatusType } from "../generated/requests-client";

import { StatusTypeIcon } from "./StatusTypeIcon";

interface P {
  name: string;
  type: ReqMgtStatusType;
}

export const StatusTag: React.FC<P> = ({ name, type }) => {
  return (
    <Tag size="small">
      <StatusTypeIcon type={type} className="mr-2" />
      {name}
    </Tag>
  );
};
