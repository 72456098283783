import React, { useCallback, useContext, useEffect, useState } from "react";
import { DateTime } from "luxon";

import { formatters, useTranslation } from "@equiem/localisation-eq1";
import { Button, Modal, ProgressCircle, Table, Tag, useTheme } from "@equiem/react-admin-ui";

import { Modal as ModalContext } from "../../../contexts/ModalContext";
import { useGetVisitorActivityLogLazyQuery, VisitorStatus } from "../../../generated/visitors-client";
import type { ReceptionType } from "../../../settings/ReceptionFormValues";

interface Props {
  id: string;
  isWalkIn?: boolean;
  receptionType: ReceptionType;
}

export const VisitorActivityWidget: React.FC<Props> = ({ id, receptionType, isWalkIn }) => {
  const { i18n, t } = useTranslation();
  const { spacers } = useTheme();
  const [showModal, setShowModal] = useState(false);
  const modal = useContext(ModalContext);
  const [getActivityLog, { data: activityData, loading }] = useGetVisitorActivityLogLazyQuery({
    fetchPolicy: "network-only",
  });

  const onClose = useCallback(() => {
    setShowModal(false);
    modal.close();
  }, [setShowModal, modal]);

  useEffect(() => {
    if (modal.activeModal === "VisitorActivity" && modal.id === id) {
      setShowModal(true);
      void getActivityLog({ variables: { uuid: id } });
    }
  }, [getActivityLog, modal.activeModal, modal.id, id]);

  const activites = activityData?.visitorLogs ?? [];

  const getActivityUserName = (firstName?: string | null, lastName?: string | null, activity?: string | null) => {
    if (Boolean(firstName) && Boolean(lastName)) {
      return `${firstName} ${lastName}`;
    }

    if (activity === t("visitors.reception.accessPassSent")) {
      return t("visitors.reception.emailService");
    }

    return t("visitors.reception.onSiteBarrier");
  };

  return (
    <>
      <Modal.Dialog
        title={t("visitors.reception.activityHistory")}
        show={showModal}
        hideOnEsc={false}
        hideOnClick={false}
        focusTrapOptions={{ initialFocus: false }}
        size="md"
        centered
        scrollable
      >
        {loading ? (
          <Modal.Header noBorder={true}>
            <div className="mt-6 mb-6 d-flex justify-content-center">
              <ProgressCircle size="md" />
            </div>
          </Modal.Header>
        ) : (
          <>
            <Modal.Header
              noBorder={true}
              intro={`${activites[0]?.visitor?.firstName} ${activites[0]?.visitor?.lastName}`}
            />
            <Modal.Body>
              <Table.Table className="log-table m-0">
                <thead className="table-head">
                  <tr>
                    <Table.Header label={t("common.timeAndDate")} />
                    <Table.Header label={t("visitors.reception.action")} className="wide-column" />
                    {receptionType === "building" && <Table.Header label={t("common.user")} />}
                  </tr>
                </thead>
                <tbody className="table-body">
                  {activites.map((activity) => {
                    const time = DateTime.fromMillis(activity?.created ?? 0);

                    return (
                      <tr key={activity?.uuid} className="log">
                        <td>
                          <div className="d-flex flex-column">
                            <span>{formatters.timeshort(time, i18n.language)}</span>
                            <span className="date">{formatters.dateshort(time, i18n.language)}</span>
                          </div>
                        </td>
                        <td>
                          {activity?.message}
                          {activity?.variables?.cardId != null && (
                            <div className="date collapsed-text">{`#${activity.variables.cardId}`}</div>
                          )}
                          {isWalkIn === true && activity?.status === VisitorStatus.CheckedIn && (
                            <Tag className="ml-2">{t("visitors.reception.walkIn").toUpperCase()}</Tag>
                          )}
                        </td>
                        {receptionType === "building" && (
                          <td>
                            {getActivityUserName(
                              activity?.user?.firstName,
                              activity?.user?.lastName,
                              activity?.message,
                            )}
                          </td>
                        )}
                      </tr>
                    );
                  })}
                </tbody>
              </Table.Table>
            </Modal.Body>
          </>
        )}
        <Modal.Footer>
          <Button variant="primary" onClick={onClose}>
            {t("common.done")}
          </Button>
        </Modal.Footer>
      </Modal.Dialog>
      <style jsx>
        {`
          .table-head {
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
            text-transform: uppercase;
            color: rgba(0, 0, 0, 0.6);
          }
          :global(.log-table) {
            border-style: hidden;
            box-shadow: 0 0 0 1px #e6e6e6;
            border-radius: 8px;
            border-collapse: collapse;
            width: 100%;
            padding-bottom: ${spacers.s10};
            min-width: 300px;
          }
          .table-body {
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
          }
          .table-body tr {
            border-bottom: 1px solid #e6e6e6;
          }
          :global(.log-table) th,
          :global(.log-table) td {
            padding: ${spacers.s3} ${spacers.s5};
          }
          .wide-column {
            width: 30%;
          }
          .date {
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            color: #666666;
          }
          .collapsed-text {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            min-width: 145px;
          }
        `}
      </style>
    </>
  );
};
