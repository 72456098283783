import React, { type FC, useContext, useEffect } from "react";
import { CurrentProfile } from "@equiem/lib";
import { Field, useFormikContext } from "formik";
import { Form, Alert, useTheme } from "@equiem/react-admin-ui";
import { RiInformationLine } from "@equiem/react-admin-ui/icons";
import { useTranslation } from "@equiem/localisation-eq1";

import type { BookingFormValue } from "../models/BookingFormValue";
import { BookingModalInfo } from "../contexts/BookingModalInfoProvider";
import { isVisitorManagementEnabled } from "../../resources/hooks/useIsVisitorManagementEnabled";
import { isAutoApproveBookings } from "../libs/isAutoApproveBookings";

interface P {
  hideMessage?: React.ReactNode;
}
export const BookingVisitors: FC<P> = ({ hideMessage }) => {
  const { booking, resource } = useContext(BookingModalInfo);
  const { profile } = useContext(CurrentProfile);
  const fm = useFormikContext<BookingFormValue>();
  const { colors } = useTheme();
  const { t } = useTranslation();

  const visitorModuleDisabled = !isVisitorManagementEnabled(resource);
  const cannotAcceptAppointmentOnBookingCreate =
    !resource.allowVisitorInvites || !isAutoApproveBookings(resource, profile?.companyV2?.uuid);
  const isEditForm = booking != null;

  useEffect(() => {
    if (hideMessage != null && fm.values.createAppointmentOnSubmit) {
      void fm.setFieldValue("createAppointmentOnSubmit", false, false);
    }
    // We don't want fm to be here.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hideMessage, fm.values.createAppointmentOnSubmit]);

  if (visitorModuleDisabled || cannotAcceptAppointmentOnBookingCreate || isEditForm) {
    return null;
  }

  return (
    <div className="booking-form-addons-block pb-5">
      <h4 className="font-weight-bold mb-4">{t("visitors.common.visitors")}</h4>
      <div className="mb-6">
        {hideMessage != null ? (
          <>{hideMessage}</>
        ) : (
          <>
            <Field
              className="mb-4 d-flex justify-content-between align-items-start"
              as={Form.Checkbox}
              id="createAppointmentOnSubmit"
              name="createAppointmentOnSubmit"
              label={t("bookings.visitors.addVisitors")}
              disabled={fm.isSubmitting}
            />
            {fm.values.createAppointmentOnSubmit && (
              <Alert
                variant="gray"
                size="large"
                icon={<RiInformationLine size={18} color={colors.grayscale[50]} />}
                message={t("bookings.visitors.addVisitorsOnCreateInfo")}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};
