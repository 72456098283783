import React, { useEffect, useRef, useState } from "react";

import { Button, Form, ListGroup, Tooltip, useTheme } from "@equiem/react-admin-ui";
import { RiDeleteBinLine, RiLock2Line } from "@equiem/react-admin-ui/icons";

export interface OnChange {
  target: {
    name: string;
    value: string[];
  };
}

export const FormList: React.FC<{
  name: string;
  onChange: (event: OnChange) => void;
  buttonText: string;
  errorMessage: string;
  value?: string[];
  disabled?: boolean;
  locked?: boolean;
  tooltipText?: string;
}> = ({
  name,
  value,
  onChange,
  buttonText = "Add Value +",
  disabled,
  locked,
  tooltipText = "",
  errorMessage = "Please ensure you enter everything after the @ symbol",
}) => {
  const [localValue, setLocalValue] = useState<string[]>([]);
  const [addedRows, setAddedRows] = useState<string[]>([]);
  const [newRow, setNewRow] = useState(false);
  const [hasError, setHasError] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const { colors } = useTheme();

  useEffect(() => {
    setLocalValue(value ?? []);
  }, [value]);

  const validate = (val: string) => {
    setHasError(false);
    if (val.indexOf("@") !== -1) {
      setHasError(true);
    }
  };

  const onChangeLocal = (val: string) => {
    if (hasError) {
      return;
    }
    setNewRow(false);
    if (val === "" || localValue.includes(val)) {
      return;
    }

    if (inputRef.current != null) {
      inputRef.current.value = "";
    }

    setLocalValue((prev) => [...prev, val]);
    setAddedRows((prev) => [...prev, val]);
    onChange({
      target: {
        name,
        value: [...localValue, val],
      },
    });
  };

  const onDelete = (val: string) => {
    const newVal = localValue.filter((v) => v !== val);
    setAddedRows(addedRows.filter((v) => v !== val));
    setLocalValue(newVal);
    onChange({
      target: {
        name,
        value: newVal,
      },
    });
  };

  return (
    <>
      {localValue.length === 0 && !newRow ? (
        <></>
      ) : (
        <div className="form-list-container">
          <ListGroup.Group maxHeight={"900px"}>
            {localValue.map((val) => (
              <ListGroup.Item key={val} noMargin>
                {(locked ?? false) && !addedRows.includes(val) ? (
                  <>
                    <div className="value-col locked-value pt-2 pb-2">{val}</div>
                    <div className="delete-col">
                      <Tooltip title={tooltipText} placement={"left"}>
                        <div className="delete">
                          <RiLock2Line size={16} color={colors.grayscale[60]} />
                        </div>
                      </Tooltip>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="value-col pt-2 pb-2">{val}</div>
                    <div className="delete-col">
                      <div onClick={() => onDelete(val)} className="delete">
                        <RiDeleteBinLine size={16} color={colors.danger} />
                      </div>
                    </div>
                  </>
                )}
              </ListGroup.Item>
            ))}
            {newRow && (
              <ListGroup.Item noMargin>
                <div className="input-col">
                  <Form.Input
                    ref={inputRef}
                    variant="sm"
                    className={hasError ? "error-input-form" : ""}
                    onChange={(e) => validate(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        e.stopPropagation();
                        const val = inputRef.current?.value ?? "";
                        onChangeLocal(val);
                      }
                    }}
                    onBlur={(e) => onChangeLocal(e.target.value)}
                  />
                  {hasError && (
                    <div className="mt-2">
                      <span className="error-message">{errorMessage}</span>
                    </div>
                  )}
                </div>
                <div className={`${hasError ? "mb-6" : "mb-2"} `}>
                  <div className="delete">
                    <RiDeleteBinLine size={16} color={colors.danger} />
                  </div>
                </div>
              </ListGroup.Item>
            )}
          </ListGroup.Group>
          <div className="mt-4"></div>
        </div>
      )}
      <Button
        size="md"
        variant="secondary"
        disabled={disabled ?? false}
        onClick={(e) => {
          e.preventDefault();
          setNewRow(true);
          setTimeout(() => inputRef.current?.focus(), 50);
        }}
      >
        {buttonText}
      </Button>
      <style jsx>{`
        .value-col {
          min-width: 200px;
        }
        .input-col {
          width: 92%;
          margin-left: -2px;
        }
        .locked-value {
          color: ${colors.grayscale[40]};
        }
        .delete {
          cursor: pointer;
        }
        .error-message {
          padding-top: 4px;
          font-size: 14px;
          color: ${colors.danger};
        }
        .form-list-container :global(.error-input-form) {
          border: 1px solid ${colors.danger} !important;
          background: ${`url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.00065 13.6667C3.31865 13.6667 0.333984 10.682 0.333984 7.00004C0.333984 3.31804 3.31865 0.333374 7.00065 0.333374C10.6827 0.333374 13.6673 3.31804 13.6673 7.00004C13.6673 10.682 10.6827 13.6667 7.00065 13.6667ZM7.00065 12.3334C8.41514 12.3334 9.77169 11.7715 10.7719 10.7713C11.7721 9.77108 12.334 8.41453 12.334 7.00004C12.334 5.58555 11.7721 4.229 10.7719 3.2288C9.77169 2.22861 8.41514 1.66671 7.00065 1.66671C5.58616 1.66671 4.22961 2.22861 3.22941 3.2288C2.22922 4.229 1.66732 5.58555 1.66732 7.00004C1.66732 8.41453 2.22922 9.77108 3.22941 10.7713C4.22961 11.7715 5.58616 12.3334 7.00065 12.3334ZM6.33398 9.00004H7.66732V10.3334H6.33398V9.00004ZM6.33398 3.66671L7.66732 3.66671V7.66671H6.33398V3.66671Z' fill='%23E6000E'/%3E%3C/svg%3E")
              no-repeat calc(100% - 14px) center`} !important;
        }
        .form-list-container :global(.error-input-form:focus) {
          border: 1px solid red !important;
        }
      `}</style>
    </>
  );
};
