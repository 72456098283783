import { useTranslation } from "@equiem/localisation-eq1";
import { MemberCard } from "@equiem/react-admin-ui";
import type { FieldProps } from "formik";
import type { FC } from "react";
import React from "react";
import type { UserSearchUser } from "./UserSearchHOC";
import { UserSearchHOC } from "./UserSearchHOC";

export type BaseFieldProps = FieldProps<UserSearchUser>["field"];

interface Props extends BaseFieldProps {
  placeholder?: string;
  siteUuid: string;
}

export const UserSelectField: FC<Props> = ({ value, onChange, name, placeholder, siteUuid }) => {
  const { t } = useTranslation();

  const onSelect = (item?: UserSearchUser) => {
    onChange({
      target: {
        name: name,
        value: item,
      },
    });
  };

  return (
    <>
      {value != null ? (
        <MemberCard.Card
          className="selected-member mb-2"
          showButton={true}
          buttonVariant="outline"
          buttonText={t("common.change")}
          onButtonClick={() => {
            onSelect();
          }}
          email={value.email}
          companyName={value.companyV2?.name ?? ""}
          lastName={value.lastName ?? ""}
          firstName={value.firstName ?? ""}
          profileIconUrl={value.avatar ?? undefined}
        />
      ) : (
        <UserSearchHOC site={siteUuid} onSelect={onSelect} placeholder={placeholder} />
      )}
    </>
  );
};
