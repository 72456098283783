import { SubTopMenu } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import type { TabItem } from "@equiem/react-admin-ui";
import { ButtonLink, Tabs } from "@equiem/react-admin-ui";
import { RiAddLine } from "@equiem/react-admin-ui/icons";
import Link from "next/link";
import { useRouter } from "next/router";
import React, { useCallback, useContext, useMemo } from "react";
import { ArticleContext } from "../../contexts/ArticleContext";
import { CurrentPortfolio } from "../../contexts/PortfolioContext";
import { PortfolioSelector } from "./PortfolioSelector";

interface Props {
  title?: string;
}

export const Menu: React.FC<Props> = ({ title }) => {
  const router = useRouter();
  const { t } = useTranslation();
  const { id, slug } = router.query as { id: string; slug?: string };
  const { currentPortfolio } = useContext(CurrentPortfolio);
  const { forceAutoSave } = useContext(ArticleContext);

  const isNewArticle = router.pathname === "/content-management/[id]/articles/new";
  const isExistingArticle = router.pathname === "/content-management/[id]/articles/[slug]";

  const onTabClose = useCallback(async () => {
    await forceAutoSave();
    await router.push({
      pathname: "/content-management/[id]/articles",
      query: {
        ...router.query,
        id,
      },
    });
  }, [forceAutoSave, id, router]);

  const tabs = useMemo<TabItem[]>(() => {
    const _tabs = [
      {
        title: t("contentManagement.articles.allArticles"),
        key: "/content-management/[id]/articles",
        disabled: currentPortfolio == null,
      },
    ];

    if (isNewArticle) {
      const closeableTab: TabItem = {
        title: t("contentManagement.articles.newArticle"),
        key: "/content-management/[id]/articles/new",
        onClose: () => {
          void onTabClose();
        },
      };
      return [..._tabs, closeableTab];
    }

    if (isExistingArticle) {
      const closeableTab: TabItem = {
        title: title ?? t("contentManagement.articles.defaultTitle"),
        key: "/content-management/[id]/articles/[slug]",
        onClose: () => {
          void onTabClose();
        },
      };
      return [..._tabs, closeableTab];
    }

    return _tabs;
  }, [t, currentPortfolio, isNewArticle, isExistingArticle, onTabClose, title]);

  const handleTabSelect = (key: string) => {
    void router.push({
      pathname: key,
      query: {
        ...router.query,
        id,
        slug: key.includes("[slug]") ? slug : undefined,
      },
    });
  };

  return (
    <>
      <SubTopMenu className="title-container mb-0">
        <h1 style={{ fontWeight: "700" }}>{t("breadcrumbs.contentManagement")}</h1>
        <div className="actions d-flex">
          <PortfolioSelector />
        </div>
      </SubTopMenu>
      {currentPortfolio != null && (
        <SubTopMenu btmPadding={false} topPadding={false} minHeight={false} alignItems="flex-end" sticky>
          <Tabs
            items={tabs}
            selected={router.pathname}
            tabLinks
            onSelect={handleTabSelect}
            actionComponent={
              <Link href={`/content-management/${id}/articles/new`} passHref legacyBehavior>
                <ButtonLink
                  size="md"
                  variant="secondary"
                  className="create-button"
                  disabled={isNewArticle || isExistingArticle}
                >
                  <RiAddLine size={16} />
                  {t("contentManagement.articles.newArticle")}
                </ButtonLink>
              </Link>
            }
          />
        </SubTopMenu>
      )}
    </>
  );
};
