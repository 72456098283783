import React from "react";
import { useTranslation } from "@equiem/localisation-eq1";

import { EmptyAudienceIcon } from "./EmptyAudienceIcon";

export const EmptyAudience: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="empty-audience text-center text-muted py-9">
        <EmptyAudienceIcon />
        <p className="mt-5">{t("segmentation.emptyAudience")}</p>
        <p>{t("segmentation.emptyAudienceDescription")}</p>
      </div>
      <style jsx>{`
        p {
          padding: 0;
          margin: 0;
        }
      `}</style>
    </>
  );
};
