import React from "react";

export const SiteIcon = () => {
  return (
    <>
      <div className="shimmer"></div>
      <style jsx>{`
        div {
          height: 40px;
          width: 40px;
          border-radius: 20px;
        }
      `}</style>
    </>
  );
};
