import React, { useMemo } from "react";
import { BsChevronDown } from "react-icons/bs";
import color from "color";
import { useTheme } from "../../contexts/Theme";

// eslint-disable-next-line @typescript-eslint/no-type-alias
export type BaseButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  variant?: "link" | "primary" | "secondary";
  color?: string;
  dropdownToggle?: boolean;
};

export interface RoundButtonProps extends BaseButtonProps {
  round: true;
  /**
   * Large size only supported for round buttons.
   */
  size?: "sm" | "md" | "lg";
}

export interface NormalButtonProps extends BaseButtonProps {
  round?: false;
  size?: "sm" | "md";
}

const defaultSize = "md";

export type ButtonProps = NormalButtonProps | RoundButtonProps;

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ children, variant = "primary", dropdownToggle = false, color: colorInput, ...props }, ref) => {
    const theme = useTheme();
    const { animationDuration, focusOutline, mutedOpacity, spacers } = theme;

    const btnColors = useMemo(() => {
      const twentyPercent = 0.2;
      const custom = ((obj) =>
        obj == null
          ? undefined
          : {
              color: colorInput,
              contrast: obj.isDark() ? theme.colors.white : theme.colors.dark,
              darker: obj.darken(twentyPercent),
            })(colorInput != null ? color(colorInput) : undefined);

      return {
        border: {
          secondary: `1.5px solid ${custom?.color ?? theme.colors.buttonBorder}`,
          primary: `1.5px solid ${custom?.color ?? theme.colors.primary}`,
          link: "none",
        }[variant],
        background: {
          secondary: "none",
          primary: custom?.color ?? theme.colors.primary,
          link: "none",
        }[variant],
        color: {
          secondary: custom?.color ?? theme.colors.dark,
          primary: custom?.contrast ?? theme.colors.primaryContrast,
          link: custom?.color ?? theme.colors.dark,
        }[variant],
        hover: {
          border: {
            secondary: `1.5px solid ${custom?.darker ?? theme.colors.lightContrast}`,
            primary: `1.5px solid ${custom?.darker ?? theme.colors.primary}`,
            link: "none",
          }[variant],
          background: {
            secondary: "none",
            primary: custom?.darker ?? theme.colors.primaryShades.darker.bg,
            link: "none",
          }[variant],
          color: {
            secondary: custom?.darker ?? theme.colors.dark,
            primary: custom?.contrast ?? theme.colors.primaryShades.darker.fg,
            link: custom?.color ?? theme.colors.dark,
          }[variant],
        },
      };
    }, [theme.colors, variant, colorInput]);

    return (
      <button ref={ref} {...props}>
        {children}{" "}
        {dropdownToggle && (
          <BsChevronDown
            size={10}
            style={{
              marginRight: `-${spacers.s3}px`,
              marginTop: `${spacers.s0}px`,
            }}
          />
        )}
        <style jsx>{`
          button {
            cursor: pointer;
            font-weight: 500;
          }
        `}</style>
        <style jsx>{`
          button {
            border-radius: ${props.round === true ? "50%" : "4px"};
            white-space: ${props.round === true ? "auto" : "nowrap"};
            overflow: hidden;
            width: ${props.round !== true
              ? "auto"
              : {
                  sm: "32px",
                  md: "48px",
                  lg: "64px",
                }[props.size ?? defaultSize]};
            height: ${props.round !== true
              ? "auto"
              : {
                  sm: "32px",
                  md: "48px",
                  lg: "64px",
                }[props.size ?? defaultSize]};
            background: ${btnColors.background};
            border: ${btnColors.border};
            color: ${btnColors.color};
            font-size: ${dropdownToggle
              ? "14px"
              : props.round === true
              ? "16px"
              : {
                  sm: "12px",
                  md: "16px",
                }[props.size ?? defaultSize]};
            line-height: ${dropdownToggle
              ? "22px"
              : props.round === true
              ? "16px"
              : {
                  sm: "20px",
                  md: "24px",
                }[props.size ?? defaultSize]};
            padding: ${props.round === true
              ? "0"
              : {
                  sm: "4px 14.5px",
                  md: "8px 18.5px",
                }[props.size ?? defaultSize]};
            outline: none;
            transition: box-shadow ${animationDuration};
            text-transform: "none";
          }
          button:hover,
          button:focus,
          button:active,
          button:active:focus {
            background: ${btnColors.hover.background};
            border: ${btnColors.hover.border};
            color: ${btnColors.hover.color};
            text-decoration: ${variant === "link" ? "underline" : "none"};
            text-underline-position: under;
          }
          button:focus-visible {
            box-shadow: 0px 0px 0px ${focusOutline.size} ${focusOutline.colors.default};
          }
          button:disabled,
          button:disabled button:hover,
          button:disabled button:focus,
          button:disabled button:active,
          button:disabled button:active:focus {
            opacity: ${mutedOpacity};
            cursor: not-allowed;
            text-decoration: none;
            background: ${btnColors.background};
            border: ${btnColors.border};
            box-shadow: none;
          }
        `}</style>
      </button>
    );
  },
);

Button.displayName = "Button";
