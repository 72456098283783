export const DeleteIcon = () => {
  return (
    <svg width="8" height="8" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.99999 4.05734L8.29999 0.757339L9.24266 1.70001L5.94266 5.00001L9.24266 8.30001L8.29999 9.24267L4.99999 5.94267L1.69999 9.24267L0.757324 8.30001L4.05732 5.00001L0.757324 1.70001L1.69999 0.757339L4.99999 4.05734Z"
        fill="#666666"
      />
    </svg>
  );
};
