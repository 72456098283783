import { Role } from "@equiem/lib";

import { VisitorRole } from "../generated/visitors-client";

export const getReceptionRole = (userRole: Role): VisitorRole => {
  switch (userRole) {
    case Role.WorkplaceManager:
      return VisitorRole.WorkplaceManager;
    case Role.PropertyManager:
      return VisitorRole.PropertyManager;
    case Role.Unknown:
      return VisitorRole.Receptionist;
    /* istanbul ignore next */
    default:
      /* istanbul ignore next */
      return VisitorRole.Receptionist;
  }
};
