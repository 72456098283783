import React from "react";
import { useRouter } from "next/router";

import { useShowError } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import { Button, Text, useConfirmer } from "@equiem/react-admin-ui";

import { useDeactivateOwnUserMutation } from "../../../generated/settings-client";

export const UserProfileDangerZone: React.FC = () => {
  const router = useRouter();
  const { withConfirmation } = useConfirmer();
  const { t } = useTranslation();
  const showError = useShowError();

  const [mutation] = useDeactivateOwnUserMutation();
  const handleDeleteAccount = async () => {
    const { data } = await mutation();
    if (data?.deactivateOwnUser == null || data.deactivateOwnUser.__typename !== "SuccessfulDeactivateUserResponse") {
      console.error(data);
      throw new Error("failed to deactivate user");
    }

    await router.push("/api/logout");
  };

  return (
    <>
      <Text variant="heading" className="mb-6">
        {t("settings.dangerZone.tab")}
      </Text>
      <Text variant="text" size="small" className="mb-6">
        {t("settings.dangerZone.infoEndUser")}
      </Text>
      <Button
        variant="danger"
        onClick={withConfirmation({
          title: t("settings.dangerZone.deleteAccount"),
          message: t("settings.dangerZone.confirmDeleteAccount"),
          cancelButtonText: t("common.cancel"),
          confirmButtonText: t("settings.dangerZone.deleteAccount"),
          confirmButtonVariant: "danger",
          onConfirm: async () => handleDeleteAccount().catch(showError),
        })}
      >
        {t("settings.dangerZone.deleteAccount")}
      </Button>
    </>
  );
};
