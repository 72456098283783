import React, { useContext, useMemo } from "react";
import { useRouter } from "next/router";

import { CurrentRole, Role } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import type { TabItem } from "@equiem/react-admin-ui";
import { Tabs } from "@equiem/react-admin-ui";

const Menu = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const { currentRole } = useContext(CurrentRole);

  const tabs = useMemo(() => {
    const defaultTabs: TabItem[] = [];
    if (currentRole === Role.PropertyManager) {
      defaultTabs.push({
        title: t("visitors.settings.generalSettings"),
        key: "/visitor-management/settings/general",
      });
    }

    if (currentRole === Role.PropertyManager) {
      defaultTabs.push({
        title: t("visitors.settings.buildingSettingsv2"),
        key: "/visitor-management/settings/building",
      });
    }

    defaultTabs.push(
      {
        title: t("visitors.settings.receptionSettingsv2"),
        key: "/visitor-management/settings/receptions",
      },
      {
        title: t("visitors.settings.employeeSettingsv2"),
        key: "/visitor-management/settings/permissions",
      },
    );

    return defaultTabs;
  }, [currentRole, t]);

  const handleTabSelect = (key: string) => {
    void router.replace(key);
  };

  return (
    <div className="d-flex w-100">
      <Tabs items={tabs} selected={router.asPath} tabLinks onSelect={handleTabSelect} />
    </div>
  );
};

export { Menu };
