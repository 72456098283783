import React, { useEffect, useMemo } from "react";
import { DateTime } from "luxon";
import Link from "next/link";

import type { WidgetProps } from "@equiem/lib";
import { getReceptionName, Role } from "@equiem/lib";
import { formatters, useTranslation } from "@equiem/localisation-eq1";
import { ButtonLink, Widget as WidgetUI } from "@equiem/react-admin-ui";
import { RiTeamLine } from "@equiem/react-admin-ui/icons";

import { useVisitorReceptionsStatsQuery, VisitorRole } from "../generated/visitors-client";

import { EmptyWidget } from "./components/EmptyWidget";

type Props = Pick<WidgetProps, "setLoading" | "currentRole">;

export const ReceptionStatsWidget: React.FC<Props> = ({ setLoading, currentRole }) => {
  const { i18n, t } = useTranslation();
  const { data: receptionsData, loading } = useVisitorReceptionsStatsQuery({
    variables: {
      as: VisitorRole.Receptionist,
      dates: [DateTime.local().startOf("day").toMillis(), DateTime.local().plus({ days: 1 }).startOf("day").toMillis()],
    },
  });

  const activeReception = useMemo(() => {
    if (receptionsData != null) {
      return receptionsData.visitorReceptions.edges[0]?.node;
    }
    return null;
  }, [receptionsData]);

  useEffect(() => {
    if (!loading) {
      setLoading(false);
    }
  }, [setLoading, loading]);

  const isEmptyWidget =
    activeReception?.dailyStats[0].preBookedCount === 0 &&
    activeReception.dailyStats[1].preBookedCount === 0 &&
    !loading;
  const title = getReceptionName(activeReception, t);

  return (
    <>
      <WidgetUI.Widget
        icon={RiTeamLine}
        title={title}
        className="d-flex flex-column"
        buttonText={t("visitors.widgets.backToTop")}
        tooltip={
          currentRole === Role.PropertyManager
            ? t("visitors.widgets.statsTooltipPropertyManager")
            : t("visitors.widgets.statsTooltipWorkplaceManager")
        }
        button={
          <Link href={`/visitor-management/receptions/${activeReception?.uuid ?? ""}`} passHref legacyBehavior>
            <ButtonLink variant="secondary" className="access-button" size="md">
              {t("visitors.widgets.accessReception")}
            </ButtonLink>
          </Link>
        }
      >
        {isEmptyWidget ? (
          <div className="empty d-flex align-items-center justify-content-center">
            <EmptyWidget text={t("visitors.widgets.noDataCurrentlyAvailable", { title })} />
          </div>
        ) : (
          <>
            <h2 className="title mx-5 mt-5">{t("visitors.widgets.preBookedVisitors")}</h2>
            <div className="days p-5">
              <div className="day today d-flex flex-column justify-content-between p-5">
                <div className="d-flex flex-column">
                  <span className="title">{t("common.today")}</span>
                  <span className="date">{formatters.datelong(DateTime.local(), i18n.language)}</span>
                </div>
                <h1>{activeReception?.dailyStats[0].preBookedCount}</h1>
              </div>
              <div className="day tomorrow d-flex flex-column justify-content-between p-5">
                <div className="d-flex flex-column">
                  <span className="title">{t("common.tomorrow")}</span>
                  <span className="date">{formatters.datelong(DateTime.local().plus({ days: 1 }), i18n.language)}</span>
                </div>
                <h1>{activeReception?.dailyStats[1].preBookedCount}</h1>
              </div>
            </div>
          </>
        )}
        <style jsx>
          {`
            .empty {
              flex-grow: 1;
            }
            .days {
              display: grid;
              grid-auto-flow: column;
              grid-gap: 8px;
              flex-grow: 1;
            }
            .day {
              border: 1px solid #e6e6e6;
              border-radius: 4px;
            }
            .title {
              font-weight: 600;
              font-size: 12px;
              line-height: 16px;
              text-transform: uppercase;
              color: rgba(0, 0, 0, 0.6);
            }
            .date {
              font-size: 12px;
              line-height: 16px;
              color: #666666;
            }
            h1 {
              font-weight: 600;
              font-size: 64px;
              line-height: 64px;
            }
            :global(.access-button) {
              min-width: 140px;
            }
          `}
        </style>
      </WidgetUI.Widget>
    </>
  );
};
