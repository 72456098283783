import React, { useContext, useEffect, useState } from "react";

import { useShowError } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import {
  Button,
  Form,
  Modal,
  ProgressCircle,
  Tag,
  Text,
  useDebounced,
  useIsMobileWidth,
  useTheme,
  useToast,
} from "@equiem/react-admin-ui";
import { RiSearchLine } from "@equiem/react-admin-ui/icons";

import { Modal as ModalContext } from "../contexts/ModalContext";

type ListItem = {
  uuid: string;
  name: string;
};

const LoadingOrNotFound = ({
  loading,
  hasItems,
  children,
}: {
  loading: boolean;
  hasItems: boolean;
  children: React.ReactNode;
}) => {
  const { colors } = useTheme();

  if (loading) {
    return (
      <div className="mt-6 mb-6 d-flex justify-content-center">
        <ProgressCircle size="xs" />
      </div>
    );
  }

  if (!hasItems) {
    return (
      <div className="mt-6 mb-6 d-flex justify-content-center">
        <Text variant="text" size="small" style={{ color: colors.grayscale[60] }}>
          {children}
        </Text>
      </div>
    );
  }

  return null;
};

interface Props {
  items: ListItem[];
  onSearch: (search: string) => void;
  onAdd: (id: string) => Promise<void>;
  onRemove: (id: string) => Promise<void>;
  onFetchMore: () => void;
  onHide?: () => void;
  loading: boolean;
  type: "company" | "apartment";
}

export const AddFromCatalogue: React.FC<Props> = ({
  items,
  loading,
  type,
  onSearch,
  onFetchMore,
  onAdd,
  onRemove,
  onHide,
}) => {
  const isMobile = useIsMobileWidth();
  const { t } = useTranslation();
  const [search, setSearch] = useState("");
  const [showModal, setShowModal] = useState(false);
  const modal = useContext(ModalContext);
  const debouncedSearch = useDebounced(search, 500);
  const [itemsAdded, setItemsAdded] = useState<string[]>([]);
  const toast = useToast();
  const showError = useShowError();

  useEffect(() => {
    if (modal.activeModal === "AddCatalogue") {
      setShowModal(true);
    }
  }, [modal.activeModal]);

  useEffect(() => {
    onSearch(debouncedSearch);
  }, [debouncedSearch, onSearch]);

  const handleOnHide = () => {
    setSearch("");
    setItemsAdded([]);
    setShowModal(false);
    onHide?.();
    modal.close();
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  return (
    <>
      <Modal.Dialog
        title={t("settings.addCompanyFromCatalogue")}
        show={showModal}
        onHide={handleOnHide}
        hideOnEsc={true}
        hideOnClick={true}
        size="md"
        sideModal={isMobile}
        scrollable
      >
        <Modal.Header closeButton noBorder supportsMobile>
          {!isMobile && (
            <div className="hide-sticky">
              <Text variant="heading">
                {type === "company" ? t("settings.selectCompany") : t("settings.selectApartment")}
              </Text>
            </div>
          )}
          <div className="my-4">
            <Form.InputExtended
              icon={RiSearchLine}
              placeholder={type === "company" ? t("settings.findACompany") : t("settings.findAnApartment")}
              value={search}
              onChange={handleSearch}
              onClear={() => setSearch("")}
              clearable
            />
          </div>
        </Modal.Header>
        <Modal.Body onScrolledToBottom={onFetchMore}>
          <div>
            {items.map((item) => {
              const added = itemsAdded.includes(item.uuid);
              let addedToast: ReturnType<typeof toast.neutral> | null = null;
              const handleRemove = () => {
                addedToast?.remove();
                onRemove(item.uuid).catch(showError);
                setItemsAdded(itemsAdded.filter((uuid) => uuid !== item.uuid));
                setTimeout(() => {
                  toast.neutral(t("settings.companyRemoved", { company: item.name }));
                }, 0);
              };
              return (
                <div className="d-flex my-5" key={item.uuid}>
                  <Text variant="text" weight="bold" className="w-75 my-0">
                    {item.name} {added ? <Tag className="ml-2">{t("common.added")}</Tag> : null}
                  </Text>
                  <div>
                    {!added ? (
                      <Button
                        variant="outline"
                        size="sm"
                        className="ml-5"
                        onClick={() => {
                          onAdd(item.uuid)
                            .catch(showError)
                            .finally(() => {
                              addedToast = toast.neutral(t("settings.companyAdded", { company: item.name }), {
                                secondaryAction: {
                                  title: t("common.undo"),
                                  onClick: handleRemove,
                                },
                              });
                            });
                          setItemsAdded([...itemsAdded, item.uuid]);
                        }}
                      >
                        {t("settings.addToSite")}
                      </Button>
                    ) : (
                      <Button variant="ghost" size="sm" className="ml-5" onClick={handleRemove}>
                        {t("common.remove")}
                      </Button>
                    )}
                  </div>
                </div>
              );
            })}
            <LoadingOrNotFound loading={loading} hasItems={items.length > 0}>
              {type === "company"
                ? t("settings.emptyCompaniesTableSearch", { search: debouncedSearch })
                : t("settings.emptyApartmentsTableSearch", { search: debouncedSearch })}
            </LoadingOrNotFound>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleOnHide}>{t("common.done")}</Button>
        </Modal.Footer>
      </Modal.Dialog>
      <style jsx>{`
        :global(.sticky) .hide-sticky {
          display: none;
        }
      `}</style>
    </>
  );
};
