import React, { useState } from "react";

import { useSiteContext } from "@equiem/lib";
import { formatters, useTranslation } from "@equiem/localisation-eq1";
import { Button, EmptyState, Form, Table, Text, useTheme, useToast } from "@equiem/react-admin-ui";
import { RiAddLine } from "@equiem/react-admin-ui/icons";

import type { ProfileDetailsQuery } from "../../../generated/settings-client";
import {
  ProfileHistorySortField,
  useAddProfileCommentMutation,
  useUserHistoryQuery,
} from "../../../generated/settings-client";

interface Props {
  profile: ProfileDetailsQuery["profile"];
}

export const UserHistory: React.FC<Props> = ({ profile }) => {
  const { colors } = useTheme();
  const { t, i18n } = useTranslation();
  const toast = useToast();
  const site = useSiteContext();
  const [message, setMessage] = useState("");
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("desc");
  const [messageMutation, { loading: messageSubmitting }] = useAddProfileCommentMutation({});

  const { data, loading } = useUserHistoryQuery({
    variables: {
      uuid: profile?.uuid ?? "",
      destinationUuid: site.uuid,
      page: {
        first: 100,
      },
      sort: {
        field: ProfileHistorySortField.Timestamp,
        asc: sortDirection === "asc",
      },
    },
    fetchPolicy: "network-only",
  });
  const history = data?.user?.profile?.history.edges ?? [];

  const handleMessage = (e: React.ChangeEvent<HTMLInputElement>) => {
    setMessage(e.target.value);
  };

  const addMessage = async () => {
    try {
      await messageMutation({
        variables: {
          message,
          profileUuid: profile?.uuid ?? "",
          destinationUuid: site.uuid,
        },
        refetchQueries: ["UserHistory"],
      });

      setMessage("");
      toast.positive(
        t("settings.history.messageAdded", {
          name: `${profile?.firstName} ${profile?.lastName}`,
        }),
      );
    } catch {
      toast.negative(t("common.somethingWrong"));
    }
  };

  const handleSortDirection = () => {
    setSortDirection((prev) => (prev === "asc" ? "desc" : "asc"));
  };

  return (
    <>
      <div className="history-container">
        <Text variant="heading">{t("settings.history.title")}</Text>
        <Text variant="text" size="small" className="mt-2 mb-6">
          {t("settings.history.description")}
        </Text>
        <div className="message-container">
          <Form.Input
            placeholder={t("settings.history.writeMessage")}
            onChange={handleMessage}
            className="search"
            value={message}
            variant="sm"
          />
          <Button
            variant="secondary"
            size="md"
            disabled={message.length === 0 || messageSubmitting}
            onClick={() => {
              void addMessage();
            }}
          >
            <RiAddLine size={16} />
            {t("settings.history.addMessage")}
          </Button>
        </div>
        <Table.Table className="w-100">
          <thead>
            <tr>
              <Table.Header
                label={t("common.date")}
                direction={sortDirection}
                onClick={handleSortDirection}
                className="date-column"
              />
              <Table.Header label={t("common.message")} />
            </tr>
          </thead>
          <tbody>
            {history.map(({ node }) => {
              return (
                <tr key={node?.uuid}>
                  <td>
                    <div className="text-cell">
                      <span>{formatters.timeshort(node?.timestamp ?? 0, i18n.language)}</span>
                      <span className="details">{formatters.dateshort(node?.timestamp ?? 0, i18n.language)}</span>
                    </div>
                  </td>
                  <td>
                    <div className="text-cell">
                      <span>{node?.message}</span>
                      {node?.triggeredBy?.displayName != null && (
                        <span className="details">
                          {t("settings.history.changedBy", {
                            name: node.triggeredBy.displayName,
                          })}
                        </span>
                      )}
                    </div>
                  </td>
                </tr>
              );
            })}
            {loading && <Table.Loader loading={loading} colSpan={2} />}
            {!loading && history.length === 0 && (
              <tr>
                <td colSpan={2}>
                  <EmptyState message={t("settings.history.emptyList")} />
                </td>
              </tr>
            )}
          </tbody>
        </Table.Table>
      </div>
      <style jsx>{`
        .history-container {
          margin: 24px 0;
        }

        .message-container {
          display: grid;
          grid-template-columns: 1fr auto;
          grid-gap: 8px;
          margin-bottom: 8px;
        }

        .text-cell {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
        }

        .history-container :global(.date-column) {
          width: 30%;
        }

        .text-cell > .details {
          font-size: 0.75rem;
          color: ${colors.grayscale[60]};
        }
      `}</style>
    </>
  );
};
