import { useContext, useMemo } from "react";
import { contrastsWithWhite } from "../lib/contrastsWithWhite";
import { Site } from "@equiem/lib";

export const useLogoBackground = () => {
  const site = useContext(Site);

  return useMemo(() => {
    const whiteBg = site.showLogoOnWhiteBackground;
    const bgColor = whiteBg ? "#fff" : site.primaryColour;
    const fgColor =
      !contrastsWithWhite(bgColor) && !contrastsWithWhite(site.primaryColour)
        ? "#000"
        : whiteBg
        ? site.primaryColour
        : "#fff";

    return { whiteBg, bgColor, fgColor };
  }, [site]);
};
