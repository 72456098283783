import React from "react";

import type { ButtonProps } from "../../Button/Button";
import { Dropdown } from "../Dropdown";
import type { DropdownMobileView } from "../DropdownMenu/DropdownMenu";
import { DropdownMenu } from "../DropdownMenu/DropdownMenu";
import { DropdownToggle } from "../DropdownToggle/DropdownToggle";
import type { Placement } from "@floating-ui/react-dom";

export type DropdownButtonProps = ButtonProps & {
  placement?: Placement;
  mobileView?: DropdownMobileView;
  onSelect?: (eventKey: string) => undefined;
};

export interface DropdownButtonRef {
  click: () => void;
  focus: () => void;
}

export const DropdownButton = React.forwardRef<DropdownButtonRef, DropdownButtonProps>(
  ({ children, placement = "bottom-start", mobileView, onSelect, ...props }, ref) => {
    return (
      <Dropdown placement={placement} onSelect={onSelect} flip>
        <DropdownToggle {...props} ref={ref}>
          {props.title}
        </DropdownToggle>
        <DropdownMenu title={props.title} mobileView={mobileView}>
          {children}
        </DropdownMenu>
      </Dropdown>
    );
  },
);

DropdownButton.displayName = "DropdownButton";
