import React, { useContext, useMemo, useState } from "react";
import { Dropdown } from "../Dropdown";
import { DropdownMenu } from "../Dropdown/DropdownMenu/DropdownMenu";
import { FilterChip } from "./FilterChip";
import { ComplexFilterContext } from "./ComplexFilterContext";
import { Text } from "../Text/Text";
import * as Form from "../Form";
import { useTheme } from "../../contexts/Theme";
import type { FilterItemProps, FilterItemText } from "./ComplexFilter.types";
import { FilterTextModifier, FilterType } from "./ComplexFilter.types";
import { useTranslation } from "@equiem/localisation-eq1";
import { ModifierSelect } from "./ModifierSelect";
import { RiSearchLine } from "../../admin-icons";

export const defaultModifier = FilterTextModifier.includes;

export const FilterText: React.FC<FilterItemProps<FilterItemText>> = ({
  title,
  name,
  modifiers,
  icon,
  multiline,
  placeholder,
}) => {
  const { t } = useTranslation();
  const { colors } = useTheme();
  const { values, setValue, removeValue } = useContext(ComplexFilterContext);
  const [selectedModifier, setSelectedModifier] = useState<FilterTextModifier>(
    (values[name]?.modifier ?? modifiers?.[0] ?? defaultModifier) as FilterTextModifier,
  );
  const [prevSelectedModifier, setPrevSelectedModifier] = useState(selectedModifier);

  if (selectedModifier !== prevSelectedModifier) {
    setPrevSelectedModifier(selectedModifier);
    setValue(name, {
      type: FilterType.text,
      modifier: selectedModifier,
      value: undefined,
    });
  }

  const labelValue = useMemo(() => {
    const value = values[name];

    if (value?.value == null) {
      return undefined;
    }

    if (value.modifier === FilterTextModifier.includes) {
      return `“${value.value}”`;
    }

    return undefined;
  }, [values, name]);

  const getLocalizedTitle = (modifier: FilterTextModifier) => {
    switch (modifier) {
      case FilterTextModifier.includes:
        return t("common.includes");
      default:
        return "";
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
    setValue(name, {
      type: FilterType.text,
      modifier: selectedModifier,
      value: e.target.value.length > 0 ? e.target.value : undefined,
    });
  };

  const clearSearch = () => {
    setValue(name, {
      type: FilterType.text,
      modifier: selectedModifier,
      value: undefined,
    });
  };

  const value = values[name]?.value as string | undefined;

  return (
    <>
      <Dropdown placement="bottom-start" flip>
        <FilterChip
          title={title}
          value={labelValue}
          name={name}
          type={FilterType.text}
          onClose={() => removeValue(name)}
          icon={icon}
        />
        <DropdownMenu width={252} mobileView="regular">
          {modifiers != null && (
            <div className="text-top">
              <Text variant="label" className="m-0">
                {title}
              </Text>
              <ModifierSelect
                options={modifiers.map((m) => ({
                  value: m,
                  label: getLocalizedTitle(m),
                }))}
                selected={selectedModifier}
                onChange={(v) => setSelectedModifier(v as FilterTextModifier)}
              />
            </div>
          )}
          <div className="text-input">
            {multiline === true ? (
              <Form.Textarea
                value={value ?? ""}
                onChange={handleChange}
                placeholder={placeholder ?? t("common.enterText") ?? ""}
              />
            ) : (
              <div className="search-input">
                <Form.InputExtended
                  icon={RiSearchLine}
                  placeholder={`${t("common.search")}...`}
                  variant="sm"
                  value={value ?? ""}
                  onChange={handleChange}
                  onClear={clearSearch}
                  clearable
                />
              </div>
            )}
          </div>
        </DropdownMenu>
      </Dropdown>
      <style jsx>{`
        .text-top {
          padding: 0 1rem 0.25rem;
          display: flex;
          flex-direction: row;
          align-items: center;
          color: ${colors.grayscale[100]};
        }

        .text-input {
          display: flex;
          padding: 0 0.5rem;
        }
        .text-input :global(textarea) {
          resize: none;
        }

        .search-input {
          width: 100%;
        }
      `}</style>
    </>
  );
};
