import React from "react";

import { stringIsEmpty, useSaferFormikContext } from "@equiem/lib";
import { Form } from "@equiem/react-admin-ui";

import type { NewRequestFormValues } from "../../create-request/NewRequest";

export const NewRequestDescription = ({ disabled }: { disabled: boolean }) => {
  const { setFieldValue, values } = useSaferFormikContext<NewRequestFormValues>();

  return (
    <>
      <div>
        <Form.Textarea
          value={values.description}
          className="p-3"
          onChange={(e) => {
            void setFieldValue("description", e.target.value);
          }}
          showChrome
          disabled={disabled || stringIsEmpty(values.space?.uuid)}
        />
      </div>
    </>
  );
};
