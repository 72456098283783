import React, { type FC, type ReactNode, createContext, useMemo, useEffect } from "react";
import { useFormikContext } from "formik";
import { stringIsEmpty, stringNotEmpty, notNullOrUndefined } from "@equiem/lib";

import { useBuildingAccessGroupsQuery, type BuildingAccessGroupsQuery } from "../generated/gateway-client";
import type { FormValues } from "../lib/formValidation";

export type AccessGroup = NonNullable<BuildingAccessGroupsQuery["barrierControlAccessValues"][number]>;

export interface ResourceEditAccessControlContextType {
  accessGroups: AccessGroup[];
  accessControlEnabledForBuilding: boolean;
}

export const ResourceEditAccessControlContext = createContext<ResourceEditAccessControlContextType>({
  accessGroups: [],
  accessControlEnabledForBuilding: false,
});

const accessGroupSelectionIsValid = (
  accessGroupUuid: string | null | undefined,
  accessControlEnabledForBuilding: boolean,
  accessGroups: AccessGroup[],
) =>
  stringIsEmpty(accessGroupUuid) ||
  (accessControlEnabledForBuilding && accessGroups.some(({ uuid }) => uuid === accessGroupUuid));

export const ResourceEditAccessControlContextProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const fm = useFormikContext<FormValues>();

  const { data, loading } = useBuildingAccessGroupsQuery({
    variables: { buildingUuid: fm.values.building },
    skip: stringIsEmpty(fm.values.building),
  });

  const accessGroups = useMemo(
    () => (data?.barrierControlAccessValues ?? []).filter(notNullOrUndefined),
    [data?.barrierControlAccessValues],
  );

  const accessControlEnabledForBuilding = stringNotEmpty(fm.values.building) && !loading && accessGroups.length > 0;

  useEffect(() => {
    if (loading) {
      return;
    }

    const hostGroup = fm.values.barrierControlAccessUuid;
    const hostGroupValid = accessGroupSelectionIsValid(hostGroup, accessControlEnabledForBuilding, accessGroups);
    if (!hostGroupValid) {
      fm.setFieldValue("barrierControlAccessUuid", null).catch(console.error);
    }

    const visitorGroup = fm.values.barrierControlVisitorAccessUuid;
    const visitorGroupValid = accessGroupSelectionIsValid(visitorGroup, accessControlEnabledForBuilding, accessGroups);
    if (!visitorGroupValid) {
      fm.setFieldValue("barrierControlVisitorAccessUuid", null).catch(console.error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    loading,
    accessControlEnabledForBuilding,
    accessGroups,
    fm.values.barrierControlAccessUuid,
    fm.values.barrierControlVisitorAccessUuid,
  ]);

  return (
    <ResourceEditAccessControlContext.Provider value={{ accessGroups, accessControlEnabledForBuilding }}>
      {children}
    </ResourceEditAccessControlContext.Provider>
  );
};
