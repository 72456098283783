import type { NextPage } from "next";
import React from "react";

import type { DisplayMode } from "../pages/operations/contexts/BookingModalContext";
import { BookingModalProvider } from "../pages/operations/contexts/BookingModalContext";

// @todo rename this.
export const withContexts = <P,>(Page: NextPage<P>, defaultMode?: DisplayMode): NextPage<P> => {
  const PageWithContexts: NextPage<P> = (props) => (
    <BookingModalProvider defaultDisplayMode={defaultMode}>
      <Page {...(props as P & React.JSX.IntrinsicAttributes)} />
    </BookingModalProvider>
  );

  return PageWithContexts;
};
