import React, { useCallback, useContext, useState, createContext } from "react";

export const BcUuidContext = createContext({
  updateBcUuid: (_uuid: string, _name: string): void => undefined,
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  bcUuid: {} as null | Record<string, string>,
});

export function useBcUuidContext() {
  return useContext(BcUuidContext);
}

export const BreadcrumbsUuidProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const [bcUuid, setBcUuid] = useState<null | Record<string, string>>(null);
  const updateBcUuid = useCallback(
    (uuid: string, name: string) => {
      setBcUuid((prev) => {
        return { ...prev, [uuid]: name };
      });
    },
    [setBcUuid],
  );

  return <BcUuidContext.Provider value={{ bcUuid, updateBcUuid }}>{children}</BcUuidContext.Provider>;
};
