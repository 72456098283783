import type { ReactNode } from "react";
import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";

import { CurrentProfile, useSiteContext } from "@equiem/lib";

type ActiveRequestTab = {
  uuid: string;
  reference: string;
};

interface RequestsMenuContext {
  activeTabs: ActiveRequestTab[];
  disabled: boolean;
  setDisabled: (disabled: boolean) => void;
  addTab: (tab: ActiveRequestTab) => void;
  closeTab: (uuid: string) => void;
}

export const RequestsMenuContext = createContext<RequestsMenuContext>({
  activeTabs: [],
  disabled: false,
  setDisabled: () => undefined,
  addTab: () => undefined,
  closeTab: () => undefined,
});

export const RequestsMenuProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const MAX_TABS = 5;
  const site = useSiteContext();
  const { profile } = useContext(CurrentProfile);
  const [activeTabs, setActiveTabs] = useState<ActiveRequestTab[]>([]);
  const [disabled, setDisabled] = useState(false);

  const sessionName = useMemo(() => `REQUEST_MENU_TABS_${site.uuid}_${profile?.uuid}`, [site.uuid, profile?.uuid]);

  useEffect(() => {
    const tabs = sessionStorage.getItem(sessionName);

    if (tabs != null) {
      setActiveTabs(JSON.parse(tabs));
    }
  }, [sessionName]);

  const addTab = useCallback(
    (tab: ActiveRequestTab) => {
      const sessionTabs = sessionStorage.getItem(sessionName);
      const parsedTabs: ActiveRequestTab[] = sessionTabs != null ? (JSON.parse(sessionTabs) as ActiveRequestTab[]) : [];
      setActiveTabs(() => {
        if (parsedTabs.find((t) => t.uuid === tab.uuid) != null) {
          return parsedTabs;
        }

        const tabs = parsedTabs.length >= MAX_TABS ? [...parsedTabs.slice(1), tab] : [...parsedTabs, tab];
        sessionStorage.setItem(sessionName, JSON.stringify(tabs));

        return tabs;
      });
    },
    [sessionName],
  );

  const closeTab = (uuid: string) => {
    setActiveTabs((prev) => {
      const tabs = prev.filter((t) => t.uuid !== uuid);
      sessionStorage.setItem(sessionName, JSON.stringify(tabs));
      return tabs;
    });
  };

  return (
    <RequestsMenuContext.Provider
      value={{
        activeTabs,
        disabled,
        setDisabled,
        addTab,
        closeTab,
      }}
    >
      {children}
    </RequestsMenuContext.Provider>
  );
};
