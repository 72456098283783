import React, { useMemo } from "react";
import { FilterTab } from "@equiem/react-admin-ui";
import { useTranslation } from "@equiem/localisation-eq1";

import type { CmsSearchFilters } from "../../../generated/gateway-client";
import { useCountArticlesQuery } from "../../../generated/gateway-client";

interface Props {
  tabFilter: CmsSearchFilters;
  portfolioUuid: string | undefined;
  setTabFilter: (filter: CmsSearchFilters) => void;
}

const tabOrder = ["all", "published", "unpublishedChanges", "draft", "archived"] as const;

type Tab = (typeof tabOrder)[number];

// Add index signature to allow indexing with a string
type TabFiltersIndexSignature = Record<string, CmsSearchFilters>;
const tabFilters: Record<Tab, CmsSearchFilters> & TabFiltersIndexSignature = {
  all: {},
  published: { isPublished: true },
  unpublishedChanges: { isPublished: true, hasUnpublishedChanges: true },
  draft: { isPublished: false },
  archived: { isArchived: true },
} as const;

export const defaultTabFilter = tabFilters.all;

export const ArticleTabs: React.FC<Props> = ({ portfolioUuid, setTabFilter, tabFilter }) => {
  const activeTab: Tab = useMemo(
    () => Object.keys(tabFilters).find((key) => JSON.stringify(tabFilters[key]) === JSON.stringify(tabFilter)) as Tab,
    [tabFilter],
  );
  const { t } = useTranslation();

  const title: Record<Tab, string> = {
    all: t("common.all"),
    published: t("contentManagement.statuses.published"),
    unpublishedChanges: t("contentManagement.statuses.draftDifferentToPublished"),
    draft: t("contentManagement.statuses.draft"),
    archived: t("contentManagement.statuses.archived"),
  };

  const { data: count, loading: countLoading } = useCountArticlesQuery({
    variables: { portfolio: portfolioUuid ?? "", ...tabFilters },
    fetchPolicy: "cache-and-network",
    skip: portfolioUuid == null,
  });

  return (
    <>
      {tabOrder.map((tab) => (
        <FilterTab
          key={tab}
          title={title[tab]}
          value={count?.[tab]}
          valueLoading={countLoading}
          isActive={activeTab === tab}
          onClick={() => {
            setTabFilter(tabFilters[tab]);
          }}
        />
      ))}
    </>
  );
};
