import "iframe-resizer/js/iframeResizer.contentWindow";

import type { ReactNode } from "react";
import React, { useEffect, useState } from "react";

import type { IFramePage } from "iframe-resizer";
import { IframeResizerProvider } from "./IframePageInfoContext";
import { VisibleAreaProvider } from "./VisibleAreaContext";

declare global {
  interface Window {
    parentIFrame?: IFramePage;
  }
}

export const IframeListener: React.FC<{ children: ReactNode }> = ({ children }) => {
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const [parentIFrame, setParentIFrame] = useState(window?.parentIFrame);

  useEffect(() => {
    if (parentIFrame != null || typeof window === "undefined") {
      return () => undefined;
    }

    const listener = (e: MessageEvent) => {
      if (typeof e.data === "string" && e.data.startsWith("[iFrameSizer]")) {
        setParentIFrame(window.parentIFrame);
      }
    };

    window.addEventListener("message", listener);

    return () => {
      window.removeEventListener("message", listener);
    };
  }, [parentIFrame]);

  return (
    <IframeResizerProvider parentIFrame={parentIFrame}>
      <VisibleAreaProvider>{children}</VisibleAreaProvider>
    </IframeResizerProvider>
  );
};
