import { stringNotEmpty } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import { Button, Form, useTheme } from "@equiem/react-admin-ui";
import { RiCloseLine, RiPlayCircleFill } from "@equiem/react-admin-ui/icons";
import type { IImage } from "@equiem/uploader";
import { Field, useFormikContext } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import SortableList, { SortableItem } from "react-easy-sort";
import { ResourceDivider } from "../ResourceDivider";
import { ResourceCreateAndEditFormGalleryModal } from "./ResourceCreateAndEditFormGalleryModal";
import { ResourceVideoThumbnail, getVideoThumbnail } from "../../ResourceVideoThumbnail";
import type { FormValues } from "../../../../../lib/formValidation";
import type { IVideo } from "../../../../../components/VideoLinkInputNew";
import { getVideoUrl } from "../../../../../lib/getVideoUrl";

const IMG_WIDTH = 143;
const IMG_HEIGHT = 80;

export const ResourceCreateAndEditFormGallery: React.FC = () => {
  const { t } = useTranslation();
  const fm = useFormikContext<FormValues>();
  const { breakpoints, colors, spacers } = useTheme(true);
  const [showModal, setShowModal] = useState(false);
  const [videoThumbnailUrl, setVideoThumbnailUrl] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const handleAddSelection = (images: IImage[], video?: IVideo | null) => {
    fm.setFieldValue("images", [...fm.values.images, ...images]).catch(console.error);
    if (video != null) {
      fm.setFieldValue("video", video).catch(console.error);
    }

    setShowModal(false);
  };

  const sortOrder = useCallback(
    (fromIndex: number, toIndex: number) => {
      const newArray = [...fm.values.images];
      const startIndex = fromIndex < 0 ? newArray.length + fromIndex : fromIndex;

      if (startIndex >= 0 && startIndex < newArray.length) {
        const endIndex = toIndex < 0 ? newArray.length + toIndex : toIndex;

        const [item] = newArray.splice(fromIndex, 1);
        newArray.splice(endIndex, 0, item);

        fm.setFieldValue("images", newArray).catch(console.error);
      }
    },
    [fm],
  );

  const removeImage = useCallback(
    (imageKey: string) => {
      const newArray = fm.values.images.filter((img) => img.key !== imageKey);
      fm.setFieldValue("images", newArray).catch(console.error);
    },
    [fm],
  );

  const videoUrl = getVideoUrl(fm.values.video);
  useEffect(() => {
    let active = true;
    setLoading(true);

    if (videoUrl != null) {
      getVideoThumbnail(videoUrl)
        .then((result) => {
          if (active) {
            setVideoThumbnailUrl(result);
            setLoading(false);
          }
        })
        .catch((e) => {
          if (active) {
            console.error(e);
            setLoading(false);
          }
        });
    } else {
      setVideoThumbnailUrl(null);
      setLoading(false);
    }

    return () => {
      active = false;
    };
  }, [videoUrl, setVideoThumbnailUrl]);

  const removeVideo = useCallback(() => {
    fm.setFieldValue("video", null).catch(console.error);
    fm.setFieldValue("videoTitle", "").catch(console.error);
  }, [fm]);

  return (
    <>
      {fm.values.images.length > 0 && (
        <SortableList onSortEnd={sortOrder} draggedItemClassName="photo-dragged">
          <div className="photos-container">
            {fm.values.images.map((img, index) => (
              <SortableItem key={img.key}>
                <div className="photo-cont">
                  {index === 0 && (
                    <div className="thumbnail">{t("bookings.resources.resourceThumbnail").toLowerCase()}</div>
                  )}
                  <img
                    src={
                      stringNotEmpty(img.url)
                        ? `${img.url}?auto=compress&fit=crop&crop=entropy&w=${IMG_WIDTH}&h=${IMG_HEIGHT}`
                        : ""
                    }
                    alt=""
                  />
                  <div className="delete-photo">
                    <RiCloseLine size={16} onClick={() => removeImage(img.key)} />
                  </div>
                </div>
              </SortableItem>
            ))}
          </div>
        </SortableList>
      )}
      {fm.values.images.length > 0 && videoUrl != null && <ResourceDivider />}
      {videoUrl != null && (
        <div className="form-group-container">
          <div className="video-cont">
            <ResourceVideoThumbnail
              imageUrl={videoThumbnailUrl}
              loading={loading}
              thumbnailWidth={IMG_WIDTH}
              thumbnailHeight={IMG_HEIGHT}
            />
            <a href={videoUrl} target="_blank" rel="noreferrer">
              <RiPlayCircleFill className="play-icon" size={40} color="white" />
            </a>
            <div className="delete-video">
              <RiCloseLine size={16} onClick={() => removeVideo()} />
            </div>
          </div>

          <Form.Group label={t("bookings.resources.videoTitle")}>
            <Field
              id="videoTitle"
              name="videoTitle"
              as={Form.Input}
              placeholder={t("bookings.resources.videoTitlePlaceholder")}
            />
          </Form.Group>
        </div>
      )}
      <Button
        type="button"
        variant="secondary"
        size="lg"
        className="add-photos-video-button"
        onClick={() => setShowModal(true)}
      >
        {t("common.add")}
      </Button>
      <ResourceCreateAndEditFormGalleryModal
        showModal={showModal}
        setShowModal={setShowModal}
        onAdd={handleAddSelection}
        imagesAdded={fm.values.images.length > 0}
        videoAdded={videoUrl != null}
      />
      <style jsx>{`
        hr {
          margin: ${spacers.s0};
        }
        .photos-container {
          display: flex;
          flex-wrap: wrap;
          gap: ${spacers.s4};
          user-select: none;
        }
        .photo-cont {
          user-select: none;
          position: relative;
          cursor: grab;
        }
        .video-cont {
          position: relative;
          width: fit-content;
        }
        .photo-cont .thumbnail {
          position: absolute;
          left: ${spacers.s0};
          top: ${spacers.s0};
          font-size: 10px;
          font-weight: 500;
          line-height: 24px;
          padding: ${spacers.s0} ${spacers.s2};
          background: ${colors.transparent.white[80]};
          border-radius: 4px;
        }
        .photo-cont img,
        .video-cont img {
          pointer-events: none;
          width: ${IMG_WIDTH}px;
          height: ${IMG_HEIGHT}px;
          border-radius: 4px;
          border: 1px solid ${colors.border};
        }
        .photo-cont :global(.delete-photo),
        .video-cont :global(.delete-video) {
          position: absolute;
          top: ${spacers.s3};
          right: ${spacers.s3};
          border-radius: 16px;
          cursor: pointer;
          background: white;
        }
        .video-cont :global(.play-icon) {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
        .photo-dragged {
          z-index: 36;
          opacity: 0.7 !important;
        }
        .form-group-container {
          display: flex;
          gap: ${spacers.s5};
        }
        .add-photos-video-button {
          width: 100%;
        }
        @media screen and (max-width: ${breakpoints.md}px) {
          .form-group-container {
            flex-direction: column;
          }
        }
      `}</style>
    </>
  );
};
