import { useTranslation } from "@equiem/localisation-eq1";
import { Button, Form as EqForm, Modal, useToast } from "@equiem/react-admin-ui";
import type { FC } from "react";
import React, { useContext, useMemo } from "react";
import type { FormikHelpers } from "formik";
import { Field, Form, Formik } from "formik";
import type { PublishCmsArticleInput } from "../../generated/gateway-client";
import { PublicationDateType, usePublishArticleMutation } from "../../generated/gateway-client";
import { ArticleContext } from "../../contexts/ArticleContext";
import { SideModalContext } from "../../contexts/SideModalContext";
import { RiCalendarLine, RiMailSendLine } from "@equiem/react-admin-ui/icons";
import * as yup from "yup";

export const PublishingSettingsInner: FC = () => {
  const { t } = useTranslation();
  const { article, setArticle } = useContext(ArticleContext);
  const { openTab, closeModal } = useContext(SideModalContext);
  const toast = useToast();
  const [mutation] = usePublishArticleMutation();

  const initialValues: PublishCmsArticleInput = useMemo(() => {
    return {
      uuid: article?.uuid ?? "", // This will always be set in any case.
      publicationDateType: article?.publicationDateType,
      publicationDate: article?.publicationDate,
      publicationTime: article?.publicationTime,
    };
  }, [article]);

  const validationSchema = yup.lazy((values: PublishCmsArticleInput) =>
    yup.object().shape({
      ...(values.publicationDateType === PublicationDateType.PublishLater
        ? {
            publicationDate: yup.string().required(),
            publicationTime: yup.string().required(),
          }
        : {}),
    }),
  );

  const handleBack = () => openTab("sites");

  const handleSubmit = async (
    values: typeof initialValues,
    { setSubmitting }: FormikHelpers<PublishCmsArticleInput>,
  ) => {
    setSubmitting(true);
    try {
      const alreadyPublished = article?.publishedContent != null;

      let cleanPublicationTime = values.publicationTime;
      const pubSplit = cleanPublicationTime?.split(":");
      if (pubSplit != null && pubSplit.length === 2) {
        cleanPublicationTime = `${cleanPublicationTime}:00`;
      }

      const saved = await mutation({
        variables: {
          input: {
            ...values,
            publicationTime: cleanPublicationTime,
          },
        },
      });
      setArticle(saved.data?.publishCMSArticle);
      closeModal();

      if (alreadyPublished) {
        toast.positive(t("contentManagement.articles.modals.publishSettingsUpdateSuccess"));
      } else {
        toast.positive(
          saved.data?.publishCMSArticle.publicationDateType === PublicationDateType.PublishLater
            ? t("contentManagement.articles.modals.publishSettingsScheduleSuccess")
            : t("contentManagement.articles.modals.publishSettingsPublishSuccess"),
        );
      }
    } catch (e: unknown) {
      console.error(e);
    }
    setSubmitting(false);
  };

  return (
    <>
      <Modal.Header intro={t("contentManagement.articles.modals.publishSettingsIntro")} closeButton={true} />
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
        {({ isSubmitting, isValid, submitForm, values }) => (
          <>
            <Modal.Body>
              <Form>
                <EqForm.Group
                  label={t("contentManagement.articles.form.publishLabel")}
                  showTooltip={true}
                  tooltipText={t("contentManagement.articles.form.publishTooltip")}
                >
                  <div className="d-flex flex-row">
                    <Field
                      className="w-50 mr-2"
                      component={EqForm.HeroRadioSelect}
                      id={PublicationDateType.PublishNow}
                      name="publicationDateType"
                      icon={RiMailSendLine}
                      title={t("contentManagement.articles.form.publishNowTitle")}
                      description={t("contentManagement.articles.form.publishNowSubtext")}
                    ></Field>
                    <Field
                      className="w-50 ml-2"
                      component={EqForm.HeroRadioSelect}
                      id={PublicationDateType.PublishLater}
                      name="publicationDateType"
                      icon={RiCalendarLine}
                      title={t("contentManagement.articles.form.publishLaterTitle")}
                      description={t("contentManagement.articles.form.publishLaterSubtext")}
                    ></Field>
                  </div>
                  {values.publicationDateType === PublicationDateType.PublishLater && (
                    <div className="d-flex flex-row mt-4">
                      <EqForm.Group
                        required
                        label={t("contentManagement.articles.form.scheduleDate")}
                        showTooltip={true}
                        tooltipText={t("contentManagement.articles.form.scheduleDateTooltip")}
                      >
                        <Field name="publicationDate" type="date" max="9999-12-31" as={EqForm.Input} />
                      </EqForm.Group>
                      <EqForm.Group
                        className="ml-3"
                        required
                        label={t("contentManagement.articles.form.scheduleTime")}
                        showTooltip={true}
                        tooltipText={t("contentManagement.articles.form.scheduleTimeTooltip")}
                      >
                        <Field name="publicationTime" type="time" as={EqForm.Input} />
                      </EqForm.Group>
                    </div>
                  )}
                </EqForm.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={handleBack} variant="ghost">
                {t("common.back")}
              </Button>
              <Button
                className="ml-2"
                disabled={!isValid || isSubmitting}
                onClick={() => {
                  void submitForm();
                }}
              >
                {values.publicationDateType === PublicationDateType.PublishLater
                  ? t("contentManagement.articles.form.publishLaterTitle")
                  : t("contentManagement.articles.form.publishNowTitleShort")}
              </Button>
            </Modal.Footer>
          </>
        )}
      </Formik>
    </>
  );
};
