import React, { useCallback, useContext } from "react";

import { useTheme } from "../../../contexts/Theme";
import type { KeyboardEvent } from "react";
import { ModalContext } from "../ModalContext";

export interface Props {
  className?: string;
  containerClassName?: string;
  hideOnEsc?: boolean;
  hideOnClick?: boolean;
  expansion?: React.ReactNode;
  expansionBackground?: string;
}

export const SideModalInner = React.forwardRef<HTMLDivElement, React.PropsWithChildren<Props>>(
  (
    {
      children,
      className,
      containerClassName,
      hideOnEsc = true,
      hideOnClick = true,
      expansion,
      expansionBackground = "#fff",
    },
    ref,
  ) => {
    const { colors, breakpoints } = useTheme();
    const { title, onHide, size } = useContext(ModalContext);
    const { zIndexes } = useTheme();

    const onKeyDown = useCallback(
      (e: KeyboardEvent<HTMLDivElement>) => {
        if (e.key === "Escape") {
          e.stopPropagation();
          if (hideOnEsc) {
            onHide();
          }
        }
      },
      [hideOnEsc, onHide],
    );

    return (
      <div onClick={hideOnClick ? onHide : undefined} onKeyDown={onKeyDown} ref={ref} className={containerClassName}>
        {expansion != null && (
          <section
            role="dialog"
            aria-modal="true"
            aria-label={title}
            className="left"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {expansion}
          </section>
        )}
        <section
          role="dialog"
          aria-modal="true"
          aria-label={title}
          className={`right ${className}`}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          {children}
        </section>
        <style jsx>{`
          /* Hide Product Tour button for side modals */
          :global(.uf-visible) {
            display: none !important;
          }

          div {
            position: fixed;
            top: 0;
            left: 0;
            display: flex;
            justify-content: right;
            align-items: flex-start;
            width: 100%;
            height: 100%;
            overflow: none;
            z-index: ${zIndexes.modal};
            box-sizing: border-box;
          }

          div :global(*) {
            box-sizing: border-box;
          }

          .left {
            background: ${expansionBackground};
            flex: 2;
            height: 100%;
            overflow: auto;
          }

          .right {
            display: flex;
            pointer-events: all;
            flex-direction: column;
            justify-content: flex-end;
            background: #fff;
            border: 1px solid ${expansion != null ? "transparent" : colors.border};
            height: 100%;
            max-height: 100%;
            width: min(
              100%,
              ${{
                xs: "300px",
                sm: "360px",
                md: "560px",
                lg: "800px",
                xl: "1000px",
                auto: "auto",
              }[size]}
            );
            max-width: 100vw;
          }
          @media (max-width: ${breakpoints.md}px) {
            .right {
              min-width: 100%;
              width: 100%;
            }
          }
          @media (max-width: ${breakpoints.lg}px) {
            .left {
              position: absolute;
              left: 0;
              top: 0;
              z-index: 2;
              width: 100%;
            }
          }
        `}</style>
      </div>
    );
  },
);

SideModalInner.displayName = "SideModalInner";
