import { createContext } from "react";
import type { FlexTenantFragmentFragment } from "../generated/gateway-client";

export type SideModalTab = "addFlexTenant" | "editFlexTenant" | "viewFlexTenant" | "assignProfileToFlexTenant";

export interface SideModalContext {
  openTab: (tab: SideModalTab, flexTenant?: FlexTenantFragmentFragment) => void;
  closeModal: () => void;
  currentTab: SideModalTab;
  stepCount: number;
  setStepCount: (count: number) => void;
}

export const SideModalContext = createContext<SideModalContext>({
  openTab: () => ({}),
  closeModal: () => ({}),
  currentTab: "addFlexTenant",
  stepCount: 1,
  setStepCount: () => ({}),
});
