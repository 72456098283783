import React, { useEffect, useMemo } from "react";
import Head from "next/head";
import { useRouter } from "next/router";
import { stringNotEmpty, useBcUuidContext } from "@equiem/lib";
import { useTranslation, useApolloErrorTranslation } from "@equiem/localisation-eq1";

import { useGetArticleQuery } from "../../../generated/gateway-client";
import { mungeArticleDetail } from "../util/mungeArticleDetails";
import { ArticleMetrics } from "../metrics/ArticleMetrics";
import { ArticleContent } from "./ArticleContent";
import { ArticleLoading } from "./ArticleLoading";

export const ArticlePage: React.FC = () => {
  const { t } = useTranslation();
  const { tError } = useApolloErrorTranslation();

  const { query } = useRouter();
  const uuid = typeof query.uuid === "string" && stringNotEmpty(query.uuid) ? query.uuid : null;

  const { data, error, loading } = useGetArticleQuery({
    variables: { uuid: uuid ?? "" },
    skip: uuid == null,
  });
  const article = useMemo(() => (data != null ? mungeArticleDetail(data.article) : null), [data]);

  const { updateBcUuid } = useBcUuidContext();
  useEffect(() => {
    if (uuid != null && article != null) {
      updateBcUuid(uuid, article.title);
    }
  }, [uuid, article, updateBcUuid]);

  return (
    <>
      <Head>{article != null && <title>{article.title}</title>}</Head>
      <div className="container p-6">
        <div className="content">
          {loading ? (
            <ArticleLoading />
          ) : article != null ? (
            <ArticleMetrics articleUuid={article.uuid} articleTitle={article.title}>
              <ArticleContent article={article} />
            </ArticleMetrics>
          ) : (
            <div>{error != null ? tError(error) : t("homepage.articleNotFound")}</div>
          )}
        </div>
      </div>
      <style jsx>{`
        .container {
          background: white;
          min-height: calc(100vh - 65px);
        }
        .content {
          position: relative;
          max-width: 600px;
          margin-left: auto;
          margin-right: auto;
        }
      `}</style>
    </>
  );
};
