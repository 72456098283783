import React from "react";

import { useTranslation } from "@equiem/localisation-eq1";
import { Form } from "@equiem/react-admin-ui";

export const BuildingsSelect: React.FC<{
  name?: string;
  buildings: Array<{
    name: string;
    uuid: string;
  }>;
  value?: string[];
  noneLabel?: string;
  onChange: (event: {
    name: string;
    target: {
      name: string;
      value: string[];
    };
  }) => void;
}> = ({ name, buildings, value, onChange, noneLabel }) => {
  const { t } = useTranslation();

  if (buildings == null || buildings.length === 0) {
    return null;
  }

  return (
    <Form.MultiSelect
      enableSelectAll
      variant="wrap"
      name={name}
      className="buildings-select"
      options={buildings.map(({ name: buildingName, uuid }) => ({ label: buildingName, value: uuid }))}
      value={value?.filter((buildingUuid) => buildings.some(({ uuid }) => uuid === buildingUuid))}
      onChange={onChange}
      searchPlaceholder={t("requests.category.buildingsSearchPlaceholder")}
      placeholder={noneLabel ?? ""}
    />
  );
};
