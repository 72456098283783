import { useContext } from "react";

import { CurrentProfile, Site, stringIsEmpty } from "@equiem/lib";

import { useResetPasswordMutation } from "../../../generated/settings-client";

export function useUserResetPassword(email: string) {
  const userCtx = useContext(CurrentProfile);
  const siteCtx = useContext(Site);
  const [resetPasswordMutation, { loading }] = useResetPasswordMutation();

  const resetPassword = async () => {
    if (stringIsEmpty(email)) {
      return null;
    }
    return resetPasswordMutation({
      variables: {
        email,
        siteUuid: siteCtx.uuid,
        triggeredBy: userCtx.profile?.uuid ?? "",
      },
    });
  };
  return {
    resetPassword,
    loading,
  };
}
