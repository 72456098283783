import type { TFunction } from "@equiem/localisation-eq1";
import * as yup from "yup";

export const billingCustomerValidation = (t: TFunction) => ({
  email: yup
    .string()
    .email()
    .required(t("common.validation.required", { path: t("flex.members.form.contactEmail") })),
  contactName: yup
    .string()
    .required(t("common.validation.required", { path: t("flex.members.form.companyBusinessName") })),
  address: yup.object().shape({
    line1: yup.string().required(t("common.validation.required", { path: t("flex.members.form.address") })),
    line2: yup.string(),
    postalCode: yup.string().required(t("common.validation.required", { path: t("flex.members.form.postalCode") })),
    city: yup.string().required(t("common.validation.required", { path: t("flex.members.form.city") })),
    state: yup.string(),
    country: yup.string().required(t("common.validation.required", { path: t("flex.members.form.country") })),
  }),
});
