import React, { useEffect } from "react";

import { useShowError, useSiteContext } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import { Button, Form as EqForm, ProgressCircle, Text } from "@equiem/react-admin-ui";

import {
  useSyncUserToEvolutionMutation,
  useToggleAndSyncEvolutionUserMutation,
  useUpdateUserEvolutionIdMutation,
  useUserDetailsQuery,
} from "../../../../generated/settings-client";

interface Props {
  userUuid: string;
}

export const JLLEvolutionIntegration: React.FC<Props> = ({ userUuid }) => {
  const { uuid: siteUuid } = useSiteContext();
  const showError = useShowError();
  const { t } = useTranslation();
  const [accountId, setAccountId] = React.useState<string>();
  const [isActive, setIsActive] = React.useState<boolean>(false);
  const [toggleEvolution, { loading: userEvolutionLoading }] = useToggleAndSyncEvolutionUserMutation();
  const [syncEvolution, { loading: userEvolutionSyncing }] = useSyncUserToEvolutionMutation();
  const [setEvolutionId, { loading: userEvolutionIdLoading }] = useUpdateUserEvolutionIdMutation();
  const { data: userData, loading: userDetailsLoading } = useUserDetailsQuery({
    variables: {
      uuid: userUuid,
    },
  });

  useEffect(() => {
    if (userData?.user?.integrationsV2?.evolution?.accounts.length === 1) {
      setAccountId(
        userData.user.integrationsV2.evolution.accounts
          .map((a) => a.id)
          .pop()
          ?.toString(),
      );
    }
  }, [userData?.user?.integrationsV2?.evolution?.accounts]);

  useEffect(() => {
    setIsActive(userData?.user?.integrationsV2?.evolution?.allowed ?? false);
  }, [userData?.user?.integrationsV2?.evolution?.allowed]);

  const evolutionAccountId = userData?.user?.integrationsV2?.evolution?.accounts
    .map((a) => a.id)
    .pop()
    ?.toString();

  const isLoading = userEvolutionLoading || userEvolutionIdLoading || userEvolutionSyncing || userDetailsLoading;

  const handleAccountId = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAccountId(e.target.value);
  };

  const handleAccess = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!isLoading) {
      setIsActive(e.target.checked);

      void toggleEvolution({
        refetchQueries: ["UserDetails"],
        variables: {
          enabled: e.target.checked,
          userUuid,
          destinationUuid: siteUuid,
        },
      }).then((result) => {
        if (result.data?.toggleAndSyncEvolutionUser.success === false) {
          showError(result.data.toggleAndSyncEvolutionUser);
        }
      });
    }
  };

  const handleSaveId = () => {
    if (!isLoading && accountId != null) {
      void setEvolutionId({
        refetchQueries: ["UserDetails"],
        variables: {
          accountId: parseInt(accountId, 10),
          userUuid,
          destinationUuid: siteUuid,
        },
      }).then((result) => {
        if (result.data?.updateUserEvolutionId.success === false) {
          showError(result.data.updateUserEvolutionId);
        }
      });
    }
  };

  const handleSync = () => {
    if (!isLoading && accountId != null) {
      void syncEvolution({
        refetchQueries: ["UserDetails"],
        variables: {
          userUuid,
          destinationUuid: siteUuid,
        },
      }).then((result) => {
        if (result.data?.syncUserToEvolution.success === false) {
          showError(result.data.syncUserToEvolution);
        }
      });
    }
  };

  return (
    <>
      <div className="form">
        <EqForm.Checkbox
          label={t("settings.integrations.evolution.accessEvolution")}
          value={isActive}
          disabled={isLoading}
          onChange={handleAccess}
        />
        {userEvolutionLoading && (
          <div className="loading">
            <ProgressCircle size="xs" />
            <Text variant="text" size="small">
              {t("common.loading")}...
            </Text>
          </div>
        )}
        {isActive && !userEvolutionLoading && (
          <>
            <EqForm.Group label={t("settings.integrations.evolution.accountId")} className="mb-0" inline>
              <EqForm.Input
                placeholder={t("settings.integrations.evolution.enterAccountId")}
                type="text"
                value={accountId}
                onChange={handleAccountId}
                autoComplete="off"
                id="evolution-account-id"
              />
            </EqForm.Group>
            <div className="row">
              {(userData?.user?.integrationsV2?.evolution?.accounts.length ?? 0) > 1 && (
                <Text variant="text" size="small" className="my-0">
                  {t("settings.integrations.evolution.multiAccountError")}
                </Text>
              )}
              <Button
                size="sm"
                variant="secondary"
                disabled={isLoading || accountId === evolutionAccountId}
                onClick={handleSaveId}
              >
                {t("common.save")}
              </Button>
              <Button
                size="sm"
                variant="primary"
                disabled={isLoading || accountId !== evolutionAccountId}
                onClick={handleSync}
              >
                {t("common.sync")}
              </Button>
            </div>
          </>
        )}
      </div>
      <style jsx>{`
        .form {
          padding: 8px;
          display: flex;
          flex-direction: column;
          gap: 16px;
        }
        .row {
          display: flex;
          justify-content: flex-end;
          gap: 8px;
          align-items: center;
        }
        .loading {
          display: flex;
          align-items: center;
          align-self: center;
          gap: 8px;
        }
      `}</style>
    </>
  );
};
