import React from "react";

export type Resource = {
  id: string;
  type: string;
};

export interface RoleTuple {
  siteUuid: string | null;
  type: string;
  relation: string;
  resource: Resource;
}

export interface RolesContext {
  add: (_tuple: RoleTuple) => void;
  remove: (_tuple: RoleTuple) => void;
  save: (_userUuid: string) => Promise<void>;
  saving: boolean;
  unsavedChanges: boolean;
  clear: () => void;
  updates: {
    toAdd: RoleTuple[];
    toRemove: RoleTuple[];
  };
}

export const Roles = React.createContext<RolesContext>({
  add: () => undefined,
  remove: () => undefined,
  save: async () => Promise.resolve(),
  saving: false,
  unsavedChanges: false,
  clear: () => undefined,
  updates: {
    toAdd: [],
    toRemove: [],
  },
});
