import React, { useContext } from "react";

import type { ComponentProps } from "react";
import { FormGroupContext } from "../../contexts/FormGroupContext";
import { FormInputGroupContext } from "../Form/FormInputGroup/FormInputGroupContext";
import type { ThemeContext } from "../../contexts";
import { useTheme } from "../../contexts/Theme";

// eslint-disable-next-line @typescript-eslint/no-type-alias
export type ListGroupProps = ComponentProps<"ul"> & {
  maxHeight?: string;
  noBorder?: boolean;
  variant?: Extract<keyof ThemeContext["colors"], "light" | "white">;
};

export const ListGroup = React.forwardRef<HTMLUListElement, ListGroupProps>(
  ({ children, maxHeight, noBorder, variant = "white", ...props }, ref) => {
    const { colors, borderRadius } = useTheme();
    const { inGroup } = useContext(FormInputGroupContext);
    const { labelId, hintId } = useContext(FormGroupContext);
    const showBorder = noBorder !== true && !inGroup;

    return (
      <ul aria-labelledby={labelId} aria-describedby={hintId} {...props} ref={ref}>
        {children}
        <style jsx>{`
          ul {
            background: ${variant === "light" ? colors.light : colors.white};
            list-style-type: none;
            margin: 0;
            padding: 0;
            border-radius: ${borderRadius};
            border: ${!showBorder ? "none" : `1px solid ${colors.border}`};
            max-height: ${maxHeight != null ? maxHeight : "none"};
            overflow: ${maxHeight != null ? "auto" : "visible"};
            width: ${inGroup ? "100%" : "auto"};
          }
        `}</style>
      </ul>
    );
  },
);

ListGroup.displayName = "ListGroup";
