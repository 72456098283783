import React, { type FC, useContext } from "react";
import { Form, useTheme } from "@equiem/react-admin-ui";
import { stringNotEmpty } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import { type FieldProps, type FieldInputProps, Field, useFormikContext } from "formik";

import type { FormValues } from "../../../../../lib/formValidation";
import {
  ResourceEditAccessControlContext,
  type AccessGroup,
} from "../../../../../contexts/ResourceEditAccessControlContext";

interface SelectProps {
  field: FieldInputProps<string | undefined>;
  accessGroups: AccessGroup[];
}

const AccessGroupSelect: React.FC<SelectProps> = ({ field, accessGroups }) => {
  const { t } = useTranslation();

  return (
    <Form.Select
      {...field}
      value={stringNotEmpty(field.value) ? field.value : ""}
      disabled={accessGroups.length === 0}
      placeholder={t("common.pleaseSelect")}
    >
      <option value="">{t("common.pleaseSelect")}</option>
      {accessGroups
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((x) => (
          <option key={x.uuid} value={x.uuid}>
            {x.name}
          </option>
        ))}
    </Form.Select>
  );
};

export const ResourceCreateAndEditFormAccessControl: FC = () => {
  const fm = useFormikContext<FormValues>();
  const { accessGroups } = useContext(ResourceEditAccessControlContext);
  const { spacers } = useTheme();
  const { t } = useTranslation();

  return (
    <div className="access-control">
      <Form.Group
        key="barrierControlAccessUuid"
        label={t("bookings.resources.accessGroup")}
        showTooltip
        tooltipText={t("bookings.resources.accessGroupTooltip")}
      >
        <Field name="barrierControlAccessUuid">
          {({ field }: FieldProps) => <AccessGroupSelect field={field} accessGroups={accessGroups} />}
        </Field>
      </Form.Group>
      {fm.values.allowVisitorInvites === true && (
        <Form.Group
          key="barrierControlVisitorAccessUuid"
          label={t("bookings.resources.accessGroupVisitors")}
          showTooltip
          tooltipText={t("bookings.resources.accessGroupVisitorsTooltip")}
        >
          <Field name="barrierControlVisitorAccessUuid">
            {({ field }: FieldProps) => <AccessGroupSelect field={field} accessGroups={accessGroups} />}
          </Field>
        </Form.Group>
      )}
      <style jsx>{`
        .access-control {
          display: flex;
          gap: ${spacers.s5};
        }
        .access-control > :global(*) {
          max-width: 50%;
          flex-grow: 1;
        }
      `}</style>
    </div>
  );
};
