import { createContext } from "react";

export interface BreadcrumbsObject {
  href: string;
  text: string;
}

export interface BreadcrumbsContext {
  breadcrumbs: BreadcrumbsObject[];
  back: () => void;
}

export const Breadcrumbs = createContext<BreadcrumbsContext>({
  breadcrumbs: [],
  back: () => undefined,
});
