/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { useContext, useEffect, useMemo } from "react";
import { Field, Form } from "formik";

import {
  CurrentProfile,
  CurrentRole,
  Role,
  Site,
  stringIsEmpty,
  useSaferFormikContext,
  useShowError,
} from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import {
  Button,
  Form as EqForm,
  ProgressCircle,
  Tag,
  useIsMobileWidth,
  useTheme,
  useToast,
} from "@equiem/react-admin-ui";

import { VerifiedStatus } from "../../../components/VerifiedStatus";
import { useWidgetContext } from "../../../contexts/WidgetContext";
import type { ProfileDetailsQuery } from "../../../generated/settings-client";
import {
  ProfileRegistrationType,
  ProfileStatus,
  useApartmentListQuery,
  useCompaniesV2Query,
  useSendEmailVerificationMutation,
  useSiteAttributesQuery,
  useSiteGroupsQuery,
} from "../../../generated/settings-client";
import { useUserResetPassword } from "../hooks/useUserResetPassword";
import type { FormValues } from "../types";

import { UserDebugInfo } from "./UserDebugInfo";

interface Props {
  profile: ProfileDetailsQuery["profile"];
  adminView: boolean;
}

// eslint-disable-next-line complexity
export const EditProfile: React.FC<Props> = ({ profile, adminView }) => {
  const { resetPassword, loading: resetting } = useUserResetPassword(profile?.email ?? "");
  const { t } = useTranslation();
  const { colors, spacers } = useTheme();
  const site = useContext(Site);
  const { values, errors, touched, submitForm } = useSaferFormikContext<FormValues>();
  const { subscribe } = useWidgetContext();
  const toast = useToast();
  const showError = useShowError();
  const isMobile = useIsMobileWidth();
  const [sendEmailVerificationMutation, { loading: sendingVerification }] = useSendEmailVerificationMutation();
  const { data: companiesQueryData } = useCompaniesV2Query({
    variables: {
      first: 1000,
      destinationUuid: site.uuid,
    },
    skip: !adminView || profile == null,
  });
  const { data: attributesQueryData } = useSiteAttributesQuery({
    variables: {
      destinationUuid: site.uuid,
    },
    skip: profile == null,
  });

  const { data: apartmentsQueryData } = useApartmentListQuery({
    variables: {
      first: 1000,
      destinationUuid: site.uuid,
    },
    skip: !adminView || profile == null,
  });

  const { currentRole } = useContext(CurrentRole);
  const showCompany = currentRole === Role.PropertyManager && adminView;

  const { canManageRegion } = useContext(CurrentProfile);
  const showDebug = canManageRegion && adminView;

  useEffect(() => {
    return subscribe("onSave", submitForm);
  }, [submitForm, subscribe]);

  const colorVerified = useMemo(
    () => (profile?.emailVerified ?? false ? colors.status.positive.primary : colors.grayscale[60]),
    [colors.grayscale, colors.status.positive.primary, profile?.emailVerified],
  );
  const textVerified = useMemo(
    () =>
      profile?.emailVerified ?? false ? t("settings.editProfile.verified") : t("settings.editProfile.notVerified"),
    [profile, t],
  );

  const pendingApproval = profile?.status === ProfileStatus.PendingApproval;

  const getFieldError = (field: keyof typeof values) =>
    touched[field] === true || Array.isArray(touched[field]) ? errors[field]?.toString() : undefined;

  const Status = (status: ProfileStatus) => {
    switch (status) {
      case ProfileStatus.Active:
        return t("settings.profileStatus.active");
      case ProfileStatus.Deactivated:
        return t("settings.profileStatus.deactivated");
      case ProfileStatus.PendingApproval:
        return t("settings.profileStatus.pendingApproval");
      default:
        return "";
    }
  };

  const { data: groupsQueryData } = useSiteGroupsQuery({
    variables: {
      destinationUuid: site.uuid,
    },
    skip: profile == null,
  });

  const resendVerificationEmail = async () => {
    try {
      const emailAddress = profile?.email ?? "";
      if (stringIsEmpty(emailAddress)) {
        return;
      }

      await sendEmailVerificationMutation({
        variables: {
          email: emailAddress,
          siteUuid: site.uuid,
        },
      });
      toast.neutral(
        adminView
          ? t("settings.editProfile.verificationEmailWasResent", {
              firstName: profile?.firstName ?? "",
              lastName: profile?.lastName ?? "",
            })
          : t("settings.editProfile.verificationEmailWasResentEndUser", { email: emailAddress }),
      );
    } catch (e: unknown) {
      showError(e);
    }
  };

  const sendResetPasswordEmail = async () => {
    try {
      const emailAddress = profile?.email ?? "";
      if (stringIsEmpty(emailAddress)) {
        return;
      }
      await resetPassword();
      toast.neutral(
        adminView
          ? t("settings.editProfile.passwordResetEmailSent", {
              firstName: profile?.firstName ?? "",
              lastName: profile?.lastName ?? "",
            })
          : t("settings.editProfile.passwordResetEmailSentEndUser", { email: emailAddress }),
      );
    } catch (e: unknown) {
      showError(e);
    }
  };

  const ResendButton = () => (
    <>
      {sendingVerification ? (
        <span className="resend-email loading pt-2">
          <ProgressCircle size={12} /> {t("settings.editProfile.sending")}
        </span>
      ) : (
        <span onClick={resendVerificationEmail} className="resend-email pt-2">
          {isMobile ? t("settings.editProfile.resendEmail") : t("settings.editProfile.resendVerificationEmail")}
        </span>
      )}
      <style jsx>
        {`
          .resend-email {
            font-weight: 700;
            font-size: 12px;
            line-height: 16px;
            letter-spacing: 0.02em;
            text-transform: uppercase;
            cursor: pointer;
            color: ${colors.grayscale[60]};
            padding: ${spacers.s2};
          }
          .resend-email:hover {
            border-radius: 4px;
            background: ${colors.transparent.black[5]};
          }
          .loading {
            color: ${colors.grayscale[40]};
          }
        `}
      </style>
    </>
  );

  return (
    <div style={{ background: colors.white }} className="mb-4">
      <div className="main-container-inner">
        <div className="">
          <Form>
            <EqForm.Group inline label={t("common.firstName")} error={getFieldError("firstName")}>
              <Field as={EqForm.Input} name="firstName" />
            </EqForm.Group>
            <EqForm.Group inline label={t("common.lastName")} error={getFieldError("lastName")}>
              <Field as={EqForm.Input} name="lastName" />
            </EqForm.Group>
            {adminView && (
              <EqForm.Group inline label={t("common.status")} error={getFieldError("status")}>
                <Field
                  as={EqForm.DynamicSelect}
                  name="status"
                  mobileView="minimal"
                  options={
                    pendingApproval
                      ? [
                          {
                            value: ProfileStatus.PendingApproval,
                            label: <Tag>{Status(ProfileStatus.PendingApproval)}</Tag>,
                          },
                          {
                            value: ProfileStatus.Active,
                            label: <Tag variant="positive">{Status(ProfileStatus.Active)}</Tag>,
                          },
                          {
                            value: ProfileStatus.Deactivated,
                            label: <Tag variant="negative">{Status(ProfileStatus.Deactivated)}</Tag>,
                          },
                        ]
                      : [
                          {
                            value: ProfileStatus.Active,
                            label: <Tag variant="positive">{Status(ProfileStatus.Active)}</Tag>,
                          },
                          {
                            value: ProfileStatus.Deactivated,
                            label: <Tag variant="negative">{Status(ProfileStatus.Deactivated)}</Tag>,
                          },
                        ]
                  }
                />
              </EqForm.Group>
            )}
            <EqForm.Group inline label={t("common.emailAddress")} error={getFieldError("email")}>
              <Field as={EqForm.Input} name="email" />
            </EqForm.Group>
            <div className="d-flex email-status-container">
              <div className="spacer"></div>
              <div className="d-flex flex-row email-status">
                <span className="verified-label">
                  <VerifiedStatus verified={profile?.emailVerified} color={colorVerified} />
                  <span className="verified-text ml-2" style={{ color: colorVerified }}>
                    {textVerified}
                  </span>
                </span>
                {!(profile?.emailVerified ?? false) && <ResendButton />}
              </div>
            </div>
            <EqForm.Group
              className="pt-6"
              inline
              label={t("common.mobileNumber")}
              showTooltip={!adminView}
              tooltipText={adminView ? null : t("settings.editProfile.mobileNumberToolTipEndUser")}
              error={getFieldError("mobileNumber")}
            >
              <Field as={EqForm.PhoneNumber} name="mobileNumber" siteTimezone={site.timezone} />
            </EqForm.Group>
            <div className="d-flex align-items-baseline">
              <div className="password-label">
                <EqForm.Label>{t("settings.editProfile.password")}</EqForm.Label>
              </div>
              <div className="reset-container">
                <Button onClick={sendResetPasswordEmail} variant="outline">
                  {resetting ? (
                    <span>
                      <ProgressCircle size={12} /> {t("settings.editProfile.resetting")}
                    </span>
                  ) : (
                    t("settings.editProfile.resetPassword")
                  )}
                </Button>
              </div>
            </div>
            <hr className="mt-6" />
            {showCompany && (
              <EqForm.Group inline label={t("settings.editProfile.userType")} error={getFieldError("userType")}>
                <Field
                  as={EqForm.DynamicSelect}
                  name="userType"
                  mobileView="minimal"
                  options={Object.entries(ProfileRegistrationType).map((type) => {
                    return {
                      value: type[1],
                      label: type[0],
                    };
                  })}
                />
              </EqForm.Group>
            )}
            {showCompany && values.userType === ProfileRegistrationType.Residential && (
              <EqForm.Group inline label={t("settings.editProfile.apartment")} error={getFieldError("apartment")}>
                <Field
                  as={EqForm.DynamicSelect}
                  name="apartment"
                  searchPlaceholder={t("settings.editProfile.searchApartments")}
                  search
                  options={apartmentsQueryData?.apartments.edges.map((apartment) => {
                    return {
                      value: apartment.node?.uuid,
                      label: apartment.node?.name,
                    };
                  })}
                />
              </EqForm.Group>
            )}
            {showCompany && (
              <EqForm.Group inline label={t("common.company")} error={getFieldError("company")}>
                <Field
                  as={EqForm.DynamicSelect}
                  name="company"
                  search
                  searchPlaceholder={t("settings.editProfile.searchCompanies")}
                  options={
                    companiesQueryData?.companiesV2.edges.map((company) => {
                      return { label: company.node?.name, value: company.node?.uuid };
                    }) ?? []
                  }
                ></Field>
              </EqForm.Group>
            )}
            {adminView && (
              <EqForm.Group
                inline
                label={t("common.groups")}
                error={getFieldError("groups")}
                showTooltip
                tooltipText={t("settings.editProfile.roleToolTip")}
              >
                <Field
                  name="groups"
                  as={EqForm.MultiSelect}
                  variant="wrap"
                  placeholder={t("settings.editProfile.selectGroups")}
                  isMulti={true}
                  options={(groupsQueryData?.siteGroups ?? []).map((group) => {
                    return { label: group.name, value: group.uuid };
                  })}
                />
              </EqForm.Group>
            )}
            <EqForm.Group
              inline
              label={t("settings.editProfile.interests")}
              error={getFieldError("attributes")}
              showTooltip
              tooltipText={
                adminView
                  ? t("settings.editProfile.interestsToolTip")
                  : t("settings.editProfile.interestsToolTipEndUser")
              }
            >
              <Field
                name="attributes"
                as={EqForm.MultiSelect}
                variant="wrap"
                placeholder={adminView ? null : t("common.pleaseSelect")}
                disabled={adminView}
                isMulti={true}
                options={(attributesQueryData?.siteAttributes ?? []).map((attribute) => {
                  return { label: attribute.name, value: attribute.uuid };
                })}
              />
            </EqForm.Group>
          </Form>
          {showDebug && profile?.uuid != null ? <UserDebugInfo profile={profile} /> : null}
        </div>
      </div>
      <style jsx>
        {`
          .main-container-inner {
            padding-right: 0px !important;
          }
          .verified-text {
            font-size: 12px;
            line-height: 16px;
          }
          .email-status {
            place-content: space-between;
            width: 100%;
          }
          .spacer {
            width: 40%;
          }
          .email-status-container {
            margin-top: -18px;
          }
          .password-label {
            width: 40%;
          }
          .reset-container {
            width: 100%;
          }
          hr {
            border: none;
            border-top: 1px solid ${colors.border};
            margin-bottom: 16px;
          }
        `}
      </style>
    </div>
  );
};
