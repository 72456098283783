import type { ComponentProps, ReactElement, UIEvent } from "react";
import React, { useCallback, useState, useMemo } from "react";
import { Card } from "../Card";
import type { IconType } from "react-icons";
import { NoResults } from "./NoResults";
import { useTheme } from "../../contexts/Theme";
import { WidgetLoadingContent } from "./WidgetLoadingContent";
import { StickyContainerWithRef } from "../Sticky/StickyContainer";
import { StickyElement } from "../Sticky/StickyElement";
import { Tooltip } from "../Tooltip/Tooltip";
import { RiArrowUpLine, RiInformationLine } from "../../admin-icons";
import { AdminButton } from "../Button/AdminButton";
import { useForwardedRef } from "../../util/useForwardedRef";

export interface Props extends ComponentProps<"div"> {
  title?: string;
  icon?: IconType;
  dropdownMenu?: ReactElement;
  button?: ReactElement;
  isLoading?: boolean;
  tooltip?: string | null;
  buttonText: string;
  disableOverflow?: boolean;
  noResults?: {
    hasNoResults: boolean;
    text: string;
  };
}

const headerHeight = 32;

export const Widget = React.forwardRef<HTMLDivElement, Props>(
  ({ children, title, isLoading, noResults, buttonText = "Back To Top", disableOverflow, ...props }, ref) => {
    const { colors, spacers, zIndexes } = useTheme();
    const [stickyHeader, setStickyHeader] = useState(false);
    const innerRef = useForwardedRef(ref);

    const handleScroll = useCallback((e: UIEvent<HTMLDivElement>) => {
      setStickyHeader(e.currentTarget.scrollTop > headerHeight);
    }, []);

    // eslint-disable-next-line @typescript-eslint/promise-function-async
    const content = useMemo(() => {
      if (isLoading === true) {
        return (
          <div className="loading">
            <WidgetLoadingContent height={80} />
            <WidgetLoadingContent height={80} />
            <WidgetLoadingContent height={80} />
            <WidgetLoadingContent height={80} />
            <style jsx>{`
              .loading {
                padding: ${spacers.s5};
                display: flex;
                flex-direction: column;
                gap: 8px;
              }
            `}</style>
          </div>
        );
      }

      if (noResults?.hasNoResults === true && props.icon != null) {
        return <NoResults text={noResults.text} icon={props.icon} />;
      }

      return children;
    }, [isLoading, children, noResults, props.icon, spacers]);

    return (
      <StickyContainerWithRef ref={innerRef}>
        <Card
          {...props}
          ref={innerRef}
          style={{
            height: "100%",
            overflowY: isLoading === true || disableOverflow === true ? "hidden" : "auto",
            boxShadow: `${spacers.s0} ${spacers.s2} ${spacers.s3} ${spacers.s0} rgba(0, 0, 0, 0.05)`,
            position: "relative",
          }}
          onScroll={handleScroll}
        >
          <StickyElement<HTMLDivElement>>
            {({ ref: headerRef }) => (
              <div
                ref={headerRef}
                className="stickyHeader"
                style={{
                  zIndex: zIndexes.dropdown + 1,
                  borderBottom: stickyHeader ? `solid 1px ${colors.border}` : "none",
                }}
              >
                <div className="stickyInner sticky-title">
                  {isLoading === true ? (
                    <WidgetLoadingContent height={20} width={20} disableAnimation />
                  ) : (
                    props.icon != null && (
                      <props.icon
                        size={20}
                        className="mr-3 sticky-title-icon"
                        style={{ color: colors.grayscale[60] }}
                      />
                    )
                  )}
                  <h2 style={{ opacity: stickyHeader ? 1 : 0 }} className="transition">
                    {title}
                  </h2>
                </div>
                <div className="stickyInner">
                  <span
                    style={{
                      opacity: stickyHeader ? 1 : 0,
                      pointerEvents: stickyHeader ? "auto" : "none",
                    }}
                    className="transition"
                  >
                    {props.button}
                  </span>
                  {props.tooltip != null && (
                    <Tooltip title={props.tooltip} placement="top">
                      <div
                        style={{
                          display: stickyHeader ? "none" : "inline-block",
                        }}
                      >
                        <RiInformationLine
                          style={{
                            color: colors.medium,
                            cursor: "pointer",
                            display: "block",
                          }}
                        />
                      </div>
                    </Tooltip>
                  )}
                  {props.dropdownMenu != null && <span className="ml-3 dropdown">{props.dropdownMenu}</span>}
                </div>
              </div>
            )}
          </StickyElement>
          <div className="header">
            {isLoading === true ? (
              <WidgetLoadingContent height={24} width={160} />
            ) : (
              <>
                <h1>{title}</h1>
                <div className="button-container">{props.button}</div>
              </>
            )}
          </div>
          {content}
          <div
            className="mt-3 p-3 d-flex align-items-center justify-content-center"
            style={{ position: "sticky", bottom: 10, pointerEvents: "none" }}
          >
            {stickyHeader && (
              <AdminButton
                variant="primary"
                size="md"
                style={{ pointerEvents: "all" }}
                onClick={() => {
                  innerRef.current?.scrollTo({ top: 0, behavior: "smooth" });
                }}
              >
                <RiArrowUpLine size={16} />
                {buttonText}
              </AdminButton>
            )}
          </div>
        </Card>
        <style jsx>{`
          .stickyHeader {
            position: sticky;
            top: 0;
            z-index: 1;
            background: ${colors.white};
            padding: ${spacers.s4} ${spacers.s5} ${spacers.s4} ${spacers.s4};
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 48px;
            gap: 16px;
          }
          .stickyHeader h2 {
            text-transform: uppercase;
            font-size: 12px;
            font-weight: 500;
            line-height: 24px;
            padding: 0;
            margin: 0;
            color: ${colors.transparent.black[60]};
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .transition {
            transition: opacity 100ms;
          }
          .header {
            display: flex;
            align-items: flex-end;
            justify-content: space-between;
            column-gap: 16px;
            background: ${colors.white};
            border-bottom: solid 1px ${colors.grayscale[10]};
            padding: ${spacers.s0} ${spacers.s5} ${spacers.s3};
          }
          .header h1 {
            margin: 0;
            font-weight: 700;
            font-size: 20px;
            line-height: 24px;
            padding-bottom: ${spacers.s2};
            color: ${colors.dark};
          }
          .stickyInner {
            display: flex;
            align-items: center;
            justify-content: space-between;
          }
          .sticky-title {
            overflow: hidden;
            text-overflow: ellipsis;
          }
          :global(.sticky-title-icon) {
            min-width: max-content;
          }
          .button-container {
            min-width: max-content;
          }
          .dropdown {
            margin-right: -4px;
          }
        `}</style>
      </StickyContainerWithRef>
    );
  },
);

Widget.displayName = "Widget";
