import { Literal, Null, Record, String } from "runtypes";

import { ReqMgtStatusType } from "../../../generated/requests-client";

// const status = Record({ name: String, type: ReqMgtStatusType });
// no enum support in runtypes :( https://github.com/pelotom/runtypes/issues/66

// space.name;
// space.buildingLevel.name;
// space.buildingLevel.building.name;
const space = Record({
  name: String,
  buildingLevel: Record({
    name: String,
    building: Record({
      name: String,
    }),
  }),
});

const profile = Record({
  uuid: String,
  firstName: String,
  lastName: String,
  avatar: String.nullable(),
});

export const category = Record({
  change: Record({
    field: Literal("category"),
    from: Record({ category: Record({ name: String }) }),
    to: Record({ category: Record({ name: String }) }),
  }),
});

export const subCategoryUpdated = Record({
  change: Record({
    field: Literal("subcategory"),
    from: Record({ subCategory: Record({ name: String }) }),
    to: Record({ subCategory: Record({ name: String }) }),
  }),
});

export const subCategoryAdded = Record({
  change: Record({
    field: Literal("subcategory"),
    from: Null,
    to: Record({ subCategory: Record({ name: String }) }),
  }),
});

export const subCategoryRemoved = Record({
  change: Record({
    field: Literal("subcategory"),
    from: Record({ subCategory: Record({ name: String }) }),
    to: Null,
  }),
});

export const requestOpened = Record({
  change: Record({
    field: Literal("reporter"),
    from: Null,
    to: Record({ profile }),
  }),
});

export const requestClosed = Record({
  change: Record({
    field: Literal("status"),
    from: Record({
      status: Record({
        name: String,
        type: String,
      }),
    }),
    to: Record({
      status: Record({
        name: String,
        type: Literal(ReqMgtStatusType.Completed),
      }),
    }),
  }),
});

export const statusUpdated = Record({
  change: Record({
    field: Literal("status"),
    from: Record({
      status: Record({
        name: String,
        type: String,
      }),
    }),
    to: Record({
      status: Record({
        name: String,
        type: String,
      }),
    }),
  }),
});

export const locationUpdated = Record({
  change: Record({
    field: Literal("location"),
    from: Record({ space }),
    to: Record({ space }),
  }),
});
