import { dollarToCents, stringNotEmpty } from "@equiem/lib";
import type { BookableResourceAddOnFragmentFragment } from "../../../../../generated/gateway-client";
import { BookableResourceAddOnType } from "../../../../../generated/gateway-client";
import { convertInputMoneyToNumber } from "../../../../../lib/convertInputMoneyToNumber";
import { convertInputNumberToNumber } from "../../../../../lib/convertNumberStringToNumber";
import type { FormValues } from "../../../../../lib/formValidation";

const processOptions = (addOn: BookableResourceAddOnFragmentFragment) => {
  // Cleanup on the free text mode.
  if (addOn.type === BookableResourceAddOnType.FreeText) {
    return [];
  }

  const options = (addOn.options ?? []).map((option) => ({
    uuid: stringNotEmpty(option.uuid) ? option.uuid : undefined,
    name: option.name,
    unitPrice: dollarToCents(convertInputMoneyToNumber(option.unitPrice)) ?? 0,
    hasQuantity: option.hasQuantity,
    maxQuantity: convertInputNumberToNumber(option.maxQuantity),
  }));

  // Make sure the single option only have 1 value.
  if (addOn.type === BookableResourceAddOnType.SingleOption) {
    return [options[0]];
  }

  return options;
};

export const addOnToInput = (addOns?: FormValues["addOns"]) => {
  if (addOns == null || addOns.length === 0) {
    return [];
  }

  return addOns.map((addOn) => ({
    uuid: stringNotEmpty(addOn.uuid) ? addOn.uuid : undefined,
    mandatory: addOn.mandatory,
    name: addOn.name ?? "",
    tooltipTxt: addOn.tooltipTxt,
    type: addOn.type,
    options: processOptions(addOn),
  }));
};
