import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { Dropdown } from "../Dropdown";
import { DropdownMenu } from "../Dropdown/DropdownMenu/DropdownMenu";
import { FilterChip } from "./FilterChip";
import { ComplexFilterContext } from "./ComplexFilterContext";
import { Text } from "../Text/Text";
import * as Form from "../Form";
import { useTheme } from "../../contexts/Theme";
import { DateTime } from "luxon";
import type {
  FilterItemDate,
  FilterItemProps,
  FilterValueDate,
  FilterValueDateBetween,
  HTMLDateInputElement,
} from "./ComplexFilter.types";
import { FilterDateModifier, FilterType } from "./ComplexFilter.types";
import { formatters, useTranslation } from "@equiem/localisation-eq1";
import { ModifierSelect } from "./ModifierSelect";

export const defaultModifier = FilterDateModifier.is;

export const FilterDate: React.FC<FilterItemProps<FilterItemDate>> = ({ title, name, modifiers, min, max, icon }) => {
  const { t } = useTranslation();
  const endDateRef = useRef<HTMLDateInputElement>(null);
  const { colors } = useTheme();
  const { values, setValue, removeValue, language } = useContext(ComplexFilterContext);
  const [selectedModifier, setSelectedModifier] = useState<FilterDateModifier>(
    (values[name]?.modifier != null ? values[name]?.modifier : modifiers?.[0] ?? defaultModifier) as FilterDateModifier,
  );
  const [prevSelectedModifier, setPrevSelectedModifier] = useState(selectedModifier);

  const isRange = useMemo(() => selectedModifier === FilterDateModifier.between, [selectedModifier]);

  useEffect(() => {
    if (selectedModifier === prevSelectedModifier) {
      return;
    }
    setPrevSelectedModifier(selectedModifier);
    setValue(name, {
      type: FilterType.date,
      modifier: selectedModifier,
      value: undefined,
    });
  }, [selectedModifier, prevSelectedModifier]);

  const handleStartChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (isRange) {
      setValue(name, {
        type: FilterType.date,
        modifier: FilterDateModifier.between,
        value: [e.target.value, values[name]?.value?.[1] ?? e.target.value],
      } as FilterValueDateBetween);
    } else {
      setValue(name, {
        type: FilterType.date,
        modifier: selectedModifier,
        value: e.target.value,
      } as FilterValueDate);
    }
  };

  const handleEndChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (isRange) {
      setValue(name, {
        type: FilterType.date,
        modifier: FilterDateModifier.between,
        value: [values[name]?.value?.[0] ?? e.target.value, e.target.value],
      } as FilterValueDateBetween);
    }
  };

  const labelValue = useMemo(() => {
    const getDate = (iso: string | undefined) =>
      iso != null ? formatters.dateshort(DateTime.fromISO(iso), language) : "";

    const value = values[name];

    if (value?.modifier === FilterDateModifier.between && value.value != null) {
      return `${getDate(value.value[0])} - ${getDate(value.value[1])}`;
    }

    if (value?.modifier === FilterDateModifier.before && value.value != null) {
      return `${t("common.before")} ${getDate(value.value)}`;
    }

    if (value?.modifier === FilterDateModifier.after && value.value != null) {
      return `${t("common.after")} ${getDate(value.value)}`;
    }

    if (value?.modifier === FilterDateModifier.is) {
      return getDate(value.value);
    }

    return undefined;
  }, [values, name]);

  const getLocalizedTitle = (modifier: FilterDateModifier) => {
    switch (modifier) {
      case FilterDateModifier.is:
        return t("common.is");
      case FilterDateModifier.between:
        return t("common.between");
      case FilterDateModifier.before:
        return t("common.before");
      case FilterDateModifier.after:
        return t("common.after");
      default:
        return "";
    }
  };

  const value = values[name]?.value as string | string[] | undefined;

  return (
    <>
      <Dropdown placement="bottom-start" flip>
        <FilterChip
          title={title}
          value={labelValue}
          type={FilterType.date}
          name={name}
          onClose={() => removeValue(name)}
          icon={icon}
          size="lg"
        />
        <DropdownMenu width={isRange ? 390 : 252} mobileView="regular">
          {modifiers != null && (
            <div className="calendar-top">
              <Text variant="label" className="m-0">
                {title}
              </Text>
              <ModifierSelect
                options={modifiers.map((m) => ({
                  value: m,
                  label: getLocalizedTitle(m),
                }))}
                selected={selectedModifier}
                onChange={(v) => setSelectedModifier(v as FilterDateModifier)}
              />
            </div>
          )}
          {!isRange ? (
            <div className="date-input">
              <Form.Input type="date" value={value ?? ""} onChange={handleStartChange} min={min} max={max} />
            </div>
          ) : (
            <div className="range-input">
              <Form.Input
                type="date"
                value={value?.[0] ?? ""}
                onChange={handleStartChange}
                min={min}
                max={value?.[1] ?? max}
              />
              <Form.Input
                ref={endDateRef}
                type="date"
                value={value?.[1] ?? ""}
                onChange={handleEndChange}
                min={value?.[0] ?? min}
                max={max}
              />
            </div>
          )}
        </DropdownMenu>
      </Dropdown>
      <style jsx>{`
        .options-list {
          display: flex;
          flex-direction: column;
          gap: 1rem;
          padding: 1rem;
        }

        .calendar-top {
          padding: 0 1rem 0.25rem;
          display: flex;
          flex-direction: row;
          align-items: center;
          color: ${colors.grayscale[100]};
        }

        .date-input {
          display: flex;
          padding: 0 0.5rem;
        }

        .range-input {
          padding: 0 0.5rem;
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-gap: 0.5rem;
        }
      `}</style>
    </>
  );
};
