import React, { useEffect, useState, useCallback } from "react";
import { useFormikContext } from "formik";
import { useApolloErrorTranslation, useTranslation } from "@equiem/localisation-eq1";
import type { CardFragmentFragment } from "../../../../generated/gateway-client";
import { useUserCreditCardsQuery } from "../../../../generated/gateway-client";
import { Button, Form, Skeleton } from "@equiem/react-admin-ui";
import type { BookingFormValue } from "../../models/BookingFormValue";
import { CardDisplay, PaymentCardSelector } from "@equiem/lib";

type Card = CardFragmentFragment | undefined;

interface LP {
  paymentGatewayCustomerId?: string | null;
  cards: CardFragmentFragment[];
}
const Local: React.FC<LP> = ({ paymentGatewayCustomerId, cards }) => {
  const { t } = useTranslation();
  const fm = useFormikContext<BookingFormValue>();
  const [showSelector, setShowSelector] = useState(false);
  const toggleSelector = () => setShowSelector((v) => !v);

  const cardId = fm.values.creditcard?.paymentGatewayPaymentMethodId;
  const selectedCard = cardId != null ? cards.find((card) => card.paymentGatewayCardId === cardId) : undefined;

  const selectCard = useCallback(
    (card: Card) => {
      if (card == null) {
        void fm.setFieldValue("creditcard", undefined);
        return;
      }

      void fm.setFieldValue("creditcard", {
        paymentGatewayCustomerId,
        paymentGatewayPaymentMethodId: card.paymentGatewayCardId,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [paymentGatewayCustomerId],
  );

  // When user does not have any credit card, we select it after its added.
  useEffect(() => {
    if (cardId == null) {
      const defaultCard = cards.find((card) => card.default);
      selectCard(defaultCard);
    }
  }, [cardId, cards, selectCard]);

  return (
    <div className="booking-cc">
      {showSelector ? (
        <Form.Group label={t("bookings.operations.savedCardDetails")}>
          <PaymentCardSelector
            cards={cards}
            selectedCard={selectedCard}
            onHide={() => setShowSelector((x) => !x)}
            // eslint-disable-next-line @typescript-eslint/require-await
            onSave={async (card) => {
              selectCard(card as Card);
            }}
          />
        </Form.Group>
      ) : (
        <>
          {selectedCard != null ? (
            <Form.Group label={t("bookings.operations.savedCardDetails")}>
              <CardDisplay
                card={selectedCard}
                suffix={
                  <Button variant="outline" onClick={toggleSelector}>
                    {t("common.change")}
                  </Button>
                }
              />
            </Form.Group>
          ) : (
            <Button type="button" variant="outline" onClick={toggleSelector}>
              {t("equiem-one-ui.creditcard.addNewCard")}
            </Button>
          )}
        </>
      )}
    </div>
  );
};

export const BookingPaymentCreditCard: React.FC = () => {
  const { data, loading, error } = useUserCreditCardsQuery();
  const { tError } = useApolloErrorTranslation();
  const { t } = useTranslation();

  if (error != null) {
    return <div>{tError(error)}</div>;
  }
  if (loading) {
    return (
      <>
        <Skeleton.Line width="100px" height="20px" className="mb-2 d-block" />
        <Skeleton.Line width="100%" height="50px" />
      </>
    );
  }

  const profile = data?.profile;
  if (profile == null) {
    return <div>{t("equiem-one-ui.creditcard.cardInfoNotFound")}</div>;
  }

  return <Local cards={profile.cards} paymentGatewayCustomerId={profile.paymentGatewayCustomerId} />;
};
