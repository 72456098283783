import React from "react";
import { BaseTimeRange } from "./BaseTimeRange";
import { TimeRangeCaption } from "./TimeRangeCaption";

export const OwnTimeRange: React.FC<{
  start: number;
  end: number;
  timezone: string;
}> = ({ start, end, timezone }) => {
  return (
    <BaseTimeRange start={start} end={end} timezone={timezone} className="inactive-time-slot own-time-slot">
      <TimeRangeCaption startTime={start} endTime={end} timezone={timezone} totalColumns={1} />
    </BaseTimeRange>
  );
};
