import { Address } from "address-rfc2821";

export const validEmail = (email?: string | null) => {
  try {
    if (email == null || (email.startsWith("<") && email.endsWith(">"))) {
      return false;
    }
    const parsed = new Address(email);
    if (parsed.host.length < 1) {
      return false;
    }
    return true;
  } catch (_: unknown) {
    return false;
  }
};
