import React, { useState } from "react";

import { useTranslation } from "@equiem/localisation-eq1";
import { Modal, Skeleton, Text, useIsMobileWidth, useTheme } from "@equiem/react-admin-ui";
import { RiChatHistoryLine } from "@equiem/react-admin-ui/icons";

import type { RequestQuery } from "../../../generated/requests-client";

import { Activities } from "./Activities";

interface P {
  request?: RequestQuery["reqMgt"]["request"] | null;
  loading: boolean;
}
export const RequestActivity: React.FC<P> = ({ request, loading }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [showActivitiesModal, setShowActivitiesModal] = useState(false);
  const isMobile = useIsMobileWidth();

  return (
    <div className="container">
      <div className="p-6 header" onClick={() => setShowActivitiesModal(true)} role="button">
        {isMobile && <RiChatHistoryLine size={16} className="mr-2" color={theme.colors.grayscale["60"]} />}
        <Text variant="label" color={theme.colors.grayscale[isMobile ? "60" : "40"]}>
          {isMobile ? t("requests.details.viewActivities") : t("common.activity")}
        </Text>
      </div>

      {loading ? (
        <div className="p-6">
          <Skeleton.Line height="25px" width="100%" className="mb-2" />
          <Skeleton.Line height="25px" width="100%" className="mb-2" />
          <Skeleton.Line height="25px" width="100%" />
        </div>
      ) : (
        <>
          {request != null ? (
            <div className="activities">
              <Activities request={request} />
            </div>
          ) : null}
        </>
      )}

      <Modal.Dialog
        show={isMobile && showActivitiesModal}
        sideModal
        title={t("common.activity")}
        hideOnEsc={true}
        hideOnClick={true}
        onHide={() => setShowActivitiesModal(false)}
        size="md"
      >
        <Modal.Header closeButton noBorder={false} onClose={() => setShowActivitiesModal(false)} supportsMobile />
        {request != null ? <Activities request={request} /> : null}
      </Modal.Dialog>
      <style jsx>{`
        .container {
          height: calc(100vh - 242px);
        }
        .header {
          display: flex;
          align-items: center;
          border-bottom: 1px solid ${theme.colors.border};
        }

        .activities {
          height: 100%;
        }

        @media (max-width: ${theme.breakpoints.lg}px) {
          .container {
            height: 100%;
          }

          .activities {
            display: none;
          }
        }
      `}</style>
    </div>
  );
};
