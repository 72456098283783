import { useTranslation } from "@equiem/localisation-eq1";
import { Text, useToast } from "@equiem/react-admin-ui";
import React, { useEffect } from "react";
import type { BookableResourceEditQuery } from "../../generated/gateway-client";

interface P {
  resource: BookableResourceEditQuery["bookableResource"];
}
export const ResourceActiveBookingWarning: React.FC<P> = ({ resource }) => {
  const toast = useToast();
  const { t } = useTranslation();

  useEffect(() => {
    if (resource.totalActiveBookings > 0) {
      toast.positive(
        <Text variant="text" size="small" className="resource-active-booking-warning">
          <b>{t("common.note")}:</b> {t("bookings.resources.activeWarning", { count: resource.totalActiveBookings })}
        </Text>,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resource.totalActiveBookings, t]);

  return null;
};
