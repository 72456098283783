import type { ApolloClientOptions } from "@apollo/client";
import { ApolloClient, HttpLink, InMemoryCache } from "@apollo/client";
import { authenticate, dataIdFromObject, errorLink, headers, withAuth } from "./gatewayUtils";
import { commonTypePolicies } from "./typePolicies";

let authenticatedGateway: ApolloClient<object> | undefined = undefined;
let unauthenticatedGateway: ApolloClient<object> | undefined = undefined;

const getClientOptions = (fetchFn: typeof fetch): ApolloClientOptions<object> => {
  const cache = new InMemoryCache({
    dataIdFromObject,
    typePolicies: commonTypePolicies,
  });

  const link = errorLink.concat(
    new HttpLink({
      uri: process.env.gatewayUrl,
      fetch: fetchFn,
      headers,
    }),
  );

  return {
    name: "equiem-one-ui",
    cache,
    link,
    defaultOptions: {
      query: { errorPolicy: "all" },
      watchQuery: { errorPolicy: "all" },
    },
  };
};

export const getGlobalGateway = (getTokenFromPostMessage: boolean) => {
  if (authenticatedGateway == null) {
    authenticatedGateway = new ApolloClient(getClientOptions(withAuth(fetch, getTokenFromPostMessage)));
  }

  return {
    authenticate,
    client: authenticatedGateway,
  };
};

export const getGlobalUnauthenticatedGateway = () => {
  if (unauthenticatedGateway == null) {
    unauthenticatedGateway = new ApolloClient(getClientOptions(fetch));
  }
  return {
    client: unauthenticatedGateway,
  };
};
