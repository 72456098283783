import type { FC } from "react";
import React from "react";
import type { CreateCmsArticleInput, DocumentV2FragmentFragment } from "../../generated/gateway-client";
import { Form as EqForm } from "@equiem/react-admin-ui";
import { PreviewCard } from "./PreviewCard";

interface Props {
  article: Omit<CreateCmsArticleInput, "portfolio" | "images" | "downloads">;
  title: string;
  publishDate?: string;
  publishTime?: string;
  downloads?: DocumentV2FragmentFragment[];
  images?: DocumentV2FragmentFragment[];
}

export const ArticlePreview: FC<Props> = ({ article, title, publishDate, publishTime, downloads, images }) => {
  return (
    <>
      <div className="pt-4 px-6">
        <EqForm.Group label={title}>
          <PreviewCard
            publishDate={publishDate}
            publishTime={publishTime}
            article={article}
            downloads={downloads}
            images={images}
          />
        </EqForm.Group>
      </div>
    </>
  );
};
