import React, { useContext } from "react";
import { ArticleContext } from "../../contexts/ArticleContext";
import { useTranslation } from "@equiem/localisation-eq1";
import { ArticlePublishingTag } from "./ArticlePublishingTag";

export const ArticleFormTopBar = () => {
  const { article } = useContext(ArticleContext);
  const { t } = useTranslation();

  return (
    <>
      <div className="d-flex justify-content-between align-items-center mb-4">
        <h1>
          {article == null ? t("contentManagement.articles.newArticle") : t("contentManagement.articles.editArticle")}
        </h1>
        <div>
          <ArticlePublishingTag article={article} />
        </div>
      </div>
      <style jsx>{`
        h1 {
          font-size: 20px;
          line-height: 24px;
          font-weight: 700;
        }
      `}</style>
    </>
  );
};
