import type { TFunction } from "@equiem/localisation-eq1";

import { SpaceVisibilityType } from "../../../generated/settings-client";

export function translateVisibilityType(t: TFunction, visibilityType?: SpaceVisibilityType | null) {
  switch (visibilityType) {
    case SpaceVisibilityType.Public:
      return t("settings.build.widget.building");
    case SpaceVisibilityType.Members:
      return t("settings.build.widget.members");
    case SpaceVisibilityType.Custom:
      return t("settings.build.widget.custom");
    default:
      return t("settings.build.widget.private");
  }
}
