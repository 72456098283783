import type { FC } from "react";
import React from "react";
import { Tag } from "@equiem/react-admin-ui";
import { useTranslation } from "@equiem/localisation-eq1";
import { FlexTenantStatus } from "../generated/gateway-client";

interface Props {
  status: FlexTenantStatus;
}

export const StatusTag: FC<Props> = ({ status }) => {
  const { t } = useTranslation();

  switch (status) {
    case FlexTenantStatus.Active:
      return (
        <Tag variant="positive" className="ml-3">
          {t("flex.memberStatus.active")}
        </Tag>
      );
    case FlexTenantStatus.Expired:
      return (
        <Tag variant="negative" className="ml-3">
          {t("flex.memberStatus.expired")}
        </Tag>
      );
    case FlexTenantStatus.NearingExpiry:
      return (
        <Tag variant="warning" className="ml-3">
          {t("flex.memberStatus.expiring", { days: 30 })}
        </Tag>
      );
    case FlexTenantStatus.PreMoveIn:
      return (
        <Tag variant="warning" className="ml-3">
          {t("flex.memberStatus.pending")}
        </Tag>
      );
    default:
      return null;
  }
};
