import React, { useContext } from "react";

import { FormComboBoxContext } from "../FormComboBoxContext";
import * as ListGroup from "../../../ListGroup";
import type { ListGroupProps } from "../../../ListGroup/ListGroup";

type Props = ListGroupProps & { highlightColor?: string };

export const FormComboBoxOptions = React.forwardRef<HTMLUListElement, Props>(({ highlightColor, ...props }, ref) => {
  const combo = useContext(FormComboBoxContext);

  return (
    <ListGroup.Group {...props} noBorder={props.noBorder ?? true} ref={ref}>
      {combo.items.map((item, i) => (
        <ListGroup.Item
          onClick={() => {
            combo.setSelected(i);
            combo.setFocused(false);
          }}
          onMouseOver={() => {
            combo.setHighlighted(i);
          }}
          key={i}
          ref={combo.itemRefs[i]}
          noMargin
          highlighted={combo.highlighted === i}
          highlightColor={highlightColor}
          noBorder={props.noBorder}
        >
          {item.el ?? item.value}
        </ListGroup.Item>
      ))}
    </ListGroup.Group>
  );
});

FormComboBoxOptions.displayName = "FormComboBoxOptions";
