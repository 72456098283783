/* eslint-disable @typescript-eslint/no-magic-numbers */
/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import { ProgressCircle } from "../ProgressCircle/ProgressCircle";
import { DateTime } from "luxon";
import React, { useEffect, useRef, useState } from "react";
import groupBy from "lodash/groupBy";
import { useTranslation, formatters } from "@equiem/localisation-eq1";

import { useTheme } from "../../contexts/Theme";
import { useDebounced } from "../../hooks";
import { CalendarNavigation } from "./CalendarNavigation";

interface EventTime {
  start: number;
  end: number;
}

interface BlockedTimeInfo {
  start: number;
  end: number;
  color: string;
}

// eslint-disable-next-line @typescript-eslint/no-type-alias
type BlockedTime = Record<number, BlockedTimeInfo[]>;

const MINS_IN_HOUR = 60;

const generateDays = (activeTime: DateTime): DateTime[] => {
  const weekStart = activeTime.startOf("week");
  return Array(7)
    .fill(null)
    .map((_, i) => weekStart.plus({ day: i }));
};

const generateHours = (language: string): string[] => {
  const oneAm = DateTime.fromFormat("1", "h");
  const formatter = Intl.DateTimeFormat(language, { hour: "numeric", timeZone: oneAm.zoneName ?? undefined });
  return Array(23)
    .fill(null)
    .map((_, i) => formatter.format(oneAm.plus({ hour: i }).toJSDate()));
};

const populateSelectedTime = (
  time: { start?: number; end?: number } | null | undefined,
  timezone: string,
): { start: DateTime; end: DateTime } | null => {
  if (time?.start != null && time?.end != null) {
    const start = DateTime.fromMillis(time.start, { zone: timezone });
    const end = DateTime.fromMillis(time.end, { zone: timezone });
    return { start, end };
  }

  // if only one side is specified, pretend it's a 30 minute event but clamp the
  // times to make sure they stay on the same day
  if (time?.start != null) {
    const start = DateTime.fromMillis(time.start, { zone: timezone });
    const end = DateTime.min(start.plus({ minutes: 30 }), start.endOf("day"));
    return { start, end };
  }
  if (time?.end != null) {
    const end = DateTime.fromMillis(time.end, { zone: timezone });
    const start = DateTime.max(end.minus({ minutes: 30 }), end.startOf("day"));
    return { start, end };
  }

  return null;
};

const isToday = (date: DateTime, timezone: string) => date.hasSame(DateTime.local({ zone: timezone }), "day");

const isDisplayed = (timestamp: number, activeTime: DateTime, timezone: string) =>
  DateTime.fromMillis(timestamp, { zone: timezone }).hasSame(activeTime, "week");

const BlockedTime: React.FC<{
  start: number;
  end: number;
  timezone: string;
}> = ({ start, end, timezone }) => {
  const { colors } = useTheme();

  const startTime = DateTime.fromMillis(start, { zone: timezone });
  const endTime = DateTime.fromMillis(end, { zone: timezone });

  const gridRowStart = startTime.hour * MINS_IN_HOUR + startTime.minute + 1;
  const gridRowEnd = endTime.hour * MINS_IN_HOUR + endTime.minute + 1;
  return (
    <div className={`event ${start}-${end}`} style={{ gridRowStart, gridRowEnd, background: colors.grayscale[20] }} />
  );
};

const SelectedTime: React.FC<{
  start?: number;
  end?: number;
  timezone: string;
}> = ({ start, end, timezone }) => {
  const { i18n } = useTranslation();
  const { colors, spacers } = useTheme();

  const time = populateSelectedTime({ start, end }, timezone);
  if (time == null) {
    return null;
  }

  const gridRowStart = time.start.hour * MINS_IN_HOUR + time.start.minute + 1;
  const gridRowEnd = time.end.hour * MINS_IN_HOUR + time.end.minute + 1;

  const captionTextHeight = 12; // line height
  const captionHeight = captionTextHeight + 2 * 8; // line height +  2 * y-margin
  const eventHeight = gridRowEnd - gridRowStart; // 1 grid row = 1 min = 1px
  const showCaption = eventHeight >= captionTextHeight;
  const isShort = eventHeight < captionHeight;

  const timeStr = [
    start != null && formatters.timeshort(time.start, i18n.language, { timezone }),
    end != null && formatters.timeshort(time.end, i18n.language, { timezone }),
  ]
    .filter((token) => typeof token === "string")
    .join(" - ")
    .toLowerCase();

  return (
    <>
      <div
        className={`event selected-time ${gridRowStart}-${gridRowEnd} ${isShort ? "short-event" : ""}`}
        title={timeStr}
        style={{ gridRowStart, gridRowEnd }}
      >
        {showCaption && <span className="caption">{timeStr}</span>}
      </div>
      <style jsx>{`
        .selected-time {
          display: flex;
          border-left: 2px solid ${colors.primary};
          background: ${colors.blue[10]};
          overflow: hidden;
        }
        .selected-time.short-event {
          align-items: center;
        }
        .caption {
          display: inline-block;
          font-size: 12px;
          font-weight: 600;
          line-height: 12px;
          margin: 8px ${spacers.s3};
          flex: 1;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .short-event .caption {
          margin: 0 ${spacers.s3};
        }
      `}</style>
    </>
  );
};

export interface Props {
  timezone: string;
  selectedDate: DateTime;
  selectedTime?: { start?: number; end?: number } | null;
  autoScrollCurrentTime?: boolean;
  getBlockedTimesCb: (originalTimeOfTheWeek: DateTime) => Promise<EventTime[]>;
}

export const Calendar: React.FC<Props> = ({
  timezone,
  selectedDate,
  selectedTime,
  autoScrollCurrentTime = true,
  getBlockedTimesCb,
}) => {
  const { i18n } = useTranslation();
  const theme = useTheme();
  const calendarRef = useRef<HTMLDivElement>(null);
  const [activeTimeMs, setActiveTime] = useState(selectedDate.toMillis());
  const [loading, setLoading] = useState(false);
  const [rawBlockedTimes, setRawBlockedTimes] = useState<EventTime[]>([]);

  const activeTime = DateTime.fromMillis(activeTimeMs, { zone: timezone });
  const days = generateDays(activeTime);
  const hours = generateHours(i18n.language);
  const blocked = groupBy(rawBlockedTimes, ({ start }) => DateTime.fromMillis(start, { zone: timezone }).day);

  const selectedTimeBase = selectedTime?.start ?? selectedTime?.end ?? null;
  const selectedTimeDay =
    selectedTimeBase != null && isDisplayed(selectedTimeBase, activeTime, timezone)
      ? DateTime.fromMillis(selectedTimeBase, { zone: timezone }).day
      : null;
  const scrollHour =
    selectedTimeBase != null && isDisplayed(selectedTimeBase, activeTime, timezone)
      ? DateTime.fromMillis(selectedTimeBase, { zone: timezone }).hour
      : DateTime.local({ zone: timezone }).hour;
  useEffect(() => {
    if (autoScrollCurrentTime && calendarRef.current != null) {
      calendarRef.current.scrollTo({
        top: (scrollHour - 2) * MINS_IN_HOUR,
        behavior: "smooth",
      });
    }
  }, [autoScrollCurrentTime, calendarRef, scrollHour]);

  const processTime = async (newTime: DateTime) => {
    if (loading) {
      return;
    }

    try {
      setLoading(true);
      const evs = await getBlockedTimesCb(newTime);
      setRawBlockedTimes(evs);
      setActiveTime(newTime.toMillis());
    } finally {
      setLoading(false);
    }
  };

  const debouncedSelectedDate = useDebounced(selectedDate, 500);
  useEffect(() => {
    // eslint-disable-next-line no-console
    processTime(debouncedSelectedDate).catch(console.error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSelectedDate.toFormat("yyyy-LL-dd")]);

  const reset = async () => processTime(DateTime.local({ zone: timezone }));
  const prevWeek = async () => processTime(activeTime.minus({ week: 1 }));
  const nextWeek = async () => processTime(activeTime.plus({ week: 1 }));

  // As a general rule, rolling your own custom localised formatters like this
  // is a bad idea and should be avoided.
  // However, in this case, these custom date formats are only relevant for the
  // calendar view and we explicitly *don't* want them reused elsewhere.
  const monthFormatter = new Intl.DateTimeFormat(i18n.language, { month: "long", year: "numeric", timeZone: timezone });
  const dayFormatter = new Intl.DateTimeFormat(i18n.language, { weekday: "short", timeZone: timezone });

  return (
    <>
      <div className="calendar-cont">
        <div className="header">
          <div className="font-weight-bold">{monthFormatter.format(activeTime.toJSDate())}</div>
          <div>
            {loading ? <ProgressCircle size="sm" className="calendar-loading mr-4" /> : null}
            <CalendarNavigation loading={loading} prev={prevWeek} next={nextWeek} reset={reset} />
          </div>
        </div>

        <div className="cal-holder">
          <div className="dates-heading">
            <div className="time-spacer"></div>
            <div className="dates">
              {days.map((d) => (
                <div
                  key={d.day}
                  className={`date ${d.toFormat("cccd").toLowerCase()} ${isToday(d, timezone) ? "today" : ""}`}
                >
                  <span className="mr-2">{dayFormatter.format(d.toJSDate())}</span>
                  <span className="date-number">{d.day}</span>
                </div>
              ))}
            </div>
          </div>

          <div className="calendar" ref={calendarRef}>
            <div className="times">
              <div className="spacer"></div>
              {hours.map((h) => (
                <div key={h} className="time">
                  <span>{h}</span>
                </div>
              ))}
            </div>

            <div className="days">
              {days.map((d) => (
                <div key={d.toISO()} className={`day ${d.weekdayShort?.toLowerCase()}`}>
                  {blocked[d.day]?.map((b, i) => (
                    <BlockedTime key={i} timezone={timezone} {...b} />
                  ))}
                  {selectedTime != null && selectedTimeDay === d.day && (
                    <SelectedTime timezone={timezone} {...selectedTime} />
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <style jsx>{`
        .cal-holder {
          overflow-x: auto;
          padding-top: 10px;
        }
        .header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0 0 ${theme.spacers.s6};
        }
        .calendar {
          max-height: 600px;
        }
        .calendar,
        .dates-heading {
          display: grid;
          gap: 2px;
          grid-template-columns: auto 1fr;
        }
        @media (min-width: ${theme.breakpoints.lg}px) {
          .calendar,
          .dates-heading {
            gap: 10px;
          }
        }
        .days {
          display: flex;
        }
        .dates-heading {
          border-bottom: 1px solid ${theme.colors.border};
        }
        .dates {
          display: flex;
          text-align: center;
          font-size: 14px;
          padding-bottom: ${theme.spacers.s5};
        }
        .date.today .date-number {
          border-radius: 50%;
          background: black;
          color: white;
          padding: 0.5em;
        }
        .times {
          display: flex;
          flex-direction: column;
        }
        .time-spacer,
        .time {
          width: 40px;
        }
        .time {
          position: relative;
          height: 60px;
          font-size: 11px;
        }
        .time span {
          position: absolute;
          display: block;
          bottom: -9px;
          right: 0;
        }
        .date,
        .day {
          width: 100px;
        }
        .day {
          display: grid;
          grid-template-rows: repeat(1440, 1px);
          grid-template-columns: repeat(7, 98px);
          padding: 1px 1px 0;
          border: 1px solid ${theme.colors.border};
          border-width: 0 1px 1px 0;
          background-image: linear-gradient(${theme.colors.border} 1px, transparent 1px);
          background-size: 1px 60px;
        }
        .day:first-child {
          border-width: 0 1px 1px;
        }
        .event {
          opacity: 0.5;
        }

        @media (min-width: ${theme.breakpoints.md + 1}px) {
          .date,
          .day {
            width: calc(100% / 7);
          }
          .day {
            grid-template-columns: none;
            grid-auto-columns: 1fr;
          }
        }
      `}</style>
    </>
  );
};
