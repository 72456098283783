import { useTranslation } from "@equiem/localisation-eq1";
import { Form as EqForm, remainingCharacters, useTheme } from "@equiem/react-admin-ui";
import { RiCloseLine } from "@equiem/react-admin-ui/icons";
import { Field, useFormikContext, type ArrayHelpers } from "formik";
import React from "react";
import type { FormValues } from "./FormValues";

const MAXCHAR = 100;

interface P {
  index: number;
  arrayHelpers: ArrayHelpers;
}
export const RelatedInformation: React.FC<P> = ({ index, arrayHelpers }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const fm = useFormikContext<FormValues>();

  return (
    <>
      <div className="box mb-5">
        <div className="remove-cont">
          <RiCloseLine
            onClick={() => {
              arrayHelpers.remove(index);
            }}
            size={theme.sizes.sm}
            className="cursor-pointer"
          />
        </div>
        <div className="fields">
          <EqForm.Group
            className="mb-0"
            label={t("common.title")}
            required
            hint={t("common.remainingCharacters", {
              count: remainingCharacters(MAXCHAR, fm.values.links[index]?.title.length ?? 0),
            })}
          >
            <Field name={`links.${index}.title`} as={EqForm.Input} maxLength={MAXCHAR} />
          </EqForm.Group>
          <EqForm.Group className="mb-0" label={t("common.url")} required>
            <Field name={`links.${index}.url`} as={EqForm.Input} />
          </EqForm.Group>
        </div>
      </div>
      <style jsx>{`
        .fields {
          display: flex;
          gap: ${theme.spacers.s5};
        }
        .box {
          border: 1px solid ${theme.colors.border};
          border-radius: ${theme.borderRadius};
          padding: ${theme.spacers.s3} ${theme.spacers.s4};
          position: relative;
        }
        .remove-cont {
          position: absolute;
          right: ${theme.spacers.s2};
          top: ${theme.spacers.s2};
        }
      `}</style>
    </>
  );
};
