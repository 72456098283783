import React, { useMemo } from "react";
import type { ComponentProps } from "react";
import { RiSortAsc, RiSortDesc } from "react-icons/ri";
import { useTheme } from "../../contexts/Theme";
import { Text } from "../Text/Text";

// eslint-disable-next-line @typescript-eslint/no-type-alias
type Props = ComponentProps<"th"> & {
  label: string | React.ReactNode;
  direction?: "asc" | "desc";
};

export const Header = React.forwardRef<HTMLTableCellElement, Props>(
  ({ label, direction, className = "", children, ...props }, ref) => {
    const { colors } = useTheme();

    const isInteractive: boolean = useMemo(() => {
      return direction != null && props.onClick != null;
    }, [direction, props.onClick]);

    return (
      <>
        <th {...props} className={`${className} ${isInteractive ? "interactive" : ""}`.trim()} ref={ref}>
          {typeof label === "string" ? <Text variant="label">{label}</Text> : label}
          {direction != null && (
            <div className="direction">{direction === "asc" ? <RiSortAsc size={16} /> : <RiSortDesc size={16} />} </div>
          )}
          {children}
          <style jsx>{`
            th {
              box-shadow: inset 0 -1px ${colors.grayscale[10]};
              background-color: #fff;
              color: ${colors.grayscale[60]};
              text-align: left;
              padding: 0.5rem 1rem;
              line-height: 1rem;
              z-index: 1;
            }
            th:last-child {
              border-radius: 0 8px 0 0;
            }
            th:first-child {
              border-radius: 8px 0 0 0;
            }
            th.interactive {
              cursor: pointer;
            }
            th.interactive:hover {
              background-color: ${colors.grayscale_5};
            }
            .direction {
              display: inline;
              margin-left: 0.25rem;
            }
          `}</style>
        </th>
      </>
    );
  },
);

Header.displayName = "TableHeader";
