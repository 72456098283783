import type { TFunction } from "@equiem/localisation-eq1";
import { useTranslation, formatters } from "@equiem/localisation-eq1";
import { DateTime } from "luxon";
import React from "react";

interface Props {
  datetime: number;
  language: string;
  timezone?: string;
}

export const toRelativeDateString = ({ datetime, timezone, language }: Props, t: TFunction) => {
  const today = Number(DateTime.local({ zone: timezone }).toFormat("d"));
  const date = DateTime.fromMillis(datetime, { zone: timezone });
  const dayDiff = Number(date.toFormat("d")) - today;
  const dateDisplay =
    dayDiff === 0
      ? t("common.today").toUpperCase()
      : dayDiff === 1
      ? t("common.tomorrow").toUpperCase()
      : `${formatters.datelong(date, language)}`.toUpperCase();

  return dateDisplay;
};

export const RelativeDate: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  return <>{toRelativeDateString(props, t)}</>;
};
