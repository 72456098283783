import React, { useCallback, useContext, useEffect, useState } from "react";

import { AppointmentsMenuContext } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import { Button, Modal, useIsMobileWidth, useTheme } from "@equiem/react-admin-ui";

import { Modal as ModalContext } from "../../../contexts/ModalContext";

export const CancelFormWidget: React.FC<{
  onCancel?: () => void;
  bodyText?: string;
  isAllBuildingsReceptionsView?: boolean;
  receptionUuid?: string;
  isReceptionView?: boolean;
}> = ({ onCancel, bodyText, isAllBuildingsReceptionsView, isReceptionView, receptionUuid }) => {
  const { t } = useTranslation();
  const appointmentsMenu = useContext(AppointmentsMenuContext);
  const [showModal, setShowModal] = useState(false);
  const modal = useContext(ModalContext);
  const { colors } = useTheme();
  const isMobile = useIsMobileWidth();

  const onClose = useCallback(() => {
    setShowModal(false);
    modal.close();
  }, [setShowModal]);

  useEffect(() => {
    if (modal.activeModal === "CancelForm") {
      setShowModal(true);
    }
  }, [modal.activeModal, setShowModal]);

  const getNavigationPreviousUrl = () => {
    if (isAllBuildingsReceptionsView === true) {
      return "/visitor-management/building-receptions/pre_booked";
    }

    if (isReceptionView === true) {
      return `/visitor-management/receptions/${receptionUuid}/pre_booked`;
    }

    return undefined;
  };

  const handleReset = () => {
    appointmentsMenu.clearState();
    appointmentsMenu.navigatePreviousPage(getNavigationPreviousUrl());
    setShowModal(false);
    modal.close();
    onCancel?.();
  };

  return (
    <>
      <Modal.Dialog
        title={t("common.areYouSure")}
        show={showModal}
        onHide={onClose}
        hideOnEsc={true}
        supportsMobile
        mobileView
        hideOnClick={false}
        focusTrapOptions={{ initialFocus: false }}
        size="md"
        className="cancel-form-widget"
        centered
      >
        <Modal.Header closeButton={false} noBorder={true} />
        <Modal.Body>
          <h3 className="title m-0">{bodyText ?? t("visitors.appointmentForm.cancelBody")}</h3>
        </Modal.Body>
        {isMobile && <hr className="mt-6 mb-4 m-0" />}
        <Modal.Footer>
          <Button variant="ghost" className="mr-4" onClick={onClose}>
            {t("visitors.appointmentForm.cancelNo")}
          </Button>
          <Button variant="danger" className="cancel-button" onClick={handleReset}>
            {t("visitors.appointmentForm.cancelYes")}
          </Button>
        </Modal.Footer>
      </Modal.Dialog>
      <style jsx>
        {`
          hr {
            border: 0;
            border-top: 1px solid ${colors.border};
          }

          :global(.cancel-form-widget .footer) {
            display: flex;
            gap: 8px;
            padding: ${isMobile ? "1rem !important" : "0"};
          }
          :global(.cancel-form-widget .footer button) {
            width: ${isMobile ? "100%" : "auto"};
          }

          .title {
            font-weight: 400;
            font-size: 18px;
            line-height: 28px;
          }

          :global(.cancel-button) {
            margin-right: ${isMobile ? "0" : "0.5rem"};
          }
        `}
      </style>
    </>
  );
};
