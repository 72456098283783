import React from "react";

import { useIsMobileWidth } from "@equiem/react-admin-ui";

export const AppointmentFormTitle = ({
  isEditView,
  title,
  type,
}: {
  type: "create" | "update";
  isEditView: boolean;
  title: string;
}) => {
  const isMobile = useIsMobileWidth();
  const isTitleVisible = isMobile && !isEditView;

  return (
    <>
      {isTitleVisible && (
        <h1 style={{ fontWeight: "700" }} className="mb-2">
          {title}
        </h1>
      )}

      {!isMobile && type === "update" && !isEditView && <h1 style={{ fontWeight: "700" }}>{title}</h1>}
    </>
  );
};
