import { notNullOrUndefined } from "@equiem/lib";

import type { ApartmentListQuery } from "../../../generated/settings-client";
import { useApartmentListQuery } from "../../../generated/settings-client";

export type ApartmentListItem = NonNullable<ApartmentListQuery["apartments"]["edges"][number]["node"]>;

interface Settings {
  search?: string;
  destinationUuid?: string;
  excludeDestination?: boolean;
  pageSize?: number;
}

export function usePagedApartmentList({ search, destinationUuid, excludeDestination, pageSize }: Settings = {}) {
  const { data, loading, error, refetch, fetchMore } = useApartmentListQuery({
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
    variables: {
      destinationUuid,
      excludeDestination,
      first: pageSize,
      search: search != null && search.length > 0 ? search : undefined,
    },
  });

  return {
    error,
    loading,
    apartments: data?.apartments.edges.map(({ node }) => node).filter(notNullOrUndefined) ?? [],
    totalCount: data?.apartments.totalCount ?? 0,
    hasMoreData: data?.apartments.pageInfo.hasNextPage ?? false,
    refetch,
    fetchMore: () => {
      fetchMore({
        variables: {
          first: pageSize,
          after: data?.apartments.pageInfo.endCursor,
        },
        updateQuery(prev, { fetchMoreResult }) {
          return {
            ...fetchMoreResult,
            apartments: {
              ...fetchMoreResult.apartments,
              edges: [...prev.apartments.edges, ...fetchMoreResult.apartments.edges],
            },
          };
        },
      }).catch((e) => console.error(e));
    },
  };
}
