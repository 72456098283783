import { type SiteBookingsQuery, BookingStatus } from "../../../generated/gateway-client";
import { canCancelBooking } from "./canCancelBooking";

type Booking = Pick<
  NonNullable<SiteBookingsQuery["siteBookingsList"]["edges"][number]["node"]>,
  | "resource"
  | "user"
  | "status"
  | "isPast"
  | "isEditable"
  | "hasAnyPartialRefunds"
  | "hasAnyAdjustments"
  | "viewerPermissions"
>;

export const canApproveBooking = (booking: Booking) => {
  const canApproveCredits = booking.viewerPermissions.canApproveCredits;
  const needsCreditsApproval = booking.status === BookingStatus.PendingWorkplaceManagerApproval;

  const canApprove =
    booking.isEditable && booking.resource.viewerPermissions?.canManageBookings === true && canCancelBooking(booking);
  const needsApproval = booking.status === BookingStatus.PendingApproval;

  const showApproveOrDeny = (canApproveCredits && needsCreditsApproval) || (canApprove && needsApproval);

  return {
    needsCreditsApproval,
    canApproveCredits,

    needsApproval,
    canApprove,

    showApproveOrDeny,
  };
};
