import { Alert, Button, Modal, Form as EqForm, useTheme } from "@equiem/react-admin-ui";
import type { FC } from "react";
import { useEffect, useState } from "react";
import { Field, Form, Formik } from "formik";
import type { WidgetProps } from "@equiem/lib";
import { useShortcuts } from "@equiem/lib";
import { useInviteUsersMutation, type InviteUsersMutation } from "./generated/gateway-client";
import * as yup from "yup";
import { ResultScreen } from "./ResultScreen";
import { useTranslation } from "@equiem/localisation-eq1";
import type { ShortcutEvents } from "./ShortcutEvents";

const validationSchema = () =>
  yup.object().shape({
    emails: yup.string().required(),
  });

export const Widget: FC<WidgetProps> = ({ setLoading, siteUuid, viewer }) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [saved, setSaved] = useState<InviteUsersMutation["inviteUsersV3"]>();
  const [mutation, { error }] = useInviteUsersMutation();
  const { spacers, colors } = useTheme();

  const initialValues = {
    emails: "",
  };

  const { on } = useShortcuts<ShortcutEvents>();

  const hide = () => {
    setShowModal(false);
    setSaved(undefined);
  };

  useEffect(() => {
    setLoading(false);
  }, [setLoading]);

  useEffect(() => {
    on((shortcut) => {
      if (shortcut === "addUserAsUnknownRole") {
        setShowModal(true);
      }
    });
    // Exclude on to avoid multiple trigger.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Modal.Dialog
        title={t("home.widgets.inviteUsers")}
        show={showModal}
        onHide={hide}
        hideOnEsc={true}
        size="md"
        withFooter
      >
        <Modal.Header closeButton={false} noBorder={true} />
        {saved == null ? (
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema()}
            onSubmit={async (values, actions) => {
              actions.setSubmitting(true);
              const rs = await mutation({
                variables: {
                  users: values.emails.split(",").map((email) => ({
                    email,
                  })),
                  destinationUuid: siteUuid,
                  fromFirstName: viewer.firstName ?? "",
                  fromLastName: viewer.lastName ?? "",
                },
              });
              actions.setSubmitting(false);
              setSaved(rs.data?.inviteUsersV3);
            }}
          >
            {({ errors, isValid, isSubmitting, dirty, submitForm }) => (
              <>
                <Modal.Body>
                  <h1>{t("home.widgets.whoYouLikeToInvite")}</h1>
                  <Form>
                    {error != null && <Alert variant="negative" className="mb-3" message={error.message} />}
                    <EqForm.Group
                      label={t("common.emails")}
                      error={dirty && errors.emails ? errors.emails : undefined}
                      required
                    >
                      <Field
                        id="emails"
                        name="emails"
                        label={t("home.widgets.emailAddresses")}
                        placeholder={t("home.widgets.emailAddressesHint")}
                        as={EqForm.Textarea}
                        disabled={isSubmitting}
                      />
                      <p className="small mt-0 mb-0" style={{ color: colors.medium }}>
                        {t("home.widgets.emailAddressesInfo")}
                      </p>
                    </EqForm.Group>
                  </Form>
                </Modal.Body>
                <Modal.Footer>
                  <Button onClick={hide} variant="ghost" size="sm" className="mr-2">
                    {t("common.cancel")}
                  </Button>
                  <Button
                    type="submit"
                    size="sm"
                    className="submit"
                    onClick={submitForm}
                    disabled={isSubmitting || !isValid || !dirty}
                  >
                    {isSubmitting ? <>{t("home.widgets.inviting")}</> : <>{t("home.widgets.inviteUsers")}</>}
                  </Button>
                </Modal.Footer>
              </>
            )}
          </Formik>
        ) : (
          <>
            <Modal.Body>
              <ResultScreen firstName={viewer.firstName} result={saved} />
            </Modal.Body>
            <Modal.Footer>
              <Button size="sm" onClick={hide}>
                {t("common.done")}
              </Button>
            </Modal.Footer>
          </>
        )}
      </Modal.Dialog>
      <style jsx>{`
        h1 {
          font-weight: 700;
          margin-bottom: ${spacers.s5};
        }
      `}</style>
    </>
  );
};
