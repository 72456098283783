import type { ChangeEvent } from "react";
import React, { useCallback, useContext, useMemo } from "react";

import { stringIsEmpty } from "@equiem/lib/stringNotEmpty";
import { useTranslation } from "@equiem/localisation-eq1";
import { BulkTable, Form } from "@equiem/react-admin-ui";

import parseAndValidateName from "../../../../helpers/parseAndValidateName";
import validateEmail from "../../../../helpers/validateEmail";

import type { BulkUploadVisitor } from "./BulkUploadVisitorsContext";
import { BulkUploadVisitorsContext } from "./BulkUploadVisitorsContext";

export const BulkUploadVisitorsTable: React.FC = () => {
  const { visitors, updateVisitorRow, selectRows, removeRows, rowsAreSelected } = useContext(BulkUploadVisitorsContext);
  const { t } = useTranslation();

  const onUpdate = useCallback(
    (
      visitor: BulkUploadVisitor,
      e: ChangeEvent<HTMLInputElement>,
      property: "firstName" | "lastName" | "email" | "company",
    ) => {
      const parsedFirstName = parseAndValidateName(property === "firstName" ? e.target.value : visitor.firstName, t);
      const parsedLastName = parseAndValidateName(property === "lastName" ? e.target.value : visitor.lastName, t);
      updateVisitorRow({
        index: visitor.index,
        firstName: parsedFirstName.parsedName ?? visitor.firstName,
        lastName: parsedLastName.parsedName ?? visitor.lastName,
        email: property === "email" ? e.target.value : visitor.email,
        companyName: property === "company" ? e.target.value : visitor.companyName,
        firstNameError: parsedFirstName.errorMessage,
        lastNameError: parsedLastName.errorMessage,
      });
    },
    [updateVisitorRow, t],
  );

  const onBlur = useCallback(
    (visitor: BulkUploadVisitor) => () => {
      const parsedFirstName = parseAndValidateName(visitor.firstName, t);
      const parsedLastName = parseAndValidateName(visitor.lastName, t);
      updateVisitorRow({
        index: visitor.index,
        firstName: parsedFirstName.parsedName ?? visitor.firstName,
        lastName: parsedLastName.parsedName ?? visitor.lastName,
        email: visitor.email,
        companyName: visitor.companyName,
        firstNameError: parsedFirstName.errorMessage,
        lastNameError: parsedLastName.errorMessage,
        companyNameError:
          visitor.companyName != null && visitor.companyName.length > 100
            ? t("settings.editProfile.charactersMaximumNum", { maxNum: "100" })
            : undefined,
        emailError:
          visitor.email != null && !stringIsEmpty(visitor.email) && !validateEmail(visitor.email)
            ? t("home.widgets.invalidEmail")
            : undefined,
      });
    },
    [updateVisitorRow, t],
  );

  const bodyRows = useMemo(
    () =>
      visitors.map((visitor) => ({
        id: visitor.index,
        className: `visitor-row${
          visitor.firstNameError != null ||
          visitor.lastNameError != null ||
          visitor.emailError != null ||
          visitor.companyNameError != null
            ? " has-error"
            : ""
        }`,
        cols: [
          {
            content: (
              <Form.Group className="mb-0" error={visitor.firstNameError}>
                <Form.Input
                  variant="sm"
                  className={`${visitor.firstNameError == null ? "hide-border " : " visitor-input "}pl-3`}
                  type="text"
                  disabled={rowsAreSelected && !visitor.isChecked}
                  placeholder={t("common.firstName") ?? ""}
                  value={visitor.firstName}
                  onChange={(e) => onUpdate(visitor, e, "firstName")}
                  onBlur={onBlur(visitor)}
                  style={{ textOverflow: "ellipsis" }}
                />
              </Form.Group>
            ),
          },
          {
            content: (
              <Form.Group className="mb-0" error={visitor.lastNameError}>
                <Form.Input
                  variant="sm"
                  className={`${visitor.lastNameError == null ? "hide-border " : " visitor-input "}pl-3`}
                  type="text"
                  disabled={rowsAreSelected && !visitor.isChecked}
                  placeholder={t("common.lastName") ?? ""}
                  value={visitor.lastName}
                  onChange={(e) => onUpdate(visitor, e, "lastName")}
                  onBlur={onBlur(visitor)}
                  style={{ textOverflow: "ellipsis" }}
                />
              </Form.Group>
            ),
          },
          {
            content: (
              <Form.Group className="mb-0" error={visitor.companyNameError}>
                <Form.Input
                  variant="sm"
                  className={`${visitor.companyNameError == null ? "hide-border " : " visitor-input "}pl-3`}
                  type="text"
                  disabled={rowsAreSelected && !visitor.isChecked}
                  placeholder={t("common.company") ?? ""}
                  value={visitor.companyName}
                  onChange={(e) => onUpdate(visitor, e, "company")}
                  onBlur={onBlur(visitor)}
                  style={{ textOverflow: "ellipsis" }}
                />
              </Form.Group>
            ),
          },
          {
            content: (
              <Form.Group className="mb-0" error={visitor.emailError}>
                <Form.Input
                  variant="sm"
                  className={`${visitor.emailError == null ? "hide-border " : " visitor-input "}email pl-3`}
                  type="email"
                  disabled={rowsAreSelected && !visitor.isChecked}
                  placeholder={t("common.email") ?? ""}
                  value={visitor.email}
                  onChange={(e) => onUpdate(visitor, e, "email")}
                  onBlur={onBlur(visitor)}
                  style={{ textOverflow: "ellipsis" }}
                />
              </Form.Group>
            ),
          },
        ],
      })),
    [visitors, onUpdate, onBlur, rowsAreSelected, t],
  );

  return (
    <div className="bulk-visitor-upload-table">
      {
        <BulkTable
          sideBorders={false}
          className="table"
          onDeleted={removeRows}
          onSelected={selectRows}
          style={{ width: "100%" }}
          headers={[
            { label: t("common.firstName") },
            { label: t("common.lastName") },
            { label: t("common.company") },
            { label: t("common.email"), style: { minWidth: "16rem", width: "30%" } },
          ]}
          bodyRows={bodyRows}
          deletion={{ bulk: true, single: true }}
        />
      }
      <style jsx>
        {`
          .bulk-visitor-upload-table :global(.hide-border) {
            border-bottom-color: transparent !important;
            border-top-color: transparent !important;
            border-left-color: transparent !important;
            border-right-color: transparent !important;
            background: transparent !important;
          }
          .bulk-visitor-upload-table :global(.visitor-input:focus) {
            background: white !important;
          }
          .bulk-visitor-upload-table :global(.hide-border:focus) {
            background: white !important;
          }
        `}
      </style>
    </div>
  );
};
