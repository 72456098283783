import type { PropsWithChildren } from "react";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation, formatters } from "@equiem/localisation-eq1";

import { SegmentSkeleton } from "../components/SegmentSkeleton";
import type { SavedSegmentV2, SaveSegmentV2Input } from "../generated/gateway-client";
import {
  useSavedSegmentLazyQuery,
  useFiltersForSiteLazyQuery,
  useEstimateAudienceLazyQuery,
} from "../generated/gateway-client";
import { useSummaryFilter } from "../hooks/useSummaryForFilter";
import { useSummaryForUserTypeFilter } from "../hooks/useSummaryForUserTypeFilter";
import { mapFilterTypeToFilter, mapFilterToFilterType, translateFilterType } from "../lib/mapGraphqlFilterType";
import type { SFI } from "../lib/sfi";
import { NotificationMethod } from "../state/NotificationMethod";
import { NotificationType } from "../state/NotificationType";
import { CurrentAudience } from "./AudienceProvider";

export const filterType = [
  "apartments",
  "areas",
  "buildings",
  "companies",
  "companyAttributes",
  "levels",
  "roles",
  "userAttributes",
  "userType",
  "permissionRoles",
  "flexOperator",
  "flexTenants",
] as const;

export type FilterType = (typeof filterType)[number];

export interface Filter {
  type: FilterType;
  available: boolean;
  enabled: boolean;
}

export interface SegmentContext {
  id: string;
  isFilterEnabled: (filter: FilterType) => boolean;
  toggleFilter: (filter: FilterType, enabled: boolean) => void;
  toggleItem: (item: string, enabled: boolean) => void;
  toggleAllItemsForFilter: (filter: FilterType, enabled: boolean) => void;
  activeFilters: Filter[];
  inactiveFilters: Filter[];
  filterItems: SFI[];
  savedSegment?: SavedSegmentV2;
  estimatedAudience: number;
  commercialSelected: boolean;
  setCommercialSelected: (selected: boolean) => void;
  residentialSelected: boolean;
  setResidentialSelected: (selected: boolean) => void;
  visitorsSelected: boolean;
  setVisitorsSelected: (selected: boolean) => void;
}

export const CurrentSegment = React.createContext<SegmentContext>({
  id: "",
  isFilterEnabled: () => false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  toggleFilter: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  toggleItem: () => {},
  activeFilters: [],
  inactiveFilters: [],
  filterItems: [],
  estimatedAudience: 0,
  commercialSelected: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setCommercialSelected: () => {},
  residentialSelected: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setResidentialSelected: () => {},
  visitorsSelected: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setVisitorsSelected: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  toggleAllItemsForFilter: () => {},
});

interface Props {
  id: string;
  segmentId?: string;
  onQueryUpdate: (id: string, query: SaveSegmentV2Input, summary: string, changes?: string) => void;
}

export const SegmentProvider: React.FC<PropsWithChildren<Props>> = ({ id, segmentId, onQueryUpdate, children }) => {
  const { t, i18n } = useTranslation();
  const { site, notificationMethod, notificationType, gatewayEndpoint } = useContext(CurrentAudience);
  const [loaded, setLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [estimatedAudience, setEstimatedAudience] = useState(0);
  const [savedSegment, setSavedSegment] = useState<SavedSegmentV2>();
  const [commercialSelected, setCommercialSelected] = useState(false);
  const [residentialSelected, setResidentialSelected] = useState(false);
  const [visitorsSelected, setVisitorsSelected] = useState(false);
  const [filterItems, setFilterItems] = useState<SFI[]>([]);
  const [filterState, setFilterState] = useState<Filter[]>(
    filterType.map((f) => {
      return {
        type: f,
        available: true,
        enabled: false,
      };
    }),
  );

  const [siteFiltersQuery] = useFiltersForSiteLazyQuery({
    context: {
      uri: gatewayEndpoint,
    },
  });
  const [savedSegmentQuery] = useSavedSegmentLazyQuery({
    context: {
      uri: gatewayEndpoint,
    },
  });
  const [estimateAudienceQuery] = useEstimateAudienceLazyQuery({
    context: {
      uri: gatewayEndpoint,
    },
  });
  const [filterSummary] = useSummaryFilter(filterItems);
  const [utFilterSummary] = useSummaryForUserTypeFilter(commercialSelected, visitorsSelected, residentialSelected);

  // Filters that are available on the site.
  const availableFilters = useCallback(() => {
    return filterState.filter((f) => f.available);
  }, [filterState]);

  // Filters that are available and in use.
  const activeFilters = availableFilters().filter((f) => f.enabled);
  // Filters that available on the site and not in use.
  const inactiveFilters = availableFilters().filter((f) => !f.enabled);
  // Checked filter items for a specific filter
  const checkedItemsForFilter = useCallback(
    (filter: FilterType) => {
      return filterItems
        .filter((fi) => mapFilterTypeToFilter(fi.filterType) === filter && fi.checked)
        .map((fi) => fi.uuid);
    },
    [filterItems],
  );

  const saveSegmentInput = useCallback(() => {
    const filtersExcludingUserType = availableFilters()
      .filter((f) => f.enabled)
      .filter((af) => af.type !== "userType");

    const input: SaveSegmentV2Input = {
      site: site.destination.uuid,
      filters: filtersExcludingUserType.map((f, i) => {
        const type = mapFilterToFilterType(f.type);
        return {
          order: i,
          type,
          checkedItems: checkedItemsForFilter(f.type),
        };
      }),
    };
    const registrationFilters = availableFilters()
      .filter((f) => f.enabled)
      .find((af) => af.type === "userType");
    if (registrationFilters != null) {
      const rt: string[] = [];
      if (commercialSelected) {
        rt.push("COMMERCIAL");
      }
      if (residentialSelected) {
        rt.push("RESIDENTIAL");
      }
      if (visitorsSelected) {
        rt.push("VISITOR");
      }
      input.registrationTypes = rt;
    }

    input.requiresSubscribedToEmails = notificationMethod === NotificationMethod.EMAIL;
    input.requiresMobileNumber = notificationMethod === NotificationMethod.SMS;
    input.requiresSubscribedToNotifications = notificationType === NotificationType.REGULAR;

    return input;
  }, [
    site,
    checkedItemsForFilter,
    commercialSelected,
    residentialSelected,
    visitorsSelected,
    availableFilters,
    notificationMethod,
    notificationType,
  ]);

  const changeSummary = useCallback(() => {
    const parts: string[] = [];
    filterState.forEach((ft) => {
      if (ft.type !== "userType") {
        const starting = savedSegment?.filters.filter((f) => f.type === mapFilterToFilterType(ft.type)) ?? [];
        const fi = filterItems.filter((f) => f.filterType === mapFilterToFilterType(ft.type));
        const startedEnabled = starting.length > 0;
        const ftType = translateFilterType(ft.type, t);
        if (startedEnabled && !ft.enabled) {
          parts.push(t("segmentation.changeSummary.filterDisabled", { filterType: ftType }));
        } else if (!startedEnabled && ft.enabled) {
          parts.push(t("segmentation.changeSummary.filterEnabled", { filterType: ftType }));
        } else if (ft.enabled) {
          const added = fi
            .filter((c) => c.checked && !starting.flatMap((sc) => sc.checkedItems).includes(c.uuid))
            .map((c) => c.name);
          if (added.length > 0) {
            parts.push(
              t("segmentation.changeSummary.filterAdded", {
                filterType: ftType,
                items: formatters.list(added, i18n.language, { style: "short" }),
              }),
            );
          }
          const removed = fi
            .filter((c) => !c.checked && starting.flatMap((sc) => sc.checkedItems).includes(c.uuid))
            .map((c) => c.name);
          if (removed.length > 0) {
            parts.push(
              t("segmentation.changeSummary.filterRemoved", {
                filterType: ftType,
                items: formatters.list(removed, i18n.language, { style: "short" }),
              }),
            );
          }
        }
      }
    });

    if (parts.length === 0) {
      return undefined;
    }

    return formatters.list(parts, i18n.language, { style: "narrow" });
  }, [savedSegment, filterState, filterItems, t, i18n.language]);

  useEffect(() => {
    const input = saveSegmentInput();
    let newSummary = "";
    if (input.filters.length === 0) {
      newSummary = `${t("common.all")}.`;
    }
    input.filters.forEach((f) => {
      const selectedSummary = filterSummary(mapFilterTypeToFilter(f.type));

      newSummary = `${newSummary} ${selectedSummary}.`;
    });
    const utSummary = utFilterSummary();
    if (utSummary != null) {
      newSummary = `${newSummary} ${utSummary}.`;
    }

    onQueryUpdate(id, input, newSummary, changeSummary());
  }, [id, onQueryUpdate, saveSegmentInput, filterSummary, utFilterSummary, changeSummary, t]);

  useEffect(() => {
    const cb = async () => {
      const input = saveSegmentInput();

      const rs = await estimateAudienceQuery({
        variables: {
          input,
        },
      });
      setEstimatedAudience(rs.data?.estimateAudienceUserCount ?? 0);
    };

    if (loaded) {
      void cb();
    }
  }, [filterItems, loaded, site, saveSegmentInput, estimateAudienceQuery]);

  useEffect(() => {
    const cb = async () => {
      const registration = site.destination.settings.registration;
      if (
        !registration.commercialSignupEnabled &&
        !registration.visitorSignupEnabled &&
        !registration.residentialSignupEnabled
      ) {
        setFilterState((prev) => {
          return prev.map((f) => {
            if (f.type === "userType") {
              return {
                ...f,
                available: false,
                enabled: false,
              };
            } else {
              return f;
            }
          });
        });
      }
      const data = await siteFiltersQuery({
        variables: {
          uuid: site.destination.uuid,
          segmentUuid: segmentId,
        },
      });
      const items = data.data?.filtersForSiteV2 ?? [];
      setFilterItems(items.map((i) => ({ ...i, checked: false })));

      setFilterState((prev) => {
        return prev.map((f) => {
          if (f.type !== "userType") {
            // Check if fitler items available.
            const availItems = items.find((i) => i.filterType === mapFilterToFilterType(f.type));
            return {
              ...f,
              available: availItems != null,
            };
          } else {
            return f;
          }
        });
      });

      if (segmentId != null) {
        const segment = (
          await savedSegmentQuery({
            variables: {
              uuid: segmentId,
            },
          })
        ).data?.savedSegmentV2;
        setSavedSegment(segment);
        if (segment?.registrationTypes != null && segment.registrationTypes.length > 0) {
          setFilterState((prev) => {
            return prev.map((f) => {
              if (f.type === "userType") {
                return {
                  ...f,
                  enabled: true,
                };
              } else {
                return f;
              }
            });
          });
          setCommercialSelected(segment.registrationTypes.find((rt) => rt === "COMMERCIAL") !== undefined);
          setResidentialSelected(segment.registrationTypes.find((rt) => rt === "RESIDENTIAL") !== undefined);
          setVisitorsSelected(segment.registrationTypes.find((rt) => rt === "VISITOR") !== undefined);
        }
        segment?.filters.forEach((segmentFilter) => {
          if (segmentFilter.checkedItems.length > 0) {
            const converted = mapFilterTypeToFilter(segmentFilter.type);
            setFilterState((prev) => {
              return prev.map((f) => {
                if (f.type === converted) {
                  return {
                    ...f,
                    enabled: true,
                  };
                } else {
                  return f;
                }
              });
            });
            segmentFilter.checkedItems.forEach((checkedUuid) => {
              setFilterItems((prev) => {
                return prev.map((p) => {
                  if (p.uuid === checkedUuid) {
                    return {
                      ...p,
                      checked: true,
                    };
                  } else {
                    return p;
                  }
                });
              });
            });
          }
        });
      }
      setLoaded(true);
    };
    if (!loaded && !loading) {
      setLoading(true);
      void cb();
    }
  }, [site, savedSegmentQuery, segmentId, siteFiltersQuery, loaded, loading]);

  const isFilterEnabled = (filter: FilterType) => {
    return filterState.find((f) => f.type === filter)?.enabled ?? false;
  };

  const toggleFilter = (filter: FilterType, enabled: boolean) => {
    setFilterState((prev) => {
      return prev.map((f) => {
        if (f.type === filter) {
          return {
            ...f,
            enabled,
          };
        } else {
          return f;
        }
      });
    });
  };

  const toggleItem = (item: string, enabled: boolean) => {
    setFilterItems((prev) => {
      return prev.map((p) => {
        if (p.uuid === item) {
          return {
            ...p,
            checked: enabled,
          };
        } else {
          return p;
        }
      });
    });
  };

  const toggleAllItemsForFilter = (filter: FilterType, enabled: boolean) => {
    setFilterItems((prev) => {
      return prev.map((p) => {
        if (p.filterType === mapFilterToFilterType(filter)) {
          return {
            ...p,
            checked: enabled,
          };
        } else {
          return p;
        }
      });
    });
  };

  return (
    <CurrentSegment.Provider
      value={{
        id,
        isFilterEnabled,
        toggleFilter,
        toggleItem,
        activeFilters,
        inactiveFilters,
        filterItems,
        savedSegment,
        estimatedAudience,
        commercialSelected,
        setCommercialSelected,
        residentialSelected,
        setResidentialSelected,
        visitorsSelected,
        setVisitorsSelected,
        toggleAllItemsForFilter,
      }}
    >
      {!loaded && <SegmentSkeleton />}
      {loaded && children}
    </CurrentSegment.Provider>
  );
};
