import React from "react";
import type { AlertAction, AlertSize } from "../Alert/Alert";

export interface ToastOptions {
  showClose?: boolean;
  autoDismiss?: boolean;
  icon?: React.ReactNode;
  primaryAction?: AlertAction;
  secondaryAction?: AlertAction;
  size?: AlertSize;
  name?: string;
}

export type ToastFn = (message: string | React.ReactNode, options?: ToastOptions) => { remove: () => void };

export interface ToastContext {
  provided: boolean;
  neutral: ToastFn;
  positive: ToastFn;
  negative: ToastFn;
}

export const ToastContext = React.createContext<ToastContext>({
  provided: false,
  neutral: () => ({ remove: () => undefined }),
  positive: () => ({ remove: () => undefined }),
  negative: () => ({ remove: () => undefined }),
});
