import { useShowError } from "@equiem/lib";
import { useCallback, useRef, useState } from "react";
import axios from "axios";
import { fileToImageUrl } from "./fileToImageUrl";
import { IImage } from "./IImage";
import { useToast } from "@equiem/react-admin-ui";
import { useTranslation } from "@equiem/localisation-eq1";

const defaultWidth = 500;
const defaultHeight = 280;

export function useImageUploaderState(
  value: IImage[],
  onChange: (imgs: IImage[]) => void,
  createPresignedUrl: (args: { filename: string; contentType?: string }) => Promise<{
    key: string;
    signedUrl: string;
    imgixUrl?: string;
  }>,
  multipleUpload = false,
) {
  const toast = useToast();
  const showError = useShowError();
  const { t } = useTranslation();
  const [progress, setProgress] = useState<number>(0);
  const [dragover, setDragover] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [imgHeight, setImgHeight] = useState(defaultHeight);
  const [imgWidth, setImgWidth] = useState(defaultWidth);
  const cropperRef = useRef<HTMLImageElement>(null);
  const [localAttachedImage, setLocalAttachedImage] = useState<{
    file: File;
    img: string;
  }>();
  const [localCroppedImage, setLocalCroppedImage] = useState<string>();
  const [attachedImages, setAttachedImages] = useState<IImage[]>(
    value != null && (value[0]?.key?.length > 0 || value[0]?.url != null) ? value : [],
  );

  const onCrop = useCallback(() => {
    const imageElement: any = cropperRef?.current;
    const cropper: any = imageElement?.cropper;
    const canvas = cropper.getCroppedCanvas();

    setImgWidth(canvas.width);
    setImgHeight(canvas.height);
    setLocalCroppedImage(canvas.toDataURL());
  }, [cropperRef, setLocalCroppedImage, setImgWidth, setImgHeight]);

  const onSaveCropped = async () => {
    readAndUpload();
  };

  const clearCropper = () => {
    setLocalAttachedImage(undefined);
    setLocalCroppedImage(undefined);
  };

  const readAndUpload = async () => {
    if (!localAttachedImage) {
      return;
    }

    try {
      setProgress(3);
      setUploading(true);
      const presignedUrl = await createPresignedUrl({
        filename: localAttachedImage.file.name,
        contentType: localAttachedImage.file.type,
      });
      const blob = await (await fetch(localCroppedImage as any)).blob();
      const result = await axios.put(presignedUrl.signedUrl!, blob, {
        headers: {
          "Content-Type": localAttachedImage.file.type,
        },
        onUploadProgress: (e: ProgressEvent) => {
          const p = Math.ceil((e.loaded / e.total) * 100);
          setProgress(p);

          if (p === 100) {
            setTimeout(() => {
              setProgress(0);
            }, 750);
          }
        },
      });

      if (result.status === 200) {
        const newImg = {
          alt: "Image",
          title: "Image",
          key: presignedUrl.key,
          thumbnail_key: presignedUrl.key!,
          url: presignedUrl.imgixUrl!,
        };
        clearCropper();
        const newImages = multipleUpload ? [...attachedImages, newImg] : [newImg];
        setAttachedImages(newImages);
        onChange(newImages);
      } else {
        throw new Error(`Upload error: ${result.statusText}`);
      }
    } catch (err: unknown) {
      showError(err);
    } finally {
      setUploading(false);
      setProgress(0);
    }
  };

  const sortOrder = useCallback(
    (fromIndex: number, toIndex: number) => {
      const newArray = [...attachedImages];
      const startIndex = fromIndex < 0 ? newArray.length + fromIndex : fromIndex;

      if (startIndex >= 0 && startIndex < newArray.length) {
        const endIndex = toIndex < 0 ? newArray.length + toIndex : toIndex;

        const [item] = newArray.splice(fromIndex, 1);
        newArray.splice(endIndex, 0, item);

        setAttachedImages(newArray);
        onChange(newArray);
      }
    },
    [attachedImages],
  );

  const onFileChange = async (files: FileList) => {
    if (files.length !== 0) {
      if (!files[0].type.includes("image")) {
        toast.negative(t("common.invalidImage"));
        return;
      }

      const img = (await fileToImageUrl(files[0])) as string;
      setLocalAttachedImage({ file: files[0], img });
    }
  };

  const removeImage = useCallback(
    (imageKey: string) => {
      const newImages = attachedImages.filter((img) => img.key !== imageKey);
      setAttachedImages(newImages);
      onChange(newImages);
    },
    [attachedImages],
  );

  const removeImages = () => {
    onChange([
      {
        alt: "",
        title: "",
        key: "",
        thumbnail_key: "",
        url: "",
      },
    ]);
    setAttachedImages([]);
  };

  return {
    localAttachedImage,
    attachedImages,
    dragover,
    uploading,
    setDragover,
    onFileChange,
    progress,
    onCrop,
    onSaveCropped,
    cropperRef,
    clearCropper,
    sortOrder,
    removeImage,
    removeImages,
    setAttachedImages,
    imgHeight,
    imgWidth,
  };
}
