import React, { forwardRef } from "react";
import DOMPurify from "isomorphic-dompurify";

import { sanitiseOptions } from "./domPurifyHook";

interface Props {
  className?: string;
  html: string;
}

export const EscapedHtmlSpan = forwardRef<HTMLSpanElement, Props>(({ className, html }, ref) => {
  return (
    <>
      <span
        ref={ref}
        className={`content ${className ?? ""}`}
        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(html, sanitiseOptions) }}
      ></span>
      <style jsx>{`
        .content :global(img) {
          max-width: 100%;
        }
      `}</style>
    </>
  );
});

EscapedHtmlSpan.displayName = "EscapedHtmlSpan";
