import React from "react";
import { useTheme } from "@equiem/react-admin-ui";
import { CreditsSettingsMenu, CreditsGeneralSettings } from "../components/credits/CreditsSettings";

export const CreditsSettingsPage: React.FC = () => {
  const { colors } = useTheme();
  return (
    <div className="credits d-flex flex-column flex-grow-1 flex-shrink-1 flex-basis-0">
      <CreditsSettingsMenu />
      <CreditsGeneralSettings />
      <style jsx>
        {`
          .credits {
            background: ${colors.white};
          }
        `}
      </style>
    </div>
  );
};
