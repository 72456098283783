import React from "react";
import { PaymentGatewayConnect } from "./components/PaymentGatewayConnect";
import { SettingsTab } from "./components/SettingsTab";
import { PaymentAdjustmentToggle } from "./components/PaymentAdjustmentToggle";
import { useBookingSettingQuery } from "../../generated/gateway-client";
import { useSiteContext } from "@equiem/lib";
import { Skeleton } from "@equiem/react-admin-ui";
import { useTranslation } from "@equiem/localisation-eq1";
import { PaymentAdjustmentDayMax } from "./components/PaymentAdjustmentDayMax";

export const PaymentDetailsPage = () => {
  const site = useSiteContext();
  const { t } = useTranslation();
  const settingLoad = useBookingSettingQuery({ variables: { site: site.uuid }, fetchPolicy: "network-only" });
  const setting = settingLoad.data?.bookingSetting;

  if (settingLoad.error != null) {
    console.log(settingLoad.error);
  }

  return (
    <SettingsTab>
      <>
        {settingLoad.loading ? (
          <div>
            <Skeleton.Line width="100px" height="15px" block className="mb-3" />
            <Skeleton.Line width="250px" height="20px" />
          </div>
        ) : (
          <>
            <PaymentGatewayConnect setting={setting} />
            <PaymentAdjustmentToggle setting={setting} />
            <PaymentAdjustmentDayMax setting={setting} />
          </>
        )}
        {settingLoad.error != null && <>{t("bookings.settings.settingsFailedToLoad")}</>}
      </>
    </SettingsTab>
  );
};
