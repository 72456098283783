import React, { useMemo, useState } from "react";
import { useTranslation } from "@equiem/localisation-eq1";
import { Dropdown, Form, ProgressCircle, useTheme, Text } from "@equiem/react-admin-ui";
import { RiSearchLine } from "@equiem/react-admin-ui/icons";

const RESERVE_SCROLL_SIZE = 50;

export const AllMembershipFilter = ({
  loading,
  options,
  onSearch,
  handleNext,
  hasNextPage = false,
  onChange,
}: {
  loading?: boolean;
  options: Array<{ label: string; value: string }>;
  handleNext: () => void;
  onSearch: (v: string) => void;
  hasNextPage?: boolean;
  onChange?: (v: Array<{ label: string; value: string }>) => void;
}) => {
  const { t } = useTranslation();
  const { colors } = useTheme();
  const [currentTenant, setCurrentTenant] = useState<Array<{ label: string; value: string }>>([]);

  const handleChange = (option: { label: string; value: string }) => () => {
    const currentValue = (currentTenant ?? []) as Array<{ label: string; value: string }>;
    const checked = !currentValue.map((c) => c.value).includes(option.value);
    const selectedValues = checked ? [...currentValue, option] : currentValue.filter((v) => v.value !== option.value);

    setCurrentTenant(selectedValues);
    onChange?.(selectedValues);
  };

  const getTitle = () => {
    if (currentTenant.length === 0 || currentTenant.length === options.length) {
      return t("credits.allTenants");
    }

    return currentTenant.map((c) => c.label).join(", ");
  };

  const values = useMemo(() => (currentTenant ?? []).map((c) => c.value), [currentTenant]);

  const onScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const target = e.target as HTMLDivElement;

    if (loading === true || !hasNextPage) {
      return;
    }

    const isNearBottom = target.scrollHeight - target.scrollTop <= target.clientHeight + RESERVE_SCROLL_SIZE;

    if (isNearBottom) {
      handleNext();
    }
  };

  return (
    <>
      <Dropdown.Button
        size="sm"
        title={<span className={currentTenant.length > 0 ? "selected-title" : ""}>{getTitle()}</span>}
        variant="ghost"
      >
        <>
          <Form.InputGroup.Group className="search-input">
            <Form.InputGroup.Prefix>
              <RiSearchLine color={colors.muted1} size={16} />
            </Form.InputGroup.Prefix>
            <Form.Input
              placeholder={t("common.search")}
              autoComplete="off"
              onChange={(e) => onSearch(e.target.value)}
              type="text"
            />
          </Form.InputGroup.Group>
          <div onScroll={onScroll} className="options-list">
            {options.map((option) => (
              <div
                className={values.includes(option.value) ? "option option-active" : "option"}
                key={option.label}
                onClick={handleChange(option)}
              >
                <input
                  type="checkbox"
                  name={option.label}
                  onChange={handleChange(option)}
                  checked={values.includes(option.value)}
                  className="m-0"
                />
                <Text variant="text" component="label" size="small" className="ml-3">
                  {option.label}
                </Text>
              </div>
            ))}
            {loading === true && (
              <ProgressCircle style={{ height: 40 }} className="d-flex justify-content-center" size="sm" />
            )}
          </div>
        </>
      </Dropdown.Button>
      <style jsx>
        {`
          .selected-title {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            max-width: 200px;
          }

          .options-list {
            display: flex;
            flex-direction: column;
            gap: 0.125rem;
            padding: 0.5rem 0.5rem 0;
            overflow-y: auto;
          }
          :global(.search-input) {
            margin: 0 0.5rem;
          }
          .options-top {
            padding: 0 1rem ${options.length === 0 ? "0" : "0.25rem"};
            display: flex;
            flex-direction: row;
            align-items: center;
            color: ${colors.grayscale[100]};
          }

          .option {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 0.5rem;
            cursor: pointer;
          }

          .option-active {
            background-color: ${colors.blue[10]};
            border-radius: 0.25rem;
          }

          input[type="checkbox"] {
            width: 1rem;
            height: 1rem;
            background: #ffffff;
            border: 1px solid ${colors.grayscale[20]};
            border-radius: 0.25rem;
            appearance: none;
            position: relative;
            margin: 0;
            flex-shrink: 0;
          }

          input[type="checkbox"]:checked {
            border: none;
          }

          input[type="checkbox"]:checked:before {
            content: "";
            background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAACXBIWXMAABYlAAAWJQFJUiTwAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACHSURBVHgB7ZPRDYAgDEQ7giM4ghs6GiM4AiMwwlmiH4YgqIEzIX1JwxfcC21FDMMwOgNg1nLxFDZnuMeBp0ok4ZFNaxIGY4R/6VfL8EUraK0v7rT79uShqgRa9/zyA1UJ9Bq4JxLoPe0lCbBWLScB9p5nJAItvCDBC7+R4IYnEu6XcMMwhmQHt/C0plArFXgAAAAASUVORK5CYII=");
            background-size: 1rem 1rem;
            background-repeat: no-repeat;
            background-position: center;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            border-radius: 0.25rem;
            background-color: ${colors.blue[60]};
            color: #fff;
          }
        `}
      </style>
    </>
  );
};
