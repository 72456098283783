import React, { useContext, useEffect, useMemo } from "react";
import { Field, Form } from "formik";

import type { CompanyFormValues } from "@equiem/lib";
import {
  CurrentRole,
  FormList,
  notNullOrUndefined,
  Role,
  useCompanyIndustries,
  useSaferFormikContext,
  useShowError,
  useSiteContext,
} from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import {
  Alert,
  Button,
  Dropdown,
  Form as EqForm,
  Text,
  useDebounced,
  useTheme,
  useToast,
} from "@equiem/react-admin-ui";
import { RiInformationLine } from "@equiem/react-admin-ui/icons";

import { LevelsList } from "../../../components/LevelsList";
import { Modal as ModalContext } from "../../../contexts/ModalContext";
import { useWidgetContext } from "../../../contexts/WidgetContext";
import {
  useAddCompanyV2DestinationMutation,
  useCompanyAttributesQuery,
  useCompanyListLazyQuery,
} from "../../../generated/settings-client";

export const AddCompanyForm: React.FC<{ open: boolean; isEdit: boolean }> = ({ open, isEdit }) => {
  const { t } = useTranslation();
  const { colors } = useTheme();
  const { values, errors, touched, submitForm, setFieldValue } = useSaferFormikContext<CompanyFormValues>();
  const { subscribe } = useWidgetContext();
  const site = useSiteContext();
  const debouncedValues = useDebounced(values, 500);
  const [getCompanies, { data: suggestions }] = useCompanyListLazyQuery();
  const [addMutation] = useAddCompanyV2DestinationMutation();
  const toast = useToast();
  const showError = useShowError();
  const modal = useContext(ModalContext);
  const { currentRole } = useContext(CurrentRole);
  const isPropertyManager = currentRole === Role.PropertyManager;

  const industries = useCompanyIndustries(!open);

  const { data: attributeData } = useCompanyAttributesQuery({
    variables: { siteUuid: site.uuid },
    skip: !open,
  });

  const suggestedCompanies = useMemo(() => {
    return suggestions?.companiesV2.edges.map((edge) => edge.node).filter(notNullOrUndefined) ?? [];
  }, [suggestions]);

  useEffect(() => {
    return subscribe("onSave", () => {
      void submitForm();
    });
  }, [submitForm, subscribe]);

  useEffect(() => {
    void getCompanies({ variables: { search: values.name, withBuildingLevels: false, withFlexTenants: false } });
  }, [debouncedValues.name]);

  const handleAdd = (uuid: string, name: string) => () => {
    addMutation({
      variables: { destination: site.uuid, companies: [uuid] },
      refetchQueries: ["CompanyList"],
    })
      .catch(showError)
      .finally(() => {
        toast.neutral(t("settings.companyAdded", { company: name }));
        modal.close();
      });
  };

  const getFieldError = (field: keyof typeof values) =>
    touched[field] === true || Array.isArray(touched[field]) ? errors[field]?.toString() : undefined;

  return (
    <div style={{ background: colors.white }} className="mb-4">
      <div className="main-container-inner">
        <div>
          <Form>
            <EqForm.Group
              required
              label={t("settings.createCompany.companyName")}
              error={getFieldError("name")}
              showTooltip
              tooltipText={t("settings.createCompany.companyNameTooltips")}
            >
              <Dropdown.Container
                placement="bottom-start"
                disabled={isEdit}
                trigger={<Field as={EqForm.Input} name="name" autoComplete="off" disabled={isEdit} />}
                width={100}
                sameWidth
                disableKeyboard
              >
                {values.name.length > 0 && suggestedCompanies.length > 0 ? (
                  <div className="suggestions-list">
                    <Text variant="label" className="title" component="p">
                      {t("settings.createCompany.searchHint")}
                    </Text>
                    {suggestedCompanies.map((node) => (
                      <div className="suggestion" key={node.uuid}>
                        <Text variant="text" weight="bold" component="span">
                          {node.name}
                        </Text>
                        <Button variant="outline" size="md" onClick={handleAdd(node.uuid, node.name)}>
                          {t("settings.addToSite")}
                        </Button>
                      </div>
                    ))}
                  </div>
                ) : null}
              </Dropdown.Container>
            </EqForm.Group>
            <EqForm.Group
              required
              label={t("settings.createCompany.industry")}
              showTooltip
              tooltipText={t("settings.createCompany.industryTooltips")}
              error={getFieldError("industry")}
            >
              <Field
                as={EqForm.DynamicSelect}
                disabled={isEdit}
                name="industry"
                mobileView="regular"
                title={t("settings.createCompany.industry")}
                noneLabel={<span>&nbsp;</span>}
                searchPlaceholder={t("settings.createCompany.searchIndustries")}
                search
                options={industries.map((industry) => {
                  return { value: industry.uuid, label: industry.name };
                })}
              />
            </EqForm.Group>

            {isPropertyManager && (
              <EqForm.Group
                label={t("settings.createCompany.attributes")}
                error={getFieldError("attributes")}
                showTooltip
                tooltipText={t("settings.createCompany.attributesTooltips")}
              >
                <Field
                  as={EqForm.MultiSelect}
                  name="attributes"
                  isMulti={true}
                  searchPlaceholder={t("settings.createCompany.searchAttributes")}
                  title={t("settings.createCompany.attributes")}
                  mobileView="regular"
                  className="attributes"
                  options={
                    attributeData?.destination.companyAttributes != null
                      ? attributeData.destination.companyAttributes.map((attribute) => {
                          return {
                            value: attribute.uuid,
                            label: attribute.name,
                          };
                        })
                      : []
                  }
                />
              </EqForm.Group>
            )}
            <hr className="mt-6 mb-6 divider" />
            <div>
              <h4 className="font-weight-bold">{t("settings.editCompany.autoApproveRegistrationsHeading")}</h4>
              <div className="pt-4 pb-3">
                <Text variant="label" color={colors.muted0}>
                  {t("settings.editCompany.autoApproveRegistrationsLabel")}
                </Text>
              </div>
              <EqForm.RadioButton
                name="autoApproveRegistrations"
                className="mb-3"
                disabled={values.autoApproveRegistrations == null}
                label={t("settings.editCompany.autoApproveRegistrationsNoLabel")}
                extraInfo={t("settings.editCompany.autoApproveRegistrationsNoExtraInfo")}
                checked={values.autoApproveRegistrations === false}
                onChange={() => {
                  setFieldValue("autoApproveRegistrations", false).catch(showError);
                }}
              />
              <EqForm.RadioButton
                name="autoApproveRegistrations"
                disabled={values.autoApproveRegistrations == null}
                label={t("settings.editCompany.autoApproveRegistrationsYesLabel")}
                extraInfo={t("settings.editCompany.autoApproveRegistrationsYesExtraInfo")}
                className="pb-3"
                checked={values.autoApproveRegistrations === true}
                onChange={() => {
                  setFieldValue("autoApproveRegistrations", true).catch(showError);
                }}
              />
            </div>
            {values.autoApproveRegistrations === true && (
              <>
                <Alert
                  size="large"
                  variant="gray"
                  icon={<RiInformationLine size={18} color={colors.grayscale[50]} />}
                  message={t("settings.createCompany.validationHint")}
                  className="mb-6"
                />
                <EqForm.Group
                  label={t("settings.createCompany.approvedDomains")}
                  error={getFieldError("validationValues")}
                  showTooltip
                  tooltipText={t("settings.createCompany.validationTooltips")}
                >
                  <Field
                    locked={isEdit}
                    tooltipText={t("settings.editCompany.valuesToolTip")}
                    errorMessage={t("settings.createCompany.validationValuesErrorMessage")}
                    as={FormList}
                    name="validationValues"
                    buttonText={`+ ${t("settings.createCompany.addDomain")}`}
                  />
                </EqForm.Group>
              </>
            )}
            <hr className="mt-6 mb-6 divider" />
            <Text variant="heading" size="small">
              {t("settings.levels")}
            </Text>
            <Text variant="text">{t("settings.levelsHint")}</Text>
            <LevelsList name="levels" />
          </Form>
        </div>
      </div>
      <style jsx>
        {`
          .main-container-inner {
            padding-right: 0px !important;
          }

          .divider {
            border: none;
            border-top: 1px solid ${colors.grayscale[10]};
          }

          .suggestions-list {
            width: 100%;
            padding: 8px 16px 0px;
          }

          .suggestions-list :global(.title) {
            margin: 0;
            color: ${colors.grayscale[60]};
          }

          .suggestion {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 8px 0;
          }

          .suggestion :global(button) {
            min-width: 100px;
          }

          .suggestion :global(span) {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        `}
      </style>
    </div>
  );
};
