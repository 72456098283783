import React from "react";

import { RiBankCard2Line } from "@equiem/react-admin-ui/icons";

import { CardBrand } from "../../../../generated/settings-client";

const images: Record<CardBrand, string | null> = {
  [CardBrand.Mastercard]: "/images/credit-card-brands/mastercard.png",
  [CardBrand.Visa]: "/images/credit-card-brands/visa.png",
  [CardBrand.AmericanExpress]: "/images/credit-card-brands/american_express.png",
  [CardBrand.DinersClub]: "/images/credit-card-brands/diners_club.png",
  [CardBrand.Discover]: "/images/credit-card-brands/discover.png",
  [CardBrand.Jcb]: "/images/credit-card-brands/jcb.png",
  [CardBrand.Unionpay]: "/images/credit-card-brands/unionpay.png",
  [CardBrand.Unknown]: null,
};

export const CreditCardBrand: React.FC<{ brand: CardBrand }> = ({ brand }) => {
  const brandImage = images[brand];

  return (
    <>
      {brandImage == null ? <RiBankCard2Line size="2em" /> : <img src={brandImage} />}
      <style jsx>{`
        img {
          height: 2em;
          vertical-align: middle;
        }
      `}</style>
    </>
  );
};
