import type { FC } from "react";
import React from "react";

import { useTranslation } from "@equiem/localisation-eq1";
import { Button, Card, Text, useConfirmer } from "@equiem/react-admin-ui";

import {
  type BillingCustomerFragmentFragment,
  CompanyDetailsDocument,
  useBillingCustomerDeleteMutation,
} from "../../../generated/settings-client";

interface Props {
  billingCustomer: BillingCustomerFragmentFragment;
  companyUuid: string;
  siteUuid: string;
}

export const BillingDetails: FC<Props> = ({ billingCustomer, companyUuid, siteUuid }) => {
  const [mutation] = useBillingCustomerDeleteMutation();
  const { t } = useTranslation();
  const { withConfirmation } = useConfirmer();

  const handleDelete = async () => {
    await mutation({
      variables: {
        input: {
          billingCustomerUuid: billingCustomer.id,
        },
      },
      refetchQueries: [
        {
          query: CompanyDetailsDocument,
          variables: {
            uuid: companyUuid,
            siteUuid,
          },
        },
      ],
    });
  };

  return (
    <>
      <Card.Card>
        <Card.CardBody>
          <div className="d-flex justify-content-between align-items-center">
            <div className="body">
              <Text variant="heading" size="small">
                {billingCustomer.address?.line1}
              </Text>
              {billingCustomer.address?.line2 != null && (
                <Text variant="text" size="small" className="mt-1 mb-0">
                  {billingCustomer.address.line2}
                </Text>
              )}
              <Text variant="text" size="small" className="mt-1 mb-0">
                {billingCustomer.address?.city} {billingCustomer.address?.state} {billingCustomer.address?.postalCode}{" "}
                {billingCustomer.address?.country}
              </Text>
              <Text variant="text" size="extra-small" className="mt-1">
                {billingCustomer.contactName} • {billingCustomer.email}{" "}
                {billingCustomer.phone != null ? `• ${billingCustomer.phone}` : ""}
              </Text>
            </div>
            <div>
              <Button
                variant="ghost"
                onClick={withConfirmation({
                  onConfirm: handleDelete,
                  title: t("common.areYouSure"),
                  message: t("common.billingDelete"),
                  confirmButtonVariant: "danger",
                  confirmButtonText: t("common.yesDelete"),
                })}
              >
                {t("common.delete")}
              </Button>
            </div>
          </div>
        </Card.CardBody>
      </Card.Card>
      <style jsx>{""}</style>
    </>
  );
};
