import React from "react";
import { Tooltip } from "@equiem/react-admin-ui";
import { RiInformationLine } from "@equiem/react-admin-ui/icons";
import { useTranslation } from "@equiem/localisation-eq1";

import { NotificationMethod } from "../state/NotificationMethod";

interface Props {
  notificationMethod?: NotificationMethod;
}

export const AudienceTooltip: React.FC<Props> = ({ notificationMethod }) => {
  const { t } = useTranslation();

  const title = () => {
    switch (notificationMethod) {
      case NotificationMethod.PUSH_NOTIFICATION:
        return t("segmentation.audienceTooltipPushNotification");
      case NotificationMethod.SMS:
        return t("segmentation.audienceTooltipSms");
      case NotificationMethod.EMAIL:
        return t("segmentation.audienceTooltipEmail");
      default:
        return t("segmentation.audienceTooltip");
    }
  };

  return (
    <>
      <Tooltip title={title()}>
        <span>
          <RiInformationLine size="16" className="ms-1" />
        </span>
      </Tooltip>
      <style jsx>{`
        span {
          cursor: pointer;
        }
      `}</style>
    </>
  );
};
