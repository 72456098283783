import React from "react";
import { StickyElement, Table, ProgressCircle } from "@equiem/react-admin-ui";
import { useTranslation } from "@equiem/localisation-eq1";

interface Props {
  loading: boolean;
  rowHeaders: string[];
  children?: React.ReactNode;
}

const Loader: React.FC<{ loading: boolean; colSpan: number }> = ({ loading, colSpan }) => {
  const { t } = useTranslation();
  if (!loading) {
    return null;
  }
  return (
    <tr>
      <td colSpan={colSpan}>
        <div className="mt-6 mb-6 d-flex justify-content-center align-items-center">
          <ProgressCircle size="md" />
          <span className="ml-4">{`${t("common.loading")}...`}</span>
        </div>
      </td>
    </tr>
  );
};

export const StickyHeaderTable: React.FC<Props> = ({ rowHeaders, loading, children }) => (
  <StickyElement<HTMLTableCellElement>>
    {({ ref, top, isStuck }) => (
      <Table.Table className="w-100" sticky={{ top, isStuck }}>
        <thead>
          <tr>
            {rowHeaders.map((label, i) => (
              <Table.Header key={i} ref={i === 0 ? ref : null} label={label} />
            ))}
          </tr>
        </thead>
        <tbody>
          {children}
          {loading && <Loader loading={loading} colSpan={rowHeaders.length} />}
        </tbody>
      </Table.Table>
    )}
  </StickyElement>
);
