import type { ReactNode } from "react";

export interface LabelElement {
  searchText: string;
  element: Exclude<ReactNode, Promise<unknown>>;
  facadeElement?: Exclude<ReactNode, Promise<unknown>>;
}

export interface Option {
  label: string | LabelElement;
  value: string;
  indeterminate?: boolean;
  disabled?: boolean;
  group?: { name: string; weight: number };
}

export const optionLabel = (option?: Option, showFacade = false) => {
  if (option == null) {
    return "";
  }
  if (typeof option.label === "string") {
    return option.label;
  }

  if (!showFacade || option.label.facadeElement == null) {
    return option.label.element;
  }

  return option.label.facadeElement;
};

export const optionSearchText = (option?: Option) => {
  if (option == null) {
    return "";
  }
  return typeof option.label === "string" ? option.label : option.label.searchText;
};
