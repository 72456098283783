import type { FC } from "react";
import { useContext, useMemo } from "react";
import type { Viewer } from "@equiem/lib";
import { CurrentProfile } from "@equiem/lib";
import { NewInvitesProvider } from "./NewInvitesProvider";
import { InviteModalInner } from "./InviteModalInner";

interface Props {
  siteUuid: string;
  viewer: Viewer;
}

export const InviteModal: FC<Props> = ({ siteUuid, viewer }) => {
  const { canManageCurrentSite, canManageFlexTenants } = useContext(CurrentProfile);
  const company = useMemo(
    () => (!canManageCurrentSite && !canManageFlexTenants ? viewer.company?.uuid : undefined),
    [viewer, canManageCurrentSite, canManageFlexTenants],
  );

  return (
    <NewInvitesProvider
      siteUuid={siteUuid}
      viewerFirstName={viewer.firstName ?? ""}
      viewerLastName={viewer.lastName ?? ""}
      companyUuid={company}
    >
      <InviteModalInner />
    </NewInvitesProvider>
  );
};
