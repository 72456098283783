import React from "react";
import { useFormikContext } from "formik";
import {
  BookingInvoiceContactTypeInput,
  type BillingCustomerFragmentFragment,
} from "../../../../generated/gateway-client";
import type { BookingFormValue } from "../../models/BookingFormValue";
import { Form, useTheme } from "@equiem/react-admin-ui";

interface P {
  billingCustomer: BillingCustomerFragmentFragment;
}
export const BillingCustomerLabel: React.FC<P> = ({ billingCustomer }) => {
  return (
    <>
      <div className="address">
        <strong>{billingCustomer.address?.line1}</strong>
        <div>{billingCustomer.address?.line2}</div>
        <div>
          {billingCustomer.address?.city} {billingCustomer.address?.state} {billingCustomer.address?.postalCode}{" "}
          {billingCustomer.address?.country}
        </div>
      </div>
      <div className="d-flex">
        <small className="muted">
          {billingCustomer.contactName} • {billingCustomer.email}{" "}
          {billingCustomer.phone != null ? `• ${billingCustomer.phone}` : ""}
        </small>
      </div>
    </>
  );
};

export const BookingPaymentBillingCustomer: React.FC<P> = ({ billingCustomer }) => {
  const fm = useFormikContext<BookingFormValue>();
  const contact = fm.values.contact;
  const theme = useTheme();

  return (
    <>
      <div className="invoice">
        <Form.RadioButton
          name="person"
          id={billingCustomer.id}
          checked={
            contact != null &&
            contact.type === BookingInvoiceContactTypeInput.BillingCustomer &&
            contact.billingCustomerId === billingCustomer.id
          }
          label={<BillingCustomerLabel billingCustomer={billingCustomer} />}
          onClick={() => {
            const isSelected =
              contact != null &&
              contact.type === BookingInvoiceContactTypeInput.BillingCustomer &&
              contact.billingCustomerId === billingCustomer.id;

            if (isSelected) {
              void fm.setFieldValue("contact", undefined);
            } else {
              void fm.setFieldValue("contact", {
                type: BookingInvoiceContactTypeInput.BillingCustomer,
                billingCustomerId: billingCustomer.id,
              });
            }
          }}
          onChange={() => {
            // Do nothing to remove the warning.
          }}
        />
      </div>
      <style jsx>{`
        div {
          border: 1px solid ${theme.colors.border};
          border-radius: ${theme.borderRadius};
          padding: ${theme.spacers.s4};
          margin: 0 0 ${theme.spacers.s3};
          display: flex;
          align-items: center;
        }
        div :global(.checkbox) {
          width: 100%;
        }
        div :global(.form-check-label) {
          display: block;
        }
      `}</style>
    </>
  );
};
