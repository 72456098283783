import type { ActivitiesQueryVariables } from "@equiem/lib";
import { ActivitySortField } from "../../../generated/gateway-client";

export const activityQueryInputVariables = (entityUuid: string): ActivitiesQueryVariables => {
  return {
    entityUuid,
    first: 25,
    sort: {
      asc: false,
      field: ActivitySortField.Timestamp,
    },
  };
};
