import React from "react";
import { Button, Form as EqForm, Modal, Toggler, useTheme } from "@equiem/react-admin-ui";
import type { CookieSettings } from "../../hooks";
import { useTranslation } from "@equiem/localisation-eq1";
import { Field, Form, Formik } from "formik";
import * as yup from "yup";
import { CookiePolicy } from "./CookiePolicy";

interface Props {
  context: CookieSettings;
  onSubmitComplete: () => void;
}

const validationSchema = () =>
  yup.object().shape({
    requiredCookies: yup.boolean(),
    optionalCookies: yup.boolean(),
  });

export const CookiePreferencesForm: React.FC<Props> = ({ context, onSubmitComplete }) => {
  const { optionalCookiesEnabled, setOptionalCookiesEnabled, setCookiePreferencesSaved } = context;
  const { spacers, colors } = useTheme();
  const { t } = useTranslation();

  const initialValues = {
    requiredCookies: true,
    optionalCookies: optionalCookiesEnabled,
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema()}
        onSubmit={(values, actions) => {
          actions.setSubmitting(true);
          setOptionalCookiesEnabled(values.optionalCookies);
          setCookiePreferencesSaved();
          actions.setSubmitting(false);
          onSubmitComplete();
        }}
      >
        {({ submitForm, values }) => (
          <Form>
            <div className="container">
              <Modal.Body>
                <h1>{t("home.cookiePreference.thisSiteUsesCookies")}</h1>
                <p className="mt-0 mb-6">
                  {t("home.cookiePreference.weUseCookies")}
                  <Toggler className="mt-6" heading={t("home.cookiePreference.cookiePolicy")} defaultOpen={false}>
                    <CookiePolicy />
                  </Toggler>
                </p>
                <div className="brake-line" />
                <EqForm.Group className="mb-0">
                  <label htmlFor="requiredCookies">
                    <Field
                      type="checkbox"
                      id="requiredCookies"
                      name="requiredCookies"
                      disabled={true}
                      label={t("home.cookiePreference.strictlyNecessaryCookies")}
                      className="mt-5"
                    />
                    <span className="ml-3">{t("home.cookiePreference.strictlyNecessaryCookies")}</span>
                  </label>
                  <p className="sub-text ml-7 mr-2 mt-3">{t("home.cookiePreference.theseCookiesAreRequired")}</p>
                  <div className="brake-line" />
                  <label htmlFor="optionalCookies">
                    <Field
                      type="checkbox"
                      id="optionalCookies"
                      name="optionalCookies"
                      label={t("home.cookiePreference.optionalCookies")}
                      className="mt-5"
                    />
                    <span className="ml-3">{t("home.cookiePreference.optionalCookies")}</span>
                  </label>
                  <p className="sub-text ml-7 mr-2 mt-3">{t("home.cookiePreference.theseCookiesUsedTo")}</p>
                  <div className="brake-line" />
                </EqForm.Group>
              </Modal.Body>
            </div>
            <Modal.Footer>
              <Button
                type="submit"
                size="sm"
                className="mr-2"
                onSubmit={() => {
                  void submitForm();
                }}
              >
                {values.optionalCookies
                  ? t("home.cookiePreference.acceptAllCookies")
                  : t("home.cookiePreference.acceptNecessaryCookies")}
              </Button>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
      <style jsx>{`
        .container {
          max-height: 400px;
          overflow-y: auto;
        }
        h1 {
          font-weight: 700;
          margin-bottom: ${spacers.s3};
        }
        label {
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
        }
        .sub-text {
          font-size: 14px;
        }
        .brake-line {
          border-top: 1px solid ${colors.grayscale[10]};
        }
        :global(h4) {
          font-size: 14px;
          line-height: 30px;
        }
      `}</style>
    </>
  );
};
