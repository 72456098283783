import React from "react";
import type { CardFragmentFragment } from "../../generated/gateway-client";
import { CreditCardBrand } from "./CreditCardBrand";
import { useTranslation } from "@equiem/localisation-eq1";
import { useTheme } from "@equiem/react-admin-ui";

interface P {
  card: CardFragmentFragment;
  prefix?: React.ReactNode;
  suffix?: React.ReactNode;
}
export const CardDisplay: React.FC<P> = ({ card, prefix, suffix }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <>
      <div className="card-block">
        <div className="card-block-info">
          {prefix}
          <CreditCardBrand brand={card.brand} />
          <div>
            <b>{card.description ?? t("equiem-one-ui.creditcard.untitled")}</b>
            <br />
            <span>**** **** **** {card.last4}</span>
          </div>
        </div>
        {suffix}
      </div>
      <style jsx>{`
        .card-block {
          border: 1px solid ${theme.colors.border};
          border-radius: ${theme.borderRadius};
          padding: ${theme.spacers.s3};
          display: flex;
          justify-content: space-between;
          width: 100%;
        }
        .card-block-info {
          display: flex;
          gap: ${theme.spacers.s3};
          align-items: center;
        }
      `}</style>
    </>
  );
};
