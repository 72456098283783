import React, { useCallback, useContext, useEffect } from "react";
import { Field, Form } from "formik";

import { useSaferFormikContext } from "@equiem/lib";
import { Trans, useTranslation } from "@equiem/localisation-eq1";
import { Alert, Form as EqForm, Text, Toggler, useTheme } from "@equiem/react-admin-ui";
import { RiErrorWarningLine } from "@equiem/react-admin-ui/icons";

import { useWidgetContext } from "../../../contexts/WidgetContext";
import { CurrentUserRoleContext } from "../contexts/CurrentUserRoleContext";
import { useBookingNotificationPreferencesDisplay } from "../hooks/useBookingNotificationPreferencesDisplay";
import type { FormValues } from "../types";

import { UserNotificationFieldTooltipWrapper } from "./UserNotificationFieldTooltipWrapper";

export const UserNotifications: React.FC<{
  adminView: boolean;
  showBookingNotificationPreferences: boolean;
  showApprovalNotificationsPreferences: boolean;
}> = ({ showBookingNotificationPreferences, showApprovalNotificationsPreferences }) => {
  const { t, i18n } = useTranslation();
  const { submitForm, setFieldValue, values } = useSaferFormikContext<FormValues>();
  const { subscribe } = useWidgetContext();
  const { spacers, colors } = useTheme();
  const currentRoleContext = useContext(CurrentUserRoleContext);
  const { allDeselected, allSelected, showAdminPreferences, Components, isAdminPreferenceDisplayed } =
    useBookingNotificationPreferencesDisplay(currentRoleContext, values);

  const selectAllFn = useCallback(
    (checked: boolean) => {
      setFieldValue("notificationPreferences", {
        // If admin options are eligible to be shown, let them be changed.
        // If not, preserve their values when Select all option is picked.
        adminApprovalRequestEmail: isAdminPreferenceDisplayed("adminApprovalRequestEmail")
          ? checked
          : values.notificationPreferences.adminApprovalRequestEmail,
        adminConfirmationEmail: isAdminPreferenceDisplayed("adminConfirmationEmail")
          ? checked
          : values.notificationPreferences.adminConfirmationEmail,
        adminUpdateEmail: isAdminPreferenceDisplayed("adminUpdateEmail")
          ? checked
          : values.notificationPreferences.adminUpdateEmail,
        adminCancellationEmail: isAdminPreferenceDisplayed("adminCancellationEmail")
          ? checked
          : values.notificationPreferences.adminCancellationEmail,
        adminDeclinedBookingEmail: isAdminPreferenceDisplayed("adminDeclinedBookingEmail")
          ? checked
          : values.notificationPreferences.adminDeclinedBookingEmail,
        endUserAwaitingApprovalEmail: checked,
        endUserCancellationEmail: checked,
        endUserChargedAdjustmentEmail: checked,
        endUserConfirmationEmail: checked,
        endUserDeclinedBookingEmail: checked,
        endUserReminderEmail: checked,
        endUserUpdateEmail: checked,
      }).catch(() => {
        console.error("Failed to select all options");
      });
    },
    [
      setFieldValue,
      isAdminPreferenceDisplayed,
      values.notificationPreferences.adminApprovalRequestEmail,
      values.notificationPreferences.adminConfirmationEmail,
      values.notificationPreferences.adminUpdateEmail,
      values.notificationPreferences.adminCancellationEmail,
      values.notificationPreferences.adminDeclinedBookingEmail,
    ],
  );

  useEffect(() => {
    return subscribe("onSave", () => {
      void submitForm();
    });
  }, [submitForm, subscribe]);

  return (
    <>
      <Text variant="heading" className="mb-6">
        {t("settings.notifications.title")}
      </Text>
      <div className="settings-container">
        <Toggler heading={t("settings.notifications.general")} defaultOpen chevronButtonPosition="right">
          <Form className="form">
            {(values.subscribedToEmails === false || values.subscribedToNotifications === false) && (
              <Alert
                size="large"
                variant="gray"
                icon={<RiErrorWarningLine size={18} color={colors.grayscale[50]} />}
                message={t("settings.notifications.toolTips.general.warning")}
              />
            )}
            <UserNotificationFieldTooltipWrapper title={t("settings.notifications.toolTips.general.pushNotifications")}>
              <Field
                as={EqForm.Checkbox}
                name="subscribedToNotifications"
                label={t("settings.notifications.pushNotifications")}
              />
            </UserNotificationFieldTooltipWrapper>
            <UserNotificationFieldTooltipWrapper title={t("settings.notifications.toolTips.general.emails")}>
              <Field as={EqForm.Checkbox} name="subscribedToEmails" label={t("common.emails")} />
            </UserNotificationFieldTooltipWrapper>
          </Form>
        </Toggler>
        {showBookingNotificationPreferences && (
          <Toggler
            className="booking-toggle"
            heading={t("settings.notifications.bookings")}
            defaultOpen
            chevronButtonPosition="right"
          >
            <Form className="form">
              <Alert
                size="large"
                variant="gray"
                icon={<RiErrorWarningLine size={18} color={colors.grayscale[50]} />}
                message={t("settings.notifications.allSites")}
              />
              <EqForm.Checkbox
                className="select-all-button"
                label={t("settings.notifications.bookingPreferences.selectAll")}
                value={allSelected}
                onChange={(e) => selectAllFn(e.target.checked)}
                indeterminate={!allDeselected && !allSelected}
              />
              {showAdminPreferences && (
                <div className="admin-preferences">
                  <UserNotificationFieldTooltipWrapper
                    title={t("settings.notifications.toolTips.bookings.admin.header")}
                  >
                    <EqForm.Label>{t("settings.notifications.bookingPreferences.admin")}</EqForm.Label>
                  </UserNotificationFieldTooltipWrapper>
                  {Components(t)}
                </div>
              )}
              <div className="end-user-preferences">
                <UserNotificationFieldTooltipWrapper
                  title={t("settings.notifications.toolTips.bookings.endUser.header")}
                >
                  <EqForm.Label>{t("settings.notifications.bookingPreferences.endUser")}</EqForm.Label>
                </UserNotificationFieldTooltipWrapper>
                <UserNotificationFieldTooltipWrapper
                  title={t("settings.notifications.toolTips.bookings.endUser.confirmation")}
                >
                  <Field
                    className="booking-preference-checkbox"
                    as={EqForm.Checkbox}
                    name="notificationPreferences.endUserConfirmationEmail"
                    label={t("settings.notifications.bookingPreferences.endUserConfirmationEmail")}
                  />
                </UserNotificationFieldTooltipWrapper>
                <UserNotificationFieldTooltipWrapper
                  title={t("settings.notifications.toolTips.bookings.endUser.update")}
                >
                  <Field
                    className="booking-preference-checkbox"
                    as={EqForm.Checkbox}
                    name="notificationPreferences.endUserUpdateEmail"
                    label={t("settings.notifications.bookingPreferences.endUserUpdateEmail")}
                  />
                </UserNotificationFieldTooltipWrapper>
                <UserNotificationFieldTooltipWrapper
                  title={t("settings.notifications.toolTips.bookings.endUser.cancellation")}
                >
                  <Field
                    className="booking-preference-checkbox"
                    as={EqForm.Checkbox}
                    name="notificationPreferences.endUserCancellationEmail"
                    label={t("settings.notifications.bookingPreferences.endUserCancellationEmail")}
                  />
                </UserNotificationFieldTooltipWrapper>
                <UserNotificationFieldTooltipWrapper
                  title={t("settings.notifications.toolTips.bookings.endUser.reminder")}
                >
                  <Field
                    className="booking-preference-checkbox"
                    as={EqForm.Checkbox}
                    name="notificationPreferences.endUserReminderEmail"
                    label={t("settings.notifications.bookingPreferences.endUserReminderEmail")}
                  />
                </UserNotificationFieldTooltipWrapper>
                <UserNotificationFieldTooltipWrapper
                  title={t("settings.notifications.toolTips.bookings.endUser.awaitingApproval")}
                >
                  <Field
                    className="booking-preference-checkbox"
                    as={EqForm.Checkbox}
                    name="notificationPreferences.endUserAwaitingApprovalEmail"
                    label={t("settings.notifications.bookingPreferences.endUserAwaitingApprovalEmail")}
                  />
                </UserNotificationFieldTooltipWrapper>
                <UserNotificationFieldTooltipWrapper
                  title={t("settings.notifications.toolTips.bookings.endUser.decline")}
                >
                  <Field
                    className="booking-preference-checkbox"
                    as={EqForm.Checkbox}
                    name="notificationPreferences.endUserDeclinedBookingEmail"
                    label={t("settings.notifications.bookingPreferences.endUserDeclinedBookingEmail")}
                  />
                </UserNotificationFieldTooltipWrapper>
                <UserNotificationFieldTooltipWrapper
                  title={t("settings.notifications.toolTips.bookings.endUser.chargeAdjustment")}
                >
                  <Field
                    className="booking-preference-checkbox"
                    as={EqForm.Checkbox}
                    name="notificationPreferences.endUserChargedAdjustmentEmail"
                    label={t("settings.notifications.bookingPreferences.endUserChargedAdjustmentEmail")}
                  />
                </UserNotificationFieldTooltipWrapper>
              </div>
            </Form>
          </Toggler>
        )}
        {showApprovalNotificationsPreferences && (
          <Toggler
            className="approval-toggle"
            heading={t("settings.notifications.userAccounts")}
            defaultOpen
            chevronButtonPosition="right"
          >
            <Form className="form">
              <Alert
                size="large"
                variant="gray"
                icon={<RiErrorWarningLine size={18} color={colors.grayscale[50]} />}
                message={
                  <span>
                    <Trans
                      t={t}
                      i18n={i18n}
                      i18nKey="settings.notifications.toolTips.userAccounts.awaitingApprovalInfo"
                      components={[
                        <a
                          key="link"
                          href="https://help.getequiem.com/space/CS/1330741622/Updating+User+Notification+Preferences"
                          target="_blank"
                          rel="noreferrer"
                          className="link"
                        />,
                      ]}
                    />
                  </span>
                }
              />
              <UserNotificationFieldTooltipWrapper
                title={t("settings.notifications.toolTips.userAccounts.awaitingApproval")}
              >
                <Field
                  as={EqForm.Checkbox}
                  name="approvalNotificationsPreferences.awaitingApproval"
                  label={t("settings.notifications.userApprovals")}
                />
              </UserNotificationFieldTooltipWrapper>
            </Form>
          </Toggler>
        )}
      </div>
      <style jsx>{`
        .settings-container :global(.form) {
          padding: ${spacers.s3};
          display: flex;
          flex-direction: column;
          gap: ${spacers.s5};
        }

        .settings-container :global(.form) :global(label) {
          gap: ${spacers.s3};
        }

        .settings-container :global(.toggler) {
          margin-bottom: ${spacers.s4};
        }

        .settings-container :global(.form-group) {
          margin-bottom: ${spacers.s3};
        }

        .settings-container :global(.booking-preference-checkbox) {
          padding-bottom: ${spacers.s4};
        }

        .settings-container :global(.select-all-button) {
          border-bottom: 1px solid ${colors.border};
          padding-bottom: ${spacers.s5};
        }
        .link {
          text-decoration: underline;
        }
      `}</style>
    </>
  );
};
