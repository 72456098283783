import type { FC, CSSProperties } from "react";
import React from "react";
import dynamic from "next/dynamic";

interface Props {
  value: string;
  height?: CSSProperties["height"];
}

const DEFAULT_HEIGHT = 200;

function formatHeight(height: string | number): string {
  return typeof height === "number" ? `${height}px` : height;
}

const ReadonlyCkEditor = dynamic(async () => import("./ReadonlyCkEditor"), { ssr: false });

export const ReadonlyRichTextBox: FC<Props> = ({ value, height = DEFAULT_HEIGHT }) => {
  const formattedHeight = formatHeight(height);
  return (
    <>
      <div className="ck-editor-wrapper">
        <ReadonlyCkEditor value={value} height={formattedHeight} />
      </div>
      <style jsx>{`
        .ck-editor-wrapper {
          height: calc(${formattedHeight} + 1px);
        }
      `}</style>
    </>
  );
};
