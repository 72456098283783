import React from "react";

import { FormInputXFix } from "./FormInputXFix";
import type { Props } from "./FormInputXFix";

export const FormInputPrefix = React.forwardRef<HTMLDivElement, Omit<Props, "type">>((props, ref) => (
  <FormInputXFix type="prefix" {...props} ref={ref} />
));

FormInputPrefix.displayName = "FormInputPrefix";
