const getDefaultEquiemOneHost = () => {
  const defaultHost = process.env.defaultEquiemOneHost;
  if (defaultHost == null) {
    throw new Error("Set env variable defaultEquiemOneHost");
  }

  return defaultHost;
};

const getLegacyHosts = () => {
  const legacyHost = process.env.legacyEquiemOneHosts;
  if (legacyHost == null) {
    throw new Error("Set env variable legacyEquiemOneHosts");
  }

  return legacyHost.split(",");
};

const getReviewAppHost = () => {
  return process.env.reviewAppHost ?? null;
};

export const getHostInfo = (currentHostName: string) => {
  const defaultHost = getDefaultEquiemOneHost();
  const legacyHosts = getLegacyHosts();
  const reviewAppHost = getReviewAppHost();

  const hostUrl = `https://${currentHostName}`;
  const currentHostUrl = new URL(hostUrl);

  const matchesWithDefaultHost = currentHostUrl.hostname === defaultHost;

  const matchesWithLegacyUrl = legacyHosts
    .map((legacyHost) => new URL(`https://${legacyHost}`))
    .some((legacyHost) => legacyHost.hostname === currentHostUrl.hostname);

  const matchesWithReviewAppHost = reviewAppHost != null && currentHostUrl.hostname.endsWith(reviewAppHost);
  const inSiteMode = !(matchesWithDefaultHost || matchesWithLegacyUrl || matchesWithReviewAppHost);

  return {
    appLoadedByDefaultUrl: matchesWithDefaultHost,
    appLoadedByLegacyUrl: matchesWithLegacyUrl || matchesWithReviewAppHost,
    inSiteMode,
    hostUrl,
    defaultHost,
  };
};
