import { stringNotEmpty, useSiteContext } from "@equiem/lib";
import type { i18n } from "@equiem/localisation-eq1";
import { useTranslation } from "@equiem/localisation-eq1";
import {
  Skeleton,
  Table,
  DateTime as DateTimeUi,
  ProgressCircle,
  useToast,
  useTheme,
  Tooltip,
  useConfirmer,
  IconButton,
  Tag,
} from "@equiem/react-admin-ui";
import { RiDeleteBin6Line, RiEditLine } from "@equiem/react-admin-ui/icons";
import { DateTime } from "luxon";
import React, { useCallback, useContext } from "react";

import { useDeleteBookingBlockOutMutation } from "../../../../generated/gateway-client";
import { BlockoutContext } from "./BlockoutProvider";

interface TableContent {
  name: string;
  periodDisplay: React.ReactNode;
  resourceNames: string[];
  bundle: string;
  startDate: DateTime;
  endDate: DateTime;
  resourceUuids: string[];
}

const formatPeriod = (timezone: string, start: number, end: number, i18n: i18n) => {
  const startDt = DateTime.fromMillis(start, { zone: timezone });
  const endDt = DateTime.fromMillis(end, { zone: timezone });
  const isSameDay = startDt.hasSame(endDt, "day");

  return (
    <>
      <DateTimeUi.DateDisplay language={i18n.language} datetime={start} timezone={timezone} />
      {startDt.toFormat("HH:mm") !== "00:00" && (
        <>
          @ <DateTimeUi.TimeDisplay language={i18n.language} datetime={start} timezone={timezone} />
        </>
      )}{" "}
      {!isSameDay && (
        <>
          - <DateTimeUi.DateDisplay language={i18n.language} datetime={end} timezone={timezone} />
        </>
      )}
      {endDt.toFormat("HH:mm") !== "23:59" && (
        <>
          {isSameDay ? "-" : "@"} <DateTimeUi.TimeDisplay language={i18n.language} datetime={end} timezone={timezone} />
        </>
      )}
    </>
  );
};

const BlockTd: React.FC<{ block: TableContent }> = ({ block }) => {
  const { t } = useTranslation();
  const toast = useToast();
  const theme = useTheme();
  const { edit, refetchBlockOuts } = useContext(BlockoutContext);
  const [mutation, { loading: deleting }] = useDeleteBookingBlockOutMutation();
  const { withConfirmation } = useConfirmer();

  const onDelete = async (bundle: string) => {
    const result = await mutation({ variables: { bundle } });
    if (result.data?.deleteBookingBlockOut.success === false) {
      toast.negative(result.data.deleteBookingBlockOut.message);
    } else {
      toast.positive(t("bookings.settings.blockOutDateDeleted"));
      refetchBlockOuts().catch(console.error);
    }
  };

  const onEdit = useCallback(() => {
    const startTime = block.startDate.toFormat("HH:mm");
    const endTime = block.endDate.toFormat("HH:mm");

    edit({
      title: block.name,
      endDate: block.endDate.toFormat("yyyy-LL-dd"),
      endTime: endTime === "23:59" ? "" : endTime,
      startDate: block.startDate.toFormat("yyyy-LL-dd"),
      startTime: startTime === "00:00" ? "" : startTime,
      resources: block.resourceUuids,
      bundle: block.bundle,
    });
  }, [block.bundle, block.endDate, block.name, block.resourceUuids, block.startDate, edit]);

  return (
    <tr key={block.bundle}>
      <td>{block.name}</td>
      <td>{block.periodDisplay}</td>
      <td style={{ maxWidth: "500px" }}>
        <div className="d-flex flex-wrap" style={{ gap: theme.spacers.s3 }}>
          {block.resourceNames.map((r, i) => (
            <Tag size="large" key={i}>
              {r}
            </Tag>
          ))}
        </div>
      </td>
      <td style={{ minWidth: "70px" }}>
        <div className="d-flex">
          <IconButton
            onClick={onEdit}
            disabled={deleting}
            className="edit"
            hover={{ background: theme.colors.grayscale_5 }}
          >
            {deleting ? (
              <ProgressCircle size={16} />
            ) : (
              <Tooltip title={t("common.edit")}>
                <span>
                  <RiEditLine size={16} />
                </span>
              </Tooltip>
            )}
          </IconButton>

          <IconButton
            onClick={withConfirmation({
              onConfirm: () => {
                onDelete(block.bundle).catch(console.log);
              },
              title: t("bookings.settings.blockOutDelete"),
              message: t("common.deleteConfirmationWithTitle", { title: block.name }),
              confirmButtonText: t("common.yesDelete"),
              confirmButtonVariant: "danger",
            })}
            disabled={deleting}
            className="delete"
            hover={{ background: theme.colors.status.danger.accent }}
          >
            {deleting ? (
              <ProgressCircle size={16} />
            ) : (
              <Tooltip title={t("common.delete")} placement="bottom">
                <span>
                  <RiDeleteBin6Line size={16} color={theme.colors.danger} />
                </span>
              </Tooltip>
            )}
          </IconButton>
        </div>
      </td>
    </tr>
  );
};

export const List: React.FC<{ className?: string }> = ({ className }) => {
  const { t, i18n } = useTranslation();
  const { timezone } = useSiteContext();
  const { bookingBlockOuts, loading, error } = useContext(BlockoutContext);

  if (loading) {
    return (
      <div>
        <Skeleton.Line height="20px" width="200px" />
      </div>
    );
  }
  if (error != null) {
    return <div>{error.message}</div>;
  }

  const blocks = Object.values(
    bookingBlockOuts.reduce<Record<string, undefined | TableContent>>((prev, block) => {
      const resourceName = block.resource?.name;
      const prevResources = prev[block.bundle]?.resourceNames ?? [];
      const resourceUuid = block.resource?.uuid;
      const prevResourceUuids = prev[block.bundle]?.resourceUuids ?? [];

      return {
        ...prev,
        [block.bundle]: {
          bundle: block.bundle,
          name: block.name,
          periodDisplay: formatPeriod(timezone, block.startDate, block.endDate, i18n),
          resourceNames: stringNotEmpty(resourceName) ? prevResources.concat(resourceName) : prevResources,
          startDate: DateTime.fromMillis(block.startDate, { zone: timezone }),
          endDate: DateTime.fromMillis(block.endDate, { zone: timezone }),
          resourceUuids: stringNotEmpty(resourceUuid) ? prevResourceUuids.concat(resourceUuid) : prevResourceUuids,
        },
      };
    }, {}),
  ).flatMap((block) =>
    block == null
      ? []
      : [
          {
            ...block,
            resourceNames: block.resourceNames.sort((a, b) => a.localeCompare(b)),
          },
        ],
  );

  return (
    <Table.Table className={`w-100 ${className}`}>
      <thead className="table-head">
        <tr>
          <Table.Header label={t("bookings.settings.name")} />
          <Table.Header label={t("bookings.settings.period")} />
          <Table.Header label={t("bookings.settings.resources")} />
          <Table.Header label="" />
        </tr>
      </thead>
      <tbody>
        {blocks.length === 0 && (
          <tr>
            <td colSpan={4}>{t("common.emptyNone")}</td>
          </tr>
        )}
        {blocks.map((block) => (
          <BlockTd key={block.bundle} block={block} />
        ))}
      </tbody>
    </Table.Table>
  );
};
