import type { FC } from "react";
import { useEffect, useMemo } from "react";
import { useAdminNotificationsQuery } from "../../generated/gateway-client";
import { EmptyState } from "./EmptyState";
import { LoadingState } from "./LoadingState";
import { Notification } from "./Notification";

interface Props {
  clearedAllNotifications: boolean;
  setHasNotifications: (_state: boolean) => void;
}

export const NotificationsInner: FC<Props> = ({ clearedAllNotifications, setHasNotifications }) => {
  const { data, loading, refetch } = useAdminNotificationsQuery({
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if ((data?.adminNotifications.edges.length ?? 0) > 0) {
      setHasNotifications(true);
    }
  }, [data?.adminNotifications.edges, setHasNotifications]);

  const showEmptyState = useMemo(
    () => clearedAllNotifications || (!loading && data?.adminNotifications.totalCount === 0),
    [clearedAllNotifications, loading, data?.adminNotifications],
  );
  const showLoadingState = useMemo(() => !clearedAllNotifications && loading, [loading, clearedAllNotifications]);
  const showNotificatons = useMemo(
    () =>
      !clearedAllNotifications && data?.adminNotifications.edges != null && data.adminNotifications.edges.length > 0,
    [clearedAllNotifications, data?.adminNotifications],
  );

  return (
    <div>
      {showEmptyState && <EmptyState />}
      {showLoadingState && <LoadingState />}
      {showNotificatons &&
        data?.adminNotifications.edges.map((edge) => {
          if (edge?.node != null) {
            return (
              <Notification
                key={edge.cursor}
                uuid={edge.node.uuid}
                title={edge.node.title}
                read={edge.node.read}
                url={edge.node.url}
                created={edge.node.created}
                avatar={edge.node.from?.avatar}
                firstName={edge.node.from?.firstName}
                lastName={edge.node.from?.lastName}
                linkToWidget={edge.node.linkToWidget}
                reload={refetch}
              />
            );
          }
          return null;
        })}
    </div>
  );
};
