import { useTheme } from "@equiem/react-admin-ui";
import { EmptyIcon } from "./EmptyIcon";
import { useTranslation } from "@equiem/localisation-eq1";

export const EmptyState = () => {
  const { colors, spacers } = useTheme();
  const { t } = useTranslation();

  return (
    <div className="container">
      <EmptyIcon />
      <p>{t("home.youHaveNoNotifications")}</p>
      <style jsx>{`
        .container {
          cursor: default;
          text-align: center;
          padding: ${spacers.s6};
          font-size: 12px;
          font-weight: 400;
          line-height: 16px;
          color: ${colors.medium};
        }
      `}</style>
    </div>
  );
};
