import { CKEditorNoSSR, CurrentProfile, ErrorBoundary, stringNotEmpty, useSiteContext } from "@equiem/lib";
import { formatters, useTranslation } from "@equiem/localisation-eq1";
import { Form as EqForm, remainingCharacters, useTheme } from "@equiem/react-admin-ui";
import { Field, Form, Formik, type FormikHelpers } from "formik";
import type { FC } from "react";
import React, { useContext, useMemo, useState } from "react";
import { ArticleContext } from "../../contexts/ArticleContext";
import { useCreateArticleMutation, useUpdateArticleMutation } from "../../generated/gateway-client";
import { FileUploader } from "../shared/FileUploader";
import { ImageUploader } from "../shared/ImageUploader";
import { ArticleFormBottomBar } from "./ArticleFormBottomBar";
import { ArticleFormTopBar } from "./ArticleFormTopBar";
import { ArticlePreview } from "./ArticlePreview";

interface Props {
  portfolioUuid: string;
  allowAutosave?: boolean;
}

type LocalFiles = Array<{
  filename?: string;
  value?: {
    filename: string;
  };
  key?: string;
  temporaryUuid: string;
}> | null;

export const ArticleForm: FC<Props> = ({ portfolioUuid, allowAutosave = true }) => {
  const { article, articleFormRef, setArticle } = useContext(ArticleContext);
  const { profile } = useContext(CurrentProfile);
  const { timezone } = useSiteContext();
  const { colors, breakpoints } = useTheme();
  const [focused, setFocused] = useState(false);
  const [uploading, setUploading] = useState(false);

  const [createMutation] = useCreateArticleMutation();
  const [updateMutation] = useUpdateArticleMutation();
  const { t, i18n } = useTranslation();

  const initialValues = useMemo(() => {
    return {
      title: article?.draftContent.title,
      excerpt: article?.draftContent.excerpt,
      body: article?.draftContent.body,
      images: article?.draftContent.images as LocalFiles,
      downloads: article?.draftContent.downloads as LocalFiles,
    };
  }, [article]);

  const defaultTitle = () => {
    const name = profile?.displayName ?? "Unknown";
    const date = formatters.dateshort(Date.now(), i18n.language, { timezone });
    const time = formatters.timeshort(Date.now(), i18n.language, { timezone });
    return `Draft - [${name}] - [${date} ${time}]`;
  };

  const valueToInput = (values: typeof initialValues) => {
    return {
      ...values,
      title: stringNotEmpty(values.title) ? values.title : defaultTitle(),
      images: values.images?.map((file) => ({
        key: file.key,
        temporaryUuid: file.temporaryUuid,
        filename: file.value != null ? file.value.filename : file.filename!,
      })),
      downloads: values.downloads?.map((file) => ({
        key: file.key,
        temporaryUuid: file.temporaryUuid,
        filename: file.value != null ? file.value.filename : file.filename!,
      })),
      authorName: profile?.displayName,
    };
  };

  const handleSubmit = async (
    values: typeof initialValues,
    { setSubmitting }: FormikHelpers<typeof initialValues>,
  ): Promise<boolean> => {
    setSubmitting(true);
    try {
      if (article != null) {
        const saved = await updateMutation({
          variables: {
            input: {
              ...valueToInput(values),
              uuid: article.uuid,
            },
          },
        });
        setArticle(saved.data?.updateCMSArticle);
      } else {
        const saved = await createMutation({
          variables: {
            input: {
              ...valueToInput(values),
              portfolio: portfolioUuid,
            },
          },
        });
        setArticle(saved.data?.createCMSArticle);
      }
      setSubmitting(false);
      return true;
    } catch (e: unknown) {
      console.error(e);
      setSubmitting(false);
      return false;
    }
  };

  return (
    <>
      <Formik innerRef={articleFormRef} initialValues={initialValues} onSubmit={handleSubmit}>
        {({ values, isSubmitting, submitForm, dirty }) => (
          <Form>
            <div className="main-container-inner">
              <div className="grid article">
                <div>
                  <ArticleFormTopBar />
                  <EqForm.Group
                    label={t("contentManagement.articles.form.title")}
                    hint={t("common.remainingCharacters", {
                      count: remainingCharacters(200, values.title != null ? values.title.length : 0),
                    })}
                    required
                  >
                    <Field
                      onFocus={() => setFocused(true)}
                      onBlur={() => setFocused(false)}
                      placeholder={t("contentManagement.articles.form.titlePlaceholder")}
                      type="text"
                      name="title"
                      as={EqForm.Input}
                      maxLength={200}
                      autoFocus={article == null}
                    />
                  </EqForm.Group>
                  <EqForm.Group
                    label={t("contentManagement.articles.form.excerpt")}
                    hint={t("common.remainingCharacters", {
                      count: remainingCharacters(250, values.excerpt?.length ?? 0),
                    })}
                  >
                    <Field
                      onFocus={() => setFocused(true)}
                      onBlur={() => setFocused(false)}
                      placeholder={t("contentManagement.articles.form.excerptPlaceholder")}
                      type="text"
                      name="excerpt"
                      as={EqForm.Textarea}
                      maxLength={250}
                    />
                  </EqForm.Group>
                  <EqForm.Group label={t("contentManagement.articles.form.images")} required>
                    <ErrorBoundary>
                      <Field
                        name="images"
                        placeholder={t("common.image")}
                        as={ImageUploader}
                        onUploading={(isUploading: boolean) => {
                          setFocused(isUploading);
                          setUploading(isUploading);
                        }}
                      />
                    </ErrorBoundary>
                  </EqForm.Group>
                  <EqForm.Group label={t("contentManagement.articles.form.body")} required>
                    <Field
                      onFocus={() => setFocused(true)}
                      onBlur={() => setFocused(false)}
                      type="text"
                      name="body"
                      as={CKEditorNoSSR}
                      height="auto"
                      toolbar={[
                        "heading",
                        "|",
                        "bold",
                        "italic",
                        "|",
                        "link",
                        "|",
                        "bulletedList",
                        "numberedList",
                        "|",
                        "outdent",
                        "indent",
                        "|",
                        "undo",
                        "redo",
                      ]}
                    />
                  </EqForm.Group>
                  <EqForm.Group
                    label={t("contentManagement.articles.form.files")}
                    showTooltip={true}
                    tooltipText={t("contentManagement.articles.form.filesTooltip")}
                  >
                    <Field
                      id="downloads"
                      name="downloads"
                      placeholder={t("common.file")}
                      as={FileUploader}
                      onUploading={(isUploading: boolean) => {
                        setFocused(isUploading);
                        setUploading(isUploading);
                      }}
                    />
                  </EqForm.Group>
                </div>
                <div>
                  <ArticlePreview
                    article={values}
                    publishDate={article?.publicationDate ?? undefined}
                    publishTime={article?.publicationTime ?? undefined}
                    title={t("contentManagement.preview")}
                    downloads={article?.draftContent.downloads ?? undefined}
                    images={article?.draftContent.images ?? undefined}
                  />
                </div>
              </div>
            </div>
            <ArticleFormBottomBar
              allowAutosave={allowAutosave}
              inputIsFocused={focused}
              formIsDirty={dirty}
              isSubmitting={isSubmitting || uploading}
              submitForm={submitForm}
            />
          </Form>
        )}
      </Formik>
      <style jsx>{`
        .grid {
          display: flex;
          flex-direction: row;
        }

        .grid > div:first-child {
          flex: 55;
          padding: 32px;
        }

        .grid > div:last-child {
          flex: 45;
          min-height: 100vh;
          background: ${colors.grayscale_5};
        }

        @media (max-width: ${breakpoints.lg}px) {
          .grid {
            flex-direction: column;
            min-height: 0;
          }
        }

        @media (max-width: ${breakpoints.sm}px) {
          .grid > div:first-child {
            padding: 16px;
          }
        }
      `}</style>
    </>
  );
};
