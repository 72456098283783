import React from "react";

import { useTranslation } from "@equiem/localisation-eq1";
import { Tooltip } from "../Tooltip/Tooltip";
import { ProgressCircle } from "../ProgressCircle/ProgressCircle";
import { RiArrowRightDownLine, RiArrowRightUpLine, RiInformationLine } from "../../admin-icons";
import { useTheme } from "../../contexts";
import type { MetricValue } from "./types";

// eslint-disable-next-line @typescript-eslint/no-type-alias
type SingleMetricValue = Omit<MetricValue, "title">;

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  title: string;
  tooltip?: string;
  value: SingleMetricValue;
  upTrend?: SingleMetricValue;
  downTrend?: SingleMetricValue;
  children?: React.ReactNode;
  loading?: boolean;
  action?: React.ReactNode;
}

export const SingleMetric: React.FC<Props> = ({
  title,
  tooltip,
  value,
  upTrend,
  downTrend,
  children,
  action,
  loading = false,
  ...rest
}) => {
  const { t } = useTranslation();
  const { colors } = useTheme();

  const displayValue = ({ value: metricValue, decimalPlaces, unit }: MetricValue, valueDecimalPlaces = 0) => {
    const formattedValue =
      typeof metricValue === "string" ? metricValue : metricValue.toFixed(decimalPlaces ?? valueDecimalPlaces);
    return formattedValue + (unit === "Percentage" ? "%" : "");
  };

  return (
    <div className="stat d-flex flex-column justify-content-between p-5" {...rest}>
      <div className="d-flex align-items-center justify-content-between">
        <span className="title">{title}</span>
        {tooltip != null && (
          <Tooltip placement="bottom-start" title={tooltip}>
            <div className="ms-1" style={{ display: "inline-block" }}>
              <RiInformationLine
                style={{
                  color: colors.medium,
                  cursor: "pointer",
                }}
              />
            </div>
          </Tooltip>
        )}
      </div>
      {!loading && children}
      <div className="d-flex flex-column align-items-end">
        {loading && <ProgressCircle size="sm" />}
        {!loading && (
          <>
            {value.tooltip != null ? (
              <Tooltip placement="bottom-start" title={value.tooltip}>
                <h1>{displayValue(value)}</h1>
              </Tooltip>
            ) : (
              <h1>{displayValue(value)}</h1>
            )}
            <div className="trends">
              {upTrend != null && upTrend.value !== 0 && (
                <Tooltip title={upTrend.tooltip ?? t("home.widgets.trendFromPreviousPeriod")} placement="bottom-start">
                  <span className="up">
                    <RiArrowRightUpLine />
                    {displayValue(upTrend)}
                  </span>
                </Tooltip>
              )}
              {downTrend != null && downTrend.value !== 0 && (
                <Tooltip
                  title={downTrend.tooltip ?? t("home.widgets.trendFromPreviousPeriod")}
                  placement="bottom-start"
                >
                  <span className="down">
                    <RiArrowRightDownLine />
                    {displayValue(downTrend)}
                  </span>
                </Tooltip>
              )}
              {action == null &&
                (upTrend?.value == null || upTrend.value === 0) &&
                (downTrend?.value == null || downTrend.value === 0) && <span className="zero">0</span>}
            </div>
            {action}
          </>
        )}
      </div>
      <style jsx>
        {`
          .stat {
            border: 1px solid #e6e6e6;
            border-radius: 4px;
            min-height: 232px;
            height: 100%;
          }
          .title {
            font-weight: 600;
            font-size: 0.75rem;
            line-height: 1rem;
            text-transform: uppercase;
            color: rgba(0, 0, 0, 0.6);
          }
          h1 {
            font-weight: 700;
            font-size: 3rem;
            line-height: 3rem;
            margin-bottom: 0.5rem;
            text-align: right;
          }
          .trends {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            gap: 0.5rem;
          }
          .placeholder {
            height: 1.5rem;
          }
          .up,
          .down,
          .zero {
            font-size: 1rem;
            line-height: 1.5rem;
            font-weight: 600;
            height: max-content;
          }
          .down {
            color: ${colors.status.danger.primary};
          }
          .up {
            color: ${colors.status.positive.primary};
          }
        `}
      </style>
    </div>
  );
};
