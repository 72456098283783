import getScrollbarSize from "dom-helpers/scrollbarSize";
import { useRouter } from "next/router";
import React, { createContext, useCallback, useEffect, useState } from "react";

type ActiveDetails = null | "notification";

interface SideMenuContext {
  isShown: boolean;
  toggle: () => void;
  show: () => void;
  hide: () => void;
  zIndex: {
    overlay: number;
    sidemenu: number;
  };
  activeDetails: ActiveDetails;
  openAndShowDetails: (_a: ActiveDetails) => void;
}

// tslint:disable:no-empty
const initial = {
  isShown: false,
  toggle: () => {
    throw new Error("Not implemented");
  },
  show: () => {
    throw new Error("Not implemented");
  },
  hide: () => {
    throw new Error("Not implemented");
  },
  zIndex: {
    overlay: 10,
    sidemenu: 11,
  },
  activeDetails: null as ActiveDetails,
  openAndShowDetails: (_activeDetails: ActiveDetails) => {
    throw new Error("Not implemented");
  },
};
// tslint:enable:no-empty
export const SideMenuContext = createContext<SideMenuContext>(initial);

export const SideMenuProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const [sidemenu, setSidemenu] = useState(initial);
  const router = useRouter();

  const hideScrollbar = () => {
    const scrollbar = getScrollbarSize();
    document.body.style.overflow = "hidden";
    if (scrollbar > 0) {
      document.body.style.paddingRight = `${scrollbar}px`;
    }
  };

  const show = useCallback(() => {
    setSidemenu({ ...sidemenu, isShown: true });
    hideScrollbar();
  }, [sidemenu]);

  const hide = useCallback(() => {
    setSidemenu(initial);
    document.body.style.overflow = "";
    document.body.style.paddingRight = "";
  }, [sidemenu]);

  const toggle = useCallback(() => {
    if (sidemenu.isShown) {
      hide();
    } else {
      show();
    }
  }, [sidemenu]);

  const openAndShowDetails = useCallback((activeDetails: ActiveDetails) => {
    setSidemenu({ ...sidemenu, activeDetails, isShown: true });
    hideScrollbar();
  }, []);

  useEffect(() => {
    hide();
  }, [router.asPath]);

  const context = {
    ...sidemenu,
    toggle,
    show,
    hide,
    openAndShowDetails,
  };

  return <SideMenuContext.Provider value={context}>{children}</SideMenuContext.Provider>;
};
