import { Site } from "@equiem/lib";
import { useTheme } from "@equiem/react-admin-ui";
import React, { useContext, useState } from "react";

interface Props {
  title: string;
  className?: string;
  openInit?: boolean;
  children?: React.ReactNode;
}

export const ParentLink: React.FC<Props> = ({ title, children, className, openInit = false }) => {
  const { breakpoints } = useTheme();
  const [open, setOpen] = useState(openInit);
  const site = useContext(Site);

  return (
    <div className={`parent-menu ${className} ${open ? "expanded" : ""}`}>
      <a
        tabIndex={0}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            setOpen((o) => !o);
          }
        }}
        onClick={() => {
          setOpen((o) => !o);
        }}
        className="parent"
      >
        <span>{open ? "-" : "+"}</span>
        {title}
      </a>
      <div className="children">{children}</div>
      <style jsx>{`
        .parent-menu {
          margin: 0 0 18px;
        }
        .parent {
          user-select: none;
          cursor: pointer;
          position: relative;
          opacity: 0.7;
          transition: 0.25s ease-in-out;
          font-size: 18px;
          text-decoration: none;
          color: ${site.primaryContrastColour};
        }
        .children {
          display: none;
          padding: 16px 0 0 16px;
        }
        .parent-menu.expanded .children {
          display: block;
        }
        .parent span {
          display: block;
          position: absolute;
          top: 0px;
          left: -24px;
          line-height: 100%;
        }
        .parent-menu.expanded .parent span {
          left: -20px;
          top: -1px;
        }
        .parent-menu.expanded .parent {
          opacity: 1;
          text-decoration: none;
        }
        @media (min-width: ${breakpoints.md}px) {
          .parent {
            font-size: 24px;
          }
          .parent-menu {
            margin: 0 0 24px;
          }
          .children {
            padding-left: 24px;
          }
          .parent span {
            top: -4px;
            left: -30px;
            font-size: 30px;
          }
          .parent-menu.expanded .parent span {
            top: -6px;
            left: -24px;
          }
        }
      `}</style>
    </div>
  );
};
