import React, { useImperativeHandle } from "react";
import type { PropsWithChildren } from "react";
import { Alert } from "../Alert/Alert";
import type { Props as AlertProps } from "../Alert/Alert";
import type { ToastExitDirection } from "./ToastProvider";
import type { TransitionStatus } from "react-transition-group";
import { TOASTS_LIMIT, TOAST_ANIMATION_DURATION_MS } from "./Toast.constants";

export interface Ref {
  remove: () => void;
}

type Props = AlertProps & {
  status: TransitionStatus;
  exitDirection: ToastExitDirection;
  index: number;
  remove: () => void;
};

export const AlertToast = React.forwardRef<Ref, PropsWithChildren<Props>>(
  ({ status, remove, exitDirection, index, ...props }, ref) => {
    useImperativeHandle(ref, () => ({
      remove,
    }));

    const exitIndex = exitDirection === "top" ? index + 1 : index - 1;

    return (
      <div>
        <Alert {...props} className="toast" />
        <style jsx>
          {`
            div {
              position: absolute;
              padding: 0 0.5rem 0 0.5rem;
              bottom: 0;
              left: 0;
              right: 0;
              margin: 0 auto;
              transition: ${TOAST_ANIMATION_DURATION_MS}ms all !important;
              display: flex;
              flex-direction: column;
              align-items: center;
              z-index: ${TOASTS_LIMIT + 1 - index};
              transform: translateY(
                ${status === "entered"
                  ? `calc(${index * -100}% - ${index * 0.5}rem)`
                  : `calc(${exitIndex * -100}% - ${exitIndex * 0.5}rem)`}
              );
            }
            div :global(.toast) {
              transition: ${TOAST_ANIMATION_DURATION_MS}ms opacity !important;
              opacity: ${status === "entered" ? 1 : 0};
              pointer-events: all;
              margin-bottom: 0.5rem;
            }
          `}
        </style>
      </div>
    );
  },
);

AlertToast.displayName = "Toast";
