/* eslint-disable @typescript-eslint/no-magic-numbers */
import type { FormikErrors } from "formik";
import { centsToDollar, dollarToCents } from "@equiem/lib";
import { convertInputMoneyToNumber } from "./convertInputMoneyToNumber";

import { BookableResourceCancellationRateType as RateType } from "../generated/gateway-client";
import type { BookableResourceEditQuery, BookableResourceInput } from "../generated/gateway-client";

export interface CancellationRate {
  type: RateType;
  amount: string;
  daysBefore: number | "" | null;
}

export function getCancellationRatesForForm(
  resource: BookableResourceEditQuery["bookableResource"],
): CancellationRate[] {
  return resource.paymentRateCancellation.map(({ type, amount, daysBefore }) => ({
    type,
    amount: String(type === RateType.Percentage ? Math.round(amount * 100) : centsToDollar(amount)),
    daysBefore: daysBefore ?? null,
  }));
}

export function toInputCancellationRates(
  rates: CancellationRate[],
): NonNullable<BookableResourceInput["paymentRateCancellation"]> {
  return rates.map(({ type, amount, daysBefore }) => ({
    type,
    amount:
      type === RateType.Percentage
        ? Number(amount) / 100
        : dollarToCents(convertInputMoneyToNumber(amount, false)) ?? 0,
    daysBefore: daysBefore !== "" ? daysBefore : null,
  }));
}

export function validateCancellationRates(rates: CancellationRate[]): Array<FormikErrors<CancellationRate>> | null {
  const seenDaysBefore = new Set<number | null>();

  const errors = toInputCancellationRates(rates).map(({ amount, daysBefore }) => {
    const rateErrors: FormikErrors<CancellationRate> = {};

    if (Number.isNaN(amount) || amount < 0) {
      rateErrors.amount = "Must be greater than 0";
    }

    if (Number.isNaN(daysBefore) || (daysBefore != null && daysBefore < 0)) {
      rateErrors.daysBefore = "Must be greater than 0";
    } else if (seenDaysBefore.has(daysBefore ?? null)) {
      rateErrors.daysBefore = "You cannot have multiple rates for the same period";
    }
    seenDaysBefore.add(daysBefore ?? null);

    return rateErrors;
  });

  return errors.some((err) => Object.keys(err).length > 0) ? errors : null;
}
