import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import type { ApolloError } from "@apollo/client";
import type { FormikHelpers, FormikProps } from "formik";
import { Formik, validateYupSchema, yupToFormErrors } from "formik";
import * as yup from "yup";

import { useShowError } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import { Button, Modal, ProgressCircle, useConfirmer, useTheme, useToast } from "@equiem/react-admin-ui";

import { useQueueQuery } from "../generated/requests-client";
import { ModalContext } from "../pages/settings/contexts/ModalContext";
import { useQueueData } from "../pages/settings/hooks/useQueueData";

import type { QueueFormValues } from "./QueueForm";
import { QueueForm } from "./QueueForm";
import { QueuePreview } from "./QueuePreview";

export const QueueModal: React.FC = () => {
  const { t } = useTranslation();
  const { companies, create, toggle, update, loading, queues, canCreateQueues } = useQueueData();
  const toast = useToast();
  const showError = useShowError();
  const formInnerRef = useRef<FormikProps<QueueFormValues>>(null);
  const { withConfirmation } = useConfirmer();

  const { colors } = useTheme();
  const modal = useContext(ModalContext);
  const [showModal, setShowModal] = useState(false);
  const isUpdateModal = modal.id != null;
  const [isPreview, setIsPrevew] = useState(isUpdateModal);
  const { data, loading: loadingQueueDetails } = useQueueQuery({
    variables: {
      uuid: modal.id ?? "",
    },
    skip: modal.id == null,
  });

  const queue = useMemo(() => {
    return data?.reqMgt.queue;
  }, [data]);

  useEffect(() => {
    setIsPrevew(modal.id != null);
    setShowModal(modal.activeModal === "QueueModal");
  }, [modal.activeModal, modal.id]);

  const onClose = useCallback(() => {
    setIsPrevew(false);
    setShowModal(false);
    modal.close();
  }, [setShowModal, modal]);

  const onCloseModal = useCallback(() => {
    formInnerRef.current?.dirty === true
      ? withConfirmation({
          title: t("common.areYouSure"),
          message: t("common.cancelMessage"),
          confirmButtonText: t("common.yesCancel"),
          cancelButtonText: t("home.widgets.cancelNo"),
          confirmButtonVariant: "danger",
          onConfirm: onClose,
        })()
      : onClose();
  }, [withConfirmation, t, onClose, formInnerRef]);

  const getValidationSchema = (values: QueueFormValues) =>
    yup.object().shape({
      name: yup
        .string()
        .min(1)
        .required(t("common.nameRequired"))
        .test({
          message: () => t("requests.queues.queueAlreadyExists"),
          test: (name) => {
            return !queues.some((q) => q.name === name && q.uuid !== values.uuid);
          },
        }),
      ownerCompanyUuid: yup.string().min(1).required(t("requests.queues.companyIsRequired")),
      requestManagers: yup
        .array()
        .min(1, t("requests.queues.atLeastOneManagerAndOneAssigneeAreRequired"))
        .of(
          yup.object().shape({
            uuid: yup.string().required(),
            companyUuid: yup.string().required(),
          }),
        ),
      requestAssignees: yup
        .array()
        .min(1, t("requests.queues.atLeastOneManagerAndOneAssigneeAreRequired"))
        .of(
          yup.object().shape({
            uuid: yup.string().required(),
            companyUuid: yup.string().required(),
          }),
        ),
    });

  const getInitialValues = (): QueueFormValues => {
    return {
      uuid: queue?.uuid,
      name: queue?.name ?? "",
      ownerCompanyUuid: queue?.ownerCompany.uuid ?? "",
      requestManagers:
        queue?.managers.map((x) => {
          return {
            uuid: x.uuid,
            email: x.email,
            firstName: x.firstName,
            lastName: x.lastName,
            companyUuid: x.companyV2?.uuid,
            companyName: x.companyV2?.name,
            profileIconUrl: x.avatar,
          };
        }) ?? [],
      requestAssignees:
        queue?.assignees.map((x) => {
          return {
            uuid: x.uuid,
            email: x.email,
            firstName: x.firstName,
            lastName: x.lastName,
            companyUuid: x.companyV2?.uuid,
            companyName: x.companyV2?.name,
            profileIconUrl: x.avatar,
          };
        }) ?? [],
    };
  };

  const onSubmit = async (values: QueueFormValues, formikHelpers: FormikHelpers<QueueFormValues>) => {
    const { uuid, name, ownerCompanyUuid, requestManagers, requestAssignees } = values;
    const { setFieldError } = formikHelpers;
    try {
      if (isUpdateModal && uuid != null) {
        const { data: updateResponse, errors } = await update(uuid, {
          name: name,
          managers: requestManagers.map((x) => x.uuid),
          assignees: requestAssignees.map((x) => x.uuid),
        });
        if (updateResponse?.reqMgt.updateQueue.uuid != null) {
          toast.positive(t("requests.queues.changesSaved"));
          onClose();
        } else {
          showError(errors?.map((x) => x.message).concat(";\n"));
        }
      } else {
        const { data: createResponse, errors } = await create({
          name: name,
          ownerCompanyUuid: ownerCompanyUuid,
          managers: requestManagers.map((x) => x.uuid),
          assignees: requestAssignees.map((x) => x.uuid),
        });
        if (createResponse?.reqMgt.createQueue.uuid != null) {
          toast.positive(t("requests.queues.changesSaved"));
          onClose();
        } else {
          showError(errors?.map((x) => x.message).concat(";\n"));
        }
      }
    } catch (e: unknown) {
      if (
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        (e as ApolloError)?.graphQLErrors?.some((gqlError) => gqlError.extensions?.code === "ALREADY_EXISTS")
      ) {
        console.error(e);
        setFieldError("name", t("requests.category.alreadyExists"));
        return;
      }

      showError(e);
    }
  };

  return (
    <>
      <Modal.Dialog
        title={
          isUpdateModal
            ? isPreview
              ? t("requests.queues.queueDetails")
              : t("requests.queues.editQueue")
            : t("requests.queues.newQueue")
        }
        show={showModal}
        sideModal={true}
        onHide={onCloseModal}
        hideOnEsc={true}
        hideOnClick={true}
        size="md"
      >
        <Modal.Header closeButton noBorder={false} onClose={onCloseModal} supportsMobile />
        {loading || loadingQueueDetails ? (
          <div className="loading-container">
            <ProgressCircle size="lg" />
          </div>
        ) : isPreview && queue != null ? (
          <>
            <Modal.Body>
              <div className="content">{<QueuePreview {...queue} />}</div>
            </Modal.Body>
            <Modal.Footer>
              <div className="preview-footer-container">
                {canCreateQueues ? (
                  queue.active ? (
                    <Button
                      variant="danger"
                      size="lg"
                      style={{ width: "50%", marginRight: "15px" }}
                      onClick={() => {
                        withConfirmation({
                          title: t("requests.queues.setAsInactiveConfirmationHeader"),
                          message: t("requests.queues.setAsInactiveConfirmationText"),
                          confirmButtonText: t("requests.queues.setAsInactive"),
                          cancelButtonText: t("common.cancel"),
                          confirmButtonVariant: "danger",
                          onConfirm: () => {
                            void toggle(queue.uuid, false);
                            toast.neutral(t("requests.queues.queueHasBeenSetAsInactive", { name: queue.name }));
                            onClose();
                          },
                        })();
                      }}
                    >
                      {t("requests.queues.setAsInactive")}
                    </Button>
                  ) : (
                    <Button
                      variant="secondary"
                      size="lg"
                      style={{ width: "50%", marginRight: "15px" }}
                      onClick={() => {
                        void toggle(queue.uuid, true);
                        toast.positive(t("requests.queues.queueHasBeenSetToActive", { name: queue.name }));
                        onClose();
                      }}
                    >
                      {t("requests.queues.setAsActive")}
                    </Button>
                  )
                ) : (
                  <Button variant="ghost" size="lg" style={{ width: "50%" }} className="mr-4" onClick={onCloseModal}>
                    {t("common.cancel")}
                  </Button>
                )}
                <Button variant="primary" size="lg" style={{ width: "50%" }} onClick={() => setIsPrevew(false)}>
                  {t("common.edit")}
                </Button>
              </div>
            </Modal.Footer>
          </>
        ) : (
          <Formik
            enableReinitialize
            initialValues={getInitialValues()}
            innerRef={formInnerRef}
            validate={(values) => {
              try {
                void validateYupSchema<QueueFormValues>(values, getValidationSchema(values), true);
              } catch (err: unknown) {
                return yupToFormErrors(err);
              }
              return {};
            }}
            onSubmit={onSubmit}
          >
            {({ submitForm, isValid, dirty, isSubmitting }) => (
              <>
                <Modal.Body>
                  <div className="content">
                    {<QueueForm loading={loading} companies={companies} canCreateQueues={canCreateQueues} />}
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="ghost"
                    size="lg"
                    style={{ width: "50%" }}
                    className="mr-4"
                    disabled={isSubmitting}
                    onClick={onCloseModal}
                  >
                    {t("common.cancel")}
                  </Button>
                  <Button
                    type="submit"
                    variant="primary"
                    size="lg"
                    style={{ width: "50%" }}
                    disabled={isSubmitting || !isValid || !dirty}
                    onClick={() => {
                      submitForm().catch(showError);
                    }}
                  >
                    {isUpdateModal ? (isPreview ? t("common.edit") : t("common.save")) : t("requests.queues.addQueue")}
                  </Button>
                </Modal.Footer>
              </>
            )}
          </Formik>
        )}
      </Modal.Dialog>
      <style jsx>{`
        .mobile-header {
          border-top: 1px solid ${colors.grayscale[10]};
          border-bottom: 1px solid ${colors.grayscale[10]};
          position: sticky;
          top: 64px;
          z-index: 2;
        }
        .content {
          padding-top: 16px;
        }
        .preview-footer-container {
          flex-grow: 1;
          display: flex;
          flex-direction: row;
          justify-content: space-evenly;
        }
        .separator {
          margin: 4px 0;
          border: 0;
          border-top: 1px solid ${colors.border};
        }
        .loading-container {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          background-color: ${colors.white};
          padding: 0 32px 0;
          text-align: center;
          width: 100%;
          height: 100%;
        }
      `}</style>
    </>
  );
};
