import type { ReactNode } from "react";
import React, { Children, useRef } from "react";

import { useTheme } from "../../contexts";
import { ColumnViewContext } from "./ColumnViewContext";

export const ColumnView: React.FC<{ children: ReactNode }> = ({ children }) => {
  const { colors, breakpoints } = useTheme();
  const gridRef = useRef<HTMLDivElement>(null);

  const scrollNextColumn = () => {
    const grid = gridRef.current;

    if (grid != null) {
      const currentOffset = gridRef.current?.parentElement?.scrollLeft ?? 0;
      const index = Math.floor(currentOffset / grid.children[0].clientWidth) + 1;

      grid.parentElement?.scrollTo({
        left: index * grid.children[0].clientWidth,
        behavior: "smooth",
      });
    }
  };

  return (
    <ColumnViewContext.Provider value={{ scrollNextColumn }}>
      <div className="container">
        <div className="grid-container" ref={gridRef}>
          {children}
        </div>
        <style jsx>
          {`
            .container {
              overflow-x: auto;
              scroll-snap-type: x mandatory;
              width: 100%;
            }

            .grid-container {
              display: inline-grid;
              grid-template-columns: repeat(${Children.count(children)}, minmax(300px, 1fr));
              border: 1px solid ${colors.grayscale[10]};
              border-radius: 0.5rem;
              height: 100%;
              width: 100%;
              margin: 0;
            }

            @media (max-width: ${breakpoints.xl}px) {
              .grid-container {
                width: auto;
              }
            }

            @media (max-width: ${breakpoints.lg}px) {
              .grid-container {
                margin: 0 32px;
              }
            }

            @media (max-width: ${breakpoints.sm}px) {
              .grid-container {
                margin: 0 16px;
              }
            }
          `}
        </style>
      </div>
    </ColumnViewContext.Provider>
  );
};
