import React, { useMemo } from "react";

import { utilityClasses as getUtilityClasses } from "../util/utilityClasses";
import { useTheme } from "./Theme";

const random = "c0179c41-ba25-4028-add7-c483e217345c";
let instance = 0;

export const ScopedUtilityClassProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const theme = useTheme();

  // `styled-jsx` won't process `:global` directives in styles we construct by
  // string concatenation like in `utilityClasses`. Give this container element
  // a unique class name so we can make these styles global.
  const className = useMemo(() => `utility-class-root-${random}-${instance++}`, []);

  const utilityClasses = useMemo(() => getUtilityClasses(theme, `.${className}`), [theme, className]);
  return (
    <>
      <div className={className}>{children}</div>
      {/* eslint-disable-next-line react/no-unknown-property */}
      <style jsx global>{`
        ${utilityClasses}

        /* Override theme-dependent styles from GlobalStyles.tsx */
        .${className} {
          font-size: ${theme.fontSize};
        }
      `}</style>
    </>
  );
};
