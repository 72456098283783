/* eslint-disable @typescript-eslint/no-magic-numbers */
import React, { useState, useRef, useEffect, useCallback } from "react";
import { Table, DateTime as DateTimeUi, useTheme } from "@equiem/react-admin-ui";
import { useSiteContext } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import { useReactToPrint } from "react-to-print";
import { DateTime, Interval } from "luxon";
import groupBy from "lodash/groupBy";

import type { Booking } from "../../../hooks/usePagedSiteBookings";

const getPrepTimes = (b: Booking, timezone: string) => {
  const before = Interval.fromDateTimes(
    DateTime.fromMillis(b.startDateWithPrepTime, { zone: timezone }),
    DateTime.fromMillis(b.startDate, { zone: timezone }),
  );
  const after = Interval.fromDateTimes(
    DateTime.fromMillis(b.endDate, { zone: timezone }),
    DateTime.fromMillis(b.endDateWithPrepTime, { zone: timezone }),
  );

  return {
    before:
      before.isValid && !before.isEmpty()
        ? {
            start: before.start.toMillis(),
            end: before.end.toMillis(),
          }
        : null,
    after:
      after.isValid && !after.isEmpty()
        ? {
            start: after.start.toMillis(),
            end: after.end.toMillis(),
          }
        : null,
  };
};

const AddOns: React.FC<{ addOns: Booking["addOns"] }> = ({ addOns }) => {
  const { spacers } = useTheme();
  return (
    <>
      {addOns.length === 0 && "-"}
      {addOns.map((addOn, i) => (
        <div key={i} className="add-on">
          {addOn.addOnName != null && <strong>{addOn.addOnName}</strong>}
          {addOn.description != null && <div>{addOn.description}</div>}
          {addOn.options.map((option, j) => (
            <div key={j}>
              {option.description}
              {option.quantity != null && ` x ${option.quantity}`}
            </div>
          ))}
        </div>
      ))}
      <style jsx>{`
        .add-on {
          line-height: ${spacers.s5};
        }
        .add-on:not(:last-child) {
          margin-bottom: ${spacers.s4};
        }
      `}</style>
    </>
  );
};

const RunsheetDay: React.FC<{ bookings: Booking[] }> = ({ bookings }) => {
  const { timezone } = useSiteContext();
  const { t, i18n } = useTranslation();

  // should never happen
  if (bookings.length === 0) {
    return null;
  }

  const multiSite = bookings.some((b) => b.resource.destination.uuid !== bookings[0].resource.destination.uuid);

  return (
    <>
      <div className="runsheet-day">
        <h1 className="mb-4">
          <strong>Date:</strong>{" "}
          <DateTimeUi.DateDisplay datetime={bookings[0].startDate} language={i18n.language} timezone={timezone} />
        </h1>
        <Table.Table className="w-100">
          <thead>
            <tr>
              <Table.Header label={t("common.host")} />
              <Table.Header label={t("bookings.operations.resourceNameAndLocation")} />
              <Table.Header label={t("bookings.resources.preparationTime")} />
              <Table.Header label={t("bookings.operations.bookingTime")} />
              <Table.Header label={t("bookings.operations.cleanUpTime")} />
              <Table.Header label={t("bookings.operations.config")} />
              <Table.Header label={t("bookings.operations.addOns")} />
              <Table.Header label={t("bookings.operations.hostNote")} />
              <Table.Header label={t("bookings.operations.adminNote")} />
            </tr>
          </thead>
          <tbody>
            {bookings.map((b) => {
              const { before, after } = getPrepTimes(b, timezone);
              return (
                <tr key={b.uuid} className={b.cancelled ? "strikethrough" : ""}>
                  <td>
                    <div style={{ whiteSpace: "nowrap" }}>
                      {b.user.profile?.firstName} {b.user.profile?.lastName}
                    </div>
                    <br />
                    <div>{b.user.profile?.companyV2?.name}</div>
                  </td>
                  <td>
                    <div>{b.resource.name}</div>
                    {b.resource.level != null && <div>{b.resource.level.name}</div>}
                    {b.resource.building != null && <div>{b.resource.building.name}</div>}
                    {multiSite && <div>{b.resource.destination.name}</div>}
                  </td>
                  <td className="nowrap">
                    {before != null ? (
                      <>
                        <div>
                          <DateTimeUi.TimeDisplay
                            datetime={before.start}
                            timezone={timezone}
                            language={i18n.language}
                          />
                        </div>
                        <div style={{ textTransform: "uppercase" }}>{t("bookings.resources.to")}</div>
                        <div>
                          <DateTimeUi.TimeDisplay datetime={before.end} timezone={timezone} language={i18n.language} />
                        </div>
                      </>
                    ) : (
                      "-"
                    )}
                  </td>
                  <td className="nowrap">
                    <div>
                      <DateTimeUi.TimeDisplay datetime={b.startDate} timezone={timezone} language={i18n.language} />
                    </div>
                    <div style={{ textTransform: "uppercase" }}>{t("bookings.resources.to")}</div>
                    <div>
                      <DateTimeUi.TimeDisplay datetime={b.endDate} timezone={timezone} language={i18n.language} />
                    </div>
                  </td>
                  <td className="nowrap">
                    {after != null ? (
                      <>
                        <div>
                          <DateTimeUi.TimeDisplay datetime={after.start} timezone={timezone} language={i18n.language} />
                        </div>
                        <div style={{ textTransform: "uppercase" }}>{t("bookings.resources.to")}</div>
                        <div>
                          <DateTimeUi.TimeDisplay datetime={after.end} timezone={timezone} language={i18n.language} />
                        </div>
                      </>
                    ) : (
                      "-"
                    )}
                  </td>
                  <td>{b.roomConfig?.name ?? "-"}</td>
                  <td>
                    <AddOns addOns={b.addOns} />
                  </td>
                  <td style={{ whiteSpace: "pre-line" }}>{b.note}</td>
                  <td style={{ whiteSpace: "pre-line" }}>{b.adminNote}</td>
                </tr>
              );
            })}
          </tbody>
        </Table.Table>
      </div>
      <style jsx>{`
        .runsheet-day {
          padding: 20px;
          clear: both;
          page-break-after: always;
        }
        td {
          word-break: break-word;
        }
        td.nowrap {
          white-space: nowrap;
        }
        .strikethrough {
          text-decoration: line-through;
        }
      `}</style>
    </>
  );
};

export function usePrintableBookings() {
  const { timezone } = useSiteContext();
  const contentRef = useRef<HTMLDivElement>(null);
  const [isPrinting, setIsPrinting] = useState(false);
  const [bookingsToPrint, setBookingsToPrint] = useState<Booking[] | null>(null);

  const print = useReactToPrint({
    content: () => contentRef.current,
    onAfterPrint: () => {
      setBookingsToPrint(null);
      setIsPrinting(false);
    },
  });

  const handlePrint = useCallback(
    (bookings: Booking[]) => {
      if (bookingsToPrint == null) {
        setBookingsToPrint(bookings);
      }
    },
    [bookingsToPrint],
  );

  const renderContent = () => {
    if (bookingsToPrint == null) {
      return null;
    }

    const { clientWidth, clientHeight } = document.documentElement;
    const bookingsByDate = Object.entries(
      groupBy(bookingsToPrint, (b) =>
        DateTime.fromMillis(b.startDate, { zone: timezone }).startOf("day").toFormat("yyyy-LL-dd"),
      ),
    );

    return (
      <>
        <div className="offscreen-container">
          <div ref={contentRef}>
            {bookingsByDate.map(([date, dayBookings]) => (
              <RunsheetDay key={date} bookings={dayBookings} />
            ))}
          </div>
        </div>
        <style jsx>{`
          @media print {
            @page {
              size: landscape;
            }
          }
          .offscreen-container {
            height: ${clientHeight}px;
            width: ${clientWidth}px;

            /* hide the table offscreen */
            position: absolute;
            top: -${clientHeight + 100}px;
            left: -${clientWidth + 100}px;
            overflow: hidden;
          }
        `}</style>
      </>
    );
  };

  useEffect(() => {
    if (bookingsToPrint != null && !isPrinting && contentRef.current != null) {
      setIsPrinting(true);
      print();
    }
  }, [bookingsToPrint, print, isPrinting]);

  return {
    handlePrint,
    renderPrintTable: renderContent,
  };
}
