export type Capacity = "10" | "20" | "50" | "50+";

type CapacityMap = {
  [C in Capacity]: {
    option: { value: C; label: string };
    filter: { maximumCapacity?: number; minimumCapacity?: number };
  };
};

export const capacities: CapacityMap = {
  "10": {
    option: { value: "10", label: "1 - 10" },
    filter: { maximumCapacity: 10 },
  },
  "20": {
    option: { value: "20", label: "11 - 20" },
    filter: { minimumCapacity: 11, maximumCapacity: 20 },
  },
  "50": {
    option: { value: "50", label: "21 - 50" },
    filter: { minimumCapacity: 21, maximumCapacity: 50 },
  },
  "50+": {
    option: { value: "50+", label: "50+" },
    filter: { minimumCapacity: 51 },
  },
};
