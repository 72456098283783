import React from "react";
import { useTheme } from "../../../contexts/Theme";

interface Props {
  hint?: string | null;
  error?: string | null;
  id?: string;
}

export const FormHint: React.FC<Props> = ({ hint, error, id }) => {
  const { colors, spacers } = useTheme();
  const hasError = error != null && error !== "";
  const hasHint = hint != null && hint !== "";
  const show = hasError || hasHint;

  return !show ? null : (
    <p id={id}>
      {hasError ? error : hasHint ? hint : null}
      <style jsx>{`
        p {
          font-size: 13px;
          color: ${hasError ? colors.danger : colors.dark};
          margin: ${spacers.s3} 0;
        }
        p:first-letter {
          text-transform: ${hasError ? "uppercase" : "none"};
        }
      `}</style>
    </p>
  );
};
