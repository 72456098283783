import React from "react";
import { StickyElement, useTheme } from "@equiem/react-admin-ui";
import { useSiteContext } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import { DateTime } from "luxon";

import type { SiteCalendarBookingFragment } from "../../../generated/gateway-client";
import type { SiteCalendarDay } from "../../../hooks/useMultiDayBookingsCalendar";
import { OperationsCalendarBooking } from "./OperationsCalendarBooking";

interface Props {
  calendar: SiteCalendarDay[];
  onDateClicked: (date: number) => unknown;
  onBookingClicked: (booking: SiteCalendarBookingFragment) => unknown;
}

const WeekDay: React.FC<{
  day: SiteCalendarDay;
  onBookingClicked: (booking: SiteCalendarBookingFragment) => unknown;
}> = ({ day, onBookingClicked }) => {
  const { timezone } = useSiteContext();
  const { colors, spacers } = useTheme();
  const { t } = useTranslation();

  const SATURDAY = 6;
  const dt = DateTime.fromMillis(day.date, { zone: timezone });
  const isWeekend = dt.weekday >= SATURDAY;

  return (
    <>
      <div className={`day ${dt.toFormat("yyyy-MM-dd")} ${isWeekend ? "weekend" : ""}`}>
        {day.bookings.map((booking) => (
          <OperationsCalendarBooking
            key={booking.uuid}
            booking={booking}
            onBookingClicked={() => onBookingClicked(booking)}
          />
        ))}
        {day.numMore > 0 && (
          <div className="num-more label-text">{t("bookings.operations.plusMore", { count: day.numMore })}</div>
        )}
      </div>
      <style jsx>{`
        .day {
          height: 100%;
          min-height: 400px;
          display: flex;
          flex-direction: column;
          padding: ${spacers.s3};
        }
        .day:not(:last-child) {
          border-right: 1px solid ${colors.border};
        }
        .day.weekend {
          background: ${colors.grayscale[3]};
        }
        .num-more {
          text-transform: uppercase;
          padding: ${spacers.s2} ${spacers.s3};
          font-weight: 500;
          color: ${colors.grayscale[60]};
        }
      `}</style>
    </>
  );
};

const WeekDayHeader: React.FC<{ day: SiteCalendarDay; onDateClicked: () => unknown }> = ({ day, onDateClicked }) => {
  const { colors } = useTheme();
  const { timezone } = useSiteContext();
  const { i18n } = useTranslation();

  const dt = DateTime.fromMillis(day.date, { zone: timezone });
  const isToday = dt.hasSame(DateTime.now().setZone(timezone), "day");

  // As a general rule, rolling your own custom localised formatters like this
  // is a bad idea and should be avoided.
  // However, in this case, these custom date formats are only relevant for the
  // calendar view and we explicitly *don't* want them reused elsewhere.
  const dayOfWeekFormatter = new Intl.DateTimeFormat(i18n.language, { weekday: "short", timeZone: timezone });
  const dateFormatter = new Intl.DateTimeFormat(i18n.language, { day: "numeric", timeZone: timezone });

  return (
    <>
      <div className="day-of-week p-3 label-text button" role="button" onClick={onDateClicked}>
        <div className="mb-3">{dayOfWeekFormatter.format(dt.toJSDate())}</div>
        <div className={`date button ${isToday ? "today" : "label-text"}`}>{dateFormatter.format(dt.toJSDate())}</div>
      </div>
      <style jsx>{`
        .day-of-week {
          text-transform: uppercase;
          text-align: center;
          font-weight: 500;
          color: ${colors.grayscale[60]};
          background: ${colors.white};
          border-bottom: 1px solid ${colors.border};
          cursor: pointer;
        }
        .date {
          display: inline-block;
          height: 2em;
          width: 2em;
          line-height: 2em;
          border-radius: 50%;
        }
        .date.today {
          background: ${colors.dark};
          color: ${colors.white};
        }
      `}</style>
    </>
  );
};

export const OperationsCalendarWeek: React.FC<Props> = ({ calendar, onDateClicked, onBookingClicked }) => {
  return (
    <>
      <StickyElement<HTMLDivElement>>
        {({ ref, top, isStuck }) => (
          <div className="week mt-4">
            <div ref={ref} className="headers" style={isStuck ? { position: "sticky", top } : undefined}>
              {calendar.map((day) => (
                <WeekDayHeader key={day.date} day={day} onDateClicked={() => onDateClicked(day.date)} />
              ))}
            </div>
            <div className="body">
              {calendar.map((day) => (
                <WeekDay key={day.date} day={day} onBookingClicked={onBookingClicked} />
              ))}
            </div>
          </div>
        )}
      </StickyElement>
      <style jsx>{`
        .headers,
        .body {
          display: grid;
          grid-auto-columns: minmax(0, 1fr);
          grid-auto-flow: column;
        }
        .headers {
          z-index: 1;
        }
      `}</style>
    </>
  );
};
