import React from "react";

import { useTranslation } from "@equiem/localisation-eq1";
import { Button } from "@equiem/react-admin-ui";
import { RiPrinterLine } from "@equiem/react-admin-ui/icons";

export const BulkOperations = ({ onClick }: { onClick: () => void }) => {
  const { t } = useTranslation();

  return (
    <Button variant="ghost" size="sm" className="text-uppercase ml-n3" onClick={onClick}>
      <RiPrinterLine size={16} />
      {t("visitors.reception.printPasses")}
    </Button>
  );
};
