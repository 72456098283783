import React, { useState } from "react";
import type { BookingFragmentFragment } from "../../../generated/gateway-client";
import { Text } from "@equiem/react-admin-ui";
import { useTranslation } from "@equiem/localisation-eq1";
import { RiEditLine } from "@equiem/react-admin-ui/icons";
import { BookingDetailsAdminNoteFormModal } from "./BookingDetailsAdminNoteFormModal";

interface Props {
  booking: BookingFragmentFragment;
}

export const BookingDetailsAdminNote: React.FC<Props> = ({ booking }) => {
  const { t } = useTranslation();
  const [showAdminNoteModal, setShowAdminNoteModal] = useState(false);

  return (
    <div className="admin-note">
      <div className="row">
        <div className="mb-2 text-medium">
          <Text variant="label">{t("bookings.operations.adminNote")}</Text>
          <RiEditLine
            style={{ cursor: "pointer" }}
            className="ml-3 admin-note-edit"
            onClick={() => setShowAdminNoteModal(true)}
          />
        </div>
        <div className="value" style={{ whiteSpace: "pre-line", wordWrap: "break-word" }}>
          {booking.adminNote}
        </div>
      </div>
      <BookingDetailsAdminNoteFormModal
        booking={booking}
        showModal={showAdminNoteModal}
        setShowModal={setShowAdminNoteModal}
      />
    </div>
  );
};
