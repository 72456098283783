import { DateTime } from "luxon";

export const toDateTime = (time: string | null | undefined) => {
  if (time == null || time === "") {
    return undefined;
  }
  const detect = time.split(":");
  if (detect.length === 1) {
    return undefined;
  }

  return DateTime.fromFormat(time, detect.length === 3 ? "HH:mm:ss" : "HH:mm");
};

export const toSeconds = (time: string | null | undefined) => {
  return toDateTime(time)?.toSeconds();
};

export const fromSeconds = (seconds: number) => DateTime.fromSeconds(seconds).toFormat("HH:mm");

export const parseTime = (e: React.ChangeEvent<HTMLSelectElement>) => fromSeconds(Number(e.currentTarget.value));
