import React, { useCallback, useContext, useMemo } from "react";
import { NewInvites } from "./NewInvitesContext";
import { useSiteGroups } from "./hooks/useSiteGroups";
import { useTranslation } from "@equiem/localisation-eq1";
import { Form } from "@equiem/react-admin-ui";

export const BulkGroupSelection: React.FC = () => {
  const { selectedGroups, extendGroupsBulk } = useContext(NewInvites);
  const groups = useSiteGroups();
  const { t } = useTranslation();

  const onChange = useCallback(
    (e: { target: { value: string[] } }) => {
      extendGroupsBulk(e.target.value);
    },
    [extendGroupsBulk],
  );

  const options = useMemo(() => {
    return groups.map((group) => {
      return {
        ...group,
        indeterminate: selectedGroups.find((sr) => sr.group === group.value && sr.mixedSelection) != null,
      };
    });
  }, [groups, selectedGroups]);

  const getValue = useMemo(() => {
    return selectedGroups.flatMap((group) => (!group.mixedSelection ? [group.group] : []));
  }, [selectedGroups]);

  return (
    <Form.MultiSelect
      size="small"
      placeholder={t("home.widgets.welcomeAssignGroups")}
      options={options}
      value={getValue}
      onChange={onChange}
      className="bulk-group-selector"
      key="bulki"
      facade="ghost"
    />
  );
};
