import React, { useContext } from "react";
import { Form } from "formik";

import { useShowError } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import { ButtonLink, ColumnView, Tooltip, useConfirmer, useTheme, useToast } from "@equiem/react-admin-ui";
import { RiAddLine, RiDeleteBinLine, RiEditLine, RiQuestionLine } from "@equiem/react-admin-ui/icons";

import {
  CategoryCafmConfigurationsByCategoryUuidDocument,
  useCategoryCafmConfigurationsByCategoryUuidQuery,
  useDeleteCategoryCafmConfigurationMutation,
} from "../generated/requests-client";
import { ModalContext } from "../pages/settings/contexts/ModalContext";

import { CategoryCafmIntegrationModalContext } from "./category-cafm-integration/CategoryCafmIntegrationModalContext";
import { CategoryCafmIntegrationWidget } from "./category-cafm-integration/CategoryCafmIntegrationWidget";

export const CategoryIntegrationsForm: React.FC<{ isEditing: boolean }> = ({ isEditing }) => {
  const { t } = useTranslation();
  const categoryModal = useContext(ModalContext);
  const { colors } = useTheme();
  const toast = useToast();
  const { withConfirmation } = useConfirmer();
  const showError = useShowError();
  const modal = useContext(CategoryCafmIntegrationModalContext);
  const { data, loading } = useCategoryCafmConfigurationsByCategoryUuidQuery({
    variables: {
      categoryUuid: categoryModal.id!,
    },
  });

  const [deleteCategoryCafmConfiguration] = useDeleteCategoryCafmConfigurationMutation({
    refetchQueries: [
      {
        query: CategoryCafmConfigurationsByCategoryUuidDocument,
        variables: {
          categoryUuid: categoryModal.id!,
        },
      },
    ],
  });

  const onClickCategoryModal = (linkId?: string) => {
    modal.open("CafmIntegrationModal", linkId);
  };

  return (
    <div className="integrations">
      <p className="title">{t("requests.category.integrations.cafmIntegration")}</p>
      <div className="subtitle">
        <p>{t("requests.category.integrations.cafmProviders")}</p>
        <Tooltip title={t("requests.category.integrations.cafmConnectTooltip")} placement="top" showArrow={true}>
          <span>
            <RiQuestionLine size="16" className="ms-1" />
          </span>
        </Tooltip>
      </div>
      <Form>
        <CategoryCafmIntegrationWidget configurationLinks={data} />
        {data?.reqMgt.categoryCafmConfigurationsByCategoryUuid != null &&
          data.reqMgt.categoryCafmConfigurationsByCategoryUuid.length > 0 && (
            <ColumnView.Container>
              {data.reqMgt.categoryCafmConfigurationsByCategoryUuid.map((link) => (
                <ColumnView.Item
                  value={link.cafmConfiguration.type}
                  key={link.uuid}
                  maxIcons={2}
                  className="integration-item"
                  valueTags={link.buildings.map((lb) => lb.name)}
                  actions={[
                    {
                      title: t("common.edit"),
                      icon: RiEditLine,
                      onClick: () => {
                        onClickCategoryModal(link.uuid);
                      },
                    },
                    {
                      title: t("common.delete"),
                      icon: RiDeleteBinLine,
                      color: colors.status.danger.primary,
                      onClick: withConfirmation({
                        onConfirm: () => {
                          deleteCategoryCafmConfiguration({ variables: { uuid: link.uuid } })
                            .then(() => {
                              toast.neutral(t("requests.category.integrations.delSucceseful"));
                            })
                            .catch(showError);
                        },
                        title: t("common.areYouSure"),
                        message: t("requests.category.integrations.confirmDel"),
                        confirmButtonText: t("common.yesDelete"),
                        confirmButtonVariant: "danger",
                      }),
                    },
                  ]}
                />
              ))}
            </ColumnView.Container>
          )}
        {isEditing && (
          <div className="flex-grow-1">
            <ButtonLink
              variant="secondary"
              size="md"
              className="mt-5"
              disabled={loading}
              onClick={() => onClickCategoryModal()}
            >
              <RiAddLine size={16} />
              {t("requests.category.integrations.add")}
            </ButtonLink>
          </div>
        )}
      </Form>
      <style jsx>{`
        .title {
          font-size: 18px;
          font-weight: 700;
          line-height: 28px;
          margin-top: 0;
        }
        .subtitle {
          font-size: 12px;
          font-weight: 500;
          line-height: 16px;
          text-transform: uppercase;
          display: flex;
          margin-bottom: 4px;
        }
        .subtitle p,
        .subtitle span {
          color: ${colors.grayscale[60]};
          margin: 0;
        }
        .integrations :global(.integration-item .flex-column > div) {
          display: flex;
          flex-direction: row;
          gap: 4px;
          flex-wrap: wrap;
          margin-top: 4px;
        }
        .integrations :global(.integration-item) {
          height: auto;
          padding: 16px;
          border-bottom-right-radius: 0;
          border-bottom-left-radius: 0;
          border-bottom: 1px solid ${colors.border};
        }
        .integrations :global(.integration-item .tag) {
          margin-top: 0 !important;
          margin-bottom: 0 !important;
        }
        .integrations :global(.integration-item):last-child {
          border-bottom: none;
        }
        .integrations :global(.grid-container) {
          display: grid;
          grid-template-columns: auto;
        }
        .iconNameGroup :global(p) {
          margin-right: -10rem;
          margin-bottom: -1rem;
        }
      `}</style>
    </div>
  );
};
