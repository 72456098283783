import { stringNotEmpty } from "@equiem/lib";
import React from "react";
import type { BookingAddOnFragmentFragment } from "../../../../generated/gateway-client";
import { BookableResourceAddOnType } from "../../../../generated/gateway-client";
import { useTranslation } from "@equiem/localisation-eq1";

interface P {
  bookingAddOns: BookingAddOnFragmentFragment[];
}
export const BookingAddOnsSummary: React.FC<P> = ({ bookingAddOns }) => {
  const { t } = useTranslation();
  const moreThanOne = bookingAddOns.length > 1;

  return (
    <div className="booking-addons-summary">
      {bookingAddOns.length === 0 ? (
        <div>{t("common.emptyNone")}.</div>
      ) : (
        <>
          {bookingAddOns.map((bookingAddOn, index) => {
            switch (bookingAddOn.addOnType) {
              case BookableResourceAddOnType.FreeText:
                return (
                  <div key={index} className={moreThanOne ? "mb-5" : ""}>
                    <div>
                      <strong>{bookingAddOn.addOnName}</strong>
                    </div>
                    {bookingAddOn.description}
                  </div>
                );

              case BookableResourceAddOnType.SingleChoice:
              case BookableResourceAddOnType.SingleOption:
              case BookableResourceAddOnType.MultiOption: {
                return (
                  <div key={index} className={moreThanOne ? "mb-5" : ""}>
                    {stringNotEmpty(bookingAddOn.addOnName) ? (
                      <div>
                        <strong>{bookingAddOn.addOnName}</strong>
                      </div>
                    ) : null}
                    {bookingAddOn.options.map((option, optionIndex) => (
                      <div
                        className={
                          bookingAddOn.addOnType === BookableResourceAddOnType.SingleOption ? "font-weight-bold" : ""
                        }
                        key={`${index}-${optionIndex}`}
                      >
                        {option.description}
                        {option.quantity != null ? ` (x${option.quantity})` : ""}
                      </div>
                    ))}
                  </div>
                );
              }

              default:
                return null;
            }
          })}
        </>
      )}
    </div>
  );
};
