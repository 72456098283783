import { useCallback, useContext, useMemo } from "react";

import { CurrentProfile, CurrentRole, notNullOrUndefined, Role, useSiteContext } from "@equiem/lib";

import type {
  QueueQuery,
  QueuesQuery,
  ReqMgtCreateQueueInput,
  ReqMgtUpdateQueueInput,
} from "../../../generated/requests-client";
import {
  useCompaniesV2Query,
  useCreateQueueMutation,
  useQueuesQuery,
  useToggleQueueStatusMutation,
  useUpdateQueueMutation,
} from "../../../generated/requests-client";

export type Queue = NonNullable<QueueQuery["reqMgt"]["queue"]>;
export type ShortQueue = NonNullable<QueuesQuery["reqMgt"]["queues"][number]>;

export function useQueueData() {
  const { currentRole } = useContext(CurrentRole);
  const { profile, canManageRegion, canManageCurrentSite } = useContext(CurrentProfile);
  const { data, loading } = useQueuesQuery({
    variables: {
      ownerCompanyUuid:
        [Role.FlexManager, Role.PropertyManager].some((r) => r === currentRole) && !canManageRegion
          ? profile?.companyV2?.uuid
          : undefined,
    },
    skip:
      !canManageCurrentSite &&
      !canManageRegion &&
      ![Role.FlexManager, Role.PropertyManager].some((r) => r === currentRole),
  });
  const { uuid: siteUuid } = useSiteContext();
  const { data: companiesData, loading: companiesLoading } = useCompaniesV2Query({
    variables: { destinationUuid: siteUuid, first: 1000 },
  });
  const [createMutation, { loading: createLoading }] = useCreateQueueMutation();
  const [toggleMutation, { loading: toggleLoading }] = useToggleQueueStatusMutation();
  const [updateMutation, { loading: updateLoading }] = useUpdateQueueMutation();
  const create = useCallback(
    async (input: ReqMgtCreateQueueInput) => {
      return createMutation({
        variables: {
          input: input,
        },
        refetchQueries: ["Queues"],
      });
    },
    [createMutation],
  );

  const toggle = useCallback(
    async (uuid: string, active: boolean) => {
      return toggleMutation({
        variables: {
          uuid: uuid,
          active: active,
        },
        refetchQueries: ["Queues"],
      });
    },
    [toggleMutation],
  );

  const update = useCallback(
    async (uuid: string, input: ReqMgtUpdateQueueInput) => {
      return updateMutation({
        variables: {
          uuid: uuid,
          input: input,
        },
        refetchQueries: ["Queues"],
      });
    },
    [updateMutation],
  );

  const queues = useMemo(() => {
    return (
      data?.reqMgt.queues.map(
        (q) =>
          ({
            ...q,
          } satisfies ShortQueue),
      ) ?? []
    );
  }, [data]);

  const companies = useMemo(() => {
    return (
      companiesData?.companiesV2.edges
        .map((c) =>
          c.node?.uuid != null
            ? {
                uuid: c.node.uuid,
                name: c.node.name,
              }
            : undefined,
        )
        .filter(notNullOrUndefined) ?? []
    );
  }, [companiesData]);

  return {
    queues,
    companies,
    create,
    toggle,
    update,
    canCreateQueues: canManageRegion,
    canEditQueues: [Role.FlexManager, Role.PropertyManager].some((r) => r === currentRole),
    loading: loading || companiesLoading || createLoading || toggleLoading || updateLoading,
  };
}
