import { useContext } from "react";
import { CurrentRole, useSiteContext } from "../context";
import { Role } from "../Role";

export function useRequestEnabled() {
  const { currentRole } = useContext(CurrentRole);
  const site = useSiteContext();

  if (!site.featureModules.requests.enabled) {
    return false;
  }

  if (!site.featureMenus.requestsEnabled && currentRole !== Role.PropertyManager) {
    return false;
  }

  return true;
}
