import { useDisabledSiteSwitcher } from "@equiem/lib";
import React from "react";
import { EditArticle as EditArticleComponent } from "../components/articles/EditArticle";
import { PortfolioProvider } from "../contexts/PortfolioProvider";

export const EditArticle = () => {
  useDisabledSiteSwitcher();

  return (
    <PortfolioProvider>
      <EditArticleComponent />
    </PortfolioProvider>
  );
};
