import type { FC, KeyboardEvent, PropsWithChildren, ReactElement } from "react";
import React, { useContext, useMemo } from "react";

import { Dropdown, DropdownContext } from "../Dropdown";
import type { IconBaseProps, IconType } from "react-icons";
import { DropdownMenu } from "../DropdownMenu/DropdownMenu";
import type { Placement } from "@floating-ui/react-dom";
import type { sizes } from "../../../util/sizes";
import { useSize } from "../../../util/useSize";
import { useTheme } from "../../../contexts/Theme";
import type { NavContext } from "../../../contexts/NavContext";

interface InnerProps extends Omit<IconBaseProps, "onSelect"> {
  icon: IconType;
  hoverIcon?: IconType;
  isHoverTrigger?: boolean;
  inverted?: boolean;
  size?: number | keyof typeof sizes;
}

export interface Props extends InnerProps {
  placement?: Placement;
  onSelect?: (eventKey: string) => undefined;
  fontWeight?: NavContext["fontWeight"];
  fixedHeader?: ReactElement;
  width?: number;
  maxHeight?: number;
  supportsMobile?: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-type-alias
export type DropdownIconProps = PropsWithChildren<Props>;

export interface DropdownIconRef extends HTMLDivElement {
  click: () => void;
  focus: () => void;
}

const Inner: FC<InnerProps> = ({ icon: Icon, hoverIcon: HoverIcon, size = "md", inverted = false, ...props }) => {
  const propSize = useSize(size);
  const dropdown = useContext(DropdownContext);
  const { colors, isAdmin } = useTheme();

  const iconSize = () => {
    switch (size) {
      case "xs":
        return 10;
      case "sm":
        return 16;
      case "md":
        return 20;
      case "lg":
        return 50;
      case "xl":
        return 100;
      default:
        return 20;
    }
  };

  const onClick = () => {
    dropdown.setShow(!dropdown.show);
  };

  const onKeyDown = (e: KeyboardEvent) => {
    if (e.key === "Enter") {
      dropdown.setShow(!dropdown.show);
    }
  };

  const onMouseEnter = () => {
    if (props.isHoverTrigger === true) {
      dropdown.setShow(true);
    }
  };

  const onMouseLeave = () => {
    if (props.isHoverTrigger === true) {
      dropdown.setShow(false);
    }
  };

  const hoverColor = useMemo(() => {
    return isAdmin ? colors.grayscale[60] : colors.primary;
  }, [isAdmin, colors]);

  return (
    <>
      <div
        ref={dropdown.reference}
        onClick={onClick}
        onKeyDown={onKeyDown}
        tabIndex={0}
        className={props.className}
        onMouseLeave={onMouseLeave}
        onMouseEnter={onMouseEnter}
      >
        <span>
          {HoverIcon !== undefined && <HoverIcon {...props} size={iconSize()} className="hover" />}

          <Icon {...props} size={iconSize()} className="icon" />
        </span>
      </div>
      <style jsx>{`
        div {
          display: inline-block;
          align-items: center;
          justify-content: center;
          width: ${propSize}px;
          height: ${propSize}px;
          background: ${dropdown.show ? (inverted ? colors.white : colors.grayscale[5]) : "none"};
          border-radius: 50%;
          cursor: pointer;
          color: ${dropdown.show
            ? hoverColor
            : isAdmin
            ? inverted
              ? colors.white
              : colors.medium
            : inverted
            ? colors.white
            : colors.dark};
        }
        span {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        div:hover {
          color: ${hoverColor};
          background: ${inverted ? colors.white : isAdmin ? colors.grayscale[5] : colors.lightHover};
          border: none;
        }
        .icon {
          display: ${dropdown.show && HoverIcon !== undefined ? "none" : "inline-block"};
        }
        div:hover > span > .icon {
          display: ${HoverIcon !== undefined ? "none" : "block"};
        }
        .hover {
          display: ${dropdown.show ? "block" : "none"};
        }
        div:hover > span > .hover {
          display: block;
        }
      `}</style>
    </>
  );
};

export const DropdownIcon = React.forwardRef<DropdownIconRef, DropdownIconProps>(
  (
    {
      children,
      placement = "bottom-start",
      onSelect,
      fontWeight,
      fixedHeader,
      width,
      maxHeight,
      supportsMobile,
      ...props
    },
    _ref,
  ) => {
    const { isAdmin } = useTheme();

    return (
      <>
        <Dropdown
          placement={placement}
          onSelect={onSelect}
          fontWeight={fontWeight ?? (isAdmin ? "heavy" : "light")}
          flip
        >
          <Inner {...props} />
          <DropdownMenu
            maxHeight={maxHeight}
            fixedHeader={fixedHeader}
            width={width}
            mobileView={supportsMobile === true ? "minimal" : undefined}
          >
            {children}
          </DropdownMenu>
        </Dropdown>
      </>
    );
  },
);

DropdownIcon.displayName = "DropdownIcon";
