import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import type { ComponentProps, FC } from "react";
import React, { useState } from "react";
import { RiArrowDownSLine, RiArrowUpSLine } from "react-icons/ri";
import { useTheme } from "../../contexts/Theme";
import { NavContext } from "../../contexts/NavContext";

export interface Props extends ComponentProps<"div"> {
  title?: string;
  isExpandable?: boolean;
}

export const SideNav: FC<Props> = ({ title, isExpandable = true, children, ...props }) => {
  const [open, setOpen] = useState(true);
  const { colors, spacers, isAdmin } = useTheme();

  const handleClick = () => {
    if (isExpandable) {
      setOpen(!open);
    }
  };

  return (
    <NavContext.Provider value={{ fontWeight: isAdmin ? "heavy" : "light" }}>
      <div {...props}>
        {title !== undefined && (
          <div className="title" onClick={handleClick}>
            <h2>{title}</h2>
            {isExpandable ? (
              <div className="nav-switch">
                {open ? (
                  isAdmin ? (
                    <RiArrowDownSLine />
                  ) : (
                    <BsChevronDown size={10} />
                  )
                ) : isAdmin ? (
                  <RiArrowUpSLine />
                ) : (
                  <BsChevronUp size={10} />
                )}
              </div>
            ) : (
              <></>
            )}
          </div>
        )}

        {open && <>{children}</>}
      </div>
      <style jsx>{`
        .title {
          display: flex;
          align-items: center;
          justify-content: space-between;
          color: ${colors.medium};
          margin: ${spacers.s2} ${spacers.s3};
          cursor: ${isExpandable ? "pointer" : "default"};
        }
        .nav-switch {
          height: 20px;
          width: 20px;
          display: flex;
          border-radius: 50%;
          justify-content: center;
          align-items: center;
        }
        .nav-switch:hover {
          background: ${colors.grayscale[5]};
          color: ${colors.grayscale[60]};
        }
        h2 {
          text-transform: uppercase;
          font-weight: 500;
          font-size: 10px;
          line-height: 16px;
          margin: 0;
        }
      `}</style>
    </NavContext.Provider>
  );
};
