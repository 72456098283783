import { useContext, useMemo } from "react";
import { useSiteContext, CurrentProfile, stringIsEmpty } from "@equiem/lib";

import { useViewerBookingsLocationsQuery, type ViewerBookingsLocationsQuery } from "../../../generated/gateway-client";

export interface BookingsBuildingLevel {
  uuid: string;
  name: string;
}

export interface BookingsBuilding {
  uuid: string;
  name: string;
  address: string;
  buildingLevels: BookingsBuildingLevel[];
}

export interface BookingsSite {
  uuid: string;
  name: string;
  buildings: BookingsBuilding[];
}

const toLocations = (data: ViewerBookingsLocationsQuery | undefined): BookingsSite[] => {
  return (data?.myManageableBookableResourceLocations ?? [])
    .map((s) => ({
      uuid: s.destinationUuid,
      name: s.destination?.name ?? "",
      buildings: s.buildings.map((b) => ({
        uuid: b.buildingUuid,
        name: b.building.name,
        address: b.building.address,
        buildingLevels: b.buildingLevels,
      })),
    }))
    .sort((a, b) => a.name.localeCompare(b.name));
};

export const useAuthorizedDestinationDetails = (ownerCompanyUuid: string | null | undefined) => {
  const currentSiteCtx = useSiteContext();
  const { canManageRegion } = useContext(CurrentProfile);

  const skipOwnerQuery = stringIsEmpty(ownerCompanyUuid) || !canManageRegion;

  const viewer = useViewerBookingsLocationsQuery();
  const owner = useViewerBookingsLocationsQuery({ variables: { companyUuid: ownerCompanyUuid }, skip: skipOwnerQuery });

  return useMemo(() => {
    const viewerLocations = toLocations(viewer.data);
    if (!viewer.loading && viewer.error == null && viewerLocations.every((s) => s.uuid !== currentSiteCtx.uuid)) {
      // always include the current site, even if the user has no relevant roles here
      viewerLocations.push({ uuid: currentSiteCtx.uuid, name: currentSiteCtx.name, buildings: [] });
      viewerLocations.sort((a, b) => a.name.localeCompare(b.name));
    }

    return {
      loading: viewer.loading || owner.loading,
      error: viewer.error,
      viewerLocations,
      ownerCompanyLocations: canManageRegion ? toLocations(owner.data) : viewerLocations,
    };
  }, [
    currentSiteCtx.uuid,
    currentSiteCtx.name,
    canManageRegion,
    viewer.data,
    viewer.loading,
    viewer.error,
    owner.data,
    owner.loading,
  ]);
};
