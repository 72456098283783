import { Widget as WidgetUI } from "@equiem/react-admin-ui";
import type { FC } from "react";
import { useContext, useState } from "react";
import type { Role, WidgetProps } from "@equiem/lib";
import { CurrentRole, ErrorBoundary, CurrentProfile, Site } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";

export interface ShortcutProps {
  currentRole: Role;
}

export interface Props {
  Component: FC<WidgetProps>;
}

export const Widget: FC<Props> = ({ Component }) => {
  const [loading, setLoading] = useState(true);
  const { currentRole } = useContext(CurrentRole);
  const { uuid } = useContext(Site);
  const { t } = useTranslation();
  const { profile } = useContext(CurrentProfile);

  return (
    <>
      {loading && <WidgetUI.Widget buttonText={t("visitors.widgets.backToTop")} isLoading />}
      <ErrorBoundary>
        <div style={{ display: loading ? "none" : "block", height: "100%" }}>
          {profile != null && (
            <Component
              currentRole={currentRole}
              setLoading={setLoading}
              siteUuid={uuid}
              viewer={{
                uuid: profile.uuid,
                email: profile.email,
                firstName: profile.firstName ?? undefined,
                lastName: profile.lastName ?? undefined,
                company: profile.companyV2 ?? undefined,
              }}
            />
          )}
        </div>
      </ErrorBoundary>
    </>
  );
};
