import type { ReactNode } from "react";
import React, { createContext, useCallback, useContext, useEffect, useState } from "react";
import { useRouter } from "next/router";

import { stringNotEmpty, UrlParams } from "@equiem/lib";
import { useDebounced } from "@equiem/react-admin-ui";

export type RequestFilterType = "search";

interface CategoriesFilterContext {
  search?: string;
  debouncedSearch?: string;
  clearSearch: () => void;
  handleSearch: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const CategoriesFilterContext = createContext<CategoriesFilterContext>({
  search: undefined,
  clearSearch: () => {
    throw new Error("Not init");
  },
  handleSearch: () => {
    throw new Error("Not init");
  },
});

export const CategoriesFilterProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const { setParam, deleteParam } = useContext(UrlParams);
  const router = useRouter();
  const searchFromQueryParams = router.query.search as string | undefined;
  const [search, setSearch] = useState<string | undefined>(searchFromQueryParams ?? "");
  const debouncedSearch = useDebounced(search, 500);

  useEffect(() => {
    if (stringNotEmpty(debouncedSearch)) {
      if (debouncedSearch !== router.query.search) {
        setParam("search", debouncedSearch);
      }
    } else if (router.query.search != null) {
      deleteParam("search");
    }
  }, [debouncedSearch]);

  const handleSearch = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setSearch(e.target.value);
    },
    [setSearch],
  );

  const clearSearch = useCallback(() => {
    setSearch("");
  }, [setSearch]);

  return (
    <CategoriesFilterContext.Provider
      value={{
        clearSearch,
        handleSearch,
        search,
        debouncedSearch,
      }}
    >
      {children}
    </CategoriesFilterContext.Provider>
  );
};
