import React, { useContext, useEffect, useState } from "react";
import { useTheme } from "../../contexts/Theme";
import { Dropdown } from "../Dropdown";
import { DropdownMenu } from "../Dropdown/DropdownMenu/DropdownMenu";
import { FilterChip } from "./FilterChip";
import { ComplexFilterContext } from "./ComplexFilterContext";
import { Text } from "../Text/Text";
import * as Form from "../Form";
import { RiSearchLine } from "../../admin-icons";
import type { FilterItemOptions, FilterItemProps, FilterOption } from "./ComplexFilter.types";
import { FilterOptionsModifier, FilterType } from "./ComplexFilter.types";
import { useTranslation } from "@equiem/localisation-eq1";
import { ModifierSelect } from "./ModifierSelect";

import { useDebounced } from "../../hooks";

import { ProgressCircle } from "../ProgressCircle/ProgressCircle";

export const defaultModifier = FilterOptionsModifier.includes;

const RESERVE_SCROLL_SIZE = 50;

const saveWaitTimeMs = 500;
export const FilterOptionsV2: React.FC<FilterItemProps<FilterItemOptions>> = ({
  title,
  name,
  options,
  modifiers,
  fetchMore,
  hasMoreItems = true,
  loading = false,
  icon,
  searchCallback,
}) => {
  const { t } = useTranslation();
  const { colors } = useTheme();
  const { values, setValue, removeValue } = useContext(ComplexFilterContext);
  const [search, setSearch] = useState("");
  const [selectedModifier, setSelectedModifier] = useState(modifiers?.[0] ?? defaultModifier);
  const debouncedSearch = useDebounced(search, saveWaitTimeMs);
  const [prevSelectedModifier, setPrevSelectedModifier] = useState(selectedModifier);

  if (selectedModifier !== prevSelectedModifier) {
    setPrevSelectedModifier(selectedModifier);
    setValue(name, {
      type: FilterType.infiniteOptions,
      modifier: selectedModifier,
      value: undefined,
    });
  }

  const handleChange = (option: FilterOption) => () => {
    const currentValue = (values[name]?.value ?? []) as FilterOption[];
    const checked = !currentValue.map((c) => c.value).includes(option.value);

    if (checked) {
      setValue(name, {
        type: FilterType.infiniteOptions,
        modifier: selectedModifier,
        value: [...currentValue, option],
      });
    } else {
      setValue(name, {
        type: FilterType.infiniteOptions,
        modifier: selectedModifier,
        value: currentValue.filter((v) => v.value !== option.value),
      });
    }
  };

  const handleSearch = (searchValue: string) => {
    const value = (values[name]?.value ?? []) as FilterOption[];
    setSearch(searchValue);

    const filterValue = {
      type: FilterType.infiniteOptions,
      modifier: selectedModifier,
      value,
    } as const;
    setValue(name, searchValue.length > 0 ? { ...filterValue, search: searchValue } : filterValue);
    searchCallback?.(searchValue)?.catch(console.error);
  };

  const handleClose = () => {
    handleSearch("");
  };

  const isEmpty = selectedModifier === FilterOptionsModifier.empty;

  const getLocalizedTitle = (modifier: FilterOptionsModifier) => {
    switch (modifier) {
      case FilterOptionsModifier.includes:
        return t("common.includes");
      case FilterOptionsModifier.excludes:
        return t("common.excludes");
      case FilterOptionsModifier.empty:
        return t("common.isEmpty");
      default:
        return modifier;
    }
  };

  const valueTitle = isEmpty ? t("common.isEmpty") : values[name]?.value;

  useEffect(() => {
    searchCallback?.(debouncedSearch).catch(console.error);
  }, [debouncedSearch]);

  return (
    <>
      <Dropdown placement="bottom-start" flip onClose={handleClose}>
        <FilterChip
          title={title}
          value={valueTitle}
          name={name}
          type={FilterType.infiniteOptions}
          onClose={() => removeValue(name)}
          icon={icon}
        />
        <DropdownMenu width={252} mobileView="regular">
          {modifiers != null && (
            <div className="options-top">
              <Text variant="label" className="m-0">
                {title}
              </Text>
              <ModifierSelect
                options={modifiers.map((m) => ({
                  value: m,
                  label: getLocalizedTitle(m),
                }))}
                selected={selectedModifier}
                onChange={(v) => setSelectedModifier(v as FilterOptionsModifier)}
              />
            </div>
          )}
          {!isEmpty && (
            <>
              <Form.InputGroup.Group className="search-input">
                <Form.InputGroup.Prefix>
                  <RiSearchLine color={colors.muted1} size={16} />
                </Form.InputGroup.Prefix>
                <Form.Input
                  placeholder={`${t("common.search")}...`}
                  autoComplete="off"
                  onChange={(e) => handleSearch(e.target.value)}
                  type="text"
                />
              </Form.InputGroup.Group>
              <div
                onScroll={(e) => {
                  const target = e.target as HTMLDivElement;

                  if (loading || !hasMoreItems) {
                    return;
                  }

                  const isNearBottom =
                    target.scrollHeight - target.scrollTop <= target.clientHeight + RESERVE_SCROLL_SIZE;

                  if (isNearBottom) {
                    fetchMore?.();
                  }
                }}
                className="options-list"
              >
                {options.map((option) => (
                  <div
                    className={
                      ((values[name]?.value ?? []) as FilterOption[]).map((c) => c.value).includes(option.value)
                        ? "option option-active"
                        : "option"
                    }
                    key={option.value}
                    onClick={handleChange(option)}
                  >
                    <input
                      type="checkbox"
                      name={name}
                      onChange={handleChange(option)}
                      checked={((values[name]?.value ?? []) as FilterOption[])
                        .map((c) => c.value)
                        .includes(option.value)}
                      className="m-0"
                    />
                    <Text variant="text" component="label" size="small" className="ml-3">
                      {option.label}
                    </Text>
                  </div>
                ))}
                {loading && (
                  <ProgressCircle style={{ height: 40 }} className="d-flex justify-content-center" size="sm" />
                )}
              </div>
            </>
          )}
        </DropdownMenu>
      </Dropdown>
      <style jsx>{`
        .options-list {
          display: flex;
          flex-direction: column;
          gap: 0.125rem;
          padding: 0.5rem 0.5rem 0;
          overflow-y: auto;
        }
        :global(.search-input) {
          margin: 0 0.5rem;
        }
        .options-top {
          padding: 0 1rem ${isEmpty ? "0" : "0.25rem"};
          display: flex;
          flex-direction: row;
          align-items: center;
          color: ${colors.grayscale[100]};
        }

        .option {
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 0.5rem;
          cursor: pointer;
        }

        .option-active {
          background-color: ${colors.blue[10]};
          border-radius: 0.25rem;
        }

        input[type="checkbox"] {
          width: 1rem;
          height: 1rem;
          background: #ffffff;
          border: 1px solid ${colors.grayscale[20]};
          border-radius: 0.25rem;
          appearance: none;
          position: relative;
          margin: 0;
          flex-shrink: 0;
        }

        input[type="checkbox"]:checked {
          border: none;
        }

        input[type="checkbox"]:checked:before {
          content: "";
          background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAACXBIWXMAABYlAAAWJQFJUiTwAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACHSURBVHgB7ZPRDYAgDEQ7giM4ghs6GiM4AiMwwlmiH4YgqIEzIX1JwxfcC21FDMMwOgNg1nLxFDZnuMeBp0ok4ZFNaxIGY4R/6VfL8EUraK0v7rT79uShqgRa9/zyA1UJ9Bq4JxLoPe0lCbBWLScB9p5nJAItvCDBC7+R4IYnEu6XcMMwhmQHt/C0plArFXgAAAAASUVORK5CYII=");
          background-size: 1rem 1rem;
          background-repeat: no-repeat;
          background-position: center;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          border-radius: 0.25rem;
          background-color: ${colors.blue[60]};
          color: #fff;
        }
      `}</style>
    </>
  );
};
