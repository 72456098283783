import type { CSSProperties } from "react";
import { useTheme } from "@equiem/react-admin-ui";
import { useContext } from "react";
import { CurrentProfile } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";

export const Greeting = ({ style }: { style?: CSSProperties }) => {
  const { profile } = useContext(CurrentProfile);
  const { colors } = useTheme();
  const { t } = useTranslation();

  const hours = new Date().getHours();

  return (
    <h1
      style={{
        fontWeight: "bold",
        fontSize: 28,
        color: colors.transparent.black[80],
        ...style,
      }}
    >
      {hours < 12
        ? t("home.greetingAM", { name: profile?.firstName ?? "" })
        : t("home.greetingPM", { name: profile?.firstName ?? "" })}
    </h1>
  );
};
