import React, { forwardRef } from "react";
import type { ComponentProps } from "react";

import type { ThemeContext } from "../../../contexts";
import { useTheme } from "../../../contexts/Theme";

// eslint-disable-next-line @typescript-eslint/no-type-alias
type Props = ComponentProps<"li"> & {
  variant?: Extract<keyof ThemeContext["colors"], "light" | "white">;
  sticky?: "top" | "bottom";
  noBorder?: boolean;
  noMargin?: boolean;
  action?: boolean;
  highlighted?: boolean;
  highlightColor?: string;
};

export const ListGroupItem = forwardRef<HTMLLIElement, Props>(
  (
    {
      children,
      variant = "white",
      sticky,
      noBorder = false,
      noMargin = false,
      action = false,
      highlighted = false,
      className = "",
      highlightColor,
      ...props
    },
    ref,
  ) => {
    const { spacers, colors, borderRadius } = useTheme();

    return (
      <li
        className={`${className} ${action ? "action" : ""} ${highlighted ? "highlighted" : ""}`}
        tabIndex={action ? 0 : undefined}
        ref={ref}
        {...props}
      >
        {children}
        <style jsx>{`
          li {
            background: ${variant === "light" ? colors.light : colors.white};
            position: ${sticky != null ? "sticky" : "static"};
            ${sticky === "top" ? "top: 0;" : ""}
            ${sticky === "bottom" ? "bottom: 0;" : ""}
            left: 0;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: ${noMargin ? `${spacers.s3} ${spacers.s5}` : `${spacers.s3} ${spacers.s3}`};
            border-top: ${noBorder ? "none" : `1px solid ${colors.border}`};
            margin: ${noMargin ? "0" : `0 ${spacers.s3}`};
            border-radius: 0 0 ${borderRadius} ${borderRadius};
          }
          li:first-child {
            border-top: none;
            border-radius: ${borderRadius} ${borderRadius} 0 0;
          }
          li.highlighted,
          li.action:hover,
          li.action:focus {
            outline: none;
            cursor: pointer;
            background: ${highlightColor ?? colors.lightHover};
            margin: 0;
            padding: ${spacers.s3} ${spacers.s5};
            border-bottom: ${noBorder ? "none" : `1px solid ${colors.border}`};
          }
          li.highlighted + li,
          li.action:hover + li,
          li.action:focus + li {
            border-top: 0;
          }
        `}</style>
      </li>
    );
  },
);

ListGroupItem.displayName = "ListGroupItem";
