import type { ComponentProps, ReactNode } from "react";
import React, { useEffect, useState, useContext, useMemo } from "react";
import { useTheme } from "../../../contexts/Theme";
import { FormGroupContext } from "../../../contexts/FormGroupContext";
import { useShift } from "../../../util/useShift";

interface Props extends Omit<ComponentProps<"input">, "type" | "value" | "crossOrigin"> {
  label: ReactNode;
  value?: boolean;
  indeterminate?: boolean;
  detectShift?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>, shiftHeld?: boolean) => void;
}

export const FormCheckbox = React.forwardRef<HTMLInputElement, Props>((props, ref) => {
  const {
    label,
    value: propsValue,
    checked,
    onChange,
    className,
    indeterminate = false,
    detectShift = false,
    disabled = false,
    ...inputProps
  } = props;
  const theme = useTheme();
  const { id, hintId } = useContext(FormGroupContext);
  const [localValue, setLocalValue] = useState<boolean>(checked ?? propsValue ?? false);
  const [indeterminateClicked, setIndeterminateClicked] = useState(propsValue ?? false);
  const { shiftHeld } = useShift(detectShift);
  const inputId = useMemo(() => props.id ?? id ?? props.name, [id, props.id, props.name]);

  // To make sure it resets when the value from outside change.
  useEffect(() => {
    setIndeterminateClicked(false);
  }, [indeterminate]);

  useEffect(() => {
    const target = checked ?? propsValue ?? localValue;
    if (target !== localValue) {
      setLocalValue(target);
    }
  }, [checked, localValue, propsValue]);

  return (
    <>
      <div
        className={`${indeterminate && !indeterminateClicked ? "indeterminate " : ""}${className ?? ""} ${
          disabled ? "disabled" : ""
        }`}
      >
        <label>
          <input
            {...inputProps}
            ref={ref}
            id={inputId}
            aria-describedby={hintId}
            type="checkbox"
            checked={localValue}
            disabled={disabled}
            onChange={(e) => {
              setLocalValue(e.target.checked);
              if (!indeterminateClicked) {
                setIndeterminateClicked(true);
              }
              if (onChange != null) {
                onChange(e, shiftHeld);
              }
            }}
          />
          <span>{label}</span>
        </label>
      </div>
      <style jsx>{`
        div {
          position: relative;
          user-select: none;
        }
        div.disabled {
          opacity: 0.5;
        }
        input[type="checkbox"] {
          opacity: 0;
        }
        label {
          display: flex;
          align-items: center;
          gap: ${theme.spacers.s2};
        }
        div:not(.disabled) input,
        div:not(.disabled) label {
          cursor: pointer;
        }
        span:before {
          content: "";
          position: absolute;
          left: 0;
          top: 1px;
          width: 16px;
          height: 16px;
          background-color: ${theme.colors.light};
          border: 1px solid ${theme.colors.muted1};
          border-radius: 4px;
          pointer-events: none;
          user-select: none;
          z-index: 1;
        }
        div:not(.disabled) label:hover span:before {
          border-color: ${theme.colors.primary};
          box-shadow: 0px 0px 0px 4px ${theme.colors.lightHover};
        }
        .indeterminate span:before,
        input[type="checkbox"]:checked ~ span:before {
          border-color: ${theme.colors.primary};
          background: ${theme.colors.primary};
        }
        span:after {
          content: "";
          display: none;
          position: absolute;
          pointer-events: none;
          border: solid ${theme.colors.primaryContrast};
          border-width: 0 2px 2px 0;
          height: 10px;
          width: 4px;
          left: 6px;
          top: 3px;
          transform: rotate(45deg);
          z-index: 2;
        }
        input[type="checkbox"]:checked ~ span:after {
          display: block;
        }
        .indeterminate span:after {
          display: block;
          border: 1px solid ${theme.colors.primaryContrast};
          height: 1px;
          width: 6px;
          left: 5px;
          top: 8px;
          transform: none;
        }
      `}</style>
    </>
  );
});

FormCheckbox.displayName = "FormCheckbox";
