import type { Dispatch, SetStateAction } from "react";
import React, { useContext, useMemo, useState } from "react";
import type { FormikHelpers, FormikProps } from "formik";
import { Formik } from "formik";

import type { AccessGroupsDefaultValues, CompanyFormValues } from "@equiem/lib";
import { companyValidationSchema, Site } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import type { TabItem } from "@equiem/react-admin-ui";
import { Alert, Button, Modal, Skeleton, Tabs, useIsMobileWidth, useTheme } from "@equiem/react-admin-ui";
import { RiErrorWarningLine } from "@equiem/react-admin-ui/icons";

import { Modal as ModalContext } from "../../../contexts/ModalContext";
import { useWidgetContext } from "../../../contexts/WidgetContext";
import { useAccessGroupsQuery } from "../../../generated/settings-client";
import { useCompanyDetails } from "../hooks/useCompanyDetails";

import { AddCompanyForm } from "./AddCompanyForm";
import { CompanyAccessGroups } from "./CompanyAccessGroups";
import { CompanyBilling } from "./CompanyBilling";
import { CompanyIntegrations } from "./CompanyIntegrations";
import { CompanyPermissions } from "./CompanyPermissions";

interface Props {
  handleSubmit: (
    initialValues: CompanyFormValues,
  ) => (values: CompanyFormValues, { setSubmitting }: FormikHelpers<CompanyFormValues>) => Promise<void>;
  isEdit: boolean;
  showModal: boolean;
  onCloseModal: () => void;
  onClose: () => void;
  selectedTab: string;
  setSelectedTab: Dispatch<SetStateAction<string>>;
  enabledTabs: TabItem[];
  formInnerRef: React.Ref<FormikProps<CompanyFormValues>>;
}

export const CompanyDetailsForm: React.FC<Props> = ({
  handleSubmit,
  isEdit,
  onCloseModal,
  onClose,
  showModal,
  selectedTab,
  setSelectedTab,
  enabledTabs,
  formInnerRef,
}) => {
  const { t } = useTranslation();
  const modal = useContext(ModalContext);
  const site = useContext(Site);
  const { companyData, companyLoading } = useCompanyDetails({
    companyUuid: modal.id,
    siteUuid: site.uuid,
  });
  const { data: groups } = useAccessGroupsQuery({ variables: { siteUuid: site.uuid } });
  const isMobile = useIsMobileWidth();
  const { spacers, colors } = useTheme();
  const { handleSave, actionsState } = useWidgetContext();
  const [accessPassesFormValues, setAccessPassesFormValues] = useState<CompanyFormValues | undefined>(undefined);

  const companyDestination = companyData?.companyDestination;
  const accessGroupsDefaultValues: AccessGroupsDefaultValues = {
    accessGroupsUuids: companyDestination?.ac1Config?.accessGroups?.map((group) => group.uuid) ?? [],
    defaultAccessGroupUuid: companyDestination?.ac1Config?.defaultAccessGroup?.uuid,
  };

  const initialValues: CompanyFormValues = useMemo(
    () => ({
      name: companyData?.name ?? "",
      industry: companyData?.industry?.uuid ?? "",
      attributes: companyDestination?.attributes.map((a) => a.uuid) ?? [],
      validationValues: companyData?.validation?.values.map((v) => v.value),
      levels: companyDestination?.buildingLevels.map((l) => l.uuid),
      autoApproveRegistrations: companyLoading ? undefined : companyDestination?.autoApproveRegistrations ?? false,
      defaultAccessGroupUuid: accessGroupsDefaultValues.defaultAccessGroupUuid,
      accessGroupsUuids: accessGroupsDefaultValues.accessGroupsUuids,
      accessPasses:
        companyData?.companyDestination?.ac1Config?.companySiteCredentialSetupConfigs?.map((pass) => ({
          autoAssignment: pass.autoAssignment,
          accessPassesMaxNumber: pass.accessPassesMaxNumber,
          uuid: pass.credentialSetup.uuid,
          enabled: pass.enabled,
          category: pass.credentialSetup.category,
          passName: pass.credentialSetup.passName,
        })) ?? [],
      addsCardsAutomatically: false, // todo we'll need to populate this once this is available in AC1 backend, see: https://equiem.atlassian.net/browse/AC1-740
    }),
    [companyLoading, companyData, companyDestination],
  );

  return (
    <>
      <Modal.Header
        intro={!isMobile ? (isEdit ? companyData?.name.trim() ?? "" : t("settings.createCompany.newCompany")) : null}
        closeButton
        noBorder={!isMobile}
        onClose={onCloseModal}
        supportsMobile
      />
      <Formik
        initialValues={accessPassesFormValues ?? initialValues}
        enableReinitialize={true}
        innerRef={formInnerRef}
        validationSchema={companyValidationSchema(t)}
        onSubmit={handleSubmit(initialValues)}
      >
        <>
          <Modal.Body noPadding>
            {companyLoading ? (
              <div className="px-6 mt-5">
                <Skeleton.Line width="80px" height="1.5rem" className="mr-4" />
                <Skeleton.Line width="80px" height="1.5rem" className="mr-4" />
                <Skeleton.Line width="80px" height="1.5rem" className="mb-0" />
                <Skeleton.Line width="100%" height="1px" />
                <Skeleton.Line width="90px" height="1.5rem" className="mt-6 mb-3" />
                <Skeleton.Line width="100%" height="2rem" />
                <Skeleton.Line width="120px" height="1.5rem" className="mt-6 mb-3" />
                <Skeleton.Line width="100%" height="2rem" />
                <Skeleton.Line width="105px" height="1.5rem" className="mt-6 mb-3" />
                <Skeleton.Line width="100%" height="2rem" />
              </div>
            ) : (
              <>
                {isEdit && (
                  <>
                    <div className="px-6">
                      <Tabs items={enabledTabs} selected={selectedTab} onSelect={setSelectedTab} />
                    </div>
                    <hr className="mt-0 pb-4" />
                  </>
                )}
                <div className="content">
                  {isEdit && selectedTab !== "integrations" && (
                    <Alert
                      size="large"
                      variant="gray"
                      icon={<RiErrorWarningLine size={18} color={colors.grayscale[50]} />}
                      message={t("settings.editCompany.editCompanyInfo")}
                      className="mb-6"
                    />
                  )}
                  {!isEdit && (
                    <Alert
                      size="large"
                      variant="gray"
                      icon={<RiErrorWarningLine size={18} color={colors.grayscale[50]} />}
                      message={t("settings.editCompany.addCompanyInfo")}
                      className="mb-6"
                    />
                  )}
                  {companyData != null && (
                    <>
                      {selectedTab === "integrations" && <CompanyIntegrations company={companyData} />}
                      {selectedTab === "permissions" && (
                        <CompanyPermissions modalClose={onClose} company={companyData} />
                      )}
                      {selectedTab === "billing" && <CompanyBilling modalClose={onClose} company={companyData} />}
                      {selectedTab === "groups" && groups?.accessGroups != null && (
                        <CompanyAccessGroups
                          company={companyData}
                          accessGroups={groups.accessGroups}
                          onAccessPassesChange={setAccessPassesFormValues}
                        />
                      )}
                    </>
                  )}
                  {selectedTab === "general" && <AddCompanyForm isEdit={isEdit} open={showModal} />}
                </div>
              </>
            )}
          </Modal.Body>
          {actionsState.onSave !== "hidden" && (
            <Modal.Footer>
              <Button variant="ghost" className="mr-4" onClick={onCloseModal}>
                {t("common.cancel")}
              </Button>
              <Button
                disabled={["loading", "disabled"].includes(actionsState.onSave)}
                type="submit"
                variant="primary"
                onClick={handleSave}
              >
                {isEdit ? t("settings.createCompany.saveChanges") : t("settings.createCompany.createCompany")}
              </Button>
            </Modal.Footer>
          )}
        </>
      </Formik>

      <style jsx>{`
        .title {
          font-weight: 400;
          font-size: 18px;
          line-height: 28px;
        }
        .content {
          padding: 0 ${spacers.s6} 0;
        }
        hr {
          border: none;
          border-top: 1px solid ${colors.border};
          margin-bottom: 16px;
        }
      `}</style>
    </>
  );
};
