import type { PropsWithChildren } from "react";
import React from "react";
import { useTheme } from "@equiem/react-admin-ui";
import type { BookingPaymentStatus as Status } from "../../generated/gateway-client";
import { useTranslation } from "@equiem/localisation-eq1";

const Wrapper: React.FC<PropsWithChildren> = ({ children }) => <div className="payment-status">{children}</div>;

interface P {
  status?: Status | null;
}
export const BookingPaymentStatus: React.FC<P> = ({ status }) => {
  const theme = useTheme(true);
  const { t } = useTranslation();

  if (status == null) {
    return <Wrapper>-</Wrapper>;
  }

  switch (status) {
    case "FAILED":
      return (
        <Wrapper>
          <span style={{ color: theme.colors.danger }}>{t("common.paymentFailed")}</span>
        </Wrapper>
      );
    case "PAID":
      return <Wrapper>{t("common.paid")}</Wrapper>;
    case "PENDING":
      return <Wrapper>{t("common.paymentPending")}</Wrapper>;
    case "REFUNDED":
      return <Wrapper>{t("common.refunded")}</Wrapper>;
    case "REFUND_FAILED":
      return <Wrapper>{t("common.refundFailed")}</Wrapper>;
    default:
      return <Wrapper>{status}</Wrapper>;
  }
};
