export const getScrollableParent = (node: HTMLElement | null): HTMLElement | null => {
  if (node == null) {
    return null;
  }

  try {
    if (node.scrollHeight > node.clientHeight) {
      return node;
    } else {
      return getScrollableParent(node.parentNode as HTMLElement);
    }
  } catch {
    return null;
  }
};
