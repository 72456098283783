import { useMemo } from "react";

import { notNullOrUndefined, stringIsEmpty, useSaferFormikContext, useSiteContext } from "@equiem/lib";

import {
  AccessControlSolution,
  useAccessGroupsQuery,
  useBarrierControlAccessValuesQuery,
} from "../../generated/visitors-client";
import type { ReceptionFormValues } from "../ReceptionFormValues";

/** transform access groups into uuid-name pairs */
const transformUuidName = <T extends { uuid: string; name: string }>(accessGroupsData: Array<T | null> | undefined) =>
  accessGroupsData?.filter(notNullOrUndefined).map(({ uuid, name }) => ({ uuid, name })) ?? [];

export const useReceptionFormBarrierControlAccesses = () => {
  const { values } = useSaferFormikContext<ReceptionFormValues>();
  const { accessControl, uuid: siteUuid } = useSiteContext();

  /** use standard Barrier Control or AC1? default to Barrier Control */
  const isBarrierControl = accessControl.solution === AccessControlSolution.Vicinitee;

  const { data: barrierControlAccessesData, loading } = useBarrierControlAccessValuesQuery({
    variables: {
      buildingUuid: values.buildingUuid,
    },
    skip: !isBarrierControl || stringIsEmpty(values.buildingUuid),
  });

  const { data: ac1AccessGroupsData, loading: ac1AccessGroupsLoading } = useAccessGroupsQuery({
    variables: { siteUuid, buildingUuid: values.buildingUuid },
    skip: isBarrierControl || stringIsEmpty(values.buildingUuid),
  });

  // A list of all the barrier control access groups at the current site for the selected building.
  const barrierControlAccesses = useMemo(
    () =>
      isBarrierControl
        ? transformUuidName(barrierControlAccessesData?.barrierControlAccessValues)
        : transformUuidName(ac1AccessGroupsData?.accessGroups),
    [isBarrierControl, barrierControlAccessesData?.barrierControlAccessValues, ac1AccessGroupsData?.accessGroups],
  );

  const selectedBarrierControlAccess = useMemo(
    () =>
      barrierControlAccesses.find(
        (barrierControlAccess) => barrierControlAccess.uuid === values.barrierControlAccessUuid,
      ),
    [values.barrierControlAccessUuid, barrierControlAccesses],
  );

  return {
    barrierControlAccesses,
    selectedBarrierControlAccess,
    loading: isBarrierControl ? loading : ac1AccessGroupsLoading,
  };
};
