import { createContext } from "react";
import type { PortfolioFragmentFragment } from "../generated/gateway-client";

export interface PortfolioContext {
  currentPortfolio: PortfolioFragmentFragment | null;
  myPortfolios: PortfolioFragmentFragment[];
  setCurrentPortfolio: (_portfolio: PortfolioFragmentFragment) => void;
}

export const CurrentPortfolio = createContext<PortfolioContext>({
  currentPortfolio: null,
  myPortfolios: [],
  setCurrentPortfolio: () => undefined,
});
