import { stringIsEmpty } from "@equiem/lib";
import { DateTime } from "luxon";
import type { PropsWithChildren } from "react";
import React, { createContext, useState, useMemo, useCallback } from "react";
import { dateFormat, formatSelectedTime } from "../libs/formatSelectedTime";

type SelectedTimeInput = { start?: string | null; end?: string | null };

interface BookingCalendarContextType {
  selectedDate: string;
  selectedTime: { start?: number; end?: number } | null;
  setSelectedDate: (timezone: string, date: string) => void;
  setSelectedTime: (timezone: string, input: SelectedTimeInput) => void;
}

export const BookingCalendarContext = createContext<BookingCalendarContextType>({
  selectedDate: DateTime.local().toFormat(dateFormat),
  selectedTime: null,
  setSelectedDate: () => undefined,
  setSelectedTime: () => undefined,
});

export const BookingCalendarContextProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [selectedTimezone, setTimezone] = useState<string | null>();
  const [selectedDate, setSelectedDate] = useState(DateTime.local().toFormat(dateFormat));
  const [selectedStartTime, setSelectedStartTime] = useState("");
  const [selectedEndTime, setSelectedEndTime] = useState("");

  const handleSelectedDateChange = useCallback((timezone: string, newSelectedDate: string) => {
    if (stringIsEmpty(newSelectedDate) || stringIsEmpty(timezone)) {
      return;
    }

    const parsed = DateTime.fromFormat(newSelectedDate, dateFormat);
    if (!parsed.isValid) {
      return;
    }

    setTimezone(timezone);
    setSelectedDate(parsed.toFormat(dateFormat));
  }, []);

  const setSelectedTime = useCallback((timezone: string, input: SelectedTimeInput) => {
    setTimezone(timezone);

    if (input.start !== undefined) {
      setSelectedStartTime(input.start ?? "");
    }
    if (input.end !== undefined) {
      setSelectedEndTime(input.end ?? "");
    }
  }, []);

  const selectedTime = useMemo(() => {
    return selectedTimezone != null
      ? formatSelectedTime(selectedTimezone, selectedDate, selectedStartTime, selectedEndTime)
      : null;
  }, [selectedTimezone, selectedStartTime, selectedEndTime, selectedDate]);

  return (
    <BookingCalendarContext.Provider
      value={{
        selectedDate,
        setSelectedDate: handleSelectedDateChange,
        selectedTime,
        setSelectedTime,
      }}
    >
      {children}
    </BookingCalendarContext.Provider>
  );
};
