import type { ComponentProps, PropsWithChildren } from "react";
import React from "react";
import { useTheme } from "../../../contexts/Theme";

export const DropdownHeader: React.FC<PropsWithChildren<ComponentProps<"h5">>> = ({ children, ...props }) => {
  const { colors, spacers } = useTheme();

  return (
    <>
      <h1 {...props}>{children}</h1>
      <style jsx>{`
        h1 {
          margin: ${spacers.s2} ${spacers.s5};
          cursor: default;
          text-transform: uppercase;
          font-weight: 500;
          font-size: 10px;
          line-height: 16px;
          color: ${colors.medium};
        }
      `}</style>
    </>
  );
};
