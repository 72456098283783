import React from "react";
import { useTranslation } from "@equiem/localisation-eq1";
import type { NextPage } from "next";
import Head from "next/head";

export const NoAccessPage: NextPage = () => {
  const { t } = useTranslation();
  return (
    <>
      <Head>
        <title>{t("home.noAccess")}</title>
      </Head>
      <div className="m-8 cnt" data-eq-test="denied">
        <h1 className="m-5">{t("home.youDontHavePermission")}</h1>
        <h2>{t("home.shouldHaveAccessHelp")}</h2>
      </div>
      <style jsx>{`
        div.cnt {
          text-align: center;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }
        h1 {
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
          color: #666666;
        }
        h2 {
          font-size: 12px;
          line-height: 16px;
          color: #666666;
          margin-bottom: 24px;
        }
        img {
          width: 100%;
          max-width: 500px;
        }
      `}</style>
    </>
  );
};
