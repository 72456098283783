import { useDisabledSiteSwitcher } from "@equiem/lib";
import React from "react";
import { Menu } from "../components/shared/Menu";
import { PortfolioProvider } from "../contexts/PortfolioProvider";

export const Dashboard = () => {
  useDisabledSiteSwitcher();

  return (
    <PortfolioProvider>
      <Menu />
    </PortfolioProvider>
  );
};
