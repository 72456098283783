import { useTranslation } from "@equiem/localisation-eq1";

export const useAppointmentsHeaders = (enableAccessCard?: boolean) => {
  const { t } = useTranslation();

  return [
    { label: t("common.time") },
    { label: t("visitors.common.visitor") },
    { label: t("common.host") },
    { label: t("visitors.common.location") },
    { label: t("visitors.reception.meetingTitle"), className: "large-column" },
    ...(enableAccessCard !== true ? [] : [{ label: t("visitors.reception.cardId"), className: "large-column" }]),
    { label: t("visitors.reception.details") },
    { label: t("visitors.reception.action") },
    { label: t("visitors.reception.notes") },
    { label: "" },
  ];
};
