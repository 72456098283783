import React from "react";

import { Button, Tag, useTheme } from "@equiem/react-admin-ui";
import { RiDeleteBin6Line } from "@equiem/react-admin-ui/icons";

import type { CreditPackage } from "../FormValues";
import { NetPrice } from "./NetPrice";
import { notNullOrUndefined, stringNotEmpty } from "@equiem/lib";

interface Props {
  creditPackage: CreditPackage;
  onRemove: () => void;
}

export const PreviewCreditPackage = ({
  creditPackage: { discount, netPrice, creditsAmount, discountedNetPrice },
  onRemove,
}: Props) => {
  const { colors } = useTheme();

  const convertDiscount = (_discount: number) => `-${_discount}%`;

  const creditPackageDiscount =
    typeof discount === "number" || (typeof discount === "string" && stringNotEmpty(discount))
      ? Number(discount)
      : discount;

  return (
    <>
      <td className="credit-package-column">
        <div>{creditsAmount}</div>
      </td>
      <td className="credit-package-column">
        <div className="net-price">
          <NetPrice
            netPrice={netPrice}
            discount={creditPackageDiscount}
            discountedNetPrice={Number(discountedNetPrice)}
          />
        </div>
      </td>
      <td className="credit-package-icons">
        <>
          {notNullOrUndefined(creditPackageDiscount) &&
            typeof creditPackageDiscount === "number" &&
            creditPackageDiscount > 0 && (
              <Tag
                className="discount"
                variant="primary-counter"
                type="counter"
                size="small"
                value={convertDiscount(creditPackageDiscount)}
              />
            )}

          <div className="controls">
            <Button
              variant="ghost"
              round
              onClick={() => {
                onRemove();
              }}
            >
              <RiDeleteBin6Line size={16} color={colors.danger} />
            </Button>
          </div>
        </>
      </td>
      <style jsx>{`
        .credit-package-column {
          width: 40%;
        }

        .default-option {
          text-transform: lowercase;
        }

        .default-appointment-type {
          opacity: 0.5;
        }

        .enable-visitors {
          display: flex;
          align-items: center;
          gap: 8px;
        }

        .controls {
          display: flex;
          align-items: center;
          gap: 8px;
        }

        .credit-package-icons {
          width: 100%;
          display: flex;
          height: 50px;
          gap: 8px;
          justify-content: flex-end;
          color: ${colors.grayscale[60]};
        }

        .credit-package-icons :global(.discount) {
          align-self: center;
        }
      `}</style>
    </>
  );
};
