import { useTranslation } from "@equiem/localisation-eq1";
import { Form, useTheme } from "@equiem/react-admin-ui";
import { Field, useFormikContext } from "formik";
import { DateTime } from "luxon";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { toSeconds, parseTime } from "../../../lib/dateTimeHelpers";
import { BookingModalInfo } from "../contexts/BookingModalInfoProvider";
import { BookingCalendarContext } from "../contexts/BookingCalendarContext";
import type { BookingFormValue } from "../models/BookingFormValue";
import type { ListingTime } from "../models/ListingTime";
import { stringNotEmpty } from "@equiem/lib";

const defaultMinTimeInMinutes = 15;

interface P {
  selectFirstAvailability?: boolean;
  listingTimes: ListingTime[];
  date: number;
}
export const BookingFormFlexibleTime: React.FC<P> = ({ listingTimes, date, selectFirstAvailability = false }) => {
  const { t } = useTranslation();
  const { spacers } = useTheme();
  const { timezone } = useContext(BookingModalInfo);
  const { setSelectedTime } = useContext(BookingCalendarContext);
  const fm = useFormikContext<BookingFormValue>();
  const hasSuperPower = fm.values.hasSuperPower === true;

  const today = DateTime.fromObject({}, { zone: timezone });
  const isToday = today.hasSame(DateTime.fromMillis(date, { zone: timezone }), "day");

  const { minStartTimeHour, minStartTimeMinute, maxEndTimeHour, maxEndTimeMinute } = useMemo(() => {
    const minStartTimeHourDefault = 0;
    const minStartTimeMinuteDefault = 0;
    const maxEndTimeHourDefault = 23;
    const maxEndTimeMinuteDefault = 59;
    const timeStep = 15;

    if (listingTimes.length === 0) {
      return {
        minStartTimeHour: minStartTimeHourDefault,
        minStartTimeMinute: minStartTimeMinuteDefault,
        maxEndTimeHour: maxEndTimeHourDefault,
        maxEndTimeMinute: maxEndTimeMinuteDefault,
      };
    }

    let minStart = Infinity;
    let maxEnd = -Infinity;
    listingTimes.forEach((timeSlot) => {
      const timeSlotMinStart = timeSlot.times[0].start;
      if (timeSlotMinStart < minStart) {
        minStart = timeSlotMinStart;
      }
      // eslint-disable-next-line @typescript-eslint/no-magic-numbers
      const timeSlotMaxEnd = timeSlot.times.slice(-1)[0].end;
      if (timeSlotMaxEnd > maxEnd) {
        maxEnd = timeSlotMaxEnd;
      }
    });

    let minStartTime = null;
    if (minStart !== Infinity) {
      minStartTime = DateTime.fromMillis(minStart, { zone: timezone });
      const remainder = minStartTime.minute % timeStep;
      if (remainder > 0) {
        minStartTime = minStartTime.plus({ minutes: timeStep - remainder });
      }
    }
    const maxEndTime = maxEnd !== -Infinity ? DateTime.fromMillis(maxEnd, { zone: timezone }) : null;

    const returnMinStartTimeHour = minStartTime?.hour ?? minStartTimeHourDefault;
    const returnMinStartTimeMinute = minStartTime?.minute ?? minStartTimeMinuteDefault;

    if (hasSuperPower) {
      return {
        minStartTimeHour: isToday ? returnMinStartTimeHour : minStartTimeHourDefault,
        minStartTimeMinute: isToday ? returnMinStartTimeMinute : minStartTimeMinuteDefault,
        maxEndTimeHour: maxEndTimeHourDefault,
        maxEndTimeMinute: maxEndTimeMinuteDefault,
      };
    }

    return {
      minStartTimeHour: returnMinStartTimeHour,
      minStartTimeMinute: returnMinStartTimeMinute,
      maxEndTimeHour: maxEndTime?.hour ?? maxEndTimeHourDefault,
      maxEndTimeMinute: maxEndTime?.minute ?? maxEndTimeMinuteDefault,
    };
  }, [hasSuperPower, isToday, listingTimes, timezone]);

  // Select the first availability when needed.
  const [firstSelectionDone, setFirstSelectionDone] = useState(0);
  useEffect(() => {
    if (
      !selectFirstAvailability ||
      firstSelectionDone === date ||
      stringNotEmpty(fm.values.start) ||
      stringNotEmpty(fm.values.end)
    ) {
      return;
    }
    const dt = DateTime.now().set({ hour: minStartTimeHour, minute: minStartTimeMinute });
    const start = dt.toFormat("HH:mm");
    const endDt = dt.plus({ minute: listingTimes[0].minTimeInMinutes ?? defaultMinTimeInMinutes });
    const end = endDt.toFormat("HH:mm");
    setSelectedTime(timezone, { start: start, end });
    fm.setValues({ ...fm.values, start, end }).catch(console.error);

    setFirstSelectionDone(date);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fm.values.end, fm.values.start, firstSelectionDone, selectFirstAvailability]);

  useEffect(() => {
    if (!selectFirstAvailability) {
      setSelectedTime(timezone, { start: fm.values.start ?? "" });
      fm.setFieldValue("start", fm.values.start ?? "").catch(console.error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fm.values.start]);

  useEffect(() => {
    if (!selectFirstAvailability) {
      setSelectedTime(timezone, { end: fm.values.end ?? "" });
      fm.setFieldValue("end", fm.values.end ?? "").catch(console.error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fm.values.end]);

  const startError = fm.touched.start === true ? fm.errors.start : undefined;
  const endError = fm.touched.end === true ? fm.errors.end : undefined;

  return (
    <div className="date-time-form">
      <Form.Group label={t("bookings.operations.startTime")} error={startError} required>
        <Field name="start">
          {() => (
            <Form.TimeSelect
              id="start"
              name="start"
              defaultOptionValue={t("bookings.settings.startTime")}
              value={toSeconds(fm.values.start) ?? 0}
              onChange={(e) => {
                const time = parseTime(e);
                setSelectedTime(timezone, { start: time });
                fm.setFieldValue("start", time).catch(console.error);
                fm.setFieldTouched("start").catch(console.error);
              }}
              startTime={{ hour: minStartTimeHour, minute: minStartTimeMinute }}
              endTime={{ hour: maxEndTimeHour, minute: maxEndTimeMinute }}
              diffMinutes={15}
            />
          )}
        </Field>
      </Form.Group>
      <Form.Group label={t("common.end")} error={endError} required>
        <Field name="end">
          {() => (
            <Form.TimeSelect
              id="end"
              name="end"
              defaultOptionValue={t("bookings.settings.endTime")}
              value={toSeconds(fm.values.end) ?? 0}
              onChange={(e) => {
                const time = parseTime(e);
                setSelectedTime(timezone, { end: time });
                fm.setFieldValue("end", time).catch(console.error);
                fm.setFieldTouched("end").catch(console.error);
              }}
              startTime={{ hour: minStartTimeHour, minute: minStartTimeMinute }}
              endTime={{ hour: maxEndTimeHour, minute: maxEndTimeMinute }}
              diffMinutes={15}
            />
          )}
        </Field>
      </Form.Group>
      <style jsx>{`
        .date-time-form {
          display: flex;
          gap: ${spacers.s4};
        }
      `}</style>
    </div>
  );
};
