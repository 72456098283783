import { useTranslation } from "@equiem/localisation-eq1";
import { uniq } from "lodash";
import React, { forwardRef, useCallback, useMemo, useState } from "react";
import { RiDeleteBin6Line, RiDeleteBinLine } from "react-icons/ri";
import { useTheme } from "../../contexts/Theme";
import { useConfirmer } from "../../hooks";
import { AdminButton as Button } from "../Button/AdminButton";
import { IconButton } from "../Button/IconButton";
import { Checkbox } from "../Form";
import type { Props as TableProps } from "../Table";
import { Table, Header } from "../Table";
import type { TablePaginationProps } from "../Table/TablePagination";
import { TablePagination } from "../Table/TablePagination";

interface Header {
  className?: string;
  label?: string | React.ReactNode;
  children?: React.ReactNode;
  style?: React.CSSProperties;
}
interface Row {
  id: string;
  className?: string;
  cols: Col[];
}
interface Col {
  className?: string;
  content: React.ReactNode;
}

interface TableSort {
  columnIndex: number;
  direction: "asc" | "desc";
  handleSort: (index: number) => void;
}

export interface Props extends TableProps {
  deletion?: {
    bulk: boolean;
    single: boolean;
    confirmation?: {
      title: string;
      message: string;
      button: string;
      variant: "danger" | "primary";
    };
  };
  onDeleted?: (ids: string[]) => void;
  onSelected?: (ids: string[]) => void;
  onRowClick?: (id: string, e: React.MouseEvent) => void;
  bulkOperations?: React.ReactNode;
  headers: Header[];
  bodyRows: Row[];
  selectionEnabled?: boolean;
  sort?: TableSort;
  pagination?: TablePaginationProps;
}

export const BulkTable = forwardRef<HTMLTableCellElement, Props>(
  (
    {
      headers,
      bodyRows: rows,
      bulkOperations,
      deletion = { bulk: false, single: false },
      onDeleted,
      onSelected,
      onRowClick,
      selectionEnabled = true,
      sort,
      pagination,
      ...props
    },
    ref,
  ) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const { withConfirmation } = useConfirmer();
    const [selected, setSelected] = useState<string[]>([]);
    const allIds = useMemo(() => rows.map((row) => row.id), [rows]);
    const anySelected = selected.length > 0;
    const allSelected = useMemo(() => {
      return rows.length > 0 && rows.every((row) => selected.includes(row.id));
    }, [rows, selected]);

    const processSelected = useCallback(
      (ids: string[], select: boolean) => {
        const extended = select ? uniq(selected.concat(ids)) : selected.filter((item) => !ids.includes(item));
        setSelected(extended);
        onSelected?.(extended);
      },
      [onSelected, selected],
    );

    const selectAll = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        processSelected(allIds, e.target.checked);
      },
      [allIds, processSelected],
    );

    const removeRows = useCallback(
      (ids: string[]) => () => {
        processSelected(ids, false);
        onDeleted?.(ids);
      },
      [onDeleted, processSelected],
    );

    // Shiftmode: Search what is the previous checked id to include it all.
    const findPreviousSelectedIds = useCallback(
      (id: string) => {
        const targetIndex = rows.findIndex((r) => r.id === id);
        if (targetIndex < 0) {
          return [];
        }

        const idsBeforeTarget = rows.slice(0, targetIndex).reduceRight<{ target: string[]; done: boolean }>(
          (prev, current) => {
            if (!prev.done && current.id !== id) {
              const currentChecked = selected.includes(current.id);
              if (!currentChecked) {
                return {
                  ...prev,
                  target: prev.target.concat([current.id]),
                };
              }

              return { ...prev, done: true };
            }

            return prev;
          },
          { target: [], done: false },
        );

        // Can't find checked item and the array finished.
        if (!idsBeforeTarget.done) {
          return [];
        }

        return idsBeforeTarget.target;
      },
      [rows, selected],
    );

    const onSelectRow = useCallback(
      (id: string) => (e: React.ChangeEvent<HTMLInputElement>, shiftHeld?: boolean) => {
        // When shift not held or unchecked operation.
        if (shiftHeld == null || !shiftHeld || !e.target.checked) {
          processSelected([id], e.target.checked);
          return;
        }

        processSelected([id].concat(findPreviousSelectedIds(id)), e.target.checked);
      },
      [findPreviousSelectedIds, processSelected],
    );
    const removeSelected = useCallback(() => {
      removeRows(selected)();
    }, [removeRows, selected]);

    const confirm = useCallback(
      (count: number) => {
        const conf = deletion.confirmation;
        withConfirmation({
          title: conf?.title ?? t("common.areYouSure"),
          message: conf?.message ?? t("common.deleteConfirmation", { count }),
          confirmButtonText: conf?.button ?? t("common.yesDelete"),
          confirmButtonVariant: conf?.variant ?? "danger",
          onConfirm: () => removeSelected(),
        })();
      },
      [deletion.confirmation, removeSelected, t, withConfirmation],
    );

    const colSpan = deletion.single ? headers.length + 1 : headers.length;
    const checkboxWidth = "18px";
    const allowSelection = rows.length > 0 && selectionEnabled;

    const stopPropagationHandle = (e: React.MouseEvent) => {
      e.stopPropagation();
    };

    const handleRowClick = (id: string) => (e: React.MouseEvent) => {
      onRowClick?.(id, e);
    };

    return (
      <>
        <Table {...props}>
          <thead>
            <tr style={{ visibility: anySelected ? "collapse" : "visible" }}>
              {allowSelection && (
                <Header style={{ width: checkboxWidth }} label="" ref={ref}>
                  <Checkbox
                    className="select-all"
                    onChange={selectAll}
                    checked={allSelected}
                    indeterminate={anySelected && !allSelected}
                    label=""
                  />
                </Header>
              )}
              {headers.map((header, i) => (
                <Header
                  className={header.className}
                  key={i}
                  label={header.label ?? ""}
                  style={header.style}
                  ref={ref}
                  direction={sort?.columnIndex === i ? sort.direction : undefined}
                  onClick={() => sort?.handleSort(i)}
                >
                  {header.children}
                </Header>
              ))}
              {deletion.single && rows.length > 0 && <Header label="" />}
            </tr>
            {anySelected && (
              <tr className="bulk-operations">
                <Header style={{ width: checkboxWidth }} label="">
                  <Checkbox
                    className="select-all"
                    onChange={selectAll}
                    checked={allSelected}
                    indeterminate={!allSelected}
                    label=""
                  />
                </Header>
                <Header label="" colSpan={colSpan}>
                  <div className="d-flex ml-n3">
                    {bulkOperations != null && <>{bulkOperations}</>}
                    {deletion.bulk && (
                      <Button
                        type="button"
                        size="sm"
                        variant="ghost"
                        className="text-uppercase"
                        onClick={() => confirm(selected.length)}
                      >
                        <RiDeleteBinLine size={16} /> {t("common.deleteSelected")}
                      </Button>
                    )}
                  </div>
                </Header>
              </tr>
            )}
          </thead>
          <tbody>
            {rows.length === 0 && (
              <tr>
                <td colSpan={colSpan}>-</td>
              </tr>
            )}
            {rows.map((row) => {
              const rowSelected = selected.includes(row.id);

              return (
                <tr
                  key={row.id}
                  className={`${rowSelected ? "selected" : ""} ${row.className}`}
                  onClick={handleRowClick(row.id)}
                >
                  {allowSelection && (
                    <td onClick={stopPropagationHandle}>
                      <Checkbox
                        className="select"
                        label=""
                        checked={rowSelected}
                        onChange={onSelectRow(row.id)}
                        detectShift={true}
                      />
                    </td>
                  )}
                  {row.cols.map((col, i) => (
                    <td className={col.className} key={i}>
                      {col.content}
                    </td>
                  ))}
                  {deletion.single && (
                    <td width={15} className="delete-td">
                      <div>
                        <IconButton
                          className="delete"
                          onClick={removeRows([row.id])}
                          disabled={rowSelected}
                          hover={{
                            background: theme.colors.status.danger.accent,
                          }}
                        >
                          <RiDeleteBin6Line size={16} color={theme.colors.danger} />
                        </IconButton>
                      </div>
                    </td>
                  )}
                </tr>
              );
            })}
          </tbody>
          <style jsx>{`
            thead :global(th) {
              line-height: 1.6rem;
            }
            tbody :global(tr.has-error td) {
              vertical-align: top;
            }

            .delete-td div {
              opacity: 0;
              transition: opacity 0.5s;
            }
            tr:hover td {
              background: ${theme.colors.grayscale[3]};
            }
            tr:hover .delete-td div {
              opacity: 1;
            }

            tr.selected td,
            tr.selected:hover td {
              background: ${theme.colors.lightHover};
            }
            tr.selected:hover .delete-td div {
              opacity: 0;
            }
          `}</style>
        </Table>
        {pagination != null && <TablePagination {...pagination} />}
      </>
    );
  },
);

BulkTable.displayName = "BulkTable";
