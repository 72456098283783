import { useState } from "react";

import { notNullOrUndefined } from "@equiem/lib";

import type { MyBookingsQueryVariables } from "../generated/gateway-client";
import { useMyBookingsQuery } from "../generated/gateway-client";

type Variables = Omit<MyBookingsQueryVariables, "page">;

export const DEFAULT_PAGE_SIZE = 24;

export function usePagedMyBookings(variables: Variables, pageSize = DEFAULT_PAGE_SIZE) {
  const [loadingMore, setLoadingMore] = useState(false);

  const result = useMyBookingsQuery({
    variables: {
      ...variables,
      page: { first: pageSize },
    },
    fetchPolicy: "network-only",
  });

  const fetchMore = () => {
    if (result.loading || loadingMore) {
      return;
    }

    setLoadingMore(true);

    result
      .fetchMore({
        variables: {
          ...variables,
          page: {
            first: pageSize,
            after: result.data?.myBookingsList.pageInfo.endCursor,
          },
        },
        updateQuery(prev, { fetchMoreResult }) {
          return {
            ...fetchMoreResult,
            myBookingsList: {
              ...fetchMoreResult.myBookingsList,
              edges: [...prev.myBookingsList.edges, ...fetchMoreResult.myBookingsList.edges],
            },
          };
        },
      })
      .catch((e) => console.error(e))
      .finally(() => setLoadingMore(false));
  };

  return {
    bookings: result.data?.myBookingsList.edges.map((edge) => edge.node).filter(notNullOrUndefined) ?? [],
    hasMoreData: result.data?.myBookingsList.pageInfo.hasNextPage ?? false,
    error: result.error,
    loading: result.loading,
    fetchMore,
    loadingMore,
  };
}
