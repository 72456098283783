import type { FieldProps } from "formik";
import type { FC } from "react";
import { useContext } from "react";
import { useCreatePresignedUrlMutation } from "../generated/iris-client";
import { Button } from "@equiem/react-admin-ui";
import { Session } from "@equiem/lib/context/SessionContext";
import Image from "next/image";
import type { IImage } from "@equiem/uploader";
import { useImageUploaderState, CropperUi, Dropzone } from "@equiem/uploader";
import { useTranslation } from "@equiem/localisation-eq1";

export const ImageUploader: FC<FieldProps<IImage[]>["field"]> = (props) => {
  const { t } = useTranslation();
  const session = useContext(Session);
  const [createPresignedUrl, { error }] = useCreatePresignedUrlMutation({
    client: session.irisClient?.cortex,
  });
  const {
    localAttachedImage,
    attachedImages,
    dragover,
    uploading,
    setDragover,
    onFileChange,
    progress,
    onCrop,
    onSaveCropped,
    cropperRef,
    clearCropper,
    removeImages,
    imgHeight,
    imgWidth,
  } = useImageUploaderState(
    props.value,
    (imgs) => {
      props.onChange({
        target: {
          name: props.name,
          value: imgs,
        },
      });
    },
    async (input) =>
      createPresignedUrl({
        variables: {
          input: {
            filename: input.filename,
            contentType: input.contentType!,
          },
        },
      }).then((r) => ({
        // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
        key: r.data?.createPresignedUrl?.key!,
        // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
        signedUrl: r.data?.createPresignedUrl?.presignedUrl!,
      })),
  );

  return (
    <>
      {localAttachedImage == null && attachedImages.length === 0 && (
        <Dropzone
          dragover={dragover}
          uploading={uploading}
          setDragover={setDragover}
          onFileChange={onFileChange}
          progress={progress}
        />
      )}
      {localAttachedImage != null && attachedImages.length === 0 && (
        <CropperUi
          localAttachedImage={localAttachedImage}
          onCrop={onCrop}
          cropperRef={cropperRef}
          onSaveCropped={onSaveCropped}
          uploading={uploading}
          progress={progress}
          clearCropper={clearCropper}
          error={error}
          disableAspectRatio={false}
          // eslint-disable-next-line @typescript-eslint/no-magic-numbers
          aspectRatio={3 / 2}
        />
      )}
      {localAttachedImage == null && attachedImages.length !== 0 && (
        <div className="text-center" style={{ display: "block" }}>
          <Image
            src={`/${attachedImages[0].key}`}
            alt="image"
            width={imgWidth}
            height={imgHeight}
            layout="responsive"
          />
          <Button size="sm" variant="outline" onClick={removeImages} className="mt-5">
            {t("home.widgets.removeImage")}
          </Button>
        </div>
      )}
      <style jsx>
        {`
          .center {
            display: flex;
            justify-content: space-between;
            font-size: 10pt;
            font-weight: 300;
          }
        `}
      </style>
    </>
  );
};
