import { useEffect, useMemo, useState } from "react";

import { getReceptionName, useSaferFormikContext } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";

import type { ReceptionFormValues } from "../ReceptionFormValues";

import { useReceptionFormBarrierControlAccesses } from "./useReceptionFormBarrierControlAccesses";
import { useReceptionFormBuildings } from "./useReceptionFormBuildings";
import { useReceptionFormCompanies } from "./useReceptionFormCompanies";
import { useReceptionFormReception } from "./useReceptionFormReception";
import { useReceptionFormUserSearch } from "./useReceptionFormUserSearch";

export const useReceptionForm = ({
  uuid,
  setReceptionName,
}: {
  uuid?: string | null;
  setReceptionName: (name: string) => void;
}) => {
  const { t } = useTranslation();
  const { values, resetForm } = useSaferFormikContext<ReceptionFormValues>();
  const { reception, loading: receptionLoading } = useReceptionFormReception({ uuid });
  const { companies, selectedCompany, loading: companiesLoading } = useReceptionFormCompanies();
  const { barrierControlAccesses, loading: barrierControlAccessesLoading } = useReceptionFormBarrierControlAccesses();
  const {
    buildings,
    levels,
    selectedBuilding,
    selectedBuildingLevel,
    loading: buildingsLoading,
  } = useReceptionFormBuildings({ selectedCompany });
  const { users: searchUsersRecipients, loading: searchUsersLoadingRecipients } =
    useReceptionFormUserSearch("recipients");
  const { users: searchUsers, loading: searchUsersLoading } = useReceptionFormUserSearch("receptionists");

  useEffect(() => {
    setReceptionName(
      getReceptionName(
        {
          company: selectedCompany,
          building: selectedBuilding,
          buildingLevel: selectedBuildingLevel,
          suffix: values.suffix,
        },
        t,
      ),
    );
  }, [selectedCompany, selectedBuilding, selectedBuildingLevel, values.suffix, reception, setReceptionName]);

  const [lastReceptionType, setLastReceptionType] = useState(values.receptionType);
  useEffect(() => {
    if (values.receptionType !== lastReceptionType) {
      setLastReceptionType(values.receptionType);
      resetForm({
        values: {
          receptionType: values.receptionType,
          receptionists: [],
          recipients: [],
          buildingLevelUuid: "",
          buildingUuid: "",
          companyUuid: "",
          suffix: "",
          receptionistsSearch: "",
          recipientsSearch: "",
          enableAccessCard: false,
          enableHoldInLobby: false,
          sendPassOnCheckIn: false,
          enablePassPrinting: false,
          enableAutomaticPassPrinting: false,
          barrierControlAccessUuid: "",
        },
      });
    }
  }, [values.receptionType, lastReceptionType, resetForm]);

  return useMemo(
    () => ({
      reception,
      receptionLoading,
      buildings,
      levels,
      selectedBuilding,
      selectedBuildingLevel,
      companies,
      selectedCompany,
      companiesLoading,
      buildingsLoading,
      searchUsers,
      searchUsersLoading,
      searchUsersRecipients,
      searchUsersLoadingRecipients,
      barrierControlAccesses,
      barrierControlAccessesLoading,
    }),
    [
      reception,
      receptionLoading,
      buildings,
      levels,
      selectedBuilding,
      selectedBuildingLevel,
      companies,
      selectedCompany,
      companiesLoading,
      buildingsLoading,
      searchUsers,
      searchUsersLoading,
      searchUsersRecipients,
      searchUsersLoadingRecipients,
      barrierControlAccesses,
      barrierControlAccessesLoading,
    ],
  );
};
