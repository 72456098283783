import type { ApolloClient } from "@apollo/client";
import React from "react";

export interface AuthenticationContext {
  loaded: boolean;
  authenticated: boolean;
  authenticationError?: string;
  idToken?: string;
  accessToken?: string;
  authenticatedClient?: ApolloClient<object>;
  globalClient?: ApolloClient<object>;
  irisClient?: {
    cortex: ApolloClient<object>;
  };
  refresh?: () => Promise<void>;
}

export type SessionContext =
  | (AuthenticationContext & { side: "server" })
  | (AuthenticationContext & {
      side: "client";
      authenticatedClient: ApolloClient<object>;
      irisClient: {
        cortex: ApolloClient<object>;
      };
      globalClient: ApolloClient<object>;
    });

export const Session = React.createContext<SessionContext>({
  side: "server",
  loaded: false,
  authenticated: false,
});
