import type { UserFilterV2, UserListQuery } from "../../../generated/settings-client";
import { UserSortFieldV2, useUserListQuery } from "../../../generated/settings-client";

const DEFAULT_PAGE_SIZE = 20;

export type UserListItem = NonNullable<UserListQuery["usersV4"]["edges"][number]["node"]> &
  Pick<UserListQuery["usersV4"]["edges"][number], "authorizationRoles">;
export function usePagedUserList(filter: UserFilterV2 = {}, pageSize = DEFAULT_PAGE_SIZE) {
  const { data, loading, error, fetchMore, refetch } = useUserListQuery({
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
    variables: {
      page: { first: pageSize },
      filter: {
        ...filter,
        status: filter.email != null ? undefined : filter.status, // If search is filled, don't filter on status.
      },
      sort: {
        asc: true,
        field: UserSortFieldV2.FirstNameKeyword,
      },
    },
  });

  return {
    error,
    loading,
    users:
      data?.usersV4.edges.flatMap(({ node, authorizationRoles }) =>
        node == null ? [] : [{ ...node, authorizationRoles }],
      ) ?? [],
    hasMoreData: data?.usersV4.pageInfo.hasNextPage ?? false,
    totalCount: data?.usersV4.totalCount ?? 0,
    refetch,
    fetchMore: () => {
      fetchMore({
        variables: {
          page: {
            first: pageSize,
            after: data?.usersV4.pageInfo.endCursor,
          },
        },
        updateQuery(prev, { fetchMoreResult }) {
          return {
            ...fetchMoreResult,
            usersV4: {
              ...fetchMoreResult.usersV4,
              edges: [...prev.usersV4.edges, ...fetchMoreResult.usersV4.edges],
            },
          };
        },
      }).catch((e) => console.error(e));
    },
  };
}
