import React, { useMemo, useState } from "react";
import { DateTime } from "luxon";

import { useDisabledSiteSwitcher, useQueryState, useSiteContext } from "@equiem/lib";
import type { BookingPermissionFilters } from "@equiem/lib/generated/gateway-client";
import { useTranslation } from "@equiem/localisation-eq1";
import type { RadioIconOption } from "@equiem/react-admin-ui";
import { Button, RadioIcons, useTheme, useToast } from "@equiem/react-admin-ui";
import { RiCalendar2Line, RiListCheck } from "@equiem/react-admin-ui/icons";

import { BookingsTab } from "../../components/BookingsTab";
import { useSiteBookingCountsQuery } from "../../generated/gateway-client";
import { useBookingFilters } from "../../hooks/useBookingFilters";
import { usePagedSiteBookings } from "../../hooks/usePagedSiteBookings";
import { matchesSearchText } from "../../lib/matchesSearch";
import { BookingsView } from "../../models/BookingsView";

import { OperationsCalendar } from "./components/OperationsCalendar";
import { OperationsList } from "./components/OperationsList";
import { usePrintableBookings } from "./hooks/usePrintableBookings";
import { getSearchTokens } from "./models/BookingFilters";
import { BookingsManagementFilterTabs, defaultTabFilter } from "./BookingsManagementFilterTabs";

const isView = (v: unknown): v is BookingsView => Object.values(BookingsView).includes(v as BookingsView);

export const BookingsManagement: React.FC = () => {
  const toast = useToast();
  const { t, i18n } = useTranslation();
  const { timezone } = useSiteContext();
  const { breakpoints, spacers } = useTheme();
  const [tabFilter, setTabFilter] = useState<BookingPermissionFilters | null>(defaultTabFilter);

  useDisabledSiteSwitcher();

  const viewOptions: Array<RadioIconOption<BookingsView>> = [
    {
      icon: RiListCheck,
      value: BookingsView.BOOKINGS_MANAGEMENT_LIST,
      className: BookingsView.BOOKINGS_MANAGEMENT_LIST,
      tooltipText: t("bookings.operations.list"),
    },
    {
      icon: RiCalendar2Line,
      value: BookingsView.BOOKINGS_MANAGEMENT_CALENDAR,
      className: BookingsView.BOOKINGS_MANAGEMENT_CALENDAR,
      tooltipText: t("bookings.operations.calendar"),
    },
  ];

  const [{ view }, setQueryState] = useQueryState({
    initial: { view: BookingsView.BOOKINGS_MANAGEMENT_LIST },
    parse: { view: (v) => (isView(v) ? v : BookingsView.BOOKINGS_MANAGEMENT_LIST) },
    clearQueryOnChange: true,
    rememberLastState: true,
  });

  const counts = useSiteBookingCountsQuery({
    variables: {
      date: DateTime.now().setZone(timezone).startOf("day").toMillis(),
      endDate: DateTime.now().setZone(timezone).plus({ months: 1 }).endOf("day").toMillis(),
    },
    fetchPolicy: "cache-and-network",
  });

  const { filtersLoading, multiSiteUser, filterOptions, defaultValues, filterValues, filters, onFilterChange } =
    useBookingFilters(view, "bookings-management");
  const complexFilters = useMemo(
    () => ({
      loading: filtersLoading,
      items: filterOptions,
      initialValues: filterValues,
      defaultValues,
      setValues: onFilterChange,
    }),
    [defaultValues, filterValues, filtersLoading, filterOptions, onFilterChange],
  );
  const listResult = usePagedSiteBookings(filters, tabFilter, view !== BookingsView.BOOKINGS_MANAGEMENT_LIST);

  const [searchText, setSearchText] = useState("");
  const listBookingsToShow = listResult.bookings.filter((booking) =>
    matchesSearchText(searchText, getSearchTokens(booking, i18n.language)),
  );

  const [isPrinting, setIsPrinting] = useState(false);
  const { handlePrint, renderPrintTable } = usePrintableBookings();
  const onPrintClicked = async () => {
    if (isPrinting) {
      return;
    }

    setIsPrinting(true);
    try {
      const bookingsToPrint = (await listResult.fetchAll())?.filter((booking) =>
        matchesSearchText(searchText, getSearchTokens(booking, i18n.language)),
      );
      if (bookingsToPrint != null) {
        handlePrint(bookingsToPrint);
      }
    } catch (e: unknown) {
      console.error(e);
      toast.negative(t("common.unknownError"));
    } finally {
      setIsPrinting(false);
    }
  };

  const handleTabFilterChange = (newTabFilter: BookingPermissionFilters | null) => {
    setSearchText("");
    setTabFilter(newTabFilter);
  };

  return (
    <>
      <BookingsTab
        title={
          <div className="title-container">
            <RadioIcons
              options={viewOptions}
              value={view}
              onChange={(newView: BookingsView) => setQueryState({ view: newView })}
            />
            <BookingsManagementFilterTabs
              setTabFilter={handleTabFilterChange}
              countLoading={counts.loading}
              totalCount={counts.data?.siteBookingCounts.all}
              myResourcesCount={counts.data?.siteBookingCounts.myResources}
              myEmployeesCount={counts.data?.siteBookingCounts.myEmployees}
            />
          </div>
        }
        search={{ searchText, setSearchText }}
        filters={complexFilters}
        button={
          view === BookingsView.BOOKINGS_MANAGEMENT_LIST && (
            <Button
              className="main-button"
              variant="primary"
              size="md"
              onClick={() => {
                void onPrintClicked();
              }}
            >
              {t("bookings.operations.printRunSheet")}
            </Button>
          )
        }
      >
        <div className="operations-list">
          {view === BookingsView.BOOKINGS_MANAGEMENT_LIST && (
            <OperationsList
              {...listResult}
              bookings={listBookingsToShow}
              multiSiteUser={multiSiteUser}
              isPrinting={isPrinting}
            />
          )}
          {view === BookingsView.BOOKINGS_MANAGEMENT_CALENDAR && (
            <OperationsCalendar filters={filters} permissionFilters={tabFilter} searchText={searchText} />
          )}
        </div>
      </BookingsTab>
      {renderPrintTable()}
      <style jsx>{`
        .title-container {
          display: flex;
          gap: ${spacers.s5};
          align-items: center;
        }
        .main-button {
          text-transform: uppercase;
        }
        .operations-list {
          padding: ${spacers.s0} ${spacers.s7} ${spacers.s5};
        }
        @media screen and (max-width: ${breakpoints.sm}px) {
          .operations-list {
            padding: ${spacers.s0} ${spacers.s5} ${spacers.s5};
          }
        }
      `}</style>
    </>
  );
};
