import { useSaferFormikContext } from "@equiem/lib";
import { formatters, useTranslation } from "@equiem/localisation-eq1";
import { Button, Form as EqForm, Modal, useTheme } from "@equiem/react-admin-ui";
import { RiInformationLine } from "@equiem/react-admin-ui/icons";
import type { FieldProps } from "formik";
import { Field } from "formik";
import React, { useEffect, useState } from "react";
import type { GetCreditProviderQuery } from "../../../generated/gateway-client";
import { convertCents } from "../utils/convertCents";
import type { AmountOfCredits, Membership, PurchaseCreditsFormInput } from "./FormValues";
import { getTaxValue } from "../utils/getTaxValue";

enum Steps {
  ORDER = "ORDER",
  SUMMARY = "SUMMARY",
}

export const PurchaseCreditsView: React.FC<{
  onClose: () => void;
  showModal: boolean;
  memberships: Membership[];
  setFlexOperatorUuid: (value: string) => void;
  creditProvider?: GetCreditProviderQuery | null;
}> = ({ onClose, creditProvider, showModal, setFlexOperatorUuid, memberships = [] }) => {
  const { values, setFieldValue, handleSubmit, touched, errors, setTouched } =
    useSaferFormikContext<PurchaseCreditsFormInput>();
  const [step, setStep] = useState<Steps>(Steps.ORDER);
  const [amountOfCredits, setAmountOfCredits] = useState<AmountOfCredits[]>([]);
  const [selectedItem, setSelectedItem] = useState<AmountOfCredits | null>(null);
  const [selectedFlexTenant, setSelectedFlexTenant] = useState<Membership | null>(null);
  const { t, i18n } = useTranslation();
  const { colors } = useTheme();

  useEffect(() => {
    if (!showModal) {
      setStep(Steps.ORDER);
      setSelectedItem(null);
      setSelectedFlexTenant(null);
      void setFieldValue("purchasableItemUuid", "");
      void setFieldValue("accountUuid", "");
      void setTouched({});
    }
  }, [showModal]);

  useEffect(() => {
    if (
      selectedFlexTenant?.accountUuid != null &&
      selectedFlexTenant.accountUuid.length > 0 &&
      selectedFlexTenant.accountUuid !== values.accountUuid
    ) {
      setFlexOperatorUuid(selectedFlexTenant.flexOperatorUuid);
      void setFieldValue("accountUuid", selectedFlexTenant.accountUuid);
      void setFieldValue("taxRateUuid", selectedFlexTenant.tax.uuid);
    }
  }, [selectedFlexTenant, setFieldValue, setFlexOperatorUuid]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (selectedFlexTenant != null || memberships.length > 1 || memberships[0] == null) {
      return;
    }
    // set initial values
    setSelectedFlexTenant(memberships[0]);
    if (values.accountUuid === "" && memberships[0].accountUuid.length > 0) {
      void setFieldValue("accountUuid", memberships[0].accountUuid);
    }
    if (values.taxRateUuid === "") {
      void setFieldValue("taxRateUuid", memberships[0].tax.uuid);
    }
  }, [selectedFlexTenant, memberships, values.accountUuid, values.taxRateUuid, setFieldValue]);

  useEffect(() => {
    if (creditProvider?.creditProvider == null) {
      setAmountOfCredits([]);
    } else {
      const { creditsPurchaseEnabled, purchasableItems } = creditProvider.creditProvider;
      setAmountOfCredits(
        creditsPurchaseEnabled && values.accountUuid.length > 0
          ? purchasableItems.flatMap((item) => ({
              uuid: item.uuid,
              creditValue: item.creditValue,
              discount: item.discount,
              discountedNetPrice: item.discountedNetPrice,
              netPrice: item.netPrice,
              taxPrice: getTaxValue({
                nettoPrice: item.discountedNetPrice,
                taxRateValue: selectedFlexTenant?.tax.rate ?? 0,
              }),
            }))
          : [],
      );
    }
  }, [selectedFlexTenant?.tax.rate, creditProvider, values.accountUuid]);

  if (memberships.length < 1) {
    return null;
  }

  return (
    <div className="modal-wrapper">
      <Modal.Dialog
        title={step === Steps.ORDER ? t("credits.purchaseWidget.header") : t("credits.purchaseWidget.checkout")}
        show={showModal}
        scrollable={true}
        centered={true}
        onHide={onClose}
        hideOnEsc={true}
        hideOnClick={false}
        focusTrapOptions={{ allowOutsideClick: () => true }}
        size="md"
      >
        <Modal.Header
          closeButton={true}
          noBorder={true}
          noTitle={false}
          intro={t("credits.purchaseWidget.requestAdditionalCredits")}
        ></Modal.Header>
        <Modal.Body>
          {step === Steps.ORDER ? (
            <>
              <div className="pt-4">
                {memberships.length > 1 && (
                  <EqForm.Group
                    label={t("credits.purchaseWidget.membershipName")}
                    required
                    error={
                      touched.accountUuid === true && values.accountUuid === ""
                        ? t("credits.purchaseWidget.error.noMembership")
                        : ""
                    }
                    hasError={touched.accountUuid === true && values.accountUuid === "" && Boolean(errors.accountUuid)}
                  >
                    <Field name="taxRateUuid" type="hidden" />
                    <Field name="accountUuid">
                      {({ field }: FieldProps) => (
                        <EqForm.Select
                          {...field}
                          onChange={(e) => {
                            setSelectedItem(null);
                            void setFieldValue("purchasableItemUuid", "");
                            setSelectedFlexTenant(
                              memberships.find((flexTenant) => flexTenant.accountUuid === e.target.value) ?? null,
                            );
                          }}
                        >
                          <option value="" disabled>
                            <>{t("credits.purchaseWidget.selectMembership")}</>
                          </option>
                          {memberships.map((flexTenant) => (
                            <option key={flexTenant.uuid} value={flexTenant.accountUuid}>
                              {flexTenant.name}
                            </option>
                          ))}
                        </EqForm.Select>
                      )}
                    </Field>
                  </EqForm.Group>
                )}
              </div>
              {amountOfCredits.length > 0 && (
                <div className="circles">
                  <EqForm.Group label={t("credits.purchaseWidget.amountOfCredits")} required className="mb-0">
                    {amountOfCredits.map((amount) => (
                      <EqForm.RadioButton
                        key={amount.uuid}
                        id={`${convertCents(amount.creditValue)}`}
                        name="purchasableItemUuid"
                        className={`cirlce${values.purchasableItemUuid === amount.uuid ? " checked" : ""}${
                          amount.discount != null ? " discounted" : ""
                        }`}
                        label={convertCents(amount.creditValue)}
                        value={amount.uuid}
                        checked={values.purchasableItemUuid === amount.uuid}
                        data-discount={amount.discount}
                        onChange={() => {
                          setSelectedItem(amount);
                          void setFieldValue("purchasableItemUuid", amount.uuid);
                        }}
                      />
                    ))}
                  </EqForm.Group>
                </div>
              )}
            </>
          ) : (
            <>
              <p className="number-of-credits">
                {t("credits.purchaseWidget.credits", {
                  amount: convertCents(selectedItem?.creditValue ?? 0),
                })}
              </p>
              <p className="credits-info d-flex align-items-center">
                <RiInformationLine size={20} color={colors.grayscale[100]} />
                {t("credits.purchaseWidget.info")}
              </p>
              <div className="separator" />
              <div className="d-flex flex-column summary">
                <div className="d-flex flex-row justify-content-between">
                  <span>{t("credits.netPrice")}</span>
                  <span>
                    {formatters.currency(convertCents(selectedItem?.netPrice ?? 0), i18n.language, {
                      currency: "GBP",
                    })}
                  </span>
                </div>
                {selectedItem?.discount != null && (
                  <div className="d-flex flex-row justify-content-between discount">
                    <span>
                      {t("credits.discountAmount", {
                        percentage: selectedItem.discount,
                      })}
                    </span>
                    <span>
                      {formatters.currency(
                        convertCents(selectedItem.discountedNetPrice - selectedItem.netPrice),
                        i18n.language,
                        {
                          currency: "GBP",
                        },
                      )}
                    </span>
                  </div>
                )}
                <div className="d-flex flex-row justify-content-between">
                  <span>{t("credits.tax")}</span>
                  <span>
                    {formatters.currency(convertCents(selectedItem?.taxPrice ?? 0), i18n.language, {
                      currency: "GBP",
                    })}
                  </span>
                </div>
                <div className="d-flex flex-row justify-content-between total">
                  <span>{t("credits.total")}</span>
                  <span>
                    {formatters.currency(
                      convertCents((selectedItem?.taxPrice ?? 0) + (selectedItem?.discountedNetPrice ?? 0)),
                      i18n.language,
                      {
                        currency: "GBP",
                      },
                    )}
                  </span>
                </div>
              </div>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          {step === Steps.ORDER && (
            <>
              <Button type="button" className="mx-4" variant="ghost" onClick={onClose}>
                {t("common.cancel")}
              </Button>
              <Button
                type="button"
                variant="primary"
                disabled={values.accountUuid.length === 0 || values.purchasableItemUuid.length === 0}
                onClick={() => setStep(Steps.SUMMARY)}
              >
                {t("credits.purchaseWidget.goToCheckout")}
              </Button>
            </>
          )}
          {step === Steps.SUMMARY && (
            <>
              <Button
                type="button"
                className="mx-4"
                variant="ghost"
                onClick={() => {
                  setStep(Steps.ORDER);
                }}
              >
                {t("common.back")}
              </Button>
              <Button type="button" variant="primary" disabled={false} onClick={() => handleSubmit()}>
                {t("credits.purchaseWidget.requestCredits", {
                  amount: convertCents(
                    amountOfCredits.find((amount) => amount.uuid === values.purchasableItemUuid)?.creditValue ?? 0,
                  ),
                })}
              </Button>
            </>
          )}
        </Modal.Footer>
      </Modal.Dialog>
      <style jsx>
        {`
          .number-of-credits {
            font-size: 1.5rem;
            line-height: 2rem;
            font-weight: bold;
            margin: 1rem 0 0;
          }
          .credits-availability {
            font-size: 1rem;
            line-height: 1.5rem;
            margin: 0;
          }
          .credits-info {
            padding: .8rem;
            border-radius: 4px;
            background-color: ${colors.grayscale[5]};
            font-size: 0.875rem;
            line-height: 1.25rem;
            margin: 24px 0;
          }
          .credits-info :global(svg) {
            min-width: 20px;
            margin-right: 12px;
          }
          .separator {
            width: 100%;
            height: 1px;
            background-color: ${colors.border};
          }
          .summary {
            margin: 24px 0;
            font-size: 1rem;
            line-height: 1.5rem;
            color: ${colors.grayscale[60]};
          }
          .discount {
            color: ${colors.status.positive.primary};
            line-height: 2rem;
            font-weight: 500;
          }
          .total {
            font-size: 1.125rem;
            margin-top: 20px;
            font-weight: bold;
            color: ${colors.grayscale[100]};
          }
          .circles :global(.cirlce) {
            padding: 8px 20px;
            border: 1px solid ${colors.border};
            border-radius: 100px;
            position: relative;
            margin-right: 8px;
            margin-bottom: 12px;
          }
          .circles :global(.checked) {
            border: 1px solid ${colors.blue[60]};
            background-color: ${colors.blue[10]};
          }
          .circles :global(.discounted input::after) {
            content: "-" attr(data-discount) "%";
            display: block;
            color: ${colors.white};
          }
          .circles :global(.discounted input) {
            display: block;
            background-color: ${colors.blue[60]};
            border-radius: 8px;
            color: ${colors.white};
            position: absolute;
            appearance: none;
            padding 10px;
            top: -10px;
            right: -10px;
            padding: 0 4px;
            width: auto;
            height: 16px;
            border: none;
            font-size: 10px;
            line-height: 16px;
            font-weight: bold;
          }
          .circles :global(.discounted input::before), .circles :global(.cirlce:not(.discounted) input) {
            display: none;
          }
        `}
      </style>
    </div>
  );
};
