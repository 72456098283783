import { Button, Dropdown, Form, Modal, Tooltip, useConfirmer, useTheme } from "@equiem/react-admin-ui";
import {
  RiArrowUpDownLine,
  RiCloseCircleLine,
  RiInformationFill,
  RiSearchLine,
  RiShareBoxLine,
} from "@equiem/react-admin-ui/icons";
import type { FC } from "react";
import { useContext, useEffect, useMemo, useState } from "react";
import { Role, Site, CurrentRole, RoleFromJWTRole, SiteLogo, CurrentProfile } from "@equiem/lib";
import type { DestinationListQuery } from "../../generated/gateway-client";
import { useDestinationListQuery } from "../../generated/gateway-client";
import { SiteListItem } from "./SiteListItem";
import { useRouter } from "next/router";
import { Session } from "@equiem/lib/context/SessionContext";
import includes from "lodash.includes";
import { useTranslation } from "@equiem/localisation-eq1";
import { useSiteLoaderMode } from "../../hooks/useSiteLoaderMode";
import uniqBy from "lodash.uniqby";

type SiteType = DestinationListQuery["viewer"]["destinationsWithRelation"]["member"]["edges"];
type DestinationType = NonNullable<SiteType[number]["node"]>;

const initialPageSize = 10;
const morePageSize = 50;

export const MenuSwitcher: FC<{ enabled: boolean }> = ({ enabled }) => {
  const theme = useTheme(true);
  const session = useContext(Session);
  const { withConfirmation } = useConfirmer();
  const router = useRouter();
  const { uuid, name, setSiteSwitchingInProgress, setMemberSites } = useContext(Site);
  const { currentRole, setCurrentRole, jwtRoles } = useContext(CurrentRole);
  const { profile, canManageRegion } = useContext(CurrentProfile);
  const { spacers, colors } = useTheme();
  const [siteList, setSites] = useState<SiteType>([]);
  const [filteredSites, setFilteredSites] = useState<SiteType>([]);
  const [filterText, setFilterText] = useState("");
  const [isEmptySearch, setIsEmptySearch] = useState(true);
  const { t } = useTranslation();
  const { inSiteMode } = useSiteLoaderMode();
  const [showSwitchSiteModal, setShowSwitchSiteModal] = useState<
    { show: false } | { show: true; destination: DestinationType }
  >({ show: false });
  const equiemEssentialsEnabled = process.env.equiemEssentialsEnabled === "true";

  const { data: siteListData, refetch } = useDestinationListQuery({
    client: session.globalClient,
    variables: {
      first: initialPageSize,
    },
  });

  useEffect(() => {
    if (siteListData?.viewer.destinationsWithRelation.member.edges != null) {
      const edges = siteListData.viewer.destinationsWithRelation.member.edges;
      setSites((curr) => uniqBy([...curr, ...edges], "node.uuid"));
      if (siteListData.viewer.destinationsWithRelation.member.pageInfo.hasNextPage) {
        refetch({
          first: morePageSize,
          after: siteListData.viewer.destinationsWithRelation.member.pageInfo.endCursor,
        }).catch((e) => {
          console.error(e);
        });
      }
    }
  }, [siteListData, refetch, session.globalClient]);

  useEffect(() => {
    setMemberSites(siteList.flatMap((s) => (s.node == null ? [] : [{ uuid: s.node.uuid, name: s.node.name }])));
  }, [siteList, setMemberSites]);

  useEffect(() => {
    filterText.length > 0 && setIsEmptySearch(false);
    const result = siteList.filter((val) => includes(val.node?.name.toLowerCase(), filterText.toLowerCase()));
    setFilteredSites(result);
  }, [setFilteredSites, setIsEmptySearch, filterText, siteList]);

  const currentDestination = profile?.siteProfiles.edges.find((e) => e.node?.site.destination?.uuid === uuid);

  const switchRoleAction = async (roleName: string) => {
    if (roleName === Role.FlexManager) {
      setCurrentRole(Role.FlexManager);
    } else {
      setCurrentRole(RoleFromJWTRole(roleName));
    }
    await router.push("/");
  };

  const switchSiteAction = async (siteUuid: string) => {
    setSiteSwitchingInProgress(true);
    await router.push(`/api/login?site=${siteUuid}`);
  };

  const emptySearch = useMemo(() => {
    return filteredSites.length === 0 && !isEmptySearch;
  }, [filteredSites, isEmptySearch]);

  return (
    <>
      <Modal.Dialog
        className="site-switch-modal"
        title={t("home.switchSite")}
        show={showSwitchSiteModal.show}
        onHide={() => setShowSwitchSiteModal({ show: false })}
        hideOnEsc={true}
        centered={true}
        withFooter={true}
        size="sm"
        zIndex={1020}
      >
        <Modal.Header closeButton={true} noBorder={true} />
        <Modal.Body>{t("home.youSureYouLikeToSwitchSite")}</Modal.Body>
        <Modal.Footer>
          {equiemEssentialsEnabled && inSiteMode ? (
            <Button
              size="md"
              variant="outline"
              className="mr-3"
              onClick={() => setShowSwitchSiteModal({ show: false })}
            >
              {
                <>
                  <a
                    className="m-0 new-tab-open-link"
                    target="_blank"
                    href={
                      showSwitchSiteModal.show
                        ? `https://${
                            showSwitchSiteModal.destination.infrastructure?.equiemOneDomain?.hostname ?? ""
                          }/api/login`
                        : ""
                    }
                    rel="noreferrer"
                  >
                    {t("home.widgets.openInNewTab")}
                  </a>
                  <RiShareBoxLine size={12} />
                </>
              }
            </Button>
          ) : (
            <Button className="cancel" variant="ghost" onClick={() => setShowSwitchSiteModal({ show: false })}>
              {t("home.widgets.cancelNo")}
            </Button>
          )}
          <Button
            size="md"
            onClick={async () => {
              if (!showSwitchSiteModal.show) {
                return;
              }

              /**
               * Note for local: site switching operation for site mode will cause the
               * app to be redirected to the staging instance of Equiem one. This is
               * unfortunately the case because domain service doesn't really serve the local
               * equiem one instance urls for just dev environments.
               *
               * If site switching is done in this mode and development is required in the
               * site that's just been switched to, please take not of the the machine name of the site
               * and visit https://[machineName].eq:3000. This domain will need to be added to the hosts
               * file as well.
               */
              const destination = showSwitchSiteModal.destination;
              if (
                equiemEssentialsEnabled &&
                inSiteMode &&
                destination.infrastructure?.equiemOneDomain?.hostname != null
              ) {
                window.location.href = `https://${destination.infrastructure.equiemOneDomain.hostname}/api/login`;
              } else {
                await switchSiteAction(destination.uuid);
              }
            }}
          >
            {t("home.yesSwitch")}
          </Button>
        </Modal.Footer>
      </Modal.Dialog>
      <div className="container">
        <div className="container-inner">
          <SiteLogo className="mr-3" />
          <div className="mr-3">
            <div>
              <h6 className="siteName">{name}</h6>
              {currentRole !== Role.Unknown && (
                <h6 className="role">
                  {currentRole}
                  {currentRole === Role.PropertyManager && canManageRegion && (
                    <Tooltip title={t("home.roleMightBeInferred")} placement="bottom" showArrow>
                      <span className="p-1 ml-1">
                        <RiInformationFill
                          size={16}
                          color={theme.colors.warning}
                          style={{ verticalAlign: "baseline !important" }}
                        />
                      </span>
                    </Tooltip>
                  )}
                </h6>
              )}
            </div>
          </div>
        </div>
        {enabled && (jwtRoles.length > 1 || siteList.length > 1) ? (
          <Tooltip title={t("home.switchSiteRole")} placement="bottom">
            <div>
              <Dropdown.Icon
                icon={RiArrowUpDownLine}
                size="sm"
                placement="bottom-end"
                className="switcher"
                width={212}
                fixedHeader={
                  <>
                    {currentDestination != null && (
                      <>
                        <div className="details-container-inner">
                          <SiteLogo className="mr-3" />
                          <div>
                            <div>
                              <h6 className="siteName siteName--large">{name}</h6>
                            </div>
                          </div>
                        </div>
                        {jwtRoles.map((role) => {
                          return (
                            <Dropdown.Item
                              key={role}
                              onClick={withConfirmation({
                                title: t("home.switchRole"),
                                message: t("home.areYouSureSwitch"),
                                confirmButtonText: t("home.yesSwitch"),
                                onConfirm: async () => switchRoleAction(role),
                                cancelButtonText: t("home.widgets.cancelNo"),
                              })}
                              selected={RoleFromJWTRole(role) === currentRole}
                            >
                              {RoleFromJWTRole(role)}
                            </Dropdown.Item>
                          );
                        })}
                      </>
                    )}
                    {siteList.length > 1 && <Dropdown.Divider />}
                  </>
                }
              >
                {siteList.length > 1 && (
                  <>
                    <Dropdown.Header>{t("home.switchSites")}</Dropdown.Header>
                    {siteList.length > 6 && (
                      <Form.InputGroup.Group className="m-3 search-company-container">
                        <Form.InputGroup.Prefix>
                          <RiSearchLine color={colors.muted1} size={15} />
                        </Form.InputGroup.Prefix>
                        <Form.Input
                          placeholder={t("home.findSite")}
                          onChange={(e) => setFilterText(e.target.value)}
                          className="search"
                          value={filterText}
                        />
                        <Form.InputGroup.Suffix>
                          <RiCloseCircleLine
                            style={{
                              display: filterText.length === 0 ? "none" : "block",
                              cursor: "pointer",
                            }}
                            onClick={() => setFilterText("")}
                            color={colors.muted1}
                            size={15}
                          />
                        </Form.InputGroup.Suffix>
                      </Form.InputGroup.Group>
                    )}
                    {emptySearch ? (
                      <div className="empty-search text-center text-muted m-3 search-company-container">
                        <p className="crop-text">{t("home.noResultsFoundFor", { filterText })}</p>
                      </div>
                    ) : (
                      (filteredSites.length > 0 ? filteredSites : siteList)
                        .filter((e) => e.node?.uuid !== uuid)
                        .map((siteEdge) => {
                          const destination = siteEdge.node;
                          if (destination == null) {
                            return null;
                          }

                          return (
                            <Dropdown.Item
                              onClick={() => setShowSwitchSiteModal({ show: true, destination })}
                              key={destination.uuid}
                              className="site-list-item"
                            >
                              <SiteListItem destination={destination} size="small" />
                            </Dropdown.Item>
                          );
                        })
                    )}
                  </>
                )}
              </Dropdown.Icon>
            </div>
          </Tooltip>
        ) : (
          <div></div>
        )}
      </div>
      <style jsx>{`
        .container {
          border-bottom: solid 1px rgba(0, 0, 0, 0.1);
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: ${spacers.s2} ${spacers.s4};
          height: 64px;
        }
        .container-inner {
          display: flex;
          align-items: center;
          justify-content: left;
        }
        .details-container-inner {
          padding: ${spacers.s2} ${spacers.s4} ${spacers.s4};
          display: flex;
          align-items: center;
          justify-content: left;
          min-width: 210px;
        }
        .siteName {
          font-size: 14px;
          line-height: 16px;
          font-weight: bold;
          cursor: default;
        }
        .siteName--large {
          font-size: 16px;
          line-height: 20px;
        }
        .role {
          font-size: 12px;
          line-height: 16px;
          white-space: nowrap;
          cursor: default;
        }
        .empty-search {
          font-size: 12px;
        }
        .crop-text {
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
        :global(.site-list-item) {
          padding: ${spacers.s2} !important;
          margin-bottom: 0 !important;
          white-space: wrap !important;
        }
        :global(.search-company-container) {
          width: 194px;
        }
        .new-tab-open-link {
          color: ${colors.primary};
          text-decoration: none;
        }
      `}</style>
    </>
  );
};
