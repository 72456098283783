import React from "react";
import { useTranslation } from "@equiem/localisation-eq1";

import { EmptySearchIcon } from "./EmptySearchIcon";

export const EmptySearch: React.FC<{ searchTerm: string }> = ({ searchTerm }) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="empty-search text-center text-muted py-9">
        <EmptySearchIcon />
        <p className="mt-5">{t("segmentation.emptySearch", { searchTerm })}</p>
        <p>{t("segmentation.emptySearchDescription")}</p>
      </div>
      <style jsx>{`
        p {
          padding: 0;
          margin: 0;
        }
      `}</style>
    </>
  );
};
