import { Button, Modal, Text, useTheme } from "@equiem/react-admin-ui";
import { useContext } from "react";
import { NewInvites } from "./NewInvitesContext";
import { useTranslation } from "@equiem/localisation-eq1";
import { Widget } from "./WidgetProvider";

export const ErrorInner: React.FC = () => {
  const { closeModal } = useContext(Widget);
  const { close, submissionResponse } = useContext(NewInvites);
  const { borderRadius, colors, spacers } = useTheme(true);
  const { t } = useTranslation();

  return (
    <>
      <Modal.Body>
        {submissionResponse?.existingEmails !== "" && (
          <>
            <Text variant="text">{t("home.widgets.theFollowingUsersHaveAlreadyRegistered")}:</Text>
            <div className="section">
              <Text variant="text">{submissionResponse?.existingEmails}</Text>
            </div>
          </>
        )}
        {submissionResponse?.existingInviteEmails !== "" && (
          <>
            <Text variant="text">{t("home.widgets.theFollowingUsersHaveAlreadyInvited")}:</Text>
            <div className="section">
              <Text variant="text">{submissionResponse?.existingInviteEmails}</Text>
            </div>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" className="invite-btn" onClick={() => close(closeModal)}>
          {t("common.done")}
        </Button>
      </Modal.Footer>
      <style jsx>{`
        .section {
          border-radius: ${borderRadius};
          background: ${colors.adminBackground};
          padding: ${spacers.s5};
        }
      `}</style>
    </>
  );
};
