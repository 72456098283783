import { RiInformationLine } from "@equiem/react-admin-ui/icons";
import React, { useState } from "react";
import { useCreditAccountAdjustCreditsMutation } from "../../../generated/gateway-client";
import { CreditsAdjustemntsModalContainer } from "./CreditsAdjustments";
import { SuccessfulAdjustmentToast } from "./SuccessfulAdjustmentToast";
import { Button, useTheme, useToast } from "@equiem/react-admin-ui";
import { useApolloErrorTranslation, useTranslation } from "@equiem/localisation-eq1";

export const CreditsAdjustmentsWidget = ({ onUpdate }: { onUpdate?: () => void }) => {
  const { colors } = useTheme();
  const { tError } = useApolloErrorTranslation();
  const { t } = useTranslation();
  const toaster = useToast();
  const [showCreditAdjustmentsModal, setShowCreditAdjustmentsModal] = useState(false);
  const [creditAccountAdjustCredits] = useCreditAccountAdjustCreditsMutation();
  const onAgree = ({
    creditsAmount,
    accountUuid,
    flexTenantName,
  }: {
    creditsAmount: number;
    accountUuid: string;
    flexTenantName: string;
  }) => {
    creditAccountAdjustCredits({ variables: { input: { accountUuid, amount: creditsAmount } } })
      .then(() => {
        toaster.positive(<SuccessfulAdjustmentToast flexTenantName={flexTenantName} />);
        onUpdate?.();
        setShowCreditAdjustmentsModal(false);
      })
      .catch((e) => {
        toaster.negative(
          <div className="error-toast">
            <RiInformationLine size={24} color={colors.white} />
            {tError(e)}
          </div>,
        );
      });
  };

  return (
    <>
      <Button variant="secondary" onClick={() => setShowCreditAdjustmentsModal(true)}>
        {t("credits.overview.adjustCredits")}
      </Button>

      {showCreditAdjustmentsModal && (
        <CreditsAdjustemntsModalContainer
          showModal={showCreditAdjustmentsModal}
          onAgree={onAgree}
          onClose={() => {
            setShowCreditAdjustmentsModal(false);
          }}
        />
      )}
      <style jsx>
        {`
          :global(.error-toast) {
            display: flex;
            align-items: center;
            gap: 8px;
            width: 100%;
            justify-content: space-between;
          }
        `}
      </style>
    </>
  );
};
