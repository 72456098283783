import type { AppContext } from "next/app";
import { CookieJar } from "./auth/cookies/CookieJar";

export const getCookieJar = ({ ctx }: AppContext) => {
  if (ctx.req?.headers.host != null) {
    const host = ctx.req.headers.host;
    const hostname = host.split(":")[0];
    return new CookieJar(ctx.req.headers.cookie, hostname);
  } else {
    const hostname = window.location.hostname;
    return new CookieJar(document.cookie, hostname);
  }
};
