import { DateTime } from "luxon";

import { formatters } from "@equiem/localisation-eq1";

import type { DurationDate } from "../types";

/**
 * Get the available duration intervals for a given appointment.
 *
 * @param startTime The unix timestamp in seconds, of the start time, relative to the current date.
 * @param inputDate The yyyy-MM-dd formatted date input of the start date selected for the appointment.
 * @param language The currently selected language code.
 * @returns A Map of available durations, indexed by start time in unix millisecond timestamps.
 */
export const getAppointmentIntervals = (startTime: number, inputDate: string, language: string) => {
  const res: Map<number, DurationDate> = new Map();
  const startTimeObj = DateTime.fromSeconds(Number(startTime)).toObject();
  const date = DateTime.fromFormat(inputDate, "yyyy-MM-dd");
  const { year, month, day } = date.toObject();

  const incrementMinutes = 15;

  const minDate = DateTime.fromObject({
    hour: startTimeObj.hour,
    minute: startTimeObj.minute,
    second: startTimeObj.second,
    year,
    month,
    day,
  });
  const maxDate = DateTime.fromObject({ year, month, day, hour: 23, minute: 59, second: 0 });

  // Round to closest starting increment.
  let nextDate = DateTime.fromSeconds(Math.floor(minDate.toSeconds() / 60 / incrementMinutes) * incrementMinutes * 60);

  while (nextDate.diff(maxDate).as("minutes") < 0) {
    nextDate = nextDate.plus({ minutes: incrementMinutes });

    if (!nextDate.hasSame(date, "day")) {
      nextDate = nextDate.minus({ minutes: 1 });
    }

    res.set(nextDate.toMillis(), {
      label: `${formatters.durationshort(
        Math.max(Math.round(nextDate.diff(minDate, "minutes").as("minute")), 1),
        language,
      )} (${formatters.timeshort(nextDate, language)})`,
      value: nextDate.toFormat("h:mma").toLowerCase(),
    });
  }

  return res;
};
