import React, { useState } from "react";
import type { AutocompleteProps } from "@react-google-maps/api";
import { Autocomplete as WithAutocomplete, LoadScript } from "@react-google-maps/api";

import { Form } from "@equiem/react-admin-ui";

import type { AddressInfo } from "../types";
import { formatPlaceResult, googleMapsApiKey } from "../utils/address";

interface Props {
  name: string;
  value: AddressInfo;
  disabled?: boolean;
  onChange: (addressInfo: AddressInfo) => void;
}

interface GoogleMapsProps {
  onLoad: AutocompleteProps["onLoad"];
  onPlaceChanged: AutocompleteProps["onPlaceChanged"];
  children: AutocompleteProps["children"];
}

type Autocomplete = google.maps.places.Autocomplete;

const WithGoogleMaps: React.FC<GoogleMapsProps> = ({ onLoad, onPlaceChanged, children }) => {
  return googleMapsApiKey == null ? (
    <>{children}</>
  ) : (
    <LoadScript libraries={["places"]} googleMapsApiKey={googleMapsApiKey}>
      <WithAutocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged}>
        {children}
      </WithAutocomplete>
    </LoadScript>
  );
};

export const AddressInput: React.FC<Props> = ({ name, value, disabled, onChange }) => {
  const [autocomplete, setAutocomplete] = useState<null | Autocomplete>(null);

  const onPlaceChanged = async () => {
    const place = autocomplete?.getPlace();

    if (place != null) {
      onChange(await formatPlaceResult(place, value));
    }
  };

  const onRawValueChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange({ ...value, address: event.target.value });
  };

  const handleBlur = () => {
    onChange({ ...value, address: value.address });
  };

  return (
    <WithGoogleMaps
      onLoad={setAutocomplete}
      onPlaceChanged={() => {
        void onPlaceChanged();
      }}
    >
      <Form.Input
        type="text"
        name={name}
        onChange={onRawValueChanged}
        onBlur={handleBlur}
        disabled={disabled}
        value={value.address}
      />
    </WithGoogleMaps>
  );
};
