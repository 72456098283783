import React, { useCallback, useContext, useState } from "react";
import { Form, Tag, Toggler, useTheme } from "@equiem/react-admin-ui";
import { RiCloseCircleLine, RiSearchLine } from "@equiem/react-admin-ui/icons";
import { useTranslation } from "@equiem/localisation-eq1";

import { CurrentSegment } from "../contexts/SegmentProvider";
import type { Filter as IFilter } from "../contexts/SegmentProvider";
import { FilterSuffix } from "./FilterSuffix";
import { mapFilterTypeToFilter, translateFilterType } from "../lib/mapGraphqlFilterType";
import { groupItems } from "../lib/groupItems";
import { useSummaryFilter } from "../hooks/useSummaryForFilter";
import { EmptySearch } from "./EmptySearch";

interface Props {
  filter: IFilter;
  segmentId: string;
}

export const Filter: React.FC<Props> = ({ segmentId, filter }) => {
  const { t } = useTranslation();
  const { filterItems, toggleItem, toggleAllItemsForFilter } = useContext(CurrentSegment);
  const [filterSummary, count] = useSummaryFilter(filterItems);
  const theme = useTheme();
  const [items, setItems] = useState(filterItems.filter((fi) => mapFilterTypeToFilter(fi.filterType) === filter.type));
  const [filterText, setFilterText] = useState<string>("");
  const [allSelected, setAllSelected] = useState(items.find((i) => !i.checked) === undefined);

  const groupedItems = groupItems(items);

  const filteredItems = groupedItems.filter((i) => {
    if (filterText.trim() === "" || i.name.match(new RegExp(filterText, "igu")) != null) {
      return i;
    }
    return false;
  });

  const toggle = (groupedId: string, check: boolean) => {
    groupedItems
      .find((gi) => gi.id === groupedId)
      ?.values.forEach((val) => {
        toggleItem(val.uuid, check);
        setItems((prev) =>
          prev.map((p) => {
            if (p.uuid === val.uuid) {
              return {
                ...p,
                checked: check,
              };
            } else {
              return p;
            }
          }),
        );
      });
  };

  const selectAllFn = useCallback(
    (checked: boolean) => {
      toggleAllItemsForFilter(filter.type, checked);
      setItems((prev) => prev.map((p) => ({ ...p, checked })));
      setAllSelected(checked);
    },
    [toggleAllItemsForFilter, filter],
  );

  const summary = useCallback(() => {
    const sum = filterSummary(filter.type);

    return <div className="filter-summary">{sum}</div>;
  }, [filterSummary, filter.type, t]);

  return (
    <>
      <Toggler
        heading={
          <span className="heading">
            {translateFilterType(filter.type, t)}{" "}
            <Tag type="counter" className="ms-2" variant="dark" value={count(filter.type)} />
          </span>
        }
        subheading={summary()}
        headingSuffix={<FilterSuffix filter={filter.type} />}
        className="filter-toggler mb-2"
      >
        <Form.InputGroup.Group className="m-2">
          <Form.InputGroup.Prefix>
            <RiSearchLine size={18} />
          </Form.InputGroup.Prefix>
          <Form.Input
            type="text"
            placeholder={t("common.search")}
            className="search"
            value={filterText}
            onChange={(e) => setFilterText(e.target.value)}
          />
          {filterText !== "" && (
            <Form.InputGroup.Suffix>
              <RiCloseCircleLine onClick={() => setFilterText("")} />
            </Form.InputGroup.Suffix>
          )}
        </Form.InputGroup.Group>
        <div className="select-all m-2">
          <Form.Checkbox
            name={`all-${filter.type}`}
            id={`${segmentId}-all-${filter.type}`}
            label={t("common.selectAll")}
            value={allSelected}
            onChange={(e) => selectAllFn(e.target.checked)}
          />
        </div>
        {filteredItems.length === 0 && <EmptySearch searchTerm={filterText} />}
        <div className="selections m-2 mt-3">
          {filteredItems.map((item) => {
            return (
              <Form.Checkbox
                label={item.name}
                key={item.id}
                id={`${segmentId}-${item.id}`}
                name={item.id}
                value={item.checked}
                onChange={(e) => toggle(item.id, e.target.checked)}
                className="mb-2 filter-check"
              />
            );
          })}
        </div>
      </Toggler>
      <style jsx>{`
        .heading {
          align-self: flex-start;
          display: flex;
          align-items: center;
        }
        .select-all {
          border-bottom: 1px solid ${theme.colors.border};
          padding: ${theme.spacers.s4} 0;
          margin-bottom: ${theme.spacers.s3};
        }
        .selections {
          overflow-y: scroll;
          max-height: 240px;
          padding: ${theme.spacers.s3} ${theme.spacers.s3} 0;
        }
      `}</style>
    </>
  );
};
