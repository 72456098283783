import { useCallback, useContext, useEffect, useMemo, useState } from "react";

import { CurrentProfile, CurrentRole, notNullOrUndefined, Role, useShowError } from "@equiem/lib";

import type { Building, BuildingLevel, ProfileSiteProfileConnection, Space } from "../../../generated/requests-client";
import {
  ProfileRegistrationType,
  SpaceVisibilityType,
  useCategoriesLazyQuery,
  useFlexManagerBuildingsQuery,
  useFlexManagerLevelsLazyQuery,
  useMyResidenceQuery,
  useMySpacesQuery,
} from "../../../generated/requests-client";
import { getUniqueArray } from "../../../utils/getUniqueArray";
import { StepType } from "../../../utils/types";
import { CreateRequestContext } from "../CreateRequestContext";

export function useBuildingData() {
  const showError = useShowError();
  const { values } = useContext(CreateRequestContext);
  const { currentRole } = useContext(CurrentRole);
  const { profile } = useContext(CurrentProfile);
  const isFlexManager = useMemo(() => currentRole === Role.FlexManager, [currentRole]);
  const isUserResidential = useMemo(
    () =>
      profile?.siteProfiles.edges.some((sp) => sp.node?.registrationType === ProfileRegistrationType.Residential) ??
      false,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const [categoriesQuery, { data: categoriesData, loading: categoriesLoading }] = useCategoriesLazyQuery();
  const [flexLevelQuery, { data: flexLevelData, loading: flexLevelLoading }] = useFlexManagerLevelsLazyQuery();
  const {
    data: flexBuildingsData,
    loading: flexBuildingsLoading,
    error: flexBuildingsError,
  } = useFlexManagerBuildingsQuery({
    variables: {
      first: 100,
    },
    skip: !isFlexManager,
  });
  const { data: myResidenceData, loading: myResidenceLoading } = useMyResidenceQuery({
    skip: !isUserResidential,
  });
  const { data: mySpacesData, loading: mySpacesLoading } = useMySpacesQuery({
    fetchPolicy: "cache-and-network",
    skip: isUserResidential || isFlexManager,
  });

  const [searchQuery, setSearchQuery] = useState("");

  const spacesBasedOnVisibility = useCallback(
    (listOfSpaces: Array<{ visibilityType: SpaceVisibilityType }>, visibilityType: SpaceVisibilityType) =>
      listOfSpaces.filter((space) => space.visibilityType === visibilityType),
    [],
  );

  const extractBuildings = useCallback(
    (myResidenceSitesProfile: ProfileSiteProfileConnection | undefined): Array<Pick<Building, "uuid" | "name">> => {
      const buildingsWithSpaces = (myResidenceSitesProfile?.edges ?? [])
        .flatMap((prof) =>
          prof.node?.apartment?.buildingLevels.filter(
            (level) => spacesBasedOnVisibility(level.spaces, SpaceVisibilityType.Public).length > 0,
          ),
        )
        .filter(notNullOrUndefined)
        .reduce<Record<string, { uuid: string; name: string }>>((obj, level) => {
          obj[level.building.uuid] = { uuid: level.building.uuid, name: level.building.name };
          return obj;
        }, {});
      return Object.values(buildingsWithSpaces);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [myResidenceData?.profile?.siteProfiles],
  );

  const extractBuildingLevels = useCallback(
    (myResidenceSitesProfile: ProfileSiteProfileConnection | undefined, buildingUuid: string) => {
      if (myResidenceSitesProfile != null) {
        const residence = myResidenceSitesProfile.edges.find(
          (sp) => sp.node?.apartment?.buildingLevels != null && sp.node.apartment.buildingLevels.length > 0,
        );
        return (
          residence?.node?.apartment?.buildingLevels
            .map((level) =>
              values.buildingUuid != null &&
              spacesBasedOnVisibility(level.spaces, SpaceVisibilityType.Public).length > 0 && // don't show levels without spaces
              level.building.uuid === buildingUuid
                ? level
                : null,
            )
            .filter(notNullOrUndefined) ?? []
        );
      }

      return [];
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [myResidenceData?.profile?.siteProfiles, values.buildingUuid],
  );

  const buildings = useMemo(() => {
    if (isUserResidential) {
      return extractBuildings(myResidenceData?.profile?.siteProfiles as ProfileSiteProfileConnection);
    }
    if (isFlexManager && flexBuildingsData != null) {
      return flexBuildingsData.myFlexBuildings.edges.flatMap((edge) => edge.node) as Building[];
    }
    return getUniqueArray(mySpacesData?.mySpaces as Space[], values, StepType.BUILDINGS);
  }, [
    myResidenceData,
    isUserResidential,
    isFlexManager,
    flexBuildingsData,
    extractBuildings,
    mySpacesData?.mySpaces,
    values,
  ]);

  const buildingLevels = useMemo(() => {
    if (isUserResidential) {
      return extractBuildingLevels(
        myResidenceData?.profile?.siteProfiles as ProfileSiteProfileConnection,
        values.buildingUuid ?? "",
      );
    }
    if (isFlexManager && flexLevelData != null) {
      return flexLevelData.myFlexLevels.filter(
        (level) => spacesBasedOnVisibility(level.spaces, SpaceVisibilityType.Members).length > 0,
      ) as BuildingLevel[];
    }
    return getUniqueArray(mySpacesData?.mySpaces as Space[], values, StepType.LEVELS) as BuildingLevel[];
  }, [
    myResidenceData,
    isUserResidential,
    isFlexManager,
    flexLevelData,
    extractBuildingLevels,
    spacesBasedOnVisibility,
    mySpacesData?.mySpaces,
    values,
  ]);

  if (flexBuildingsError != null) {
    showError(flexBuildingsError);
  }

  const spaces = useMemo(() => {
    const buildingLevel = buildingLevels.find(
      (bl: { uuid: string }) => bl.uuid === values.buildingLevelUuid,
    ) as BuildingLevel | null;
    if (buildingLevel == null) {
      return [];
    }
    if (isUserResidential) {
      return buildingLevel.spaces;
    }
    if (isFlexManager && flexLevelData != null) {
      return flexLevelData.myFlexLevels
        .flatMap((level) =>
          buildingLevel.uuid === level.uuid &&
          spacesBasedOnVisibility(level.spaces, SpaceVisibilityType.Members).length > 0
            ? spacesBasedOnVisibility(level.spaces, SpaceVisibilityType.Members)
            : null,
        )
        .filter(notNullOrUndefined) as Space[];
    }
    return getUniqueArray(mySpacesData?.mySpaces as Space[], values, StepType.SPACES);
  }, [
    buildingLevels,
    isUserResidential,
    values,
    mySpacesData?.mySpaces,
    isFlexManager,
    flexLevelData,
    spacesBasedOnVisibility,
  ]);

  useEffect(() => {
    if (values.buildingUuid != null) {
      if (!isUserResidential && isFlexManager) {
        flexLevelQuery({
          variables: {
            building: values.buildingUuid,
          },
        }).catch(showError);
      }
      // after space is selected, we need to fetch categories
      if (spaces.length > 0 && values.spaceUuid != null) {
        const space = spaces.find((s) => s.uuid === values.spaceUuid);
        const variables = {
          variables: {
            buildingUuid: values.buildingUuid,
            ownerCompanyUuid: space?.ownerCompany != null ? space.ownerCompany.uuid : undefined,
          },
        };
        categoriesQuery(variables).catch(showError);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.buildingUuid, spaces]);

  const levels = useMemo(() => {
    return searchQuery.length > 0
      ? buildingLevels.filter((level) => level.name.toLowerCase().includes(searchQuery.toLowerCase()))
      : buildingLevels;
  }, [buildingLevels, searchQuery]);

  const categories = useMemo(() => categoriesData?.reqMgt.categories ?? [], [categoriesData]);

  const locationName = useMemo(() => {
    const building = buildings.find((b) => b.uuid === values.buildingUuid);
    const level = levels.find((l) => l.uuid === values.buildingLevelUuid);
    const space = spaces.find((s) => s.uuid === values.spaceUuid);

    if (building == null || level == null || space == null) {
      return "";
    }

    return `${building.name}, ${level.name}, ${space.name}`;
  }, [buildings, levels, spaces, values]);

  const currentCategory = useMemo(() => {
    return categories.find((c) => c.uuid === values.categoryUuid);
  }, [categories, values]);

  const loading: boolean = useMemo(() => {
    return flexBuildingsLoading || flexLevelLoading || categoriesLoading || myResidenceLoading || mySpacesLoading;
  }, [categoriesLoading, flexLevelLoading, myResidenceLoading, flexBuildingsLoading, mySpacesLoading]);

  return {
    buildings,
    levels,
    spaces,
    categories,
    locationName,
    currentCategory,
    loading,
    searchQuery,
    setSearchQuery,
  };
}
