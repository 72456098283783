import { Button, Form, Modal, Text } from "@equiem/react-admin-ui";
import type { ChangeEvent } from "react";
import { useCallback, useContext, useState } from "react";
import { NewInvites } from "./NewInvitesContext";
import { useTranslation } from "@equiem/localisation-eq1";
import { Widget } from "./WidgetProvider";

export const BulkInner: React.FC = () => {
  const { t } = useTranslation();
  const { close, setScreen, addBulkEmails } = useContext(NewInvites);
  const { confirmCancel } = useContext(Widget);
  const [emails, setEmails] = useState<string[]>([]);

  const cancel = useCallback(() => {
    confirmCancel(close);
  }, [close, confirmCancel]);

  const onChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const text = e.target.value;

    const newEmails = text
      .split(",")
      .map((email) => email.split("\n").map((e2) => e2.trim()))
      .flat();
    setEmails(newEmails);
  };

  const localSubmit = () => {
    addBulkEmails(emails);
    setScreen("table");
  };

  return (
    <>
      <Modal.Body>
        <Text className="my-5 intro" variant="heading" size="large">
          {t("home.widgets.bulkUserUpload")}
        </Text>
        <Form.Group
          className="pt-3"
          label={t("home.widgets.copyAndPasteEmailAddresses")}
          showTooltip={true}
          tooltipText={t("home.widgets.copyAndPasteEmailAddressesTooltip")}
        >
          <Form.Textarea
            className="bulk-textarea"
            placeholder={t("home.widgets.emailAddressesBulk") ?? ""}
            onChange={onChange}
          />
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="ghost" className="me-2 cancel-btn" onClick={cancel}>
          {t("common.cancel")}
        </Button>
        <Button variant="primary" className="invite-btn" onClick={localSubmit} disabled={emails.length === 0}>
          {t("common.upload")}
        </Button>
      </Modal.Footer>
    </>
  );
};
