/* eslint-disable complexity */
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Field, Form, Formik } from "formik";
import { useRouter } from "next/router";
import * as yup from "yup";

import { ButtonTray, CurrentProfile, notNullOrUndefined, Role, Site, SubTopMenu, useShortcuts } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import { Alert, Button, Form as EqForm, MemberCard, UserSearch, useTheme, useToast } from "@equiem/react-admin-ui";
import { RiErrorWarningLine } from "@equiem/react-admin-ui/icons";

import type { VisitorProfileShortFragment } from "../../generated/visitors-client";
import {
  useCompaniesV2Query,
  useGetOperationsCompanyQuery,
  useListProfilesQuery,
  useSetAppointmentOrganizerRoleMutation,
  useUpdateOperationsCompanyMutation,
  useVisitorManagementSiteSettingsQuery,
} from "../../generated/visitors-client";

import { ConfirmationWidget } from "./components/ConfirmationWidget";
import { Menu } from "./components/NestedMenu";
import { NoUsersPlaceholder } from "./components/NoUsersPlaceholder";

interface FormProperties {
  company: string;
}

interface Props {
  currentRole: Role;
}

type SelectedUser = {
  existingUser?: boolean;
  unsetRole?: boolean;
} & VisitorProfileShortFragment;

enum RadioGroup {
  AllUsers = "allUsers",
  SelectedUsers = "selectedUsers",
}

export const ReceptionsPermissions: React.FC<Props> = ({ currentRole }) => {
  const { t } = useTranslation();
  const theme = useTheme(true);
  const site = useContext(Site);
  const profile = useContext(CurrentProfile);
  const toast = useToast();
  const router = useRouter();

  const [radio, setRadio] = useState("");
  const { data: defaultSiteSettingsMaxAppointmentCreationMonths } = useVisitorManagementSiteSettingsQuery({
    variables: {
      siteUuid: site.uuid,
    },
  });

  const customMaxAppointmentCreationMonthsOptions: Array<{
    value: string;
    label: string;
  }> = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((i) => ({
    label: (i + 1).toString(),
    value: (i + 1).toString(),
  }));

  const [maxAppointmentCreationMonths, setMaxAppointmentCreationMonths] = useState<number | null | undefined>();
  const [searchTerm, setSearchTerm] = useState("");
  const [touched, setTouched] = useState(false);
  const [companyUuid, setCompanyUuid] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [action, setAction] = useState<() => void | undefined>();
  const { dispatch } = useShortcuts<string>();

  const isPropertyManager = useMemo(() => currentRole === Role.PropertyManager, [currentRole]);
  const userFilterCompanyUuid = useMemo(
    () => (isPropertyManager ? companyUuid : profile.profile?.companyV2?.uuid),
    [companyUuid, isPropertyManager, profile.profile?.companyV2?.uuid],
  );

  const [setUsersRoleMutation, { loading: setUserLoading }] = useSetAppointmentOrganizerRoleMutation();
  const [updateCompanyMutation, { loading: updateCompanyLoading }] = useUpdateOperationsCompanyMutation();

  const { data: companyData, refetch: refetchCompanyData } = useGetOperationsCompanyQuery({
    variables: {
      ...(isPropertyManager ? { companyUuid } : {}),
    },
  });
  const [maxAppointmentCreationMonthsCustomSelected, setMaxAppointmentCreationMonthsCustomSelected] = useState<boolean>(
    companyData?.operationsCompany?.maxAppointmentCreationMonths != null,
  );

  const [selectedUsers, setSelectedUsers] = useState<SelectedUser[]>([]);

  useEffect(() => {
    /* istanbul ignore next */
    const routeChangeStart = (route: string) => {
      if (router.pathname !== route) {
        const changeRouteAction = () => () => {
          void router.push({ pathname: route });
        };
        setAction(changeRouteAction);
      }
      if (touched && !showModal) {
        router.events.emit("routeChangeError");
        const pathname = "/visitor-management/settings/permissions";

        void router.replace({ pathname }, undefined, { shallow: true });
        setShowModal(true);
      }
    };
    router.events.on("routeChangeStart", routeChangeStart);
    return () => {
      router.events.off("routeChangeStart", routeChangeStart);
    };
  }, [router, touched, showModal]);

  useEffect(() => {
    const userData = companyData?.operationsCompany?.users.map((user) => ({
      existingUser: true,
      companyV2: user.profile?.companyV2,
      ...user.profile,
    })) as SelectedUser[];
    userData != null ? setSelectedUsers(userData) : setSelectedUsers([]);
  }, [companyData, radio]);

  useEffect(() => {
    if (companyData?.operationsCompany == null) {
      setRadio("");
      setMaxAppointmentCreationMonths(null);
      setMaxAppointmentCreationMonthsCustomSelected(false);
    } else {
      const selectedOption = companyData.operationsCompany.everyoneCanCreateAppointments
        ? RadioGroup.AllUsers
        : RadioGroup.SelectedUsers;
      setRadio(selectedOption);
      setMaxAppointmentCreationMonths(companyData.operationsCompany.maxAppointmentCreationMonths);
      setMaxAppointmentCreationMonthsCustomSelected(companyData.operationsCompany.maxAppointmentCreationMonths != null);
    }
  }, [
    companyData,
    defaultSiteSettingsMaxAppointmentCreationMonths?.visitorManagementSiteSettings.maxAppointmentCreationMonths,
  ]);

  useEffect(() => {
    setTouched(false);
  }, [currentRole]);

  const { data: companiesQueryData, loading: companiesLoading } = useCompaniesV2Query({
    variables: {
      first: 1000,
      destinationUuid: site.uuid,
    },
  });
  const {
    data: searchUserData,
    refetch: searchUsers,
    loading,
  } = useListProfilesQuery({
    variables: {
      page: {
        first: 20,
      },
      filter: {
        active: true,
        ...(userFilterCompanyUuid !== "" ? { companyUuid: userFilterCompanyUuid } : {}),
      },
    },
    skip: searchTerm === "",
  });

  useEffect(() => {
    void searchUsers({
      filter: {
        name: searchTerm,
        email: searchTerm,
        ...(userFilterCompanyUuid !== "" ? { companyUuid: userFilterCompanyUuid } : {}),
      },
    });
  }, [searchTerm, searchUsers, userFilterCompanyUuid]);

  const validationSchema = yup.object().shape({
    company: yup.string(),
  });

  const companies =
    companiesQueryData != null
      ? companiesQueryData.companiesV2.edges.map((edge) => ({
          name: edge.node?.name,
          uuid: edge.node?.uuid,
        }))
      : [];

  const handleSubmit = async ({ company }: FormProperties) => {
    setTouched(false);
    if (radio === RadioGroup.AllUsers) {
      await updateCompanyMutation({
        variables: {
          input: {
            everyoneCanCreateAppointments: true,
            maxAppointmentCreationMonths: maxAppointmentCreationMonthsCustomSelected
              ? maxAppointmentCreationMonths
              : null,
            ...(isPropertyManager ? { uuid: company } : {}),
          },
        },
      });
    }
    if (radio === RadioGroup.SelectedUsers) {
      const payloadUsers = selectedUsers.map((user) => ({
        userUuid: user.uuid,
        ...(user.unsetRole === true && { unsetRole: true }),
      }));
      await updateCompanyMutation({
        variables: {
          input: {
            everyoneCanCreateAppointments: false,
            maxAppointmentCreationMonths: maxAppointmentCreationMonthsCustomSelected
              ? maxAppointmentCreationMonths
              : null,
            ...(isPropertyManager ? { uuid: company } : {}),
          },
        },
      });

      await setUsersRoleMutation({
        variables: {
          ...(isPropertyManager ? { company } : {}),
          input: payloadUsers,
        },
      });
    }
    toast.positive(t("visitors.settings.permissionsSaved"));
    await refetchCompanyData();
  };

  const removeUser = (uuid: string) => {
    setTouched(true);
    const unsetRole = selectedUsers.find((user) => user.uuid === uuid && user.existingUser);
    if (unsetRole != null) {
      const updatedUser = selectedUsers.map((user) => (user.uuid === uuid ? { ...user, unsetRole: true } : user));
      setSelectedUsers(updatedUser);
    } else {
      const filteredUsers = selectedUsers.filter((user) => user.uuid !== uuid);
      setSelectedUsers(filteredUsers);
    }
  };

  const noSelectedUsers = () =>
    selectedUsers.length === 0 || selectedUsers.filter((user) => user.unsetRole !== true).length === 0;

  const radioDisabled = (company: string) => company === "" && currentRole === Role.PropertyManager;
  const saveDisabled = (company: string) =>
    setUserLoading || updateCompanyLoading || radio === "" || radioDisabled(company);
  const searchDisabled = (company: string) => radioDisabled(company) || radio !== RadioGroup.SelectedUsers;
  const userIsSelected = useCallback(
    (uuid: string) => selectedUsers.some((user) => user.uuid === uuid && user.unsetRole !== true),
    [selectedUsers],
  );
  const setAllow = (user: SelectedUser) => {
    setTouched(true);
    const userWasUnset = selectedUsers.find((u) => u.uuid === user.uuid && u.unsetRole);
    if (userWasUnset != null) {
      const backUserToAllow = selectedUsers.map((u) => (u.uuid === user.uuid ? { ...u, unsetRole: false } : u));
      setSelectedUsers(backUserToAllow);
    } else {
      setSelectedUsers([...selectedUsers, user]);
    }
  };
  const setCompany = (value: string, setValues: (data: { company: string }) => unknown) => {
    const setCompanyAction = () => () => {
      setValues({ company: value });
      setCompanyUuid(value);
      setTouched(false);
    };
    /* istanbul ignore next */
    if (touched) {
      setAction(setCompanyAction);
      setShowModal(true);
    } else {
      setCompanyAction()();
    }
  };

  const suggestedUsers = useMemo(
    () =>
      searchUserData?.listProfiles.edges
        .map((e) => e.node)
        .filter(notNullOrUndefined)
        .map((u) => ({ ...u, selected: userIsSelected(u.uuid) }))
        .map((user) => ({ value: "", user })) ?? [],
    [searchUserData, userIsSelected],
  );

  return (
    <>
      <div className="page-container">
        <Formik initialValues={{ company: "" }} validationSchema={validationSchema} onSubmit={handleSubmit}>
          {({ submitForm, values, setValues }) => (
            <Form>
              <SubTopMenu btmPadding={false} topPadding={false}>
                <h1 className="font-weight-bold mb-2">Settings</h1>
              </SubTopMenu>
              <SubTopMenu btmPadding={false} topPadding={false} minHeight={false} alignItems="flex-end" sticky>
                <Menu />
              </SubTopMenu>
              <div className="d-flex sections-container">
                <div className="left-container">
                  <div className="d-flex" style={{ flexDirection: "column" }}>
                    <span className="item-description">{t("visitors.settings.managePermissions")}</span>
                    {currentRole === Role.PropertyManager && (
                      <EqForm.Group
                        label={t("common.company")}
                        required
                        showTooltip={true}
                        tooltipText={t("visitors.settings.tenantSelectTooltip")}
                      >
                        <Field
                          name="company"
                          value={values.company}
                          as={EqForm.Select}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            setCompany(e.target.value, setValues);
                          }}
                          placeholder={t("visitors.settings.tenantSelectPlaceholder")}
                        >
                          <option value="" disabled>
                            {companiesLoading
                              ? `${t("common.loading")}...`
                              : companies.length === 0
                              ? t("common.noneAvailable")
                              : `${t("visitors.appointmentForm.selectCompany")}...`}
                          </option>
                          {companies.map((company) => (
                            <option key={company.uuid} value={company.uuid}>
                              {company.name}
                            </option>
                          ))}
                        </Field>
                      </EqForm.Group>
                    )}

                    <EqForm.Group
                      label={t("visitors.settings.visitorsBlockTitle")}
                      required
                      showTooltip={true}
                      tooltipText={t("visitors.settings.visitorsBlockTooltip")}
                    >
                      <div className="label-container">
                        <label className="label-item">
                          <Field
                            as={() => (
                              <input
                                id="InviteUsersSelect"
                                disabled={radioDisabled(values.company)}
                                name="permissionAllUsers"
                                className="radio-input"
                                checked={radio === RadioGroup.AllUsers}
                                onChange={() => {
                                  setTouched(true);
                                  setRadio(RadioGroup.AllUsers);
                                }}
                                type="radio"
                              />
                            )}
                          ></Field>
                          <span className={radioDisabled(values.company) ? "label-disable" : ""}>
                            {t("visitors.settings.allUsersCanInvite")}
                          </span>
                        </label>
                        <label className="label-item">
                          <Field
                            as={() => (
                              <input
                                disabled={radioDisabled(values.company)}
                                name="permissionListUsers"
                                className="radio-input"
                                checked={radio === RadioGroup.SelectedUsers}
                                onChange={() => {
                                  setTouched(true);
                                  setRadio(RadioGroup.SelectedUsers);
                                }}
                                type="radio"
                              />
                            )}
                          ></Field>
                          <span className={radioDisabled(values.company) ? "label-disable" : ""}>
                            {t("visitors.settings.onlyUsersCanInvite")}
                          </span>
                        </label>
                      </div>
                    </EqForm.Group>
                    <EqForm.Group
                      label={t("visitors.settings.maximumCreationDuration")}
                      showTooltip={true}
                      tooltipText={t("visitors.settings.maximumCreationDurationTooltipCompany")}
                    >
                      {currentRole === Role.WorkplaceManager && (
                        <div className="label-container">
                          <Alert
                            size="large"
                            centerIcon={true}
                            variant="gray"
                            icon={
                              <RiErrorWarningLine
                                style={{ alignSelf: "center" }}
                                size={18}
                                color={theme.colors.grayscale[50]}
                              />
                            }
                            message={t("visitors.settings.maximumCreationDurationTooltipCompanyWorkplaceManager", {
                              num:
                                companyData?.operationsCompany?.maxAppointmentCreationMonths ??
                                defaultSiteSettingsMaxAppointmentCreationMonths?.visitorManagementSiteSettings
                                  .maxAppointmentCreationMonths ??
                                0,
                            })}
                            className="mb-6"
                          />
                        </div>
                      )}
                      {currentRole === Role.PropertyManager && (
                        <div className="label-container">
                          <label className="label-item">
                            <Field
                              as={() => (
                                <input
                                  disabled={radioDisabled(values.company)}
                                  name="maximumCreationDuration"
                                  className="radio-input"
                                  checked={!maxAppointmentCreationMonthsCustomSelected}
                                  onChange={() => {
                                    setTouched(true);
                                    setMaxAppointmentCreationMonths(
                                      defaultSiteSettingsMaxAppointmentCreationMonths?.visitorManagementSiteSettings
                                        .maxAppointmentCreationMonths ?? 12,
                                    );
                                    setMaxAppointmentCreationMonthsCustomSelected(false);
                                  }}
                                  type="radio"
                                />
                              )}
                            ></Field>
                            <span className={radioDisabled(values.company) ? "label-disable" : ""}>
                              {t("common.defaultOption")}
                            </span>
                          </label>
                          <label className="label-item">
                            <Field
                              as={() => (
                                <input
                                  disabled={radioDisabled(values.company)}
                                  name="customMaximumCreationDuration"
                                  className="radio-input"
                                  checked={maxAppointmentCreationMonthsCustomSelected}
                                  onChange={() => {
                                    setTouched(true);
                                    setMaxAppointmentCreationMonthsCustomSelected(true);
                                  }}
                                  type="radio"
                                />
                              )}
                            ></Field>
                            <span className={radioDisabled(values.company) ? "label-disable" : ""}>
                              {t("common.customOption")}
                            </span>
                          </label>
                          <div className="d-flex flex-row">
                            <div
                              className={
                                radioDisabled(values.company)
                                  ? "pr-3 align-center label-disable"
                                  : maxAppointmentCreationMonthsCustomSelected
                                  ? "pr-3 align-center label-selected"
                                  : "pr-3 align-center label-enable"
                              }
                            >
                              <label>{t("visitors.settings.maximumCreationDurationUpToNumberOfMonths")}</label>
                            </div>
                            <div
                              className={
                                radioDisabled(values.company)
                                  ? "month-select align-center label-disable"
                                  : "month-select align-center"
                              }
                            >
                              <EqForm.Select
                                id="vmSettingsSiteMaxAppCreationMonthsSelect"
                                name="maxMonthSelect"
                                disabled={!maxAppointmentCreationMonthsCustomSelected}
                                onChange={(e) => {
                                  setMaxAppointmentCreationMonths(Number(e.target.value));
                                }}
                                value={
                                  maxAppointmentCreationMonths?.toString() ??
                                  defaultSiteSettingsMaxAppointmentCreationMonths?.visitorManagementSiteSettings.maxAppointmentCreationMonths.toString() ??
                                  "12"
                                }
                              >
                                {customMaxAppointmentCreationMonthsOptions.map((option) => (
                                  <option key={option.value} value={option.value}>
                                    {option.label}
                                  </option>
                                ))}
                              </EqForm.Select>
                            </div>
                            <div
                              className={
                                radioDisabled(values.company)
                                  ? "pl-3 align-center label-disable"
                                  : maxAppointmentCreationMonthsCustomSelected
                                  ? "pl-3 align-center label-selected"
                                  : "pl-3 align-center label-enable"
                              }
                            >
                              {t("visitors.settings.maximumCreationDurationUpMonths")}
                            </div>
                          </div>
                        </div>
                      )}
                    </EqForm.Group>
                  </div>
                </div>
                <div
                  style={searchDisabled(values.company) ? { filter: "opacity(0.5)" } : {}}
                  className="left-container right-section  mr-0"
                >
                  <div className="d-flex justify-content-left pr-7 pl-7 pt-7  flex-column">
                    <EqForm.Group
                      label={t("visitors.settings.usersWithInvitePermissions")}
                      showTooltip={!searchDisabled(values.company)}
                      tooltipPlacement="bottom"
                      tooltipText={t("visitors.settings.usersWithInvitePermissionsTooltip")}
                    >
                      <UserSearch
                        disabled={searchDisabled(values.company)}
                        loading={loading}
                        onSearch={setSearchTerm}
                        onSelect={({ user }) => {
                          userIsSelected(user.uuid) ? removeUser(user.uuid) : setAllow(user);
                        }}
                        variant="md"
                        items={suggestedUsers}
                        onAddUser={() => {
                          dispatch("addUserAsUnknownRole");
                        }}
                        buttonText={(item) => (item.user.selected ? t("common.remove") : t("common.allow"))}
                      />
                      {[...selectedUsers].reverse().map((user) => {
                        return (
                          user.unsetRole !== true && (
                            <MemberCard.Card
                              key={user.uuid}
                              firstName={user.firstName ?? ""}
                              lastName={user.lastName ?? ""}
                              companyName={user.companyV2?.name ?? ""}
                              email={user.email}
                              profileIconUrl={user.avatar ?? undefined}
                              className="mt-4"
                              buttonVariant="ghost"
                              buttonText={t("common.remove")}
                              showButton={true}
                              onButtonClick={() => {
                                !searchDisabled(values.company) && removeUser(user.uuid);
                              }}
                            />
                          )
                        );
                      })}
                    </EqForm.Group>
                  </div>
                  {noSelectedUsers() ? (
                    <NoUsersPlaceholder
                      text={
                        <>
                          {t("visitors.settings.emptyUsersList")}
                          <br /> {t("visitors.settings.emptyUsersListInfo")}
                        </>
                      }
                    />
                  ) : null}
                </div>
              </div>
              <ButtonTray>
                <Button
                  disabled={saveDisabled(values.company)}
                  onClick={() => {
                    void submitForm();
                  }}
                  variant="primary"
                  size="md"
                  type="submit"
                >
                  {t("common.save")}
                </Button>
              </ButtonTray>
            </Form>
          )}
        </Formik>
        <ConfirmationWidget showModal={showModal} action={action} setShowModal={setShowModal} />
      </div>
      <style jsx>{`
        .page-container {
          background: ${theme.colors.white};
        }
        .left-container {
          min-height: calc(100vh - 160px);
          margin-right: 20px;
          margin-left: 40px;
          width: 100%;
        }
        .item-description {
          font-weight: 700;
          font-size: 20px;
          padding: 30px 0 25px 0;
        }
        .label-container {
        }
        .label-item {
          display: flex;
          width: fit-content;
          margin-bottom: 12px;
        }
        .radio-input {
          margin: 0 10px 0 0;
        }
        .right-section {
          height: calc(100% + 100px);
          padding-bottom: 90px;
          background: #f7f7f7;
        }
        .sections-container {
          height: 100%;
        }
        .label-disable {
          color: ${theme.colors.muted0};
        }
        .label-enable {
          color: ${theme.colors.muted1};
        }
        .label-selected {
          color: rgba(102, 102, 102, 1);
        }
        .search-progress-circle {
          color: ${theme.colors.muted1};
        }
        .search-label {
          font-weight: 500;
          font-size: 12px;
          line-height: 16px;
          color: ${theme.colors.medium};
        }
        .align-center {
          align-self: center;
        }
        .workplace-manager-settings-placeholder {
          text-wrap: initial;
        }
      `}</style>
    </>
  );
};
