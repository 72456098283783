import React, { useCallback, useContext } from "react";
import { DateTime } from "luxon";
import { useRouter } from "next/router";

import { notNullOrUndefined, stringNotEmpty, useSiteContext } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import { DateTime as DateTimeUi, EmptyState, InfiniteScrollTable, Tooltip, useTheme } from "@equiem/react-admin-ui";

import { AssigneeTag } from "../../../components/AssigneeTag";
import { CategoryDisplay } from "../../../components/CategoryDisplay";
import { StatusTag } from "../../../components/StatusTag";
import { type AllRequestsFragmentFragment } from "../../../generated/requests-client";
import { RequestsAccessContext } from "../contexts/RequestsAccessContext";
import type { RequestPageScope } from "../contexts/RequestsScopeContext";
import { RequestsScopeContext } from "../contexts/RequestsScopeContext";
import { usePagedRequests } from "../hooks/usePagedRequests";

import { RequestWatching } from "./RequestWatching";

interface RowP {
  request: AllRequestsFragmentFragment;
  scope: RequestPageScope;
}
const Row: React.FC<RowP> = ({ request, scope }) => {
  const access = useContext(RequestsAccessContext);
  const { currentScope } = useContext(RequestsScopeContext);
  const showAssignee = access.showAssignee || currentScope === "requestManager";
  const { timezone } = useSiteContext();
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const spaces = [request.space?.buildingLevel.name, request.space?.name].filter(stringNotEmpty);
  const router = useRouter();

  const goNow = useCallback(() => {
    router
      .push({
        pathname: `/request-management/request/${request.uuid}`,
        query: router.query,
      })
      .catch(console.log);
  }, [request.uuid, router.query]);

  const translateTimePassedFromCreateDate = () => {
    const diff = DateTime.now().diff(DateTime.fromMillis(request.reported), ["months", "days", "hours", "minutes"]);

    if (diff.months > 0) {
      return diff.months === 1
        ? (t("requests.list.monthAgo") as string)
        : (t("requests.list.monthsAgo", { num: diff.months.toFixed(0) }) as string);
    }
    if (diff.days > 0) {
      return diff.days === 1
        ? (t("requests.list.dayAgo") as string)
        : (t("requests.list.daysAgo", { num: diff.days.toFixed(0) }) as string);
    }
    if (diff.hours > 0) {
      return diff.hours === 1
        ? (t("requests.list.hourAgo") as string)
        : diff.minutes === 0
        ? (t("requests.list.hoursAgo", { num: diff.hours }) as string)
        : (t("requests.list.hoursAndMinutesAgo", {
            hours: diff.hours.toFixed(0),
            minutes: diff.minutes.toFixed(0),
          }) as string);
    }

    return t("requests.list.minutesAgo", { num: diff.minutes.toFixed(0) }) as string;
  };

  return (
    <>
      <tr className="request-row" onClick={goNow}>
        <td>
          <div className="d-flex align-items-center">
            <strong>{request.reference}</strong>
            <RequestWatching className="ml-2" watchedByMe={request.watchedByMe} />
          </div>
          <Tooltip showArrow placement="bottom-start" title={request.description}>
            <div className="info desc">{request.description}</div>
          </Tooltip>
        </td>
        {scope !== "my" && (
          <td>
            <div>
              {request.reporter?.firstName} {request.reporter?.lastName}
            </div>
            <div className="info">{request.reporterCompany?.name}</div>
          </td>
        )}
        <td>
          {translateTimePassedFromCreateDate()}
          <div className="info">
            <DateTimeUi.DateDisplay language={i18n.language} datetime={request.reported} timezone={timezone} />
          </div>
        </td>
        <td>
          {request.space?.buildingLevel.building.name}
          <div className="info">{spaces.join(", ")}</div>
        </td>
        <td>
          <CategoryDisplay icon={request.category.iconName} name={request.category.name} />
        </td>
        {showAssignee && (
          <td>
            <AssigneeTag profile={request.assignee} />
          </td>
        )}
        <td>
          <StatusTag type={request.status.type} name={request.status.name} />
        </td>
      </tr>
      <style jsx>{`
        .request-row {
          cursor: pointer;
        }
        .request-row:hover {
          background: ${theme.colors.grayscale_5};
        }
        .info {
          color: ${theme.colors.grayscale["60"]};
          max-width: 200px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: 13px;
        }
        .info.desc {
          display: inline-block;
        }
      `}</style>
    </>
  );
};

export const RequestsList: React.FC = () => {
  const access = useContext(RequestsAccessContext);
  const { currentScope } = useContext(RequestsScopeContext);
  const showAssignee = access.showAssignee || currentScope === "requestManager";
  const { fetchMore, hasMoreData, loading, requests, handleSort, sortDirection } = usePagedRequests(currentScope);
  const { t } = useTranslation();
  const theme = useTheme();
  const colSpan = (currentScope !== "my" ? 1 : 0) + (showAssignee ? 1 : 0) + 5;

  return (
    <div className="requests-list">
      <InfiniteScrollTable
        loading={loading}
        hasMoreData={hasMoreData}
        scrollDownToLoad={t("requests.list.scrollDownToLoad")}
        rowHeaders={[
          t("requests.list.request"),
          currentScope !== "my" ? t("requests.list.reportedBy") : undefined,
          t("requests.list.created"),
          t("requests.list.location"),
          t("requests.list.category"),
          showAssignee ? t("requests.assignee") : undefined,
          t("requests.list.status"),
        ].filter(notNullOrUndefined)}
        sort={{
          columnIndex: 0,
          direction: sortDirection,
          handleSort,
        }}
        rowCount={requests.length}
        fetchMore={fetchMore}
      >
        {requests.map((request) => (
          <Row key={request.uuid} request={request} scope={currentScope} />
        ))}
        {!loading && requests.length === 0 && (
          <tr>
            <td colSpan={colSpan} className="text-center">
              <EmptyState />
            </td>
          </tr>
        )}
      </InfiniteScrollTable>
      <style jsx>{`
        .icon {
          display: inline-block;
          padding: ${theme.spacers.s5};
          background: ${theme.colors.grayscale[5]};
          border-radius: 50%;
        }
        .requests-list :global(.infinite-scroll-component) {
          padding: 0 32px;
        }
        @media (max-width: ${theme.breakpoints.sm}px) {
          .requests-list :global(.infinite-scroll-component) {
            padding: 16px;
          }
        }
      `}</style>
    </div>
  );
};
