import { useTranslation } from "@equiem/localisation-eq1";
import type { FC } from "react";
import React, { useCallback, useEffect, useState } from "react";
import { RiSearchLine } from "react-icons/ri";

import { useTheme } from "../../contexts/Theme";
import { useDebounced } from "../../hooks";
import { useAsRef } from "../../util/useAsRef";
import type { Form } from "../admin";
import * as MemberCard from "../MemberCard";
import type { Props as ComboBoxProps } from "../Form/FormComboBox/FormComboBox";
import { FormComboBox } from "../Form/FormComboBox/FormComboBox";
import { FormComboBoxMenu } from "../Form/FormComboBox/FormComboBoxMenu/FormComboBoxMenu";
import type { Props as FormInputExtendedProps } from "../Form/FormInputExtended/FormInputExtended";
import { FormInputExtended } from "../Form/FormInputExtended/FormInputExtended";

// eslint-disable-next-line @typescript-eslint/no-type-alias
export type SearchItemType = Form.ComboBox.Item;

// eslint-disable-next-line @typescript-eslint/no-type-alias
export type SearchResultType<T> = {
  item: T;
  onButtonClick?: () => void;
  onButtonFocus?: () => void;
  buttonText?: (item: T) => string | undefined;
};

// eslint-disable-next-line @typescript-eslint/no-type-alias
export type Props<T extends SearchItemType> = Omit<ComboBoxProps<T>, "placeholder" | "showMenuWhenEmpty" | "children"> &
  // eslint-disable-next-line @typescript-eslint/no-type-alias
  Pick<FormInputExtendedProps, "disabled" | "loading" | "placeholder" | "variant"> & {
    onAddUser?: () => void;
    onSearch: (value: string) => void;
    buttonText?: string | ((item: T) => string | undefined);
    debounce?: number;
    searchResult: FC<SearchResultType<T>>;
  };

export const Search = <T extends SearchItemType>({
  disabled,
  items,
  loading,
  onSearch,
  onSelect,
  placeholder,
  buttonText: buttonTextInput,
  // eslint-disable-next-line @typescript-eslint/no-magic-numbers
  debounce = 500,
  variant = "lg",
  searchResult: SearchResult,
}: Props<T>) => {
  const theme = useTheme(true);
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState("");
  const buttonText = useCallback(
    (item: T) => (typeof buttonTextInput === "function" ? buttonTextInput(item) : buttonTextInput),
    [buttonTextInput],
  );

  const debouncedSearchTerm = useDebounced(searchTerm, debounce);
  const onSearchRef = useAsRef(onSearch);

  useEffect(() => {
    onSearchRef.current(debouncedSearchTerm);
  }, [onSearchRef, debouncedSearchTerm]);

  return (
    <FormComboBox
      onChange={(e) => {
        setSearchTerm(e.target.value);
      }}
      onSelect={onSelect}
      items={items}
      menuWidth="matchReference"
    >
      {({ setSelected, setHighlighted, highlighted, itemRefs, inputProps, floating }) => (
        <div style={{ position: "relative" }}>
          <FormInputExtended
            placeholder={placeholder ?? `${t("common.searchToAdd")}`}
            disabled={disabled}
            loading={loading}
            variant={variant}
            icon={RiSearchLine}
            clearable
            outerRef={floating?.reference}
            className="search-input"
            {...inputProps}
          />
          <FormComboBoxMenu showWhenEmpty={loading !== true && debouncedSearchTerm !== ""} maxHeight="10rem">
            {items.length === 0 && searchTerm !== "" && loading !== true ? (
              <div className="placeholder-container align-items-center flex-column p-3">
                <span className="placeholder-message">{`Nothing found for '${searchTerm}'`}</span>
              </div>
            ) : (
              items.map((item, i) => (
                <MemberCard.Wrap key={i} highlight={highlighted === i} ref={itemRefs[i]}>
                  <SearchResult
                    item={item}
                    onButtonClick={() => {
                      setSelected(i);
                    }}
                    onButtonFocus={() => {
                      setHighlighted(i);
                    }}
                    buttonText={buttonText}
                  />
                </MemberCard.Wrap>
              ))
            )}
          </FormComboBoxMenu>
          <style jsx>{`
            .placeholder-container {
              display: flex;
              text-align: center;
            }
            .placeholder-message {
              font-size: 12px;
              font-weight: 400;
              color: ${theme.colors.medium};
            }
          `}</style>
        </div>
      )}
    </FormComboBox>
  );
};
