export enum CatalogueView {
  LIST = "list",
  CALENDAR = "calendar",
}

/**
 * It's not wise to be doing something like view.toLower() as CatalogueView
 * is an internal enum that is not bound by any contract (API). This can change
 * any time and subsequently the metric input as well. This can in turn break
 * any reporting dashboards that are relying on the input that's sent.
 */
export const catalogueViewToMetricCategory = (view: CatalogueView): "calendar" | "list" =>
  view === CatalogueView.CALENDAR ? "calendar" : "list";
