import type { FC, ReactNode } from "react";
import React, { useEffect, useRef, useState, useCallback } from "react";
import { ButtonTrayContext, GradientContainer } from "@equiem/lib";
import { useTheme, useIsMobileWidth, StickyContainer, Tooltip, Button } from "@equiem/react-admin-ui";
import { RiArrowLeftSLine, RiArrowRightSLine } from "@equiem/react-admin-ui/icons";
import { useTranslation } from "@equiem/localisation-eq1";

import { TopMenu } from "../insights/TopMenu";
import { SideMenu } from "../sidemenu/SideMenu";
import { Footer } from "../Footer";
import { StatusCodes } from "http-status-codes";

export const General: FC<{ children: ReactNode }> = ({ children }) => {
  const isMobile = useIsMobileWidth();
  const [showMenu, setShowMenu] = useState(true);
  const [isToggling, setIsToggling] = useState(false);
  const [showButton, setShowButton] = useState(false);
  const [buttonHover, setButtonHover] = useState(false);
  const { colors } = useTheme();
  const buttonTrayRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();

  const width = 240;
  const closedWidth = isMobile ? 0 : 18;

  let toggleButtonMargin = width - 12;
  if (!showMenu) {
    toggleButtonMargin = isMobile ? -24 : 6;
  }

  const showToggleButton = isMobile || !showMenu || showButton || isToggling;

  const toggleMenu = useCallback(() => {
    setShowMenu(!showMenu);
    setButtonHover(false);
    setShowButton(false);
  }, [showMenu]);

  useEffect(() => {
    setIsToggling(true);
    const timeout = setTimeout(() => {
      setIsToggling(false);
      window.dispatchEvent(new Event("resize"));
    }, StatusCodes.USE_PROXY);
    return () => clearTimeout(timeout);
  }, [showMenu]);

  useEffect(() => {
    if (isMobile === showMenu) {
      toggleMenu();
    }
  }, [isMobile]);

  return (
    <StickyContainer>
      <GradientContainer>
        <div className="menu" onMouseOver={() => setShowButton(true)} onMouseLeave={() => setShowButton(false)}>
          <div className="menu-inner">
            <SideMenu width={width} toggleMenu={toggleMenu} />
          </div>
          <Tooltip placement="right" title={showMenu ? t("home.hideTheSideMenu") : t("home.showTheSideMenu")}>
            <div className="menu-btn">
              <div
                onMouseOver={() => {
                  setShowButton(true);
                  setButtonHover(true);
                }}
                onMouseLeave={() => {
                  setShowButton(false);
                  setButtonHover(false);
                }}
              >
                <Button variant="outline" size="sm" shape="round" className="menu-btn" onClick={toggleMenu}>
                  {showMenu ? <RiArrowLeftSLine size={16} /> : <RiArrowRightSLine size={16} />}
                </Button>
              </div>
            </div>
          </Tooltip>
        </div>
        <div
          className="rhs-panel"
          style={{
            /**
             * This has to be an inline style, otherwise it causes a transition animation on
             * first render which breaks the react-grid-layout width calculations.
             */
            marginLeft: isMobile ? 0 : showMenu ? width : closedWidth,
            width: isMobile ? "100%" : showMenu ? `calc(100% - ${width}px)` : `calc(100% - ${closedWidth}px)`,
          }}
        >
          <div className="main" ref={buttonTrayRef}>
            <TopMenu toggleMenu={toggleMenu} />
            <ButtonTrayContext.Provider value={{ containerRef: buttonTrayRef }}>{children}</ButtonTrayContext.Provider>
          </div>
          <Footer />
        </div>
        <div className="overlay" onClick={toggleMenu}></div>
      </GradientContainer>
      <style jsx>{`
        :global(body),
        :global(html),
        :global(#__next) {
          height: 100%;
        }
        .overlay {
          position: fixed;
          top: 0;
          left: 0;
          z-index: 35;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.4);
          display: ${isMobile && showMenu ? "block" : "none"};
        }
        .rhs-panel {
          min-height: 100%;
          width: 100%;
          transition: margin ease-in-out 0.3s;
          position: relative;
          z-index: 35;
          display: flex;
          flex-direction: column;
        }
        .main {
          flex: 1;
          flex-direction: column;
          display: flex;
        }
        .footer {
          flex: 0;
        }
        .menu-btn {
          position: fixed;
          top: 50vh;
          margin-left: ${toggleButtonMargin}px;
          transition: margin ease-in-out 0.3s;
          z-index: 36;
          background: white;
          border-radius: 50%;
          width: 24px;
          display: ${showToggleButton ? "block" : "none"};
        }
        .menu {
          width: ${showMenu ? width : closedWidth}px;
          background: white;
          transition: width ease-in-out 0.3s;
          height: 100vh;
          position: fixed;
          top: 0;
          z-index: 36;
          border-right: ${isMobile
            ? "none"
            : buttonHover
            ? `solid 2px ${colors.buttonBorder}`
            : `solid 1px ${colors.border}`};
        }
        .menu-inner {
          margin-left: ${showMenu ? 0 : `-${width}px`};
          transition: margin ease-in-out 0.3s;
          overflow-x: hidden;
        }
      `}</style>
    </StickyContainer>
  );
};
