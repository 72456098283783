import type { FC } from "react";
import React from "react";
import { useCompanyFlexLevelUuidsQuery, useMyFlexLevelsQuery } from "../../generated/gateway-client";
import { Form } from "@equiem/react-admin-ui";
// eslint-disable-next-line node/no-extraneous-import
import type { OnChange } from "@equiem/react-ui/src/components/Form/FormMultiSelect/model/OnChange";
import { useTranslation } from "@equiem/localisation-eq1";
import { sortByAlphabet } from "@equiem/lib";

interface Props {
  building: string;
  onChange: (event: OnChange) => void;
  defaultValues?: string[];
  companyUuid: string;
  readOnly?: boolean;
}

export const LevelSelect: FC<Props> = ({ building, onChange, defaultValues, companyUuid, readOnly }) => {
  const { t } = useTranslation();
  const { data, loading } = useMyFlexLevelsQuery({
    variables: {
      building,
    },
  });
  const { data: companyLevels, loading: companyLoading } = useCompanyFlexLevelUuidsQuery({
    variables: {
      uuid: companyUuid,
    },
  });

  const flexLevels =
    data?.myFlexLevels.map((level) => ({
      value: level.uuid,
      label: level.name,
    })) ?? [];

  const existingCompanyFlexLevels = companyLevels?.companyFlexLevelUuids ?? [];

  const isLoading = loading || companyLoading;

  const levels = sortByAlphabet(
    flexLevels.map((level) => {
      return {
        ...level,
        disabled: existingCompanyFlexLevels.includes(level.value),
      };
    }),
    "label",
  );

  return (
    <Form.MultiSelect
      placeholder={t("flex.members.form.levels")}
      disabled={isLoading || readOnly}
      options={levels}
      defaultValue={defaultValues}
      onChange={onChange}
    />
  );
};
