import { useToast } from "@equiem/react-admin-ui";
import React, { useCallback } from "react";
import type { ResourceTypeFragmentFragment } from "../../../../generated/gateway-client";
import { useDeleteResourceTypeMutation } from "../../../../generated/gateway-client";
import { DeleteItem } from "./DeleteItem";

interface P {
  item: ResourceTypeFragmentFragment;
  disable: boolean;
  refetch: () => Promise<void>;
}
export const ResourceTypeDelete: React.FC<P> = ({ refetch, item, disable }) => {
  const [deleteRoomConfig, { loading }] = useDeleteResourceTypeMutation({
    variables: { uuid: item.uuid },
  });
  const toast = useToast();
  const disableIt = loading || disable;

  const onDelete = useCallback(async () => {
    if (loading) {
      return;
    }

    try {
      await deleteRoomConfig();
      await refetch();
    } catch (e: unknown) {
      toast.negative(e instanceof Error ? e.message : "Unknown error", { autoDismiss: true });
    }
  }, [deleteRoomConfig, loading, refetch, toast]);

  return <DeleteItem title={item.name} disable={disableIt} onDelete={onDelete} loading={loading} />;
};
