import type React from "react";
import { useEffect, useRef } from "react";

export const useForwardedRef = <T extends Element>(ref: React.ForwardedRef<T | null>) => {
  const innerRef = useRef<T>(null);

  useEffect(() => {
    if (ref == null) {
      return;
    }
    if (typeof ref === "function") {
      ref(innerRef.current);
    } else {
      ref.current = innerRef.current;
    }
  });

  return innerRef;
};
