import { ErrorBoundary } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import { Button, Form, Modal, useTheme } from "@equiem/react-admin-ui";
import type { IImage } from "@equiem/uploader";
import { Field, Form as FormikForm, Formik } from "formik";
import React from "react";
import { ResourceCreateAndEditFormGalleryUploader } from "./ResourceCreateAndEditFormGalleryUploader";
import { ResourceDivider } from "../ResourceDivider";
import type { IVideo } from "../../../../../components/VideoLinkInputNew";
import { VideoLinkInputNew } from "../../../../../components/VideoLinkInputNew";
import { galleryModalValidationSchema } from "../../../../../lib/validationSchema";

export type GalleryModalFormValues = {
  images: IImage[];
  video?: IVideo | null;
  videoTitle?: string;
};

interface Props {
  showModal: boolean;
  setShowModal: (_state: boolean) => void;
  onAdd: (_images: IImage[], _video?: IVideo | null) => void;
  imagesAdded: boolean;
  videoAdded: boolean;
}

export const ResourceCreateAndEditFormGalleryModal: React.FC<Props> = ({
  showModal,
  setShowModal,
  onAdd,
  imagesAdded,
  videoAdded,
}) => {
  const { t } = useTranslation();
  const { breakpoints, colors, spacers } = useTheme(true);

  return (
    <Modal.Dialog
      title={!videoAdded ? t("bookings.resources.addPhotosAndVideos") : t("bookings.resources.addPhotos")}
      show={showModal}
      size="xl"
      onHide={() => {
        setShowModal(false);
      }}
      hideOnClick={false}
      hideOnEsc={false}
      supportsMobile={true}
      focusTrapOptions={{ initialFocus: false }}
      centered
    >
      <div className="gallery-modal">
        <Modal.Header supportsMobile={true} closeButton={true} noBorder={false} />
        <Formik<GalleryModalFormValues>
          initialValues={{
            images: [],
            video: null,
            videoTitle: "",
          }}
          validationSchema={galleryModalValidationSchema(t)}
          validateOnMount={true}
          onSubmit={(values, actions) => {
            actions.setSubmitting(true);
            onAdd(values.images, values.video);
            actions.setSubmitting(false);
          }}
        >
          {({ isSubmitting, isValid, submitForm, errors }) => (
            <FormikForm>
              <Modal.Body>
                <Form.Group className="image-upload">
                  <ErrorBoundary>
                    <Field
                      id="images"
                      name="images"
                      placeholder={t("common.image")}
                      showThumbnail={!imagesAdded}
                      as={ResourceCreateAndEditFormGalleryUploader}
                    />
                  </ErrorBoundary>
                </Form.Group>

                {!videoAdded && (
                  <>
                    <ResourceDivider />

                    <Form.Group
                      label={t("bookings.resources.videoLink")}
                      showTooltip
                      tooltipText={t("bookings.resources.videoLinkTooltip")}
                      error={errors.video}
                    >
                      <ErrorBoundary>
                        <Field id="video" name="video" as={VideoLinkInputNew} />
                      </ErrorBoundary>
                    </Form.Group>
                  </>
                )}
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="ghost"
                  type="button"
                  onClick={() => {
                    setShowModal(false);
                  }}
                >
                  {t("common.cancel")}
                </Button>
                <Button
                  className="add-button"
                  type="submit"
                  variant="primary"
                  onSubmit={() => {
                    void submitForm();
                  }}
                  disabled={isSubmitting || !isValid}
                >
                  {t("common.add")}
                </Button>
              </Modal.Footer>
            </FormikForm>
          )}
        </Formik>
      </div>
      <style jsx>
        {`
          .gallery-modal :global(.header) {
            padding: ${spacers.s6} !important;
          }
          .gallery-modal :global(.header .title-row) {
            margin: ${spacers.s0} !important;
          }
          .gallery-modal :global(.header .title) {
            color: ${colors.dark};
            font-size: 24px;
            font-weight: 700;
            line-height: 28px;
            text-transform: none;
            text-align: left;
            z-index: 1;
          }
          .gallery-modal :global(.modal-body) {
            display: flex;
            flex-direction: column;
            padding: ${spacers.s7} ${spacers.s6};
            margin: ${spacers.s0};
            gap: ${spacers.s7};
            overflow-y: auto;
            max-height: 400px;
          }
          .gallery-modal :global(.modal-body .form-group),
          .gallery-modal :global(.modal-body hr) {
            margin: ${spacers.s0};
          }
          .gallery-modal :global(.modal-body .uploader) {
            height: 160px;
            background-color: white;
          }
          .gallery-modal :global(.footer) {
            padding: ${spacers.s5} ${spacers.s7} !important;
            gap: ${spacers.s3};
            border-top: 1px solid ${colors.grayscale[10]};
          }
          .gallery-modal :global(.footer .add-button) {
            width: 200px;
          }
          @media screen and (max-width: ${breakpoints.md}px) {
            .gallery-modal :global(.header .title) {
              font-size: 20px;
            }
            .gallery-modal :global(.header button) {
              z-index: 2;
            }
            .gallery-modal :global(.modal-body) {
              padding: ${spacers.s5};
            }
            .gallery-modal :global(.modal-body .uploader) {
              height: 88px;
            }
            .gallery-modal :global(.footer button) {
              flex: 1;
            }
          }
        `}
      </style>
    </Modal.Dialog>
  );
};
