import { useTranslation } from "@equiem/localisation-eq1";
import { Text, useTheme } from "@equiem/react-admin-ui";
import React from "react";
import { ResourceCreateAndEditFormPreviewGallery } from "./ResourceCreateAndEditFormPreviewGallery";
import { ResourceCreateAndEditFormPreviewFeatures } from "./ResourceCreateAndEditFormPreviewFeatures";
import { ResourceCreateAndEditFormPreviewLocation } from "./ResourceCreateAndEditFormPreviewLocation";
import { ResourceCreateAndEditFormPreviewName } from "./ResourceCreateAndEditFormPreviewName";
import { ResourceCreateAndEditFormPreviewOverview } from "./ResourceCreateAndEditFormPreviewOverview";
import { ResourceCreateAndEditFormPreviewVideo } from "./ResourceCreateAndEditFormPreviewVideo";
import { BookableResourceFeatureType } from "../../../../../generated/gateway-client";

export const ResourceCreateAndEditFormPreview: React.FC = () => {
  const { t } = useTranslation();
  const { colors, spacers } = useTheme(true);

  return (
    <div className="form-preview-container">
      <Text variant="label" color={colors.grayscale[60]}>
        {t("common.preview")}
      </Text>
      <div className="resource-info-container">
        <ResourceCreateAndEditFormPreviewName />
        <ResourceCreateAndEditFormPreviewLocation />
        <ResourceCreateAndEditFormPreviewGallery />
        <ResourceCreateAndEditFormPreviewOverview />
        <ResourceCreateAndEditFormPreviewVideo />
        <ResourceCreateAndEditFormPreviewFeatures featureType={BookableResourceFeatureType.RoomFeatures} />
        <ResourceCreateAndEditFormPreviewFeatures featureType={BookableResourceFeatureType.SharedFacilities} />
      </div>
      <style jsx>{`
        .form-preview-container {
          flex: 45;
          padding: ${spacers.s7} ${spacers.s6};
          max-height: 100vh;
          overflow-y: auto;
          z-index: 0;

          display: flex;
          flex-direction: column;
          gap: ${spacers.s5};
          background: ${colors.grayscale[5]};
        }
        .resource-info-container {
          display: flex;
          flex-direction: column;
          gap: ${spacers.s6};
          padding: ${spacers.s6};
          background: ${colors.white};
        }
      `}</style>
    </div>
  );
};
