import { useEffect, useMemo, useState } from "react";
import type {
  InviteRoleTupleInput,
  RolesICanGrantQuery,
  RolesICanGrantQueryVariables,
} from "../generated/gateway-client";
import { RolesICanGrantDocument } from "../generated/gateway-client";
import { useShowError, toRoleName } from "@equiem/lib";
import { useApolloClient } from "@apollo/client";
import { useTranslation } from "@equiem/localisation-eq1";

type Option = {
  label:
    | string
    | {
        element: string;
        searchText: string;
        facadeElement: string;
      };
  value: string;
  tuple: InviteRoleTupleInput;
  disabled: boolean;
  group?: { name: string; weight: number };
};

export const useRoles = ({
  siteUuid,
  granteeCompanyUuids = [],
}: {
  siteUuid: string;
  granteeCompanyUuids?: string[];
}) => {
  const showError = useShowError();
  const client = useApolloClient();
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState<RolesICanGrantQuery["rolesICanGrant"] | undefined>(undefined);
  const { t } = useTranslation();

  useEffect(() => {
    if (granteeCompanyUuids.length === 0) {
      setResult([]);
      return;
    }

    setLoading(true);

    Promise.all([
      ...granteeCompanyUuids.map(async (granteeCompanyUuid) =>
        Promise.all([
          client.query<RolesICanGrantQuery, RolesICanGrantQueryVariables>({
            query: RolesICanGrantDocument,
            variables: { granteeCompanyUuid, siteUuid },
          }),
          client.query<RolesICanGrantQuery, RolesICanGrantQueryVariables>({
            query: RolesICanGrantDocument,
            variables: { granteeCompanyUuid },
          }),
        ]),
      ),
    ])
      .then((results) => {
        setResult(
          results
            .map((r) => r.map((rd) => rd.data.rolesICanGrant).flat())
            .reduce(
              (prev, next, i) =>
                i === 0
                  ? next
                  : prev.filter(({ role, resources }) =>
                      next.some(
                        (sr) =>
                          sr.role.type === role.type &&
                          sr.role.relation === role.relation &&
                          sr.resources.map((r) => r.id).some((id) => resources.map((r) => r.id).includes(id)),
                      ),
                    ),
              [],
            ),
        );
      })
      .catch((e) => showError(e))
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [granteeCompanyUuids.join(), client, showError, setLoading]);

  return useMemo(() => {
    return {
      loading,
      roles:
        result?.flatMap<Option>(({ role: { name: untranslatedRoleName, relation, type }, resources }, weight) => {
          const resourceCount = resources.length;
          const roleName = t(`settings.roles.roleNames.${toRoleName(untranslatedRoleName)}`, { count: 1 });

          return resourceCount === 0
            ? [{ label: roleName, value: `${type}#${relation}`, disabled: true, tuple: { id: "", type, relation } }]
            : resources.map(({ id, name: resourceName, viewerCanGrant }) => ({
                label:
                  resourceCount === 1
                    ? roleName
                    : {
                        element: resourceName,
                        facadeElement: `${roleName} @ ${resourceName}`,
                        searchText: `${roleName} @ ${resourceName}`,
                      },
                value: `${type}:${id}#${relation}`,
                group:
                  (type === "site" || type === "siteCompany" || type === "flexOperator") && resourceCount === 1
                    ? undefined
                    : { name: roleName, weight },
                disabled: viewerCanGrant !== true,
                tuple: {
                  id,
                  relation,
                  type,
                },
              }));
        }) ?? [],
    };
  }, [loading, result, t]);
};
