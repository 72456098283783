import type { ChangeEventHandler, ComponentProps } from "react";
import React, { useState, useContext, useMemo } from "react";

import { useTheme } from "../../../contexts/Theme";
import { Text } from "../../Text/Text";
import { FormGroupContext } from "../../../contexts/FormGroupContext";
import type { IconType } from "react-icons";

interface Props extends Omit<ComponentProps<"input">, "type" | "value" | "checked"> {
  label: string;
  icon: IconType;
  description?: string;
  value?: boolean;
}

export const FormHeroCheckbox = React.forwardRef<HTMLInputElement, Props>((props, ref) => {
  const { label, description, value: propsValue, icon: Icon, onChange, className, ...inputProps } = props;

  const { colors } = useTheme();
  const { id, hintId, hasError } = useContext(FormGroupContext);

  const [localValue, setLocalValue] = useState<boolean>(propsValue ?? false);
  const value = useMemo(() => propsValue ?? localValue, [propsValue, localValue]);
  const inputId = useMemo(() => props.id ?? id ?? props.name, [id, props.id, props.name]);

  const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setLocalValue(e.target.checked);
    if (onChange != null) {
      onChange(e);
    }
  };

  return (
    <>
      <label
        className={`label ${value ? "label--active" : ""} ${hasError ? "label--error" : ""} ${className ?? ""}`.trim()}
        htmlFor={inputId}
      >
        <div className="control">
          <Icon size={24} fill={value ? colors.blue[60] : "#000"} />
          <input
            {...inputProps}
            ref={ref}
            id={inputId}
            aria-describedby={hintId}
            type="checkbox"
            checked={value}
            onChange={handleChange}
          />
        </div>
        <div className="info">
          <Text variant="heading" size="small" className="mb-2">
            {label}
          </Text>
          <Text variant="text" size="extra-small" className="description m-0">
            {description}
          </Text>
        </div>
      </label>
      <style jsx>{`
        input[type="checkbox"] {
          width: 24px;
          height: 24px;
          background: #ffffff;
          border: 1px solid ${colors.grayscale[20]};
          border-radius: 4px;
          appearance: none;
          position: relative;
          margin: 0;
        }

        input[type="checkbox"]:checked {
          border: none;
        }

        input[type="checkbox"]:checked:before {
          content: "";
          background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAACXBIWXMAABYlAAAWJQFJUiTwAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACHSURBVHgB7ZPRDYAgDEQ7giM4ghs6GiM4AiMwwlmiH4YgqIEzIX1JwxfcC21FDMMwOgNg1nLxFDZnuMeBp0ok4ZFNaxIGY4R/6VfL8EUraK0v7rT79uShqgRa9/zyA1UJ9Bq4JxLoPe0lCbBWLScB9p5nJAItvCDBC7+R4IYnEu6XcMMwhmQHt/C0plArFXgAAAAASUVORK5CYII=");
          background-size: 16px 16px;
          background-repeat: no-repeat;
          background-position: center;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          border-radius: 4px;
          background-color: ${colors.blue[60]};
          color: #fff;
        }

        .label {
          display: inline-flex;
          white-space: nowrap;
          max-width: 100%;
          cursor: pointer;
          user-select: none;
          flex-direction: column;
          border-radius: 4px;
          padding: 1rem;
          border: 1px solid ${colors.transparent.black[10]};
        }

        .label:not(.label--active):hover {
          background-color: ${colors.transparent.black[5]};
        }

        .label--error,
        .label-error input {
          border-color: ${colors.status.danger.primary};
        }

        .label--active {
          background-color: ${colors.blue[10]};
          border-color: ${colors.blue[60]};
        }

        .control {
          display: flex;
          justify-content: space-between;
          margin-bottom: 0.5rem;
        }

        .info {
          display: flex;
          flex-direction: column;
        }

        .info :global(.description) {
          color: ${colors.transparent.black[60]};
        }
      `}</style>
    </>
  );
});

FormHeroCheckbox.displayName = "FormHeroCheckbox";
