/* eslint-disable @typescript-eslint/no-magic-numbers */
import type { ReportParams } from "./ReportParams";
import { getCsvFileName } from "./getCsvFileName";
import { formatUSDate, formatUSShortDate } from "./formatters";
import { downloadCsv } from "./downloadCsv";

interface CsvRow {
  yardiType: string;
  /**
   * must be numeric, doesn't need to be globally unique, just needs to uniquely
   * identify the booking within the CSV
   */
  trannum: string;
  reference: string;
  yardiTenantCode: string;
  companyName: string;
  startDate: string;
  created: string;
  remark: string;
  yardiPropertyCode: string;
  totalPrice: string;
  yardiAccountId: string;
  yardiAccrualId: string;
  yardiOffsetId: string;
  yardiChecknumId: string;
  description: string;
}

const truncate = (str: string, maxLength: number) => str.slice(0, maxLength);

export const downloadYardiExport = (params: ReportParams) => {
  const { bookings, timezone } = params;
  downloadCsv<CsvRow>({
    fileName: getCsvFileName("yardi", params),
    columns: [
      { key: "yardiType", header: "TYPE" },
      { key: "trannum", header: "TRANNUM" },
      { key: "yardiTenantCode", header: "PERSON" },
      { key: "companyName", header: "NAME" },
      { key: "startDate", header: "DATE" },
      { key: "created", header: "POSTMONTH" },
      { key: "reference", header: "REF" },
      { key: "remark", header: "REMARK" },
      { key: "yardiPropertyCode", header: "PROP" },
      { key: "totalPrice", header: "AMOUNT" },
      { key: "yardiAccountId", header: "ACCT" },
      { key: "yardiAccrualId", header: "ACCRUAL" },
      { key: "yardiOffsetId", header: "OFFSET" },
      { key: "yardiChecknumId", header: "CHECKNUM" },
      { key: "description", header: "DESC" },
    ],
    rows: bookings.map<CsvRow>((booking, i) => ({
      yardiType: truncate(booking.yardiType ?? "", 1),
      trannum: truncate(`${i}`, 8),
      reference: truncate(booking.reference, 8),
      yardiTenantCode: truncate(booking.yardiTenantCode ?? "", 8),
      companyName: truncate(booking.companyName ?? "", 40),
      startDate: formatUSDate(booking.startDate, timezone),
      created: formatUSDate(booking.created, timezone),
      description: truncate(`${booking.resourceName} ${formatUSDate(booking.startDate, timezone)}`, 120),
      yardiPropertyCode: truncate(booking.yardiPropertyCode ?? "", 8),
      totalPrice: truncate((booking.totalPrice / 100).toFixed(2), 10),
      yardiAccountId: truncate(booking.yardiAccountId ?? "", 10),
      yardiAccrualId: truncate(booking.yardiAccrualId ?? "", 9),
      yardiOffsetId: truncate(booking.yardiOffsetId ?? "", 9),
      yardiChecknumId: truncate(booking.yardiChecknumId ?? "", 8),
      remark: truncate(`${booking.resourceName} ${formatUSShortDate(booking.startDate, timezone)}`, 36),
    })),
  });
};
