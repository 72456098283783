import React from "react";

import { useTranslation } from "@equiem/localisation-eq1";
import { useTheme } from "@equiem/react-admin-ui";
import { RiTeamLine } from "@equiem/react-admin-ui/icons";

export const EmptyList = () => {
  const { t } = useTranslation();
  const { colors } = useTheme();

  return (
    <div className="container d-flex align-items-center mt-8">
      <div className="d-flex align-items-center flex-column">
        <div className="icon d-flex align-items-center justify-content-center">
          <RiTeamLine size={24} style={{ color: colors.medium }} />
        </div>
        <h2 className="info mt-5">{t("visitors.receptions.emptyList")}</h2>
      </div>
      <style jsx>{`
        .container {
          height: auto;
        }
        .info {
          display: block;
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          padding: 0;
          margin: 0;
          color: rgba(0, 0, 0, 0.4);
          text-align: center;
        }
        .icon {
          border-radius: 50%;
          height: 64px;
          width: 64px;
          line-height: 64px;
          background-color: #f2f2f2;
          text-align: center;
        }
      `}</style>
    </div>
  );
};
