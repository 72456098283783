import React from "react";

export const EmptyFiltersIcon = () => {
  return (
    <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="56" height="56" rx="28" fill="#F2F2F2" />
      <path
        d="M19 33V35H25V33H19ZM19 21V23H29V21H19ZM29 37V35H37V33H29V31H27V37H29ZM23 25V27H19V29H23V31H25V25H23ZM37 29V27H27V29H37ZM31 25H33V23H37V21H33V19H31V25Z"
        fill="#999999"
      />
    </svg>
  );
};
