import React from "react";

import css from "styled-jsx/css";
import { FormGroupContext } from "../../contexts/FormGroupContext";
import { FormInputGroupContext } from "./FormInputGroup/FormInputGroupContext";
import { useTheme } from "../../contexts/Theme";

export interface BorderCssProps {
  selector?: string;
  showBorder?: boolean;
  showChrome?: boolean | "onInteraction";
  rounded?: "corners" | "ends";
}

export const useInputBorderCss = ({
  selector = "*",
  showBorder,
  showChrome,
  rounded: roundedInput,
}: BorderCssProps = {}) => {
  const { hasError } = React.useContext(FormGroupContext);
  const { colors, borderRadius, animationDuration, focusOutline } = useTheme();
  const inputGroup = React.useContext(FormInputGroupContext);

  const show = showBorder ?? showChrome ?? !inputGroup.inGroup;
  const rounded = roundedInput ?? inputGroup.rounded ?? "corners";
  const borderRadiusResolved = rounded === "corners" ? borderRadius : "100px";

  if (show === true) {
    return css.resolve`
      ${selector} {
        border: 1px solid ${hasError ? colors.danger : colors.border} !important;
        border-radius: ${borderRadiusResolved} !important;
        transition: ${animationDuration} box-shadow, border;
      }
      ${selector}:focus,
      ${selector}.focus-in {
        border: 1px solid ${hasError ? colors.danger : colors.inputBorder} !important;
        box-shadow: 0 0 0 ${focusOutline.size} ${hasError ? focusOutline.colors.error : focusOutline.colors.default} !important;
      }
    `;
  }

  if (show === "onInteraction") {
    return css.resolve`
      ${selector} {
        border: 1px solid ${hasError ? colors.danger : "transparent"} !important;
        border-radius: ${borderRadiusResolved} !important;
        transition: ${animationDuration} box-shadow, border;
      }
      ${selector}:hover {
        border: 1px solid ${hasError ? colors.danger : colors.border} !important;
        background-color: ${colors.white} !important;
      }
      ${selector}:focus,
      ${selector}.focus-in {
        border: 1px solid ${hasError ? colors.danger : colors.inputBorder} !important;
        background-color: ${colors.white} !important;
        box-shadow: 0 0 0 ${focusOutline.size} ${hasError ? focusOutline.colors.error : focusOutline.colors.default} !important;
      }
    `;
  }

  return css.resolve`
    ${selector} {
      border: none;
      border-radius: ${borderRadiusResolved} !important;
    }
  `;
};
