import React, { useCallback } from "react";

import { useSaferFormikContext } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import { Button, Tag, Text, useTheme } from "@equiem/react-admin-ui";
import { RiDeleteBin6Line, RiEdit2Line, RiLock2Line } from "@equiem/react-admin-ui/icons";

import { VisitorAppointmentType } from "../../../../generated/visitors-client";
import type { GeneralSettingsFormValues, VisitorTypeFormValue } from "../GeneralSettingsValues";
import { useAppointmentTypes } from "../hooks/useAppointmentTypes";

interface Props {
  visitorType: VisitorTypeFormValue;
  onShowModal: () => void;
}

export const PreviewVisitorType = ({ visitorType, onShowModal }: Props) => {
  const { appointmentTypeTranslations } = useAppointmentTypes();
  const { colors } = useTheme();
  const { t } = useTranslation();
  const { setFieldValue, values, setValues } = useSaferFormikContext<GeneralSettingsFormValues>();

  const isDefaultChangeVisible = useCallback(({ appointmentTypes }: VisitorTypeFormValue) => {
    return (
      appointmentTypes != null &&
      [VisitorAppointmentType.PreBooked, VisitorAppointmentType.WalkIn].every((item: VisitorAppointmentType) =>
        appointmentTypes.includes(item),
      )
    );
  }, []);

  return (
    <>
      <td>
        {visitorType.isDefault ? (
          <Text variant="text" weight="regular" size="small" className="p-0 m-0" color={colors.grayscale[50]}>
            {visitorType.name} <span className="default-option">({t("common.defaultOption")})</span>
          </Text>
        ) : (
          <div>{visitorType.name}</div>
        )}
      </td>
      <td className={`appointment-type-icons ${visitorType.isDefault ? "lock-icon" : ""}`}>
        {visitorType.isDefault ? (
          <>
            <div className="default-appointment-type">
              {visitorType.appointmentTypes?.map((appointmentType) => (
                <Tag key={appointmentType}>{appointmentTypeTranslations[appointmentType]}</Tag>
              ))}
            </div>
            <RiLock2Line size={16} />
          </>
        ) : (
          <>
            <div className="appointment-type">
              {visitorType.appointmentTypes?.map((appointmentType) => (
                <Tag key={appointmentType}>{appointmentTypeTranslations[appointmentType]}</Tag>
              ))}
            </div>
            <div className="controls">
              <Button
                variant="ghost"
                round
                onClick={() => {
                  const newVisitorTypes = values.visitorTypes.map((item) =>
                    item === visitorType ? { ...item, isEditing: true } : item,
                  );
                  setFieldValue("visitorTypes", newVisitorTypes).catch(console.error);
                }}
              >
                <RiEdit2Line size={16} color={colors.grayscale[60]} />
              </Button>
              <Button
                variant="ghost"
                round
                onClick={() => {
                  setValues({
                    ...values,
                    visitorTypes: values.visitorTypes.filter((item) => item !== visitorType),
                  }).catch(console.error);
                }}
              >
                <RiDeleteBin6Line size={16} color={colors.danger} />
              </Button>
              {isDefaultChangeVisible(visitorType) && (
                <Button variant="ghost" round onClick={onShowModal}>
                  <RiLock2Line size={16} />
                </Button>
              )}
            </div>
          </>
        )}
      </td>
      <style jsx>{`
        .default-option {
          text-transform: lowercase;
        }

        .default-appointment-type {
          opacity: 0.5;
        }

        .enable-visitors {
          display: flex;
          align-items: center;
          gap: 8px;
        }

        .appointment-type-icons {
          height: 50px;
        }

        .appointment-type {
          display: flex;
          gap: 8px;
        }

        .controls {
          display: flex;
          gap: 8px;
        }

        .appointment-type-icons {
          display: flex;
          gap: 8px;
          justify-content: flex-end;
          padding: 16px;
          color: ${colors.grayscale[60]};
        }
      `}</style>
    </>
  );
};
