import { formatters } from "@equiem/localisation-eq1";
import { DateTime } from "luxon";
import React from "react";

interface Props {
  datetime: number;
  language: string;
  timezone?: string;
}

export const toTimeString = ({ datetime, timezone, language }: Props) => {
  const date = DateTime.fromMillis(datetime, { zone: timezone });

  return formatters.timeshort(date, language);
};

export const TimeDisplay: React.FC<Props> = (props) => <>{toTimeString(props)}</>;
