import { stringNotEmpty } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import { Form } from "@equiem/react-admin-ui";
import type { ArrayHelpers } from "formik";
import React, { useMemo } from "react";
import type { BookableResourceAddOn } from "../../../../generated/gateway-client";
import { BookableResourceAddOnType } from "../../../../generated/gateway-client";
import { useAddOnError } from "../../hooks/useAddOnError";
import { AddOnCheckboxOption } from "./AddOnCheckboxOption";

interface P {
  addOn: BookableResourceAddOn;
  index: number;
  arrayHelpers: ArrayHelpers;
  hideCost?: boolean;
}
export const AddonSingleOption: React.FC<P> = ({ addOn, index, arrayHelpers, hideCost = false }) => {
  const { t } = useTranslation();
  const error = useAddOnError(index);
  const showTooltip = useMemo(() => {
    return stringNotEmpty(addOn.tooltipTxt);
  }, [addOn.tooltipTxt]);

  if (addOn.type !== BookableResourceAddOnType.SingleOption || addOn.options == null || addOn.options.length === 0) {
    return null;
  }

  return (
    <Form.Group
      label={addOn.name ?? t("common.unknown")}
      showTooltip={showTooltip}
      tooltipText={addOn.tooltipTxt ?? undefined}
      required={addOn.mandatory}
      error={error}
    >
      <AddOnCheckboxOption option={addOn.options[0]} index={index} arrayHelpers={arrayHelpers} hideCost={hideCost} />
    </Form.Group>
  );
};
