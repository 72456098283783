import React, { useState } from "react";
import type { AudienceInnerWidgetProps } from "./AudienceInnerWidget";
import { NotificationMethod } from "../state/NotificationMethod";
import { Button, Form } from "@equiem/react-admin-ui";
import { HttpLink } from "@apollo/client";
import { AudienceModal } from "./AudienceModal";

interface Props {
  accessToken?: string;
}

const defaultEndpoint = () =>
  localStorage.getItem("segmenter-endpoint") ?? "https://staging.gateway.getequiem.com/graphql";

const link = (auth: string) =>
  new HttpLink({
    uri: defaultEndpoint(),
    headers: {
      authorization: `Bearer ${auth}`,
    },
  });

const testCallback: AudienceInnerWidgetProps["callback"] = (segmentIds, segmentSummary, changes, matchingSegments) => {
  console.log(
    `Callback: [Segment IDs: ${segmentIds}, Audience: ${segmentSummary}, Changes: ${changes}, Matching: ${matchingSegments}]`,
  );
};

const defaultSegment = (): string[] | undefined => {
  const segment = localStorage.getItem("segmenter-segment") ?? undefined;
  if (segment === "" || segment === undefined) {
    return undefined;
  }
  return [segment];
};

export const TestApp: React.FC<Props> = ({ accessToken }) => {
  const [segmentIds, setSegmentIds] = useState(defaultSegment());
  const [showModal, setShowModal] = useState(false);
  const [endpoint, setEndpoint] = useState(defaultEndpoint());
  const [notificationMethod, setNotificationMethod] = useState(NotificationMethod.EMAIL);

  const [site, setSite] = useState<string | null>(localStorage.getItem("segmenter-site") ?? null);

  return (
    <div className="p-5 bg-white">
      {site != null && site.length > 0 && (
        <>
          <AudienceModal
            connection={{ link: link(accessToken ?? "") }}
            site={site}
            segmentIds={segmentIds}
            callback={testCallback}
            notificationMethod={notificationMethod}
            modal={{
              showModal,
              onHide: () => setShowModal(false),
            }}
          />
          <Button onClick={() => setShowModal(true)}>Show modal</Button>
        </>
      )}

      <div className="mt-4">
        <Form.Group label="Site">
          <Form.Input
            type="text"
            placeholder="Enter UUID"
            value={site ?? ""}
            onChange={(e) => {
              localStorage.setItem("segmenter-site", e.target.value);
              setSite(e.target.value);
            }}
          />
        </Form.Group>
        <Form.Group label="Region">
          <Form.Select
            onChange={(e) => {
              const endpointInput = e.target.value.trim();
              localStorage.setItem("segmenter-endpoint", endpointInput);
              setEndpoint(endpointInput);
            }}
            value={endpoint}
          >
            <option value={"https://staging.gateway.getequiem.com/graphql"}>Staging</option>
            <option value={"https://au.gateway.getequiem.com/graphql"}>AU</option>
            <option value={"https://eu.gateway.getequiem.com/graphql"}>EU</option>
            <option value={"https://us.gateway.getequiem.com/graphql"}>US</option>
          </Form.Select>
        </Form.Group>
        <Form.Group label="Notification Method">
          <Form.Select
            onChange={(e) => setNotificationMethod(parseInt(e.target.value))}
            value={notificationMethod.toString()}
          >
            <option value={0}>Email</option>
            <option value={1}>SMS</option>
            <option value={2}>Push Notification</option>
          </Form.Select>
        </Form.Group>
        <Form.Group label="Segment ID (optional)">
          <Form.Input
            onChange={(e) => {
              const id = e.target.value.trim();
              localStorage.setItem("segmenter-segment", id);
              setSegmentIds([id]);
            }}
            value={segmentIds !== undefined ? segmentIds[0] : undefined}
          />
        </Form.Group>
      </div>
    </div>
  );
};
