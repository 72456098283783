import React from "react";
import { BaseTimeRange } from "./BaseTimeRange";

export const UnavailableTimeRange: React.FC<{ start: number; end: number; timezone: string }> = ({
  start,
  end,
  timezone,
}) => {
  return (
    <BaseTimeRange start={start} end={end} timezone={timezone} className="inactive-time-slot unavailable-time-range" />
  );
};
