import { useTheme } from "@equiem/react-admin-ui";
import React from "react";

export const ResourceDivider: React.FC = () => {
  const theme = useTheme(true);

  return (
    <>
      <hr />
      <style jsx>{`
        hr {
          border: 0;
          border-bottom: 1px solid ${theme.colors.border};
          margin: ${theme.spacers.s6} 0;
        }
      `}</style>
    </>
  );
};
