import type { ReactNode } from "react";
import React, { useState } from "react";

export type SettingsChildModal = "AvatarUpload";

export interface ModalChildContext {
  activeModal?: SettingsChildModal;
  id?: string;
  open: (type: SettingsChildModal, id?: string) => void;
  close: () => void;
}

/* istanbul ignore next */
export const ModalChild = React.createContext<ModalChildContext>({
  open: (_type: SettingsChildModal) => undefined,
  close: () => undefined,
});

export const ModalChildProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [activeModal, setActiveModal] = useState<SettingsChildModal>();
  const [id, setId] = useState<string>();

  const open = (type: SettingsChildModal, _id?: string) => {
    setActiveModal(type);
    setId(_id);
  };

  const close = () => {
    setActiveModal(undefined);
    setId(undefined);
  };

  return (
    <ModalChild.Provider
      value={{
        open,
        close,
        activeModal,
        id,
      }}
    >
      {children}
    </ModalChild.Provider>
  );
};
