import type { ChangeEvent, FocusEvent, KeyboardEvent, RefCallback } from "react";
import React from "react";

import type { FormComboBoxItem } from "./FormComboBoxItem";
import type { useFloating } from "@floating-ui/react-dom";

export interface FormComboBoxContext {
  /**
   * The index of the currently highlighted item.
   */
  highlighted: number;
  /**
   * Sets the index of the hightlighted item.
   */
  setHighlighted: (index: number) => void;
  /**
   * Whether the input is currently focused.
   */
  focused: boolean;
  /**
   * Sets whether the input is currently focused.
   */
  setFocused: (focused: boolean) => void;
  /**
   * Clear the value in input.
   */
  clearValue: () => void;
  /**
   * Called when the user selects (picks) an item.
   */
  setSelected: (index: number) => void;
  /**
   * The items available in the combo box.
   */
  items: FormComboBoxItem[];
  /**
   * Props to be assigned to the combo box's input field.
   */
  inputProps: {
    onKeyDown: (e: KeyboardEvent) => void;
    onFocus: (e: FocusEvent) => void;
    value: string;
    onChange: (e: ChangeEvent<HTMLInputElement>) => void;
    role: "combobox";
  };
  /**
   * A ref for each item available in the combo box.
   * Used to scroll selected items into view when necessary
   * (ie. when using the keyboard to navigate the list).
   */
  itemRefs: Array<RefCallback<HTMLElement | null>>;
  /**
   * A ref to be assigned to the floating dropdown element
   * so that it can be correctly positioned.
   */
  floating?: ReturnType<typeof useFloating>;
}

export const FormComboBoxContext = React.createContext<FormComboBoxContext>({
  highlighted: 0,
  setHighlighted: () => undefined,
  focused: false,
  setFocused: () => undefined,
  setSelected: () => undefined,
  clearValue: () => undefined,
  items: [],
  inputProps: {
    onKeyDown: () => undefined,
    onFocus: () => undefined,
    onChange: () => undefined,
    value: "",
    role: "combobox",
  },
  itemRefs: [],
});
