import React from "react";

export const EmptySearchIcon = () => {
  return (
    <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="56" height="56" rx="28" fill="#F2F2F2" />
      <path
        d="M34.031 32.617L38.314 36.899L36.899 38.314L32.617 34.031C31.0237 35.3082 29.042 36.0029 27 36C22.032 36 18 31.968 18 27C18 22.032 22.032 18 27 18C31.968 18 36 22.032 36 27C36.0029 29.042 35.3082 31.0237 34.031 32.617ZM32.025 31.875C33.2941 30.5699 34.0029 28.8204 34 27C34 23.132 30.867 20 27 20C23.132 20 20 23.132 20 27C20 30.867 23.132 34 27 34C28.8204 34.0029 30.5699 33.2941 31.875 32.025L32.025 31.875Z"
        fill="#999999"
      />
    </svg>
  );
};
