import React from "react";

import { stringNotEmpty } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";

import type { SpaceSiteAudience, SpaceSiteAudienceInput } from "../../generated/settings-client";

export const isAll = (audience: SpaceSiteAudienceInput | SpaceSiteAudience) =>
  audience.segmentIds == null || audience.segmentIds.length === 0;

interface AudienceInfoProps {
  audience: SpaceSiteAudienceInput | SpaceSiteAudience;
}

export const AudienceInfo: React.FC<AudienceInfoProps> = (props) => {
  const { t } = useTranslation();
  const { audience } = props;
  if (stringNotEmpty(audience.segmentSummary)) {
    return (
      <span
        dangerouslySetInnerHTML={{
          __html: audience.segmentSummary.replace(/: /gu, ":<br/>").replace(/, Segment /gu, "<br/>Segment "),
        }}
      />
    );
  } else {
    return <span>{isAll(audience) ? t("common.all") : "-"}</span>;
  }
};
