import React from "react";

import { TopMenuBar } from "../../TopMenuBar";
import { TopMenuTitle } from "../../TopMenuTitle";
import { GreetingIcon } from "./GreetingIcon";
import { MenuButtonIcon } from "./MenuButtonIcon";

export const TopMenu = () => {
  return (
    <>
      <TopMenuBar>
        <div className="d-flex justify-content-end align-items-center w-100">
          <MenuButtonIcon />
          <MenuButtonIcon />
          <MenuButtonIcon />
        </div>
      </TopMenuBar>
      <TopMenuBar>
        <TopMenuTitle>
          <GreetingIcon />
        </TopMenuTitle>
      </TopMenuBar>
    </>
  );
};
