import { useTranslation } from "@equiem/localisation-eq1";

import { VisitorAppointmentType } from "../../../../generated/visitors-client";

export const useAppointmentTypes = () => {
  const { t } = useTranslation();

  return {
    appointmentTypeTranslations: {
      [VisitorAppointmentType.WalkIn]: t("visitors.visitorTypes.walkIn"),
      [VisitorAppointmentType.PreBooked]: t("visitors.visitorTypes.preBooked"),
    },
    appointmentTypeOptions: [
      { label: t("visitors.visitorTypes.walkIn"), value: VisitorAppointmentType.WalkIn },
      { label: t("visitors.visitorTypes.preBooked"), value: VisitorAppointmentType.PreBooked },
    ],
  };
};
