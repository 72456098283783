import { Form, BulkTable, Tooltip, Text } from "@equiem/react-admin-ui";
import type { ChangeEvent } from "react";
import { useCallback, useContext, useMemo } from "react";
import { InviteGroupWidget } from "./InviteGroupWidget";
import type { Invite } from "./NewInvitesContext";
import { NewInvites } from "./NewInvitesContext";
import { useTranslation } from "@equiem/localisation-eq1";
import { BulkGroupSelection } from "./BulkGroupSelection";
import { InviteRoleWidget } from "./InviteRoleWidget";
import { BulkRoleSelection } from "./BulkRoleSelection";
import { InviteCompanyWidget } from "./InviteCompanyWidget";
import { BulkCompanySelectionWidget } from "./BulkCompanySelectionWidget";
import { CurrentProfile } from "@equiem/lib";
import { RiInformationLine } from "react-icons/ri";
import { InviteFlexTenantWidget } from "./InviteFlexTenantWidget";

export const InviteTable: React.FC = () => {
  const { t } = useTranslation();
  const { validatedInvites, updateInviteRow, selectRows, removeRows, rowsAreSelected } = useContext(NewInvites);
  const { canManageCurrentSite, canManageFlexTenants, isFlexRelated } = useContext(CurrentProfile);

  const onEmailUpdate = useCallback(
    (invite: Invite) => (e: ChangeEvent<HTMLInputElement>) => {
      updateInviteRow(invite.id, e.target.value);
    },
    [updateInviteRow],
  );

  const bodyRows = useMemo(
    () =>
      validatedInvites.map((invite) => ({
        id: invite.id,
        className: `invite-row${invite.error ? " has-error" : ""}`,
        cols: [
          {
            className: "input-td",
            content: (
              <Form.Group className="mb-0" error={invite.error}>
                <Form.Input
                  variant="sm"
                  className="email invite-email"
                  type="email"
                  disabled={rowsAreSelected && !invite.isChecked}
                  placeholder={t("common.email")}
                  value={invite.email ?? ""}
                  onChange={onEmailUpdate(invite)}
                />
              </Form.Group>
            ),
          },
          {
            className: "input-td",
            content: <InviteGroupWidget disabled={rowsAreSelected && !invite.isChecked} invite={invite} />,
          },
          ...(canManageCurrentSite || canManageFlexTenants
            ? [
                {
                  className: "input-td",
                  content: <InviteCompanyWidget disabled={rowsAreSelected && !invite.isChecked} invite={invite} />,
                },
              ]
            : []),
          {
            className: "input-td",
            content: (
              <InviteRoleWidget key={invite.id} disabled={rowsAreSelected && !invite.isChecked} invite={invite} />
            ),
          },
          ...(isFlexRelated
            ? [
                {
                  className: "input-td flex-tenants",
                  content: (
                    <InviteFlexTenantWidget
                      key={invite.id}
                      disabled={rowsAreSelected && !invite.isChecked}
                      invite={invite}
                    />
                  ),
                },
              ]
            : []),
        ],
      })),
    [canManageCurrentSite, isFlexRelated, canManageFlexTenants, onEmailUpdate, rowsAreSelected, t, validatedInvites],
  );

  return (
    <>
      <div className="invite-cont">
        <BulkTable
          sideBorders={false}
          style={{ minWidth: "800px" }}
          onDeleted={removeRows}
          onSelected={selectRows}
          bulkOperations={
            <>
              <BulkGroupSelection />
              {canManageCurrentSite || canManageFlexTenants ? <BulkCompanySelectionWidget /> : null}
              <BulkRoleSelection />
            </>
          }
          headers={[
            { label: t("common.email") },
            { label: t("common.groups") },
            ...(canManageCurrentSite || canManageFlexTenants ? [{ label: t("common.company") }] : []),
            {
              label: (
                <>
                  <Text variant="label" className="mt-3">
                    {t("common.roles")}
                  </Text>
                  <Tooltip title={t("home.widgets.inviteRoleTooltip")}>
                    <span style={{ fontSize: 16 }}>
                      <RiInformationLine size={16} className="ml-2" />
                    </span>
                  </Tooltip>
                </>
              ),
            },
            ...(isFlexRelated
              ? [
                  {
                    label: (
                      <>
                        <Text variant="label" className="mt-3">
                          {t("flex.members.editForm.titles.membership")}
                        </Text>
                        <Tooltip title={t("home.widgets.inviteFlexTenantTooltip")}>
                          <span style={{ fontSize: 16 }}>
                            <RiInformationLine size={16} className="ml-2" />
                          </span>
                        </Tooltip>
                      </>
                    ),
                  },
                ]
              : []),
          ]}
          bodyRows={bodyRows}
          deletion={{ bulk: true, single: true }}
        />
      </div>
      <style jsx>{`
        .invite-cont :global(.input-td) {
          width: 300px;
          max-width: 300px;
        }
      `}</style>
    </>
  );
};
