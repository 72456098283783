import React, { useContext, useEffect, useMemo } from "react";
import { createPortal } from "react-dom";
import type { ReactElement, ReactNode } from "react";

import { DropdownContext } from "../Dropdown";
import { useTheme } from "../../../contexts/Theme";
import { ModalContext } from "../../Modal/ModalContext";
import { AdminButton as Button } from "../../Button/AdminButton";
import { Text } from "../../Text/Text";
import { useIsMobileWidth } from "../../../hooks";
import { BodyPortal } from "../../BodyPortal/BodyPortal";

// Intersection Ratio how much element visible before
// we hide.
const intersectionRatioThreshold = 0.25;

export const DialogPortal: React.FC<{ children: ReactNode }> = ({ children }) => {
  const { modalBodyRef } = useContext(ModalContext);

  if (modalBodyRef?.current == null || typeof document === "undefined") {
    return <>{children}</>;
  }

  const dialog = modalBodyRef.current.closest("[role='dialog']");
  if (dialog == null) {
    return <>{children}</>;
  }

  return createPortal(children, dialog);
};

export type DropdownMobileView = "minimal" | "regular" | "full";

export interface Props {
  fixedHeader?: ReactElement;
  width?: number;
  title?: string;
  maxHeight?: number;
  mobileView?: DropdownMobileView;
  portalType?: "dialog" | "body";
  isNested?: boolean;
}

export const DropdownMenu: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  fixedHeader,
  width,
  title,
  mobileView,
  maxHeight,
  portalType,
  isNested = false,
}) => {
  const isMobileWidth = useIsMobileWidth();
  const { show, floating, x, y, strategy, toggleId, setShow, refs } = useContext(DropdownContext);
  const { spacers, colors, borderRadius, zIndexes, breakpoints } = useTheme();

  const { modalBodyRef } = useContext(ModalContext);

  const isMobile = useMemo(() => {
    return mobileView != null && isMobileWidth;
  }, [isMobileWidth, mobileView]);

  useEffect(() => {
    // focus first input element for mobile
    if (isMobile && show && refs.floating.current != null) {
      refs.floating.current.querySelector("input")?.focus();
    }
  }, [isMobile, show, refs]);

  useEffect(() => {
    // hide body scroll for full overlays
    if (isMobile && mobileView === "full") {
      document.body.style.overflow = show ? "hidden" : "";
    }
  }, [isMobile, show, mobileView]);

  useEffect(() => {
    if (isMobile || modalBodyRef?.current == null || refs.reference.current == null || !show) {
      return undefined;
    }

    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].intersectionRatio <= intersectionRatioThreshold) {
          setShow(false);
        }
      },
      {
        threshold: intersectionRatioThreshold,
      },
    );

    observer.observe(refs.reference.current as HTMLDivElement);
    return () => {
      observer.disconnect();
    };
  }, [isMobile, modalBodyRef, refs.reference, setShow, show]);

  const handleDone = () => {
    setTimeout(() => {
      setShow(false);
    }, 0);
  };

  const sectionClassName = useMemo(() => {
    if (isMobile) {
      if (mobileView === "full") {
        return "section-mobile section-mobile--full";
      }

      return "section-mobile";
    }

    return undefined;
  }, [isMobile, mobileView]);

  const Portal = useMemo(() => {
    if (portalType === "body") {
      return BodyPortal;
    }

    return DialogPortal;
  }, [portalType]);

  return (
    <>
      {show && (
        <Portal>
          {isMobile && <div className="dropdown-backdrop-mobile" onClick={handleDone} />}
          <section
            ref={floating}
            role="listbox"
            aria-labelledby={toggleId}
            data-escape-focus-trap
            className={sectionClassName}
          >
            {fixedHeader}
            {isMobile && (
              <div className={`header-mobile ${title == null && "header-mobile--short"}`}>
                {mobileView !== "minimal" && (
                  <Button variant="ghost" onClick={handleDone} className="done-button-mobile">
                    Done
                  </Button>
                )}
                {title != null && (
                  <Text variant="button" size="small">
                    {title}
                  </Text>
                )}
              </div>
            )}
            <div className="content">{children}</div>
          </section>
        </Portal>
      )}
      <style jsx>{`
        section {
          background: ${colors.white};
          border: 1px solid ${colors.border};
          border-radius: ${borderRadius};
          color: ${colors.dark};
          max-width: ${width !== undefined ? "auto" : "18rem"};
          width: ${width !== undefined ? `${width}px` : "auto"};
          z-index: ${zIndexes.dropdown};
          position: ${strategy};
          top: ${(y ?? 0) + (isNested && !isMobile ? -4 : 0)}px;
          left: ${x ?? 0}px;
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
          margin-top: ${spacers.s2};
          padding: ${spacers.s3} 0;
        }

        .content {
          overflow-y: auto;
          max-height: ${maxHeight ?? 200}px;
          display: flex;
          flex-direction: column;
        }
        .content :global(button) {
          display: flex;
          align-items: center;
          margin: 0 8px;
        }

        @media (max-width: ${breakpoints.lg}px) {
          .section-mobile {
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
            border-bottom-left-radius: 0px;
            border-bottom-right-radius: 0px;
            width: 100%;
            max-width: unset;
            position: fixed;
            bottom: 0px;
            right: 0px;
            left: 0px;
            top: unset;
            padding: 12px 0 16px 0;
          }

          .section-mobile--full {
            top: 0;
            border-radius: 0;
            margin: 0;
          }

          .section-mobile .content {
            max-height: calc(100vh - 56px);
            padding: 8px;
            padding-bottom: 0px;
          }

          .section-mobile--full .content {
            padding-bottom: 8px;
          }

          .dropdown-backdrop-mobile {
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: ${zIndexes.dropdown - 1};
            background-color: ${colors.transparent.black[40]};
          }

          .header-mobile {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: row-reverse;
            padding: 12px 12px 16px;
            border-bottom: 1px solid ${colors.transparent.black[10]};
            color: ${colors.grayscale[60]};
          }

          .header-mobile--short {
            justify-content: space-between;
            padding-top: 0;
            padding-bottom: 0;
            border: 0;
          }

          :global(.section-mobile) .header-mobile :global(.done-button-mobile) {
            position: absolute;
            top: 16px;
            right: 12px;
            z-index: 1;
          }
        }
      `}</style>
    </>
  );
};

DropdownMenu.displayName = "DropdownMenu";
