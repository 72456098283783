import React, { useRef, useContext, useEffect } from "react";
import { EscapedHtmlDiv } from "@equiem/react-admin-ui";

import { useSiteContext } from "../context/SiteContext";
import { IframelyContext } from "../context/IframelyContext";
import { getLinkType } from "../getLinkType";

function targetBlankExternalLinks(container: HTMLElement, siteDomainAliases: string[]) {
  for (const link of container.getElementsByTagName("a")) {
    const type = getLinkType(link.href, siteDomainAliases);
    if (type === "external") {
      link.setAttribute("target", "_blank");
      link.setAttribute("rel", "noopener noreferrer");
    }
  }
}

export const RichHtml: React.FC<{ className?: string; html: string; minHeight?: number }> = ({
  className,
  html,
  minHeight,
}) => {
  const { domainAliases } = useSiteContext();
  const iframely = useContext(IframelyContext);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref.current != null) {
      iframely.load(ref.current);
      targetBlankExternalLinks(ref.current, domainAliases);
    }
  }, [domainAliases, iframely]);

  return <EscapedHtmlDiv ref={ref} className={className} html={html} minHeight={minHeight} />;
};
