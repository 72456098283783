import { useTranslation } from "@equiem/localisation-eq1";
import { Form } from "@equiem/react-admin-ui";
import { useFormikContext } from "formik";
import React, { useContext, useMemo } from "react";
import { getAvailabilityDateRange } from "../../../lib/getAvailabilityDateRange";
import { BookingModalInfo } from "../contexts/BookingModalInfoProvider";
import { BookingCalendarContext } from "../contexts/BookingCalendarContext";
import type { BookingFormValue } from "../models/BookingFormValue";
import { DateTime } from "luxon";

export const BookingFormDate: React.FC = () => {
  const { t } = useTranslation();
  const fm = useFormikContext<BookingFormValue>();
  const { resource, timezone } = useContext(BookingModalInfo);
  const { setSelectedDate } = useContext(BookingCalendarContext);
  const hasSuperPower = fm.values.hasSuperPower === true;

  const { startDate, endDate } = useMemo(
    () =>
      getAvailabilityDateRange(
        resource.availabilityDateRange,
        resource.bookingWindowMinInMinutes,
        resource.bookingWindowMaxInMinutes,
        timezone,
      ),
    [resource.availabilityDateRange, resource.bookingWindowMaxInMinutes, resource.bookingWindowMinInMinutes, timezone],
  );

  return (
    <Form.Group label={t("common.date")} error={fm.touched.date === true ? fm.errors.date : undefined} required>
      <Form.Input
        id="date"
        name="date"
        type="date"
        value={fm.values.date}
        min={
          hasSuperPower ? DateTime.local({ zone: timezone }).toFormat("yyyy-LL-dd") : startDate.toFormat("yyyy-LL-dd")
        }
        max={hasSuperPower ? undefined : endDate?.toFormat("yyyy-LL-dd") ?? "9999-12-31"}
        onChange={(e) => {
          setSelectedDate(timezone, e.target.value);
          fm.setFieldValue("date", e.target.value).catch(console.error);
          fm.setFieldTouched("date").catch(console.error);
        }}
      />
    </Form.Group>
  );
};
