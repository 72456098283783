import React from "react";
import { SettingsTab } from "./components/SettingsTab";
import { RoomConfigurationSummary } from "./components/resourceConfiguration/RoomConfigurationSummary";
import { ResourceTypeSummary } from "./components/resourceConfiguration/ResourceTypeSummary";
import { ResourceFeatureSummary } from "./components/resourceConfiguration/ResourceFeatureSummary";
import { ResourceSharedFacilitySummary } from "./components/resourceConfiguration/ResourceSharedFacilitySummary";
import { Material } from "@equiem/react-admin-ui";

export const SettingPage = () => {
  return (
    <SettingsTab>
      <Material.Provider>
        <ResourceTypeSummary />
        <RoomConfigurationSummary />
        <ResourceFeatureSummary />
        <ResourceSharedFacilitySummary />
      </Material.Provider>
    </SettingsTab>
  );
};
