import { useMemo } from "react";

import { useTranslation } from "@equiem/localisation-eq1";
import type { FilterItem, FilterOption } from "@equiem/react-admin-ui";
import { FilterOptionsModifier } from "@equiem/react-admin-ui";
import { RiBarcodeBoxLine } from "@equiem/react-admin-ui/icons";

import { ReqMgtStatusType } from "../../../generated/requests-client";
import type { ActiveTab } from "../contexts/ActiveTab";

interface Status {
  uuid: string;
  name: string;
  type: ReqMgtStatusType;
}

export function useRequestFilterStatus(
  statuses?: Status[],
  activeTab?: ActiveTab,
  userSelectedStatuses?: string | FilterOption[] | [string, string] | undefined,
) {
  const { t } = useTranslation();

  const options = useMemo(() => {
    return (statuses ?? [])
      .filter((x) =>
        activeTab === "completed"
          ? x.type === ReqMgtStatusType.Completed
          : activeTab === "open"
          ? x.type !== ReqMgtStatusType.Completed
          : true,
      )
      .map((status) => ({
        label: status.name,
        value: status.uuid,
      }))
      .sort((a, b) => a.label.localeCompare(b.label));
  }, [statuses, activeTab]);

  const filter: FilterItem = {
    title: t("common.status"),
    type: "options",
    options,
    icon: RiBarcodeBoxLine,
    modifiers: [FilterOptionsModifier.includes],
  };

  if (userSelectedStatuses != null) {
    const legalStatusUuids = options.map((x) => x.value);
    const selectedStatusUuids = (userSelectedStatuses as FilterOption[]).map((x) => x.value);

    if (selectedStatusUuids.some((selected) => !legalStatusUuids.includes(selected))) {
      return { filter: null };
    }
  }

  return {
    filter: filter.options.length > 0 ? filter : null,
  };
}
