"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LocaleDetector = void 0;
var getLocalePreference_1 = require("./getLocalePreference");
var setLocalePreference_1 = require("./setLocalePreference");
var LocaleDetector = /** @class */ (function () {
    function LocaleDetector(gqlRequester, localePreferenceScope, supportedLanguages) {
        this.gqlRequester = gqlRequester;
        this.localePreferenceScope = localePreferenceScope;
        this.supportedLanguages = supportedLanguages;
        this.type = "languageDetector";
        this.async = true;
        this.realLanguagePreferenceLoaded = false;
        this.languagePromise = Promise.resolve((0, getLocalePreference_1.getUninitializedLocalePreference)(supportedLanguages));
    }
    LocaleDetector.prototype.init = function () {
        var _this = this;
        if (this.gqlRequester != null) {
            this.languagePromise = (0, getLocalePreference_1.getLocalePreference)(this.gqlRequester, this.localePreferenceScope, this.supportedLanguages)
                .then(function (lng) {
                _this.realLanguagePreferenceLoaded = true;
                return lng;
            })
                .catch(function (e) {
                console.error(e);
                return undefined;
            });
        }
    };
    LocaleDetector.prototype.detect = function (callback) {
        this.languagePromise.then(function (lng) { return callback(lng); }).catch(function () { return callback(undefined); });
    };
    LocaleDetector.prototype.cacheUserLanguage = function (lng) {
        if (typeof localStorage !== "undefined") {
            localStorage.setItem(getLocalePreference_1.LOCALE_PREFERENCE_CACHE_KEY, lng);
        }
        // don't cache if we haven't considered the user's existing preferences
        if (this.gqlRequester != null &&
            this.realLanguagePreferenceLoaded &&
            this.localePreferenceScope.useViewer === true &&
            this.localePreferenceScope.preferredLanguage == null) {
            (0, setLocalePreference_1.setLocalePreference)(this.gqlRequester, lng);
        }
    };
    return LocaleDetector;
}());
exports.LocaleDetector = LocaleDetector;
