/* eslint-disable @typescript-eslint/no-type-alias */
import type { RefObject } from "react";
import React from "react";

export type StickyRef = RefObject<HTMLElement>;
export type StackItem = {
  top: number;
  ref: StickyRef | undefined;
  stack: boolean;
};
export type Stack = StackItem[];
export type StickyElementOptions = { shouldStack: boolean };

export interface StickyContext {
  push: (ref: StickyRef, options: StickyElementOptions) => StackItem;
  pop: (ref: StickyRef) => void;
  stack: RefObject<Stack>;
  rootRef: RefObject<HTMLElement | null> | null;
}

export const StickyContext = React.createContext<StickyContext>({
  push: () => ({ ref: { current: null }, top: 0, stack: false }),
  pop: () => undefined,
  stack: { current: [] },
  rootRef: null,
});
