import React, { useContext, forwardRef } from "react";
import type { ComponentProps, ReactElement } from "react";

import { BsBoxArrowUpRight } from "../../end-user-icons";
import type { IconType } from "react-icons";
import { RiShareBoxLine } from "../../admin-icons";
import { useTheme } from "../../contexts/Theme";
import { NavContext } from "../../contexts/NavContext";

export interface NavLinkProps extends ComponentProps<"a"> {
  noMargin?: boolean;
  icon?: IconType;
  actionButton?: ReactElement;
}

export const NavLink = forwardRef<HTMLAnchorElement, NavLinkProps>(
  ({ children, noMargin = false, icon: Icon, actionButton, ...props }, ref) => {
    const { fontWeight } = useContext(NavContext);
    const { spacers, colors, borderRadius, isAdmin } = useTheme();

    return (
      <a ref={ref} role="option" className="dropdownitem" {...props}>
        <div>
          {Icon != null && (
            <span className="icon">
              <Icon size={16} />
            </span>
          )}
          {children}
        </div>
        {props.target === "_blank" && (
          <span className="external">
            {actionButton != null && <span className="actions">{actionButton}</span>}
            {isAdmin ? <RiShareBoxLine size={12} /> : <BsBoxArrowUpRight size={12} />}
          </span>
        )}
        <style jsx>{`
          a {
            padding: ${spacers.s3};
            cursor: pointer;
            text-align: left;
            border-radius: ${borderRadius};
            margin: ${noMargin ? "none" : `0 ${spacers.s3}`};
            font-weight: ${fontWeight === "light" ? "initial" : "500"};
            font-size: 12px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            text-decoration: none;
            margin-bottom: ${spacers.s2};
            overflow: hidden;
          }
          a:hover {
            background: ${colors.grayscale_5};
          }
          div {
            flex: 1;
            display: flex;
            align-items: center;
            word-break: break-word;
          }
          .icon {
            margin-right: ${spacers.s3};
            color: ${colors.primary};
          }
          .external {
            flex: 0;
            display: flex;
            align-items: center;
            flex-wrap: nowrap;
            margin-left: ${spacers.s3};
            color: ${colors.medium};
            opacity: 0;
          }
          a:hover .external {
            opacity: 1;
          }
          .actions {
            padding-right: ${spacers.s3};
            display: inline-flex;
          }
        `}</style>
      </a>
    );
  },
);
NavLink.displayName = "NavLink";
