import type { FC } from "react";
import React from "react";
import type { CmsArticleFragmentFragment } from "../../generated/gateway-client";
import { Tag } from "@equiem/react-admin-ui";
import { useTranslation } from "@equiem/localisation-eq1";

interface Props {
  article?: CmsArticleFragmentFragment;
}

export const ArticlePublishingTag: FC<Props> = ({ article }) => {
  const { t } = useTranslation();

  if (article?.isArchived === true) {
    return (
      <Tag variant="negative" size="large">
        {t("contentManagement.statuses.archived")}
      </Tag>
    );
  }

  return (
    <>
      {(article == null || article.publishedContent == null) && (
        <Tag variant="default" size="large">
          {t("contentManagement.statuses.draft")}
        </Tag>
      )}
      {article?.publishedContent != null && (
        <>
          {article.draftIsDifferentToPublished && (
            <Tag variant="warning" size="large">
              {t("contentManagement.statuses.draftDifferentToPublished")}
            </Tag>
          )}
          {!article.draftIsDifferentToPublished && (
            <Tag variant="positive" size="large">
              {t("contentManagement.statuses.published")}
            </Tag>
          )}
        </>
      )}
    </>
  );
};
