import { useTranslation } from "@equiem/localisation-eq1";
import { Form } from "@equiem/react-admin-ui";
import { useFormikContext } from "formik";
import React from "react";
import type { BookingFormValue } from "../models/BookingFormValue";

export const BookingFormTitle: React.FC = () => {
  const { t } = useTranslation();
  const fm = useFormikContext<BookingFormValue>();
  const titleError = fm.touched.title === true ? fm.errors.title : undefined;

  return (
    <>
      <Form.Group
        error={titleError}
        label={t("common.title")}
        showTooltip={true}
        tooltipText={t("bookings.operations.addTitleForBookingTooltip")}
      >
        <Form.Input
          name="title"
          placeholder={t("common.title")}
          value={fm.values.title ?? ""}
          onChange={(e) => {
            fm.setFieldValue("title", e.target.value).catch(console.error);
            fm.setFieldTouched("title").catch(console.error);
          }}
        />
      </Form.Group>
    </>
  );
};
