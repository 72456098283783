import { formatters, useTranslation } from "@equiem/localisation-eq1";
import { DateTime } from "luxon";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useTheme } from "../../contexts/Theme";
import { Dropdown } from "../Dropdown";
import { DropdownMenu } from "../Dropdown/DropdownMenu/DropdownMenu";
import * as Form from "../Form";
import { Text } from "../Text/Text";
import type {
  FilterItemDateMonth,
  FilterItemProps,
  FilterValueDateMonth,
  FilterValueDateMonthBetween,
  HTMLDateInputElement,
} from "./ComplexFilter.types";
import { FilterDateMonthModifier, FilterType } from "./ComplexFilter.types";
import { ComplexFilterContext } from "./ComplexFilterContext";
import { FilterChip } from "./FilterChip";
import { ModifierSelect } from "./ModifierSelect";

export const defaultModifier = FilterDateMonthModifier.is;
const dropdownWidth = 252;
const dropdownWidthRange = 390;

export const FilterDateMonth: React.FC<FilterItemProps<FilterItemDateMonth>> = ({
  title,
  name,
  modifiers,
  min,
  max,
  icon,
}) => {
  const { t } = useTranslation();
  const endDateRef = useRef<HTMLDateInputElement>(null);
  const { colors } = useTheme();
  const { values, setValue, removeValue, language } = useContext(ComplexFilterContext);
  const [selectedModifier, setSelectedModifier] = useState<FilterDateMonthModifier>(
    (values[name]?.modifier ?? modifiers?.[0] ?? defaultModifier) as FilterDateMonthModifier,
  );
  const [prevSelectedModifier, setPrevSelectedModifier] = useState(selectedModifier);

  const isRange = useMemo(() => selectedModifier === FilterDateMonthModifier.between, [selectedModifier]);

  useEffect(() => {
    if (selectedModifier === prevSelectedModifier) {
      return;
    }
    setPrevSelectedModifier(selectedModifier);
    setValue(name, {
      type: FilterType.datemonth,
      modifier: selectedModifier,
      value: undefined,
    });
  }, [selectedModifier, prevSelectedModifier]);

  const handleStartChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (isRange) {
      setValue(name, {
        type: FilterType.datemonth,
        modifier: FilterDateMonthModifier.between,
        value: [e.target.value, values[name]?.value?.[1] ?? e.target.value],
      } as FilterValueDateMonthBetween);
    } else {
      setValue(name, {
        type: FilterType.datemonth,
        modifier: selectedModifier,
        value: e.target.value,
      } as FilterValueDateMonth);
    }
  };

  const handleEndChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (isRange) {
      setValue(name, {
        type: FilterType.datemonth,
        modifier: FilterDateMonthModifier.between,
        value: [values[name]?.value?.[0] ?? e.target.value, e.target.value],
      } as FilterValueDateMonthBetween);
    }
  };

  const labelValue = useMemo(() => {
    const getDate = (iso: string | undefined) =>
      iso != null ? formatters.datemonth(DateTime.fromISO(iso), language) : "";

    const value = values[name];
    if (value?.modifier === FilterDateMonthModifier.between && value.value != null) {
      return `${getDate(value.value[0])} - ${getDate(value.value[1])}`;
    }

    if (value?.modifier === FilterDateMonthModifier.before && value.value != null) {
      return `${t("common.before")} ${getDate(value.value)}`;
    }

    if (value?.modifier === FilterDateMonthModifier.after && value.value != null) {
      return `${t("common.after")} ${getDate(value.value)}`;
    }

    if (value?.modifier === FilterDateMonthModifier.is) {
      return getDate(value.value);
    }

    return undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values, name]);

  const getLocalizedTitle = (modifier: FilterDateMonthModifier) => {
    switch (modifier) {
      case FilterDateMonthModifier.is:
        return t("common.is");
      case FilterDateMonthModifier.between:
        return t("common.between");
      case FilterDateMonthModifier.before:
        return t("common.before");
      case FilterDateMonthModifier.after:
        return t("common.after");
      default:
        return "";
    }
  };

  const value = values[name]?.value as string | string[] | undefined;

  return (
    <>
      <Dropdown placement="bottom-start" flip>
        <FilterChip
          title={title}
          value={labelValue}
          type={FilterType.datemonth}
          name={name}
          onClose={() => removeValue(name)}
          icon={icon}
          size="lg"
        />
        <DropdownMenu width={isRange ? dropdownWidthRange : dropdownWidth} mobileView="regular">
          {modifiers != null && (
            <div className="calendar-top">
              <Text variant="label" className="m-0">
                {title}
              </Text>
              <ModifierSelect
                options={modifiers.map((m) => ({
                  value: m,
                  label: getLocalizedTitle(m),
                }))}
                selected={selectedModifier}
                onChange={(v) => setSelectedModifier(v as FilterDateMonthModifier)}
              />
            </div>
          )}
          {!isRange ? (
            <div className="date-input">
              <Form.Input type="month" value={value ?? ""} onChange={handleStartChange} min={min} max={max} />
            </div>
          ) : (
            <div className="range-input">
              <Form.Input
                type="month"
                value={value?.[0] ?? ""}
                onChange={handleStartChange}
                min={min}
                max={value?.[1] ?? max}
              />
              <Form.Input
                ref={endDateRef}
                type="month"
                value={value?.[1] ?? ""}
                onChange={handleEndChange}
                min={value?.[0] ?? min}
                max={max}
              />
            </div>
          )}
        </DropdownMenu>
      </Dropdown>
      <style jsx>{`
        .options-list {
          display: flex;
          flex-direction: column;
          gap: 1rem;
          padding: 1rem;
        }

        .calendar-top {
          padding: 0 1rem 0.25rem;
          display: flex;
          flex-direction: row;
          align-items: center;
          color: ${colors.grayscale[100]};
        }

        .date-input {
          display: flex;
          padding: 0 0.5rem;
        }

        .range-input {
          padding: 0 0.5rem;
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-gap: 0.5rem;
        }
      `}</style>
    </>
  );
};
