"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useServerMessageTranslation = void 0;
var react_i18next_1 = require("react-i18next");
var ServerMessageVariables_1 = require("./ServerMessageVariables");
var react_1 = require("react");
function parseVariables(variables, forKey) {
    if (variables == null) {
        return {};
    }
    if (typeof variables === "string") {
        try {
            return ServerMessageVariables_1.ServerMessageVariables.check(JSON.parse(variables));
        }
        catch (e) {
            console.warn("Failed to parse server message localisation variables as JSON", { forKey: forKey, variables: variables, e: e });
            return {};
        }
    }
    return variables;
}
function useServerMessageTranslation() {
    var _a = (0, react_i18next_1.useTranslation)("server-messages"), t = _a.t, i18n = _a.i18n, ready = _a.ready;
    var tServer = (0, react_1.useCallback)(function (_a) {
        var key = _a.key, variables = _a.variables, variablesJSON = _a.variablesJSON, fallback = _a.fallback;
        var output = t(key, {
            replace: parseVariables(variables !== null && variables !== void 0 ? variables : variablesJSON, key),
            // never show a localisation key in the UI, even if this string hasn't
            // been deployed on the front-end yet
            defaultValue: fallback,
            // make it possible to handle the server returning a non-terminal
            // translation key (e.g. `"operations-api"`) - if this is false, we just
            // get a string error message from i18next
            returnObjects: true,
            // ESLint in the `lint` task seems to struggle with the function
            // overloading for `t` - it thinks it returns `any`. TypeScript and
            // intelisense-ESLint both agree it returns `string` or a sub-object of
            // the string table.
        });
        return typeof output === "string" ? output : fallback;
    }, [t]);
    return {
        tServer: tServer,
        i18n: i18n,
        ready: ready,
    };
}
exports.useServerMessageTranslation = useServerMessageTranslation;
