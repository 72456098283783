import { useCallback, useEffect, useState } from "react";
import {
  useUserCompaniesForSegmentsQuery,
  type UserCompaniesForSegmentsQueryVariables,
} from "@equiem/segmentation-widget/src/generated/gateway-client";

type Variables = Omit<UserCompaniesForSegmentsQueryVariables, "after" | "batchSize">;

const DEFAULT_PAGE_SIZE = 100;

export function useUserCompaniesForSegments(variables: Variables, skip: boolean, pageSize = DEFAULT_PAGE_SIZE) {
  const result = useUserCompaniesForSegmentsQuery({
    variables: {
      ...variables,
      batchSize: pageSize,
    },
    skip,
    fetchPolicy: "cache-and-network",
  });
  const [loadingMore, setLoadingMore] = useState(false);
  const loading = result.loading || loadingMore;

  const hasMoreData = result.data?.userCompaniesForSegmentsConnection.pageInfo.hasNextPage ?? false;
  const endCursor = result.data?.userCompaniesForSegmentsConnection.pageInfo.endCursor ?? null;
  const canFetchMore = !loading && hasMoreData && endCursor != null;

  const fetchMoreAsync = useCallback(
    async (latestEndCursor = endCursor) =>
      result.fetchMore({
        variables: {
          ...variables,
          batchSize: pageSize,
          after: latestEndCursor,
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          return {
            ...fetchMoreResult,
            userCompaniesForSegmentsConnection: {
              ...fetchMoreResult.userCompaniesForSegmentsConnection,
              edges: [
                ...prev.userCompaniesForSegmentsConnection.edges,
                ...fetchMoreResult.userCompaniesForSegmentsConnection.edges,
              ],
            },
          };
        },
      }),
    [endCursor, pageSize, result, variables],
  );

  const fetchAll = useCallback(async () => {
    if (!canFetchMore) {
      return;
    }

    setLoadingMore(true);
    try {
      let latestEndCursor: string | null = endCursor;
      let latestHasMoreData: boolean = hasMoreData;
      while (latestHasMoreData) {
        const next = await fetchMoreAsync(latestEndCursor);
        latestEndCursor = next.data.userCompaniesForSegmentsConnection.pageInfo.endCursor ?? null;
        latestHasMoreData = next.data.userCompaniesForSegmentsConnection.pageInfo.hasNextPage;
      }
    } finally {
      setLoadingMore(false);
    }
  }, [canFetchMore, endCursor, hasMoreData, fetchMoreAsync]);

  useEffect(() => {
    if (canFetchMore) {
      fetchAll().catch(console.error);
    }
  }, [canFetchMore, fetchAll]);

  return {
    companies: result.data?.userCompaniesForSegmentsConnection.edges.map((edge) => edge.node) ?? [],
    loading: result.loading || loadingMore,
    error: result.error,
  };
}
