import { useGetReceptionQuery } from "../../generated/visitors-client";

export const useReceptionFormReception = ({ uuid }: { uuid?: string | null }) => {
  const { data, loading } = useGetReceptionQuery({
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    variables: { uuid: uuid! },
    skip: uuid == null,
  });

  return {
    reception: data?.visitorReception,
    loading,
  };
};
