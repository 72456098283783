import { useTranslation } from "@equiem/localisation-eq1";
import { Material, Tag, Text, Tooltip, useTheme } from "@equiem/react-admin-ui";
import React, { useContext } from "react";
import { resourceFeatureGroupToString, resourceFeatureToString } from "../../../lib/resourceTypeToString";
import { BookingModalInfo } from "../contexts/BookingModalInfoProvider";
import type { BookableResourceFeatureType } from "../../../generated/gateway-client";

interface Props {
  featureTypes: BookableResourceFeatureType[];
  showLabel?: boolean;
  showIconOnly?: boolean;
  className?: string;
}
export const BookingResourceFeatures: React.FC<Props> = ({
  featureTypes,
  showLabel = false,
  className = "",
  showIconOnly = false,
}) => {
  const { t } = useTranslation();
  const { resource } = useContext(BookingModalInfo);
  const { colors, spacers } = useTheme(true);

  const features = resource.features.filter((f) => featureTypes.includes(f.type));
  if (features.length === 0) {
    return null;
  }

  return (
    <>
      <div className={`features-cont ${className}`}>
        {showLabel && (
          <div className="pb-3">
            <Text variant="label" color={colors.grayscale[60]}>
              {featureTypes.map((featureType) => resourceFeatureGroupToString(featureType, t)).join(" & ")}
            </Text>
          </div>
        )}
        <div className="features-tags">
          {showIconOnly ? (
            <>
              {features.map((feature) => (
                <Tooltip key={feature.uuid} title={resourceFeatureToString(feature.name, t)}>
                  <div>{feature.iconName != null && <Material.Icon size="sm" name={feature.iconName} />}</div>
                </Tooltip>
              ))}
            </>
          ) : (
            <>
              {features.map((feature) => (
                <Tag key={feature.uuid} variant="default">
                  {feature.iconName != null && <Material.Icon className="mr-3" size="sm" name={feature.iconName} />}
                  {resourceFeatureToString(feature.name, t)}
                </Tag>
              ))}
            </>
          )}
        </div>
      </div>
      <style jsx>{`
        .features-tags div {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 28px;
          height: 28px;
          background: ${colors.grayscale[10]};
          border-radius: 28px;
        }
        .features-tags {
          display: flex;
          flex-wrap: wrap;
          gap: ${spacers.s3};
        }
        .features-tags :global(.tag) {
          padding: ${spacers.s2} ${spacers.s3};
          font-size: 12px;
          line-height: 16px;
        }
      `}</style>
    </>
  );
};
