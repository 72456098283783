import React from "react";
import { DateTime } from "luxon";

import { getReceptionName, useSiteContext } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import { DateTime as DateTimeComponents, Form } from "@equiem/react-admin-ui";

import type { VisitorAppointmentsByReceptionQuery, VisitorReceptionQuery } from "../../../../generated/visitors-client";

interface Props {
  info: VisitorReceptionQuery["visitorReception"];
  appointments: VisitorAppointmentsByReceptionQuery["visitorAppointmentsByReception"];
  setDeletionTime: (e: string) => void;
  deletionTime: string;
}

export const ReceptionDeleteInfo: React.FC<Props> = ({ info, appointments, setDeletionTime, deletionTime }) => {
  const { t, i18n } = useTranslation();
  const site = useSiteContext();

  const timezone = site.timezone;
  const tzName = DateTime.fromMillis(Date.now(), { zone: timezone }).toFormat("ZZZZ");

  return (
    <>
      <div>
        <h1>
          {t("visitors.reception.deleteInfoTitle", {
            count: appointments.totalCount,
            title: getReceptionName(info, t),
          })}
        </h1>
        <p>{t("visitors.reception.deleteInfoLine1")}</p>
        <p>
          <strong>{t("visitors.reception.deleteInfoLine2")}</strong>
        </p>
        <ul>
          <li>{t("visitors.reception.deleteInfoLine3")}</li>
          <li>{t("visitors.reception.deleteInfoLine4")}</li>
          <li>{t("visitors.reception.deleteInfoLine5")}</li>
        </ul>

        <p>{t("visitors.reception.deleteInfoLine6")}</p>

        <strong>
          <DateTimeComponents.DateDisplay
            language={i18n.language}
            datetime={DateTime.fromISO(deletionTime).toMillis()}
            timezone={timezone}
          />
          {` ${tzName}`}
        </strong>
        <p>
          <div className="form-fields ">
            <Form.Group
              className="deletion-time mb-0"
              label={t("visitors.reception.deleteScheduleAfter")}
              tooltipText={t("visitors.reception.deleteScheduleTooltip")}
              showTooltip
            />
          </div>
          <Form.Input
            type="date"
            min={deletionTime}
            value={deletionTime}
            onChange={(e) => setDeletionTime(e.target.value)}
          />
        </p>
        <p>
          <strong>{t("visitors.reception.deleteInfoLine7")}</strong>
        </p>
        <ul>
          <li>{t("visitors.reception.deleteInfoLine8")}</li>
          <li>{t("visitors.reception.deleteInfoLine9")}</li>
        </ul>
        <p>{t("visitors.reception.deleteInfoLine10")}</p>
      </div>
      <style jsx>{`
        ul {
          margin: 0;
          padding: 0;
          padding-inline-start: 1.5em;
        }
        li {
          line-height: 1.5;
        }
        .form-fields {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      `}</style>
    </>
  );
};
