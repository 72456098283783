export const handleMultilineTextAreaInput = (
  e: React.KeyboardEvent<HTMLTextAreaElement>,
  setValue: (value: string) => void,
) => {
  if (e.key === "Enter") {
    e.preventDefault();
    const textArea = e.target as HTMLTextAreaElement;

    const start = textArea.selectionStart;
    const end = textArea.selectionEnd;
    const textValue = textArea.value;

    const before = textValue.substring(0, start);
    const after = textValue.substring(end);
    const updatedValue = `${before}\n${after}`;

    setValue(updatedValue);

    setTimeout(() => {
      textArea.selectionStart = textArea.selectionEnd = start + 1;
    }, 0);
  }
};
