import React, { useEffect, useState } from "react";

import type { UseFloatingReturn } from "@floating-ui/react-dom";
import { flip, shift, size, useFloating } from "@floating-ui/react-dom";
import { FormRichSelectContext } from "./FormRichSelectContext";

export interface Props {
  onOpen?: () => void;
  onClose?: () => void;
  disabled?: boolean;
}

export const FormRichSelect: React.FC<React.PropsWithChildren<Props>> = ({
  onOpen,
  onClose,
  children,
  disabled = false,
}) => {
  const [open, setOpen] = useState(false);

  const floating = useFloating({
    placement: "bottom-start",
    middleware: [
      shift(),
      flip(),
      size({
        apply({ availableWidth }) {
          if (floating.refs.floating.current != null) {
            floating.refs.floating.current.style.width = `${availableWidth}px`;
          }
        },
      }),
    ],
  });

  useEffect(() => {
    if (open) {
      onOpen?.();
    } else {
      onClose?.();
    }
  }, [open, onOpen, onClose]);

  useEffect(() => {
    const onClick = (e: MouseEvent) => {
      const target = e.target as unknown as Node;
      const f = floating as UseFloatingReturn<HTMLElement>;
      if (
        f.refs.reference.current !== target &&
        f.refs.reference.current?.contains(target) === false &&
        f.refs.floating.current?.contains(target) === false
      ) {
        setOpen(false);
      }
    };

    const onKeyup = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        e.stopImmediatePropagation();
        e.preventDefault();
        setOpen(false);
      }
    };

    floating.refs.floating.current?.addEventListener("keyup", onKeyup);

    return () => {
      window.removeEventListener("click", onClick);
      floating.refs.floating.current?.removeEventListener("keyup", onKeyup);
    };
  }, [floating]);

  return (
    <FormRichSelectContext.Provider
      value={{
        setOpen: (s: boolean) => {
          setOpen(s);
          floating.update();
        },
        open,
        disabled,
        ...floating,
      }}
    >
      {children}
    </FormRichSelectContext.Provider>
  );
};
