import { useTranslation } from "@equiem/localisation-eq1";
import { Button, Form as EqForm, Modal, useToast } from "@equiem/react-admin-ui";
import type { FC } from "react";
import React, { useCallback, useContext, useMemo } from "react";
import { CurrentPortfolio } from "../../contexts/PortfolioContext";
import type { FormikHelpers } from "formik";
import { Field, Form, Formik } from "formik";
import { SideModalContext } from "../../contexts/SideModalContext";
import { ArticleContext } from "../../contexts/ArticleContext";
import type { SiteArticlesFragmentFragment } from "../../generated/gateway-client";
import { useSetSitesForArticleMutation } from "../../generated/gateway-client";
import { SiteSettingToggle } from "./SiteSettingToggle";
import * as yup from "yup";

const siteArrayToObject = (sites: SiteArticlesFragmentFragment[]): Record<string, SiteArticlesFragmentFragment> => {
  return sites.reduce<Record<string, SiteArticlesFragmentFragment>>((acc, site) => {
    acc[site.siteUuid] = site;
    return acc;
  }, {});
};

const siteObjectToArray = (sites: Record<string, SiteArticlesFragmentFragment>): SiteArticlesFragmentFragment[] => {
  const siteUuids = Object.keys(sites);
  return siteUuids.map((siteUuid) => {
    return {
      ...sites[siteUuid],
      siteUuid,
    };
  });
};

export const SiteSettingsInner: FC = () => {
  const { t } = useTranslation();
  const toast = useToast();
  const { currentPortfolio } = useContext(CurrentPortfolio);
  const { article, setArticle } = useContext(ArticleContext);
  const { closeModal, openTab } = useContext(SideModalContext);
  const [mutation] = useSetSitesForArticleMutation();

  const validationSchema = yup.object().shape({
    siteUuids: yup.array().min(1, t("contentManagement.articles.form.sitesRequired")),
  });

  const initialValues = useMemo(() => {
    return {
      siteUuids: article?.siteArticles.map((sa) => sa.siteUuid) ?? [],
      sites: article != null ? siteArrayToObject(article.siteArticles) : {},
    };
  }, [article]);

  const handleSubmit = async (values: typeof initialValues, { setSubmitting }: FormikHelpers<typeof initialValues>) => {
    setSubmitting(true);
    try {
      if (article?.uuid != null) {
        const siteArray = siteObjectToArray(values.sites);
        const saved = await mutation({
          variables: {
            input: {
              uuid: article.uuid,
              sites: values.siteUuids.map((su) => {
                const site = siteArray.find(({ siteUuid }) => su === siteUuid);
                return {
                  siteUuid: su,
                  audience:
                    site?.audience != null
                      ? {
                          segmentIds: site.audience.segmentIds,
                          segmentSummary: site.audience.segmentSummary,
                        }
                      : undefined,
                  publicationAuthorType: site?.publicationAuthorType,
                  publicationAuthor:
                    site?.publicationAuthor != null
                      ? {
                          uuid: site.publicationAuthor.uuid,
                        }
                      : undefined,
                };
              }),
            },
          },
        });
        setArticle(saved.data?.setSitesForCMSArticle);
        toast.positive(t("contentManagement.articles.modals.sitesSuccess"));
        if (article.publishedContent == null) {
          openTab("publishSettings");
        } else {
          closeModal();
        }
      }
    } catch (e: unknown) {
      console.error(e);
    }
    setSubmitting(false);
  };

  const options =
    currentPortfolio?.portfolioSites.map((site) => {
      return {
        value: site.destination.uuid,
        label: site.destination.name,
        disabled: !site.iHaveManagerAccess,
      };
    }) ?? [];

  const siteForSiteUuid = useCallback(
    (siteUuid: string) => {
      return currentPortfolio?.portfolioSites.find((s) => s.destination.uuid === siteUuid);
    },
    [currentPortfolio],
  );

  return (
    <>
      <Modal.Header intro={t("contentManagement.articles.modals.sitesIntro")} closeButton={true} />
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        validateOnMount={true}
      >
        {({ isSubmitting, isValid, submitForm, values }) => (
          <>
            <Modal.Body>
              <Form>
                <EqForm.Group label={t("contentManagement.articles.form.sites")} required>
                  <Field
                    name="siteUuids"
                    options={options}
                    as={EqForm.MultiSelect}
                    placeholder={t("contentManagement.articles.form.searchSites")}
                    searchPlaceholder={t("contentManagement.articles.form.searchSites")}
                  />
                </EqForm.Group>
                <EqForm.Group
                  label={t("contentManagement.articles.form.siteSettings")}
                  showTooltip={true}
                  tooltipText={t("contentManagement.articles.form.siteSettingsTooltip")}
                >
                  {values.siteUuids
                    .sort((a, b) => {
                      const aSite = siteForSiteUuid(a);
                      const bSite = siteForSiteUuid(b);
                      if (aSite == null || bSite == null) {
                        return 0;
                      }
                      return aSite.destination.name.localeCompare(bSite.destination.name);
                    })
                    .map((siteUuid) => {
                      const loadedSite = siteForSiteUuid(siteUuid);
                      return (
                        <SiteSettingToggle
                          formValue={values.sites[siteUuid]}
                          key={siteUuid}
                          site={loadedSite}
                          siteUuid={siteUuid}
                          disabled={loadedSite?.iHaveManagerAccess === false}
                        />
                      );
                    })}
                </EqForm.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={closeModal} variant="ghost">
                {t("common.cancel")}
              </Button>
              <Button
                className="ml-2"
                disabled={!isValid || isSubmitting}
                onClick={() => {
                  void submitForm();
                }}
              >
                {article?.publishedContent == null
                  ? t("contentManagement.articles.modals.sitesNextButton")
                  : t("contentManagement.articles.modals.saveChanges")}
              </Button>
            </Modal.Footer>
          </>
        )}
      </Formik>
    </>
  );
};
