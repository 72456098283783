import type { TFunction } from "@equiem/localisation-eq1";
import { useTranslation } from "@equiem/localisation-eq1";
import { useCompanyIndustriesQuery } from "../generated/gateway-client";

// eslint-disable-next-line complexity
export const companyNameTranslator = (name: string, t: TFunction) => {
  switch (name) {
    case "Accounting":
      return t("settings.companyIndustries.accounting");
    case "Advertising/Public Relations":
      return t("settings.companyIndustries.advertising_public_relations");
    case "Aerospace":
      return t("settings.companyIndustries.aerospace");
    case "Agriculture":
      return t("settings.companyIndustries.agriculture");
    case "Airlines":
      return t("settings.companyIndustries.airlines");
    case "Architectural Services":
      return t("settings.companyIndustries.architectural_services");
    case "Automotive":
      return t("settings.companyIndustries.automotive");
    case "Banking":
      return t("settings.companyIndustries.banking");
    case "Bars & Restaurants":
      return t("settings.companyIndustries.bars_and_restaurants");
    case "Building Services":
      return t("settings.companyIndustries.building_services");
    case "Communications":
      return t("settings.companyIndustries.communications");
    case "Construction":
      return t("settings.companyIndustries.construction");
    case "Defense":
      return t("settings.companyIndustries.defense");
    case "Educational Services":
      return t("settings.companyIndustries.educational_services");
    case "Electronics":
      return t("settings.companyIndustries.electronics");
    case "Energy & Natural Resources":
      return t("settings.companyIndustries.energy_and_natural_resources");
    case "Entertainment":
      return t("settings.companyIndustries.entertainment");
    case "Environment":
      return t("settings.companyIndustries.environment");
    case "Fashion":
      return t("settings.companyIndustries.fashion");
    case "Finance":
      return t("settings.companyIndustries.finance");
    case "Food & Beverage":
      return t("settings.companyIndustries.food_and_beverage");
    case "General Contractors":
      return t("settings.companyIndustries.general_contractors");
    case "Government Services":
      return t("settings.companyIndustries.government_services");
    case "Health Services":
      return t("settings.companyIndustries.health_services");
    case "Hospitality":
      return t("settings.companyIndustries.hospitality");
    case "Information Technology":
      return t("settings.companyIndustries.information_technology");
    case "Insurance":
      return t("settings.companyIndustries.insurance");
    case "Lawyers/Law Firms":
      return t("settings.companyIndustries.lawyers_law_firms");
    case "Logistics":
      return t("settings.companyIndustries.logistics");
    case "Manufacturing":
      return t("settings.companyIndustries.manufacturing");
    case "Media":
      return t("settings.companyIndustries.media");
    case "Other":
      return t("settings.companyIndustries.other");
    case "Pharmaceutical":
      return t("settings.companyIndustries.pharmaceutical");
    case "Printing & Publishing":
      return t("settings.companyIndustries.printing_and_publishing");
    case "Real Estate":
      return t("settings.companyIndustries.real_estate");
    case "Retail":
      return t("settings.companyIndustries.retail");
    case "Securities & Investment":
      return t("settings.companyIndustries.securities_and_investment");
    case "Telecoms":
      return t("settings.companyIndustries.telecoms");
    case "Tourism":
      return t("settings.companyIndustries.tourism");
    case "Transportation":
      return t("settings.companyIndustries.transportation");
    case "Utilities":
      return t("settings.companyIndustries.utilities");
    case "Venture Capital":
      return t("settings.companyIndustries.venture_capital");
    case "Technology":
      return t("settings.companyIndustries.technology");
    case "Engineering":
      return t("settings.companyIndustries.engineering");
    case "Legal":
      return t("settings.companyIndustries.legal");
    case "Parking":
      return t("settings.companyIndustries.parking");
    case "Security":
      return t("settings.companyIndustries.security");
    case "Dry Cleaning":
      return t("settings.companyIndustries.dry_cleaning");
    case "Sports & Fitness":
      return t("settings.companyIndustries.sports_and_fitness");
    case "Drilling/Mining":
      return t("settings.companyIndustries.drilling_mining");
    case "Professional Services":
      return t("settings.companyIndustries.professional_services");
    case "Postal/Freight":
      return t("settings.companyIndustries.postal_freight");
    case "Recruitment & Consulting":
      return t("settings.companyIndustries.recruitment_and_consulting");
    case "Not for Profit":
      return t("settings.companyIndustries.not_for_profit");
    case "Coworking":
      return t("settings.companyIndustries.coworking");
    case "Health & Wellbeing":
      return t("settings.companyIndustries.health_and_wellbeing");
    case "Data Management/Analytics":
      return t("settings.companyIndustries.data_management_analytics");
    case "Graphic Design":
      return t("settings.companyIndustries.graphic_design");
    case "Urban Development":
      return t("settings.companyIndustries.urban_development");
    case "Home and Furnishings":
      return t("settings.companyIndustries.home_and_furnishings");
    case "Medical":
      return t("settings.companyIndustries.medical");
    case "Life Sciences":
      return t("settings.companyIndustries.life_sciences");
    case "Research":
      return t("settings.companyIndustries.research");
    case "Maritime":
      return t("settings.companyIndustries.maritime");
    case "Hardware":
      return t("settings.companyIndustries.hardware");
    case "Retailer":
      return t("settings.companyIndustries.retailer");
    case "Community Services":
      return t("settings.companyIndustries.community_services");
    default:
      return name;
  }
};

export function useCompanyIndustries(skip: boolean) {
  const { t } = useTranslation();
  const industries = useCompanyIndustriesQuery({
    fetchPolicy: "cache-and-network",
    skip,
  });

  return (industries.data?.companyIndustries ?? []).map((industry) => ({
    uuid: industry.uuid,
    name: companyNameTranslator(industry.name, t),
  }));
}
