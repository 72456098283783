import React from "react";

import { useTranslation } from "@equiem/localisation-eq1";
import { Text, Tooltip, useIsMobileWidth, useTheme } from "@equiem/react-admin-ui";
import { RiErrorWarningLine } from "@equiem/react-admin-ui/icons";

import { SettingsHead } from "../../components/SettingsHead";
import { withContexts } from "../../contexts/withContexts";

import { ApartmentsFilter } from "./components/ApartmentsFilter";
import { ApartmentsList } from "./components/ApartmentsList";
import { ApartmentsFilterProvider } from "./contexts/ApartmentsFilterContext";
import { AddApartmentCatalogueWidget } from "./widgets/AddApartmentCatalogueWidget";
import { ApartmentDetailsWidget } from "./widgets/ApartmentDetailsWidget";

const ApartmentsBase: React.FC = () => {
  const { t } = useTranslation();
  const { colors, breakpoints } = useTheme();
  const isMobile = useIsMobileWidth();

  return (
    <ApartmentsFilterProvider>
      <div className="page-container">
        <SettingsHead />
        <div className="main-container">
          {!isMobile && (
            <div className="d-flex align-items-center mb-5">
              <Text variant="heading" size="medium">
                {t("settings.apartments")}
              </Text>
              <Tooltip title={t("settings.apartmentsTooltip")} placement="right" showArrow>
                <div className="ml-2">
                  <RiErrorWarningLine size={16} color={colors.grayscale[60]} />
                </div>
              </Tooltip>
            </div>
          )}
          <ApartmentsFilter />
          <ApartmentsList className="mt-5" />
          <ApartmentDetailsWidget />
          <AddApartmentCatalogueWidget />
        </div>
      </div>
      <style jsx>{`
        .page-container {
          height: 100%;
          width: 100%;
          background: ${colors.white};
        }
        .main-container {
          display: flex;
          flex-direction: column;
          padding: 32px;
        }

        @media (max-width: ${breakpoints.md}px) {
          .main-container {
            padding: 16px 0;
          }
        }
      `}</style>
    </ApartmentsFilterProvider>
  );
};

export const Apartments = withContexts(ApartmentsBase);
