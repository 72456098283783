export function matchesSearchText(searchText: string, valueTokens: string[]): boolean {
  // split the search text into words, grouping words wrapped by double quotes
  // e.g. 'apple b-asdf123 "John Smith"' -> ["apple", "b-asdf123", "john smith"]
  const searchTokens = searchText
    .trim()
    .toLowerCase()
    .match(/[\w\-:]+|"[^"]+"/gu)
    ?.map((token) => token.replace(/"/gu, ""));
  if (searchTokens == null || searchTokens.length === 0) {
    return true;
  }

  // EVERY search token MUST be included in SOME value token
  // i.e. if I search for 'boardroom "John Smith" cancelled' on the operations
  // screen I should see cancelled bookings from John Smith for the boardroom.
  return searchTokens.every((searchToken) =>
    valueTokens.some((valueToken) => valueToken.toLowerCase().includes(searchToken)),
  );
}
