import React from "react";
import { MembershipsTable } from "./MembershipsTable";
import { Menu } from "./Menu";

export const MembershipPageInner = () => {
  return (
    <>
      <Menu />
      <div className="p-6">{<MembershipsTable />}</div>
    </>
  );
};
