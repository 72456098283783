import React from "react";
import type { CardBrand } from "../../generated/gateway-client";
import { RiBankCardLine } from "@equiem/react-admin-ui/icons";

interface Props {
  brand: CardBrand;
}
export const CreditCardBrand: React.FC<Props> = ({ brand }) => (
  <>
    {brand === "UNKNOWN" ? (
      <RiBankCardLine size="30" />
    ) : (
      <img src={`/images/credit-card-brands/${brand.toLowerCase()}.png`} width="40" />
    )}
  </>
);
