import { stringIsEmpty } from "@equiem/lib";
import { useTheme } from "@equiem/react-admin-ui";
import React from "react";
import { Record, String, Literal, Union } from "runtypes";

const EmbedResult = Record({
  version: Literal("1.0"),
  type: Literal("video"),
  provider_name: Union(Literal("Vimeo"), Literal("YouTube")),
  url: String,
  thumbnail_url: String,
});

export const getVideoThumbnail = async (link: string): Promise<string | null> => {
  if (stringIsEmpty(link)) {
    return null;
  }

  const res = await fetch(
    `https://iframe.ly/api/oembed?key=${process.env.iframelyApiKey}&url=${encodeURIComponent(link)}`,
  );
  if (!res.ok) {
    throw new Error(`failed to find oembed: ${res.status}`);
  }

  const result = EmbedResult.check(await res.json());

  return result.thumbnail_url;
};

interface VideoThumbnailProps {
  imageUrl: string | null;
  loading: boolean;
  thumbnailWidth?: number;
  thumbnailHeight?: number;
}

export const ResourceVideoThumbnail: React.FC<VideoThumbnailProps> = ({
  imageUrl,
  loading,
  thumbnailWidth,
  thumbnailHeight,
}) => {
  const { colors } = useTheme();

  if (!loading && imageUrl == null) {
    return null;
  }

  return (
    <>
      <div className="video-thumbnail-cont">
        {loading ? <div className="shimmer"></div> : <img src={imageUrl ?? ""} alt="" />}
      </div>
      <style jsx>{`
        .video-thumbnail-cont {
          position: relative;
          width: ${`${thumbnailWidth}px`};
          height: ${`${thumbnailHeight}px`};
          border-radius: 4px;
          border: 1px solid ${colors.border};
          overflow: hidden;
        }
        img,
        .shimmer {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      `}</style>
    </>
  );
};
