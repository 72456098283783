import React, { useContext } from "react";

import { UrlParams } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import { Button, ComplexFilter, Text, useTheme } from "@equiem/react-admin-ui";
import { RiAddLine } from "@equiem/react-admin-ui/icons";

import { ModalContext } from "../../contexts/ModalContext";

interface P {
  className?: string;
}
export const CategoriesFilter: React.FC<P> = ({ className = "" }) => {
  const { t, i18n } = useTranslation();
  const { breakpoints } = useTheme();
  const urlParamsProvider = useContext(UrlParams);
  const modal = useContext(ModalContext);

  return (
    <>
      <div className={`filters ${className}`}>
        <ComplexFilter filters={{}} language={i18n.language} urlParamsProvider={urlParamsProvider} autoShow>
          {() => (
            <>
              <div className="main-row">
                <div className="left-side">
                  <Text variant="heading" size="medium" className="mb-3">
                    {t("requests.category.configureCategories")}
                  </Text>
                </div>
                <div className="right-side">
                  <Button
                    variant="primary"
                    onClick={() => {
                      modal.open("CategoryModal");
                    }}
                  >
                    <RiAddLine /> {t("requests.category.addCategory")}
                  </Button>
                </div>
              </div>
            </>
          )}
        </ComplexFilter>
      </div>
      <style jsx>{`
        .filters {
          display: flex;
          flex-direction: column;
          width: 100%;
        }
        .main-row {
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 8px;
        }
        .right-side,
        .left-side {
          display: flex;
          gap: 8px;
          align-items: flex-start;
        }
        .search-container {
          width: 270px;
        }

        @media (max-width: ${breakpoints.lg}px) {
          .main-row .left-side {
            overflow-x: auto;
          }

          .main-row .left-side::-webkit-scrollbar,
          .quick-filters-dropdown::-webkit-scrollbar {
            display: none;
          }

          .search-container {
            width: 100%;
          }
        }

        @media (max-width: ${breakpoints.sm}px) {
          .filters {
            padding: 16px;
          }
        }
      `}</style>
    </>
  );
};
