import React, { useContext } from "react";
import { SiteLoading as Loading } from "@equiem/lib";
import { ProgressCircle } from "@equiem/react-admin-ui";
import { PageContext } from "@equiem/lib/context/PageContext";
import { Web } from "../web/Web";
import { Mobile } from "./Mobile";

export const SiteLoading: React.FC = () => {
  const { isInWebNgLayout, isInMobileLayout } = useContext(PageContext);

  if (isInWebNgLayout || isInMobileLayout) {
    const Wrapper = isInWebNgLayout ? Web : Mobile;

    return (
      <Wrapper>
        <div className="loading">
          <ProgressCircle size={"md"} />
        </div>
        <style jsx>{`
          .loading {
            display: flex;
            justify-content: center;
            align-items: center;
          }
        `}</style>
      </Wrapper>
    );
  }

  return <Loading />;
};
