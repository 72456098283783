import React, { useCallback, useContext, useMemo, useState } from "react";

import { stringIsEmpty, stringNotEmpty, useShowError } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import { Dropdown, Form, Skeleton, Text, useTheme, useToast } from "@equiem/react-admin-ui";
import { RiMapPinLine } from "@equiem/react-admin-ui/icons";

import type { Building } from "../../../generated/requests-client";
import { type RequestQuery, useRequestBuildingsWithLevelsAndSpacesQuery } from "../../../generated/requests-client";
import { RequestDetailsContext } from "../contexts/RequestDetailsContext";
import { useAutoWidth } from "../hooks/useAutoWidth";

interface Space {
  uuid: string;
  name: string;
}
type Levels = Record<
  string,
  {
    uuid: string;
    name: string;
    spaces: Space[];
  }
>;
type Buildings = Record<
  string,
  {
    uuid: string;
    name: string;
    levels: Levels;
  }
>;

interface P {
  request: RequestQuery["reqMgt"]["request"];
  className?: string;
}
const RequestLocationEdit: React.FC<P> = ({ request, className }) => {
  const { ref, width } = useAutoWidth();
  const theme = useTheme();
  const { t } = useTranslation();
  const { loading, update } = useContext(RequestDetailsContext);
  const toast = useToast();

  const [buildingUuid, setBuildingUuid] = useState(request.space?.buildingLevel.building.uuid);
  const [buildingLevelUuid, setLevelUuid] = useState(request.space?.buildingLevel.uuid);
  const [spaceUuid, setSpaceUuid] = useState(request.space?.uuid);

  const resetSelection = useCallback(() => {
    setBuildingUuid(request.space?.buildingLevel.building.uuid);
    setLevelUuid(request.space?.buildingLevel.uuid);
    setSpaceUuid(request.space?.uuid);
  }, [request.space?.buildingLevel.building.uuid, request.space?.buildingLevel.uuid, request.space?.uuid]);

  const locations = [
    request.space?.buildingLevel.building.name,
    request.space?.buildingLevel.name,
    request.space?.name,
  ].filter(stringNotEmpty);

  const {
    data: buildingsData,
    loading: buildingsLoading,
    error: buildingsError,
  } = useRequestBuildingsWithLevelsAndSpacesQuery({
    variables: {
      ownerCompanyUuid: request.space?.ownerCompany.uuid,
    },
  });
  const buildings: Buildings = useMemo(() => {
    return ((buildingsData?.reqMgt.buildings ?? []) as Building[])
      .map((item) => ({
        ...item,
        buildingLevels: item.buildingLevels.filter(({ spaces }) => spaces.length > 0),
      }))
      .filter(({ buildingLevels }) => buildingLevels.length > 0)
      .reduce<Buildings>((prev, curr) => {
        prev[curr.uuid] = {
          uuid: curr.uuid,
          name: curr.name,
          levels: curr.buildingLevels.reduce<Levels>((lprev, lcurr) => {
            lprev[lcurr.uuid] = { ...lcurr };

            return lprev;
          }, {}),
        };

        return prev;
      }, {});
  }, [buildingsData?.reqMgt.buildings]);

  const showError = useShowError();
  if (buildingsError != null) {
    showError(buildingsError);
    return null;
  }

  return (
    <>
      <div ref={ref} className={`building-cont ${className}`}>
        <Dropdown.Container
          mobileView="minimal"
          disabled={loading}
          maxHeight={240}
          placement="bottom-start"
          width={width}
          trigger={
            <Form.SelectFacade showChrome disabled={loading}>
              <div className={`d-flex align-items-center location ${loading ? "disabled" : ""}`}>
                <RiMapPinLine className="mr-2" size="16px" /> {locations.join(", ")}
              </div>
            </Form.SelectFacade>
          }
          onClose={() => {
            if (stringIsEmpty(spaceUuid)) {
              resetSelection();
              toast.negative(t("requests.details.spaceIsRequired"));
              return;
            }
            if (spaceUuid === request.space?.uuid) {
              return;
            }
            update(request, { space: spaceUuid }).catch(console.log);
          }}
        >
          <div className="inside">
            {buildingsLoading ? (
              <Skeleton.Line width="100%" height="40px" borderRadius={theme.borderRadius} />
            ) : (
              <>
                <div>
                  <div className="pb-3">
                    <Text variant="label" color={theme.colors.grayscale["50"]}>
                      {t("common.building")}
                    </Text>
                  </div>
                  <Form.DynamicSelect
                    className="building"
                    disabled={loading || buildingUuid != null}
                    value={buildingUuid ?? ""}
                    mobileView="minimal"
                    name="building"
                    options={Object.keys(buildings).map((uuid) => ({
                      value: buildings[uuid].uuid,

                      label: buildings[uuid].name,
                    }))}
                    onChange={(e) => {
                      setBuildingUuid(e.target.value);
                      setLevelUuid(undefined);
                      setSpaceUuid(undefined);
                    }}
                  />
                </div>
                {buildingUuid != null && buildings[buildingUuid] != null && (
                  <>
                    <div>
                      <div className="pb-3">
                        <Text variant="label" color={theme.colors.grayscale["50"]}>
                          {t("requests.details.level")}
                        </Text>
                      </div>
                      <Form.DynamicSelect
                        className="level"
                        disabled={loading}
                        value={buildingLevelUuid ?? ""}
                        mobileView="minimal"
                        name="level"
                        options={Object.keys(buildings[buildingUuid].levels).map((uuid: string) => ({
                          value: buildings[buildingUuid].levels[uuid].uuid,
                          label: buildings[buildingUuid].levels[uuid].name,
                        }))}
                        onChange={(e) => {
                          setLevelUuid(e.target.value);
                          setSpaceUuid(undefined);
                        }}
                      />
                    </div>

                    {buildingLevelUuid != null && buildings[buildingUuid].levels[buildingLevelUuid] != null && (
                      <div>
                        <div className="pb-3">
                          <Text variant="label" color={theme.colors.grayscale["50"]}>
                            {t("requests.details.space")}
                          </Text>
                        </div>
                        <Form.DynamicSelect
                          className="space"
                          disabled={loading}
                          value={spaceUuid ?? ""}
                          mobileView="minimal"
                          name="level"
                          options={buildings[buildingUuid].levels[buildingLevelUuid].spaces.map((space) => ({
                            value: space.uuid,
                            label: space.name,
                          }))}
                          onChange={(e) => {
                            setSpaceUuid(e.target.value);
                          }}
                        />
                      </div>
                    )}
                  </>
                )}
              </>
            )}
          </div>
        </Dropdown.Container>
      </div>

      <style jsx>{`
        .location {
          color: ${theme.colors.grayscale["60"]};
        }
        .building-cont {
          margin-left: -${theme.spacers.s3};
        }
        .inside {
          display: flex;
          flex-direction: column;
          gap: ${theme.spacers.s4};
          padding: ${theme.spacers.s3};
        }
        .disabled {
          color: ${theme.colors.grayscale["40"]};
        }
      `}</style>
    </>
  );
};

export const RequestLocation: React.FC<P> = ({ request, className }) => {
  const theme = useTheme();
  const isConnectedToCafm =
    request.cafmRequestId != null || (request.cafmSendAttempts != null && request.cafmSendAttempts > 0);

  if (request.viewerRelations.canManage && !isConnectedToCafm) {
    return <RequestLocationEdit request={request} className={className} />;
  }

  const locations = [
    request.space?.buildingLevel.building.name,
    request.space?.buildingLevel.name,
    request.space?.name,
  ].filter(stringNotEmpty);

  return (
    <>
      <div className="d-flex align-items-center location py-3">
        <RiMapPinLine className="mr-2" size="16px" /> {locations.join(", ")}
      </div>
      <style jsx>{`
        .location {
          color: ${theme.colors.grayscale["60"]};
        }
      `}</style>
    </>
  );
};
