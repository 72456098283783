import { stringIsEmpty } from "@equiem/lib";
import { Skeleton } from "@equiem/react-admin-ui";
import { useFormikContext } from "formik";
import React from "react";
import type { FormValues } from "../../../../../lib/formValidation";

export const ResourceCreateAndEditFormPreviewName: React.FC = () => {
  const { values } = useFormikContext<FormValues>();

  if (stringIsEmpty(values.title)) {
    return <Skeleton.Line width="100%" height="28.8px" />;
  }

  return (
    <>
      <h1>{values.title}</h1>
      <style jsx>{`
        h1 {
          line-height: 1.2em;
        }
      `}</style>
    </>
  );
};
