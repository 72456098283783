import type { ReactNode } from "react";
import React, { createContext } from "react";

import { UserProfileWidget } from "../pages/users/widgets/UserProfileWidget";

import { ModalChildProvider } from "./ModalChildContext";
import { Modal, ModalProvider } from "./ModalContext";
import { WidgetProvider } from "./WidgetContext";

interface MyProfileModalContext {
  open: () => void;
  close: () => void;
}

export const MyProfileModalContext = createContext<MyProfileModalContext>({
  open: () => undefined,
  close: () => undefined,
});

export const MyProfileModalProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <ModalProvider>
      <ModalChildProvider>
        <Modal.Consumer>
          {({ open, close, activeModal }) => (
            <>
              <MyProfileModalContext.Provider value={{ open: () => open("EditProfile", undefined), close }}>
                {children}
              </MyProfileModalContext.Provider>
              {activeModal === "EditProfile" && (
                <WidgetProvider>
                  <UserProfileWidget />
                </WidgetProvider>
              )}
            </>
          )}
        </Modal.Consumer>
      </ModalChildProvider>
    </ModalProvider>
  );
};
