import React, { useContext } from "react";

import { useTranslation } from "@equiem/localisation-eq1";
import { Form, handleMultilineTextAreaInput, Text } from "@equiem/react-admin-ui";
import type { IFileV2 } from "@equiem/uploader";

import { FilePreuploader } from "../../../components/FilePreuploader";
import { CreateRequestContext } from "../CreateRequestContext";

export const DetailsStep: React.FC = () => {
  const { step, values, setValues, setIsAttachmentUploading, isAttachmentUploading } = useContext(CreateRequestContext);
  const { t } = useTranslation();

  const handleDescription = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setValues((prev) => ({ ...prev, description: e.target.value }));
  };

  const handleEnter = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    handleMultilineTextAreaInput(e, (value) => setValues((prev) => ({ ...prev, description: value })));
  };

  return (
    <>
      <div className="container">
        <Text variant="label">{t("common.step", { step })}</Text>
        <Text variant="heading" size="small">
          {t("requests.create.details")}
        </Text>
        <div className="form">
          <Form.Group label={t("common.description")}>
            <Form.Textarea
              placeholder={t("common.addDescription")}
              onChange={handleDescription}
              value={values.description}
              onKeyDown={handleEnter}
            />
          </Form.Group>
          <Form.Group label={t("common.attachments")}>
            <FilePreuploader
              showUploadButton
              value={values.attachments ?? []}
              name="attachments"
              onUploading={(uploading) => {
                setIsAttachmentUploading(uploading);
              }}
              accept=".png,.jpg,.jpeg,.pdf"
              showRemove={!isAttachmentUploading}
              onRemove={(attachments: IFileV2[]) => {
                setValues((prev) => ({ ...prev, attachments }));
              }}
              onChange={({ target: { value } }: { target: { value: IFileV2[] } }) => {
                setValues((prev) => ({ ...prev, attachments: value }));
              }}
            />
          </Form.Group>
        </div>
      </div>

      <style jsx>
        {`
          .container {
            display: flex;
            flex-direction: column;
            height: 100%;
          }

          .form {
            display: flex;
            flex-direction: column;
            margin-top: 24px;
          }

          .form :global(textarea) {
            max-height: 200px;
          }
        `}
      </style>
    </>
  );
};
