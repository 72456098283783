import type { FC } from "react";
import React from "react";

import { useTranslation } from "@equiem/localisation-eq1";
import { Button } from "@equiem/react-admin-ui";
import type { IImage } from "@equiem/uploader";
import { CropperUi, Dropzone, useImageUploaderState } from "@equiem/uploader";

import type { CreateAvatarUploadUrlMutation } from "../generated/settings-client";
import { AvatarImageType, useCreateAvatarUploadUrlMutation } from "../generated/settings-client";

const getContentType = (type?: string): AvatarImageType => {
  switch (type?.toLowerCase()) {
    case "image/png":
      return AvatarImageType.Png;
    case "image/gif":
      return AvatarImageType.Gif;
    case "image/jpeg":
      return AvatarImageType.Jpeg;
    default:
      throw new Error("Only jpg, png, gif formats are supported");
  }
};

type Upload = CreateAvatarUploadUrlMutation["createAvatarUploadUrl"];

interface ImageUploaderProps {
  onChange: (img: IImage[]) => void;
  value: IImage[];
}

export const ImageUploader: FC<ImageUploaderProps> = (props) => {
  const { t } = useTranslation();
  const [createPresignedUrl, { error }] = useCreateAvatarUploadUrlMutation();
  const {
    localAttachedImage,
    attachedImages,
    dragover,
    uploading,
    setDragover,
    onFileChange,
    progress,
    onCrop,
    onSaveCropped,
    cropperRef,
    clearCropper,
    removeImages,
  } = useImageUploaderState(
    props.value,
    (imgs) => props.onChange(imgs),
    async (input) =>
      createPresignedUrl({
        variables: {
          input: {
            filename: input.filename,
            contentType: getContentType(input.contentType),
          },
        },
      }).then((r) => r.data?.createAvatarUploadUrl as Upload),
  );

  return (
    <>
      {localAttachedImage == null && attachedImages.length === 0 && (
        <Dropzone
          dragover={dragover}
          uploading={uploading}
          setDragover={setDragover}
          onFileChange={onFileChange}
          progress={progress}
        />
      )}
      {localAttachedImage != null && attachedImages.length === 0 && (
        <CropperUi
          localAttachedImage={localAttachedImage}
          onCrop={onCrop}
          cropperRef={cropperRef}
          onSaveCropped={onSaveCropped}
          progress={progress}
          uploading={uploading}
          clearCropper={clearCropper}
          error={error}
          aspectRatio={1}
        />
      )}
      {localAttachedImage == null && attachedImages.length !== 0 && (
        <div className="text-center">
          <img src={attachedImages[0].url ?? ""} alt="" style={{ width: "100%" }} />
          <div className="text-left pt-4">
            <Button type="button" variant="outline" onClick={removeImages}>
              {t("bookings.operations.removeImage")}
            </Button>
          </div>
        </div>
      )}
    </>
  );
};
