import React, { useRef } from "react";
import type { ComponentProps, ReactNode } from "react";

import { useTheme } from "../../contexts/Theme";
import { FormCheckbox } from "../Form/FormCheckbox/FormCheckbox";

export interface SelectItemProps extends ComponentProps<"div"> {
  noMargin?: boolean;
  selected?: boolean;
  label: ReactNode;
  indeterminate?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>, shiftHeld?: boolean) => void;
  disabled?: boolean;
  itemSuffix?: ReactNode;
  onClickContainer?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

export const SelectItem: React.FC<SelectItemProps> = ({
  noMargin = false,
  selected = false,
  indeterminate = false,
  label,
  className,
  onChange,
  disabled,
  itemSuffix,
  onClickContainer,
  role,
}) => {
  const { spacers, colors, borderRadius } = useTheme();
  const ref = useRef<HTMLDivElement>(null);

  return (
    <div
      role={role ?? "option"}
      className={className}
      ref={ref}
      onClick={(e) => {
        if (e.target === ref.current) {
          onClickContainer?.(e);
        }
      }}
    >
      <span className="text">
        <FormCheckbox
          disabled={disabled}
          label={label}
          checked={selected}
          indeterminate={indeterminate}
          onChange={onChange}
        />
      </span>
      {itemSuffix}
      <style jsx>{`
        div {
          padding: ${spacers.s3};
          cursor: pointer;
          text-align: left;
          border-radius: ${borderRadius};
          margin: ${noMargin ? "none" : `0 ${spacers.s3}`};
          font-weight: 400;
          font-size: 14px;
          line-height: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: ${spacers.s1};
          background: ${selected ? colors.lightHover : "none"};
          white-space: nowrap;
        }
        .text {
          overflow: hidden;
          text-overflow: ellipsis;
        }
        div:hover,
        div:focus {
          background: ${selected ? colors.lightHover : colors.grayscale_5};
        }
        div:focus {
          outline: none;
        }
      `}</style>
    </div>
  );
};
