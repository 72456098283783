import type { ComponentProps } from "react";
import React from "react";
import { useTheme } from "../../../contexts/Theme";

export const CardFooter: React.FC<ComponentProps<"div">> = ({ children, ...props }) => {
  const { colors, spacers } = useTheme();

  return (
    <>
      <div {...props}>{children}</div>
      <style jsx>{`
        div {
          border-top: solid 1px ${colors.border};
          padding: ${spacers.s3};
        }
      `}</style>
    </>
  );
};
