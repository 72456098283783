import React, { useContext, useEffect } from "react";
import type { PropsWithChildren } from "react";

import { FormInputGroupContext } from "./FormInputGroupContext";
import { useTheme } from "../../../contexts/Theme";

// eslint-disable-next-line @typescript-eslint/no-type-alias
export type Props = PropsWithChildren<{
  type: "prefix" | "suffix";
  align?: "top" | "end" | "bottom";
}>;

export const FormInputXFix = React.forwardRef<HTMLDivElement, Props>(({ children, type, align = "center" }, ref) => {
  const group = useContext(FormInputGroupContext);
  const { spacers } = useTheme();

  useEffect(() => {
    if (type === "prefix") {
      group.setHasPrefix(true);
    } else {
      group.setHasSuffix(true);
    }
  }, [type, group]);

  return (
    <div ref={ref} className={type}>
      {children}
      <style jsx>{`
        div {
          display: flex;
          justify-content: center;
          align-self: ${{
            top: "start",
            bottom: "end",
            center: "center",
          }[align]};
          padding: ${spacers.s3};
        }
      `}</style>
    </div>
  );
});

FormInputXFix.displayName = "FormInputXFix";
