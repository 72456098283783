import React, { useContext } from "react";

import { stringIsEmpty, useSaferFormikContext } from "@equiem/lib";
import { useIsMobileWidth } from "@equiem/react-admin-ui";
import type { IFileV2 } from "@equiem/uploader";

import { FilePreuploader } from "../../../components/FilePreuploader";
import type { NewRequestFormValues } from "../../create-request/NewRequest";
import { NewRequestContext } from "../../requests/contexts/NewRequestContext";

export const NewRequestAttachments = ({ disabled }: { disabled: boolean }) => {
  const { values, setValues } = useSaferFormikContext<NewRequestFormValues>();
  const { setIsAttachmentUploading, isAttachmentUploading } = useContext(NewRequestContext);
  const isMobile = useIsMobileWidth();

  return (
    <div className="new-request-file-uploader">
      <FilePreuploader
        showUploadButton
        value={values.attachments ?? []}
        name="attachments"
        disabled={disabled || stringIsEmpty(values.space?.uuid)}
        onUploading={(uploading) => {
          setIsAttachmentUploading(uploading);
        }}
        accept=".png,.jpg,.jpeg,.pdf"
        showRemove={!isAttachmentUploading}
        onRemove={(attachments: IFileV2[]) => {
          void setValues((prev) => ({ ...prev, attachments }));
        }}
        onChange={({ target: { value } }: { target: { value: IFileV2[] } }) => {
          void setValues((prev) => ({ ...prev, attachments: value }));
        }}
      />
      <style jsx>{`
        .new-request-file-uploader {
          margin-top: 16px;
        }

        :global(.new-request-file-uploader .attached-files .attachment) {
          flex: ${isMobile ? "0 0 100% !important" : "0 0 32% !important"};
        }

        :global(.new-request-file-uploader .upload-file-button-container) {
          width: ${isMobile ? "100%" : "auto !important"};
        }
      `}</style>
    </div>
  );
};
