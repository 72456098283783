import { useTranslation } from "@equiem/localisation-eq1";
import { Button, Modal, useTheme, useToast } from "@equiem/react-admin-ui";
import type { FC } from "react";
import React, { useContext } from "react";
import { usePublishArticleMutation } from "../../generated/gateway-client";
import { ArticleContext } from "../../contexts/ArticleContext";
import { SideModalContext } from "../../contexts/SideModalContext";
import { ArticlePreview } from "./ArticlePreview";

export const PublishingInner: FC = () => {
  const { t } = useTranslation();
  const { article, setArticle } = useContext(ArticleContext);
  const { closeModal } = useContext(SideModalContext);
  const { spacers } = useTheme();
  const toast = useToast();
  const [mutation] = usePublishArticleMutation();

  const handleSubmit = async () => {
    try {
      if (article?.uuid != null) {
        const wasPreviouslyDraft = article.publishedContent == null;
        const saved = await mutation({
          variables: {
            input: {
              uuid: article.uuid,
            },
          },
        });
        setArticle(saved.data?.publishCMSArticle);
        closeModal();
        toast.positive(
          wasPreviouslyDraft
            ? t("contentManagement.articles.modals.publishingSuccess")
            : t("contentManagement.articles.modals.updatingSuccess"),
        );
      }
    } catch (e: unknown) {
      console.error(e);
    }
  };

  const draftArticle = {
    title: article?.draftContent.title,
    excerpt: article?.draftContent.excerpt,
    body: article?.draftContent.body,
  };
  const publishedArticle = {
    title: article?.publishedContent?.title,
    excerpt: article?.publishedContent?.excerpt,
    body: article?.publishedContent?.body,
  };

  return (
    <>
      <Modal.Header intro={t("contentManagement.articles.modals.publishingIntro")} closeButton={true} />
      <>
        <Modal.Body>
          <div className="grid article">
            <div>
              <ArticlePreview
                article={publishedArticle}
                downloads={article?.publishedContent?.downloads ?? undefined}
                images={article?.publishedContent?.images ?? undefined}
                title={t("contentManagement.publishedVersion")}
              />
            </div>
            <div>
              <ArticlePreview
                article={draftArticle}
                downloads={article?.draftContent.downloads ?? undefined}
                images={article?.draftContent.images ?? undefined}
                title={t("contentManagement.newVersion")}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={closeModal} variant="ghost">
            {t("common.cancel")}
          </Button>
          <Button
            className="ml-2"
            disabled={article?.isPublishable !== true}
            onClick={() => {
              void handleSubmit();
            }}
          >
            {t("contentManagement.articles.bottomBar.publishChangesConfirm")}
          </Button>
        </Modal.Footer>
      </>
      <style jsx>{`
        .grid {
          display: flex;
          flex-direction: row;
        }

        .grid > div {
          flex: 50;
          padding: ${spacers.s5};
        }

        @media all and (max-width: 1000px) {
          .grid {
            flex-direction: column;
            min-height: 0;
          }
        }
      `}</style>
    </>
  );
};
