import type { BookableResourceFragmentFragment, Weekday } from "../../../generated/gateway-client";
import type { DateTime } from "luxon";

export type Availability = BookableResourceFragmentFragment["availability"][number];

export interface TradingHour {
  day: number;
  open: {
    hour: number;
    minute: number;
  };
  close: {
    hour: number;
    minute: number;
  };
}

class DayAvailability {
  constructor(private readonly tradingHour: TradingHour) {}

  public inDayRange(target: DateTime): boolean {
    return this.tradingHour.day !== target.weekday ? false : true;
  }

  public inRange(target: DateTime): boolean {
    const [hour, minute] = [target.hour, target.minute];

    if (!this.inDayRange(target)) {
      return false;
    }
    if (this.tradingHour.open.hour > hour || this.tradingHour.close.hour < hour) {
      return false;
    }
    if (this.tradingHour.open.hour === hour && this.tradingHour.open.minute > minute) {
      return false;
    }
    if (this.tradingHour.close.hour === hour && this.tradingHour.close.minute < minute) {
      return false;
    }

    return true;
  }

  public static tradingHour(weekday: Weekday, open: string, close: string): TradingHour {
    const WeekdaysIndex = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"] as Weekday[];
    const day = WeekdaysIndex.findIndex((wd) => wd === weekday);
    if (day < 0) {
      throw new Error("Invalid weekday input.");
    }
    const [openHour, openMinute] = open.split(":");
    const [closeHour, closeMinute] = close.split(":");

    return {
      day,
      open: {
        hour: Number(openHour),
        minute: Number(openMinute),
      },
      close: {
        hour: Number(closeHour),
        minute: Number(closeMinute),
      },
    };
  }
}

/**
 * ResourceAvailability.
 */
export class ResourceAvailability {
  private readonly availabilities: Availability[] | null;

  constructor(availabilities: Availability[] | null = null) {
    this.availabilities = availabilities != null && availabilities.length > 0 ? availabilities : null;
  }

  public findMatchingAvailability(start: DateTime, end: DateTime) {
    if (this.availabilities == null) {
      return undefined;
    }

    return this.availabilities.find((availability) => {
      const tradingHours = ResourceAvailability.getTradingHours(availability);
      // Shouldn't be empty, if empty means its not getting translate correctly.
      if (tradingHours.length === 0) {
        return false;
      }

      return tradingHours.some((tradingHour) => {
        const dayAvailability = new DayAvailability(tradingHour);

        const startInRange = dayAvailability.inRange(start);
        if (!startInRange) {
          return false;
        }

        const endInRange = dayAvailability.inRange(end);
        if (!endInRange) {
          return false;
        }

        return true;
      });
    });
  }

  public static getTradingHours(availability: Availability): TradingHour[] {
    if (availability.__typename === "BookableResourceFlexibleAvailability") {
      return availability.days.map((day) => DayAvailability.tradingHour(day, availability.start, availability.end));
    }

    return [];
  }
}
