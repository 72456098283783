import React, { useState } from "react";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";

import { stringNotEmpty, useShowError } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import { Button, Form, ProgressCircle, useDefaultFont, useTheme, useToast } from "@equiem/react-admin-ui";

import { CardBrand, useSaveNewCardMutation } from "../../../../generated/settings-client";

import { CreditCardBrand } from "./CreditCardBrand";

const brandMap: Partial<Record<string, CardBrand>> = {
  amex: CardBrand.AmericanExpress,
  diners: CardBrand.DinersClub,
  discover: CardBrand.Discover,
  jcb: CardBrand.Jcb,
  mastercard: CardBrand.Mastercard,
  unionpay: CardBrand.Unionpay,
  visa: CardBrand.Visa,
};

export const AddCard: React.FC<{ onSave: () => void }> = ({ onSave }) => {
  const [open, setOpen] = useState(false);
  const [saving, setSaving] = useState(false);

  const [focused, setFocused] = useState(false);
  const [cardError, setCardError] = useState<string | null>(null);
  const [brand, setBrand] = useState<CardBrand>(CardBrand.Unknown);
  const [cardComplete, setCardComplete] = useState(false);
  const [description, setDescription] = useState("");
  const [descriptionDirty, setDescriptionDirty] = useState(false);

  const [mutation] = useSaveNewCardMutation();

  const elements = useElements();
  const stripe = useStripe();
  const toast = useToast();
  const showError = useShowError();
  const { colors, borderRadius, focusOutline, animationDuration } = useTheme();
  const defaultFont = useDefaultFont();
  const { t } = useTranslation();

  if (!open) {
    return (
      <Button variant="secondary" onClick={() => setOpen(true)}>
        {t("settings.payment.addCreditCardButton")}
      </Button>
    );
  }

  const descriptionError =
    descriptionDirty && !stringNotEmpty(description) ? t("settings.payment.descriptionRequired") : null;

  const formIsValid = cardComplete && cardError == null && stringNotEmpty(description) && descriptionError == null;

  const handleCancel = () => {
    setOpen(false);

    setFocused(false);
    setCardError(null);
    setBrand(CardBrand.Unknown);
    setCardComplete(false);
    setDescription("");
    setDescriptionDirty(false);
  };

  const handleSave = async () => {
    try {
      setSaving(true);

      const cardElement = elements?.getElement(CardElement);
      if (cardElement == null || stripe == null) {
        throw new Error("Stripe not ready");
      }

      const { token, error } = await stripe.createToken(cardElement, { name: description });
      if (error != null) {
        console.error(error);
        toast.negative(error.message ?? t("common.somethingWrong"));
        return;
      }

      const result = await mutation({
        variables: {
          card: {
            newCardToken: token.id,
            description,
            setDefault: true,
          },
        },
      });

      const saved = result.data?.saveOwnCard;
      if (saved == null) {
        throw new Error("failed to save");
      }

      toast.positive(
        saved.created < Date.now() - 60000
          ? t("settings.payment.savedExistingCardSuccessNotification")
          : t("settings.payment.savedNewCardSuccessNotification"),
      );

      handleCancel();
      onSave();
    } finally {
      setSaving(false);
    }
  };

  return (
    <>
      <div>
        <div className="card-form mb-6">
          <Form.Group className="mb-3" error={descriptionError}>
            <Form.Input
              name="card-description"
              maxLength={50}
              disabled={saving}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              onBlur={() => setDescriptionDirty(true)}
              placeholder={t("common.addDescription")}
            />
          </Form.Group>
          <div className="card-row mb-3">
            <div className="brand-container mr-4">
              <CreditCardBrand brand={brand} />
            </div>
            <div className={`stripe-container ${focused ? "focused" : ""}`}>
              <CardElement
                onFocus={() => setFocused(true)}
                onBlur={() => setFocused(false)}
                onChange={(e) => {
                  setBrand(brandMap[e.brand] ?? CardBrand.Unknown);
                  setCardComplete(e.complete);
                  setCardError(e.error?.message ?? null);
                }}
                options={{
                  disabled: saving,
                  hideIcon: true,
                  hidePostalCode: true,
                  style: {
                    base: {
                      "fontFamily": `${defaultFont}, Arial, sans-serif`,
                      "fontSize": "14px",
                      "lineHeight": "22px",
                      "::placeholder": { color: colors.muted1 },
                    },
                    invalid: { color: "#000" },
                  },
                }}
              />
            </div>
          </div>
          {stringNotEmpty(cardError) && <div className="error-row mb-3">{cardError}</div>}
          <div className="button-row">
            {saving && <ProgressCircle className="mr-2" size={32} />}
            <Button className="mr-2" variant="ghost" disabled={saving} onClick={handleCancel}>
              {t("common.cancel")}
            </Button>
            <Button
              variant="primary"
              disabled={!formIsValid || saving}
              onClick={() => {
                void handleSave().catch(showError);
              }}
            >
              {t("common.save")}
            </Button>
          </div>
        </div>
      </div>
      <style jsx>{`
        .card-form {
          padding: 1rem;
          border: 1px solid ${colors.border};
          border-radius: ${borderRadius};
        }
        .card-row {
          display: flex;
          align-items: center;
        }
        .brand-container {
          width: 45px;
          text-align: center;
        }
        .stripe-container {
          flex: 1;
          padding: 8px 16px;
          border: 1px solid ${cardError != null ? colors.danger : colors.border};
          border-radius: ${borderRadius};
          transition: ${animationDuration} box-shadow, border;
        }
        .stripe-container.focused {
          border: 1px solid ${cardError != null ? colors.danger : colors.inputBorder} !important;
          box-shadow: 0 0 0 ${focusOutline.size}
            ${cardError != null ? focusOutline.colors.error : focusOutline.colors.default} !important;
        }
        .error-row {
          font-size: 13px;
          color: ${colors.danger};
        }
        .button-row {
          display: flex;
          justify-content: right;
        }
      `}</style>
    </>
  );
};
