import { useContext, useState } from "react";
import { useApolloClient } from "@apollo/client";
import { parse } from "json2csv";
import { DateTime } from "luxon";

import { getReceptionName, notNullOrUndefined, Site, useSiteContext } from "@equiem/lib";
import { formatters, useTranslation } from "@equiem/localisation-eq1";

import type {
  VisitorReceptionsExtendedQuery,
  VisitorReceptionsExtendedQueryVariables,
  VisitorRole,
} from "../../../generated/visitors-client";
import { VisitorReceptionsExtendedDocument } from "../../../generated/visitors-client";
import { filterReceptionSettingsList } from "../../../helpers/filterReceptionList";

interface Props {
  search: string | null;
  role: VisitorRole;
}
export const useReceptionsSettingsExportData = ({ search, role }: Props) => {
  const client = useApolloClient();
  const { t, i18n } = useTranslation();
  const { timezone } = useContext(Site);
  const [reportLoading, setReportLoading] = useState(false);
  const { name: siteName } = useSiteContext();
  const fetchVisitorReceptionsForExport = async () => {
    const { data } = await client.query<VisitorReceptionsExtendedQuery, VisitorReceptionsExtendedQueryVariables>({
      query: VisitorReceptionsExtendedDocument,
      fetchPolicy: "no-cache",
      variables: {
        as: role,
        first: 1000,
      },
    });

    const result = filterReceptionSettingsList<VisitorReceptionsExtendedQuery>(data, search);
    return result == null ? [] : result.visitorReceptions.edges.map((e) => e.node).filter(notNullOrUndefined);
  };

  const getCSVFields = (maxNumOfReceptionistsInReception: number): string[] => {
    const fields = [
      { title: t("visitors.receptions.csv.siteName"), visible: true },
      { title: t("visitors.settings.receptionType"), visible: true },
      { title: t("common.building"), visible: true },
      { title: t("visitors.csv.floorLevel"), visible: true },
      { title: t("visitors.common.tenant"), visible: true },
      { title: t("visitors.receptions.csv.receptionName"), visible: true },
      { title: t("visitors.csv.dateTimeCreated"), visible: true },
      { title: t("visitors.csv.createdBy"), visible: true },
      { title: t("visitors.settings.cardIdTracking"), visible: true },
      { title: t("visitors.receptions.csv.passPrintingEnabled"), visible: true },
      { title: t("visitors.receptions.csv.sendPassOnCheckIn"), visible: true },
      { title: t("visitors.receptions.csv.barrierControlAccessGroup"), visible: true },
    ];

    for (let index = 0; index < maxNumOfReceptionistsInReception; index++) {
      fields.push({
        title: t("visitors.receptions.csv.receptionistNameAndEmail", { number: `${index + 1}` }),
        visible: true,
      });
    }

    return fields.filter((item) => item.visible).reduce((acc: string[], curr) => [...acc, curr.title], []);
  };

  const exportReceptionsCsv = async () => {
    setReportLoading(true);
    const receptions = await fetchVisitorReceptionsForExport();
    const maxNumOfReceptionistsInReception =
      receptions
        .map((r) => r.receptionists.map((rec) => rec.profile).filter(notNullOrUndefined))
        .sort((a, b) => (a.length > b.length ? -1 : 1))
        .at(0)?.length ?? 0;

    const opts = {
      fields: getCSVFields(maxNumOfReceptionistsInReception),
    };

    const csvData = receptions.map((reception) => {
      const createdDateTime = DateTime.fromMillis(reception.created, { zone: timezone });

      const result = {
        [t("visitors.receptions.csv.siteName")]: siteName,
        [t("visitors.settings.receptionType")]:
          reception.company != null ? t("visitors.common.tenant") : t("visitors.common.building"),
        [t("common.building")]: reception.building?.name,
        [t("visitors.csv.floorLevel")]: reception.buildingLevel?.name,
        [t("visitors.common.tenant")]: reception.company?.name,
        [t("visitors.receptions.csv.receptionName")]: getReceptionName(reception, t),
        [t("visitors.csv.dateTimeCreated")]: `${formatters.dateshort(createdDateTime, i18n.language, {
          timezone: timezone,
        })} ${formatters.timeshort(createdDateTime, i18n.language, { timezone: timezone })}`,
        [t("visitors.settings.cardIdTracking")]: `${reception.enableAccessCard ?? false}`,
        [t("visitors.receptions.csv.passPrintingEnabled")]: `${reception.enablePassPrinting ?? false}`,
        [t("visitors.receptions.csv.sendPassOnCheckIn")]: `${reception.sendPassOnCheckIn ?? false}`,
        [t("visitors.receptions.csv.barrierControlAccessGroup")]: reception.barrierControlAccess?.name,
      };

      reception.receptionists
        .map((r) => r.profile)
        .filter(notNullOrUndefined)
        .forEach((profile, index) => {
          result[
            t("visitors.receptions.csv.receptionistNameAndEmail", { number: `${index + 1}` })
          ] = `${profile.firstName} ${profile.lastName} (${profile.email})`;
        });

      return result;
    });

    csvData.flat();

    const csv = parse(csvData.flat(), opts);
    const blob = new Blob(["\ufeff", csv]);
    setReportLoading(false);
    return blob;
  };

  const exportCsv = async () => {
    const blob = await exportReceptionsCsv();
    const url = URL.createObjectURL(blob);
    const element = document.createElement("a");
    element.href = url;
    element.download =
      search != null && search !== ""
        ? `Custom Reception Report for ${siteName}.csv`
        : `Full Receptions Report for ${siteName}.csv`;

    document.body.appendChild(element);
    element.click();
  };

  return { exportCsv, reportLoading };
};
