/* eslint-disable @typescript-eslint/no-empty-function */
import type { ReactNode } from "react";
import React, { createContext, useState } from "react";

interface BuildingDataContext {
  selectedBuildingUuid?: string;
  selectedLevelUuid?: string;
  setSelectedBuildingUuid: (uuid?: string) => void;
  setSelectedLevelUuid: (uuid?: string) => void;
}

export const BuildingDataContext = createContext<BuildingDataContext>({
  selectedBuildingUuid: undefined,
  selectedLevelUuid: undefined,
  setSelectedBuildingUuid: () => {},
  setSelectedLevelUuid: () => {},
});

export const BuildingDataProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [selectedBuildingUuid, setSelectedBuildingUuid] = useState<string>();
  const [selectedLevelUuid, setSelectedLevelUuid] = useState<string>();

  return (
    <BuildingDataContext.Provider
      value={{ selectedBuildingUuid, setSelectedBuildingUuid, selectedLevelUuid, setSelectedLevelUuid }}
    >
      {children}
    </BuildingDataContext.Provider>
  );
};
