import type { ComponentProps } from "react";
import React from "react";
import { FormGroupContext } from "../../../contexts/FormGroupContext";
import { FormHint } from "../FormHint/FormHint";
import { FormLabel } from "../FormLabel/FormLabel";
import { useHtmlId } from "../../../hooks/useHtmlId";
import { useTheme } from "../../../contexts/Theme";
import { RiInformationLine } from "../../../admin-icons";
import { BsInfoCircle } from "../../../end-user-icons";
import { Tooltip } from "../../Tooltip/Tooltip";
import type { Placement } from "@floating-ui/react-dom-interactions";
import css from "styled-jsx/css";

export const FormGroup: React.FC<
  Partial<FormGroupContext> &
    ComponentProps<"div"> & {
      showTooltip?: boolean;
      tooltipText?: string | null;
      disabled?: boolean;
      tooltipPlacement?: Placement;
      inline?: "sm" | "md" | "lg" | boolean;
      noHeaderMargin?: boolean;
      noGroupMargin?: boolean;
      labelWidth?: string;
      placeholder?: string;
    }
> = ({
  label,
  error,
  required = false,
  hint,
  id: inputId,
  children,
  hasError = error != null && error !== "",
  showTooltip = false,
  tooltipText,
  tooltipPlacement = "right",
  inline = false,
  noHeaderMargin = false,
  labelWidth,
  noGroupMargin,
  ...props
}) => {
  const { isAdmin, spacers, breakpoints } = useTheme();
  const id = useHtmlId(inputId);
  const labelId = `${id}-label`;
  const hintId = `${id}-hint`;
  const TooltipIcon = isAdmin ? RiInformationLine : BsInfoCircle;

  const { styles: inlineResponsiveStyles, className } =
    typeof inline !== "string"
      ? css.resolve``
      : css.resolve`
          @media screen (max-width: ${breakpoints[inline]}px) {
            .form-group {
              display: block;
            }
            .tooltip {
              margin-top: 3px;
            }
            .form-header {
              width: auto;
            }
          }
        `;

  return (
    <div {...props} className={`form-group ${props.className ?? ""} ${className}`} role="group">
      <div className="form-header">
        {label != null || required ? (
          <FormLabel noMargin={noHeaderMargin} required={required} htmlFor={id} id={labelId} inline={inline}>
            {label}
          </FormLabel>
        ) : null}

        {showTooltip && (
          <Tooltip title={tooltipText ?? ""} placement={tooltipPlacement} showArrow limitMobileWidth={true}>
            <div className="tooltip">
              <TooltipIcon color="#666666" />
            </div>
          </Tooltip>
        )}
      </div>
      <div>
        <FormGroupContext.Provider
          value={{
            label,
            labelId,
            hintId,
            error,
            required,
            hint,
            id,
            hasError,
          }}
        >
          {children}
        </FormGroupContext.Provider>
        <FormHint hint={hint} error={error} id={hintId} />
      </div>
      <style jsx>{`
        div {
          width: 100%;
        }
        .form-group {
          margin-bottom: ${isAdmin && noGroupMargin !== true ? spacers.s6 : spacers.s0};
          display: ${inline !== false ? "flex" : "block"};
        }
        .tooltip {
          margin-left: ${spacers.s2};
          opacity: 1;
          position: relative;
          z-index: 1;
          height: 1rem;
          margin-top: ${inline !== false ? "11px" : "3px"};
          width: max-content;
        }
        .tooltip svg {
          display: block;
        }
        :global(.tooltip-text .multi-line) {
          text-align: left !important;
        }
        .form-header {
          display: flex;
          flex-direction: row;
          text-transform: ${isAdmin ? "uppercase" : undefined};
          width: ${inline !== false ? labelWidth ?? "40%" : undefined};
        }
      `}</style>
      {inlineResponsiveStyles}
    </div>
  );
};
