import { DateTime } from "luxon";

export const getMaxAllowedAppointmentDateTime = (
  companyLimitMonths?: number | null,
  siteLimitMonths?: number | null,
  timezone?: string,
) => {
  const maxDate = DateTime.now()
    .setZone(timezone)
    .plus({ months: companyLimitMonths ?? siteLimitMonths ?? 12 })
    .startOf("day");
  return maxDate;
};
