import { useContext } from "react";

import { CurrentRole, Role, useSiteContext } from "@equiem/lib";

export function useVisitorEnabled(): boolean {
  const { currentRole } = useContext(CurrentRole);
  const site = useSiteContext();

  const isEnabledForManagers =
    [Role.PropertyManager, Role.WorkplaceManager].includes(currentRole) && site.featureModules.visitors.enabled;

  const isEnabledForEndUsers =
    site.featureMenus.visitorsEnabled && currentRole === Role.Unknown && site.tierLevel === "Essentials";

  return isEnabledForManagers || isEnabledForEndUsers;
}
