import type { AnchorHTMLAttributes } from "react";
import React, { forwardRef } from "react";

import { useTheme } from "../../contexts/Theme";
import type { BaseButtonVariants, BaseButtonSize, BaseButtonShape } from "./renderAdminButtonStyles";
import { renderAdminButtonStyles } from "./renderAdminButtonStyles";
import { AdminButtonDropdownToggle } from "./AdminButtonDropdownToggle";

export type { BaseButtonVariants, BaseButtonSize } from "./renderAdminButtonStyles";

export interface ButtonProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
  variant?: BaseButtonVariants;
  size?: BaseButtonSize;
  inverted?: boolean;
  shape?: BaseButtonShape;
  disabled?: boolean;
  dropdownToggle?: boolean;
}

export const AdminButtonLink = forwardRef<HTMLAnchorElement, ButtonProps>(
  (
    {
      className,
      children,
      variant = "primary",
      size = "md",
      dropdownToggle = false,
      inverted = false,
      shape = "default",
      disabled = false,
      ...props
    },
    ref,
  ) => {
    const theme = useTheme(true);
    const { className: buttonClassName, styles } = renderAdminButtonStyles({
      element: "a",
      variant,
      size,
      shape,
      inverted,
      theme,
    });

    return (
      <a
        ref={ref}
        className={`${className ?? ""} ${buttonClassName} ${disabled ? "disabled" : ""}`}
        aria-disabled={disabled}
        {...props}
      >
        {children}
        {dropdownToggle && <AdminButtonDropdownToggle size={size} />}
        {styles}
      </a>
    );
  },
);

AdminButtonLink.displayName = "Button";
