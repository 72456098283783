import React from "react";

interface Props {
  className?: string;
  logo?: string | null;
  name: string;

  size?: string;
  logoSize?: string;

  showLogoOnWhiteBackground: boolean;
  primaryColour: string;
}

export const SiteLogo: React.FC<Props> = ({
  className = "",
  logo,
  name,
  size = "40px",
  logoSize = size,
  showLogoOnWhiteBackground,
  primaryColour,
}) => {
  return (
    <>
      <div className={`logo ${className}`}>{logo != null && <img src={logo} alt={name} />}</div>
      <style jsx>{`
        .logo {
          background: ${showLogoOnWhiteBackground ? "#fff" : primaryColour};
          height: ${size};
          width: ${size};
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          overflow: hidden;
        }
        img {
          max-width: ${logoSize};
          max-height: ${logoSize};
        }
      `}</style>
    </>
  );
};
