import { FormRichSelect } from "./FormRichSelect";
import { FormRichSelectCurrent } from "./FormRichSelectCurrent/FormRichSelectCurrent";
import { FormRichSelectItem } from "./FormRichSelectItem/FormRichSelectItem";
import { FormRichSelectMenu } from "./FormRichSelectMenu/FormRichSelectMenu";

export {
  FormRichSelect as Select,
  FormRichSelectCurrent as Current,
  FormRichSelectItem as Item,
  FormRichSelectMenu as Menu,
};
