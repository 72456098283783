import { stringNotEmpty } from "@equiem/lib";
import { useFormikContext } from "formik";
import { useMemo } from "react";
import type { BookingFormValue } from "../models/BookingFormValue";

export const useAddOnError = (addOnIndex: number) => {
  const fm = useFormikContext<BookingFormValue>();

  return useMemo(() => {
    if (!Array.isArray(fm.errors.addOns) || !Array.isArray(fm.touched.addOns)) {
      return undefined;
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
    const touched: boolean = fm.touched.addOns[addOnIndex]?.value ?? fm.touched.addOns[addOnIndex] ?? false;

    return touched && stringNotEmpty(fm.errors.addOns[addOnIndex]) ? fm.errors.addOns[addOnIndex] : undefined;
  }, [addOnIndex, fm.errors.addOns, fm.touched.addOns]);
};
