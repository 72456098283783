import { Session, stringIsEmpty, stringNotEmpty } from "@equiem/lib";
import { type TFunction, useTranslation } from "@equiem/localisation-eq1";
import { Button, Form, Modal, Text, useTheme } from "@equiem/react-admin-ui";
import { AudienceModal } from "@equiem/segmentation-widget";
import { Form as FormikForm, Formik, Field } from "formik";
import React, { useContext, useState } from "react";
import { useAudienceDestinations } from "../../../hooks/useAudienceDestinations";
import type { BookableResourceSiteAudienceInput } from "../../../../../generated/gateway-client";
import { BookableResourcePaymentMethod } from "../../../../../generated/gateway-client";
import type { SegmentMapItem } from "../../../../../lib/validationSchema";
import { permissionsModalValidationSchema } from "../../../../../lib/validationSchema";
import { useCompanyIsFlexOperator } from "../../../hooks/useCompanyIsFlexOperator";
import { ResourceCreateAndEditSiteContext } from "../ResourceCreateAndEditSiteContext";

type BookableResourceSiteAudience = BookableResourceSiteAudienceInput & { siteName: string };

type PermissionsModalFormValues = {
  siteAudience: BookableResourceSiteAudience;
};

interface Props {
  showModal: boolean;
  setShowModal: (_state: boolean) => void;
  modalIndex: number | null;
  setModalIndex: (_index: number | null) => void;
  resourceOwnerSite: string;
  siteAudience?: BookableResourceSiteAudience;
  theOnlyDomesticAudience: boolean;
  segmentsMap: SegmentMapItem[];
  currency?: string;
  onAdd: (siteAudience: BookableResourceSiteAudience) => void;
  onEdit: (siteAudience: BookableResourceSiteAudience, _index: number) => void;
}

export const isAll = (audience: BookableResourceSiteAudience) =>
  audience.segmentIds == null || audience.segmentIds.length === 0;

const getAudiencesInfo = (audience: BookableResourceSiteAudience, t: TFunction) => {
  if (stringNotEmpty(audience.segmentSummary)) {
    return audience.segmentSummary;
  } else {
    return isAll(audience) ? t("common.all") : "-";
  }
};

export const ResourceCreateAndEditFormPermissionsModal: React.FC<Props> = ({
  showModal,
  setShowModal,
  modalIndex,
  setModalIndex,
  resourceOwnerSite,
  siteAudience,
  theOnlyDomesticAudience,
  segmentsMap,
  currency,
  onAdd,
  onEdit,
}) => {
  const { t } = useTranslation();
  const { breakpoints, colors, spacers } = useTheme(true);
  const { authenticatedClient: client } = useContext(Session);
  const { canManageSite } = useContext(ResourceCreateAndEditSiteContext);

  const [audienceShowModal, setAudienceShowModal] = useState(false);

  const sites = useAudienceDestinations(resourceOwnerSite);

  const isFlexOperator = useCompanyIsFlexOperator();

  return (
    <Modal.Dialog
      title={modalIndex != null ? t("bookings.resources.editAudience") : t("bookings.resources.addAnotherAudience")}
      show={showModal}
      size="xl"
      onHide={() => {
        setShowModal(false);
        setModalIndex(null);
      }}
      hideOnClick={false}
      hideOnEsc={false}
      supportsMobile={true}
      focusTrapOptions={{ initialFocus: false }}
      centered
    >
      <div className="permissions-modal">
        <Modal.Header supportsMobile={true} closeButton={true} noBorder={false} />
        <Formik<PermissionsModalFormValues>
          initialValues={{
            siteAudience: siteAudience ?? {
              site: "",
              siteName: "",
              segmentIds: [],
              segmentSummary: "",
            },
          }}
          validationSchema={permissionsModalValidationSchema(segmentsMap, t)}
          validateOnMount={true}
          onSubmit={(values, actions) => {
            actions.setSubmitting(true);
            modalIndex != null ? onEdit(values.siteAudience, modalIndex) : onAdd(values.siteAudience);
            actions.setSubmitting(false);
          }}
        >
          {({ isSubmitting, isValid, setFieldValue, setValues, submitForm, touched, values, errors }) => {
            let siteAudienceError;
            if (touched.siteAudience?.site === true && errors.siteAudience?.site != null) {
              siteAudienceError = errors.siteAudience.site;
            } else if (errors.siteAudience?.segmentIds != null) {
              siteAudienceError = errors.siteAudience.segmentIds;
            }

            return (
              <FormikForm>
                <Modal.Body>
                  <Form.Group label={t("common.site")} required error={siteAudienceError}>
                    <div className="audience-form-group-container">
                      <div className="audience-form-group">
                        {!theOnlyDomesticAudience ? (
                          <Field
                            id="siteAudience.site"
                            name="siteAudience.site"
                            disabled={isSubmitting}
                            as={Form.Select}
                            value={values.siteAudience.site}
                            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                              const siteNames = new Map(sites.map((site) => [site.uuid, site.name]));
                              setValues({
                                ...values,
                                siteAudience: {
                                  site: e.target.value,
                                  siteName: siteNames.get(e.target.value) ?? "",
                                  segmentIds: [],
                                  segmentSummary: "",
                                },
                              }).catch(console.error);
                            }}
                          >
                            <option disabled value="">
                              {t("bookings.resources.selectSite")}
                            </option>
                            {sites.map((site) => (
                              <option key={site.uuid} value={site.uuid}>
                                {site.name}
                              </option>
                            ))}
                          </Field>
                        ) : (
                          <div className="domestic-site-name">
                            <Text variant="text" component="span" size="small">
                              {siteAudience?.siteName}
                            </Text>
                          </div>
                        )}
                        <div>
                          <Button
                            variant="secondary"
                            type="button"
                            size="lg"
                            disabled={isSubmitting || stringIsEmpty(values.siteAudience.site)}
                            onClick={() => setAudienceShowModal(true)}
                          >
                            {t("bookings.resources.selectAudience")}
                          </Button>
                        </div>
                      </div>
                      <div>
                        <Text variant="text" component="span" size="small">
                          {getAudiencesInfo(values.siteAudience, t)}
                        </Text>
                      </div>
                    </div>
                  </Form.Group>

                  {stringNotEmpty(values.siteAudience.site) &&
                    (resourceOwnerSite !== values.siteAudience.site || !isAll(values.siteAudience)) && (
                      <>
                        <div className="form-group-container">
                          <div>
                            <Form.Group label={t("bookings.resources.hourly")}>
                              <Field
                                name="siteAudience.paymentRateHourly"
                                as={Form.Money}
                                currency={currency}
                                min={0}
                                max={9999.99}
                              />
                            </Form.Group>
                          </div>

                          <div>
                            <Form.Group label={t("bookings.resources.halfDay")}>
                              <Field
                                name="siteAudience.paymentRateHalfDay"
                                as={Form.Money}
                                currency={currency}
                                min={0}
                                max={9999.99}
                              />
                            </Form.Group>
                          </div>

                          <div>
                            <Form.Group label={t("bookings.resources.fullDay")}>
                              <Field
                                name="siteAudience.paymentRateFullDay"
                                as={Form.Money}
                                currency={currency}
                                min={0}
                                max={9999.99}
                              />
                            </Form.Group>
                          </div>

                          <div>
                            <Form.Group label={t("bookings.resources.hourlyAfterHours")}>
                              <Field
                                name="siteAudience.paymentRateHourlyAfterHours"
                                as={Form.Money}
                                currency={currency}
                                min={0}
                                max={9999.99}
                              />
                            </Form.Group>
                          </div>

                          <div>
                            <Form.Group label={t("bookings.resources.hourlyWeekend")}>
                              <Field name="siteAudience.paymentRateHourlyWeekend" as={Form.Money} currency={currency} />
                            </Form.Group>
                          </div>
                        </div>

                        <Form.Group label={t("bookings.resources.paymentMethods")}>
                          <div className="payment-methods-container">
                            <Field
                              as={Form.Checkbox}
                              id={`siteAudiences-pm-${BookableResourcePaymentMethod.CreditCard}`}
                              name="siteAudience.paymentMethods"
                              label={t("common.creditCard")}
                              disabled={!canManageSite}
                              value={values.siteAudience.paymentMethods?.includes(
                                BookableResourcePaymentMethod.CreditCard,
                              )}
                              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                const updatedMethods = e.target.checked
                                  ? [
                                      ...(values.siteAudience.paymentMethods ?? []),
                                      BookableResourcePaymentMethod.CreditCard,
                                    ]
                                  : (values.siteAudience.paymentMethods ?? []).filter(
                                      (method) => method !== BookableResourcePaymentMethod.CreditCard,
                                    );
                                setFieldValue("siteAudience.paymentMethods", updatedMethods).catch(console.error);
                              }}
                            />
                            <Field
                              as={Form.Checkbox}
                              id={`siteAudiences-pm-${BookableResourcePaymentMethod.Invoice}`}
                              name="siteAudience.paymentMethods"
                              label={t("common.invoice")}
                              value={values.siteAudience.paymentMethods?.includes(
                                BookableResourcePaymentMethod.Invoice,
                              )}
                              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                const updatedMethods = e.target.checked
                                  ? [
                                      ...(values.siteAudience.paymentMethods ?? []),
                                      BookableResourcePaymentMethod.Invoice,
                                    ]
                                  : (values.siteAudience.paymentMethods ?? []).filter(
                                      (method) => method !== BookableResourcePaymentMethod.Invoice,
                                    );
                                setFieldValue("siteAudience.paymentMethods", updatedMethods).catch(console.error);
                              }}
                            />
                            {isFlexOperator && (
                              <Field
                                as={Form.Checkbox}
                                id={`siteAudiences-pm-${BookableResourcePaymentMethod.Credits}`}
                                name="siteAudience.paymentMethods"
                                label={t("common.credits")}
                                value={values.siteAudience.paymentMethods?.includes(
                                  BookableResourcePaymentMethod.Credits,
                                )}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                  const updatedMethods = e.target.checked
                                    ? [
                                        ...(values.siteAudience.paymentMethods ?? []),
                                        BookableResourcePaymentMethod.Credits,
                                      ]
                                    : (values.siteAudience.paymentMethods ?? []).filter(
                                        (method) => method !== BookableResourcePaymentMethod.Credits,
                                      );
                                  setFieldValue("siteAudience.paymentMethods", updatedMethods).catch(console.error);
                                }}
                              />
                            )}
                          </div>
                        </Form.Group>
                      </>
                    )}
                </Modal.Body>

                <Modal.Footer>
                  <Button
                    variant="ghost"
                    type="button"
                    onClick={() => {
                      setShowModal(false);
                      setModalIndex(null);
                    }}
                  >
                    {t("common.cancel")}
                  </Button>
                  <Button
                    className="add-button"
                    type="submit"
                    variant="primary"
                    onSubmit={() => {
                      void submitForm();
                    }}
                    disabled={isSubmitting || !isValid}
                  >
                    {modalIndex != null ? t("common.save") : t("common.add")}
                  </Button>
                </Modal.Footer>
                <AudienceModal
                  connection={{ client }}
                  site={values.siteAudience.site}
                  segmentIds={values.siteAudience.segmentIds ?? undefined}
                  callback={(segmentIds, segmentSummary) => {
                    setAudienceShowModal(false);

                    setFieldValue("siteAudience.segmentSummary", segmentSummary)
                      .then(() => {
                        setFieldValue("siteAudience.segmentIds", segmentIds).catch(console.error);
                      })
                      .catch(console.error);
                  }}
                  modal={{
                    showModal: audienceShowModal,
                    onHide: () => setAudienceShowModal(false),
                  }}
                />
              </FormikForm>
            );
          }}
        </Formik>
      </div>

      <style jsx>
        {`
          .permissions-modal :global(.header) {
            padding: ${spacers.s6} !important;
          }
          .permissions-modal :global(.header .title-row) {
            margin: ${spacers.s0} !important;
          }
          .permissions-modal :global(.header .title) {
            color: ${colors.dark};
            font-size: 24px;
            font-weight: 700;
            line-height: 28px;
            text-transform: none;
            text-align: left;
            z-index: 1;
          }
          .permissions-modal :global(.modal-body) {
            display: flex;
            flex-direction: column;
            padding: ${spacers.s7} ${spacers.s6};
            margin: ${spacers.s0};
            gap: ${spacers.s7};
            overflow-y: auto;
            max-height: 400px;
          }
          .permissions-modal :global(.modal-body p),
          .permissions-modal :global(.modal-body .form-group) {
            margin: ${spacers.s0};
          }
          .permissions-modal :global(.modal-body .audience-form-group-container) {
            display: flex;
            flex-direction: column;
            gap: ${spacers.s3};
          }
          .permissions-modal :global(.modal-body .audience-form-group) {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: ${spacers.s3};
          }
          .permissions-modal :global(.modal-body .domestic-site-name) {
            padding-left: ${spacers.s5};
          }
          .permissions-modal :global(.modal-body .form-group-container) {
            display: flex;
            gap: ${spacers.s7};
          }
          .permissions-modal :global(.modal-body .payment-methods-container) {
            display: flex;
            gap: ${spacers.s7};
          }
          .permissions-modal :global(.footer) {
            padding: ${spacers.s5} ${spacers.s7} !important;
            gap: ${spacers.s3};
            border-top: 1px solid ${colors.grayscale[10]};
          }
          .permissions-modal :global(.footer .add-button) {
            width: 200px;
          }
          @media screen and (max-width: ${breakpoints.md}px) {
            .permissions-modal :global(.header .title) {
              font-size: 20px;
            }
            .permissions-modal :global(.header button) {
              z-index: 2;
            }
            .permissions-modal :global(.modal-body) {
              padding: ${spacers.s5};
              gap: ${spacers.s5};
            }
            .permissions-modal :global(.modal-body .form-group-container) {
              flex-direction: column;
              gap: ${spacers.s5};
            }
            .permissions-modal :global(.footer button) {
              flex: 1;
            }
          }
        `}
      </style>
    </Modal.Dialog>
  );
};
